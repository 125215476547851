import * as React from 'react'

const ListIcon: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4H28V6H14V4ZM14 18H28V20H14V18ZM8 10H2C1.46973 9.99947 0.961329 9.78859 0.586371 9.41363C0.211413 9.03867 0.000529477 8.53027 0 8V2C0.000529477 1.46973 0.211413 0.961329 0.586371 0.586371C0.961329 0.211413 1.46973 0.000529477 2 0H8C8.53027 0.000529477 9.03867 0.211413 9.41363 0.586371C9.78859 0.961329 9.99947 1.46973 10 2V8C9.99947 8.53027 9.78859 9.03867 9.41363 9.41363C9.03867 9.78859 8.53027 9.99947 8 10ZM2 2V8H8.001L8 2H2ZM8 24H2C1.46973 23.9995 0.961329 23.7886 0.586371 23.4136C0.211413 23.0387 0.000529477 22.5303 0 22V16C0.000529477 15.4697 0.211413 14.9613 0.586371 14.5864C0.961329 14.2114 1.46973 14.0005 2 14H8C8.53027 14.0005 9.03867 14.2114 9.41363 14.5864C9.78859 14.9613 9.99947 15.4697 10 16V22C9.99947 22.5303 9.78859 23.0387 9.41363 23.4136C9.03867 23.7886 8.53027 23.9995 8 24ZM2 16V22H8.001L8 16H2Z"
        fill="#26A7DE"
      />
    </svg>
  )
}

export default ListIcon
