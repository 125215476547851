import * as React from 'react'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from 'src/ui-elements/toast/Alert'
import VersionDetector from './VersionDetector'

const VersionAlert: React.FC<PropsWithChildren> = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false)

  const { t } = useTranslation()

  const showRefreshAlert = () => {
    setShowAlert(true)
  }

  useEffect(() => {
    VersionDetector.setVersionCallback(showRefreshAlert)
  }, [])

  return (
    <>
      {showAlert && (
        <div className="fixed mx-4 xsm:mx-20  sm:mx-0 sm:left-1/2 sm:-translate-x-1/2 top-0 z-50">
          <Alert
            alertId={-1}
            title={t('new_version_is_available')}
            description={`${t('a_new_version_is_available')}. ${t(
              'complete_your_work_click_here_to_refresh',
            )}`}
            type="info"
            autoClose={false}
            clearFunc={() => setShowAlert(false)}
            onClick={VersionDetector.refresh}
          />
        </div>
      )}
      {children}
    </>
  )
}

export default VersionAlert
