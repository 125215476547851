import { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ProjectPage from '../page/project/ProjectPage'
import { ProjectContext } from './ProjectContextProvider/ProjectContext'
import { UserContext } from './UserContextProvider/UserContext'

interface ISystemsContextHOC {
  routeProps?: RouteComponentProps
}

const ProjectContextHOC = ({ routeProps }: ISystemsContextHOC) => {
  const projectContext = useContext(ProjectContext)
  const userContext = useContext(UserContext)

  return (
    projectContext.state.currentProject &&
    userContext.state.user && (
      <ProjectPage
        projectId={projectContext.state.currentProject.id}
        projectAdmin={
          projectContext.state.isProjectAdmin ||
          userContext.state.user.isSystemAdmin
        }
        reload={projectContext.actions.reload}
        routeProps={routeProps}
      />
    )
  )
}

export default ProjectContextHOC
