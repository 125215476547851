import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPanel } from 'react-tabs'
import DisciplinesList from '../../components/discipline/DisciplinesList'
import {
  deleteContract,
  getProjectContracts,
} from '../../service/ContractService'
import { IContract } from '../../service/OrgTypes'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import { ResourceType } from '../../ui-elements/list/ListContextProvider'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import { TabsWrapper } from '../../ui-elements/tabs/Tabs'
import { capFirstLetter } from '../../utility/utils.js'
import DeleteModal from '../delete-modal/DeleteModal'
import ContractUsersList from '../user/ContractUsersList'
import ContractForm from './ContractForm'
import ContractInspectorPanel from './contractInspectorPanel'

interface IContractsList {
  projectId: number
  reloadParent: () => void
  onTreeSelect: (id: number, type: string) => void
}

const ContractsList = ({
  projectId,
  reloadParent,
  onTreeSelect,
}: IContractsList) => {
  useEffect(() => {
    reload()
    setContracts([])
    setLoading(true)
  }, [projectId])

  const { t } = useTranslation()
  const [contracts, setContracts] = useState<IContract[]>([])
  const [selectedContract, setSelectedContract] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const tabItems: string[] = [capFirstLetter(t('disciplines')), t('users')]
  const [openInspectorPanel, setOpenInspection] = useState(false)

  const reload = () => {
    getProjectContracts(projectId)
      .then((res: IContract[]) => {
        setContracts(res)
        setLoading(false)
      })
      .catch((err) => console.error(err))
    reloadParent()
  }

  const onEditItemClick = (contract: IContract) => {
    setShowEditModal((prevState) => !prevState)
    setSelectedContract(contract)
  }

  const onDeletItemClick = (contract: IContract) => {
    setShowDeleteModal((prevState) => !prevState)
    setSelectedContract(contract)
  }

  const closeDetailsModal = () => {
    setShowDetailsModal((prevState) => !prevState)
    reload()
  }

  const openEditModal = () => {
    setShowEditModal((prevState) => !prevState)
  }

  const closeEditModal = () => {
    setShowEditModal((prevState) => !prevState)
    reload()
  }

  const closeDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState)
  }

  const openCreateModal = () => {
    setShowCreateModal((prevState) => !prevState)
  }

  const closeCreateModal = () => {
    setShowCreateModal((prevState) => !prevState)
    reload()
  }

  const onDeleteContract = () => {
    deleteContract(selectedContract)
      .then(() => {
        reload()
      })
      .catch(() => {
        setLoading(false)
      })

    setShowDeleteModal((prevState) => !prevState)
  }

  const onRowClick = (row: any) => {
    setSelectedContract(row)
    onTreeSelect
      ? onTreeSelect?.(row.id, 'contract')
      : setShowDetailsModal((prevState) => !prevState)
  }

  const onCloseInpectionPanel = () => {
    setOpenInspection(false)
  }

  const handlePreviewClick = (e: any, data: IContract) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedContract(data)
    setOpenInspection(true)
  }

  const columns: IListColumns[] = [
    {
      name: 'contract_number',
      size: '300',
      id: 'contractNumber',
      dataField: 'contractNumber',
    },
    {
      name: 'contract_name',
      size: '300',
      id: 'contractName',
      dataField: 'contractName',
    },
    {
      name: 'size',
      size: '300',
      id: 'contractSize',
      dataField: 'contractSize',
    },
    {
      name: 'company',
      size: '300',
      id: 'companyName',
      dataField: 'company_name',
    },
    {
      name: 'created_at',
      size: '130',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  return (
    <>
      <List
        actionButton={
          <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
            {t('new_contract')}
          </Button>
        }
        data={contracts}
        tableName={'contractList'}
        columns={columns}
        resourceType={ResourceType.CONTRACT}
        itemsPerPage={0}
        onRowClick={onRowClick}
        actionMenu={[
          {
            name: t('edit'),
            action: onEditItemClick,
          },
          {
            name: t('delete'),
            action: onDeletItemClick,
          },
        ]}
        onPreviewClick={handlePreviewClick}
      />

      <Modal
        show={showDetailsModal}
        closeModal={closeDetailsModal}
        title={
          selectedContract
            ? selectedContract.contractNumber +
              ' ' +
              selectedContract.contractName
            : t('contract_details')
        }
        size={'w-9/10'}
        maxWidth={1100}
      >
        {selectedContract ? (
          <div className={'w-auto'}>
            <TabsWrapper
              tabIndex={tabIndex}
              tabItems={tabItems}
              onSelect={(index) => setTabIndex(index)}
              tabListStyle={'-mt-px'}
            >
              <TabPanel className={'w-full'}>
                <div
                  className={
                    'overflow-x-auto overflow-y-hidden  ml-2 pl-1 pb-4 -mb-4'
                  }
                >
                  <DisciplinesList
                    parentType={'Contract'}
                    parentId={selectedContract.id}
                    readonly={false}
                  />
                </div>
              </TabPanel>
              <TabPanel className={'w-full'}>
                <div
                  className={
                    'overflow-x-auto overflow-y-hidden ml-5 pb-4 -mb-4'
                  }
                >
                  <ContractUsersList contractId={selectedContract.id} />
                </div>
              </TabPanel>
            </TabsWrapper>
          </div>
        ) : null}
      </Modal>

      <Modal
        show={showEditModal}
        closeModal={openEditModal}
        title={t('edit_contract')}
        maxWidth={600}
      >
        <ContractForm
          editingMode={true}
          contract={selectedContract}
          projectId={projectId}
          closeModal={closeEditModal}
        />
      </Modal>

      {selectedContract && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={onDeleteContract}
          itemIdnType={`${selectedContract.contractNumber} (${t('contract')})`}
          itemName={`${selectedContract.contractNumber} - ${selectedContract.contractName}`}
        />
      )}

      <Modal
        size={'w-1/3'}
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_contract')}
        maxWidth={600}
      >
        <ContractForm
          editingMode={false}
          contract={{
            id: 0,
            contractName: '',
            contractNumber: '',
            company_name: '',
            contractSize: '',
          }}
          projectId={projectId}
          closeModal={closeCreateModal}
        />
      </Modal>
      {openInspectorPanel && selectedContract && selectedContract.id && (
        <ContractInspectorPanel
          contractId={selectedContract.id}
          open={openInspectorPanel}
          onClose={onCloseInpectionPanel}
          onUpdate={reload}
        />
      )}
      {loading ? <Loader /> : null}
    </>
  )
}

export default ContractsList
