import { Fragment, PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  IShape,
  setColorForIcon,
} from '../../components/discipline/disciplineUtils'
import { classNames } from '../../utility/utils'
import Icon from '../icon/Icon'
import Input from '../input/Input'

interface ISelectProps extends WithTranslation {
  items: any[]
  label?: string
  selectedItem?: number | string
  selectedCustomItem?: IShape
  noFirstOpen?: boolean
  isIconSelector?: boolean
  onChange?: (e: any) => void
  placeholder?: string
  disabled?: boolean
  onItemSelect?: (item: any) => void
  required?: boolean
  errorMessage?: string
}

export interface ISelectItem {
  name: string
  id: number | string
}

export interface ISelectCutomState {
  selectBoxOpen: boolean
  selectedItem: number | string
  searchValue: string
  hasError: boolean
}

class Select extends PureComponent<ISelectProps, ISelectCutomState> {
  private static styleClass = {
    root: classNames(
      'w-full',
      'flex',
      'flex-col',
      'px-2',
      'select-custom',
      'relative',
    ),
    label: classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'font-roboto',
      'my-1',
    ),
    errorMessage: classNames(
      'text-red-600',
      'my-2',
      'ml-2',
      'text-sm',
      'font-normal',
    ),
    select: {
      button: (error: boolean) =>
        classNames(
          'w-full',
          'flex',
          'bg-white',
          'flex-row',
          'justify-start',
          'items-center',
          'border',
          'border-1',
          'py-1',
          'text-sm',
          'font-normal',
          'px-1',
          'rounded',
          error ? 'border-red-300' : 'border-gray-300',
          'cursor-pointer',
        ),
      dropwDownContainer: classNames(
        'absolute',
        'inset-x-0',
        'bg-white',
        'shadow',
        'border',
        'border-2',
        'border-blue-one',
        'rounded',
        'max-h-63',
        'overflow-y-scroll',
      ),
      selectItem: classNames(
        'hover:text-blue-one',
        'font-normal',
        'text-sm',
        'px-1',
        'py-2',
        'cursor-pointer',
      ),
    },
    alternateSelector: {
      root: classNames('flex', 'flex-col', 'relative'),
      button: classNames(
        'flex',
        'flex-row',
        'justify-between',
        'items-center',
        'select',
        'px-2',
      ),
      dropDownContainer: classNames(
        'inline-block',
        'flex',
        'flex-row',
        'bg-white',
        'p-4',
        '-mt-2',
        'm-2',
        'm-2',
        'shadow',
        'inset-x-0',
        'absolute',
      ),
      item: classNames('p-2', 'hover:bg-grey-light'),
    },
  }

  public constructor(props: ISelectProps) {
    super(props)
    this.state = {
      selectBoxOpen: false,
      selectedItem: '',
      searchValue: '',
      hasError: !!props.errorMessage,
    }
  }

  public componentDidUpdate(_prevProps: ISelectProps) {
    this.setState({ hasError: !!this.props.errorMessage })
  }

  private toggleCustomSelectBox = () => {
    const { disabled } = this.props
    if (disabled) {
      return
    }
    this.setState((prevState) => ({
      selectBoxOpen: !prevState.selectBoxOpen,
    }))
  }

  private onItemClickRow = (i: any) => (_e: any) => {
    const { onItemSelect } = this.props
    if (onItemSelect) {
      onItemSelect(i)
      this.toggleCustomSelectBox()
    }
  }

  private renderIconSelector = () => {
    const { items } = this.props
    const { selectBoxOpen } = this.state
    return (
      <div className={Select.styleClass.alternateSelector.root}>
        {selectBoxOpen ? (
          <div
            className={Select.styleClass.alternateSelector.dropDownContainer}
            style={{
              top: '0',
              maxHeight: '200px',
              overflowY: 'auto',
              zIndex: 30,
            }}
          >
            {items.map((i: IShape, key: number) => {
              return (
                <div
                  key={key}
                  onClick={this.onItemClickRow(i)}
                  className={Select.styleClass.alternateSelector.item}
                >
                  {setColorForIcon(i)}
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }

  private getSelectedItemName = () => {
    const { selectedCustomItem, items, selectedItem, isIconSelector, t } =
      this.props
    if (isIconSelector) {
      if (selectedCustomItem) {
        return (
          <Fragment>
            {setColorForIcon(selectedCustomItem)}{' '}
            <Icon icon={Icon.IconType.ARROW_DOWN_GRAY} className={'ml-auto'} />
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            {t('select')}{' '}
            <Icon icon={Icon.IconType.ARROW_DOWN_GRAY} className={'ml-auto'} />
          </Fragment>
        )
      }
    }
    const name = items.filter((item: any) => item.id === selectedItem)
    return name.length > 0 ? (
      <Fragment>
        <div className={'w-5'}>
          <Icon icon={Icon.IconType.CHECK_BLUE} className={'mr-1'} />
        </div>
        <div className={'truncate'}>{name[0].name}</div>
        <Icon icon={Icon.IconType.ARROW_DOWN_GRAY} className={'ml-auto'} />
      </Fragment>
    ) : (
      <Fragment>
        {t('select')}{' '}
        <Icon icon={Icon.IconType.ARROW_DOWN_GRAY} className={'ml-auto'} />
      </Fragment>
    )
  }

  private onSelect = (id: any) => (_e: any) => {
    const { onChange } = this.props
    const target = {
      target: {
        value: id,
      },
    }
    if (onChange) {
      this.setState({
        selectedItem: id,
        selectBoxOpen: false,
      })
      onChange(target)
    }
  }

  private onSearch = (e: any) => {
    const value = e.target.value
    this.setState({
      searchValue: value,
    })
  }

  public render() {
    const { items, label, isIconSelector, required, errorMessage } = this.props

    const { selectBoxOpen, searchValue, hasError } = this.state

    return (
      <Fragment>
        {selectBoxOpen ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 9,
            }}
            onClick={() => this.setState({ selectBoxOpen: false })}
          />
        ) : null}
        <div className={Select.styleClass.root}>
          {label ? (
            <label className={Select.styleClass.label}>
              {label}
              {required ? ' *' : ''}
              {hasError && (
                <span className={Select.styleClass.errorMessage}>
                  {errorMessage}
                </span>
              )}
            </label>
          ) : null}
          <div className={'relative'}>
            <div
              className={Select.styleClass.select.button(hasError)}
              onClick={this.toggleCustomSelectBox}
            >
              {this.getSelectedItemName()}
            </div>
            {isIconSelector ? (
              this.renderIconSelector()
            ) : (
              <div
                className={Select.styleClass.select.dropwDownContainer}
                style={{ top: selectBoxOpen ? '0' : '-1000px', zIndex: 10 }}
              >
                <div
                  className={
                    'flex flex-row justify-start items-center py-1 px-1 select font-normale text-sm'
                  }
                  onClick={this.toggleCustomSelectBox}
                >
                  {this.getSelectedItemName()}
                </div>
                <Input
                  type="text"
                  required={true}
                  block={true}
                  placeholder="Søk"
                  value={searchValue}
                  onChange={this.onSearch}
                />
                {items
                  .map((p, key) => {
                    return (
                      <div
                        key={key + 1}
                        className={Select.styleClass.select.selectItem}
                        onClick={this.onSelect(p.id)}
                      >
                        {p.name}
                      </div>
                    )
                  })
                  .filter((item) => {
                    const search = item.props.children.toLowerCase()
                    return search.includes(searchValue.toLowerCase())
                  })}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation()(Select)
