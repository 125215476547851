import { FC, useState } from 'react'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import { IFileContainer } from '../../../types/IFileContainer'
import DocumentInspectorPanel from '../../Inspector/DocumentInspectorPanel'

interface DocumentUploadListProps {
  file_containers: IFileContainer[]
}

const DocumentUploadList: FC<DocumentUploadListProps> = ({
  file_containers,
}) => {
  const [showInspector, setShowInspector] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState<IFileContainer>()

  const columns: IListColumns[] = [
    {
      name: 'document_number',
      size: '300',
      id: 'recordId',
      dataField: 'record_id',
    },
    {
      name: 'document_name',
      size: '300',
      id: 'name',
      dataField: 'name',
    },
  ]

  const handlePreviewClick = (e: MouseEvent, data: IFileContainer) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedDocument(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedDocument(undefined)
  }

  return (
    <>
      <List
        columns={columns}
        disableColumnSelector={true}
        tableName={'UploadedFileContainers'}
        disableConfigStore={true}
        data={file_containers}
        itemsPerPage={0}
        onPreviewClick={handlePreviewClick}
      />
      {showInspector && selectedDocument && (
        <DocumentInspectorPanel
          documentId={selectedDocument.id}
          open={showInspector}
          onClose={onCloseInspector}
        />
      )}
    </>
  )
}

export default DocumentUploadList
