import { useTranslation } from 'react-i18next'
import { capFirstLetter } from 'src/utility/utils'

export type IUseMultipleKeysTranslation = (
  keys: string | string[],
  options?: {
    capitalize?: boolean
    upperCase?: boolean
    punctation?: boolean
    extraChars?: { [p: number]: string }
    params?: { [p: string]: string | number }
  },
) => string

const useMultipleKeysTranslation = () => {
  const { t: legacyT } = useTranslation()

  const parseWord = (
    key: string,
    capitalize: boolean,
    upperCase: boolean,
    punctation: boolean,
    params: { [param: string]: string | number },
  ) => {
    let word = legacyT(key, params)
    if (upperCase) word = word.toUpperCase()
    else if (capitalize) word = capFirstLetter(word)
    else if (punctation) word += '. '
    return word
  }

  const returnSingleWord = (
    key: string,
    capitalize: boolean,
    upperCase: boolean,
    punctation: boolean,
    params: { [param: string]: string | number },
  ) => {
    return parseWord(key, capitalize, upperCase, punctation, params)
  }

  const returnMultipleWords = (
    keys: string[],
    capitalize: boolean,
    upperCase: boolean,
    punctation: boolean,
    extraChars: { [afterWord: number]: string },
    params: { [param: string]: string | number },
  ) => {
    let res = ''
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (capitalize && i === 0) {
        res += parseWord(key, true, upperCase, false, params)
      } else {
        res += parseWord(key, false, upperCase, false, params)
      }
      if (extraChars[i]) res += extraChars[i]
      if (i !== keys.length - 1) res += ' '
      if (punctation && i === keys.length - 1 && punctation) res += '. '
    }
    return res
  }

  const t = (
    keys: string | string[],
    options?: {
      capitalize?: boolean
      upperCase?: boolean
      punctation?: boolean
      extraChars?: { [afterWord: number]: string }
      params?: { [param: string]: string | number }
    },
  ) => {
    const capitalize = options?.capitalize === false ? false : true
    const upperCase = !!options?.upperCase
    const punctation = !!options?.punctation
    const extraChars = options?.extraChars || {}
    const params = options?.params || {}

    if (!Array.isArray(keys))
      return returnSingleWord(keys, capitalize, upperCase, punctation, params)
    return returnMultipleWords(
      keys,
      capitalize,
      upperCase,
      punctation,
      extraChars,
      params,
    )
  }

  return { t }
}

export { useMultipleKeysTranslation }
