import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const testConfig: IEnvironmentConfig = {
  host: "test.bygg.io",
  exportBaseUrl: "https://export-test.bygg.io/",
  frontendBaseUrl: "https://test.bygg.io/",
  organizationBaseUrl: "https://org-test.bygg.io/",
  proxyBaseUrl: "https://proxy-test.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-test.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-test.bygg.io/",
  bimServiceBaseUrl: "https://bim-service-test.bygg.io/",
  kpiBaseUrl: "https://kpi-test.bygg.io/",
}
