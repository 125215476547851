import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { IUserKPIfiltersData } from 'src/page/user/UserKPIPage'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { IDiscipline, IUserData } from 'src/service/OrgTypes'
import {
  getProjectUsers,
  getUserBySelectedDisciplines,
} from 'src/service/UserService'
import Button from 'src/ui-elements/button/Button'
import Icon from 'src/ui-elements/icon/Icon'
import { renderDayContents } from 'src/utility/Utility'
import { capFirstLetter } from '../../utility/utils'
import { KPIFilterStyles } from '../KPI/KPIUtils'
import { FloatingFilterstyleClass } from '../canvas-filter/CanvasFloatingFilter'
import FilterIcon from '../canvas-header/FilterIcon'
import CloseClickOutside from '../click-outside/CloseClickOutside'
import useDidMountEffect from '../hooks/UseDidMountEffect'
import MultiSelector from '../multi-selector/MultiSelector'

interface IUserKPIFilterProps {
  projectId: number
  startDate: moment.Moment
  endDate: moment.Moment
  onFiltersChange: (f: IUserKPIfiltersData) => void
  onFilterClear?: () => void
}

const styleClass = FloatingFilterstyleClass()
const KPIStyles = KPIFilterStyles

const UserKPIFilter: React.FC<IUserKPIFilterProps> = ({
  projectId,
  onFiltersChange,
  onFilterClear,
  ...props
}: IUserKPIFilterProps) => {
  const { t } = useTranslation()
  const [rangeStartDate, setRangeStartDate] = useState<moment.Moment>(
    props.startDate,
  )
  const [rangeEndDate, setRangeEndDate] = useState<moment.Moment>(props.endDate)
  const [fInput, setFInput] = useState<'startDate' | 'endDate' | null>(null)
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [users, setUsers] = useState<IUserData[]>([])
  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([])
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [isDisciplineFirst, setIsDisciplineFirst] = useState<boolean>(true)
  const [isUserFirst, setIsUserFirst] = useState<boolean>(true)
  const [disciplineLoading, setDisciplineLoading] = useState<boolean>(false)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)

  useEffect(() => {
    setIsDisciplineFirst(true)
    setIsUserFirst(true)
    setSelectedUsers([])
    setSelectedDisciplines([])
  }, [projectId])

  useDidMountEffect(() => {
    setFiltersApplied(!noFilters())
  }, [disciplines, users, selectedDisciplines, selectedUsers])

  const noFilters = () => {
    return _.isEmpty(selectedDisciplines) && _.isEmpty(selectedUsers)
  }

  const onOpenDiscipline = () => {
    return new Promise<void>(async (resolve) => {
      if (isDisciplineFirst) {
        setDisciplineLoading(true)
        if (disciplines.length === 0) {
          const allDisciplines = await getProjectDisciplines(projectId)
          setDisciplines(allDisciplines)
        }
        setDisciplineLoading(false)
        setIsDisciplineFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenUser = () => {
    return new Promise<void>(async (resolve) => {
      if (isUserFirst) {
        setUserLoading(true)
        if (selectedDisciplines.length > 0) {
          const disciplineUsers = await getUserBySelectedDisciplines(
            projectId,
            { discipline: selectedDisciplines },
          )
          setUsers(disciplineUsers)
        } else {
          const allUsers = await getProjectUsers(projectId)
          setUsers(allUsers)
        }
        setUserLoading(false)
        setIsUserFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onChangeDate = (range: any) => {
    const { startDate, endDate } = range
    onFiltersChange({
      startDate: startDate ? startDate : rangeStartDate,
      endDate: endDate ? endDate : rangeEndDate,
    })
    setRangeStartDate(startDate)
    setRangeEndDate(endDate)
  }

  const onChangeDiscipline = async (selectedids: number[]) => {
    setSelectedDisciplines(selectedids)
    const newSelectedUsers: IUserData[] = await getUserBySelectedDisciplines(
      projectId,
      { discipline: selectedids },
    )
    setUsers(newSelectedUsers)
    const newSelectedUserIds: number[] = newSelectedUsers.map((user) => user.id)
    setSelectedUsers(newSelectedUserIds)
    onFiltersChange({
      startDate: rangeStartDate,
      endDate: rangeEndDate,
      selectedDisciplineIds: selectedids,
      selectedUserIds: newSelectedUserIds,
    })
    setDisciplineLoading(false)
  }

  const onChangeUser = async (selectedids: number[]) => {
    if (selectedids.length === 0) {
      setSelectedDisciplines([])
      setSelectedUsers([])
      onFiltersChange({
        startDate: rangeStartDate,
        endDate: rangeEndDate,
        selectedDisciplineIds: [],
        selectedUserIds: [],
      })
      return
    }
    setSelectedUsers(selectedids)
    onFiltersChange({
      startDate: rangeStartDate,
      endDate: rangeEndDate,
      selectedDisciplineIds: selectedDisciplines,
      selectedUserIds: selectedids,
    })
    const allUsers: IUserData[] =
      users.length > 0
        ? users
        : await getUserBySelectedDisciplines(projectId, {
            discipline: selectedDisciplines,
          })
    if (disciplines.length === 0) {
      const allDisciplines = await getProjectDisciplines(projectId)
      setDisciplines(allDisciplines)
    }
    const selectedDisciplineIds = new Set<number>()
    allUsers
      .filter((user: IUserData) => selectedids.indexOf(user.id) > -1)
      .map((user) => {
        if (user.disciplines) {
          user.disciplines.map((disp) => {
            selectedDisciplineIds.add(disp.id)
          })
        }
      })
    setSelectedDisciplines(Array.from(selectedDisciplineIds))
  }

  const onClear = () => {
    setSelectedDisciplines([])
    setSelectedUsers([])
    setFiltersApplied(false)
    if (onFilterClear) {
      onFilterClear()
    }
  }

  return (
    <CloseClickOutside onClose={() => setOpen(false)}>
      <div className={styleClass.root()}>
        <div className="flex justify-end mr-2">
          <FilterIcon
            open={open}
            setOpen={setOpen}
            filtersApplied={filtersApplied}
          />
        </div>
        {open && (
          <div className={styleClass.filter}>
            <div className={styleClass.rootFilter}>
              <div className={'pl-5 pt-4 pb-5'}>
                <div className={`${styleClass.filterSelector}`}>
                  <p className={KPIStyles.filtersRow.label}>
                    {t('date_range')}
                  </p>
                  <DateRangePicker
                    firstDayOfWeek={1}
                    startDate={rangeStartDate}
                    startDateId="date-start"
                    endDate={rangeEndDate}
                    endDateId="date-end"
                    onDatesChange={onChangeDate}
                    renderDayContents={renderDayContents}
                    focusedInput={fInput}
                    onFocusChange={setFInput}
                    block={true}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    hideKeyboardShortcutsPanel={true}
                    showDefaultInputIcon={true}
                    isOutsideRange={() => false}
                  />
                </div>
                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={disciplines}
                    onOpenList={onOpenDiscipline}
                    label={t('disciplines')}
                    hidelabel={false}
                    dataFields={['shortName', 'name']}
                    selectedItems={selectedDisciplines}
                    fontWeight={'bold'}
                    onSelect={onChangeDiscipline}
                    loading={disciplineLoading}
                    scroll={true}
                    noBorder={true}
                    bgColor={'white'}
                  />
                </div>
                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={users}
                    onOpenList={onOpenUser}
                    label={t('users')}
                    hidelabel={false}
                    dataFields={['firstName', 'lastName']}
                    selectedItems={selectedUsers}
                    fontWeight={'bold'}
                    onSelect={onChangeUser}
                    loading={userLoading}
                    scroll={true}
                    noBorder={true}
                    bgColor={'white'}
                  />
                </div>
              </div>
              <div className={'w-full flex justify-end px-4 py-2 bg-gray-100'}>
                <Button
                  onClick={onClear}
                  size={Button.ButtonSize.XSMALL}
                  noTextWrap={true}
                >
                  <Icon
                    icon={Icon.IconType.CLOSE_GRAY}
                    className={'mr-2 w-4 h-4 flex'}
                  />
                  {capFirstLetter(t('reset'))}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </CloseClickOutside>
  )
}

export default UserKPIFilter
