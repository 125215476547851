import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApproveOrDeclineButton from 'src/components/system/system-fmi/ApproveOrDeclineButton'
import ConfirmModal from 'src/reviewed-ui-elements/ConfirmModal'
import { createComment } from 'src/service/CommentService'
import { IComment, ISystem, ISystemFMI } from 'src/service/OrgTypes'
import {
  addSystemFMIStatusMapper,
  getProjectSystemFMIGroups,
  updateSystemFMIStatusMapper,
} from 'src/service/SystemService'
import FieldGroup from 'src/ui-elements/fields/FieldGroup'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import MentionTextArea from 'src/ui-elements/textarea/MentionTextArea'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'

interface IFmiSelectorWithCommentProps {
  systemData?: ISystem
  projectId: number
  onChangeSystemInput: (update: Partial<ISystem>) => void
  systemId: number
  systemFmis: ISystemFMI[]
  reloadSystemData: () => void
  disabled?: boolean
}

export type FmiObject = {
  id: number
  value: number
}

export enum FmiStatus {
  APPROVED = 'approved',
  DECLINED = 'declined',
  SUGGEST = 'suggest',
}

const FmiSelectorsWithComment = ({
  systemData,
  projectId,
  systemId,
  onChangeSystemInput,
  systemFmis,
  disabled,
}: IFmiSelectorWithCommentProps) => {
  const { t } = useTranslation()

  const [commentValue, setCommentValue] = useState<string>('')
  const [fmiMapperId, setFmiMapperId] = useState<number>()
  const [showFmiModal, setShowFmiModal] = useState<boolean>(false)
  const [selectedFmiSuggestion, setSelectedFmiSuggestion] =
    useState<FmiObject>()
  const [intermediateFmiSuggestion, setIntermediateFmiSuggestion] =
    useState<FmiObject>()
  const [fmiStatus, setFmiStatus] = useState<FmiStatus>()
  const [modalText, setModalText] = useState<string>()
  const [modalIconColor, setModalIconColor] = useState<string>()

  const onChangeFmiDropdown = (fmiId: number | undefined) => {
    const localFmi = systemFmis.find((fmi) => fmi.id === fmiId)?.value
    if (localFmi && fmiId) {
      setIntermediateFmiSuggestion({ id: fmiId, value: localFmi })
    }
    updateOrSuggestFmiStatusState(
      FmiStatus.SUGGEST,
      t('system_fmi_suggestion'),
      'fill-yellow',
    )
  }
  const submitFMIsuggestion = async () => {
    const result = await addSystemFMIStatusMapper(projectId, {
      system_id: systemId,
      suggested_fmi: intermediateFmiSuggestion?.value,
    })
    setFmiMapperId(result.id)
    onChangeSystemInput({
      system_fmi_status_mapper_id: result.id,
    })
    setSelectedFmiSuggestion(intermediateFmiSuggestion)
  }
  const approveOrDeclineFmiSuggestion = async (status: FmiStatus) => {
    if (systemData?.system_fmi_status_mapper) {
      await updateSystemFMIStatusMapper(
        status,
        systemData?.system_fmi_status_mapper,
        projectId,
      )
      setSelectedFmiSuggestion(undefined)
      onChangeSystemInput({
        system_fmi_status_mapper_id: fmiMapperId,
      })
    }
  }
  const updateOrSuggestFmiStatus = async (status: FmiStatus) => {
    if (status === FmiStatus.SUGGEST) {
      await submitFMIsuggestion()
    } else {
      await approveOrDeclineFmiSuggestion(status)
    }
    if (commentValue.trim().length !== 0) {
      await addComment()
    }
    setShowFmiModal(false)
  }
  const updateOrSuggestFmiStatusState = (
    status: FmiStatus,
    modalText: string,
    iconColor: string,
  ) => {
    setFmiStatus(status)
    setModalText(modalText)
    setModalIconColor(iconColor)
    setShowFmiModal(true)
  }

  const closeModal = () => {
    setIntermediateFmiSuggestion(selectedFmiSuggestion)
    setCommentValue('')
    setShowFmiModal(false)
  }

  const addComment = async () => {
    const commentData: Partial<IComment> = {
      content: commentValue,
      parent_id: systemId,
      parent_type: 'System',
    }
    await createComment(projectId, commentData)
    setCommentValue('')
  }

  useEffect(() => {
    if (systemFmis) {
      const initialSuggestion = systemData?.system_fmi_status_mapper
        ?.suggested_fmi
        ? {
            id: 0,
            value: systemData?.system_fmi_status_mapper?.suggested_fmi,
          }
        : undefined
      setSelectedFmiSuggestion(initialSuggestion)
      setFmiMapperId(systemData?.system_fmi_status_mapper_id)
    }
  }, [systemData, systemFmis])

  return (
    <FieldGroup>
      <SelectorInlineInputComponent
        getItems={() => getProjectSystemFMIGroups(projectId)}
        getItemLabel={(fmi_group) => fmi_group?.name ?? ''}
        label={'system_fmi_group'}
        onValueSubmitted={(system_fmi_group_id) => {
          onChangeSystemInput({ system_fmi_group_id })
        }}
        initialItem={systemData?.system_fmi_group}
        cancelButton={true}
        inspectorPanel={false}
        inputWidth={'w-52'}
        disabled={disabled}
      />
      <SelectorInlineInputComponent
        getItemLabel={(fmi) => fmi?.value?.toString() ?? ''}
        label={`${t('approved')} ${t('system_fmi')} `}
        initialItem={{
          id: 0,
          value: systemData?.system_fmi_status_mapper?.approved_fmi,
        }}
        inspectorPanel={false}
        disabled={true}
      />
      {!disabled && (
        <div className={'flex flex-col items-center'}>
          <SelectorInlineInputComponent
            getItemLabel={(fmi) => fmi?.value.toString() ?? ''}
            label={'system_fmi_suggestion'}
            onValueSubmitted={(system_fmi_id) => {
              onChangeFmiDropdown(system_fmi_id)
            }}
            selectedId={selectedFmiSuggestion?.id}
            controlled={true}
            initialItem={selectedFmiSuggestion}
            cancelButton={false}
            inspectorPanel={false}
            items={systemFmis}
            buildDropdownItemsOnItemsChange={true}
            inputWidth={'w-52'}
          />
          <div className={'flex flex-col justify-start w-full'}>
            <Tooltip
              show={!selectedFmiSuggestion}
              message={t('create_a_suggestion_by_choosing_an_fmi')}
              classNames={'items-start w-2/3'}
              offset={6}
            >
              <div className={'flex flex-row gap-2'}>
                <ApproveOrDeclineButton
                  suggestedFmi={selectedFmiSuggestion}
                  fmiStatus={FmiStatus.APPROVED}
                  updateOrSuggestFmiStatusState={updateOrSuggestFmiStatusState}
                />
                <ApproveOrDeclineButton
                  fmiStatus={FmiStatus.DECLINED}
                  updateOrSuggestFmiStatusState={updateOrSuggestFmiStatusState}
                  suggestedFmi={selectedFmiSuggestion}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      )}

      <ConfirmModal
        show={showFmiModal}
        closeModal={() => closeModal()}
        onSubmit={() =>
          updateOrSuggestFmiStatus(fmiStatus ?? FmiStatus.SUGGEST)
        }
        confirmMessage={`${modalText}: ${
          intermediateFmiSuggestion?.value ?? selectedFmiSuggestion?.value
        }`}
        title={t('confirm_submission')}
        iconColor={modalIconColor}
      >
        <div className={'mx-2 my-4'}>
          <div className={'my-2 mx-2 font-medium'}>
            <label htmlFor={'comment-field'}>
              {`${t('optional_comment')}:`}
            </label>
          </div>
          <MentionTextArea
            id={'comment-field'}
            placeholder={t('write_a_comment')}
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
            autoFocus
          />
        </div>
      </ConfirmModal>
    </FieldGroup>
  )
}
export default FmiSelectorsWithComment
