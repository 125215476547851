import * as React from 'react'

interface IInlineBaseProps {
  children: React.ReactNode
}

const InlineBaseComponent: React.FC<IInlineBaseProps> = ({ children }) => {
  return <div className={`flex w-full min-h-8 items-start`}>{children}</div>
}

export default InlineBaseComponent
