import { PureComponent } from 'react'
import { ErrorMessageType } from './ErrorMessageEnum'
// import { classNames } from '../../utils';

interface IErrorProps {
  type?: ErrorMessageType
  text: string
  description?: string
  clear?: () => void
}

interface IErrorState {
  hidden: boolean
}

export default class ErrorMessage extends PureComponent<
  IErrorProps,
  IErrorState
> {
  public static defaultProps: Partial<IErrorProps> = {
    type: ErrorMessageType.DEFAULT,
  }

  public static Type = ErrorMessageType

  public constructor(props: IErrorProps) {
    super(props)
    this.state = { hidden: false }
  }

  private closeAlert = (e: any) => {
    e.preventDefault()

    if (this.props.clear) {
      this.props.clear()
    }
    this.setState({ hidden: true })
  }

  public render() {
    setTimeout(() => {
      if (this.props.clear) {
        this.props.clear()
      } else {
        this.setState({
          hidden: true,
        })
      }
    }, 4000)
    const { text, description } = this.props

    return (
      <div
        style={this.state.hidden ? { display: 'none' } : { display: 'flex' }}
        className={
          'fixed inset-0 items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end'
        }
      >
        {/* max-w-sm */}
        <div
          style={{ maxWidth: '25rem' }}
          className="w-full bg-white shadow-lg pointer-events-auto"
        >
          <div className="ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <svg
                    className="h-6 w-6 text-red-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  {description && (
                    <p className="text-sm leading-5 font-medium text-gray-900">
                      {description}
                    </p>
                  )}
                  <p className="mt-1 text-sm leading-5 text-gray-500">
                    {text.substring(0, 80) + '...'}
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    onClick={(e) => this.closeAlert(e)}
                    className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  >
                    <svg
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
