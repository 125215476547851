import moment from 'moment'
import { MouseEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from '../../context/ProjectContextProvider/ProjectContext'
import {
  deleteDiscipline,
  getContractDisciplines,
  getProjectDisciplines,
  swapOrderDiscipline,
} from '../../service/DisciplineService'
import { IContract, IDiscipline, IUserData } from '../../service/OrgTypes'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import { ResourceType } from '../../ui-elements/list/ListContextProvider'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Modal from '../../ui-elements/modal/Modal'
import { concatinateValuesForTable } from '../../utility/Utility'
import { classNames } from '../../utility/utils'
import UserInspectorPanel from '../ContractDisciplineTree/UserInspectorPanel'
import DisciplineUsersList from '../user/DisciplineUsersList'
import DisciplineForm from './DisciplineForm'
import DisciplineInspectorPanel from './DisciplineInspectorPanel'
import { setColorForIcon } from './disciplineUtils'

interface IDisciplinesList {
  parentType: string
  parentId: number
  handleRowClick?: (i: number) => void
  reloadParent?: () => void
  onTreeSelect?: (id: number, type: string) => void
  selectedContracts?: number[]
  readonly: boolean
}

const DisciplinesList = ({
  parentType,
  parentId,
  handleRowClick: _handleRowClick,
  reloadParent,
  onTreeSelect,
  selectedContracts,
  readonly,
}: IDisciplinesList) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col', 'mt-1'),
    inputGroup: classNames('w-full', 'flex'),
  }

  const { t } = useTranslation()
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [selectedDiscipline, setSelectedDiscipline] =
    useState<IDiscipline | null>(null)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [openInspectorPanel, setOpenInspection] = useState(false)
  const [openUserInspection, setOpenUserInspection] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUserData>()

  const projectContext = useContext(ProjectContext)

  const loadData = useCallback(() => {
    if (parentType === 'Project') {
      getProjectDisciplines(parentId)
        .then((res) => {
          setDisciplines(
            selectedContracts
              ? res.filter((each) => selectedContracts.includes(each.id))
              : res,
          )
        })
        .catch((err) => console.error(err))
    } else if (parentType === 'Contract') {
      getContractDisciplines(parentId)
        .then((res) => {
          setDisciplines(res)
        })
        .catch((err) => console.error(err))
    }
  }, [parentId, parentType, selectedContracts])

  useEffect(() => {
    loadData()
    setDisciplines([])
  }, [loadData, parentId, selectedContracts])

  const reload = () => {
    loadData()
    reloadParent?.()
  }

  const onUserClick = (
    id: number,
    users: IUserData[],
    closeDisciplineInspectorPanel: () => void,
  ) => {
    const user = users.find((each) => each.id === id)
    setSelectedUser(user)
    closeDisciplineInspectorPanel()
    setOpenUserInspection(true)
  }

  const onDeletItemClick = (row: IDiscipline) => {
    deleteDiscipline(row).then(() => {
      reload()
    })
  }

  const closeDetailsModal = () => {
    setShowDetailsModal((prevState) => !prevState)
    reload()
  }

  const openCreateModal = () => {
    setShowCreateModal((prevState) => !prevState)
  }

  const closeCreateModal = () => {
    setShowCreateModal((prevState) => !prevState)
    reload()
  }

  const onRowClick = (row: IDiscipline) => {
    setSelectedDiscipline(row)
    onTreeSelect
      ? onTreeSelect(row.id, 'Discipline')
      : setShowDetailsModal((prevState) => !prevState)
  }

  const onCloseUserInpectionPanel = () => {
    setOpenUserInspection(false)
  }

  const swapDiscipline = (id: number, seq: number) => {
    const fromDisciplineId: number = disciplines[id].id

    swapOrderDiscipline(fromDisciplineId, seq).then(() => {
      reload()
    })
  }

  const columns: IListColumns[] = [
    {
      name: 'name',
      size: '300',
      id: 'name',
      dataField: 'name',
    },
    {
      name: 'code',
      size: '200',
      id: 'shortName',
      dataField: 'shortName',
    },
    {
      name: 'icon',
      size: '200',
      id: 'shape',
      dataField: 'shape||color',
      cell: ({ shape, color }: IDiscipline) =>
        setColorForIcon({ shape, color }),
    },
    {
      name: 'contract',
      size: '300',
      id: 'contract',
      dataField: 'contract',
      disabled: parentType === 'Contract' || !selectedContracts,
      cell: (contract: IContract) => (
        <span>
          {contract
            ? concatinateValuesForTable(
                contract.contractNumber,
                contract.contractName,
              )
            : ' '}
        </span>
      ),
    },
    {
      name: 'created_at',
      size: '200',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: string) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '200',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: string) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
    {
      name: 'order',
      size: '150',
      id: 'sequence',
      dataField: 'sequence',
      disabled: parentType === 'Contract',
    },
  ]

  const onCloseInpectionPanel = () => {
    setOpenInspection(false)
  }

  const handlePreviewClick = (e: MouseEvent, data: IDiscipline) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedDiscipline(data)
    setOpenInspection(true)
  }

  return (
    <div className={styleClass.root}>
      <List
        actionButton={
          !readonly ? (
            <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
              {t('new_discipline')}
            </Button>
          ) : undefined
        }
        columns={columns}
        data={disciplines}
        onRowClick={onRowClick}
        resourceType={ResourceType.DISCIPLINE}
        itemsPerPage={0}
        tableName={'DisciplinesList'}
        actionMenu={
          readonly
            ? undefined
            : [
                {
                  name: t('delete'),
                  action: onDeletItemClick,
                },
              ]
        }
        isRowDraggable={!readonly}
        swapOrder={swapDiscipline}
        onPreviewClick={handlePreviewClick}
      />

      <Modal
        show={showDetailsModal}
        closeModal={closeDetailsModal}
        title={
          selectedDiscipline
            ? selectedDiscipline.shortName + ' ' + selectedDiscipline.name
            : t('discipline_details')
        }
        size={'w-9/10'}
        maxWidth={1100}
      >
        {selectedDiscipline ? (
          <div>
            <p className={'text-sm text-gray-800 font-semibold pl-2 mt-2 mb-3'}>
              {t('users')}
            </p>
            <div className={'overflow-x-auto overflow-y-hidden pb-4'}>
              <DisciplineUsersList disciplineId={selectedDiscipline.id} />
            </div>
          </div>
        ) : null}
      </Modal>

      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_discipline')}
        size={'w-1/3'}
        maxWidth={600}
      >
        <div className={''}>
          <DisciplineForm
            editingMode={false}
            discipline={{
              id: 0,
              name: '',
              shortName: '',
              color: '',
              shape: '',
              sequence: disciplines.length + 1,
              contract_id: parentId,
            }}
            projectId={projectContext.state?.currentProject.id}
            closeModal={closeCreateModal}
            disciplines={disciplines}
          />
        </div>
      </Modal>

      {selectedUser && openUserInspection && (
        <UserInspectorPanel
          user={selectedUser}
          open={openUserInspection}
          onClose={onCloseUserInpectionPanel}
        />
      )}

      {openInspectorPanel && selectedDiscipline && (
        <DisciplineInspectorPanel
          discipline={selectedDiscipline}
          projectId={projectContext.state.currentProject.id}
          open={openInspectorPanel}
          onClose={onCloseInpectionPanel}
          onUserClick={onUserClick}
          readonly={readonly}
          onUpdate={reload}
        />
      )}
    </div>
  )
}

export default DisciplinesList
