import { useMemo } from 'react'
import { getDetailUrl } from 'src/utility/DetailPageUtils'

export const useDetailUrl = (
  detailPageData: { key: string; ids: any } | undefined,
) => {
  const detailUrl = useMemo(() => {
    if (!detailPageData || !detailPageData.ids) return ''
    const getUrlFunction = getDetailUrl[detailPageData.key]

    if (!getUrlFunction) return ''

    const isValid = getDetailUrl.validateIds(detailPageData.ids)
    if (isValid !== true) return ''

    return getUrlFunction(detailPageData.ids)
  }, [detailPageData])

  return detailUrl
}
