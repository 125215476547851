import { isString } from 'lodash'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { capFirstLetter, classNames } from 'src/utility/utils'

export interface IInspectorSectionTableRowsData {
  cells: string[] | number[] | React.ReactNode[]
  id?: number
  selected?: boolean
  showOverflow?: boolean
}

interface IInspectorSectionTable {
  headerColumns: Array<string | JSX.Element>
  rowsData: Array<IInspectorSectionTableRowsData>
  handleClick?: (id: number) => void
  loading?: boolean
  actionMenu?: {
    icon: string
    title: string
    onClick: (id: number) => void
    className?: string
    show?: (id: number) => boolean
  }
  showTooltipOnActionMenu?: boolean
}

const InspectorSectionTable = ({
  headerColumns,
  rowsData,
  handleClick,
  loading,
  actionMenu,
  showTooltipOnActionMenu,
}: IInspectorSectionTable) => {
  const ActionMenuContent = ({
    row,
  }: {
    row: IInspectorSectionTableRowsData
  }) => {
    const show = row.id
      ? ((actionMenu?.show && actionMenu.show(row.id)) ?? true)
      : false

    return (
      actionMenu && (
        <td
          title={actionMenu.title}
          className={twMerge(
            'text-left mx-2 my-1 first:border-l border-r border-b border-gray-300',
            'hover:bg-blue-100',
          )}
        >
          {show && (
            <Tooltip message={actionMenu.title} show={showTooltipOnActionMenu}>
              <div className="flex h-full justify-center items-center">
                <MaterialIcon
                  className={'text-base cursor-pointer ' + actionMenu.className}
                  icon={actionMenu.icon}
                  onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation()
                    e.stopPropagation()
                    row?.id && actionMenu.onClick(row.id)
                  }}
                />
              </div>
            </Tooltip>
          )}
        </td>
      )
    )
  }

  return (
    <table
      className={classNames(
        'w-full h-full table-auto border-separate border-spacing-0',
      )}
    >
      <thead>
        <tr
          className={classNames(
            'font-normal text-sm text-gray-500 bg-gray-100 py-2',
          )}
        >
          {headerColumns?.map((col, k) => (
            <th
              key={k}
              className={classNames(
                'text-left px-2 py-1 border-t border-b border-gray-300 first:border-l border-r first:rounded-tl last:rounded-tr',
              )}
            >
              {isString(col) ? capFirstLetter(col) : col}
            </th>
          ))}
          {actionMenu && (
            <th
              key="action_menu"
              className={classNames(
                'text-left px-2 py-1 border-t border-b border-gray-300 first:border-l border-r first:rounded-tl last:rounded-tr',
              )}
            />
          )}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <div className={'transform scale-25'}>
            <SpinnerMobile />
          </div>
        ) : (
          rowsData?.map((row, i) => (
            <tr
              key={`${row.id}-${i}`}
              onClick={() => row.id && handleClick?.(row.id)}
              className={classNames(
                'text-xs text-gray-500 py-1 odd:bg-white even:bg-slate-50 ',
                !!row.selected ? '!bg-blue-100' : '',
                handleClick ? 'cursor-pointer' : '',
              )}
            >
              {row.cells?.map((cell, j) => (
                <td
                  key={j}
                  className={classNames(
                    'text-left px-2 py-1  first:border-l border-r border-b border-gray-300',
                    !row.showOverflow && 'truncate',
                  )}
                >
                  {cell}
                </td>
              ))}
              {showTooltipOnActionMenu ? (
                <Tooltip
                  message={actionMenu?.title ?? ''}
                  classNames={'h-full'}
                >
                  <ActionMenuContent row={row} />
                </Tooltip>
              ) : (
                <ActionMenuContent row={row} />
              )}
            </tr>
          ))
        )}
      </tbody>
    </table>
  )
}

export default InspectorSectionTable
