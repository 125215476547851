import { capitalize } from 'lodash'
import { Fragment, PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import CheckboxWithLabel from 'src/ui-elements/checkbox/CheckboxWithLabel'
import { classNames } from '../../utility/utils'
import Icon from '../icon/Icon'
import Input from '../input/Input'
import ColumnFilterItem from './ColumnFilterItem'
import { IListContext } from './ListContextProvider'
import { withListContext } from './withListContext'

const styleClass = {
  root: classNames(
    'w-full',
    'h-full',
    'cursor-pointer',
    'flex',
    'justify-center',
    'w-10',
    'h-7',
  ),
  dropdown: classNames(
    'flex',
    'flex-col',
    'absolute',
    'bg-white',
    'border-gray-300',
    'border',
    'py-2',
    'overflow-y-auto',
    'selectorScroll',
    'min-w-48 ',
  ),
  backdrop: classNames('absolute', 'top-0', 'inset-0'),
  filterItem: classNames(
    'w-full',
    'hover:bg-grey-light',
    'p-1',
    'flex',
    'cursor-pointer',
    'text-sm',
    'col',
    'items-center',
  ),
}

interface IColumnSelectorProps extends IListContext, WithTranslation {}

interface IColumnSelectorState {
  dropdownOpen: boolean
  search: string
}

class ColumnSelector extends PureComponent<
  IColumnSelectorProps,
  IColumnSelectorState
> {
  constructor(props: IColumnSelectorProps) {
    super(props)
    this.state = {
      dropdownOpen: false,
      search: '',
    }
  }

  private toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  private filterList = (e: any) => {
    const value = e.target.value
    this.setState({ search: value })
  }

  public render() {
    const { columns, selectedColumns } = this.props.state
    const { t } = this.props
    const allSelected = !selectedColumns.some((column) => !column.active)
    const { addColumn, removeColumn } = this.props.actions
    return (
      <Fragment>
        {this.state.dropdownOpen && (
          <div className={styleClass.backdrop} onClick={this.toggleDropdown} />
        )}
        <div className={styleClass.root} onClick={this.toggleDropdown}>
          <Icon icon={Icon.IconType.TUNE_GRAY} className="w-5 h-4" />
        </div>
        <div className={'relative'} style={{ zIndex: 100 }}>
          {this.state.dropdownOpen && (
            <div
              className={styleClass.dropdown}
              style={{ left: '-25px', top: '18px', maxHeight: '300px' }}
            >
              <Input
                block={true}
                placeholder={`${t('search')}...`}
                onChange={this.filterList}
                value={this.state.search}
              />
              <div className={'pt-2 text-sm'}>
                <CheckboxWithLabel
                  key={'selectAll'}
                  name={
                    allSelected ? capitalize(t('remove_all')) : t('select_all')
                  }
                  labelRight={true}
                  value={allSelected}
                  onChange={() =>
                    columns
                      .filter((column) =>
                        column.name.includes(this.state.search.toLowerCase()),
                      )
                      .forEach((column) => {
                        allSelected ? removeColumn(column) : addColumn(column)
                      })
                  }
                />
                <div className="h-1 border-t border-dashed border-gray-300 mx-1" />
                {columns
                  .map((column, key) => {
                    const selctedColumn = selectedColumns.find(
                      (sc) => sc.id === column.id,
                    )
                    return (
                      <ColumnFilterItem
                        column={column}
                        key={key}
                        active={
                          selctedColumn !== undefined && selctedColumn.active
                        }
                      />
                    )
                  })
                  .filter((item) => {
                    const noCap = item.props.column.name
                      ? item.props.column.name.toLowerCase()
                      : ''
                    return noCap.includes(this.state.search.toLowerCase())
                  })}
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

export default withTranslation()(withListContext(ColumnSelector))
