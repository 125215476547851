import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { getIcon } from 'src/components/statistics/graph-components/TooltipContent'

const LegendContent: FC<{
  payload?: Payload[]
}> = ({ payload }) => {
  const { t } = useTranslation()
  if (!payload?.length) return null

  return (
    <div className="flex flex-wrap justify-center gap-x-6">
      {payload.map((entry, index) => (
        <div key={index} className="flex items-center">
          {getIcon(entry)}
          <span className="text-m-text-grey first-capitalize">
            {t(entry.value)}
          </span>
        </div>
      ))}
    </div>
  )
}

export default LegendContent
