import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { userDefinedColumns } from 'src/components/TableColumns/Columns'
import {
  DisciplineAndResponsibleColumn,
  ResponsibleAndDisciplineColumn,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import useProjectId from 'src/components/hooks/useProjectId'
import { IMetaValue } from 'src/document/types/IMetaData'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import { IDiscipline, ITestSystemGroup, IUserData } from 'src/service/OrgTypes'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import { editTestSystemGroup } from 'src/service/TestSystemGroupService'
import {
  dateColumn,
  editableDateColumn,
  editableTextColumn,
  multiFilterOptionsColumn,
  systemStatusColumn,
  textColumn,
  userColumn,
} from 'src/ui-elements/Table/Columns'
import useListHelper from 'src/ui-elements/list/UseListHelper'

interface ITesSystemGroupColumnsProps {
  writeAccess: boolean
  reload: () => void
}

interface IDeliveryResponsibility {
  responsible_id: number
  discipline_id: number
}

export const useTestSystemGroupColumns = ({
  writeAccess,
  reload,
}: ITesSystemGroupColumnsProps) => {
  const projectId = useProjectId()
  const { t } = useTranslation()

  const { data: testSystemGroup } =
    useSystemTypeGroupForDomain('TestSystemGroup')

  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])

  const { getTestSystemGroupStatusFilter, getUserFilter, getContractFilter } =
    useListHelper()

  const onFieldChange = (
    field: string,
    key: number,
    value: string | number | null,
  ) => {
    const testSystemGroup = { id: key, [field]: value }
    editTestSystemGroup(testSystemGroup).then(() => {
      reload()
    })
  }

  const updateResponsibleDiscipline = (
    id: number,
    { responsible_id, discipline_id }: IDeliveryResponsibility,
  ) => {
    const testSystemGroup = { id, responsible_id, discipline_id }
    editTestSystemGroup(testSystemGroup).then(() => {
      reload()
    })
  }

  const updateMetaField = (data: IMetaValue) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload()
      })
    }
  }

  const userDefinedAttributesColumns = testSystemGroup?.optional_fields
    ? userDefinedColumns(
        testSystemGroup.optional_fields,
        updateMetaField,
        'meta_data',
        true,
        false,
        !writeAccess,
      )
    : []

  const columns = [
    editableTextColumn(
      'record_id',
      { name: t('test_object_group_id') },
      (key, value) => onFieldChange('record_id', +key, value),
      !writeAccess,
    ),
    editableTextColumn(
      'title',
      { name: capitalize(t('title')) },
      (key, value) => onFieldChange('title', +key, value),
      !writeAccess,
    ),
    systemStatusColumn(
      'test_system_group_status',
      {
        name: t('status'),
        getFilter: getTestSystemGroupStatusFilter,
        field: 'test_system_group_status.name',
      },
      (key, status) =>
        onFieldChange('test_system_group_status_id', +key, status ?? ''),
      projectId,
      'TestSystemGroup',
      !writeAccess,
    ),
    editableTextColumn(
      'description',
      { name: capitalize(t('description')) },
      (key, value) => onFieldChange('description', +key, value),
      !writeAccess,
    ),
    textColumn('percent_completed', {
      name: capitalize(t('percent_completed')),
    }),
    editableDateColumn(
      'planned_start',
      { name: t('planned_start') },
      (date, item) =>
        onFieldChange('planned_start', item.id, date ? date : null),
      undefined,
      !writeAccess,
    ),
    editableDateColumn(
      'planned_end',
      { name: t('planned_end') },
      (date, item) => onFieldChange('planned_end', item.id, date ? date : null),
      undefined,
      !writeAccess,
    ),
    editableDateColumn(
      'actual_start',
      { name: t('actual_start') },
      (date, item) =>
        onFieldChange('actual_start', item.id, date ? date : null),
      undefined,
      !writeAccess,
    ),
    editableDateColumn(
      'actual_end',
      { name: t('actual_end') },
      (date, item) => onFieldChange('actual_end', item.id, date ? date : null),
      undefined,
      !writeAccess,
    ),
    ResponsibleAndDisciplineColumn(
      projectId,
      projectUsers,
      projectDiscipline,
      (id, responsible_id, discipline_id) =>
        updateResponsibleDiscipline(id, { responsible_id, discipline_id }),
    ),
    DisciplineAndResponsibleColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      (id, discipline_id, responsible_id) =>
        updateResponsibleDiscipline(id, { responsible_id, discipline_id }),
    ),
    multiFilterOptionsColumn(
      'contract',
      {
        name: capitalize(t('contract')),
        getFilter: getContractFilter,
        field: 'contract.contractNumber||contract.contractName',
      },
      ['contractNumber', 'contractName'],
    ),
    dateColumn('created_at', {
      name: t('created_at'),
    }),
    dateColumn('updated_at', {
      name: t('updated_at'),
    }),
    userColumn('created_by', {
      name: t('created_by'),
      getFilter: getUserFilter,
      field: 'created_by.firstName||created_by.lastName',
    }),
    userColumn('updated_by', {
      name: t('updated_by'),
      getFilter: getUserFilter,
      field: 'updated_by.firstName||updated_by.lastName',
    }),
  ] as ColumnDef<ITestSystemGroup>[]

  const defaultOrdering = [
    'select',
    'record_id',
    'title',
    'test_work_group_status',
    'description',
    'percent_completed',
    'planned_start',
    'planned_end',
    'actual_start',
    'actual_end',
    'responsible',
    'discipline',
    'contract',
    'created_by',
    'updated_by',
    'created_at',
    'updated_at',
  ]

  return { columns, defaultOrdering, userDefinedAttributesColumns }
}
