import { RowSelectionState } from '@tanstack/react-table'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  draftRecordIdColumn,
  userDefinedColumns,
} from 'src/components/TableColumns/Columns'
import ConstructionTaskTypeTable from 'src/components/construction/task_types/ConstructionTaskTypeTable'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { constructionTaskListExportTemplate } from 'src/export-templates/ConstructionTaskExport'
import { contructionTaskImportTemplate } from 'src/export-templates/ConstructionTaskImportTemplate'
import useDraftMode from 'src/hooks/useDraftMode'
import { getProjectSystemTypeGroups } from 'src/service/SystemTypeGroupService'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import useAlert from 'src/ui-elements/toast/useAlert'
import {
  IWithProjectContext,
  withProjectContext,
} from '../../../context/withProjectContext'
import { IMetaValue } from '../../../document/types/IMetaData'
import {
  bulkDeleteConstructionTasks,
  createConstructionTask,
  deleteConstructionTask,
  editConstructionTask,
  getConstructionTaskFilter,
  getConstructionWagonTaskFilter,
} from '../../../service/ConstructionTaskService'
import {
  IConstructionTask,
  IConstructionWagon,
  IDiscipline,
  IImportItemList,
  ISystemTypeGroup,
} from '../../../service/OrgTypes'
import { updateUserDefinedFieldsValue } from '../../../service/SystemTypeFieldService'
import { actionStatus, ITypes, taskUnits } from '../../../service/SystemValues'
import Button from '../../../ui-elements/button/Button'
import { ButtonType } from '../../../ui-elements/button/ButtonEnums'
import List from '../../../ui-elements/list/List'
import { IListFilter } from '../../../ui-elements/list/ListContextProvider'
import {
  filterCase,
  filterType,
  IListColumns,
  ISorting,
} from '../../../ui-elements/list/ListTypes'
import Loader from '../../../ui-elements/loader/Loader'
import {
  concatinateValuesForTable,
  displineComparator,
  titleComparator,
  toFixed,
} from '../../../utility/Utility'
import { constructFilterJson, IActiveFilter } from '../../../utility/utils'
import TableDatePicker from '../../datepicker/TableDatePicker'
import { setColorForIcon } from '../../discipline/disciplineUtils'
import TableStatusLabel from '../../status-dropdown/TableStatusLabel'
import ConstructionTaskForm from './ConstructionTaskForm'

interface IConstructionTaskList extends IWithProjectContext {
  constructionWagonId: number
  isProjectLevelList: boolean
  reloadParent?: () => void
  isExpandedElement?: boolean
}

const ConstructionTaskList = ({
  constructionWagonId,
  projectContext,
  isProjectLevelList,
  isExpandedElement,
  reloadParent,
}: IConstructionTaskList) => {
  /**
   * Initial load of component. fetches the constructionTasks, refetches if porjectConstext changes.
   */

  const projectId = projectContext.state.currentProject.id
  const isConstructionManager = projectContext.state.isConstructionManager
  const { t } = useTranslation()
  const [constructionTasks, setConstructionTasks] = useState<
    IConstructionTask[]
  >([])
  const [loading, setLoading] = useState<boolean>(false)

  const [createModal, setCreateModal] = useState<boolean>(false)
  const [copyModal, setCopyModal] = useState<boolean>(false)
  const [selectedTasksToCopy, setSelectedTasksToCopy] =
    useState<RowSelectionState>()
  const [updateConstructionTask, setUpdateConstructionTask] =
    useState<boolean>(false)
  const [selectedConstructionTask, setSelectedConstructionTask] =
    useState<IConstructionTask>({} as IConstructionTask)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const { addAlert } = useAlert()
  const [systemTypeGroup, setSystemTypeGroup] = useState<ISystemTypeGroup>()
  const mounted = useRef(false)
  const [selectedTasks, setSelectedTasks] = useState<number[]>([])

  const { draftMode, turnDraftModeOn } = useDraftMode()

  const pageSize = 30

  useEffect(() => {
    setLoading(true)
    fetchConstructionTasks()
  }, [projectContext, draftMode])

  const {
    getDisciplineFilter,
    getContractFilter,
    getWagonFilter,
    getTrainFilter,
    getControlAreaFilter,
  } = useListHelper()
  const constructionTasksRef = useRef(constructionTasks)

  const fetchSystemGroupType = useCallback(() => {
    setLoading(true)
    getProjectSystemTypeGroups(projectId).then((res: ISystemTypeGroup[]) => {
      const value = res.find((item) => item.domain === 'ConstructionTask')
      setSystemTypeGroup(value)
    })
  }, [projectId])

  useEffect(() => {
    constructionTasksRef.current = constructionTasks
  })

  useEffect(() => {
    fetchSystemGroupType()
  }, [fetchSystemGroupType])

  useEffect(() => {
    setReloadTable((n) => !n)
  }, [constructionWagonId])

  const showAlart = (text: string) => {
    addAlert({
      type: 'error',
      title: t('an_error_occurred'),
      description: text,
      autoClose: true,
    })
  }

  const openCreateModal = () => {
    turnDraftModeOn()
    setCreateModal(true)
    setUpdateConstructionTask(false)
  }

  const onDelete = (item: IConstructionTask) => {
    turnDraftModeOn()
    setSelectedConstructionTask(item)
    setShowDeleteModal(true)
  }

  const onBulkDelete = async () => {
    turnDraftModeOn()
    setLoading(true)
    await bulkDeleteConstructionTasks(selectedTasks)
    fetchConstructionTasks()
    setSelectedTasks([])
    setLoading(false)
  }

  const onDeleteItemClick = () => {
    if (selectedConstructionTask) {
      setLoading(true)
      deleteConstructionTask(selectedConstructionTask).then(() => {
        fetchConstructionTasks()
        setShowDeleteModal(false)
      })
    }
  }

  const onEdit = (item: IConstructionTask) => {
    turnDraftModeOn()
    setSelectedConstructionTask(item)
    setCreateModal(true)
    setUpdateConstructionTask(true)
  }

  const onCloseModal = () => {
    fetchConstructionTasks()
    setCreateModal(false)
  }

  const fetchConstructionTasks = () => {
    setReloadTable((n) => !n)
    reloadParent?.()
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    constructionTaskFilter(activeFilters, page)
  }

  const constructionTaskFilter = (
    activeFilters: IActiveFilter,
    page: number,
  ) => {
    if (isProjectLevelList) {
      getConstructionTaskFilter(
        projectId,
        activeFilters,
        page,
        pageSize,
        draftMode,
      ).then((res) => {
        setConstructionTasks(res.tasks)
        setTotalPages(res.pages)
        setLoading(false)
      })
    } else {
      getConstructionWagonTaskFilter(
        constructionWagonId,
        activeFilters,
        page,
        pageSize,
        draftMode,
      ).then((res) => {
        setConstructionTasks(res.tasks)
        setTotalPages(res.pages)
        setLoading(false)
      })
    }
  }

  const onStatusUpdate = (statusChange: ITypes, key: number) => {
    const dataField = constructionTasksRef.current
      .filter((d) => d.id === key)
      .pop()
    if (dataField) {
      dataField.status = statusChange.id

      editConstructionTask(dataField).then(() => {
        setLoading(true)
        fetchConstructionTasks()
      })
    }
  }

  const onStartDateChange = (date: moment.Moment, key: number) => {
    const dataField = constructionTasksRef.current
      .filter((d) => d.id === key)
      .pop()
    if (
      !moment(date).isBetween(
        moment(dataField?.construction_locomotive?.startTime),
        moment(dataField?.construction_locomotive?.startTime).add(
          dataField?.construction_locomotive?.extended_duration,
          'days',
        ),
        'day',
        '[]',
      )
    ) {
      showAlart(
        t('date_must_be_between_dates', {
          startDate: moment(
            dataField?.construction_locomotive?.startTime,
          ).format('L'),
          endDate: moment(dataField?.construction_locomotive?.startTime)
            .add(dataField?.construction_locomotive?.extended_duration, 'days')
            .format('L'),
        }),
      )
    } else if (dataField) {
      dataField.deadline = date

      editConstructionTask(dataField).then(() => {
        setLoading(true)
        fetchConstructionTasks()
      })
    }
  }

  const importControlAreaTemplate: IImportItemList = {
    title: t('upload_construction_task'),
    templateJson: contructionTaskImportTemplate,
    type: 'construction_tasks',
    reload: fetchConstructionTasks,
  }

  const updateMetaFiled = useCallback((data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        setReloadTable((n) => !n)
      })
    }
  }, [])

  const userDefinedAttributesColumns = useMemo(() => {
    if (!mounted.current) {
      mounted.current = true
      return []
    }
    if (systemTypeGroup) {
      const _col = userDefinedColumns(
        systemTypeGroup.optional_fields ?? [],
        updateMetaFiled,
        'meta_data',
      )
      setTimeout(() => setLoading(false), 500)
      return _col
    } else {
      setTimeout(() => setLoading(false), 500)
      return []
    }
  }, [systemTypeGroup, updateMetaFiled])

  const columns: IListColumns[] = [
    draftRecordIdColumn(draftMode, t),
    {
      name: 'title',
      size: '300',
      id: 'title',
      dataField: 'title',
      sortingField: 'title',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'deadline',
      size: '150',
      id: 'deadline',
      sortingField: 'deadline',
      dataField: 'deadline||update_access',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (data: any, row: number) => (
        <TableDatePicker
          date={data.deadline}
          rowId={row}
          onDateSubmit={onStartDateChange}
          inModal={true}
        />
      ),
    },
    {
      name: 'status',
      size: '200',
      id: 'status',
      sortingField: 'status',
      dataField: 'status||deadline||update_access',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_STATUS,
      filterDataField: 'status',
      filterDataValue: 'status',
      filter: [
        {
          name: 'open',
          value: 'open',
          active: false,
        },
        {
          name: 'in_progress',
          value: 'in_progress',
          active: false,
        },
        {
          name: 'done',
          value: 'done',
          active: false,
        },
      ],
      cell: (cell: any, key: number) => (
        <TableStatusLabel
          statusTypes={actionStatus(t)}
          cell={cell}
          rowId={key}
          onStatusSelect={onStatusUpdate}
        />
      ),
    },
    {
      name: 'discipline',
      size: '200',
      id: 'discipline',
      dataField: 'discipline',
      sortingField: 'discipline',
      filterType: filterType.DEFAULT,
      filterDataField: 'discipline.shortName',
      filterDataValue: 'discipline.id',
      getFilter: getDisciplineFilter,
      filter: [],
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? displineComparator(a, b, direction) : 0) : 0,
      cell: (discipline: IDiscipline) =>
        discipline ? (
          <span className={'flex flex-row'}>
            <span className={'mr-2'}>
              {setColorForIcon({
                color: discipline.color,
                shape: discipline.shape,
              })}
            </span>
            <span>
              {discipline
                ? concatinateValuesForTable(
                    discipline.shortName,
                    discipline.name,
                  )
                : ''}
            </span>
          </span>
        ) : (
          <span />
        ),
    },
    {
      name: 'contract',
      size: '240',
      id: 'contract',
      dataField: 'contract',
      sortingField: 'contract',
      filterType: filterType.DEFAULT,
      filterDataField: 'contract.contractName',
      filterDataValue: 'contract.id',
      filter: [],
      getFilter: getContractFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (contract: any) =>
        contract ? (
          <span>
            {concatinateValuesForTable(
              contract.contractNumber,
              contract.contractName,
            )}
          </span>
        ) : (
          <span />
        ),
    },
    {
      name: 'unit',
      size: '200',
      id: 'unit',
      dataField: 'unit',
      sortingField: 'unit',
      filterType: filterType.DEFAULT,
      filter: [
        {
          value: 'm_2',
          name: 'm2',
          active: false,
        },
        {
          value: 'm_3',
          name: 'm3',
          active: false,
        },
        {
          value: 'M',
          name: 'm',
          active: false,
        },
        {
          value: 'count',
          name: 'Stk',
          active: false,
        },
      ],
      cell: (unit: any) => (
        <span>
          {taskUnits(t)
            .filter((pt: any) => pt.id === unit)
            .map((pt: any) => pt.name)
            .pop()}
        </span>
      ),
    },
    {
      name: 'total_units',
      size: '200',
      id: 'totalUnits',
      dataField: 'total_units',
      sortingField: 'total_units',
      filter: [],
      filterType: filterType.NUMBER,
      cell: (unit: number) => <span>{toFixed(unit)}</span>,
    },
    {
      name: 'hours_per_unit',
      size: '200',
      id: 'hoursPerUnit',
      dataField: 'hours_per_unit',
      sortingField: 'hours_per_unit',
      filter: [],
      filterType: filterType.NUMBER,
      cell: (unit: number) => <span>{toFixed(unit)}</span>,
    },
    {
      name: 'productivity_factor',
      size: '200',
      id: 'productivity',
      dataField: 'productivity',
      sortingField: 'productivity',
      filter: [],
      filterType: filterType.NUMBER,
      cell: (unit: number) => <span>{toFixed(unit)}</span>,
    },

    {
      name: 'total_work_hours',
      size: '200',
      id: 'effectiveWorkHours',
      dataField: 'productivity||total_work_hours',
      sortingField: 'total_work_hours',
      cell: (data: any) => (
        <span>{toFixed(data.total_work_hours / data.productivity)}</span>
      ),
    },
    {
      name: 'duration_in_hours',
      size: '200',
      id: 'planeedExecutionHours',
      dataField: 'planned_execution_hours',
      sortingField: 'planned_execution_hours',
      filter: [],
      filterType: filterType.NUMBER,
      cell: (unit: number) => <span>{toFixed(unit)}</span>,
    },
    {
      name: 'number_of_workers',
      size: '220',
      id: 'numberOfWorks',
      dataField: 'number_of_workers',
      sortingField: 'number_of_workers',
      filter: [],
      filterType: filterType.NUMBER,
      cell: (unit: number) => <span>{toFixed(unit)}</span>,
    },
    ...userDefinedAttributesColumns,
    {
      name: 'created_at',
      size: '150',
      id: 'createdAt',
      dataField: 'created_at',
      sortingField: 'created_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (date) => <span>{moment(date).format('L')}</span>,
    },
    {
      name: 'updated_at',
      size: '150',
      id: 'updatedAt',
      dataField: 'updated_at',
      sortingField: 'updated_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const trainWagonColumns = [
    {
      name: 'train',
      size: '200',
      id: 'train',
      dataField: 'construction_locomotive',
      sortingField: 'construction_train',
      filterFiled: 'construction_train',
      filterType: filterType.DEFAULT,
      filter: [],
      getFilter: getTrainFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (locomotive: IConstructionWagon) =>
        locomotive ? (
          <span>{locomotive.construction_train?.title}</span>
        ) : (
          <span />
        ),
    },
    {
      name: 'wagon',
      size: '200',
      id: 'wagon',
      dataField: 'construction_locomotive',
      sortingField: 'construction_locomotive',
      filterType: filterType.DEFAULT,
      filter: [],
      getFilter: getWagonFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (locomotive: IConstructionWagon) =>
        locomotive ? <span>{locomotive.title}</span> : <span />,
    },
    {
      name: 'control_area',
      size: '200',
      id: 'control_area',
      dataField: 'construction_locomotive',
      sortingField: 'control_area',
      filterFiled: 'control_area',
      filterType: filterType.DEFAULT,
      filter: [],
      getFilter: getControlAreaFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (locomotive: IConstructionWagon) =>
        locomotive ? <span>{locomotive.control_area?.title}</span> : <span />,
    },
  ]

  const getColumns = () => {
    if (!constructionWagonId) {
      columns.splice(1, 0, ...trainWagonColumns)
    }
    return columns
  }

  const copyConstructionTasksFromTemplate = async () => {
    if (!selectedTasksToCopy || !constructionWagonId) return
    setLoading(true)
    await createConstructionTask(
      {
        construction_task_type_ids: Object.keys(selectedTasksToCopy).map(
          (key) => Number(key),
        ),
      },
      constructionWagonId,
    )
    setCopyModal(false)
    setReloadTable((n) => !n)
    setSelectedTasksToCopy(undefined)
    setLoading(false)
  }

  return (
    <>
      <List
        actionButton={
          !isProjectLevelList ? (
            <>
              <Button type={ButtonType.PRIMARY} onClick={openCreateModal}>
                {t('new_activity')}
              </Button>
              <Button
                type={ButtonType.PRIMARY}
                onClick={() => setCopyModal(true)}
              >
                {t('copy_activity')}
              </Button>
            </>
          ) : undefined
        }
        columns={getColumns()}
        tableName={
          isProjectLevelList
            ? 'ConstructionTaskTable'
            : 'WagonConstructionTaskTable'
        }
        data={constructionTasks}
        onRowClick={onEdit}
        itemsPerPage={pageSize}
        exportTemplate={
          isProjectLevelList
            ? constructionTaskListExportTemplate(
                `projects/${projectContext.state.currentProject.id}/`,
              )
            : constructionTaskListExportTemplate(
                `/construction_locomotives/${constructionWagonId}`,
              )
        }
        pagination={true}
        getFilteredData={getFilteredData}
        reload={reloadTable}
        importItem={
          isConstructionManager ? importControlAreaTemplate : undefined
        }
        sortBackend={true}
        filterResetOption={true}
        totalPages={totalPages}
        isExpandedElement={isExpandedElement}
        actionMenu={[
          {
            name: t('delete'),
            action: onDelete,
          },
        ]}
        isRowSelectable={true}
        bulkDelete={true}
        onSelectRow={(ids: number[]) => setSelectedTasks(ids)}
        onBulkDelete={onBulkDelete}
        selectedRows={selectedTasks}
      />

      {!isEmpty(selectedConstructionTask) && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={() => setShowDeleteModal(false)}
          onDelete={onDeleteItemClick}
          itemIdnType={t('activity_title', {
            title: selectedConstructionTask.title,
          })}
          itemName={`${selectedConstructionTask.record_id} - ${selectedConstructionTask.title}`}
        />
      )}

      {createModal ? (
        <ConstructionTaskForm
          open={createModal}
          isEditing={updateConstructionTask}
          constructionTask={selectedConstructionTask}
          constructionWagonIdProps={
            isProjectLevelList
              ? selectedConstructionTask.construction_locomotive_id
              : constructionWagonId
          }
          closeModal={onCloseModal}
          updateList={fetchConstructionTasks}
        />
      ) : null}
      {copyModal && (
        <Modal
          show={copyModal}
          closeModal={() => setCopyModal(false)}
          size="w-5/6"
          maxWidth={null}
          title={t('add_activities_to_wagon')}
        >
          <div className="pl-2">
            <ConstructionTaskTypeTable
              selectionState={selectedTasksToCopy}
              onSelect={(ids) => setSelectedTasksToCopy(ids)}
            />
          </div>
          <ModalFooter>
            {loading ? (
              <Spinner />
            ) : (
              <Button
                type={Button.ButtonType.PRIMARY}
                dontSubmit={true}
                onClick={copyConstructionTasksFromTemplate}
              >
                {t('add')}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      )}
      {loading ? <Loader /> : null}
    </>
  )
}

export default withProjectContext(ConstructionTaskList)
