import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ITestExecution } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'

interface SendTestProps {
  id: number
  isTestOwner: boolean
  step: string
  editExecution: (id: number, data: Partial<ITestExecution>) => void
  className?: string
}

const SendForSigning: FC<SendTestProps> = ({
  isTestOwner,
  step,
  editExecution,
  id,
  className: _className = '',
}) => {
  const { t } = useTranslation()

  const isDisabled = useMemo(() => {
    return !isTestOwner || step !== 'in_progress'
  }, [isTestOwner, step])

  return (
    <Button
      disabled={isDisabled}
      type={ButtonType.BOUNDARY_WHITE}
      onClick={() => {
        editExecution(id, { step: 'singing' })
      }}
    >
      <Icon
        icon={isDisabled ? Icons.SEND_GRAY : Icons.SEND}
        className={`mr-2`}
      />
      {t('send_approval_to_all')}
    </Button>
  )
}

export default SendForSigning
