import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createProjectPPU, updateProjectPPU } from 'src/service/ProjectService'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import TimePicker from 'src/ui-elements/time-pickers/TimePicker'
import Checkbox from '../../components/switchHoc/CheckBox'
import { IPPUGoal } from '../../service/OrgTypes'
import {
  capFirstLetter,
  classNames,
  cloneObj,
  convertPPUType,
} from '../../utility/utils'
import Selector from '../selectors/Selector'

interface IPPUFormProps {
  editingMode: boolean
  projectId: number
  ppu?: IPPUGoal
  closeModal: () => void
  updatePPU: () => void
}

const PPUForm = ({
  projectId,
  editingMode,
  closeModal,
  ppu,
  updatePPU,
}: IPPUFormProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
    wrapper: classNames('w-full', 'px-2'),
    label: classNames(
      'flex',
      'items-center',
      'text-sm',
      'leading-5',
      'font-normal',
      'text-gray-500',
    ),
    selectorLabel: classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'font-roboto',
      'my-2',
    ),
  }

  const { t } = useTranslation()
  const [goal, setGoal] = useState<number>(90)
  const [active, setActive] = useState<boolean>(true)
  const [goalError, setGoalError] = useState<string>('')
  const [planFreezeWeeks, setPlanFreezeWeeks] = useState<number>(2)
  const [planFreezeWeeksError, setPlanFreezeWeeksError] = useState<string>('')
  const [label, setLabel] = useState<string>('')
  const [selectError, setSelecteError] = useState<string>('')
  const [selectedType, setSelectedType] = useState<string>('')
  const [calculationTime, setCalculationTime] =
    useState<moment.Moment>(moment())
  const [selectedCalculationDay, setSelectedCalculationDay] =
    useState<number>(0)

  const goalLabel = [
    {
      id: 'key_point',
      goal: t('key_point_ppu_goals'),
    },
    {
      id: 'delivery',
      goal: t('delivery_ppu_goals'),
    },
    {
      id: 'task',
      goal: t('task_ppu_goals'),
    },
  ]

  useEffect(() => {
    if (ppu) {
      setLabel(convertPPUType(ppu.data_type))
      setGoal(ppu.goal)
      setActive(ppu.active)
      setPlanFreezeWeeks(ppu.plan_freeze_period)
      setCalculationTime(
        moment(ppu.calculation_time).utcOffset(ppu.calculation_time),
      )
      setSelectedCalculationDay(ppu.calculation_day ? ppu.calculation_day : 0)
    }
  }, [])

  const onSubmit = async (e: any) => {
    e.preventDefault()
    let error = false
    if (goal === 0) {
      setGoalError(
        t('can_not_be_zero', {
          val: `${editingMode ? `${convertPPUType(label)}` : t('ppu_goals')}`,
        }),
      )
      error = true
    }

    if (goal > 100) {
      setGoalError(
        t('can_not_be_greater_than_100_percent', {
          val: `${editingMode ? `${convertPPUType(label)}` : t('ppu_goals')}`,
        }),
      )
      error = true
    }

    if (planFreezeWeeks < 1) {
      setPlanFreezeWeeksError(
        t('can_not_be_less_than_a_week', {
          val: `${
            editingMode ? `${convertPPUType(label)}` : t('ppu_freeze_period')
          }`,
        }),
      )
      error = true
    }

    if (selectedType === '' && !editingMode) {
      setSelecteError(t('select_ppu_goal_type'))
      error = true
    }

    if (!error) {
      if (editingMode && ppu) {
        const ppuCopy: IPPUGoal = cloneObj(ppu)
        ppuCopy.goal = goal
        ppuCopy.plan_freeze_period = planFreezeWeeks
        ppuCopy.active = active
        ppuCopy.calculation_time = calculationTime.format('HH:mm:ss')
        ppuCopy.calculation_day = selectedCalculationDay

        await updateProjectPPU(ppuCopy)
      } else {
        await createProjectPPU(projectId, {
          data_type: selectedType,
          goal: goal,
          plan_freeze_period: planFreezeWeeks,
          calculation_time: calculationTime.format('HH:mm:ss'),
          calculation_day: selectedCalculationDay,
        } as IPPUGoal)
      }
      updatePPU()
    }
  }

  const onGoalChange = (e: any) => {
    setGoal(Number(e.target.value))
  }

  const onSetPlanFreezeWeeksChange = (e: any) => {
    setPlanFreezeWeeks(Number(e.target.value))
  }

  const onTypeChange = (value: string) => {
    setSelectedType(value)
  }

  const onActiveChange = () => {
    setActive((n) => !n)
  }

  const onCalculationTimeChange = (date: moment.Moment) => {
    setCalculationTime(date ? moment(date) : calculationTime.add(1, 'hours'))
  }

  const onCalculationDayChange = (value: number) => {
    setSelectedCalculationDay(value)
  }

  const CalculationDays = [
    {
      id: 0,
      value: t('sunday'),
    },
    {
      id: 1,
      value: t('monday'),
    },
    {
      id: 2,
      value: t('tuesday'),
    },
    {
      id: 3,
      value: t('wednesday'),
    },
    {
      id: 4,
      value: t('thursday'),
    },
    {
      id: 5,
      value: t('friday'),
    },
    {
      id: 6,
      value: t('saturday'),
    },
  ]

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        {!editingMode && (
          <div className={styleClass.wrapper}>
            <Selector
              items={goalLabel}
              selectedItemId={selectedType}
              onSelect={onTypeChange}
              label={t('ppu_type')}
              dataFields={['goal']}
              required={true}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={selectError}
            />
          </div>
        )}
      </div>

      <div className={styleClass.inputGroup}>
        <Input
          label={t('goal_percent')}
          block={true}
          value={goal}
          type={'number'}
          required={true}
          errorMessage={goalError}
          onChange={onGoalChange}
          autoFocus={true}
        />
      </div>

      <div className={styleClass.inputGroup}>
        <Input
          label={t('ppu_freeze_period_weeks')}
          block={true}
          value={planFreezeWeeks}
          type={'number'}
          defaultValue={2}
          min={1}
          required={true}
          errorMessage={planFreezeWeeksError}
          onChange={onSetPlanFreezeWeeksChange}
          autoFocus={true}
        />
      </div>

      <div className={`${styleClass.inputGroup}`}>
        <div className="w-1/2">
          <Selector
            items={CalculationDays}
            selectedItemId={selectedCalculationDay}
            onSelect={onCalculationDayChange}
            label={t('calculation_day')}
            dataFields={['value']}
            required={false}
            fontSize={'sm'}
            fontWeight={'bold'}
            userSelector={false}
          />
        </div>
        <div className={'ml-4 flex flex-col w-1/2'}>
          <label className={styleClass.selectorLabel}>
            {t('calculation_time')}
          </label>
          <div className={'flex items-center'}>
            <TimePicker
              onTimeSubmitted={onCalculationTimeChange}
              date={calculationTime}
            />
          </div>
        </div>
      </div>

      <div className={'mb-2 mt-4 pl-2 flex items-center'}>
        <label className={styleClass.label}>{t('scheduling_mode')}</label>
        <div className={'pl-2 flex items-center'}>
          <Checkbox
            disableTab={true}
            onChange={onActiveChange}
            valueProp={!active}
          />
        </div>
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        {editingMode ? (
          <Button type={Button.ButtonType.PRIMARY}>
            {capFirstLetter(t('update'))}
          </Button>
        ) : (
          <Button type={Button.ButtonType.PRIMARY}>
            {capFirstLetter(t('add'))}
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default PPUForm
