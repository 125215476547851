import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import { IFloor, IRoom, ISection } from '../../../service/OrgTypes'
import { getFilteredProjectRooms } from '../../../service/RoomService'
import Button from '../../../ui-elements/button/Button'
import List from '../../../ui-elements/list/List'
import {
  filterType,
  IListColumns,
  ISorting,
} from '../../../ui-elements/list/ListTypes'
import Modal from '../../../ui-elements/modal/Modal'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import { titleComparator } from '../../../utility/Utility'
import {
  classNames,
  constructFilterJson,
  IActiveFilter,
} from '../../../utility/utils'

interface IRoomList {
  projectId: number
  selectedRoomsIds: number[]
  onSelectedRooms: (data: number[]) => void
  closeModal: () => void
}

const SelectRoomForm = ({
  projectId,
  selectedRoomsIds,
  onSelectedRooms,
  closeModal,
}: IRoomList) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'p-2', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  useEffect(() => {
    reload()
  }, [projectId])

  const { t } = useTranslation()
  const [rooms, setRooms] = useState<IRoom[]>([])

  const [totalPages, setTotalPages] = useState<number>(0)
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [selectedRooms, setSelectedRooms] = useState<number[]>(selectedRoomsIds)

  const { getSectionFilter, getFloorFilter, getControlAreaFilter } =
    useListHelper()

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    roomListFilter(activeFilters, page)
  }

  const roomListFilter = async (activeFilters: IActiveFilter, page: number) => {
    getFilteredProjectRooms(projectId, activeFilters, page, 50).then((res) => {
      setRooms(res.rooms)
      setTotalPages(res.pages)
    })
  }

  const reload = () => {
    setReloadTable((n) => !n)
  }

  const columnsForSelector: IListColumns[] = [
    {
      name: 'room_name',
      size: '200',
      id: 'roomName',
      dataField: 'room_name',
      sortingField: 'room_name',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'room_number',
      size: '200',
      id: 'functionRoomNumber',
      dataField: 'functional_room_number',
      sortingField: 'functional_room_number',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'function_name',
      size: '200',
      id: 'functionName',
      dataField: 'function_name',
      sortingField: 'function_name',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'control_area',
      size: '240',
      id: 'controlArea',
      dataField: 'control_area',
      sortingField: 'control_area',
      filterType: filterType.DEFAULT,
      filter: [],
      getFilter: getControlAreaFilter,
      filterDataField: 'control_area.title',
      filterDataValue: 'control_area.id',
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (controlArea: any) =>
        controlArea ? <span>{controlArea.title}</span> : <span />,
    },
    {
      name: 'section',
      size: '200',
      id: 'section',
      dataField: 'section',
      sortingField: 'section',
      filterType: filterType.DEFAULT,
      filterDataField: 'section.name',
      filterDataValue: 'section.id',
      filter: [],
      getFilter: getSectionFilter,
      cell: (section: ISection) => <span>{section ? section.name : ''}</span>,
    },
    {
      name: 'floor',
      size: '200',
      id: 'floor',
      dataField: 'floor',
      sortingField: 'floor',
      filterType: filterType.DEFAULT,
      filterDataField: 'floor.name',
      filterDataValue: 'floor.id',
      filter: [],
      getFilter: getFloorFilter,
      cell: (floor: IFloor) => <span>{floor ? floor.name : ''}</span>,
    },
    {
      name: 'function_number',
      size: '200',
      id: 'functionNumber',
      dataField: 'function_number',
      sortingField: 'function_number',
      filter: [],
      filterType: filterType.TEXT,
    },
  ]

  const onSubmit = (e: any) => {
    e.preventDefault()
    onSelectedRooms?.(selectedRooms)
  }

  return (
    <Modal
      show={true}
      title={t('select_items_you_want_to_add_w_param', { param: t('rooms') })}
      closeModal={closeModal}
      size={'w-9/10'}
      maxWidth={1520}
    >
      <div className={styleClass.root}>
        <List
          columns={columnsForSelector}
          tableName={'roomTable'}
          data={rooms}
          pagination={true}
          isRowSelectable={true}
          onSelectRow={setSelectedRooms}
          selectedRows={selectedRooms}
          reload={reloadTable}
          totalPages={totalPages}
          sortBackend={true}
          filterResetOption={true}
          getFilteredData={getFilteredData}
          itemsPerPage={50}
        />
      </div>
      <ModalFooter>
        <Button type={Button.ButtonType.PRIMARY} onClick={onSubmit}>
          {t('save')}
        </Button>
        <Button onClick={closeModal}>{t('cancel')}</Button>
      </ModalFooter>
    </Modal>
  )
}

export default SelectRoomForm
