import { mapValues } from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import useTree from 'src/hooks/useTree'
import { getDisciplineTree } from 'src/service/DisciplineService'
import { IUserData } from 'src/service/OrgTypes'
import { getDisplineUsersData } from 'src/service/UserService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import BreadCrumb from 'src/ui-elements/breadcrumb/BreadCrumb'
import { numberOrUndefined } from 'src/utility/utils'
import { TreePageStyleClass } from '../../ui-elements/tabs/Utils'
import DisciplinesList from '../discipline/DisciplinesList'
import DropDownExplorer from '../explorer/DropDownExplorer'
import DisciplineRoleDetailsPage from './DisciplineRoleDetails'
import UserInspectorPanel from './UserInspectorPanel'

interface IDisciplineTree {
  projectId: number
  disciplineId?: number
}

const DisciplineTree: React.FC<IDisciplineTree> = ({
  projectId,
  disciplineId: _disciplineId,
}) => {
  const { path: basePath } = useRouteMatch()
  const { t } = useMultipleKeysTranslation()
  const [users, setUsers] = useState<IUserData[]>([])
  const [showUserInspectorPanel, setShowUserInspectorPanel] = useState(true)

  const {
    data,
    loading,
    activeKey,
    reloadTree,
    onItemClick,
    openNodes,
    breadCrumbsList,
  } = useTree(['disciplines', 'users'], getDisciplineTree, 'Discipline', () =>
    setShowUserInspectorPanel(true),
  )

  const history = useHistory()

  const getUsers = async (disciplineId: number, _userId: number) => {
    const users = await getDisplineUsersData(disciplineId)
    setUsers(users)
  }

  const onOpenUserInpectorPanel = async (
    disciplineId: number,
    userId: number,
  ) => {
    setShowUserInspectorPanel(true)
    getUsers(disciplineId, userId)
    history.push(`${basePath}/disciplines/${disciplineId}/users/${userId}`)
  }

  const onTreeSelect = (id: number) => {
    history.push(`${basePath}/disciplines/${id}`)
  }

  return (
    <div className={TreePageStyleClass.root}>
      <DropDownExplorer
        data={data}
        header={{
          title: t('disciplines'),
        }}
        onItemClick={onItemClick}
        initialActiveKey={activeKey}
        initialOpenNodes={openNodes}
        currentPage={1}
        totalPages={1}
        pagination
        loading={loading}
        keyName={'Discipline'}
      />
      <div className={TreePageStyleClass.content}>
        <BreadCrumb breadCrumbs={breadCrumbsList} />
        <Route path={basePath} exact>
          <DisciplinesList
            parentType={'Project'}
            parentId={projectId}
            reloadParent={reloadTree}
            onTreeSelect={onTreeSelect}
            readonly={false}
          />
        </Route>
        <Route
          path={`${basePath}/disciplines/:disciplineId`}
          render={({ match }) => (
            <DisciplineRoleDetailsPage
              projectId={projectId}
              disciplineId={Number(match.params.disciplineId)}
              onOpenUserInpectorPanel={onOpenUserInpectorPanel}
            />
          )}
        />
        <Route
          path={`${basePath}/disciplines/:disciplineId/users/:userId`}
          render={({ match }) => {
            const { userId, disciplineId } = mapValues(
              match.params,
              numberOrUndefined,
            )
            if (!userId || !disciplineId) return
            if (!users.length) getUsers(disciplineId, userId)
            const user = users.find((user) => user.id === userId)
            if (!user) return
            return (
              <UserInspectorPanel
                user={user}
                open={showUserInspectorPanel}
                onClose={() => setShowUserInspectorPanel(false)}
                disableClickOutside={true}
              />
            )
          }}
        />
      </div>
    </div>
  )
}

export default DisciplineTree
