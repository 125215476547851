import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import {
  getConstructionWagon,
  getWagonCheckListsWithValues,
} from 'src/service/ConstructionWagonService'
import {
  IConstructionChecklist,
  IConstructionWagon,
} from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { classNames } from '../../utility/utils'
import MVognInfo from './MVognInfo'

interface IMTavlamote {
  onAddTavlamote: () => void
}

const MTavlamote = ({ onAddTavlamote }: IMTavlamote) => {
  const styleClass = {
    root: classNames('bg-mobile', 'relative'),
  }

  useEffect(() => {
    fetchTavlamotes()
  }, [])

  const { t } = useTranslation()
  const { vid } = useParams<{ caid: string; vid: string }>()
  const fetchTavlamotes = async () => {
    if (vid) {
      setLoading(true)
      const wagonIssues = await getWagonCheckListsWithValues(+vid)
      setChecklists(wagonIssues)
      await getConstructionWagon(+vid).then((res) => setWagon(res))
      setLoading(false)
    }
  }

  const [wagon, setWagon] = useState<IConstructionWagon>()
  const [checklists, setChecklists] = useState<IConstructionChecklist[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  const getItem = (checklist: IConstructionChecklist, key: number) => {
    const { check_list_task_count } = checklist
    const { done, total } = check_list_task_count
      ? check_list_task_count
      : { total: 0, done: 0 }

    return (
      <div
        onClick={() =>
          history.push(`/mobile/tavlamotes/${checklist.id}`, { wagonId: vid })
        }
        key={key}
        className={
          'bg-white px-4 py-2 flex w-full max-w-screen overflow-x-hidden md:rounded'
        }
      >
        <div
          className={
            'flex-grow-0 flex-shrink-0 flex items-center pr-4 w-mx-content'
          }
        >
          <Icon
            icon={Icons.TAVLAMOTE_CIRCLED}
            className={'w-6 h-6 flex flex-shrink-0'}
          />
        </div>

        <div className={'flex-auto min-w-0'}>
          <p
            className={
              'w-full text-xs block font-extralight text-gray-800 truncate'
            }
          >
            {checklist?.comment}
          </p>
          <p
            className={'w-full text-xs block font-light text-gray-600 truncate'}
          >
            {moment(checklist?.created_at).format('ddd MMM DD YYYY HH:MM')}
          </p>
          <p
            className={'w-full font-light block text-gray-800 text-xs truncate'}
          >
            {checklist.signed_by}
          </p>
        </div>

        <div
          className={
            'flex-grow-0 flex-shrink-0 flex flex-col justify-between items-center py-1 w-mx-content'
          }
        >
          <p
            className={'font-light text-gray-600 text-sm'}
          >{`${done}/${total}`}</p>
          <div
            className={`bg-${checklist.checklist_color}-300 w-4 h-4 rounded-full`}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styleClass.root}>
      {wagon && (
        <MVognInfo
          isTavlamote={true}
          wagon={wagon}
          onFloatingBtnClick={onAddTavlamote}
        />
      )}
      {loading ? (
        <div className={'w-full h-full grid place-items-center'}>
          <SpinnerMobile />
        </div>
      ) : (
        <section className={'flex flex-col'}>
          {checklists && checklists.length > 0 ? (
            <div
              className={
                'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:px-4 md:py-2'
              }
            >
              {checklists.map((checklist: any, i: number) =>
                getItem(checklist, i),
              )}
            </div>
          ) : (
            <Badge
              text={t('no_results_found')}
              size={Badge.BadgeSize.LARGE}
              color={Badge.BadgeColor.YELLOW}
            />
          )}
        </section>
      )}
    </div>
  )
}

export default MTavlamote
