import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ITaskData } from 'src/service/OrgTypes'
import InlineComponent from 'src/ui-elements/page-display/inline-components/InlineComponent'
import { useHighlights } from './pdf-queries'

interface TaskReviewInFloorPlanProps {
  task: ITaskData
}

const TaskReviewInFloorPlan: FC<TaskReviewInFloorPlanProps> = ({ task }) => {
  const { t } = useTranslation()
  const { data: highlights } = useHighlights()

  const history = useHistory()

  const highlight = useMemo(() => {
    return highlights?.find((highlight) => highlight.task?.id === task.id)
  }, [highlights, task])

  if (!highlight) return

  const openFile = () => {
    history.replace(`/constructions/plan?highlightId=${highlight.id}`)
  }

  return (
    <InlineComponent
      label="view_task_in_floor_plan"
      content={
        <label className={'font-medium text-sm leading-5 text-gray-700 mt-1'}>
          <a
            className={'text-blue-one cursor-pointer underline'}
            onClick={() => openFile()}
          >
            {t('floor_plan')}
          </a>
        </label>
      }
    />
  )
}

export default TaskReviewInFloorPlan
