import * as React from 'react'
import { capFirstLetter, classNames } from '../../utility/utils'
import Input from '../input/Input'

interface IInputDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  errorMessage?: string
  isValid: boolean
  description?: string
  block?: boolean | undefined
  className?: string
  noPadding?: boolean
  isLoading?: boolean
  disabled?: boolean
  revert?: boolean
  required?: boolean
  small?: boolean
  searchabledata?: any[]
  focus?: boolean
  showSearchIcon?: boolean
  login?: boolean
  value?: string | number | string[]
  editingMode: boolean
  displayData: string
  type?: string
  inTask?: boolean
  onChange: (e: any) => void
  noPaddingRight?: boolean
  autoFocus?: boolean
  minValue?: string | number
  displayError?: string
  step?: string
}

export default class InputDisplay extends React.PureComponent<
  IInputDisplayProps,
  any
> {
  private styleClass = {
    tableRow: (hasError: boolean) =>
      classNames(
        'flex',
        'flex-row',
        'flex-wrap',
        'justify-between',
        'sm:grid',
        'sm:grid-cols-3',
        'sm:gap-4',
        'sm:pl-6',
        'sm:pr-1',
        hasError ? 'sm:pb-2' : 'sm:py-3',
        hasError ? 'pb-1' : 'py-2',
        hasError ? '' : 'my-2',
        'border-b',
        'border-gray-200',
      ),
    tableLeftText: (editingMode: boolean) =>
      classNames(
        'flex',
        'items-start',
        'text-sm',
        'leading-5',
        'font-medium',
        'text-gray-700',
        'pl-1',
        'pb-2',
        'truncate',
        !editingMode ? 'w-1/2' : 'w-full',
        'sm:w-auto',
      ),
    tableCellRight: (
      inTask: boolean,
      editingMode: boolean,
      noPaddingRight: boolean,
    ) =>
      classNames(
        'mt-1',
        'text-sm',
        'leading-5',
        'sm:mt-0',
        'sm:col-span-2',
        inTask ? 'w-full' : '',
        inTask && !editingMode
          ? 'pr-5'
          : editingMode && noPaddingRight
            ? 'pr-0'
            : 0,
        inTask ? 'mx-2' : '',
        !editingMode ? 'w-1/2' : 'w-full',
        'sm:w-auto',
      ),
    displayText: (inTask: boolean) =>
      classNames(
        'block',
        'text-gray-500',
        !inTask ? 'pl-2' : '',
        'break-words',
      ),
  }

  public render() {
    const {
      label,
      errorMessage,
      block,
      className,
      noPadding,
      isLoading,
      disabled,
      required,
      focus,
      showSearchIcon,
      login,
      editingMode,
      displayData,
      value,
      type,
      inTask,
      onChange,
      noPaddingRight,
      autoFocus,
      minValue,
      displayError,
      step,
    } = this.props
    const hasError = !!(errorMessage && errorMessage !== '')
    return (
      <div className={this.styleClass.tableRow(hasError)}>
        <dt className={this.styleClass.tableLeftText(editingMode || false)}>
          {capFirstLetter(label)}
          {required && editingMode ? ' *' : ''}
        </dt>
        <dd
          className={this.styleClass.tableCellRight(
            inTask || false,
            editingMode || false,
            noPaddingRight || false,
          )}
        >
          <div className="w-full">
            {editingMode ? (
              <Input
                label={label}
                errorMessage={errorMessage}
                block={block}
                className={className}
                noPadding={noPadding}
                isLoading={isLoading}
                disabled={disabled}
                required={required}
                focus={focus}
                showSearchIcon={showSearchIcon}
                hideLabel={true}
                login={login}
                value={value}
                onChange={onChange}
                type={type}
                autoFocus={autoFocus}
                minValue={minValue}
                step={step}
              />
            ) : (
              <span className={this.styleClass.displayText(inTask || false)}>
                {displayData}
                {displayError && (
                  <span className={'text-red-600 pl-1'}>({displayError})</span>
                )}
              </span>
            )}
          </div>
        </dd>
      </div>
    )
  }
}
