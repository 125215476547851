import Close from '@icons/close.svg'
import OpenInNew from '@icons/open_in_new.svg'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import useAccessToken from 'src/hooks/useAccessToken'
import IconButton from 'src/ui-elements/button/IconButton'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { classNames, openFileExternal } from 'src/utility/utils'

interface ImagePreview {
  imageUrl?: string
  imageName: string
  openOnClick?: boolean
  onDelete?: () => void
  className?: string
  addToken?: boolean
}

const ImagePreview = ({
  imageUrl,
  imageName,
  className,
  openOnClick,
  onDelete,
  addToken = false,
}: ImagePreview) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isHovered, setIsHovered] = useState(false)
  const token = useAccessToken()

  const addTokenToURL = (url?: string) => {
    return `${url}&token=${token}`
  }
  return (
    <div
      className={classNames(
        'relative overflow-hidden w-48 h-24 flex rounded-md',
        className,
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {onDelete && (
        <IconButton
          icon={<Close />}
          onClick={onDelete}
          className="absolute right-1 top-1 bg-white shadow-md z-50"
        />
      )}
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full z-10 flex flex-col items-center px-2 bg-slate-50">
          <SpinnerMobile className="scale-50 mt-2.5 mr-1.5 z-20" />
          <span className="text-xxs mt-auto text-blue-500 underline truncate w-full">
            {imageName}
          </span>
        </div>
      )}
      {isHovered && openOnClick && (
        <div
          className=" bg-white bg-opacity-75  w-full h-full absolute z-50 flex justify-center items-center cursor-pointer"
          onClick={openFileExternal(imageUrl)}
        >
          <OpenInNew />
        </div>
      )}
      <LazyLoadImage
        onLoad={() => setIsLoading(false)}
        effect="blur"
        alt={imageName}
        src={addToken ? addTokenToURL(imageUrl) : imageUrl}
        className="w-full h-full object-cover"
        wrapperClassName="w-full h-full"
      />
    </div>
  )
}

export default ImagePreview
