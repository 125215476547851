import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import Loader from 'src/ui-elements/loader/Loader'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import {
  addRoomsToControlArea,
  deleteRoomFromControlArea,
  getControlAreaRooms,
} from '../../../service/ControlAreaService'
import { IFloor, IRoom, ISection } from '../../../service/OrgTypes'
import Button from '../../../ui-elements/button/Button'
import { classNames } from '../../../utility/utils'
import SelectRoomForm from '../room/SelectRoomForm'

interface IControlAreaRoomList {
  controlAreaId: number
}

const ControlAreaRoomList = ({ controlAreaId }: IControlAreaRoomList) => {
  const styleClass = {
    root: classNames('flex', 'flex-col'),
    inputGroup: classNames('flex', 'flex-row', 'w-full'),
    label: classNames(
      'w-full',
      'font-roboto',
      'text-sm',
      'font-bold',
      'mb-2',
      'pl-1',
    ),
    selectedRoom: classNames(
      'rounded',
      'border',
      'p-1',
      'mr-2',
      'mt-2',
      'flex',
      'flex-row',
      'flex-initial',
      'bg-white-one',
      'text-black',
    ),
    selectedRoomClose: classNames('cursor-pointer', 'm-auto'),
  }

  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id

  useEffect(() => {
    fetchRooms()
  }, [projectId])

  const { t } = useTranslation()
  const [rooms, setRooms] = useState<IRoom[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showRoomsSelector, setShowRoomsSelector] = useState<boolean>(false)
  const [selectedRoomsId, setSelectedRoomsId] = useState<number[]>([])

  const toggelRoomsSelector = () => {
    setShowRoomsSelector((value) => !value)
    fetchRooms()
  }

  const fetchRooms = () => {
    setLoading(true)
    getControlAreaRooms(controlAreaId).then((res) => {
      setRooms(res)
      setSelectedRoomsId(res.map((r) => (r.id ? r.id : 0)))
      setLoading(false)
    })
  }

  const removeRoom = (selectedRoom: IRoom) => {
    if (selectedRoom.id) {
      deleteRoomFromControlArea(controlAreaId, selectedRoom.id).then((res) => {
        setSelectedRoomsId(res.map((r) => (r.id ? r.id : 0)))
        setRooms(res)
      })
    }
  }

  const addRoomList = (ids: number[]) => {
    addRoomsToControlArea(controlAreaId, ids).then((res) => {
      fetchRooms()
      setRooms(res)
    })
    setShowRoomsSelector((value) => !value)
  }

  const columns: IListColumns[] = [
    {
      name: 'room_name',
      size: '200',
      id: 'roomName',
      dataField: 'room_name',
    },
    {
      name: 'functional_room_number',
      size: '230',
      id: 'functionalRoomNumber',
      dataField: 'functional_room_number',
    },
    {
      name: 'geographical_room_number',
      size: '230',
      id: 'geographicalRoomNumber',
      dataField: 'geographical_room_number',
    },
    {
      name: 'section',
      size: '150',
      id: 'section',
      dataField: 'section',
      cell: (section: ISection) => <span>{section ? section.name : ''}</span>,
    },
    {
      name: 'floor',
      size: '150',
      id: 'floor',
      dataField: 'floor',
      cell: (floor: IFloor) => <span>{floor ? floor.name : ''}</span>,
    },
  ]

  return (
    <div className={styleClass.root}>
      <List
        actionButton={
          <Button type={ButtonType.PRIMARY} onClick={toggelRoomsSelector}>
            {t('add_room')}
          </Button>
        }
        columns={columns}
        tableName={'roomTable'}
        data={rooms}
        itemsPerPage={0}
        actionMenu={[
          {
            name: t('delete'),
            action: removeRoom,
          },
        ]}
      />

      {showRoomsSelector && (
        <SelectRoomForm
          projectId={projectId}
          closeModal={toggelRoomsSelector}
          selectedRoomsIds={selectedRoomsId}
          onSelectedRooms={addRoomList}
        />
      )}
      {loading ? <Loader /> : null}
    </div>
  )
}

export default ControlAreaRoomList
