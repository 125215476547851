export const actionListExportTemplate = (taskType?: string) => ({
  geniusApiRequest: {
    url: `{organizationApiBaseUrl}projects/{projectId}/filter_tasks/${
      taskType ?? 'Aksjon'
    }/pages/0/0`,
    method: 'POST',
  },
  name: 'Actions',
  displayName: taskType ?? 'Aksjoner',
  columns: [
    'Id',
    'Tittel',
    'Type',
    'Status',
    'Beskrivelse',
    'Forbedringsforslag',
    'Frist',
    'Varighet (dager)',
    'Lukket',
    'Ansvarlig',
    'Disiplin',
    'Kontrakt',
    'Sakstype',
    'Sakstype (møte)',
    'Innemelder',
    'Hovedprosess',
    'Kilde',
    'Opprettet',
    'Sist endret',
    'System',
  ],
  rows: [
    'record_id',
    'title',
    'tag.name',
    'status',
    'description',
    'improvement.title',
    'deadline',
    'duration',
    'closed_date',
    'responsible.firstName||responsible.lastName',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'task_type.taskTypeName',
    'taskType',
    'reporter.firstName||reporter.lastName',
    'main_process.name',
    'parent_type',
    'created_at',
    'updated_at',
    'system.record_id',
  ],
})
