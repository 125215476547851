import { IPaginatedRooms, IRoom } from './OrgTypes'
import { request } from './service-utils'

export const createRoom = (room: IRoom, projectId: number): Promise<any> => {
  return request(`projects/${projectId}/rooms`, 'POST', room)
}

export const editRoom = (room: Partial<IRoom>): Promise<any> => {
  return request(`rooms/${room.id}`, 'PUT', room)
}

export const deleteRoom = (room: IRoom): Promise<any> => {
  return request(`rooms/${room.id}`, 'DELETE', {}, true)
}

export const getProjectRooms = (projectId: number): Promise<IRoom[]> => {
  return request(`projects/${projectId}/rooms`, 'GET')
}

export const getRoom = (roomId: number): Promise<IRoom> => {
  return request(`rooms/${roomId}`, 'GET')
}

export const getFilteredProjectRooms = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginatedRooms> => {
  return request(
    `projects/${projectId}/rooms/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getFilteredFloorRooms = (
  floorId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginatedRooms> => {
  return request(
    `floors/${floorId}/rooms/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getRoomsForSelector = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/rooms_for_selector`, 'GET')
}

export const deleteBulkRooms = (data: number[]): Promise<any> => {
  return request(`/rooms/remove_multiple`, 'POST', { room: data })
}
