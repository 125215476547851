import { createContext } from 'react'

export interface ITableInputContext {
  actions: {
    updateFiled: (key: number, filed: string) => void
  }
  state: ITableInputState
}

export interface ITableInputState {
  key: number
  filed: string
}

export type TableInputContext = ITableInputContext | any

export const TableInputContext = createContext<TableInputContext>({})
