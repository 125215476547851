import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SystemTaskIcon } from 'src/components/grid/Grid/components/CellType/TaskCell'
import InspectorSectionTable, {
  IInspectorSectionTableRowsData,
} from 'src/document/components/Inspector/InspectorSectionTable'
import { IntermediateSystemTask } from 'src/page/systematic-completion/SystematicCompletionTable/panels/ActorsInterfacePanel'
import { SystemTaskTypes } from 'src/service/OrgTypes'
import { classNames } from 'src/utility/utils'

const createRowCells = (
  id: number,
  systemRecordId: string,
  systemTaskType: SystemTaskTypes,
  isMain: boolean,
  disciplineName: string,
  comment: string,
  isPrimarySystemRow: boolean,
  isOnlySystemRow: boolean,
  expanded: boolean,
  toggleExpanded: () => void,
  setMain: (id: number, isMain: boolean) => void,
  setComment: (id: number, comment: string, submit: boolean) => void,
) => [
  <span key="recordId">
    {isPrimarySystemRow ? (
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={() => !isOnlySystemRow && toggleExpanded()}
      >
        {!isOnlySystemRow && (
          <div className="text-md mr-1 text-black">
            {expanded ? <span>&#9660;</span> : <span>&#9654;</span>}
          </div>
        )}
        <p>{systemRecordId}</p>
      </div>
    ) : (
      ''
    )}
  </span>,
  <div key="systemTaskType" className="flex justify-center text-[12px]">
    <SystemTaskIcon size={18} taskType={systemTaskType} isAuxiliary={!isMain} />
  </div>,
  <div
    key="isMain"
    onClick={() => setMain(id, isMain)}
    className={classNames('cursor-pointer')}
  >
    <span
      className={classNames(
        'w-3 h-3 rounded-full m-auto block',
        isMain ? 'bg-blue-root' : 'bg-blue-100 hover:bg-blue-200',
      )}
    ></span>
  </div>,
  <span key="shortname">{disciplineName}</span>,
  <span key="comment">
    <input
      type="text"
      className="w-full font-light text-sm bg-inherit hover:bg-white focus:bg-white p-1 rounded-s focus:border"
      contentEditable={true}
      onChange={(e) => setComment(id, e.target.value, false)}
      onBlur={(e) => setComment(id, e.target.value, true)}
      value={comment}
      spellCheck="false"
    />
  </span>,
]

const SystemTaskSummaryTable = ({
  tasksToSubmit,
  toggleMainResponsible,
  setComment,
  deleteTask,
}: {
  tasksToSubmit: IntermediateSystemTask[]
  toggleMainResponsible: (id: number, isMainResponsible: boolean) => void
  setComment: (id: number, comment: string, submit?: boolean) => void
  deleteTask: (ids: number[]) => void
}) => {
  const { t } = useTranslation()
  const [contractedSystems, setContractedSystems] = useState<number[]>([])
  const rows: IInspectorSectionTableRowsData[] = []
  tasksToSubmit.forEach((task, i) => {
    const isFirstSystemRow =
      tasksToSubmit.findIndex(
        (taskToFind) => taskToFind.system.id === task.system.id,
      ) === i

    const isOnlySystemRow = !tasksToSubmit.some(
      (someTask) =>
        someTask.system.id === task.system.id &&
        (someTask.discipline.id !== task.discipline.id ||
          someTask.task_type !== task.task_type),
    )

    const expandedRow = !contractedSystems.includes(task.system.id)

    const toggleRowExpansion = () =>
      expandedRow
        ? setContractedSystems([...contractedSystems, task.system.id])
        : setContractedSystems(
            contractedSystems.filter((id) => id !== task.system.id),
          )

    if (isFirstSystemRow || expandedRow) {
      const cells = createRowCells(
        task.id,
        task.system.recordId,
        task.task_type,
        !task.is_auxiliary,
        task.discipline.shortName,
        task.comment,
        isFirstSystemRow,
        isOnlySystemRow,
        expandedRow,
        toggleRowExpansion,
        toggleMainResponsible,
        setComment,
      )
      rows.push({ cells, id: task.id })
    }
  })
  return (
    <>
      <InspectorSectionTable
        headerColumns={[
          t('system'),
          t('task'),
          t('main_responsible'),
          t('discipline'),
          t('comment'),
        ]}
        rowsData={rows}
        actionMenu={{
          title: 'delete',
          icon: 'delete',
          className: 'text-red-500',
          onClick: (id: number) => {
            const taskToDelete = tasksToSubmit.find((task) => task.id === id)
            if (!taskToDelete) return
            const sameSystemTasks = tasksToSubmit.filter(
              (task) => task.system.id === taskToDelete.system.id,
            )
            const isFirstSystemRow =
              sameSystemTasks.findIndex(
                (task) => task.id === taskToDelete.id,
              ) === 0
            if (
              isFirstSystemRow &&
              contractedSystems.includes(taskToDelete.system.id)
            ) {
              deleteTask(sameSystemTasks.map((task) => task.id))
            } else {
              deleteTask([id])
            }
          },
        }}
      />
    </>
  )
}

export default SystemTaskSummaryTable
