import { FC } from 'react'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import PopUp, { PopUpProps } from './Popup'

interface ImportPopUp extends PopUpProps {
  importFiles: () => void
  addLink: () => void
}

const UploadPopUp: FC<ImportPopUp> = ({
  pos,
  show,
  close,
  importFiles,
  addLink,
}) => {
  const { t } = useMultipleKeysTranslation()
  return (
    <PopUp show={show} close={close} pos={pos}>
      <div
        onClick={importFiles}
        className="flex pt-1 cursor-pointer hover:scale-105 items-center font-normal text-sm"
      >
        <MaterialIcon
          outline={true}
          className="text-base  px-1"
          icon="insert_drive_file"
        />
        {t('upload_files')}
      </div>
      <div
        onClick={addLink}
        className="flex pt-1 cursor-pointer hover:scale-105 items-center font-normal text-sm"
      >
        <MaterialIcon outline={true} className="text-base  px-1" icon="link" />
        {t('add_link_to_file')}
      </div>
    </PopUp>
  )
}

export default UploadPopUp
