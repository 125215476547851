import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from 'src/components/switchHoc/CheckBox'
import { ISystem, ITestSystemGroup, ITestWorkGroup } from 'src/service/OrgTypes'
import { editSystem } from 'src/service/SystemService'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import { IMPORT_TYPE } from '../notifications/import/ImportNotificationItem'

interface SystemUploadListProps {
  importType: IMPORT_TYPE
  items: ISystem[] | ITestSystemGroup[] | ITestWorkGroup[]
}

const SystemUploadList: FC<SystemUploadListProps> = ({ importType, items }) => {
  const { t } = useTranslation()

  const column = (header: string, dataField: string) => {
    return {
      size: '350',
      id: header,
      name: header,
      dataField: dataField,
      cell: (data: string) => {
        return <span>{data}</span>
      },
    }
  }

  const systemColumns: IListColumns[] = [
    column('system_number', 'record_id'),
    column('name', 'name'),
    column('description', 'description'),
    {
      size: '250',
      id: 'isActive',
      name: 'is_active',
      dataField: 'is_active',
      cell: (isActive: boolean, systemId: number) => (
        <span className="flex items-center">
          <Checkbox
            valueProp={isActive ?? false}
            onChange={(v: boolean) => updateSystem(systemId, v)}
          />
          <span className="ml-2">{isActive ? t('active') : t('inactive')}</span>
        </span>
      ),
    },
  ]

  const columnsTwg: IListColumns[] = [
    column('test_work_group_id', 'record_id'),
    column('title', 'title'),
    column('description', 'description'),
  ]

  const columnsTsg: IListColumns[] = [
    column('test_object_group_id', 'record_id'),
    column('title', 'title'),
    column('description', 'description'),
  ]

  const columnsDocument: IListColumns[] = [
    column('document_number', 'record_id'),
    column('document_name', 'name'),
    column('description', 'description'),
  ]

  const systemTypeColumns: IListColumns[] = [
    column('type_code', 'type_code'),
    column('name', 'name'),
    column('description', 'description'),
  ]

  const updateSystem = async (id: number, activeBool: boolean) => {
    await editSystem({ id, is_active: activeBool })
  }

  const getColumns = () => {
    switch (importType) {
      case IMPORT_TYPE.SYSTEM_IMPORT:
        return systemColumns
      case IMPORT_TYPE.SYSTEM_TYPE_IMPORT:
        return systemTypeColumns
      case IMPORT_TYPE.TEST_SYSTEM_GROUP_IMPORT:
        return columnsTsg
      case IMPORT_TYPE.TEST_WORK_GROUP_IMPORT:
        return columnsTwg
      case IMPORT_TYPE.DOCUMENT_IMPORT:
        return columnsDocument
      default:
        return [
          column('record_id', 'record_id'),
          column('name', 'name'),
          column('description', 'description'),
        ]
    }
  }

  return (
    <List
      columns={getColumns()}
      disableColumnSelector={true}
      tableName={'RejectedFileList'}
      disableConfigStore={true}
      data={items}
      onRowClick={() => {
        /*noop */
      }}
      itemsPerPage={0}
    />
  )
}

export default SystemUploadList
