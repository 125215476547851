import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KPIComposedChartComponent from 'src/components/KPI/KPIComposedChartComponent'
import HelperIcons from 'src/components/canvas-header/HelperIcons'
import UserKPIFilter from 'src/components/user/UserKPIFilter'
import { IBarData } from 'src/service/OrgTypes'
import { getLoginsKPIData } from 'src/service/UserService'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'

export interface IUserKPIfiltersData {
  startDate: moment.Moment
  endDate: moment.Moment
  selectedDisciplineIds?: number[]
  selectedUserIds?: number[]
}

interface IUserKPIPageProps {
  projectId: number
}

const UserKPIPage: FC<IUserKPIPageProps> = ({
  projectId,
}: IUserKPIPageProps) => {
  const { t } = useTranslation()
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(1, 'months'),
  )
  const [endDate, setEndDate] = useState<moment.Moment>(moment())
  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([])
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])

  useEffect(() => {
    reload()
  }, [startDate, endDate, selectedUsers, projectId])

  const onFiltersChange = (filters: IUserKPIfiltersData) => {
    if (!startDate.isSame(filters.startDate, 'day')) {
      setStartDate(filters.startDate)
    }
    if (!endDate.isSame(filters.endDate, 'day')) {
      setEndDate(filters.endDate)
    }
    if (
      filters.selectedDisciplineIds &&
      selectedDisciplines !== filters.selectedDisciplineIds
    ) {
      setSelectedDisciplines(filters.selectedDisciplineIds)
    }
    if (filters.selectedUserIds && selectedUsers !== filters.selectedUserIds) {
      setSelectedUsers(filters.selectedUserIds)
    }
  }

  const reload = () => {
    if (selectedDisciplines.length > 0 && selectedUsers.length === 0) {
      setData([])
      return
    }
    fetchData()
  }

  const fetchData = () => {
    setLoading(true)
    const body = Object.assign(
      {},
      { date: [startDate, endDate], users: [...selectedUsers] },
    )
    getLoginsKPIData(projectId, body).then((res) => {
      setData(reFormatDate(res))
      setLoading(false)
    })
  }

  const reFormatDate = (response: Array<{ date: moment.MomentInput }>) => {
    const clone = [...response]
    clone.forEach(
      (item: { date: moment.MomentInput }) =>
        (item.date = moment(item.date).format('L')),
    )
    return clone
  }

  const loginStatLineData: IBarData[] = [
    {
      dataKey: 'login_count',
      name: t('number_of_logged_in_users'),
      fill: '#76a9fa',
    },
  ]

  const onFilterClear = () => {
    setSelectedDisciplines([])
    setSelectedUsers([])
  }

  return (
    <div className={TabPageStyleClass.root}>
      <HelperIcons className="top-28 right-12">
        <UserKPIFilter
          projectId={projectId}
          startDate={moment().subtract(1, 'months')}
          endDate={moment()}
          onFiltersChange={onFiltersChange}
          onFilterClear={onFilterClear}
        />
      </HelperIcons>
      <KPIComposedChartComponent
        barData={loginStatLineData}
        loading={loading}
        axisDataKey={'date'}
        data={data}
        title={t('login_state')}
        subTitle={t('total_number_of_logged_in_users_per_day')}
        equalHeightloader={true}
      />
    </div>
  )
}

export default UserKPIPage
