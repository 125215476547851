import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import useAlert from 'src/ui-elements/toast/useAlert'
import { classNames } from 'src/utility/utils'

export interface IDocButton extends React.HTMLProps<HTMLDivElement> {
  text?: string
  icon?: Icons
  type?: 'default' | 'danger'
  disabledMessage?: string
  fillParentHeight?: boolean
  materialIcon?: string
  flat?: boolean
  outline?: boolean
}

const DocButton: React.FC<IDocButton> = ({
  text,
  onClick,
  fillParentHeight = false,
  type = 'default',
  disabled = false,
  disabledMessage = '',
  icon,
  className = '',
  materialIcon,
  outline = false,
  flat = false,
}) => {
  const styleClass = {
    root: classNames(
      'w-auto',
      'max-w-max',
      'inline-flex',
      'items-center',
      fillParentHeight ? 'h-full' : 'h-7',
      'py-1.5',
      'px-3',
      type === 'default' && 'text-blue-root',
      type === 'danger' && 'text-d-danger',
      disabled
        ? 'text-d-grey-light cursor-not-allowed'
        : 'cursor-pointer hover:bg-d-lightblue',
      fillParentHeight ? 'flex items-center' : 'leading-snug',
      'text-sm',
      flat ? (disabled ? 'bg-d-grey-lightest' : 'bg-d-lightblue') : 'bg-white',
      flat ? 'font-normal' : 'font-medium',
      flat && 'rounded-sm',
      flat ? 'hover:shadow-sm' : 'shadow-xsm',
      className,
    ),
  }

  const { t } = useTranslation()
  const { showAlert } = useAlert()

  const handleClick = (e: any) => {
    if (disabled && disabledMessage) {
      showAlert('warning', t('error'), disabledMessage)
    }
    if (!disabled) {
      onClick?.(e)
    }
  }

  return (
    <div onClick={handleClick} className={styleClass.root}>
      {text ? <span className={'mr-1.5 truncate'}>{text}</span> : null}
      {icon && <Icon icon={icon} styleClass={'flex w-3 h-3 items-center'} />}
      {materialIcon && (
        <MaterialIcon
          outline={outline}
          className={`text-sm`}
          icon={materialIcon}
        />
      )}
    </div>
  )
}

export default DocButton
