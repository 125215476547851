import { useContext } from 'react'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { Module } from 'src/service/OrgTypes'

const useUserAccess = (module: Module) => {
  const { hasWriteAccess, hasAdminAccess } = useContext(UserContext).actions
  const { isProjectAdmin, isSystemAdmin } = useContext(UserContext).state.user

  const writeAccess = hasWriteAccess(module)
  const adminAccess = hasAdminAccess(module)
  return {
    writeAccess,
    adminAccess,
    isProjectAdmin: isSystemAdmin || isProjectAdmin,
  }
}

export default useUserAccess
