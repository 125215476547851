import moment from 'moment'

export const getMilestoneDetailGroups = (
  process: any,
  milestone: any,
  isTema?: boolean,
) => {
  const groups: any[] = []
  process.map((mp: any) => {
    const parent = {
      id: `P${mp.id}`,
      title: mp.name,
      stackItems: false,
      root: true,
      recordId: mp.record_id,
      parent: null,
    }
    groups.push(parent)
    const mile = milestone.filter((m: any) => m.main_process_id === mp.id)
    const milestones: any[] = mile.map((ms: any) => {
      const delay = calculateDelay(ms)
      return {
        id: isTema ? `T${ms.tema_id}` : `M${ms.id}`,
        title: isTema ? ms.tema : ms.name,
        stackItems: false,
        root: false,
        recordId: isTema ? '' : ms.record_id,
        delay,
        deadline: ms.deadline,
        status: ms.status,
        parent: `P${ms.main_process_id}`,
      }
    })
    milestones.sort((dateData1, dateData2) => {
      const dateA = new Date(dateData1.deadline)
      const dateB = new Date(dateData2.deadline)
      return dateA.getTime() - dateB.getTime()
    })
    groups.push(milestones)
    return milestones
  })
  return [...groups]
}

export const getMilestoneDetailItems = (d: any) => {
  const ret: any[] = []
  d.mile_stones.forEach((milestone: any) => {
    const delay = calculateDelay(milestone)
    if (delay > 0) {
      if (milestone.done_children > 0) {
        ret.push(makeMilestone(milestone, delay, true))
      }
      ret.push(makeMilestone(milestone, delay, false))
    } else {
      ret.push(makeMilestone(milestone, 0, true))
    }
  })
  return ret
}

export const getDynamicToday = (groups: any[], parentDelay?: number) => {
  const dynamicDelay = {}
  let prevDelay: number = parentDelay || 0
  const now = moment()
  groups.map((group: any) => {
    const preDelay = moment(now).subtract(prevDelay, 'days')
    const currDelay = moment(now).subtract(group.delay, 'days')
    const stDate = preDelay.isBefore(currDelay) ? preDelay : currDelay
    const endDate = preDelay.isBefore(currDelay) ? currDelay : preDelay

    dynamicDelay[group.id] = {
      id: group.id,
      groupId: `${group.id}`,
      startTime: midDay(stDate),
      endTime: midDay(endDate),
      noTop: stDate.isSame(endDate),
      left: preDelay.isAfter(currDelay),
    }
    prevDelay = group.delay
  })

  return { dynamicDelay }
}

const calculateDelay = (ms: any): number => {
  const startTime = moment(ms.startTime)
  const endTime = moment(ms.deadline)
  const doneChildren = ms.done_children
  const openChildren = ms.open_children
  const totalChildren = doneChildren + openChildren
  const percentDone = totalChildren > 0 ? doneChildren / totalChildren : 1
  const delay =
    ms.status === 'done'
      ? moment(endTime).isAfter(moment(), 'day')
        ? moment().diff(moment(endTime), 'days') * percentDone
        : 0
      : moment(endTime).isAfter(moment(), 'day')
        ? 0
        : moment().diff(moment(startTime), 'days') +
          moment(startTime).diff(moment(endTime), 'days') * percentDone

  return delay
}

const makeMilestone = (
  milestone: any,
  delay: number,
  donePart: boolean,
  isTema?: boolean,
): any => {
  const item = {
    id: `M${milestone.id}_${donePart ? '1_2' : '2_2'}`,
    group: isTema ? `T${milestone.tema_id}` : `M${milestone.id}`,
    title: milestone.record_id,
    start_time:
      delay > 0 && !donePart
        ? midDay(moment().subtract(delay, 'days'))
        : midDay(moment(isTema ? milestone.start_date : milestone.startTime)),
    end_time:
      delay > 0 && donePart
        ? midDay(moment().subtract(delay, 'days'))
        : midDay(moment(milestone.deadline)),
    className:
      moment(milestone.deadline).day() === 6 ||
      moment(milestone.deadline).day() === 0
        ? 'item-weekend'
        : '',
    bgColor:
      milestone.status === 'done'
        ? '#36B55D'
        : moment(milestone.deadline).isBefore(moment(), 'day')
          ? '#FD7171'
          : (milestone.expired_children && milestone.expired_children > 0) ||
              (milestone.behind_schedule_children &&
                milestone.behind_schedule_children > 0)
            ? '#FFD635'
            : '#E0E6FC',
    selectedBgColor: '#7b7b7b',
    color: '#FFFFF',
    data: milestone,
    donePart,
    delay,
  }

  return item
}

const midDay = (day: moment.Moment): moment.Moment => {
  return day.startOf('day').add(12, 'hours')
}
