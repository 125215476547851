import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { classNames } from '../../utility/utils'
import MaterialIcon from '../icon/materialIcon'
import { ListContext } from './ListContextProvider'

interface IPaginationProps {
  onChangePage: () => void
  isExpandedElement?: boolean
}

const styleClass = {
  root: classNames(
    'flex',
    'flex-row',
    'flex-wrap',
    'rounded-sm',
    'items-center',
  ),
  item: (selected: boolean, _isExpandedElement?: boolean) =>
    classNames(
      'px-2',
      'py-1',
      'bg-white',
      'text-xs',
      'hover:text-blue-root hover:font-medium',
      'cursor-pointer',
      selected ? 'text-blue-root font-medium' : 'text-gray-400',
    ),
}

const Pagination: FC<IPaginationProps> = ({
  onChangePage,
  isExpandedElement,
}) => {
  const listContext = useContext(ListContext)
  const { currentPage, totalPages } = listContext.state
  const { onPageChange } = listContext.actions

  const [pages, setPages] = useState<Array<{ index: number }>>([])
  const [startIndex, setStartIndex] = useState(1)
  const [endIndex, setEndIndex] = useState(1)
  const maxPagesToShow = 30

  useEffect(() => {
    const pageArray = []
    for (let i = 1; i <= totalPages; i++) {
      pageArray.push({ index: i })
    }
    setPages(pageArray)
    setEndIndex(
      pageArray.length > maxPagesToShow ? maxPagesToShow : pageArray.length,
    )
  }, [totalPages])

  const pageItem = (page: number) => {
    return (
      <div
        key={page}
        className={styleClass.item(currentPage === page, isExpandedElement)}
        onClick={() => onClickPage(page)}
      >
        {page}
      </div>
    )
  }

  const onClickPage = (page: number) => {
    if (currentPage === page) {
      return
    }
    onPageChange(page, onChangePage)
  }

  const onPreviousPage = () => {
    if (currentPage > 1 && currentPage - 1 < startIndex) {
      onPreviousBatch()
    }
    if (currentPage > 1) {
      onPageChange(currentPage - 1, onChangePage)
    }
  }

  const onNextPage = () => {
    if (currentPage + 1 > endIndex) {
      onNextBatch()
    }
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1, onChangePage)
    }
  }

  const visiblePages = useMemo(() => {
    return pages.filter(
      (page) => page.index >= startIndex && page.index <= endIndex,
    )
  }, [pages, startIndex, endIndex])

  const showPrevBatch = useMemo(() => {
    return startIndex > 1
  }, [startIndex])

  const showNextBatch = useMemo(() => {
    return endIndex < pages.length
  }, [pages, endIndex])

  const onPreviousBatch = () => {
    setStartIndex((prevIndex) =>
      prevIndex - maxPagesToShow >= 1 ? prevIndex - maxPagesToShow : 1,
    )
    setEndIndex((prevIndex) => {
      const diff = endIndex % maxPagesToShow
      const gap = diff === 0 ? maxPagesToShow : diff
      return prevIndex - gap
    })
  }

  const onNextBatch = () => {
    setStartIndex((prevIndex) =>
      prevIndex + maxPagesToShow < pages.length
        ? prevIndex + maxPagesToShow
        : prevIndex,
    )
    setEndIndex((prevIndex) =>
      prevIndex + maxPagesToShow <= pages.length
        ? prevIndex + maxPagesToShow
        : pages.length,
    )
  }

  return (
    <>
      {pages.length > 1 && (
        <div className={styleClass.root}>
          <div
            className={`${styleClass.item(
              false,
              isExpandedElement,
            )} rounded-l-md flex gap-2`}
          >
            {showPrevBatch && (
              <MaterialIcon
                onClick={onPreviousBatch}
                icon={'skip_previous'}
                className="text-gray-400 hover:text-blue-root text-sm mt-0.5 flex items-center"
              />
            )}
            <MaterialIcon
              onClick={onPreviousPage}
              icon={'arrow_back_ios'}
              className="text-gray-400 hover:text-blue-root text-sm mt-0.5 flex items-center"
            />
          </div>
          {visiblePages.map((page) => {
            return pageItem(page.index)
          })}
          <div
            className={`${styleClass.item(
              false,
              isExpandedElement,
            )} rounded-r-md flex gap-2`}
          >
            <MaterialIcon
              onClick={onNextPage}
              icon={'arrow_forward_ios'}
              className="text-gray-400 hover:text-blue-root text-sm mt-0.5 flex items-center"
            />
            {showNextBatch && (
              <MaterialIcon
                onClick={onNextBatch}
                icon={'skip_next'}
                className="text-gray-400 hover:text-blue-root text-sm mt-0.5 flex items-center"
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Pagination
