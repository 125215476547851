import _ from 'lodash'
import { Fragment, memo, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import Checkbox from 'src/components/switchHoc/CheckBox'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import ImportUploadWithVerification from 'src/components/upload-item/ImportWithValidation'
import UploadModal from 'src/components/upload-item/UploadModal'
import { ExportContext } from 'src/context/ExportContext/ExportContext'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IImportItemList,
  IImportWithValidation,
  IListFilterFromParent,
} from 'src/service/OrgTypes'
import ExportService from '../../service/ExportService'
import { classNames } from '../../utility/utils'
import Icon from '../icon/Icon'
import Loader from '../loader/Loader'
import useAlert from '../toast/useAlert'
import ColumnSelector from './ColumnSelector'
import ListColumn from './ListColumn'
import { IListContext } from './ListContextProvider'
import ListItem from './ListItem'
import { IListActionMenuItem, IListColumns, ISelectType } from './ListTypes'

import Pagination from './Pagination'
import { withListContext } from './withListContext'

interface IListBodyProps extends IListContext {
  onRowClick?: (row: any) => void
  actionButton?: JSX.Element
  actionMenu?: IListActionMenuItem[]
  title?: string | JSX.Element
  exportTemplate?: any
  onChangePage?: () => void
  parentId?: number | number[]
  pagination?: boolean
  centerHeaders?: boolean
  filterFromParent?: IListFilterFromParent[]
  expandedElement?: (data: any, id?: number, key?: number) => JSX.Element
  isExpandedElement?: boolean
  isRowDraggable?: boolean
  swapOrder?: (id: number, sequence: number) => void
  disableColumnSelector?: boolean
  exportQrCodes?: any
  importItem?: IImportItemList
  importItemWithValidation?: IImportWithValidation
  isRowSelectable?: boolean
  onSelectRow?: (data: number[], clickedRow?: ISelectType) => void
  selectedRows?: number[]
  highlightedRows?: number[]
  bulkDelete?: boolean
  onBulkDelete?: () => void
  clickExpand?: boolean
  resetFilter?: () => void
  onPreviewClick?: (e: any, data: any) => void
  onEditClick?: (e: any, data: any) => void
  onRowDisable?: (id: number, index: number, data: any) => boolean
  onRowGrayOut?: (data: any) => boolean
}

const styleClass = {
  root: classNames('flex-col', 'bg-white', 'relative'),
  header: classNames(
    'flex',
    'flex-row',
    'justify-start',
    'bg-gray-50',
    'border',
    'border-gray-300',
    'border-l-gray-50 z-9 relative',
    'sticky',
  ),
  listItemContainer: classNames('flex', 'flex-col'),
  top: (isExpandedElement?: boolean) =>
    classNames('flex', 'items-center', isExpandedElement ? 'h-7' : 'h-10'),
  topItem: (button?: boolean) =>
    classNames(
      'flex',
      'rounded-sm',
      button ? '' : 'hover:bg-blue-50',
      'h-1/2',
      'items-center',
      'justify-center',
      'inner-shadow',
      button
        ? '-ml-1 mr-1'
        : 'border bg-white border-gray-300 shadow-sm h-[29px] px-1.5 mr-2',
    ),
}

const ListBody = ({
  onRowClick,
  actionButton,
  actionMenu,
  title,
  exportTemplate,
  pagination,
  centerHeaders,
  state,
  actions,
  parentId,
  filterFromParent,
  expandedElement,
  isExpandedElement,
  isRowDraggable,
  swapOrder,
  disableColumnSelector,
  exportQrCodes,
  importItem,
  importItemWithValidation,
  isRowSelectable,
  onSelectRow,
  selectedRows,
  highlightedRows,
  onBulkDelete,
  bulkDelete,
  resetFilter,
  onPreviewClick,
  onEditClick,
  onRowDisable,
  onRowGrayOut,
  clickExpand,
}: IListBodyProps) => {
  const [selectedRow, setSelectedRow] = useState<number>(-1)
  const [uploadModal, toggleUploadModal] = useState<boolean>(false)
  const { data, columns, selectedColumns, columns: columnData } = state
  const { sortList, moveColumn } = actions
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false)
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState<boolean>(false)
  const [confirmBulkDelete, setConfirmBulkDelete] = useState<boolean>(false)
  const projectContext = useContext(ProjectContext)
  const { actions: exportActions } = useContext(ExportContext)

  const reloadExport = exportActions.addPendingExport

  const { addAlert } = useAlert()
  const { t } = useTranslation()

  useDidMountEffect(() => {
    setCheckboxValue(false)
  }, [data])

  const renderActionColumn = () => {
    return (
      <tr>
        <td className={'border-l border-[#e1e1e1]'} />
      </tr>
    )
  }

  const renderColumns = () => {
    return columnData.map((column: IListColumns, key: number) => {
      const width = column.size ? `${column.size}px` : undefined
      const selectedColumn = selectedColumns.find((sc) => sc.id === column.id)
      const active = selectedColumn !== undefined && selectedColumn.active
      const colFilterFromParent = filterFromParent?.find(
        (fp) => fp.id === column.id,
      )
      return (
        <Fragment key={`list-header-${column.id}-${key}`}>
          {active && (
            <tr style={{ minWidth: width, maxWidth: width }}>
              <ListColumn
                index={key}
                column={column}
                columns={columnData}
                filterFromParent={colFilterFromParent}
                data={data}
                handleSort={() => sortList(column)}
                handleMove={(fromIndex: number, toIndex: number) =>
                  moveColumn(fromIndex, toIndex)
                }
                centerHeaders={key === 0 ? undefined : centerHeaders}
              />
            </tr>
          )}
        </Fragment>
      )
    })
  }

  const onRowActuallyClick = (id: number) => (e: any) => {
    if (
      e.currentTarget.className.match('list-item--row') &&
      !e.currentTarget.className.match('top10RiskSwitch')
    ) {
      if (onRowClick) {
        setSelectedRow(id)
        onRowClick(JSON.parse(e.currentTarget.getAttribute('data-row')))
      }
    }
  }

  const onCheckboxClick = () => {
    const allIds = !checkboxValue ? data.map((d) => d.id) : []
    onSelectRowList(allIds, !checkboxValue, true)
    setCheckboxValue((n) => !n)
  }

  const onSelectRowList = (
    selectedData: number[],
    add: boolean,
    isHeader: boolean,
    rowData?: any,
    shift?: boolean,
  ) => {
    if (selectedRows && onSelectRow) {
      const updatedData: number[] = selectedRows
      if (shift && selectedRows.length > 0) {
        const lastSelectedRow = selectedRows[selectedRows.length - 1]
        const lastSelectedRowData = data.find((d) => d.id === lastSelectedRow)
        const lastSelectedRowDataIndex = data.indexOf(lastSelectedRowData)
        const comingRowDataIndex = data.indexOf(rowData)

        const getRowsinBetween = (start: number, end: number) => {
          const rows = []
          if (start > end) {
            const temp = start
            start = end
            end = temp
          }

          for (let i = start; i <= end; i++) {
            rows.push(data[i].id)
          }
          return rows
        }
        const rowsInBetween = getRowsinBetween(
          lastSelectedRowDataIndex,
          comingRowDataIndex,
        )
        updatedData.push(...rowsInBetween)
      } else if (!add) {
        if (selectedData.length === 1) {
          setCheckboxValue(false)
          const index = updatedData.indexOf(selectedData[0])
          if (index !== -1) {
            updatedData.splice(index, 1)
          }
        } else {
          updatedData.length = 0
        }
      } else {
        updatedData.push(...selectedData)
        if (selectedData.length === 1 && updatedData.length === data.length) {
          setCheckboxValue(true)
        }
      }
      onSelectRow(_.uniq(updatedData), {
        isHeader,
        rowData,
        rowSelected: add,
      })
    }
  }

  const onExport = async (excel: boolean) => {
    const exportColumns: IListColumns[] = []
    selectedColumns.map((sc) => {
      const sfc = columns.find((column) => column.id === sc.id && sc.active)
      if (sfc) {
        exportColumns.push(sfc)
      }
    })

    const exportFilter = {
      filter: state.filters,
      parentId,
      parentType: state.parentType,
      filterFromParent,
    }

    if (excel) {
      const res = await ExportService.exportToExcel(
        t,
        exportTemplate,
        exportColumns.length > 0 ? exportColumns : columns,
        exportFilter,
      )
      reloadExport(res.value.uuId)
    } else {
      const project = projectContext.state.currentProject
      const res = await ExportService.exportQrDocx(
        exportQrCodes,
        project.logo ?? '',
        exportFilter,
      )
      reloadExport(res.value.uuId)
    }
  }

  const handleBulkDelete = () => {
    if (selectedRows && selectedRows.length > 0 && bulkDelete && onBulkDelete) {
      setShowBulkDeleteModal(true)
    } else {
      addAlert({
        type: 'error',
        title: t('an_error_occurred'),
        description: t('select_item_to_delete'),
        autoClose: true,
      })
    }
  }

  const onBulkDeleteClick = () => {
    if (confirmBulkDelete) {
      if (onBulkDelete) {
        setShowBulkDeleteModal(false)
        setConfirmBulkDelete(false)
        onBulkDelete()
      }
    } else {
      addAlert({
        type: 'error',
        title: t('an_error_occurred'),
        description: t('we_need_your_confirmation_to_continue'),
        autoClose: true,
      })
    }
  }

  const onCloseBulkDeleteModal = () => {
    setShowBulkDeleteModal(false)
    setConfirmBulkDelete(false)
  }

  const hasActiveFilter = state.filters.some((filter) =>
    filter.filterOptions.some((option) => option.active),
  )

  const checkDisabled = (id: number, index: number, row: any): boolean => {
    return onRowDisable?.(id, index, row) ?? false
  }
  const headButtons =
    actionButton ||
    exportQrCodes ||
    exportTemplate ||
    importItem ||
    importItemWithValidation ||
    bulkDelete ||
    !disableColumnSelector ||
    title
  return (
    <>
      <div>
        {headButtons && (
          <div className={styleClass.top(isExpandedElement)}>
            {actionButton ? (
              <div className={styleClass.topItem(true)}> {actionButton} </div>
            ) : null}
            {exportTemplate ? (
              <a title={t('export')} className={styleClass.topItem()}>
                <div
                  className={'flex justify-center w-10 h-7'}
                  onClick={() => onExport(true)}
                >
                  <Icon icon={Icon.IconType.EXCEL} className={'w-5 h-4'} />
                </div>
              </a>
            ) : null}

            {exportQrCodes ? (
              <a title={t('export_qr_codes')} className={styleClass.topItem()}>
                <div
                  className={'flex justify-center w-10 h-7'}
                  onClick={() => onExport(false)}
                >
                  <Icon icon={Icon.IconType.QR} className={'w-5 h-4'} />
                </div>
              </a>
            ) : null}
            {importItem || importItemWithValidation ? (
              <a title={t('upload_item')} className={styleClass.topItem()}>
                <div
                  className={'flex justify-center w-10 h-7'}
                  onClick={() => toggleUploadModal((n) => !n)}
                >
                  <Icon icon={Icon.IconType.UPLOAD} className={'w-5 h-4'} />
                </div>
              </a>
            ) : null}
            {bulkDelete ? (
              <a title={t('multi_delete')} className={styleClass.topItem()}>
                <div
                  className={'flex justify-center w-10 h-7'}
                  onClick={handleBulkDelete}
                >
                  <Icon
                    icon={Icon.IconType.DELETE_BULK}
                    className={'w-5 h-4'}
                  />
                </div>
              </a>
            ) : null}

            {resetFilter ? (
              <a
                title={t('reset')}
                className={classNames(
                  'flex',
                  'rounded-sm',
                  'hover:bg-blue-50',
                  'h-1/2',
                  'items-center',
                  'justify-center',
                  'inner-shadow',
                  'border border-gray-300 shadow-sm h-[29px] px-1.5 mr-2',
                  hasActiveFilter ? 'bg-blue-100' : 'bg-white',
                )}
              >
                <div
                  className={'flex justify-center w-10 h-7'}
                  onClick={resetFilter}
                >
                  <Icon
                    icon={Icon.IconType.RESET_FILTER}
                    className={'w-5 h-4'}
                  />
                </div>
              </a>
            ) : null}

            {!disableColumnSelector && (
              <div className={styleClass.topItem()}>
                <ColumnSelector />
              </div>
            )}

            {title ? (
              typeof title !== 'string' ? (
                title
              ) : (
                <div
                  className={
                    'border border-b-0 bg-white inner-shadow h-full flex justify-center items-center p-2'
                  }
                >
                  <span className="text-sm text-gray-500 font-medium">
                    {title}
                  </span>
                </div>
              )
            ) : null}
          </div>
        )}

        <div className={'w-max'}>
          <div className={styleClass.root}>
            <table className={'border-l border-r border-bg-50'}>
              <thead className={styleClass.header}>
                {expandedElement && (
                  <tr className="flex flex-row items-center">
                    <th>
                      <div className={'w-[1.9rem]'} />
                    </th>
                  </tr>
                )}
                {isRowDraggable && (
                  <tr className="flex flex-row items-center">
                    <th>
                      <div className={'w-[1.9rem]'} />
                    </th>
                  </tr>
                )}
                {isRowSelectable && (
                  <tr className="flex flex-row items-center">
                    <th className={'w-[1.9rem]'}>
                      <Checkbox
                        valueProp={checkboxValue}
                        onChange={onCheckboxClick}
                      />
                    </th>
                  </tr>
                )}
                {onEditClick && (
                  <tr className="flex flex-row items-center">
                    <th>
                      <div className={'w-[1.9rem]'} />
                    </th>
                  </tr>
                )}
                {highlightedRows && (
                  <tr className="flex flex-row items-center">
                    <th>
                      <div className={'w-[1.9rem]'} />
                    </th>
                  </tr>
                )}
                {onPreviewClick && (
                  <tr className="flex flex-row items-center">
                    <th>
                      <div className={'w-[1.9rem]'} />
                    </th>
                  </tr>
                )}
                {renderColumns()}
                {actionMenu && renderActionColumn()}
              </thead>
              <tbody className={styleClass.listItemContainer}>
                {state.loading ? (
                  <Loader />
                ) : (
                  data?.map((data: any, key: number) => {
                    return (
                      <ListItem
                        data={data}
                        dataFields={columns}
                        onClick={onRowActuallyClick(key)}
                        actionMenu={actionMenu}
                        key={`list-item-${key}-${data?.id}`}
                        index={key}
                        selectedRow={selectedRow}
                        selectedColumns={selectedColumns}
                        expandedElement={expandedElement}
                        isRowDraggable={isRowDraggable}
                        swapOrder={swapOrder}
                        onSelectRow={(selectedData, isAdd, shift) =>
                          onSelectRowList(
                            selectedData,
                            isAdd,
                            false,
                            data,
                            shift,
                          )
                        }
                        isRowSelectable={isRowSelectable}
                        selectedRows={selectedRows}
                        highlightedRows={highlightedRows}
                        clickExpand={clickExpand}
                        onPreviewClick={onPreviewClick}
                        onEditClick={onEditClick}
                        disable={checkDisabled(data.id, key, data)}
                        onRowGrayOut={onRowGrayOut}
                      />
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        {!state.loading && pagination && (
          <Pagination
            isExpandedElement={isExpandedElement}
            onChangePage={actions.onPageClick}
          />
        )}
      </div>
      {uploadModal && importItem && (
        <UploadModal
          showModal={uploadModal}
          closeModal={() => toggleUploadModal(false)}
          importItem={importItem}
        />
      )}
      <DeleteModal
        show={showBulkDeleteModal}
        itemName={selectedRows ? `${selectedRows.length}` : ''}
        customTitle={t('are_you_sure_you_want_to_delete_with_param', {
          item:
            selectedRows && selectedRows.length > 1
              ? `${selectedRows.length} ${t('elements')}`
              : `${t('an_element')}`,
        })}
        disableDeleteBtn={!confirmBulkDelete}
        onDelete={onBulkDeleteClick}
        closeModal={onCloseBulkDeleteModal}
        isBulk={true}
      >
        <div className={'flex px-2 items-center'}>
          <SwitchHOC
            valueProp={confirmBulkDelete}
            onChange={(val) => setConfirmBulkDelete(val)}
          />
          <p className={'text-gray-800 p-2 text-sm'}>
            {t('i_confirm_deletion_of')} <strong>{selectedRows?.length}</strong>{' '}
            {selectedRows && selectedRows.length > 1
              ? `${t('elements')}`
              : `${t('an_element')}`}{' '}
            (<span className={'italic'}>{t('n_b_this_can_not_be_undone')}</span>
            )
          </p>
        </div>
      </DeleteModal>

      {uploadModal && importItemWithValidation && (
        <ImportUploadWithVerification
          show={uploadModal}
          close={() => {
            importItemWithValidation.reload()
            toggleUploadModal(false)
          }}
          {...importItemWithValidation}
        />
      )}
      {showBulkDeleteModal && (
        <DeleteModal
          show={showBulkDeleteModal}
          itemName={selectedRows ? `${selectedRows.length}` : ''}
          customTitle={t('are_you_sure_you_want_to_delete_with_param', {
            item:
              selectedRows && selectedRows.length > 1
                ? `${selectedRows.length} ${t('elements')}`
                : `${t('an_element')}`,
          })}
          disableDeleteBtn={!confirmBulkDelete}
          onDelete={onBulkDeleteClick}
          closeModal={onCloseBulkDeleteModal}
          isBulk={true}
        >
          <div className={'flex px-2 items-center'}>
            <SwitchHOC
              valueProp={confirmBulkDelete}
              onChange={(val) => setConfirmBulkDelete(val)}
            />
            <p className={'text-gray-800 p-2 text-sm'}>
              {t('i_confirm_deletion_of')}{' '}
              <strong>{selectedRows?.length}</strong>{' '}
              {selectedRows && selectedRows.length > 1
                ? `${t('elements')}`
                : `${t('an_element')}`}{' '}
              (
              <span className={'italic'}>
                {t('n_b_this_can_not_be_undone')}
              </span>
              )
            </p>
          </div>
        </DeleteModal>
      )}
    </>
  )
}

export default memo(withListContext(ListBody))
