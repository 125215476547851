import moment from 'moment'
import { useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { capFirstLetter, classNames } from '../../utility/utils'
import Icon from '../icon/Icon'
import TimePicker from '../time-pickers/TimePicker'

interface ISingleDateDisplayProps {
  date: moment.Moment | null
  onDateChange: (data: moment.Moment) => void
  renderDayContents: (day: moment.Moment) => string | JSX.Element
  focused: boolean
  onFocusChange: (e: any) => void
  id: string
  small: boolean
  isOutsideRange: (day: any) => boolean
  showDefaultInputIcon: boolean
  noBorder?: boolean
  numberOfMonths: number
  displayFormat: () => string
  hideKeyboardShortcutsPanel: boolean
  required: boolean
  disabled?: boolean
  label: string
  editingMode: boolean
  displayData: string
  errorMessage?: string
  showTimeSelector?: boolean
  minimumTime?: moment.Moment
}

const styleClass = {
  placeholder: classNames(
    'text-gray-400',
    'font-normal',
    'text-sm',
    'lowercase',
  ),
  displayText: classNames('block', 'truncate', 'text-gray-500'),
  tableRow: (hasError: boolean) =>
    classNames(
      'flex',
      'flex-row',
      'flex-wrap',
      'justify-between',
      'sm:grid',
      'sm:grid-cols-3',
      'sm:gap-4',
      'sm:pl-6',
      'sm:pr-1',
      hasError ? 'sm:pb-2' : 'sm:py-3',
      hasError ? 'pb-1' : 'py-2',
      hasError ? '' : 'my-2',
      'border-b',
      'border-gray-200',
    ),
  tableLeftText: (editingMode: boolean) =>
    classNames(
      'flex',
      'items-center',
      'text-sm',
      'leading-5',
      'font-medium',
      'text-gray-700',
      'pl-1',
      'pb-2',
      !editingMode ? 'w-1/2' : 'w-full',
      'sm:w-auto',
      'truncate',
    ),
  tableCellRight: (editingMode: boolean) =>
    classNames(
      'mt-1',
      'text-sm',
      'leading-5',
      'sm:mt-0',
      'sm:col-span-2',
      !editingMode ? 'w-1/2' : 'w-full',
      'sm:w-auto',
    ),
  errorMessage: classNames(
    'flex',
    'items-center',
    'text-red-600',
    'ml-2',
    'mb-1',
    'text-sm',
    'font-normal',
    'mb-1',
  ),
}

const SingleDateDisplay = (props: ISingleDateDisplayProps) => {
  const {
    label,
    date,
    onDateChange,
    renderDayContents,
    focused,
    onFocusChange,
    id,
    small,
    isOutsideRange,
    showDefaultInputIcon,
    noBorder,
    numberOfMonths,
    displayFormat,
    hideKeyboardShortcutsPanel,
    required,
    disabled,
    editingMode,
    displayData,
    errorMessage,
    showTimeSelector,
    minimumTime,
  } = props

  const [validDate, setValidDate] = useState<boolean>(
    date !== null && date.isValid?.(),
  )
  const [itemFoucsed, setItemFoucesd] = useState<boolean>(focused)
  const [libDate, setLibDate] = useState<moment.Moment | null>(date)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setLibDate(date)
    setValidDate(date !== null && date.isValid?.())
  }, [editingMode])

  useEffect(() => {
    setItemFoucesd(focused)
    if (!focused) {
      setValidDate(date !== null && date.isValid?.())
    }
  }, [focused])

  useEffect(() => {
    moment.locale(i18n.language)
  }, [i18n.language])

  const onDateisChanged = (d: moment.Moment) => {
    setLibDate(d)
    onDateChange(d)
  }

  const hasError = !!(errorMessage && errorMessage !== '')
  return (
    <div className={styleClass.tableRow(hasError)}>
      <dt className={styleClass.tableLeftText(editingMode || false)}>
        {capFirstLetter(label)}
        {required && editingMode ? ' *' : ''}
      </dt>
      <dd className={styleClass.tableCellRight(editingMode || false)}>
        <div className="w-full pr-5 mx-2">
          {editingMode && hasError ? (
            <p className={styleClass.errorMessage}>{errorMessage}</p>
          ) : null}
          {editingMode ? (
            <>
              {validDate ? (
                <div className="flex">
                  <div>
                    <SingleDatePicker
                      firstDayOfWeek={1}
                      date={libDate}
                      onDateChange={onDateisChanged}
                      renderDayContents={renderDayContents}
                      focused={itemFoucsed}
                      onFocusChange={onFocusChange}
                      id={id}
                      small={small}
                      isOutsideRange={isOutsideRange}
                      showDefaultInputIcon={showDefaultInputIcon}
                      noBorder={noBorder}
                      numberOfMonths={numberOfMonths}
                      displayFormat={displayFormat}
                      hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel}
                      required={required}
                      disabled={disabled}
                      renderMonthText={(d: any) =>
                        d
                          .locale(i18n.language === 'no' ? 'nb' : i18n.language)
                          .format('MMMM YYYY')
                      }
                      isDayHighlighted={(day) =>
                        moment(moment()).isSame(
                          moment(day as moment.Moment),
                          'day',
                        )
                      }
                      placeholder={t('select_w_param', { param: t('date') })}
                    />
                  </div>
                  <>
                    {showTimeSelector && (
                      <div className={'px-2 flex flex-row items-center'}>
                        <TimePicker
                          onlyQuarter={true}
                          minimumTime={
                            date && moment(date).isSame(minimumTime, 'day')
                              ? moment(minimumTime)
                              : undefined
                          }
                          onTimeSubmitted={onDateisChanged}
                          date={
                            date && moment(date).isValid?.()
                              ? moment(date)
                              : moment()
                          }
                        />
                      </div>
                    )}
                  </>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setLibDate(moment())
                    setItemFoucesd(true)
                    setValidDate(true)
                  }}
                >
                  <div className="SingleDatePickerInput SingleDatePickerInput_1">
                    <button
                      className="SingleDatePickerInput_calendarIcon SingleDatePickerInput_calendarIcon_1"
                      type="button"
                    >
                      <Icon
                        styleClass="align-top"
                        icon={Icon.IconType.CALENDAR_TWO}
                        className="SingleDatePickerInput_calendarIcon_svg SingleDatePickerInput_calendarIcon_svg_1"
                      />
                    </button>
                    <span className="DateInput_input">
                      {t('select_w_param', { param: t('date') })}
                    </span>
                  </div>
                </div>
              )}
            </>
          ) : (
            <span className={styleClass.displayText}>
              {displayData || <span className={styleClass.placeholder} />}
            </span>
          )}
        </div>
      </dd>
    </div>
  )
}

export default SingleDateDisplay
