import { createConsumer } from '@rails/actioncable'
import { updateWallToken } from 'src/service/TheWallService'
import { getOrganizationBaseUrl } from './utility/utils'

const getWebSocketURL = async () => {
  const token = await updateWallToken()
  const baseUrl = getOrganizationBaseUrl().split('://', 2)
  if (baseUrl[0] === 'https') {
    return `wss://${baseUrl[1]}/cable?token=${token}`
  } else {
    return `ws://${baseUrl[1]}/cable?token=${token}`
  }
}

const getConsumer = async () => createConsumer(await getWebSocketURL())

export default getConsumer
