import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { getProjectContracts } from 'src/service/ContractService'
import { editDiscipline, getDiscipline } from 'src/service/DisciplineService'
import { IContract, IDiscipline, IUserData } from 'src/service/OrgTypes'
import { getDisplineUsersData } from 'src/service/UserService'
import { Icons } from 'src/ui-elements/icon/Icon'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { modalUrlBuilder } from 'src/utility/utils'
import InspectorSections from '../../document/components/Inspector/InspectorSections'
import FixedPane from '../../ui-elements/fixed-pane/FixedPane'
import DateTimeInlineInputComponent from '../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'

interface IDisciplineInspectorPanel {
  discipline: IDiscipline
  projectId: number
  open: boolean
  onClose: () => void
  onUserClick: (
    id: number,
    users: IUserData[],
    closeDisciplineInspectorPanel: () => void,
  ) => void
  readonly: boolean
  onUpdate: () => void
}

const DisciplineInspectorPanel: React.FC<IDisciplineInspectorPanel> = ({
  discipline,
  open,
  onClose,
  projectId,
  readonly,
  onUpdate,
}) => {
  const { t } = useTranslation()
  const [contracts, setContracts] = useState<IContract[]>([])
  const [users, setusers] = useState<IUserData[]>([])
  const [disciplineData, setDisciplineData] = useState<IDiscipline>(discipline)

  const history = useHistory()
  const modalContext = useContext(ModalContext)
  const fetchDisciplineUsers = useCallback(async () => {
    const newUsers = await getDisplineUsersData(discipline.id)
    setusers(newUsers)
  }, [discipline.id])

  const getSingleDiscipline = useCallback(() => {
    getDiscipline(discipline.id).then((res) => setDisciplineData(res))
  }, [discipline.id])

  useEffect(() => {
    fetchDisciplineUsers()
    getSingleDiscipline()
  }, [fetchDisciplineUsers, getSingleDiscipline])

  useEffect(() => {
    getProjectContracts(projectId).then((res) => {
      setContracts(res)
    })
  }, [projectId])

  const handleUserClick = (id: number) => {
    const modalURL = modalUrlBuilder(projectId, 'user', id)
    history.push(modalURL)
    modalContext.actions.checkURL()
  }

  const getRows = (): Array<{
    name: string
    icon: any
    activeIcon: any
    content: JSX.Element
  }> => [
    {
      name: t('users'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorSectionTable
          headerColumns={[t('name'), t('email'), t('telephone'), t('active')]}
          rowsData={
            users
              ? users.map((user) => {
                  return {
                    cells: [
                      user?.firstName + ' ' + (user?.lastName ?? ''),
                      user?.email ?? '',
                      user?.telephoneNumber ?? '',
                      user?.isActive ? t('yes') : t('no'),
                    ],
                    id: user.id,
                  }
                })
              : []
          }
          handleClick={readonly ? undefined : handleUserClick}
        />
      ),
    },
  ]

  const onChangeInput = (update: Partial<IDiscipline>) => {
    editDiscipline({
      ...update,
      id: discipline.id,
    }).then((res) => {
      setDisciplineData(res)
      onUpdate()
    })
  }

  return (
    <FixedPane
      title={`${disciplineData?.shortName ?? ''} -  ${disciplineData?.name}`}
      show={open}
      onClose={() => onClose()}
      className={'w-[600px]'}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.DISCIPLINE,
        ids: {
          contractId: disciplineData.contract_id,
          disciplineId: disciplineData.id,
        },
      }}
    >
      <>
        <InlineComponentsWrapper padding="left" inputWidth={'w-64'}>
          <TextInlineInputCompontent
            label="title"
            value={disciplineData?.name}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('fill_out_w_param', { param: t('name') })
              }
              return undefined
            }}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            disabled={readonly}
          />
          <TextInlineInputCompontent
            label="code"
            value={disciplineData?.shortName}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('fill_out_w_param', { param: t('code') })
              }
              return undefined
            }}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ shortName: newValue })
            }}
            disabled={readonly}
          />
          <SelectorInlineInputComponent
            items={contracts}
            label={'contract'}
            selectedId={disciplineData?.contract_id}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            validate={(newValue) => {
              if (newValue === undefined) {
                return t('fill_out_w_param', { param: t('contract') })
              }
              return
            }}
            initialItem={contracts.find(
              (item) => item.id === disciplineData?.contract_id,
            )}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ contract_id: +newValue })
            }}
            inspectorPanel={true}
            disabled={readonly}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={disciplineData?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={disciplineData?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
        <InspectorSections defaultIndex={0} sections={getRows()} />
      </>
    </FixedPane>
  )
}
export default DisciplineInspectorPanel
