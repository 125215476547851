import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'
import FdvuSubcontractorWrapper from 'src/fdvu-subcontractor/components/FdvuSubcontractorWrapper'
import FdvuSubcontractorPage from 'src/fdvu-subcontractor/pages/FdvuSubcontractorPage'

const FdvuSubcontractorRouter = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    moment.locale(i18n.language === 'no' ? 'nb' : i18n.language)
  }, [i18n.language])

  return (
    <Switch>
      <FdvuSubcontractorWrapper>
        <Route
          exact={true}
          path={`/subcontractor`}
          render={(_routeProps) => {
            return <FdvuSubcontractorPage />
          }}
        />
      </FdvuSubcontractorWrapper>
    </Switch>
  )
}

export default FdvuSubcontractorRouter
