import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getActivitiesForUser,
  getActivitiesForProject,
  getActivitiesForType,
} from 'src/service/ActivityLogService'

export const useActivityLogForUser = (
  userId: number,
  maxDate?: moment.Moment,
  options?: { enabled?: boolean },
) => {
  const projectId = useProjectId()
  return useInfiniteQuery({
    ...options,
    queryKey: ['activity_log_user', projectId, userId, maxDate],
    queryFn: ({ pageParam }) =>
      getActivitiesForUser(projectId, userId, pageParam, maxDate),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) =>
      lastPage.length === 10 ? lastPageParam + 1 : undefined,
  })
}

export const useActivityLogForProject = (
  maxDate?: moment.Moment,
  options?: { enabled?: boolean },
) => {
  const projectId = useProjectId()
  return useInfiniteQuery({
    ...options,
    queryKey: ['activity_log_project', projectId, maxDate],
    queryFn: ({ pageParam }) =>
      getActivitiesForProject(projectId, pageParam, maxDate),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _allPages, lastPageParam) =>
      lastPage.length === 10 ? lastPageParam + 1 : undefined,
  })
}

export const useActivityLogsForType = (
  parentType: string,
  parentId: number,
  options?: { enabled?: boolean },
) =>
  useQuery({
    ...options,
    queryKey: ['activity_log_type', parentType, parentId],
    queryFn: () => getActivitiesForType(parentType, parentId),
  })
