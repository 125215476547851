import * as React from 'react'
import { classNames } from 'src/utility/utils'

interface IRemoveIcon {
  disabled?: boolean
  onClick: () => void
}

const styleClass = {
  root: (disabled?: boolean) =>
    classNames(
      'bg-d-cardbg',
      'h-4',
      'w-4',
      'flex',
      'items-center',
      'justify-center',
      disabled ? 'text-d-grey-light' : 'text-d-danger',
      !disabled && 'cursor-pointer',
      !disabled && 'hover:bg-d-lightblue',
    ),
}

const RemoveIcon: React.FC<IRemoveIcon> = ({ disabled, onClick }) => {
  return (
    <div className={styleClass.root(disabled)} onClick={onClick}>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 3L3 9"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 3L9 9"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default RemoveIcon
