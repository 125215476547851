import { getProjectContracts } from 'src/service/ContractService'
import { getProjectControlAreas } from 'src/service/ControlAreaService'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { getProjectRooms } from 'src/service/RoomService'
import {
  getProjectSystemFMIsOnly,
  getProjectSystemMMIsOnly,
  getProjectSystems,
} from 'src/service/SystemService'
import { getStatusesOnlyForType } from 'src/service/SystemStatusService'
import { getAllTests } from 'src/service/TestService'
import { getProjectTestSystemGroupOnly } from 'src/service/TestSystemGroupService'
import { getProjectTestWorkGroupOnly } from 'src/service/TestWorkGroupService'
import { getProjectUsersData } from 'src/service/UserService'
import { priorities } from '../../../../service/SystemValues'
import DocumentTypeService from '../../../services/DocumentTypeService'

export enum ImportFiledTypes {
  CONTRACTS = 'contractsImports',
  CONTROL_AREAS = 'controlAreasImports',
  DISCIPLINE = 'disciplineImports',
  DISCIPLINES = 'disciplinesImports',
  ROOMS = 'roomsImports',
  SYSTEM_STATUSES = 'systemStatusesImports',
  TEST_SYSTEM_GROUP_STATUSES = 'testSystemGroupStatusImports',
  TEST_WORK_GROUP_STATUSES = 'testWorkGroupStatusImports',
  SYSTEM_MMI = 'systemMMIImports',
  SYSTEM_FMI = 'systemFMIImports',
  TESTS = 'testsImports',
  REPLACE_BY = 'replaceByImports',
  TEST_GROUP_ID = 'testGroupIdImports',
  RESPONSIBLE = 'responsibleImports',
  TEST_WORK_GROUP = 'testWorkGroupImports',
  DOCUMENT_TYPE = 'document_type',
  PRIORITY = 'priority',
}

export enum DATA_TYPES {
  string = 'string',
  boolean = 'boolean',
  date = 'date',
  enum = 'enum',
  multiEnum = 'multi_enum',
  constantEnum = 'constant_enum',
}

export interface ImportDataFiled {
  selector: string
  getData?: (id: number) => Promise<any[]>
  listItems?: (t: any) => { id: string; name: string }[]
  header: string
  dataType: DATA_TYPES
  mapper?: string
}

export type ImportDataFiledSelector = {
  [key in ImportFiledTypes]: ImportDataFiled
}

class DataSelectors {
  _items = {}

  readonly _getData = async (
    key: string,
    api: () => Promise<any>,
  ): Promise<any[]> => {
    if (this._items[key]) return Promise.resolve(this._items[key])
    const data = await api()
    this._items[key] = data
    return data
  }

  private readonly _selectors: ImportDataFiledSelector = {
    [ImportFiledTypes.TESTS]: {
      selector: 'name',
      getData: (id: number) => this._getData('test', () => getAllTests(id)),
      mapper: 'test_id',
      header: 'test',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.CONTRACTS]: {
      selector: 'contractNumber.contractName',
      getData: (id) => this._getData('contract', () => getProjectContracts(id)),
      mapper: 'contract_id',
      header: 'contract',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.CONTROL_AREAS]: {
      selector: 'title',
      getData: (id) =>
        this._getData('control_area', () => getProjectControlAreas(id)),
      mapper: 'control_area_id',
      header: 'control_area',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.DISCIPLINE]: {
      selector: 'shortName.name',
      getData: (id) =>
        this._getData('discipline', () => getProjectDisciplines(id)),
      mapper: 'discipline_id',
      header: 'discipline',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.DISCIPLINES]: {
      selector: 'shortName.name',
      getData: (id) =>
        this._getData('disciplines', () => getProjectDisciplines(id)),
      mapper: 'disciplines',
      header: 'disciplines',
      dataType: DATA_TYPES.multiEnum,
    },
    [ImportFiledTypes.ROOMS]: {
      selector: 'room_name',
      getData: (id) => this._getData('room', () => getProjectRooms(id)),
      mapper: 'room_id',
      header: 'room',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.SYSTEM_MMI]: {
      selector: 'name',
      getData: (id) =>
        this._getData('system_mmi', () => getProjectSystemMMIsOnly(id)),
      mapper: 'system_mmi_id',
      header: 'system_mmi',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.SYSTEM_FMI]: {
      selector: 'value',
      getData: (id) =>
        this._getData('system_fmi', () => getProjectSystemFMIsOnly(id)),
      mapper: 'system_fmi',
      header: 'system_fmi',
      dataType: DATA_TYPES.string,
    },
    [ImportFiledTypes.SYSTEM_STATUSES]: {
      selector: 'name',
      getData: (id) =>
        this._getData('system_status', () =>
          getStatusesOnlyForType(id, 'System'),
        ),
      mapper: 'system_status_id',
      header: 'system_status',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.TEST_SYSTEM_GROUP_STATUSES]: {
      selector: 'name',
      getData: (id) =>
        this._getData('test_system_group_status', () =>
          getStatusesOnlyForType(id, 'TestSystemGroup'),
        ),
      mapper: 'test_system_group_status_id',
      header: 'test_system_group_status',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.TEST_WORK_GROUP_STATUSES]: {
      selector: 'name',
      getData: (id) =>
        this._getData('test_work_group_status', () =>
          getStatusesOnlyForType(id, 'TestWorkGroup'),
        ),
      mapper: 'test_work_group_status_id',
      header: 'test_work_group_status',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.TEST_GROUP_ID]: {
      selector: 'record_id.title',
      getData: (id) =>
        this._getData('test_system_group', () =>
          getProjectTestSystemGroupOnly(id),
        ),
      mapper: 'test_system_group_id',
      header: 'test_system_group',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.REPLACE_BY]: {
      selector: 'record_id.name',
      getData: (id) => this._getData('test', () => getProjectSystems(id)),
      mapper: 'replaced_by_id',
      header: 'replaced_by',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.RESPONSIBLE]: {
      selector: 'firstName.lastName',
      getData: (id) =>
        this._getData('responsible', () => getProjectUsersData(id)),
      mapper: 'responsible_id',
      header: 'responsible',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.DOCUMENT_TYPE]: {
      selector: 'name',
      getData: (_id) =>
        this._getData('document_type', () =>
          DocumentTypeService.getProjectDocumentTypes(),
        ),
      mapper: 'document_type_id',
      header: 'document_type',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.TEST_WORK_GROUP]: {
      selector: 'record_id.title',
      getData: (id) =>
        this._getData('test_work_group', () => getProjectTestWorkGroupOnly(id)),
      mapper: 'test_work_group_id',
      header: 'test_work_group',
      dataType: DATA_TYPES.enum,
    },
    [ImportFiledTypes.PRIORITY]: {
      selector: 'priority',
      listItems: (t) => priorities(t),
      mapper: 'priority',
      header: 'priority',
      dataType: DATA_TYPES.constantEnum,
    },
  }

  readonly defineDataField = (
    selector: string,
    dataType: DATA_TYPES,
    header?: string,
  ) => {
    return {
      selector: selector,
      mapper: selector,
      header: header ?? selector,
      dataType: dataType,
    }
  }

  readonly getData = async (key: string, id: number) => {
    if (!Object.values(ImportFiledTypes).includes(key as ImportFiledTypes)) {
      return Promise.resolve()
    }
    return await this._selectors[key].getData(id)
  }

  readonly getDataField = (key: string) => {
    return this._selectors[key]
  }

  readonly getMapperFromHeader = (header: string) => {
    const key = Object.keys(this._selectors).find(
      (key) => this._selectors[key].header === header,
    )
    return this._selectors[key ?? '']?.mapper
  }

  readonly getDataFieldFromHeader = (header: string) => {
    const key = Object.keys(this._selectors).find(
      (key) => this._selectors[key].header === header,
    )
    return key ? this._selectors[key] : undefined
  }

  readonly getHeader = (key: string) => {
    return this._selectors[key]?.header ?? key
  }
}

export default new DataSelectors()
