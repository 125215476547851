import { IRecurringEvent } from 'src/components/event/RecurringEvent'

export enum EmailProvider {
  GMAIL = 'gmail',
  OUTLOOK = 'outlook',
  APPLE = 'apple',
  OTHER = 'other',
}

export interface IEvent {
  id: number
  title: string
  description: string
  location?: string
  start: string | moment.Moment
  end: string | moment.Moment
  rrule?: IRecurringEvent
  url?: string
  participants: { name: string; email: string }[]
}

interface Answer {
  name: string
  type: number
  TTL: number
  data: string
}

interface EmailRes {
  Answer: Answer[]
}

class EmailUtil {
  public static emailProvider = async (
    email: string,
  ): Promise<EmailProvider> => {
    const provider = localStorage.getItem('emailProvider')
    if (provider) {
      return Promise.resolve(provider as EmailProvider)
    } else {
      const provider = await this.getEmailProvider(email)
      localStorage.setItem('emailProvider', provider)
      return provider
    }
  }

  private static getEmailProvider = (email: string): Promise<EmailProvider> => {
    const emailProvider = email.split('@')[1].split('.')[0]
    switch (emailProvider) {
      case 'gmail':
        return Promise.resolve(EmailProvider.GMAIL)
      case 'outlook':
      case 'hotmail':
      case 'live':
        return Promise.resolve(EmailProvider.OUTLOOK)
      case 'icloud':
        return Promise.resolve(EmailProvider.APPLE)
      default:
        return this.getRemoteProvider(email)
    }
  }

  private static getRemoteProvider = (
    email: string,
  ): Promise<EmailProvider> => {
    return new Promise(async (resolve, reject) => {
      const emailProvider = email.split('@')[1]
      try {
        const url = `https://dns.google/resolve?name=${emailProvider}&type=mx`
        const data: EmailRes = await fetch(url).then((res) => res.json())
        const provider = data.Answer[0]?.data?.toLowerCase()
        if (provider.includes('google')) {
          resolve(EmailProvider.GMAIL)
        } else if (provider.includes('outlook')) {
          resolve(EmailProvider.OUTLOOK)
        } else if (provider.includes('icloud')) {
          resolve(EmailProvider.APPLE)
        } else {
          resolve(EmailProvider.OTHER)
        }
      } catch (error) {
        reject(error)
      }
    })
  }
}

export default EmailUtil
