import { useTranslation } from 'react-i18next'
import Button from 'src/ui-elements/button/Button'

const NoProjectPage = () => {
  const { t } = useTranslation()
  const tryAgain = () => {
    location.reload()
  }

  return (
    <div
      className={`min-w-screen min-h-screen flex justify-center items-center p-5 overflow-hidden relative bg-yellow-100`}
    >
      <div className="min-h-full w-full rounded-none bg-white p-4 text-gray-800 relative flex items-center text-center text-left">
        <div className="w-full flex flex-col justify-between h-full">
          <div className="flex flex-col pl-1 mb-4">
            <h1 className={`font-bold text-yellow-300`}>
              {t('access_limited')}
            </h1>
            <p className={'text-gray-400'}>
              {t(
                'you_do_not_have_access_to_the_project_please_contract_system_admin',
              )}
            </p>
          </div>
          <div>
            <Button onClick={tryAgain} type={Button.ButtonType.DEFAULT}>
              {t('try_again')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoProjectPage
