import Mediation from '@icons/mediation-fill.svg'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { treeSyntaxLevelColor } from 'src/utility/syntaxUtils'
import { classNames } from 'src/utility/utils'
import MaterialIcon from '../icon/materialIcon'

export interface Item {
  hasNodes: boolean
  isOpen: boolean
  level: number
  key: string
  label: string
  image_url?: string
  data_type?: string
  [name: string]: any
  collapsed?: boolean
}

const styleClass = {
  root: (active?: boolean) =>
    classNames(
      'flex',
      'items-center',
      active && 'bg-d-lightblue',
      'hover:bg-d-grey-two',
      'px-4',
      'py-1',
    ),
  label: (active: boolean, disabled?: boolean) =>
    classNames(
      'font-normal text-sm leading-5 truncate px-1',
      active ? 'text-task-blue' : 'text-gray-700',
      disabled && '!text-slate-300 line-through',
    ),
  badge: (active?: boolean) =>
    classNames(
      'text-[8px] px-1 rounded-md ml-auto',
      active ? 'bg-blue-root text-white' : ' bg-gray-300',
    ),
}

const ToggleIcon = ({ on }: { on: boolean }) => (
  <MaterialIcon
    className="text-base"
    icon={on ? 'expand_more' : 'chevron_right'}
  />
)

export interface TreeMenuItem extends Item {
  active?: boolean
  onClick: (event: React.MouseEvent<HTMLLIElement>) => void
  toggleNode: (key: string) => void
  itemKey: string
}

export type TreeMenuChildren = (props: {
  items: TreeMenuItem[]
  resetOpenNodes?: (openNodes?: string[]) => void
}) => JSX.Element

const SkeletonItem = ({ level }: { level: number }) => {
  return (
    <li
      className={'flex transition-opacity animate-pulse h-[26px] items-center'}
      role="button"
      aria-pressed={true}
      style={{ paddingLeft: level > 0 ? 24 * level : 10 }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="h-[15px] w-[15px] rounded-full opacity-50 bg-gray-300" />
      <div className="h-[15px] w-[200px] rounded-md opacity-50 bg-gray-300 ml-2" />
    </li>
  )
}

export const ItemComponent: React.FunctionComponent<TreeMenuItem> = ({
  itemKey,
  hasNodes = false,
  isOpen = false,
  onClick,
  toggleNode,
  active,
  label = 'unknown',
  numbers = '',
  level,
  icon,
  image_url,
  data_type,
  is_active,
  collapsed = false,
  iconClass,
}) => {
  const inactiveTitle = useMemo(() => {
    return is_active === false
  }, [is_active])

  const { t } = useTranslation()

  if (data_type === 'loading') {
    return <SkeletonItem level={level} />
  }

  const nodeIcon = () => {
    switch (data_type) {
      case 'system_setting':
        return <Mediation className={'fill-task-blue'} />
      case 'system':
        const color = treeSyntaxLevelColor(level)
        return (
          <div className={twMerge(color, 'rounded h-[13px] w-[13px]')}></div>
        )
      case 'user':
        return (
          <img
            src={image_url ?? '/user_profile.png'}
            className="rounded-full inline border-blue-two hover:border-blue-root border h-6 w-6"
            alt=""
          />
        )
      default: {
        return (
          <MaterialIcon
            className={twMerge(
              'flex items-center w-4 h-4 text-sm mr-1.5  text-task-blue',
              iconClass,
            )}
            icon={icon ?? 'folder'}
          />
        )
      }
    }
  }

  const Title = () => (
    <span title={label} className={styleClass.label(false, inactiveTitle)}>
      {label ? `${label}` : t('no_title')}
    </span>
  )

  return (
    <li
      className={styleClass.root(active)}
      role="button"
      aria-pressed={active}
      style={{ paddingLeft: level > 0 ? 24 * level : 10 }}
      onClick={onClick}
    >
      {hasNodes && (
        <div
          className="flex items-center"
          onClick={(e) => {
            toggleNode(itemKey)
            e.stopPropagation()
          }}
        >
          <ToggleIcon on={isOpen} />
        </div>
      )}
      {icon && (
        <span
          key={active ? '1' : '2'}
          className={hasNodes ? 'insert_drive_file' : 'pl-4'}
        >
          {nodeIcon()}
        </span>
      )}
      {!collapsed &&
        (inactiveTitle ? (
          <Tooltip message={t('inactive')} classNames={'items-start'}>
            <Title />
          </Tooltip>
        ) : (
          <Title />
        ))}
      <span className={styleClass.badge(active)}>{numbers}</span>
    </li>
  )
}
