import { HTMLProps, PropsWithChildren } from 'react'
import { extendTailwindMerge } from 'tailwind-merge'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'

const twMerge = extendTailwindMerge({
  classGroups: {
    'font-size': ['text-xxs'],
  },
})

const CircularBtnStyleClass = {
  root: (active: boolean, disabled?: boolean, className?: string) =>
    twMerge(
      'w-9',
      'h-9',
      'rounded-full',
      !disabled && 'hover:bg-blue-root hover:fill-white cursor-pointer',
      'group/button',
      'flex',
      'justify-center',
      'items-center',
      'relative',
      'shadow-md',
      'select-none',
      'bg-white',
      'fill-blue-root',
      active && 'bg-blue-root fill-white text-white',
      disabled && 'bg-bg-grey fill-text-grey text-grey',
      className,
    ),
  icon: (active: boolean, disabled?: boolean) =>
    twMerge(
      !disabled && 'text-blue-root group-hover/button:text-white',
      active && 'text-white',
    ),
}

type ButtonProps = PropsWithChildren<{
  onClick: HTMLProps<HTMLDivElement>['onClick']
  mdIcon?: string
  tooltipText?: string
  className?: string
  active?: boolean
  disabled?: boolean
  disabledTooltipText?: string
  loading?: boolean
}>
const InnerButton = ({
  onClick,
  mdIcon,
  className = '',
  children,
  active = false,
  disabled,
  loading = false,
}: ButtonProps) => {
  return (
    <div className="group/buttonWrapper">
      <div onClick={!disabled ? onClick : undefined}>
        <span
          className={CircularBtnStyleClass.root(active, disabled, className)}
        >
          {mdIcon ? (
            <MaterialIcon
              outline={true}
              icon={mdIcon}
              className={CircularBtnStyleClass.icon(active, disabled)}
            />
          ) : (
            <span className={CircularBtnStyleClass.icon(active, disabled)}>
              {loading ? (
                <SpinnerMobile className="scale-[30%] mt-1 mr-0.5 flex self-center" />
              ) : (
                children
              )}
            </span>
          )}
        </span>
      </div>
    </div>
  )
}

const CircularButton = ({
  onClick,
  mdIcon,
  tooltipText,
  className,
  children = null,
  active,
  disabled,
  disabledTooltipText,
  loading,
}: ButtonProps) => {
  const InnerBtn = () => (
    <InnerButton
      onClick={onClick}
      mdIcon={mdIcon}
      className={className}
      active={active}
      disabled={disabled || loading}
      loading={loading}
    >
      {children}
    </InnerButton>
  )
  return (
    <>
      {tooltipText ? (
        <Tooltip
          offset={2.5}
          message={
            disabled && disabledTooltipText ? disabledTooltipText : tooltipText
          }
          direction={'bottom'}
          show={!disabled || disabledTooltipText !== undefined}
        >
          <InnerBtn />
        </Tooltip>
      ) : (
        <InnerBtn />
      )}
    </>
  )
}

export default CircularButton
