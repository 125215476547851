import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TabPanel } from 'react-tabs'
import KeypointDependencyList from 'src/components/key-point/KeypointDependencyList'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import history from 'src/history'
import { useFreezePlan } from 'src/query/planning/freezePlan'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import { getProjectMilestones } from 'src/service/MilestoneService'
import {
  getKeypointDetails,
  getProjectProcesses,
  IModalOrigin,
  IProjectKeypointResponse,
  updateKeypoint,
} from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getErrorMessage,
  getKeyPointErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import PageHeader from 'src/ui-elements/page-display/PageHeader'
import PageRoot from 'src/ui-elements/page-display/PageRoot'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { ContentTabsWrapper } from 'src/ui-elements/tabs/ContentTabs'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter } from 'src/utility/utils'
import { useSystemTypeGroupForDomain } from '../../query/systemTypeGroups'
import { metaDataSection } from '../TableColumns/Columns'
import ChangeLog from '../changelog/Changelog'
import Comments from '../comment/Comments'
import CopyUrl from '../copy/CopyUrl'
import DocumentsList from '../document/DocumentsList'
import ImprovementPanel from '../improvement/improvement-panel/ImprovementPanel'
import ProjectDeliveriesList from '../process/delivery/ProjectDeliveriesList'
import ChangeLogList from '../process/main-process/ChangeLogList'
import KeypointChangeLogModal from '../process/main-process/KeypointChangeLogModal'
import QrModal from '../qr/QrModal'
import { getMetaDataValues, loadMetaValues } from '../system/SystemUtil'
import ActionsList from '../task/ActionsList'

interface IKeypointDetailPageProps {
  projectId: number
  keypointId: number
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  reloadTree?: () => void
  origin: IModalOrigin
}

const KeypointDetailsPage = ({
  projectId,
  keypointId,
  onOpenItem,
  reloadTree,
  origin,
}: IKeypointDetailPageProps) => {
  const { t } = useTranslation()
  const [keypointData, setKeypointData] = useState<IProjectKeypointResponse>()
  const projectContext = useContext(ProjectContext)
  const { isBaselineAdmin } = projectContext.state
  const { addAlert } = useAlert()
  const [qrString, setQrString] = useState('')
  const [showQrModal, setShowQrModal] = useState(false)
  const [showImprovementPanel, setShowImprovementPanel] = useState(false)
  const [changeLogModal, setChangeLogModal] = useState(false)
  const [changeLogData, setChangeLogData] = useState<any>('')
  const endDateRef = useRef(moment())
  const [tabIndex, setTabIndex] = useState(0)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])

  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('KeyPoint')
  const { data: planFreezeDuration } = useFreezePlan('key_point')

  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<IProjectKeypointResponse>(
      setKeypointData,
      keypointData,
    )

  const reload = () => {
    getKeypointDetails(keypointId, projectId).then((res) => {
      setKeypointData(res)
      if (systemTypeGroup) {
        const metaData = getMetaDataValues(res.meta_data)
        setOptionalFields(
          loadMetaValues(
            keypointId,
            'KeyPoint',
            systemTypeGroup?.optional_fields,
            metaData,
          ),
        )
      }
    })
  }

  useEffect(() => {
    reload()
  }, [keypointId, projectId])

  const isEndTimeValid = (endTimeValue: moment.Moment) => {
    if (!endTimeValue || !moment(endTimeValue).isValid()) {
      return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
    }

    if (endTimeValue) {
      if (keypointData?.mile_stone) {
        if (
          moment(endTimeValue).isAfter(
            moment(keypointData?.mile_stone?.deadline),
            'day',
          )
        ) {
          return getKeyPointErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE,
            t,
            moment(keypointData?.mile_stone?.deadline).format('L'),
          )
        }
      }

      if (keypointData?.improvement) {
        if (
          moment(endTimeValue).isAfter(
            moment(keypointData?.improvement.deadline),
            'day',
          )
        ) {
          return getKeyPointErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
            moment(keypointData.improvement.deadline).format('L'),
          )
        }
      }
    }
  }

  const tabItems: string[] = [
    capFirstLetter(t('deliveries')),
    t('actions'),
    t('change_log'),
    t('incoming_dependencies'),
    t('outgoing_dependencies'),
    t('attachment'),
    t('comments'),
    t('change_log'),
  ]

  const detailItems: string[] = [
    capFirstLetter(t('details')),
    capFirstLetter(t('metadata')),
  ]
  const [detailTabIndex, setDetailTabIndex] = useState<number>(0)

  const onChangeInput = (update: Partial<IProjectKeypointResponse>) => {
    const freezeUntil =
      planFreezeDuration > 0
        ? moment()
            .startOf('week')
            .add(planFreezeDuration - 1, 'weeks')
            .endOf('week')
        : moment().startOf('day')
    const movedToTheFuture: boolean = moment(update?.endTime).isAfter(
      moment(keypointData?.endTime),
      'day',
    )
    if (
      !!update.endTime &&
      movedToTheFuture &&
      moment(keypointData?.endTime).isBefore(freezeUntil, 'day')
    ) {
      setChangeLogModal(true)
      endDateRef.current = moment(update.endTime)
    } else {
      const allUpdates = getChangesForUpdate(update)
      updateKeypoint(keypointId, {
        ...convertUndefinedToNull(allUpdates),
        id: keypointData?.id,
      }).then(() => {
        reload()
        if (update.name) reloadTree?.()
      })
    }
  }

  const onChangeLogSubmit = () => {
    toggleChangelogModal()
    updateKeypoint(keypointId, {
      id: keypointData?.id,
      endTime: endDateRef.current,
      change_reason: changeLogData,
    }).then(() => {
      reload()
    })
  }

  const onChangelogReasonChange = (comment: string) => {
    setChangeLogData(comment)
  }

  const showAlart = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const onStatusSelect = () => {
    if (!keypointData?.update_access) {
      showAlart('error', t('access_limited'), t('do_not_have_access_to_edit'))
      return
    }

    if (keypointData?.open_children && keypointData.open_children > 0) {
      showAlart(
        'error',
        t('an_error_occurred'),
        getKeyPointErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    const updatedKeypoint = { ...keypointData, status: 'done' }
    updateKeypoint(updatedKeypoint.id, updatedKeypoint).then(() => {
      reload()
    })
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const qrString = url
    setQrString(qrString)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setShowQrModal(true)
  }

  const toggleImprovementPanel = () => {
    setShowImprovementPanel(!showImprovementPanel)
  }

  const extraButtons = () => {
    return (
      <div className="flex flex-row flex-wrap items-center">
        {keypointData?.status && keypointData?.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {capFirstLetter(t('done'))}
          </Button>
        )}

        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <CopyUrl />
        </span>

        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <a title={t('generate_qr_code')} className="flex items-center">
            <Icon
              style={{ width: 20, height: 20 }}
              icon={Icon.IconType.QR}
              onClick={toggleQrModal}
            />
          </a>
        </span>

        <Link
          to={`/main-process/keypoint/${keypointId}?rootUrl=${
            origin.url
          }&rootName=${
            origin.name
          }&showHome=${!!origin.showHome}${addProjectToUrl()}`}
        >
          <Button size={Button.ButtonSize.SMALL}>
            {t('view_or_plan_deliveries')}
          </Button>
        </Link>
        {keypointData?.improvement_id ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={toggleImprovementPanel}
          >
            {t('see_improvement_measures')}
          </Button>
        ) : null}
      </div>
    )
  }

  const addProjectToUrl = (): string => {
    const params = new URLSearchParams(history.location.search)
    if (params.has('project')) {
      return `&project=${params.get('project')}`
    }
    return ''
  }

  const reloadParent = () => {
    if (reloadTree) {
      reloadTree()
    }
    reload()
  }

  const toggleChangelogModal = () => {
    setChangeLogModal(!changeLogModal)
  }

  const getModalTitle = (): string => {
    return keypointData?.record_id
      ? keypointData.record_id + ' - ' + keypointData.name
      : 'Loading...'
  }

  const mainSection = () => {
    return (
      <div className="flex">
        <InlineComponentsWrapper padding="left" border={'right'}>
          <TextInlineInputCompontent
            label={t('title')}
            value={keypointData?.name}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            validate={(value) => {
              if (value === undefined || value === '')
                return getErrorMessage(ValidationError.MISSING_TITLE, t)
              return
            }}
          />

          <SelectorInlineInputComponent
            items={statusTypes(t)}
            label={t('status')}
            getItemLabel={(stat) => stat?.name}
            initialItem={{
              id: keypointData?.status ?? '',
              name:
                statusTypes(t).find(
                  (statData) => statData.id === keypointData?.status,
                )?.name ?? '',
            }}
            validate={(value) => {
              if (value === undefined)
                return getErrorMessage(ValidationError.MISSING_STATUS, t)

              if (keypointData?.open_children && value === 'done') {
                return getKeyPointErrorMessage(
                  StructureValidationError.OPEN_DEPENDENCY,
                  t,
                )
              }
              if (
                value &&
                keypointData?.mile_stone &&
                keypointData.mile_stone.status === 'done' &&
                value !== 'done'
              ) {
                return getKeyPointErrorMessage(
                  StructureValidationError.PARENT_IS_CLOSED,
                  t,
                )
              }
              return
            }}
            selectedId={keypointData?.status}
            inspectorPanel={false}
            onValueSubmitted={(stat) => {
              onChangeInput({ status: stat })
            }}
          />

          <DateTimeInlineInputComponent
            label={t('deadline')}
            selectedTime={keypointData?.endTime}
            onValueSubmitted={(deadline) => {
              onChangeInput({ endTime: deadline })
            }}
            validate={(value) => {
              if (value) {
                return isEndTimeValid(value)
              }
              return
            }}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="closed_date"
            selectedTime={keypointData?.closed_date}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            disabled={!isBaselineAdmin}
            label={t('baseline_date')}
            selectedTime={keypointData?.baseline}
            onValueSubmitted={(baselinedate) => {
              onChangeInput({ baseline: baselinedate })
            }}
            inspectorPanel={false}
          />

          <SelectorInlineInputComponent
            getItems={() => getProjectMilestones(projectId)}
            label={t('milestone')}
            getItemLabel={(milestone) =>
              `${milestone?.record_id} - ${milestone?.name}`
            }
            initialItem={keypointData?.mile_stone}
            selectedId={keypointData?.mile_stone_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ mile_stone_id: val })
            }}
            inspectorPanel={false}
          />
          <TextInlineInputCompontent
            label={t('description')}
            value={keypointData?.description}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ description: newValue })
            }}
            textArea={true}
          />

          <TextInlineInputCompontent
            label={t('assumptions')}
            value={keypointData?.assumptions}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ assumptions: newValue })
            }}
            textArea={true}
          />
        </InlineComponentsWrapper>
        <InlineComponentsWrapper padding="left" border={undefined}>
          <TextInlineInputCompontent
            label={t('duration_days')}
            value={`${keypointData?.duration ?? ''}`}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({
                  duration: parseInt(`${newValue}`, 10),
                })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return undefined
            }}
          />
          <TextInlineInputCompontent
            label={t('delay_days')}
            disabled={true}
            value={`${keypointData?.delay ?? 0}`}
          />
          <TextInlineInputCompontent
            label={t('deliveries')}
            disabled={true}
            value={`${keypointData?.done_children || 0}/${
              (keypointData?.open_children || 0) +
              (keypointData?.done_children || 0)
            }`}
          />

          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={keypointData?.discipline}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={keypointData?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              addChangesToAppendWhenKeyUpdates('responsible_id', {
                discipline_id,
              })
            }}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              keypointData?.discipline_id
                ? getDisplineUsers(keypointData?.discipline_id)
                : getProjectUsersWithDisciplines(projectId)
            }
            label="responsible"
            getItemLabel={(responsible) =>
              `${responsible?.firstName} ${responsible?.lastName}`
            }
            initialItem={keypointData?.responsible}
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('responsible'),
                })
              return
            }}
            selectedId={keypointData?.responsible_id ?? 0}
            onValueSubmitted={(responsible_id) => {
              onChangeInput({ responsible_id })
            }}
            dependencies={[keypointData?.discipline_id]}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            label={'contract'}
            disabled={true}
            selectedId={keypointData?.contract_id ?? ''}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            initialItem={keypointData?.contract}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectProcesses(projectId)}
            label={t('main_process')}
            getItemLabel={(process) =>
              `${process?.record_id} - ${process?.name}`
            }
            initialItem={keypointData?.main_process}
            validate={(value) => {
              if (!value || parseInt(`${value}`, 10) === 0) {
                return getErrorMessage(ValidationError.MISSING_MAIN_PROCESS, t)
              }
              return
            }}
            selectedId={keypointData?.main_process_id}
            onValueSubmitted={(val) => {
              onChangeInput({
                main_process_id: val,
                team_id: undefined,
              })
            }}
            inspectorPanel={false}
          />

          <SelectorInlineInputComponent
            getItems={() =>
              keypointData?.main_process_id
                ? getMainprocessTeams(keypointData?.main_process_id)
                : getProjectTeams(projectId)
            }
            label={t('team')}
            getItemLabel={(team) => team?.name}
            initialItem={keypointData?.team}
            selectedId={keypointData?.team_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ team_id: val })
            }}
            cancelButton={true}
            dependencies={[keypointData?.main_process_id]}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectTags(projectId)}
            label={t('type')}
            getItemLabel={(tag) => tag?.name}
            initialItem={keypointData?.tag}
            selectedId={keypointData?.tag_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ tag_id: val })
            }}
            cancelButton={true}
            inspectorPanel={false}
          />

          <SelectorInlineInputComponent
            getItems={() =>
              getFilteredProjectImprovementsWithPagination(projectId).then(
                (res) => res.items,
              )
            }
            label={t('improvement')}
            getItemLabel={(improvement) => improvement?.title}
            initialItem={keypointData?.improvement}
            selectedId={keypointData?.improvement_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ improvement_id: val })
            }}
            cancelButton={true}
            inspectorPanel={false}
          />
          <TextInlineInputCompontent
            label={t('reporter')}
            value={`${keypointData?.user?.firstName ?? ''} ${
              keypointData?.user?.lastName ?? ''
            }`}
            disabled={true}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={`${keypointData?.created_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={`${keypointData?.updated_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }

  return (
    <>
      {keypointId && (
        <PageRoot>
          <PageHeader
            title={
              keypointData?.name
                ? `${keypointData?.record_id} - ${keypointData?.name}`
                : t('loading...')
            }
            subTitle={''}
            additionalButtons={extraButtons()}
          />
          <div className="my-2">
            {optionalFields.length > 0 ? (
              <ContentTabsWrapper
                tabIndex={detailTabIndex}
                tabItems={detailItems}
                onSelect={(index) => setDetailTabIndex(index)}
              >
                <TabPanel>{mainSection()}</TabPanel>
                <TabPanel>
                  <div className="flex">
                    {metaDataSection(optionalFields, reload)}
                  </div>
                </TabPanel>
              </ContentTabsWrapper>
            ) : (
              <>{mainSection()}</>
            )}
          </div>

          {keypointId && (
            <div>
              <ContentTabsWrapper
                tabIndex={tabIndex}
                tabItems={tabItems}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabPanel>
                  <div className={'mx-4'}>
                    {keypointData?.id && (
                      <ProjectDeliveriesList
                        keypoint={keypointData}
                        disableAdd={
                          keypointData ? keypointData?.status === 'done' : false
                        }
                        tableName={'keypointDeliveriesTable'}
                        onOpenItem={onOpenItem}
                        reloadTree={reloadParent}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={'mx-4'}>
                    <ActionsList
                      projectId={projectId}
                      parentType={'KeyPoint'}
                      parentId={keypointId}
                      tableName={'keypointModalActionTable'}
                      onOpenItem={onOpenItem}
                      reloadTree={reloadTree}
                      module={'planning'}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <ChangeLogList id={keypointId} parentType={'KeyPoint'} />
                </TabPanel>
                <TabPanel>
                  <KeypointDependencyList
                    keyPointId={keypointId}
                    projectId={projectId}
                    showDependentOn={true}
                  />
                </TabPanel>
                <TabPanel>
                  <KeypointDependencyList
                    keyPointId={keypointId}
                    projectId={projectId}
                    showDependentOn={false}
                  />
                </TabPanel>
                <TabPanel>
                  <div className="mx-4">
                    <DocumentsList
                      projectId={projectId}
                      parentId={keypointId}
                      parentType={'KeyPoint'}
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={'mx-4'}>
                    <Comments parentId={keypointId} parentType={'KeyPoint'} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={'mx-4'}>
                    <ChangeLog parentId={keypointId} parentType={'KeyPoint'} />
                  </div>
                </TabPanel>
              </ContentTabsWrapper>
            </div>
          )}
          {showQrModal && (
            <QrModal
              show={showQrModal}
              close={() => setShowQrModal(false)}
              title={getModalTitle()}
              value={qrString}
            />
          )}
          {showImprovementPanel && keypointData?.improvement_id && (
            <ImprovementPanel
              projectId={projectContext.state.currentProject.id}
              improvementId={keypointData?.improvement_id}
              onClose={toggleImprovementPanel}
              show={showImprovementPanel}
            />
          )}
          <KeypointChangeLogModal
            show={changeLogModal}
            toggleChangeLogModal={toggleChangelogModal}
            onChangeLogSubmit={onChangeLogSubmit}
            onChangeReasonLog={onChangelogReasonChange}
          />
        </PageRoot>
      )}
    </>
  )
}

export default KeypointDetailsPage
