import {
  ISetStatusConnection,
  IStatusConnection,
  IStatusConnectionType,
} from '../components/system/status-connections/types'
import { IStatus } from './OrgTypes'
import { request } from './service-utils'

export const createStatuses = (
  systemStatus: Omit<IStatus, 'id'>,
  projectId: number,
): Promise<IStatus> => {
  return request(
    `projects/${projectId}/${systemStatus.parent_type}/statuses`,
    'POST',
    systemStatus,
  )
}

export const editStatus = (systemStatus: IStatus): Promise<IStatus> => {
  return request(`statuses/${systemStatus.id}`, 'PUT', systemStatus)
}

export const getStatusesForType = (
  projectId: number,
  type: string,
): Promise<IStatus[]> => {
  return request(`projects/${projectId}/${type}/statuses`, 'GET')
}

export const getAllStatuses = (projectId: number): Promise<IStatus[]> => {
  return request(`projects/${projectId}/statuses`, 'GET')
}

export const updateStatusesConnections = (
  projectId: number,
  statusConnection: ISetStatusConnection,
  method: 'POST' | 'PUT',
): Promise<ISetStatusConnection> => {
  return request(
    `projects/${projectId}/status_connection`,
    method,
    statusConnection,
  )
}

export const getStatusConnections = (
  projectId: number,
): Promise<IStatusConnection[]> => {
  return request(`projects/${projectId}/status_connections`, 'GET')
}

export const deleteStatusConnections = (
  projectId: number,
  ids: IStatusConnection['id'][],
): Promise<IStatusConnection[]> => {
  return request(`projects/${projectId}/status_connections`, 'DELETE', { ids })
}

export const getStatusConnectionTypes = (
  projectId: number,
): Promise<IStatusConnectionType[]> => {
  return request(`projects/${projectId}/status_connection_types`, 'GET')
}

export const getStatusesOnlyForType = (
  projectId: number,
  type: string,
): Promise<IStatus[]> => {
  return request(`projects/${projectId}/${type}/statuses_only`, 'GET')
}

export const deleteStatus = (systemStatus: IStatus) => {
  return request(`/statuses/${systemStatus.id}`, 'DELETE', {}, true)
}
