import { useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button/Button'
import { ButtonSize, ButtonType } from '../button/ButtonEnums'
import Icon from '../icon/Icon'

export const learningTipSeen = (title: string) => {
  return window.localStorage.getItem(`${title}`) === 'seen'
}

interface ILearningTip {
  title: string
  tips: string[]
}

const LearningTip: React.FC<ILearningTip> = ({ title, tips }) => {
  const [currentTip, setCurrentTip] = useState(0)
  const [show, setShow] = useState(true)

  const { t } = useTranslation()
  const finishLearning = () => {
    window.localStorage.setItem(`${title}`, 'seen')
    setShow(false)
  }

  if (learningTipSeen(title) || !show) {
    return null
  }

  return (
    <div
      className={
        'absolute z-10 bg-white text-sm transform translate-y-32 -translate-x-4 shadow-md px-4 py-2 w-72'
      }
    >
      <div className={'flex justify-between'}>
        <p className={'text-xs font-bold capitalize text-gray-500'}>
          {t('tip')}
        </p>
        <Icon
          className={'justify-self-end w-3 cursor-pointer'}
          icon={Icon.IconType.CROSS_GRAY}
          onClick={() => setShow(false)}
        />
      </div>
      <p>{tips[currentTip]}</p>
      <div className={'flex justify-between items-end'}>
        <p className={'text-xs text-gray-500'}>{`${currentTip + 1}/${
          tips.length
        }`}</p>
        <Button
          type={ButtonType.SECONDARY}
          size={ButtonSize.NARROW}
          className={'text-xs'}
          onClick={() => {
            currentTip === tips.length - 1
              ? finishLearning()
              : setCurrentTip(currentTip + 1)
          }}
        >
          {currentTip === tips.length - 1 ? t('got_it') : t('next')}
        </Button>
      </div>
    </div>
  )
}

export default LearningTip
