import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import Icon from '../../ui-elements/icon/Icon'
import { capFirstLetter, classNames } from '../../utility/utils'
import { Direction } from '../multi-selector/MultiSelector'

interface ITableStatusDropdownProps {
  selectedStatus: string
  onSelectClick: (status: IStatusTypes) => void
  statusTypes: IStatusTypes[]
  onClose: () => void
  disabled?: boolean
  openDirection: Direction
}

export interface IStatusTypes {
  id: string
  name: string
  inActive?: boolean
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'relative', 'w-full'),
  label: classNames('font-roboto', 'font-normal', 'mb-2'),
  button: (open: boolean, openDirection: Direction) =>
    classNames(
      'flex',
      'justify-between',
      'items-center',
      'cursor-pointer',
      'bg-white',
      !open
        ? 'border'
        : openDirection === Direction.UP
          ? 'border-b border-r border-l mb-2'
          : 'border-t border-r border-l',
      'border-blue-one',
      'text-left',
      'px-2',
      'py-1',
      'text-sm',
      'cursor-pointer',
      'w-full',
    ),
  inactive_button: classNames(
    'flex',
    'justify-between',
    'items-center',
    'bg-grey',
    'text-black',
    'text-left',
    'px-2',
    'py-1',
    'text-sm',
    'w-full',
  ),
  dropdown: (openDirection: Direction) =>
    classNames(
      'absolute',
      'p-2',
      '-mt-4',
      openDirection === Direction.UP ? '' : 'shadow',
      openDirection === Direction.UP ? 'border-t' : 'border-b',
      'border-r',
      'border-l',
      'border-blue-one',
      openDirection === Direction.UP ? 'rounded-t-lg' : 'rounded-b-lg',
      'bg-white',
      'w-full',
    ),
  dropdownItem: (inActive?: boolean) =>
    classNames(
      'p-1',
      inActive ? 'hover:bg-gray-500' : 'hover:bg-blue-one',
      'hover:text-white',
      'rounded',
      'cursor-pointer',
      'text-sm',
      'whitespace-normal',
    ),
}

const TableStatusDropdown = ({
  selectedStatus,
  onSelectClick,
  statusTypes,
  onClose,
  disabled = false,
  openDirection = Direction.DOWN,
}: ITableStatusDropdownProps) => {
  const [open, setOpen] = useState<boolean>(true)
  const { t } = useTranslation()

  const toggleDropdown = (e: any) => {
    e.stopPropagation()
    setOpen((n) => !n)
  }

  const onItemClick = (status: IStatusTypes) => (e: any) => {
    if (status.inActive) {
      return
    }

    e.stopPropagation()
    setOpen(false)

    onSelectClick(status)
  }

  const buildStatusList = () => {
    return statusTypes.map((status: IStatusTypes, key: number) => {
      return (
        <div
          key={`statuslist-item--${key}`}
          className={twMerge(
            'first-capitalize',
            styleClass.dropdownItem(status.inActive),
          )}
          onClick={onItemClick(status)}
        >
          {t(status.id)}
        </div>
      )
    })
  }

  return (
    <div className={styleClass.root}>
      <div
        className={
          disabled
            ? styleClass.inactive_button
            : styleClass.button(open, openDirection)
        }
        onClick={toggleDropdown}
      >
        {capFirstLetter(t(selectedStatus))}
        {!disabled ? (
          <Icon icon={Icon.IconType.CLOSE} onClick={onClose} />
        ) : null}
      </div>
      {open && !disabled ? (
        <div
          className={styleClass.dropdown(openDirection)}
          style={{
            top:
              openDirection === Direction.UP
                ? `-${statusTypes.length * 27}px`
                : '47px',
            right: 0,
            left: 0,
            zIndex: 80,
          }}
        >
          {buildStatusList()}
        </div>
      ) : null}
    </div>
  )
}

export default TableStatusDropdown
