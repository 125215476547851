import { capitalize } from 'lodash'
import { FormEventHandler, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'
import { useGridStore } from 'src/components/grid/Grid/GridStore'
import { SystemTaskIcon } from 'src/components/grid/Grid/components/CellType/TaskCell'
import { ISystem } from 'src/components/grid/Grid/types/ISystem'
import useProjectId from 'src/components/hooks/useProjectId'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import SystemTaskSummaryTable from 'src/page/systematic-completion/SystematicCompletionTable/panels/components/SystemTaskSummaryTable'
import {
  IDiscipline,
  ISystemTask,
  SystemTaskTypes,
  SystemTaskTypesTranslations,
} from 'src/service/OrgTypes'
import { massCreateOrUpdateSystemTasks } from 'src/service/SystemService'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import Loader from 'src/ui-elements/loader/Loader'
import Spinner from 'src/ui-elements/loader/Spinner'
import MultiSelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/MultiSelectorInlineInputComponent'

type IntermediateSystemTask = Omit<
  ISystemTask,
  'system_id' | 'discipline_id'
> & {
  system: ISystem
  discipline: IDiscipline
}

interface IMultiAddActorsInterfacePanelProps {
  selectedSystems: ISystem[]
  selectedDisciplines: IDiscipline[]
  existingSystemTasks: ISystemTask[]
  onUpdate: () => Promise<void>
  show: boolean
  onClose: () => void
  setSelection?: boolean
  initialSelectedDisciplineIds?: number[]
}

const createSelectedCells = (
  systemIds: number[],
  disciplineIds: number[],
): Record<number, string[]> =>
  systemIds.reduce(
    (selectedCells, currentSystemId) => ({
      ...selectedCells,
      [currentSystemId]: disciplineIds.map(
        (disciplineId) => `Discipline_${disciplineId}`,
      ),
    }),
    {},
  )

const MultiAddActorsInterfacePanel = ({
  selectedSystems,
  selectedDisciplines,
  existingSystemTasks,
  onUpdate,
  show,
  onClose: onCloseCallback,
  setSelection = true,
  initialSelectedDisciplineIds,
}: IMultiAddActorsInterfacePanelProps) => {
  const { t } = useTranslation()
  const [systemOptions, setSystemOptions] = useState<ISystem[]>([])
  const [disciplineOptions, setDisciplineOptions] = useState<IDiscipline[]>([])
  const [submitting, setSubmitting] = useState(false)
  const [selectedTaskTypes, setSelectedTaskTypes] = useState<string[]>([])
  const projectId = useProjectId()
  const [setMultipleSelectedCells] = useGridStore(
    useShallow((state) => [state.setMultipleSelectedCells]),
  )
  const [selectedDisciplineIds, setSelectedDisciplineIds] = useState<number[]>(
    initialSelectedDisciplineIds ??
      selectedDisciplines.map((discipline) => discipline.id),
  )
  const selectedSystemIds = selectedSystems.map((system) => system.id)
  const originalselectedSystemIds = useRef(selectedSystemIds)
  const originalselectedDisciplineIds = useRef(selectedDisciplineIds)

  const [systemTasksToSubmit, setSystemTasksToSubmit] = useState<
    IntermediateSystemTask[]
  >([])

  const onClose = () => {
    onCloseCallback()
    setSelection &&
      setMultipleSelectedCells(
        createSelectedCells(
          originalselectedSystemIds.current,
          originalselectedDisciplineIds.current,
        ),
      )
  }

  useEffect(() => {
    const initialTasksToCreate: IntermediateSystemTask[] = []
    let i = 1
    selectedSystems.forEach((system) =>
      selectedTaskTypes.forEach((taskType: SystemTaskTypes) =>
        selectedDisciplines
          .filter((discipline) =>
            setSelection ? true : selectedDisciplineIds.includes(discipline.id),
          )
          .forEach((discipline) => {
            const existingSystemTask = existingSystemTasks.find(
              (systemTask) =>
                systemTask.discipline_id === discipline.id &&
                systemTask.system_id === system.id &&
                systemTask.task_type === taskType,
            )
            const defaultIsAuxiliary = initialTasksToCreate.some(
              (task) =>
                task.system.id === system.id &&
                task.task_type === taskType &&
                !task.is_auxiliary,
            )
            const isAuxiliary = existingSystemTask
              ? existingSystemTask.is_auxiliary
              : defaultIsAuxiliary
            const comment = existingSystemTask?.comment ?? ''
            initialTasksToCreate.push({
              id: i,
              task_type: taskType,
              discipline,
              system,
              comment,
              is_auxiliary: isAuxiliary,
            })
            i++
          }),
      ),
    )
    setSystemTasksToSubmit(initialTasksToCreate)
  }, [
    JSON.stringify(selectedSystemIds),
    JSON.stringify(selectedDisciplineIds),
    JSON.stringify(selectedTaskTypes),
    JSON.stringify(existingSystemTasks),
  ])

  useEffect(() => {
    //We never want to remove a previously selected system or discipline as option
    setSystemOptions([
      ...systemOptions,
      ...selectedSystems.filter(
        (system) =>
          !systemOptions.some((systemOption) => system.id === systemOption.id),
      ),
    ])
    setDisciplineOptions([
      ...disciplineOptions,
      ...selectedDisciplines.filter(
        (discipline) =>
          !disciplineOptions.some(
            (disciplineOption) => discipline.id === disciplineOption.id,
          ),
      ),
    ])
  }, [selectedSystems, selectedDisciplines])

  useEffect(() => {
    setSelectedDisciplineIds(
      initialSelectedDisciplineIds ??
        selectedDisciplines.map((discipline) => discipline.id),
    )
  }, [selectedDisciplines, initialSelectedDisciplineIds])

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    setSubmitting(true)
    await massCreateOrUpdateSystemTasks(
      projectId,
      systemTasksToSubmit.map((task) => ({
        task_type: task.task_type,
        comment: task.comment,
        is_auxiliary: task.is_auxiliary,
        system_id: task.system.id,
        discipline_id: task.discipline.id,
      })),
    )
    await onUpdate()
    setSelectedTaskTypes([])
    setSubmitting(false)
    onClose()
  }

  return (
    <>
      {submitting && (
        <div className="absolute top-0.5 left-0.5">
          <Loader />
        </div>
      )}
      <FixedPane
        title={capitalize(t('assign_new_tasks'))}
        show={show}
        onClose={onClose}
        disableOutsideClose
        className="w-120"
      >
        <div className="mx-4 text-sm h-full">
          <label className="block mb-1 font-medium">
            {capitalize(t('systems'))}
          </label>
          <MultiSelectorInlineInputComponent
            inline={false}
            items={systemOptions}
            initialItems={selectedSystems}
            search={false}
            getItemLabel={(system) => system.recordId}
            inputWidth="w-full"
            selectedIds={selectedSystemIds}
            onValueSubmitted={(newValue) => {
              if (!newValue) return
              setSelection &&
                setMultipleSelectedCells(
                  createSelectedCells(newValue, selectedDisciplineIds),
                )
            }}
            submitOnChange
          />
          <label className="block mb-1 mt-2 font-medium">
            {capitalize(t('discipline'))}
          </label>
          <MultiSelectorInlineInputComponent
            inline={false}
            items={disciplineOptions}
            initialItems={selectedDisciplines}
            search={false}
            getItemLabel={(discipline) => discipline.shortName}
            inputWidth="w-full"
            selectedIds={selectedDisciplineIds}
            onValueSubmitted={(newValue) => {
              if (!newValue) return
              setSelection
                ? setMultipleSelectedCells(
                    createSelectedCells(selectedSystemIds, newValue),
                  )
                : setSelectedDisciplineIds(newValue)
            }}
            submitOnChange
          />
          <label className="block mb-1 mt-2 font-medium">{`${capitalize(
            t('new_plural'),
          )} ${t('system_tasks')}`}</label>
          <MultiSelectorInlineInputComponent
            inline={false}
            placeholder={`${capitalize(t('select'))} ${t('system_tasks')}`}
            search={false}
            selectAll={true}
            inputWidth="w-full"
            items={Object.values(SystemTaskTypes).map((taskType) => ({
              id: taskType,
            }))}
            getItemLabel={(item) =>
              capitalize(SystemTaskTypesTranslations(item.id, t))
            }
            getDropDownLabel={(item) => (
              <span className="flex text-sm">
                <SystemTaskIcon taskType={item.id} size={18} />
                <p className="ml-2">
                  {capitalize(SystemTaskTypesTranslations(item.id, t))}
                </p>
              </span>
            )}
            selectedIds={selectedTaskTypes}
            onValueSubmitted={(newValue) =>
              setSelectedTaskTypes(newValue ?? [])
            }
            submitOnChange
          />
          {selectedTaskTypes.length > 0 ? (
            <div className="min-h-[400px]">
              <label className="block mt-2 font-medium">
                {capitalize(t('summary'))}
              </label>
              <div className="mb-1 flex flex-col justify-center">
                {selectedDisciplineIds.length === 1 && (
                  <div className="flex flex-row items-center mb-1 ">
                    <label className="mr-2">
                      {capitalize(t('all_are_main_responsible'))}
                    </label>
                    <SwitchHOC
                      valueProp={
                        !systemTasksToSubmit.some((task) => task.is_auxiliary)
                      }
                      onChange={(value: boolean) =>
                        setSystemTasksToSubmit(
                          systemTasksToSubmit.map((task) => ({
                            ...task,
                            is_auxiliary: !value,
                          })),
                        )
                      }
                    />
                  </div>
                )}
                <div>
                  <label>{capitalize(t('set_comment_for_all'))}</label>
                  <input
                    type="text"
                    className="ml-1 font-light text-sm bg-inherit hover:bg-white focus:bg-white p-1 rounded-s border"
                    contentEditable={true}
                    onChange={(e) =>
                      setSystemTasksToSubmit(
                        systemTasksToSubmit.map((task) => ({
                          ...task,
                          comment: e.target.value,
                        })),
                      )
                    }
                    spellCheck="false"
                  />
                </div>
              </div>
              <div className="max-h-[calc(100%_-_20rem)] overflow-scroll">
                <SystemTaskSummaryTable
                  tasksToSubmit={systemTasksToSubmit}
                  setComment={(id: number, comment: string) =>
                    setSystemTasksToSubmit(
                      systemTasksToSubmit.map((task) => {
                        return task.id === id
                          ? { ...task, comment }
                          : { ...task }
                      }),
                    )
                  }
                  toggleMainResponsible={(id: number, isMain: boolean) => {
                    const taskToChange = systemTasksToSubmit.find(
                      (task) => task.id === id,
                    )
                    if (!taskToChange) return
                    setSystemTasksToSubmit(
                      systemTasksToSubmit.map((task) => {
                        const isSameSystemAndTasktype =
                          taskToChange.system.id === task.system.id &&
                          taskToChange.task_type === task.task_type
                        if (!isSameSystemAndTasktype) {
                          return task
                        }
                        return {
                          ...task,
                          is_auxiliary:
                            taskToChange.id === task.id ? isMain : true,
                        }
                      }),
                    )
                  }}
                  deleteTask={(ids: number[]) =>
                    setSystemTasksToSubmit(
                      systemTasksToSubmit.filter(
                        (task) => !ids.includes(task.id),
                      ),
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <>
              <label className="block mb-1 mt-2 font-medium">
                {capitalize(t('summary'))}
              </label>
              <div className="w-full h-96 bg-gray-100 font-normal flex justify-center items-center text-gray-400">
                {capitalize(t('nothing_is_added'))}
              </div>
            </>
          )}
          <div className="flex justify-end mt-2">
            <Button onClick={onClose} className="mr-2 w-1/6">
              {t('cancel')}
            </Button>
            {submitting ? (
              <Spinner />
            ) : (
              <Button
                className="w-1/6"
                disabled={submitting}
                onClick={onSubmit}
                type={ButtonType.PRIMARY}
              >
                {t('add')}
              </Button>
            )}
          </div>
        </div>
      </FixedPane>
    </>
  )
}

export default MultiAddActorsInterfacePanel
