import NavigateNext from '@icons/navigate_next.svg'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { classNames } from '../../utility/utils'

export interface IBreadCrumb {
  index: number
  text: string
  link?: string
  onItemClick?: () => void
}
interface IProps {
  breadCrumbs: IBreadCrumb[]
  inDocument?: boolean
  className?: string
}

const BreadCrumb = ({ breadCrumbs, className = '' }: IProps) => (
  <div className={twMerge('flex flex-wrap items-center text-sm', className)}>
    {breadCrumbs
      .sort((a, b) => (a.index > b.index ? 1 : -1))
      .map(({ text, link, onItemClick }, index) => {
        const isLastElement = index === breadCrumbs.length - 1
        return (
          <Fragment key={index}>
            <div
              onClick={onItemClick}
              className={classNames(
                'flex-shrink-0 max-w-[200px] truncate  leading-5',
                !isLastElement
                  ? 'text-d-fontgrey hover:text-blue-root-lighter transition duration-150 ease-in-out cursor-pointer'
                  : 'text-blue-root-lighter font-medium',
              )}
            >
              {link && !isLastElement ? <Link to={link}>{text}</Link> : text}
            </div>
            {!isLastElement && (
              <NavigateNext className="mx-1 fill-d-fontgrey" />
            )}
          </Fragment>
        )
      })}
  </div>
)

export default BreadCrumb
