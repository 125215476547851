import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { IConstructionTrain } from '../../../service/OrgTypes'
import Modal from '../../../ui-elements/modal/Modal'

import NewTrainForm from './NewTrainForm'

interface ITrainModal {
  open: boolean
  closeModal: () => void
  parentId: number
  train?: IConstructionTrain
  startDate?: moment.Moment
  onSave?: () => void
  controlAreaGroupId?: number
}

const TrainModal = ({
  open,
  parentId,
  closeModal,
  onSave,
  train,
  startDate,
  controlAreaGroupId,
}: ITrainModal) => {
  const { t } = useTranslation()
  return (
    <Modal
      show={open}
      title={t('new_train')}
      size={'w-2/3'}
      closeModal={closeModal}
      maxWidth={800}
    >
      <NewTrainForm
        parentId={parentId}
        train={train}
        startDate={startDate}
        closeModal={closeModal}
        controlAreaGroupId={controlAreaGroupId}
        onSave={onSave}
      />
    </Modal>
  )
}

export default TrainModal
