import moment from 'moment-timezone'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import MultiSelector from 'src/components/multi-selector/MultiSelector'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import {
  getFilterValues,
  setFilterValues,
} from 'src/service/CanavasFilterService'
import { getProjectContracts } from 'src/service/ContractService'
import { getProjectContractDisciplines } from 'src/service/DisciplineService'
import { IContract, IDiscipline } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import useWindowDimensions from 'src/ui-elements/page-display/UseWindowDimensions'
import { renderDayContents } from 'src/utility/Utility'
import { classNames } from '../../utility/utils'

export interface IWagonListData {
  selectedDisplineIds: number[]
  selectedContractIds: number[]
  startTime: moment.Moment
  endTime: moment.Moment
  checkedIn: boolean | null
  checkedOut: boolean | null
}

interface IMWagonFilterProps {
  projectId: number
  onFiltersChange: (f: IWagonListData) => void
  onFilterClear?: () => void
  onOpen: (val: boolean) => void
  filterName: string
  filtersApplied: boolean
  initState: { current: IWagonListData }
  myFilters?: { myDisciplines: IDiscipline[]; myContracts: IContract[] }
}

const styleClass = {
  root: classNames('z-40', 'mx-2', 'my-1'),
  rootFloating: (hide?: boolean) =>
    classNames(
      'absolute',
      'top-20',
      'right-16',
      'z-40',
      hide ? 'invisible' : '',
    ),
  icon: (opened: boolean) =>
    classNames(
      'w-7',
      'h-7',
      opened ? 'rounded-full shadow-md' : '',
      opened ? 'bg-gray-500 mt-1' : '',
      'text-white',
      'flex',
      'justify-center',
      'items-center',
      'cursor-pointer',
      'relative',
    ),
  filter: classNames(
    'flex',
    'flex-col',
    'absolute',
    'right-0',
    'bg-white',
    'shadow-xl',
    'w-screen',
    'top-11',
    'rounded-t-xl',
  ),
  rootFilter: classNames(
    'flex',
    'flex-col',
    'items-left',
    'justify-between',
    'flex-wrap',
    'w-full',
    'bg-white',
  ),
  filterSelector: classNames('mr-6', 'mb-2', 'w-full'),
  switch: (halfWidth: boolean, right?: boolean) =>
    classNames(
      right ? '' : 'mr-5',
      'w-full',
      'flex',
      right ? 'justify-end' : 'justify-start',
      'items-end',
      halfWidth ? 'w-1/2' : 'sm:w-1/2 lg:w-1/3',
      'py-2',
      'mb-0.8',
      'max-w-38',
    ),
  booleanFilters: (noBooleanFilters: boolean) =>
    classNames(
      'flex',
      'justify-between',
      'w-full',
      noBooleanFilters ? 'my-3' : '',
    ),
  label: {
    root: classNames(
      'absolute',
      'p-1',
      'rounded',
      'bg-black',
      'text-xxs',
      'z-10',
      'font-normal',
      'text-white',
    ),
    label: classNames(
      'text-sm',
      'leading-5',
      'font-medium',
      'text-gray-600',
      'pr-2',
      'w-mx-content',
      'my-2',
    ),
  },
  filterBox: classNames(
    'bg-white',
    'font-normal',
    'border-gray-300',
    'border',
    'shadow-sm',
    'flex',
    'flex-col',
    'justify-start',
    'text-capitalize',
    'px-1',
  ),
}

const MWagonFilter: React.FC<IMWagonFilterProps> = ({
  projectId,
  onFiltersChange,
  onOpen,
  onFilterClear,
  filterName,
  filtersApplied,
  initState,
  myFilters,
}: IMWagonFilterProps) => {
  const { t } = useTranslation()
  moment.tz.setDefault('Europe/Oslo')
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [contracts, setContracts] = useState<IContract[]>([])
  const [focus, setFocus] = useState<string>('')

  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>(
    initState.current.selectedDisplineIds,
  )
  const [selectedContracts, setSelectedContracts] = useState<number[]>(
    initState.current.selectedContractIds,
  )
  const [checkedIn, setCheckedIn] = useState<boolean | null>(
    initState.current.checkedIn,
  )
  const [checkedOut, setCheckedOut] = useState<boolean | null>(
    initState.current.checkedOut,
  )

  const [isdisciplineFirst, setIsDisciplineFirst] = useState<boolean>(true)
  const [isContractFirst, setIsContractFirst] = useState<boolean>(true)
  const [disciplineLoading, setDisciplineLoading] = useState<boolean>(false)
  const [contractLoading, setContractLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [rangeStartDate, setRangeStartDate] = useState<moment.Moment>(
    initState.current.startTime,
  )
  const [rangeEndDate, setRangeEndDate] = useState<moment.Moment>(
    initState.current.endTime,
  )
  const { width } = useWindowDimensions()

  useEffect(() => {
    loadCache()
  }, [projectId])

  const onClear = () => {
    setIsDisciplineFirst(true)
    setIsContractFirst(true)
    setSelectedContracts([])
    setSelectedDisciplines([])
    setCheckedIn(null)
    setCheckedOut(null)
    setRangeStartDate(initState.current.startTime)
    setRangeEndDate(initState.current.endTime)
    if (onFilterClear) {
      onFilterClear()
    }
    onFiltersChange({ ...initState.current })
    setFilterValues(projectId, filterName, initState.current)
    onToggleClose()
  }

  const setFilterToTableKeeper = (filterData: IWagonListData) => {
    setFilterValues(projectId, filterName, filterData)
  }

  const loadCache = async () => {
    const cachedFilters = await getFilterValues(projectId, filterName)
    const filters: IWagonListData =
      cachedFilters && !Array.isArray(cachedFilters)
        ? cachedFilters
        : initState.current
    if (filters) {
      setCheckedIn(filters?.checkedIn)
      setCheckedOut(filters?.checkedOut)
      setRangeStartDate(
        filters.startTime
          ? moment(filters.startTime)
          : initState.current.startTime,
      )
      setRangeEndDate(
        filters.endTime ? moment(filters.endTime) : initState.current.endTime,
      )
      if (myFilters) {
        const { selectedDisplineIds, selectedContractIds } = initState.current
        setDisciplines(myFilters.myDisciplines)
        setContracts(myFilters.myContracts)
        setSelectedDisciplines(selectedDisplineIds)
        setSelectedContracts(selectedContractIds)
        onFiltersChange({
          ...filters,
          selectedDisplineIds,
          selectedContractIds,
        })
      } else {
        setSelectedContracts(filters?.selectedContractIds ?? [])
        setSelectedDisciplines(filters?.selectedDisplineIds ?? [])
        onFiltersChange(filters)
      }
    }
  }

  const getCurrentFilters = (): IWagonListData => {
    return {
      selectedDisplineIds: selectedDisciplines,
      selectedContractIds: selectedContracts,
      checkedIn,
      checkedOut,
      startTime: rangeStartDate,
      endTime: rangeEndDate,
    }
  }

  const onOpenContract = () => {
    return new Promise<void>(async (resolve) => {
      if (isContractFirst) {
        setContractLoading(true)
        const allContracts = await getProjectContracts(projectId)
        setContracts(allContracts)
        setContractLoading(false)
        setIsContractFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenDiscipline = () => {
    return new Promise<void>(async (resolve) => {
      if (isdisciplineFirst) {
        setDisciplineLoading(true)
        const allDisciplines = await getProjectContractDisciplines(projectId, {
          contract: selectedContracts,
        })
        setDisciplines(allDisciplines)
        setDisciplineLoading(false)
        setIsDisciplineFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onChangeContract = async (selectedids: number[]) => {
    setSelectedContracts(selectedids)
    setDisciplineLoading(true)
    const allDisciplines = await getProjectContractDisciplines(projectId, {
      contract: selectedids,
    })
    setDisciplines(allDisciplines)
    if (selectedids.length > 0) {
      const newSelectedDiscipline = allDisciplines.map(
        (discipline: any) => discipline.id,
      )
      setSelectedDisciplines(newSelectedDiscipline)
      const newFilter = getCurrentFilters()
      newFilter.selectedContractIds = selectedids
      newFilter.selectedDisplineIds = newSelectedDiscipline
      setFilterToTableKeeper(newFilter)
      onFiltersChange(newFilter)
    } else {
      setSelectedDisciplines([])
      const newFilter = getCurrentFilters()
      newFilter.selectedContractIds = selectedids
      newFilter.selectedDisplineIds = []
      setFilterToTableKeeper(newFilter)
      onFiltersChange(newFilter)
    }
    setDisciplineLoading(false)
  }

  const onChangeDiscipline = async (selectedids: number[]) => {
    setSelectedDisciplines(selectedids)
    const newFilter = getCurrentFilters()
    newFilter.selectedDisplineIds = selectedids
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
    if (selectedids.length === 0) {
      setSelectedContracts([])
      const allDisciplines = await getProjectContractDisciplines(projectId, {
        contract: [],
      })
      setDisciplineLoading(true)
      setDisciplines(allDisciplines)
      setDisciplineLoading(false)
      return
    }
    const selectedContractIds = new Set<number>()
    const selectedDisciplineObj = disciplines.filter(
      (discipline) => selectedids.indexOf(discipline.id) !== -1,
    )
    contracts.filter((contract) => {
      selectedDisciplineObj.map((disp) => {
        if (disp.contract_id === contract.id) {
          selectedContractIds.add(contract.id)
        }
      })
    })
    const contractsByDiscipline = contracts
      .filter((contract: IContract) => selectedContractIds.has(contract.id))
      .map((contr) => contr.id)
    setSelectedContracts(contractsByDiscipline)
    newFilter.selectedContractIds = contractsByDiscipline
    setFilterToTableKeeper(newFilter)
  }

  const onCheckedInChange = (v: boolean) => {
    setCheckedIn(v)
    const newFilter = getCurrentFilters()
    newFilter.checkedIn = v
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onCheckedOutChange = (v: boolean) => {
    setCheckedOut(v)
    const newFilter = getCurrentFilters()
    newFilter.checkedOut = v
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onChangeStartTime = (time: moment.Moment) => {
    setRangeStartDate(time)
    const newFilter = getCurrentFilters()
    newFilter.startTime = time
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onChangeEndTime = (time: moment.Moment) => {
    setRangeEndDate(time)
    const newFilter = getCurrentFilters()
    newFilter.endTime = time
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onClose = (e: React.MouseEvent) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const onToggleClose = () => {
    setOpen((n) => {
      onOpen(false)
      return !n
    })
  }

  const filterContainerStyles = {
    background: 'rgba(0,0,0,0.3)',
    height: 'calc(100vh - 72px)',
  }

  const getRootHeight = () =>
    width >= 768 ? { height: 'auto' } : { height: 'calc(100vh - 116px' }

  return (
    <div className={styleClass.root}>
      <div onClick={() => onToggleClose()} className={styleClass.icon(open)}>
        <span className={`w-2/3 h-2/3 ${open ? 'flex' : ''}`}>
          <Icon
            icon={
              open
                ? Icon.IconType.CLOSE_WHITE
                : filtersApplied
                  ? Icon.IconType.FILTER_BLUE_FILLED
                  : Icon.IconType.FILTER_OUTLINE
            }
          />
        </span>
      </div>
      {open && (
        <div style={filterContainerStyles} className={styleClass.filter}>
          <CloseClickOutside onClose={onClose}>
            <div style={getRootHeight()} className={styleClass.rootFilter}>
              <div className={'px-5 pt-4 pb-5'}>
                <div className={styleClass.filterSelector}>
                  <div className={'w-full'}>
                    <MultiSelector
                      items={contracts}
                      onOpenList={onOpenContract}
                      loading={contractLoading}
                      onSelect={onChangeContract}
                      label={t('contracts')}
                      hidelabel={false}
                      selectedItems={selectedContracts}
                      dataFields={['contractNumber', 'contractName']}
                      fontWeight={'bold'}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                      inMobile={true}
                    />
                  </div>
                </div>
                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={disciplines}
                    onOpenList={onOpenDiscipline}
                    label={t('disciplines')}
                    hidelabel={false}
                    dataFields={['shortName', 'name']}
                    selectedItems={selectedDisciplines}
                    fontWeight={'bold'}
                    onSelect={onChangeDiscipline}
                    loading={disciplineLoading}
                    scroll={true}
                    noBorder={true}
                    bgColor={'white'}
                    inMobile={true}
                  />
                </div>

                <p
                  className={
                    'block font-medium text-sm leading-5 text-gray-700 my-3'
                  }
                >
                  {t('date_range')}
                </p>
                <div
                  className={
                    'mt-2 mb-2 mr-6 w-full flex justify-between wagonRangeFilter'
                  }
                >
                  <SingleDatePicker
                    firstDayOfWeek={1}
                    date={rangeStartDate}
                    onDateChange={onChangeStartTime}
                    renderDayContents={renderDayContents}
                    focused={focus === 'startTime'}
                    onFocusChange={(focused) =>
                      setFocus(focused.focused ? 'startTime' : '')
                    }
                    id="Starttidspunkt"
                    small={true}
                    isOutsideRange={() => false}
                    showDefaultInputIcon={true}
                    noBorder={true}
                    numberOfMonths={1}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    hideKeyboardShortcutsPanel={true}
                    placeholder={t('start_time')}
                  />

                  <SingleDatePicker
                    firstDayOfWeek={1}
                    date={rangeEndDate}
                    onDateChange={onChangeEndTime}
                    renderDayContents={renderDayContents}
                    focused={focus === 'endTime'}
                    onFocusChange={(focused) =>
                      setFocus(focused.focused ? 'endTime' : '')
                    }
                    id="Starttidspunkt"
                    small={true}
                    isOutsideRange={() => false}
                    showDefaultInputIcon={true}
                    noBorder={true}
                    numberOfMonths={1}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    hideKeyboardShortcutsPanel={true}
                    placeholder={t('end_time')}
                  />
                </div>

                <div className={styleClass.booleanFilters(false)}>
                  <div className={styleClass.switch(true)}>
                    <span className={'mr-2  text-sm font-medium text-gray-600'}>
                      {t('checked_in')}
                    </span>
                    <SwitchHOC
                      valueProp={checkedIn ? true : false}
                      className="custom-classname"
                      onChange={(v) => onCheckedInChange(v)}
                    />
                  </div>

                  <div className={styleClass.switch(true, true)}>
                    <span className={'mx-2 text-sm font-medium text-gray-600'}>
                      {t('checked_out')}
                    </span>
                    <SwitchHOC
                      valueProp={checkedOut ? true : false}
                      className="custom-classname"
                      onChange={(v) => onCheckedOutChange(v)}
                    />
                  </div>
                </div>
              </div>
              <div
                className={'w-full flex justify-between px-4 py-2 bg-gray-100'}
              >
                <Button
                  onClick={onClear}
                  type={Button.ButtonType.WARNING}
                  size={ButtonSize.XSMALL}
                  noTextWrap={true}
                  inMobile={true}
                >
                  {t('reset')}
                  <Icon
                    icon={Icon.IconType.CLOSE_GRAY}
                    className={'ml-2 w-4 h-4 flex'}
                  />
                </Button>
                <Button
                  onClick={onToggleClose}
                  type={Button.ButtonType.SUCCESS}
                  inMobile={true}
                  size={ButtonSize.XSMALL}
                  noTextWrap={true}
                >
                  {t('done')}
                  <Icon
                    icon={Icon.IconType.CHECK_WHITE_CIRLCE}
                    className={'ml-2 w-4 h-4 flex'}
                  />
                </Button>
              </div>
            </div>
          </CloseClickOutside>
        </div>
      )}
    </div>
  )
}

export default MWagonFilter
