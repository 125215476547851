import moment from 'moment'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ITestExecution } from 'src/service/OrgTypes'
import { getInfoWrapperStyle } from '../MUtils'

interface MTestExecutionProps {
  execution: ITestExecution
}

const MTestExecution: FC<MTestExecutionProps> = ({ execution }) => {
  const { t } = useTranslation()
  return (
    <div className={getInfoWrapperStyle(true, null, true, true)}>
      <div className={`w-full border-t-green-300 pl-2`}>
        <details className={'-ml-2 border border-gray-300 rounded-md py-1.5'}>
          <summary className={'font-semibold text-gray-600 text-xs px-3'}>{`${t(
            'test_execution',
          )}`}</summary>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>{t('id')}</div>
            <div className={'w-3/4 font-light pl-2'}>{execution.record_id}</div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>{t('title')}</div>
            <div className={'w-3/4 font-light pl-2'}>{execution.test.name}</div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>{t('status')}</div>
            <div className={'w-3/4 font-light pl-2'}>{t(execution.status)}</div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('description')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>
              {execution.test.description}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('planned_start')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>
              {moment(execution.test_planned_start).format('L')}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('planned_end')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>
              {moment(execution.test_planned_end).format('L')}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('meeting_place')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>{execution.location}</div>
          </div>
        </details>
      </div>
    </div>
  )
}

export default MTestExecution
