import * as React from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import TokenService from 'src/service/TokenService'
import useAlert from 'src/ui-elements/toast/useAlert'

const NetworkInterceptor = (props: React.PropsWithChildren<any>) => {
  const { fetch: origFetch } = window
  const { t } = useTranslation()
  const alertShowing = useRef<string[]>([])

  const { showError } = useAlert()

  const removeText = (text: string) => {
    const index = alertShowing.current.indexOf(text)
    if (index > -1) {
      alertShowing.current.splice(index, 1)
    }
  }

  const fireAlert = (title: string, description: string) => {
    if (alertShowing.current.indexOf(description) < 0) {
      showError(title, description)
      alertShowing.current.push(description)
      setTimeout(() => removeText(description), 10000)
    }
  }

  const getErrorMessage = (body: any): string[] => {
    const { error_code, errors, body: b, message } = body
    const message_error = b?.message?.error_keys

    if (errors && Array.isArray(errors)) {
      return errors.map((error) => t(`${error.attribute}_${error.type}`))
    } else if (message_error && Array.isArray(message_error)) {
      return message_error.map((error) => t(error))
    } else if (error_code) {
      return [t(error_code)]
    } else if (message) {
      return [t(message)]
    } else {
      return [t('something_went_wrong')]
    }
  }

  const checkIfStatusIsOk = async (res: Response) => {
    if (res.status === 401) {
      TokenService.getAccessToken(true).then(() => {
        window.location.reload()
      })
    } else if (res.status === 403) {
      fireAlert(t('no_access'), t('you_do_not_have_access_to_perform_this'))
    } else if (res.status >= 400 && res.status < 500) {
      const body = await res.clone().json()
      const errors = getErrorMessage(body)
      errors.forEach((error) => {
        fireAlert(t('an_error_occurred'), t(error))
      })
    }
  }

  window.fetch = async (...args) => {
    const response = await origFetch(...args)
    checkIfStatusIsOk(response)
    return response
  }

  return <>{props.children}</>
}

export default NetworkInterceptor
