import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportContext } from 'src/context/ImportContext/ImportContext'
import { classNames } from 'src/utility/utils'
import ImportNotificationItem from './ImportNotificationItem'

const styleClass = {
  row: classNames('hover:bg-grey-lightest'),
  badge: classNames(
    'rounded-full ',
    'bg-red-500',
    'text-xs',
    'text-white',
    'font-bold',
    'px-1',
  ),
  img: classNames('rounded-full', 'inline', 'h-6', 'w-6'),
  dropDownContainer: classNames(
    'shadow-md',
    'topmenu-dropdown',
    'inline-block',
    'bg-white',
    'inset-x-0',
    'absolute',
    'overflow-hidden',
  ),
}

const ImportNotifications = () => {
  const { t } = useTranslation()

  const { state, actions } = useContext(ImportContext)
  const { importTasks } = state
  const { setSelectedItem, removeImportTask } = actions

  return (
    <div
      className="notifsCont overflow-y-auto p-6 pt-0"
      style={{
        height: 'calc(100% - 98px)',
        maxHeight: 'calc(100vh - 98px)',
      }}
    >
      <div className="flex flex-col gap-4">
        {importTasks.map((activity) => {
          return (
            <div className={styleClass.row} key={activity.id}>
              <ImportNotificationItem
                item={activity}
                onClick={() => setSelectedItem(undefined, activity)}
                remove={() => removeImportTask(activity.id)}
              />
            </div>
          )
        })}
      </div>
      {importTasks.length === 0 && (
        <div className="flex justify-center items-center w-full min-h-12">
          <p className="text-gray-500 font-thin text-sm italic">
            {t('no_unread_notifications')}
          </p>
        </div>
      )}
    </div>
  )
}

export default ImportNotifications
