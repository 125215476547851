import Cancel from '@icons/cancel.svg'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment/moment'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import UploadProvider from 'src/document/components/FileUpload/context/FileUploadContext'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import FileList from 'src/fdvu-subcontractor/components/requirements/FileList'
import FileUpload from 'src/fdvu-subcontractor/components/requirements/FileUpload'
import { getAccessToken } from 'src/fdvu-subcontractor/utils/AccessToken'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import InlineInputLabelComponent from 'src/ui-elements/page-display/inline-components/InlineInputLabelComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { StatusEnum } from 'src/utility/statusEnums'

interface IFileSubmissionPanel {
  selectedFileContainer: IFdvuFileContainer
  hideCompleted: boolean
}

const FileSubmissionPanel = ({
  selectedFileContainer,
  hideCompleted,
}: IFileSubmissionPanel) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const refresh = () => {
    queryClient.invalidateQueries({
      queryKey: ['fdvu', 'subcontractor', 'fileContainers', hideCompleted],
    })
  }

  const declinedHistory = useMemo(() => {
    const declinedRequirements = selectedFileContainer.requirements.filter(
      (req) => req.isDeclined,
    )
    return declinedRequirements.flatMap((req) =>
      req.declinedHistory.map((hist) => ({
        ...hist,
        requirementName: req.title,
      })),
    )
  }, [selectedFileContainer])

  const title = (
    <div className="flex justify-center bg-grey-light p-1 rounded-t-sm text-sm font-bold">
      <div className="max-w-74 truncate">{`${selectedFileContainer?.recordId ?? ''} ${selectedFileContainer?.name ?? ''}`}</div>
    </div>
  )

  const isOpen = [StatusEnum.OPEN, StatusEnum.DECLINED].includes(
    selectedFileContainer.status,
  )

  return (
    <div className="w-1/3">
      <div className="bg-white border rounded-sm h-full flex flex-col">
        {title}
        <InlineComponentsWrapper
          padding="left"
          labelWidth={'w-32'}
          inputWidth={'w-full'}
        >
          <TextInlineInputCompontent
            label="covers_requirements"
            value={selectedFileContainer.requirements
              .map((req) => req.title)
              .join(', ')}
            disabled
          />
          <TextInlineInputCompontent
            label="systems"
            value={selectedFileContainer.systems
              .map((system) => system.recordId)
              .join(', ')}
            disabled
          />
        </InlineComponentsWrapper>
        {declinedHistory.length ? (
          <InlineComponentsWrapper padding="left" labelWidth={'w-16'}>
            <InlineInputLabelComponent label={'history'} />
          </InlineComponentsWrapper>
        ) : (
          <></>
        )}
        {declinedHistory.map((history) => (
          <div
            key={history.id}
            className="overflow-hiddenfont-medium text-sm mx-2"
          >
            <div className={'flex items-center gap-2'}>
              <Cancel className={`text-xl fill-red`} />
              <p className={`font-bold text-red`}>{t('declined')}</p>
              <p>{`${moment(history.date).format('L')} ${t('by')} ${history.user.firstName} ${history.user.lastName}`}</p>
            </div>
            <InlineComponentsWrapper
              padding="left"
              labelWidth={'w-16'}
              inputWidth={'w-full'}
            >
              <TextInlineInputCompontent
                label="requirement"
                value={history.requirementName}
                disabled
              />
              <TextInlineInputCompontent
                label="reason"
                value={history.reason}
                disabled
              />
            </InlineComponentsWrapper>
          </div>
        ))}
        <UploadProvider>
          <FileUpload
            multiple={true}
            uploadUrl={`{FDVU_URL}subcontractor/files/file-container-id/${selectedFileContainer?.id}/step-id/${selectedFileContainer?.currentStepId}`}
            accessToken={getAccessToken()}
            onUploadComplete={refresh}
            disabled={!isOpen}
          />
        </UploadProvider>
        {selectedFileContainer && (
          <FileList
            files={selectedFileContainer.projectFiles}
            refreshFileContainer={refresh}
            disableDeleteFile={!isOpen}
          />
        )}
      </div>
    </div>
  )
}

export default FileSubmissionPanel
