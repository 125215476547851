import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { classNames } from '../../utility/utils'

interface IMSearchBoxProps {
  value: string
  onChange: (e?: any) => void
  onSubmit: (e?: any) => void
  handleQR: () => void
  placeholder: string
}

const MSearchBox = ({
  value,
  onChange,
  onSubmit,
  placeholder,
  handleQR,
}: IMSearchBoxProps) => {
  const styleClass = {
    root: classNames(
      'p-1',
      'h-10',
      'rounded-full',
      'shadow-md',
      'flex',
      'bg-white',
    ),
    form: classNames('w-full', 'flex'),
    input: classNames(
      'w-full',
      'outline-none',
      'px-1',
      'text-xs',
      'font-light',
      'text-gray-600',
    ),
    btn: classNames(
      'bg-blue-root',
      'rounded-full',
      'font-light',
      'text-xs',
      'text-white',
      'h-8',
      'px-2',
    ),
    img: classNames('transform', 'scale-75'),
  }

  const { t } = useTranslation()

  const onQRClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    handleQR()
  }

  return (
    <div className={styleClass.root}>
      <form className={styleClass.form} onSubmit={onSubmit}>
        <img
          width={'auto'}
          height={'100%'}
          className={styleClass.img}
          src="/icons/search-gray.svg"
          alt=""
        />
        <input
          value={value}
          className={styleClass.input}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
        />
        <button className={styleClass.btn}>{t('search')}</button>
      </form>
      <button
        onClick={(e) => onQRClick(e)}
        className={'ml-2 mr-1 px-0.5 border-blue-root border rounded-lg'}
      >
        <Icon icon={Icons.QR} className={'h-6 w-6'} />
      </button>
    </div>
  )
}

export default MSearchBox
