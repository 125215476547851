import { IKeypoint, IMilestone } from 'src/service/OrgTypes'
import { request } from './service-utils'

export const createMilestone = (
  milestone: IMilestone,
  projectId: number,
): Promise<any> => {
  return request(`projects/${projectId}/mile_stones`, 'POST', milestone)
}

export const editMilestone = (milestone: any): Promise<any> => {
  return request(`mile_stones/${milestone.id}`, 'PUT', milestone)
}

export const deleteMilestone = (milestone: IMilestone): Promise<any> => {
  return request(`mile_stones/${milestone.id}`, 'DELETE', {}, true)
}

export const getMilestone = (
  projectId: number,
  milestoneId: number,
): Promise<IMilestone> => {
  return request(`projects/${projectId}/mile_stones/${milestoneId}`, 'GET')
}

export const getMilestonesTree = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/mile_stones/tree`, 'GET')
}

export const getProjectMilestones = (
  projectId: number,
): Promise<IMilestone[]> => {
  return request(`projects/${projectId}/mile_stones`, 'GET')
}

export const getProductionMileStone = (
  projectId: number,
): Promise<IMilestone[]> => {
  return request(`projects/${projectId}/production_mile_stones`, 'GET')
}

export const getProjectMilestonesPaginated = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/mile_stones/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getMainprocessMilestoneCanvasData = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/mile_stone_main_process_canvas`,
    'POST',
    data,
  )
}

export const deleteBulkMilestones = (
  projectId: number,
  data: number[],
): Promise<any> => {
  return request(`projects/${projectId}/mile_stones/remove_multiple`, 'POST', {
    ids: data,
  })
}

export const getMilestoneTemaCanvasData = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(`projects/${projectId}/mile_stone_team_canvas`, 'POST', data)
}

export const getProjectSuperListPaginated = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/super_table/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getFloatingMilestonesKeypoints = (
  milestoneId: number,
): Promise<IKeypoint[]> => {
  return request(`/mile_stones/${milestoneId}/floating_key_points`, 'GET')
}

export const getFloatingMilestonesUserKeypoints = (
  userId: number,
  milestoneId: number,
): Promise<IKeypoint[]> => {
  return request(
    `/users/${userId}/mile_stones/${milestoneId}/floating_key_points`,
    'GET',
  )
}
