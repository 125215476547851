import DataSelectors from 'src/document/components/FileUpload/selectors/DataSelectors'
import { IFormatData } from 'src/document/components/FileUpload/types'

export interface Template {
  name: string
  required: boolean
  value: string
  description: string
  example: string
}

export interface ImportTemplate {
  name: string
  displayName: string
  columns: string[]
  templateColumns: string[]
  template: Template[]
}

export const validateRowForImport = (
  key: IFormatData,
  header: string,
  value: string,
  da: IFormatData[] = [],
) => {
  const index = da.indexOf(key) ?? -1
  if (index === -1) return
  return da.map((fd: any, i: number) => {
    const data = {}
    Object.keys(fd).forEach((d: string) => {
      const newValue = i === index && d === header
      const headerMap = DataSelectors.getMapperFromHeader(d) ?? d
      const _v = fd[d]?.value
      data[headerMap] = newValue
        ? value
        : typeof _v === 'object' && _v
          ? _v['id']
          : _v
    })
    if (fd['user_defined_values']) {
      data['optional_fields'] = fd['user_defined_values']
      delete data['user_defined_values']
    }
    return data
  })
}

export const translateTemplate = (
  template: ImportTemplate,
  translate: any,
): ImportTemplate => {
  const newTemplate: ImportTemplate = { ...template }
  newTemplate.displayName = translate(newTemplate.displayName)
  newTemplate.columns = newTemplate.columns.map(translate).map(capitalize)
  newTemplate.templateColumns = newTemplate.templateColumns
    .map(translate)
    .map(capitalize)
  newTemplate.template = _translateTemplateExample(
    newTemplate.template,
    translate,
  )
  return newTemplate
}

const _translateTemplateExample = (
  tEg: Template[],
  translate: any,
): Template[] => {
  return tEg.map((eg: Template) => {
    const newEg = { ...eg }
    newEg.name = capitalize(translate(newEg.name))
    newEg.value = capitalize(translate(newEg.value))
    newEg.description = capitalize(translate(newEg.description))
    return newEg
  })
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
