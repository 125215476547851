import * as React from 'react'
import { useEffect, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import AssignedFileContainerList from 'src/fdvu-subcontractor/components/requirements/AssignedRequirementList'
import FileSubmissionPanel from 'src/fdvu-subcontractor/components/requirements/FileSubmissionPanel'
import { SplashScreenUtil } from 'src/page/splash/splashScreenStore'
import { useSubcontractorFileContainers } from 'src/query/fdvu/subcontractor'

const FdvuSubcontractorPage: FC = () => {
  const { t } = useTranslation()
  const [hideCompleted, setHideCompleted] = useState<boolean>(true)

  const splashScreenUtil = SplashScreenUtil()

  const [selectedFileContainer, setSelectedFileContainer] =
    useState<IFdvuFileContainer>()

  const { data = [], isLoading } = useSubcontractorFileContainers(hideCompleted)

  useEffect(() => {
    const updatedFileContainer = data.find(
      (fc) => fc.id === selectedFileContainer?.id,
    )
    setSelectedFileContainer(updatedFileContainer ?? data[0])
  }, [data])

  useEffect(() => {
    if (!isLoading) splashScreenUtil.removeSplashScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div className="flex flex-col justify-between text-gray-700 h-full">
      <div className="flex justify-between items-end m-4">
        <div className="flex items-center gap-1 text-sm">
          {t('hide_completed_requirements')}
          <SwitchHOC valueProp={hideCompleted} onChange={setHideCompleted} />
        </div>
      </div>
      <div className={'flex m-2 gap-2 h-full'}>
        <div className={'w-2/3'}>
          <p
            className={
              'flex px-2 py-1 font-bold bg-grey-light rounded-sm text-sm first-capitalize'
            }
          >
            {t('documents')}
          </p>
          <AssignedFileContainerList
            selectedFileContainerId={selectedFileContainer?.id}
            setSelectedFileContainer={setSelectedFileContainer}
            hideCompleted={hideCompleted}
          />
        </div>
        {selectedFileContainer && (
          <FileSubmissionPanel
            selectedFileContainer={selectedFileContainer}
            hideCompleted={hideCompleted}
          />
        )}
      </div>
    </div>
  )
}

export default FdvuSubcontractorPage
