import moment from 'moment'
import * as React from 'react'
import Selector from 'src/components/selectors/Selector'
import { capFirstLetter, classNames } from '../../utility/utils'
import Badge from '../badge/Badge'
import { Icons } from '../icon/Icon'
import { PageDisplayStyleClass } from './pageUtils'

interface ISelectorPageDisplayProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onSelect: (id: number | string | any) => void
  items: any[]
  selectedItemId: number | string
  onOpenSelectFunction?: () => void
  label: string
  dataFields: any[]
  disabled?: boolean
  fontSize?: string
  fontWeight?: string
  required?: boolean
  errorMessage?: string
  icon?: Icons
  userSelector?: boolean
  loading?: boolean
  cancelButton?: boolean
  hasBorder?: boolean
  scroll?: boolean
  onCancel?: () => void
  border?: boolean
  showIcon?: boolean
  inTopMenu?: boolean
  editingMode: boolean
  displayData: string
  link?: string
  isStatus?: boolean
  deadline?: moment.Moment
  grayFill?: boolean
  spansTwoColumns?: boolean
  isCleared?: boolean
}

export default class SelectorPageDisplay extends React.PureComponent<
  ISelectorPageDisplayProps,
  any
> {
  private styleClass = {
    placeholder: classNames(
      'text-gray-400',
      'font-normal',
      'text-sm',
      'lowercase',
    ),
    ...PageDisplayStyleClass,
  }

  public render() {
    const {
      onSelect,
      items,
      label,
      selectedItemId,
      dataFields,
      fontSize,
      fontWeight,
      required,
      errorMessage,
      icon,
      loading,
      scroll,
      hasBorder,
      onOpenSelectFunction,
      disabled,
      userSelector,
      cancelButton,
      onCancel,
      border,
      showIcon,
      inTopMenu,
      editingMode = false,
      displayData,
      isStatus,
      deadline,
      grayFill = false,
      spansTwoColumns = false,
      isCleared,
      link,
    } = this.props

    return (
      <div
        className={this.styleClass.tableRow(
          grayFill,
          editingMode,
          spansTwoColumns,
        )}
      >
        <dt className={this.styleClass.tableLeftText(editingMode)}>
          {capFirstLetter(label)}
          {required && editingMode ? ' *' : ''}
          {errorMessage && editingMode && (
            <span className={this.styleClass.errorMessage}>{errorMessage}</span>
          )}
        </dt>
        <dd className={this.styleClass.tableCellRight(editingMode)}>
          {editingMode ? (
            <Selector
              onSelect={onSelect}
              items={items}
              label={label}
              selectedItemId={selectedItemId}
              dataFields={dataFields}
              fontSize={fontSize}
              fontWeight={fontWeight}
              required={required}
              icon={icon}
              loading={loading}
              scroll={scroll}
              hasBorder={hasBorder}
              onOpenSelectFunction={onOpenSelectFunction}
              disabled={disabled}
              userSelector={userSelector}
              cancelButton={cancelButton}
              onCancel={onCancel}
              border={border}
              showIcon={showIcon}
              hidelabel={true}
              inTopMenu={inTopMenu}
              isCleared={isCleared}
            />
          ) : isStatus ? (
            <Badge
              size={Badge.BadgeSize.LARGE}
              text={displayData !== '' ? displayData : 'N/A'}
              color={
                selectedItemId === 'done' ||
                selectedItemId === 'closed_risk_occured' ||
                selectedItemId === 'closed_risk_not_occured'
                  ? Badge.BadgeColor.GREEN
                  : deadline && moment(deadline).isBefore(moment(), 'day')
                    ? Badge.BadgeColor.RED
                    : Badge.BadgeColor.BLUE
              }
            />
          ) : (
            <span className={this.styleClass.displayText}>
              {(link ? (
                <a
                  className="underline text-blue-root text-gray-500"
                  href={link}
                >
                  {' '}
                  {displayData}{' '}
                </a>
              ) : (
                displayData
              )) || <span className={this.styleClass.placeholder} />}
            </span>
          )}
        </dd>
      </div>
    )
  }
}
