import { noop } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import InspectorRoot from 'src/document/components/Inspector/InspectorRoot'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import { useEditWagonType, useWagonType } from 'src/query/construction/queries'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { IWagonType } from 'src/service/OrgTypes'
import { getProjectTrainTypes } from 'src/service/TrainTypeService'
import { Icons } from 'src/ui-elements/icon/Icon'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import DateTimeInlineInputComponent from '../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import { toFixed } from '../../../utility/Utility'
import CleanZone from '../util/CleanZone'

interface IWagonTypeInspectorPanel {
  wagonId: number
  open: boolean
  onClose: () => void
  onUpdate: () => void
  projectId: number
}

const WagonTypeInspectorPanel: React.FC<IWagonTypeInspectorPanel> = ({
  wagonId,
  open,
  onClose,
  onUpdate,
  projectId,
}) => {
  const { t } = useTranslation()
  const { data: defaultWagonType } = useWagonType(wagonId)
  const editWagonType = useEditWagonType()

  const sections: IInspectorSection[] = [
    {
      name: t('activities'),
      icon: Icons.ACTIVITY_GREY,
      activeIcon: Icons.ACTIVITY,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('title'),
            t('discipline'),
            t('working_hours'),
            t('duration_in_hours'),
            t('number_of_workers'),
          ]}
          rowsData={
            defaultWagonType?.construction_task_types
              ? defaultWagonType.construction_task_types.map((activityType) => {
                  return {
                    cells: [
                      activityType?.title ?? '',
                      activityType?.discipline
                        ? activityType?.discipline?.shortName +
                          ' ' +
                          activityType?.discipline?.name
                        : '',
                      activityType.total_work_hours
                        ? toFixed(
                            activityType.total_work_hours /
                              activityType.productivity,
                          )
                        : 0,
                      toFixed(activityType.planned_execution_hours),
                      toFixed(activityType.number_of_workers),
                    ],
                  }
                })
              : []
          }
        />
      ),
    },
  ]

  const onChangeInput = async (update: Partial<IWagonType>) => {
    editWagonType
      .mutateAsync({
        ...convertUndefinedToNull(update),
        id: defaultWagonType?.id,
      })
      .then(() => {
        onUpdate()
      })
  }

  return (
    <InspectorRoot
      title={defaultWagonType?.title ?? t('loading')}
      show={open}
      onClose={() => onClose()}
      hasTable={false}
      className={'w-[600px]'}
      detailPageData={{
        key: DetailPageKeys?.WAGON_TYPE,
        ids: {
          trainTypeId: defaultWagonType?.train_type_id,
          wagonTypeId: defaultWagonType?.id,
        },
      }}
    >
      <div>
        <InlineComponentsWrapper padding="left" inputWidth={'w-[400px]'}>
          <TextInlineInputCompontent
            label={t('title')}
            value={defaultWagonType?.title}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('fill_out_w_param', { param: t('title') })
              }
              return
            }}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ title: newValue })
            }}
          />

          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={defaultWagonType?.discipline}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={defaultWagonType?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              onChangeInput({ discipline_id })
            }}
            dependencies={[defaultWagonType?.contract_id]}
            inspectorPanel={true}
          />
          <TextInlineInputCompontent
            label={t('duration_days')}
            value={`${defaultWagonType?.duration ?? 0}`}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              if (!newValue || +newValue <= 0) {
                return t('duration_must_be_greater_than_0')
              }
              return
            }}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ duration: +newValue })
            }}
          />
          {defaultWagonType && defaultWagonType.train_type_id && (
            <SelectorInlineInputComponent
              getItems={() => getProjectTrainTypes(projectId)}
              label={t('train')}
              initialItem={defaultWagonType?.train_type}
              getItemLabel={(trainType) => `${trainType?.title}`}
              selectedId={defaultWagonType?.train_type_id}
              onValueSubmitted={(train_type_id) => {
                onChangeInput({ train_type_id })
              }}
              inspectorPanel={true}
            />
          )}

          <TextInlineInputCompontent
            label={t('working_hours')}
            value={`${defaultWagonType?.planned_execution_hours ?? 0}`}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return
            }}
            onValueSubmitted={(plannedExecutionHours) => {
              if (plannedExecutionHours)
                onChangeInput({
                  planned_execution_hours: +plannedExecutionHours,
                })
            }}
            disabled={true}
          />

          <TextInlineInputCompontent
            label={t('average_staffing')}
            value={`${defaultWagonType?.planned_staffing ?? 0}`}
            onValueSubmitted={(plannedStaffing) => {
              if (plannedStaffing)
                onChangeInput({ planned_staffing: +plannedStaffing })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return
            }}
            disabled={true}
          />

          <SelectorInlineInputComponent
            items={CleanZone.info(t)}
            label={t('clean_status')}
            onValueSubmitted={(clean_status) => {
              onChangeInput({ clean_status: CleanZone.zone(clean_status) })
            }}
            getItemLabel={(item) => CleanZone.text(t, item?.id)}
            selectedId={defaultWagonType?.clean_status as any}
            initialItem={CleanZone.selected(t, defaultWagonType?.clean_status)}
            cancelButton={true}
            inspectorPanel={true}
          />

          <TextInlineInputCompontent
            label="description"
            value={defaultWagonType?.subject}
            onValueSubmitted={(subject) => {
              if (subject) onChangeInput({ subject })
            }}
            textArea
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={defaultWagonType?.created_at}
            onValueSubmitted={noop}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={defaultWagonType?.updated_at}
            onValueSubmitted={noop}
            disabled={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
        <InspectorSections sections={sections} />
      </div>
    </InspectorRoot>
  )
}
export default WagonTypeInspectorPanel
