import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  ITabContext,
  TabContext,
} from 'src/context/TabContextProvider/TabContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { ISystem } from 'src/service/OrgTypes'
import { getSystem } from 'src/service/SystemService'
import Badge from 'src/ui-elements/badge/Badge'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { capFirstLetter } from '../../utility/utils'
import { contentStyle, FullPageLoader } from '../MUtils'
import MCard from '../components/MCard'
import MChips from '../components/MChips'
import MContent from '../components/MContent'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MTabs, { IMTabItem } from '../components/MTabs'
import MCustomIssues, { CustomIssueTypes } from './MCustomIssues'

interface ILocation {
  fromHome: boolean
}

const MSystem = (_props: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userContext: IUserContext = useContext(UserContext)
  const { user } = userContext.state
  const { sid } = useParams<{ sid: string }>()
  const location = useLocation<ILocation>()
  const [loading, setLoading] = useState<boolean>(false)
  const [systemRes, setSystemRes] = useState<ISystem>({} as ISystem)
  const [systemData, setSystemData] = useState<any>({})
  const [showBackBtn, setShowBackBtn] = useState<boolean>(true)
  const tabContext: ITabContext = useContext(TabContext)
  const { tabIndex } = tabContext.state
  const { setCurrentIndex } = tabContext.actions

  useEffect(() => {
    fetchSystem(sid)
    const showBtn = location?.state?.fromHome ? false : true
    setShowBackBtn(showBtn)
  }, [sid])

  const fetchSystem = async (id: string) => {
    if (id && +id > 0) {
      setLoading(true)
      const system = await getSystem(+id)
      setSystemRes(system)
      const systemDataArr = [
        { id: system.id },
        { left: t('system_number'), right: `${system?.record_id}` },
        { left: t('system_name'), right: `${system?.name}` },
        { left: t('description'), right: `${system?.description}` },
        { left: t('system_located'), right: `${system?.room?.room_name}` },
        {
          left: t('disciplines'),
          right: `${system?.disciplines
            ?.map(
              (discipline) => `${discipline?.shortName} ${discipline?.name}`,
            )
            .join(',')}`,
        },
        {
          left: t('contracts'),
          right: `${system?.contracts
            ?.map(
              (contract) =>
                `${contract?.contractNumber} ${contract?.contractName}`,
            )
            .join(',')}`,
        },
      ]
      setSystemData(systemDataArr)
      setLoading(false)
    }
  }

  const SystemDetail = () =>
    loading ? (
      <FullPageLoader />
    ) : (
      <MContent>
        <>
          {!isEmpty(systemRes) ? (
            <div className={'p-6 pt-4'}>
              {systemData ? (
                <>
                  <p className={'text-gray-600 text-sm pt-4 mb-2'}>
                    {capFirstLetter(t('system'))}
                  </p>
                  <MCard data={systemData} header={getHeader()} />
                </>
              ) : null}
            </div>
          ) : (
            <div className={'flex justify-center m-8'}>
              <Badge
                text={t('no_results_found')}
                size={Badge.BadgeSize.LARGE}
                color={Badge.BadgeColor.TEAL}
              />
            </div>
          )}
        </>
      </MContent>
    )

  const getHeader = (): JSX.Element => {
    return (
      <div className={'flex justify-end mb-2'}>
        <MChips text={capFirstLetter(t('system'))} color={'green'} />
      </div>
    )
  }

  const tabItems: IMTabItem[] = [
    {
      name: t('system'),
      content: <SystemDetail />,
      icon: 'SYSTEM_GRAY',
      activeIcon: 'SYSTEM_BLUE',
      disabledIcon: 'SYSTEM_GRAY',
    },
    {
      name: t('cases_native'),
      content: (
        <MCustomIssues parentId={+sid} parentType={CustomIssueTypes.System} />
      ),
      icon: 'ISSUE',
      activeIcon: 'ISSUE_ACTIVE',
      disabledIcon: 'ISSUE_DISABLED',
    },
  ]

  return (
    <MPage>
      <>
        <MHeader
          onBackClick={showBackBtn ? () => history.goBack() : undefined}
          title={t('system_details')}
          user={user}
        />
        <div
          style={tabIndex >= 0 ? contentStyle : {}}
          className={`w-full ${tabIndex >= 0 ? 'absolute bottom-0 z-20' : ''}`}
        >
          <MTabs
            tabIndex={tabIndex}
            tabItems={tabItems}
            setTabIndex={(index) => setCurrentIndex(index)}
          />
        </div>
      </>
    </MPage>
  )
}

export default MSystem
