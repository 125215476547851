import { useTranslation } from 'react-i18next'
import { IRoom } from '../../service/OrgTypes'
import { capFirstLetter } from '../../utility/utils'
import { getInfoWrapperStyle } from '../MUtils'
import FloatingButton from '../components/FloatingButton'

interface IMRoomInfo {
  room: IRoom
  noPadding?: boolean
  isCard?: boolean
  onFloatingBtnClick?: () => void
  sticky?: boolean
}

const MRoomInfo = ({
  room,
  noPadding = false,
  isCard = false,
  onFloatingBtnClick,
  sticky = true,
}: IMRoomInfo) => {
  const { t } = useTranslation()

  return (
    <div
      className={getInfoWrapperStyle(
        noPadding,
        onFloatingBtnClick,
        isCard,
        sticky,
      )}
    >
      {onFloatingBtnClick && (
        <FloatingButton floating={false} onClick={onFloatingBtnClick} />
      )}
      <div className={`w-full border-t-green-300 pl-2`}>
        <details className={'-ml-2 border border-gray-300 rounded-md py-1'}>
          <summary className={'font-semibold text-gray-600 text-xs px-2'}>
            {t('room_details')}
          </summary>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/3 font-medium capitalize'}>{t('room')}</div>
            <div className={'w-2/3 break-all font-light pl-2'}>
              {room?.room_name ?? ''}{' '}
              {room?.room_function_number ? room?.room_function_number : ''}
            </div>
          </div>
          {room?.section && (
            <div
              className={
                'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
              }
            >
              <div className={'w-1/3 font-medium'}>
                {capFirstLetter(t('section'))}
              </div>
              <div className={'w-2/3 break-all font-light pl-2'}>
                {room?.section?.name ?? ''}-
                {room?.section?.section_number ?? ''}
              </div>
            </div>
          )}

          {room?.floor && (
            <div
              className={
                'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
              }
            >
              <div className={'w-1/3 font-medium capitalize'}>{t('floor')}</div>
              <div className={'w-2/3 break-all font-light pl-2'}>
                {room?.floor?.name ?? ''}-{room?.floor?.floor_number ?? ''}
              </div>
            </div>
          )}
        </details>
      </div>
    </div>
  )
}

export default MRoomInfo
