import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useQuery = () => {
  const location = useLocation()

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  )

  return { location, searchParams }
}
