export const constructionTaskListExportTemplate = (url: string) => {
  return {
    geniusApiRequest: {
      url: `{organizationApiBaseUrl}${url}/construction_tasks/pages/1/1000`,
      method: 'POST',
    },
    name: 'ConstructionTasks',
    displayName: 'Aktivitet',
    columns: [
      'ID',
      'Tittel',
      'Tog',
      'Vogn',
      'Frist',
      'Status',
      'Disiplin',
      'Kontrakt',
      'Enhet',
      'Mengde',
      'Timer/enhet',
      'Produktivitetsfaktor',
      'Arbeidstimer',
      'Varighet (timer)',
      'Bemanning',
      'Opprettet',
      'Sist endret',
    ],
    rows: [
      'record_id',
      'title',
      'construction_locomotive.construction_train.title',
      'construction_locomotive.title',
      'deadline',
      'status',
      'discipline.shortName||discipline.name',
      'contract.contractNumber||contract.contractName',
      'unit',
      'total_units',
      'hours_per_unit',
      'productivity',
      'total_work_hours',
      'planned_execution_hours',
      'number_of_workers',
      'created_at',
      'updated_at',
    ],
  }
}
