import { PropsWithChildren } from 'react'
import { classNames } from '../../utility/utils'

interface IModalFooterProps {
  positionAbsolute?: boolean
  alignLeft?: boolean
  hide?: boolean
  className?: string
}

const styleClass = {
  root: (absolute: boolean, right: boolean, hide: boolean, className: string) =>
    classNames(
      absolute ? 'absolute' : 'sticky',
      'w-full',
      'bottom-0',
      'left-0',
      'bg-white',
      right ? 'flex justify-end py-4' : 'pb-4',
      className,
      hide && 'hidden',
      'flex',
      'gap-x-2',
    ),
}

const ModalFooter = ({
  positionAbsolute = false,
  alignLeft = false,
  children,
  hide = false,
  className = '',
}: PropsWithChildren<IModalFooterProps>) => {
  return (
    <div
      className={styleClass.root(positionAbsolute, !alignLeft, hide, className)}
    >
      {children}
    </div>
  )
}

export default ModalFooter
