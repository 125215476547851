export const objectTypeExportTemplate = (
  systemSettingId: number,
  categoryId?: number,
) => {
  const systemTypeUrl = categoryId
    ? `system_syntax_categories/${categoryId}`
    : `system_settings/${systemSettingId}`
  return {
    geniusApiRequest: {
      url: `{organizationApiBaseUrl}projects/{projectId}/${systemTypeUrl}/system_types/pages/0/0`,
      method: 'POST',
    },
    name: 'object types',
    displayName: 'object types',
    columns: [
      'type_code',
      'name',
      'description',
      'metadata',
      'createdAt',
      'updatedAt',
    ],
    rows: [
      'type_code',
      'name',
      'description',
      'system_type_group.name',
      'created_at',
      'updated_at',
    ],
  }
}
