import Close from '@icons/close.svg'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { SingleDatePicker } from 'react-dates'
import { classNames } from 'src/utility/utils'
import { renderDayContents } from '../../utility/Utility'

export interface ITableDatePickerProps {
  rowId: number
  date: string | null
  onDateSubmit: (date: moment.Moment | null, key: any) => void
  disabled?: boolean
  validRange?: (date: moment.Moment, id?: number) => boolean
  inModal?: boolean
  displayContent?: any
  nullable?: boolean
  deadline?: boolean
}

interface IFloatLabel {
  left: number
  top: number
}

const TableDatePicker = ({
  rowId,
  date,
  onDateSubmit,
  disabled,
  validRange,
  inModal,
  displayContent,
  nullable = false,
  deadline = false,
}: ITableDatePickerProps) => {
  const [open, setOpen] = useState(false)
  const [focused, setFocused] = useState(true)
  const [floatValue, setFloatValue] = useState<IFloatLabel>({ left: 0, top: 0 })
  const [openDirection, setOpenDirection] = useState<'down' | 'up'>('down')

  const setDatepickerFocus = (focused: any) => {
    setFocused(focused.focused)
  }

  const onDateRange = (date: moment.Moment): boolean => {
    if (validRange) {
      return validRange(date, rowId)
    }

    return false
  }

  const toggleDate = (e: any) => {
    e.stopPropagation()
    setFocused(true)
    if (window.innerHeight - e.target.getBoundingClientRect().top < 380) {
      setOpenDirection('up')
    }

    if (!open && inModal) {
      const target = e.target.getBoundingClientRect()
      const tmpFloatLabel = { ...floatValue }
      tmpFloatLabel.left = target.x - 10
      tmpFloatLabel.top = target.top - 5
      setFloatValue(tmpFloatLabel)
      setOpen(disabled ? false : !open)
    } else {
      setOpen(disabled ? false : !open)
    }
  }

  useEffect(() => {
    if (!open) {
      setOpenDirection('down')
    }
  }, [open])

  const onDateClick = (date: moment.Moment) => {
    if (!date) return
    setOpen(false)
    onDateSubmit(date, rowId)
  }

  return (
    <div className={'w-full'}>
      {!open ? (
        <div
          className={'min-w-full min-h-full cursor-pointer'}
          onClick={toggleDate}
        >
          {displayContent ? (
            displayContent
          ) : date != null ? (
            <span
              className={classNames(
                deadline && moment(date).isBefore(moment()) && 'text-red',
                'flex justify-between',
              )}
            >
              {moment(date).format('L')}
              {nullable && date && (
                <Close
                  className="mr-2 fill-red text-lg"
                  onClick={(e) => {
                    e.stopPropagation()
                    onDateSubmit(null, rowId)
                  }}
                />
              )}
            </span>
          ) : (
            '__'
          )}
        </div>
      ) : (
        <>
          <div
            className={'fixed inset-x-0 top-0 z-10'}
            style={{ bottom: 0 }}
            onClick={toggleDate}
          />
          <div
            className={`z-50 ${inModal ? 'fixed' : 'relative'}`}
            style={{
              left: floatValue.left,
              top: floatValue.top,
            }}
          >
            <SingleDatePicker
              firstDayOfWeek={1}
              date={date ? moment(date) : moment()}
              onDateChange={onDateClick}
              renderDayContents={renderDayContents}
              focused={focused}
              onFocusChange={setDatepickerFocus}
              id="datepicker-tablechange"
              isOutsideRange={validRange ? onDateRange : () => false}
              small={true}
              showDefaultInputIcon={false}
              noBorder={true}
              openDirection={openDirection}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              hideKeyboardShortcutsPanel={true}
              required={true}
              disabled={disabled}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default TableDatePicker
