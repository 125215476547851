
export const baseConfig = {
  host: "",
  proxyBaseUrl: "",
  organizationBaseUrl: "",
  frontendBaseUrl: "",
  exportBaseUrl: "",
  fdvuBaseUrl:"",
  tableKeeperBaseUrl: "",
  bimServiceBaseUrl: "",
  kpiBaseUrl: ""
}


export type IEnvironmentConfig = typeof baseConfig
