import { getOrganizationBaseUrl } from '../utility/utils'
import { IDocument } from './OrgTypes'
import { request } from './service-utils'

export const getUploadDocURl = (
  projectId: number,
  parentType: string,
): string => {
  return `${getOrganizationBaseUrl()}projects/${projectId}/upload_file/${parentType}`
}

export const getUploadUrlForParentId = (
  projectId: number,
  parentType: string,
  parentId: number,
): string => {
  return `${getOrganizationBaseUrl()}projects/${projectId}/parents/${parentType}/${parentId}/images`
}

export const uploadBase64File = (
  projectId: number,
  parentType: string,
  base64: string,
): Promise<IDocument> => {
  return request(`projects/${projectId}/upload_base64/${parentType}`, 'POST', {
    base64,
  })
}
