import { Fragment, PropsWithChildren, PureComponent } from 'react'
import { twMerge } from 'tailwind-merge'
import { HeadingType } from 'src/ui-elements/heading/HeadingEnums'
import { classNames } from 'src/utility/utils'

interface IHeadingProps extends PropsWithChildren {
  type: HeadingType
  pageHeader?: boolean
}

export default class Heading extends PureComponent<
  PropsWithChildren<IHeadingProps>
> {
  public static HeadingType = HeadingType

  private static styleClass = {
    root: (type: HeadingType, pageHeader: boolean | undefined) =>
      classNames(
        Heading.generateHeading(type),
        'font-roboto',
        pageHeader ? 'py-2' : 'px-2',
      ),
  }

  private static generateHeading(type: HeadingType) {
    switch (type) {
      case HeadingType.H1:
        return twMerge('text-3xl', 'my-4', 'font-medium')
      case HeadingType.H2:
        return twMerge('text-2xl', 'my-4', 'font-bold')
      case HeadingType.H3:
        return twMerge('text-lg', 'my-2', 'font-bold')
      case HeadingType.H4:
        return twMerge('text-base', 'my-2', 'font-bold', 'all-small-caps')
      case HeadingType.H5:
        return twMerge('text-base', 'my-2', 'font-medium')
      case HeadingType.H6:
        return twMerge('text-sm', 'my-2', 'font-medium', 'all-small-caps')
      default:
        return twMerge('text-3xl')
    }
  }

  constructor(props: IHeadingProps, _context: any) {
    super(props)
  }

  render() {
    const { type, children, pageHeader } = this.props
    return (
      <Fragment>
        {type === HeadingType.H1 ? (
          <h1 className={Heading.styleClass.root(type, pageHeader)}>
            {children}
          </h1>
        ) : null}
        {type === HeadingType.H2 ? (
          <h2 className={Heading.styleClass.root(type, pageHeader)}>
            {children}
          </h2>
        ) : null}
        {type === HeadingType.H3 ? (
          <h3 className={Heading.styleClass.root(type, pageHeader)}>
            {children}
          </h3>
        ) : null}
        {type === HeadingType.H4 ? (
          <h4 className={Heading.styleClass.root(type, pageHeader)}>
            {children}
          </h4>
        ) : null}
        {type === HeadingType.H5 ? (
          <h5 className={Heading.styleClass.root(type, pageHeader)}>
            {children}
          </h5>
        ) : null}
        {type === HeadingType.H6 ? (
          <h6 className={Heading.styleClass.root(type, pageHeader)}>
            {children}
          </h6>
        ) : null}
      </Fragment>
    )
  }
}
