import { parse } from 'query-string'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SplashScreenUtil } from 'src/page/splash/splashScreenStore'
import { updateSessionShareToken } from 'src/utility/shareToken'
import { IProject, IProjectData } from '../../service/OrgTypes'
import { request } from '../../service/service-utils'
import {
  initialState,
  IProjectContextState,
  ProjectContext,
} from './ProjectContext'

type Props = {
  children: ReactNode
}

const ProjectContextProviderAnonymous = (props: Props) => {
  const [project, setProject] = useState<IProject>({} as IProject)
  const { search } = useLocation()
  const { projectId, shareToken } = parse(search)
  const splashScreenUtil = SplashScreenUtil()

  const loadProject = useCallback(async () => {
    if (shareToken && typeof shareToken === 'string' && shareToken.length > 0) {
      const project = await request(
        `projects/${projectId}`,
        'GET',
        undefined,
        undefined,
        shareToken,
      )

      setProject(project)
      splashScreenUtil.removeSplashScreen()
    }
  }, [shareToken, projectId])

  useEffect(() => {
    updateSessionShareToken(shareToken as string)
  }, [shareToken])

  useEffect(() => {
    loadProject()
  }, [loadProject])

  const projectData: IProjectData = {
    project,
    isProjectAdmin: false,
    isProjectRiskAdmin: false,
    isBaselineAdmin: false,
    meetingModuleAccess: false,
    status_manager: false,
    isConstructionManager: false,
  }

  const contextValue: IProjectContextState = {
    ...initialState,
    currentProject: project,
    projects: [projectData],
  }

  const actions = {
    setProject: (_project: number) => undefined,
    reload: loadProject,
  }

  return (
    <ProjectContext.Provider value={{ state: contextValue, actions: actions }}>
      {props.children}
    </ProjectContext.Provider>
  )
}

export default ProjectContextProviderAnonymous
