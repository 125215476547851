import * as React from 'react'
import { ReactElement } from 'react'
import { StatusEnum } from 'src/utility/statusEnums'
import { classNames } from '../../utility/utils'
import Icon, { Icons } from '../icon/Icon'
import {
  BadgeColor,
  BadgeColorMapper,
  BadgeRoundedness,
  BadgeRoundednessMapper,
  BadgeSize,
  BadgeSizeMapper,
  StatusColorMapper,
} from './BadgeEnums'

interface IBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string
  size?: BadgeSize
  color?: BadgeColor
  roundedness?: BadgeRoundedness
  showDot?: boolean
  showCloseBtn?: boolean
  iconType?: Icons
  className?: string
  status?: StatusEnum
  icon?: ReactElement
}

export default class Badge extends React.PureComponent<IBadgeProps> {
  public static BadgeSize = BadgeSize
  public static BadgeColor = BadgeColor
  public static BadgeRoundedness = BadgeRoundedness

  public static defaultProps = {
    size: 'default',
    color: 'gray',
    roundedness: 'full',
    showDot: false,
    showCloseBtn: false,
  }

  private static styleClass = {
    root: (
      size: BadgeSize | undefined,
      color: BadgeColor | undefined,
      roundedness: BadgeRoundedness | undefined,
      className: string,
      status: StatusEnum | undefined,
    ) =>
      classNames(
        size && BadgeSizeMapper[size],
        status ? StatusColorMapper[status] : color && BadgeColorMapper()[color],
        roundedness === BadgeRoundedness.SMALL &&
          size === BadgeSize.LARGE &&
          BadgeRoundednessMapper[
            `${BadgeRoundedness.SMALL}-${BadgeSize.LARGE}`
          ],
        roundedness && BadgeRoundednessMapper[roundedness],
        'inline-flex',
        'py-0.5',
        'justify-center',
        'items-center',
        'font-medium',
        className,
      ),
    closeBtn: classNames(
      'flex-shrink-0',
      '-mr-0.5',
      'ml-1.5',
      'inline-flex',
      'focus:outline-none',
    ),
  }

  public render() {
    const {
      text,
      size,
      color,
      roundedness,
      showDot,
      showCloseBtn,
      className,
      iconType,
      status,
      icon,
      onClick,
    } = this.props
    return (
      <div
        className={Badge.styleClass.root(
          size,
          color,
          roundedness,
          className ?? '',
          status,
        )}
        onClick={onClick}
      >
        {showDot && (
          <svg className="mr-1.5 h-2 w-2" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
          </svg>
        )}
        {iconType && (
          <Icon icon={iconType} className={'mr-1 w-4 min-w-4 h-[22px]'} />
        )}
        {icon}
        <span className={'first-capitalize'}>{text}</span>
        {showCloseBtn && (
          <button type="button" className={Badge.styleClass.closeBtn}>
            <svg
              className="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        )}
      </div>
    )
  }
}
