import Lock from '@icons/lock.svg'
import LockOpen from '@icons/lock_open.svg'
import { useTranslation } from 'react-i18next'
import CircularButton from '../CircularButton'

export interface IOpenInspectorProps {
  lock: boolean
  setLock: (lock: boolean) => void
}

const LockIcon = ({ lock, setLock }: IOpenInspectorProps) => {
  const { t } = useTranslation()
  return (
    <CircularButton
      onClick={() => {
        setLock(!lock)
      }}
      tooltipText={t('lock_canvas')}
      active={lock}
    >
      {lock ? <Lock /> : <LockOpen />}
    </CircularButton>
  )
}

export default LockIcon
