import fileDownload from 'js-file-download'
import moment from 'moment'
import { stringify } from 'query-string'
import { rrulestr } from 'rrule'
import { EmailProvider, IEvent } from './EmailProviderUtil'
import ICalGenerator from './ICalGenerator'

class AddEventToCalendar {
  private provider: EmailProvider

  constructor(provider: EmailProvider) {
    this.provider = provider
  }

  private getRRule = (event: IEvent): string | null => {
    const _event = event?.rrule
    if (!_event || !_event.freq) {
      return null
    }
    const rrule = []
    if (_event.freq) {
      rrule.push(`FREQ=${_event.freq.toLowerCase()}`)
    }
    if (_event.count) {
      rrule.push(`;COUNT=${_event.count}`)
    }
    if (_event.byDay) {
      rrule.push(
        `;BYDAY=${
          Array.isArray(_event.byDay) ? _event.byDay.join(',') : _event.byDay
        }`,
      )
    }
    if (_event.interval) {
      rrule.push(`;INTERVAL=${_event.interval}`)
    }
    if (_event.until) {
      rrule.push(`;UNTIL=${_event.until}`)
    }
    if (_event.byMonthDay) {
      rrule.push(`;BYMONTHDAY=${_event.byMonthDay}`)
    }
    if (_event.byMonth) {
      rrule.push(`;BYMONTH=${_event.byMonth}`)
    }
    if (_event.bySetPos) {
      rrule.push(`;BYSETPOS=${_event.bySetPos}`)
    }

    return rrulestr(`RRULE:${rrule.join('')}`.toUpperCase()).toString()
  }

  public addToCalendar = (event: IEvent) => {
    switch (this.provider) {
      case EmailProvider.GMAIL:
        this.addToGoogleCalendar(event)
        break
      case EmailProvider.OUTLOOK:
        this.addToOutlookCalendar(event)
        break
      case EmailProvider.APPLE:
        this.addToIcloudCalendar(event)
      default:
        break
    }
  }

  public saveICalFile = (event: IEvent) => {
    const repeating = this.getRRule(event)
    const calendar = ICalGenerator.generateICalForMeeting(event, repeating)

    const blob = new Blob([calendar.toString()], {
      type: 'text/calendar;charset=utf-8',
    })

    fileDownload(blob, `${event.title}.ics`)
  }

  public getICalString = (event: IEvent) => {
    const repeating = this.getRRule(event)
    return ICalGenerator.generateICalForMeeting(event, repeating)
  }

  private addToGoogleCalendar = (event: IEvent) => {
    const baseUrl = 'https://calendar.google.com/calendar/render'
    const params = {
      action: 'TEMPLATE',
      text: `${event.title}`,
      dates: `${moment(event.start).format('YYYYMMDDTHHmmSSZ')}/${moment(
        event.end,
      ).format('YYYYMMDDTHHmmSSZ')}`,
      details: `${event.description}`,
      location: `${event.location}`,
      ctz: 'Europe/Oslo',
      recur: `${this.getRRule(event)}`,
      sf: 'true',
    }
    window.open(`${baseUrl}?${stringify(params)}`, '_blank')
  }

  private addToOutlookCalendar = (event: IEvent) => {
    const baseUrl = 'https://outlook.office.com/calendar/0/deeplink/compose'
    const params = {
      path: '/calendar/action/compose',
      rru: 'addevent',
      startdt: `${moment(event.start).format('YYYY-MM-DDTHH:mm:SSZ')}`,
      enddt: `${moment(event.end).format('YYYY-MM-DDTHH:mm:SSZ')}`,
      subject: `${event.title}`,
      body: `${event.description}`,
      recur: `${this.getRRule(event)}`,
      location: `${event.location}`,
    }
    window.open(`${baseUrl}?${stringify(params)}`, '_blank')
  }

  private addToIcloudCalendar = (event: IEvent) => {
    const baseUrl = 'https://calendar.icloud.com/eventedit'
    const params = {
      dtstart: `${moment(event.start).format('YYYY-MM-DDTHH:mm:SSZ')}`,
      dtend: `${moment(event.end).format('YYYY-MM-DDTHH:mm:SSZ')}`,
      summary: `${event.title}`,
      description: `${event.description}`,
    }
    window.open(`${baseUrl}?${stringify(params)}`, '_blank')
  }
}

export default AddEventToCalendar
