import { useRef } from 'react'
import { classNames } from '../../utility/utils'
// import { useEffect } from 'react';

const styleClass = {
  root: classNames(
    'inset-y-0',
    'border-r-2',
    'w-2',
    'border-gray-400',
    'gantt-resize',
  ),
}

interface IGanntHeaderResizeProps {
  size: number
  updateSize: (size: number) => void
  reload?: () => void
}

const GanntHeaderResize = ({
  size,
  updateSize,
  reload,
}: IGanntHeaderResizeProps) => {
  const offRef = useRef(0)

  const mouseDown = useRef(false)
  const setMouseDown = (down: boolean) => {
    mouseDown.current = down
  }

  const onMouseDown = (e: any) => {
    const di = e.target
    document.addEventListener('mousemove', onDrag)
    document.addEventListener('mouseup', onMouseUp)
    e.stopPropagation()
    offRef.current = di.offsetWidth - -Number(size) - e.pageX
    setMouseDown(true)
  }

  const onDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (mouseDown.current) {
      const si = offRef.current + e.pageX
      if (si > 120) {
        updateSize(si)
      }
    }
  }

  const onMouseUp = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    document.removeEventListener('mousemove', onDrag)
    setMouseDown(false)
    document.removeEventListener('mouseup', onMouseUp)
    if (reload) {
      reload()
    }
  }

  return (
    <div
      className={styleClass.root}
      style={{ cursor: 'col-resize', height: '100%' }}
      onMouseDown={onMouseDown}
    />
  )
}

export default GanntHeaderResize
