import * as React from 'react'
import { useTranslation } from 'react-i18next'
import history from 'src/history'
import Button from 'src/ui-elements/button/Button'
import ResolutionHandler from '../../service/ResolutionHandler'

interface IErrorPage {
  is404?: boolean
  tryAgain?: boolean
}

const ErrorPage = ({
  is404 = true,
  tryAgain = true,
  children,
}: React.PropsWithChildren<IErrorPage>) => {
  const { t } = useTranslation()
  const goBackHome = () => {
    if (ResolutionHandler.shouldShowMobileVersion()) {
      history.replace('/mobile')
      location.reload()
      return
    }
    location.reload()
  }

  return (
    <div
      className={`min-w-screen min-h-screen flex justify-center items-center p-5 lg:p-20 overflow-hidden relative ${
        is404 ? 'bg-blue-100' : 'bg-yellow-100'
      }`}
    >
      <div className="min-h-full w-full max-w-full lg:max-w-4/5 xl:max-w-3/5 xxl:max-w-2/5 rounded-none bg-white shadow-lg p-4 md:p-8 lg:p-20 text-gray-800 relative md:flex items-center text-center md:text-left">
        <div className="w-full md:w-1/2 flex flex-col justify-between h-full">
          <div className="flex flex-col pl-1 mb-4">
            <h1
              className={`text-3xl lg:text-5xl font-bold ${
                is404 ? 'text-blue-300' : 'text-yellow-300'
              }`}
            >
              {t('oops')}
            </h1>
            <p className={'text-gray-400'}>
              {is404 ? t('error_page_not_found') : t('something_went_wrong')}
            </p>
          </div>
          <div>
            {tryAgain && (
              <Button onClick={goBackHome} type={Button.ButtonType.DEFAULT}>
                {t('try_again')}
              </Button>
            )}
          </div>
          {children}
        </div>
        <div className="w-full md:w-1/2">
          <img
            src={is404 ? '/404.svg' : '/500.svg'}
            alt=""
            width="100%"
            height="auto"
          />
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
