export const issuesListExportTemplate = (url: string) => {
  return {
    geniusApiRequest: {
      url: `{organizationApiBaseUrl}${url}/pages/0/0`,
      method: 'POST',
    },
    name: 'Actions',
    displayName: 'Avvik',
    columns: [
      'id',
      'title',
      'status',
      'deadline',
      'closedDate',
      'responsible',
      'discipline',
      'contract',
      'user',
      'system',
      'room',
      'parentType',
      'controlArea',
      'wagon',
      'createdAt',
      'updatedAt',
    ],
    rows: [
      'record_id',
      'title',
      'status',
      'deadline',
      'close_date',
      'responsible.firstName||responsible.lastName',
      'discipline.shortName',
      'contract.contractNumber||contract.contractName',
      'reporter.firstName||reporter.lastName',
      'system.name',
      'room.room_name',
      'parent_type',
      'control_area.title',
      'construction_locomotive.title',
      'created_at',
      'updated_at',
    ],
  }
}
