import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { IRiskThreat } from '../../service/OrgTypes'
import { editRiskThreat } from '../../service/RiskService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import { capFirstLetter, classNames } from '../../utility/utils'

interface ICreateRiskThreatFormProps {
  closeModal: () => void
  editingMode: boolean
  riskThreat: IRiskThreat
  quantityUnit: string
}

const RiskThreatForm = ({
  closeModal,
  editingMode,
  riskThreat,
  quantityUnit,
}: ICreateRiskThreatFormProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'mt-2'),
  }

  const [id] = useState<number>(riskThreat.id)
  const [name, setName] = useState<string>(riskThreat.name)
  const [from, setFrom] = useState<string>(riskThreat.from)
  const [to, setTo] = useState<string>(riskThreat.to)
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('')
  const [fromErrorMessage, setFromErrorMessage] = useState<string>('')
  const [toErrorMessage, setToErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false

    setLoading(true)
    // RiskProbability validation
    if (!name) {
      setNameErrorMessage(t('fill_in_the_name'))
      error = true
    }

    if (!from) {
      setFromErrorMessage(t('fill_in_from_value'))
      error = true
    }

    if (!to) {
      setToErrorMessage(t('fill_in_to_value'))
      error = true
    }

    if (!error) {
      if (editingMode) {
        editRiskThreat({ id, name, from, to }).then(() => {
          closeModal()
        })
      }
    }

    setLoading(false)
  }

  const onNameChange = (e: any) => {
    setName(e.target.value)
    setNameErrorMessage('')
  }

  const onFromChange = (e: any) => {
    setFrom(e.target.value)
    setFromErrorMessage('')
  }

  const onToChange = (e: any) => {
    setTo(e.target.value)
    setToErrorMessage('')
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('name')}
          block={true}
          value={name}
          errorMessage={nameErrorMessage}
          required={true}
          onChange={onNameChange}
        />
      </div>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('from_value', quantityUnit)}
          block={true}
          value={from}
          errorMessage={fromErrorMessage}
          type={'number'}
          required={true}
          onChange={onFromChange}
        />
        <Input
          label={t('to_value', quantityUnit)}
          block={true}
          type={'number'}
          value={to}
          errorMessage={toErrorMessage}
          required={true}
          onChange={onToChange}
        />
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        {editingMode ? (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : capFirstLetter(t('update'))}
          </Button>
        ) : (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : capFirstLetter(t('add'))}
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default RiskThreatForm
