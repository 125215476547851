import { capitalize } from 'lodash'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import DependencyControl from 'src/components/dependency-control/DependencyControl'
import CheckboxWithLabel from 'src/ui-elements/checkbox/CheckboxWithLabel'
import { capFirstLetter, classNames } from '../../utility/utils'
import TimeLineResetDate from '../canvas-header/TimelineResetDate'
import InfoModal from '../info/info'

type Column = {
  active: boolean
  value: string
  name: string
}

type IGantInfoRowProps = PropsWithChildren<{
  inMainProcess?: boolean
  showArrows: boolean
  showDependencies: boolean
  toggleShowArrows: () => void
  clearDependency: () => void
  columns?: Column[]
  toggleColumn?: (col: string) => void
  resetTimePosition: () => void
}>

type Items = [string, string][]

const ItemGroup = ({ items, name }: { items: Items; name: string }) => (
  <div className="flex flex-col items-start text-gray-400">
    <span>{name}</span>
    {items.map(([icon, infoText]) => (
      <div
        key={icon + infoText}
        className="flex items-center justify-center py-1"
      >
        <div className="px-0.5 transform scale-75 opacity-75">
          <div className={icon} />
        </div>
        <p className="text-sm text-gray-600">{capFirstLetter(infoText)}</p>
      </div>
    ))}
  </div>
)

const GanttInfoRow = ({
  inMainProcess = false,
  columns,
  toggleColumn,
  resetTimePosition,
  showDependencies,
  clearDependency,
  showArrows,
  toggleShowArrows,
}: IGantInfoRowProps) => {
  const { t } = useTranslation()

  const items_general: Items = [
    ['triangle-down-black', t('milestone')],
    ['diamond-black', t('keypoint')],
    [`circle-black`, t('delivery')],
    [`triangle-right-black`, t('task')],
    [`circle-black`, t('open')],
  ]

  const items_status: Items = [
    [`circle-green`, t('completed')],
    ['circle-yellow', t('delayed')],
    [`circle`, t('expired')],
  ]

  return (
    <div
      className={classNames(
        'absolute',
        inMainProcess ? 'top-20' : 'top-[4.5rem]',
        'flex',
        'gap-4',
        inMainProcess ? 'right-4' : 'right-14',
        'z-50',
      )}
    >
      {showDependencies && (
        <DependencyControl
          showArrows={showArrows ?? false}
          toggleShowArrow={toggleShowArrows}
          clearDependency={clearDependency}
        />
      )}
      {columns && (
        <InfoModal
          tooltipText={t('column_selector')}
          mdIcon="tune"
          modalOrigin="topCenter"
        >
          <div className="p-2">
            {columns.map((item) => (
              <div
                key={item.name}
                className={classNames(
                  'w-full',
                  'hover:bg-grey-light',
                  'p-1',
                  'flex',
                  'cursor-pointer',
                  'text-sm',
                  'col',
                  'items-center',
                )}
                onClick={() => toggleColumn?.(item.value)}
              >
                <CheckboxWithLabel
                  labelRight
                  value={item.active}
                  name={capitalize(t(item.name.toLowerCase()))}
                />
              </div>
            ))}
          </div>
        </InfoModal>
      )}
      <TimeLineResetDate onReset={resetTimePosition} />
      <InfoModal>
        <div className="flex flex-col items-start p-3 gap-4">
          <ItemGroup
            name={capFirstLetter(t('general'))}
            items={items_general}
          />
          <ItemGroup name={capFirstLetter(t('status'))} items={items_status} />
        </div>
      </InfoModal>
    </div>
  )
}

export default GanttInfoRow
