import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import CheckboxWithLabel from 'src/ui-elements/checkbox/CheckboxWithLabel'
import { capFirstLetter } from '../../utility/utils'
import { IListContext } from './ListContextProvider'
import { IListColumns } from './ListTypes'
import { withListContext } from './withListContext'

interface IColumnFilterItem extends IListContext, WithTranslation {
  column: IListColumns
  active: boolean
}

class ColumnFilterItem extends PureComponent<IColumnFilterItem> {
  private toggleItem = () => {
    const { removeColumn, addColumn } = this.props.actions
    const { active, column } = this.props
    if (active) {
      removeColumn(column)
    } else {
      addColumn(column)
    }
  }

  private getTranslatedColumnName = (val: string) => {
    const { t } = this.props
    const hasParam = val.match(',')

    if (hasParam) {
      const splittedVal = val.split(',')
      return capFirstLetter(
        t(splittedVal[0], { [splittedVal[1]]: splittedVal[2] }),
      )
    } else {
      return capFirstLetter(t(val))
    }
  }

  public render() {
    const { name } = this.props.column
    const { active } = this.props
    return (
      <CheckboxWithLabel
        name={this.getTranslatedColumnName(name)}
        value={active}
        onChange={this.toggleItem}
        labelRight
      />
    )
  }
}

export default withTranslation()(withListContext(ColumnFilterItem))
