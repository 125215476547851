import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MaterialIcon from '../../../../ui-elements/icon/materialIcon'
import DateTimeInlineInputComponent from '../../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import { IStringDropDownItem } from '../../../../ui-elements/page-display/inline-components/IDropDown'
import InlineComponentsWrapper from '../../../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import MultiSelectorInlineInputComponent from '../../../../ui-elements/page-display/inline-components/MultiSelectorInlineInputComponent'
import SelectorInlineInputComponent from '../../../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import BooleanInput from '../../BooleanInput'
import ErrorPopUp from '../popup/ErrorPopUp'
import { DATA_TYPES, ImportDataFiled } from '../selectors/DataSelectors'
import { IPos } from '../types'

interface DocValidationCellProps {
  parentId: number
  value?: string | object | string[]
  errors?: string[]
  onChange: (value: string | string[] | null) => void
  dataFiled: ImportDataFiled
}

export interface CellDataFiled {
  selector: string
  getData: () => Promise<any[]>
  mapper: string
  display?: JSX.Element
}

const _getEnumValue = (
  value: string | object | null,
  selector: string,
): IStringDropDownItem => {
  return {
    id: typeof value === 'object' ? (value?.['id'] ?? '') : `${value}`,
    name:
      typeof value === 'object'
        ? (selector
            .split('.')
            .map((s) => value?.[s] ?? '')
            .join(' ') ?? '')
        : value,
  }
}

const _getLabelValue = (selector: string, value?: string | object | null) => {
  if (!value) return ''
  return typeof value === 'object'
    ? (selector
        .split('.')
        .map((s) => value?.[s] ?? '')
        .join(' ') ?? '')
    : value
}

const _getMultiEnumValue = (
  values: (object | string | null)[],
  selector: string,
): IStringDropDownItem[] => {
  return (
    values?.map((value) => ({
      id: typeof value === 'object' ? (value?.['id'] ?? '') : `${value}`,
      name:
        typeof value === 'object'
          ? (selector
              .split('.')
              .map((s) => value?.[s] ?? '')
              .join(' ') ?? '')
          : value,
    })) ?? []
  )
}

const DocValidationCell: FC<DocValidationCellProps> = ({
  parentId,
  value,
  errors,
  onChange: _onChange,
  dataFiled,
}) => {
  const { t } = useTranslation()
  const [showError, setShowError] = useState(false)
  const [importPos, setPos] = useState<IPos>({
    left: 0,
    top: 0,
  })
  const onMouseEnter = (e: any) => {
    if (errors?.length) {
      const target = e.target.getBoundingClientRect()
      setPos({
        left: target.x,
        top: target.top,
      })
      setShowError(true)
    }
  }

  const onMouseLeave = () => {
    setShowError(false)
  }
  const onChange = (id: string | string[] | null) => {
    _onChange(id)
  }

  const getData = useCallback(async () => {
    if (!dataFiled.getData) {
      return Promise.resolve([])
    }

    return await dataFiled.getData(parentId)
  }, [parentId])

  const dataComponent = (
    dataValue: string | boolean | object | object[] | undefined | null,
    dataType?: DATA_TYPES,
  ) => {
    if (dataType === DATA_TYPES.date) {
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <DateTimeInlineInputComponent
            selectedTime={`${dataValue ?? ''}`}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
            cancelButton={true}
          />
        </InlineComponentsWrapper>
      )
    } else if (dataType === DATA_TYPES.boolean) {
      return (
        <BooleanInput
          value={dataValue as boolean}
          onChange={(value) => onChange(`${value}`)}
        />
      )
    } else if (dataType === DATA_TYPES.string) {
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <TextInlineInputCompontent
            fullWidth={true}
            value={dataValue as string}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
          />
        </InlineComponentsWrapper>
      )
    } else if (dataType === DATA_TYPES.enum) {
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <SelectorInlineInputComponent<string, any>
            getItems={() =>
              getData?.() ?? Promise.resolve(dataFiled?.listItems?.(t) ?? [])
            }
            items={dataFiled?.listItems?.(t) ?? []}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
            selectedId={dataValue?.['id']}
            initialItem={_getEnumValue(
              dataValue as object,
              dataFiled?.selector ?? '',
            )}
            getItemLabel={(item) => _getLabelValue(dataFiled?.selector, item)}
            cancelButton={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      )
    } else if (dataType === DATA_TYPES.constantEnum) {
      const value = dataValue as string
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <SelectorInlineInputComponent<string, any>
            items={dataFiled?.listItems?.(t) ?? []}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
            selectedId={value}
            initialItem={{ id: value, name: t(value) }}
            getItemLabel={(item) => item.name ?? ''}
            cancelButton={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      )
    } else if (dataType === DATA_TYPES.multiEnum) {
      const dataValueList = dataValue ? (dataValue as object[]) : []
      const selectedIds = dataValueList.map((it) => it?.['id'])
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <MultiSelectorInlineInputComponent<string, IStringDropDownItem>
            getItems={() => getData?.() ?? Promise.resolve([])}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
            selectedIds={selectedIds}
            initialItems={_getMultiEnumValue(
              dataValueList,
              dataFiled?.selector,
            )}
            getItemLabel={(item) => item.name ?? ''}
            cancelButton={true}
          />
        </InlineComponentsWrapper>
      )
    }

    return ''
  }

  return (
    <div className="flex content-center items-center w-full">
      {!!errors?.length && (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <MaterialIcon
            className="text-base text-red-danger mr-1"
            icon="error"
          />
          {showError && (
            <ErrorPopUp
              show={showError}
              close={() => setShowError(false)}
              pos={importPos}
            >
              <ul>
                {errors?.map((error) => {
                  return <li key={error}>{t(error ?? '')}</li>
                })}
              </ul>
            </ErrorPopUp>
          )}
        </div>
      )}
      {dataComponent(value ?? '', dataFiled?.dataType)}
    </div>
  )
}

export default DocValidationCell
