import { IConstructionChecklist } from './OrgTypes'
import { request } from './service-utils'

export const createConstructionChecklist = (
  checklist: IConstructionChecklist,
  constructionLocomotivesId: number,
): Promise<IConstructionChecklist> => {
  return request(
    `construction_locomotives/${constructionLocomotivesId}/construction_checklists`,
    'POST',
    checklist,
  )
}

export const getConstructionLocomotivesChecklists = (
  locomotiveId: number,
): Promise<IConstructionChecklist[]> => {
  return request(
    `construction_locomotives/${locomotiveId}/construction_checklists`,
    'GET',
  )
}

export const getWagonCheckListsWithValues = (locomotiveId: number) => {
  return request(
    `/construction_locomotives/${locomotiveId}/checklists_with_total_values`,
    'GET',
  )
}

export const deleteConstructionChecklist = (
  checklist: IConstructionChecklist,
) => {
  return request(`/construction_checklists/${checklist.id}`, 'DELETE', {}, true)
}
