import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CircularButton from 'src/components/CircularButton'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import { useMainProcesses } from 'src/query/planning/process'
import { useProjectUsers } from 'src/query/users'
import { editImprovement } from 'src/service/ImprovementsService'
import { IImprovement } from 'src/service/OrgTypes'
import {
  costTypes,
  effectTypes,
  improvementStatusTypes,
} from 'src/service/SystemValues'
import InputDisplay from 'src/ui-elements/input-group/InputDisplay'
import SelectorDisplay from 'src/ui-elements/input-group/SelectorDisplay'
import SingleDateDisplay from 'src/ui-elements/input-group/SingleDateDisplay'
import TextAreaDisplay from 'src/ui-elements/input-group/TextAreaDisplay'
import { useDebouncer } from 'src/utility/Debouncer'
import { renderDayContents } from 'src/utility/Utility'
import { capFirstLetter } from 'src/utility/utils'
import ImprovementSharingButtons from './ImprovementSharingButtons'

interface ImprovementFormProps {
  improvement: IImprovement
  projectId: number
  newStatus?: string
  newPriority?: number
  editMode?: boolean
  onCancelEdit?: () => void
  onUpdate: () => void
}

const ImprovementForm = ({
  improvement: initialImprovement,
  projectId,
  newStatus,
  newPriority,
  editMode,
  onCancelEdit,
  onUpdate,
}: ImprovementFormProps) => {
  const { t } = useTranslation()

  const { data: users, isPending: usersLoading } = useProjectUsers()
  const { data: mainProcesses, isPending: mainProcessesLoading } =
    useMainProcesses()

  const [improvement, setImprovement] = useState({
    ...initialImprovement,
    status: newStatus || initialImprovement.status,
    priority: newPriority || initialImprovement.priority,
  })

  const [loading, setLoading] = useState(false)
  const [editingMode, setEditingMode] = useState(editMode || false)
  const [deadlineDatePickerFocused, setDeadlineDatePickerFocused] = useState({
    focused: false,
  })

  const [titleErrorMessage, setTitleErrorMessage] = useState('')
  const [responsibleErrorMessage, setResponsibleErrorMessage] = useState('')
  const [backgroundErrorMessage, setBackgroundErrorMessage] = useState('')
  const [currentSituationErrorMessage, setCurrentSituationErrorMessage] =
    useState('')

  const disabled = !improvement.update_access

  const updateImprovement = (value: unknown, field: string) =>
    setImprovement((i) => ({ ...i, [field]: value }))

  useDidMountEffect(() => {
    if (!editingMode && onCancelEdit) {
      onCancelEdit()
    }
    setImprovement(initialImprovement)
  }, [initialImprovement, editingMode])

  useEffect(() => {
    if (newPriority && newStatus === improvement.status) {
      updateImprovement(newPriority, 'priority')
    } else if (improvement.status !== initialImprovement.status) {
      updateImprovement(-1, 'priority')
    } else {
      updateImprovement(initialImprovement.priority, 'priority')
    }
  }, [
    improvement.status,
    initialImprovement.priority,
    initialImprovement.status,
    newPriority,
    newStatus,
  ])

  const validateForm = () => {
    const errors = {
      title:
        !improvement.title && editingMode
          ? t('fill_out_w_param', { param: t('title') })
          : '',
      background:
        !improvement.background && editingMode
          ? t('fill_out_w_param', { param: t('background') })
          : '',
      currentSituation:
        !improvement.current_situation && editingMode
          ? t('fill_out_w_param', { param: t('current_situation') })
          : '',
      responsible:
        improvement.status !== 'open_improvement' &&
        !improvement.responsible_id &&
        editingMode
          ? t('fill_out_w_param', {
              param: t('responsible_for_the_improvement'),
            })
          : '',
    }

    setTitleErrorMessage(errors.title)
    setBackgroundErrorMessage(errors.background)
    setCurrentSituationErrorMessage(errors.currentSituation)
    setResponsibleErrorMessage(errors.responsible)
  }

  const debounceFormValidation = useDebouncer({
    delay: 500,
    callback: () => validateForm(),
  })

  useEffect(() => {
    debounceFormValidation()
  }, [improvement, debounceFormValidation])

  const error = useMemo(
    () =>
      titleErrorMessage ||
      responsibleErrorMessage ||
      backgroundErrorMessage ||
      currentSituationErrorMessage,
    [
      titleErrorMessage,
      responsibleErrorMessage,
      backgroundErrorMessage,
      currentSituationErrorMessage,
    ],
  )

  const onSaveImprovement = async () => {
    if (error) {
      return
    }

    setLoading(true)
    try {
      await editImprovement(improvement)
      onUpdate()
      setEditingMode(false)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col w-full relative border-8 border-gray-100 flex-1 min-h-[60vh]">
      <div className="flex gap-4 pb-2 px-2 justify-end w-full bg-gray-100">
        {!editingMode && (
          <>
            <ImprovementSharingButtons
              improvement={improvement}
              projectId={projectId}
            />
          </>
        )}
        <CircularButton
          mdIcon={editingMode ? 'close' : 'edit'}
          onClick={() => setEditingMode((e) => !e)}
          tooltipText={editingMode ? t('cancel') : capFirstLetter(t('edit'))}
          disabled={disabled}
        />
        {editingMode && (
          <CircularButton
            mdIcon={'save'}
            onClick={() => onSaveImprovement()}
            tooltipText={t('save')}
            disabled={disabled || loading || error !== ''}
            disabledTooltipText={error}
          />
        )}
      </div>

      <div className="w-full overflow-x-hidden overflow-y-scroll">
        <InputDisplay
          isValid={false}
          label={t('title')}
          block
          value={improvement.title}
          onChange={(e) => updateImprovement(e.target.value, 'title')}
          required
          errorMessage={titleErrorMessage}
          disabled={disabled}
          displayData={improvement.title}
          editingMode={editingMode}
        />

        <SingleDateDisplay
          date={improvement.deadline ? moment(improvement.deadline) : null}
          onDateChange={(date) => updateImprovement(date, 'deadline')}
          renderDayContents={renderDayContents}
          focused={deadlineDatePickerFocused.focused}
          onFocusChange={setDeadlineDatePickerFocused}
          id="improvement_deadline_date"
          small
          isOutsideRange={() => false}
          showDefaultInputIcon
          noBorder
          numberOfMonths={1}
          displayFormat={() => moment.localeData('no').postformat('DD.MM.YY')}
          hideKeyboardShortcutsPanel
          label={t('deadline')}
          disabled={disabled}
          editingMode={editingMode}
          displayData={
            improvement.deadline ? moment(improvement.deadline).format('L') : ''
          }
          required={false}
        />

        <SelectorDisplay
          items={improvementStatusTypes(t)}
          selectedItemId={improvement.status}
          onSelect={(status) => updateImprovement(status, 'status')}
          label={t('status')}
          dataFields={['name']}
          fontSize={'sm'}
          userSelector={false}
          fontWeight={'bold'}
          disabled={disabled}
          editingMode={editingMode}
          displayData={t(improvement.status)}
          isStatus
        />

        <SelectorDisplay
          items={users || []}
          selectedItemId={improvement.responsible_id || 0}
          loading={usersLoading}
          onSelect={(responsibleId) =>
            updateImprovement(responsibleId, 'responsible_id')
          }
          label={t('responsible')}
          dataFields={['firstName', 'lastName']}
          required={improvement.status !== 'open_improvement'}
          fontSize={'sm'}
          userSelector
          fontWeight={'bold'}
          displayData={
            improvement.responsible
              ? `${improvement.responsible.firstName} ${improvement.responsible.lastName}`
              : ''
          }
          editingMode={editingMode}
          errorMessage={responsibleErrorMessage}
        />

        <SelectorDisplay
          disabled
          items={[]}
          selectedItemId={0}
          onSelect={() => {}}
          label={t('reporter')}
          dataFields={['firstName', 'lastName']}
          required
          fontSize={'sm'}
          userSelector
          fontWeight={'bold'}
          editingMode={false}
          displayData={
            improvement.user
              ? `${improvement.user.firstName} ${improvement.user.lastName}`
              : ''
          }
        />

        <SelectorDisplay
          items={mainProcesses || []}
          selectedItemId={improvement.main_process_id || 0}
          onSelect={(mainProcessId) =>
            updateImprovement(mainProcessId, 'main_process_id')
          }
          label={t('main_process')}
          dataFields={['name']}
          required={false}
          fontSize={'sm'}
          fontWeight={'bold'}
          editingMode={editingMode}
          displayData={improvement.main_process?.name || ''}
          loading={mainProcessesLoading}
        />

        <TextAreaDisplay
          value={improvement.background}
          isValid={false}
          label={t('background')}
          onChange={(e) => updateImprovement(e.target.value, 'background')}
          block
          required
          errorMessage={backgroundErrorMessage}
          displayData={improvement.background}
          editingMode={editingMode}
        />

        <TextAreaDisplay
          value={improvement.current_situation}
          isValid={false}
          label={t('current_situation')}
          onChange={(e) =>
            updateImprovement(e.target.value, 'current_situation')
          }
          block
          required
          errorMessage={currentSituationErrorMessage}
          displayData={improvement.current_situation}
          editingMode={editingMode}
        />

        <TextAreaDisplay
          value={improvement.measure}
          isValid={false}
          label={t('wanted_situation')}
          onChange={(e) => updateImprovement(e.target.value, 'measure')}
          block
          required={false}
          displayData={improvement.measure || ''}
          editingMode={editingMode}
        />

        <TextAreaDisplay
          value={improvement.profitability}
          isValid={false}
          label={t('profitability')}
          onChange={(e) => updateImprovement(e.target.value, 'profitability')}
          block
          required={false}
          errorMessage={''}
          displayData={improvement.profitability || ''}
          editingMode={editingMode}
        />

        <TextAreaDisplay
          value={improvement.description}
          isValid={false}
          label={t('root_cause')}
          onChange={(e) => updateImprovement(e.target.value, 'description')}
          block
          required={false}
          errorMessage={''}
          displayData={improvement.description || ''}
          editingMode={editingMode}
        />

        <TextAreaDisplay
          value={improvement.countermeasures}
          isValid={false}
          label={t('countermeasures')}
          onChange={(e) => updateImprovement(e.target.value, 'countermeasures')}
          block
          required={false}
          errorMessage={''}
          displayData={improvement.countermeasures || ''}
          editingMode={editingMode}
        />

        <TextAreaDisplay
          value={improvement.plan}
          isValid={false}
          label={t('plan')}
          onChange={(e) => updateImprovement(e.target.value, 'plan')}
          block
          required={false}
          errorMessage={''}
          displayData={improvement.plan || ''}
          editingMode={editingMode}
        />

        <SelectorDisplay
          items={costTypes(t)}
          selectedItemId={improvement.improvement_cost || 0}
          onSelect={(improvementCost) =>
            updateImprovement(improvementCost, 'improvement_cost')
          }
          label={t('improvement_cost')}
          dataFields={['name']}
          required={false}
          userSelector={false}
          fontSize={'sm'}
          fontWeight={'bold'}
          editingMode={editingMode}
          displayData={improvement.improvement_cost || ''}
        />

        <SelectorDisplay
          items={effectTypes(t)}
          selectedItemId={improvement.improvement_effect || 0}
          onSelect={(improvementEffect) =>
            updateImprovement(improvementEffect, 'improvement_effect')
          }
          label={t('benefit')}
          dataFields={['name']}
          required={false}
          fontSize={'sm'}
          userSelector={false}
          fontWeight={'bold'}
          editingMode={editingMode}
          displayData={improvement.improvement_effect || ''}
        />

        <TextAreaDisplay
          value={improvement.decison_description}
          isValid={false}
          label={t('decison_description')}
          onChange={(e) =>
            updateImprovement(e.target.value, 'decison_description')
          }
          block
          required={false}
          displayData={improvement.decison_description || ''}
          editingMode={editingMode}
        />

        <TextAreaDisplay
          value={improvement.effect}
          isValid={false}
          label={t('effect')}
          onChange={(e) => updateImprovement(e.target.value, 'effect')}
          block
          required={false}
          displayData={improvement.effect || ''}
          editingMode={editingMode}
        />
      </div>
    </div>
  )
}

export default ImprovementForm
