import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDraftMode from 'src/hooks/useDraftMode'
import { ICheckInOut, IConstructionWagon } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import useAlert from 'src/ui-elements/toast/useAlert'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import { getWagonCheckInOuts } from '../../../service/ConstructionWagonService'
import { capFirstLetter } from '../../../utility/utils'
import CheckInOutDetail from './CheckInOutDetail'
import WagonCheckInOut from './WagonCheckInOut'

interface IChecckListDetail {
  wagon: IConstructionWagon
  onUpdate: () => void
}

const CheckListDetail = ({ wagon, onUpdate }: IChecckListDetail) => {
  const { addAlert } = useAlert()
  const { t } = useTranslation()
  const [showCheckInOutModal, setShowCheckInOutModal] = useState<boolean>(false)
  const [isCheckIn, setIsCheckIn] = useState<boolean>(false)
  const [checkInData, setCheckInData] = useState<ICheckInOut | undefined>(
    undefined,
  )
  const [checkOutData, setCheckOutData] = useState<ICheckInOut | undefined>(
    undefined,
  )
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id
  const [editing, setEditing] = useState<boolean>(false)

  const { draftMode } = useDraftMode()

  useEffect(() => {
    loadData()
  }, [wagon])

  const loadData = () => {
    if (wagon.id) {
      getWagonCheckInOuts(wagon.id).then((res) => {
        setCheckInData(
          res.filter((item: ICheckInOut) => item.is_check_in).pop(),
        )
        setCheckOutData(
          res.filter((item: ICheckInOut) => !item.is_check_in).pop(),
        )
      })
    }
  }

  const onCheckInOutSubmit = () => {
    setShowCheckInOutModal(false)
    loadData()
    onUpdate()
  }

  const handleCheckIn = () => {
    setIsCheckIn(true)
    setEditing(false)
    setShowCheckInOutModal(true)
  }

  const handleCheckOut = () => {
    if (wagon?.status_color !== null) {
      setIsCheckIn(false)
      setEditing(false)
      setShowCheckInOutModal(true)
      return
    }
    addAlert({
      type: 'error',
      title: t('something_went_wrong'),
      description: t('the_wagon_must_have_status_meeting'),
      autoClose: true,
    })
  }

  const onEditCheckInout = (isThisCheckIn: boolean) => {
    setIsCheckIn(isThisCheckIn)
    setEditing(true)
    setShowCheckInOutModal(true)
  }

  if (draftMode)
    return (
      <div className="m-8 text-gray-500">
        {t('can_t_check_in_check_out_in_draft_mode')}
      </div>
    )

  return (
    <div className={'min-h-95 m-3 mb-0'}>
      <div className="flex flex-col md:flex-row flex-wrap w-full xl:w-2/3 min-w-124 ">
        {checkInData && (
          <div className="w-full md:w-1/2 pb-3">
            <CheckInOutDetail
              checkInOut={checkInData}
              checkedIn={true}
              wagonId={wagon?.id ? wagon?.id : 0}
              onEdit={() => onEditCheckInout(true)}
              reload={onUpdate}
            />
          </div>
        )}

        {checkOutData && (
          <div className="w-full md:w-1/2 pb-3">
            <CheckInOutDetail
              checkInOut={checkOutData}
              checkedIn={false}
              wagonId={wagon?.id ? wagon?.id : 0}
              actualStaffing={wagon?.actual_staffing ?? 0}
              actualWorkHours={wagon?.actual_work_hours ?? 0}
              onEdit={() => onEditCheckInout(false)}
              reload={onUpdate}
            />
          </div>
        )}

        {wagon.id &&
          (wagon.check_in === null ? (
            <div className="pl-4">
              <Button
                type={Button.ButtonType.SECONDARY}
                onClick={handleCheckIn}
              >
                {capFirstLetter(t('check_in'))}
              </Button>
            </div>
          ) : wagon.id && wagon.check_out === null ? (
            <div className="pl-4">
              <Button
                type={Button.ButtonType.SECONDARY}
                onClick={handleCheckOut}
              >
                {capFirstLetter(t('check_out'))}
              </Button>
            </div>
          ) : null)}

        {showCheckInOutModal && wagon.id && (
          <WagonCheckInOut
            show={showCheckInOutModal}
            isCheckIn={isCheckIn}
            close={() => setShowCheckInOutModal(false)}
            wagonId={wagon.id}
            projectId={projectId}
            onSubmit={onCheckInOutSubmit}
            isEditing={editing}
          />
        )}
      </div>
    </div>
  )
}

export default CheckListDetail
