import { createContext } from 'react'
import { IMenu } from '../../service/OrgTypes'

export interface IMenuContext {
  actions: {
    updateTitle: (res: string) => void
    toggleActiveMenu: (res: number) => void
    toggleActiveSubmenu: (res?: number) => void
  }
  state: IMenu
}

export type MenuContext = IMenuContext

export const MenuContext = createContext<MenuContext>({
  actions: {
    toggleActiveMenu: () => null,
    updateTitle: () => null,
    toggleActiveSubmenu: () => null,
  },
  state: {
    title: '',
    activeSubmenu: 0,
    activeTab: 0,
  },
})
