import i18next from 'i18next'
import fileDownload from 'js-file-download'
import _ from 'lodash/camelCase'
import moment from 'moment'
import { ImportTemplate } from 'src/components/upload-item/ImportHelpers'
import {
  ExportTemplate,
  ExportResponse,
  MeetingExportTemplate,
  ExportTask,
  ExportData,
} from 'src/context/ExportContext/ExportTypes'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import {
  capFirstLetter,
  constructFilterJson,
  getCurrentProject,
  getExportBaseUrl,
  getOrganizationBaseUrl,
} from 'src/utility/utils'
import { IListFilterFromParent } from './OrgTypes'
import { updateWallToken } from './TheWallService'

export interface ExportFilter {
  filter?: IListFilter[]
  parentId?: number | number[]
  parentType?: string
  filterFromParent?: IListFilterFromParent[]
  complete?: boolean
}

export interface MeetingExport {
  template: MeetingExportTemplate
  meetingId: number
  projectLogo: string
  displayName: string
}

export type cc = { name: string; id: string }

interface User {
  id: string
  firstName: string
  lastName: string
}

class BaseExportService {
  private getLang = (): string => {
    return i18next.language === 'no' ? 'nb' : i18next.language
  }

  get projectId() {
    return getCurrentProject()
  }

  get user(): User | undefined {
    const userJson = localStorage.getItem('loggedInUser')
    if (userJson) {
      return JSON.parse(userJson)
    }
    return
  }

  protected ExportApi = async (
    template: ExportTemplate | ImportTemplate,
    endPoint: string,
  ) => {
    const token = await updateWallToken()
    return await fetch(
      `${getExportBaseUrl()}api/export/${endPoint}?culture=${this.getLang()}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Project-Id': this.projectId ?? '',
          'User-Id': this.user?.id ?? '',
          'Org-Api': getOrganizationBaseUrl(),
        },
        body: JSON.stringify(template),
        method: 'POST',
      },
    )
  }

  protected ExportFile = (
    template: ExportTemplate,
    endPoint: string,
  ): Promise<ExportResponse> => {
    return new Promise<ExportResponse>(async (resolve, reject) => {
      const res = await this.ExportApi(template, endPoint)
      if (res.status >= 400 && res.status < 600) {
        return reject('Bad response from server')
      }
      const response = res.clone().json()
      response.then(resolve).catch(reject)
    })
  }

  protected ExportImportFile = (
    template: ImportTemplate,
    endPoint: string,
  ): Promise<Blob> => {
    return new Promise<Blob>(async (resolve, reject) => {
      const res = await this.ExportApi(template, endPoint)
      if (res.status >= 400 && res.status < 600) {
        return reject('Bad response from server')
      }
      const response = res.clone().blob()
      response.then(resolve).catch(reject)
    })
  }

  protected ExportTasksApi = (
    endPoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    body?: string,
  ): Promise<ExportTask[]> => {
    return new Promise<ExportTask[]>(async (resolve, reject) => {
      const token = await updateWallToken()
      const res = await fetch(
        `${getExportBaseUrl()}api/${endPoint}?culture=${this.getLang()}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'Project-Id': this.projectId ?? '',
            'User-Id': this.user?.id ?? '',
            'Org-Api': getOrganizationBaseUrl(),
          },
          body,
          method,
        },
      )
      if (res.status >= 400 && res.status < 600) {
        return reject('Bad response from server')
      }
      res.clone().json().then(resolve).catch(reject)
    })
  }

  protected ExportAndDownloadFile = async (
    template: ImportTemplate,
    endPoint: string,
    fileType: { extension: string; type: string },
  ) => {
    const blob = await this.ExportImportFile(template, endPoint)
    fileDownload(
      blob,
      `${template.displayName ?? 'Meeting'}-${new Date().toISOString()}.${
        fileType.extension
      }`,
      fileType.type,
    )
  }

  protected downloadFile = (
    url: string,
    fileType: { extension: string; type: string },
    date = moment().format('YYYY-MM-DD'),
  ): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const token = await updateWallToken()
      const res = await fetch(`${url}&token=${token}`, {
        method: 'GET',
      })
      if (res.status >= 400 && res.status < 600) {
        return reject('Bad response from server')
      }
      const blob = await res.blob()
      fileDownload(
        blob,
        `Meeting-${moment(date).format('L')}.${fileType.extension}`,
        fileType.type,
      )
    })
  }

  protected downloadGeneric = (
    url: string,
    fileName: string,
    fileType: string,
  ): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const token = await updateWallToken()
      const res = await fetch(`${url}&token=${token}`, {
        method: 'GET',
      })
      if (res.status >= 400 && res.status < 600) {
        return reject('Bad response from server')
      }
      const blob = await res.blob()
      fileDownload(blob, fileName, fileType)
    })
  }

  protected replaceUrl = (template: ExportData) => {
    if (!this.projectId) return
    template.geniusApiRequest.url = template.geniusApiRequest.url.replace(
      '{organizationApiBaseUrl}',
      getOrganizationBaseUrl(),
    )
    template.geniusApiRequest.url = template.geniusApiRequest.url.replace(
      '{projectId}',
      this.projectId,
    )
  }

  protected buildFilter = (exportFilter: ExportFilter) => {
    const { filter, parentId, parentType, filterFromParent } = exportFilter
    const activeFilters = filter ? constructFilterJson(filter) : {}
    if (parentId) {
      activeFilters['parent_id'] = []
      activeFilters['parent_id'].push(parentId)
    }

    if (parentType) {
      activeFilters['parent_type'] = []
      activeFilters['parent_type'].push(parentType)
    }

    if (filterFromParent) {
      filterFromParent.map(
        (fp) => (activeFilters[fp.filed] = fp.dateValue || fp.value),
      )
    }
    return activeFilters
  }

  protected prepareMeeting = (data: MeetingExport) => {
    const { template, meetingId, projectLogo } = data
    this.replaceUrl(template)
    template.geniusApiRequest.url = template.geniusApiRequest.url.replace(
      '{meetingID}',
      `${meetingId}`,
    )
    template.timeZone = new Date().getTimezoneOffset()
    template.projectLogo = projectLogo
    template.uploadApiRequest.url = template.uploadApiRequest.url.replace(
      '{organizationApiBaseUrl}',
      getOrganizationBaseUrl(),
    )
    template.uploadApiRequest.url = template.uploadApiRequest.url.replace(
      '{meetingID}',
      `${meetingId}`,
    )
    if (this.user) {
      const user = this.user
      template.sender = `${user.firstName} ${user.lastName}`
    }
    template.frontendUrl = window.location.origin
    template.displayName = data.displayName

    return template
  }

  protected checkColumn = (column: cc, columnName: string): boolean => {
    return (
      columnName.toLowerCase() === this.getWord(column.name) ||
      columnName.toLowerCase() === column.id.toLowerCase()
    )
  }

  private getWord = (word: string) => {
    const t = i18next.getFixedT('no')
    return t(word).toLowerCase()
  }

  protected orgUrl = getOrganizationBaseUrl

  protected sp = (column: string) => {
    return capFirstLetter(_(column))
  }

  protected capFirst = capFirstLetter
}

export default BaseExportService
