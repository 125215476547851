import { round, toInteger } from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import CircleIcon from 'src/components/widget/icons/CircleIcon'
import SquareIcon from 'src/components/widget/icons/SquareIcon'

export const getIcon = (
  entry: {
    type?: string
    color?: string
    stroke?: string
    fill?: string
  },
  payload?: { fill?: string },
) => {
  const color = entry.color ?? payload?.fill
  if (entry.type === 'line' || !!entry.stroke)
    return <CircleIcon color={color} />
  return <SquareIcon color={color} />
}

const TooltipContent: FC<TooltipProps<ValueType, NameType>> = ({
  payload,
  active,
}) => {
  const { t } = useTranslation()
  if (!payload?.length || !active) return null

  return (
    <div className="bg-white px-3 py-1 rounded border border-grey">
      {payload.map((entry, index) => (
        <div key={index} className="flex items-center gap-3">
          {getIcon(entry, entry.payload)}
          <span className="text-m-text-grey first-capitalize">
            {t(`${entry.name ?? ''}`)}
          </span>
          <span>{round(entry.value ? toInteger(entry.value) : 0, 2)}</span>
        </div>
      ))}
    </div>
  )
}

export default TooltipContent
