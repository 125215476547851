interface DropdownExplorerConfig {
  width: number
}

class DropDownExplorerStorageHandler {
  private readonly storageKey = 'dropDownExplorer'

  readonly readConfig = (key: string): DropdownExplorerConfig => {
    const storageString =
      window.localStorage.getItem(key) ||
      window.localStorage.getItem(this.storageKey) ||
      ''
    return storageString ? JSON.parse(storageString) : { width: 300 }
  }

  readonly updateConfig = (key: string, config: DropdownExplorerConfig) => {
    const configAsString = JSON.stringify(config)
    return window.localStorage.setItem(key, configAsString)
  }
}

export default new DropDownExplorerStorageHandler()
