import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { IRiskArea } from '../../service/OrgTypes'
import { createRiskArea, editRiskArea } from '../../service/RiskService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import { classNames } from '../../utility/utils'
import Checkbox from '../switchHoc/CheckBox'

interface ICreateRiskAreaFormProps {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  riskArea: IRiskArea
}

const RiskAreaForm = ({
  projectId,
  closeModal,
  editingMode,
  riskArea,
}: ICreateRiskAreaFormProps) => {
  const [id] = useState<number | undefined>(riskArea.id)
  const [name, setName] = useState<string>(riskArea.name)
  const [description, setDescription] = useState<string>(riskArea.description)
  const [quantityUnit, setQuantityUnit] = useState<string>(
    riskArea.quantity_unit,
  )
  const [active, setActive] = useState<boolean>(
    riskArea.include_risk_estimation,
  )

  const [nameErrorMessage, setNameErrorMessage] = useState<string>('')
  const [quantityUnitErrorMessage, setQuantityUnitErrorMessage] =
    useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'mt-2'),
    label: classNames(
      'flex',
      'items-center',
      'text-sm',
      'leading-5',
      'font-normal',
      'text-gray-500',
    ),
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false
    setNameErrorMessage('')
    setQuantityUnitErrorMessage('')
    setLoading(true)

    if (!name) {
      setNameErrorMessage(t('fill_in_the_name'))
      error = true
    }

    if (!quantityUnit) {
      setQuantityUnitErrorMessage(t('fill_in_quantity_unit'))
      error = true
    }

    if (!error) {
      if (editingMode) {
        editRiskArea({
          id,
          name,
          description,
          quantity_unit: quantityUnit,
          include_risk_estimation: active,
        }).then(() => {
          closeModal()
        })
      } else {
        createRiskArea(
          {
            id: undefined,
            name,
            description,
            quantity_unit: quantityUnit,
            include_risk_estimation: active,
          },
          projectId,
        ).then(() => {
          closeModal()
        })
      }
    }

    setLoading(false)
  }

  const onRiskAreaNameChange = (e: any) => {
    setName(e.target.value)
    setNameErrorMessage('')
  }

  const onRiskAreaDiscriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const onQuantityUnitChange = (e: any) => {
    setQuantityUnit(e.target.value)
    setQuantityUnitErrorMessage('')
  }

  const onActiveChange = () => {
    setActive((n) => !n)
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('name')}
          block={true}
          value={name}
          required={true}
          errorMessage={nameErrorMessage}
          onChange={onRiskAreaNameChange}
          autoFocus={true}
        />
        <Input
          label={t('quantity_unit')}
          block={true}
          required={true}
          value={quantityUnit}
          onChange={onQuantityUnitChange}
          errorMessage={quantityUnitErrorMessage}
        />
      </div>
      <div className={styleClass.inputGroup}>
        <Textarea
          isValid={false}
          label={t('description')}
          block={true}
          value={description}
          required={false}
          onChange={onRiskAreaDiscriptionChange}
        />
      </div>

      <div className={'mb-2 mt-4 pl-2 flex items-center'}>
        <label className={styleClass.label}>{t('triple_estimate')}</label>
        <div className={'pl-2 flex items-center'}>
          <Checkbox
            disableTab={true}
            onChange={onActiveChange}
            valueProp={active}
          />
        </div>
      </div>
      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        {editingMode ? (
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : t('update')}
          </Button>
        ) : (
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : t('add')}
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default RiskAreaForm
