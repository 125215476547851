import moment from 'moment'
import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { isEmpty, TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import { ITeam } from '../../service/OrgTypes'
import {
  deleteTeam,
  getProjectTeamsConfig,
  swapOrderTeam,
} from '../../service/TeamService'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import { stringComparator } from '../../utility/Utility'
import DeleteModal from '../delete-modal/DeleteModal'
import TeamForm from './TeamForm'

interface ITeamsListProps extends WithTranslation {
  projectId: number
}

interface ITeamssListState {
  teams: ITeam[]
  selectedTeam: ITeam
  loading: boolean
  showEditModal: boolean
  showDeleteModal: boolean
  showCreateModal: boolean
}

class TeamList extends PureComponent<ITeamsListProps, ITeamssListState> {
  public constructor(props: ITeamsListProps) {
    super(props)
    this.state = {
      teams: [],
      selectedTeam: {} as ITeam,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showCreateModal: false,
    }
  }

  private reload() {
    getProjectTeamsConfig(this.props.projectId)
      .then((res: ITeam[]) => {
        this.setState({
          teams: res,
          loading: false,
        })
      })
      .catch((err) => console.error(err))
  }

  private swapTeam = (id: number, seq: number) => {
    const fromTeam = this.state.teams[id].id

    swapOrderTeam(fromTeam, seq).then(() => {
      this.reload()
    })
  }

  public componentDidMount() {
    this.reload()
  }

  public componentDidUpdate(prevProps: ITeamsListProps) {
    if (prevProps !== this.props) {
      this.setState(
        {
          teams: [],
          loading: true,
        },
        () => {
          this.reload()
        },
      )
    }
  }

  private onEditItemClick = (team: ITeam) => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
      selectedTeam: team,
    }))
  }

  private onDeletItemClick = (team: ITeam) => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
      selectedTeam: team,
    }))
  }

  private openEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }))
  }

  private closeEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }))

    this.reload()
  }

  private reloadList = () => {
    this.reload()
  }

  private closeDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }))
  }

  private openCreateModal = () => {
    this.setState((prevState) => ({
      showCreateModal: !prevState.showCreateModal,
    }))
  }

  private closeCreateModal = () => {
    this.setState((prevState) => ({
      showCreateModal: !prevState.showCreateModal,
    }))

    this.reload()
  }

  private deleteTeam = () => {
    deleteTeam(this.state.selectedTeam)
      .then(() => {
        this.reload()
      })
      .catch((err) => console.error(err))

    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }))
  }

  public render() {
    const { teams, loading, showDeleteModal, showCreateModal, showEditModal } =
      this.state

    const { t } = this.props

    const columns = [
      {
        name: 'theme_name',
        size: '400',
        id: 'name',
        dataField: 'name',
        sortingField: 'name',
      },
      {
        name: 'main_process',
        size: '500',
        id: 'mainProcess',
        dataField: 'main_process',
        sortingField: 'main_process',
        comparator: (a: any, b: any, direction: boolean) =>
          a ? (b ? stringComparator(a.name, b.name, direction) : 0) : 0,
        cell: (mainProcess: any) => (
          <span>{mainProcess ? mainProcess.name : ''}</span>
        ),
      },
      {
        name: 'created_at',
        size: '130',
        id: 'createdAt',
        sortingField: 'created_at',
        dataField: 'created_at',
        cell: (created: any) => (
          <span>{created ? moment(created).format('L') : ''}</span>
        ),
      },
      {
        name: 'updated_at',
        size: '130',
        id: 'updatedAt',
        sortingField: 'updated_at',
        dataField: 'updated_at',
        cell: (updated: any) => (
          <span>{updated ? moment(updated).format('L') : ''}</span>
        ),
      },
      {
        name: 'order',
        size: '150',
        id: 'sequence',
        dataField: 'sequence',
        sortingField: 'sequence',
      },
    ]

    return (
      <div className={TabPageStyleClass.root}>
        <List
          actionButton={
            <Button
              onClick={this.openCreateModal}
              type={Button.ButtonType.PRIMARY}
            >
              {t('new_theme')}
            </Button>
          }
          data={teams}
          columns={columns}
          tableName={'teamsList'}
          itemsPerPage={0}
          onRowClick={this.onEditItemClick}
          actionMenu={[
            {
              name: t('delete'),
              action: this.onDeletItemClick,
            },
          ]}
          isRowDraggable={true}
          swapOrder={this.swapTeam}
        />

        <Modal
          size={'w-1/3'}
          show={showEditModal}
          closeModal={this.openEditModal}
          title={t('edit_theme')}
          maxWidth={600}
        >
          <TeamForm
            editingMode={true}
            team={this.state.selectedTeam}
            projectId={this.props.projectId}
            closeModal={this.closeEditModal}
            reloadList={this.reloadList}
          />
        </Modal>

        {!isEmpty(this.state.selectedTeam) && (
          <DeleteModal
            show={showDeleteModal}
            closeModal={this.closeDeleteModal}
            onDelete={this.deleteTeam}
            itemIdnType={t('theme_order', {
              order: this.state.selectedTeam.sequence,
            })}
            itemName={`${t('order')}: ${this.state.selectedTeam.sequence} - ${
              this.state.selectedTeam.name
            }`}
          />
        )}

        <Modal
          size={'w-1/3'}
          show={showCreateModal}
          closeModal={this.openCreateModal}
          title={t('add_theme')}
          maxWidth={600}
        >
          <TeamForm
            editingMode={false}
            team={{
              id: 0,
              name: '',
              sequence: teams.length + 1,
              main_process_id: 0,
            }}
            projectId={this.props.projectId}
            closeModal={this.closeCreateModal}
            reloadList={this.reloadList}
          />
        </Modal>

        {loading ? <Loader /> : null}
      </div>
    )
  }
}

export default withTranslation()(TeamList)
