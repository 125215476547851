import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IMatrixCellConfig,
  IRisk,
  IRiskAssessmentData,
} from '../../service/OrgTypes'
import Modal from '../../ui-elements/modal/Modal'
import { colorCorrectionRiskMatrix } from '../../utility/utils'
import RiskMatrixConfigForm from '../risk/RiskMatrixConfigForm'

interface ICell {
  count: number
  cellColor: string
  cellText: string
  id: string
}

interface IRiskMatrixComponentProps {
  title: string
  matrixType: string
  updateModal?: (updatedCellConfig: IMatrixCellConfig) => void
  conseqValueProp?: number
  likelyValueProp?: number
  columnsProp: number
  rowsProp: number
  configArray: IMatrixCellConfig[]
  editable?: boolean
  risk?: IRisk
  risks?: IRisk[]
  showFirstAssessment?: boolean
  showPreviousAssessment?: boolean
  showRiskIds?: boolean
  fontSize?: string
  fontWeight?: string
  getClickedRisk?: (ids: number[], column: number, row: number) => void
  selectedColumn?: number
  selectedRow?: number
  matrixOnly?: boolean
}

const RiskMatrixComponent = ({
  title,
  matrixType,
  updateModal,
  conseqValueProp = -1,
  likelyValueProp = -1,
  columnsProp,
  rowsProp,
  configArray = [],
  editable = false,
  risk,
  risks,
  showFirstAssessment,
  showPreviousAssessment,
  showRiskIds,
  fontSize,
  fontWeight,
  getClickedRisk,
  selectedColumn,
  selectedRow,
  matrixOnly = false,
}: IRiskMatrixComponentProps) => {
  const { t } = useTranslation()

  if (columnsProp < 0) {
    columnsProp = 0
  }
  if (rowsProp < 0) {
    rowsProp = 0
  }

  const createMatrixArray = () => {
    const array: ICell[][] = []
    for (let i = 0; i < columnsProp; i++) {
      array.push([])
      for (let _ = 0; _ < rowsProp; _++) {
        array[i].push({ cellColor: '', cellText: '', count: 0, id: '' })
      }
    }

    for (const config of configArray!) {
      const x = config.cValue - 1
      const y = config.lValue - 1

      array[x][y].cellColor = config.color
    }
    return array
  }

  const arrayMatrix = createMatrixArray()
  if (conseqValueProp! !== -1 && likelyValueProp! !== -1) {
    const matrixCell = arrayMatrix?.[conseqValueProp!]?.[likelyValueProp!]
    if (matrixCell) {
      arrayMatrix[conseqValueProp!][likelyValueProp!].cellText = 'O'
    }
  }

  const [cellArray, setCellArray] = useState<ICell[][]>(arrayMatrix)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [selectedMatrixCellConfig, setSelectedMatrixCellConfig] =
    useState<IMatrixCellConfig>({} as IMatrixCellConfig)

  useEffect(() => {
    setRiskIndexes()
  }, [
    title,
    matrixType,
    conseqValueProp,
    likelyValueProp,
    columnsProp,
    rowsProp,
    configArray,
    risk?.assessments,
    risks,
  ])

  const setRiskIndexes = () => {
    const array = createMatrixArray()
    if (risk) {
      const newAssessment = risk.assessments.filter(
        (a) => !a.isInitialAssessment,
      )
      newAssessment.forEach((assessment, key) => {
        placeAssessment(assessment, key, array)
      })
      const initial = risk.assessments.filter((a) => a.isInitialAssessment)
      initial.forEach((assessment, key) => {
        placeAssessment(assessment, key, array)
      })
      setCellArray(array)
    } else if (risks) {
      risks.forEach((element) => {
        const assessmentTypeId = `risk_${matrixType}_id`
        const assessmentList = element.assessments.filter(
          (x) => x[assessmentTypeId] != null,
        )
        placeAssessmentMultiple(
          { ...element, assessments: assessmentList },
          array,
        )
      })
      setCellArray(array)
    } else {
      const x = conseqValueProp > -1 ? conseqValueProp : -1
      const y = likelyValueProp > -1 ? likelyValueProp : -1
      if (x !== -1 && y !== -1 && array?.[x]?.[y]) {
        array[x][y].cellText = 'P'
      }
      setCellArray(array)
    }
  }

  const placeAssessmentMultiple = (risk: IRisk, matrix: ICell[][]) => {
    const recordId = risk.record_id.split('-').pop()
    const assessment = showFirstAssessment
      ? risk.assessments.filter((a) => a.isInitialAssessment).slice(-1)[0]
      : showPreviousAssessment
        ? risk.assessments.slice(-2)[0]
        : risk.assessments.slice(-1)[0]
    if (risk && assessment) {
      const riskIndex = (assessment.messureIndex ?? 0) - 1
      const probabilityIndex = (assessment.possibilityIndex ?? 0) - 1
      if (probabilityIndex !== -1 && riskIndex !== -1 && matrix?.length) {
        matrix[riskIndex][probabilityIndex].count += 1
        const cellText =
          matrix[riskIndex][probabilityIndex].cellText === ''
            ? recordId
            : ', ' + recordId
        matrix[riskIndex][probabilityIndex].cellText += cellText
        const id =
          matrix[riskIndex][probabilityIndex].id === ''
            ? risk.id
            : ', ' + risk.id
        matrix[riskIndex][probabilityIndex].id += id
      }
    }
  }

  const placeAssessment = (
    assessment: IRiskAssessmentData,
    key: number,
    matrix: ICell[][],
  ) => {
    if (
      matrix &&
      assessment.possibilityIndex &&
      assessment.possibilityIndex > 0 &&
      assessment.messureIndex &&
      assessment.messureIndex > 0
    ) {
      matrix[assessment.messureIndex - 1][
        assessment.possibilityIndex - 1
      ].cellText += setTypeOfAssessment(assessment, key)
    }
  }

  const setTypeOfAssessment = (
    assessment: IRiskAssessmentData,
    key: number,
  ) => {
    if (!risk) {
      return null
    }
    if (assessment.isInitialAssessment) {
      return 'P1'
    } else {
      return `P${key + 2} `
    }
  }

  const getLabel = (cell: ICell) => {
    if (showRiskIds) return cell.cellText ?? ''
    return cell.count !== 0
      ? cell.count
      : cell.cellText !== ''
        ? cell.cellText
        : ''
  }

  const cellClicked = async (cell: ICell, column: number, row: number) => {
    if (cell.count !== 0) {
      const ids = cell.id.split(',').map((id: string) => Number(id))
      getClickedRisk?.(ids, column, row)
    } else {
      getClickedRisk?.([], column, row)
    }
  }

  const closeEditModal = () => {
    setShowEditModal(false)
  }

  const createMatrix = (array: ICell[][]) => {
    if (array?.length !== columnsProp && array[0]?.length !== rowsProp) {
      return null
    }
    const matrix = []

    for (let i = rowsProp; i >= 0; i--) {
      for (let j = -1; j < columnsProp; j++) {
        if (i === 0 || j === -1) {
          let label = ''
          switch (true) {
            case i !== 0 && j === -1:
              label = `${i}`
              break
            case j !== -1 && i === 0:
              label = `${j + 1}`
              break
          }
          matrix.push(
            <div
              key={i * 10 + j}
              className={'flex flex-col w-full h-full justify-center m-0.5'}
            >
              <span className={'text-sm'}>{label}</span>
            </div>,
          )
        } else {
          const column = j
          const row = i - 1
          const cell = array?.[column]?.[row]
          if (cell) {
            const label = editable ? '' : `${getLabel(cell)}`

            const showPointer = cell.count !== 0 || editable
            const showBorder = row === selectedRow && column === selectedColumn
            matrix.push(
              <span
                key={i * 10 + j}
                onClick={() =>
                  editable
                    ? changeColor(column, row)
                    : showPointer && cellClicked(cell, column, row)
                }
                className={`${colorCorrectionRiskMatrix(
                  cell.cellColor,
                )} w-full h-full m-auto ${showPointer && 'cursor-pointer'} ${
                  showBorder && 'border border-black'
                } font-${fontWeight ?? 'normal'} text-${
                  fontSize ?? 'sm'
                } flex justify-center items-center m-0`}
              >
                {label}
              </span>,
            )
          }
        }
      }
    }
    return (
      <div className={'flex flex-grow'}>
        <div className={`flex flex-col justify-center`}>
          <span
            className={
              'font-normal text-sm text-gray-700 -rotate-90 transform h-fit'
            }
          >
            {t('probability')}
          </span>
        </div>
        <div className={'flex flex-col w-full h-full'}>
          <div
            className={`h-full w-full grid grid-cols-${
              columnsProp + 1
            } grid-rows-${
              rowsProp + 1
            } gap-1 items-stretch text-gray-600 text-center`}
          >
            {matrix}
          </div>
          <span
            className={`font-normal text-center text-sm text-gray-700 pb-1 pt-2 h-fit`}
          >
            {title}
          </span>
        </div>
      </div>
    )
  }

  const changeColor = (column: number, row: number) => {
    const array: IMatrixCellConfig[] = JSON.parse(JSON.stringify(configArray))
    const index = getIndex(column + 1, row + 1, array)
    if (index !== -1) {
      setShowEditModal(true)
      setSelectedMatrixCellConfig(array[index])
    }
  }

  const updateMatrixCell = (matrixCell: IMatrixCellConfig) => {
    updateModal!(matrixCell)
    closeEditModal()
  }

  const getIndex = (column: number, row: number, arr: IMatrixCellConfig[]) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]['cValue'] === column && arr[i]['lValue'] === row) {
        return i
      }
    }
    return -1 // to handle the case where the value doesn't exist
  }

  return (
    <div
      className={`flex flex-col bg-white w-full h-full p-1 ${
        !matrixOnly ? 'border border-gray-300' : ''
      }`}
    >
      {!matrixOnly && (
        <div className={'my-4 border-b text-gray-100 mb-2'}>
          <span className="font-semibold text-sm text-gray-600 block pt-0 pb-2 pl-8">
            {title} {t('matrix')}
          </span>
        </div>
      )}
      <div
        key="matrix"
        className={`flex flex-col h-full ${
          !matrixOnly ? 'pr-12 w-96' : 'w-full'
        }`}
      >
        {createMatrix(cellArray)}
      </div>
      {editable! ? (
        <Modal
          show={showEditModal}
          closeModal={closeEditModal}
          title={t('change_the_color_of_the_cell', {
            val: `(${selectedMatrixCellConfig.cValue},${selectedMatrixCellConfig.lValue})`,
          })}
          maxWidth={600}
        >
          <div>
            <RiskMatrixConfigForm
              matrixType={matrixType}
              matrixCellConfig={selectedMatrixCellConfig}
              closeModal={closeEditModal}
              updateModal={updateMatrixCell}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

export default RiskMatrixComponent
