import * as React from 'react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfirmModal } from 'src/hooks/useConfirmModal'
import { capFirstLetter, classNames } from '../../utility/utils'
import Icon from '../icon/Icon'

/**
 * Pass maxWidth as per column of modal content i.e 400 for 1 column length, 800 for 2, etc
 * If it is a detail modal or has a wide List or not sure, please set maxWidth to null
 *
 */
export interface IModalProps {
  show?: boolean
  title: string | JSX.Element
  size?: string
  smallHeading?: boolean
  closeModal: () => void
  minHeight?: string
  height?: string | number
  noHorizontalScroll?: boolean
  noVerticalScroll?: boolean
  noPaddingBottom?: boolean
  maxWidth?: number | null
  modalBodyClassname?: string
  confirmOutsideClick?: boolean
  notClipContent?: boolean
}

const Modal = ({
  show,
  children,
  closeModal,
  title,
  size,
  smallHeading,
  height,
  minHeight,
  noHorizontalScroll,
  noVerticalScroll,
  maxWidth,
  modalBodyClassname = '',
  confirmOutsideClick = false,
  notClipContent = false,
}: React.PropsWithChildren<IModalProps>) => {
  const styleClass = {
    root: classNames(
      'z-[80]',
      'bg-grey-transparent',
      'fixed',
      'inset-0',
      'flex',
      'items-center',
      'justify-center',
    ),
    modalContainer: (widthSize: string | undefined) =>
      classNames(
        'bg-white',
        'mx-auto',
        'mt-14',
        'shadow',
        'rounded',
        widthSize ? `md:w-9/10 lg:${widthSize}` : 'md:w-9/10 lg:w-5/6',
        'w-full',
        'flex',
        'flex-col',
        'max-h-90vh',
        notClipContent ? 'overflow-visible' : 'overflow-hidden',
      ),
    header: classNames(
      'flex',
      'flex-row',
      'justify-between',
      'bg-blue-50',
      'px-6',
      'py-[10px]',
      'rounded-tl',
      'rounded-tr',
      'mb-2',
    ),
    body: (noScrollX: boolean, noVerticalScroll: boolean) =>
      classNames(
        noVerticalScroll
          ? 'overflow-y-hidden'
          : notClipContent
            ? 'overflow-y-visible'
            : 'overflow-y-auto',
        noScrollX
          ? 'overflow-x-hidden'
          : notClipContent
            ? 'overflow-x-visible'
            : 'overflow-x-auto',
        'max-h-modal-height',
        'px-4',
        'rounded-md',
        'h-full',
        modalBodyClassname ?? '',
      ),
    largeHeading: classNames(
      'text-base',
      'leading-6',
      'font-medium',
      'text-gray-800',
      'truncate',
    ),
    smallHeading: classNames(
      'font-extralight',
      'text-grey-dark',
      'text-sm',
      'truncate',
    ),
  }

  const { t } = useTranslation()
  const { confirm } = useConfirmModal()

  const ref = useRef<HTMLDivElement>(null)
  const handleOutsideClick = async (e: MouseEvent) => {
    if (ref && ref.current && ref.current === e.target) {
      if (confirmOutsideClick) {
        const isConfirmed = await confirm({
          title: t('you_have_unsaved_changes'),
          message: t('are_you_sure_you_want_to_cancel') + '?',
          confirmText: t('yes'),
          cancelText: t('no'),
        })
        if (!isConfirmed) {
          return
        }
      }
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  })

  const getContainerStyles = () => {
    return {
      maxWidth: maxWidth ? maxWidth : 1700,
      height,
      minHeight,
    }
  }

  return (
    <React.Fragment>
      {show ? (
        <div ref={ref} className={styleClass.root}>
          <div
            style={getContainerStyles()}
            className={styleClass.modalContainer(size)}
          >
            <div className={styleClass.header}>
              <div
                className={
                  smallHeading
                    ? styleClass.smallHeading
                    : styleClass.largeHeading
                }
              >
                {typeof title === 'string' ? capFirstLetter(title) : title}
              </div>
              <Icon
                style={{ opacity: 0.5 }}
                icon={Icon.IconType.CLOSE_GRAY}
                onClick={closeModal}
                className={'cursor-pointer flex-shrink-0'}
              />
            </div>
            <div
              id={'modal-body'}
              style={getContainerStyles()}
              className={styleClass.body(
                !!noHorizontalScroll,
                !!noVerticalScroll,
              )}
            >
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default Modal
