import * as React from 'react'
import { Tab, TabList, Tabs } from 'react-tabs'
import { capFirstLetter, classNames } from '../../utility/utils'
import { ProTabsStyles } from './Utils'

interface ITabsWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  tabIndex: number | undefined
  onSelect: (e: any) => void
  tabItems: string[]
  fixedTabList?: boolean
  tabListStyle?: string
  onTop?: boolean
}

const TabsWrapperStyleClass = {
  tabs: classNames('w-auto'),
  tabList: (fixedTabList: boolean, tabListStyle: string, onTop?: boolean) =>
    classNames(
      'flex',
      'items-center',
      'flex-wrap',
      fixedTabList ? 'fixed' : '',
      fixedTabList && 'w-screen',
      fixedTabList && onTop ? 'z-20' : 'z-10',
      fixedTabList && 'bg-body',
      '-mt-3',
      'pb-2',
      'border-b border-gray-200',
      tabListStyle ? tabListStyle : '',
    ),
  tab: classNames(
    'hover:text-blue-root',
    'inline-block',
    'relative',
    'text-sm',
    'font-normal',
    'ml-3',
    'pt-3',
    'pb-1',
    'px-1',
    'list-none',
    'cursor-pointer',
    'text-gray-500',
    'focus:text-gray-700 focus:border-gray-300',
    'whitespace-nowrap',
  ),
  selectedTab: classNames(
    'text-blue-root',
    'font-medium',
    'border-b-2',
    'border-blue-root',
    'text-blue-root',
    'hover:text-gray-700 hover:border-gray-300 focus:outline-none',
    'focus:outline-none focus:text-blue-root focus:border-blue-root',
  ),
}

export const TabsWrapper = ({
  tabIndex = 0,
  onSelect,
  children,
  tabItems,
  fixedTabList = false,
  onTop = false,
}: ITabsWrapperProps) => {
  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={(index) => onSelect(index)}
      className={ProTabsStyles.tabs}
    >
      <TabList
        className={TabsWrapperStyleClass.tabList(
          fixedTabList,
          'border-none',
          onTop,
        )}
      >
        {tabItems &&
          tabItems.map((tabName) => (
            <Tab
              key={tabItems.indexOf(tabName)}
              className={ProTabsStyles.tab}
              selectedClassName={ProTabsStyles.selectedTab}
            >
              {capFirstLetter(tabName)}
            </Tab>
          ))}
      </TabList>
      {children}
    </Tabs>
  )
}
