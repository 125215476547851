import { createContext } from 'react'
import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'
import { IMPORT_TYPE } from '../../components/notifications/import/ImportNotificationItem'
import { ImportTask } from './ImportTypes'

interface IImportContextState {
  importTasks: ImportTask[]
  pullInterval: number | null
  selectedItem?: ImportTask
  metaData: IMetaField[]
}
interface IImportContext {
  actions: {
    addImportTask: (res: ImportTask) => void
    updateImportTask: (res: ImportTask) => void
    removeImportTask: (jobId: number) => void
    getImportTask: (jobId: number) => ImportTask | undefined
    reload: () => void
    setSelectedItem: (importType?: IMPORT_TYPE, item?: ImportTask) => void
  }
  state: IImportContextState
}

export const ImportContext = createContext<IImportContext>({} as IImportContext)
