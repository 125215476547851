import { IFolderData } from 'src/document/types/IFolderData'
import { MovedSteps } from '../components/FileUpload/types'
import { IFileContainer } from '../types/IFileContainer'
import { IFolderTreeData } from '../types/IFolderTreeData'
import { GenericDocumentService } from './GenericDocumentService'

const steps = 'steps'
const add_reviewers = 'add_reviewers'
const file_containers = 'file_containers'
const folder_tree = 'tree'
const blue_folders = 'blue_folders'
const blue_folders_without_mandatory_metadata =
  'blue_folders_without_mandatory_metadata'

class FolderService extends GenericDocumentService {
  constructor() {
    super('folders')
  }

  readonly createFolder = async (newFolder: Omit<IFolderData, 'id'>) => {
    return await this.doRequet<void>({
      method: 'POST',
      body: newFolder,
    })
  }

  readonly updateFolder = (
    folder: IFolderData,
    folderId: IFolderData['id'],
  ) => {
    const url = this.getUrl(folderId)
    return this.doRequet<IFolderData>({ url, body: folder, method: 'PUT' })
  }

  readonly getFolders = () => {
    return this.doRequet<IFolderData[]>()
  }

  readonly getBlueFolders = () => {
    const url = this.getUrl(blue_folders)
    return this.doRequet<IFolderData[]>({ url })
  }

  readonly getBlueFoldersWithoutMandatoryMetadata = () => {
    const url = this.getUrl(blue_folders_without_mandatory_metadata)
    return this.doRequet<IFolderData[]>({ url })
  }

  readonly getFolderTree = (reload?: boolean) => {
    const url = this.getUrl(folder_tree)
    return this.doRequet<IFolderTreeData[]>({
      method: 'POST',
      url,
      body: { reload },
    })
  }

  readonly getSingleFolder = (folderId: IFolderTreeData['id']) => {
    const url = this.getUrl(folderId)
    return this.doRequet<IFolderData>({ url })
  }

  readonly deleteFolder = (folderId: IFolderData['id']) => {
    const url = this.getUrl(folderId)
    return this.doRequet<void>({ url, method: 'DELETE', text: true })
  }

  readonly copyFolder = (folderId: IFolderData['id'], newName: string) => {
    const url = this.getUrl(folderId)
    const duplicationUrl = `${url}/duplicate`
    return this.doRequet<void>({
      url: duplicationUrl,
      method: 'POST',
      body: { name: newName },
      text: true,
    })
  }

  readonly addReviewers = (
    folderId: IFolderData['id'],
    stepId: number,
    reviewers: number[],
  ) => {
    const url = this.getUrl(folderId, steps, stepId, add_reviewers)
    return this.doRequet<void>({ url, method: 'PUT', body: { reviewers } })
  }

  readonly getContainers = (folderId: number) => {
    const url = this.getUrl(folderId, 'file_containers_minimized')
    return this.doRequet<IFileContainer[]>({ url, method: 'GET' })
  }

  readonly moveMultiple = (folderId: number, data: MovedSteps[]) => {
    const url = this.getUrl(folderId, file_containers, 'move_multiple')
    return this.doRequet<void>({ url, method: 'PUT', body: { data } })
  }

  readonly uploadUsingLink = (docId: number, file_url: string) => {
    const url = this.getUrl(docId, 'upload_file')
    return this.doRequet<void>({ url, method: 'POST', body: { file_url } })
  }
}

export default new FolderService()
