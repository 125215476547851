import moment from 'moment'

interface ITimePickerProps {
  date: moment.Moment
  onTimeSubmitted: (date: moment.Moment) => void
  minimumTime?: moment.Moment
  onlyQuarter?: boolean
}

const TimePicker = ({
  date,
  onTimeSubmitted,
  minimumTime,
  onlyQuarter,
}: ITimePickerProps) => {
  // handel on change
  const onTimeChange = (e: any) => {
    const localTime = moment(e.target.value, 'HH:mm')
    const dateClone = moment(date, 'HH:mm')
    if (localTime.isValid()) {
      dateClone.set('hour', localTime.get('hour'))
      dateClone.set('minute', localTime.get('minute'))
      if (minimumTime) {
        if (dateClone.isBefore(minimumTime)) {
          return
        }
      }
      onTimeSubmitted(dateClone)
    }
  }

  return (
    <label className="text-gray-700" htmlFor="time">
      <input
        step={onlyQuarter ? 15 * 60 : 60}
        type="time"
        value={date.format('HH:mm')}
        onChange={onTimeChange}
        min={minimumTime ? minimumTime.format('HH:mm') : undefined}
        className="appearance-none border border-gray-300 w-full
                py-0.5 px-1 bg-white text-black placeholder-gray-400
                text-base font-light focus__from-blue focus__ring-2 focus__ring-purple-600 flex-1"
      />
    </label>
  )
}

export default TimePicker
