import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useDebouncedValue from 'src/hooks/useDebouncedValue'

type Props = {
  loadingText?: string

  // Controls showing the loader. Use this to get animated appears
  active?: boolean
}

const BackgroundLoader = ({ loadingText, active }: Props) => {
  const { t } = useTranslation()

  const debouncedActive = useDebouncedValue(!!active, 500, (v) => v)
  return (
    <div className="absolute bottom-0 right-16 z-20 overflow-hidden bottom-info">
      <div
        className={twMerge(
          'bg-white rounded-lg shadow-lg z-50 shadow-grey h-8 text-blue-root m-4 p-1 flex items-center gap-1 transition-transform duration-300 translate-y-0',
          !debouncedActive && 'translate-y-60',
        )}
      >
        <svg
          className="animate-spin h-6 text-blue-root"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            strokeWidth="4"
            stroke="currentColor"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <span className="text-sm">{loadingText ?? t('loading') + '...'}</span>
      </div>
    </div>
  )
}

export default BackgroundLoader
