import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { classNames } from 'src/utility/utils'

export interface ICheckBoxProps {
  valueProp?: boolean
  className?: string
  onChange: (v: boolean, shift?: boolean) => void
  disableTab?: boolean
  disable?: boolean
  validateCheck?: (v: boolean, shift?: boolean) => boolean
  controlValueInParent?: boolean
  readonly?: boolean
  id?: string
}
const CheckBox: React.FC<ICheckBoxProps> = ({
  valueProp,
  onChange,
  className,
  disableTab,
  disable,
  validateCheck,
  controlValueInParent = false,
  readonly = false,
  id,
}: ICheckBoxProps) => {
  const styleClass = {
    root: classNames(
      disable ? 'invert-[.15]' : ' ',
      'accent-pink-500',
      'w-3',
      'h-3',
      'relative',
      className,
    ),
  }

  const [value, setValue] = useState(!!valueProp)
  const shiftIsPressed = useRef(false)
  const onValueChange = () => {
    const validatedCheck = validateCheck
      ? validateCheck(!value, shiftIsPressed.current)
      : true
    if (validatedCheck) {
      onChange(!value, shiftIsPressed.current)
      setValue((n) => !n)
    }
  }

  useEffect(() => {
    setValue(!!valueProp)
  }, [valueProp])

  if (readonly)
    return (
      <input
        type="checkbox"
        tabIndex={disableTab ? -1 : undefined}
        className={styleClass.root}
        defaultChecked={valueProp}
        disabled={!!disable}
        key={!!value ? 1 : 2}
      />
    )

  return (
    <input
      type="checkbox"
      id={id}
      tabIndex={disableTab ? -1 : undefined}
      onChange={
        !controlValueInParent ? onValueChange : () => onChange(!valueProp)
      }
      className={styleClass.root}
      checked={controlValueInParent ? valueProp : value}
      disabled={!!disable}
      onClick={(e) => {
        shiftIsPressed.current = e.shiftKey
      }}
      key={!!value ? 1 : 2}
    />
  )
}
export default CheckBox
