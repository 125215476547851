import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import { useContext, useEffect, useState } from 'react'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { IRoom, ITest } from 'src/service/OrgTypes'
import { editRoom, getRoom } from 'src/service/RoomService'
import {
  getProjectSections,
  getSectionFloors,
} from 'src/service/SectionService'
import { getRoomTests } from 'src/service/TestService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import { Icons } from 'src/ui-elements/icon/Icon'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { classNames, modalUrlBuilder } from 'src/utility/utils'
import InspectorSections from '../../../document/components/Inspector/InspectorSections'
import FixedPane from '../../../ui-elements/fixed-pane/FixedPane'
import InlineComponentsWrapper from '../../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from '../../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../../ui-elements/page-display/inline-components/TextInlineInputComponent'

interface IRoomInspectorPanel {
  roomId: number
  open: boolean
  onClose: () => void
  defaultIndex?: number
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
}

const RoomInspectorPanel: React.FC<IRoomInspectorPanel> = ({
  roomId,
  open,
  onClose,
  defaultIndex = 0,
}) => {
  const { t } = useMultipleKeysTranslation()
  const history = useHistory()
  const modalContext = useContext(ModalContext)
  const [room, setRoom] = useState<IRoom>()
  const [tests, setTests] = useState<ITest[]>([])
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state?.currentProject.id

  const loadSystemData = () => {
    getRoom(roomId).then((res: IRoom) => {
      setRoom(res)
    })
  }

  const getTests = () => {
    getRoomTests(projectId, roomId).then((res) => {
      if (res.length) {
        setTests(res)
      }
    })
  }

  useEffect(() => {
    loadSystemData()
  }, [projectId, roomId])

  const onTestsClick = (id: number) => {
    const modalURL = modalUrlBuilder(projectId, 'test', id)
    history.push(modalURL)
    modalContext.actions.checkURL()
  }

  const getRows = (): Array<{
    name: string
    icon: any
    activeIcon?: any
    content: JSX.Element
    onClick?: () => void
  }> => [
    {
      name: t('room_details'),
      icon: Icons.SYSTEM_GRAY,
      activeIcon: Icons.SYSTEM_BLACK,
      content: getMainContent(),
      onClick: loadSystemData,
    },
    {
      name: t('tests'),
      icon: Icons.CHECK_GRAY,
      activeIcon: Icons.CHECK_GRAY,
      onClick: getTests,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('name'),
            t('test_type'),
            t('system_type'),
            t('responsible'),
          ]}
          rowsData={
            tests
              ? tests.map((test) => {
                  return {
                    cells: [
                      test?.record_id ?? '',
                      test?.name ?? '',
                      `${test?.test_type?.name ?? ''}`,
                      `${test?.responsible?.firstName ?? ''} ${
                        test?.responsible?.lastName ?? ''
                      }`,
                      ,
                    ],
                    id: test.id,
                  }
                })
              : []
          }
          handleClick={onTestsClick}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={roomId} parentType="Room" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={roomId} parentType="Room" />,
    },
  ]

  const onChangeInput = async (update: Partial<IRoom>) => {
    editRoom({
      ...update,
      id: roomId,
    }).then(() => {
      loadSystemData()
    })
  }

  const getFloors = async () => {
    return room?.section_id ? await getSectionFloors(room?.section_id) : []
  }

  const getMainContent = () => {
    return (
      <div className="-mt-3 -ml-6">
        <InlineComponentsWrapper padding="left" inputWidth={'w-96'}>
          <TextInlineInputCompontent
            label="room_name"
            value={room?.room_name}
            validate={(newValue: string) => {
              if (!newValue?.length) {
                return t('required')
              }
              return undefined
            }}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ room_name: newValue })
            }}
          />
          <SelectorInlineInputComponent
            getItemLabel={(item) => item?.name ?? ''}
            label={'section'}
            getItems={() => getProjectSections(projectId)}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ section_id: newValue })
            }}
            selectedId={room?.section_id}
            initialItem={room?.section}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItemLabel={(item) => item?.name ?? ''}
            label={'floor'}
            getItems={() => getFloors()}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ floor_id: newValue })
            }}
            selectedId={room?.floor_id}
            initialItem={room?.floor}
            inspectorPanel={true}
          />
          <TextInlineInputCompontent
            label="function_number"
            value={room?.function_number}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ function_number: newValue })
            }}
          />
          <TextInlineInputCompontent
            label="function_name"
            value={room?.function_name}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ function_name: newValue })
            }}
          />
          <TextInlineInputCompontent
            label="sub_function_number"
            value={room?.sub_function_number}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ sub_function_number: newValue })
            }}
          />
          <TextInlineInputCompontent
            label="sub_function_name"
            value={room?.sub_function_name}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ sub_function_name: newValue })
            }}
          />
          <TextInlineInputCompontent
            label="functional_room_number"
            value={room?.functional_room_number}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ functional_room_number: newValue })
            }}
          />
          <TextInlineInputCompontent
            label="geographical_room_number"
            value={room?.geographical_room_number}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ geographical_room_number: newValue })
            }}
          />
          <TextInlineInputCompontent
            label="planned_area"
            value={`${room?.planned_area}`}
            type={'number'}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ planned_area: parseInt(newValue, 10) })
            }}
          />
          <TextInlineInputCompontent
            label="built_area"
            value={`${room?.built_area}`}
            type={'number'}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ built_area: parseInt(newValue, 10) })
            }}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }

  return (
    <FixedPane
      title={room?.room_name ?? ''}
      show={open}
      onClose={() => onClose()}
      className={'w-[600px]'}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.ROOM,
        ids: {
          sectionId: room?.section_id ?? 0,
          floorId: room?.floor_id ?? 0,
          roomId: room?.id ?? 0,
        },
      }}
    >
      <div className={styleClass.root}>
        <InspectorSections defaultIndex={defaultIndex} sections={getRows()} />
      </div>
    </FixedPane>
  )
}
export default RoomInspectorPanel
