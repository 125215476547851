import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import { capFirstLetter } from 'src/utility/utils'

const GenericList: React.FC<{
  data: any[]
  columns: IListColumns[]
}> = ({ data, columns }) => {
  const { t } = useTranslation()

  return (
    <table
      className="table-fixed text-gray-500 bg-gray-50 border-gray-300 border w-full"
      width={1}
    >
      <colgroup>
        {columns.map((column, index) => (
          <col key={index} width={column.size} />
        ))}
      </colgroup>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th
              key={index}
              className="text-left font-medium bg-gray-200 text-sm leading-6"
            >
              <div className="border-gray-300 border px-1 py-0.5">
                {capFirstLetter(t(column.name))}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((data, index) => (
          <tr key={index}>
            {columns.map((column, index) => {
              return (
                <td
                  key={index}
                  className="pl-3 text-sm leading-5 py-1 mr-4 h-8 truncate"
                >
                  {column.cell?.(data[column.dataField]) ||
                    data[column.dataField]}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default GenericList
