import moment from 'moment'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { filterDelivery } from 'src/service/DeliveryService'
import { getTable, setTableConfig } from 'src/service/TableKeeperService'
import {
  actionSource,
  booleanTypes,
  statusFilterTypes,
} from '../../service/SystemValues'
import { filterCase, IListColumns, ISorting } from './ListTypes'

interface IListContextProps extends WithTranslation {
  projectId: number
  data: any[]
  columns: IListColumns[]
  parent_type?: string
  parentId?: number | number[]
  filterParent: string
  tableName: string
  itemsPerPage: number
  resourceType?: ResourceType
  getFilteredData?: (
    filters: IListFilter[],
    sorting: ISorting,
    page: number,
  ) => void
  totalPages?: number
  reload?: boolean
  userId: number
  disableConfigStore?: boolean
  sortBackend?: boolean
  columnChange?: (ids: string[]) => void
  t: any
  tableReset: boolean
  setResetToDone: () => void
  expandedRows?: number[]
  children: React.ReactNode
}

export interface IListContextState {
  data: any[]
  columns: IListColumns[]
  columnsSize: IListColumns[]
  filters: IListFilter[]
  sorting: ISorting
  fullSearchValue: string
  selectedColumns: IListStoreData[]
  loading: boolean
  intialload: boolean
  activeFilter: any
  tableName: string
  currentPage: number
  totalPages: number
  parentType?: string
  expandedRows?: number[]
}

export interface IListFilter {
  column: IListColumns
  open: boolean
  searchValue: string
  filterOptions: ISingleFilter[]
  isDate: boolean
}

export interface ISingleFilter {
  value: string
  name: string
  active: boolean
  dateValue?: string[]
}

export interface IListContext {
  actions: IListContextActions
  state: IListContextState
}

export interface IListStoreData {
  id: string
  active: boolean
  size?: string
  filters?: ISingleFilter[]
}

type RemoteTable = {
  selectedColumns: any
}

interface IListContextActions {
  setFilter: (column: IListColumns, filter: ISingleFilter) => void
  setDateFilter: (
    column: IListColumns,
    startDate: moment.Moment,
    endDate: moment.Moment,
  ) => void
  setTextFilter: (column: IListColumns, text: string) => void
  onFullListSearch: (value: string) => void
  sortList: (column: IListColumns) => void
  moveColumn: (fromIndex: number, toIndex: number) => void
  openFilter: (column: IListColumns) => void
  filterSearch: (column: IListColumns, search: string) => void
  clearSingleFilter: (filter: IListFilter) => void
  getFieldValue: (column: IListColumns, data: any, field: string) => string
  updateColumnSize: (column: IListColumns, size: string) => void
  removeColumn: (column: IListColumns) => void
  addColumn: (column: IListColumns) => void
  saveTableConfig: () => void
  onPageChange: (page: number, onChangeEnd: () => void) => void
  reloadDeliveries: () => void
  onPageClick: () => void
}

export enum ResourceType {
  TASK = 'task',
  DELIVERY = 'delivery',
  DELIVERY_THEMELOG = 'delivery_themeLog',
  KEYPOINT = 'keypoint',
  KEYPOINT_THEMELOG = 'keypoint_themeLog',
  RISK = 'risk',
  CONTRACT = 'CONTRACT',
  DISCIPLINE = 'DISCIPLINE',
  IMPROVEMENT = 'improvement',
  PROJECT_USERS = 'PROJECT_USERS',
}

export type ListContext = IListContext
export const ListContext = React.createContext<ListContext>({
  actions: {} as IListContextActions,
  state: {} as IListContextState,
})

export const ListContextConsumer = ListContext.Consumer

class ListContextProvider extends React.Component<
  IListContextProps,
  IListContextState
> {
  constructor(props: IListContextProps) {
    super(props)
    this.state = {
      data: props.data,
      columns: props.columns,
      columnsSize: [],
      filters: [],
      sorting: { field: '', direction: false, active: false } as ISorting,
      fullSearchValue: '',
      loading: false,
      selectedColumns: [],
      intialload: true,
      activeFilter: '',
      tableName: props.tableName,
      currentPage: 1,
      totalPages: props.totalPages ? props.totalPages : 0,
      parentType: this.props.parent_type,
      expandedRows: this.props.expandedRows,
    }
  }

  public componentDidMount() {
    this.prepareColumns()
  }

  public componentDidUpdate(prevProps: IListContextProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.columns !== this.props.columns
    ) {
      this.setState({
        data: this.props.data,
        columnsSize: this.props.columns,
        totalPages: this.props.totalPages ? this.props.totalPages : 0,
      })
    }

    if (prevProps.expandedRows !== this.props.expandedRows) {
      this.setState({
        expandedRows: this.props.expandedRows,
      })
    }

    if (
      prevProps.tableReset !== this.props.tableReset &&
      this.props.tableReset
    ) {
      this.prepareColumns(true)
      !this.props.disableConfigStore && this.tableToRemote()
      this.props.setResetToDone()
    }

    if (prevProps.projectId && prevProps.projectId !== this.props.projectId) {
      this.prepareColumns()
    }

    if (
      !this.state.intialload &&
      prevProps.projectId === this.props.projectId &&
      prevProps.reload !== this.props.reload
    ) {
      this.constructFilterJson()
    }
  }

  private prepareColumns = async (resetFilter?: boolean) => {
    const storedTable = await this.tableFromRemote()
    if (!this.props.disableConfigStore && storedTable) {
      const selectedColumns = this.checkForNewColumns(storedTable).map(
        (selectedColumn: IListStoreData) => {
          if (selectedColumn) {
            return {
              ...selectedColumn,
              filters: resetFilter ? [] : selectedColumn.filters,
            }
          } else {
            return selectedColumn
          }
        },
      )

      const columns = selectedColumns.map((selectedColumn: IListStoreData) => {
        const column: IListColumns | undefined = this.props.columns.find(
          (col: IListColumns) => col.id === selectedColumn.id,
        )
        if (column) {
          return {
            ...column,
            size: selectedColumn.size ? selectedColumn.size : column.size,
            filter: selectedColumn.filters
              ? selectedColumn.filters
              : column.filter,
          }
        } else {
          return column
        }
      })

      const newColumns = columns.filter(
        (c) => c !== undefined,
      ) as IListColumns[]
      this.setState(
        {
          intialload: true,
          columns: newColumns,
          selectedColumns,
          sorting: resetFilter
            ? { field: '', active: false, direction: false }
            : storedTable.sort,
        },
        () => {
          this.reloadFilters()
        },
      )
    } else {
      this.setState(
        {
          intialload: true,
          columns: this.props.columns,
          selectedColumns: this.prepareStoreConfig(this.props.columns),
          columnsSize: this.props.columns,
        },
        () => {
          this.reloadFilters()
        },
      )
    }
  }

  private checkForNewColumns = (storedTable: any) => {
    const { columns } = this.props
    const { selectedColumns } = storedTable
    const updatedColumn = [...selectedColumns]
    columns.map((column, index) => {
      if (!selectedColumns.some((sc: IListStoreData) => sc.id === column.id)) {
        updatedColumn.splice(index, 0, ...this.prepareStoreConfig([column]))
      }
    })
    return updatedColumn
  }

  private prepareStoreConfig = (columns: IListColumns[]): IListStoreData[] => {
    const data = columns.map((column: IListColumns) => {
      return {
        id: column.id,
        size: column.size,
        active: !column.hidden,
        filters: [],
      } as IListStoreData
    })
    return data
  }

  private reloadFilters = () => {
    const { selectedColumns } = this.state
    const filters: IListFilter[] = []
    const columnFrom: IListColumns[] = this.props.columns
    columnFrom.map((column: IListColumns) => {
      column.initialSorting = true
      const selectedColumn = selectedColumns.find((sc) => sc.id === column.id)
      let filterOption = column.filter
        ? column.filter.map((cf) => {
            if (
              selectedColumn &&
              selectedColumn.filters &&
              selectedColumn.filters.some(
                (scf: ISingleFilter) => scf.name === cf.name,
              )
            ) {
              return { ...cf, active: true }
            } else {
              return {
                ...cf,
                active: false,
              }
            }
          })
        : []

      if (
        column.filter &&
        column.filter.length === 0 &&
        selectedColumn &&
        selectedColumn.filters
      ) {
        filterOption = selectedColumn.filters.map((sef) => {
          return {
            value: sef.value,
            name: column.dataField,
            active: true,
          } as ISingleFilter
        })
      }

      if (column.filter) {
        filters.push({
          column,
          open: false,
          searchValue: '',
          isDate: false,
          filterOptions: filterOption,
        })
      }
    })

    const f: IListFilter[] = filters.map((filter) => {
      if (filter.filterOptions.length > 1) {
        filter.filterOptions.splice(0, 0, {
          value: 'SELECT_ALL',
          name: 'select_all', // this will be translated on filter item
          active: false,
        } as ISingleFilter)
      }
      return filter
    })
    this.setState({ filters: f, intialload: false }, () => {
      this.constructFilterJson()
    })
  }

  private onFullListSearch = (value: string) => {
    if (value === '') {
      this.setState({
        fullSearchValue: value,
      })
      this.constructFilterJson()
    } else {
      const array = this.state.data.filter((obj) => {
        return Object.keys(obj).some((key) =>
          obj[key]
            ? obj[key].toString().toLowerCase().includes(value.toLowerCase())
            : false,
        )
      })
      this.setState({
        fullSearchValue: value,
        data: array,
      })
    }
  }

  private openFilter = async (column: IListColumns) => {
    const { filters, selectedColumns } = this.state
    const newFilter = [...filters]
    const theFilter = [
      ...newFilter.filter((f) => f.column.name === column.name),
    ]
    const currentFilter = theFilter[0]
    currentFilter.open = !currentFilter.open
    this.setState({
      filters: newFilter,
    })
    const colmunSaved = selectedColumns.find((sc) => sc.id === column.id)
    const colmunSavedFilter =
      colmunSaved && colmunSaved.filters ? colmunSaved.filters : []
    if (currentFilter.column.getFilter) {
      const options = await currentFilter.column.getFilter()
      if (options.length > 1 && options[0].value !== 'SELECT_ALL') {
        options.splice(0, 0, {
          value: 'SELECT_ALL',
          name: 'select_all', // this will be translated on filter item
          active: false,
        } as ISingleFilter)
      }
      if (options.length > 1 && options[1].value !== 'null') {
        options.splice(1, 0, {
          value: 'null',
          name: 'select_no_value', // this will be translated on filter item
          active: false,
        } as ISingleFilter)
      }
      const finalOptions = options.map((option) => {
        const activeoptions = colmunSavedFilter.find(
          (ops) => ops.active && ops.value === option.value,
        )
        if (activeoptions) {
          option.active = true
        } else {
          option.active = false
        }
        return option
      })
      currentFilter.filterOptions = finalOptions
      this.setState({ filters: newFilter })
    }
  }

  private setFilter = (column: IListColumns, currentFilter: ISingleFilter) => {
    const { filters } = this.state
    const newFilters = [...filters]
    const listFilter = newFilters.find((f) => f.column.name === column.name)
    if (!listFilter) return
    const filterOption: ISingleFilter =
      listFilter.filterOptions.find(
        (f) => f.name === currentFilter.name && f.value === currentFilter.value,
      ) || ({} as ISingleFilter)
    filterOption.active = !filterOption.active
    if (filterOption.value === 'SELECT_ALL') {
      listFilter.filterOptions
        .filter((f: ISingleFilter) => {
          const name = f.name ? f.name.toLowerCase() : ''
          return (
            name.includes(listFilter.searchValue.toLowerCase()) ||
            ['select_all'].includes(name)
          )
        })
        .map((option) => (option.active = filterOption.active))
    }
    this.storeFilter(column, newFilters)
    this.setState(
      {
        filters: newFilters,
        currentPage: 1,
      },
      () => {
        this.constructFilterJson()
      },
    )
  }

  private setDateFilter = (
    column: IListColumns,
    startDate: moment.Moment,
    endDate: moment.Moment,
  ) => {
    const { filters } = this.state
    const newFilter = [...filters]
    const theFilter = [
      ...newFilter.filter((f) => f.column.name === column.name),
    ]
    const currentFilter = theFilter[0]

    const formatedStartDate = (startDate ? startDate : moment())
      .clone()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const formatedEndDate = (endDate ? endDate : moment())
      .clone()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    currentFilter.filterOptions = [
      {
        value: formatedStartDate.format(),
        name: column.dataField,
        active: startDate ? true : false,
        dateValue: [formatedStartDate.format()],
      },
      {
        value: formatedEndDate.format(),
        name: column.dataField,
        active: endDate ? true : false,
        dateValue: [formatedEndDate.format()],
      },
    ]
    this.storeFilter(column, newFilter)
    this.setState(
      {
        filters: newFilter,
        currentPage: 1,
      },
      () => {
        this.constructFilterJson()
      },
    )
  }

  private setTextFilter = (column: IListColumns, text: string) => {
    const { filters } = this.state
    const newFilter = [...filters]
    const theFilter = [
      ...newFilter.filter((f) => f.column.name === column.name),
    ]
    const currentFilter = theFilter[0]

    currentFilter.filterOptions = [
      {
        value: text,
        name: column.dataField,
        active: text !== '' ? true : false,
      },
    ]
    this.storeFilter(column, newFilter)
    this.setState(
      {
        filters: newFilter,
        currentPage: 1,
      },
      () => {
        this.constructFilterJson()
      },
    )
  }

  private storeFilter = (column: IListColumns, filters: IListFilter[]) => {
    const { selectedColumns } = this.state
    const storeColmIndex = selectedColumns.findIndex(
      (selected) => selected.id === column.id,
    )
    const filter = filters.find((f) => f.column.id === column.id)
    selectedColumns[storeColmIndex] = {
      ...selectedColumns[storeColmIndex],
      filters: filter ? filter.filterOptions.filter((fo) => fo.active) : [],
    }

    this.setState({ selectedColumns }, () => {
      this.tableToRemote()
    })
  }

  public constructFilterJson = () => {
    if (this.props.getFilteredData) {
      this.props.getFilteredData(
        this.state.filters,
        this.state.sorting,
        this.state.currentPage,
      )
    }
  }

  private reloadDeliveries = () => {
    this.setState({
      loading: true,
      data: [],
    })
    filterDelivery(
      this.props.projectId,
      this.state.activeFilter,
      this.state.currentPage,
      this.props.itemsPerPage,
    ).then((res) => {
      this.setState({
        data: res.items,
        totalPages: res.pages,
        loading: false,
      })
      this.resort()
    })
  }

  private getFieldValue = (column: IListColumns, data: any, field: string) => {
    const { t } = this.props
    if (column[field]) {
      const objectSpread: string[] = column[field].split('||')
      if (objectSpread.length > 1) {
        const returnObject: any[] = objectSpread.map((objStr: string) => {
          const objStrDivider: string[] = objStr.split('.')
          if (objStrDivider.length > 1) {
            if (data[objStrDivider[0]]) {
              return [
                data[objStrDivider[0]][objStrDivider[1]],
                data[objStrDivider[0]].id ? data[objStrDivider[0]].id : null,
              ]
            }
            return null
          }
          return null
        })
        const returnString = returnObject.map((objStr) =>
          objStr ? objStr[0] : null,
        )

        return {
          name: returnString.join(' '),
          id: returnObject[0] ? returnObject[0][1] : null,
        }
      }
      const splitStr = column[field].split('.')
      if (splitStr.length > 1) {
        if (data[splitStr[0]]) {
          return data[splitStr[0]][splitStr[1]]
        } else {
          return null
        }
      }
      if (column.filterCase === filterCase.ACTION_STATUS) {
        const status = data[column[field]]
        return statusFilterTypes(t)
          .filter((st: any) => st.id === status)
          .map((st: any) => st.name)
          .pop()
      }

      if (column.filterCase === filterCase.ACTION_SOURCE) {
        const source = data[column[field]]
        return actionSource(t)
          .filter((at: any) => at.id === source)
          .map((st: any) => st.name)
          .pop()
      }

      if (column.filterCase === filterCase.ACTION_YES_NO) {
        const source = data[column[field]]
        return booleanTypes(t)
          .filter((at: any) => at.id === source)
          .map((st: any) => st.name)
          .pop()
      }

      return data[column[field]] || false
    }
  }

  private clearSingleFilter = (removedFilter: IListFilter) => {
    const { filters, selectedColumns } = this.state
    const newFilters = [...filters]

    newFilters.map((filter: IListFilter) => {
      if (filter.column.id === removedFilter.column.id) {
        filter.filterOptions.map((singleFilter: ISingleFilter, key: number) => {
          filter.filterOptions[key].active = false
          filter.searchValue = ''
        })
      }
    })
    const selectedIndex = selectedColumns.findIndex(
      (sc) => sc.id === removedFilter.column.id,
    )
    if (selectedIndex > -1) {
      selectedColumns[selectedIndex].filters = []
    }
    this.setState(
      {
        filters: newFilters,
        selectedColumns,
      },
      () => {
        this.tableToRemote()
        this.constructFilterJson()
      },
    )
  }

  private filterSearch = (column: IListColumns, search: string) => {
    const { filters } = this.state
    const newFilter = [...filters]
    const theFilter = [
      ...newFilter.filter((f) => f.column.name === column.name),
    ]
    const currentFilter = theFilter[0]
    currentFilter.searchValue = search
    this.setState({
      filters: newFilter,
    })
  }

  private sortList = async (column: IListColumns) => {
    const { data, sorting } = this.state
    const existingSorting: boolean = column.sortingField === sorting.field

    const sortingDirection = existingSorting ? !sorting.direction : false
    const sortingField = column.sortingField ?? ' '
    let sortedData: any[] = []
    const { columns } = this.state
    const newColumns = [...columns]

    if (!this.props.sortBackend) {
      sortedData = data.sort((a: any, b: any) => {
        return column.comparator
          ? column.comparator(
              a[sortingField],
              b[sortingField],
              sortingDirection,
            )
          : this.defaultComparator(
              a[sortingField],
              b[sortingField],
              sortingDirection,
            )
      })

      const index = newColumns.indexOf(column)
      newColumns[index].initialSorting = !column.initialSorting
    } else {
      sortedData = data
    }

    this.setState(
      {
        data: sortedData,
        columns: newColumns,
        sorting: {
          field: column.sortingField ?? '',
          direction: sortingDirection,
          active: true,
          is_user_defined_field: column.userDefinedFiled !== undefined,
          data_type: column.userDefinedType,
        },
      },
      () => {
        this.tableToRemote()
        if (this.props.sortBackend) {
          this.constructFilterJson()
        }
      },
    )
  }

  private moveColumn = (fromIndex: number, toIndex: number) => {
    const { columns, selectedColumns } = this.state
    const newColumns = [...columns]
    const newSelectedColumns = [...selectedColumns]

    const tempColumns = newColumns[fromIndex]
    newColumns.splice(fromIndex, 1)
    newColumns.splice(toIndex, 0, tempColumns)

    const tempSelectedColumns = newSelectedColumns[fromIndex]
    newSelectedColumns.splice(fromIndex, 1)
    newSelectedColumns.splice(toIndex, 0, tempSelectedColumns)

    this.setState(
      {
        columns: newColumns,
        selectedColumns: newSelectedColumns,
        columnsSize: newColumns,
      },
      () => {
        this.tableToRemote()
        this.onColumnChange()
      },
    )
    /*
    updateTableConfig(this.props.tableName, {data: {selectedColumns: newSelectedColumns, columns: newColumns}})
      .then(res => this.setTableInStorage(res))
      .catch( err => console.log(err));
      */
  }

  private resort = () => {
    const { data, sorting } = this.state
    if (sorting.active) {
      const sortingField = sorting.field
      const sortingDirection = sorting.direction
      const sortedData: any[] = data.sort((a: any, b: any) => {
        return sorting.comparator
          ? sorting.comparator(
              a[sortingField],
              b[sortingField],
              sortingDirection,
            )
          : this.defaultComparator(
              a[sortingField],
              b[sortingField],
              sortingDirection,
            )
      })

      this.setState({
        data: sortedData,
        sorting: {
          field: sorting.field,
          direction: sorting.direction,
          active: true,
          comparator: sorting.comparator,
        },
      })
    }
    if (this.state.totalPages < this.state.currentPage) {
      this.setState({
        currentPage: 1,
      })
    }
  }

  private defaultComparator = (a: any, b: any, direction: boolean) => {
    if (a === null) {
      return direction ? 1 : -1
    }

    if (b === null) {
      return direction ? -1 : 1
    }
    if (a > b) {
      return direction ? 1 : -1
    }
    if (a < b) {
      return direction ? -1 : 1
    }
    return 0
  }

  private onPageClick = () => {
    if (this.props.getFilteredData) {
      this.props.getFilteredData(
        this.state.filters,
        this.state.sorting,
        this.state.currentPage,
      )
    }
  }

  /**
   * Resize handlers
   */
  private updateColumnSize = (column: IListColumns, size: string) => {
    const { columns, selectedColumns } = this.state
    const newColumns = [...columns]
    const currentCollumn = newColumns.find((c) => c === column)
    if (currentCollumn) {
      currentCollumn.size = size
    }
    const storeColmIndex = selectedColumns.findIndex(
      (selected) => selected.id === column.id,
    )
    selectedColumns[storeColmIndex] = {
      ...selectedColumns[storeColmIndex],
      size: column.size,
    }

    this.setState({ columns: newColumns, selectedColumns })
  }

  private addColumn = (column: IListColumns) => {
    const { selectedColumns } = this.state
    const newColumns = [...selectedColumns]
    const selectedColumn = selectedColumns.find((col) => col.id === column.id)
    if (selectedColumn) {
      newColumns[newColumns.indexOf(selectedColumn)].active = true
    }
    this.setState(
      {
        selectedColumns: newColumns,
      },
      () => {
        this.tableToRemote()
        this.onColumnChange()
      },
    )

    /*
    updateTableConfig(this.props.tableName, {data: {selectedColumns: newColumns, columns: this.state.columns}})
      .then(res => this.setTableInStorage(res))
      .catch( err => console.log(err));
      */
  }

  /**
   * Column handlers
   */
  private removeColumn = (column: IListColumns) => {
    const { selectedColumns } = this.state
    const newColumns = [...selectedColumns]
    const selectedColumn = selectedColumns.find((col) => col.id === column.id)
    if (selectedColumn) {
      newColumns[newColumns.indexOf(selectedColumn)].active = false
    }
    this.setState(
      {
        selectedColumns: newColumns,
      },
      () => {
        this.tableToRemote()
        this.onColumnChange()
      },
    )

    /*
    updateTableConfig(this.props.tableName, {data: {selectedColumns: newColumns, columns: this.state.columns}})
      .then(res => this.setTableInStorage(res))
      .catch( err => console.log(err));
      */
  }

  private saveTableConfig = () => {
    this.tableToRemote()
  }

  private tableFromRemote = async (): Promise<any> => {
    if (this.props.disableConfigStore) {
      return
    }
    const { projectId, tableName } = this.props
    try {
      const remoteTable = await getTable<RemoteTable>(
        projectId,
        tableName,
        false,
      )
      return remoteTable.selectedColumns ? remoteTable : undefined
    } catch (error) {
      // catch remote errors status >= 300
      return undefined
    }
  }

  private tableToRemote = async () => {
    if (this.props.disableConfigStore) {
      return
    }
    const { projectId, tableName } = this.props
    const data = {
      name: tableName,
      projectId,
      data: {
        selectedColumns: this.state.selectedColumns,
        sort: this.state.sorting,
      },
    }
    await setTableConfig(projectId, tableName, data)
  }

  private onPageChange = (page: number, onChangeEnd: () => void): void => {
    this.setState(
      {
        currentPage: page,
      },
      () => {
        onChangeEnd()
      },
    )
  }

  private onColumnChange = () => {
    if (this.props.columnChange) {
      this.props.columnChange(
        this.state.selectedColumns
          .filter((col) => col.active)
          .map((col) => col.id),
      )
    }
  }

  public render() {
    const { state } = this
    const context = {
      actions: {
        setFilter: this.setFilter,
        onFullListSearch: this.onFullListSearch,
        sortList: this.sortList,
        moveColumn: this.moveColumn,
        openFilter: this.openFilter,
        filterSearch: this.filterSearch,
        setDateFilter: this.setDateFilter,
        setTextFilter: this.setTextFilter,
        clearSingleFilter: this.clearSingleFilter,
        getFieldValue: this.getFieldValue,
        updateColumnSize: this.updateColumnSize,
        removeColumn: this.removeColumn,
        addColumn: this.addColumn,
        saveTableConfig: this.saveTableConfig,
        onPageChange: this.onPageChange,
        reloadDeliveries: this.reloadDeliveries,
        onPageClick: this.onPageClick,
      },
      state,
    }
    return (
      <React.Fragment>
        {!state.intialload && (
          <ListContext.Provider value={context}>
            {this.props.children}
          </ListContext.Provider>
        )}
      </React.Fragment>
    )
  }
}

export default React.memo(withTranslation()(ListContextProvider))
