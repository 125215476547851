import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getProjectSystemTypeGroupsPerDomain } from 'src/service/SystemTypeGroupService'

export const useSystemTypeGroupForDomain = (domainName: string) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['systemTypeGroups', projectId, domainName],
    queryFn: () =>
      getProjectSystemTypeGroupsPerDomain(projectId, domainName).then((res) =>
        res.pop(),
      ),
  })
}

export const useSystemTypeGroups = (domainName: string) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['systemTypeGroups', projectId, domainName],
    queryFn: () => getProjectSystemTypeGroupsPerDomain(projectId, domainName),
  })
}
