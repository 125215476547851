import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import { getProjectDisciplines } from '../../../service/DisciplineService'
import { IDiscipline } from '../../../service/OrgTypes'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import { renderDayContents } from '../../../utility/Utility'
import { classNames } from '../../../utility/utils'
import Selector from '../../selectors/Selector'
import CleanZone from '../util/CleanZone'

interface IWagonFormForTrain {
  closeModal: () => void
  sendWagonForm: (wagon: any) => void
  open: boolean
}

const WagonFormForTrain = ({
  closeModal,
  open,
  sendWagonForm,
}: IWagonFormForTrain) => {
  const styleClass = {
    root: classNames('flex', 'flex-col', 'flex-shrink'),
    inputGroup: classNames('flex', 'flex-row', 'w-full'),
    label: classNames(
      'w-full',
      'font-roboto',
      'text-sm',
      'font-bold',
      'mb-2',
      'pl-1',
    ),
  }

  const { t } = useTranslation()
  const [title, setTitle] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [startTime, setStartTime] = useState<moment.Moment>(moment())
  const [duration, setDuration] = useState<number>(0)
  const [isClean, setIsClean] = useState(CleanZone.notClean())
  const [contractId, setContractId] = useState<number>(0)
  const [disciplineId, setDisciplineId] = useState<number>(0)
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false)
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('')
  const [durationErrorMessage, setDurationErrorMessage] = useState<string>('')
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id

  useEffect(() => {
    getProjectDisciplines(projectId).then((res) => {
      setDisciplines(res)
    })
  }, [projectId])

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value)
    setTitleErrorMessage('')
  }

  const onChangeSubject = (e: any) => {
    setSubject(e.target.value)
  }

  const setDatePickerFocus = (focused: any) => {
    setDatePickerFocused(focused.focused)
  }

  const onChangeStartTime = (time: moment.Moment) => {
    setStartTime(time || moment().format('L'))
    setDateErrorMessage('')
  }

  const onChangeDuration = (e: any) => {
    const value = e.target.value.replace(/^0+/, '')
    setDuration(value)
    setDurationErrorMessage('')
  }

  const onChangeDiscipline = (newDisciplineId: number) => {
    setDisciplineId(newDisciplineId)
    setDisciplineErrorMessage('')
    if (contractId === 0) {
      const dis = disciplines.find(
        (discipline) => discipline.id === newDisciplineId,
      )
      setContractId(dis ? dis.contract_id : 0)
    }
  }

  const onSubmitForm = (e: any, _value?: boolean) => {
    e.preventDefault()
    setIsLoading(true)
    let error = false

    if (!title) {
      setTitleErrorMessage(t('fill_out_w_param', { param: t('title') }))
      error = true
    }

    if (!startTime) {
      setDateErrorMessage(t('fill_out_w_param', { param: t('start_date') }))
      error = true
    }

    if (!duration || duration <= 0) {
      setDurationErrorMessage(t('fill_out_w_param', { param: t('duration') }))
      error = true
    }

    if (!disciplineId) {
      setDisciplineErrorMessage(
        t('fill_out_w_param', { param: t('discipline') }),
      )
      error = true
    }

    if (!error) {
      const updatedWagon: any = {
        title,
        status,
        startTime,
        duration,
        subject,
        contract_id: contractId,
        project_id: projectId,
        clean_status: isClean,
        discipline_id: disciplineId > 0 ? disciplineId : null,
      }
      sendWagonForm(updatedWagon)
      closeModal()
    } else {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      show={open}
      title={t('new_wagon')}
      closeModal={closeModal}
      maxWidth={600}
    >
      <form className={styleClass.root} onSubmit={onSubmitForm}>
        <div className={styleClass.inputGroup}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <div className={'flex'}>
              <label className={'text-sm font-bold my-2 capitalize'}>
                {t('start_date_required')}
              </label>
              {dateErrorMessage ? (
                <label className={'pl-2 text-sm my-2'} style={{ color: 'Red' }}>
                  {dateErrorMessage}
                </label>
              ) : null}
            </div>
            <div className={'flex justify-between w-full'}>
              <SingleDatePicker
                firstDayOfWeek={1}
                date={startTime}
                onDateChange={onChangeStartTime}
                renderDayContents={renderDayContents}
                focused={datePickerFocused}
                onFocusChange={setDatePickerFocus}
                id="datepicker-wagon"
                small={true}
                isOutsideRange={() => false}
                showDefaultInputIcon={true}
                noBorder={true}
                numberOfMonths={1}
                displayFormat={() =>
                  moment.localeData('no').postformat('DD.MM.YY')
                }
                required={true}
                hideKeyboardShortcutsPanel={true}
              />
            </div>
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-full'}>
            <Input
              block={true}
              label={t('title')}
              onChange={onChangeTitle}
              value={title}
              required={true}
              errorMessage={titleErrorMessage}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Selector
              items={disciplines}
              selectedItemId={disciplineId}
              onSelect={onChangeDiscipline}
              label={t('wagon_discipline')}
              dataFields={['shortName', 'name']}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={disciplineErrorMessage}
              required={true}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Selector
              items={CleanZone.info(t)}
              selectedItemId={CleanZone.id(isClean)}
              onSelect={(id) => setIsClean(CleanZone.zone(id))}
              label={t('clean_status')}
              dataFields={['name']}
              required={true}
              fontSize={'sm'}
              fontWeight={'bold'}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <Input
            block={true}
            label={t('wagon_duration_days')}
            onChange={onChangeDuration}
            value={Math.round(Number(duration) * 100) / 100}
            errorMessage={durationErrorMessage}
            required={true}
          />
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-full'}>
            <Input
              block={true}
              label={t('description')}
              onChange={onChangeSubject}
              value={subject}
            />
          </div>
        </div>

        <ModalFooter>
          <Button onClick={closeModal}>{t('cancel')}</Button>
          <Button type={Button.ButtonType.PRIMARY} disabled={isLoading}>
            {isLoading ? <Spinner /> : t('add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default WagonFormForTrain
