import Badge from 'src/ui-elements/badge/Badge'
import { StatusEnum } from 'src/utility/statusEnums'

export const getRequirementStatusColor = (
  status: StatusEnum,
  active = true,
) => {
  if (!active) {
    return 'rgb(156 163 175);'
  }

  switch (status) {
    case StatusEnum.COMPLETED:
      return '#0e9f6e'
    case StatusEnum.OPEN:
      return '#FBBF24'
    case StatusEnum.DECLINED:
      return '#FD7171'
    case StatusEnum.OVERDUE:
      return '#F44336'
    default:
      return '#6b7280'
  }
}

export const getRequirementStatusTextColor = (
  status: StatusEnum,
  active = true,
) => {
  if (!active) {
    return 'line-through text-gray-400'
  }

  switch (status) {
    case StatusEnum.COMPLETED:
      return 'text-green-500'
    case StatusEnum.OPEN:
      return 'text-yellow-400'
    case StatusEnum.DECLINED:
      return 'text-red-two'
    case StatusEnum.OVERDUE:
      return 'text-red-500'
    default:
      return 'text-gray-500'
  }
}

export const getRequirementStatusBadgeColor = (
  status: StatusEnum,
  active = true,
) => {
  if (!active) {
    return Badge.BadgeColor.DEFAULT
  }

  switch (status) {
    case StatusEnum.COMPLETED:
      return Badge.BadgeColor.GREEN
    case StatusEnum.OPEN:
      return Badge.BadgeColor.YELLOW
    case StatusEnum.DECLINED:
    case StatusEnum.OVERDUE:
      return Badge.BadgeColor.RED
    default:
      return Badge.BadgeColor.DEFAULT
  }
}

export const getRequirementStatusBackgroundColor = (
  status: StatusEnum,
  active: boolean,
) => {
  if (!active) {
    return 'bg-gray-400'
  }

  switch (status) {
    case StatusEnum.COMPLETED:
      return 'bg-green-400'
    case StatusEnum.OPEN:
      return 'bg-yellow-400'
    default:
      return 'bg-red-500'
  }
}

export const getAllRequirementStatus = () => {
  return [
    StatusEnum.OVERDUE,
    StatusEnum.DECLINED,
    StatusEnum.OPEN,
    StatusEnum.COMPLETED,
    StatusEnum.EMPTY,
  ]
}
