import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import MilestoneCardTimeline from 'src/components/milestone-canvas/MilestoneCardTimeline'
import MilestoneDetailTimeline from 'src/components/milestone-canvas/MilestoneDetailTimeline'
import MilestoneTemaTimeline from 'src/components/milestone-canvas/MilestoneTemaTimeline'
import MilestoneTimeline from 'src/components/milestone-canvas/MilestoneTimeline'
import MilestoneTree from 'src/components/projectPlanningTree/MilestoneTree'
import TabView, { ITabs } from 'src/components/tab-view/TabView'
import MilestoneMetadataConfigPage from 'src/page/milestone/MilestoneMetadataConfigPage'
import { CanvasType } from 'src/service/OrgTypes'

interface IMilestonePage {
  projectId: number
  routeProps?: RouteComponentProps<{
    deliveryId?: string
    keypointId?: string
    milestoneId?: string
    taskId?: string
  }>
}

const MilestonePage = ({
  projectId: _projectId,
  routeProps: _routeProps,
}: IMilestonePage) => {
  const { t } = useTranslation()

  const tabs: ITabs = {
    overview: {
      title: t('table_overview'),
      show: true,
      content: <MilestoneTree />,
    },
    visual_overview_per_main_process_card: {
      title: t('visual_overview_per_main_process'),
      show: true,
      content: <MilestoneCardTimeline />,
      path: 'visual_overview_card',
    },
    visual_overview_per_main_process: {
      title: t('visual_overview_per_main_process'),
      show: true,
      content: <MilestoneTimeline canvasType={CanvasType.MAIN_PROCESS} />,
      path: 'visual_overview_lines',
    },
    visual_overview_by_discipline: {
      title: t('visual_overview_by_discipline'),
      show: true,
      content: <MilestoneTimeline canvasType={CanvasType.DISCIPLINE} />,
      path: 'visual_overview_discipline',
    },
    visual_overview_per_theme: {
      title: t('visual_overview_per_theme'),
      show: true,
      content: <MilestoneTemaTimeline />,
      path: 'visual_overview_theme',
    },
    visual_overview_with_progress_front: {
      title: t('visual_overview_with_progress_front'),
      show: true,
      content: <MilestoneDetailTimeline />,
      path: 'visual_overview_progress',
    },
    settings: {
      title: t('settings'),
      show: true,
      content: <MilestoneMetadataConfigPage />,
      path: 'settings',
    },
  }

  return <TabView tabs={tabs} />
}

export default MilestonePage
