import { IPdfHighlight, PDFHighlightParentInfo } from './OrgTypes'
import { request } from './service-utils'

export const getProjectFileHighlights = (
  fileId: string,
): Promise<IPdfHighlight[]> => {
  return request(`/parents/ProjectFile/${fileId}/pdf_highlights`, 'GET')
}

export const saveProjectFileHighlight = (projectId: number, data: unknown) => {
  return request(`projects/${projectId}/pdf_highlights`, 'POST', data)
}

export const resolveHighlight = (highlightId: number) => {
  return request(`pdf_highlights/${highlightId}/resolve`, 'PUT')
}

export const unResolveHighlight = (highlightId: number) => {
  return request(`pdf_highlights/${highlightId}/un_resolve`, 'PUT')
}

export const deleteHighlight = (highlightId: number) => {
  return request(`pdf_highlights/${highlightId}`, 'DELETE')
}

export const getHighlightUrlInfo = (
  highlightId: number,
): Promise<PDFHighlightParentInfo> => {
  return request(`pdf_highlights/${highlightId}/parent_info`, 'GET')
}
