import { TableFilter } from 'src/ui-elements/Table/useTable'
import { IDraft, IPaginateResult } from './OrgTypes'
import { request } from './service-utils'

type DefaultParams = {
  projectId: number
}

type Params<T = unknown> = DefaultParams & T

export const getDrafts = ({ projectId }: Params) => {
  return request<IDraft[]>(`projects/${projectId}/drafts/`, 'GET')
}

export const getFilteredDrafts = (
  projectId: number,
  filter: TableFilter,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginateResult<IDraft>> => {
  return request(
    `projects/${projectId}/drafts_filter/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}
