import { twMerge } from 'tailwind-merge'
import App from 'src/App'
import 'src/styles/index.css'
import 'src/i18n'
import createSelectors from 'src/utility/storeSelectors'
import { allLoadedStore } from './splashScreenStore'

const Splash = ({ onlyLoader = false }: { onlyLoader?: boolean }) => {
  const useSplashStore = createSelectors(allLoadedStore)
  const [loading] = useSplashStore.use.loading()
  return (
    <div className="relative">
      <div
        className={twMerge(
          'absolute pointer-events-none top-0 left-0 w-full h-screen flex justify-center items-center',
          loading ? 'opacity-100' : 'opacity-0',
        )}
      >
        <img
          className="scale-50 animate-pulse opacity-75"
          src="/taskctr-logomork.png"
        />
      </div>
      {!onlyLoader && (
        <div className={twMerge(loading ? 'opacity-0' : 'opacity-100')}>
          <App />
        </div>
      )}
    </div>
  )
}

export default Splash
