import { getProxyBaseUrl } from '../utility/utils'
import { IWallUser } from './TheWallTypes'
import TokenService from './TokenService'

export interface ILoginUserBody {
  email: string
  password: string
}

export const loginUser = (body: ILoginUserBody): Promise<any> => {
  return fetch(`${getProxyBaseUrl()}/login/`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .catch((err) => {
      return err
    })
}

export const forgotPassword = async (
  email: string,
  lang = 'no',
): Promise<any> => {
  try {
    const response = await fetch(`${getProxyBaseUrl()}/forgot-password`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ email, lang }),
    })
    return await response.json()
  } catch (err) {
    return err
  }
}

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<any> => {
  try {
    const response = await fetch(`${getProxyBaseUrl()}/change-password/`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${TokenService.getRefreshToken}`,
      },
      method: 'POST',
      body: JSON.stringify({ oldPassword, password: newPassword }),
    })
    return response
  } catch (err) {
    return err
  }
}

export const checkAuthentication = TokenService.isTokenValid

export const updateWallToken = TokenService.getAccessToken

export const createUser = (userObject: IWallUser): Promise<any> => {
  return new Promise((resolve, reject) => {
    updateWallToken().then((token) => {
      return fetch(`${getProxyBaseUrl()}/create-user`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        method: 'POST',
        body: JSON.stringify(userObject),
      })
        .then((response) => {
          if (response.status >= 400 && response.status < 600) {
            return response.json().then((data) => reject(data))
          } else {
            return response.json()
          }
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  })
}

export const deleteUser = (wallId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    updateWallToken().then((token) => {
      return fetch(`${getProxyBaseUrl()}/user/${wallId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        method: 'DELETE',
      })
        .then((response) => {
          if (response.status >= 400 && response.status < 600) {
            return response.json().then((data) => reject(data))
          } else {
            return response.json()
          }
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  })
}
