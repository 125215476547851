import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentTypeService from 'src/document/services/DocumentTypeService'
import { IDocumentType } from 'src/document/types/IFlow'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { classNames } from 'src/utility/utils'
import Textarea from '../../../ui-elements/textarea/Textarea'

interface IDocumentTypeForm {
  documentType?: IDocumentType
  onSubmit: () => void
  onCancel: () => void
}

const DocumentTypeForm: React.FC<IDocumentTypeForm> = ({
  onSubmit,
  onCancel,
  documentType,
}) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [name, setName] = useState(documentType?.name ?? '')
  const [codeErrorMessage, setCodeErrorMessage] = useState('')
  const [code, setCode] = useState(documentType?.name ?? '')
  const [description, setDescription] = useState(
    documentType?.description ?? '',
  )

  const onNameChange = (e: any) => {
    setNameErrorMessage('')
    setName(e.target.value)
  }

  const onCodeChange = (e: any) => {
    setCodeErrorMessage('')
    setCode(e.target.value)
  }

  const onDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const onSubmitClick = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    let error = false
    if (!name) {
      setNameErrorMessage(t('required'))
      error = true
    }
    if (!code) {
      setCodeErrorMessage(t('required'))
      error = true
    }
    if (!error) {
      setLoading(true)
      documentType
        ? await DocumentTypeService.editDocumentType({
            id: documentType.id,
            name,
            code,
            description,
          })
        : await DocumentTypeService.createDocumentType({
            name,
            code,
            description,
          })
      setLoading(false)
      onSubmit()
    }
  }

  return (
    <Modal
      show={true}
      title={
        documentType
          ? t('edit_item', { item: t('document_type').toLowerCase() })
          : t('new_w_param', { param: t('document_type').toLowerCase() })
      }
      closeModal={onCancel}
      size={'w-1/2'}
      maxWidth={600}
    >
      <form className={styleClass.root} onSubmit={onSubmitClick}>
        <div className={styleClass.inputGroup}>
          <Input
            label={t('name')}
            block={true}
            value={name}
            errorMessage={nameErrorMessage}
            required={true}
            onChange={onNameChange}
            autoFocus={true}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <Input
            label={t('code')}
            block={true}
            value={code}
            errorMessage={codeErrorMessage}
            required={true}
            onChange={onCodeChange}
            autoFocus={true}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <Textarea
            isValid={false}
            label={t('description')}
            value={description}
            onChange={onDescriptionChange}
            block={true}
          />
        </div>
        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={onCancel}>
            {t('cancel')}
          </Button>
          {documentType ? (
            <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
              {loading ? <Spinner /> : t('update')}
            </Button>
          ) : (
            <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
              {loading ? <Spinner /> : t('add')}
            </Button>
          )}
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default DocumentTypeForm
