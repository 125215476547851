import ArrowDown from '@icons/keyboard_arrow_down.svg'
import ArrowUp from '@icons/keyboard_arrow_up.svg'
import { HTMLAttributes } from 'react'
import { Tabs, Tab, TabList } from 'react-tabs'
import { twMerge } from 'tailwind-merge'
import { classNames } from 'src/utility/utils'

interface IContentTabsWrapperProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  tabIndex: number | undefined
  onSelect: (index: number) => void
  tabItems: string[]
  tabCounter?: Record<number, number>
  fixedTabList?: boolean
  tabListStyle?: string
  tabStyle?: string
  onTop?: boolean
  showExpandIcon?: boolean
  expanded?: boolean
  lastTab?: boolean
}

export const ContentTabsWrapperStyleClass = {
  tabs: classNames('w-auto'),
  tabList: (
    fixedTabList: boolean,
    tabListStyle: string,
    addPadding: boolean,
    onTop?: boolean,
  ) =>
    classNames(
      'flex',
      'items-center',
      'flex-wrap',
      'mb-2',
      fixedTabList ? 'fixed' : '',
      fixedTabList && 'w-screen',
      fixedTabList && onTop ? 'z-20' : 'z-10',
      fixedTabList && 'bg-body',
      '-mt-1',
      'border-b border-gray-200',
      addPadding && 'px-8',
      tabListStyle ? tabListStyle : '',
    ),
  tab: (first: boolean, tabStyle: string, selected?: boolean) =>
    classNames(
      'bg-white',
      'text-blue-root',
      'hover:text-blue-root',
      'inline-block',
      'relative',
      'text-sm',
      'font-normal',
      'ml-4',
      'list-none',
      'cursor-pointer',
      selected
        ? 'text-blue-root border-b-2 border-blue-root font-medium'
        : 'text-gray-500',
      'focus:outline-none focus:text-blue-root focus:border-blue-root active:border-blue-root ',
      'whitespace-nowrap',
      tabStyle ? tabStyle : '',
    ),
  activityTabWrapper: classNames('mx-4'),
}

export const ContentTabsWrapper = ({
  tabIndex = 0,
  fixedTabList = false,
  tabItems,
  tabCounter,
  tabListStyle = '',
  tabStyle = '',
  onTop = false,
  onSelect,
  showExpandIcon,
  expanded = true,
  lastTab,
  ...rest
}: IContentTabsWrapperProps) => {
  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={onSelect}
      className={ContentTabsWrapperStyleClass.tabs}
    >
      <TabList
        className={ContentTabsWrapperStyleClass.tabList(
          fixedTabList,
          tabListStyle,
          onTop,
        )}
      >
        {tabItems &&
          tabItems.map((tabName, i) => (
            <Tab
              key={tabItems.indexOf(tabName)}
              className={ContentTabsWrapperStyleClass.tab(
                i === 0,
                tabStyle,
                tabIndex === i,
              )}
            >
              {showExpandIcon && tabIndex === i ? (
                <div className="flex items-center first-capitalize">
                  <p>{tabName}</p>

                  <p>{(tabCounter && tabCounter[i]) ?? ''}</p>
                  <div className={'fill-blue-root'}>
                    {expanded ? <ArrowUp /> : <ArrowDown />}
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <p className={'bg-none first-capitalize'}>{`${tabName}`}</p>
                  {tabCounter && tabCounter[i] ? (
                    <p
                      className={twMerge(
                        'h-4 text-xs flex justify-center items-center mx-2 p-1 rounded',
                        tabIndex === i
                          ? 'bg-light-blue text-blue-root'
                          : 'bg-lightest-grey text-gray-root',
                      )}
                    >
                      {(tabCounter && tabCounter[i]) ?? ''}
                    </p>
                  ) : null}
                </div>
              )}
            </Tab>
          ))}
      </TabList>
      {!expanded && lastTab ? null : rest.children}
    </Tabs>
  )
}
