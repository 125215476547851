export const convertUndefinedToNull = <T>(
  object: Partial<T>,
): NullablePartial<T> => {
  const res: NullablePartial<T> = {}

  for (const [key, value] of Object.entries(object)) {
    res[key as keyof NullablePartial<T>] =
      value === undefined ? null : (value as T[keyof T])
  }

  return res
}
