import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import CheckBox from 'src/components/switchHoc/CheckBox'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { IMainProcess } from '../../../service/OrgTypes'
import {
  createMainProcess,
  updateMainProcess,
} from '../../../service/ProcessService'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import Spinner from '../../../ui-elements/loader/Spinner'
import { capFirstLetter, classNames } from '../../../utility/utils.js'

interface IMainProcessFromProps extends WithTranslation {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  mainProcess: IMainProcess
}

interface IMainProcessFromState {
  id: number
  name: string
  nameError: string
  loading: boolean
  isProductionProcess: boolean
}

class MainProcessFrom extends PureComponent<
  IMainProcessFromProps,
  IMainProcessFromState
> {
  private static styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  public constructor(props: IMainProcessFromProps) {
    super(props)
    this.state = {
      id: props.mainProcess.id ? props.mainProcess.id : 0,
      name: props.mainProcess.name,
      isProductionProcess: props.mainProcess.is_production_process ?? false,
      nameError: '',
      loading: false,
    }
  }

  private onSubmit = (e: any) => {
    let error = false
    e.preventDefault()
    const { t } = this.props
    this.setState({
      loading: false,
      nameError: '',
    })

    if (!this.state.name) {
      this.setState({
        nameError: t('fill_in_the_name'),
      })
      error = true
    }

    if (!error) {
      if (this.props.editingMode) {
        updateMainProcess(this.state.id, {
          name: this.state.name,
          is_production_process: this.state.isProductionProcess,
        }).then(() => {
          this.props.closeModal()
        })
      } else {
        createMainProcess(this.props.projectId, {
          name: this.state.name,
          is_production_process: this.state.isProductionProcess,
        }).then(() => {
          this.props.closeModal()
        })
      }
    }

    this.setState({
      loading: false,
    })
  }

  private onMainProcessNameChange = (e: any) => {
    this.setState({
      name: e.target.value,
      nameError: '',
    })
  }

  private onProductionProcessChange = (val: boolean) => {
    this.setState({
      isProductionProcess: val,
    })
  }

  public render() {
    const { name, nameError, loading, isProductionProcess } = this.state
    const { t } = this.props
    return (
      <form
        className={MainProcessFrom.styleClass.root}
        onSubmit={this.onSubmit}
      >
        <div className={MainProcessFrom.styleClass.inputGroup}>
          <Input
            label={t('main_process_name')}
            block={true}
            value={name}
            onChange={this.onMainProcessNameChange}
            required={true}
            errorMessage={nameError}
            autoFocus={true}
          />
        </div>
        <div
          className={`${MainProcessFrom.styleClass.inputGroup} items-center my-2`}
        >
          <span className={'font-medium text-sm leading-5 text-gray-700 mx-2'}>
            <CheckBox
              disableTab={true}
              onChange={this.onProductionProcessChange}
              valueProp={isProductionProcess}
            />
          </span>

          <label className={`mr-4 text-sm leading-5 text-gray-700 font-medium`}>
            {t('production_main_process')}
          </label>
        </div>

        <ModalFooter>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={this.props.closeModal}
          >
            {capFirstLetter(t('cancel'))}
          </Button>
          {this.props.editingMode ? (
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={loading ? true : false}
            >
              {loading ? <Spinner /> : capFirstLetter(t('update'))}
            </Button>
          ) : (
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={loading ? true : false}
            >
              {loading ? <Spinner /> : capFirstLetter(t('add'))}
            </Button>
          )}
        </ModalFooter>
      </form>
    )
  }
}

export default withTranslation()(MainProcessFrom)
