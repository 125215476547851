import { useTranslation } from 'react-i18next'
import { IConstructionCanvasControlArea } from '../../service/OrgTypes'
import { capFirstLetter } from '../../utility/utils'
import { getInfoWrapperStyle } from '../MUtils'
import FloatingButton from '../components/FloatingButton'

interface IMRoomInfo {
  controlArea: IConstructionCanvasControlArea
  noPadding?: boolean
  isCard?: boolean
  onFloatingBtnClick?: () => void
  sticky?: boolean
}

const MControlAreaInfo = ({
  controlArea,
  noPadding = false,
  isCard = false,
  onFloatingBtnClick,
  sticky = true,
}: IMRoomInfo) => {
  const { t } = useTranslation()

  return (
    <div
      className={getInfoWrapperStyle(
        noPadding,
        onFloatingBtnClick,
        isCard,
        sticky,
      )}
    >
      {onFloatingBtnClick && (
        <FloatingButton floating={false} onClick={onFloatingBtnClick} />
      )}
      <div className={`w-full border-t-green-300 pl-2`}>
        <details className={'-ml-2 border border-gray-300 rounded-md py-1'}>
          <summary className={'font-semibold text-gray-600 text-xs px-2'}>
            {t('control_area_details')}
          </summary>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/3 font-medium'}>
              {capFirstLetter(t('control_area'))}
            </div>
            <div className={'w-2/3 break-all font-light pl-2'}>
              {controlArea?.title ?? ''}{' '}
              {controlArea?.record_id ? '-' + controlArea?.record_id : ''}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/3 font-medium capitalize'}>{t('area')}</div>
            <div className={'w-2/3 break-all font-light pl-2'}>
              {controlArea?.total_area ?? ''}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/3 font-medium capitalize'}>
              {capFirstLetter(t('control_area_group'))}
            </div>
            <div className={'w-2/3 break-all font-light pl-2'}>
              {controlArea?.control_area_group?.title ?? ''}
            </div>
          </div>
        </details>
      </div>
    </div>
  )
}

export default MControlAreaInfo
