import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConstructionScopeModal, {
  IConstructionUpdateScope,
} from 'src/components/ConstructionCanvas/ConstructionScopeModal'
import useDraftMode from 'src/hooks/useDraftMode'
import { editConstructionWagon } from 'src/service/ConstructionWagonService'
import Button from 'src/ui-elements/button/Button'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import {
  getContractDisciplines,
  getProjectDisciplines,
} from '../../../service/DisciplineService'
import {
  IConstructionTrain,
  IConstructionWagon,
  IControlArea,
} from '../../../service/OrgTypes'
import { cleanStatusTypes, trainColors } from '../../../service/SystemValues'
import BooleanInlineInputComponent from '../../../ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import DateTimeInlineInputComponent from '../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from '../../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from '../../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import type { Moment } from 'moment'

interface IWagonModalDetail {
  onUpdate?: () => void
  wagon: IConstructionWagon
  onSave: boolean
}

const WagonModalDetail = ({ onUpdate, wagon, onSave }: IWagonModalDetail) => {
  const projectContext = useContext(ProjectContext)

  const { turnDraftModeOn } = useDraftMode()

  useEffect(() => {
    if (onSave) {
      setScopeModal(true)
    }
  }, [onSave])

  useEffect(() => {
    const currentProjectId = projectContext.state.currentProject.id
    setProjectId(currentProjectId)
  }, [projectContext])

  useEffect(() => {
    setSelectedWagon(wagon)
    setControlArea(wagon.control_area)
    setConstructionTrain(wagon.construction_train)
  }, [wagon])

  const { t } = useTranslation()
  const [selectedWagon, setSelectedWagon] = useState<IConstructionWagon>(wagon)

  const [constructionTrain, setConstructionTrain] = useState<
    undefined | IConstructionTrain
  >(undefined)
  const [controlArea, setControlArea] = useState<IControlArea | undefined>(
    undefined,
  )
  const [projectId, setProjectId] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [scopeModal, setScopeModal] = useState<boolean>(false)
  const { isConstructionManager } = projectContext.state

  const onChangeInput = (
    field: string,
    value: string | boolean | number | Moment | null,
  ) => {
    if (selectedWagon) {
      const wagon = { ...selectedWagon }
      wagon[field] = value
      setSelectedWagon(wagon)
    }
  }

  const onSubmitForm = (scope: IConstructionUpdateScope) => {
    setScopeModal(false)
    setIsLoading(true)
    const wagonTemp = { ...selectedWagon }
    const updatedWagon = {
      id: wagon.id,
      title: wagonTemp.title,
      status: wagonTemp.status,
      startTime: wagonTemp.startTime,
      duration: wagonTemp.duration,
      subject: wagonTemp.subject,
      actual_work_hours: wagonTemp.actual_work_hours,
      actual_staffing: wagonTemp.actual_staffing,
      construction_train_id: wagonTemp.construction_train_id,
      contract_id: wagonTemp.contract_id,
      project_id: wagonTemp.project_id,
      discipline_id: wagonTemp.discipline_id,
      color: wagonTemp.color,
      clean_status: wagonTemp.clean_status,
      ...scope,
    }

    editConstructionWagon(updatedWagon)
      .then(() => {
        setIsLoading(false)
        onUpdate?.()
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const closeScopeModal = () => {
    setScopeModal(false)
    setSelectedWagon(wagon)
  }

  return (
    <>
      <div className="flex wagonmodaldetail">
        <InlineComponentsWrapper padding="left" border={'right'}>
          <TextInlineInputCompontent
            label="title"
            value={selectedWagon.title}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput('title', newValue)
            }}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('fill_out_w_param', { param: t('title') })
              }
              return undefined
            }}
            disabled={!isConstructionManager}
          />
          <DateTimeInlineInputComponent
            label="start_date"
            selectedTime={`${selectedWagon.startTime}`}
            onValueSubmitted={(value) => {
              if (value) {
                const ISOMidnight = moment(value).format(
                  'YYYY-MM-DDT00:00:00.000Z',
                )
                onChangeInput('startTime', ISOMidnight)
              }
            }}
            validate={(newValue) => {
              if (!newValue) {
                return t('fill_out_w_param', { param: t('start_date') })
              }
              if (
                constructionTrain &&
                newValue.isBefore(constructionTrain.startTime, 'day')
              ) {
                return t('can_not_be_before_train_w_date', {
                  date: moment(constructionTrain.startTime).format('L'),
                })
              }

              return
            }}
            inspectorPanel={false}
            disabled={!isConstructionManager}
          />
          <TextInlineInputCompontent
            label="duration_days"
            type={'number'}
            value={`${Math.round(Number(selectedWagon.duration) * 100) / 100}`}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput('duration', newValue)
            }}
            validate={(newValue) => {
              if (!newValue || Number(newValue) < 0) {
                return t('fill_out_w_param', { param: t('duration') })
              }
              return undefined
            }}
            disabled={!isConstructionManager}
          />
          {controlArea && (
            <TextInlineInputCompontent
              label="control_area"
              value={controlArea.title}
              disabled={true}
            />
          )}
          {constructionTrain && (
            <TextInlineInputCompontent
              label="train"
              value={constructionTrain.title}
              disabled={true}
            />
          )}
          <TextInlineInputCompontent
            label="description"
            value={selectedWagon.subject}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput('subject', newValue)
            }}
            disabled={!isConstructionManager}
          />
        </InlineComponentsWrapper>

        <InlineComponentsWrapper
          padding="left"
          border={'right'}
          inputWidth="w-56"
          labelWidth="w-56"
        >
          <TextInlineInputCompontent
            label="working_hours"
            type={'number'}
            value={`${
              Math.round(Number(selectedWagon.planned_execution_hours) * 100) /
              100
            }`}
            disabled={true}
          />
          <TextInlineInputCompontent
            label="average_staffing"
            type={'number'}
            value={`${
              Math.round(Number(selectedWagon.planned_staffing) * 100) / 100
            }`}
            disabled={true}
          />
          <TextInlineInputCompontent
            label="actual_working_hours"
            type={'number'}
            value={`${
              Math.round(Number(selectedWagon.actual_work_hours) * 100) / 100
            }`}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput('actual_work_hours', newValue)
            }}
            disabled={!isConstructionManager}
          />
          <TextInlineInputCompontent
            label="actual_staffing"
            type={'number'}
            value={`${
              Math.round(Number(selectedWagon.actual_staffing) * 100) / 100
            }`}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput('actual_staffing', newValue)
            }}
            disabled={!isConstructionManager}
          />
          <BooleanInlineInputComponent
            label="consider_weekends_as_working_days"
            onValueSubmitted={(ignore_weekends) => {
              if (ignore_weekends !== undefined)
                onChangeInput('ignore_weekends', ignore_weekends)
            }}
            value={selectedWagon.ignore_weekends}
            showCheckBox
            disabled
          />
          <BooleanInlineInputComponent
            label="consider_holidays_as_working_periods"
            onValueSubmitted={(ignore_holidays) => {
              if (ignore_holidays !== undefined)
                onChangeInput('ignore_holidays', ignore_holidays)
            }}
            value={selectedWagon.ignore_holidays}
            showCheckBox
            disabled
          />
        </InlineComponentsWrapper>
        <InlineComponentsWrapper padding="left" inputWidth="w-56">
          <SelectorInlineInputComponent
            items={cleanStatusTypes(t)}
            label={t('clean_status')}
            getItemLabel={(clean_status) => clean_status?.name}
            initialItem={{
              id: selectedWagon?.clean_status ?? '',
              name:
                cleanStatusTypes(t).find(
                  (status) => status.id === selectedWagon?.clean_status,
                )?.name ?? '',
            }}
            selectedId={selectedWagon?.clean_status}
            onValueSubmitted={(clean_status) => {
              if (clean_status) onChangeInput('clean_status', clean_status)
            }}
            selectorHeight={'max-h-[85px]'}
            inspectorPanel={false}
            disabled={!isConstructionManager}
          />
          <SelectorInlineInputComponent
            items={trainColors(t)}
            label={t('color')}
            getItemLabel={(stat) => stat?.name}
            initialItem={{
              id: selectedWagon?.color ?? '',
              name:
                trainColors(t).find(
                  (statData) => statData.id === selectedWagon?.color,
                )?.name ?? '',
            }}
            validate={() => {
              if (!selectedWagon.color) {
                return t('fill_out_w_param', { param: t('train_color') })
              }
              return
            }}
            selectedId={selectedWagon?.color}
            onValueSubmitted={(color) => {
              if (color) onChangeInput('color', color)
            }}
            inspectorPanel={false}
            disabled={!isConstructionManager}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              selectedWagon?.contract_id
                ? getContractDisciplines(selectedWagon?.contract_id)
                : getProjectDisciplines(projectId)
            }
            label="discipline"
            getItemLabel={(discipline) =>
              `${discipline?.shortName} ${discipline?.name}`
            }
            initialItem={selectedWagon?.discipline}
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={selectedWagon?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              onChangeInput('discipline_id', discipline_id)
            }}
            dependencies={[selectedWagon?.contract_id]}
            inspectorPanel={false}
            disabled={!isConstructionManager}
          />
          <TextInlineInputCompontent
            label="number_of_Board_meetings"
            type={'number'}
            value={`${
              Math.round(Number(wagon.number_of_checklists) * 100) / 100
            }`}
            disabled={true}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={wagon?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={wagon?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      </div>

      {scopeModal && (
        <ConstructionScopeModal
          show={scopeModal}
          closeModal={closeScopeModal}
          onSubmit={onSubmitForm}
          movedWagonId={wagon.id}
        />
      )}

      <ModalFooter className="pr-4 mt-4">
        <Button
          type={Button.ButtonType.DEFAULT}
          onClick={() => setSelectedWagon(wagon)}
        >
          {t('cancel')}
        </Button>
        <Button
          type={Button.ButtonType.PRIMARY}
          disabled={isLoading}
          onClick={() => {
            turnDraftModeOn()
            setScopeModal(true)
          }}
        >
          {t('update')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default WagonModalDetail
