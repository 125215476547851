import moment from 'moment'
import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import { isEmpty, TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import { IRole } from '../../service/OrgTypes'
import { deleteRole, getProjectRoles } from '../../service/RoleService'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import DeleteModal from '../delete-modal/DeleteModal'
import RoleForm from './RoleForm'

interface IRolesListProps extends WithTranslation {
  projectId: number
}

interface IRolesListState {
  roles: IRole[]
  selectedRole: IRole
  loading: boolean
  showEditModal: boolean
  showDeleteModal: boolean
  showCreateModal: boolean
}

class RolesList extends PureComponent<IRolesListProps, IRolesListState> {
  public constructor(props: IRolesListProps) {
    super(props)
    this.state = {
      roles: [],
      selectedRole: {} as IRole,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showCreateModal: false,
    }
  }

  private reload() {
    getProjectRoles(this.props.projectId)
      .then((res: IRole[]) => {
        this.setState({
          roles: res,
          loading: false,
        })
      })
      .catch((err) => console.error(err))
  }

  public componentDidMount() {
    this.reload()
  }

  public componentDidUpdate(prevProps: IRolesListProps) {
    if (prevProps !== this.props) {
      this.setState(
        {
          roles: [],
          loading: true,
        },
        () => {
          this.reload()
        },
      )
    }
  }

  private onEditItemClick = (role: IRole) => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
      selectedRole: role,
    }))
  }

  private onDeletItemClick = (role: IRole) => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
      selectedRole: role,
    }))
  }

  private openEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }))
  }

  private closeEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }))

    this.reload()
  }

  private closeDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }))
  }

  private openCreateModal = () => {
    this.setState((prevState) => ({
      showCreateModal: !prevState.showCreateModal,
    }))
  }

  private closeCreateModal = () => {
    this.setState((prevState) => ({
      showCreateModal: !prevState.showCreateModal,
    }))

    this.reload()
  }

  private deleteRole = () => {
    deleteRole(this.state.selectedRole)
      .then(() => {
        this.reload()
      })
      .catch((err) => console.error(err))

    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }))
  }

  public render() {
    const { roles, loading, showDeleteModal, showCreateModal, showEditModal } =
      this.state

    const { t } = this.props
    const columns: IListColumns[] = [
      {
        name: 'role_name',
        size: '400',
        id: 'roleName',
        dataField: 'roleName',
      },
      {
        name: 'description',
        size: '600',
        id: 'description',
        dataField: 'description',
      },
      {
        name: 'created_at',
        size: '130',
        id: 'createdAt',
        dataField: 'created_at',
        cell: (created: any) => (
          <span>{created ? moment(created).format('L') : ''}</span>
        ),
      },
      {
        name: 'updated_at',
        size: '130',
        id: 'updatedAt',
        dataField: 'updated_at',
        cell: (updated: any) => (
          <span>{updated ? moment(updated).format('L') : ''}</span>
        ),
      },
    ]

    return (
      <div className={TabPageStyleClass.root}>
        <List
          actionButton={
            <Button
              onClick={this.openCreateModal}
              type={Button.ButtonType.PRIMARY}
            >
              {t('new_role')}
            </Button>
          }
          data={roles}
          columns={columns}
          tableName={'roleList'}
          itemsPerPage={0}
          onRowClick={this.onEditItemClick}
          actionMenu={[
            {
              name: t('delete'),
              action: this.onDeletItemClick,
            },
          ]}
        />

        <Modal
          show={showEditModal}
          closeModal={this.openEditModal}
          title={t('edit_role')}
          maxWidth={800}
          notClipContent
        >
          <RoleForm
            editingMode={true}
            role={this.state.selectedRole}
            projectId={this.props.projectId}
            closeModal={this.closeEditModal}
          />
        </Modal>

        {!isEmpty(this.state.selectedRole) && (
          <DeleteModal
            show={showDeleteModal}
            closeModal={this.closeDeleteModal}
            onDelete={this.deleteRole}
            itemIdnType={`(${t('role')})`}
            itemName={`${this.state.selectedRole.roleName}`}
          />
        )}

        <Modal
          size={'w-1/3'}
          show={showCreateModal}
          closeModal={this.openCreateModal}
          title={t('add_role')}
          maxWidth={800}
          notClipContent
        >
          <RoleForm
            editingMode={false}
            role={{
              id: 0,
              roleName: '',
              description: '',
              role_permissions: [{ module: 'document', permission: 'read' }],
            }}
            projectId={this.props.projectId}
            closeModal={this.closeCreateModal}
          />
        </Modal>
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

export default withTranslation()(RolesList)
