import { Moment } from 'moment'
import * as React from 'react'
import { useState } from 'react'
import DateTimeInput from 'src/document/components/DateTimeInput'
import { IInlineBaseProps } from './IDropDown'
import InlineBaseComponent from './InlineBaseComponent'
import InlineBorderComponent from './InlineBorderComponent'
import InlineErrorMessageComponent from './InlineErrorMessageComponent'
import InlineInputLabelComponent from './InlineInputLabelComponent'

interface IDateTimeInlineInputComponent extends IInlineBaseProps {
  selectedTime?: string | Moment
  includeTime?: boolean
  onValueSubmitted: (newTime?: string) => void
  onClear?: () => void
  validate?: (newTime?: Moment) => string | undefined
  className?: string
  inspectorPanel?: boolean
  id?: string
  cancelButton?: boolean
  showBorder?: boolean
  setParentError?: (error: boolean) => void
}

const DateTimeInlineInputComponent: React.FC<IDateTimeInlineInputComponent> = ({
  label,
  selectedTime,
  includeTime = false,
  validate,
  onValueSubmitted,
  labelWidth,
  labelTextSize,
  disabled = false,
  disableTooltip,
  id,
  cancelButton,
  showBorder,
  setParentError,
}) => {
  const [errorMessage, setErrorMessage] = useState('')

  const setError = (message: string) => {
    setErrorMessage(message)
    setParentError?.(!!message.length)
  }

  return (
    <InlineBaseComponent>
      {label && (
        <InlineInputLabelComponent
          label={label}
          labelWidth={labelWidth}
          labelTextSize={labelTextSize}
          disableTooltip={disableTooltip}
        />
      )}
      <div>
        <InlineErrorMessageComponent errorMessage={errorMessage} />
        <InlineBorderComponent
          errorMessage={errorMessage}
          disabled={disabled}
          showBorder={showBorder}
        >
          <DateTimeInput
            id={id}
            date={selectedTime}
            includeTime={includeTime}
            onSave={onValueSubmitted}
            validate={validate}
            setErrorMessage={setError}
            disabled={disabled}
            cancelButton={cancelButton}
          />
        </InlineBorderComponent>
      </div>
    </InlineBaseComponent>
  )
}

export default DateTimeInlineInputComponent
