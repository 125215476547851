export const contructionTaskImportTemplate = {
  name: 'construction_task_import_template',
  displayName: 'construction_task_import_template',
  columns: [
    'record_id',
    'title',
    'wagon',
    'start_date',
    'deadline',
    'status',
    'discipline',
    'unit',
    'total_units',
    'hours_per_unit',
    'productivity_factor',
    'working_hours',
    'duration_hours',
    'number_of_workers',
  ],
  templateColumns: ['name', 'required', 'value', 'description', 'example'],
  template: [
    {
      name: 'record_id',
      required: false,
      value: 'text',
      description: 'record_id',
      example: 'TM23-T568-V3164-AR13504‌‌‍‍‍‌‍‌‌',
    },
    {
      name: 'title',
      required: true,
      value: 'text',
      description: 'title_of_task',
      example: 'Navn til title',
    },
    {
      name: 'wagon',
      required: true,
      value: 'text',
      description: 'name_of_wagon',
      example: 'Test wagon',
    },
    {
      name: 'deadline',
      required: true,
      value: 'date_mm_dd_yyyy',
      description: 'deadline_of_task',
      example: '01.04.2021',
    },
    {
      name: 'status',
      required: true,
      value: 'status_types',
      description: 'status_of_task',
      example: 'Åpen',
    },
    {
      name: 'discipline',
      required: true,
      value: 'text',
      description: 'discipline_of_task',
      example: 'RIV',
    },
    {
      name: 'unit',
      required: false,
      value: 'text',
      description: 'unit_values',
      example: 'meter',
    },
    {
      name: 'total_units',
      required: false,
      value: 'text',
      description: 'unit_of_task',
      example: '1',
    },
    {
      name: 'hours_per_unit',
      required: false,
      value: 'text',
      description: 'hours_per_unit',
      example: '1',
    },
    {
      name: 'productivity_factor',
      required: false,
      value: 'text',
      description: 'productivity_factor',
      example: 0.9,
    },
    {
      name: 'working_hours',
      required: false,
      value: 'text',
      description: 'working_hours',
      example: 1,
    },
    {
      name: 'working_hours',
      required: false,
      value: 'text',
      description: 'working_hours',
      example: 1,
    },
    {
      name: 'number_of_workers',
      required: false,
      value: 'text',
      description: 'number_of_workers',
      example: 0.555,
    },
  ],
}
