export interface IDynamicDateIndicatorProps {
  getLayerRootProps: any
  getLeftOffsetFromDate: any
  date: any
}

const DynamicDateIndicator = ({
  getLayerRootProps,
  getLeftOffsetFromDate,
  date,
}: IDynamicDateIndicatorProps) => {
  const left = getLeftOffsetFromDate(date.startTime.valueOf())
  const right = getLeftOffsetFromDate(date.endTime.valueOf())

  return (
    <div {...getLayerRootProps()}>
      {
        <div
          className="absolute items-center flex"
          key={date.id}
          style={{
            left,
            width: right - left,
            backgroundColor: 'transparent',
            zIndex: 15,
            borderStyle: 'solid',
            minWidth: '1px',
            borderWidth: 0,
            borderColor: date.noTop ? undefined : 'blue',
            borderLeftWidth: date.left ? 2 : 0,
            borderRightWidth: date.left ? 0 : 2,
            borderTopWidth: date.noTop ? 0 : 2,
            height: '110%',
            boxShadow: date.noTop ? '-2px 0 blue' : '-2px 0 transparent',
          }}
        >
          {''}
        </div>
      }
    </div>
  )
}

export default DynamicDateIndicator
