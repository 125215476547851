import { isEmpty } from 'lodash'
import { ICategory } from 'src/components/grid/Grid/types'
import { IRequirement } from 'src/fdvu/types/IRequirement'
import { ISystem, ISystemConfig } from '../types/ISystem'

export const last = (array: any[]) => {
  return array[array.length - 1]
}

const getIndexes = (
  ids: string[] | number[],
  id1: string | number,
  id2: string | number,
) => {
  const index1 = ids.findIndex((id) => id === id1)
  if (id1 === id2) return [index1, index1]
  const index2 = ids.findIndex((id) => id === id2)
  return index1 < index2 ? [index1, index2] : [index2, index1]
}

export const getSortedIdsFromSystemsConfig = (
  systemsConfig: Record<number, ISystemConfig>,
  systems: ISystem[],
) => {
  const systemIds: number[] = []
  systems.forEach((item) => {
    systemIds.push(item.id)
    if (!isEmpty(item.subSystems) && systemsConfig[item.id]?.isExpanded) {
      systemIds.push(
        ...getSortedIdsFromSystemsConfig(systemsConfig, item.subSystems),
      )
    }
  })
  return systemIds
}

export const getSystemConfigSubArray = (
  sortedSystemIds: number[],
  systemId1: number,
  systemId2: number,
) => {
  const [systemStartIndex, systemEndIndex] = getIndexes(
    sortedSystemIds,
    systemId1,
    systemId2,
  )
  return sortedSystemIds.slice(systemStartIndex, systemEndIndex + 1)
}

export const getCategoriesSubArray = (
  categoryIds: string[],
  categoryId1: string,
  categoryId2: string,
) => {
  const [categoryStartIndex, categoryEndIndex] = getIndexes(
    categoryIds,
    categoryId1,
    categoryId2,
  )
  return categoryIds.slice(categoryStartIndex, categoryEndIndex + 1)
}
export const getSelectedCategoriesAndGroups = (
  multipleSelectedCells: Record<number, string[]>,
  categories: ICategory[],
): [string[], ICategory[]] => {
  const uniqueSelectedCategoryIds = [
    ...new Set(Object.values(multipleSelectedCells).flat()),
  ]
  const selectedCategories = categories.filter((category) =>
    uniqueSelectedCategoryIds.includes(category.id),
  )
  const selectedCategoryGroups = selectedCategories
    .map((category) => category.group)
    .filter((val): val is string => !!val)
  return [[...new Set(selectedCategoryGroups)], selectedCategories]
}

export const getFirstSelectedCell = (
  multipleSelectedCells: Record<number, string[]>,
) => {
  const systemId = Number(Object.keys(multipleSelectedCells)[0])
  const categoryId = multipleSelectedCells[systemId][0]
  return { systemId, categoryId }
}

export const getCellsFromRequirements = (
  requirements: Record<number, Record<string, IRequirement[]>>,
) => {
  const cells: Record<number, string[]> = {}
  Object.keys(requirements).forEach((systemId) => {
    cells[systemId] = Object.keys(requirements[systemId])
  })
  return cells
}

export const addSubSystemsToSystem = (
  systemList: ISystem[],
  subSystems: Record<number, ISystem[]>,
) => {
  systemList.forEach((system) => {
    if (Object.keys(subSystems).map(Number).includes(system.id)) {
      system.subSystems = [...system.subSystems, ...subSystems[system.id]]
    }
    if (system.subSystems.length) {
      addSubSystemsToSystem(system.subSystems, subSystems)
    }
  })
  return systemList
}

export const removeSubSystemsFromSystem = (
  systemList: ISystem[],
  subSystemIds: number[],
) => {
  systemList.forEach((system) => {
    system.subSystems = system.subSystems.filter(
      (subSystem) => !subSystemIds.includes(subSystem.id),
    )
    if (system.subSystems.length) {
      removeSubSystemsFromSystem(system.subSystems, subSystemIds)
    }
  })
}

export const getAllChildrenIdsForSystem = (system: ISystem) => {
  const childrenIds: number[] = []
  system.subSystems.forEach((subSystem) => {
    childrenIds.push(subSystem.id)
    if (subSystem.subSystems.length) {
      childrenIds.push(...getAllChildrenIdsForSystem(subSystem))
    }
  })
  return childrenIds
}
