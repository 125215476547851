import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { capFirstLetter, classNames } from 'src/utility/utils'
import BlueBadge from '../base/BlueBadge'

export interface IInspectorHeaderRowData {
  left: {
    label: string
    value: string | boolean | undefined | JSX.Element
    showBadge?: boolean
  }
  right?: {
    label: string
    value: string | boolean | undefined | JSX.Element
    showBadge?: boolean
  }
}

export const InspectorTopRow: React.FC<IInspectorHeaderRowData> = ({
  left,
  right,
}) => {
  const styleClass = {
    label: classNames(
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'truncate',
    ),
    value: classNames(
      'font-normal',
      'text-sm',
      'leading-5',
      'text-gray-500',
      'mb-2',
      'pt-1/4',
      'truncate',
    ),
  }

  const { t } = useTranslation()
  return (
    <div className={'flex flex-none px-5'}>
      <div className={'flex flex-1 flex-col overflow-hidden'}>
        <span className={styleClass.label}>{capFirstLetter(left.label)}</span>
        <span className={styleClass.value}>
          {typeof left.value === 'boolean' ||
          (typeof left.value === 'boolean' && left.showBadge) ? (
            <BlueBadge
              text={
                typeof left.value === 'boolean'
                  ? left.value
                    ? t('yes')
                    : t('no')
                  : left.value
                    ? left.value
                    : t('not_set')
              }
            />
          ) : (
            (left.value ?? t('not_set'))
          )}
        </span>
      </div>
      {right && (
        <div className={'flex flex-1 flex-col overflow-hidden'}>
          <span className={styleClass.label}>
            {capFirstLetter(right.label)}
          </span>
          <span className={styleClass.value}>
            {typeof right.value === 'boolean' ||
            (typeof right.value === 'boolean' && right.showBadge) ? (
              <BlueBadge
                text={
                  typeof right.value === 'boolean'
                    ? right.value
                      ? t('yes')
                      : t('no')
                    : right.value
                      ? right.value
                      : t('not_set')
                }
              />
            ) : (
              (right.value ?? t('not_set'))
            )}
          </span>
        </div>
      )}
    </div>
  )
}
