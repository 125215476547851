import * as React from 'react'
import { IMetaValue } from 'src/document/types/IMetaData'
import Input from 'src/ui-elements/input/Input'
import InputPageDisplay from 'src/ui-elements/page-display/InputPageDisplay'

interface IDocumentMetaDataStringInput {
  field: IMetaValue
  onChange: (newValue: string) => void
  isPage: boolean
  editMode: boolean
}

const DocumentMetaDataStringInput: React.FC<IDocumentMetaDataStringInput> = ({
  field,
  onChange,
  isPage,
  editMode,
}) => {
  return (
    <div className="w-full">
      {isPage ? (
        <InputPageDisplay
          isValid={false}
          block={true}
          editingMode={!!editMode}
          label={field.name}
          required={field.is_required}
          onChange={(e) => onChange(e.target.value)}
          value={field.data_value as string}
          displayData={`${field.data_value}`}
          errorMessage={field.error}
        />
      ) : (
        <Input
          block={true}
          label={field.name}
          required={field.is_required}
          onChange={(e) => onChange(e.target.value)}
          value={field.data_value as string}
          errorMessage={field.error}
        />
      )}
    </div>
  )
}

export default DocumentMetaDataStringInput
