import { Required } from 'utility-types'
import {
  IConstructionCanvasControlArea,
  IControlArea,
  IControlAreaGroup,
  IPaginatedControlAreas,
  IRoom,
  Create,
} from './OrgTypes'
import { request } from './service-utils'

export const getControlArea = (
  controlAreaId: number,
): Promise<IConstructionCanvasControlArea> => {
  return request(`control_areas/${controlAreaId}`, 'GET')
}

export const getControlAreaWithCurrentVogns = (
  controlAreaId: number,
): Promise<IConstructionCanvasControlArea> => {
  return request(`control_areas/${controlAreaId}/this_weeks_locomotives`, 'GET')
}

export const getCurrentControlAreas = (
  projectId: number,
): Promise<IConstructionCanvasControlArea[]> => {
  return request(`projects/${projectId}/this_weeks_control_areas`, 'GET')
}

export const createControlArea = (
  wagon: Create<IControlArea>,
  projectId: number,
): Promise<IControlArea> => {
  return request(`projects/${projectId}/control_areas`, 'POST', wagon)
}

export const editControlArea = (
  controlArea: Required<Partial<IControlArea>, 'id'>,
): Promise<IControlArea> => {
  return request(`control_areas/${controlArea.id}`, 'PUT', controlArea)
}

export const swapOrderControlAreaGroups = (
  controlAreaGroupId: number,
  executionOrder: number,
): Promise<IControlArea[]> => {
  return request(
    `control_area_groups/${controlAreaGroupId}/change_order`,
    'PUT',
    { execution_order: executionOrder },
  )
}

export const getControlAreaGroupTree = (
  projectId: number,
  draftMode: boolean,
): Promise<any> => {
  return request(
    `/projects/${projectId}/control_area_groups/tree?drafting=${draftMode}`,
    'GET',
  )
}

export const getControlAreaTree = (
  projectId: number,
  draftMode: boolean = false,
): Promise<any> => {
  return request(
    `/projects/${projectId}/control_areas/tree?drafting=${draftMode}`,
    'GET',
  )
}

export const swapOrderControlArea = (
  controlAreaId: number,
  executionOrder: number,
): Promise<IControlArea[]> => {
  return request(`control_areas/${controlAreaId}/change_order`, 'PUT', {
    execution_order: executionOrder,
  })
}

export const getProjectControlAreas = (
  projectId: number,
): Promise<IControlArea[]> => {
  return request(`projects/${projectId}/control_areas`, 'GET')
}

export const getProjectGrouplessControlAreas = (
  projectId: number,
): Promise<IControlArea[]> => {
  return request(`projects/${projectId}/control_areas_without_group`, 'GET')
}

export const addControlAreas = (
  controlAreaId: number,
  body: any,
): Promise<any> => {
  return request(
    `/control_area_groups/${controlAreaId}/add_control_areas`,
    'POST',
    body,
    true,
  )
}

export const getControlAreaGroupControlAreas = (
  controlAreaGroupId: number,
): Promise<IControlArea[]> => {
  return request(
    `control_area_groups/${controlAreaGroupId}/control_areas`,
    'GET',
  )
}

export const getControlAreaGroupsControlAreas = (
  projectId: number,
  data: { control_area_group: number[] },
): Promise<IControlArea[]> => {
  return request(
    `projects/${projectId}/control_area_groups/control_areas`,
    'POST',
    data,
  )
}

export const getControlAreaRooms = (controlArea: number): Promise<IRoom[]> => {
  return request(`control_areas/${controlArea}/rooms`, 'GET')
}

export const deleteControlArea = (task: IControlArea) => {
  return request(`/control_areas/${task.id}`, 'DELETE', {}, true)
}

export const createControlAreaGroup = (
  wagon: IControlAreaGroup,
  projectId: number,
): Promise<IControlAreaGroup> => {
  return request(`projects/${projectId}/control_area_groups`, 'POST', wagon)
}

export const editControlAreaGroup = (
  controlArea: IControlAreaGroup,
): Promise<IControlAreaGroup> => {
  return request(`control_area_groups/${controlArea.id}`, 'PUT', controlArea)
}

export const getProjectControlAreaGroups = (
  projectId: number,
): Promise<IControlAreaGroup[]> => {
  return request(`projects/${projectId}/control_area_groups`, 'GET')
}
export const getControlAreaGroup = (id: number) => {
  return request(`/control_area_groups/${id}`, 'GET')
}

export const deleteControlAreaGroup = (task: IControlAreaGroup) => {
  return request(`/control_area_groups/${task.id}`, 'DELETE', {}, true)
}

export const addRoomsToControlArea = (
  controlAreaId: number,
  roomIds: number[],
): Promise<IRoom[]> => {
  return request(`control_areas/${controlAreaId}/rooms`, 'POST', {
    room_ids: roomIds,
  })
}

export const deleteRoomFromControlArea = (
  controlAreaId: number,
  roomId: number,
): Promise<IRoom[]> => {
  return request(
    `/control_areas/${controlAreaId}/rooms/${roomId}`,
    'DELETE',
    {},
    false,
  )
}

export const getPaginatedProjectControlAreas = (
  projectId: number,
  page: number,
  filter?: any,
  perPage?: number,
  draftMode = false,
): Promise<IPaginatedControlAreas> => {
  return request(
    `projects/${projectId}/control_areas/pages/${page}/${
      perPage ? perPage : 100000
    }?drafting=${draftMode}`,
    'POST',
    filter,
  )
}

export const getControlAreaThisWeekStats = (
  projectId: number,
): Promise<any> => {
  return request(`projects/${projectId}/this_weeks_control_area_stats`, 'GET')
}

export const deleteMultipleControlAreas = (
  ids: number[],
): Promise<IControlArea[]> => {
  return request('control_areas/remove_multiple', 'POST', { ids })
}
