import * as React from 'react'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { ITestSystemGroup } from 'src/service/OrgTypes'
import { editTestSystemGroup } from 'src/service/TestSystemGroupService'
import { getProjectTestWorkGroupOnly } from 'src/service/TestWorkGroupService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'

interface ITestSystemGroupFields {
  testSystemGroup?: ITestSystemGroup
  setTestSystemGroup: (tsg: ITestSystemGroup) => void
  onUpdate: () => void
  inspectorPanel?: boolean
}

const TestSystemGroupFields: FC<ITestSystemGroupFields> = ({
  testSystemGroup,
  setTestSystemGroup,
  onUpdate,
  inspectorPanel = false,
}) => {
  const projectId = useProjectId()
  const { t } = useTranslation()
  const userContext = useContext(UserContext)
  const writeAccess =
    userContext.actions.hasWriteAccess('object') ||
    testSystemGroup?.responsible_id === userContext.state.user.id

  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<ITestSystemGroup>(
      setTestSystemGroup,
      testSystemGroup,
    )

  const onChangeInput = (update: Partial<ITestSystemGroup>) => {
    const allUpdates = getChangesForUpdate(update)
    editTestSystemGroup({
      ...convertUndefinedToNull(allUpdates),
      id: testSystemGroup?.id,
    }).then(() => {
      onUpdate()
    })
  }
  return (
    <>
      <TextInlineInputCompontent
        label="title"
        value={testSystemGroup?.title}
        validate={(newValue) => (newValue?.length ? undefined : t('required'))}
        onValueSubmitted={(title) => {
          if (title) onChangeInput({ title })
        }}
        disabled={!writeAccess}
      />
      <TextInlineInputCompontent
        label="test_object_group_id"
        value={testSystemGroup?.record_id}
        validate={(newValue) => (newValue?.length ? undefined : t('required'))}
        onValueSubmitted={(record_id) => {
          if (record_id) onChangeInput({ record_id })
        }}
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        getItems={() => getProjectTestWorkGroupOnly(projectId)}
        label="test_work_group"
        initialItem={testSystemGroup?.test_work_group}
        getItemLabel={(testWorkGroup) =>
          `${testWorkGroup?.record_id} ${testWorkGroup?.title}`
        }
        validate={(value) => (value !== undefined ? undefined : t('required'))}
        selectedId={testSystemGroup?.test_work_group_id}
        onValueSubmitted={(test_work_group_id) => {
          if (test_work_group_id) onChangeInput({ test_work_group_id })
        }}
        inspectorPanel={inspectorPanel}
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        getItems={() =>
          testSystemGroup?.discipline_id
            ? getDisplineUsers(testSystemGroup?.discipline_id)
            : getProjectUsersWithDisciplines(projectId)
        }
        label="responsible"
        initialItem={testSystemGroup?.responsible}
        getItemLabel={(responsible) =>
          `${responsible?.firstName} ${responsible?.lastName}`
        }
        validate={(value) => {
          if (value === undefined) return t('required')
          return
        }}
        selectedId={testSystemGroup?.responsible_id}
        onValueSubmitted={(responsible_id) => {
          onChangeInput({ responsible_id })
        }}
        inspectorPanel={inspectorPanel}
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        getItems={() => getProjectDisciplines(projectId)}
        label="discipline"
        initialItem={testSystemGroup?.discipline}
        getItemLabel={(discipline) =>
          `${discipline?.shortName} - ${discipline?.name}`
        }
        validate={(newValue) =>
          newValue !== undefined ? undefined : t('required')
        }
        selectedId={testSystemGroup?.discipline_id}
        onValueSubmitted={(discipline_id) => {
          addChangesToAppendWhenKeyUpdates('responsible_id', {
            discipline_id,
          })
          onChangeInput({ discipline_id })
        }}
        inspectorPanel={inspectorPanel}
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        label={'contract'}
        disabled
        selectedId={testSystemGroup?.contract_id}
        getItemLabel={(contract) =>
          `${contract?.contractNumber} - ${contract?.contractName}`
        }
        initialItem={testSystemGroup?.contract}
        inspectorPanel={inspectorPanel}
      />
      <TextInlineInputCompontent
        label="description"
        value={testSystemGroup?.description}
        onValueSubmitted={(description) => {
          if (description) onChangeInput({ description })
        }}
        textArea
        disabled={!writeAccess}
      />
    </>
  )
}

export default TestSystemGroupFields
