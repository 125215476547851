import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITag } from 'src/service/OrgTypes.js'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import {
  deleteTag,
  getProjectTags,
  swapOrderTag,
} from '../../service/TagService'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import { capFirstLetter } from '../../utility/utils.js'
import TagForm from './TagForm'

interface ITagsListProps {
  projectId: number
}

const TagList = ({ projectId }: ITagsListProps) => {
  const { t } = useTranslation()
  const [tags, setTags] = useState<ITag[]>([])
  const [selectedTag, setSelectedTag] = useState<ITag>({} as ITag)
  const [loading, setLoading] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)

  const reload = () => {
    getProjectTags(projectId)
      .then((res: ITag[]) => {
        setTags(res)
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  const swapTag = (id: number, seq: number) => {
    const fromTag = tags[id].id ? tags[id].id : 0
    if (fromTag) {
      swapOrderTag(fromTag, seq).then(() => {
        reload()
      })
    }
  }

  useEffect(() => {
    reload()
  }, [])

  const onEditItemClick = (tag: ITag) => {
    setShowEditModal((n) => !n)
    setSelectedTag(tag)
  }

  const onDeletItemClick = (tag: ITag) => {
    setShowDeleteModal((n) => !n)
    setSelectedTag(tag)
  }

  const openEditModal = () => {
    setShowEditModal((n) => !n)
  }

  const closeEditModal = () => {
    setShowEditModal((n) => !n)
    reload()
  }

  const openDeleteModal = () => {
    setShowDeleteModal((n) => !n)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal((n) => !n)
  }

  const openCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
  }

  const thisDeleteTag = () => {
    deleteTag(selectedTag).then(reload)
    setShowDeleteModal((n) => !n)
  }

  const columns = [
    {
      name: 'name',
      size: '400',
      id: 'name',
      dataField: 'name',
      sortingField: 'name',
    },
    {
      name: 'description',
      size: '200',
      id: 'description',
      sortingField: 'description',
      dataField: 'description',
    },
    {
      name: 'created_at',
      size: '130',
      id: 'createdAt',
      sortingField: 'created_at',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      sortingField: 'updated_at',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
    {
      name: 'order',
      size: '150',
      id: 'sequence',
      dataField: 'sequence',
      sortingField: 'sequence',
    },
  ]

  return (
    <div className={TabPageStyleClass.root}>
      <List
        actionButton={
          <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
            {t('new_type')}
          </Button>
        }
        data={tags}
        columns={columns}
        tableName={'tagsList'}
        itemsPerPage={0}
        onRowClick={onEditItemClick}
        actionMenu={[
          {
            name: t('delete'),
            action: onDeletItemClick,
          },
        ]}
        isRowDraggable={true}
        swapOrder={swapTag}
      />

      <Modal
        size={'w-1/3'}
        show={showEditModal}
        closeModal={openEditModal}
        title={t('edit_type')}
        maxWidth={600}
      >
        <TagForm
          editingMode={true}
          tag={selectedTag}
          projectId={projectId}
          closeModal={closeEditModal}
        />
      </Modal>

      <Modal
        show={showDeleteModal}
        closeModal={openDeleteModal}
        title={t('are_you_sure_you_want_to_delete')}
        maxWidth={600}
      >
        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={closeDeleteModal}>
            {capFirstLetter(t('cancel'))}
          </Button>
          <Button type={Button.ButtonType.DANGER} onClick={thisDeleteTag}>
            {capFirstLetter(t('delete'))}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size={'w-1/3'}
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_type')}
        maxWidth={600}
      >
        <TagForm
          editingMode={false}
          tag={{ id: 0, name: '', sequence: tags.length + 1 }}
          projectId={projectId}
          closeModal={closeCreateModal}
        />
      </Modal>

      {loading ? <Loader /> : null}
    </div>
  )
}

export default TagList
