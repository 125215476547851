import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getProjectTaskTypes } from 'src/service/TaskService'

export const useProjectTaskTypes = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['taskTypes', projectId],
    queryFn: () => getProjectTaskTypes(projectId),
  })
}
