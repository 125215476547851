import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import history from 'src/history'
import {
  editConstructionTask,
  getConstructionTask,
} from 'src/service/ConstructionTaskService'
import { getConstructionWagon } from 'src/service/ConstructionWagonService'
import { IConstructionTask, IConstructionWagon } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { classNames } from '../../utility/utils'
import { contentStyle } from '../MUtils'
import MCard from '../components/MCard'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MRowDisplay, { IMRowDisplayData } from '../components/MRowDisplay'
import MVognInfo from './MVognInfo'

const MWorkopsDetail = () => {
  const styleClass = {
    container: classNames('px-6', 'py-2', 'overflow-y-auto'),
  }

  const { t } = useTranslation()
  const { wid: wagonId, wopsid: workOpsId } = useParams<{
    wid: string
    wopsid: string
  }>()
  const userContext = useContext(UserContext)
  const projectContext = useContext(ProjectContext)
  const [reload, setReload] = useState(false)

  const onBackClick = () => {
    history.goBack()
  }
  useEffect(() => {
    setIsLoading(true)
    getConstructionTask(+workOpsId).then((res) => {
      setTask(res)
      setIsLoading(false)
    })
    getConstructionWagon(+wagonId).then((res) => {
      setWagon(res)
    })
  }, [projectContext, reload, wagonId, workOpsId])

  const getStatusColor = (task: IConstructionTask) => {
    return task.status === 'done'
      ? Badge.BadgeColor.GREEN
      : moment(task.status).isBefore(moment(), 'day')
        ? Badge.BadgeColor.RED
        : Badge.BadgeColor.BLUE
  }

  const [task, setTask] = useState<IConstructionTask>({} as IConstructionTask)
  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const headerRowData = (): IMRowDisplayData => [
    { label: t('title'), value: task?.title },
    {
      label: t('deadline'),
      value: task.deadline
        ? moment(task.deadline).format('ddd MMM DD YYYY')
        : '',
    },
    { label: t('contract'), value: task?.contract?.contractName },
    { label: t('discipline'), value: task?.discipline?.name },
    {
      label: t('suggested_values'),
      value: task?.is_estimate ? t('yes') : t('no'),
    },
    { label: t('unit'), value: task?.unit ? t(task?.unit) : '' },
    {
      label: t('total_units'),
      value: `${
        !isNaN(task.total_units) ? Number(task?.total_units)?.toFixed(1) : ''
      }`,
    },
    {
      label: t('hours_per_unit'),
      value: `${
        !isNaN(task.hours_per_unit)
          ? Number(task?.hours_per_unit)?.toFixed(1)
          : ''
      }`,
    },
    {
      label: t('productivity_factor'),
      value: `${
        !isNaN(task.productivity) ? Number(task?.productivity).toFixed(1) : ''
      }`,
    },
    {
      label: t('working_hours'),
      value: `${
        !isNaN(task.hours_per_unit) && !isNaN(task.hours_per_unit)
          ? Number(task?.hours_per_unit * task?.total_units).toFixed(1)
          : ''
      }`,
    },
    {
      label: t('duration_hours'),
      value: `${
        !isNaN(task.planned_execution_hours)
          ? Number(task?.planned_execution_hours).toFixed(1)
          : ''
      }`,
    },
    {
      label: t('number_of_workers'),
      value: `${
        !isNaN(task.number_of_workers)
          ? Number(task?.number_of_workers).toFixed(1)
          : ''
      }`,
    },
    {
      label: t('status'),
      value: (
        <Badge
          text={t(task.status)}
          size={Badge.BadgeSize.LARGE}
          color={getStatusColor(task)}
        />
      ),
    },
  ]

  const onEditWorkOps = () => {
    if (wagon && !isEmpty(wagon)) {
      history.push(`/mobile/add-workops/${workOpsId}/${wagon?.id}`)
    }
  }

  const onMarkComplete = () => {
    const clone: IConstructionTask = { ...task }
    clone.project_id = projectContext.state.currentProject.id
    clone.status = 'done'
    editConstructionTask(clone).then(() => setReload((prev) => !prev))
  }

  return (
    <MPage bgColor={'mobile'}>
      <>
        <MHeader
          showHomeBtn={true}
          showUser={false}
          title={t('activity_details')}
          onBackClick={onBackClick}
          user={userContext.state.user}
        />
        <div style={contentStyle} className={styleClass.container}>
          {isLoading ? (
            <div className={'w-full h-full grid place-items-center'}>
              <SpinnerMobile />
            </div>
          ) : (
            <>
              {wagon && !isEmpty(wagon) && (
                <MCard noPadding={true} className={'overflow-hidden'}>
                  <div className={'w-full md:w-1/2'}>
                    <MVognInfo
                      wagon={wagon}
                      isCard={false}
                      openDetails={true}
                    />
                  </div>
                </MCard>
              )}
              <MCard className={'mt-2'}>
                <MRowDisplay
                  equalWidth={true}
                  noPaddingX={true}
                  data={headerRowData()}
                />
                <div className={'w-full flex justify-between'}>
                  {task.status !== 'done' && (
                    <Button
                      onClick={onMarkComplete}
                      inMobile={true}
                      buttonType={Button.ButtonType.SUCCESS}
                      size={ButtonSize.XSMALL}
                    >
                      <p className={'text-green-400'}>
                        {t('mark_it_as_complete')}
                      </p>
                      <Icon
                        className={'w-6 h-6 ml-2 flex items-center'}
                        icon={Icons.CHECK_GREEN_CIRLCE}
                      />
                    </Button>
                  )}
                  <Button
                    onClick={onEditWorkOps}
                    inMobile={true}
                    buttonType={Button.ButtonType.SUCCESS}
                    size={ButtonSize.XSMALL}
                  >
                    <p className={'text-blue-root'}>{t('edit')}</p>
                    <Icon
                      className={'w-6 h-6 ml-2 flex items-center'}
                      icon={Icons.EDIT_BLUE}
                    />
                  </Button>
                </div>
              </MCard>
            </>
          )}
        </div>
      </>
    </MPage>
  )
}

export default MWorkopsDetail
