import * as React from 'react'
import { Subtract } from 'utility-types'
import { IListContext, ListContextConsumer } from './ListContextProvider'

export const withListContext = <P extends IListContext>(
  Component: React.ComponentType<P>,
) =>
  class WithListContext extends React.PureComponent<Subtract<P, IListContext>> {
    public render() {
      return (
        <ListContextConsumer>
          {(listContext: IListContext) => (
            <Component
              {...(this.props as P)}
              actions={listContext.actions}
              state={listContext.state}
            />
          )}
        </ListContextConsumer>
      )
    }
  }
