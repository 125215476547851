import { IDiscipline } from '../../service/OrgTypes'
import { capFirstLetter } from '../../utility/utils'

const shapes: IShape[] = [
  {
    shape: 'triangle-up',
    color: '#a3baff',
  },
  {
    shape: 'triangle-up',
    color: '#5ED582',
  },
  {
    shape: 'triangle-up',
    color: '#C070E1',
  },
  {
    shape: 'triangle-up',
    color: '#FB8936',
  },
  {
    shape: 'triangle-up',
    color: '#FC62AB',
  },
  {
    shape: 'triangle-up',
    color: '#FF0000',
  },
  {
    shape: 'triangle-up',
    color: '#0F06FF',
  },
  {
    shape: 'triangle-up',
    color: '#70FF14',
  },
  {
    shape: 'triangle-down',
    color: '#a3baff',
  },
  {
    shape: 'triangle-down',
    color: '#5ED582',
  },
  {
    shape: 'triangle-down',
    color: '#C070E1',
  },
  {
    shape: 'triangle-down',
    color: '#FB8936',
  },
  {
    shape: 'triangle-down',
    color: '#FC62AB',
  },
  {
    shape: 'triangle-down',
    color: '#FF0000',
  },
  {
    shape: 'triangle-down',
    color: '#0F06FF',
  },
  {
    shape: 'triangle-down',
    color: '#70FF14',
  },
  {
    shape: 'parallelogram',
    color: '#a3baff',
  },
  {
    shape: 'parallelogram',
    color: '#5ED582',
  },
  {
    shape: 'parallelogram',
    color: '#C070E1',
  },
  {
    shape: 'parallelogram',
    color: '#FB8936',
  },
  {
    shape: 'parallelogram',
    color: '#FC62AB',
  },
  {
    shape: 'parallelogram',
    color: '#FF0000',
  },
  {
    shape: 'parallelogram',
    color: '#0F06FF',
  },
  {
    shape: 'parallelogram',
    color: '#70FF14',
  },
  {
    shape: 'circle',
    color: '#a3baff',
  },
  {
    shape: 'circle',
    color: '#5ED582',
  },
  {
    shape: 'circle',
    color: '#C070E1',
  },
  {
    shape: 'circle',
    color: '#FB8936',
  },
  {
    shape: 'circle',
    color: '#FC62AB',
  },
  {
    shape: 'circle',
    color: '#FF0000',
  },
  {
    shape: 'circle',
    color: '#0F06FF',
  },
  {
    shape: 'circle',
    color: '#70FF14',
  },
  {
    shape: 'square',
    color: '#a3baff',
  },
  {
    shape: 'square',
    color: '#5ED582',
  },
  {
    shape: 'square',
    color: '#C070E1',
  },
  {
    shape: 'square',
    color: '#FB8936',
  },
  {
    shape: 'square',
    color: '#FC62AB',
  },
  {
    shape: 'square',
    color: '#FF0000',
  },
  {
    shape: 'square',
    color: '#0F06FF',
  },
  {
    shape: 'square',
    color: '#70FF14',
  },
  {
    shape: 'triangle-right',
    color: '#a3baff',
  },
  {
    shape: 'triangle-right',
    color: '#5ED582',
  },
  {
    shape: 'triangle-right',
    color: '#C070E1',
  },
  {
    shape: 'triangle-right',
    color: '#FB8936',
  },
  {
    shape: 'triangle-right',
    color: '#FC62AB',
  },
  {
    shape: 'triangle-right',
    color: '#FF0000',
  },
  {
    shape: 'triangle-right',
    color: '#0F06FF',
  },
  {
    shape: 'triangle-right',
    color: '#70FF14',
  },
  {
    shape: 'triangle-left',
    color: '#a3baff',
  },
  {
    shape: 'triangle-left',
    color: '#5ED582',
  },
  {
    shape: 'triangle-left',
    color: '#C070E1',
  },
  {
    shape: 'triangle-left',
    color: '#FB8936',
  },
  {
    shape: 'triangle-left',
    color: '#FC62AB',
  },
  {
    shape: 'triangle-left',
    color: '#FF0000',
  },
  {
    shape: 'triangle-left',
    color: '#0F06FF',
  },
  {
    shape: 'triangle-left',
    color: '#70FF14',
  },
  {
    shape: 'triangle-up',
    color: '#E2760',
  },
  {
    shape: 'triangle-down',
    color: '#85DCB',
  },
  {
    shape: 'parallelogram',
    color: '#E8A87C',
  },
  {
    shape: 'circle',
    color: '#C38D9E',
  },
  {
    shape: 'triangle-up',
    color: '#242582',
  },
  {
    shape: 'triangle-down',
    color: '#553D67',
  },
  {
    shape: 'parallelogram',
    color: '#F64C72',
  },
  {
    shape: 'circle',
    color: '#17252A',
  },
  {
    shape: 'square',
    color: '#2B7A78',
  },
  {
    shape: 'triangle-right',
    color: '#17E9E0',
  },
  {
    shape: 'triangle-left',
    color: '#E64398',
  },
  {
    shape: 'triangle-up',
    color: '#0677A1',
  },
  {
    shape: 'triangle-down',
    color: '#59253A',
  },
  {
    shape: 'parallelogram',
    color: '#1F2605',
  },
  {
    shape: 'circle',
    color: '#10E7DC',
  },
  {
    shape: 'square',
    color: '#F79E02',
  },
  {
    shape: 'triangle-right',
    color: '#5F6366',
  },
  {
    shape: 'triangle-left',
    color: '#EDB5BF',
  },
  {
    shape: 'triangle-up',
    color: '#8D8741',
  },
  {
    shape: 'triangle-down',
    color: '#659DBD',
  },
  {
    shape: 'parallelogram',
    color: '#BC986A',
  },
  {
    shape: 'circle',
    color: '#EDF5F1',
  },
  {
    shape: 'square',
    color: '#501B1D',
  },
  {
    shape: 'triangle-right',
    color: '#83677B',
  },
  {
    shape: 'triangle-left',
    color: '#66FCF1',
  },
  {
    shape: 'triangle-up',
    color: '#5C2018',
  },
  {
    shape: 'triangle-down',
    color: '#D4A59A',
  },
  {
    shape: 'parallelogram',
    color: '#BDA589',
  },
  {
    shape: 'circle',
    color: '#F18904',
  },
  {
    shape: 'square',
    color: '#56388',
  },
  {
    shape: 'triangle-right',
    color: '#6465A5',
  },
  {
    shape: 'triangle-left',
    color: '#732226',
  },
  {
    shape: 'triangle-up',
    color: '#BE9063',
  },
  {
    shape: 'triangle-down',
    color: '#DE8CF0',
  },
  {
    shape: 'parallelogram',
    color: '#704404',
  },
  {
    shape: 'circle',
    color: '#D4dCA9',
  },
  {
    shape: 'square',
    color: '#1F1641',
  },
  {
    shape: 'triangle-right',
    color: '#05ACD3',
  },
  {
    shape: 'triangle-left',
    color: '#2E303E',
  },
  {
    shape: 'triangle-up',
    color: '#594346',
  },
  {
    shape: 'triangle-down',
    color: '#DCD9C6',
  },
  {
    shape: 'parallelogram',
    color: '#A882C1',
  },
  {
    shape: 'circle',
    color: '#423A01',
  },
]

export interface IShape {
  shape: string
  color: string
}

export const setColorForIcon = (i: IShape) => {
  if (i.shape === 'triangle-up') {
    return (
      <div
        className={i.shape}
        style={{ borderBottom: `15px solid ${i.color}` }}
      />
    )
  }
  if (i.shape === 'triangle-down') {
    return (
      <div className={i.shape} style={{ borderTop: `15px solid ${i.color}` }} />
    )
  }
  if (i.shape === 'triangle-right') {
    return (
      <div
        className={i.shape}
        style={{ borderLeft: `15px solid ${i.color}` }}
      />
    )
  }
  if (i.shape === 'triangle-left') {
    return (
      <div
        className={i.shape}
        style={{ borderRight: `15px solid ${i.color}` }}
      />
    )
  }

  return <div className={i.shape} style={{ background: i.color }} />
}

export const generateShapeList = (disciplines: IDiscipline[]): IShape[] => {
  disciplines.map((discpline: IDiscipline) => {
    shapes.map((shape: IShape, index: number) => {
      if (shape.color === discpline.color && shape.shape === discpline.shape) {
        shapes.splice(index, 1)
      }
    })
  })
  return shapes
}

export interface IMainProcessDataTypes {
  id: string
  name: string
}

export const mainProcessDatatypes = (t: any): IMainProcessDataTypes[] => [
  { id: 'mile_stone', name: capFirstLetter(t('milestone')) },
  { id: 'key_point', name: capFirstLetter(t('keypoint')) },
  { id: 'delivery', name: capFirstLetter(t('delivery')) },
  { id: 'task', name: capFirstLetter(t('task')) },
]
