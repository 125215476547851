import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ClosedColumn,
  CreatedAtColumn,
  DeadlineColumn,
  RecordIdColumn,
  ReporterColumn,
  TaskTypeColumn,
  TitleColumn,
  UpdatedAtColumn,
} from 'src/components/TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumn,
  ResponsibleColumn,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import ConstructionTaskForm from 'src/components/task/ConstructionTaskForm'
import TaskInspectorPanel from 'src/components/task/TaskInspectorPanel'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { issuesListExportTemplate } from 'src/export-templates/IssuesExport'
import useDraftMode from 'src/hooks/useDraftMode'
import { getPaginatedWagonsIssues } from 'src/service/ConstructionWagonService'
import {
  IContract,
  IDiscipline,
  IStatusTypes,
  ITask,
  ITaskData,
  IUserData,
} from 'src/service/OrgTypes'
import { actionSource, actionStatus } from 'src/service/SystemValues'
import {
  createTask,
  deleteTask,
  editTask,
  getFilterTasks,
} from 'src/service/TaskService'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import List from 'src/ui-elements/list/List'
import {
  IListFilter,
  ResourceType,
} from 'src/ui-elements/list/ListContextProvider'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import Loader from 'src/ui-elements/loader/Loader'
import Modal from 'src/ui-elements/modal/Modal'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import {
  concatinateValuesForTable,
  contractComparator,
  displineComparator,
  titleComparator,
  userComparator,
} from 'src/utility/Utility'
import { constructFilterJson, IActiveFilter } from 'src/utility/utils'
import {
  filterCase,
  filterType,
  IListColumns,
  ISorting,
} from '../../../ui-elements/list/ListTypes'
import TableDatePicker from '../../datepicker/TableDatePicker'
import { setColorForIcon } from '../../discipline/disciplineUtils'
import TableStatusLabel from '../../status-dropdown/TableStatusLabel'
import UserIcon from '../../user/UserIcon'

interface IConstructionIssuesListProps {
  wagonId?: number
  issueData?: any
  disable?: boolean
  parentType?: string
  parentId?: number
  parentIds?: number[]
  tableName: string
  isExpandedElement?: boolean
}

const ConstructionIssuesList = ({
  wagonId,
  issueData,
  disable,
  parentType,
  parentId,
  parentIds,
  tableName,
  isExpandedElement,
}: IConstructionIssuesListProps) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tasks, setTasks] = useState<ITask[]>([])
  const [selectedTask, setSelectedTask] = useState<ITaskData>({} as ITaskData)
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(0)

  const pageSize = 30

  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state?.currentProject.id
  const { getControlAreaFilter, getWagonFilter, getRoomFilter } =
    useListHelper()

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showInspector, setShowInspector] = useState(false)
  const tasksRef = useRef(tasks)

  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])

  const { draftMode } = useDraftMode()

  useEffect(() => {
    reload()
  }, [wagonId, projectContext])

  const reload = () => {
    setReloadTable((n) => !n)
  }

  useEffect(() => {
    tasksRef.current = tasks
  })

  const onDateChange = (date: moment.Moment, key: number) => {
    const dataField = { id: key, deadline: date } as ITask
    editTask(dataField).then(() => {
      reload()
    })
  }

  const onShowTaskModal = (row: any) => {
    setShowInspector(true)
    setSelectedTask(row)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting

    if (parentType === 'ControlArea') {
      activeFilters['control_area'] = [parentId]
    } else if (parentType && (parentId || parentIds)) {
      activeFilters['parent_type'] = [parentType]
      activeFilters['parent_id'] = parentId ? [parentId] : parentIds
    } else if (parentType && !parentId) {
      activeFilters['parent_type'] = [parentType]
    } else if (wagonId) {
      activeFilters['construction_locomotive_id'] = [wagonId]
    } else {
      if (!activeFilters['parent_type']?.length) {
        activeFilters['parent_type'] = [
          'ControlArea',
          'ChecklistParameter',
          'CheckInParameter',
          'ConstructionLocomotive',
          'System',
          'Room',
          'Project',
        ]
      }
    }

    taskFilter(activeFilters, page)
  }

  const taskFilter = (activeFilters: IActiveFilter, page: number) => {
    setLoading(true)
    if (issueData) {
      setTasks(issueData)
      setLoading(false)
    } else {
      wagonId
        ? getPaginatedWagonsIssues(wagonId, page, activeFilters, pageSize).then(
            (res) => {
              setTasks(res.tasks)
              setTotalPages(res.pages)
              setLoading(false)
            },
          )
        : getFilterTasks(
            projectContext.state.currentProject.id,
            activeFilters,
            page,
            pageSize,
            'Aksjon',
          ).then((res) => {
            setTasks(res.tasks)
            setTotalPages(res.pages)
            setLoading(false)
          })
    }
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const taskList = [...tasksRef.current]
    const task = taskList.find((d) => d.id === key)
    if (task) {
      task[field] = value
      saveTask(task)
    }
  }

  const saveTask = (value: ITask) => {
    editTask(value).then(() => {
      reload()
    })
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const dataField = {
      id,
      discipline_id: disciplineId,
      responsible_id: responsibleId,
    } as ITask
    saveTask(dataField)
  }

  const updateUser = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const dataField = {
      id,
      discipline_id: disciplineId,
      responsible_id: responsibleId,
    } as ITask
    saveTask(dataField)
  }

  const onStatusSelect = (stat: IStatusTypes, key: number) => {
    const dataField: Partial<ITask> = { id: key }
    if (stat.id === 'done') {
      dataField.closed_date = moment()
    } else {
      if (dataField.status === 'done') {
        dataField.closed_date = null
      }
    }
    dataField.status = stat.id
    dataField.id = key

    editTask(dataField).then(() => {
      reload()
    })
  }

  const noFiltercolumns: IListColumns[] = [
    {
      name: 'id',
      size: '200',
      id: 'recordId',
      sortingField: 'record_id',
      dataField: 'record_id',
    },
    {
      name: 'title',
      size: '480',
      id: 'title',
      sortingField: 'title',
      dataField: 'title',
    },
    {
      name: 'status',
      size: '200',
      id: 'status',
      sortingField: 'status',
      dataField: 'status||deadline||update_access',
      cell: (cell, key: number) => (
        <TableStatusLabel
          statusTypes={actionStatus(t)}
          cell={cell}
          rowId={key}
          onStatusSelect={onStatusSelect}
        />
      ),
    },
    {
      name: 'deadline',
      size: '200',
      id: 'deadline',
      sortingField: 'deadline',
      dataField: 'deadline||parent||parent_type',
      cell: (data, row: number) => (
        <TableDatePicker
          date={data.deadline}
          rowId={row}
          onDateSubmit={onDateChange}
          inModal={true}
        />
      ),
    },
    {
      name: 'closed_date',
      size: '200',
      id: 'closedDate',
      sortingField: 'closed_date',
      dataField: 'closed_date',
      cell: (closedDate) => (
        <span>{closedDate ? moment(closedDate).format('L') : ''}</span>
      ),
    },
    {
      name: 'responsible',
      size: '250',
      id: 'responsible',
      dataField: 'responsible',
      sortingField: 'responsible',
      comparator: (a, b, direction: boolean) =>
        a ? (b ? userComparator(a, b, direction) : 0) : 0,
      cell: (responsible) =>
        responsible ? (
          <UserIcon
            userId={responsible.id}
            firstName={responsible.firstName}
            lastName={responsible.lastName}
            image_url={responsible.image_url}
          />
        ) : (
          <span />
        ),
    },
    {
      name: 'discipline',
      size: '200',
      id: 'discipline',
      dataField: 'discipline',
      sortingField: 'discipline',
      comparator: (a, b, direction: boolean) =>
        a ? (b ? displineComparator(a, b, direction) : 0) : 0,
      cell: (discipline: IDiscipline) =>
        discipline ? (
          <span className={'flex flex-row'}>
            <span className={'mr-2'}>
              {setColorForIcon({
                color: discipline.color,
                shape: discipline.shape,
              })}
            </span>
            <span>
              {discipline
                ? concatinateValuesForTable(
                    discipline.shortName,
                    discipline.name,
                  )
                : ''}
            </span>
          </span>
        ) : (
          <span />
        ),
    },
    {
      name: 'contract',
      size: '200',
      id: 'contract',
      dataField: 'contract',
      sortingField: 'contract',
      comparator: (a, b, direction: boolean) =>
        a ? (b ? contractComparator(a, b, direction) : 0) : 0,
      cell: (contract: IContract) =>
        contract ? (
          <span className={'flex flex-row'}>
            <span>
              {contract
                ? concatinateValuesForTable(
                    contract.contractNumber,
                    contract.contractName,
                  )
                : ''}
            </span>
          </span>
        ) : (
          <span />
        ),
    },
    {
      name: 'user',
      size: '240',
      id: 'user',
      dataField: 'reporter',
      sortingField: 'reporter',
      comparator: (a, b, direction: boolean) =>
        a ? (b ? userComparator(a, b, direction) : 0) : 0,
      cell: (user) =>
        user ? (
          <UserIcon
            userId={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            image_url={user.image_url}
          />
        ) : (
          <span />
        ),
    },
    {
      name: 'source',
      size: '200',
      id: 'parentType',
      dataField: 'parent_type',
      sortingField: 'parent_type',
      cell: (parent) => (
        <span>
          {actionSource(t)
            .filter((pt) => pt.id === parent)
            .map((pt) => pt.name)
            .pop()}
        </span>
      ),
    },
    {
      name: 'created_at',
      size: '200',
      id: 'createdAt',
      dataField: 'created_at',
      sortingField: 'created_at',
      cell: (created) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '200',
      id: 'updatedAt',
      dataField: 'updated_at',
      sortingField: 'updated_at',
      cell: (updated) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const columns: IListColumns[] = [
    RecordIdColumn('200'),
    TitleColumn('title', '480', (key: number, value: string) =>
      onFieldChange('title', key, value),
    ),
    {
      name: 'status',
      size: '200',
      id: 'status',
      sortingField: 'status',
      dataField: 'status||deadline||update_access',
      filterType: filterType.DEFAULT,
      filter: [
        {
          name: 'open',
          value: 'open',
          active: true,
        },
        {
          name: 'in_progress',
          value: 'in_progress',
          active: true,
        },
        {
          name: 'done',
          value: 'done',
          active: false,
        },
      ],
      cell: (cell, key: number) => (
        <TableStatusLabel
          statusTypes={actionStatus(t)}
          cell={cell}
          rowId={key}
          onStatusSelect={onStatusSelect}
        />
      ),
    },
    DeadlineColumn('deadline', 'deadline||parent||parent_type', onDateChange),
    ClosedColumn,
    ResponsibleColumn(projectId, projectUsers, projectDiscipline, updateUser),
    DisciplineColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      updateDiscipline,
    ),
    ContractColumn(),
    TaskTypeColumn(projectId, (key, value) =>
      onFieldChange('task_type_id', key, value),
    ),
    ReporterColumn('reporter'),
    CreatedAtColumn(),
    UpdatedAtColumn(),
  ]

  const parentTypeColumn: IListColumns[] = [
    {
      name: 'source',
      size: '180',
      id: 'parentType',
      dataField: 'parent_type',
      sortingField: 'parent_type',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_SOURCE,
      filterDataField: 'parent_type',
      filterDataValue: 'parent_type',
      filter: [
        {
          value: 'ChecklistParameter',
          name: 'board_meeting',
          active: true,
        },
        {
          value: 'CheckInParameter',
          name: 'check_in_out',
          active: true,
        },
        {
          value: 'ConstructionLocomotive',
          name: 'wagon',
          active: true,
        },
        {
          value: 'ControlArea',
          name: 'control_area',
          active: true,
        },
        {
          value: 'System',
          name: 'system',
          active: true,
        },
        {
          value: 'Room',
          name: 'room',
          active: true,
        },
        {
          value: 'Project',
          name: 'project',
          active: true,
        },
      ],
      cell: (parent) => (
        <span>
          {actionSource(t)
            .filter((pt) => pt.id === parent)
            .map((pt) => pt.name)
            .pop()}
        </span>
      ),
    },
    {
      name: 'room',
      size: '240',
      id: 'location',
      dataField: 'location',
      sortingField: 'location',
      filterType: filterType.DEFAULT,
      filterDataField: 'location.room_name',
      filterDataValue: 'location.id',
      getFilter: getRoomFilter,
      filter: [],
      cell: (location) =>
        location ? <span>{location.room_name}</span> : <span />,
    },
    {
      name: 'control_area',
      size: '200',
      id: 'controlArea',
      dataField: 'control_area',
      sortingField: 'control_area',
      filterType: filterType.DEFAULT,
      filter: [],
      getFilter: getControlAreaFilter,
      filterDataField: 'control_area.title',
      filterDataValue: 'control_area.id',
      comparator: (a, b, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (controlArea) =>
        controlArea ? <span>{controlArea.title}</span> : <span />,
    },
    {
      name: 'wagon',
      size: '200',
      id: 'wagon',
      dataField: 'construction_locomotive',
      sortingField: 'construction_locomotive',
      filterType: filterType.DEFAULT,
      filter: [],
      getFilter: getWagonFilter,
      comparator: (a, b, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (locomotive) =>
        locomotive ? <span>{locomotive.title}</span> : <span />,
    },
  ]

  const getActionButton = () => {
    if (disable || draftMode || issueData || !(parentId || wagonId)) {
      return undefined
    }

    if (isExpandedElement) {
      return (
        <Icon
          icon={Icons.PLUS}
          className="py-1/5 text-center w-6"
          onClick={openCreateModal}
        />
      )
    }

    return (
      <Button
        onClick={openCreateModal}
        type={Button.ButtonType.PRIMARY}
        size={Button.ButtonSize.SMALL}
      >
        {t('new_case')}
      </Button>
    )
  }

  const getActionMenus = () => {
    return !issueData
      ? [
          {
            name: t('delete'),
            action: onDeletItemClick,
          },
        ]
      : undefined
  }

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const closeCreateModal = () => {
    setShowCreateModal(false)
  }

  const onDeletItemClick = (row: any) => {
    setShowDeleteModal(!showDeleteModal)
    setSelectedTask(row)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
    reload()
  }

  const removeTask = () => {
    deleteTask(selectedTask)
      .then(() => {
        setShowDeleteModal(!showDeleteModal)
        reload()
      })
      .catch((err) => console.error(err))
  }

  const getColumns = () => {
    if (issueData) {
      return noFiltercolumns
    }
    const currentColumns = columns
    if (wagonId || parentType) {
      return currentColumns
    } else {
      currentColumns.splice(currentColumns.length - 2, 0, ...parentTypeColumn)
      return currentColumns
    }
  }

  const createTaskForConstructionIssues = async (taskData: ITaskData) => {
    if (parentId && parentType) {
      taskData.parent_id = parentId
      taskData.parent_type = parentType
    }
    createTask(taskData).then(() => {
      reload()
      setShowCreateModal(false)
    })
  }

  const onPreviewClick = (e: any, data: any) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedTask(data)
    setShowInspector(true)
  }

  return (
    <>
      <List
        actionButton={getActionButton()}
        columns={getColumns()}
        data={tasks}
        tableName={tableName}
        exportTemplate={issuesListExportTemplate(
          wagonId
            ? `construction_locomotives/${wagonId}/tasks`
            : `/projects/${projectId}/filter_tasks/Aksjon/pages/0/0`,
        )}
        itemsPerPage={pageSize}
        pagination={true}
        resourceType={ResourceType.TASK}
        onRowClick={onShowTaskModal}
        sortBackend={!issueData}
        filterResetOption={true}
        getFilteredData={getFilteredData}
        parent_type={parentType ? parentType : undefined}
        parentId={parentId ? parentId : undefined}
        reload={reloadTable}
        totalPages={totalPages}
        actionMenu={getActionMenus()}
        onPreviewClick={onPreviewClick}
      />

      {loading ? <Loader /> : null}

      {showCreateModal && (
        <Modal
          show={showCreateModal}
          closeModal={closeCreateModal}
          title={t('new_case')}
          maxWidth={800}
        >
          <div className={''}>
            <ConstructionTaskForm
              projectId={projectId}
              parentType={
                parentType
                  ? parentType
                  : wagonId
                    ? 'ConstructionLocomotive'
                    : 'ControlArea'
              }
              parentId={parentId ? parentId : wagonId ? wagonId : 0}
              closeModal={closeCreateModal}
              updateList={() => {}}
              submitTask={createTaskForConstructionIssues}
            />
          </div>
        </Modal>
      )}

      {!isEmpty(selectedTask) && (
        <DeleteModal
          customTitle={
            selectedTask.delete_access
              ? t('are_you_sure_you_want_to_delete_no_question_mark') +
                ' ' +
                selectedTask.record_id +
                '?'
              : t('you_are_not_entitled_to_that_operation')
          }
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={removeTask}
          itemIdnType={`${selectedTask.record_id} (${t('task')})`}
          itemName={`${selectedTask.record_id} - ${selectedTask.title}`}
        />
      )}

      {!isEmpty(selectedTask) && showInspector && (
        <TaskInspectorPanel
          taskId={selectedTask.id}
          open={showInspector}
          onClose={() => {
            setShowInspector(false)
            setSelectedTask({} as ITaskData)
          }}
          projectId={projectId}
          onUpdate={reload}
        />
      )}
    </>
  )
}
export default ConstructionIssuesList
