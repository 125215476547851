import { useTranslation } from 'react-i18next'
import CopyUrl from 'src/components/copy/CopyUrl'
import Tooltip from 'src/components/tool-tip/Tooltip'
import { capFirstLetter, classNames } from '../../utility/utils'
import Icon from '../icon/Icon'

interface IPageHeaderProps {
  title?: string | JSX.Element
  subTitle?: string
  hasCrumPage?: boolean
  editingMode?: boolean
  disabled?: boolean
  onEdit?: () => void
  onSave?: (e: any) => void
  onCancel?: () => void
  onQrClick?: () => void
  copyUrl?: boolean
  onDeleteClick?: () => void
  onExportClick?: (pdf?: boolean) => void
  onSendEmailClick?: () => void
  additionalButtons?: JSX.Element
  expanded?: boolean
  onExpandClick?: () => void
  onFeedbackFormClick?: () => void
  className?: string
  noHeader?: boolean
  disabledMessage?: string
}

const styleClass = {
  root: (breadCrumbOnTop: boolean, moreClasses: string) =>
    classNames(
      'w-auto',
      'pl-7',
      'py-1',
      'border-b',
      'border-gray-200',
      moreClasses,
    ),
  title: classNames('text-base', 'leading-6', 'font-medium', 'text-gray-900'),
  header: classNames('flex', 'flex-row', 'items-center', 'flex-wrap'),
  btnContainer: (hide: boolean) =>
    classNames('flex', 'flex-row', 'items-center', hide ? 'hidden' : ''),
  actionBtn: (type: string, isDisabled?: boolean) =>
    classNames(
      'mx-2',
      'flex',
      'items-center',
      'justify-center',
      type === 'edit'
        ? isDisabled
          ? 'opacity-25 bg-gray-300'
          : 'bg-blue-root-tab-wash'
        : '',
      type === 'save'
        ? ' border border-green-300 bg-green-200 hover:bg-green-300'
        : '',
      type === 'cancel'
        ? ' border border-red-300 bg-red-200 hover:bg-red-300'
        : '',
      'rounded-full',
      'w-8',
      'h-8',
      'p-1',
      'hover:cursor-pointer',
    ),
  commonBtn: {
    root: classNames(
      'mx-2',
      'w-8',
      'h-8',
      'p-0.5',
      'flex',
      'justify-center',
      'items-center',
      'border-gray-300',
      'border',
      'rounded-full',
      'hover:cursor-pointer',
    ),
    icon: classNames('flex', 'items-center', 'w-5p', 'h-5'),
  },
  subtitle: classNames(
    'mt-1',
    'max-w-2xl',
    'text-sm',
    'leading-5',
    'text-gray-500',
  ),
}

const PageHeader = ({
  title,
  subTitle,
  editingMode,
  onEdit,
  onSave,
  onCancel,
  hasCrumPage = true,
  onQrClick,
  copyUrl,
  onDeleteClick,
  onExportClick,
  onSendEmailClick,
  additionalButtons,
  disabled = false,
  expanded,
  onExpandClick,
  onFeedbackFormClick,
  className = '',
  noHeader,
  disabledMessage,
}: IPageHeaderProps) => {
  const { t } = useTranslation()
  return (
    <div className={styleClass.root(hasCrumPage, className)}>
      {!noHeader && (
        <div className={styleClass.header}>
          {onExpandClick && (
            <span
              onClick={onExpandClick}
              className={`${styleClass.commonBtn.root} ml-0 border-none`}
            >
              <Icon
                icon={
                  expanded ? Icon.IconType.ARROW_UP : Icon.IconType.ARROW_DOWN
                }
              />
            </span>
          )}
          <h3 className={styleClass.title}>
            {typeof title === 'string' ? capFirstLetter(title) : title}
          </h3>
          <span className={'flex pl-4'}>
            <span className={styleClass.btnContainer(editingMode ?? false)}>
              {onEdit && (
                <Tooltip
                  direction={'right'}
                  content={
                    disabledMessage
                      ? disabledMessage
                      : t('do_not_have_access_to_edit')
                  }
                  hidden={!disabled}
                >
                  <span
                    onClick={() => (!disabled ? onEdit() : null)}
                    className={styleClass.actionBtn('edit', disabled)}
                  >
                    <Icon
                      icon={
                        !disabled ? Icon.IconType.EDIT_BLUE : Icon.IconType.EDIT
                      }
                    />
                  </span>
                </Tooltip>
              )}
              {onQrClick && (
                <span onClick={onQrClick} className={styleClass.commonBtn.root}>
                  <a title={t('generate_qr_code')}>
                    <Icon
                      className={styleClass.commonBtn.icon}
                      icon={Icon.IconType.QR}
                    />
                  </a>
                </span>
              )}
              {copyUrl && (
                <span className={styleClass.commonBtn.root}>
                  <CopyUrl />
                </span>
              )}
              {!disabled && onExportClick && (
                <span
                  onClick={() => {
                    onExportClick(false)
                  }}
                  className={styleClass.commonBtn.root}
                >
                  <a title={t('generate_meeting_hours')}>
                    <Icon
                      className={styleClass.commonBtn.icon}
                      icon={Icon.IconType.DOCX}
                    />
                  </a>
                </span>
              )}
              {onExportClick && (
                <span
                  onClick={() => {
                    onExportClick(true)
                  }}
                  className={styleClass.commonBtn.root}
                >
                  <a title={t('generate_meeting_hours')}>
                    <Icon
                      className={styleClass.commonBtn.icon}
                      icon={Icon.IconType.PDF}
                    />
                  </a>
                </span>
              )}
              {onSendEmailClick && (
                <span
                  onClick={onSendEmailClick}
                  className={styleClass.commonBtn.root}
                >
                  <a title={t('send_meeting_hours_by_e-mail')}>
                    <Icon
                      className={styleClass.commonBtn.icon}
                      icon={Icon.IconType.EMAIL}
                    />
                  </a>
                </span>
              )}
              {onDeleteClick && (
                <span
                  onClick={onDeleteClick}
                  className={styleClass.actionBtn('cancel')}
                >
                  <Icon icon={Icon.IconType.DELETE_GRAY} />
                </span>
              )}
              {onFeedbackFormClick && (
                <span
                  onClick={onFeedbackFormClick}
                  className={`${styleClass.commonBtn.root} pl-1`}
                >
                  <Icon
                    icon={Icon.IconType.FEEDBACK_BLUE}
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
              )}
            </span>
            <span className={styleClass.btnContainer(!editingMode)}>
              <span
                onClick={onCancel}
                className={styleClass.actionBtn('cancel')}
              >
                <Icon icon={Icon.IconType.CLOSE_GRAY} />
              </span>
              {onSave && (
                <span
                  onClick={(e) => onSave(e)}
                  className={styleClass.actionBtn('save')}
                >
                  <Icon icon={Icon.IconType.CHECK_GREEN} />
                </span>
              )}
            </span>
            <span className={`ml-2`}>{additionalButtons}</span>
          </span>
        </div>
      )}
      {subTitle && <p className="">{subTitle}</p>}
    </div>
  )
}

export default PageHeader
