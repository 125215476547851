import { useUpdateComment } from 'src/query/comment'
import { IComment } from 'src/service/OrgTypes'
import { CommentFileItem } from './CommentFilesList'
import CommentInput, { FileItem } from './CommentInput'

interface EditCommentProps {
  comment: IComment
  files: CommentFileItem[]
  onCommentSaved: () => void
  onCancel: () => void
}

const EditComment = ({
  comment,
  files,
  onCommentSaved,
  onCancel,
}: EditCommentProps) => {
  const { mutate: updateComment } = useUpdateComment()

  const onSave = async (newComment: string, files: FileItem[]) => {
    const newFiles = files
      .map((f) => f.file)
      .filter((f): f is File => f !== undefined)
    updateComment({
      comment: {
        ...comment,
        content: newComment,
        documents: comment.documents.filter((document) =>
          files.find((file) => file.documentId === document.id),
        ),
      },
      newFiles,
    })
    onCommentSaved()
  }

  return (
    <CommentInput
      onSave={onSave}
      onCancel={onCancel}
      value={comment.content}
      editMode
      existingFiles={files}
    />
  )
}

export default EditComment
