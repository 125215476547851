import { FC, PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import { IPos } from '../types'

export interface PopUpProps {
  pos?: IPos
  show: boolean
  close: () => void
  className?: string
}

const PopUp: FC<PropsWithChildren<PopUpProps>> = ({
  pos,
  children,
  show,
  close,
  className,
}) => {
  const styleClass = twMerge(
    'p-3 bg-white rounded shadow flex-col z-[30] text-gray-900 border border-gray-300',
    className,
  )
  return (
    <>
      {show && (
        <CloseClickOutside onClose={close}>
          <div style={{ ...pos }} className={styleClass + ' fixed'}>
            {children}
          </div>
        </CloseClickOutside>
      )}
    </>
  )
}

export default PopUp
