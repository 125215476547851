import Check from '@icons/check.svg'
import Close from '@icons/close.svg'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import {
  FmiObject,
  FmiStatus,
} from 'src/components/system/system-fmi/FmiSelectorsWithComment'
import Button from 'src/ui-elements/button/Button'

interface ApproveOrDeclineButtonProps {
  suggestedFmi?: FmiObject
  fmiStatus: FmiStatus
  updateOrSuggestFmiStatusState: (
    status: FmiStatus,
    modalText: string,
    iconColor: string,
  ) => void
}
const ApproveOrDeclineButton = ({
  suggestedFmi,
  updateOrSuggestFmiStatusState,
  fmiStatus,
}: ApproveOrDeclineButtonProps) => {
  const { t } = useTranslation()

  const buttonText = fmiStatus === FmiStatus.APPROVED ? 'approve' : 'decline'

  return (
    <Button
      className={twMerge(
        'border-0 py-0 pl-1 pr-2 text-xs rounded',
        fmiStatus === FmiStatus.APPROVED && 'bg-green-check',
        fmiStatus === FmiStatus.DECLINED && 'bg-red-status',
        suggestedFmi && 'hover:opacity-50',
      )}
      disabled={!suggestedFmi}
      onClick={() =>
        updateOrSuggestFmiStatusState(
          fmiStatus,
          `${capitalize(t(buttonText))} ${t('system_fmi_suggestion')}`,
          `fill-${fmiStatus === FmiStatus.APPROVED ? 'green' : 'red'}`,
        )
      }
    >
      {fmiStatus === FmiStatus.APPROVED ? (
        <Check
          className={twMerge(
            'text-xl',
            suggestedFmi ? `fill-green` : 'fill-gray-400',
          )}
        />
      ) : (
        <Close
          className={twMerge(
            'text-xl',
            suggestedFmi ? `fill-d-danger` : 'fill-gray-400',
          )}
        />
      )}

      <div>{capitalize(t(buttonText))}</div>
    </Button>
  )
}

export default ApproveOrDeclineButton
