import { useEffect } from 'react'
import {
  Route,
  generatePath,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

const RedirectHelper = ({ to, base }: { to: string; base: string }) => {
  const history = useHistory()
  const match = useRouteMatch()
  useEffect(() => {
    const urlParams = history.location.search
    const notMatchedPath = history.location.pathname.replace(
      new RegExp(`^${match.url}`),
      '',
    )
    const newPath = generatePath(`${base}/${to}`, match.params)
    history.replace(`${newPath}${notMatchedPath}${urlParams}`)
  }, [])
  return <></>
}

/**
This function creates a route which will redirect all links matching base/from/* to base/to/*.
It is therefore very useful if a page has been moved from one page to the other, so that all old links still work with a single redirect.
@param base: The base url that prefixes both the old and new url
@param from: The old url
@param to: The new url
@param exact: If only exactly matching paths should be redirected, i. e. only exactly base/from will be redirected. Default false.
*/
export const ImprovedRedirect = (
  base: string,
  from: string,
  to: string,
  exact: boolean = false,
) => {
  return (
    <Route
      path={`${base}/${from}`}
      key={'from'}
      exact={exact}
      render={() => <RedirectHelper to={to} base={base} />}
    />
  )
}
