import * as React from 'react'
import LinkfyText from 'src/components/linkfyText/LinkfyText'
import { capFirstLetter, classNames } from '../../utility/utils'
import Textarea from '../textarea/Textarea'

interface ITextAreaDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  errorMessage?: string
  isValid: boolean
  description?: string
  block?: boolean | undefined
  className?: string
  required?: boolean
  value?: string
  disabled?: boolean
  onChange: (e: any) => void
  editingMode: boolean
  displayData: string
  minPadding?: boolean
}

export default class TextAreaDisplay extends React.PureComponent<
  ITextAreaDisplayProps,
  any
> {
  private styleClass = {
    placeholder: classNames(
      'text-gray-400',
      'font-normal',
      'text-sm',
      'lowercase',
    ),
    displayText: classNames('block', 'mx-2', 'text-gray-500', 'break-words'),
    tableRow: (hasError: boolean, minPadding: boolean) =>
      classNames(
        'sm:grid',
        'sm:grid-cols-3',
        'sm:gap-4',
        minPadding ? 'pl-2' : 'sm:pl-6',
        hasError ? 'sm:pb-2' : 'sm:py-3',
        hasError ? 'pb-1' : 'py-2',
        hasError ? '' : 'my-2',
        'sm:pr-1',
        'border-b',
        'border-gray-200',
      ),
    tableLeftText: (_editingMode: boolean) =>
      classNames(
        'flex',
        'items-start',
        'text-sm',
        'leading-5',
        'font-medium',
        'text-gray-700',
        'pl-1',
        'pb-2',
        'truncate',
      ),
    tableCellRight: (_editingMode: boolean) =>
      classNames('mt-1', 'text-sm', 'leading-5', 'sm:mt-0', 'sm:col-span-2'),
  }

  public render() {
    const {
      label,
      errorMessage,
      isValid,
      description,
      block,
      className,
      required,
      editingMode,
      displayData,
      value,
      onChange,
      disabled,
      minPadding,
    } = this.props
    const hasError = !!(errorMessage && errorMessage !== '')
    return (
      <div className={this.styleClass.tableRow(hasError, minPadding || false)}>
        <dt className={this.styleClass.tableLeftText(editingMode)}>
          {capFirstLetter(label)}
          {required && editingMode ? ' *' : ''}
        </dt>
        <dd className={this.styleClass.tableCellRight(editingMode || false)}>
          <div className="w-full">
            {editingMode ? (
              <div style={{ marginLeft: 0 }}>
                <Textarea
                  label={label}
                  hidelabel={true}
                  errorMessage={errorMessage}
                  isValid={isValid}
                  description={description}
                  block={block}
                  className={className}
                  required={required}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                />
              </div>
            ) : (
              <span className={this.styleClass.displayText}>
                <LinkfyText value={displayData} />
              </span>
            )}
          </div>
        </dd>
      </div>
    )
  }
}
