import * as React from 'react'

interface IInlineErrorMessageComponent {
  errorMessage?: string
}

const InlineErrorMessageComponent: React.FC<IInlineErrorMessageComponent> = ({
  errorMessage,
}) => {
  return (
    <div
      className={`font-light text-xxs text-red ${
        errorMessage?.length ? '' : 'opacity-0'
      }`}
    >
      {errorMessage}
    </div>
  )
}

export default InlineErrorMessageComponent
