import ArrowBack from '@icons/arrow_back.svg'
import ArrowForward from '@icons/arrow_forward.svg'
import Warning from '@icons/warning.svg'
import * as React from 'react'
import { KeyboardEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionMeta, createFilter, OnChangeValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { twMerge } from 'tailwind-merge'
import { IMetaData } from 'src/document/types/IMetaData'
import Input from '../../../../ui-elements/input/Input'
import { metaDataTypes } from '../../../types/FolderMetadataTypes'
import FolderMetadataField from '../FolderMetadataField/FolderMetadataField'
import RemoveIcon from '../icons/RemoveIcon'
import FolderMetadataDefault from './FolderMetadataDefault'

interface IFolderMetadataRowProps {
  field: IMetaData
  updateMetadata: (id: string, fieldUpdate: Partial<IMetaData>) => void
  onRemove?: () => void
  metaFieldMenuOnTheLeft?: boolean
  disabled?: boolean
}

interface Option {
  label: string
  value: string
}

interface State {
  inputValue: string
  value: Option[]
}

const FolderMetadataRow: React.FC<IFolderMetadataRowProps> = ({
  field,
  updateMetadata,
  onRemove,
  metaFieldMenuOnTheLeft,
  disabled,
}) => {
  const { t } = useTranslation()
  const [enumError, setEnumError] = useState('')
  const [enumValue, setEnumValue] = useState<State>({
    inputValue: '',
    value: [],
  })
  const pageSize = 200
  const [page, setPage] = useState(1)
  const [currentPageRange, setCurrentPageRange] = useState(page * pageSize)

  useEffect(() => {
    setCurrentPageRange(page * pageSize)
  }, [page])

  useEffect(() => {
    if (
      (field.data_type === 'enum' || field.data_type === 'multi_enum') &&
      Array.isArray(field.pattern)
    ) {
      setEnumValue({
        inputValue: '',
        value: field.pattern
          .slice(currentPageRange - pageSize, currentPageRange)
          .map((value) => createOption(value)),
      })
    }
  }, [field, currentPageRange])

  const handleEnumChange = (
    value: OnChangeValue<Option, true>,
    _actionMeta: ActionMeta<Option>,
  ) => {
    setEnumValue({ ...enumValue, value: value.map((option) => option) })
    updateMetadata(field.id, {
      pattern: value.map((option) => option.value),
      default_value: field.default_value,
      default_value_list: field.default_value_list,
    })
  }

  const handleEnumInputChange = (inputValue: string) => {
    setEnumValue({ ...enumValue, inputValue: inputValue })
  }

  const handleKeyDownEnumInput: KeyboardEventHandler<HTMLDivElement> = (
    event,
  ) => {
    const { inputValue, value } = enumValue
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (value.length === 0 || !value.find((v) => v.value === inputValue)) {
          const newValue = [...value, createOption(inputValue)]
          setEnumValue({
            inputValue: '',
            value: newValue,
          })
          updateMetadata(field.id, {
            ...metaDataTypes[field.data_type].children.enum,
            pattern: newValue.map((value: Option) => value.value),
          })
          setEnumError('')
        } else {
          setEnumError(t('duplicate_value_not_allowed'))
        }
        event.preventDefault()
    }
  }

  const createOption = (label: string) => ({
    label,
    value: label,
  })

  const components = {
    DropdownIndicator: null,
  }

  const getPattern = (dataType: string, newPattern: string | string[]) => {
    if (
      (field.data_type === 'enum' || field.data_type === 'multi_enum') &&
      (dataType === 'enum' || dataType === 'multi_enum')
    ) {
      return field.pattern
    } else {
      return newPattern
    }
  }

  const getDefaultValueList = (dataType: string) => {
    if (
      field.data_type === 'enum' &&
      dataType === 'multi_enum' &&
      field.default_value
    ) {
      return [field.default_value?.toString()]
    } else {
      return []
    }
  }

  const getDefaultValue = (dataType: string) => {
    if (
      field.data_type === 'multi_enum' &&
      dataType === 'enum' &&
      field.default_value_list
    ) {
      return field.default_value_list.pop()
    } else {
      return null
    }
  }

  return (
    <>
      {onRemove && (
        <div className={`flex justify-end pt-2 `}>
          <RemoveIcon disabled={disabled} onClick={onRemove} />
        </div>
      )}
      <div className={`flex`}>
        <Input
          label={t('field_name')}
          placeholder={''}
          type={'text'}
          block={true}
          value={field.name}
          required={true}
          onChange={(e) =>
            updateMetadata(field.id, { ...field, name: e.target.value })
          }
          disabled={disabled}
        />
        <div className={`mx-2 mt-3 w-1/4`}>
          <label
            className={'text-sm leading-5 mb-1 font-medium text-gray-700 '}
          >
            {t('data_type')} *
          </label>
          <FolderMetadataField
            possibleMetadataTypes={metaDataTypes}
            disabled={disabled}
            onTypeChange={(newMetadataType) => {
              updateMetadata(field.id, {
                ...newMetadataType,
                default_value_list: getDefaultValueList(
                  newMetadataType.data_type,
                ),
                default_value: getDefaultValue(newMetadataType.data_type),
                pattern: getPattern(
                  newMetadataType.data_type,
                  newMetadataType.pattern,
                ),
              })
            }}
            value={field}
            menuOnTheLeft={metaFieldMenuOnTheLeft}
          />
        </div>
      </div>

      <div className={`mx-2 mt-1`}>
        {(field.data_type === 'enum' || field.data_type === 'multi_enum') && (
          <>
            <label
              className={'text-sm leading-5 mb-1 font-medium text-gray-700'}
            >
              {t('enum')}
            </label>
            {field.pattern.length > pageSize && (
              <>
                <div className={'flex gap-2 items-center'}>
                  <Warning className={'fill-yellow text-lg'} />
                  <p className={'text-sm'}>
                    {t('warning_many_options', {
                      start: currentPageRange - pageSize,
                      end:
                        currentPageRange >= field.pattern.length
                          ? field.pattern.length
                          : currentPageRange,
                      total: field.pattern.length,
                    })}
                  </p>
                </div>
                <div className={'flex w-full justify-between'}>
                  <ArrowBack
                    className={twMerge(
                      'hover:fill-blue-root hover:cursor-pointer text-xxl',
                      page === 1 && 'invisible',
                    )}
                    onClick={() => {
                      setPage((prev) => prev - 1)
                    }}
                  />
                  <ArrowForward
                    className={twMerge(
                      'hover:fill-blue-root hover:cursor-pointer text-xxl',
                      currentPageRange >= field.pattern.length && 'invisible',
                    )}
                    onClick={() => {
                      setPage((prev) => prev + 1)
                    }}
                  />
                </div>
              </>
            )}
            {enumError && (
              <p className={'text-red-600 ml-2 text-sm font-normal'}>
                {enumError}
              </p>
            )}
            <CreatableSelect
              components={components}
              inputValue={enumValue.inputValue}
              isClearable
              isMulti
              filterOption={createFilter({ ignoreAccents: false })}
              isDisabled={disabled}
              menuIsOpen={false}
              onChange={handleEnumChange}
              onInputChange={handleEnumInputChange}
              onKeyDown={handleKeyDownEnumInput}
              placeholder={t('enum_place_holder')}
              value={enumValue.value}
              styles={{
                multiValue: (styles: any, {}) => ({
                  ...styles,
                  background: '#DFF0FE',
                }),
              }}
            />
          </>
        )}
      </div>
      <FolderMetadataDefault
        field={field}
        updateMetadata={updateMetadata}
        enumValues={enumValue.value}
      />
    </>
  )
}

export default FolderMetadataRow
