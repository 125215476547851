import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Mention,
  MentionsInput,
  MentionsInputProps,
  SuggestionDataItem,
} from 'react-mentions'
import UserIcon from 'src/components/user/UserIcon'
import { useProjectSingleUsers } from 'src/query/users'
import { capFirstLetter, classNames } from '../../utility/utils'
import defaultStyle from './MentionDefaultstyles'

export interface SuggestedUserItemProps extends SuggestionDataItem {
  firstName: string
  lastName: string
  image_url: string
}
interface IMentionTextArea extends Omit<MentionsInputProps, 'children'> {
  onClick?: () => void
  value?: string
  placeholder?: string
  label?: string
  className?: string
  rows?: number
  noBorder?: boolean
  backgroundColor?: string
}

const MentionTextArea: React.FC<IMentionTextArea> = ({
  label,
  className = '',
  placeholder,
  value = '',
  singleLine = false,
  noBorder = false,
  disabled = false,
  backgroundColor = 'white',
  ...props
}) => {
  const { t } = useTranslation()

  const { data: users, isPending } = useProjectSingleUsers()

  const placeHolder = placeholder
    ? placeholder
    : capFirstLetter(t('write_in')) + ' ' + label
      ? label?.toLowerCase()
      : ''

  const style = useMemo(() => {
    return defaultStyle
  }, [])

  const userData: SuggestedUserItemProps[] =
    users?.map((user) => ({
      id: user.id,
      display: user.firstName + ' ' + user.lastName,
      firstName: user.firstName,
      lastName: user.lastName,
      image_url: user.image_url || '/user_profile.png',
    })) || []

  return (
    <MentionsInput
      value={value}
      placeholder={placeHolder}
      disabled={disabled}
      style={style}
      singleLine={singleLine}
      className={classNames(
        'text-gray-800 text-sm',
        disabled ? 'bg-gray-200' : `bg-${backgroundColor}`,
        !noBorder && 'border border-silver',
        className,
      )}
      allowSuggestionsAboveCursor
      allowSpaceInQuery
      {...props}
    >
      <Mention
        trigger="@"
        data={userData}
        isLoading={isPending}
        className="bg-blue-root-wash font-medium"
        displayTransform={(_id: string, display: string) => `@${display}`}
        renderSuggestion={(suggestion: SuggestedUserItemProps) => (
          <UserIcon
            firstName={suggestion.firstName}
            lastName={suggestion.lastName}
            image_url={suggestion.image_url}
          />
        )}
        appendSpaceOnAdd
      />
    </MentionsInput>
  )
}

export default MentionTextArea
