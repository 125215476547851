import { partition } from 'lodash'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useServiceData from 'src/hooks/useServiceData'
import { IShareToken, IUserData } from 'src/service/OrgTypes'
import { deleteShareToken, getShareTokens } from 'src/service/ShareTokenService'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import Loader from 'src/ui-elements/loader/Loader'
import Modal from 'src/ui-elements/modal/Modal'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import { getFrontendBaseUrl } from 'src/utility/utils'
import DeleteModal from '../delete-modal/DeleteModal'
import { TypographyH5 } from '../typography'
import UserIcon from '../user/UserIcon'
import ShareTokenForm from './ShareTokenForm'

interface IProjectSharingPageProps {
  projectId: number
  projectAdmin: boolean
}

const createLink = (token: IShareToken) => {
  const resource = token.resource === 'project_gantt' ? 'gantt' : ''

  return `${getFrontendBaseUrl()}share/${resource}?shareToken=${
    token.token
  }&projectId=${token.project_id}`
}

const copyToClipboard = (data: string) => {
  navigator.clipboard.writeText(data)
}

const CopyItemCell = ({ text }: { text: string }) => {
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => copied && setCopied(false), 1000)
    return () => clearTimeout(timer)
  }, [copied])

  return (
    <div
      className="w-full overflow-clip group relative"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setCopied(true)
        copyToClipboard(text)
      }}
    >
      <Icon icon={Icons.COPY_TO_CLIPBOARD} className="text-gray-50 inline" />
      <span className="invisible z-10 group-hover:visible absolute left-6 bg-gray-200 px-2 py-0.5 rounded-lg p">
        {copied ? t('copied_to_clipboard') : t('copy')}
      </span>
      <span className="group-hover:opacity-60">{text}</span>
    </div>
  )
}

const ProjectSharingPage: FC<IProjectSharingPageProps> = ({
  projectId,
  projectAdmin: _projectAdmin,
}) => {
  const { t } = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedToken, setSelectedToken] = useState<IShareToken>()

  const { data, loading, reload } = useServiceData(
    () => getShareTokens(projectId),
    [projectId],
  )

  const tokens = data?.map((t) => ({ ...t, data: t })) ?? []

  const [activeTokens, disabledTokens] = partition(
    tokens,
    (token) => token.active && moment(token.expiration_time).isAfter(moment()),
  )

  const tokenName = (token: IShareToken) =>
    `${t('sharing_link')} ${token.resource}`

  const editItem = (token: IShareToken) => {
    setSelectedToken(token)
    setShowEditModal(true)
  }

  const deleteItem = (token: IShareToken) => {
    setSelectedToken(token)
    setShowDeleteModal(true)
  }

  const performDelete = () => {
    selectedToken && deleteShareToken(selectedToken)
    setShowDeleteModal(false)
    reload()
  }

  const resourceName = (resource: string) => {
    switch (resource) {
      case 'project_gantt':
        return t('gantt_chart')
      default:
        return resource
    }
  }

  const columns = (disabled = false): IListColumns[] => [
    {
      name: 'resources',
      size: '200',
      id: 'resource',
      dataField: 'resource',
      cell: (resource: string) => <span>{resourceName(resource)}</span>,
    },
    {
      name: 'comment',
      size: '300',
      id: 'comment',
      dataField: 'comment',
    },
    {
      name: 'expires',
      size: '130',
      id: 'expires',
      dataField: 'expiration_time',
      cell: (expires: string) =>
        disabled ? (
          <Badge color={BadgeColor.RED} text={t('disactivated')} />
        ) : (
          <span>{moment(expires).format('L')}</span>
        ),
    },
    {
      name: 'created_by',
      size: '200',
      id: 'createdBy',
      dataField: 'created_by',
      cell: (user: IUserData) => (
        <UserIcon
          userId={user.id}
          firstName={user.firstName}
          lastName={user.lastName}
          image_url={user.image_url}
        />
      ),
    },
    {
      name: 'created_at',
      size: '130',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: string) => <span>{moment(created).format('L')}</span>,
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: string) => <span>{moment(updated).format('L')}</span>,
    },
    {
      name: 'link',
      size: '200',
      id: 'link',
      dataField: 'data',
      cell: (data: IShareToken) => <CopyItemCell text={createLink(data)} />,
    },
  ]

  return (
    <div className={TabPageStyleClass.root}>
      <List
        actionButton={
          <Button
            onClick={() => setShowCreateModal(true)}
            type={Button.ButtonType.PRIMARY}
          >
            {t('new_shared_link')}
          </Button>
        }
        data={activeTokens}
        columns={columns()}
        tableName={'linkSharingList'}
        itemsPerPage={0}
        onRowClick={editItem}
        actionMenu={[
          {
            name: t('delete'),
            action: deleteItem,
          },
        ]}
      />

      <div className="my-8">
        <TypographyH5>{t('inactive_items', { item: t('links') })}</TypographyH5>
        <List
          disableConfigStore
          disableColumnSelector
          data={disabledTokens}
          columns={columns(true)}
          tableName={'linkSharingListDisabled'}
          itemsPerPage={0}
          onRowDisable={() => true}
        />
      </div>

      <Modal
        show={showEditModal}
        closeModal={() => setShowEditModal(false)}
        title={t('edit_shared_link')}
        maxWidth={600}
      >
        <ShareTokenForm
          editingMode={true}
          shareToken={selectedToken}
          projectId={projectId}
          closeModal={() => {
            setShowEditModal(false)
            reload()
          }}
        />
      </Modal>

      {selectedToken && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={() => {
            reload()
            setShowDeleteModal(false)
          }}
          onDelete={performDelete}
          itemIdnType={t('shared_link')}
          itemName={tokenName(selectedToken)}
        />
      )}

      <Modal
        size={'w-1/3'}
        show={showCreateModal}
        closeModal={() => setShowCreateModal(false)}
        title={t('create_shared_link')}
        maxWidth={600}
      >
        <ShareTokenForm
          editingMode={false}
          projectId={projectId}
          closeModal={() => {
            setShowCreateModal(false)
            reload()
          }}
        />
      </Modal>
      {loading ? <Loader /> : null}
    </div>
  )
}

export default ProjectSharingPage
