import moment from 'moment'
import { filterType } from '../../../ui-elements/list/ListTypes'
import { SystemTypeGroupColumn } from '../../TableColumns/Columns'

export const columns = () => {
  return [
    {
      name: 'type_code',
      id: 'type_code',
      size: '300',
      dataField: 'type_code',
      sortingField: 'type_code',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'name',
      id: 'name',
      size: '300',
      dataField: 'name',
      sortingField: 'name',
      filter: [],
      filterType: filterType.TEXT,
      cell: (name: any) => <span className="truncate">{name}</span>,
    },
    {
      name: 'description',
      id: 'description',
      size: '400',
      dataField: 'description',
      sortingField: 'description',
      filter: [],
      filterType: filterType.TEXT,
      cell: (description: any) => (
        <span className="truncate">{description}</span>
      ),
    },
    SystemTypeGroupColumn(),
    {
      name: 'created_at',
      size: '130',
      id: 'createdAt',
      dataField: 'created_at',
      sortingField: 'created_at',
      filter: [],
      filterType: filterType.RANGEDATE,
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      dataField: 'updated_at',
      sortingField: 'updated_at',
      filter: [],
      filterType: filterType.RANGEDATE,
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]
}
