import { useState, useEffect } from 'react'
import { getMyProjects } from 'src/service/ProjectService'
import NoProjectPage from '../page/dashboard/error/NoProject'

const CheckProjectAcess = (props: any) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [hasProject, setHasProject] = useState<boolean>(false)

  useEffect(() => {
    checkForUserProject()
  }, [props.userId])

  useEffect(() => {
    if (window && window.rnw && window.rnw.unseen_count > 0) {
      window.rnw('show')
    }
  }, [])

  const checkForUserProject = async () => {
    const currentProject = window.localStorage.getItem('currentProject')
    if (currentProject) {
      setHasProject(true)
    } else {
      const projects = await getMyProjects(props.userId)
      if (projects && projects.length > 0) {
        window.localStorage.setItem(
          'currentProject',
          `${projects[0].project.id}`,
        )
        setHasProject(true)
      } else {
        setHasProject(false)
      }
    }
    setLoading(false)
  }

  return <>{loading ? null : hasProject ? props.children : <NoProjectPage />}</>
}

export default CheckProjectAcess
