import ExitToApp from '@icons/exit_to_app.svg'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import { useDetailUrl } from 'src/hooks/useDetailUrl'
import { DetailPageKeys, IDetailPageData } from 'src/utility/DetailPageUtils'
import { classNames } from 'src/utility/utils'
import Icon from '../icon/Icon'
import Tooltip from '../tooltip/Tooltip'

interface IFixedHorizontalPane {
  title: string | JSX.Element
  onClose: () => void
  children: JSX.Element
  closeClickOutside: boolean
  show: boolean
  className?: string
  minHeight?: string
  detailPageData?: IDetailPageData<DetailPageKeys>
}

const FixedHorizintalPane: React.FC<IFixedHorizontalPane> = ({
  title,
  onClose,
  children,
  show,
  className = '',
  minHeight = 'min-h-128',
  closeClickOutside,
  detailPageData,
}) => {
  const styleClass = {
    root: classNames(
      minHeight,
      'fixed',
      'shadow-xsm',
      'bg-white',
      'z-[70]',
      'overflow-hidden',
      'left-[65px] print:left-0',
      'bottom-[1px]',
      show ? 'visible' : 'inivisible',
      'w-[calc(100vw_-_80px)] print:w-[100vw]',
    ),
    header: classNames(
      'flex',
      'items-center',
      'justify-between',
      'pl-3',
      'pr-3',
      'pt-2',
      'pb-1',
      'mb-2',
      'border-b',
      'border-gray-300',
    ),
    title: classNames(
      'text-m',
      'text-black',
      'leading-normal',
      'font-medium',
      'flex',
      'items-center',
      'gap-2',
    ),
    content: twMerge('w-full', 'h-full', 'pb-2', 'overflow-auto', className),
  }

  const onClickOutside = () => {
    if (closeClickOutside) {
      onClose()
    }
  }

  const { t } = useTranslation()
  const detailUrl = useDetailUrl(detailPageData)

  return show ? (
    <CloseClickOutside onClose={onClickOutside}>
      <div className={styleClass.root}>
        <div className={styleClass.header}>
          <p className={styleClass.title}>
            {title}
            {detailUrl && (
              <Tooltip
                direction={'bottom'}
                message={t('go_to_page_view')}
                offset={2.5}
                classNames={'z-[60]'}
              >
                <Link to={detailUrl}>
                  <ExitToApp
                    className={'hover:fill-blue-root fill-gray-600'}
                    fontSize={20}
                  />
                </Link>
              </Tooltip>
            )}
          </p>
          <Icon
            style={{ opacity: 0.5 }}
            icon={Icon.IconType.CLOSE_GRAY}
            onClick={onClose}
            className={'cursor-pointer flex-shrink-0'}
          />
        </div>

        <div className={styleClass.content}>{children}</div>
      </div>
    </CloseClickOutside>
  ) : null
}

export default FixedHorizintalPane
