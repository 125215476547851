import {
  ISystemSyntax,
  ISystemSyntaxElement,
  ISystemSyntaxSpacer,
} from 'src/document/types/ISystemSyntax'
import { ISystemSyntaxHierarchy } from 'src/service/OrgTypes'
import { getRandomId } from 'src/utility/getRandomId'
import { repeatLetter } from 'src/utility/repeatLetter'
import { spacers, syntaxes } from '../SyntaxElements'

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

class SyntaxObjectConverter {
  private level = 1
  private currentVisualSymbolIndex = 0
  readonly convertObjectToSyntax = (
    systemSyntaxCategories?: ISystemSyntaxHierarchy[],
  ) => {
    const res: ISystemSyntax[] = []
    this.level = 1

    const parsedSyntaxItem = this.parseSyntaxObjectItem(systemSyntaxCategories)
    if (parsedSyntaxItem) {
      parsedSyntaxItem.map((each) => res.push(each))
    }

    return res
  }

  readonly getSyntaxCode = (
    systemSyntaxCategories?: ISystemSyntaxHierarchy[],
  ): string => {
    const syntaxes = this.convertObjectToSyntax(systemSyntaxCategories)
    return this.syntaxesToString(this.initializeNewSyntax(syntaxes))
  }

  private readonly syntaxesToString = (syntaxes: ISystemSyntax[]) => {
    const ret: string[] = []
    syntaxes.map((each) => {
      if (each.type === 'spacer') {
        ret.push(each.visualSymbol ?? '')
      } else {
        ret.push(repeatLetter(each.numberLength ?? 1, each.visualSymbol ?? ''))
      }
    })
    return ret.join('')
  }

  private readonly parseSyntaxObjectItem = (
    systemSyntaxCategories?: ISystemSyntaxHierarchy[],
  ) => {
    const res: ISystemSyntax[] = []

    systemSyntaxCategories?.map((each) => {
      const spacer = this.parseSpacer(each.spacer)
      if (spacer) res.push(spacer)
      const syntax = this.parseSyntaxElement(
        each.variant,
        each.category_count ?? 1,
        each.name,
        systemSyntaxCategories,
      )
      if (syntax) {
        res.push(syntax)
      }
      if (each.mid_spacer) {
        const midSpacer = this.parseSpacer(each.mid_spacer)
        if (midSpacer) res.push(midSpacer)
      }
      if (each.number_of_digits) {
        const id = this.parseSyntaxElement(
          'id',
          each.number_of_digits ?? 1,
          'id',
          systemSyntaxCategories,
        )
        if (id) {
          res.push(id)
        }
      }
    })
    return res
  }

  private readonly initializeNewSyntax = (
    syntax: ISystemSyntax[],
  ): ISystemSyntax[] => {
    this.currentVisualSymbolIndex = 0
    const tmpMap = new Map<string, ISystemSyntax>([])
    for (const syntaxItem of syntax) {
      this.setNextVisualSymol(syntaxItem)
      tmpMap.set(syntaxItem.id, syntaxItem)
    }
    return Array.from(tmpMap.values())
  }

  private setNextVisualSymol = (item: ISystemSyntax) => {
    if (item.type === 'syntax') {
      if (item.variant === 'id') {
        item.visualSymbol = 'n'
      } else {
        item.visualSymbol = letters[this.currentVisualSymbolIndex]
        this.currentVisualSymbolIndex++
      }
    }
  }

  private readonly parseSpacer = (visualSymbol: string) => {
    const staticSpacer = spacers.find(
      (spacer) => spacer.visualSymbol === visualSymbol,
    )
    if (!staticSpacer) return
    const res: ISystemSyntaxSpacer = {
      ...staticSpacer,
      id: getRandomId(),
    }
    return res
  }

  private readonly parseSyntaxElement = (
    variant: string,
    numberLength: number,
    name: string,
    systemSyntaxCategories?: ISystemSyntaxHierarchy[],
  ) => {
    const staticSyntaxElement = syntaxes.find(
      (syntax) => syntax.variant === variant,
    )
    if (!staticSyntaxElement) return
    const id = getRandomId()
    const res: ISystemSyntaxElement = {
      ...staticSyntaxElement,
      numberLength,
      id,
      name: name,
      readableNameKey: name ?? variant,
    }
    if (staticSyntaxElement.variant === 'category') {
      this.findAndUpdateSystemSyntax(id, systemSyntaxCategories)
    }

    return res
  }

  private readonly findAndUpdateSystemSyntax = (
    id: string,
    systemSyntaxCategories?: ISystemSyntaxHierarchy[],
  ) => {
    const syntax = systemSyntaxCategories?.find(
      (systemSyntax) => systemSyntax.level === this.level,
    )
    if (syntax) {
      syntax.localId = id
      this.level++
    }
  }
}

export default new SyntaxObjectConverter()
