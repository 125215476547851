import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import { useConstructionWagon } from 'src/query/construction/construction'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import RadioItems, { IRadioItems } from 'src/ui-elements/radio/RadioItems'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import { classNames } from '../../utility/utils'

export interface IConstructionScopeModalProps {
  show: boolean
  closeModal: () => void
  onSubmit: (scope: IConstructionUpdateScope) => void
  movedWagonId: number
}

export interface IConstructionUpdateScope {
  vertical_move: boolean
  single_move: boolean
  change_only_future_locomotives_in_group: boolean
  ignore_weekends: boolean
  ignore_holidays: boolean
}

const styleClass = {
  inputGroup: classNames('flex', 'flex-row', 'w-full'),
  row: classNames('min-w-[385px]', 'max-w-[385px]'),
}

type IMovementTypes =
  | 'single_move'
  | 'diagonal_with_control_area'
  | 'diagonal_with_control_area_group'
  | 'vertical'

const ConstructionScopeModal = ({
  show,
  closeModal,
  onSubmit,
  movedWagonId,
}: IConstructionScopeModalProps) => {
  const { t } = useTranslation()
  const [selectedMovement, setSelectedMovement] =
    useState<IMovementTypes>('single_move')
  const [showConfirm, setShowConfirm] = useState(false)
  const [ignoreWeekends, setIgnoreWeekends] = useState(false)
  const [ignoreHolidays, setIgnoreHolidays] = useState(false)
  const [loading, setLoading] = useState(false)
  const { data: movedWagon } = useConstructionWagon(movedWagonId)

  useEffect(() => {
    setIgnoreHolidays(!!movedWagon?.ignore_holidays)
    setIgnoreWeekends(!!movedWagon?.ignore_weekends)
  }, [movedWagon])

  const movementType: IRadioItems[] = [
    {
      id: 'single_move',
      name: (
        <div className={'leading-normal mt-1'}>{t('move_only_this_wagon')}</div>
      ),
      classNames: 'radio_in_modal',
    },
    {
      id: 'diagonal_with_control_area',
      name: (
        <div className={'leading-normal'}>
          {t('move_all_future_wagons_for_this_train_in_this_control_area')}
        </div>
      ),
      classNames: 'radio_in_modal',
    },
    {
      id: 'diagonal_with_control_area_group',
      name: (
        <div className={'leading-normal'}>
          {t(
            'move_all_future_wagons_in_this_train_in_the_for_future_control_areas_in_this_group',
          )}
        </div>
      ),
      classNames: 'radio_in_modal',
    },
    {
      id: 'vertical',
      name: (
        <div className={'leading-normal'}>
          {t(
            'move_with_all_future_wagons_in_the_same_train_and_apply_to_the_wagons_on_the_same_day',
          )}
        </div>
      ),
      classNames: 'radio_in_modal',
    },
  ]

  useEffect(() => {
    setSelectedMovement('single_move')
  }, [show])

  const onItemSet = (e: any) => {
    setSelectedMovement(e.target.value)
  }

  const onSubmitClicked = (confirmed: boolean = false) => {
    const scope: IConstructionUpdateScope = {
      vertical_move: selectedMovement === 'vertical',
      single_move: selectedMovement === 'single_move',
      change_only_future_locomotives_in_group:
        selectedMovement === 'diagonal_with_control_area_group',
      ignore_weekends: ignoreWeekends,
      ignore_holidays: ignoreHolidays,
    }
    if (ignoreHolidays && !confirmed) {
      setShowConfirm(true)
      return
    }
    setLoading(true)
    onSubmit(scope)
  }

  useEffect(() => {
    setLoading(false)
  }, [show])

  const imageLinks = [
    { id: 'single_move', val: '/move_single.png' },
    { id: 'diagonal_with_control_area', val: '/move_controlarea.png' },
    {
      id: 'diagonal_with_control_area_group',
      val: '/move_controlareagroup.png',
    },
    { id: 'vertical', val: '/move_vertical.png' },
  ]

  const selectedImage = useMemo(
    () => imageLinks.find((item) => item.id === selectedMovement),
    [selectedMovement],
  )

  return (
    <>
      <Modal
        show={show}
        closeModal={closeModal}
        title={t('choose_how_you_want_to_move_wagons')}
        maxWidth={1200}
      >
        <div className={'mt-2 mb-8 flex flex-row gap-4 items-center'}>
          <div className={styleClass.row}>
            <RadioItems
              items={movementType}
              onClick={onItemSet}
              value={selectedMovement}
              className={'flex flex-col gap-3'}
            />
            <div className="mt-10 flex items-center text-gray-600">
              <SwitchHOC
                controlValueInParent
                valueProp={ignoreWeekends}
                onChange={() => setIgnoreWeekends(!ignoreWeekends)}
                id="ignore_weekends"
              />
              <label
                className="first-capitalize ml-3 text-sm"
                htmlFor="ignore_weekends"
              >
                {t('ignore_weekends')}
              </label>
            </div>

            <div className="mt-2 flex items-center text-sm  text-gray-600">
              <SwitchHOC
                controlValueInParent
                valueProp={ignoreHolidays}
                onChange={() => setIgnoreHolidays(!ignoreHolidays)}
                id="ignore_holidays"
              />
              <label
                className="first-capitalize ml-3 text-sm"
                htmlFor="ignore_holidays"
              >
                {`${t('consider_holidays_as_working_periods')}?`}
              </label>
            </div>
          </div>
          {selectedImage && (
            <img src={selectedImage.val} className={'h-[250px] w-auto'} />
          )}
        </div>
        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            loading={loading}
            disabled={loading}
            onClick={() => onSubmitClicked()}
          >
            {t('update')}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        show={showConfirm}
        title={t('confirm_move_in_holiday')}
        closeModal={() => setShowConfirm(false)}
        maxWidth={500}
      >
        <p className="mt-3 text-sm ml-2">
          {t('are_you_sure_you_want_to_regard_holidays_as_work_periods?')}
        </p>
        <ModalFooter>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={() => setShowConfirm(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            loading={loading}
            disabled={loading}
            onClick={() => onSubmitClicked(true)}
          >
            {t('update')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ConstructionScopeModal
