import { FC } from 'react'
import FileUploader, { IFileUploadModalProps } from './FileUploader'
import UploadProvider from './context/FileUploadContext'

const FileUploadModal: FC<IFileUploadModalProps> = (props) => {
  return (
    <UploadProvider>
      <FileUploader {...props} />
    </UploadProvider>
  )
}

export default FileUploadModal
