import * as React from 'react'
import { Subtract } from 'utility-types'
import { IUserContext, UserContext } from './UserContextProvider/UserContext'

export const withUserContext = <P extends IUserContext>(
  Component: React.ComponentType<P>,
) =>
  class WithContext extends React.PureComponent<Subtract<P, IUserContext>> {
    public render() {
      return (
        <UserContext.Consumer>
          {(userContext: IUserContext) =>
            userContext.state.user?.id && (
              <Component
                {...(this.props as P)}
                actions={userContext.actions}
                state={userContext.state}
              />
            )
          }
        </UserContext.Consumer>
      )
    }
  }
