import { classNames } from 'src/utility/utils'

interface ICard {
  children: JSX.Element
  classname?: string
}
const Card = ({ children, classname = '' }: ICard) => {
  const styleClass = {
    root: classNames('bg-white', 'shadow-xsm', 'p-4', classname),
  }

  return <div className={styleClass.root}>{children}</div>
}

export default Card
