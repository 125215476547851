import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import RiskMatrixComponent from '../../components/risk/RiskMatrixComponent'
import { IMatrixCellConfig, IMatrixData } from '../../service/OrgTypes'
import {
  deleteRiskMatrix,
  editRiskMatrixCell,
  getRiskMatrix,
} from '../../service/RiskService'
import Button from '../../ui-elements/button/Button'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import DeleteModal from '../delete-modal/DeleteModal'
import RiskMatrixForm from './RiskMatrixForm'

interface IRiskMatrixConfigListProps {
  projectId: number
  showDeleteModal?: boolean
  toggleDelModal: () => void
  hideInstruction?: boolean
}

const RiskMatrix = ({
  projectId,
  showDeleteModal,
  toggleDelModal,
  hideInstruction,
}: IRiskMatrixConfigListProps) => {
  const [riskMatrixData, setRiskMatrixData] = useState<IMatrixData>({
    rowSize: 0,
    columnSize: 0,
    matrixThreatConfigData: [],
    matrixPossibilityConfigData: [],
  })
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { t } = useTranslation()

  useEffect(() => {
    reload()
  }, [projectId])

  const reload = () => {
    getRiskMatrix(projectId)
      .then((res: IMatrixData) => {
        setRiskMatrixData(res)
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  const closeDeleteModal = () => {
    if (toggleDelModal) {
      toggleDelModal()
    }
  }

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const closeCreateModal = () => {
    setShowCreateModal(false)
    reload()
  }

  const deleteRiskMatrixValur = () => {
    deleteRiskMatrix(projectId)
      .then(() => {
        reload()
      })
      .catch((err) => console.error(err))

    if (toggleDelModal) {
      toggleDelModal()
    }
  }

  const updateMatrixCell = (matrixCell: IMatrixCellConfig) => {
    editRiskMatrixCell(matrixCell)
      .then(() => {
        reload()
      })
      .catch((err) => console.error(err))
  }

  const riskMatrixExists =
    riskMatrixData.rowSize !== 0 && riskMatrixData.columnSize !== 0

  return (
    <div className={TabPageStyleClass.root}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {!riskMatrixExists ? (
            <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
              {t('create_risk_matrix')}
            </Button>
          ) : (
            <Button onClick={toggleDelModal} type={Button.ButtonType.DANGER}>
              {t('delete_risk_matrix')}
            </Button>
          )}
          {riskMatrixExists && (
            <div>
              {!hideInstruction && (
                <div
                  style={{ paddingLeft: 2 }}
                  className={'text-gray-500 mt-2 text-sm'}
                >
                  {t('touch_a_cell_to_change_color')}
                </div>
              )}
              <div className={'flex'}>
                <div className="relative pr-6 w-mx-content">
                  <RiskMatrixComponent
                    title={t('threat')}
                    matrixType="threat"
                    columnsProp={riskMatrixData.columnSize}
                    rowsProp={riskMatrixData.rowSize}
                    configArray={riskMatrixData.matrixThreatConfigData}
                    editable={true}
                    updateModal={updateMatrixCell}
                  />
                </div>
                <div className="relative pr-6 w-mx-content">
                  <RiskMatrixComponent
                    title={t('possibility')}
                    matrixType="possibility"
                    columnsProp={riskMatrixData.columnSize}
                    rowsProp={riskMatrixData.rowSize}
                    configArray={riskMatrixData.matrixPossibilityConfigData}
                    editable={true}
                    updateModal={updateMatrixCell}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <DeleteModal
        show={showDeleteModal ? showDeleteModal : false}
        closeModal={closeDeleteModal}
        onDelete={deleteRiskMatrixValur}
        itemIdnType={t('risk_matrix')}
        itemName={t('risk_matrix')}
      >
        <div className="text-sm p-2">
          {t('all_risk_matrix_and_all_risk_assessments_will_be_deleted')}
        </div>
      </DeleteModal>

      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_risk_matrix')}
        maxWidth={600}
      >
        <RiskMatrixForm projectId={projectId} closeModal={closeCreateModal} />
      </Modal>
    </div>
  )
}

export default RiskMatrix
