import { IEnvironmentConfig } from "./IEnvironmentConfig";

export const impleniaConfig: IEnvironmentConfig = {
  host: "implenia.bygg.io",
  exportBaseUrl: "https://export-implenia.bygg.io/",
  frontendBaseUrl: "https://implenia.bygg.io/",
  organizationBaseUrl: "https://org-implenia.bygg.io/",
  proxyBaseUrl: "https://proxy-implenia.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-implenia.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-implenia.bygg.io/",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "https://kpi-implenia.bygg.io/"
}
