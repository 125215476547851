import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import {
  getMetaDataValues,
  loadMetaValues,
} from 'src/components/system/SystemUtil'
import TestWorkGroupFields from 'src/components/system/test-work-groups/TestWorkGroupFields'
import TestWorkGroupStatusFields from 'src/components/system/test-work-groups/TestWorkGroupStatusFields'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections from 'src/document/components/Inspector/InspectorSections'
import { IMetaValue } from 'src/document/types/IMetaData'
import {
  ISystemTypeGroup,
  ITestSystemGroup,
  ITestWorkGroup,
} from 'src/service/OrgTypes'
import { getProjectSystemTypeGroupsPerDomain } from 'src/service/SystemTypeGroupService'
import {
  getSingleTestWorkGroup,
  getTestWorkGroupSystemGroup,
} from 'src/service/TestWorkGroupService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import { Icons } from 'src/ui-elements/icon/Icon'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { modalUrlBuilder } from 'src/utility/utils'

interface ITestWorkGroupInspectorPanel {
  projectId: number
  testWorkGroupId: number
  open: boolean
  onClose: () => void
  onUpdate: () => void
  defaultIndex?: number
}

const TestWorkGroupInspectorPanel: React.FC<ITestWorkGroupInspectorPanel> = ({
  projectId,
  testWorkGroupId,
  open,
  onClose,
  onUpdate,
  defaultIndex,
}) => {
  const { t } = useMultipleKeysTranslation()
  const userContext = useContext(UserContext)
  const writeAccess = userContext.actions.hasWriteAccess('object')

  const [testWorkGroup, setTestWorkGroup] = useState<ITestWorkGroup>()
  const [testSystemGroups, setTestSystemGroups] = useState<ITestSystemGroup[]>(
    [],
  )
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)

  const history = useHistory()
  const modalContext = useContext(ModalContext)

  const loadTestWorkGroup = useCallback(
    (value?: ISystemTypeGroup) => {
      getSingleTestWorkGroup(testWorkGroupId).then((res: ITestWorkGroup) => {
        setTestWorkGroup(res)
        if (value || systemTypeGroup) {
          const metaData = getMetaDataValues(res.meta_data)
          setOptionalFields(
            loadMetaValues(
              testWorkGroupId,
              'TestWorkGroup',
              value?.optional_fields ?? systemTypeGroup?.optional_fields,
              metaData,
            ),
          )
        }
      })
    },
    [systemTypeGroup, testWorkGroupId],
  )

  const getTestSystemList = useCallback(() => {
    getProjectSystemTypeGroupsPerDomain(projectId, 'TestWorkGroup').then(
      (resp: ISystemTypeGroup[]) => {
        const value = resp.pop()
        setSystemTypeGroup(value)
        loadTestWorkGroup(value)
      },
    )
  }, [loadTestWorkGroup, projectId])
  useEffect(() => {}, [getTestSystemList, projectId])

  const loadTestSystemGroups = () => {
    getTestWorkGroupSystemGroup(testWorkGroupId).then((res) => {
      setTestSystemGroups(res)
    })
  }

  useEffect(() => {
    loadTestWorkGroup()
  }, [projectId, testWorkGroupId])

  const onTestWorkGroupUpdate = () => {
    loadTestWorkGroup()
    onUpdate()
  }

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4 -mt-2'}>
      {optionalFields.length > 0 && (
        <MetaDataFieldsForPage
          onFieldsUpdate={onTestWorkGroupUpdate}
          fields={optionalFields}
          required={false}
          editMode={false}
          inspectorPanel={true}
          disabled={!writeAccess}
        />
      )}
    </div>
  )

  const onTestSystemGroupClick = (id: number) => {
    const modalURL = modalUrlBuilder(projectId, 'testsystemgroup', id)
    history.push(modalURL)
    modalContext.actions.checkURL()
  }
  const getRows = (): Array<{
    name: string
    icon: string | JSX.Element
    activeIcon?: string | JSX.Element
    content: JSX.Element
    onClick?: () => void
  }> => [
    {
      name: t('details'),
      icon: Icons.SYSTEM_GRAY,
      activeIcon: Icons.SYSTEM_BLACK,
      content: (
        <InlineComponentsWrapper padding="left">
          <TestWorkGroupFields
            setTestWorkGroup={setTestWorkGroup}
            onUpdate={onTestWorkGroupUpdate}
            testWorkGroup={testWorkGroup}
            inspectorPanel
          />
          <TestWorkGroupStatusFields
            setTestWorkGroup={setTestWorkGroup}
            onUpdate={onTestWorkGroupUpdate}
            testWorkGroup={testWorkGroup}
            inspectorPanel
          />
        </InlineComponentsWrapper>
      ),
      onClick: loadTestWorkGroup,
    },
    {
      name: t('metadata'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      content: getMetaDataContent(),
      onClick: loadTestWorkGroup,
    },
    {
      name: t('test_system_groups'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      onClick: loadTestSystemGroups,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('test_object_group_id'),
            t('title'),
            t('completed_percent'),
            t('status'),
          ]}
          rowsData={testSystemGroups?.map((testSystemGroup) => {
            return {
              cells: [
                testSystemGroup?.record_id,
                testSystemGroup?.title,
                testSystemGroup?.percent_completed,
                testSystemGroup?.test_system_group_status?.name,
              ],
              id: testSystemGroup.id,
            }
          })}
          handleClick={onTestSystemGroupClick}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: (
        <Comments parentId={testWorkGroupId} parentType="TestWorkGroup" />
      ),
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: (
        <ChangeLog parentId={testWorkGroupId} parentType="TestWorkGroup" />
      ),
    },
  ]

  return (
    <FixedPane
      title={
        !testWorkGroup?.record_id
          ? t('loading...')
          : `${testWorkGroup?.record_id + ' - ' + testWorkGroup?.title}`
      }
      show={open}
      onClose={() => onClose()}
      className={'w-[700px]'}
      noMainContent={true}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.TEST_WORK_GROUP,
        ids: {
          testWorkGroupId: testWorkGroupId,
        },
      }}
    >
      <div className={'flex flex-col min-h-full'}>
        <InspectorSections
          defaultIndex={defaultIndex ?? 0}
          sections={getRows()}
        />
      </div>
    </FixedPane>
  )
}
export default TestWorkGroupInspectorPanel
