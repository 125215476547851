import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Selector from 'src/components/selectors/Selector'

interface ILanguageSelector {
  onLanguageSelected?: (language: string) => void
  inTopMenu?: boolean
}

const languages = {
  no: { name: 'no' },
  en: { name: 'en' },
} as const

const LanguageSelector: React.FC<ILanguageSelector> = ({
  onLanguageSelected,
  inTopMenu = false,
}) => {
  const { i18n } = useTranslation()

  const changeLanguage = (val: string) => {
    i18n.changeLanguage(val)
    onLanguageSelected && onLanguageSelected(val)
  }

  return (
    <div className={'md:px-2 py-1 ml-2 w-22'}>
      <Selector
        inTopMenu={inTopMenu}
        hidelabel
        label={''}
        renderItem={(item: string) => (
          <p className={'text-blue-root '}>{item.toUpperCase()}</p>
        )}
        items={Object.values(languages).map((v) => ({ ...v, id: v.name }))}
        selectedItemId={i18n.language}
        onSelect={changeLanguage}
        dataFields={['name']}
        scroll
        disabled={false}
        hasBorder={false}
      />
    </div>
  )
}

export default LanguageSelector
