import { IActiveFilter } from '../utility/utils'
import { ITestSystemGroup, ITestWorkGroup, IPaginateResult } from './OrgTypes'
import { request } from './service-utils'

export interface IFilter {
  planned_end: moment.Moment[]
  discipline: number[]
}

export interface IStatusCount {
  not_set: number
  not_relevant: number
  in_progress: number
  error: number
  done: number
  open: number
}
export interface IPlannedTestWorkGroupsSummary {
  [year: string]: {
    [month: string]: {
      details: []
      status_count: IStatusCount
    }
  }
}

export const createTestWorkGroup = (
  testWorkGroup: ITestWorkGroup,
  projectId: number,
): Promise<ITestWorkGroup> => {
  return request(
    `projects/${projectId}/test_work_groups`,
    'POST',
    testWorkGroup,
  )
}

export const editTestWorkGroup = (
  testWorkGroup: NullablePartial<ITestWorkGroup>,
): Promise<ITestWorkGroup> => {
  return request(`test_work_groups/${testWorkGroup.id}`, 'PUT', testWorkGroup)
}

export const getProjectTestWorkGroup = (
  projectId: number,
  page: number,
  filter: IActiveFilter,
  itemsPerPage: number,
): Promise<IPaginateResult<ITestWorkGroup>> => {
  return request(
    `projects/${projectId}/test_work_groups/${page}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const plannedTestWorkGroupsSummary = (
  projectId: number,
  filter: IFilter,
): Promise<IPlannedTestWorkGroupsSummary[]> => {
  return request(
    `projects/${projectId}/planned_test_work_groups_summary`,
    'POST',
    filter,
  )
}

export const massTestWorkGroup = (
  projectId: number,
  testWorkGroupIds: number[],
  testWorkGroup: Partial<ITestWorkGroup>,
): Promise<any> => {
  return request(`projects/${projectId}/test_work_groups/mass_update`, 'POST', {
    ...testWorkGroup,
    test_work_groups_ids: testWorkGroupIds,
  })
}

export const getProjectTestWorkGroupOnly = (
  projectId: number,
): Promise<ITestWorkGroup[]> => {
  return request(`projects/${projectId}/test_work_groups_only`, 'GET')
}

export const getTestWorkGroupSystemGroup = (
  id: number,
): Promise<ITestSystemGroup[]> => {
  return request(`test_work_groups/${id}/test_system_groups`, 'GET')
}

export const getSingleTestWorkGroup = (id: number): Promise<ITestWorkGroup> => {
  return request(`test_work_groups/${id}`, 'GET')
}

export const deleteTestWorkGroup = (testWorkGroupId: number) => {
  return request(`/test_work_groups/${testWorkGroupId}`, 'DELETE', {}, true)
}

export const getTestWorkGroupsTree = (projectId: number, reload?: boolean) => {
  return request(`projects/${projectId}/test_work_groups/tree`, 'POST', {
    reload: reload,
  })
}

export const testWorkGroupValidateFromJson = (
  projectID: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectID}/test_work_groups/validate_for_import_job`,
    'POST',
    data,
  )
}

export const testWorkGroupBulkDelete = (
  projectId: number,
  ids: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/test_work_groups/remove_multiple`,
    'POST',
    {
      ids,
    },
  )
}
