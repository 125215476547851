const encodeUrlParams = (url: string): string => {
  const splitURL = new URL(url)
  const encodedUrl = `${splitURL.origin}${splitURL.pathname}${splitURL.search
    .split('&')
    .map((param) => param.split('='))
    .map(([key, ...params]) =>
      params.length ? `${key}=${params.join('=').replace('+', '%2B')}` : key,
    )
    .join('&')}`
  return encodedUrl
}

export default encodeUrlParams
