import { FC, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'

interface DocUploadSummeryItemProps {
  documentImportCount: number
  newDocuments: boolean
}

const DocumentUploadSummeryItem: FC<
  PropsWithChildren<DocUploadSummeryItemProps>
> = ({ children, documentImportCount, newDocuments }) => {
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <div className="w-full">
      <div
        className={
          'flex uppercase flex-col rounded-tr rounded-tl border-x border-t border-d-grey-lightest bg-[#F4F6F7] ' +
          (!show && 'border-b rounded')
        }
      >
        <div className="flex px-4  justify-between w-full h-14 items-center">
          <span className="text-d-fontchip font-semibold text-base">
            {newDocuments ? t('new_documents') : t('updated_documents')}
          </span>
          <div className="flex items-center">
            <span className="mx-2 px-2.5 py-1.5 aspect-square flex justify-center items-center rounded-xl text-center text-sm font-normal bg-d-grey-lightest">
              {documentImportCount}
            </span>
            <MaterialIcon
              onClick={() => {
                if (documentImportCount > 0) {
                  setShow(!show)
                }
              }}
              icon={!show ? 'expand_more' : 'expand_less'}
              key={show ? '1' : '2'}
              className="text-d-fontchip pl-8 text-lg cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className={!show ? 'hidden' : 'py-4'}>{children}</div>
      {!show && <div className="w-[1140px] mb-4" />}
    </div>
  )
}

export default DocumentUploadSummeryItem
