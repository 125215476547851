import 'filepond/dist/filepond.min.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { capFirstLetter } from 'src/utility/utils'
import { IDocument } from '../../service/OrgTypes'
import Icon from '../../ui-elements/icon/Icon'

interface IUploadFileListProps {
  documents: IDocument[]
  updateDocuments: (documents: IDocument[]) => void
}

const UploadFileList = ({
  documents,
  updateDocuments,
}: IUploadFileListProps) => {
  const [uploadedDocuments, setUploadedDocuments] = useState<IDocument[]>([])
  const { t } = useTranslation()
  useEffect(() => {
    setUploadedDocuments(documents)
  }, [documents])

  const removeDocument = (fileUrl: string) => {
    const remainingDocuments = uploadedDocuments.filter(
      (item) => item.fileUrl !== fileUrl,
    )

    setUploadedDocuments(remainingDocuments)
    updateDocuments(remainingDocuments)
  }

  return (
    <div className={'ml-2 mb-4'}>
      <label className="block font-medium text-sm leading-5 text-gray-700 my-2">
        {capFirstLetter(t('attachment'))}
      </label>
      {uploadedDocuments.map((item: IDocument, key) => (
        <div key={`upload-list-${key}`} className={'flex items-center mb-2'}>
          <div>
            <Icon icon={Icon.IconType.FILE} className={'w-4 h-4 mb-3 mr-4'} />
          </div>
          <div
            className={
              'leading-5 text-gray-500 items-center font-normal text-sm mr-6'
            }
          >
            {item.fileName}
          </div>
          <div>
            <Icon
              icon={Icon.IconType.DELETE_RED}
              className={'w-4 h-4 mb-3'}
              onClick={() => removeDocument(item.fileUrl)}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
export default UploadFileList
