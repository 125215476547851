import { useRef } from 'react'

/**
 * This hook is used to handle dependecy updates. When we update an attribute of object T,
 * and only want it to be sent to the API after a different field has been updated, this hook will store all the pending updates in the useRef.
 * When the key that the pending updates are waiting for are being updated, the getChangesForUpdate will apply all these changes.
 *
 * @param setObjectToUpdate setState function for T
 * @param objectToUpdate object to update T
 * @returns addChangesToAppendWhenKeyUpdates is a method used to tell what updates that should be applied when a key in object T changes.
 * getChangesForUpdate is a method that will apply all pending updates to the object T when changed.
 */

export const useInlineDependencyUpdate = <T>(
  setObjectToUpdate: (object: T) => void,
  objectToUpdate?: T,
) => {
  const allChangesToAppend = useRef<{
    [key: string]: Partial<T>
  }>({})

  const addChangesToAppendWhenKeyUpdates = (
    key: keyof T,
    changesToAppend: Partial<T>,
  ) => {
    const currentChanges = allChangesToAppend.current[key as string]
    const tmpChanges = { ...currentChanges, ...changesToAppend }
    allChangesToAppend.current[key as string] = tmpChanges
    const mergedChanges = mergePendingUpdates(changesToAppend, false)
    setObjectToUpdate({ ...objectToUpdate, ...(mergedChanges as T) })
  }

  const mergePendingUpdates = (
    update: Partial<T>,
    deleteAllUpdatesForKey: boolean,
  ) => {
    let pendingUpdates: Partial<T> = {}

    for (const key of Object.keys(update)) {
      const changesForKey = getChangesForKey(key, deleteAllUpdatesForKey)
      pendingUpdates = { ...pendingUpdates, ...changesForKey }
    }
    return { ...update, ...pendingUpdates }
  }

  const getChangesForUpdate = (update: Partial<T>) => {
    const res = mergePendingUpdates(update, true)
    return res
  }

  const deleteChangesForKey = (key: string) => {
    delete allChangesToAppend.current[key]
  }

  const getChangesForKey = (key: string, deleteAllUpdatesForKey: boolean) => {
    const changes = { ...allChangesToAppend.current[key] }
    if (deleteAllUpdatesForKey) deleteChangesForKey(key)
    return changes
  }

  return {
    addChangesToAppendWhenKeyUpdates,
    getChangesForUpdate,
  }
}
