import { IComment } from './OrgTypes'
import { request } from './service-utils'

export const createComment = (
  projectId: number,
  commentData: Partial<IComment>,
): Promise<IComment> => {
  return request(`projects/${projectId}/comments`, 'POST', commentData)
}

export const updateComment = (
  commentData: IComment,
  commentId: number,
): Promise<IComment> => {
  return request(`comments/${commentId}`, 'PUT', commentData)
}

export const deleteComment = (commentId: number): Promise<void> => {
  return request(`comments/${commentId}`, 'DELETE', {}, true)
}

export const getCommentsForType = (
  parentType: string,
  parentId: number,
): Promise<IComment[]> =>
  request(`parents/${parentType}/${parentId}/comments`, 'GET')
