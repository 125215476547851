import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  bulkDeleteConstructionTaskTypes,
  createConstructionTaskType,
  deleteConstructionTaskType,
  editConstructionTaskType,
  getProjectConstructionTaskTypes,
} from 'src/service/ConstructionTaskTypeService'
import {
  IConstructionTaskType,
  ITrainType,
  IWagonType,
} from 'src/service/OrgTypes'
import {
  editTrainType,
  getaSingleTrainType,
  trainTypeWagonWorkforce,
} from 'src/service/TrainTypeService'
import {
  bulkDeleteWagonTypes,
  createWagonType,
  deleteWagonType,
  editWagonType,
  getProjectWagonTypes,
  getWagonType,
  swapOrderTrainTypeWagons,
} from 'src/service/WagonTypeService'
import { getFilteredDrafts } from 'src/service/draftService'

export const useFilteredDrafts = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof getFilteredDrafts>[1]['sort']
  filters: Omit<Parameters<typeof getFilteredDrafts>[1], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'filteredDrafts',
      { projectId: projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getFilteredDrafts(projectId, { ...filters, sort }, page, pageSize),
    placeholderData: keepPreviousData,
    enabled,
  })
}

export const useTrainType = (trainTypeId: number) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['trainType', { projectId: projectId, trainTypeId: trainTypeId }],
    queryFn: () => getaSingleTrainType(trainTypeId),
  })
}

export const useEditTrainType = () => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()

  return useMutation({
    mutationFn: (
      trainType: NullablePartial<ITrainType & { execution_order: number }>,
    ) => editTrainType(trainType),
    onSettled: (data: ITrainType) => {
      queryClient.invalidateQueries({
        queryKey: ['trainType', { trainTypeId: data.id, projectId }],
      })
      queryClient.invalidateQueries({
        queryKey: ['train_types'],
      })
      queryClient.invalidateQueries({
        queryKey: ['wagonTypes'],
      })
    },
  })
}

export const useSwapTrainTypeWagons = () => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()

  return useMutation({
    mutationFn: ({
      wagonTypeId,
      executionOrder,
    }: {
      wagonTypeId: number
      executionOrder: number
    }) => swapOrderTrainTypeWagons(wagonTypeId, executionOrder),
    onSettled: (data: IWagonType[]) => {
      queryClient.invalidateQueries({
        queryKey: [
          'trainType',
          { trainTypeId: data[0].train_type_id, projectId },
        ],
      })
      queryClient.invalidateQueries({
        queryKey: ['train_types'],
      })
    },
  })
}

export const useWagonType = (wagonTypeId: number) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['wagonType', { projectId, wagonTypeId: wagonTypeId }],
    queryFn: () => getWagonType(wagonTypeId),
  })
}

export const useCreateWagonType = () => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()

  return useMutation({
    mutationFn: (wagonType: IWagonType) =>
      createWagonType(wagonType, projectId),
    onSettled: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['wagonTypes'],
      })
      if (data?.train_type_id) {
        queryClient.invalidateQueries({
          queryKey: ['train_types'],
        })
        queryClient.invalidateQueries({
          queryKey: ['trainType'],
        })
      }
    },
  })
}

export const useEditWagonType = () => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()

  return useMutation({
    mutationFn: (wagonType: NullablePartial<IWagonType>) =>
      editWagonType(wagonType),
    onSettled: (data: IWagonType) => {
      queryClient.invalidateQueries({
        queryKey: ['wagonType', { wagonTypeId: data.id, projectId }],
      })
      queryClient.invalidateQueries({
        queryKey: ['wagonTypes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['train_types'],
      })
      queryClient.invalidateQueries({
        queryKey: ['trainType'],
      })
      queryClient.invalidateQueries({
        queryKey: ['constructionTaskTypes'],
      })
    },
  })
}

export const useDeleteWagonType = () => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()

  return useMutation({
    mutationFn: (wagonType: IWagonType) => deleteWagonType(wagonType),
    onSettled: (data: IWagonType) => {
      queryClient.invalidateQueries({
        queryKey: ['wagonType', { wagonTypeId: data.id, projectId }],
      })
      queryClient.invalidateQueries({
        queryKey: ['wagonTypes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['train_types'],
      })
      queryClient.invalidateQueries({
        queryKey: ['trainType'],
      })
    },
  })
}

export const useBulkDeleteWagonTypes = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (ids: number[]) => bulkDeleteWagonTypes(ids),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['wagonTypes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['train_types'],
      })
      queryClient.invalidateQueries({
        queryKey: ['trainType'],
      })
    },
  })
}

export const useFilteredWagonTypes = ({
  sort,
  filters,
  page,
  pageSize,
}: {
  sort: Parameters<typeof getProjectWagonTypes>[3]['sort']
  filters: Omit<Parameters<typeof getProjectWagonTypes>[3], 'sort'>
  page: number
  pageSize: number
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'wagonTypes',
      { projectId: projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getProjectWagonTypes(projectId, page, pageSize, { ...filters, sort }),
  })
}

export const useCreateConstructionTaskType = () => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()

  return useMutation({
    mutationFn: (taskType: IConstructionTaskType) =>
      createConstructionTaskType(taskType, projectId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['constructionTaskTypes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['trainType'],
      })
    },
  })
}

export const useEditConstructionTaskType = () => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()

  return useMutation({
    mutationFn: (taskType: NullablePartial<IConstructionTaskType>) =>
      editConstructionTaskType(taskType),
    onSettled: (data: IConstructionTaskType) => {
      queryClient.invalidateQueries({
        queryKey: [
          'constructionTaskType',
          { constructionTaskTypeId: data.id, projectId },
        ],
      })
      queryClient.invalidateQueries({
        queryKey: ['constructionTaskTypes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['trainType'],
      })
    },
  })
}

export const useDeleteConstructionTaskType = () => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()

  return useMutation({
    mutationFn: (taskType: IConstructionTaskType) =>
      deleteConstructionTaskType(taskType),
    onSettled: (data: IConstructionTaskType) => {
      queryClient.invalidateQueries({
        queryKey: [
          'constructionTaskType',
          { constructionTaskTypeId: data.id, projectId },
        ],
      })
      queryClient.invalidateQueries({
        queryKey: ['constructionTaskTypes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['trainType'],
      })
    },
  })
}

export const useTrainTypeWagonStatistics = (
  trainTypeId: number,
  disciplineIds?: number[],
) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'trainType',
      { projectId: projectId, trainTypeId: trainTypeId, disciplineIds },
    ],
    queryFn: () =>
      trainTypeWagonWorkforce(projectId, trainTypeId, disciplineIds),
  })
}

export const useBulkDeleteConstructiontTaskTypes = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (ids: number[]) => bulkDeleteConstructionTaskTypes(ids),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['constructionTaskTypes'],
      })
      queryClient.invalidateQueries({
        queryKey: ['trainType'],
      })
    },
  })
}

export const useFilteredConstructionTaskTypes = ({
  sort,
  filters,
  page,
  pageSize,
}: {
  sort: Parameters<typeof getProjectConstructionTaskTypes>[2]['sort']
  filters: Omit<Parameters<typeof getProjectConstructionTaskTypes>[2], 'sort'>
  page: number
  pageSize: number
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'constructionTaskTypes',
      { projectId: projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getProjectConstructionTaskTypes(
        projectId,
        page,
        { ...filters, sort },
        pageSize,
      ),
  })
}
