import Task from '@material-symbols/svg-500/rounded/task.svg'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QrReader from 'react-qr-reader'
import { useHistory } from 'react-router-dom'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import Selector from 'src/components/selectors/Selector'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { getUserProjectContracts } from 'src/service/ContractService'
import { getControlAreaThisWeekStats } from 'src/service/ControlAreaService'
import { getUserDisciplines } from 'src/service/DisciplineService'
import {
  IContract,
  IDiscipline,
  IProjectData,
  Languages,
} from 'src/service/OrgTypes'
import { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import useAlert from 'src/ui-elements/toast/useAlert'
import AppCommunicator from '../../service/AppCommunicator'
import { capFirstLetter, classNames } from '../../utility/utils'
import SplashScreen from '../SplashScreen'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MSearchBox from '../components/MSearchBox'
import StatCard from '../components/StatCard'

const styleClass = {
  qr: classNames(
    'w-full',
    'h-auto',
    'bg-white',
    'shadow',
    'p-4',
    'mt-8',
    'flex',
    'flex-col',
    'items-center',
    'border-dotted',
    'border-2',
    'border-blue-root',
    'rounded-md',
  ),
}

interface IMHome {
  lang: Languages
  changeLang: (e: Languages) => void
}

const MHome = ({ lang, changeLang }: IMHome) => {
  const history = useHistory()
  const projectContext: IProjectContext = useContext(ProjectContext)
  const { projects, currentProject } = projectContext.state
  const { id: currProjectId } = currentProject
  const userContext: IUserContext = useContext(UserContext)
  const user = userContext.state.user
  const [searchText, setSearchText] = useState<string>('')
  const [qrReaderModalOpen, setQrReaderModalOpen] = useState<boolean>(false)
  const [loading] = useState<boolean>(false)
  const [statLoading, setStatLoading] = useState<boolean>(false)

  const [myDisciplines, setMyDisciplines] = useState<IDiscipline[]>([])
  const [myContracts, setMyContracts] = useState<IContract[]>([])
  const [myContractNames, setMyContractNames] = useState<string>('-')
  const [myDisciplinenames, setMyDisciplineNames] = useState<string>('-')

  const [stats, setStats] = useState<any>()
  const [selectedLanguage, setSelectedLanguage] = useState<Languages>(lang)
  const [cameraMode, setCameraMode] = useState<'environment' | 'user'>(
    'environment',
  )
  const { addAlert } = useAlert()
  const { t } = useTranslation()
  const allowedQrTypes = [
    'controlarea',
    'constructionlocomotive',
    'system',
    'room',
  ]
  const newProjectId = useRef<number>(0)

  useEffect(() => {
    fetchMyContractAndDisiplines()
    newProjectId.current = 0
  }, [user, currProjectId])

  useEffect(() => {
    setSelectedLanguage(lang)
  }, [lang])

  const fetchMyContractAndDisiplines = async () => {
    if (user?.id && currProjectId) {
      setStatLoading(true)
      const statsRes = await getControlAreaThisWeekStats(currProjectId)
      setStats(statsRes)
      setStatLoading(false)
      const contracts = await getUserProjectContracts(user.id, currProjectId)
      setMyContractNames(
        contracts?.map((contract) => contract.contractName).join(', '),
      )
      setMyContracts(contracts)

      const disciplines = await getUserDisciplines(currProjectId, user.id)
      setMyDisciplineNames(disciplines?.map((disp) => disp.name).join(', '))
      setMyDisciplines(disciplines)
    }
  }

  const getProjects = () => {
    const filterProject: any[] = []
    projects.map((project: IProjectData) => {
      filterProject.push({
        id: project.project.id,
        name: project.project.projectNumber + ' ' + project.project.projectName,
      })
    })
    return filterProject || []
  }

  const setProject = (e: any) => {
    const projectId = e
    projectContext.actions.setProject(parseInt(projectId, 0))
  }

  const search = async (event: any) => {
    event.preventDefault()
    if (searchText !== '') {
      history.push(`/mobile/search/${searchText}`)
    }
  }

  const qrErrorMessages = {
    URL_NOT_VALID: t('the_qr_code_is_not_from_this_page_or_is_not_a_valid_url'),
    NO_PROJECT_ACCESS: t(
      'selected project does not match or no project access granted',
    ),
    UNKNOWN_QR_TYPE: t('this_qr_type_is_not_supported'),
  }

  const handleScan = (data: any) => {
    if (!data) {
      return
    }

    const caId = isControlAreaExceptionURl(data)
    if (caId && +caId > 0) {
      history.push({ pathname: `/mobile/control-areas/${caId}` })
      return
    }

    if (!isQRValid(data)) {
      return
    }

    navigateToDetailPage(data)
  }

  const isControlAreaExceptionURl = (data: any) => {
    const { type, projectId, itemId, pathName } = getQRParsedData(data)
    if (!type && !projectId && !itemId && pathName?.includes('control_area')) {
      const splitted = pathName.split('/')
      const hasValidId =
        Number(splitted[splitted.length - 1]) > 0 ? true : false
      return hasValidId ? splitted[splitted.length - 1] : 0
    } else {
      return 0
    }
  }

  const getQRParsedData = (data: any) => {
    const parsedUrl = new URL(data)
    const type = parsedUrl.searchParams.get('modal')
    const projectId = parsedUrl.searchParams.get('project')
    const itemId = parsedUrl.searchParams.get('id')
    const pathName = parsedUrl.pathname
    return { projectId, type, itemId, pathName }
  }

  const isQRValid = (data: any) => {
    if (!data) {
      return false
    }

    const validURl = new URL(data)
    const domainMatches = window.location.hostname === validURl.hostname
    if (!domainMatches) {
      return false
    }

    const { projectId, type, itemId } = getQRParsedData(data)

    const userProjects: IProjectData[] = projectContext.state.projects
    const hasAcessToProject = projectId
      ? userProjects.findIndex(
          (projectData) => projectData.project.id === +projectId,
        )
      : -1

    if (hasAcessToProject > -1 && projectId && +projectId !== currProjectId) {
      newProjectId.current = +projectId
    }

    if (!projectId || !type || !itemId) {
      showQRError(qrErrorMessages.URL_NOT_VALID)
      return false
    }
    if (hasAcessToProject < 0) {
      showQRError(qrErrorMessages.NO_PROJECT_ACCESS)
      return false
    }
    if (type && !allowedQrTypes.includes(type)) {
      showQRError(qrErrorMessages.UNKNOWN_QR_TYPE)
      return false
    }
    return true
  }

  const showQRError = (errorMessage: string) => {
    addAlert({
      type: 'error',
      title: t('an_error_occurred'),
      description: errorMessage,
      autoClose: false,
    })
    setQrReaderModalOpen(false)
  }

  const navigateToDetailPage = (data: any) => {
    const { type, itemId } = getQRParsedData(data)
    if (type && itemId) {
      if (newProjectId?.current > 0) {
        setProject(`${newProjectId.current}`)
        const message = t('current_project_was_changed_to', {
          projetName: projects
            .filter((item: any) => item?.project?.id === newProjectId.current)
            .map((item: any) => item?.project?.projectName),
        })
        addAlert({
          type: 'info',
          title: t('information'),
          description: message,
          autoClose: false,
        })
      }
      if (type === 'controlarea') {
        history.push({ pathname: `/mobile/control-areas/${itemId}` })
      }
      if (type === 'constructionlocomotive') {
        history.push({ pathname: `/mobile/wagons/${itemId}` })
      }
      if (type === 'system') {
        history.push({
          pathname: `/mobile/systems/${itemId}`,
          state: { fromHome: true },
        })
      }
      if (type === 'room') {
        history.push({
          pathname: `/mobile/rooms/${itemId}`,
          state: { fromHome: true },
        })
      }
    }
  }

  const changeLanguage = (val: any) => {
    if (val) {
      changeLang(val)
    }
  }

  const getLanguageIcon = (selectedId: string) => {
    return selectedId === 'no' ? Icons.NORWEGIAN : Icons.ENGLISH
  }

  const handleError = (err: Error) => {
    addAlert({
      type: 'error',
      title: t('an_error_occured'),
      description: `${err.message}`,
      autoClose: true,
    })
  }

  const handleQRClick = () => {
    setQrReaderModalOpen((prev) => !prev)
  }

  const toggleCameraMode = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    cameraMode === 'user' ? setCameraMode('environment') : setCameraMode('user')
  }

  const availableLanguages = [
    { id: 'no', name: 'no', icon: Icons.NORWEGIAN },
    { id: 'en', name: 'en', icon: Icons.ENGLISH },
  ]

  const handleStatClick = (type: string) => {
    if (type === 'controlArea' && stats?.this_week_control_area_count > 0) {
      history.push(`/mobile/${currProjectId}/control-areas`)
    }
    if (
      type === 'wagon' &&
      stats?.this_week_construction_locomotive_count > 0
    ) {
      history.push(`/mobile/${currProjectId}/wagons`)
    }
    if (type === 'wagonDiscipline' && stats?.my_disciplines_locomotives > 0) {
      history.push(`/mobile/${currProjectId}/wagons?show=myDisciplines`, {
        myDisciplines,
        myContracts,
      })
    }
    if (type === 'issueDiscipline' && stats?.my_disciplines_tasks > 0) {
      history.push(`/mobile/${currProjectId}/issues/myDisciplines`, {
        myDisciplines,
        myContracts,
      })
    }
    if (type === 'issueCurrent' && stats?.this_week_task_count > 0) {
      history.push(`/mobile/${currProjectId}/issues/current`)
    }
    if (type === 'issueAll' && stats?.total_task_count > 0) {
      history.push(`/mobile/${currProjectId}/issues/all`)
    }
    if (type === 'constructionTaskDiscipline') {
      history.push(`/mobile/${currProjectId}/activities/upcoming`)
    }
  }

  const MStat = (): JSX.Element => (
    <div className={'w-full grid grid-cols-2 gap-6 mt-8 pt-1'}>
      <StatCard
        title={
          <span>
            <strong>{capFirstLetter(t('wagons'))}</strong>
            <br />
            <em>{`${t('my_discipline')} ${t('this_week')}`}</em>
          </span>
        }
        icon={Icons.LOCOMOTIVE_PURPLE}
        onClick={() => handleStatClick('wagonDiscipline')}
        value={stats?.my_disciplines_locomotives}
        bg={'purple-100'}
        textColor={'purple-400'}
      />
      <StatCard
        title={
          <span>
            <strong>{capFirstLetter(t('open_issue'))}</strong>
            <br />
            <em>{t('my_discipline')}</em>
          </span>
        }
        icon={Icons.ISSUE_PURPLE}
        onClick={() => handleStatClick('issueDiscipline')}
        value={stats?.my_disciplines_tasks}
        bg={'purple-100'}
        textColor={'purple-400'}
      />
      <StatCard
        title={
          <span>
            <strong>{capFirstLetter(t('activities'))}</strong>
            <br />
            <em>{`${t('my_discipline')} ${t('this_week')}`}</em>
          </span>
        }
        svg={Task}
        onClick={() => handleStatClick('constructionTaskDiscipline')}
        value={stats?.my_disciplines_construction_tasks}
        bg={'purple-100'}
        textColor={'purple-400'}
        fillColor={'fill-purple-400'}
      />

      <StatCard
        title={
          <span>
            <strong>{t('open_issue')}</strong>
            <br />
            <em>{t('registered_this_week')}</em>
          </span>
        }
        icon={Icons.ISSUE_ORANGE}
        onClick={() => handleStatClick('issueCurrent')}
        value={stats?.this_week_task_count}
        bg={'orange-100'}
        textColor={'orange-400'}
      />

      <StatCard
        title={
          <span>
            <strong>{t('open_issue')}</strong>
            <br />
            <em className={'lowercase'}>{t('total')}</em>
          </span>
        }
        icon={Icons.ISSUE_ORANGE}
        onClick={() => handleStatClick('issueAll')}
        value={stats?.total_task_count}
        bg={'orange-100'}
        textColor={'orange-400'}
      />
      <StatCard
        title={
          <span>
            <strong>{t('control_areas')}</strong>
            <br />
            <em>{t('this_week')}</em>
          </span>
        }
        icon={Icons.CONSTRUCTION_BLUE}
        onClick={() => handleStatClick('controlArea')}
        value={stats?.this_week_control_area_count}
      />

      <StatCard
        title={
          <span>
            <strong>{t('wagons')}</strong>
            <br />
            <em>{t('this_week')}</em>
          </span>
        }
        icon={Icons.LOCOMOTIVE_BLUE}
        onClick={() => handleStatClick('wagon')}
        value={stats?.this_week_construction_locomotive_count}
      />
    </div>
  )

  return loading ? (
    <SplashScreen />
  ) : (
    <MPage>
      <>
        <MHeader showHomeBtn={false} user={user} />
        <div
          className={
            'relative bg-blue-root h-auto w-full flex flex-col px-6 pt-4 pb-8'
          }
        >
          <p
            className={'text-1xl md:text-2xl text-white font-bold'}
          >{`${user.firstName} ${user.lastName}`}</p>
          {AppCommunicator.isApp ? (
            <div className={'w-full flex text-xs text-white mb-2'}>
              <p className={'min-w-20 '}>{capFirstLetter(t('project'))}</p>
              <p>{projectContext.state.currentProject?.projectName}</p>
            </div>
          ) : (
            <>
              <div className={'w-full flex text-xs text-white mb-2'}>
                <p className={'min-w-20 '}>{t('contract')}</p>
                <p>{myContractNames}</p>
              </div>
              <div className={'w-full flex text-xs text-white'}>
                <p className={'min-w-20 '}>{capFirstLetter(t('discipline'))}</p>
                <p>{myDisciplinenames}</p>
              </div>
            </>
          )}
          {!AppCommunicator.isApp && (
            <div
              className={
                'absolute w-screen bottom-0 left-0 -mb-4 z-50 flex px-4'
              }
            >
              <div className={'w-2/3 mr-4'}>
                <Selector
                  hidelabel={true}
                  label={''}
                  items={getProjects()}
                  selectedItemId={currProjectId}
                  onSelect={setProject}
                  dataFields={['name']}
                  scroll={true}
                  bgColor={'white'}
                  fontSize={'sm'}
                  inMobile={true}
                  required={true}
                />
              </div>

              <div className={'w-mx-content min-w-28 max-w-28'}>
                <Selector
                  hidelabel={true}
                  label={''}
                  items={availableLanguages}
                  selectedItemId={selectedLanguage}
                  onSelect={changeLanguage}
                  dataFields={['name']}
                  scroll={true}
                  icon={getLanguageIcon(selectedLanguage)}
                  inMobile={true}
                  required={true}
                  bgColor={'white'}
                />
              </div>
            </div>
          )}
        </div>

        <div className={'pt-8 mt-2 pb-4 px-3  flex-auto overflow-y-auto'}>
          <>
            <MSearchBox
              placeholder={t('search_anything')}
              value={searchText}
              onChange={(v) => setSearchText(v)}
              onSubmit={search}
              handleQR={handleQRClick}
            />
            {qrReaderModalOpen ? (
              <CloseClickOutside onClose={() => setQrReaderModalOpen(false)}>
                <div className={styleClass.qr}>
                  <p className={'text-gray-800 text-sm font-light'}>
                    {qrReaderModalOpen ? t('scanning') : t('scan_qr_code_here')}
                  </p>
                  <div className={'p-2 w-full'}>
                    <p
                      onClick={(e) => toggleCameraMode(e)}
                      className={'text-sm font-semibold'}
                    >
                      {t('rotate_camera')}
                    </p>
                    <QrReader
                      delay={300}
                      onError={(error: any) => handleError(error)}
                      onScan={(data: any) => handleScan(data)}
                      style={{ height: '100%' }}
                      facingMode={cameraMode}
                    />
                  </div>
                </div>
              </CloseClickOutside>
            ) : statLoading ? (
              <div className={'h-full grid place-items-center'}>
                <SpinnerMobile />
              </div>
            ) : (
              <MStat />
            )}
          </>
        </div>
      </>
    </MPage>
  )
}

export default MHome
