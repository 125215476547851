import { IConstructionTrain, IPaginatedConstructionTrains } from './OrgTypes'
import { request } from './service-utils'

export const createConstructionTrain = (
  train: IConstructionTrain,
  projectId: number,
): Promise<IConstructionTrain> => {
  return request(`projects/${projectId}/construction_trains`, 'POST', train)
}

export const editConstructionTrain = (
  taskType: Partial<IConstructionTrain>,
): Promise<IConstructionTrain> => {
  return request(`construction_trains/${taskType.id}`, 'PUT', taskType)
}

export const getConstructionTrain = (
  trainId: number,
  draftMode = false,
): Promise<IConstructionTrain> => {
  return request(`construction_trains/${trainId}?drafting=${draftMode}`, 'GET')
}

export const getProjectConstructionTrains = (
  projectId: number,
): Promise<IConstructionTrain[]> => {
  return request(`projects/${projectId}/construction_trains`, 'GET')
}

export const deleteConstructionTrain = (
  task: IConstructionTrain,
  deleteGroup?: boolean,
) => {
  return request(
    `/construction_trains/${task.id}${
      deleteGroup ? '?change_trains_in_group=true' : ''
    }`,
    'DELETE',
    {},
    true,
  )
}

export const getPaginatedControlAreaConstructionTrains = (
  controlAreaId: number,
  page: number,
  draftMode: boolean,
  filter?: any,
  perPage?: number,
): Promise<IPaginatedConstructionTrains> => {
  return request(
    `control_areas/${controlAreaId}/construction_trains/pages/${page}/${
      perPage ? perPage : 100000
    }?drafting=${draftMode}`,
    'Post',
    filter,
  )
}
