import StepInto from '@icons/step_into.svg'
import { useTranslation } from 'react-i18next'
import CircularButton from '../CircularButton'

export interface ITimeLineResetDateProps {
  onReset: () => void
}

const TimeLineResetDate = ({ onReset }: ITimeLineResetDateProps) => {
  const { t } = useTranslation()
  return (
    <CircularButton onClick={onReset} tooltipText={t('todays_date')}>
      <StepInto fontSize={22} />
    </CircularButton>
  )
}

export default TimeLineResetDate
