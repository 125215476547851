import * as React from 'react'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Button from '../../ui-elements/button/Button'
import Modal from '../../ui-elements/modal/Modal'

interface DeleteModalBodyProps {
  itemName?: string
  isBulk?: boolean
}

interface ICloseModalProps {
  closeModal: () => void
  show: boolean
  onDelete: (e?: any) => void
  itemName?: string
  itemIdnType?: string
  customTitle?: string
  children?: React.ReactElement
  hideDeleteBtn?: boolean
  disableDeleteBtn?: boolean
  isBulk?: boolean
}

export const DeleteModalBody = ({
  itemName,
  isBulk,
  children,
}: PropsWithChildren<DeleteModalBodyProps>) => {
  const { t } = useTranslation()

  return (
    <>
      {children && !isBulk ? (
        <div className={'pl-2 pr-5'}>{children}</div>
      ) : (
        <div className={'flex flex-row items-center w-full mt-2 px-3 pt-4'}>
          <Icon
            className={'flex flex-none h-10 w-10 mr-4'}
            styleClass={'w-full'}
            icon={Icons.WARNING_RED}
          />
          <div className={'flex flex-col flex-1'}>
            {isBulk ? (
              children
            ) : (
              <div className={'text-gray-800 mr-4 w-full text-sm break-words'}>
                {t('confirm_that_you_want_to_remove_all_related_information')}
                <strong className={'pl-1'}>{itemName ? itemName : ''}</strong>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

/**
 * @deprecated Use useDeleteModal hook instead
 * 
 * example:
 * 
 *  import { useDeleteModal } from 'src/hooks/useDeleteModal')
 * 
 *  const { confirmDelete } = useDeleteModal()
 * 
 *  const deleteConfirmed = await confirmDelete({
      itemIdnType,
      itemName
    })

    if (deleteConfirmed) {
      // delete item
    }
 */
const DeleteModal = ({
  show,
  onDelete,
  closeModal,
  customTitle,
  children,
  hideDeleteBtn,
  itemName,
  itemIdnType,
  disableDeleteBtn,
  isBulk,
}: ICloseModalProps) => {
  const { t } = useTranslation()
  const onClick = (e: any) => {
    e.preventDefault()
    onDelete()
  }

  return (
    <Modal
      title={
        customTitle
          ? customTitle
          : t('confirm_deletion_of', {
              item: `${itemIdnType ? itemIdnType : ''}`,
            })
      }
      closeModal={closeModal}
      show={show}
      size={'w-1/3'}
      maxWidth={600}
      noHorizontalScroll={true}
    >
      <DeleteModalBody itemName={itemName} isBulk={isBulk}>
        {children}
      </DeleteModalBody>
      <ModalFooter>
        <Button
          width={70}
          type={Button.ButtonType.DEFAULT}
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        {!hideDeleteBtn && (
          <Button
            width={70}
            disabled={disableDeleteBtn}
            type={Button.ButtonType.DANGER}
            onClick={onClick}
          >
            {t('delete')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModal
