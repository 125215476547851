import { IShareToken } from './OrgTypes'
import { request } from './service-utils'

type IShareTokenCreate = Pick<
  Partial<IShareToken>,
  'project_id' | 'resource' | 'comment'
>

type IShareTokenEdit = Pick<
  Partial<IShareToken>,
  'project_id' | 'resource' | 'comment' | 'id'
>

export const createShareToken = (
  token: IShareTokenCreate,
): Promise<IShareToken> => {
  return request(`projects/${token.project_id}/share`, 'POST', token)
}

export const editShareToken = (
  token: IShareTokenEdit,
): Promise<IShareToken> => {
  const { id, ...tokenEdit } = token
  return request(`projects/${token.project_id}/share/${id}`, 'PUT', tokenEdit)
}

export const getShareTokens = (projectId: number): Promise<IShareToken[]> => {
  return request(`projects/${projectId}/share`, 'GET')
}

export const deleteShareToken = (token: IShareToken) => {
  return request(
    `/projects/${token.project_id}/share/${token.id}`,
    'DELETE',
    {},
    true,
  )
}

export const getShareTokenResourceTypes = (): Promise<string[]> => {
  return request(`/share/resource_types`, 'GET')
}

export const validateShareToken = (
  token?: string,
): Promise<{
  message: string
  valid: boolean
}> => {
  return request('/share/validate', 'GET', undefined, undefined, token)
    .then((data) => data)
    .catch((data) => data)
}
