import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MultiSelector from 'src/components/multi-selector/MultiSelector'
import { getProjectUserDefinedField } from 'src/service/SystemTypeFieldService'
import Input from 'src/ui-elements/input/Input'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { queryClient } from '../../../query/client'
import { ISystemTypeGroup } from '../../../service/OrgTypes'
import {
  createSystemTypeGroup,
  editSystemTypeGroup,
  getSystemTypeGroup,
  swapUserDefinedFieldIdOrder,
} from '../../../service/SystemTypeGroupService'
import Button from '../../../ui-elements/button/Button'
import List from '../../../ui-elements/list/List'
import { IListColumns } from '../../../ui-elements/list/ListTypes'
import Spinner from '../../../ui-elements/loader/Spinner'
import { capFirstLetter, classNames } from '../../../utility/utils'
import { IMetaField } from '../system-type-fields/SystemTypeFieldsForm'

interface ISystemTypeGroupProps {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  systemTypeGroupId?: number
  domain: string
  subDomain: string
}

export interface ISystemGroupFields {
  id?: number
  name: string
  project_id: number
  required_fields: number[]
  optional_fields: number[]
  domain: string
}

const SystemTypeGroupForm: React.FC<ISystemTypeGroupProps> = ({
  projectId,
  closeModal,
  editingMode,
  systemTypeGroupId,
  domain,
  subDomain,
}) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'w-1/2'),
    buttonGroup: classNames(
      'w-full',
      'flex',
      'row',
      'my-1',
      'justify-start',
      'pt-4',
      'mb-2',
    ),
    filterSelector: classNames('w-full', 'flex', 'row', 'px-2', 'w-1/2'),
  }

  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [nameErrorMessage, setNameErrorMessage] = useState('')

  const [fields, setFields] = useState<IMetaField[]>([])
  const [selectedFields, setSelectedFields] = useState<IMetaField[]>([])
  const [selectedFieldIds, setSelectedFieldIds] = useState<number[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getProjectUserDefinedField(projectId).then((res) => {
      if (systemTypeGroupId) {
        loadSystemTypeGroup(systemTypeGroupId)
      }

      setFields(res)
      setLoading(false)
    })
  }, [projectId, systemTypeGroupId])

  const loadSystemTypeGroup = (id: number) => {
    getSystemTypeGroup(id).then((resp: ISystemTypeGroup) => {
      const optional_fields = resp.optional_fields || []
      setName(resp.name)
      setSelectedFields(optional_fields)
      const selected: number[] = []
      optional_fields.map((each) => {
        if (each.id) selected.push(each.id)
      })
      setSelectedFieldIds(selected)
    })
  }

  const onSubmit = (e: any) => {
    let error = false
    e.preventDefault()
    setLoading(true)
    setNameErrorMessage('')

    if (!name.length) {
      setNameErrorMessage(t('fill_in_the_name'))
      error = true
    }
    if (!error) {
      submitSystemTypeGroup()
    }

    setLoading(false)
  }

  const submitSystemTypeGroup = async () => {
    const data: ISystemGroupFields = {
      name,
      project_id: projectId,
      required_fields: [],
      optional_fields: selectedFieldIds,
      domain: subDomain,
    }

    if (systemTypeGroupId) {
      const updateData: ISystemGroupFields = {
        ...data,
        id: systemTypeGroupId,
      }
      await editSystemTypeGroup(updateData)
    } else {
      await createSystemTypeGroup(projectId, data)
    }
    await queryClient.invalidateQueries({
      queryKey: ['systemTypeGroups', projectId, domain],
    })
    closeModal()
  }

  const onNameChange = (e: any) => {
    setName(e.target.value)
    setNameErrorMessage('')
  }

  const onChangeField = async (selectedIds: number[]) => {
    setSelectedFields(
      fields.filter((field) =>
        field.id ? selectedIds.includes(field.id) : [],
      ),
    )
    setSelectedFieldIds(selectedIds)
  }

  const possibleValues = (dataType: string, pattern: string[]) => {
    switch (dataType) {
      case 'boolean':
        return t('boolean')
      case 'string':
        return t('string')
      case 'date':
        return t('date')
      case 'time':
        return t('date')
      case 'enum':
        return pattern.join(', ')
      case 'multi_enum':
        return pattern.join(', ')
      default:
        return ''
    }
  }

  const columns: IListColumns[] = [
    {
      name: 'field_name',
      size: '300',
      id: 'name',
      dataField: 'name',
    },
    {
      name: 'data_type',
      size: '150',
      id: 'data_type',
      dataField: 'data_type',
      cell: (data_type: string) => <span>{t(data_type)}</span>,
    },
    {
      name: 'values',
      size: '300',
      id: 'possible_values',
      dataField: 'pattern||data_type',
      cell: (data: any) => (
        <span className="truncate">
          {possibleValues(data.data_type, data.pattern)}
        </span>
      ),
    },
    {
      name: 'default_value',
      size: '300',
      id: 'default_value',
      dataField: 'default_value||default_value_list||data_type',
      cell: (data: {
        data_type: string
        default_value: string
        default_value_list: string
      }) => {
        return (
          <span className="truncate">
            {data.data_type === 'boolean'
              ? data.default_value === 'true'
                ? t('yes')
                : data.default_value === 'false'
                  ? t('no')
                  : ''
              : data.data_type === 'multi_enum'
                ? `${data.default_value_list}`
                : data.default_value
                  ? `${data.default_value}`
                  : ''}
          </span>
        )
      },
    },
  ]

  const swapSequence = (index: number, seq: number) => {
    const selectedField = selectedFields?.[index]
    if (systemTypeGroupId && selectedField && selectedField.id) {
      swapUserDefinedFieldIdOrder(
        systemTypeGroupId,
        selectedField.id,
        seq,
      ).then(() => {
        loadSystemTypeGroup(systemTypeGroupId)
      })
    }
  }

  return (
    <form
      className={'text-left flex flex-col'}
      style={{ minHeight: '100px' }}
      onSubmit={onSubmit}
    >
      <div className={styleClass.inputGroup}>
        <Input
          block={true}
          label={t('name')}
          onChange={onNameChange}
          value={name}
          required={true}
          errorMessage={nameErrorMessage}
        />
      </div>
      <div className={styleClass.filterSelector}>
        <MultiSelector
          items={fields}
          loading={loading}
          onSelect={onChangeField}
          label={t('user_defined_fields')}
          selectedItems={selectedFieldIds}
          dataFields={['name']}
          fontWeight={'bold'}
          noBorder={true}
          scroll={false}
          bgColor={'white'}
        />
      </div>
      <div className="py-2">
        <p className="pl-2 block font-medium text-sm leading-5 text-gray-700 my-2 font-roboto">
          {t('selected_fields')}
        </p>
        <div className="pl-2">
          <List
            data={selectedFields}
            columns={columns}
            tableName={'selectedFields'}
            itemsPerPage={0}
            disableConfigStore={true}
            disableColumnSelector={true}
            isRowDraggable={true}
            swapOrder={swapSequence}
          />
        </div>
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        {editingMode ? (
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : capFirstLetter(t('update'))}
          </Button>
        ) : (
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : capFirstLetter(t('add'))}
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default SystemTypeGroupForm
