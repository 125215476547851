/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TFunction } from 'i18next'
import fileDownload from 'js-file-download'
import { stringify } from 'query-string'
import { MouseEvent } from 'react'
import { FileStatusEnum } from 'src/document/types/IFileContainer'
import { IUserData } from 'src/service/OrgTypes'
import { IUseMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import EnvironmentLoader from '../service/EnvironmentLoader'
import { updateWallToken } from '../service/TheWallService'
import { IListFilter } from '../ui-elements/list/ListContextProvider'
import { filterCase, ISorting } from '../ui-elements/list/ListTypes'
import encodeUrlParams from './encodeUrlParams'

const hasOwn = {}.hasOwnProperty

export function classNames(...args: any[]) {
  const classes = []

  for (let i = 0; i < args.length; i++) {
    const arg = args[i]
    if (!arg) {
      continue
    }

    const argType = typeof arg

    if (argType === 'string' || argType === 'number') {
      // @ts-ignore
      classes.push((this && this[arg]) || arg)
    } else if (Array.isArray(arg)) {
      // @ts-ignore
      classes.push(classNames.apply(this, arg))
    } else if (argType === 'object') {
      for (const key in arg) {
        if (hasOwn.call(arg, key) && arg[key]) {
          // @ts-ignore
          classes.push((this && this[key]) || key)
        }
      }
    }
  }
  return classes.join(' ')
}

export const arrayHasValues = (arr?: number[]): boolean => {
  return !!arr && arr.length > 0
}

export const arrayEmpty = (arr?: number[]): boolean => {
  return !arr || arr.length === 0
}

export function getProxyBaseUrl() {
  return EnvironmentLoader.getConfig().proxyBaseUrl
}

export function getOrganizationBaseUrl() {
  return EnvironmentLoader.getConfig().organizationBaseUrl
}

export function getFdvuBaseUrl() {
  return EnvironmentLoader.getConfig().fdvuBaseUrl
}

export function getFrontendBaseUrl() {
  return EnvironmentLoader.getConfig().frontendBaseUrl
}

export function getExportBaseUrl() {
  return EnvironmentLoader.getConfig().exportBaseUrl
}

export function getTableKeeperBaseUrl() {
  return EnvironmentLoader.getConfig().tableKeeperBaseUrl
}

export function getBimServiceBaseUrl() {
  return EnvironmentLoader.getConfig().bimServiceBaseUrl
}

export function getKpiBaseUrl() {
  return EnvironmentLoader.getConfig().kpiBaseUrl
}

export const validation = {
  hasNumber: (str: string) => {
    const pattern = /\d/
    return pattern.test(str) // returns a boolean
  },
  hasSpecialCharacter: (str: string) => {
    const pattern = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
    return pattern.test(str) // returns a boolean
  },
  isEmailAddress: (str: string) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3})+$)/
    return pattern.test(str) // returns a boolean
  },
}

export const generateRandomString = () => {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 8)
}

export const modalUrlBuilder = (
  projectId: number,
  type: string,
  id: number,
) => {
  return `?project=${projectId}&modal=${type}&id=${id}`
}

export const getCurrentProject = () => {
  return window.localStorage.getItem('currentProject')
}

export const getCurrentOrganization = () => {
  return window.localStorage.getItem('currentOrganization')
}

export const getCurrentUser = () => {
  const userData = window.localStorage.getItem('loggedInUser')
  if (userData) return JSON.parse(userData) as IUserData
  return
}

export const changeReasonList = [
  { id: 'lack_of_description', name: 'Utydelig oppgavebeskrivelse' },
  { id: 'lack_of_resources', name: 'Manglende bemanning' },
  { id: 'lack_of_tools', name: 'Feil eller mangelfullt verktøy' },
  { id: 'scope', name: 'Undervurdert omfang' },
  {
    id: 'proceeding_activities_not_complete',
    name: 'Foregående aktivitet ikke ferdig',
  },
  {
    id: 'lack_of_interdisciplinery_collaboration',
    name: 'Manglende tverrfaglig deltakelse',
  },
  { id: 'task_planned_to_early', name: 'Planlagt for tidlig' },
  { id: 'low_priority', name: 'Nedprioritert for oppgave ikke i plan' },
  { id: 'placed_wrong', name: 'Feil plassert oppgave' },
  { id: 'change_decision', name: 'Besluttet endring' },
  { id: 'lack_of_skills', name: 'Manglende kompetanse' },
  { id: 'lack_of_background', name: 'Manglende underlag (ikke lev. i plan)' },
  {
    id: 'low_priority_another_plan',
    name: 'Nedprioritert for annen oppgave i plan',
  },
]

export const searchReasultTransation = {
  Comment: 'kommentar',
  Delivery: 'Leveranser',
  Improvement: 'Forbedring',
  KeyPoint: 'Noøkkelpunkter',
  MileStone: 'Milepaæler',
  Risk: 'Risiko',
  Task: 'Aksjoner',
  users: 'Bruker',
}

export const cloneObj = (
  obj: Record<string, string | number | undefined | null | object | any>,
) => {
  return JSON.parse(JSON.stringify(obj))
}

export const checkKeys = (
  a: Record<string, unknown>,
  b: Record<string, unknown>,
) => {
  const aKeys = Object.keys(a).sort()
  const bKeys = Object.keys(b).sort()
  return JSON.stringify(aKeys) === JSON.stringify(bKeys)
}

const convertStatus = (status: string) => {
  switch (status) {
    case 'Åpen':
      return 'open'
    case 'Under arbeid':
      return 'in_progress'
    case 'Ferdig':
      return 'done'
    case 'Lukklet - risiko slo til':
      return 'closed_risk_occured'
    case 'Lukket - risiko slo ikke til':
      return 'closed_risk_not_occured'
    case 'Nye forslag':
      return 'open_improvement'
    case 'Prioritering':
      return 'prioritized'
    case 'Fullførte':
      return 'completed'

    default:
      return status
  }
}

const convertTaskSource = (status: string) => {
  switch (status) {
    case 'Leveranse':
      return 'Delivery'
    case 'Risiko':
      return 'Risk'
    case 'Møte':
      return 'Topic'
    case 'Prosjekt':
      return 'Project'
    default:
      return status
  }
}

export const convertUploadType = (status: string) => {
  switch (status) {
    case 'tasks':
      return 'Aksjoner'
    case 'deliveries':
      return 'Leveranser'
    case 'key_points':
      return 'Nøkkelpunkter'
    case 'risks':
      return 'risiko'
    case 'mile_stones':
      return 'Milepæl'
    default:
      return status
  }
}

export const convertPPUType = (type: string) => {
  switch (type) {
    case 'delivery':
      return 'Leveranse PPU mål'
    case 'key_point':
      return 'Nøkkelpunkt PPU mål'
    case 'actions':
      return 'Aksjon PPU mål'
    default:
      return type
  }
}

const convertBoolean = (bool: string) => {
  switch (bool) {
    case 'Nei':
      return false
    case 'Ja':
      return true
    default:
      return status
  }
}

// temporary fix
export const colorCorrectionRiskMatrix = (color: string) => {
  if (color) {
    if (color.includes('red')) {
      return 'bg-red-500'
    } else if (color.includes('yellow')) {
      return 'bg-yellow-300'
    } else if (color.includes('green')) {
      return 'bg-green-two'
    } else {
      return color
    }
  } else {
    return color
  }
}

export interface IActiveFilter {
  sort?: ISorting
  user_defined_values?: {
    name: string
    data_type: string
    search_param: (string | boolean | number)[]
  }[]

  [key: string]: any
}

export const constructFilterJson = (filters: IListFilter[]): IActiveFilter => {
  const activeFilters: IActiveFilter = {}
  filters.map((filter) => {
    const column = filter.column
    if (filter.filterOptions.length > 0) {
      const filterName = column.filterFiled
        ? column.filterFiled.split('||')
        : column.dataField.split('||')
      const theFilter: (string | boolean | number)[] = []
      filter.filterOptions.map((option) => {
        if (option.active && option.value !== 'SELECT_ALL') {
          theFilter.push(
            column.filterCase === filterCase.ACTION_STATUS
              ? convertStatus(option.value)
              : column.filterCase === filterCase.ACTION_SOURCE
                ? convertTaskSource(option.value)
                : column.filterCase === filterCase.ACTION_YES_NO
                  ? convertBoolean(option.value)
                  : option.value,
          )
        }
      })

      if (column.userDefinedFiled && column.userDefinedType) {
        if (!activeFilters.user_defined_values) {
          activeFilters.user_defined_values = []
        }
        activeFilters['user_defined_values'].push({
          name: column.userDefinedFiled,
          data_type: column.userDefinedType,
          search_param: theFilter,
        })
      } else {
        activeFilters[filterName[0]] = theFilter
      }
    }
  })
  return activeFilters
}

/**
 * @deprecated Use the 'first-capitalize'
 */
export const capFirstLetter = (str?: string) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}

export const toLowerCase = (str: string) => {
  return `${str}`.toLowerCase()
}

export const hasEmptyString = (array: string[]) => {
  return array.indexOf('') >= 0
}

export const isProduction = () => {
  return (
    window.location.hostname.indexOf('lvb') > -1 ||
    window.location.hostname.indexOf('oslo') > -1 ||
    window.location.hostname.indexOf('taskctrl') > -1 ||
    window.location.hostname.indexOf('ncs') > -1 ||
    window.location.hostname.indexOf('implenia') > -1
  )
}

export const isNcs = () => {
  if (window.location.hostname.indexOf('ncs') > -1) {
    return true
  }
  return false
}

export const isLvb = () => {
  if (window.location.hostname.indexOf('lvb') > -1) {
    return true
  }
  return false
}

export const isImplenia = () => {
  if (window.location.hostname.indexOf('implenia') > -1) {
    return true
  }
  return false
}

export const isOslo = () => {
  if (window.location.hostname.indexOf('oslo') > -1) {
    return true
  }
  return false
}

export function getDomain() {
  const host = window.location.hostname.split('.')[0]
  return host === 'localhost' ? 'test' : host
}

export const openFile = (url: string) => (e: any) => {
  e.stopPropagation()
  return updateWallToken().then((token) => {
    window.open(`${url}&token=${token}&compress=true`, '_blank')
  })
}

const isInternalFileUrl = (url: string): boolean =>
  /bygg.io\/+api\/+file/.test(url)

export const openFileExternal = (url?: string) => (e?: MouseEvent) => {
  if (!url) return
  const encodedUrl = encodeUrlParams(url)
  if (isInternalFileUrl(url)) return openFile(encodedUrl)(e)
  return window.open(encodedUrl, '_blank')
}

export const getModelName = (type: string, t: IUseMultipleKeysTranslation) => {
  switch (type) {
    case 'task':
      return capFirstLetter(t('task'))
    case 'delivery':
      return capFirstLetter(t('delivery'))
    case 'keypoint':
      return capFirstLetter(t('keypoint'))
    case 'milestone':
      return capFirstLetter(t('milestone'))
    case 'improvement':
      return capFirstLetter(t('improvement'))
    case 'controlarea':
      return capFirstLetter(t('control_area'))
    case 'risk':
      return capFirstLetter(t('risk'))
    case 'notification':
      return capFirstLetter(t('notification'))
    case 'constructiontrain':
      return capFirstLetter(t('train'))
    case 'constructionlocomotive':
      return capFirstLetter(t('wagon'))
    case 'constructiontask':
      return capFirstLetter(t('activity'))
    case 'system':
      return capFirstLetter(t('system'))
    case 'test_system_group':
      return capFirstLetter(t('test_system_group'))
    case 'test_work_group':
      return capFirstLetter(t('test_work_group'))
    case 'filecontainer':
      return capFirstLetter(t('document'))
    case 'assessment':
      return capFirstLetter(t('risk_assessment'))
    case 'room':
      return capFirstLetter(t('room'))
    case 'test':
      return capFirstLetter(t('test'))
    case 'testcase':
      return capFirstLetter(t('test_case'))
    case 'testcasegroup':
      return capFirstLetter(t('test_case'))
    case 'testexecution':
      return capFirstLetter(t('test_execution'))
    case 'testcaseexecution':
      return capFirstLetter(t('test_execution'))
    case 'testsetuptype':
      return capFirstLetter(t('test_setup_type'))
    case 'testdocumenttype':
      return capFirstLetter(t('test_document_type'))
    default:
      return capFirstLetter(t(type))
  }
}

export const numberOrUndefined = (val?: string) => {
  const number = Number(val)
  if (isNaN(number)) return undefined
  return number
}

export const isImageType = (type: string) =>
  type === 'image/jpeg' ||
  type === 'image/jpg' ||
  type === 'image/png' ||
  type === 'image/gif'

export const emailPattern = /\S+@\S+\.\S+/

export const isEmptyArray = (arr: unknown) => {
  return Array.isArray(arr) && arr.length === 0
}

export const formatQueryParams = (
  obj: Record<string, string | number | undefined>,
) => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key])
  if (Object.keys(obj).length === 0) return ''
  return `?${stringify(obj)}`
}

export const fileStatusTypes = (t: TFunction) => [
  {
    id: FileStatusEnum.NOT_CHECKED,
    name: t('not_checked'),
  },
  {
    id: FileStatusEnum.IN_PROGRESS,
    name: t('in_progress'),
  },
  {
    id: FileStatusEnum.APPROVED,
    name: t('approved'),
  },
  {
    id: FileStatusEnum.DECLINED,
    name: t('declined'),
  },
]

export const downloadFile = async (url: string, fileName: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  fileDownload(blob, fileName)
}

export const safeAtop = (str: string): string | null => {
  try {
    return atob(str)
  } catch (e) {
    return null
  }
}
