import { createColumnHelper } from '@tanstack/react-table'
import DocListStage from 'src/document/components/FolderDocument/DocListStatus'
import { IStepData } from 'src/document/types/IFlow'
import { CustomMeta } from 'src/ui-elements/Table/Columns'
import TableColumn from 'src/ui-elements/Table/TableColumn'
import { filterType } from 'src/ui-elements/list/ListTypes'

type StepColumn = {
  current_step: IStepData
}

const stepHelper = createColumnHelper<StepColumn>()

export const stepColumn = (field: keyof StepColumn, meta: CustomMeta) =>
  stepHelper.accessor(field, {
    id: field,
    size: 150,
    cell: ({ cell }) => <DocListStage stage={cell.getValue()} />,
    header: (props) => (
      <TableColumn column={props.column} table={props.table} />
    ),
    meta: {
      field,
      filterType: filterType.TEXT,
      processFilter: (val) => ({
        [field]: [val],
      }),
      ...meta,
    },
  })
