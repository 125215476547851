import * as React from 'react'
import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import FileDrop, { FileDropProps } from '../FileUpload/FileDrop'
import { FileUploadContext } from './context/FileUploadContext'
import { NEXT_STEP, UPLOAD_STATE } from './types'

export interface IFileUploadModalProps {
  show: boolean
  close: () => void
  uploadUrl: string
  handleNext: (step: number, data: any) => Promise<NEXT_STEP>
  stepElements: React.ReactNode[]
  downloadTemplate?: () => Promise<void>
  multiple?: boolean
  type?: string
  title?: string | string[]
  showStepCount?: boolean
  currentStep?: number
  validateLoading?: boolean
  validate?: FileDropProps['validate']
}

const FileUploader: FC<IFileUploadModalProps> = ({
  show,
  close,
  uploadUrl,
  handleNext: handleNextCallback,
  stepElements,
  multiple,
  type,
  downloadTemplate,
  title,
  showStepCount = true,
  currentStep,
  validateLoading,
  validate,
}) => {
  const [step, setStep] = useState(0)
  const [nextLoading, setNextLoading] = useState(validateLoading ?? false)

  const uploadElement = (
    <FileDrop
      downloadTemplate={downloadTemplate}
      multiple={multiple}
      type={type}
      uploadUrl={uploadUrl}
      validate={validate}
    />
  )

  const elements = [uploadElement, ...stepElements.filter((e) => e !== null)]

  const { t } = useTranslation()

  const { clearQueue, files, uploadedFiles } = useContext(FileUploadContext)

  useEffect(() => {
    setNextLoading(validateLoading ?? false)
  }, [validateLoading])

  useEffect(() => {
    if (currentStep) {
      setStep(currentStep)
    }
  }, [currentStep])

  const handleNext = async () => {
    setNextLoading(true)
    const nextStep = await handleNextCallback(step, uploadedFiles)
    setNextLoading(false)
    switch (nextStep) {
      case NEXT_STEP.CONTINUE:
        if (step < elements.length - 1) {
          setStep(step + 1)
        } else {
          close()
        }
        break
      case NEXT_STEP.CLOSE:
        close()
        break
      default:
        break
    }
  }

  useEffect(() => {
    const filesUploaded = Object.keys(uploadedFiles).length > 0
    const allUploaded = Object.values(files).every(
      (file) => file.uploadStatus.status !== UPLOAD_STATE.UPLOADING,
    )
    if (allUploaded && filesUploaded) {
      handleNext()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles, files])

  const handleModalClose = () => {
    clearQueue()
    close()
  }

  const getTitle = (): string => {
    const ret = []
    if (typeof title === 'string') {
      ret.push(t(title))
    } else if (Array.isArray(title)) {
      ret.push(t(title[step]))
    } else {
      ret.push(t('upload_modal'))
    }
    if (showStepCount) {
      ret.push(` - (${step + 1}/${elements.length})`)
    }
    return ret.join(' ')
  }

  const notLastStep = step < elements.length - 1
  return (
    <Modal
      title={getTitle()}
      show={show}
      closeModal={handleModalClose}
      size={'w-9/10'}
      maxWidth={2400}
    >
      {elements[step]}
      <ModalFooter>
        <Button onClick={handleModalClose}>
          {notLastStep ? t('cancel') : t('close')}
        </Button>
        {step > 0 && notLastStep && (
          <Button
            type={ButtonType.PRIMARY}
            disabled={nextLoading}
            onClick={!nextLoading ? handleNext : undefined}
          >
            <div className="relative">{t('save')}</div>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default FileUploader
