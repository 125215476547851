import Close from '@icons/close.svg'
import ExitToApp from '@icons/exit_to_app.svg'
import { useContext, useEffect, useMemo } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import { InspectorContext } from 'src/context/InspectorContext/InspectorContext'
import { useDetailUrl } from 'src/hooks/useDetailUrl'
import { DetailPageKeys, IDetailPageData } from 'src/utility/DetailPageUtils'
import { classNames } from 'src/utility/utils'
import Tooltip from '../tooltip/Tooltip'

interface IFixedPane {
  title: string | JSX.Element
  onClose: () => void
  children: JSX.Element
  show: boolean
  noMainContent?: boolean
  className?: string
  minWidth?: string
  disableOutsideClose?: boolean
  cascaded?: boolean
  detailPageData?: IDetailPageData<DetailPageKeys>
}

const FixedPane: React.FC<IFixedPane> = ({
  title,
  onClose,
  children,
  show,
  className = '',
  noMainContent,
  minWidth = 'min-w-128',
  disableOutsideClose = false,
  cascaded = false,
  detailPageData,
}) => {
  const styleClass = {
    root: classNames(
      minWidth,
      'fixed',
      'shadow-xsm',
      'bg-white',
      'z-[70]',
      'right-[5px]',
      'top-[70px]',
      show ? 'visible' : 'inivisible',
      '!h-[calc(100vh_-_70px)]',
      'pb-2',
      className,
    ),
    header: classNames(
      'flex',
      'items-center',
      'justify-between',
      'px-3',
      'h-[3rem]',
      noMainContent ? '' : 'mb-[0.5rem]',
      'border-b',
      'border-gray-300',
    ),
    title: classNames(
      'text-m',
      'text-black',
      'leading-normal',
      'font-medium',
      'mt-2',
      'flex',
      'items-center',
      'gap-2',
    ),
  }

  const { t } = useTranslation()
  const id = useMemo(() => Math.floor(Math.random() * 100), [])
  const { actions } = useContext(InspectorContext)

  useEffect(() => {
    actions.addInspectorId(id)
    return () => {
      actions.removeInspectorId(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const rootStyle = () => {
    const index = actions.getInspectorIndex?.(id)
    const initalTop = 70
    const top =
      index === null || index <= 0 ? initalTop : initalTop + index * 50
    return cascaded ? { top } : {}
  }

  const detailUrl = useDetailUrl(detailPageData)

  return show ? (
    <CloseClickOutside
      onClose={disableOutsideClose ? () => undefined : onClose}
    >
      <div style={rootStyle()} className={styleClass.root}>
        <div className={styleClass.header}>
          <p className={styleClass.title}>
            {title}
            {detailUrl && (
              <Tooltip message={t('go_to_page_view')} offset={0.5}>
                <Link to={detailUrl}>
                  <ExitToApp
                    className={'hover:fill-blue-root fill-gray-600'}
                    fontSize={20}
                  />
                </Link>
              </Tooltip>
            )}
          </p>
          <Close
            onClick={onClose}
            fill={'#1a1a1a'}
            fontSize={24}
            className={'cursor-pointer flex-shrink-0 opacity-75'}
          />
        </div>

        <div
          className={`relative overflow-auto ${
            noMainContent ? 'h-[calc(100%_-_3rem)]' : 'h-[calc(100%_-_3.5rem)]'
          }`}
          id={'FixedPaneChildWrapper'}
        >
          {children}
        </div>
      </div>
    </CloseClickOutside>
  ) : null
}

export default FixedPane
