import { PureComponent } from 'react'
import Icon from '../../ui-elements/icon/Icon'
import { classNames } from '../../utility/utils'

interface IOptionItem {
  item: any
  onItemClick: (itemId: string) => void
}

const styleClass = {
  filterItem: classNames(
    'group',
    'flex',
    'items-center',
    'px-2',
    'py-1',
    'text-sm',
    'leading-5',
    'text-gray-700',
    'hover:bg-gray-100',
    'hover:text-gray-900',
    'focus:bg-gray-100',
    'focus:text-gray-900',
    'w-full',
    'whitespace-nowrap',
  ),
  checkbox: classNames(
    'mr-2',
    'form-checkbox',
    'h-4',
    'w-4',
    'text-gray-600',
    'transition',
    'duration-150',
    'ease-in-out',
  ),
}

class OptionItem extends PureComponent<IOptionItem> {
  public UNSAFE_componentWillReceiveProps() {
    this.forceUpdate()
  }

  public render() {
    const { item, onItemClick } = this.props
    return (
      <div
        className={styleClass.filterItem}
        onClick={() => onItemClick(item.id)}
      >
        {!item.active ? (
          <Icon icon={Icon.IconType.CHECK_BOX_TW} />
        ) : (
          <Icon icon={Icon.IconType.CHECKED_BOX_TW} />
        )}
        &nbsp;&nbsp;{item.name}
      </div>
    )
  }
}

export default OptionItem
