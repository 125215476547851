import KeyboardArrowDown from '@icons/keyboard_arrow_down.svg'
import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { styleClass } from 'src/ui-elements/Table/Columns'
import Button from 'src/ui-elements/button/Button'
import { capFirstLetter } from 'src/utility/utils'
import CloseClickOutside from '../click-outside/CloseClickOutside'
import { Direction } from '../multi-selector/MultiSelector'
import Selector from '../selectors/Selector'

interface ITableResponsibleEditorProps {
  rowId: number
  selected: ISelectedItems
  onDataSelected: (id: number, primary: number, secondary: number) => void
  getAPI: IGetAPI
  dataFields: IDataFiled
  displayContent: any
  label: ILabel
  fieldsOptional?: IIsOptional
  primaryUserSelector?: boolean
  secondaryUserSelector?: boolean
  disableEdit?: boolean
}

export interface IGetAPI {
  primary: (id?: number) => Promise<any>
  secondary: (id?: number) => Promise<any>
}

interface IDataFiled {
  primary: string[]
  secondary: string[]
}

interface ISelectedItems {
  primary: any | undefined | null
  secondary: any | undefined | null
}

interface IFloatLabel {
  left: number
  top: number
}

interface ILabel {
  primary: string
  secondary: string
}

export interface IIsOptional {
  primary: boolean
  secondary: boolean
}

const DualTableSelector = ({
  rowId,
  onDataSelected,
  selected,
  primaryUserSelector,
  getAPI,
  dataFields,
  displayContent,
  label,
  secondaryUserSelector,
  fieldsOptional,
  disableEdit,
}: ITableResponsibleEditorProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [floatLabel, setFloating] = useState<IFloatLabel>({ left: 0, top: 0 })

  const [loading, setLoading] = useState<boolean>()
  const [secondaryLoading, setSecondaryLoading] = useState<boolean>()
  const [selectionPrimaryList, setPrimarySelectionList] = useState<any>([])
  const [selectedPrimaryData, setSelectedPrimaryData] = useState<any>(
    selected.primary,
  )
  const [selectionSecondaryList, setSecondarySelectionList] = useState<any>([])
  const [selectedSecondaryData, setSelectedSecondaryData] = useState<any>(
    selected.secondary,
  )

  // error states
  const [primaryError, setPrimaryError] = useState<string>('')
  const [secondaryError, setSecondaryError] = useState<string>('')
  const [openDirection, setOpenDirection] = useState<Direction>(Direction.DOWN)

  const selectorOpen = useRef<boolean>(false)

  const { t } = useTranslation()

  const ct = (text: string): string => {
    return capFirstLetter(t(text))
  }

  useEffect(() => {
    if (open) {
      getPrimaryData()
      if (selected.primary?.id) {
        setSecondaryData(selected.primary.id)
      }
    }
  }, [open])

  useEffect(() => {
    setSelectedPrimaryData(selected.primary)
    setSelectedSecondaryData(selected.secondary)
  }, [selected])

  const onClose = (e: any) => {
    e.stopPropagation()
  }

  const getPrimaryData = async () => {
    setLoading(true)
    const response = await getAPI?.primary()
    setPrimarySelectionList(response)
    setLoading(false)
  }

  const setSecondaryData = async (id: number) => {
    setSecondaryLoading(true)
    const response = await getAPI?.secondary(id)
    if (response.length === 1) {
      setSelectedSecondaryData(response[0])
    }
    setSecondarySelectionList(response)
    setSecondaryLoading(false)
  }

  const onToggleOpen = async (e: any) => {
    if (!disableEdit) {
      e.stopPropagation()
      const target = e.target.getBoundingClientRect()
      const closeToBottom =
        window.innerHeight - e.target.getBoundingClientRect().top < 380
      setOpenDirection(closeToBottom ? Direction.UP : Direction.DOWN)
      const tmpFloatLabel = { ...floatLabel }
      tmpFloatLabel.left = target.left - 20
      tmpFloatLabel.top = target.top - (closeToBottom ? 180 : 5)
      setFloating(tmpFloatLabel)
      setOpen(true)
    }
  }

  const onPrimaryDataChange = async (id: number) => {
    const d = await getAPI?.primary()
    const dSelected = d?.find((da: any) => da.id === id)
    setSelectedPrimaryData(dSelected)
    setSecondaryData(id)
    setSelectedSecondaryData(undefined)
    setPrimaryError('')
  }

  const onSecondaryDataChange = async (id: number) => {
    const d = await getAPI?.secondary()
    const dSelected = d?.find((da: any) => da.id === id)
    setSelectedSecondaryData(dSelected)
    setSecondaryError('')
  }

  // onSave
  const onSave = () => {
    let error = false
    if (selectedPrimaryData === undefined && !fieldsOptional?.primary) {
      setPrimaryError(ct('required'))
      error = true
    }
    if (selectedSecondaryData === undefined && !fieldsOptional?.secondary) {
      setSecondaryError(ct('required'))
      error = true
    }
    if (!error) {
      onDataSelected(rowId, selectedPrimaryData?.id, selectedSecondaryData?.id)
      setOpen(false)
    }
  }

  const onCancel = () => {
    setSelectedPrimaryData(selected.primary)
    setSelectedSecondaryData(selected.secondary)
    setPrimarySelectionList([])
    setSecondarySelectionList([])
    setPrimaryError('')
    setSecondaryError('')
    setOpen(false)
  }

  const onSelectorOpen = (typeHeadOpen: boolean) => {
    selectorOpen.current = typeHeadOpen
  }

  // onClose
  const closeEdit = () => {
    if (!selectorOpen.current) {
      setOpen(false)
    }
  }

  return (
    <>
      {open ? (
        <CloseClickOutside
          className={'w-full z-50 absolute'}
          onClose={closeEdit}
        >
          <div
            onClick={onClose}
            className="ml-3 bg-white shadow-md w-80 fixed"
            style={{
              top: floatLabel.top,
              left: floatLabel.left,
            }}
          >
            <div className="pt-1 px-4 flex flex-col">
              <Selector
                onSelect={onPrimaryDataChange}
                items={selectionPrimaryList}
                selectedItemId={selectedPrimaryData?.id || 0}
                dataFields={dataFields.primary}
                errorMessage={primaryError}
                fontSize={'sm'}
                fontWeight={'sm'}
                loading={loading}
                scroll={true}
                label={t(label.primary)}
                userSelector={primaryUserSelector}
                showIcon={true}
                openByDefault={false}
                typeHeadOpen={onSelectorOpen}
                openDirection={openDirection}
              />
              <Selector
                onSelect={onSecondaryDataChange}
                items={selectionSecondaryList}
                selectedItemId={selectedSecondaryData?.id || 0}
                dataFields={dataFields.secondary}
                fontSize={'sm'}
                fontWeight={'sm'}
                loading={secondaryLoading}
                errorMessage={secondaryError}
                scroll={true}
                label={t(label.secondary)}
                userSelector={secondaryUserSelector}
                showIcon={true}
                openByDefault={false}
                typeHeadOpen={onSelectorOpen}
                openDirection={openDirection}
              />
              <div className="py-2 self-end">
                <Button onClick={onSave} type={Button.ButtonType.PRIMARY}>
                  {ct('save')}
                </Button>
                <Button onClick={onCancel} type={Button.ButtonType.SECONDARY}>
                  {ct('cancel')}
                </Button>
              </div>
            </div>
          </div>
        </CloseClickOutside>
      ) : (
        <>
          <div
            className={twMerge(
              'px-1 w-full h-full cursor-pointer truncate flex justify-between items-center',
              !disableEdit && styleClass.editableCell,
            )}
            onClick={onToggleOpen}
          >
            {displayContent}
            {!disableEdit && (
              <KeyboardArrowDown className={'fill-gray-800 text-lg'} />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DualTableSelector
