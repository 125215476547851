import { capFirstLetter, classNames } from '../../utility/utils'

export type IMRowDisplayData = Array<{
  label: string
  value: string | JSX.Element | undefined | null
}>

interface IMRowDisplay {
  data: IMRowDisplayData
  className?: string
  noPaddingX?: boolean
  equalWidth?: boolean
  wrapValue?: boolean
}

const MRowDisplay = ({
  data,
  className = '',
  noPaddingX = false,
  equalWidth = false,
  wrapValue = true,
}: IMRowDisplay) => {
  const styleClass = {
    root: (moreClasses: string, noHorizontalPadding: boolean) =>
      classNames(
        !noHorizontalPadding ? 'px-5' : '',
        'py-3',
        'flex',
        'flex-col',
        moreClasses,
      ),
    row: classNames(
      'flex',
      'justify-between',
      'items-center',
      'text-sm',
      'py-1',
    ),
    label: (equal: boolean) =>
      classNames('font-medium', 'truncate', equal ? 'w-1/2' : 'w-1/3'),
    value: (equal: boolean) =>
      classNames(
        'font-light',
        equal ? 'w-1/2' : 'w-2/3',
        'flex',
        'justify-end',
        'break-words',
      ),
  }
  return (
    <div className={styleClass.root(className, noPaddingX)}>
      {data &&
        data.map((row, key) => (
          <div key={key} className={styleClass.row}>
            <div className={styleClass.label(equalWidth)}>
              {capFirstLetter(row.label)}
            </div>
            <div className={styleClass.value(equalWidth)}>
              <div className={`w-full ${!wrapValue ? 'truncate' : ''}`}>
                {row.value !== null ? row.value : ''}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default MRowDisplay
