import moment from 'moment'

export const formatDateRelativeToNow = (
  date: string | Date | moment.Moment | number,
  language: string,
  maxDays = 2,
) => {
  if (moment(date) > moment().subtract(maxDays, 'days').startOf('day')) {
    return moment(date).locale(language).fromNow()
  }
  return moment(date).locale(language).format('ll')
}

export const isValidDate = (date: string) => {
  const datePattern = /^([0-9]{4}-[0-9]{2}-[0-9]+)\w+/

  return moment(date).isValid() && date.toString().match(datePattern)
}

export const monthNames = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]
