import { ChangeEvent, InputHTMLAttributes, PureComponent } from 'react'
import { twMerge } from 'tailwind-merge'
import { classNames, generateRandomString } from '../../utility/utils'
import Spinner from '../loader/Spinner'

interface IRadioItemsProps extends InputHTMLAttributes<HTMLInputElement> {
  items: IRadioItems[]
  value: number | string | undefined
  stack?: boolean
  disable?: boolean
  noMargin?: boolean
  onRadioClick?: (
    e: ChangeEvent<HTMLInputElement>,
    id: string | number | undefined,
    data?: any,
  ) => void
  data?: any
  disableTitle?: string
  inMobile?: boolean
  loading?: boolean
}

export interface IRadioItems {
  id: number | string | undefined
  name: JSX.Element
  disable?: boolean
  classNames?: string
}

export default class RadioItems extends PureComponent<IRadioItemsProps, any> {
  private static styleClass = {
    root: (stack: boolean | undefined, noMargin: boolean, className: string) =>
      twMerge(
        'flex',
        'flex-row',
        !noMargin ? 'px-2' : '',
        !noMargin ? 'mb-2' : '',
        stack ? 'justify-start flex-wrap' : '',
        className,
      ),
    radioContainer: classNames('flex', 'flex-row', 'gap-4'),
    radioItem: (stack: boolean, disabled: boolean, mobile: boolean) =>
      classNames(
        mobile ? 'px-2.5' : 'px-2',
        'py-1',
        'flex items-center',
        stack ? 'flex-col-reverse items-center justify-center' : 'flex-row',
        disabled ? 'cursor-not-allowed' : '',
      ),
    radioLabel: (disabled: boolean, className?: string) =>
      classNames(
        'pl-3.5',
        className,
        disabled
          ? 'cursor-not-allowed text-gray-300'
          : 'text-gray-600 cursor-pointer hover:text-blue-root',
      ),
  }

  private handleClick = (e: any, id: string | number | undefined) => {
    if (this.props.onRadioClick) {
      this.props.onRadioClick(
        e,
        id,
        this.props.data ? this.props.data : undefined,
      )
    }
  }

  public render() {
    const {
      items,
      value,
      stack,
      disable,
      noMargin = false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onRadioClick,
      inMobile = false,
      loading = false,
      className = '',
      ...rest
    } = this.props
    const randomStr = generateRandomString()
    return (
      <div className={RadioItems.styleClass.root(stack, noMargin, className)}>
        {loading ? (
          <div className={'flex justify-center items-center mt-2'}>
            <Spinner />
          </div>
        ) : (
          items.map((i, key) => {
            return (
              <div
                onClick={(e) =>
                  disable || i.disable ? null : this.handleClick(e, i.id)
                }
                title={
                  disable && this.props.disableTitle
                    ? this.props.disableTitle
                    : undefined
                }
                key={`${randomStr}-${key}`}
                className={RadioItems.styleClass.radioItem(
                  stack ? stack : false,
                  !!disable,
                  inMobile,
                )}
              >
                <input
                  className={i.classNames}
                  disabled={disable || i.disable}
                  id={`${randomStr}-${key}`}
                  type={'radio'}
                  name={randomStr}
                  value={i.id}
                  {...rest}
                  defaultChecked={value === i.id}
                />
                <label
                  htmlFor={`${randomStr}-${key}`}
                  className={RadioItems.styleClass.radioLabel(
                    !!disable,
                    i.classNames,
                  )}
                >
                  {i.name}
                </label>
              </div>
            )
          })
        )}
      </div>
    )
  }
}
