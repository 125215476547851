import { useMutation, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getTable, setTableConfig } from '../service/TableKeeperService'
import { queryClient } from './client'

export const tableConfig = 'table-Config'
export const tableConfigQueryKey = (
  projectId: number,
  tableName: string,
  projectLevel: boolean,
) => [tableConfig, projectId, tableName, projectLevel]

export const useTableConfig = <T = unknown>(
  tableName: string,
  projectLevel: boolean = false,
) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: tableConfigQueryKey(projectId, tableName, projectLevel),
    queryFn: () => getTable<T>(projectId, tableName, projectLevel),
  })
}

export const useTableConfigInvalidate = (
  tableName: string,
  projectLevel: boolean,
) => {
  const projectId = useProjectId()

  return async () =>
    await queryClient.invalidateQueries({
      queryKey: tableConfigQueryKey(projectId, tableName, projectLevel),
    })
}

export const useMutateTableConfig = <T = unknown>(tableName: string) => {
  const projectId = useProjectId()

  return useMutation({
    mutationKey: [tableConfig, projectId, tableName],
    mutationFn: (config: T) =>
      setTableConfig(projectId, tableName, { data: config }),
  })
}

export const useTableKeeper = <T = unknown>(tableName: string) => {
  const tableConfig = useTableConfig<T>(tableName)
  const mutateTable = useMutateTableConfig<T>(tableName)

  return {
    data: tableConfig.data,
    isLoading: tableConfig.isLoading,
    isPending: tableConfig.isPending,
    mutate: mutateTable,
  }
}
