import * as React from 'react'
import { useEffect, useState } from 'react'
import CheckBox from 'src/components/switchHoc/CheckBox'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import { useConfirmModal } from 'src/hooks/useConfirmModal'
import { IInlineBaseProps } from './IDropDown'
import InlineBaseComponent from './InlineBaseComponent'
import InlineErrorMessageComponent from './InlineErrorMessageComponent'
import InlineInputLabelComponent from './InlineInputLabelComponent'

interface IBooleanInlineInputComponent extends IInlineBaseProps {
  label: string
  value?: boolean
  validate?: (newValue?: boolean) => string | undefined
  labelWidth?: string
  inputWidth?: string
  onValueSubmitted: (newValue?: boolean) => void
  showCheckBox?: boolean
  disabled?: boolean
  className?: string
  showConfirmModal?: boolean
  confirmMessage?: string
}

const BooleanInlineInputComponent: React.FC<IBooleanInlineInputComponent> = ({
  label,
  value = false,
  validate,
  onValueSubmitted,
  labelWidth,
  labelTextSize,
  disabled = false,
  showCheckBox = false,
  className,
  disableTooltip,
  showConfirmModal = false,
  confirmMessage,
}) => {
  const { confirm } = useConfirmModal()

  const [internalValue, setInternalValue] = useState(value)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const save = (newValue: boolean) => {
    if (validate) {
      const errorMessage = validate(internalValue)
      if (errorMessage?.length) {
        setErrorMessage(errorMessage)
        return
      }
    }
    if (newValue !== value) {
      onValueSubmitted(newValue)
    }
  }

  const onChange = async (newValue: boolean) => {
    setInternalValue(newValue)
    if (showConfirmModal) {
      const confirmed = await confirm({
        message: confirmMessage,
      })
      if (!confirmed) {
        setInternalValue(value)
        return
      }
    }
    save(newValue)
  }

  return (
    <InlineBaseComponent>
      <InlineInputLabelComponent
        label={label}
        labelWidth={labelWidth}
        disableTooltip={disableTooltip}
        labelTextSize={labelTextSize}
      />
      <InlineErrorMessageComponent errorMessage={errorMessage} />
      <div className="pt-1 pl-1 px-1">
        {showCheckBox ? (
          <CheckBox
            valueProp={internalValue}
            onChange={onChange}
            disable={disabled}
            controlValueInParent
          />
        ) : (
          <SwitchHOC
            valueProp={internalValue}
            className={`custom-classname ${className}`}
            onChange={onChange}
            disabled={disabled}
            controlValueInParent
          />
        )}
      </div>
    </InlineBaseComponent>
  )
}

export default BooleanInlineInputComponent
