import { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import { styleClass } from 'src/ui-elements/Table/Columns'

interface IMultiValueListSelector {
  values: { id: number }[]
  field: string
  onClick?: (ids: number[], selectedId: number) => void
  selectedId: number
}

const MultiValueListSelector: FC<IMultiValueListSelector> = ({
  values,
  field,
  onClick,
  selectedId,
}) => {
  const disabled = !onClick
  return (
    <span
      onClick={(e) => {
        if (disabled) return
        e.preventDefault()
        e.stopPropagation()
        onClick(
          values.map(({ id }) => id),
          selectedId,
        )
      }}
      className={twMerge(
        !disabled ? styleClass.editableCell : styleClass.cell,
        'w-full h-full content-center',
      )}
    >
      {values?.map((value) => `${value[field]}`)?.join(', ') ?? ''}
    </span>
  )
}

export default MultiValueListSelector
