import KeyboardArrowDown from '@icons/keyboard_arrow_down.svg'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { styleClass } from 'src/ui-elements/Table/Columns'
import Spinner from 'src/ui-elements/loader/Spinner'
import CloseClickOutside from '../click-outside/CloseClickOutside'
import { Direction } from '../multi-selector/MultiSelector'
import Selector from '../selectors/Selector'

interface ITableResponsibleEditorProps {
  rowId: number
  selected: any | undefined | null
  onDataSelected: (key: any, cell?: any) => void
  getData: () => Promise<any> | any
  isUserSelector?: boolean
  dataFields: any[]
  displayContent: any
  displayNullContent?: boolean
  showCancelButton?: boolean
  disableEdit?: boolean
  showOptimisticUpdate?: boolean
}

interface IFloatLabel {
  left: number
  top: number
}

const renderLocalState = (
  data: Record<string, string>,
  dataFields: string[],
) => {
  return dataFields
    .map((df) => {
      const d = data[df]
      if (d === undefined || d === null) return ''
      return d
    })
    .join(' ')
}

const TableSelectorEditor = ({
  onDataSelected,
  selected,
  isUserSelector,
  getData,
  dataFields,
  displayContent,
  displayNullContent,
  showCancelButton,
  disableEdit,
  showOptimisticUpdate,
}: ITableResponsibleEditorProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [floatValue] = useState<IFloatLabel>({ left: 0, top: 0 })

  const [loading, setLoading] = useState<boolean>()
  const [selectionList, setSelectionList] = useState<any>([])
  const [selectedData, setSelectedData] = useState<any>(selected)
  const [openDirection, setOpenDirection] = useState<Direction>(Direction.DOWN)

  useEffect(() => {
    setSelectedData(selected)
  }, [selected])

  const onClose = (e: any) => {
    e.stopPropagation()
  }

  const onToggleOpen = async (e: any) => {
    if (disableEdit) return
    const target = e.target.getBoundingClientRect()
    const closeToBottom = window.innerHeight - target.top < 380
    setOpenDirection(closeToBottom ? Direction.UP : Direction.DOWN)
    e.stopPropagation()
    setOpen((n) => !n)
    setLoading(true)
    const d = await getData()
    setSelectionList(d)
    setLoading(false)
  }

  const onDataChange = async (id: number) => {
    setOpen(false)
    const d = await getData()
    const dSelected = d.find((da: any) => da.id === id)
    setSelectedData(dSelected)
    onDataSelected(dSelected)
  }

  return (
    <>
      {!disableEdit && open ? (
        <>
          {loading ? (
            <div onClick={onClose}>
              <Spinner />
            </div>
          ) : (
            <>
              <CloseClickOutside
                className={'w-full'}
                onClose={() => setOpen(false)}
              >
                <div
                  onClick={onClose}
                  className={`relative -ml-2`}
                  style={{
                    top: floatValue.top,
                    left: floatValue.left,
                    width: 'calc(100%+10px)',
                  }}
                  onBlur={() => setOpen(false)}
                >
                  <Selector
                    onSelect={onDataChange}
                    items={selectionList}
                    selectedItemId={selectedData ? selectedData.id : 0}
                    dataFields={dataFields}
                    fontSize={'sm'}
                    fontWeight={'sm'}
                    loading={loading}
                    scroll={true}
                    label=""
                    userSelector={isUserSelector}
                    showIcon={true}
                    cancelButton={showCancelButton}
                    onCancel={() => onDataSelected({ id: null })}
                    hidelabel={true}
                    openByDefault={true}
                    typeHeadOpen={(o) => setOpen(!o)}
                    openDirection={openDirection}
                  />
                </div>
              </CloseClickOutside>
            </>
          )}
        </>
      ) : (
        <div
          className={twMerge(
            'px-1 w-full h-full cursor-pointer flex justify-between items-center',
            !disableEdit && styleClass.editableCell,
          )}
          onClick={(e) => {
            !loading && onToggleOpen(e)
          }}
        >
          <div>
            {showOptimisticUpdate && !!selectedData ? (
              <span>{renderLocalState(selectedData, dataFields)}</span>
            ) : (
              (selectedData !== undefined || displayNullContent) &&
              displayContent
            )}
          </div>
          {!disableEdit && (
            <KeyboardArrowDown className={'fill-gray-800 text-lg'} />
          )}
        </div>
      )}
    </>
  )
}

export default TableSelectorEditor
