import moment from 'moment'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from 'src/components/user/UserIcon'
import { IMeeting } from 'src/service/OrgTypes'
import { getInfoWrapperStyle } from '../MUtils'

interface MMetingInfoProps {
  meeting: IMeeting
}

const MMetingInfo: FC<MMetingInfoProps> = ({ meeting }) => {
  const { t } = useTranslation()

  return (
    <div className={getInfoWrapperStyle(true, null, true, true)}>
      <div className={`w-full border-t-green-300 pl-2`}>
        <details className={'-ml-2 border border-gray-300 rounded-md py-1.5'}>
          <summary className={'font-semibold text-gray-600 text-xs px-3'}>{`${t(
            'meeting',
          )}`}</summary>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('meeting_number')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>{meeting.record_id}</div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>{t('title')}</div>
            <div className={'w-3/4 font-light pl-2'}>{meeting.title}</div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>{t('status')}</div>
            <div className={'w-3/4 font-light pl-2'}>{t(meeting.status)}</div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('description')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>{meeting.description}</div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('meeting_start')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>
              {moment(meeting.meetingDate).format('L')}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('meeting_place')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>
              {meeting.meetingPlace}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('meeting_chair')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>
              <UserIcon
                userId={meeting.meetingLeader?.id}
                firstName={meeting.meetingLeader?.firstName}
                lastName={meeting.meetingLeader?.lastName}
                image_url={meeting.meetingLeader?.image_url}
              />
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('referent')}
            </div>
            <div className={'w-3/4 font-light pl-2'}>
              <UserIcon
                userId={meeting.meetingRecorder?.id}
                firstName={meeting.meetingRecorder?.firstName}
                lastName={meeting.meetingRecorder?.lastName}
                image_url={meeting.meetingRecorder?.image_url}
              />
            </div>
          </div>
        </details>
      </div>
    </div>
  )
}

export default MMetingInfo
