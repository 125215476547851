import moment from 'moment'
import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import {
  getFilterValues,
  setFilterValues,
} from 'src/service/CanavasFilterService'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize, ButtonType } from 'src/ui-elements/button/ButtonEnums'
import { renderDayContents } from 'src/utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'

const styleClass = {
  root: (text: string) =>
    classNames(
      'bg-white',
      'px-6',
      'py-2',
      'border-b',
      'border-gray-200',
      'sm:px-6',
      text === 'placeholder' && 'py-2',
    ),
  title: classNames('text-sm', 'leading-6', 'font-medium', 'text-gray-900'),
  subTitle: (text: string) =>
    classNames(
      'mt-1',
      'text-sm',
      'leading-5',
      'text-gray-500',
      text === 'placeholder' && 'hidden',
    ),
  switches: {
    root: classNames('flex', 'items-center', 'inline-block'),
    container: classNames('items-center', 'inline-block', 'inline-flex'),
    text: classNames('text-sm', 'text-gray-400', 'pr-2', 'flex-nowrap'),
  },
}

export interface IProps {
  title: string
  subTitle?: string
  header?: React.ReactNode
  changeStatBasedOnTime?: (
    fetchDuration: boolean,
    startDate?: moment.Moment,
    endDate?: moment.Moment,
  ) => void
}

const InformationTitleComponent = ({
  title,
  subTitle,
  header,
  changeStatBasedOnTime,
}: IProps) => {
  const [filterByWeek, setFilterByWeek] = useState<boolean>(false)
  const [filterByMonth, setFilterByMonth] = useState<boolean>(false)
  const [filterByProjectPeriod, setFilterByProjectPeriod] =
    useState<boolean>(true)
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)
  const [focusedInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >(null)
  const filterByWeekRef = useRef(filterByWeek)
  const filterByMonthRef = useRef(filterByMonth)
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const initState = useRef({
    startDate,
    endDate,
    filterByWeek: false,
    filterByMonth: false,
  })

  const { t } = useTranslation()

  useEffect(() => {
    getFilterFromTableKeeper()
  }, [projectId])

  const getFilterFromTableKeeper = async () => {
    if (changeStatBasedOnTime) {
      const storedFilter = await getFilterValues(projectId, 'DashboardFilter')
      const filter: any =
        storedFilter && !Array.isArray(storedFilter)
          ? storedFilter
          : initState.current

      setStartDate(filter.startDate && moment(filter.startDate).utc())
      setEndDate(filter.endDate && moment(filter.endDate).utc())
      setFilterByWeek(filter.filterByWeek)
      setFilterByMonth(filter.filterByMonth)
      filterByWeekRef.current = filter.filterByWeek
      filterByMonthRef.current = filter.filterByMonth
      if (
        filter.startDate ||
        filter.endDate ||
        filter.filterByWeek ||
        filter.filterByMonth
      ) {
        setFilterByProjectPeriod(false)
      }
    }
  }

  const onDateRangeSelect = (range: any) => {
    if (changeStatBasedOnTime) {
      setFilterByProjectPeriod(false)
      setStartDate(range.startDate)
      setEndDate(range.endDate)
      changeStatBasedOnTime(
        true,
        moment(range.startDate),
        moment(range.endDate),
      )
      setFilterByWeek(false)
      setFilterByMonth(false)
      filterByWeekRef.current = false
      filterByMonthRef.current = false
      setFilterToTableKeeper({
        startDate: range.startDate,
        endDate: range.endDate,
        filterByWeek: false,
        filterByMonth: false,
      })
    }
  }

  const setFilterToTableKeeper = (filterData: any) => {
    const currentFilterValue = {
      startDate,
      endDate,
      filterByWeek,
      filterByMonth,
    }
    setFilterValues(projectId, 'DashboardFilter', {
      ...currentFilterValue,
      ...filterData,
    })
  }

  const showAllData = () => {
    if (changeStatBasedOnTime && !filterByProjectPeriod) {
      changeStatBasedOnTime(false)
      setStartDate(null)
      setEndDate(null)
      setFilterByWeek(false)
      setFilterByMonth(false)
      setFilterByProjectPeriod(true)
      filterByWeekRef.current = false
      filterByMonthRef.current = false
      setFilterToTableKeeper({
        startDate: null,
        endDate: null,
        filterByWeek: false,
        filterByMonth: false,
      })
    }
  }

  const changeMyOverViewDuration = (duration: string) => {
    if (duration === 'week') {
      filterByWeekRef.current = true
      setFilterByWeek(true)
      filterByMonthRef.current = false
      setFilterByMonth(false)
    } else {
      filterByWeekRef.current = false
      setFilterByWeek(false)
      filterByMonthRef.current = true
      setFilterByMonth(true)
    }

    if (
      (duration === 'week' || duration === 'month') &&
      changeStatBasedOnTime
    ) {
      setFilterByProjectPeriod(false)
      setStartDate(moment().utc().startOf(duration))
      setEndDate(moment().utc().endOf(duration))
      if (filterByWeekRef.current || filterByMonthRef.current) {
        changeStatBasedOnTime(
          true,
          moment().utc().startOf(duration),
          moment().utc().endOf(duration),
        )

        duration === 'week'
          ? setFilterToTableKeeper({
              startDate: moment().utc().startOf(duration),
              endDate: moment().utc().endOf(duration),
              filterByWeek: true,
              filterByMonth: false,
            })
          : setFilterToTableKeeper({
              startDate: moment().utc().startOf(duration),
              endDate: moment().utc().endOf(duration),
              filterByWeek: false,
              filterByMonth: true,
            })
      }
    }
  }

  return (
    <div className={styleClass.root(subTitle || '')}>
      <div className={styleClass.switches.root}>
        <h3 className={styleClass.title}>{capFirstLetter(title)}</h3>
        {header}
        {changeStatBasedOnTime && (
          <div className="flex flex-row flex-wrap items-center">
            <span className={`${styleClass.switches.container}`}>
              <Button
                onClick={() => showAllData()}
                size={ButtonSize.SMALL}
                type={
                  filterByProjectPeriod
                    ? ButtonType.PRIMARY
                    : ButtonType.SECONDARY
                }
                noTextWrap={true}
              >
                {t('project_duration')}
              </Button>
            </span>
            <span className={`${styleClass.switches.container}`}>
              <Button
                onClick={() => changeMyOverViewDuration('month')}
                size={ButtonSize.SMALL}
                type={filterByMonth ? ButtonType.PRIMARY : ButtonType.SECONDARY}
                noTextWrap={true}
              >
                {t('this_month')}
              </Button>
            </span>
            <span className={`${styleClass.switches.container}`}>
              <Button
                onClick={() => changeMyOverViewDuration('week')}
                size={ButtonSize.SMALL}
                type={filterByWeek ? ButtonType.PRIMARY : ButtonType.SECONDARY}
                noTextWrap={true}
              >
                {t('this_week')}
              </Button>
            </span>
            <span className={`${styleClass.switches.container}`}>
              <DateRangePicker
                startDatePlaceholderText={t('start_date')}
                endDatePlaceholderText={t('end_date')}
                startDate={startDate}
                startDateId="date-start"
                firstDayOfWeek={1}
                endDate={endDate}
                endDateId="date-end"
                onDatesChange={onDateRangeSelect}
                renderDayContents={renderDayContents}
                focusedInput={focusedInput}
                onFocusChange={(finput: 'startDate' | 'endDate' | null) =>
                  setFocusedInput(finput)
                }
                displayFormat={() =>
                  moment.localeData('no').postformat('DD.MM.YY')
                }
                hideKeyboardShortcutsPanel={true}
                showDefaultInputIcon={true}
                isOutsideRange={() => false}
                noBorder={true}
              />
            </span>
          </div>
        )}
      </div>
      {subTitle && (
        <p className={styleClass.subTitle(subTitle)}>
          {capFirstLetter(subTitle)}
        </p>
      )}
    </div>
  )
}

export default InformationTitleComponent
