import { ExportData } from 'src/context/ExportContext/ExportTypes'

export const testWorkGroupExportTemplate: ExportData = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/test_work_groups/export',
    method: 'POST',
  },
  name: 'Arbeidspakker',
  runInBg: true,
}
