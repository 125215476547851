import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { IMatrixCellConfig } from '../../service/OrgTypes'
import { createRiskMatrix } from '../../service/RiskService'
import Button from '../../ui-elements/button/Button'
import Spinner from '../../ui-elements/loader/Spinner'
import { classNames } from '../../utility/utils'
import Selector from '../selectors/Selector'

interface ICreateRiskMatrixFormProps {
  projectId: number
  closeModal: (matrix: any) => void
}

const RiskMatrixForm = ({
  projectId,
  closeModal,
}: ICreateRiskMatrixFormProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'mt-2'),
  }

  const columnsRows = [
    {
      id: '1',
      name: '2x2',
      rowSize: 2,
      columnSize: 2,
    },
    {
      id: '2',
      name: '3x3',
      rowSize: 3,
      columnSize: 3,
    },
    {
      id: '3',
      name: '3x4',
      rowSize: 3,
      columnSize: 4,
    },
    {
      id: '4',
      name: '4x3',
      rowSize: 4,
      columnSize: 3,
    },
    {
      id: '5',
      name: '4x4',
      rowSize: 4,
      columnSize: 4,
    },
    {
      id: '6',
      name: '4x5',
      rowSize: 4,
      columnSize: 5,
    },
    {
      id: '7',
      name: '5x4',
      rowSize: 5,
      columnSize: 4,
    },
    {
      id: '8',
      name: '5x5',
      rowSize: 5,
      columnSize: 5,
    },
  ]

  const [matrixId, setMatrixId] = useState<number>(0)
  const [rowSize, setRowSize] = useState<number>(0)
  const [columnSize, setColumnSize] = useState<number>(0)
  const [matrixErrorMessage, setMatrixErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false

    setLoading(true)
    if (columnSize <= 0 && rowSize <= 0) {
      setMatrixErrorMessage(t('select_matrix_size'))
      error = true
    }

    if (!error) {
      createRiskMatrix(
        {
          rowSize,
          columnSize,
          matrixPossibilityConfigData: createMatrixConfigData('possibility'),
          matrixThreatConfigData: createMatrixConfigData('threat'),
        },
        projectId,
      ).then((res: any) => {
        closeModal(res)
      })
    }

    setLoading(false)
  }

  const onMatrixSizeChange = (size: number) => {
    const matrixDimention = columnsRows.find((v) => v.id === size.toString())
    if (matrixDimention) {
      setRowSize(matrixDimention.rowSize)
      setColumnSize(matrixDimention.columnSize)
      setMatrixId(size)
      setMatrixErrorMessage('')
    }
  }

  const createMatrixConfigData = (type: string) => {
    const configData = new Array<IMatrixCellConfig>()
    const cellColor = type === 'threat' ? 'bg-green-two' : 'bg-blue-four'
    for (let i = 1; i <= columnSize; i++) {
      for (let j = 1; j <= rowSize; j++) {
        configData.push({
          id: `${i}${j}`,
          uncertaintyType: type,
          cValue: i,
          lValue: j,
          color: cellColor,
        })
      }
    }

    return configData
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={`${styleClass.inputGroup} pl-2`}>
        <Selector
          items={columnsRows}
          selectedItemId={matrixId}
          onSelect={onMatrixSizeChange}
          label={t('risk_matrix')}
          dataFields={['name']}
          required={true}
          fontSize={'sm'}
          fontWeight={'bold'}
          errorMessage={matrixErrorMessage}
        />
      </div>
      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        <Button
          type={Button.ButtonType.PRIMARY}
          disabled={loading ? true : false}
        >
          {loading ? <Spinner /> : t('add')}
        </Button>
      </ModalFooter>
    </form>
  )
}

export default RiskMatrixForm
