import Add from '@icons/add.svg'
import { capitalize, groupBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import SystemTaskTable from 'src/page/systematic-completion/SystematicCompletionTable/panels/components/SystemTaskTable'
import { IDiscipline, ISystemTask, SystemTaskTypes } from 'src/service/OrgTypes'
import { massCreateOrUpdateSystemTasks } from 'src/service/SystemService'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Loader from 'src/ui-elements/loader/Loader'

type SystemTaskSectionProps = {
  disciplines: IDiscipline[]
  existingSystemTasks: ISystemTask[]
  onUpdate: () => void
  showAddModal: () => void
}

const SystemTaskSection = ({
  disciplines,
  existingSystemTasks,
  onUpdate,
  showAddModal,
}: SystemTaskSectionProps) => {
  const { t } = useTranslation()
  const projectId = useContext(ProjectContext).state.currentProject.id

  const [systemTasks, setSystemTasks] =
    useState<ISystemTask[]>(existingSystemTasks)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setSystemTasks(existingSystemTasks)
  }, [existingSystemTasks])

  const editTask = (
    task_type: SystemTaskTypes,
    disciplineId: number,
    value: Partial<Omit<ISystemTask, 'id'>>,
  ) =>
    systemTasks.map((task) =>
      task.task_type === task_type && task.discipline_id === disciplineId
        ? { ...task, ...value }
        : task,
    )

  const updateSystemTasks = (systemTasks: ISystemTask[]) => {
    setSystemTasks(systemTasks)
    submitSystemTasks(systemTasks)
  }

  const deleteSystemTask = (systemTaskId: number) => {
    setSystemTasks(systemTasks.filter((task) => !(task.id === systemTaskId)))
    submitSystemTasks([], [systemTaskId])
  }

  const submitSystemTasks = async (
    systemTasks: ISystemTask[],
    systemTasksToDelete?: number[],
  ) => {
    setSubmitting(true)
    await massCreateOrUpdateSystemTasks(
      projectId,
      systemTasks,
      systemTasksToDelete,
    )
    onUpdate()
    setSubmitting(false)
  }

  return (
    <div className="min-h-95">
      <Button onClick={showAddModal} type={ButtonType.BOUNDARY_WHITE}>
        <Add className="fill-blue-500 mr-1" style={{ width: 20, height: 20 }} />
        {capitalize(t('add_new_tasks'))}
      </Button>
      {submitting && <Loader />}
      {Object.entries(groupBy(systemTasks, ({ task_type }) => task_type)).map(
        ([task_type, tasksOfTaskType]) => {
          const tasks = tasksOfTaskType
            .map((task, i) => {
              const discipline = disciplines.find(
                (discipline) => discipline.id === task.discipline_id,
              )
              if (!discipline) return undefined
              return { ...task, discipline, id: i + 1 }
            })
            .filter(
              (task): task is ISystemTask & { discipline: IDiscipline } =>
                !!task,
            )
          return (
            <SystemTaskTable
              key={task_type}
              task_type={task_type as SystemTaskTypes}
              tasks={tasks}
              toggleMainResponsible={(
                task_type: SystemTaskTypes,
                disciplineId: number,
                is_auxiliary: boolean,
              ) =>
                updateSystemTasks(
                  systemTasks.map((task) =>
                    task.task_type === task_type
                      ? {
                          ...task,
                          is_auxiliary:
                            task.discipline_id === disciplineId
                              ? is_auxiliary
                              : true,
                        }
                      : task,
                  ),
                )
              }
              setComment={(task_type, disciplineId, comment) =>
                setSystemTasks(editTask(task_type, disciplineId, { comment }))
              }
              submitComment={(task_type, disciplineId, comment) =>
                updateSystemTasks(
                  editTask(task_type, disciplineId, { comment }),
                )
              }
              deleteTask={(
                task_type: SystemTaskTypes,
                disciplineId: number,
              ) => {
                const task = systemTasks.find(
                  (task) =>
                    task.task_type === task_type &&
                    task.discipline_id === disciplineId,
                )
                if (!task) return
                deleteSystemTask(task.id)
              }}
            />
          )
        },
      )}
    </div>
  )
}

export default SystemTaskSection
