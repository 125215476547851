import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import useProjectId from 'src/components/hooks/useProjectId'
import { FileUploadContext } from 'src/document/components/FileUpload/context/FileUploadContext'
import {
  getCommentsForType,
  createComment,
  updateComment,
} from 'src/service/CommentService'
import { IComment } from 'src/service/OrgTypes'

export const useCommentsForType = (
  parentType: string,
  parentId: number,
  options?: { enabled?: boolean },
) =>
  useQuery({
    ...options,
    queryKey: ['comments', parentType, parentId],
    queryFn: () => getCommentsForType(parentType, parentId),
    refetchInterval: 5 * 60 * 1000,
  })

interface CommentProps<T> {
  comment: T
  newFiles?: File[]
}

export const useCreateComment = (parentType: string, parentId: number) => {
  const queryClient = useQueryClient()
  const projectId = useProjectId()
  const { addFilesToQueue } = useContext(FileUploadContext)

  return useMutation({
    mutationFn: ({ comment }: CommentProps<Partial<IComment>>) =>
      createComment(projectId, comment),
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ['comments', parentType, parentId],
      })
    },
    onSuccess: (newComment, { newFiles }) => {
      if (newFiles) {
        addFilesToQueue(newFiles, `parents/Comment/${newComment.id}/documents`)
      }
      queryClient.setQueryData(
        ['comments', parentType, parentId],
        (oldComments: IComment[]) => [
          {
            ...newComment,
            pendingFileNames: newFiles?.map(({ name }) => name),
          },
          ...oldComments,
        ],
      )
    },
  })
}

export const useUpdateComment = () => {
  const queryClient = useQueryClient()
  const { addFilesToQueue } = useContext(FileUploadContext)

  return useMutation({
    mutationFn: ({ comment }: CommentProps<IComment>) =>
      updateComment(comment, comment.id),
    onMutate: async ({ comment, newFiles }) => {
      const parentType = comment.parent_type
      const parentId = comment.parent_id

      await queryClient.cancelQueries({
        queryKey: ['comments', parentType, parentId],
      })

      if (newFiles) {
        addFilesToQueue(newFiles, `parents/Comment/${comment.id}/documents`)
      }

      queryClient.setQueryData(
        ['comments', parentType, parentId],
        (oldComments: IComment[]) =>
          oldComments.map((c: IComment) =>
            c.id === comment.id
              ? {
                  ...c,
                  ...{
                    ...comment,
                    pendingFileNames: newFiles?.map((f) => f.name),
                  },
                }
              : c,
          ),
      )
    },
    onError: (_, { comment }) => {
      queryClient.invalidateQueries({
        queryKey: ['comments', comment.parent_type, comment.parent_id],
      })
    },
  })
}
