import Search from '@icons/search.svg'
import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import Spinner from '../../ui-elements/loader/Spinner'
import { capFirstLetter, classNames } from '../../utility/utils'

interface IInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    WithTranslation {
  label?: string
  errorMessage?: string
  block?: boolean | undefined
  className?: string
  noPadding?: boolean
  isLoading?: boolean
  disabled?: boolean
  required?: boolean
  focus?: boolean
  showSearchIcon?: boolean
  hideLabel?: boolean
  login?: boolean
  inPage?: boolean
  autoFocus?: boolean
  minValue?: string | number
  noBorder?: boolean
  step?: string
  inMobile?: boolean
  bgColor?: string
  maxValue?: string | number
  inLine?: boolean
  textPadding?: string
}

interface IInputState {
  value: string | number | readonly string[]
}

class Input extends React.PureComponent<IInputProps, IInputState> {
  public static displayName = 'Input'

  private static styleClass = {
    root: (
      block: boolean | undefined,
      className: string | undefined,
      noPadding: boolean,
    ) =>
      classNames(
        block && !className ? 'md:w-full' : 'md:w-1/3',
        !noPadding ? 'px-2' : null,
        className ? className : null,
      ),
    input: (
      hasError: boolean,
      showSearchIcon: boolean,
      inPage: boolean,
      noBorder: boolean,
      bg: string,
      inLine: boolean,
      textPadding: string,
    ) =>
      classNames(
        'w-full',
        textPadding ? textPadding : 'p-2',
        showSearchIcon ? 'bg-bg-grey' : 'text-gray-800',
        noBorder ? '' : 'border border-gray-300',
        'rounded',
        'block',
        inLine ? 'sm:text-xs' : 'sm:text-sm',
        'sm:leading-4',
        inPage ? 'bg-gray-50 text-gray-600' : '',
        hasError ? 'border-red-300' : '',
        bg ? `bg-${bg}` : '',
      ),
    label: (login: boolean, mobile: boolean) =>
      classNames(
        'block',
        'font-medium',
        mobile ? 'text-xs' : 'text-sm',
        'leading-5',
        login ? 'text-white' : mobile ? 'text-gray-500' : 'text-gray-700',
        'my-2',
      ),
    errorMessage: (mobile: boolean) =>
      classNames(
        'flex',
        'items-center',
        'text-red-600',
        'ml-2',
        mobile ? 'text-xs' : 'text-sm',
        'font-normal',
      ),
  }

  private inputRef: React.RefObject<HTMLInputElement>

  constructor(props: IInputProps) {
    super(props)
    const value = props.value === 0 ? 0 : props.value ? props.value : ''
    this.state = {
      value,
    }
    this.inputRef = React.createRef()
  }

  public componentDidUpdate(_prevProps: IInputProps) {
    if (this.props.focus && this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  public UNSAFE_componentWillReceiveProps(props: IInputProps) {
    const value = props.value === 0 ? 0 : props.value ? props.value : ''

    if (
      this.props.type &&
      this.props.type === 'number' &&
      value &&
      +value < 0
    ) {
      this.setState({ value: Math.abs(+value) })
      return
    }

    this.setState({
      value,
    })
  }

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    const { onChange } = this.props

    if (onChange) {
      onChange(event)
    }

    if (
      this.props.type &&
      this.props.type === 'number' &&
      +event.target.value < 0
    ) {
      this.setState({ value: Math.abs(+event.target.value) })
      return
    }

    this.setState({ value: event.target.value })
  }

  private searchIconClass = {
    position: 'relative',
    backgroundImage: `url(/icons/search-gray.svg)`,
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'content-box',
    paddingRight: '7px',
    maxHeight: 34,
    fontSize: 13,
    borderRadius: 8,
    borderColor: '#E7ECF3',
    borderWidth: 1,
  }

  private convertToLowerCase(str: string) {
    if (str) {
      return str.toLocaleLowerCase()
    }
    return ''
  }

  public render() {
    const {
      label,
      errorMessage,
      isLoading,
      disabled,
      noPadding = false,
      block,
      placeholder,
      className,
      required,
      showSearchIcon = false,
      hideLabel,
      login,
      inPage = false,
      autoFocus = false,
      minValue,
      step,
      t,
      inMobile = false,
      noBorder = false,
      bgColor = '',
      maxValue,
      inLine = false,
      textPadding = '',
      style,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tReady, // unused var to exclude from ...rest. It'll give an unrecognized warning if included in rest. https://taskctrl.atlassian.net/browse/TAS-2853
      ...rest
    } = this.props

    const { value } = this.state
    const hasError = !!(errorMessage && errorMessage !== '')
    const mobileStyles = {
      maxHeight: 34,
      fontSize: 13,
      borderRadius: 8,
      borderColor: '#E7ECF3',
      borderWidth: 1,
    }

    return (
      <div className={Input.styleClass.root(block, className, noPadding)}>
        <div className={'flex flex-row'}>
          {!hideLabel && label && (
            <label className={Input.styleClass.label(login || false, inMobile)}>
              {capFirstLetter(label)}
              {required ? ' *' : ''}
            </label>
          )}
          {hasError && (
            <p className={Input.styleClass.errorMessage(inMobile)}>
              {errorMessage}
            </p>
          )}
        </div>
        <div className={'rounded flex items-center bg-bg-grey w-full'}>
          {showSearchIcon && (
            <Search className={'fill-blue-root ml-2 text-lg'} />
          )}
          <input
            {...rest}
            value={value}
            className={Input.styleClass.input(
              hasError,
              showSearchIcon,
              inPage,
              noBorder,
              bgColor,
              inLine,
              textPadding,
            )}
            placeholder={
              placeholder ??
              `${capFirstLetter(t('write_in'))} ` +
                this.convertToLowerCase(label || '')
            }
            disabled={isLoading || disabled}
            onChange={this.onChange}
            ref={this.inputRef}
            style={{
              ...(showSearchIcon && !value
                ? this.searchIconClass
                : inMobile
                  ? mobileStyles
                  : { maxHeight: 34 },
              { ...style }),
            }}
            autoFocus={autoFocus}
            min={minValue}
            max={maxValue}
            step={step}
          />
        </div>
        {isLoading && <Spinner />}
      </div>
    )
  }
}

export default withTranslation()(Input)
