import { Component } from 'react'
import { ITableInputState, TableInputContext } from './TableInputContext'

export default class TableInputProvider extends Component<
  any,
  ITableInputState
> {
  public constructor(props: any, context?: any) {
    super(props, context)
    this.state = {
      key: -1,
      filed: '',
    }
  }

  private updateFiled = (key: number, filed: string) => {
    this.setState({ key, filed })
  }

  private clearSelectedFileds = () => {
    this.setState({
      key: -1,
      filed: '',
    })
  }

  public render() {
    const { state } = this
    const actions = {
      updateFiled: (key: number, filed: string) => this.updateFiled(key, filed),
      clearSelectedFileds: this.clearSelectedFileds,
    }
    const context = {
      actions,
      state,
    }
    return (
      <TableInputContext.Provider value={context}>
        {this.props.children}
      </TableInputContext.Provider>
    )
  }
}
