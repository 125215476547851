import moment from 'moment'
import {
  createRef,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { ItemSelectContext } from 'src/context/ItemSelectContext/ItemSelectContext'
import DependenciesDeleteModal, {
  CanvasDeleteModalType,
} from 'src/page/delivery/DependenciesDeleteModal'
import { IKeypoint } from 'src/service/OrgTypes'
import useWindowDimensions from 'src/ui-elements/page-display/UseWindowDimensions'
import {
  canvasRootStyles,
  canvasSubTitleStyles,
  canvasTitleStyles,
  getHoverItemPosition,
  IHoverPos,
} from 'src/utility/Utility'
import {
  arrayEmpty,
  arrayHasValues,
  capFirstLetter,
  classNames,
} from '../../utility/utils'
import ContextMenu, {
  IContextMenuItem,
  IGroupContextMenu,
} from '../context-menu/ContextMenu'
import {
  dependentOnOthersIcon,
  othersDependOnThisIcon,
  twoWayDependencyIcon,
} from '../dependency-control/DependencyIcon'
import useLongPress from '../hooks/onLongPress'
import Checkbox from '../switchHoc/CheckBox'

interface IProcessTimelineItemProps {
  item: any
  getItemProps: any
  openModal: (item: { data_type: string; id: number }) => Promise<void>
  itemContext: any
  itemSelected: boolean
  timelineContext: any
  addDependencyToItem: any
  showChildren: (item: any, dependencyType: ProcessTimelineDependency) => void
  copyItem: (item: any) => void
  deleteItem: (item: any) => void
  updateItem: (item: any, type: string) => void
  addDependency: (item: any) => void
  reload: (dataRefresh: boolean) => void
}

const styleClass = {
  root: classNames('overflow-hidden'),
  mainRoot: classNames(
    'border-solid',
    'text-left',
    'flex',
    'flex-col',
    'text-wrap',
    'text-xxs',
    'bg-gray-50',
    'hover:bg-gray-100',
    'focus:bg-gray-100',
    'break-words',
    'cursor-pointer',
    'relative',
    'text-gray-800',
    'border-b-4',
  ),
  statsColor: (
    status: string,
    expired: moment.Moment,
    deliveryExpired: boolean,
  ) =>
    twMerge(
      moment(expired).isBefore(moment(), 'day') && status !== 'done'
        ? 'bg-red-four'
        : '',
      status === 'done' ? 'bg-green' : '',
      moment(expired).isSameOrAfter(moment(), 'day') && status !== 'done'
        ? 'bg-white'
        : '',
      deliveryExpired ? 'bg-yellow-300' : '',
    ),
  title: classNames('text-sm', 'font-semibold', 'px-1', 'truncate', 'pt-1'),
  subTitle: classNames('px-1', 'py-px', 'truncate', 'text-xs'),
  floatLabel: classNames(
    'fixed',
    'bg-white',
    'text-sm',
    'shadow',
    'p-3',
    'flex',
    'z-20',
    'flex-col',
  ),
  floatLabelSmallText: classNames('text-xxs', 'py-1'),
  floatConditionText: (expired: boolean) =>
    classNames('text-xxs', 'py-1', expired ? 'text-red-two' : ''),
  changes: (type: string) =>
    classNames(
      'absolute',
      'text-xxs',
      'text-white',
      'z-40',
      'text-center',
      'font-semibold',
      'text-xss',
      type === 'KeyPoint' && 'w-5p pt-px',
      type === 'Delivery' && 'w-3p pb-0.5',
    ),
}

/**
 * DOCS
 *  HORIZONTAL = past dependecies
    DEPENDENT_ON = future dependents
    VERTICAL = deps across keypoint -> delivery -> task
 */

export enum ProcessTimelineDependency {
  VERTICAL,
  HORIZONTAL,
  DEPENDENT_ON,
  ALL_DEPEDENCIES,
}

const ProcessTimelineItem = ({
  item,
  getItemProps,
  openModal,
  itemContext,
  itemSelected,
  timelineContext,
  showChildren,
  copyItem,
  updateItem,
  deleteItem,
  addDependency,
  addDependencyToItem,
  reload,
}: IProcessTimelineItemProps) => {
  const { t } = useTranslation()
  const longPress = useLongPress(
    (e: any) => showMenu(e),
    500,
    (e: any) => onSelected(e),
  )
  const [showLabel, setShowLabel] = useState<boolean>(false)
  const [showHoverCard, setShowHoverCard] = useState<boolean>(false)
  const [floatLabel, setFloatLabel] = useState<IHoverPos>({
    left: 0,
    right: undefined,
    top: 0,
    bottom: undefined,
  })
  const timer = useRef({} as any)
  const [selectedLabel, setSelectedLabel] = useState<number>(-1)

  const itemSelectContext = useContext(ItemSelectContext)
  const { width: vw, height: vh } = useWindowDimensions()

  const [showKeypontDepModal, setShowKeypointDepModal] = useState(false)
  const [showDeliveryDepModal, setShowDeliveryDepModal] = useState(false)
  const { selectMode, multiSelectMode, selectParent } = itemSelectContext.state
  const {
    addToSelectedItems,
    removeSelectedItem,
    itemSelected: singleSelected,
    itemMultiSelected,
    toggleMultiSelectModeOn,
  } = itemSelectContext.actions

  const ref = useRef(createRef<HTMLDivElement>())

  const st = moment(timelineContext.visibleTimeStart)
  const et = moment(timelineContext.visibleTimeEnd)
  const { width } = itemContext.dimensions
  const midWidth = width > 100

  const endTime = item.endTime ? item.endTime : item.deadline

  const expiredChildren = (): boolean => {
    return (
      (item.expired_children !== undefined && item.expired_children > 0) ||
      (item.behind_schedule_children !== undefined &&
        item.behind_schedule_children > 0)
    )
  }

  const getIcon = (
    type: string,
    status: string,
    expired: moment.Moment,
    deliveryExpired: boolean,
  ) => {
    const color =
      moment(expired).isBefore(moment(), 'day') && status !== 'done'
        ? ''
        : status === 'done'
          ? '-green'
          : deliveryExpired
            ? '-yellow'
            : moment(expired).isSameOrAfter(moment(), 'day') &&
                status !== 'done'
              ? '-black'
              : '-none'
    switch (type) {
      case 'MileStone':
        return `triangle-down${color}`
      case 'KeyPoint':
        return `diamond${color} transform scale-110`
      case 'Delivery':
        return `circle${color} transform scale-110`
      case 'Task':
        return `triangle-right${color}`
      default:
        return `${color}`
    }
  }

  const getChildrenStat = (type: string) => {
    switch (type) {
      case 'MileStone':
        return `${capFirstLetter(t('keypoints'))}: ${item.done_children}/${
          item.done_children + item.open_children
        }`
      case 'KeyPoint':
        return `${capFirstLetter(t('deliveries'))}: ${item.done_children}/${
          item.done_children + item.open_children
        }`
      case 'Delivery':
        return `${capFirstLetter(t('tasks'))}: ${item.done_children}/${
          item.done_children + item.open_children
        }`
      case 'Task':
        return `${capFirstLetter(t('deadline'))}: ${moment(
          item.deadline,
        ).format('L')}`
      default:
        return null
    }
  }

  const getTypeInNorsk = (type: string, level: string) => {
    switch (type) {
      case 'MileStone':
        return t('milestone')
      case 'KeyPoint':
        return t('keypoint')
      case 'Delivery':
        return t('delivery')
      case 'Task':
        return t('task')
      case 'Risk':
        return t('risk')
      default:
        return level
    }
  }

  const showMenu = (_e: any) => {
    if (selectMode) {
      return
    }
    const target = ref.current.current?.getBoundingClientRect()
    const tmpFloatLabel = { ...floatLabel }
    tmpFloatLabel.left = target?.x + itemContext.width
    tmpFloatLabel.top = target?.top
    setShowLabel(true)
    setFloatLabel(tmpFloatLabel)
    setSelectedLabel(-1)
  }

  const onMouseEnter = (e: any) => {
    if (itemContext.dragging || selectMode) {
      return
    }
    const itemRect = ref?.current?.current?.getBoundingClientRect()
    if (itemRect) {
      const tmpFloatLabel = getHoverItemPosition(
        floatLabel,
        e,
        itemRect,
        vw,
        vh,
        itemContext,
      )
      timer.current = setTimeout(() => {
        setShowHoverCard(true)
        setFloatLabel(tmpFloatLabel)
      }, 750)
      setSelectedLabel(item.id)
    }
  }

  const onMouseLeave = () => {
    clearTimeout(timer.current)
    setShowHoverCard(false)
  }

  const allowedParentTypes = ['Delivery', 'KeyPoint', 'MileStone', 'Risk']

  const showSelf = () => {
    const { item_id, type } = item
    if (item_id && type) {
      openModal({ data_type: type, id: item_id })
    }
  }

  const showParent = () => {
    const { parent_id, parent_type } = item
    if (parent_type && parent) {
      openModal({ data_type: parent_type, id: parent_id })
    }
  }

  const getParentTitle = () => {
    if (item.type === 'KeyPoint' && item.parent_type !== 'MileStone') {
      return t('show_milestone_details_keypoint_is_not_linked_to_any_milestone')
    }
    return t('show_details_w_param', {
      param: getTypeInNorsk(item.parent_type, t('parents')),
    })
  }

  const contextMenu: IGroupContextMenu[] = [
    {
      groupId: 'showDetails',
      menus: [
        {
          title: t('show_details_w_param', {
            param: getTypeInNorsk(item.type, t('self-')),
          }),
          active: true,
          onItemClick: () => showSelf(),
        },
        {
          title: getParentTitle(),
          active: allowedParentTypes.includes(item.parent_type),
          onItemClick: () => showParent(),
          hidden: item.type === 'MileStone',
        },
      ],
    },
    {
      groupId: 'showDependencies',
      menus: [
        {
          title: t('show_items_this_depends_on', {
            param: item.dependent_ids && item.dependent_ids.length,
          }),
          active: item.dependent_ids && item.dependent_ids.length > 0,
          onItemClick: () =>
            showChildren(item, ProcessTimelineDependency.HORIZONTAL),
          disableMessage: t('the_element_is_not_dependent_on_other_elements'),
        },
        {
          title: t('view_items_that_depend_on_this', {
            param: item.dependent_on_ids && item.dependent_on_ids.length,
          }),
          active: item.dependent_on_ids && item.dependent_on_ids.length > 0,
          onItemClick: () =>
            showChildren(item, ProcessTimelineDependency.DEPENDENT_ON),
          disableMessage: t('no_items_that_depend_on_this'),
        },
        {
          title: t('view_all_dependency_items', {
            param: `${Number(
              item.dependent_on_ids?.length + item.dependent_ids?.length,
            )}`,
          }),
          active: item.dependent_on_ids?.length || item.dependent_ids?.length,
          onItemClick: () =>
            showChildren(item, ProcessTimelineDependency.ALL_DEPEDENCIES),
          disableMessage: t('no_items_found'),
        },
        {
          title: t('show_underlying_items', {
            param: item.open_children + item.done_children,
          }),
          active: item.open_children + item.done_children > 0,
          onItemClick: () =>
            showChildren(item, ProcessTimelineDependency.VERTICAL),
          disableMessage: t('the_element_has_no_underlying_elements'),
        },
        {
          title: t('delete_dependencies'),
          active:
            arrayHasValues(item.dependent_on_ids) ||
            arrayHasValues(item.dependent_ids),
          onItemClick: () => {
            item.type === 'KeyPoint'
              ? setShowKeypointDepModal(true)
              : setShowDeliveryDepModal(true)
          },
          hidden: !(item.type === 'KeyPoint' || item.type === 'Delivery'),
        },
      ],
    },
    {
      groupId: 'setToComplete',
      menus: [
        {
          title: t('set_status_to_completed'),
          active: item.status !== 'done' && item.open_children === 0,
          onItemClick: () =>
            updateItem({ id: item.id, status: 'done' }, item.type),
        },
      ],
    },
    {
      groupId: 'cloneItem',
      menus: [
        {
          title: t('copy'),
          active: true,
          onItemClick: () => copyItem(item),
        },
        {
          title: t('delete'),
          active: true,
          onItemClick: () => deleteItem(item),
        },
      ],
    },
  ]

  const dependencyItem: IContextMenuItem = {
    title: t('add_dependencies'),
    active: item.status !== 'done',
    onItemClick: () => addDependency(item),
    isNew: true,
    disableMessage: t('dependencies_cant_be_added_on_closed_items'),
  }

  const title = item.title

  const renderHoverCard = () => (
    <div
      className={styleClass.floatLabel}
      style={{
        left: floatLabel.left,
        right: floatLabel.right,
        top: floatLabel.top,
        bottom: floatLabel.bottom,
      }}
    >
      <span className={'float-left'}>
        {item.record_id}: {title}
      </span>
      {item.type !== 'Task' && (
        <div className={styleClass.floatLabelSmallText}>
          {`${capFirstLetter(t('discipline'))}: ${
            item.discipline ? item.discipline.name : ''
          }`}
        </div>
      )}
      {item.type === 'KeyPoint' && item.mile_stone && (
        <div className={styleClass.floatLabelSmallText}>
          {`${capFirstLetter(t('milestone'))}: ${
            item.mile_stone.record_id ? item.mile_stone.record_id : ''
          } ${item.mile_stone.name}`}
        </div>
      )}
      <div className={styleClass.floatLabelSmallText}>
        {`${capFirstLetter(t('responsible'))}: ${
          item.responsible
            ? item.responsible.firstName + ' ' + item.responsible.lastName
            : ''
        }`}
      </div>
      {item.type === 'MileStone' ||
      item.type === 'KeyPoint' ||
      item.type === 'Delivery' ? (
        <div className={styleClass.floatConditionText(item.open_children > 0)}>
          {item.type === 'KeyPoint'
            ? `${capFirstLetter(t('deliveries'))}:`
            : item.type === 'MileStone'
              ? `${capFirstLetter(t('keypoints'))}:`
              : `${capFirstLetter(t('tasks'))}:`}{' '}
          {`${item.done_children}/${item.done_children + item.open_children}`}
        </div>
      ) : null}
      <div className={styleClass.floatLabelSmallText}>
        {capFirstLetter(t('deadline'))}
        {': '}
        {item.type === 'MileStone' || item.type === 'Task'
          ? item.deadline
            ? moment(item.deadline).format('L')
            : ''
          : item.endTime
            ? moment(item.endTime).format('L')
            : ''}
      </div>
      {(item.type === 'KeyPoint' || item.type === 'Delivery') && (
        <>
          <div className={styleClass.floatLabelSmallText}>
            {t('the_number_of_elements_this_depends_on')}:{' '}
            {item.dependent_ids ? item.dependent_ids.length : ''}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {t('number_of_items_that_depend_on_this')}:{' '}
            {item.dependent_on_ids ? item.dependent_on_ids.length : ''}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {t('number_of_underlying_elements')}:{' '}
            {item.open_children + item.done_children}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {t('number_of_deadline_changes')}: {item.changes ? item.changes : 0}
          </div>
        </>
      )}
    </div>
  )

  const showSelectBox = (): boolean => {
    return (
      et.diff(st) < 14000000000 && selectMode && selectParent !== item.item_id
    )
  }

  const dependencyType = useMemo(() => {
    const depItemDeadline = addDependencyToItem?.end_time
      ? addDependencyToItem?.end_time
      : addDependencyToItem?.endTime
        ? addDependencyToItem?.endTime
        : undefined
    return moment(item.endTime).isSameOrBefore(moment(depItemDeadline), 'day')
      ? 'past'
      : 'future'
  }, [addDependencyToItem, item.endTime])

  const handleSelectToggle = (selected: boolean) => {
    if (!selected) {
      addToSelectedItems(
        item.item_id,
        dependencyType,
        multiSelectMode ? item : undefined,
      )
    } else {
      removeSelectedItem(
        item.item_id,
        dependencyType,
        multiSelectMode ? item : undefined,
      )
    }
  }

  const idSelected = (id: number) =>
    multiSelectMode ? itemMultiSelected(id) : singleSelected(id)

  const onSelected = (e: any) => {
    e?.shiftKey && toggleMultiSelectModeOn()
    if (multiSelectMode || (selectMode && selectParent !== item.item_id)) {
      handleSelectToggle(idSelected(item.item_id))
    }
  }

  const getContextMenu = (): IGroupContextMenu[] => {
    if (item.type === 'KeyPoint' || item.type === 'Delivery') {
      const groupContextMenu = [...contextMenu]
      groupContextMenu[1].menus.push(dependencyItem)
      return groupContextMenu
    }
    return contextMenu
  }

  const showParentOnlyInSelect = () =>
    selectMode ? item.item_id === selectParent : true

  const showDependencies = (): boolean => {
    return (
      midWidth &&
      arrayHasValues(item.dependent_ids) &&
      arrayEmpty(item.dependent_on_ids) &&
      showParentOnlyInSelect()
    )
  }

  const showDependents = (): boolean => {
    return (
      midWidth &&
      arrayHasValues(item.dependent_on_ids) &&
      arrayEmpty(item.dependent_ids) &&
      showParentOnlyInSelect()
    )
  }

  const showTwoWayDependency = (): boolean => {
    return (
      midWidth &&
      arrayHasValues(item.dependent_on_ids) &&
      arrayHasValues(item.dependent_ids) &&
      showParentOnlyInSelect()
    )
  }

  const getRootItemStyles = useCallback(() => {
    const bg =
      !selectMode || idSelected(item.item_id) || itemSelected
        ? ''
        : 'transparent'
    const opacity =
      !selectMode ||
      idSelected(item.item_id) ||
      itemSelected ||
      selectParent === item.item_id
        ? 1
        : 0.5
    return {
      style: {
        color: item.color,
        zIndex: 15,
        minWidth: '20px',
        border: itemSelected ? '2px solid #269bf7' : '#fffffe 2px solid',
        opacity: opacity,
        background: bg,
      },
    }
  }, [selectMode, idSelected(item.item_id), itemSelected])

  return (
    <>
      <div
        onMouseEnter={(e) => onMouseEnter(e)}
        onMouseLeave={onMouseLeave}
        className={'flex flex-col'}
      >
        <div
          className={itemSelected ? 'shadow-inner' : ''}
          {...getItemProps(getRootItemStyles())}
        >
          <div
            id={`${item.id}`}
            onContextMenu={showMenu}
            ref={ref.current}
            {...longPress}
            onDoubleClick={() => showSelf()}
            className={styleClass.mainRoot}
            style={canvasRootStyles(
              item.discipline?.color,
              itemContext.dimensions.height,
            )}
          >
            <div className={styleClass.root} id={`${item.type}-${item.id}`}>
              <div
                style={canvasTitleStyles(item.discipline?.color)}
                className={styleClass.title}
              >
                {title}
              </div>
              <p
                style={canvasSubTitleStyles(item.discipline?.color)}
                className={styleClass.subTitle}
              >
                {capFirstLetter(t('responsible'))}:{' '}
                {item.responsible
                  ? item.responsible.firstName + ' ' + item.responsible.lastName
                  : ''}
              </p>
              <p
                style={canvasSubTitleStyles(item.discipline?.color)}
                className={styleClass.subTitle}
              >
                {getChildrenStat(item.type)}
              </p>
            </div>
            <div
              className={'flex flex-row items-start pt-1 pb-0.5'}
              style={canvasSubTitleStyles(item.discipline?.color)}
            >
              <span
                className={`${
                  et.diff(st) < 30000000000
                    ? 'flex flex-shrink pl-0.5 pr-1 pb-0.5 w-5p h-5'
                    : ''
                }`}
              >
                <div className={'px-0.5 relative'}>
                  {(item.type === 'KeyPoint' || item.type === 'Delivery') && (
                    <div className={styleClass.changes(item.type)}>
                      {item.changes ? item.changes : ''}
                    </div>
                  )}
                  <div
                    style={{ top: item.type === 'KeyPoint' ? '-11px' : '' }}
                    className={`${getIcon(
                      item.type,
                      item.status,
                      moment(endTime),
                      expiredChildren(),
                    )}`}
                  />
                </div>
              </span>
              <p className={'text-gray-800 font-semibold pl-2 truncate'}>
                {item.record_id}
              </p>
              {showDependencies() && dependentOnOthersIcon()}
              {showDependents() && othersDependOnThisIcon()}
              {showTwoWayDependency() && twoWayDependencyIcon()}
              {showSelectBox() && (
                <span className={'ml-auto mr-2'}>
                  <Checkbox
                    valueProp={idSelected(item.item_id)}
                    onChange={() => {}}
                    readonly
                  />
                </span>
              )}
            </div>
          </div>
        </div>
        {showHoverCard && selectedLabel === item.id && renderHoverCard()}
      </div>
      <ContextMenu
        show={showLabel}
        pos={floatLabel}
        groupedMenu={getContextMenu()}
        menus={[]}
        closeMenu={() => setShowLabel(false)}
      />
      {showDeliveryDepModal && (
        <DependenciesDeleteModal
          itemType={CanvasDeleteModalType.Delivery}
          data={item as IKeypoint}
          onClose={() => setShowDeliveryDepModal(false)}
          onSubmit={() => {
            setShowDeliveryDepModal(false)
            reload(false)
          }}
        />
      )}
      {showKeypontDepModal && (
        <DependenciesDeleteModal
          itemType={CanvasDeleteModalType.Keypoint}
          data={item}
          onClose={() => setShowKeypointDepModal(false)}
          onSubmit={() => {
            setShowKeypointDepModal(false)
            reload(false)
          }}
        />
      )}
    </>
  )
}

export default ProcessTimelineItem
