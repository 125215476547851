import { Component } from 'react'
import { breadCrumbRequest } from '../../service/BreadCrumService'
import {
  BreadCrumbContext,
  IBreadCrumbContext,
  IBreadCrumbContextProps,
  IBreadCrumbContextState,
  ITitleId,
} from './BreadCrumbContext'

class BreadCrumbContextProvider extends Component<
  IBreadCrumbContextProps,
  IBreadCrumbContextState
> {
  public constructor(
    props: IBreadCrumbContextProps,
    context?: IBreadCrumbContextState,
  ) {
    super(props, context)
    this.state = {
      maxDepth: props.maxDepth,
      currentDepth: 0,
      crumbs: [],
      module: props.module,
    }
  }

  public componentDidUpdate(prevProps: IBreadCrumbContextProps) {
    if (prevProps.module !== this.props.module) {
      this.setState({
        module: this.props.module,
        maxDepth: this.props.maxDepth,
        currentDepth: 0,
        crumbs: [],
      })
    }
  }

  private updateCurrentDepth = (currentDepth: number) => {
    this.setState({
      currentDepth,
    })
  }

  private setItem = (i: number, item: ITitleId) => {
    const cloneData = [...this.state.crumbs]
    cloneData[i] = item
    this.setState({
      crumbs: cloneData,
    })
  }

  private setItems = (items: any[]) => {
    const cloneData: ITitleId[] = items.map((item) => {
      return {
        title:
          item.title ||
          item.name ||
          item.room_name ||
          item.test_number ||
          item.test_case_name,
        id: item.id,
        recordId: item.record_id,
        modelType: item.model_type,
      }
    })
    this.setState({
      crumbs: cloneData,
      currentDepth:
        items.length > this.props.maxDepth ? this.props.maxDepth : items.length,
    })
  }

  private setCrums = async (projectId: number, url: string) => {
    const bread = await breadCrumbRequest(projectId, url)
    this.setItems(bread)
  }

  public render() {
    const { state } = this
    const actions = {
      setCurrentDepth: this.updateCurrentDepth,
      updateItem: this.setItem,
      setCrums: this.setCrums,
    }

    const context: IBreadCrumbContext = {
      actions,
      state,
    }

    return (
      <BreadCrumbContext.Provider value={context}>
        {this.state.crumbs && this.props.children}
      </BreadCrumbContext.Provider>
    )
  }
}

export default BreadCrumbContextProvider
