import _ from 'lodash'
import { useState } from 'react'
import { IDelivery } from 'src/service/OrgTypes'
import { IItemSelectContextState, ItemSelectContext } from './ItemSelectContext'

const ItemSelectContextProvider = (props: any) => {
  const [state, setState] = useState<IItemSelectContextState>({
    selectedItemsId: [],
    multiSelectedDeliveries: [],
    pastDependencies: [],
    futureDependencies: [],
    selectMode: false,
    selectParent: -1,
    multiSelectMode: false,
  })

  const addToSelectedItems = (
    id: number,
    dependency?: 'past' | 'future',
    multiSelectDelivery?: IDelivery,
  ) => {
    setState((prevState) => {
      const stateClone = { ...prevState }
      if (multiSelectDelivery) {
        stateClone.multiSelectedDeliveries.push(multiSelectDelivery)
      }

      if (dependency === 'past' && !stateClone.pastDependencies.includes(id)) {
        stateClone.pastDependencies.push(id)
      }
      if (
        dependency === 'future' &&
        !stateClone.futureDependencies.includes(id)
      ) {
        stateClone.futureDependencies.push(id)
      }

      if (!stateClone.selectedItemsId.includes(id)) {
        stateClone.selectedItemsId = [...stateClone.selectedItemsId, id]
      }

      return stateClone
    })
  }

  const removeSelectedItem = (
    id: number,
    dependency?: 'past' | 'future',
    multiSelectDelivery?: IDelivery,
  ) => {
    setState((prevState) => {
      const stateClone = { ...prevState }

      if (multiSelectDelivery) {
        const newState = stateClone.multiSelectedDeliveries.filter(
          (item) => item.id !== multiSelectDelivery.id,
        )
        stateClone.multiSelectedDeliveries = newState
      }

      if (dependency === 'past' && stateClone.pastDependencies.includes(id)) {
        stateClone.pastDependencies.splice(
          stateClone.pastDependencies.indexOf(id),
          1,
        )
      }
      if (
        dependency === 'future' &&
        stateClone.futureDependencies.includes(id)
      ) {
        stateClone.futureDependencies.splice(
          stateClone.futureDependencies.indexOf(id),
          1,
        )
      }

      if (stateClone.selectedItemsId.includes(id)) {
        stateClone.selectedItemsId.splice(
          stateClone.selectedItemsId.indexOf(id),
          1,
        )
      }
      return stateClone
    })
  }

  const toggleSelectedModeOn = (
    itemId: number,
    pastDepencies: number[],
    futureDepencies?: number[],
  ) => {
    const stateClone = { ...state }
    stateClone.selectMode = true
    stateClone.selectParent = itemId
    stateClone.selectedItemsId = [...pastDepencies]
    if (futureDepencies?.length) {
      stateClone.selectedItemsId = _.uniq(
        stateClone.selectedItemsId.concat(futureDepencies),
      )
      stateClone.futureDependencies = futureDepencies
    }
    stateClone.pastDependencies = pastDepencies
    setState(stateClone)
  }

  const toggleSelectedModeOff = () => {
    const stateClone = { ...state }
    stateClone.selectMode = false
    stateClone.selectParent = 0
    stateClone.selectedItemsId.length = 0
    stateClone.pastDependencies.length = 0
    stateClone.futureDependencies.length = 0
    setState(stateClone)
  }

  const toggleMultiSelectModeOn = () => {
    const stateClone = { ...state }
    stateClone.multiSelectMode = true
    setState(stateClone)
  }

  const toggleMultiSelectModeOff = () => {
    const stateClone = { ...state }
    stateClone.multiSelectMode = false
    stateClone.multiSelectedDeliveries.length = 0
    setState(stateClone)
  }

  const itemSelected = (itemId: number): boolean => {
    return state.selectedItemsId.indexOf(itemId) > -1
  }

  const itemMultiSelected = (itemId: number): boolean => {
    return state.multiSelectedDeliveries.findIndex((d) => d.id === itemId) > -1
  }

  const actions = {
    addToSelectedItems,
    removeSelectedItem,
    itemMultiSelected,
    toggleSelectedModeOn,
    toggleSelectedModeOff,
    itemSelected,
    toggleMultiSelectModeOn,
    toggleMultiSelectModeOff,
  }

  const context = {
    actions,
    state,
  }

  return (
    <ItemSelectContext.Provider value={context}>
      {props.children}
    </ItemSelectContext.Provider>
  )
}

export default ItemSelectContextProvider
