import { FC } from 'react'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import UploadStatus from './Status/UploadStatus'
import { formatBytes } from './util'

interface IFileUploadingListsProps {
  files: File[]
}

const FileUploadingLists: FC<IFileUploadingListsProps> = ({ files }) => {
  const columns: IListColumns[] = [
    {
      name: 'name',
      size: '400',
      id: 'name',
      dataField: 'name',
      cell: (name: string) => {
        return <span>{name}</span>
      },
    },
    {
      name: 'type',
      size: '200',
      id: 'type',
      dataField: 'type',
      cell: (type: string) => {
        return <span>{type}</span>
      },
    },
    {
      name: 'File Size',
      size: '200',
      id: 'size',
      dataField: 'size',
      cell: (size: number) => {
        return <span>{formatBytes(size)}</span>
      },
    },
    {
      name: 'Status',
      size: '350',
      id: 'status',
      dataField: 'name',
      cell: (name: any, _: number) => {
        const file = files.find((f) => f.name === name)
        return file ? <UploadStatus fileInfo={file} /> : <></>
      },
    },
  ]

  return (
    <List
      itemsPerPage={0}
      tableName="uploadingFiles"
      columns={columns}
      disableColumnSelector={true}
      disableConfigStore={true}
      data={files}
    />
  )
}

export default FileUploadingLists
