import { PureComponent, ImgHTMLAttributes } from 'react'

export enum Icons {
  ACTIVITY = 'activity',
  ACTIVITY_GREY = 'activity-grey',
  ADD_CIRCLE = 'add_circle',
  ADD_CIRCLE_BLUE = 'add_circle_blue',
  ADD_CIRCLE_GRAY = 'add_circle_gray',
  ADD_CIRCLE_OUTLINE = 'add_circle_outline',
  ADD_WHITE = 'add-white',
  AFTER = 'after',
  ALARM_BLUE = 'alarm-blue',
  ALARM_WHITE = 'alarm-white',
  ARROW_DOWN = 'arrow_down',
  ARROW_DOWN_BLUE = 'arrow_down-blue',
  ARROW_DOWN_BLACK = 'arrow-down-black',
  ARROW_DOWN_GRAY = 'arrow_down_gray',
  ARROW_DOWN_WHITE = 'arrow_down-white',
  ARROW_DOWN_CIRCLE = 'arrow_circle_down_black',
  ARROW_LEFT = 'arrow_left',
  ARROW_LEFT_GRAY = 'arrow_left-gray',
  ARROW_LEFT_GRAY_2 = 'arrow_left-gray-2',
  ARROW_LEFT_GRAY_3 = 'arrow_left-gray-3',
  ARROW_LEFT_WHITE = 'arrow_left-white',
  ARROW_LEFT_BLUE = 'arrow_left-blue',
  ARROW_RIGHT = 'arrow_right',
  ARROW_RIGHT_BLUE = 'arrow_right-blue',
  ARROW_RIGHT_GRAY = 'arrow_right-gray',
  ARROW_RIGHT_GRAY_2 = 'arrow_right-gray-2',
  ARROW_RIGHT_GRAY_3 = 'arrow_right-gray-3',
  ARROW_RIGHT_WHITE = 'arrow_right-white',
  ARROW_ROUNDED_MOBILE = 'arrow-rounded-mobile',
  ARROW_EAST = 'arrow_east',
  ARROW_UP = 'arrow_up',
  ARROW_UP_BLUE = 'arrow_up-blue',
  ARROW_UP_WHITE = 'arrow_up-white',
  ARROW_UP_CIRCLE = 'arrow_circle_up_black',
  BACK_MOBILE = 'back-mobile',
  BOOK_BLUE = 'book-blue',
  BOOK_WHITE = 'book-white',
  ASC = 'ascending-sort',
  ASC_WHITE = 'ascending-sort-white',
  BEFORE = 'before',
  CALENDAR = 'calendar',
  CALENDAR_TWO = 'calendar-two',
  CAMERA = 'camera',
  CAMERA_WHITE = 'camera-white',
  CAMPAIGN = 'campaign',
  CAMPAIGN_WHITE = 'campaign-white',
  CARRIAGE = 'carriage',
  CASES_BLUE = 'cases-blue',
  CASES_WHITE = 'cases-white',
  CHART = 'chart',
  CHART_BLUE = 'chart-blue',
  CHART_WHITE = 'chart-white',
  CHART_KPI = 'chart-kpi-white',
  CHART_KPI_BLUE = 'chart-kpi-blue',
  CHECK = 'check',
  CHECK_BLUE = 'check-blue',
  CHECK_IN = 'check_in',
  CHECK_GRAY = 'check-gray',
  CHECK_GRAY_BG = 'check-gray-bg',
  CHECK_GREEN = 'check-green',
  CHECK_GREEN_BG = 'check-green-bg',
  CHECK_OUT = 'check_out',
  CHECK_WHITE = 'check-white',
  CLIPBOARD_WHITE = 'clipboard-white',
  CLOCK = 'clock',
  CLOCK_WHITE = 'clock-white',
  CLOSE = 'baseline-close',
  CLOSE_ICON_GRAY = 'close-icon-gray',
  CLOSE_ICON_RED = 'close-icon-red',
  CLOSE_GRAY = 'baseline-close-gray',
  CLOSE_BLUE = 'baseline-close-blue',
  CLOSE_RED = 'baseline-close-red',
  COMMENT_BLUE = 'comment-blue',
  CONFIG_WHITE = 'config-white',
  CONFIG_BLUE = 'config-blue',
  CONSTRUCTION_WHITE = 'construction',
  CONSTRUCTION_GRAY = 'construction-gray',
  CONSTRUCTION_BLUE = 'construction-blue',
  CONTRACT_WHITE = 'contract',
  CONTRACT_BLUE = 'contract-blue',
  CLOSE_WHITE = 'close-white',
  CONTRACTS = 'contracts',
  CONTRACTS_BLACK = 'contracts-black',
  CONTRACTS_BLUE = 'contracts-blue',
  CONTRACTS_WHITE = 'contracts-white',
  COPY = 'copy',
  COPY_BLUE = 'copy_blue',
  COPY_FILE_WHITE = 'copy-file-white',
  COPY_TO_CLIPBOARD = 'copy-to-clipboard',
  COPY_GRAY = 'copy-gray',
  COPY_WHITE = 'copy-white',
  CROSS_GRAY = 'cross-gray',
  CROSS_GRAY_BG = 'cross-gray-bg',
  CROSS_RED = 'cross-red',
  CROSS_RED_BG = 'cross-red-bg',
  CROSS_WHITE = 'cross-white',
  DANGER_RED = 'danger-red',
  DANGER_GRAY = 'danger-gray',
  DANGER_YELLOW = 'danger-yellow',
  DASHBOARD_BLUE = 'dashboard-blue',
  DASHBOARD_DARK = 'dashboard-dark',
  DASHBOARD_WHITE = 'dashboard-white',
  DASHBOARD_PANEL = 'view_comfy-white',
  DASHBOARD_PANEL_BLUE = 'view_comfy-blue',
  DASHBOARD_CUSTOMIZE = 'dashboard-customize-white',
  DASHBOARD_CUSTOMIZE_BLUE = 'dashboard-customize-blue',
  DATABASE = 'database',
  DATABASE_GREY = 'database-grey',
  DATASET_BLUE = 'dataset-blue',
  DELETE = 'delete',
  DELETE_GRAY = 'delete-gray',
  DELETE_RED = 'delete-red',
  DELETE_WHITE = 'delete-white',
  DELETE_BULK = 'delete-bulk',
  DELETE_BULK_WHITE = 'delete-bulk-white',
  DESC = 'descending-sort',
  DESC_WHITE = 'descending-sort-white',
  DOCX = 'docx',
  DOC_EDIT = 'doc_edit',
  DOCUMENT = 'document',
  DOWNLOAD = 'download',
  DOWNLOAD_BLUE = 'download_blue',
  DRAG_ICON = 'drag_icon',
  DRAG_NEW = 'drag_new',
  DROPDOWN = 'dropdown',
  DURATION = 'duration',
  EDIT = 'edit',
  EDIT_WHITE = 'edit-white',
  EMAIL = 'email',
  ENGLISH = 'english',
  ICELAND = 'iceland',
  ENGINEER = 'engineer',
  ENGINEER_BLUE = 'engineer-blue',
  EVENT = 'event',
  EVENT_BLUE = 'event-blue',
  EVENT_WHITE = 'event-white',
  EXCEL = 'excel',
  EXIT = 'exit',
  EXIT_BLUE = 'exit-blue',
  EXPLORE = 'explore',
  EXPLORE_BLUE = 'explore-blue',
  EYE_GRAY = 'eye-gray',
  EYE_BLUE = 'eye-blue',
  FDVU_WHITE = 'fdvu-white',
  FDVU_BLUE = 'fdvu-blue',
  FEEDBACK_BLUE = 'feedback_blue',
  FEEDBACK_GRAY = 'feedback_gray',
  FILE = 'file',
  FILE_GRAY = 'file-gray',
  FILE_DOWNLOAD_BLUE = 'file-download-blue',
  FILE_UPLOAD = 'upload-file',
  FILTER = 'filter',
  FILTER_SELECTED = 'filter-selected',
  FILTER_OUTLINE = 'filter-outline',
  FILTER_BLUE_FILLED = 'filter-blue-filled',
  FOLDER = 'folder',
  FOLDER_GREY = 'folder-grey',
  GAVEL_BLUE = 'gavel-blue',
  GAVEL_WHITE = 'gavel-white',
  GRID_BLUE = 'grid-blue',
  GRID_WHITE = 'grid-white',
  GROUP_ADD = 'group-add',
  HAMBURGER_BLUE = 'hamburger-blue',
  HELP_CIRCLE_BLUE = 'help-circle-blue',
  HELP_CIRCLE_BLUE_FILLED = 'help-circle-filled',
  HOME = 'home',
  HOURGLASS_EMPTY = 'hourglass-empty',
  IMAGE = 'image',
  IMPROVEMENT = 'improvement',
  IMPROVEMENT_BLUE = 'improvement-blue',
  INDETERMINATE_BLUE = 'indeterminate_blue',
  INFO_BLUE = 'info-blue',
  INFO_GRAY = 'info-gray',
  ISSUE = 'issue',
  ISSUE_WHITE = 'issue-white',
  ISSUE_DISABLED = 'issue-disabled',
  ISSUE_ACTIVE = 'issue-active',
  ISSUE_ORANGE = 'issue-orange',
  ISSUE_PURPLE = 'issue-purple',
  ISSUE_CIRCLED = 'issue-circled',
  HIDE_DEPENDENCY = 'hide_dependency',
  KEY = 'key',
  KEY_BLUE = 'key-blue',
  KEY_WHITE = 'key-white',
  LINE_GRAY_BG = 'line-gray-bg',
  LINE_YELLOW_BG = 'line-yellow-bg',
  LINK = 'link',
  LIST_BLUE = 'list-blue',
  LIST_WHITE = 'list-white',
  MAN = 'man_black',
  MAXIMIZE_WHITE = 'maximize-white',
  MECHANICAL_WHITE = 'mechanical',
  MECHANICAL_BLUE = 'mechanical-blue',
  MINIMIZE_WHITE = 'minimize-white',
  MORE = 'more',
  MOVIE = 'movie',
  NORWEGIAN = 'norwegian',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_BLUE = 'notifications-blue',
  NOTIFICATIONS_LIGHT_BLUE = 'notifications-light-blue',
  ORGANIZATION = 'organization',
  ORGANIZATION_BLUE = 'organization-blue',
  PASSWORD_RESET = 'password-reset',
  PERSON_TASK = 'person-task',
  PERSON_WHITE = 'person-white',
  PERSON_BLUE = 'person-blue',
  PLUS_ICON_BLUE = 'plus-icon-blue',
  GROUP_WHITE = 'group-white',
  GROUP_BLUE = 'group-blue',
  EDIT_BLUE = 'edit-blue',
  EXCLAMATION_CIRCLE = 'exclamation_circle',
  EXCLAMATION_GRAY_BG = 'exclamation-gray-bg',
  EXCLAMATION_RED_BG = 'exclamation-red-bg',
  ERROR_RED = 'error-red',
  OPEN_INSPECTOR = 'open-inspector',
  PDF = 'pdf',
  PLUS = 'add',
  PLUS_CIRCLE = 'ic_add_circle_outline',
  POWERPOINT_ICON = 'ppt',
  PRIORITY_HIGH_RED = 'priority_high_red',
  PROCESS_BLACK = 'process-black',
  PROCESS_BLUE = 'process-blue',
  PROCESS_WHITE = 'process-white',
  QR = 'qr',
  RESIZE = 'resize',
  RISK = 'risk',
  RISK_BLUE = 'risk-blue',
  ROOM = 'room',
  ROOM_GRAY = 'room-gray',
  ROOM_BLACK = 'room-black',
  ROOM_BLUE = 'room-blue',
  REMOVE_CIRCLE_BLUE = 'remove_circle_blue',
  REMOVE_CIRCLE_GRAY = 'remove_circle_gray',
  REJECT_FILLED = 'reject-filled',
  SAVE_BLUE = 'save-blue',
  SAVE_WHITE = 'save-white',
  SEARCH = 'search',
  SEARCH_GRAY = 'search-gray',
  SEND = 'send',
  SEND_GRAY = 'send-gray',
  SETTINGS = 'settings',
  SETTINGS_WHITE = 'settings-white',
  SETTINGS_BLUE = 'settings-blue',
  SETTINGS_GRAY = 'settings-gray',
  SHARE_BLACK = 'share_black',
  SHARE_GRAY = 'share-gray',
  SHARE_POINT = 'share_point',
  SQUARE_GRAY = 'square-gray',
  DEPENDENCY = 'dependency',
  DEPENDENCY_GRAY = 'dependency-gray',
  DOUBLE_ARROW_BLUE = 'double_arrow_blue',
  DOUBLE_ARROW_WHITE = 'double_arrow_white',
  DOUBLE_ARROW_DOWN = 'double_arrow_down',
  SHOW_DEPENDENCY = 'show_dependency',
  CIRCLE = 'circle',
  CHECK_GREEN_CIRLCE = 'check-green-circle',
  CHECK_GRAY_CIRLCE = 'check-gray-circle',
  CHECK_WHITE_CIRLCE = 'check-white-circle',
  CHECK_RED_CIRLCE = 'check-red-circle',
  CHECKED_BOX = 'checked-box',
  CHECKED_BOX_WHITE = 'checked-box-white',
  CHECKED_BOX_BLUE = 'checked-box-blue',
  CHECK_BOX_TW = 'check-box-tw',
  CHECK_FILLED = 'check-filled',
  CHECKLIST_BLUE = 'checklist_blue',
  CHECKED_BOX_TW = 'checked-box-tw',
  LOCK = 'lock',
  LOCK_OPEN = 'lock-open',
  LOCOMOTIVE_BLUE = 'wagon',
  LOCOMOTIVE_PURPLE = 'wagon-purple',
  LOCOMOTIVE = 'wagon-gray',
  LOCOMOTIVE_ACTIVE = 'wagon-active',
  LOCOMOTIVE_DISABLED = 'wagon-disabled',
  MAIL_OUTLINE = 'mail_outline',
  MAIN_PROCESS_WHITE = 'main-process-white',
  MAIN_PROCESS_BLUE = 'main-process-blue',
  MOVE_DISABLED = 'move-disabled',
  QUESTION_MARK_GRAY_BG = 'question-mark-gray-bg',
  SMARTPHONE = 'smartphone',
  SIGN_OUT = 'sign-out',
  STAR_FILLED = 'star-filled',
  STAR_OUTLINED = 'star-outlined',
  SQURE_OUTLINE = 'square-outline',
  SYSTEM = 'system',
  SYSTEM_BLACK = 'system-black',
  SYSTEM_BLUE = 'system-blue',
  SYSTEM_GRAY = 'system-gray',
  TABLE_VIEW_WHITE = 'table-view-white',
  TABLE_VIEW_BLUE = 'table-view-blue',
  TAVLAMOTE = 'tavlamote',
  TAVLAMOTE_DISABLED = 'tavlamote-disabled',
  TAVLAMOTE_ACTIVE = 'tavlamote-active',
  TAVLAMOTE_CIRCLED = 'tavlamote-circled',
  TAVLAMOTE_WHITE = 'tavlamote-white',
  TUNE_GRAY = 'tune-gray',
  USER = 'user',
  UPLOAD = 'upload',
  VIEW_LIST = 'view',
  WARNING = 'warning',
  WARNING_ORANGE = 'warning-orange',
  WARNING_RED = 'warning-red',
  WARNING_YELLOW = 'warning-yellow',
  WEEK_BLUE = 'week_blue',
  WEEK = 'week',
  WIDGET_EDIT_BLUE = 'widget_edit_blue',
  WIDGET_EDIT_GRAY = 'widget_edit_gray',
  WIDGET_EDIT_WHITE = 'widget_edit_white',
  WORD_ICON = 'word',
  WORK_OPS = 'work-ops',
  WORK_OPS_DISABLED = 'work-ops-disabled',
  WORK_OPS_ACTIVE = 'work-ops-active',
  WORK_OPS_CIRCLED = 'workops-circled',
  WORK_OPS_WHITE = 'work-ops-white',
  ZIP = 'zip',
  OPEN_FOLDER = 'folder-open',
  OPEN_FOLDER_BLUE = 'folder-open-blue',
  DONE_ALL = 'done_all',
  DONE_ALL_BLUE = 'done_all_blue',
  DONE_STATUS_GREEN = 'done_status_green',
  RESET_FILTER = 'filter-reset',
}

interface IInputProps extends ImgHTMLAttributes<HTMLImageElement> {
  icon: Icons
  styleClass?: string
  className?: string
  noPointerEvent?: boolean
}

/**
 * @deprecated Use material symbol SVG (e.g. import Tune from '@icons/tune.svg')
 */
export default class Icon extends PureComponent<IInputProps, any> {
  public static IconType = Icons
  public render() {
    const { icon, styleClass, noPointerEvent, className, ...rest } = this.props

    if (className) {
      return (
        <div className={className}>
          <img
            draggable={false}
            src={`/icons/${icon}.svg`}
            className={(styleClass ? styleClass : '') + ' icon inline'}
            style={noPointerEvent ? { pointerEvents: 'none' } : {}}
            {...rest}
          />
        </div>
      )
    }

    return (
      <img
        draggable={false}
        src={`/icons/${icon}.svg`}
        className={(styleClass ? styleClass : '') + ' icon inline'}
        style={noPointerEvent ? { pointerEvents: 'none' } : {}}
        {...rest}
      />
    )
  }
}
