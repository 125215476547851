import { capitalize } from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../../../utility/utils'
import { BASE_METADATA_TYPES } from '../../../types/FolderMetadataTypes'
import RemoveIcon from '../icons/RemoveIcon'

export interface IFolderMetadataField {
  icon?: JSX.Element
  type?: BASE_METADATA_TYPES
  value?: string
  disabled?: boolean
  onClick?: () => void
  onRemoveClick?: () => void
}

const FolderMetadataDisplayField: React.FC<IFolderMetadataField> = ({
  icon: _icon,
  value,
  type: _type,
  onClick,
  disabled,
  onRemoveClick,
}) => {
  const { t } = useTranslation()
  const [isHovering, setIsHovering] = useState(false)

  const styleClass = {
    root: classNames(
      'h-8',
      'border border-gray-300',
      disabled ? 'bg-grey-light' : 'bg-white',
      disabled || onRemoveClick ? '' : 'cursor-pointer',
      disabled || onRemoveClick ? '' : 'hover:bg-grey-light',
      'px-2',
      'py-0.5',
      'flex',
      'justify-center',
      'text-sm',
      'whitespace-nowrap',
    ),
    icon: classNames('flex', 'justify-center', 'items-center', 'pr-1.5'),
  }

  return (
    <div
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={styleClass.root}
      onClick={() => {
        if (!disabled && onClick) onClick()
      }}
    >
      <>
        <div className={styleClass.icon}>{capitalize(t(value ?? ''))}</div>
        <div className="right-0.5">
          {isHovering && onRemoveClick && (
            <RemoveIcon disabled={disabled} onClick={onRemoveClick} />
          )}
        </div>
      </>
    </div>
  )
}

export default FolderMetadataDisplayField
