import 'filepond/dist/filepond.min.css'
import { Fragment, useState } from 'react'
import { FilePond } from 'react-filepond'
import { useTranslation } from 'react-i18next'
import { validateFiles } from 'src/document/components/FileUpload/foldersFileUpload/FolderFilesUpload'
import { updateWallToken } from 'src/service/TheWallService'
import { deleteFile } from '../../service/DocumentService'
import { IDocument } from '../../service/OrgTypes'
import { capFirstLetter } from '../../utility/utils'
import FileDragOverlay from './FileDragOverlay'

interface IUploadFileProps {
  allowRevert?: boolean
  uploadedDocuments: (documents: IDocument[]) => void
  uploadUrl: string
  disable?: boolean
  singleFileUpload?: boolean
  errorResponse?: (err: any) => void
  inMobile?: boolean
  inInspector?: boolean
  allowedTypes?: string[]
}

const UploadFile = ({
  allowRevert,
  uploadedDocuments,
  uploadUrl,
  disable,
  singleFileUpload,
  errorResponse,
  inMobile = false,
  inInspector = false,
  allowedTypes,
}: IUploadFileProps) => {
  const { t } = useTranslation()
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const fileUploadStarted = () => {}

  const getLabel = () => {
    return !inMobile
      ? `${t(
          'drag_and_drop_your_files_here_or',
        )} <span class="filepond--label-action">${t('upload')}</span>`
      : `<div style='background: white; display: flex; justify-content: space-between; align-items:center; width: ${
          inInspector ? '350px' : `calc(100vw - 50px)`
        };; height: 45px; padding: 7px 15px; border: dotted 2px #269bf7; border-radius: 100px;'>
          <span class="filepond--label-action">${t(
            'click_here_to_upload',
          )}</span>
          <img src=${
            inInspector ? '/icons/upload.svg' : '/icons/camera.svg'
          } width="20px" height="20px" />
     </div>`
  }

  return (
    <Fragment>
      {!disable && (
        <div className={'mt-4'}>
          {error && <p className="text-center text-red mb-3">{errorMessage}</p>}
          <FileDragOverlay t={t} />
          <FilePond
            labelIdle={getLabel()}
            labelFileLoading={t('load')}
            labelFileLoadError={t('fault_under_load')}
            labelFileProcessing={t('uploading')}
            labelFileProcessingComplete={t('upload_complete')}
            labelFileProcessingAborted={t('upload_canceled')}
            labelFileProcessingError={t('error_uploading')}
            labelTapToRetry={t('press_to_retry')}
            labelTapToCancel={t('press_to_cancel')}
            labelTapToUndo={t('press_to_undo')}
            labelButtonRemoveItem={t('remove')}
            labelButtonAbortItemLoad={t('remove')}
            labelButtonRetryItemLoad={t('try_again')}
            labelButtonAbortItemProcessing={t('cancel')}
            labelButtonUndoItemProcessing={t('undo')}
            labelButtonRetryItemProcessing={t('try_again')}
            labelButtonProcessItem={capFirstLetter(t('upload'))}
            allowRevert={allowRevert}
            server={{
              revert: (uniqueFileId, load, error) => {
                deleteFile({ file_url: uniqueFileId })
                  .then(() => {
                    load()
                    documents.forEach((item, index) => {
                      if (item.fileUrl === uniqueFileId) {
                        documents.splice(index, 1)
                      }
                      setDocuments(documents)
                      uploadedDocuments(documents)
                    })
                  })
                  .catch(() => error('oh no'))
              },

              process: async (
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort,
              ) => {
                if (allowedTypes && !allowedTypes.includes(file.type)) {
                  setError(true)
                  setErrorMessage(
                    t('file_is_not_of_allowed_type_allowed_types_are') +
                      allowedTypes.join(','),
                  )
                  setDocuments([])
                  abort()
                  return
                }
                const validation = validateFiles([file])
                if (validation.error) {
                  setError(true)
                  setErrorMessage(validation.message)
                  setDocuments([])
                  abort()
                  return
                }
                setError(false)
                setErrorMessage('')

                const token = await updateWallToken()
                const formData = new FormData()
                formData.append(fieldName, file, file.name)

                const request = new XMLHttpRequest()
                request.open('POST', uploadUrl)
                request.setRequestHeader('Authorization', `Bearer ${token}`)

                request.upload.onprogress = (e) => {
                  progress(e.lengthComputable, e.loaded, e.total)
                }

                request.onload = () => {
                  if (request.status >= 200 && request.status < 300) {
                    const document: IDocument = JSON.parse(request.response)
                    documents.push(document)
                    load(document.fileUrl)
                    uploadedDocuments(documents)
                    setDocuments([])
                  } else {
                    error('oh no')
                    errorResponse?.(request.response)
                  }
                }

                request.send(formData)

                return {
                  abort: () => {
                    setDocuments([])
                    if (request) {
                      request.abort()
                      abort()
                    }
                  },
                }
              },
            }}
            onaddfilestart={() => fileUploadStarted()}
            oninit={() => {
              /*noop*/
            }}
            allowMultiple={!singleFileUpload}
            dropOnElement={false}
            dropOnPage={true}
          />
        </div>
      )}
    </Fragment>
  )
}
export default UploadFile
