import { FC, useState } from 'react'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { ButtonType } from '../../../../ui-elements/button/ButtonEnums'

interface FileLinkUploadModalProps {
  onSubmit: (url: string) => void
  show: boolean
  close: () => void
}

const FileLinkUploadModal: FC<FileLinkUploadModalProps> = ({
  onSubmit,
  show,
  close,
}) => {
  const { t } = useMultipleKeysTranslation()

  const [link, setLink] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  // check if its valid share point link

  const isUrl = (url: string) => {
    try {
      return Boolean(new URL(url))
    } catch (e) {
      return false
    }
  }

  const handleSubmit = async (_link: string) => {
    if (isUrl(_link)) {
      onSubmit(_link)
    } else {
      setErrorMessage(' ')
    }
  }

  const onChange = (value: string) => {
    if (isUrl(value)) {
      setErrorMessage('')
    }
    setLink(value)
  }

  return (
    <Modal
      title={t(['add_link_to_file'])}
      show={show}
      closeModal={close}
      size={'w-1/3'}
      maxWidth={600}
    >
      <div className="mb-4 flex flex-col">
        <Input
          label={t(['file_link'])}
          value={link}
          onChange={(e) => onChange(e.target.value)}
          block={true}
          required={true}
          errorMessage={errorMessage}
        />
      </div>
      <ModalFooter>
        <Button onClick={close}>{t('cancel')}</Button>
        <Button type={ButtonType.PRIMARY} onClick={() => handleSubmit(link)}>
          {t('save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FileLinkUploadModal
