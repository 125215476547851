import { getFilters, setFilters } from './TableKeeperService'

export const setFilterValues = (
  projectId: number,
  filterName: string,
  data: any,
  fromLocal = true,
) => {
  const localFilter = getLocalStorageFilter()
  const filterData = { name: filterName, projectId, data }
  if (localFilter && localFilter.length > 0) {
    const currentFilterIndex = localFilter.findIndex(
      (filter: any) =>
        filter.name === filterName && filter.projectId === projectId,
    )
    if (currentFilterIndex > -1) {
      localFilter[currentFilterIndex] = filterData
    } else {
      localFilter.push(filterData)
    }
    window.localStorage.setItem('filters', JSON.stringify(localFilter))
  } else {
    window.localStorage.setItem('filters', JSON.stringify([filterData]))
  }

  if (fromLocal) {
    setFilters(projectId, filterName, data)
  }
}

export const getFilterValues = (projectId: number, filterName: string) => {
  return new Promise<any>(async (resolve) => {
    const storedFilter = getLocalStorageFilter()
    if (storedFilter) {
      const currentFilterIndex = storedFilter.findIndex(
        (filter: any) =>
          filter.name === filterName && filter.projectId === projectId,
      )
      if (currentFilterIndex > -1) {
        resolve(storedFilter[currentFilterIndex].data)
        return
      }
    }

    const res = await getFilters(projectId, filterName)
    if (res) {
      setFilterValues(projectId, filterName, res, false)
      resolve(res)
      return
    }
    resolve(null)
  })
}

const getLocalStorageFilter = () => {
  const filter = window.localStorage.getItem('filters')
  if (filter) {
    return JSON.parse(filter)
  }
  return false
}
