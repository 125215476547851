import * as React from 'react'
import { capFirstLetter } from '../../utility/utils'
import Input from '../input/Input'
import { PageDisplayStyleClass } from './pageUtils'

interface IInputPageDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  errorMessage?: string
  isValid: boolean
  description?: string
  block?: boolean | undefined
  className?: string
  isLoading?: boolean
  disabled?: boolean
  revert?: boolean
  required?: boolean
  small?: boolean
  searchabledata?: any[]
  focus?: boolean
  showSearchIcon?: boolean
  login?: boolean
  value?: string | number | string[]
  editingMode: boolean
  displayData: string
  type?: string
  minValue?: string | number
  onChange: (e: any) => void
  grayFill?: boolean
  spansTwoColumns?: boolean
  step?: string
}

export default class InputPageDisplay extends React.PureComponent<
  IInputPageDisplayProps,
  any
> {
  private styleClass = PageDisplayStyleClass

  public render() {
    const {
      label,
      errorMessage,
      block,
      className,
      isLoading,
      disabled,
      required,
      focus,
      showSearchIcon,
      login,
      editingMode = false,
      displayData,
      value,
      type,
      minValue,
      onChange,
      grayFill = false,
      spansTwoColumns = false,
      step,
      onBlur,
    } = this.props
    return (
      <div
        className={this.styleClass.tableRow(
          grayFill,
          editingMode,
          spansTwoColumns,
        )}
      >
        <dt className={this.styleClass.tableLeftText(editingMode)}>
          {capFirstLetter(label)}
          {required && editingMode ? ' *' : ''}
          {errorMessage && editingMode && (
            <span className={this.styleClass.errorMessage}>{errorMessage}</span>
          )}
        </dt>
        <dd className={this.styleClass.tableCellRight(editingMode)}>
          {editingMode ? (
            <Input
              label={label}
              block={block}
              className={className}
              noPadding={true}
              isLoading={isLoading}
              disabled={disabled}
              required={required}
              focus={focus}
              showSearchIcon={showSearchIcon}
              hideLabel={true}
              login={login}
              value={value}
              onChange={onChange}
              type={type}
              step={step}
              minValue={minValue}
              onBlur={onBlur}
            />
          ) : (
            <span className={this.styleClass.displayText}>
              {value !== undefined && value !== null ? displayData : null}
            </span>
          )}
        </dd>
      </div>
    )
  }
}
