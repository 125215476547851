import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { IDiscipline, ITest, IUserData } from 'src/service/OrgTypes'
import {
  addTestToControlAreas,
  deleteTestFromControlArea,
  editTest,
  getControlAreaTests,
} from 'src/service/TestService'
import Button from 'src/ui-elements/button/Button'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import {
  CreatedAtColumn,
  DeadlineColumn,
  RecordIdColumn,
  TestStatusColumn,
  TestTypeColumn,
  TextColumn,
  TitleColumn,
  UpdatedAtColumn,
} from '../../../components/TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumn,
  ResponsibleColumn,
} from '../../../components/TableColumns/DisciplineResponsibleColumns'
import { testStatus } from '../../../service/SystemValues'
import { classNames } from '../../../utility/utils'
import TestInspectorPanel from './TestInspectorPanel'
import TestListSelector from './TestListSelector'

export interface ITestRowClick {
  testType: { id: number; label: string }
  test: { id: number; label: string }
}

interface ITestListForControlAreaProps {
  controlAreaId: number
}

const TestListForControlArea = ({
  controlAreaId,
}: ITestListForControlAreaProps) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    errorMessage: classNames('text-red-600', 'my-1', 'ml-2', 'text-sm'),
    inputGroup: classNames('w-full', 'md:flex', 'flex-row'),
    label: classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'my-2',
    ),
  }

  const { t } = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [tests, setTests] = useState<ITest[]>([])
  const [selectedTest, setSelectedTest] = useState<ITest | null>(null)
  const [selectedTests, setSelectedTests] = useState<number[]>([])
  const testRef = useRef(tests)
  const [showInspector, setShowInspector] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const [testIdsErrorMessage, setTestIdsErrorMessage] = useState<string>('')
  const [testIds, setTestIds] = useState<number[]>([])
  const projectContext = useContext(ProjectContext)

  const projectId = projectContext.state.currentProject.id
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])

  useEffect(() => {
    reload()
  }, [projectId, controlAreaId])

  useEffect(() => {
    testRef.current = tests
  })

  const onChangeInput = (id: number, update: Partial<ITest>) => {
    editTest({
      ...update,
      id: id,
    }).then(() => {
      reload()
    })
  }

  const columns: IListColumns[] = [
    RecordIdColumn('120'),
    TestTypeColumn(false),
    TitleColumn('name', '300', (key, value) =>
      onChangeInput(key, { name: value }),
    ),
    DeadlineColumn('execution_date', 'execution_date||status', (date, key) =>
      onChangeInput(key, { execution_date: date }),
    ),
    TestStatusColumn(false, testStatus(t), (status, key) =>
      onChangeInput(key, { status: status.id }),
    ),
    TextColumn(
      'description',
      'description',
      '300',
      (key, value) => onChangeInput(key, { description: value }),
      false,
    ),
    ResponsibleColumn(
      projectId,
      projectUsers,
      projectDiscipline,
      (id, responsibleId, disciplineId) =>
        onChangeInput(id, {
          responsible_id: responsibleId,
          discipline_id: disciplineId,
        }),
    ),
    DisciplineColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      (id, disciplineId, responsibleId) =>
        onChangeInput(id, {
          responsible_id: responsibleId,
          discipline_id: disciplineId,
        }),
    ),
    ContractColumn(),
    TextColumn(
      'location',
      'location',
      '200',
      (key, value) => onChangeInput(key, { location: value }),
      false,
    ),
    CreatedAtColumn(),
    UpdatedAtColumn(),
  ]

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const handlePreviewClick = (e: any, data: ITest) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedTest(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedTest(null)
  }

  const reload = () => {
    getControlAreaTests(projectId, controlAreaId).then((res) =>
      setTests(res?.length > 0 ? res : []),
    )
  }

  const getActionButton = () => {
    return (
      <Button type={Button.ButtonType.PRIMARY} onClick={openCreateModal}>
        {t('add_test')}
      </Button>
    )
  }

  const onCloseCreateModal = () => {
    setShowCreateModal(false)
    setTestIds([])
  }

  const onTestsSelect = (data: number[]) => {
    setTestIdsErrorMessage('')
    setTestIds(data)
  }

  const onAddTest = () => {
    addTestToControlAreas(projectId, testIds, controlAreaId).then(() => {
      setShowCreateModal(false)
      setTestIds([])
      reload()
    })
  }

  const onRemoveTest = () => {
    if (selectedTest?.id) {
      deleteTestFromControlArea(projectId, controlAreaId, [
        selectedTest?.id,
      ]).then(() => {
        reload()
        setShowDeleteModal(false)
        setSelectedTest(null)
      })
    }
  }

  const onDeleteItemClick = (row: any) => {
    setShowDeleteModal(!showDeleteModal)
    setSelectedTest(row)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
    reload()
  }

  const onBulkDelete = () => {
    deleteTestFromControlArea(projectId, controlAreaId, selectedTests).then(
      () => {
        reload()
      },
    )
  }

  return (
    <div className={styleClass.root}>
      <List
        actionButton={getActionButton()}
        columns={columns}
        data={tests}
        tableName={'TestListForControlAREA'}
        itemsPerPage={50}
        onPreviewClick={handlePreviewClick}
        isRowSelectable={true}
        onSelectRow={(data) => setSelectedTests(data)}
        selectedRows={selectedTests}
        onBulkDelete={onBulkDelete}
        bulkDelete={true}
        actionMenu={[
          {
            name: t('delete'),
            action: onDeleteItemClick,
          },
        ]}
      />

      {showCreateModal && (
        <Modal
          show={true}
          title={t('add_test')}
          closeModal={onCloseCreateModal}
          size={'w-9/10'}
          maxWidth={1420}
        >
          {testIdsErrorMessage ? (
            <div className={styleClass.errorMessage}>{testIdsErrorMessage}</div>
          ) : null}
          <div className={`${styleClass.inputGroup} min-h-95`}>
            <TestListSelector
              onTestSelect={onTestsSelect}
              selectedTests={testIds}
            />
          </div>
          <ModalFooter>
            <Button onClick={onCloseCreateModal}>{t('cancel')}</Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              onClick={() => onAddTest()}
            >
              {t('add')}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {selectedTest && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          customTitle={t('are_you_sure_you_want_to_delete_with_param', {
            item: selectedTest.name ? selectedTest.name : '',
          })}
          onDelete={onRemoveTest}
          itemName={'test'}
        />
      )}

      {selectedTest && selectedTest?.id && showInspector && (
        <TestInspectorPanel
          testId={selectedTest.id}
          open={showInspector}
          onClose={onCloseInspector}
          onUpdate={reload}
        />
      )}
    </div>
  )
}

export default TestListForControlArea
