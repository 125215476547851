import ShareIcon from '@icons/share.svg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import useAlert from 'src/ui-elements/toast/useAlert'
import useProjectId from '../hooks/useProjectId'

export interface CopyUrlProps {
  params?: Record<string, number | string>
}

const CopyUrl: FC<CopyUrlProps> = ({ params }) => {
  const { showInfo, showError } = useAlert()
  const { t } = useTranslation()
  const projectId = useProjectId()

  const buildUrl = (): string => {
    const baseUrl = window.location.href.split('?')[0]
    const urlParams = new URLSearchParams()
    Object.entries(params ?? {}).forEach(([key, value]) => {
      urlParams.append(key, value.toString())
    })
    urlParams.append('project', projectId.toString())
    return `${baseUrl}?${urlParams.toString()}`
  }

  const copyUrlToClipboard = async (): Promise<void> => {
    try {
      const Url = buildUrl()
      await navigator.clipboard.writeText(Url)
      showInfo(t('successfully_copied_link_to_clipboard'))
    } catch (error) {
      showError(t('error_copying_link_to_clipboard'))
    }
  }
  return (
    <a title={t('copy_link_to_clipboard')} className="flex items-center">
      <ShareIcon
        style={{ width: 20, height: 20 }}
        onClick={() => copyUrlToClipboard()}
      />
    </a>
  )
}

export default CopyUrl
