import * as React from 'react'
import { Subtract } from 'utility-types'
import {
  IProjectContext,
  ProjectContext,
} from './ProjectContextProvider/ProjectContext'

export interface IWithProjectContext {
  projectContext: IProjectContext
}

export const withProjectContext = <P extends IWithProjectContext>(
  Component: React.ComponentType<P>,
) =>
  class WithContext extends React.PureComponent<
    Subtract<P, IWithProjectContext>
  > {
    public render() {
      return (
        <ProjectContext.Consumer>
          {(projectContext: IProjectContext) =>
            projectContext.state.currentProject?.id && (
              <Component
                {...(this.props as P)}
                projectContext={projectContext}
              />
            )
          }
        </ProjectContext.Consumer>
      )
    }
  }
