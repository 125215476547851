import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import UserIcon from 'src/components/user/UserIcon'
import history from 'src/history'
import { IUserData } from 'src/service/OrgTypes'
import Icon from 'src/ui-elements/icon/Icon'
import { onLogout } from '../../components/logout/Logout'
import AppCommunicator from '../../service/AppCommunicator'
import { capFirstLetter, classNames } from '../../utility/utils'

interface IMHeaderProps {
  user: IUserData
  showUser?: boolean
  title?: string
  onDoneClick?: (e?: any) => void
  onBackClick?: () => void
  showHomeBtn?: boolean
}

const MHeader = ({
  user,
  onDoneClick,
  onBackClick,
  showUser = true,
  title,
  showHomeBtn = true,
}: IMHeaderProps) => {
  const styleClass = {
    root: classNames(
      'w-full',
      'flex',
      'justify-between',
      'items-end',
      'px-6',
      'bg-blue-root',
      'h-18',
      'pb-3',
      'min-h-18',
    ),
    buttons: classNames(
      'flex',
      'items-center',
      AppCommunicator.isApp && 'translate-x-24',
    ),
    dropdown: classNames(
      'absolute',
      'z-50',
      'right-0',
      'p-2',
      'top-full',
      'mt-2',
      'bg-white',
      'rounded-lg',
      'text-gray-500',
      'text-sm',
      'flex',
      'w-mx-content',
    ),
    profile: classNames(
      'rounded-full',
      'h-8',
      'w-8',
      'overflow-hidden',
      'flex',
      'items-center',
    ),
    actionBtn: classNames(
      'flex',
      'text-xs',
      'pl-2 pr-1',
      'py-0.5',
      'border',
      'border-white',
      'rounded-md',
      'text-white',
    ),
  }

  const { t } = useTranslation()
  const { id, image_url } = user
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const handleClick = () => {
    setShowDropdown((v) => !v)
  }

  const onHomeClick = () => {
    history.push('/mobile/')
  }

  return (
    <div className={styleClass.root}>
      <div className={styleClass.buttons}>
        {onBackClick && (
          <Icon
            onClick={onBackClick}
            icon={Icon.IconType.BACK_MOBILE}
            className={'w-4 h-4 mb-2 mr-2 md:mr-4'}
          />
        )}
        {showHomeBtn && (
          <Icon
            onClick={onHomeClick}
            icon={Icon.IconType.HOME}
            className={'transform scale-90 w-6 h-6 ml-4'}
          />
        )}
      </div>

      {!AppCommunicator.isApp &&
        (title ? (
          <p className={'text-white text-sm font-medium'}>
            {capFirstLetter(title)}
          </p>
        ) : (
          <img
            onClick={() => history.replace('/mobile/')}
            className={'w-auto h-7'}
            src="/taskctr-logohvit.png"
            alt=""
          />
        ))}

      <div className={'flex items-center'}>
        {onDoneClick && (
          <div onClick={onDoneClick} className={styleClass.actionBtn}>
            {t('done')}
            <Icon
              icon={Icon.IconType.CHECK}
              className={'ml-2 w-4 h-4 opacity-75'}
            />
          </div>
        )}

        {showUser && !AppCommunicator.isApp && (
          <div className={'h-8 w-8 ml-2 flex shrink-0 relative'}>
            <UserIcon
              noBorder={true}
              userId={id}
              large={true}
              onMobileClick={handleClick}
              firstName={''}
              lastName={''}
              image_url={image_url}
            />
            {showDropdown && (
              <CloseClickOutside onClose={() => setShowDropdown(false)}>
                <div onClick={onLogout} className={styleClass.dropdown}>
                  <p className={'text-no-wrap'}>{t('log_out')}</p>
                  <Icon
                    icon={Icon.IconType.SIGN_OUT}
                    className={'ml-2 w-4 h-4 opacity-75'}
                  />
                </div>
              </CloseClickOutside>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default MHeader
