import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConstructionIssuesList from 'src/components/construction/construction-issues/ConstructionIssuesList'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { BadgeColorMapper } from 'src/ui-elements/badge/BadgeEnums'
import Modal from 'src/ui-elements/modal/Modal'
import {
  deleteConstructionChecklist,
  getWagonCheckListsWithValues,
} from '../../../service/ConstructionChecklistService'
import {
  IConstructionChecklist,
  IConstructionWagon,
  ITaskData,
} from '../../../service/OrgTypes'
import Button from '../../../ui-elements/button/Button'
import { ButtonSize, ButtonType } from '../../../ui-elements/button/ButtonEnums'
import List from '../../../ui-elements/list/List'
import { IListColumns } from '../../../ui-elements/list/ListTypes'
import { classNames } from '../../../utility/utils'
import ConstructionChecklistForm from './ConstructionChecklistForm'

interface IConstructionChecklistList {
  wagon: IConstructionWagon
  controlAreaId?: number
  projectId: number
  modalTitle?: string
  reloadParent?: () => void
  tableHeight?: string
  tableWidth?: string
}

const ConstructionChecklistList = ({
  wagon,
  controlAreaId,
  projectId: _projectId,
  modalTitle,
  reloadParent,
  tableHeight: _tableHeight,
  tableWidth: _tableWidth,
}: IConstructionChecklistList) => {
  const styleClass = {
    span: (bgcolor: string) =>
      classNames(
        'justify-center',
        'items-center',
        'w-10',
        'h-6',
        'rounded-full',
        'shadow',
        'flex',
        bgcolor,
      ),
    root: classNames('md_w-full', 'flex', 'flex-row'),
  }

  /**
   * Initial load of component. fetches the constructionChecklists, refetches if porjectConstext changes.
   */
  useEffect(() => {
    fetchConstructionChecklists()
  }, [wagon])

  const fetchConstructionChecklists = () => {
    if (wagon.id) {
      getWagonCheckListsWithValues(wagon.id).then((res) => {
        setConstructionChecklists(res)
        if (res.length > 0) {
          setLastConstructionChecklist(res[0])
        }
      })
    }
  }

  const { t } = useTranslation()
  const [constructionChecklists, setConstructionChecklists] = useState<
    IConstructionChecklist[]
  >([])
  const [formModal, setFormModal] = useState<boolean>(false)
  const [showTaskModal, setTaskModal] = useState<boolean>(false)
  const [editChecklistItem, setEditChecklistItem] = useState<boolean>(false)
  const [selectedConstructionChecklist, setSelectedConstructionChecklist] =
    useState<IConstructionChecklist | undefined>(undefined)
  const [lastConstructionChecklist, setLastConstructionChecklist] = useState<
    IConstructionChecklist | undefined
  >(undefined)
  const [taskParentIds, setTaskParentIds] = useState<number[]>([])
  const [taskModalTitle, setTaskModalTitle] = useState<string>(t('cases'))
  const selectedRef = useRef(selectedConstructionChecklist)
  const projectContext = useContext(ProjectContext)
  const { isConstructionManager } = projectContext.state

  const openCreateModal = () => {
    setSelectedConstructionChecklist(
      lastConstructionChecklist
        ? lastConstructionChecklist
        : ({} as IConstructionChecklist),
    )
    setFormModal(true)
    setEditChecklistItem(false)
  }

  const onDeleteItemClick = (item: IConstructionChecklist) => {
    setSelectedConstructionChecklist(undefined)
    selectedRef.current = undefined
    deleteConstructionChecklist(item).then(() => {
      fetchConstructionChecklists()
    })
  }

  const onEdit = (item: IConstructionChecklist) => {
    setSelectedConstructionChecklist(item)
    setFormModal(true)
    setEditChecklistItem(true)
  }

  const openCheckListTask = (checklistTasks: ITaskData[], type: string) => {
    if (checklistTasks && checklistTasks.length > 0) {
      setTaskParentIds(checklistTasks.map((task) => task.parent_id))
      setTaskModal(true)
      setTaskModalTitle(`${t(type)} ${t('cases')}`)
    }
  }

  const closeModal = () => {
    setTaskModal(false)
    fetchConstructionChecklists()
  }

  const closeChecklistModal = () => {
    fetchConstructionChecklists()
    reloadParent?.()
    setFormModal(false)
    setEditChecklistItem(false)
    setSelectedConstructionChecklist(undefined)
  }

  const getValue = (open: number, total: number) => {
    const done = Number(total) - Number(open)
    return `${done}/${total}`
  }

  const getColumns = (): IListColumns[] => {
    return [
      {
        name: 'board_meeting_date',
        size: '200',
        id: 'created_at',
        dataField: 'created_at',
        cell: (created) => (
          <span>{created ? moment(created).format('L LT') : ''}</span>
        ),
      },
      {
        name: 'created_by',
        size: '200',
        id: 'createdBy',
        dataField: 'signed_by',
      },
      {
        name: 'SHA',
        size: '75',
        id: 'sha',
        dataField:
          'sha_checklist||sha_color||construction_locomotive||check_list_task_count||sha_tasks',
        cell: (data: IConstructionChecklist) => (
          <span
            onClick={(e) => {
              e.stopPropagation()
              openCheckListTask(data.sha_tasks, 'sha')
            }}
            className={styleClass.span(BadgeColorMapper[data.sha_color])}
          >
            {getValue(
              data.check_list_task_count?.sha_checklist ?? 0,
              data.check_list_task_count?.sha_checklist_total ?? 0,
            )}
          </span>
        ),
      },
      {
        name: 'quality',
        size: '85',
        id: 'quality',
        dataField:
          'quality_checklist||quality_color||construction_locomotive||check_list_task_count||quality_tasks',
        cell: (data: IConstructionChecklist) => (
          <span
            onClick={(e) => {
              e.stopPropagation()
              openCheckListTask(data.quality_tasks, 'quality')
            }}
            className={styleClass.span(BadgeColorMapper[data.quality_color])}
          >
            {getValue(
              data.check_list_task_count?.quality_checklist ?? 0,
              data.check_list_task_count?.quality_checklist_total ?? 0,
            )}
          </span>
        ),
      },
      {
        name: 'neatness',
        size: '85',
        id: 'neatness',
        dataField:
          'neatness_checklist||neatness_color||construction_locomotive||check_list_task_count||neatness_tasks',
        cell: (data: IConstructionChecklist) => (
          <span
            onClick={(e) => {
              e.stopPropagation()
              openCheckListTask(data.neatness_tasks, 'neatness')
            }}
            className={styleClass.span(BadgeColorMapper[data.neatness_color])}
          >
            {getValue(
              data.check_list_task_count?.neatness_checklist ?? 0,
              data.check_list_task_count?.neatness_checklist_total ?? 0,
            )}
          </span>
        ),
      },
      {
        name: 'progress',
        size: '85',
        id: 'progress',
        dataField:
          'progress_checklist||progress_color||construction_locomotive||check_list_task_count||progress_tasks',
        cell: (data: IConstructionChecklist) => (
          <span
            onClick={(e) => {
              e.stopPropagation()
              openCheckListTask(data.progress_tasks, 'progress')
            }}
            className={styleClass.span(BadgeColorMapper[data.progress_color])}
          >
            {getValue(
              data.check_list_task_count?.progress_checklist ?? 0,
              data.check_list_task_count?.progress_checklist_total ?? 0,
            )}
          </span>
        ),
      },
      {
        name: 'number_of_workers',
        size: '200',
        id: 'staffing',
        dataField:
          'staffing_checklist||staffing_color||construction_locomotive||check_list_task_count||staffing_tasks||number_of_workers',
        cell: (data: IConstructionChecklist) => (
          <>
            <span
              onClick={(e) => {
                e.stopPropagation()
                openCheckListTask(data.staffing_tasks, 'number_of_workers')
              }}
              className={styleClass.span(
                BadgeColorMapper()[data.staffing_color],
              )}
            >
              {getValue(
                data.check_list_task_count?.staffing_checklist ?? 0,
                data.check_list_task_count?.staffing_checklist_total ?? 0,
              )}
            </span>
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {data.number_of_workers
                ? `   ${t('total_amount')}: ${data.number_of_workers}`
                : ''}
            </span>
          </>
        ),
      },
      {
        name: 'comment',
        size: '400',
        id: 'comment',
        dataField: 'comment',
      },
      {
        name: 'updated_at',
        size: '120',
        id: 'updated_at',
        dataField: 'updated_at',
        cell: (updated) => (
          <span>{updated ? moment(updated).format('L LT') : ''}</span>
        ),
      },
    ]
  }

  return (
    <div className={styleClass.root}>
      {constructionChecklists && constructionChecklists.length === 0 ? (
        <div>
          {wagon.check_in !== null && wagon.check_out === null ? (
            <Button
              size={ButtonSize.SMALL}
              type={ButtonType.SECONDARY}
              onClick={openCreateModal}
            >
              {t('new_board_meeting')}
            </Button>
          ) : (
            <p>{t('no_data_found')}</p>
          )}
        </div>
      ) : (
        <List
          actionButton={
            wagon.check_in !== null && !wagon.check_out ? (
              <Button type={ButtonType.PRIMARY} onClick={openCreateModal}>
                {t('new_board_meeting')}
              </Button>
            ) : undefined
          }
          columns={getColumns()}
          tableName={'constructionChecklistTable'}
          data={constructionChecklists}
          onRowClick={onEdit}
          itemsPerPage={0}
          title={t('done_to_total')}
          actionMenu={
            wagon.check_out !== null || !isConstructionManager
              ? undefined
              : [
                  {
                    name: t('delete'),
                    action: onDeleteItemClick,
                  },
                ]
          }
        />
      )}
      {formModal && selectedConstructionChecklist ? (
        <ConstructionChecklistForm
          open={formModal}
          detailView={editChecklistItem}
          constructionChecklist={selectedConstructionChecklist}
          wagon={wagon}
          controlAreaId={controlAreaId}
          closeModal={closeChecklistModal}
        />
      ) : null}
      <Modal
        show={showTaskModal}
        closeModal={closeModal}
        size="w-9/10"
        minHeight={'400px'}
        title={`${modalTitle ? modalTitle + ' - ' : ''}${taskModalTitle}`}
        maxWidth={null}
      >
        <div className="pb-6">
          <ConstructionIssuesList
            tableName={'wagonIssuesList'}
            parentType={'ChecklistParameter'}
            parentIds={taskParentIds}
          />
        </div>
      </Modal>
    </div>
  )
}

export default ConstructionChecklistList
