import { withTolgee, Tolgee, I18nextPlugin, DevTools } from '@tolgee/i18next'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enInfrastructure from 'src/translations/infrastructure/en.json'
import nbInfrastructure from 'src/translations/infrastructure/no.json'

import enTranslation from 'src/translations/translation/en.json'
import nbTranslation from 'src/translations/translation/no.json'

const tolgee = Tolgee()
  .use(DevTools())
  .use(I18nextPlugin())
  .init({
    apiUrl: 'https://app.tolgee.io',
    apiKey: import.meta.env.VITE_TOLGEE_API_KEY,

    staticData: {
      'en:translation': enTranslation,
      'en:infrastructure': enInfrastructure,
      'no:translation': nbTranslation,
      'no:infrastructure': nbInfrastructure,
    },
  })

withTolgee(i18n, tolgee)
  .use(initReactI18next)
  .init({
    lng: 'no',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      prefix: '{',
      suffix: '}',
    },
    fallbackNS: 'translation',
  })

export default i18n
