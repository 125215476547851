import { useQuery } from '@tanstack/react-query'
import { getSystemType } from 'src/service/SystemService'

export const useSystemType = (systemTypeId?: number) => {
  return useQuery({
    enabled: !!systemTypeId,
    queryKey: ['systemType', systemTypeId],
    queryFn: async () => getSystemType(systemTypeId!),
  })
}
