import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import { classNames } from 'src/utility/utils'

export interface IDropDownItem {
  icon?: JSX.Element
  title: string
  onClick?: () => void
  addLineBelow?: boolean
}

interface IDropdownMenu {
  isOpen: boolean
  onClose: () => void
  items: IDropDownItem[]
  position?: 'left' | 'right'
  className?: string
}

const DropdownMenu = ({
  isOpen,
  onClose,
  items,
  position = 'left',
  className = '',
}: IDropdownMenu) => {
  return (
    <CloseClickOutside onClose={onClose}>
      <div
        className="relative text-gray-600"
        onClick={(e) => e.stopPropagation()}
      >
        {isOpen && (
          <div
            className={classNames(
              'z-40 absolute top-0 p-1 shadow-md rounded-md bg-white cursor-pointer',
              position === 'left' ? 'right-0' : 'left-0',
              className,
            )}
          >
            {items.map((item, index) => (
              <div key={index}>
                <div
                  className="hover:bg-blue-light-100 p-1 rounded-md flex items-center text-sm pr-8 whitespace-nowrap"
                  onClick={() => {
                    isOpen && onClose()
                    item.onClick && item.onClick()
                  }}
                >
                  {item.icon && <div className="pr-2">{item.icon}</div>}
                  <p className={'first-capitalize'}>{item.title}</p>
                </div>
                {item.addLineBelow && (
                  <div className="border-b border-gray-200"></div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </CloseClickOutside>
  )
}

export default DropdownMenu
