import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SignatureInput from 'src/components/signature-pad/SignatureCanvas'
import ConstructionTaskForm from 'src/components/task/ConstructionTaskForm'
import {
  createCheckInWagon,
  editCheckInOut,
  getConstructionWagon,
  getWagonCheckInOuts,
} from 'src/service/ConstructionWagonService'
import {
  ICheckInOut,
  IConstructionWagon,
  ITaskData,
} from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import RadioItems, { IRadioItems } from 'src/ui-elements/radio/RadioItems'
import Input from '../../../ui-elements/input/Input'
import { classNames } from '../../../utility/utils'

interface IWagonCheck {
  isCheckIn: boolean
  show: boolean
  projectId: number
  wagonId: number
  close: () => void
  onSubmit?: () => void
  isEditing?: boolean
}

export const statusTypes = (_disable: boolean): IRadioItems[] => {
  return [
    {
      id: 'green',
      name: <div className={'h-25'} />,
      classNames: 'green_radio',
    },
    {
      id: 'yellow',
      name: <div className={'h-25'} />,
      classNames: 'yellow_radio',
    },
    {
      id: 'red',
      name: <div className={'h-25'} />,
      classNames: 'red_radio',
    },
  ]
}

export const checkOutText = (t: any) => [
  t(
    'carriage_that_now_leaves_the_roof_area_has_no_outstanding_work_to_prevent_the_next_carriages',
  ),
  t(
    'carriage_that_now_leaves_the_area_has_no_material_or_equipment_left_in_the_area',
  ),
  t('carriage_that_is_now_leaving_the_area_has_cleared_and_vacuumed_the_area'),
  t(
    'carriage_that_is_now_leaving_the_area_has_checked_that_the_area_is_safe_for_others_to_move_in',
  ),
  t(
    'carriage_that_is_now_leaving_the_area_has_registered_all_damage_that_has_occurred_to_the_floor_walls_roof_and_any_installations_or_equipment',
  ),
]

export const checkInText = (t: any) => [
  t(
    'area_in_which_the_wagon_will_now_start_has_no_outstanding_work_to_prevent_the_work_to_be_carried_out_in_this_wagon',
  ),
  t('area_has_no_material_or_equipment_left_in_the_area_that_hinders_the_work'),
  t('area_has_been_cleared_and_vacuumed'),
  t('area_is_safe_to_work_in'),
  t(
    'all_damage_that_has_occurred_to_floors_walls_ceilings_and_any_installations_or_equipment_is_registered_before_start_up',
  ),
]

const WagonCheckInOut = ({
  isCheckIn,
  show,
  wagonId,
  projectId,
  close,
  onSubmit,
  isEditing = false,
}: IWagonCheck) => {
  const styleClass = {
    root: classNames('bg-white', 'w-screen', 'h-screen', 'relative'),
    container: classNames('pl-6', 'pr-2', 'py-2', 'overflow-y-auto'),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'ml-2',
      'my-2',
      'text-sm',
      'font-light',
    ),
    label: classNames(
      'flex',
      'items-center',
      'text-sm',
      'text-gray-700',
      'p-2 pl-0',
    ),
    row: classNames(
      'flex',
      'flex-nowrap',
      'pl-1',
      'justify-between',
      'items-start',
      'mb-2',
    ),
    inputGroup: classNames('w-full', 'flex', 'flex-row'),
  }

  const { t } = useTranslation()

  const getLable = (index: number): string => {
    return isCheckIn ? checkInText(t)[index] : checkOutText(t)[index]
  }

  useEffect(() => {
    getConstructionWagon(wagonId).then((res) => {
      setWagon(res)
      setActualStaffing(
        res.actual_staffing && !isNaN(res.actual_staffing)
          ? +res.actual_staffing
          : 0,
      )
      setActualWorkHours(
        res.actual_work_hours && !isNaN(res.actual_work_hours)
          ? +res.actual_work_hours
          : 0,
      )
    })

    if (isEditing && wagonId) {
      getWagonCheckInOuts(wagonId).then((res: ICheckInOut[]) => {
        const checkData = res
          .filter((item) => item.is_check_in === isCheckIn)
          .pop()
        if (checkData && checkData.id) {
          setCheckId(checkData?.id)
          setNoEquipmentLeft(checkData?.no_equipment_left?.color)
          setNoOutstandingWork(checkData?.no_outstanding_work?.color)
          setClearedAndVacuumed(checkData?.cleared_and_vacuumed?.color)
          setSafeToWorkIn(checkData?.safe_to_work_in?.color)
          setDamageIsRegistered(checkData?.damage_is_registered?.color)

          setTasks({
            noEquipmentLeft: checkData?.no_equipment_left?.tasks,
            noOutstandingWork: checkData?.no_outstanding_work?.tasks,
            clearedAndVacuumed: checkData?.cleared_and_vacuumed?.tasks,
            safeToWorkIn: checkData?.safe_to_work_in?.tasks,
            damageIsRegistered: checkData?.damage_is_registered?.tasks,
          })
        }
      })
    }
  }, [wagonId])

  const [checkId, setCheckId] = useState<number>(0)
  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [noOutstandingWork, setNoOutstandingWork] = useState<string>('')
  const [noEquipmentLeft, setNoEquipmentLeft] = useState<string>('')
  const [clearedAndVacuumed, setClearedAndVacuumed] = useState<string>('')
  const [safeToWorkIn, setSafeToWorkIn] = useState<string>('')
  const [damageIsRegistered, setDamageIsRegistered] = useState<string>('')
  const [signature, setSignature] = useState<any>()

  const [noOutstandingWorkError, setNoOutstandingWorkError] =
    useState<string>('')
  const [noEquipmentLeftError, setNoEquipmentLeftError] = useState<string>('')
  const [clearedAndVacuumedError, setClearedAndVacuumedError] =
    useState<string>('')
  const [safeToWorkInError, setSafeToWorkInError] = useState<string>('')
  const [damageIsRegisteredError, setDamageIsRegisteredError] =
    useState<string>('')
  const [signatureError, setSignatureError] = useState<string>('')
  const [actualStaffingError, setActualStaffingError] = useState('')
  const [actualWorkHoursError, setActualWorkHoursError] = useState('')
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const [newTaskType, setNewTaskType] = useState<string>('')
  const [tasks, setTasks] = useState<any>({
    noOutstandingWork: [],
    noEquipmentLeft: [],
    clearedAndVacuumed: [],
    safeToWorkIn: [],
    damageIsRegistered: [],
  })
  const [actualStaffing, setActualStaffing] = useState<number>()
  const [actualWorkHours, setActualWorkHours] = useState<number>()

  const onOutstandingWorkChange = (e: any) => {
    setNoOutstandingWork(e.target.value)
    if (e.target.value !== 'green' && tasks?.noOutstandingWork?.length === 0) {
      setNoOutstandingWorkError(
        t('issues_must_be_created_to_select_yellow_or_red_level'),
      )
      return
    }
    setNoOutstandingWorkError('')
  }

  const onEquipmentChange = (e: any) => {
    setNoEquipmentLeft(e.target.value)
    if (e.target.value !== 'green' && tasks?.noEquipmentLeft?.length === 0) {
      setNoEquipmentLeftError(
        t('issues_must_be_created_to_select_yellow_or_red_level'),
      )
      return
    }
    setNoEquipmentLeftError('')
  }

  const onCleanlinessChange = (e: any) => {
    setClearedAndVacuumed(e.target.value)
    if (e.target.value !== 'green' && tasks?.clearedAndVacuumed?.length === 0) {
      setClearedAndVacuumedError(
        t('issues_must_be_created_to_select_yellow_or_red_level'),
      )
      return
    }
    setClearedAndVacuumedError('')
  }

  const onSafetyChange = (e: any) => {
    setSafeToWorkIn(e.target.value)
    if (e.target.value !== 'green' && tasks?.safeToWorkIn?.length === 0) {
      setSafeToWorkInError(
        t('issues_must_be_created_to_select_yellow_or_red_level'),
      )
      return
    }
    setSafeToWorkInError('')
  }

  const onDamageChange = (e: any) => {
    setDamageIsRegistered(e.target.value)
    if (e.target.value !== 'green' && tasks?.damageIsRegistered?.length === 0) {
      setDamageIsRegisteredError(
        t('issues_must_be_created_to_select_yellow_or_red_level'),
      )
      return
    }
    setDamageIsRegisteredError('')
  }

  const addTask = (type: string) => {
    setShowTaskModal(true)
    setNewTaskType(type)
  }

  const onChangeActualStaffing = (e: any) => {
    setActualStaffing(e.target.value)
    if (e.target.value >= 0) setActualStaffingError('')
  }

  const onChangeActualWorkingHours = (e: any) => {
    setActualWorkHours(e.target.value)
    if (e.target.value >= 0) setActualWorkHoursError('')
  }

  const hasValidationErrors = () => {
    const values = [
      noOutstandingWorkError,
      noEquipmentLeftError,
      clearedAndVacuumedError,
      safeToWorkInError,
      damageIsRegisteredError,
      signatureError,
    ]
    const found = values.find((item) => item !== '')
    return !!found
  }

  const submitCheckIn = (e: any) => {
    e.preventDefault()

    let error = false

    if (!noOutstandingWork) {
      setNoOutstandingWorkError(t('add_status'))
      error = true
    }

    if (!noEquipmentLeft) {
      setNoEquipmentLeftError(t('add_status'))
      error = true
    }

    if (!clearedAndVacuumed) {
      setClearedAndVacuumedError(t('add_status'))
      error = true
    }

    if (!safeToWorkIn) {
      setSafeToWorkInError(t('add_status'))
      error = true
    }

    if (!damageIsRegistered) {
      setDamageIsRegisteredError(t('add_status'))
      error = true
    }
    if (!signature) {
      setSignatureError(t('add_your_signature_here'))
      error = true
    }

    if (!isCheckIn && !(actualStaffing && actualStaffing >= 0)) {
      setActualStaffingError(t('required'))
      error = true
    }

    if (!isCheckIn && !(actualWorkHours && actualWorkHours >= 0)) {
      setActualWorkHoursError(t('required'))
      error = true
    }

    if (!error && !hasValidationErrors()) {
      const createData = {
        no_outstanding_work: noOutstandingWork,
        no_equipment_left: noEquipmentLeft,
        cleared_and_vacuumed: clearedAndVacuumed,
        safe_to_work_in: safeToWorkIn,
        damage_is_registered: damageIsRegistered,
        signature,
        no_outstanding_work_tasks: tasks.noOutstandingWork,
        no_equipment_left_tasks: tasks.noEquipmentLeft,
        cleared_and_vacuumed_tasks: tasks.clearedAndVacuumed,
        safe_to_work_in_tasks: tasks.safeToWorkIn,
        damage_is_registered_tasks: tasks.damageIsRegistered,
        actual_staffing: actualStaffing,
        actual_work_hours: actualWorkHours,
        is_check_in: isCheckIn,
      }
      const editData = {
        id: checkId,
        no_outstanding_work: noOutstandingWork,
        no_equipment_left: noEquipmentLeft,
        cleared_and_vacuumed: clearedAndVacuumed,
        safe_to_work_in: safeToWorkIn,
        damage_is_registered: damageIsRegistered,
        signature,

        no_outstanding_work_tasks: tasks.noOutstandingWork,
        no_equipment_left_tasks: tasks.noEquipmentLeft,
        cleared_and_vacuumed_tasks: tasks.clearedAndVacuumed,
        safe_to_work_in_tasks: tasks.safeToWorkIn,
        damage_is_registered_tasks: tasks.damageIsRegistered,
        actual_staffing: actualStaffing,
        actual_work_hours: actualWorkHours,
        is_check_in: isCheckIn,
      }

      if (isEditing) {
        editCheckInOut(checkId, editData).then(() => {
          if (onSubmit) {
            onSubmit()
          } else {
            close()
          }
        })
      } else {
        createCheckInWagon(wagonId, createData).then(() => {
          if (onSubmit) {
            onSubmit()
          } else {
            close()
          }
        })
      }
    }
  }

  const setNewTasks = (type: string, task: ITaskData) => {
    const cloneTasks = { ...tasks }
    if (cloneTasks[type]) {
      cloneTasks[type].push(task)
    }
    setTasks(cloneTasks)
    switch (type) {
      case 'noOutstandingWork':
        const e1 = { target: { value: noOutstandingWork } }
        onOutstandingWorkChange(e1)
        break
      case 'noEquipmentLeft':
        const e2 = { target: { value: noEquipmentLeft } }
        onEquipmentChange(e2)
        break
      case 'clearedAndVacuumed':
        const e3 = { target: { value: clearedAndVacuumed } }
        onCleanlinessChange(e3)
        break
      case 'safeToWorkIn':
        const e4 = { target: { value: safeToWorkIn } }
        onSafetyChange(e4)
        break
      case 'damageIsRegistered':
        const e5 = { target: { value: damageIsRegistered } }
        onDamageChange(e5)
        break
    }
  }

  const getTasksLength = (type: string) => {
    return tasks[type]?.length
  }

  return (
    <Modal
      show={show}
      closeModal={close}
      title={
        isCheckIn
          ? t('check_in_the_checklist_for_wagon', { title: wagon?.title })
          : t('check_out_the_checklist_for_wagon', { title: wagon?.title })
      }
      size={'w-2/3'}
      maxWidth={800}
    >
      <div className={styleClass.container}>
        {noOutstandingWorkError && (
          <p className={styleClass.errorMessage}>{noOutstandingWorkError}</p>
        )}
        <div className={styleClass.row}>
          <label className={styleClass.label}>{getLable(0)}</label>
          <div
            className={'flex items-center w-full justify-between w-mx-content'}
          >
            <RadioItems
              disableTitle={t(
                'please_create_one_or_more_issues_to_select_medium_or_high',
              )}
              items={statusTypes(false)}
              onClick={onOutstandingWorkChange}
              value={noOutstandingWork}
              noMargin={true}
            />
            {
              <div className={'flex h-full items-center'}>
                <Icon
                  icon={Icons.PLUS}
                  className={
                    'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                  }
                  onClick={() => addTask('noOutstandingWork')}
                />
                <span
                  className={
                    'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                  }
                >
                  {getTasksLength('noOutstandingWork')}
                </span>
              </div>
            }
          </div>
        </div>

        {noEquipmentLeftError && (
          <p className={styleClass.errorMessage}>{noEquipmentLeftError}</p>
        )}
        <div className={styleClass.row}>
          <label className={styleClass.label}>{getLable(1)}</label>
          <div
            className={'flex items-center w-full justify-between w-mx-content'}
          >
            <RadioItems
              disableTitle={t(
                'please_create_one_or_more_issues_to_select_medium_or_high',
              )}
              items={statusTypes(false)}
              onClick={onEquipmentChange}
              value={noEquipmentLeft}
              noMargin={true}
            />
            {
              <div className={'flex h-full items-center'}>
                <Icon
                  icon={Icons.PLUS}
                  className={
                    'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                  }
                  onClick={() => addTask('noEquipmentLeft')}
                />
                <span
                  className={
                    'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                  }
                >
                  {getTasksLength('noEquipmentLeft')}
                </span>
              </div>
            }
          </div>
        </div>

        {clearedAndVacuumedError && (
          <p className={styleClass.errorMessage}>{clearedAndVacuumedError}</p>
        )}
        <div className={styleClass.row}>
          <label className={styleClass.label}>{getLable(2)}</label>
          <div
            className={'flex items-center w-full justify-between w-mx-content'}
          >
            <RadioItems
              disableTitle={t(
                'please_create_one_or_more_issues_to_select_medium_or_high',
              )}
              items={statusTypes(false)}
              onClick={onCleanlinessChange}
              value={clearedAndVacuumed}
              noMargin={true}
            />
            {
              <div className={'flex h-full items-center'}>
                <Icon
                  icon={Icons.PLUS}
                  className={
                    'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                  }
                  onClick={() => addTask('clearedAndVacuumed')}
                />
                <span
                  className={
                    'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                  }
                >
                  {getTasksLength('clearedAndVacuumed')}
                </span>
              </div>
            }
          </div>
        </div>

        {safeToWorkInError && (
          <p className={styleClass.errorMessage}>{safeToWorkInError}</p>
        )}
        <div className={styleClass.row}>
          <label className={styleClass.label}>{getLable(3)}</label>
          <div
            className={'flex items-center w-full justify-between w-mx-content'}
          >
            <RadioItems
              disableTitle={t(
                'please_create_one_or_more_issues_to_select_medium_or_high',
              )}
              items={statusTypes(false)}
              onClick={onSafetyChange}
              value={safeToWorkIn}
              noMargin={true}
            />
            {
              <div className={'flex h-full items-center'}>
                <Icon
                  icon={Icons.PLUS}
                  className={
                    'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                  }
                  onClick={() => addTask('safeToWorkIn')}
                />
                <span
                  className={
                    'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                  }
                >
                  {getTasksLength('safeToWorkIn')}
                </span>
              </div>
            }
          </div>
        </div>

        {damageIsRegisteredError && (
          <p className={styleClass.errorMessage}>{damageIsRegisteredError}</p>
        )}
        <div className={styleClass.row}>
          <label className={styleClass.label}>{getLable(4)}</label>
          <div
            className={'flex items-center w-full justify-between w-mx-content'}
          >
            <RadioItems
              disableTitle={t(
                'please_create_one_or_more_issues_to_select_medium_or_high',
              )}
              items={statusTypes(false)}
              onClick={onDamageChange}
              value={damageIsRegistered}
              noMargin={true}
            />
            {
              <div className={'flex h-full items-center'}>
                <Icon
                  icon={Icons.PLUS}
                  className={
                    'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                  }
                  onClick={() => addTask('damageIsRegistered')}
                />
                <span
                  className={
                    'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                  }
                >
                  {getTasksLength('damageIsRegistered')}
                </span>
              </div>
            }
          </div>
        </div>

        {!isCheckIn && (
          <div className={`${styleClass.inputGroup} my-2`}>
            <Input
              required={true}
              className={'w-full'}
              block={false}
              label={t('actual_working_hours')}
              type={'number'}
              onChange={onChangeActualWorkingHours}
              value={actualWorkHours}
              errorMessage={actualWorkHoursError}
            />

            <Input
              required={true}
              className={'w-full'}
              block={false}
              label={t('actual_staffing')}
              type={'number'}
              onChange={onChangeActualStaffing}
              value={actualStaffing}
              errorMessage={actualStaffingError}
            />
          </div>
        )}

        <div className={'pl-1'}>
          {signatureError && (
            <p className={styleClass.errorMessage}>{signatureError}</p>
          )}
          <SignatureInput
            width={715}
            height={225}
            errorMessage={''}
            label={t('add_your_signature_here')}
            required={true}
            data={(d: any) => {
              setSignature(d)
              setSignatureError('')
            }}
          />
        </div>
      </div>

      {showTaskModal && (
        <Modal
          show={showTaskModal}
          closeModal={() => setShowTaskModal(false)}
          title={t('new_issue_for_wagon_title', { title: wagon.title })}
          maxWidth={800}
        >
          <div className={''}>
            <ConstructionTaskForm
              projectId={projectId}
              parentId={0}
              parentType={'ChecklistParameter'}
              closeModal={() => setShowTaskModal(false)}
              updateList={() => {}}
              submitTask={(task) => setNewTasks(newTaskType, task)}
            />
          </div>
        </Modal>
      )}

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={() => close()}>
          {t('cancel')}
        </Button>
        <Button type={Button.ButtonType.PRIMARY} onClick={submitCheckIn}>
          {isEditing ? t('update') : t('add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default WagonCheckInOut
