import { IImage } from './OrgTypes'
import { request } from './service-utils'

export const getImages = (
  parentType: string,
  parentId: number,
  projectId: number,
): Promise<IImage[]> => {
  return request(
    `projects/${projectId}/parents/${parentType}/${parentId}/images`,
    'Get',
  )
}

export const deleteImage = (id: number) => {
  return request(`images/${id}`, 'DELETE', {}, true)
}

export const editImage = (image: Partial<IImage>): Promise<IImage> => {
  return request(`images/${image.id}`, 'PUT', image)
}

export const createImage = (
  data: Partial<IImage>,
  parentType: string,
  parentId: number,
  projectId: number,
): Promise<IImage> => {
  return request(
    `/projects/${projectId}/parents/${parentType}/${parentId}/multiple_images`,
    'POST',
    data,
  )
}

export const createImageToMultipleParents = (
  data: Partial<IImage>,
  parentType: string,
  parentIds: number[],
  projectId: number,
): Promise<IImage> => {
  return request(
    `/projects/${projectId}/parents/${parentType}/multiple_images`,
    'POST',
    { ...data, parent_ids: parentIds },
  )
}

export const deleteBulkImages = (
  data: number[],
  projectId: number,
): Promise<IImage> => {
  return request(`/projects/${projectId}/images/remove_multiple`, 'POST', {
    ids: data,
  })
}

export const getDocuments = (
  projectId: number,
  parentType: string,
  parentId: number,
) => {
  return request(
    `/projects/${projectId}/parents/${parentType}/${parentId}/documents`,
    'GET',
  )
}
