import { useMemo, useState } from 'react'
import * as React from 'react'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { getRandomId } from 'src/utility/getRandomId'
import PaginationButton from './PaginationButton'

interface IPagination {
  currentPage: number
  onPageUpdate: (newPageIndex: number) => void
  totalPages: number
  maxNumberOfButtonsToShow?: number
}

const Pagination: React.FC<IPagination> = ({
  onPageUpdate,
  currentPage,
  totalPages,
  maxNumberOfButtonsToShow = 5,
}) => {
  const [paginationId] = useState(getRandomId())

  if (maxNumberOfButtonsToShow % 2 !== 1) {
    throw Error('[Pagination.tsx]: numberOfButtons must be an odd number')
  }

  const indexesToShow = useMemo(() => {
    const generateIndexes = (startIndex: number, endIndex: number) => {
      const res: number[] = []
      for (let i = startIndex; i < endIndex + 1; i++) {
        res.push(i)
      }
      return res
    }

    const isBelowMaxPages = totalPages <= maxNumberOfButtonsToShow
    const half = Math.floor(maxNumberOfButtonsToShow / 2)
    const shouldStartAtZero = currentPage - half < 1
    const shouldStartAtMax = currentPage + half > totalPages

    if (isBelowMaxPages) {
      return generateIndexes(1, totalPages)
    }

    if (shouldStartAtZero) {
      return generateIndexes(1, maxNumberOfButtonsToShow)
    } else if (shouldStartAtMax) {
      return generateIndexes(
        totalPages - maxNumberOfButtonsToShow + 1,
        totalPages,
      )
    } else {
      return generateIndexes(currentPage - half, currentPage + half)
    }
  }, [currentPage, maxNumberOfButtonsToShow, totalPages])

  const checkLowerBound = (pageIndex: number) => pageIndex > 0
  const checkUpperBound = (pageIndex: number) => pageIndex <= totalPages

  const onPageClick = (pageIndex: number) => {
    if (checkLowerBound(pageIndex) && checkUpperBound(pageIndex)) {
      onPageUpdate(pageIndex)
    }
  }

  return totalPages > 0 ? (
    <div className="flex w-full justify-center">
      <PaginationButton
        className={'px-1'}
        disabled={currentPage === 1}
        onClick={() => onPageClick(currentPage - 1)}
      >
        <MaterialIcon className={'text-xs'} icon="chevron_left" />
      </PaginationButton>
      {indexesToShow.map((item) => (
        <PaginationButton
          key={`pagination-${paginationId}-${item}`}
          onClick={() => onPageClick(item)}
          selected={item === currentPage}
        >
          {item}
        </PaginationButton>
      ))}
      <PaginationButton
        className={'px-1'}
        disabled={currentPage === totalPages}
        onClick={() => onPageClick(currentPage + 1)}
      >
        <MaterialIcon className={'text-xs'} icon="chevron_right" />
      </PaginationButton>
    </div>
  ) : null
}

export default Pagination
