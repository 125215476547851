import { ExportData } from 'src/context/ExportContext/ExportTypes'

export const testSystemGroupExportTemplate: ExportData = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/test_system_groups/export',
    method: 'POST',
  },
  name: 'test_system_Export',
  runInBg: true,
}
