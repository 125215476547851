import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { MenuContext } from 'src/context/MenuContextProvider/MenuContext'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor, BadgeSize } from 'src/ui-elements/badge/BadgeEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { capFirstLetter, classNames } from 'src/utility/utils'

import NotificationCenter from '../../notifications/NotificationCenter'
import UserIcon from '../../user/UserIcon'
import { IMenuItem } from '../Menu'

interface IMenuItemProps {
  item: IMenuItem
  index: number
  menuExpanded: boolean
  expandedItem: number
  expandItem: (id: number) => void
  showComponent?: boolean
  componentData?: any
}

const MenuItem: React.FC<IMenuItemProps> = ({
  item,
  index,
  menuExpanded,
  expandItem,
  expandedItem,
  componentData,
  showComponent: _showComponent,
}) => {
  const styleClass = {
    mainRoot: (active: number, index: number, data: any, isExpanded: boolean) =>
      classNames(
        !isExpanded
          ? 'justify-center'
          : data?.name === 'UserIcon'
            ? 'justify-beginning'
            : 'justify-between',
        isExpanded ? 'pr-4' : '',
        'py-3',
        'px-2',
        'flex',
        'items-center',
        'cursor-pointer',
        active === index
          ? 'bg-blue-100 border-blue-root border-l-4'
          : 'hover:bg-blue-100',
        'text-sm',
        data && data.name === 'Notifications' ? 'border-b border-gray-300' : '',
      ),
    rootExpanded: () =>
      classNames(
        'bg-white',
        'menu-item',
        'flex',
        'py-2',
        'items-center',
        'text-white',
        'hover:bg-white',
        'cursor-pointer',
        'px-6',
      ),
    submenu: (isExpanded: boolean, active: boolean) =>
      classNames(
        'menu-item',
        'cursor-pointer',
        'p-1',
        isExpanded ? 'pl-8 flex items-center' : 'pl-5',
        'text-xs',
        active
          ? 'bg-blue-root text-white'
          : 'hover:bg-blue-100 text-gray-800 bg-blue-root-tab-wash',
        'border-blue-root border-l-8',
      ),
    icon: (isExpanded: boolean, main: boolean) =>
      classNames(
        !main ? 'transform scale-75' : '',
        isExpanded ? (main ? 'pr-4' : 'pr-2') : 'pr-0',
      ),
    itemName: (data: any) =>
      classNames(
        'no-underline',
        data && data.name === 'UserIcon' ? 'pl-4' : '',
        data && data.name === 'Notifications' ? 'pl-4' : '',
      ),
  }

  const history = useHistory()

  const { state: menuState, actions } = useContext(MenuContext)

  return (
    <>
      <div
        id="RouterNavLink"
        title={item.name}
        onClick={() => {
          expandItem(index === expandedItem ? -1 : index)
          actions.toggleActiveMenu(index)
          if (componentData?.name === 'Notifications') {
            return
          }
          actions.updateTitle(capFirstLetter(item.name))
          if (!item.children) {
            history.push(item.link)
          }
        }}
        className={'no-underline'}
      >
        <div
          className={styleClass.mainRoot(
            menuState?.activeTab,
            index,
            componentData,
            menuExpanded,
          )}
        >
          <div
            className={classNames(
              'menu-item',
              'flex',
              'items-center',
              componentData?.name !== 'UserIcon' && 'px-2',
              expandedItem === index ? 'text-blue-root' : 'text-gray-600',
              'cursor-pointer',
              menuExpanded ? 'justify-start' : '',
            )}
          >
            {componentData ? (
              componentData.name === 'UserIcon' ? (
                <UserIcon
                  userId={componentData.props.user.id}
                  medium={true}
                  firstName={''}
                  lastName={''}
                  image_url={componentData.props.user.image_url}
                />
              ) : (
                <NotificationCenter
                  userId={componentData.props.user.id}
                  menuExpanded={menuExpanded}
                />
              )
            ) : (
              <item.activeIcon
                className={classNames(
                  'fill-blue-root',
                  'text-xxl',
                  menuExpanded && 'mr-4',
                )}
              />
            )}
            {menuExpanded && (
              <div className={styleClass.itemName(componentData || null)}>
                {capFirstLetter(item.name)}
                {item.isNew && (
                  <Badge
                    text={'NEW'}
                    color={BadgeColor.BLUE}
                    size={BadgeSize.SMALL}
                    className={'ml-3'}
                  />
                )}
              </div>
            )}
            {!menuExpanded && item.children && (
              <p className={'text-xxs text-blue-root flex self-start'}>*</p>
            )}
          </div>
          {item.children && menuExpanded && (
            <div className={'float-right'}>
              {menuState.activeTab === index ? (
                <Icon
                  icon={
                    expandedItem === index
                      ? Icons.ARROW_UP_BLUE
                      : Icons.ARROW_DOWN_BLUE
                  }
                />
              ) : (
                <Icon
                  icon={
                    expandedItem === index
                      ? Icons.ARROW_UP_BLUE
                      : Icons.ARROW_DOWN_BLUE
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className={'h-auto w-auto shadow-inner-md'}>
        {item.children &&
          expandedItem === index &&
          item.children.map((subitem, key) => {
            return (
              <Link
                title={subitem.name}
                key={key}
                to={subitem.link}
                onClick={() => {
                  actions.updateTitle(capFirstLetter(subitem.name))
                  actions.toggleActiveSubmenu(key)
                }}
                className={'no-underline'}
              >
                <div
                  className={styleClass.submenu(
                    menuExpanded,
                    menuState.activeSubmenu === key,
                  )}
                >
                  {menuState.activeSubmenu !== key ? (
                    <subitem.icon className={'fill-blue-root text-lg mr-2'} />
                  ) : (
                    <subitem.activeIcon className={'fill-white text-lg mr-2'} />
                  )}
                  {menuExpanded && (
                    <div className={'no-underline'}>
                      {capFirstLetter(subitem.name)}
                    </div>
                  )}
                </div>
              </Link>
            )
          })}
      </div>
    </>
  )
}

export default MenuItem
