import { IDiscipline, IDelivery } from './OrgTypes'
import { request } from './service-utils'

export interface IDisciplineWithDelivery extends IDiscipline {
  deliveries: IDelivery[]
}

export const createDiscipline = (
  discipline: IDiscipline,
  projectId: number,
): Promise<any> => {
  return request(`projects/${projectId}/disciplines`, 'POST', discipline)
}

export const editDiscipline = (
  discipline: Partial<IDiscipline>,
): Promise<any> => {
  return request(`disciplines/${discipline.id}`, 'PUT', discipline)
}

export const swapOrderDiscipline = (
  disciplineId: number,
  sequence: number,
): Promise<IDiscipline[]> => {
  return request(`disciplines/${disciplineId}/change_order`, 'PUT', {
    sequence: sequence,
  })
}

export const deleteDiscipline = (discipline: IDiscipline): Promise<any> => {
  return request(`disciplines/${discipline.id}`, 'DELETE', {}, true)
}

export const getProjectDisciplines = (
  projectId: number,
): Promise<IDiscipline[]> => {
  return request(`projects/${projectId}/disciplines`, 'GET')
}

export const getDeliveryDisciplineCanvasData = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(
    `/projects/${projectId}/delivery_discipline_canvas`,
    'POST',
    data,
  )
}

export const getDeliveryMainprocessCanvasData = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(
    `/projects/${projectId}/delivery_main_process_canvas`,
    'POST',
    data,
  )
}

export const getDeliveryMainprocessCanvasTeamData = (
  projectId: number,
  data: any,
): Promise<IDelivery[]> => {
  return request(`/projects/${projectId}/delivery_team_canvas`, 'POST', data)
}

export const getContractDisciplines = (
  contractId: number,
): Promise<IDiscipline[]> => {
  return request(`contracts/${contractId}/disciplines`, 'GET')
}

export const getDisciplineTree = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/disciplines/tree`, 'GET')
}

export const getProjectContractDisciplines = (
  projectId: number,
  body?: { contract: number[] },
): Promise<IDiscipline[]> => {
  return request(
    `/projects/${projectId}/contracts/disciplines`,
    'POST',
    body ? body : { contract: [] },
  )
}

export const getUserDisciplines = (
  projectId: number,
  userId: number,
): Promise<IDiscipline[]> => {
  return request(`/projects/${projectId}/users/${userId}/disciplines`, 'GET')
}

// Get disciplines
export const getDisciplines = (projectId: number): Promise<IDiscipline[]> => {
  return request(`/projects/${projectId}/disciplines`, 'GET')
}

export const getDiscipline = (disciplineId: number): Promise<IDiscipline> => {
  return request(`/disciplines/${disciplineId}`, 'GET')
}
