import { createContext, FC, useState, PropsWithChildren } from 'react'
import {
  IUploadResponse,
  IUploadStat,
  IUploadState,
  UPLOAD_STATE,
} from '../types'
import { uploadFile } from '../util'

export interface UploadedFiles {
  [key: string]: IUploadResponse
}

interface FileQueue {
  [key: string]: IUploadState
}

interface FileUploadContextState {
  files: FileQueue
  uploadedFiles: UploadedFiles
  addFileToQueue: (file: File, url: string) => Promise<void>
  addFilesToQueue: (
    files: File[],
    url: string,
    accessToken?: string,
  ) => Promise<void>
  clearQueue: () => void
  removeFileFromQueue: (file: File) => void
  cancelFile: (file: File) => void
}

export const FileUploadContext = createContext<FileUploadContextState>({
  files: {},
  uploadedFiles: {},
  addFileToQueue: (_: File, __: string) => new Promise((resolve) => resolve()),
  clearQueue: () => {
    /*noop*/
  },
  cancelFile: (_: File) => {
    /*noop*/
  },
  removeFileFromQueue: (_: File) => {
    /*noop*/
  },
  addFilesToQueue: (_: File[], __: string, ___?: string) =>
    new Promise((resolve) => resolve()),
})

const UploadProvider: FC<PropsWithChildren> = ({ children }) => {
  const [files, setFiles] = useState<FileQueue>({})
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>({})

  const clearQueue = () => {
    // cancel all uploads
    Object.values(files).forEach((file) => {
      if (file.uploadStatus.status === UPLOAD_STATE.UPLOADING) {
        file.request.abort()
      }
    })
    setFiles({})
    setUploadedFiles({})
  }

  const removeFileFromQueue = (file: File) => {
    const newFiles = { ...files }
    delete newFiles[file.name]
    setFiles(newFiles)
  }

  const cancelFile = (file: File) => {
    _onError(file, 'canceled', true)
  }

  const addFileToQueue = async (file: File, url: string) => {
    // check if file already exists in queue
    const request = await uploadFile(
      file,
      url,
      (progress, loaded) => _onProgress(file, progress, loaded),
      (res) => _onSuccess(file, res),
      (err) => _onError(file, err),
    )
    // create upload stat
    const uploadStatus: IUploadStat = {
      size: file.size,
      loaded: 0,
      progress: 0,
      status: UPLOAD_STATE.UPLOADING,
    }
    // add file to queue
    setFiles((prevFiles) => ({
      ...prevFiles,
      [file.name]: { fileName: file.name, uploadStatus, request },
    }))
  }

  const addFilesToQueue = async (
    files: File[],
    url: string,
    accessToken?: string,
  ) => {
    const newFiles = files.filter((file) => !files[file.name])
    const requests = newFiles.map(async (file) => {
      const request = await uploadFile(
        file,
        url,
        (progress, loaded) => _onProgress(file, progress, loaded),
        (res) => _onSuccess(file, res),
        (err) => _onError(file, err),
        accessToken,
      )
      const ret: IUploadState = {
        fileName: file.name,
        uploadStatus: {
          size: file.size,
          loaded: 0,
          progress: 0,
          status: UPLOAD_STATE.UPLOADING,
        },
        request,
      }
      return ret
    })
    const allFiles = await Promise.all(requests)
    const newFilesQueue = allFiles.reduce((acc, curr) => {
      return { ...acc, [curr.fileName]: curr }
    }, {})
    setFiles(newFilesQueue)
  }

  const _onProgress = (file: File, progress: number, loaded: number) => {
    const uploadStatus: IUploadStat = {
      size: file.size,
      loaded,
      progress,
      status: UPLOAD_STATE.UPLOADING,
    }
    _updateFile(file, uploadStatus)
  }

  const _onError = (file: File, error: any, cancel = false) => {
    const uploadStatus: IUploadStat = {
      size: file.size,
      loaded: 0,
      progress: 0,
      status: UPLOAD_STATE.ERROR,
      error,
    }
    _updateFile(file, uploadStatus, cancel)
  }

  const _onSuccess = (file: File, res: any) => {
    const uploadStatus: IUploadStat = {
      size: file.size,
      loaded: file.size,
      progress: 100,
      status: UPLOAD_STATE.UPLOADED,
    }
    _updateFile(file, uploadStatus)
    setUploadedFiles((prevUploadedFiles) => ({
      ...prevUploadedFiles,
      [file.name]: res,
    }))
  }

  const _updateFile = (
    file: File,
    uploadStatus: IUploadStat,
    cancel = false,
  ) => {
    if (cancel) {
      files[file.name].request.abort()
    }
    setFiles((prevFiles) => ({
      ...prevFiles,
      [file.name]: { ...files[file.name], uploadStatus },
    }))
  }

  return (
    <FileUploadContext.Provider
      value={{
        files,
        uploadedFiles,
        addFileToQueue,
        cancelFile,
        clearQueue,
        removeFileFromQueue,
        addFilesToQueue,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  )
}

export default UploadProvider
