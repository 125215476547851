import { IEnvironmentConfig } from './IEnvironmentConfig'

export const localHostConfig: IEnvironmentConfig = {
  host: "localhost:3000",
  exportBaseUrl: "https://export-test.bygg.io/",
  frontendBaseUrl: "http://localhost:3000/",
  organizationBaseUrl: "https://org-test.bygg.io/",
  proxyBaseUrl: "https://proxy-test.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-test.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-test.bygg.io/",
  bimServiceBaseUrl: "https://bim-service-test.bygg.io/",
  kpiBaseUrl: "https://kpi-test.bygg.io/",
};
