import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IProjectMilestoneResponse } from 'src/service/ProcessService'
import { capFirstLetter, classNames } from '../../utility/utils'
import MilestoneCanvasIcon from './MilestoneCanvasIcon'

export interface IMilestoneTimelineItemProps {
  getItemProps: any
  item: any
  openMilestone: (data: any, timelineContext: any) => void
  itemContext: any
  timelineContext: any
  alwaysShowLabel?: boolean
}

const styleClass = {
  item: classNames('flex', 'overflow-visible', 'cursor-pointer', 'justify-end'),
  floatLabel: classNames(
    'fixed',
    'bg-white',
    'text-sm',
    'shadow',
    'p-3',
    'flex',
    'z-30',
    'flex-col',
  ),
  floatLabelSmallText: classNames('text-xxs', 'py-1'),
}

const MilestoneTimelineItem = ({
  getItemProps,
  item,
  openMilestone,
  itemContext,
  timelineContext,
  alwaysShowLabel: alwaysShowLabel,
}: IMilestoneTimelineItemProps) => {
  const [showFloatLabel, setShowFloatLabel] = useState<{
    left: number
    top: number
  }>({ left: 0, top: 0 })
  const [showLabel, setShowLabel] = useState<boolean>(false)
  const [selectedLabel, setSelectedLabel] = useState<number>(-1)

  const { t } = useTranslation()

  const onMouseEnter = (e: any, milestoneId: number) => {
    setShowLabel(true)
    const target = e.target.getBoundingClientRect()
    const tmpFloatLabel = showFloatLabel
    if (window.innerWidth - target.x < 200) {
      tmpFloatLabel.left = target.x - (200 - (window.innerWidth - target.x))
    } else {
      tmpFloatLabel.left = target.x + 20
    }
    tmpFloatLabel.top = target.y
    setShowFloatLabel(tmpFloatLabel)
    setSelectedLabel(milestoneId)
  }

  const onMouseLeave = () => {
    setShowLabel(false)
  }

  const milestone = item
  const st = moment(timelineContext.visibleTimeStart)
  const et = moment(timelineContext.visibleTimeEnd)
  return (
    <div
      className={'flex flex-col'}
      onMouseEnter={(e) => onMouseEnter(e, item.id)}
      onMouseLeave={onMouseLeave}
      onClick={() => openMilestone(item, timelineContext)}
    >
      <div
        {...getItemProps({
          style: {
            backgroundColor: 'transparent',
            color: item.color,
            zIndex: 15,
            borderStyle: 'solid',
            minWidth: '20px',
            borderColor: 'black',
            borderWidth: 0,
            borderBottomWidth: 3,
          },
        })}
      >
        <div
          className={styleClass.item}
          style={{
            height: itemContext.dimensions.height,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <span
            title={item.name}
            className="no-underline cursor-pointer"
            style={{ color: 'black' }}
          >
            {(et.diff(st) < 30000000000 || alwaysShowLabel) && item.record_id} (
            {item.done_children}/{item.done_children + item.open_children})
          </span>
          <div className="pt-3 -mr-2">
            <MilestoneCanvasIcon item={item as IProjectMilestoneResponse} />{' '}
          </div>
        </div>
      </div>
      {showLabel && selectedLabel === milestone.id ? (
        <div
          className={styleClass.floatLabel}
          style={{ left: showFloatLabel.left, top: showFloatLabel.top }}
        >
          <div>
            <span className={'float-left'}>
              <MilestoneCanvasIcon item={item as IProjectMilestoneResponse} />
            </span>
            {milestone.name}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {milestone.main_process ? milestone.main_process.name : ''}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {`${capFirstLetter(t('discipline'))}:`}{' '}
            {item.discipline ? item.discipline.name : ''}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {`${capFirstLetter(t('responsible'))}:`}{' '}
            {item.responsible
              ? item.responsible.firstName + ' ' + item.responsible.lastName
              : ''}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {`${capFirstLetter(t('deadline'))}:`}{' '}
            {item.deadline ? moment(item.deadline).format('L') : ''}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default MilestoneTimelineItem
