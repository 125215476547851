import { DependencyList, useCallback, useEffect, useState } from 'react'

type FetchFunc<T> = () => Promise<T>

const useServiceData = <T>(dataLoader: FetchFunc<T>, deps: DependencyList) => {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(true)

  const reload = useCallback(async () => {
    setLoading(true)
    const res = await dataLoader()
    setData(res)
    setLoading(false)
  }, [dataLoader])

  useEffect(() => {
    reload()
  }, deps)

  return { data, reload, loading }
}

export default useServiceData
