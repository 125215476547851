import ArrowLeft from '@icons/keyboard_arrow_left.svg'
import Hamburger from '@icons/menu.svg'
import {
  useContext,
  useEffect,
  useState,
  useRef,
  memo,
  FC,
  SVGProps,
  ReactNode,
} from 'react'
import { twMerge } from 'tailwind-merge'
import MenuItem from 'src/components/menu/internals/MenuItem'
import TopMenu from 'src/components/menu/internals/TopMenu'
import { MenuContext } from 'src/context/MenuContextProvider/MenuContext'
import { useClickOutside } from 'src/hooks/useClickOutside'
import { useMenuItems } from 'src/hooks/useMenuItems'
import Root from 'src/mobile/Root'
import { IProject, IProjectData, IUserData } from 'src/service/OrgTypes'
import ResolutionHandler from 'src/service/ResolutionHandler'

import { classNames } from 'src/utility/utils'

export interface IMenuProps {
  user: IUserData
  projects: IProjectData[]
  currentProject: IProject
  children?: ReactNode | null
}

export interface IMenuItem {
  name: string
  link: string
  icon: FC<SVGProps<SVGElement>> & {
    title?: string
  }
  activeIcon: FC<SVGProps<SVGElement>> & {
    title?: string
  }
  show: boolean
  children?: IMenuItem[]
  componentData?: object
  isNew?: boolean
}

const styleClass = {
  root: (menuIsHidden: boolean) =>
    classNames('root-parent', !menuIsHidden ? 'compact' : ''),
  sidebar: {
    root: (menuIsExpanded: boolean, active: number, menuIsHidden: boolean) =>
      classNames(
        menuIsExpanded ? 'w-menu-width' : 'max-w-14',
        active === 1 ? '' : 'overflow-y-scroll',
        !menuIsHidden && 'hidden sm:block',
        'bg-white',
        'sidebar',
        'h-full',
        'menuScroll',
        'shadow-md',
      ),
    menuDropdown: (menuIsExpanded: boolean) =>
      classNames(
        menuIsExpanded ? 'flex flex-col' : '',
        'w-full',
        'text-blue-one',
        'text-sm',
      ),
    content: classNames('w-full'),
    button: (menuIsExpanded: boolean) =>
      classNames(
        'hidden',
        'sm:flex',
        'justify-between',
        'cursor-pointer',
        menuIsExpanded ? 'float-right' : 'm-auto',
        'p-4',
        'fill-blue-root',
        'text-xxl',
      ),
  },
  content: classNames('header'),
  dropdownOverride: classNames('topmenu-dropdown'),
}

const Menu: FC<IMenuProps> = ({ currentProject, user, projects, children }) => {
  const menuRef = useRef<HTMLDivElement | null>(null)
  const topMenuRef = useRef<HTMLDivElement | null>(null)

  const [menuExpanded, setMenuExpanded] = useState<boolean>(false)
  const [menuHidden, setMenuHidden] = useState<boolean>(false)
  const [topMenuExpanded, setTopMenuExpanded] = useState<boolean>(false)
  const [expandedItem, setExpandedItem] = useState<number>(-1)

  useClickOutside(menuRef, () => setMenuExpanded(false))
  const { state: menuState, actions } = useContext(MenuContext)

  const { activeItem, menuItems } = useMenuItems({
    currentProject,
    user,
  })

  useEffect(() => {
    actions.toggleActiveMenu(activeItem?.index ?? 3)
    if (activeItem?.index && !activeItem?.isMainItem) {
      setExpandedItem(activeItem?.index)
      actions.toggleActiveSubmenu(+activeItem?.subIndex || 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem])

  const toggleMenu = () => {
    setMenuExpanded((prevState) => !prevState)
  }

  const toggleMenuVisibility = () => {
    setMenuHidden((prevState) => !prevState)
    setMenuExpanded(false)
  }

  const toggleTopMenu = () => {
    setTopMenuExpanded((prevState) => !prevState)
  }

  const expandItem = (id: number) => {
    setExpandedItem(id)
  }

  if (ResolutionHandler.shouldShowMobileVersion()) {
    return <Root />
  }

  return (
    <div className={styleClass.root(menuHidden)}>
      <div
        className={styleClass.sidebar.root(
          menuExpanded,
          menuState?.activeTab,
          menuHidden,
        )}
        ref={menuRef}
      >
        <button
          className={styleClass.sidebar.button(menuExpanded)}
          onClick={toggleMenu}
        >
          {menuExpanded ? <ArrowLeft /> : <Hamburger />}
        </button>
        <div className={styleClass.sidebar.content}>
          <div key="menu-group">
            <div className={styleClass.sidebar.menuDropdown(menuExpanded)}>
              {menuItems.map((item, itemKey) => {
                if (!item.show) {
                  return null
                }
                return (
                  <MenuItem
                    componentData={item.componentData}
                    item={item}
                    key={`menu-item-${itemKey}`}
                    index={itemKey}
                    menuExpanded={menuExpanded}
                    expandedItem={expandedItem}
                    expandItem={expandItem}
                    showComponent={item.show}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div ref={topMenuRef} className={styleClass.content}>
        <TopMenu
          projects={projects}
          currentProject={currentProject}
          mainMenuActive={menuExpanded}
          toggleTopMenu={toggleTopMenu}
          topMenuExpanded={topMenuExpanded}
          toggleMenuVisibility={toggleMenuVisibility}
          menuHidden={menuHidden}
        />
      </div>

      <div id="navigation-row" />

      <div className={twMerge('main-content', menuExpanded && 'opacity-50')}>
        {children}
      </div>
    </div>
  )
}

export default memo(Menu)
