import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEditConstructionTaskType } from 'src/query/construction/queries'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { IConstructionTaskType } from 'src/service/OrgTypes'
import { taskUnits } from 'src/service/SystemValues'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import BooleanInlineInputComponent from 'src/ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { toFixed } from 'src/utility/Utility'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import DateTimeInlineInputComponent from '../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'

interface IConstructionTaskTypeInspectorPanel {
  data: IConstructionTaskType
  open: boolean
  onClose: () => void
  projectId: number
  onUpdate: () => void
}

const ConstructionTaskTypeInspectorPanel: React.FC<
  IConstructionTaskTypeInspectorPanel
> = ({ data, open, onClose, projectId, onUpdate }) => {
  const { t } = useTranslation()
  const [isEstimate, setIsEstimate] = useState(true)
  const [constructionTaskType, setConstructionTaskType] =
    useState<IConstructionTaskType>(data)

  const editConstructionTaskType = useEditConstructionTaskType()

  const onChangeInput = async (update: Partial<IConstructionTaskType>) => {
    try {
      if (constructionTaskType?.id) {
        setConstructionTaskType({ ...constructionTaskType, ...update })
        await editConstructionTaskType
          .mutateAsync({
            ...convertUndefinedToNull(update),
            id: constructionTaskType?.id,
            project_id: projectId,
          })
          .then(() => onUpdate())
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onChangeIsEstimate = (value: any) => {
    setIsEstimate(value)
    const thisConstructionTaskType = constructionTaskType
    if (value) {
      thisConstructionTaskType.total_units = 0
      thisConstructionTaskType.hours_per_unit = 0
      thisConstructionTaskType.unit = ''
      setConstructionTaskType({ ...thisConstructionTaskType })
    }
  }

  return (
    <FixedPane
      title={constructionTaskType.title}
      show={open}
      onClose={() => onClose()}
      className={'w-[580px]'}
    >
      <InlineComponentsWrapper padding="left">
        <TextInlineInputCompontent
          label={t('title')}
          value={constructionTaskType?.title}
          validate={(newValue) => {
            if (!newValue?.length) {
              return t('required')
            }
            return
          }}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ title: newValue })
          }}
        />
        <SelectorInlineInputComponent
          getItems={() => getProjectDisciplines(projectId)}
          label={'discipline'}
          onValueSubmitted={(discipline_id) => {
            if (discipline_id) onChangeInput({ discipline_id })
          }}
          selectedId={constructionTaskType?.discipline_id}
          validate={(newValue) => {
            if (newValue === undefined) {
              return t('select_w_param', { param: t('discipline') })
            }
            return
          }}
          getItemLabel={(discipline) =>
            `${discipline?.shortName} - ${discipline?.name}`
          }
          initialItem={constructionTaskType?.discipline}
          inspectorPanel={true}
        />
        <BooleanInlineInputComponent
          label="suggested_values"
          onValueSubmitted={() => onChangeIsEstimate(!isEstimate)}
          value={isEstimate}
          showCheckBox
        />
        {!isEstimate && (
          <SelectorInlineInputComponent
            label={t('unit')}
            initialItem={{
              id: constructionTaskType.unit,
              name: t(
                taskUnits(t).find((u) => u.id === constructionTaskType.unit)
                  ?.name,
              ),
            }}
            getItemLabel={(unit) => t(unit?.name)}
            items={taskUnits(t)}
            selectedId={data?.unit}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ unit: newValue })
            }}
            inspectorPanel={true}
          />
        )}
        {!isEstimate && (
          <TextInlineInputCompontent
            label={t('total_units')}
            value={`${data?.total_units ?? ''}`}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ total_units: parseInt(newValue, 10) })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return
            }}
          />
        )}

        {!isEstimate && (
          <TextInlineInputCompontent
            label={t('hours_per_unit')}
            value={`${data?.hours_per_unit ?? ''}`}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ hours_per_unit: parseInt(newValue, 10) })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return
            }}
          />
        )}

        <TextInlineInputCompontent
          label={t('total_work_hours')}
          value={`${data?.total_work_hours ?? ''}`}
          onValueSubmitted={(newValue) => {
            if (newValue)
              onChangeInput({ total_work_hours: parseInt(newValue, 10) })
          }}
          validate={(newValue) => {
            if (newValue === undefined) {
              return t('required')
            }
            if (newValue?.length && isNaN(+newValue)) {
              return t('must_be_a_number')
            }
            const hours =
              constructionTaskType?.hours_per_unit > 0 &&
              constructionTaskType?.productivity > 0
                ? (constructionTaskType?.hours_per_unit *
                    constructionTaskType?.total_units) /
                  constructionTaskType.productivity
                : 0.0
            if (
              hours > 0 &&
              toFixed(hours) !== toFixed(parseInt(newValue ?? '0', 10) ?? 0)
            ) {
              return t('work_hour_needs_to_match_calculated_hour_w_param', {
                param: toFixed(hours),
              })
            }
            return
          }}
        />
        <TextInlineInputCompontent
          label={t('productivity_factor')}
          value={`${data?.productivity ?? ''}`}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ productivity: parseFloat(newValue) })
          }}
          validate={(newValue) => {
            if (!newValue) return t('required')
            if (newValue?.length && isNaN(+newValue)) {
              return t('must_be_a_number')
            }
            if (parseFloat(newValue) <= 0 || parseFloat(newValue) > 1) {
              return t('the_value_must_be_between_0_and_1')
            }
            return
          }}
        />
        <TextInlineInputCompontent
          label={t('duration_hours')}
          value={`${data?.planned_execution_hours ?? ''}`}
          onValueSubmitted={(newValue) => {
            if (newValue)
              onChangeInput({ planned_execution_hours: parseInt(newValue, 10) })
          }}
          validate={(newValue) => {
            if (newValue?.length && isNaN(+newValue)) {
              return t('must_be_a_number')
            }
            return
          }}
        />
        <TextInlineInputCompontent
          label={t('number_of_workers')}
          value={`${data?.number_of_workers ?? ''}`}
          onValueSubmitted={(newValue) => {
            if (newValue)
              onChangeInput({ number_of_workers: parseInt(newValue, 10) })
          }}
          validate={(newValue) => {
            if (newValue?.length && isNaN(+newValue)) {
              return t('must_be_a_number')
            }
            return
          }}
        />
        <DateTimeInlineInputComponent
          label="created_at"
          selectedTime={data?.created_at}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="updated_at"
          selectedTime={data?.updated_at}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
      </InlineComponentsWrapper>
    </FixedPane>
  )
}
export default ConstructionTaskTypeInspectorPanel
