import DataSelectors, {
  DATA_TYPES,
} from '../../document/components/FileUpload/selectors/DataSelectors'
import { IFormatData } from '../../document/components/FileUpload/types'
import { BASE_METADATA_TYPES } from '../../document/types/FolderMetadataTypes'
import { IMetaData, IMetaValue } from '../../document/types/IMetaData'
import { IMetaField } from './system-type-fields/SystemTypeFieldsForm'

const setInitialValueOfField = (
  field: IMetaField | IMetaData,
  forList?: boolean,
) => {
  if (field.data_type === BASE_METADATA_TYPES.multiEnum && forList) {
    return field.default_value_list || undefined
  } else if (!forList) {
    return field.default_value || undefined
  }
  return undefined
}

export const initializeMetaValues = (
  metaData: IMetaField[] | IMetaData[],
  parentType: string,
  parentId?: number,
  ignoreDefault?: boolean,
) => {
  return metaData.map(
    (item) =>
      ({
        name: item.name,
        is_required: item.is_required,
        data_type: item.data_type,
        data_value: ignoreDefault ? undefined : setInitialValueOfField(item),
        data_value_list: ignoreDefault
          ? undefined
          : setInitialValueOfField(item, true),
        pattern: item.pattern,
        user_defined_field_id: item.id,
        project_id: item.project_id,
        parent_id: parentId,
        parent_type: parentType,
      }) as IMetaValue,
  )
}

export const loadMetaValues = (
  id: number,
  type: string,
  fields?: IMetaField[],
  values?: IMetaValue[],
) => {
  const metaFields = initializeMetaValues(fields ?? [], type, id)
  return setExistingValues(values ?? [], metaFields)
}

export const setExistingValues = (
  metaValue: IMetaValue[],
  metaField: IMetaValue[],
) => {
  return metaField.map((item) => {
    const found = [...metaValue]
      .reverse()
      .find(
        (v: IMetaValue) =>
          v.user_defined_field_id === item.user_defined_field_id &&
          v.data_type === item.data_type &&
          v.name === item.name,
      )

    if (found) {
      return found
    } else {
      return item
    }
  })
}

export const metaFiledToMetaValue = (field: any) => {
  delete field.domain
  delete field.default_value
  const ret = { ...field }
  ret.user_defined_field_id = field.id
  ret.data_value_list = field.default_value_list
  delete ret.default_value_list
  ret.id = null
  return ret
}

export const metaDataToMetaField = (metaData: IMetaData[]) => {
  return metaData.map(
    (item) =>
      ({
        id: Number(item.id),
        name: item.name,
        is_required: item.is_required,
        data_type: item.data_type,
        data_value: setInitialValueOfField(item),
        pattern: item.pattern,
        user_defined_field_id: item.id,
        project_id: item.project_id,
      }) as IMetaField,
  )
}

export const getMetaDataValues = (metaValue?: Record<string, IMetaValue>) => {
  return metaValue ? [...Object.values(metaValue)] : []
}

export const mergeSingleChange = (
  index: number,
  header: string,
  _value: string | string[] | null,
  formattedData: IFormatData[],
) => {
  return formattedData.map((fd, i) => {
    const data = {}
    Object.entries(fd).forEach(([key, v]) => {
      const value = v?.value
      const newValue = i === index && key === header
      const field = DataSelectors.getDataFieldFromHeader(key)
      if (field && field.dataType === DATA_TYPES.enum) {
        data[key] = newValue ? _value : getValue(value)
      } else if (field && field.dataType === DATA_TYPES.multiEnum) {
        data[key] = newValue
          ? _value
          : getListValue((value as string[] | object[]) ?? [])
      } else {
        data[key] = newValue ? _value : fd[key]?.value
      }
    })
    return data
  })
}

export const mergeMultipleChanges = (
  newValue: Record<string, string | string[] | null>[],
  formattedData: IFormatData[],
) => {
  return formattedData.map((fd, i) => {
    const data = {}
    const editedData = newValue[i]
    Object.entries(fd).forEach(([key, v]) => {
      const value = v?.value
      const newValue = !!editedData?.[key]
      const field = DataSelectors.getDataFieldFromHeader(key)
      if (field && field.dataType === DATA_TYPES.enum) {
        data[key] = newValue ? editedData[key] : getValue(value)
      } else if (field && field.dataType === DATA_TYPES.multiEnum) {
        data[key] = newValue
          ? editedData[key]
          : getListValue((value as string[] | object[]) ?? [])
      } else {
        if (key === 'user_defined_values') {
          data['optional_fields'] = editedData?.['optional_fields'] || v || []
        } else {
          data[key] = newValue ? editedData[key] : fd[key]?.value
        }
      }
    })

    return data
  })
}

const getValue = (value: string | object) => {
  return typeof value === 'object' ? value?.['id'] : value
}

const getListValue = (values: string[] | object[]) => {
  return values.map((value) =>
    typeof value === 'object' ? value?.['id'] : value,
  )
}
