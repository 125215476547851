import 'react-toggle/style.css'
import { useContext } from 'react'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { useCreateComment } from 'src/query/comment'
import { IComment } from 'src/service/OrgTypes'
import CommentInput, { FileItem } from './CommentInput'
import CommentWrapper from './CommentWrapper'

interface AddCommentProps {
  parentId: number
  parentType: string
  disabled?: boolean
  onCommentAdded?: () => void
  onCommentSubmitted?: (c: Partial<IComment>) => void
  hideFileAttachments?: boolean
}

const AddComment = ({
  parentId,
  parentType,
  disabled = false,
  onCommentAdded,
  hideFileAttachments = false,
  onCommentSubmitted,
}: AddCommentProps) => {
  const { state: userState } = useContext(UserContext)
  const { mutateAsync: createComment } = useCreateComment(parentType, parentId)

  const onSave = async (newCommentValue: string, files: FileItem[]) => {
    const comment: Partial<IComment> = {
      content: newCommentValue,
      parent_id: parentId,
      parent_type: parentType,
    }
    const newFiles = files
      .map((f) => f.file)
      .filter((f): f is File => f !== undefined)
    if (onCommentSubmitted) {
      onCommentSubmitted(comment)
    } else {
      await createComment({ comment, newFiles })
      onCommentAdded?.()
    }
  }

  return (
    <CommentWrapper user={userState.user} inputMode>
      <CommentInput
        onSave={onSave}
        disabled={disabled}
        hideFileAttachments={hideFileAttachments}
      />
    </CommentWrapper>
  )
}

export default AddComment
