import moment from 'moment'
import { MouseEvent, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import useKeyPress from 'src/components/hooks/UseKeyEvent'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import TableTextEditor from '../../components/table-texteditor/TableTextEditor'
import { TableInputContext } from '../../context/TableInputContext/TableInputContext'
import history from '../../history'
import { IRiskArea } from '../../service/OrgTypes'
import {
  deleteRiskArea,
  editRiskArea,
  getRiskAreas,
  getRiskGroupMatrixData,
} from '../../service/RiskService'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import { capFirstLetter } from '../../utility/utils.js'
import DeleteModal from '../delete-modal/DeleteModal'
import RiskAreaForm from './RiskAreaForm'
import RiskPossibilityList from './RiskPossibilityList'
import RiskProbabilityList from './RiskProbabilityList'
import RiskThreatList from './RiskThreatList'

interface IRiskAreasListProps {
  projectId: number
  riskGroupId?: number
  isProjectPage?: boolean
}

export interface IInlineEditRef {
  inputCounter: number
  items: any[]
  rowCounter: number
  selectedItem: any
  editableColumns: string[]
}

const inputs = ['name', 'description', 'quantityUnit']

const RiskAreaList = ({
  projectId,
  riskGroupId,
  isProjectPage,
}: IRiskAreasListProps) => {
  const { t } = useTranslation()
  const [riskAreas, setRiskAreas] = useState<IRiskArea[]>([])
  const [selectedRiskArea, setSelectedRiskArea] = useState<
    IRiskArea | undefined
  >(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)

  const editRef = useRef<IInlineEditRef>({
    inputCounter: 0,
    items: riskAreas,
    rowCounter: 0,
    selectedItem: selectedRiskArea,
    editableColumns: inputs,
  })

  const tableInputContext = useContext(TableInputContext)

  useKeyPress((keyType: string) => {
    switch (keyType) {
      case 'Escape':
        tableInputContext.actions.clearSelectedFileds()
        break
      case 'Tab':
        nextInput()
        break
    }
  })

  useEffect(() => {
    reload()
  }, [riskGroupId])

  useEffect(() => {
    const ref = editRef.current
    ref.selectedItem = selectedRiskArea
    ref.items = riskAreas
    editRef.current = ref
  })

  useDidMountEffect(() => {
    if (!isProjectPage) {
      history.push('/risk_groups')
    }
    reload()
  }, [projectId])

  const reload = async () => {
    const api = riskGroupId ? getRiskGroupMatrixData : getRiskAreas
    const res = await api(riskGroupId ? riskGroupId : projectId)

    const selectedId = selectedRiskArea ? selectedRiskArea.id : undefined
    const selected = selectedId
      ? res.filter((d) => d.id === selectedId).pop()
      : undefined
    setRiskAreas(res)
    setSelectedRiskArea(selected)
    setLoading(false)
  }

  const nextInput = () => {
    const { items, inputCounter, rowCounter, editableColumns } = editRef.current
    const nextIndex = (inputCounter + 1) % editableColumns.length
    editRef.current.inputCounter = nextIndex
    if (nextIndex === 0) {
      const nextRow = (rowCounter + 1) % items.length
      editRef.current.rowCounter = nextRow
      setSelectedRiskArea(items[nextRow])
      editRef.current.selectedItem = items[nextRow]
    }
    const key = editRef.current.selectedItem
    tableInputContext.actions.updateFiled(
      key ? key.id : -1,
      editableColumns[nextIndex],
    )
  }

  const onColumnChange = (ids: string[]) => {
    editRef.current.editableColumns = ids.filter(
      (id) => inputs.indexOf(id) > -1,
    )
  }

  const onEditItemClick = (riskArea: IRiskArea) => {
    setSelectedRiskArea(riskArea)
    setShowEditModal((n) => !n)
  }

  const onDeletItemClick = (riskArea: IRiskArea) => {
    setSelectedRiskArea(riskArea)
    setShowDeleteModal((n) => !n)
  }

  const openEditModal = () => {
    setShowEditModal((n) => !n)
  }

  const closeEditModal = () => {
    setShowEditModal((n) => !n)
    reload()
  }

  const toggleDeleteModal = () => {
    setShowDeleteModal((n) => !n)
  }

  const openCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
  }

  const deleteArea = () => {
    if (selectedRiskArea && selectedRiskArea.id) {
      deleteRiskArea(selectedRiskArea.id)
        .then(() => {
          reload()
        })
        .catch((err) => console.error(err))
    }

    toggleDeleteModal()
  }

  const onPreviewClick = (e: MouseEvent, data: IRiskArea) => {
    e.preventDefault()
    e.stopPropagation()

    if (data?.id === selectedRiskArea?.id) {
      setShowDetailModal(false)
      setSelectedRiskArea(undefined)
    } else {
      setShowDetailModal(true)
      setSelectedRiskArea(data)
    }
  }

  const updated = () => {
    reload()
  }

  const editArea = async (data: IRiskArea) => {
    await editRiskArea(data)
  }

  const setInputIndex = (columnId: string, row: number) => {
    const int = editRef.current.editableColumns.indexOf(columnId)
    editRef.current.rowCounter = row
    editRef.current.inputCounter = int
  }

  const columns: IListColumns[] = [
    {
      name: 'name',
      id: 'name',
      size: '150',
      dataField: 'name',
      cell: (cell: any, id: number, key: number) => (
        <TableTextEditor
          value={cell}
          columnId={'name'}
          id={id}
          onFocus={() => setInputIndex('name', key)}
          onSubmit={(value) => editArea({ id, name: value } as IRiskArea)}
        />
      ),
    },
    {
      name: 'description',
      size: '270',
      id: 'description',
      dataField: 'description',
      cell: (cell: any, id: number, key: number) => (
        <TableTextEditor
          value={cell}
          columnId={'description'}
          id={id}
          onFocus={() => setInputIndex('description', key)}
          onSubmit={(value) =>
            editArea({ id, description: value } as IRiskArea)
          }
        />
      ),
    },
    {
      name: 'quantity_unit',
      size: '150',
      id: 'quantityUnit',
      dataField: 'quantity_unit',
      cell: (cell: any, id: number, key: number) => (
        <TableTextEditor
          value={cell}
          columnId={'quantityUnit'}
          id={id}
          onFocus={() => setInputIndex('quantityUnit', key)}
          onSubmit={(value) =>
            editArea({ id, quantity_unit: value } as IRiskArea)
          }
        />
      ),
    },
    {
      name: 'triple_estimate',
      size: '200',
      id: 'include_risk_estimation',
      dataField: 'include_risk_estimation',
      cell: (active: any) => <span>{active ? t('yes') : t('no')}</span>,
    },
    {
      name: 'created_at',
      size: '130',
      id: 'createAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updatedDate: any) => (
        <span>{updatedDate ? moment(updatedDate).format('L') : ''}</span>
      ),
    },
  ]

  return (
    <>
      <List
        actionButton={
          !riskGroupId ? (
            <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
              {t('new_risk_area')}
            </Button>
          ) : undefined
        }
        data={riskAreas}
        columns={columns}
        itemsPerPage={0}
        tableName={'riskAreaList'}
        onPreviewClick={onPreviewClick}
        disableConfigStore={true}
        columnChange={onColumnChange}
        actionMenu={
          riskGroupId
            ? []
            : [
                {
                  name: t('delete'),
                  action: onDeletItemClick,
                },
                {
                  name: t('edit'),
                  action: onEditItemClick,
                },
              ]
        }
      />
      {selectedRiskArea && showDetailModal && (
        <Modal
          show={showDetailModal}
          title={t('edit_risk_area')}
          closeModal={() => setShowDetailModal(false)}
        >
          <div className={'flex flex-col'}>
            {selectedRiskArea.risk_probabilities ? (
              <div className={'mb-4'}>
                <RiskProbabilityList
                  reload={updated}
                  riskProbabilities={selectedRiskArea.risk_probabilities}
                />
              </div>
            ) : null}
            {selectedRiskArea.risk_possibilities ? (
              <div className={'mb-4'}>
                <RiskPossibilityList
                  quantityUnit={selectedRiskArea.quantity_unit}
                  reload={updated}
                  riskPossibilities={selectedRiskArea.risk_possibilities}
                />
              </div>
            ) : null}

            {selectedRiskArea.risk_threats ? (
              <div className={'mb-2'}>
                <RiskThreatList
                  quantityUnit={selectedRiskArea.quantity_unit}
                  reload={updated}
                  riskThreats={selectedRiskArea.risk_threats}
                />
              </div>
            ) : null}
          </div>
          <ModalFooter>
            <Button
              type={Button.ButtonType.PRIMARY}
              onClick={(e) => onPreviewClick(e, selectedRiskArea)}
            >
              {capFirstLetter(t('close'))}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {selectedRiskArea ? (
        <Modal
          show={showEditModal}
          closeModal={openEditModal}
          title={t('edit_risk_area')}
          maxWidth={800}
        >
          <RiskAreaForm
            editingMode={true}
            riskArea={selectedRiskArea}
            projectId={projectId}
            closeModal={closeEditModal}
          />
        </Modal>
      ) : null}

      {selectedRiskArea ? (
        <DeleteModal
          show={showDeleteModal}
          closeModal={toggleDeleteModal}
          onDelete={deleteArea}
          itemIdnType={`${selectedRiskArea.quantity_unit} (${t('risk_area')})`}
          itemName={`${selectedRiskArea.quantity_unit} - ${selectedRiskArea.name}`}
        />
      ) : null}

      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_risk_area')}
        maxWidth={800}
      >
        <RiskAreaForm
          editingMode={false}
          riskArea={{
            id: 0,
            name: '',
            description: '',
            quantity_unit: '',
            include_risk_estimation: false,
          }}
          projectId={projectId}
          closeModal={closeCreateModal}
        />
      </Modal>
      {loading ? <Loader /> : null}
    </>
  )
}

export default RiskAreaList
