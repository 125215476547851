import { useState } from 'react'
import * as React from 'react'
import CloseClickOutside from '../../../../components/click-outside/CloseClickOutside'
import { useMultipleKeysTranslation } from '../../../../service/useMultipleKeysTranslation'
import {
  IMetadataBaseType,
  IMetaDataType,
} from '../../../types/FolderMetadataTypes'
import FolderMetadataDisplayField from './FolderMetadataDisplayField'
import FolderMetadataFieldDropdown from './FolderMetadataFieldDropdown'

interface IFolderMetadataField {
  value: IMetaDataType
  disabled?: boolean
  onTypeChange?: (newType: IMetaDataType) => void
  possibleMetadataTypes: Record<string, IMetadataBaseType>
  menuOnTheLeft?: boolean
}

const FolderMetadataField: React.FC<IFolderMetadataField> = ({
  value,
  disabled,
  onTypeChange,
  menuOnTheLeft,
  possibleMetadataTypes,
}) => {
  const [showMenu, setShowMenu] = useState(false)

  const { t } = useMultipleKeysTranslation()

  const onClick = () => {
    if (disabled) return
    setShowMenu(true)
  }

  const onTypeSelected = (newType: IMetaDataType) => {
    if (onTypeChange) onTypeChange(newType)
    setShowMenu(false)
  }

  return (
    <>
      <FolderMetadataDisplayField
        value={t(value.data_type)}
        onClick={onClick}
        disabled={!!disabled}
        type={value.data_type}
      />
      {showMenu && (
        <CloseClickOutside onClose={() => setShowMenu(false)}>
          <div className="relative ml-10">
            <FolderMetadataFieldDropdown
              possibleMetadataTypes={possibleMetadataTypes}
              menuOnTheLeft={menuOnTheLeft}
              value={value}
              onClick={onTypeSelected}
            />
          </div>
        </CloseClickOutside>
      )}
    </>
  )
}

export default FolderMetadataField
