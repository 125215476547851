import { useEffect, useState } from 'react'
import { updateWallToken } from 'src/service/TheWallService'

const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    updateWallToken().then((token) => {
      setAccessToken(token)
    })
  }, [])

  return accessToken
}

export default useAccessToken
