export default {
  highlighter: {
    padding: 12,
  },
  input: {
    padding: 12,
    height: '100%',
  },
  '&multiLine': {
    control: {
      minHeight: 64,
      paddingBottom: 24,
    },
  },
  '&singleLine': {
    control: {
      height: 32,
    },
  },
  suggestions: {
    zIndex: 100,
    list: {
      backgroundColor: 'white',
      borderRadius: '5px',
      fontSize: 14,
      maxHeight: 250,
      overflow: 'auto',
      color: 'rgba(17,24,39,1)',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
    },
    item: {
      padding: '11px 17px',
      borderRadius: '5px',
      '&focused': {
        backgroundColor: '#EBECF0',
        color: 'black',
      },
    },
  },
}
