import { ImportTask } from 'src/context/ImportContext/ImportTypes'
import { request } from './service-utils'

class BackgroundJobService {
  readonly getBGJobs = (projectId: number): Promise<ImportTask[]> => {
    return request(`projects/${projectId}/background_jobs`, 'GET')
  }

  readonly getBackgroundJobStatus = (
    projectId: number,
    jobId: number,
  ): Promise<ImportTask> => {
    return request(`projects/${projectId}/background_jobs/${jobId}`, 'GET')
  }

  readonly deleteBackgroundJob = (
    projectId: number,
    jobId: number,
  ): Promise<ImportTask> => {
    return request(`projects/${projectId}/background_jobs/${jobId}`, 'DELETE')
  }
}

export default new BackgroundJobService()
