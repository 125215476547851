import { useState } from 'react'
import { classNames } from 'src/utility/utils'

interface ITooltip {
  direction: 'top' | 'right' | 'left' | 'bottom'
  content: JSX.Element | string
  children: JSX.Element
  delay?: number
  hidden?: boolean
  onHover?: () => void
  hoveredClassName?: string
}

/**
 * @deprecated Use src/ui-components/Tooltip
 */
const Tooltip = ({
  direction,
  content,
  children,
  delay,
  hidden,
  onHover,
  hoveredClassName = '',
}: ITooltip) => {
  let timeout: NodeJS.Timeout
  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      onHover?.()
      setActive(true)
    }, delay || 400)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  const styleClass = {
    root: classNames('Tooltip-Wrapper'),
    floating: classNames(`Tooltip-Tip ${direction || 'top'}`, hoveredClassName),
  }

  return (
    <div
      className={styleClass.root}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && !hidden && (
        <div className={styleClass.floating}>{content}</div>
      )}
    </div>
  )
}

export default Tooltip
