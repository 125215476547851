import { IFloor, IPaginatedFloors, ISection } from './OrgTypes'
import { request } from './service-utils'

export const createSection = (
  section: ISection,
  projectId: number,
): Promise<ISection> => {
  return request(`projects/${projectId}/sections`, 'POST', section)
}

export const editSection = (section: ISection): Promise<ISection> => {
  return request(`sections/${section.id}`, 'PUT', section)
}

export const getProjectSections = (projectId: number): Promise<ISection[]> => {
  return request(`projects/${projectId}/sections`, 'GET')
}

export const deleteSection = (task: ISection) => {
  return request(`/sections/${task.id}`, 'DELETE', {}, true)
}

export const getOneSection = (sectionId: number): Promise<ISection> => {
  return request(`sections/${sectionId}`, 'GET')
}

export const getSingleFloor = (floorId: number): Promise<IFloor> => {
  return request(`floors/${floorId}`, 'GET')
}

export const createFloor = (floor: IFloor, projectId: number): Promise<any> => {
  return request(`projects/${projectId}/floors`, 'POST', floor)
}

export const editFloor = (floor: IFloor): Promise<any> => {
  return request(`floors/${floor.id}`, 'PUT', floor)
}

export const deleteFloor = (floor: IFloor): Promise<any> => {
  return request(`floors/${floor.id}`, 'DELETE', {}, true)
}

export const getProjectFloors = (projectId: number): Promise<IFloor[]> => {
  return request(`projects/${projectId}/floors`, 'GET')
}

export const getFilteredProjectFloors = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginatedFloors> => {
  return request(
    `projects/${projectId}/floors/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getFilteredSectionFloors = (
  sectionId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginatedFloors> => {
  return request(
    `sections/${sectionId}/floors/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getSectionFloors = (sectionId: number): Promise<IFloor[]> => {
  return request(`sections/${sectionId}/floors`, 'GET')
}
