import * as React from 'react'
import { useState } from 'react'
import { MenuContext } from './MenuContext'

const MenuContextProvider: React.FC<
  React.PropsWithChildren<{ isSystemAdmin: boolean }>
> = ({ isSystemAdmin, children }) => {
  const defaultActiveMenu = isSystemAdmin ? 3 : 2

  const [title, setTitle] = useState('')
  const [activeTab, setActiveTab] = useState(defaultActiveMenu)
  const [activeSubmenu, setActiveSubmenu] = useState<number>()

  const actions = {
    updateTitle: (res: string) => setTitle(res),
    toggleActiveMenu: (res: number) => setActiveTab(res),
    toggleActiveSubmenu: (res: number) => setActiveSubmenu(res),
  }

  const state = Object.assign({}, { title, activeTab, activeSubmenu })

  const context = {
    actions,
    state,
  }

  return (
    <MenuContext.Provider value={context}>
      {state && children}
    </MenuContext.Provider>
  )
}

export default MenuContextProvider
