import moment from 'moment'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import Selector from 'src/components/selectors/Selector'
import { capFirstLetter, classNames } from '../../utility/utils'
import Badge from '../badge/Badge'
import { BadgeColor } from '../badge/BadgeEnums'
import { Icons } from '../icon/Icon'

interface ISelectorDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  onSelect: (id: number | string | any) => void
  items: any[]
  selectedItemId: number | string
  onOpenSelectFunction?: () => void
  label: string
  dataFields: any[]
  disabled?: boolean
  fontSize?: string
  fontWeight?: string
  required?: boolean
  errorMessage?: string
  icon?: Icons
  userSelector?: boolean
  loading?: boolean
  cancelButton?: boolean
  hasBorder?: boolean
  scroll?: boolean
  onCancel?: () => void
  border?: boolean
  showIcon?: boolean
  inTopMenu?: boolean
  editingMode: boolean
  displayData: string
  isStatus?: boolean
  deadline?: moment.Moment
  isCleared?: boolean
  t: any
}

class SelectorDisplay extends React.PureComponent<ISelectorDisplayProps, any> {
  private styleClass = {
    badge: (status: string) =>
      classNames(
        'inline-flex',
        'items-center',
        'px-2.5',
        'py-0.5',
        'rounded-md',
        'text-sm',
        'font-medium',
        'leading-5',
        status === 'Utført' ? 'bg-green-100' : 'bg-red-100',
        status === 'Utført' ? 'text-green-800' : 'text-red-800',
      ),
    placeholder: classNames(
      'text-gray-400',
      'font-normal',
      'text-sm',
      'lowercase',
    ),
    displayText: classNames('block', 'truncate', 'text-gray-500'),
    tableRow: (showErrorMessage: boolean) =>
      classNames(
        'flex',
        'flex-row',
        'flex-wrap',
        'justify-between',
        'sm:grid',
        'sm:grid-cols-3',
        'sm:gap-4',
        'sm:pl-6',
        'sm:pr-1',
        showErrorMessage ? 'sm:pb-2' : 'sm:py-3',
        showErrorMessage ? 'pb-1' : 'py-2',
        showErrorMessage ? '' : 'my-1',
        'border-b',
        'border-gray-200',
      ),
    tableLeftText: (editingMode: boolean) =>
      classNames(
        'flex',
        'items-center',
        'text-sm',
        'leading-5',
        'font-medium',
        'text-gray-700',
        'pl-1',
        'pb-2',
        !editingMode ? 'w-1/2' : 'w-full',
        'sm:w-auto',
        'truncate',
      ),
    tableCellRight: (editingMode: boolean) =>
      classNames(
        'mt-1',
        'text-sm',
        'leading-5',
        'sm:mt-0',
        'sm:col-span-2',
        !editingMode ? 'w-1/2' : 'w-full',
        'sm:w-auto',
      ),
  }

  public render() {
    const {
      onSelect,
      items,
      label,
      selectedItemId,
      dataFields,
      fontSize,
      fontWeight,
      required,
      errorMessage,
      icon,
      loading,
      scroll,
      hasBorder,
      onOpenSelectFunction,
      disabled,
      userSelector,
      cancelButton,
      onCancel,
      border,
      showIcon,
      inTopMenu,
      editingMode,
      displayData,
      isStatus,
      deadline,
      isCleared,
      t,
    } = this.props
    const hasError = !!(errorMessage && errorMessage !== '')

    const getBadgeColor = (): BadgeColor => {
      if (
        displayData === t('done') ||
        displayData === t('completed') ||
        displayData.split('-')[0] === t('closed') ||
        displayData.split('-')[0] === t('close')
      ) {
        return BadgeColor.GREEN
      }
      if (deadline && moment(deadline).isBefore(moment(), 'day')) {
        return BadgeColor.RED
      }
      if (displayData === t('in_progress')) {
        return BadgeColor.YELLOW
      }
      return BadgeColor.BLUE
    }

    return (
      <div className={this.styleClass.tableRow(hasError)}>
        <dt className={this.styleClass.tableLeftText(editingMode || false)}>
          {capFirstLetter(label)}
          {required && editingMode ? ' *' : ''}
        </dt>
        <dd className={this.styleClass.tableCellRight(editingMode || false)}>
          <div className="w-full">
            {editingMode ? (
              <Selector
                onSelect={onSelect}
                items={items}
                label={label}
                selectedItemId={selectedItemId}
                dataFields={dataFields}
                fontSize={fontSize}
                fontWeight={fontWeight}
                required={required}
                errorMessage={errorMessage}
                icon={icon}
                loading={loading}
                scroll={scroll}
                hasBorder={hasBorder}
                onOpenSelectFunction={onOpenSelectFunction}
                disabled={disabled}
                userSelector={userSelector}
                cancelButton={cancelButton}
                onCancel={onCancel}
                border={border}
                showIcon={showIcon}
                hidelabel={true}
                inTopMenu={inTopMenu}
                isCleared={isCleared}
              />
            ) : isStatus ? (
              <Badge
                size={Badge.BadgeSize.LARGE}
                text={displayData}
                color={getBadgeColor()}
              />
            ) : (
              <span className={this.styleClass.displayText}>
                {displayData || (
                  <span className={this.styleClass.placeholder} />
                )}
              </span>
            )}
          </div>
        </dd>
      </div>
    )
  }
}

export default withTranslation()(SelectorDisplay)
