import { FC } from 'react'
import type { ITypography } from './index'

const TypographyH5: FC<ITypography> = ({ children, className, ...rest }) => {
  return (
    <h5 className={`text-sm font-medium ${className}`} {...rest}>
      {children}
    </h5>
  )
}

export default TypographyH5
