import Info from '@icons/info_i.svg'
import * as React from 'react'
import { useRef, useState } from 'react'
import { ClassNameValue, twMerge } from 'tailwind-merge'
import { useClickOutside } from 'src/hooks/useClickOutside'
import Button from 'src/ui-elements/button/Button'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import CircularButton from '../CircularButton'

interface IInfoModalProps {
  title?: string
  tooltipText?: string
  noMaxWidth?: boolean
  noPadding?: boolean
  leftPopup?: boolean
  mdIcon?: string
  modalOrigin?: 'topCenter' | 'topRight' | 'topLeft'
  isCircular?: boolean
  className?: ClassNameValue
  targetElement?: ({ onClick }: { onClick: () => void }) => React.ReactElement
  noArrow?: boolean
  minWidth?: string
}

const InfoModal = ({
  title,
  tooltipText,
  children,
  mdIcon,
  className,
  modalOrigin = 'topRight',
  isCircular = true,
  targetElement,
  noArrow = false,
  minWidth = 'min-w-45',
}: React.PropsWithChildren<IInfoModalProps>) => {
  const [infoModal, setInfoModal] = useState(false)
  const modalRef = useRef(null)

  useClickOutside(modalRef, () => setInfoModal(false))

  return (
    <div className="relative" ref={modalRef}>
      {targetElement ? (
        targetElement({
          onClick: () => {
            setInfoModal(!infoModal)
          },
        })
      ) : isCircular ? (
        <CircularButton
          tooltipText={tooltipText}
          onClick={() => setInfoModal(!infoModal)}
          mdIcon={mdIcon}
          active={infoModal}
          className="font-serif select-none"
        >
          {!mdIcon && <Info />}
        </CircularButton>
      ) : (
        <Button
          onClick={() => setInfoModal(!infoModal)}
          className={'shadow-none border-none p-0'}
        >
          <MaterialIcon
            icon={mdIcon ?? 'info_i'}
            className={twMerge(
              'text-black hover:opacity-30',
              infoModal && 'opacity-30',
            )}
          />
        </Button>
      )}

      <div
        title={title ?? ''}
        className={twMerge(
          'absolute bg-white right-0 origin-top-right mt-4 shadow-xl z-[10]',
          modalOrigin === 'topCenter' && 'right-1/2 translate-x-1/2',
          modalOrigin === 'topLeft' && 'origin-top-left left-0',
          'after:absolute after:w-0 after:h-0 after:border-l-transparent after:border-r-transparent after:border-t-transparent after:border-b-white after:border-[10px] after:top-[-20px] after:right-[0.8rem]',
          // To center the arrow: right = 50% of modal width - 5px (half of the width of the arrow)
          modalOrigin === 'topCenter' && 'after:right-[calc(50%-5px)]',
          modalOrigin === 'topLeft' && 'after:left-[0.8rem]',
          'invisible',
          infoModal && 'visible',
          noArrow && 'after:invisible mt-1',
          'rounded',
          className,
          minWidth,
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default InfoModal
