import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { classNames } from 'src/utility/utils'

interface IDocListStageProps {
  fileCount: number
  stage: string
  showArrow?: boolean
}

const styleClass = {
  count: (underReview: boolean) =>
    classNames(
      'rounded-full justify-center flex flex-shrink-0 w-5 aspect-square flex-col text-center font-bold text-xs text-white',
      underReview ? 'bg-d-grey-light' : 'bg-d-darkblue',
    ),
  text: (underReview: boolean) =>
    classNames(
      'ml-1 font-semibold text-xs capitalize',
      underReview ? 'text-d-grey-light' : 'text-d-darkblue',
    ),
}

const DocListStage: FC<IDocListStageProps> = ({
  fileCount,
  stage,
  showArrow = false,
}: IDocListStageProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex w-full items-center">
      <span className={styleClass.count(stage === 'under_review')}>
        {fileCount > 0 ? fileCount : 0}
      </span>
      <span className={styleClass.text(stage === 'under_review')}>
        {t(stage)}
      </span>
      {showArrow && (
        <div className="flex items-center ml-auto">
          {/* a blue line */}
          <hr className="border-t-2 border-r-2 b border-d-darkblue w-8"></hr>
          <MaterialIcon
            icon="trending_flat"
            className="text-d-darkblue -ml-1"
          />
        </div>
      )}
    </div>
  )
}

export default DocListStage
