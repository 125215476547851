import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeliveryForm from 'src/components/process/delivery/DeliveryForm'
import DeliveryInspectorPanel from 'src/components/process/delivery/DeliveryInspectorPanel'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { useImprovementDeliveries } from 'src/query/improvements'
import Button from 'src/ui-elements/button/Button'

interface ImprovementDeliveriesSectionTableProps {
  improvementId: number
  projectId: number
}

const ImprovementDeliveriesSectionTable = ({
  improvementId,
  projectId,
}: ImprovementDeliveriesSectionTableProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { state: userState } = useContext(UserContext)

  const [selectedDeliveryId, setSelectedDeliveryId] = useState<number>()
  const [showCreateNewDeliveryModal, setShowCreateNewKeyDeliveryModal] =
    useState(false)

  const { data: deliveries, isLoading: deliveriesLoading } =
    useImprovementDeliveries(improvementId)

  const reload = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['improvement/deliveries', improvementId],
    })
  }, [queryClient, improvementId])

  return (
    <div>
      <Button
        type={Button.ButtonType.PRIMARY}
        size={Button.ButtonSize.SMALL}
        onClick={() => setShowCreateNewKeyDeliveryModal(true)}
        margin="mb-2"
      >
        {t('new_delivery')}
      </Button>
      <InspectorSectionTable
        headerColumns={[
          t('id'),
          t('title'),
          t('status'),
          t('deadline'),
          t('responsible'),
        ]}
        rowsData={(deliveries || [])?.map(
          ({ id, record_id, name, status, endTime, responsible }) => ({
            cells: [
              record_id,
              name,
              t(status),
              endTime ? moment(endTime).format('L') : '',
              responsible
                ? `${responsible?.firstName} ${responsible?.lastName}`
                : '',
            ],
            id,
          }),
        )}
        loading={deliveriesLoading}
        handleClick={setSelectedDeliveryId}
      />
      {selectedDeliveryId && (
        <DeliveryInspectorPanel
          deliveryId={selectedDeliveryId}
          projectId={projectId}
          open={selectedDeliveryId !== undefined}
          onClose={() => setSelectedDeliveryId(undefined)}
          onUpdate={reload}
        />
      )}
      <DeliveryForm
        projectId={projectId}
        improvementId={improvementId}
        user={userState.user}
        show={showCreateNewDeliveryModal}
        onDeliveryCreated={reload}
        isNotFromProcess
        closeModal={() => setShowCreateNewKeyDeliveryModal(false)}
      />
    </div>
  )
}

export default ImprovementDeliveriesSectionTable
