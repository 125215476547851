import i18next from 'i18next'

class AppCommunicator {
  isApp = false
  language = 'NO'
  projectId = 1
  private projectChangedCallback?: (projectId: number) => void
  private readonly urlParams: URLSearchParams

  constructor() {
    const queryString = window.location.search
    this.urlParams = new URLSearchParams(queryString)
  }

  readonly initialize = () => {
    this.checkIsApp()
    this.checkLanguage()
    this.checkProject()
    this.initializeAppPort()
  }

  private getUrlParam = (paramKey: string) => {
    return this.urlParams.get(paramKey)
  }

  private readonly checkIsApp = () => {
    const isApp = this.getUrlParam('isApp')
    this.isApp = isApp === 'true'
  }

  private readonly checkLanguage = async () => {
    const language = this.getUrlParam('language')
    if (language) {
      await i18next.changeLanguage(language)
      this.language = language
    } else {
      this.language = i18next.language
    }
  }

  private readonly checkProject = () => {
    this.projectId = Number(this.getUrlParam('projectId')) ?? 1
  }

  private readonly changeLanguage = async (languageCode: string) => {
    this.language = languageCode
    await i18next.changeLanguage(languageCode)
  }

  private readonly changeProject = (projectId: number) => {
    this.projectId = projectId
    if (this.projectChangedCallback) this.projectChangedCallback(projectId)
  }

  private readonly onMessage = (event: any) => {
    const message = JSON.parse(event.data) as { type: string; body: any }
    if (message.type === 'languageUpdated') {
      this.changeLanguage(message.body)
    } else if (message.type === 'projectUpdated') {
      this.changeProject(message.body)
    }
  }

  private initializeAppPort = () => {
    window.addEventListener('message', (event) => {
      if (event.data === 'capturePort') {
        if (event.ports[0]) {
          const port = event.ports[0]
          port.onmessage = this.onMessage
        }
      }
    })
  }

  readonly addProjectChangedCallback = (
    callbackFn: (projectId: number) => void,
  ) => {
    this.projectChangedCallback = callbackFn
    this.projectChangedCallback(this.projectId)
  }
}

export default new AppCommunicator()
