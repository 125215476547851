import 'filepond/dist/filepond.min.css'
import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { IDocument, IUserData } from '../../service/OrgTypes'
import { editUser } from '../../service/UserService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import {
  classNames,
  getOrganizationBaseUrl,
  validation,
} from '../../utility/utils'
import UploadFile from '../document/UploadFile'
import SwitchHOC from '../switchHoc/switchHoc'

interface IEditUserFormProps extends WithTranslation {
  user: IUserData
  closeModal: () => void
}

interface IEditUserFormState {
  userId: number
  fname: string
  lname: string
  email: string
  phone: string
  image_url: string
  firstNameErrorMessage: string
  lastNameErrorMessage: string
  emailErrorMessage: string
  phoneErrorMessage: string
  loading: boolean
  emailNotification: boolean
}

class EditUserForm extends PureComponent<
  IEditUserFormProps,
  IEditUserFormState
> {
  private static styleClass = {
    root: classNames('w-full', 'flex', 'row'),
    root_col: classNames('w-full', 'flex', 'flex-col', 'relative'),
    inputGroup: classNames('w-full', 'flex', 'row', 'mt-2'),
    img: (larg: boolean | undefined) =>
      classNames(
        'rounded-full',
        'mr-4',
        larg ? 'h-8' : 'h-6',
        larg ? 'w-8' : 'w-6',
      ),
  }

  public constructor(props: IEditUserFormProps) {
    super(props)
    this.state = {
      userId: props.user.id,
      fname: props.user.firstName,
      lname: props.user.lastName,
      email: props.user.email,
      phone: props.user.telephoneNumber,
      image_url: props.user.image_url ? props.user.image_url : '',
      firstNameErrorMessage: '',
      lastNameErrorMessage: '',
      emailErrorMessage: '',
      phoneErrorMessage: '',
      loading: false,
      emailNotification: props.user.email_notification
        ? props.user.email_notification
        : false,
    }
  }

  private onSubmit = (e: any) => {
    const { t } = this.props
    e.preventDefault()
    let error = false
    this.setState({
      loading: true,
    })
    // User validation
    if (!this.state.fname) {
      this.setState({
        firstNameErrorMessage: t('fill_in_first_name'),
      })
      error = true
    }
    if (!this.state.lname) {
      this.setState({
        lastNameErrorMessage: t('fill_in_last_name'),
      })
      error = true
    }
    if (this.state.email && !validation.isEmailAddress(this.state.email)) {
      this.setState({
        emailErrorMessage: t('invalid_email_address'),
      })
      error = true
    }
    if (!this.state.phone) {
      this.setState({
        phoneErrorMessage: t('fill_in_the_phone'),
      })
      error = true
    }

    if (!error) {
      editUser({
        id: this.state.userId,
        firstName: this.state.fname,
        lastName: this.state.lname,
        telephoneNumber: this.state.phone,
        email: this.state.email,
        key: this.props.user.key,
        isContractAdmin: false,
        isSystemAdmin: this.props.user.isSystemAdmin,
        isProjectAdmin: this.props.user.isProjectAdmin,
        image_url: this.state.image_url,
        email_notification: this.state.emailNotification,
      }).then(() => {
        this.props.closeModal()
      })
    }

    this.setState({
      loading: false,
    })
  }

  private onFirstNameChange = (e: any) => {
    const fnameVal = e.target.value

    this.setState({
      fname: fnameVal,
      firstNameErrorMessage: '',
    })
  }

  private onLastnameChange = (e: any) => {
    const lnameVal = e.target.value

    this.setState({
      lname: lnameVal,
      lastNameErrorMessage: '',
    })
  }

  private onEmailChange = (e: any) => {
    const emailVal = e.target.value

    this.setState({
      email: emailVal,
      emailErrorMessage: '',
    })
  }

  private onEmailNotficationChanged = (e: any) => {
    this.setState({
      emailNotification: e,
    })
  }

  private onPhoneChange = (e: any) => {
    const phoneVal = e.target.value

    this.setState({
      phone: phoneVal,
      phoneErrorMessage: '',
    })
  }

  private filesUploaded = (docs: IDocument[]) => {
    this.setState({
      image_url: docs[0] ? docs[0].fileUrl : '',
    })
  }

  private uploadUrl = (): string => {
    return `${getOrganizationBaseUrl()}/users/${this.props.user.id}/profile`
  }

  public render() {
    const {
      fname,
      lname,
      email,
      phone,
      image_url,
      loading,
      emailNotification,
      firstNameErrorMessage,
      lastNameErrorMessage,
      emailErrorMessage,
      phoneErrorMessage,
    } = this.state
    const { t } = this.props
    return (
      <form
        style={{ minHeight: 720 }}
        className={EditUserForm.styleClass.root_col}
        onSubmit={this.onSubmit}
      >
        <div className={EditUserForm.styleClass.root}>
          <div className={EditUserForm.styleClass.root_col}>
            <div className={'w-full flex flex-col content-center'}>
              <div className="m-4 flex justify-center">
                {image_url ? (
                  <img
                    className="h-48 w-48 inline rounded-full"
                    src={`${image_url}`}
                    alt=""
                  />
                ) : (
                  <img
                    className="h-48 w-48 inline rounded-full"
                    src={`/user_profile.png`}
                    alt=""
                  />
                )}
              </div>

              <UploadFile
                uploadUrl={this.uploadUrl()}
                singleFileUpload={true}
                uploadedDocuments={this.filesUploaded}
                allowedTypes={['image/jpg', 'image/jpeg', 'image/png']}
              />
            </div>

            <div className={EditUserForm.styleClass.inputGroup}>
              <Input
                label={t('first_name')}
                block={true}
                value={fname}
                onChange={this.onFirstNameChange}
                errorMessage={firstNameErrorMessage}
                required={true}
              />
            </div>

            <div className={EditUserForm.styleClass.inputGroup}>
              <Input
                label={t('surname')}
                block={true}
                value={lname}
                onChange={this.onLastnameChange}
                errorMessage={lastNameErrorMessage}
                required={true}
              />
            </div>

            <div className={EditUserForm.styleClass.inputGroup}>
              <Input
                label={t('email')}
                block={true}
                value={email}
                onChange={this.onEmailChange}
                errorMessage={emailErrorMessage}
                required={true}
              />
            </div>

            <div className={EditUserForm.styleClass.inputGroup}>
              <Input
                label={t('telephone')}
                block={true}
                value={phone}
                onChange={this.onPhoneChange}
                errorMessage={phoneErrorMessage}
                required={true}
              />
            </div>

            <div className={EditUserForm.styleClass.inputGroup}>
              <span className={'my-2 flex items-center'}>
                <label className={'px-2'}>{t('email_notification')}</label>
                <SwitchHOC
                  valueProp={emailNotification}
                  className="custom-classname"
                  onChange={this.onEmailNotficationChanged}
                />
              </span>
            </div>
          </div>
        </div>

        <ModalFooter>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={this.props.closeModal}
          >
            {t('cancel')}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : t('save')}
          </Button>
        </ModalFooter>
      </form>
    )
  }
}

export default withTranslation()(EditUserForm)
