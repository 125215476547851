import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPPUGoal } from 'src/service/OrgTypes'
import { deleteProjectPPU, getProjectPPU } from 'src/service/ProjectService'
import Button from 'src/ui-elements/button/Button'
import List from 'src/ui-elements/list/List'
import Modal from 'src/ui-elements/modal/Modal'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import { ProjectContext } from '../../context/ProjectContextProvider/ProjectContext'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import useAlert from '../../ui-elements/toast/useAlert'
import { convertPPUType } from '../../utility/utils'
import PPUForm from './PPUForm'

const PPUGoals = () => {
  const { t } = useTranslation()
  const [ppuGoals, setPPUGoals] = useState<IPPUGoal[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [updateModal, setUpdateModal] = useState<boolean>(false)
  const [createModal, setCreateModal] = useState<boolean>(false)
  const [selectedPPU, setSelectedPPU] = useState<IPPUGoal | null>(null)
  const projectContext = useContext(ProjectContext)
  const { status_manager } = projectContext.state
  const { addAlert } = useAlert()

  useEffect(() => {
    reload()
  }, [projectContext])

  const reload = async () => {
    setLoading(true)
    const { id } = projectContext.state.currentProject
    const ppus = await getProjectPPU(id)
    setPPUGoals(ppus)
    setLoading(false)
  }

  const updatePPU = (ppu: IPPUGoal) => {
    if (status_manager) {
      setSelectedPPU(ppu)
      setUpdateModal(true)
    } else {
      showAlart()
    }
  }

  const showAlart = () => {
    addAlert({
      type: 'error',
      title: t('you_do_not_have_access_to_the_PPU_setup'),
      description: t(
        'you_do_not_have_the_required_permissions_to_edit_PPU_layouts',
      ),
    })
  }

  const createPPU = () => {
    setCreateModal(true)
  }

  const onDeletItemClick = async (ppu: IPPUGoal) => {
    await deleteProjectPPU(ppu)
    reload()
  }

  const closeUpdateModalReload = () => {
    setUpdateModal(false)
    reload()
  }

  const closeCreateModalReload = () => {
    setCreateModal(false)
    reload()
  }

  const getCalculationDay = (day: number) => {
    return day === 0
      ? t('sunday')
      : day === 1
        ? t('monday')
        : day === 2
          ? t('tuesday')
          : day === 3
            ? t('wednesday')
            : day === 4
              ? t('thursday')
              : day === 5
                ? t('friday')
                : t('saturday')
  }

  const columns: IListColumns[] = [
    {
      name: 'data_type_ppu',
      size: '200',
      id: 'dataType',
      dataField: 'data_type',
      sortingField: 'data_type',
      cell: (goal: any) => <span>{convertPPUType(goal)}</span>,
    },
    {
      name: 'goal_percent',
      size: '130',
      id: 'goal',
      dataField: 'goal',
      sortingField: 'goal',
    },
    {
      name: 'ppu_freeze_period_weeks',
      size: '200',
      id: 'plan_freeze_period',
      dataField: 'plan_freeze_period',
      sortingField: 'plan_freeze_period',
    },
    {
      name: 'scheduling_mode',
      size: '200',
      id: 'active',
      dataField: 'active',
      sortingField: 'active',
      cell: (active: any) => <span>{active ? 'Nei' : 'Ja'}</span>,
    },
    {
      name: 'calculation_day',
      size: '200',
      id: 'calculationDay',
      dataField: 'calculation_day',
      sortingField: 'calculation_day',
      cell: (calculationDay: any) => (
        <span>{getCalculationDay(calculationDay)}</span>
      ),
    },
    {
      name: 'calculation_time',
      size: '200',
      id: 'calculationTime',
      dataField: 'calculation_time',
      sortingField: 'calculation_time',
      cell: (calculationTime: any) => (
        <span>
          {moment(calculationTime).utcOffset(calculationTime).format('HH:mm')}
        </span>
      ),
    },
    {
      name: 'created_at',
      size: '130',
      id: 'createdAt',
      sortingField: 'created_at',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      sortingField: 'updated_at',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  return (
    <div className={TabPageStyleClass.root}>
      {loading ? (
        <Loader />
      ) : (
        <List
          actionButton={
            status_manager ? (
              <Button onClick={createPPU} type={Button.ButtonType.PRIMARY}>
                {t('new_ppu_goal')}
              </Button>
            ) : undefined
          }
          data={ppuGoals}
          columns={columns}
          tableName={'ppuList'}
          itemsPerPage={0}
          onRowClick={updatePPU}
          actionMenu={
            status_manager
              ? [
                  {
                    name: t('delete'),
                    action: onDeletItemClick,
                  },
                ]
              : []
          }
        />
      )}

      {updateModal && selectedPPU && (
        <Modal
          title={convertPPUType(selectedPPU.data_type)}
          closeModal={() => setUpdateModal(false)}
          show={updateModal}
          size={'w-1/3'}
          maxWidth={600}
        >
          <PPUForm
            closeModal={() => setUpdateModal(false)}
            editingMode={true}
            updatePPU={closeUpdateModalReload}
            ppu={selectedPPU}
            projectId={projectContext.state.currentProject.id}
          />
        </Modal>
      )}

      {createModal && (
        <Modal
          title={t('new_ppu_goal')}
          closeModal={() => setCreateModal(false)}
          show={createModal}
          size={'w-1/3'}
          maxWidth={600}
        >
          <PPUForm
            closeModal={() => setCreateModal(false)}
            editingMode={false}
            updatePPU={closeCreateModalReload}
            projectId={projectContext.state.currentProject.id}
          />
        </Modal>
      )}
    </div>
  )
}

export default PPUGoals
