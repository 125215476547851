import { useEffect } from 'react'

const useKeyPress = (fun: (keyCode: string) => void) => {
  useEffect(() => {
    const setFromEvent = (e: any) => fun(e.key)
    window.addEventListener('keydown', setFromEvent)

    return () => {
      window.removeEventListener('keydown', setFromEvent)
    }
  }, [fun])
}

export default useKeyPress
