import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import UserInspectorPanel from 'src/components/ContractDisciplineTree/UserInspectorPanel'
import ControlAreaModal from 'src/components/construction/controlArea/ControlAreaModal'
import ConstructionTaskForm from 'src/components/construction/tasks/ConstructionTaskForm'
import WagonTypeInspectorPanel from 'src/components/construction/wagon_type/WagonTypeInspectorPanel'
import DisciplineInspectorPanel from 'src/components/discipline/DisciplineInspectorPanel'
import ImprovementPanel from 'src/components/improvement/improvement-panel/ImprovementPanel'
import KeypointInspectorPanel from 'src/components/key-point/KeyPointInspectorPanel'
import MilestoneInspectorPanel from 'src/components/milestones/MilestoneInspectorPanel'
import { buildPdfReviewUrl } from 'src/components/pdf/pdf-queries'
import DeliveryInspectorPanel from 'src/components/process/delivery/DeliveryInspectorPanel'
import SystemInspectorPanel from 'src/components/system/SystemInspectorPanel'
import TestSystemGroupInspectorPanel from 'src/components/system/test-system-groups/TestSystemGroupInspectorPanel'
import TestWorkGroupInspectorPanel from 'src/components/system/test-work-groups/TestWorkGroupInspectorPanel'
import TaskInspectorPanel from 'src/components/task/TaskInspectorPanel'
import TestTypeInspectionPanel from 'src/page/systematic-completion/test_type/TestTypeInspectionPanel'
import { getConstructionTask } from 'src/service/ConstructionTaskService'
import { getConstructionTrain } from 'src/service/ConstructionTrainService'
import { getHighlightUrlInfo } from 'src/service/HighlightService'
import { getRoom } from 'src/service/RoomService'
import Announcemnet from 'src/ui-elements/announcement/Announcement'
import Button from 'src/ui-elements/button/Button'
import Loader from 'src/ui-elements/loader/Loader'
import { getModalUrl } from 'src/utility/modalUtils'
import WagonInspectorPanel from '../../components/ConstructionCanvas/WagonInspectorPanel'
import RoomInspectorPanel from '../../components/construction/room/RoomInspectorPanel'
import TrainInspectorPanel from '../../components/construction/train/TrainInspectorPanel'
import RiskAssessmentForm from '../../components/risk/RiskAssessmentForm'
import RiskInspectorPanel from '../../components/risk/RiskInspectorPanel'
import DocumentInspectorPanel from '../../document/components/Inspector/DocumentInspectorPanel'
import history from '../../history'
import TestInspectorPanel from '../../page/systematic-completion/tests/TestInspectorPanel'
import { getNotification } from '../../service/NotificationsService'
import {
  IProjectData,
  IRisk,
  IRiskAssessmentData,
} from '../../service/OrgTypes'
import { getRisk, getRiskAssessment } from '../../service/RiskService'
import Modal from '../../ui-elements/modal/Modal'
import { capFirstLetter, getModelName } from '../../utility/utils'
import { withAlartContext } from '../withAlartContext'
import { withProjectContext } from '../withProjectContext'
import { IModalProps, IModalState, ModalContext } from './ModalContext'

class ModalContextProvider extends Component<IModalProps, IModalState> {
  public constructor(props: IModalProps, context?: any) {
    super(props, context)
    this.state = {
      project: 0,
      riskGroupId: 0,
      data: {},
      risk: {} as IRisk,
      modalText: '',
      modalType: '',
      loading: true,
      showLogsDefault: false,
    }
  }

  private openModal = (
    projectId: number,
    modal: string,
    id: number,
    additionalParams: string = '',
  ) => {
    history.push(getModalUrl(projectId, modal, id, additionalParams))
    this.checkURL()
  }

  private onCloseModal = () => {
    this.setState({
      modalType: '',
      loading: true,
    })
    if (this.props.closeModal) {
      this.props.closeModal()
    } else {
      history.push(history.location.pathname)
    }
  }

  public UNSAFE_componentWillMount() {
    this.checkURL()
  }

  private checkURL = () => {
    const params = new URLSearchParams(history.location.search)
    if (params.has('modal')) {
      const project = Number(params.get('project'))
      const modalType = params.get('modal')
      const showLogsDefault = params.get('showLogsDefault')
      if (getModelName(modalType || '', this.props.t) === 'ikke funnet') {
        this.showErrorAlert('Invalid Link', 'No modal found for this element')
        return
      }
      const userProjects: IProjectData[] =
        this.props.projectContext.state.projects
      const hasAcessToProject = userProjects.findIndex(
        (projectData) => projectData.project.id === project,
      )
      if (hasAcessToProject < 0) {
        const errorMessage = {
          title: 'Du har ikke tilgang til prosjektet',
          description: '',
        }
        this.showErrorAlert(errorMessage.title, errorMessage.description)
        return
      }
      this.setState({
        project,
        modalType: String(modalType),
        showLogsDefault: Boolean(showLogsDefault),
      })
      const id = Number(params.get('id'))
      switch (modalType) {
        case 'notification':
          return this.getNotif(id)
        case 'constructiontrain':
          return this.getTrains(id)
        case 'constructiontask':
          return this.getConstructionTasks(id)
        case 'assessment':
          return this.getRiskAssasment(id)
        case 'room':
          return this.getRoom(id)
        case 'risk':
          return this.getRisks(Number(params.get('riskGroup')), id)
        case 'filecontainer':
          const showRevision = Boolean(params.get('showRevision'))
          return this.getFileContainer(id, showRevision)
        case 'pdfhighlight':
          return this.getHighlightInfo(id)
        case 'task':
        case 'delivery':
        case 'keypoint':
        case 'milestone':
        case 'improvement':
        case 'controlarea':
        case 'constructionlocomotive':
        case 'test':
        case 'testcase':
        case 'system':
        case 'testtype':
        case 'testsystemgroup':
        case 'testworkgroup':
        case 'testsetuptype':
        case 'testdocumenttype':
        case 'discipline':
        case 'user':
        case 'wagontype':
          return this.getData(id)
      }
    }
  }

  private itemNotFoundError = (id: number | string) => {
    const params = new URLSearchParams(history.location.search)
    const modalType = params.get('modal')
    this.showErrorAlert(
      this.props.t('item_not_found'),
      `${this.props.t('modal_id_not_found', {
        modal: getModelName(modalType || '', this.props.t),
        id,
      })}`,
    )
  }

  private showErrorAlert = (title: string, description: string) => {
    this.onCloseModal()
    const { addAlert } = this.props.alartContext.actions
    addAlert({ type: 'error', title, description })
  }

  private getFileContainer = (id: number, showRevision: boolean) => {
    this.setData({ id, show_revision: showRevision })
  }

  private getData = (id: number) => {
    this.setData({ id })
  }

  private getRisks = (riskGroupId: number, id: number) => {
    this.setState({
      riskGroupId,
    })

    getRisk(id).then((risk) => {
      if (typeof risk.status === 'number') {
        this.itemNotFoundError(id)
      }
      this.setData(risk)
    })
  }

  private getNotif = (id: number) => {
    getNotification(id).then((notification) => {
      this.setData(notification)
    })
  }

  private getHighlightInfo = async (id: number) => {
    const info = await getHighlightUrlInfo(id)
    const url = buildPdfReviewUrl(info)
    this.setData({ url })
  }

  private getTrains = (id: number) => {
    getConstructionTrain(id).then((train) => {
      this.setData(train)
    })
  }

  private getConstructionTasks = (id: number) => {
    getConstructionTask(id).then((task) => {
      this.setData(task)
    })
  }

  private getRiskAssasment = async (id: number) => {
    const assessment = await getRiskAssessment(id)
    if (assessment.status === 404) {
      this.itemNotFoundError(id)
    }
    if (assessment.risk_id) {
      const risk = await getRisk(assessment.risk_id)
      this.setState(
        {
          risk,
        },
        () => {
          this.setData(assessment)
        },
      )
    }
  }

  private getThreatOrPossibility = (assessment: IRiskAssessmentData) => {
    if (assessment) {
      if (assessment.risk_threat_id) {
        return 'threat'
      }
      if (assessment.risk_possibility_id) {
        return 'possibility'
      }
    }
    return null
  }

  private getRoom = async (id: number) => {
    getRoom(id)
      .then((room) => {
        if (room?.id) {
          this.setData(room)
        } else {
          this.setData({ id })
        }
      })
      .catch(() => this.setData({ id }))
  }

  private setData(data: any) {
    if (data) {
      this.setState({
        data,
        loading: false,
      })
    } else {
      this.setState({
        modalType: 'default',
        loading: false,
        modalText: getModelName(this.state.modalType, this.props.t),
      })
    }
  }

  private renderModalSwitch(modalType: string) {
    const { project, data, modalText } = this.state
    const { currentProject } = this.props.projectContext.state
    switch (modalType) {
      case 'loading':
        return (
          <Modal
            show={modalType === 'loading'}
            closeModal={this.onCloseModal}
            title={'Loading ...'}
            maxWidth={null}
          >
            <div className={''}>
              <Loader />
            </div>
          </Modal>
        )
      case 'task':
        return (
          <TaskInspectorPanel
            taskId={this.state.data.id}
            open={modalType === 'task'}
            onClose={this.onCloseModal}
            projectId={currentProject.id}
            notFound={this.itemNotFoundError}
            defaultIndex={this.state.showLogsDefault ? 3 : 0}
          />
        )
      case 'delivery':
        return (
          <DeliveryInspectorPanel
            deliveryId={data.id}
            open={modalType === 'delivery'}
            onClose={this.onCloseModal}
            projectId={currentProject.id}
            notFound={this.itemNotFoundError}
            defaultIndex={this.state.showLogsDefault ? 6 : 0}
          />
        )
      case 'keypoint':
        return (
          <KeypointInspectorPanel
            keyPointId={data.id}
            open={modalType === 'keypoint'}
            onClose={this.onCloseModal}
            origin={{
              name: 'Oversikt',
              url: '/',
            }}
            notFound={this.itemNotFoundError}
            defaultIndex={this.state.showLogsDefault ? 6 : 0}
          />
        )
      case 'milestone':
        return (
          <MilestoneInspectorPanel
            origin={{
              name: 'Oversikt',
              url: '/',
            }}
            open={modalType === 'milestone'}
            onClose={this.onCloseModal}
            milestoneId={data.id}
            notFound={this.itemNotFoundError}
            defaultIndex={this.state.showLogsDefault ? 4 : 0}
          />
        )
      case 'room':
        return (
          <RoomInspectorPanel
            roomId={data.id}
            onClose={this.onCloseModal}
            open={modalType === 'room'}
            defaultIndex={this.state.showLogsDefault ? 2 : 0}
          />
        )
      case 'test':
        return (
          <TestInspectorPanel
            disabled={true}
            open={modalType === 'test'}
            testId={data.id}
            onClose={this.onCloseModal}
            onUpdate={() => {}}
            defaultIndex={this.state.showLogsDefault ? 5 : 0}
          />
        )
      case 'improvement':
        return (
          <ImprovementPanel
            improvementId={data.id}
            projectId={project}
            show={modalType === 'improvement'}
            onClose={this.onCloseModal}
            notFound={this.itemNotFoundError}
            defaultIndex={this.state.showLogsDefault ? 0 : undefined}
          />
        )
      case 'controlarea':
        return (
          <ControlAreaModal
            controlAreaId={data.id}
            open={modalType === 'controlarea'}
            onClose={this.onCloseModal}
            onUpdate={() => {}}
            defaultIndex={this.state.showLogsDefault ? 5 : 0}
          />
        )
      case 'risk':
        return (
          <RiskInspectorPanel
            riskId={data.id}
            open={modalType === 'risk'}
            onClose={this.onCloseModal}
            defaultIndex={this.state.showLogsDefault ? 3 : 0}
          />
        )
      case 'notification':
        return (
          <Modal
            show={modalType === 'notification'}
            closeModal={this.onCloseModal}
            title={'Systemvarsling'}
            size={'w-1/3'}
            maxWidth={null}
          >
            <div className={'px-2 text-gray-700'}>
              <div
                className={'break-words flex-wrap'}
                dangerouslySetInnerHTML={{ __html: data.newValue }}
              />
            </div>
          </Modal>
        )
      case 'constructiontrain':
        return (
          <TrainInspectorPanel
            trainId={data.id}
            open={modalType === 'constructiontrain'}
            onClose={this.onCloseModal}
            onUpdate={() => {}}
            defaultIndex={this.state.showLogsDefault ? 1 : 0}
          />
        )
      case 'constructionlocomotive':
        return (
          <WagonInspectorPanel
            open={modalType === 'constructionlocomotive'}
            wagonId={data.id}
            projectId={this.state.project}
            onClose={this.onCloseModal}
            onUpdate={() => {}}
            defaultIndex={this.state.showLogsDefault ? 3 : 0}
          />
        )
      case 'constructiontask':
        return (
          <ConstructionTaskForm
            open={modalType === 'constructiontask'}
            isEditing={true}
            constructionTask={data}
            constructionWagonIdProps={data.construction_locomotive_id}
            closeModal={this.onCloseModal}
            updateList={this.onCloseModal}
          />
        )
      case 'assessment':
        const { risk } = this.state
        return (
          <Modal
            show={modalType === 'assessment'}
            closeModal={this.onCloseModal}
            title={
              `${capFirstLetter(this.props.t('risk_assessment'))} ` +
              risk.record_id +
              '-' +
              risk.title
            }
            size={'w-5/6'}
            maxWidth={1400}
          >
            <RiskAssessmentForm
              closeModal={this.onCloseModal}
              risk={risk}
              projectId={project}
              riskAreaId={data.risk_area_id}
              matrixType={this.getThreatOrPossibility(data)}
              isInitialAssessment={false}
              assessment={data}
              createAssessment={false}
            />
          </Modal>
        )
      case 'system':
        return (
          <SystemInspectorPanel
            systemId={data.id}
            open={modalType === 'system'}
            onClose={this.onCloseModal}
            onUpdate={this.onCloseModal}
            defaultIndex={this.state.showLogsDefault ? 10 : 0}
          />
        )
      case 'testworkgroup':
        return (
          <TestWorkGroupInspectorPanel
            projectId={currentProject.id}
            testWorkGroupId={data.id}
            open={modalType === 'testworkgroup'}
            onClose={this.onCloseModal}
            onUpdate={() => {}}
            defaultIndex={3}
          />
        )
      case 'testsystemgroup':
        return (
          <TestSystemGroupInspectorPanel
            projectId={currentProject.id}
            testSystemGroupId={data.id}
            open={modalType === 'testsystemgroup'}
            onClose={this.onCloseModal}
            onUpdate={() => {}}
            defaultIndex={3}
          />
        )
      case 'testtype':
        return (
          <TestTypeInspectionPanel
            testTypeId={data.id}
            open={modalType === 'testtype'}
            onClose={this.onCloseModal}
            onUpdate={() => {}}
            disabled={true}
            defaultIndex={1}
          />
        )
      case 'filecontainer':
        return (
          <DocumentInspectorPanel
            documentId={data.id}
            showRevision={data.show_revision}
            open={modalType === 'filecontainer'}
            onClose={this.onCloseModal}
            onUpdate={this.onCloseModal}
          />
        )
      case 'discipline':
        return (
          <DisciplineInspectorPanel
            discipline={data}
            projectId={project}
            open={modalType === 'discipline'}
            onClose={this.onCloseModal}
            onUserClick={() => undefined}
            readonly={false}
            onUpdate={() => undefined}
          />
        )
      case 'user':
        return (
          <UserInspectorPanel
            user={data}
            open={modalType === 'user'}
            onClose={this.onCloseModal}
          />
        )
      case 'wagontype':
        return (
          <WagonTypeInspectorPanel
            open={modalType === 'wagontype'}
            projectId={project}
            onClose={this.onCloseModal}
            wagonId={data.id}
            onUpdate={() => undefined}
          />
        )
      case 'pdfhighlight':
        return <Redirect to={data.url} />
      default:
        return (
          <Modal
            show={modalType === 'default'}
            closeModal={this.onCloseModal}
            title={this.props.t('ooops_there_is_no', { modal: modalText })}
            maxWidth={600}
          >
            <Button
              type={Button.ButtonType.PRIMARY}
              onClick={this.onCloseModal}
            >
              {this.props.t('close')}
            </Button>
          </Modal>
        )
    }
  }

  public render() {
    const { state } = this
    const actions = {
      checkURL: () => this.checkURL(),
      openModal: (
        projectId: number,
        modal: string,
        id: number,
        additionalParams: string = '',
      ) => this.openModal(projectId, modal, id, additionalParams),
    }
    const context = {
      actions,
      state,
    }
    const { modalType, loading } = this.state
    return (
      <ModalContext.Provider value={context}>
        <Announcemnet />
        {this.props.children}
        {!(loading && modalType === '') &&
          this.renderModalSwitch(loading ? this.props.t('loading') : modalType)}
      </ModalContext.Provider>
    )
  }
}

export default withTranslation()(
  withAlartContext(withProjectContext(ModalContextProvider)),
)
