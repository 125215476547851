import { useContext, useEffect, useState } from 'react'
import { ImportContext } from '../ImportContext'
import { ImportStatus } from '../ImportTypes'

export const useJobId = <T>(jobId?: number) => {
  const { pullInterval, importTasks } = useContext(ImportContext).state
  const [status, setLoading] = useState<ImportStatus>()
  const [data, setData] = useState<T>()

  useEffect(() => {
    const _job = importTasks.find((job) => job.id === jobId)
    if (_job) {
      setData(_job.job_data)
      setLoading(_job.status)
    }
  }, [jobId, pullInterval, importTasks])

  return {
    status,
    data,
  }
}
