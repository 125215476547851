import * as React from 'react'

interface IconProp {
  color?: string
}

const SquareIcon: React.FC<IconProp> = (prop) => {
  const { color } = prop
  return (
    <svg className="w-[11px] h-[11px] mr-1" viewBox="0 0 32 32">
      <rect
        width="32"
        height="32"
        fill={color}
        stroke="white"
        strokeWidth="7"
      />
    </svg>
  )
}

export default SquareIcon
