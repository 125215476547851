import { IDocument } from './OrgTypes'
import { request } from './service-utils'

export const getDocumentsForType = (
  projectId: number,
  parentType: string,
  parentId: number,
): Promise<IDocument[]> =>
  request(
    `/projects/${projectId}/parents/${parentType}/${parentId}/documents`,
    'GET',
  )

export const deleteDocument = (
  projectId: number,
  documentId: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/documents/${documentId}`,
    'DELETE',
    {},
    true,
  )
}

export const deleteFile = (fileUrl: any): Promise<any> => {
  return request(`remove_file`, 'DELETE', fileUrl, true)
}
