import { twMerge } from 'tailwind-merge'
import { classNames } from '../../utility/utils'

interface IProgress {
  loaded: number
  total: number
}
export interface ILoaderProps {
  centerLoader?: boolean
  progress?: IProgress
}

const percentage = (progress: IProgress) => {
  return `${Math.floor((progress.loaded / progress.total) * 100)}%`
}

const Loader = ({ centerLoader = true, progress }: ILoaderProps) => {
  return (
    <div
      className={twMerge(
        centerLoader
          ? 'inset-1/2 fixed z-[200]'
          : 'justify-center items-center flex h-64 max-w-screen pb-9',
        progress && 'flex flex-col items-center gap-6',
      )}
    >
      <div className={classNames('lds-spinner', 'scale-50')}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      {progress && (
        <span className="mx-auto mt-2 text-sm">{percentage(progress)}</span>
      )}
    </div>
  )
}

export default Loader
