import { IPhase } from './OrgTypes'
import { request } from './service-utils'

export const createPhase = (
  phase: IPhase,
  projectId: number,
): Promise<IPhase> => {
  return request(`projects/${projectId}/phases`, 'POST', phase)
}

export const editPhase = (taskType: IPhase): Promise<IPhase> => {
  return request(`phases/${taskType.id}`, 'PUT', taskType)
}

export const getProjectPhases = (projectId: number): Promise<IPhase[]> => {
  return request(`projects/${projectId}/phases`, 'GET')
}

export const deletePhase = (task: IPhase) => {
  return request(`/phases/${task.id}`, 'DELETE', {}, true)
}

export const swapOrderPhase = (
  phaseId: number,
  sequence: number,
): Promise<IPhase[]> => {
  return request(`phases/${phaseId}/change_order`, 'PUT', {
    sequence: sequence,
  })
}
