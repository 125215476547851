import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { userDefinedColumns } from 'src/components/TableColumns/Columns'
import useProjectId from 'src/components/hooks/useProjectId'
import { IMetaValue } from 'src/document/types/IMetaData'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { ITestWorkGroup } from 'src/service/OrgTypes'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import { editTestWorkGroup } from 'src/service/TestWorkGroupService'
import {
  dateColumn,
  editableTextColumn,
  textColumn,
  editableDateColumn,
  multiFilterOptionsColumn,
  multiFilterOptionsColumnEditable,
  userColumn,
  systemStatusColumn,
} from 'src/ui-elements/Table/Columns'
import useListHelper from 'src/ui-elements/list/UseListHelper'

interface ISystemTableColumnsProps {
  writeAccess: boolean
  reload: () => void
}

export const useTestWorkGroupColumns = ({
  writeAccess,
  reload,
}: ISystemTableColumnsProps) => {
  const projectId = useProjectId()
  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('TestWorkGroup')
  const { t } = useTranslation()
  const {
    getContractFilter,
    getDisciplineFilter,
    getUserFilter,
    getTestWorkGroupStatusFilter,
  } = useListHelper()

  const onFieldChange = (
    field: string,
    key: number,
    value: string | number | null,
  ) => {
    const testSystemGroup = { id: key, [field]: value }
    editTestWorkGroup(testSystemGroup).then(() => {
      reload()
    })
  }

  const getPriorities = async () => [
    {
      id: 'non_critical',
      name: t('non_critical'),
      value: 'non_critical',
      active: false,
    },
    {
      id: 'critical',
      name: t('critical'),
      value: 'critical',
      active: false,
    },
  ]

  const updateMetaField = (data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload()
      })
    }
  }

  const userDefinedAttributesColumns = systemTypeGroup?.optional_fields
    ? userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateMetaField,
        'meta_data',
        true,
        false,
        !writeAccess,
      )
    : []

  const columns = [
    editableTextColumn(
      'record_id',
      { name: t('test_work_group_id') },
      (key, value) => onFieldChange('record_id', +key, value),
      !writeAccess,
    ),
    editableTextColumn(
      'title',
      { name: capitalize(t('title')) },
      (key, value) => onFieldChange('title', +key, value),
      !writeAccess,
    ),
    systemStatusColumn(
      'test_work_group_status',
      {
        name: t('status'),
        getFilter: getTestWorkGroupStatusFilter,
        field: 'test_work_group_status.name',
      },
      (key, status) =>
        onFieldChange('test_work_group_status_id', +key, status ?? ''),
      projectId,
      'TestWorkGroup',
      !writeAccess,
    ),
    editableTextColumn(
      'description',
      { name: capitalize(t('description')) },
      (key, value) => onFieldChange('description', +key, value),
      !writeAccess,
    ),
    editableTextColumn(
      'location',
      { name: capitalize(t('location')) },
      (key, value) => onFieldChange('location', +key, value),
      !writeAccess,
    ),
    textColumn('percent_completed', {
      name: capitalize(t('percent_completed')),
    }),
    editableDateColumn(
      'planned_start',
      { name: t('planned_start') },
      (date, item) =>
        onFieldChange('planned_start', item.id, date ? date : null),
      undefined,
      !writeAccess,
    ),
    editableDateColumn(
      'planned_end',
      { name: t('planned_end') },
      (date, item) => onFieldChange('planned_end', item.id, date ? date : null),
      undefined,
      !writeAccess,
    ),
    editableDateColumn(
      'actual_start',
      { name: t('actual_start') },
      (date, item) =>
        onFieldChange('actual_start', item.id, date ? date : null),
      undefined,
      !writeAccess,
    ),
    editableDateColumn(
      'actual_end',
      { name: t('actual_end') },
      (date, item) => onFieldChange('actual_end', item.id, date ? date : null),
      undefined,
      !writeAccess,
    ),
    multiFilterOptionsColumnEditable(
      'priority',
      { name: t('priority'), getFilter: getPriorities },
      ['name'],
      getPriorities,
      (key, value) => onFieldChange('priority', key, value),
      !writeAccess,
      (value) => t(value),
    ),
    multiFilterOptionsColumnEditable(
      'discipline',
      {
        name: capitalize(t('discipline')),
        getFilter: getDisciplineFilter,
        field: 'discipline.shortName||discipline.name',
      },
      ['shortName', 'name'],
      () => getProjectDisciplines(projectId),
      (key, value) => onFieldChange('discipline_id', key, value),
      !writeAccess,
    ),
    multiFilterOptionsColumn(
      'contract',
      {
        name: t('contract'),
        getFilter: getContractFilter,
        field: 'contract.contractNumber||contract.contractName',
      },
      ['contractNumber', 'contractName'],
    ),
    dateColumn('created_at', {
      name: t('created_at'),
    }),
    dateColumn('updated_at', {
      name: t('updated_at'),
    }),
    userColumn('created_by', {
      name: t('created_by'),
      getFilter: getUserFilter,
      field: 'created_by.firstName||created_by.lastName',
    }),
    userColumn('updated_by', {
      name: t('updated_by'),
      getFilter: getUserFilter,
      field: 'updated_by.firstName||updated_by.lastName',
    }),
  ] as ColumnDef<ITestWorkGroup>[]

  const defaultOrdering = [
    'select',
    'record_id',
    'name',
    'description',
    'test_system_group_status',
    'location',
    'discipline',
    'contract',
    'user_defined',
    'created_by',
    'updated_by',
    'created_at',
    'updated_at',
  ]

  return { defaultOrdering, userDefinedAttributesColumns, columns }
}
