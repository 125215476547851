import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RadioItems, { IRadioItems } from 'src/ui-elements/radio/RadioItems'
import RiskMatrixComponent from '../../components/risk/RiskMatrixComponent'
import {
  IIwithAlartContext,
  withAlartContext,
} from '../../context/withAlartContext'
import {
  IWithRiskContext,
  withRiskContext,
} from '../../context/withRiskContext'
import {
  IMatrixData,
  IRisk,
  IRiskArea,
  IRiskAssessmentData,
  IRiskPossibility,
  IRiskProbability,
  IRiskThreat,
} from '../../service/OrgTypes'
import {
  createRiskAssessment,
  getRiskAreaMatrixData,
  getRiskGroupMatrixData,
  getRiskMatrix,
} from '../../service/RiskService'
import Button from '../../ui-elements/button/Button'
import Icon from '../../ui-elements/icon/Icon'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import Textarea from '../../ui-elements/textarea/Textarea'
import { constructRangeValue, toFixed } from '../../utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'
import LinkfyText from '../linkfyText/LinkfyText'
import Selector from '../selectors/Selector'

interface IRiskAssessmentFormProps
  extends IWithRiskContext,
    IIwithAlartContext {
  risk: IRisk
  projectId: number
  isInitialAssessment: boolean
  closeModal: (reload?: boolean) => void
  riskAreaId?: number
  matrixType?: string | null
  assessment?: IRiskAssessmentData
  createAssessment: boolean
}

const RiskAssessmentForm = ({
  risk,
  projectId,
  isInitialAssessment,
  createAssessment,
  closeModal,
  riskAreaId,
  matrixType,
  assessment,
  riskContext,
}: IRiskAssessmentFormProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    fieldSet: classNames('bg-grey-light', 'rounded-sm', 'flex'),
    radioStyle: {
      desc: classNames('text-sm'),
      value: classNames('text-xs'),
    },
  }

  const [selectedRiskAreaId, setSelectedRiskAreaId] = useState<number>(
    riskAreaId ? riskAreaId : 0,
  )
  const [selectedRiskArea, setSelectedRiskArea] = useState<
    IRiskArea | undefined
  >(undefined)
  const [riskPossibilityId, setRiskPossibilityId] = useState<number>(0)
  const [riskProbabilityId, setRiskProbabilityId] = useState<number>(0)
  const [selectedRiskProbability, setSelectedRiskProbability] = useState<
    IRiskProbability | undefined
  >(undefined)
  const [riskThreatId, setRiskThreatId] = useState<number>(0)
  const [description, setDescription] = useState<string>('')
  const [riskAreas, setRiskAreas] = useState<IRiskArea[]>([])
  const [riskRBPossibilities, setRiskRBPossibilities] = useState<IRadioItems[]>(
    [],
  )
  const [riskRBProbabilities, setRiskRBProbabilities] = useState<IRadioItems[]>(
    [],
  )
  const [riskRBThreats, setRiskRBThreats] = useState<IRadioItems[]>([])
  const [riskAreaError, setRiskAreaError] = useState<string>('')
  const [riskError, setRiskError] = useState<string>('')
  const [riskRBProbabilitiesError, setRiskRBProbabilitiesError] =
    useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [matrixData, setMatrixData] = useState<IMatrixData>({
    rowSize: 4,
    columnSize: 4,
    matrixThreatConfigData: [],
    matrixPossibilityConfigData: [],
  })
  const [currentThreatIndex, setCurrentThreatIndex] = useState<number>(-1)
  const [currentProbabilityIndex, setCurrentProbabilityIndex] =
    useState<number>(-1)
  const [currentPossibilityIndex, setCurrentPossibilityIndex] =
    useState<number>(-1)
  const [riskPossibilities, setRiskPossibilities] = useState<
    IRiskPossibility[]
  >([])
  const [riskProbabilities, setRiskProbabilities] = useState<
    IRiskProbability[]
  >([])
  const [riskThreats, setRiskThreats] = useState<IRiskThreat[]>([])

  const [lowerValue, setLowerValue] = useState<number | undefined>(
    assessment ? assessment.lower_value : undefined,
  )
  const [upperValue, setUpperValue] = useState<number | undefined>(
    assessment ? assessment.upper_value : undefined,
  )
  const [averageValue, setAverageValue] = useState<number | undefined>(
    assessment ? assessment.average_value : undefined,
  )
  const [expectedValue, setExpectedValue] = useState<number | undefined>(
    assessment ? assessment.expected_value : undefined,
  )
  const [expectedValueWithProbability, setExpectedValueWithProbability] =
    useState<number | undefined>(
      assessment ? assessment.expected_value_with_probability : undefined,
    )

  const [lowerValueErrorMessage, setLowerValueErrorMessage] =
    useState<string>('')
  const [upperValueErrorMessage, setUpperValueErrorMessage] =
    useState<string>('')
  const [averageValueErrorMessage, setAverageValueErrorMessage] =
    useState<string>('')
  const [selectLoading, setSelectLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  useEffect(() => {
    reload()
  }, [])

  const reload = () => {
    setLoading(true)

    if (selectedRiskAreaId) {
      getRiskAreaMatrixData(risk.risk_group_id, selectedRiskAreaId)
        .then((res: IRiskArea) => {
          setSelectedRiskArea(res)
          if (riskAreas.length === 0 && res) {
            setRiskAreas([res])
          }
          constructRiskAreaData(res)
        })
        .catch((err) => console.error(err))
    } else {
      getRiskGroupMatrixData(risk.risk_group_id)
        .then((res: IRiskArea[]) => {
          setRiskAreas(res)
        })
        .catch((err) => console.error(err))
    }

    getRiskMatrix(projectId)
      .then((res: IMatrixData) => {
        setMatrixData(res)
      })
      .catch((err) => console.error(err))

    setLoading(false)
  }

  const getRiskAreaData = async () => {
    setSelectLoading(true)
    getRiskGroupMatrixData(risk.risk_group_id)
      .then((res: IRiskArea[]) => {
        setRiskAreas(res)
        setSelectLoading(false)
      })
      .catch((err) => console.error(err))
  }

  const constructRiskAreaData = (riskArea: IRiskArea) => {
    generateRiskRadiobuttons(riskArea)
    let riskThreatIndex = -1
    let riskProbabilityIndex = -1
    let riskPossibilityIndex = -1
    if (riskArea) {
      const riskProbability =
        riskProbabilityId && riskArea.risk_probabilities
          ? riskArea.risk_probabilities.find(
              (it) => it.id === riskProbabilityId,
            )
          : undefined
      const riskThreat =
        riskThreatId && riskArea.risk_threats
          ? riskArea.risk_threats.find((it) => it.id === riskThreatId)
          : undefined
      const riskPossibility =
        riskPossibilityId && riskArea.risk_possibilities
          ? riskArea.risk_possibilities.find(
              (it) => it.id === riskPossibilityId,
            )
          : undefined

      setSelectedRiskProbability(riskProbability)
      riskThreatIndex =
        riskThreat && riskThreat.order ? riskThreat.order - 1 : -1
      riskProbabilityIndex =
        riskProbability && riskProbability.order
          ? riskProbability.order - 1
          : -1
      riskPossibilityIndex =
        riskPossibility && riskPossibility.order
          ? riskPossibility.order - 1
          : -1
    }

    setRiskProbabilities(
      riskArea && riskArea.risk_probabilities
        ? riskArea.risk_probabilities
        : [],
    )
    setRiskThreats(
      riskArea && riskArea.risk_threats ? riskArea.risk_threats : [],
    )
    setRiskPossibilities(
      riskArea && riskArea.risk_possibilities
        ? riskArea.risk_possibilities
        : [],
    )
    setCurrentProbabilityIndex(riskProbabilityIndex)
    setCurrentThreatIndex(riskThreatIndex)
    setCurrentPossibilityIndex(riskPossibilityIndex)
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false
    setLoading(true)

    if (!selectedRiskAreaId) {
      setRiskAreaError(t('select_risk_area_in_risk_assessment'))
      error = true
    }

    if (!riskProbabilityId || riskProbabilityId === 0) {
      setRiskRBProbabilitiesError(t('select_probability'))
      error = true
    }

    if (!riskPossibilityId && !riskThreatId) {
      setRiskError(t('select_possibility_or_threat'))
      error = true
    }

    if (!error) {
      createRiskAssessment(risk.id, {
        id: 0,
        description,
        risk_area_id: selectedRiskAreaId,
        risk_probability_id: riskProbabilityId,
        risk_threat_id: riskThreatId > 0 ? riskThreatId : null,
        risk_possibility_id: riskPossibilityId > 0 ? riskPossibilityId : null,
        isInitialAssessment,
        possibilityIndex:
          currentProbabilityIndex > -1
            ? currentProbabilityIndex + 1
            : undefined,
        messureIndex:
          currentThreatIndex > -1
            ? currentThreatIndex + 1
            : currentPossibilityIndex > -1
              ? currentPossibilityIndex + 1
              : undefined,
        lower_value: lowerValue,
        upper_value: upperValue,
        average_value: averageValue,
        include_risk_estimation: selectedRiskArea
          ? selectedRiskArea.include_risk_estimation
          : false,
      }).then(() => {
        if (riskContext.actions) {
          riskContext.actions.reloadRiskData()
        }
        closeModal(true)
      })
    }

    setLoading(false)
  }

  const onRiskProbabilityChange = (e: any) => {
    const value = Number(e.target.value)
    const probability = riskProbabilities.find((it) => it.id === value)
    const probabilityIndex =
      probability && probability.order ? probability.order - 1 : -1
    setRiskProbabilityId(value)
    setSelectedRiskProbability(probability)
    setCurrentProbabilityIndex(probabilityIndex)
    setRiskRBProbabilitiesError('')

    calculateExpectedValueWithProbability(expectedValue, value)
  }

  const onRiskThreatChange = (e: any) => {
    const value = Number(e.target.value)

    const threat = riskThreats.find((it) => it.id === value)
    const threatIndex = threat && threat.order ? threat.order - 1 : -1

    setRiskThreatId(value)
    setCurrentThreatIndex(threatIndex)
    setRiskError('')
  }

  const onRiskPossibilityChange = (e: any) => {
    const value = Number(e.target.value)
    const possibility = riskPossibilities.find((it) => it.id === value)
    const possibilityIndex =
      possibility && possibility.order ? possibility.order - 1 : -1
    setRiskPossibilityId(value)
    setCurrentPossibilityIndex(possibilityIndex)
    setRiskError('')
  }

  const removeThreatMatrix = () => {
    setRiskThreatId(0)
    setCurrentThreatIndex(-1)
  }

  const removeProssibilityMatrix = () => {
    setRiskPossibilityId(0)
    setCurrentPossibilityIndex(-1)
  }

  const generateSingleRiskRadiobutton = (
    data: IRiskPossibility[],
    quantityUnit: string,
  ) => {
    const values: IRadioItems[] = []

    data.map((p: IRiskPossibility) => {
      const rangeValue = constructRangeValue(p.from, p.to, quantityUnit)
      const unit = (
        <span className={styleClass.radioStyle.value}>({rangeValue})</span>
      )
      values.push({
        id: p.id,
        name: (
          <span className={styleClass.radioStyle.desc}>
            {p.from !== null && p.to !== null ? (
              <span>
                {p.name} {unit}
              </span>
            ) : (
              p.name
            )}
          </span>
        ),
      })
    })

    return values
  }

  const generateRiskRadiobuttons = (riskArea: IRiskArea | undefined) => {
    const probabilities: IRadioItems[] = []

    if (riskArea) {
      if (riskArea.risk_possibilities) {
        setRiskRBPossibilities(
          generateSingleRiskRadiobutton(
            riskArea.risk_possibilities,
            riskArea.quantity_unit,
          ),
        )
      }
      if (riskArea.risk_threats) {
        setRiskRBThreats(
          generateSingleRiskRadiobutton(
            riskArea.risk_threats,
            riskArea.quantity_unit,
          ),
        )
      }
      if (riskArea.risk_probabilities) {
        riskArea.risk_probabilities.map((p: IRiskProbability) => {
          const unit = (
            <span className={styleClass.radioStyle.value}>
              ({p.from}% - {p.to}%)
            </span>
          )
          probabilities.push({
            id: p.id,
            name: (
              <span className={styleClass.radioStyle.desc}>
                {p.from !== null && p.to !== null ? (
                  <span>
                    {p.name} {unit}
                  </span>
                ) : (
                  p.name
                )}
              </span>
            ),
          })
        })

        setRiskRBProbabilities(probabilities)
      }
    }
  }

  const onRiskAreaChange = (value: number) => {
    const riskArea: IRiskArea | undefined = riskAreas
      .filter((ra) => ra.id === value)
      .pop()
    if (riskArea) {
      setSelectedRiskArea(riskArea)
      constructRiskAreaData(riskArea)
      generateRiskRadiobuttons(riskArea)
      setSelectedRiskAreaId(value)
      setRiskAreaError('')
    }
  }

  const onDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const onUpperValueChange = (e: any) => {
    setUpperValue(e.target.value)
    setUpperValueErrorMessage('')
    calculateExpectedValue(e.target.value, averageValue, lowerValue)
  }

  const onLowerValueChange = (e: any) => {
    setLowerValue(e.target.value)
    setLowerValueErrorMessage('')
    calculateExpectedValue(upperValue, averageValue, e.target.value)
  }

  const onAverageValueChange = (e: any) => {
    setAverageValue(e.target.value)
    setAverageValueErrorMessage('')
    calculateExpectedValue(upperValue, e.target.value, lowerValue)
  }

  const calculateExpectedValue = (
    uValue?: number,
    aValue?: number,
    lValue?: number,
  ) => {
    if (uValue && aValue && lValue) {
      const expectedValueNew =
        (Number(uValue) + Number(aValue) * 0.42 + Number(lValue)) / 2.42
      setExpectedValue(expectedValueNew)
      calculateExpectedValueWithProbability(expectedValueNew, riskProbabilityId)
    }
  }

  const calculateExpectedValueWithProbability = (
    eValue?: number,
    selectedProbabilityId?: number,
  ) => {
    if (eValue && selectedProbabilityId) {
      const probability = riskProbabilities.find(
        (it) => it.id === selectedProbabilityId,
      )
      if (probability) {
        if (probability.expected) {
          const expectedValueRated = eValue * (probability.expected / 100)
          setExpectedValueWithProbability(expectedValueRated)
        } else {
          const expectedValueRated =
            probability.from && probability.to
              ? eValue * ((probability.from + probability.to) / 200)
              : undefined
          setExpectedValueWithProbability(expectedValueRated)
        }
      }
    }
  }

  const assessmentDescription = assessment ? assessment.description : ''
  const threatId =
    assessment && assessment.risk_threat_id !== null
      ? assessment.risk_threat_id
      : 0
  const possibilityId =
    assessment && assessment.risk_possibility_id !== null
      ? assessment.risk_possibility_id
      : 0
  const probabilityId =
    assessment && assessment.risk_probability_id !== null
      ? assessment.risk_probability_id
      : 0

  const lastProbability = riskProbabilities.find(
    (it) => it.id === probabilityId,
  )
  const lastThreat = riskThreats.find((it) => it.id === threatId)
  const lastPossibility = riskPossibilities.find(
    (it) => it.id === possibilityId,
  )

  const lastThreatIndex =
    lastThreat && lastThreat.order ? lastThreat.order - 1 : -1
  const lastProbabilityIndex =
    lastProbability && lastProbability.order ? lastProbability.order - 1 : -1
  const lastPossibilityIndex =
    lastPossibility && lastPossibility.order ? lastPossibility.order - 1 : -1

  const assesmentSelectors = (
    selectedProbabilityId: number,
    selectedThreatId: number,
    selectedPossibilityId: number,
    disabled: boolean,
  ) => {
    return (
      <>
        <div className={'p-1 relative'}>
          <div className={'flex flex-col justify-start'}>
            <label className={'flex-col px-1 text-gray-500 text-sm'}>
              {t('probability')}
            </label>
            {riskRBProbabilitiesError && !disabled ? (
              <label className={'pl-2 text-sm my-2'} style={{ color: 'Red' }}>
                {riskRBProbabilitiesError}
              </label>
            ) : null}
            <RadioItems
              items={riskRBProbabilities}
              onClick={onRiskProbabilityChange}
              value={selectedProbabilityId}
              stack={true}
              disabled={disabled}
            />
          </div>
        </div>
        <>
          {selectedProbabilityId !== 0 && (
            <>
              {selectedThreatId === 0 && selectedPossibilityId !== 0 ? null : (
                <>
                  {isInitialAssessment || matrixType === 'threat' ? (
                    <div className={'p-2 relative'}>
                      {selectedThreatId !== 0 && isInitialAssessment ? (
                        <Icon
                          icon={Icon.IconType.CLOSE}
                          className={'absolute right-0 mr-2'}
                          onClick={removeThreatMatrix}
                        />
                      ) : null}
                      {selectedThreatId === 0 && selectedPossibilityId !== 0 ? (
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'rgba(239, 239, 239, 0.8)',
                            zIndex: 50,
                          }}
                        />
                      ) : null}
                      <div className={'flex flex-col justify-start'}>
                        <label
                          className={'flex-col px-1 text-gray-500 text-sm'}
                        >
                          {t('threat')}
                        </label>
                        {riskError && !disabled ? (
                          <label
                            className={'pl-2 text-sm my-2'}
                            style={{ color: 'Red' }}
                          >
                            {riskError}
                          </label>
                        ) : null}
                        <RadioItems
                          items={riskRBThreats}
                          onClick={onRiskThreatChange}
                          value={selectedThreatId}
                          stack={true}
                          disabled={
                            (selectedThreatId === 0 &&
                              selectedPossibilityId !== 0) ||
                            disabled
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              )}
              {selectedPossibilityId === 0 && selectedThreatId !== 0 ? null : (
                <>
                  {isInitialAssessment || matrixType === 'possibility' ? (
                    <div className={'p-2 relative'}>
                      {selectedPossibilityId !== 0 && isInitialAssessment ? (
                        <Icon
                          icon={Icon.IconType.CLOSE}
                          className={'absolute right-0 mr-2'}
                          onClick={removeProssibilityMatrix}
                        />
                      ) : null}
                      {selectedPossibilityId === 0 && selectedThreatId !== 0 ? (
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'rgba(239, 239, 239, 0.8)',
                            zIndex: 50,
                          }}
                        />
                      ) : null}
                      <div className={'flex flex-col justify-start'}>
                        <label
                          className={`flex-col px-1 text-gray-500 text-sm`}
                        >
                          {t('possibility')}
                        </label>
                        {riskError && !disabled ? (
                          <label
                            className={'pl-2 text-sm my-2'}
                            style={{ color: 'Red' }}
                          >
                            {riskError}
                          </label>
                        ) : null}
                        <RadioItems
                          items={riskRBPossibilities}
                          onClick={onRiskPossibilityChange}
                          value={selectedPossibilityId}
                          stack={true}
                          disabled={
                            (selectedPossibilityId === 0 &&
                              selectedThreatId !== 0) ||
                            disabled
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      </>
    )
  }

  const assesmentMatrix = (
    data: IMatrixData,
    threatIndex: number,
    probabilityIndex: number,
    possibilityIndex: number,
  ) => {
    return (
      <div className={'w-full'}>
        {threatIndex !== -1 && probabilityIndex !== -1 && (
          <div className="ml-4 mr-6 p-2 relative">
            <RiskMatrixComponent
              title={t('threat')}
              matrixType="threat"
              columnsProp={data.columnSize}
              rowsProp={data.rowSize}
              configArray={data.matrixThreatConfigData}
              conseqValueProp={threatIndex}
              likelyValueProp={probabilityIndex}
            />
          </div>
        )}
        {possibilityIndex !== -1 && probabilityIndex !== -1 && (
          <div className="ml-4 mr-6 p-2 relative">
            <RiskMatrixComponent
              title={t('possibility')}
              matrixType="possibility"
              columnsProp={data.columnSize}
              rowsProp={data.rowSize}
              configArray={data.matrixPossibilityConfigData}
              conseqValueProp={possibilityIndex}
              likelyValueProp={probabilityIndex}
            />
          </div>
        )}
      </div>
    )
  }

  const showAssessment = (
    threatIndex: number,
    probabilityIndex: number,
    possibilityIndex: number,
    selectedProbabilityId: number,
    selectedThreatId: number,
    selectedPossibilityId: number,
    title: string,
    assesDescription: string,
    disabled: boolean,
  ) => {
    return (
      <div className={'flex flex-col pt-2 mb-2 '}>
        <label className={'ml-2 mb-2 text-sm font-medium'}>{title}</label>
        <div className={'border border-gray mx-2'}>
          {assesmentSelectors(
            selectedProbabilityId,
            selectedThreatId,
            selectedPossibilityId,
            disabled,
          )}
          {assesmentMatrix(
            matrixData,
            threatIndex,
            probabilityIndex,
            possibilityIndex,
          )}
        </div>
        <div className={'w-full mt-2'}>
          {disabled ? (
            riskAttribute(
              capFirstLetter(t('assessment_comment')),
              assesDescription,
            )
          ) : (
            <Textarea
              placeholder={t('assessment_comment')}
              label={t('assessment_comment')}
              isValid={false}
              value={assesDescription}
              block={true}
              disabled={disabled}
              required={false}
              onChange={onDescriptionChange}
            />
          )}
        </div>
        {disabled &&
          selectedRiskArea &&
          selectedRiskArea.include_risk_estimation &&
          assessment &&
          showRiskEstimation(assessment)}
      </div>
    )
  }

  const showRiskEstimation = (value: IRiskAssessmentData) => {
    return (
      <div className={'flex flex-col py-2'}>
        <label className={'ml-2 mb-2 text-sm font-medium'}>
          {capFirstLetter(t('triple_estimate'))}
        </label>
        <div className={'flex mx-2 text-sm leading-5 justify-stretch'}>
          <span className={'mr-4'}>
            {capFirstLetter(t('minimum_value'))} :{' '}
            {value.lower_value ? toFixed(value.lower_value) : ''}
          </span>
          <span className={'mr-4'}>
            {capFirstLetter(t('average_value'))} :{' '}
            {value.average_value ? toFixed(value.average_value) : ''}
          </span>
          <span className={'mr-2'}>
            {capFirstLetter(t('maximum_value'))} :{' '}
            {value.upper_value ? toFixed(value.upper_value) : ''}
          </span>
        </div>
        <div className={'flex mx-2 mt-2 text-sm leading-5'}>
          <span className={'mr-4'}>
            {t('expected_value')} :{' '}
            {value.expected_value ? toFixed(value.expected_value) : ''}
          </span>
          <span className={'mr-2'}>
            {t('expected_value_with_probability')} :{' '}
            {value.expected_value_with_probability
              ? toFixed(value.expected_value_with_probability)
              : ''}
          </span>
        </div>
      </div>
    )
  }

  const createRiskEstimate = (disabled: boolean) => {
    return (
      <div className={'flex flex-col pt-2'}>
        <label className={'ml-2 mb-2 text-sm text-gray-500 font-medium'}>
          {t('triple_estimate')}
        </label>
        <div className={'flex flex-col border border-gray mx-2 pb-2'}>
          <div className={'flex  w-full'}>
            <div className={'w-1/3'}>
              <Input
                label={t('minimum_value')}
                type={'number'}
                min={'0'}
                placeholder={t('minimum_value')}
                block={true}
                value={lowerValue}
                disabled={disabled}
                errorMessage={lowerValueErrorMessage}
                onChange={onLowerValueChange}
              />
            </div>

            <div className={'w-1/3'}>
              <Input
                label={t('average_value')}
                type={'number'}
                min={'0'}
                placeholder={t('average_value')}
                block={true}
                value={averageValue}
                disabled={disabled}
                errorMessage={averageValueErrorMessage}
                onChange={onAverageValueChange}
              />
            </div>

            <div className={'w-1/3'}>
              <Input
                label={t('maximum_value')}
                type={'number'}
                min={'0'}
                placeholder={t('maximum_value')}
                block={true}
                value={upperValue}
                disabled={disabled}
                errorMessage={upperValueErrorMessage}
                onChange={onUpperValueChange}
              />
            </div>
          </div>

          <div className={'flex  w-full'}>
            <div className={'w-1/3'}>
              <Input
                label={t('expected_value')}
                placeholder={t('expected_value')}
                type={'number'}
                min={'0'}
                block={true}
                value={expectedValue ? toFixed(expectedValue) : expectedValue}
                disabled={true}
              />
            </div>
            <div className={'w-1/3'}>
              <Input
                label={t('probability')}
                placeholder={t('probability')}
                block={true}
                value={
                  createAssessment
                    ? selectedRiskProbability
                      ? selectedRiskProbability.expected + '%'
                      : ''
                    : lastProbability
                      ? lastProbability.expected + '%'
                      : ''
                }
                disabled={true}
              />
            </div>
            <div className={'w-1/3'}>
              <Input
                label={t('expected_value_with_probability')}
                placeholder={t('expected_value_with_probability')}
                type={'number'}
                min={'0'}
                block={true}
                value={
                  expectedValueWithProbability
                    ? toFixed(expectedValueWithProbability)
                    : expectedValueWithProbability
                }
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const riskAttribute = (label: string, content: string) => {
    return (
      <div className={'w-full ml-2 my-2'}>
        <div className={'items-start text-sm leading-5 font-medium'}>
          {label}
        </div>
        <span className={'text-sm block break-words text-gray-500'}>
          <LinkfyText value={content} />
        </span>
      </div>
    )
  }
  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={'flex flex-row flex-wrap'}>
        <div className={'w-full xl:w-1/2'}>
          {!createAssessment && selectedRiskArea ? (
            riskAttribute(
              capFirstLetter(t('risk_area')),
              selectedRiskArea?.name,
            )
          ) : (
            <div className={'w-1/2 mb-2'}>
              <Selector
                items={riskAreas}
                selectedItemId={selectedRiskAreaId}
                onSelect={onRiskAreaChange}
                onOpenSelectFunction={getRiskAreaData}
                loading={selectLoading}
                label={t('risk_area')}
                dataFields={['name']}
                required={true}
                fontSize={'sm'}
                fontWeight={'bold'}
                errorMessage={riskAreaError}
              />
            </div>
          )}
          {riskAttribute(t('cause_why'), risk.cause)}
          {riskAttribute(t('event_What'), risk.incident)}
          {riskAttribute(t('impact'), risk.impact)}

          {assessment &&
            assessment.risk_area_id === selectedRiskAreaId &&
            showAssessment(
              lastThreatIndex,
              lastProbabilityIndex,
              lastPossibilityIndex,
              probabilityId,
              threatId,
              possibilityId,
              t('last_assessment'),
              assessmentDescription,
              true,
            )}

          {assessment && assessment.risk_area_id !== selectedRiskAreaId && (
            <div>
              <label className={'ml-4 mb-2 text-sm text-gray-500 font-medium'}>
                {t('last_assessment')}
              </label>
              <p className="text-red ml-4 text-sm">
                {t('last_assessment_is_not_valid')}
              </p>
            </div>
          )}
        </div>

        <div className={'w-full xl:w-1/2 mt-2 py-1.5'}>
          {createAssessment && selectedRiskAreaId
            ? showAssessment(
                currentThreatIndex,
                currentProbabilityIndex,
                currentPossibilityIndex,
                riskProbabilityId,
                riskThreatId,
                riskPossibilityId,
                t('new_evaluation'),
                description,
                false,
              )
            : null}
          {selectedRiskArea && selectedRiskArea.include_risk_estimation
            ? createRiskEstimate(!createAssessment)
            : null}
        </div>

        {createAssessment && (
          <ModalFooter>
            <Button
              type={Button.ButtonType.DEFAULT}
              onClick={() => closeModal()}
            >
              {t('cancel')}
            </Button>
            <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
              {loading ? <Spinner /> : t('add')}
            </Button>
          </ModalFooter>
        )}
      </div>
    </form>
  )
}

export default withAlartContext(withRiskContext(RiskAssessmentForm))
