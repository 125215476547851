import ExitToApp from '@icons/exit_to_app.svg'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import { useDetailUrl } from 'src/hooks/useDetailUrl'
import Icon from 'src/ui-elements/icon/Icon'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { DetailPageKeys, IDetailPageData } from 'src/utility/DetailPageUtils'
import { classNames } from 'src/utility/utils'

interface IInspectorRoot {
  title: string
  onClose: () => void
  children: JSX.Element
  show: boolean
  className?: string
  headerClassName?: string
  hasTable?: boolean
  disableOutsideClick?: boolean
  detailPageData?: IDetailPageData<DetailPageKeys>
}

const InspectorRoot: React.FC<IInspectorRoot> = ({
  title,
  onClose,
  children,
  show,
  className = '',
  headerClassName = '',
  disableOutsideClick = true,
  detailPageData,
}) => {
  const styleClass = {
    root: classNames(
      'min-w-[500px]',
      'fixed',
      'shadow-xsm',
      'bg-white',
      'z-[70]',
      'right-[5px]',
      'overflow-auto',
      'top-[70px]',
      show ? 'visible' : 'inivisible',
      'h-[calc(100vh_-_75px)]',
      className,
    ),
    header: classNames(
      'flex',
      'items-center',
      'justify-between',
      'mb-4',
      'p-5',
      'pb-2',
      'mb-2',
      'border-b',
      'border-gray-200',
      headerClassName,
    ),
    title: classNames(
      'text-sm',
      'text-black',
      'leading-normal',
      'font-bold',
      'flex',
      'items-center',
      'gap-2',
    ),
    children: classNames('flex', 'flex-col'),
  }

  const { t } = useTranslation()
  const detailUrl = useDetailUrl(detailPageData)

  return show ? (
    <CloseClickOutside
      /* eslint-disable-next-line no-console */
      onClose={() => (disableOutsideClick ? console.log('') : onClose())}
    >
      <div className={styleClass.root}>
        <div className={styleClass.header}>
          <p className={styleClass.title}>
            {title}
            {detailUrl && (
              <Tooltip
                message={t('go_to_page_view')}
                direction={'bottom'}
                offset={2}
              >
                <Link to={detailUrl}>
                  <ExitToApp
                    className={'hover:fill-blue-root fill-gray-600'}
                    fontSize={20}
                  />
                </Link>
              </Tooltip>
            )}
          </p>
          <Icon
            style={{ opacity: 0.5 }}
            icon={Icon.IconType.CLOSE_GRAY}
            onClick={onClose}
            className={'cursor-pointer flex-shrink-0'}
          />
        </div>
        <div className={styleClass.children}>{children}</div>
      </div>
    </CloseClickOutside>
  ) : null
}

export default InspectorRoot
