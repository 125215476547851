import moment from 'moment'
import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { isEmpty, TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import { IMainProcess } from '../../../service/OrgTypes'
import {
  deleteMainProcess,
  getMainProcesses,
  swapOrderMainProcess,
} from '../../../service/ProcessService'
import Button from '../../../ui-elements/button/Button'
import List from '../../../ui-elements/list/List'
import { IListColumns } from '../../../ui-elements/list/ListTypes'
import Loader from '../../../ui-elements/loader/Loader'
import Modal from '../../../ui-elements/modal/Modal'
import MainProcessFrom from './MainProcessForm'

interface IMainProcessListProps extends WithTranslation {
  projectId: number
}

interface IMainProcessListState {
  mainProcess: IMainProcess[]
  selectedMainProcess: IMainProcess
  loading: boolean
  showEditModal: boolean
  showDeleteModal: boolean
  showCreateModal: boolean
  showDetailsModal: boolean
}

class MainProcessList extends PureComponent<
  IMainProcessListProps,
  IMainProcessListState
> {
  public constructor(props: IMainProcessListProps) {
    super(props)
    this.state = {
      mainProcess: [],
      selectedMainProcess: {} as IMainProcess,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showCreateModal: false,
      showDetailsModal: false,
    }
  }

  private reload() {
    getMainProcesses(this.props.projectId)
      .then((res: IMainProcess[]) => {
        this.setState({
          mainProcess: res,
          loading: false,
        })
      })
      .catch((err) => console.error(err))
  }

  public componentDidMount() {
    this.reload()
  }

  public componentDidUpdate(prevProps: IMainProcessListProps) {
    if (prevProps !== this.props) {
      this.setState(
        {
          mainProcess: [],
          loading: true,
        },
        () => {
          this.reload()
        },
      )
    }
  }

  private onEditItemClick = (contract: IMainProcess) => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
      selectedMainProcess: contract,
    }))
  }

  private onDeletItemClick = (mainprocess: IMainProcess) => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
      selectedMainProcess: mainprocess,
    }))
  }

  private openEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }))
  }

  private closeEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }))

    this.reload()
  }

  private closeDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }))
  }

  private openCreateModal = () => {
    this.setState((prevState) => ({
      showCreateModal: !prevState.showCreateModal,
    }))
  }

  private closeCreateModal = () => {
    this.setState((prevState) => ({
      showCreateModal: !prevState.showCreateModal,
    }))

    this.reload()
  }

  private deleteMainProcess = () => {
    if (this.state.selectedMainProcess.id) {
      deleteMainProcess(this.state.selectedMainProcess.id)
        .then(() => {
          this.reload()
        })
        .catch(() => {
          this.setState({
            loading: false,
          })
        })
    }

    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }))
  }

  private onRowClick = (row: any) => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
      selectedMainProcess: row,
    }))
  }

  private swapMainProcess = (id: number, seq: number) => {
    const fromMainProcess: any = this.state.mainProcess[id].id

    swapOrderMainProcess(fromMainProcess, seq).then(() => {
      this.reload()
    })
  }

  public render() {
    const {
      mainProcess,
      selectedMainProcess,
      loading,
      showDeleteModal,
      showCreateModal,
      showEditModal,
    } = this.state
    const { t } = this.props
    const columns: IListColumns[] = [
      {
        name: 'id',
        id: 'recordId',
        size: '120',
        dataField: 'record_id',
      },
      {
        name: 'main_process_name',
        size: '300',
        id: 'name',
        dataField: 'name',
      },
      {
        name: 'created_at',
        size: '130',
        id: 'createdAt',
        dataField: 'created_at',
        cell: (created: any) => (
          <span>{created ? moment(created).format('L') : ''}</span>
        ),
      },
      {
        name: 'updated_at',
        size: '130',
        id: 'updatedAt',
        dataField: 'updated_at',
        cell: (updated: any) => (
          <span>{updated ? moment(updated).format('L') : ''}</span>
        ),
      },
      {
        name: 'order',
        size: '150',
        id: 'sequence',
        dataField: 'sequence',
      },
    ]

    return (
      <div className={TabPageStyleClass.root}>
        <List
          actionButton={
            <Button
              onClick={this.openCreateModal}
              type={Button.ButtonType.PRIMARY}
            >
              {t('new_main_process')}
            </Button>
          }
          data={mainProcess}
          tableName={'mainprocesslist'}
          columns={columns}
          itemsPerPage={0}
          onRowClick={this.onRowClick}
          actionMenu={[
            {
              name: t('edit'),
              action: this.onEditItemClick,
            },
            {
              name: t('delete'),
              action: this.onDeletItemClick,
            },
          ]}
          isRowDraggable={true}
          swapOrder={this.swapMainProcess}
        />

        <Modal
          size={'w-1/3'}
          show={showEditModal}
          closeModal={this.openEditModal}
          title={t('edit_main_process')}
          maxWidth={600}
        >
          <MainProcessFrom
            editingMode={true}
            mainProcess={selectedMainProcess}
            projectId={this.props.projectId}
            closeModal={this.closeEditModal}
          />
        </Modal>

        {!isEmpty(selectedMainProcess) && (
          <DeleteModal
            show={showDeleteModal}
            closeModal={this.closeDeleteModal}
            onDelete={this.deleteMainProcess}
            itemIdnType={`${selectedMainProcess.record_id} (${t(
              'main_process',
            )})`}
            itemName={`${selectedMainProcess.record_id} - ${selectedMainProcess.name}`}
          />
        )}

        <Modal
          size={'w-1/3'}
          show={showCreateModal}
          closeModal={this.openCreateModal}
          title={t('add_main_process')}
          maxWidth={600}
        >
          <MainProcessFrom
            editingMode={false}
            mainProcess={{ name: '' }}
            projectId={this.props.projectId}
            closeModal={this.closeCreateModal}
          />
        </Modal>
        {loading ? <Loader /> : null}
      </div>
    )
  }
}

export default withTranslation()(MainProcessList)
