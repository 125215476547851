export const milestoneListExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/mile_stones/pages/0/0',
    method: 'POST',
  },
  name: 'Milestones',
  displayName: 'Milepæler',
  columns: [
    'Id',
    'Tittel',
    'Hovedprosess',
    'Tema',
    'Type',
    'Status',
    'Frist',
    'Baselinedato',
    'Varighet (dager)',
    'Forsinkelse (Dager)',
    'Nøkkelpunkter',
    'Ansvarlig',
    'Disiplin',
    'Kontrakt',
    'Opprettet',
    'Sist endret',
  ],
  rows: [
    'record_id',
    'name',
    'main_process.name',
    'team.name',
    'tag.name',
    'status',
    'deadline',
    'baseline',
    'duration',
    'delay',
    'done_children||open_children||expired_children',
    'responsible.firstName||responsible.lastName',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'created_at',
    'updated_at',
  ],
}
