import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { createContract, editContract } from '../../service/ContractService'
import { IContract } from '../../service/OrgTypes'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import { capFirstLetter, classNames } from '../../utility/utils'

interface ICreateContractForm {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  contract: IContract
}

const ContractForm = ({
  projectId,
  closeModal,
  editingMode,
  contract,
}: ICreateContractForm) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'my-1'),
  }

  const { t } = useTranslation()
  const [contractId] = useState<number>(contract.id)
  const [contractName, setContractName] = useState<string>(
    contract.contractName,
  )
  const [contractNumber, setContractNumber] = useState<string>(
    contract.contractNumber,
  )
  const [contractSize, setContractSize] = useState<string>(
    contract.contractSize,
  )
  const [companyName, setCompanyName] = useState<string>(contract.company_name)
  const [contractNameErrorMessage, setContractNameErrorMessage] =
    useState<string>('')
  const [contractNumberErrorMessage, setContractNumberErrorMessage] =
    useState<string>('')
  const [companyNameErrorMessage, setCompanyNameErrorMessage] =
    useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false
    setContractNameErrorMessage('')
    setContractNumberErrorMessage('')
    setCompanyNameErrorMessage('')
    setLoading(true)

    // Contract validation
    if (!contractName) {
      setContractNameErrorMessage(t('fill_in_the_contract_name'))
      error = true
    }

    if (!contractNumber) {
      setContractNumberErrorMessage(t('fill_in_the_contract_number'))
      error = true
    }

    if (!companyName) {
      setCompanyNameErrorMessage(t('fill_in_company_name'))
      error = true
    }

    if (!error) {
      if (editingMode) {
        editContract({
          id: contractId,
          contractName,
          contractNumber,
          company_name: companyName,
          contractSize,
        }).then(() => {
          closeModal()
        })
      } else {
        createContract(
          {
            id: 0,
            contractName,
            contractNumber,
            company_name: companyName,
            contractSize,
          },
          projectId,
        ).then(() => {
          setContractName('')
          setContractNumber('')
          closeModal()
        })
      }
    }

    setLoading(false)
  }

  const onContractNameChange = (e: any) => {
    const contractNameVal = e.target.value
    setContractName(contractNameVal)
    setContractNameErrorMessage('')
  }

  const onContractNumberChange = (e: any) => {
    const contractNumberVal = e.target.value
    setContractNumber(contractNumberVal)
    setContractNumberErrorMessage('')
  }

  const onCompanyNameChange = (e: any) => {
    setCompanyName(e.target.value)
    setCompanyNameErrorMessage('')
  }

  const onContractSizeChange = (e: any) => {
    setContractSize(e.target.value)
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('contract_name')}
          block={true}
          value={contractName}
          errorMessage={contractNameErrorMessage}
          required={true}
          onChange={onContractNameChange}
          placeholder={t('enter_the_name_of_the_contract')}
          autoFocus={true}
        />
      </div>

      <div className={styleClass.inputGroup}>
        <Input
          label={t('contract_number')}
          placeholder={t('enter_the_contract_number')}
          block={true}
          value={contractNumber}
          errorMessage={contractNumberErrorMessage}
          required={true}
          onChange={onContractNumberChange}
        />
      </div>

      <div className={styleClass.inputGroup}>
        <Input
          label={t('size')}
          type={'number'}
          min={'0'}
          placeholder={t('enter_the_estimated_contract_amount_including_vat')}
          block={true}
          value={contractSize}
          onChange={onContractSizeChange}
        />
      </div>

      <div className={styleClass.inputGroup}>
        <Input
          label={t('company')}
          placeholder={t(
            'enter_the_name_of_the_company_that_is_the_contracting_party',
          )}
          block={true}
          value={companyName}
          errorMessage={companyNameErrorMessage}
          required={true}
          onChange={onCompanyNameChange}
        />
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        {editingMode ? (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : capFirstLetter(t('update'))}
          </Button>
        ) : (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : capFirstLetter(t('add'))}
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default ContractForm
