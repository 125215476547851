import ComposedChartGraph from 'src/components/statistics/ComposedChartGraph'
import Loader from 'src/ui-elements/loader/Loader'
import { IBarData, ILineData, IAreaData } from '../../service/OrgTypes'
import InformationComponent from '../information-component/InformationComponent'
import InformationTitleComponent from '../information-component/InformationTitleComponent'
import { BarChartDataType } from './KPIUtils'

interface IKPIBarChartComponentProps {
  data: BarChartDataType
  loading: boolean
  title: string
  subTitle?: string
  axisDataKey: string
  barData?: IBarData[]
  lineData?: ILineData[]
  areaData?: IAreaData[]
  unit?: string
  equalHeightloader?: boolean
}

const KPIComposedChartComponent = ({
  data,
  loading,
  title,
  subTitle,
  axisDataKey,
  barData,
  lineData,
  areaData,
  unit,
}: IKPIBarChartComponentProps) => {
  return (
    <InformationComponent fullWidth={true} noMargin={true}>
      <InformationTitleComponent title={title} subTitle={subTitle} />
      {loading ? (
        <Loader />
      ) : (
        <ComposedChartGraph
          height={360}
          chartData={data}
          xDataKey={axisDataKey}
          yUnit={unit}
          barDataKeys={barData}
          lineDataKeys={lineData}
          areaDataKeys={areaData}
        />
      )}
    </InformationComponent>
  )
}

export default KPIComposedChartComponent
