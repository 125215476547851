import Close from '@icons/close.svg'
import Preview from '@icons/preview.svg'
import { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'

const SelectorWithTable = ({
  label,
  name,
  onShowModal,
  onRemove,
  gotoLink,
  modal,
}: {
  label: string
  name?: string
  onShowModal: () => void
  onRemove?: () => void
  gotoLink?: string
  modal?: boolean
}) => {
  const modalContext = useContext(ModalContext)
  const history = useHistory()
  return (
    <div className="flex items-center w-full">
      <Tooltip message={label}>
        <label className="min-w-[150px] font-bold text-sm leading-5 text-gray-600">
          {label}
        </label>
      </Tooltip>
      <span
        className="text-sm truncate w-full flex items-center border border-transparent hover:border-gray-300 rounded justify-between p-1 cursor-pointer"
        onClick={onShowModal}
      >
        <p className="truncate text-gray-700">{name}</p>
        <div className="flex gap-1">
          {onRemove && (
            <Close
              className="fill-red cursor-pointer w-5 h-5 hover:bg-red-100"
              onClick={(e) => {
                e.stopPropagation()
                onRemove()
              }}
            />
          )}
          {gotoLink && (
            <Link
              to={modal ? gotoLink : ''}
              onClick={(e) => {
                e.stopPropagation()
                if (modal) {
                  history.push(gotoLink)
                  modalContext.actions.checkURL(gotoLink)
                }
              }}
            >
              <Preview className="fill-blue-root cursor-pointer w-5 h-5 hover:bg-blue-100" />
            </Link>
          )}
        </div>
      </span>
    </div>
  )
}

export default SelectorWithTable
