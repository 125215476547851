import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportStatus, ImportTask } from 'src/context/ImportContext/ImportTypes'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import Spinner from 'src/ui-elements/loader/Spinner'
import { formatDateRelativeToNow } from 'src/utility/dateUtils'
import { classNames } from 'src/utility/utils'

interface ImportNotificationItemProps {
  item: ImportTask
  onClick: () => void
  remove: () => void
}

export enum IMPORT_TYPE {
  SYSTEM_IMPORT = 'systems',
  TEST_SYSTEM_GROUP_IMPORT = 'test_system_groups',
  TEST_WORK_GROUP_IMPORT = 'test_work_groups',
  SYSTEM_TYPE_IMPORT = 'system_types',

  DOCUMENT_IMPORT = 'file_containers',
  SHAREPOINT_SYNC = 'sharepoint_sync',
  UNKNOWN = 'unknown',
}

const styleClass = {
  commonBtn: {
    root: classNames(
      'mx-2',
      'w-8',
      'h-8',
      'p-0.5',
      'flex',
      'justify-center',
      'items-center',
      'rounded-full',
      'hover:cursor-pointer',
    ),
  },
  fileName: (status: ImportStatus) =>
    classNames(
      'text-xs underline font-medium text-gray-900',
      status === ImportStatus.SUCCESS
        ? 'text-blue-root hover:scale-105'
        : 'text-gray-500',
    ),
}

export const importType = (item: ImportTask): IMPORT_TYPE => {
  const name = item.job_name?.toLocaleLowerCase() ?? ''
  if (name.includes('system_import')) {
    return IMPORT_TYPE.SYSTEM_IMPORT
  } else if (name.includes('test_system_group_import')) {
    return IMPORT_TYPE.TEST_SYSTEM_GROUP_IMPORT
  } else if (name.includes('test_work_group_import')) {
    return IMPORT_TYPE.TEST_WORK_GROUP_IMPORT
  } else if (name.includes('system_type_import')) {
    return IMPORT_TYPE.SYSTEM_TYPE_IMPORT
  } else if (name.includes('filecontainer_import')) {
    return IMPORT_TYPE.DOCUMENT_IMPORT
  } else if (name.includes('sharepoint_sync')) {
    return IMPORT_TYPE.SHAREPOINT_SYNC
  } else {
    return IMPORT_TYPE.UNKNOWN
  }
}

const ImportNotificationItem: FC<ImportNotificationItemProps> = ({
  item,
  onClick,
  remove,
}) => {
  const { t, i18n } = useTranslation()
  const [hover, setHover] = useState(false)

  const getStatusText = (status: ImportStatus) => {
    switch (status) {
      case ImportStatus.ERROR:
        return t('import_failed_with_error', {
          error: item.job_data?.error_count,
        })
      case ImportStatus.FAILED:
        return t('import_fail')
      case ImportStatus.IN_PROGRESS:
        return t('import_in_progress')
      case ImportStatus.SUCCESS:
        return t('import_successfully')
    }
  }

  const badgeColor = (status: ImportStatus) => {
    switch (status) {
      case ImportStatus.ERROR:
      case ImportStatus.FAILED:
        return BadgeColor.RED
      case ImportStatus.IN_PROGRESS:
        return BadgeColor.BLUE
      case ImportStatus.SUCCESS:
        return BadgeColor.GREEN
    }
  }

  const iconClass = (status: ImportStatus) => {
    switch (status) {
      case ImportStatus.ERROR:
        return 'text-amber-500'
      case ImportStatus.FAILED:
        return 'text-red-500'
      case ImportStatus.IN_PROGRESS:
        return 'text-blue-500'
      case ImportStatus.SUCCESS:
        return 'text-green-500'
    }
  }

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      className="flex gap-4 justify-start border-b border-gray-100 pb-2 items-center"
    >
      <span className={styleClass.commonBtn.root}>
        <>
          {importType(item) === IMPORT_TYPE.SHAREPOINT_SYNC ? (
            <Icon icon={Icons.SHARE_POINT} />
          ) : (
            <MaterialIcon
              icon="cloud_upload"
              className={iconClass(item.status) + ' opacity-75'}
            />
          )}
        </>
      </span>
      <div className="flex items-center w-full justify-between">
        <div className="flex flex-col justify-start gap-2 ">
          <a className={styleClass.fileName(item.status)}>
            {t(importType(item).toLocaleString())}
          </a>
          <Badge
            color={badgeColor(item.status)}
            text={getStatusText(item.status)}
          />
        </div>
        <div className="flex flex-col items-center gap-2">
          {hover && (
            <MaterialIcon
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                remove()
              }}
              icon="close "
              className="self-end text-xs text-black hover:scale-105"
            />
          )}
          <div className={'text-xxs text-gray-500 -mt-px whitespace-no-wrap'}>
            {formatDateRelativeToNow(item.created_at, i18n.language)}
          </div>
          {item.status === ImportStatus.IN_PROGRESS && <Spinner />}
        </div>
      </div>
    </div>
  )
}

export default ImportNotificationItem
