import { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import { IUserData } from 'src/service/OrgTypes'
import { getUser } from 'src/service/UserService'
import { classNames } from 'src/utility/utils'

interface IUserPreview {
  userId: number
}

const UserPreview = forwardRef(({ userId }: IUserPreview, ref) => {
  useImperativeHandle(ref, () => ({
    handleUserClick() {
      handleUserClick()
    },
  }))

  const [user, setUser] = useState<IUserData | null>(null)
  const history = useHistory()

  const styleClass = {
    root: classNames(
      'min-w-[120px]',
      'w-max',
      'h-8',
      'rounded-md',
      'bg-white',
      'flex',
      'flex-row',
      'gap-2',
      'items-center',
      'shadow-md',
      'p-2',
    ),
  }

  useEffect(() => {
    const fetchUser = async () => {
      if (+userId) {
        const res = await getUser(userId)
        setUser(res)
      }
    }

    fetchUser()
  }, [userId])

  const handleUserClick = () => {
    if (userId) history.push(`/users/${userId}`)
  }

  return (
    <div className={styleClass.root}>
      {!user ? (
        <p className={'text-blue-root'}>{''}</p>
      ) : (
        <>
          <img
            className={'rounded-full overflow-hidden w-6 h-6'}
            src={user?.image_url ?? '/user_profile.png'}
            alt={user?.firstName ?? ''}
          />
          <p
            className={'text-blue-root font-semibold'}
          >{`${user.firstName} ${user.lastName}`}</p>
        </>
      )}
    </div>
  )
})

UserPreview.displayName = 'UserPreview'

export default UserPreview
