import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getDocumentHistory } from '../../service/FileContainerService'

export const fileContainerHistoryKey = 'file-container-history'
export const useFileContainerHistory = (documentId: number) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [fileContainerHistoryKey, projectId, documentId],
    queryFn: () => getDocumentHistory(projectId, documentId),
  })
}
