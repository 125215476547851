import SentimentSatisfied from '@icons/sentiment_satisfied.svg'
import EmojiPicker from 'emoji-picker-react'
import { useState } from 'react'
import IconButton from 'src/ui-elements/button/IconButton'
import CloseClickOutside from '../click-outside/CloseClickOutside'

interface EmojiPickerButtonProps {
  onSelect: (emoji: string) => void
}

const EmojiPickerButton = ({ onSelect }: EmojiPickerButtonProps) => {
  const [open, setOpen] = useState(false)
  return (
    <CloseClickOutside onClose={() => setOpen(false)} className="relative">
      <div className="absolute right-0 bottom-8">
        <EmojiPicker
          autoFocusSearch={false}
          open={open}
          onEmojiClick={(value) => {
            setOpen(false)
            onSelect(value.emoji)
          }}
          previewConfig={{
            showPreview: false,
          }}
          className="z-50"
          lazyLoadEmojis
        />
      </div>
      <IconButton
        className="fill-gray-800"
        size="large"
        onClick={() => setOpen((value) => !value)}
        icon={<SentimentSatisfied />}
        tooltipText="Emoji"
      />
    </CloseClickOutside>
  )
}

export default EmojiPickerButton
