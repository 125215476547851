import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRiskProbability } from '../../service/OrgTypes'
import List from '../../ui-elements/list/List'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import { classNames } from '../../utility/utils.js'
import RiskProbabilityForm from './RiskProbabilityForm'

interface IRiskProbabilityListProps {
  riskProbabilities: IRiskProbability[]
  reload: () => void
}

const RiskProbabilityList = ({
  riskProbabilities,
  reload,
}: IRiskProbabilityListProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  const [selectedRiskProbability, setSelectedRiskProbability] = useState<
    IRiskProbability | undefined
  >(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)

  const { t } = useTranslation()

  const onEditItemClick = (riskProbability: IRiskProbability) => {
    setShowEditModal((n) => !n)
    setSelectedRiskProbability(riskProbability)
  }

  const openEditModal = () => {
    setShowEditModal(true)
  }

  const closeEditModal = () => {
    setShowEditModal(false)
    setLoading(true)
    reload()
    setLoading(false)
  }

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    setLoading(true)
    reload()
    setLoading(false)
  }

  const columns: IListColumns[] = [
    {
      name: 'name',
      size: '200',
      id: 'name',
      dataField: 'name',
    },
    {
      name: 'from_percent',
      size: '200',
      id: 'from',
      dataField: 'from',
    },
    {
      name: 'to_percent',
      size: '200',
      id: 'to',
      dataField: 'to',
    },
    {
      name: 'average_value_probability',
      size: '200',
      id: 'expected',
      dataField: 'expected',
    },
    {
      name: 'order',
      size: '150',
      id: 'order',
      dataField: 'order',
    },
    {
      name: 'created_at',
      size: '130',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  return (
    <div className={styleClass.root}>
      <List
        data={riskProbabilities}
        title={t('probabilities')}
        columns={columns}
        itemsPerPage={0}
        tableName={'riskProbabilityList'}
        onRowClick={onEditItemClick}
      />
      {selectedRiskProbability ? (
        <Modal
          show={showEditModal}
          closeModal={openEditModal}
          title={t('edit_probability')}
          maxWidth={800}
        >
          <div>
            <RiskProbabilityForm
              editingMode={true}
              riskProbability={selectedRiskProbability}
              closeModal={closeEditModal}
            />
          </div>
        </Modal>
      ) : null}

      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_probability')}
        maxWidth={800}
      >
        <div>
          <RiskProbabilityForm
            editingMode={false}
            riskProbability={{
              id: 0,
              name: '',
              order: 0,
              from: 0,
              to: 0,
              expected: 0,
            }}
            closeModal={closeCreateModal}
          />
        </div>
      </Modal>
      {loading ? <Loader /> : null}
    </div>
  )
}

export default RiskProbabilityList
