import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UploadFile from 'src/components/document/UploadFile'
import { IDocument } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { capFirstLetter } from 'src/utility/utils'

interface IUploadFileModal {
  title: string
  show: boolean
  setShow: (show: boolean) => void
  uploadURL: string
  onUpload: (newFileUrl: string) => void
}

const AddFileModal = ({
  title,
  setShow,
  show,
  uploadURL,
  onUpload,
}: IUploadFileModal) => {
  const { t } = useTranslation()
  const [fileUrlErrorMessage, setFileUrlErrorMessage] = useState<string>('')
  const [error, setError] = useState(false)
  const [fileUrl, setFileUrl] = useState<string>('')
  const filesUploaded = (docs: IDocument[]) => {
    docs[0] && setFileUrl(docs[0].fileUrl)
  }

  const onCloseModal = () => {
    setShow(false)
    setFileUrl('')
  }

  const validateFileUrl = () => {
    const urlRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    if (!fileUrl) {
      setFileUrlErrorMessage(capFirstLetter(t('fill_in_link')))
      setError(true)
    } else if (!fileUrl.match(urlRegex)) {
      setFileUrlErrorMessage(capFirstLetter(t('add_valid_url')))
      setError(true)
    } else {
      setFileUrlErrorMessage('')
      setError(false)
      return false
    }
    return true
  }

  return (
    <Modal
      title={`${capFirstLetter(t('upload'))} ${title.toLowerCase()}`}
      closeModal={() => setShow(false)}
      show={show}
      maxWidth={600}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (validateFileUrl()) return
          onUpload(fileUrl)
          onCloseModal()
        }}
      >
        <div className="p-8">
          <UploadFile
            uploadUrl={uploadURL}
            uploadedDocuments={filesUploaded}
            singleFileUpload
          />
        </div>
        <div className="flex flex-row items-end">
          <div className={'w-full pb-1'}>
            <Input
              block={true}
              label={t('link')}
              onChange={(e) => {
                setFileUrlErrorMessage('')
                setError(false)
                setFileUrl(e.target.value)
              }}
              value={fileUrl}
              errorMessage={fileUrlErrorMessage}
            />
          </div>
        </div>
        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={onCloseModal}>
            {t('cancel')}
          </Button>
          <Button type={Button.ButtonType.PRIMARY} disabled={error}>
            {t('save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default AddFileModal
