import moment from 'moment'
import {
  ChangeEvent,
  Fragment,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DateRangePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { Direction } from 'src/components/multi-selector/MultiSelector'
import { IListFilterFromParent } from 'src/service/OrgTypes'
import { useDebouncer } from 'src/utility/Debouncer'
import { renderDayWithoutTimezone } from '../../utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'
import Icon from '../icon/Icon'
import Input from '../input/Input'
import FilterItem from './FilterItem'
import { IListFilter, ISingleFilter, ListContext } from './ListContextProvider'
import ListResize from './ListResize'
import { filterType, IListColumns } from './ListTypes'

interface IListColumnProps {
  index: number
  column: IListColumns
  columns: IListColumns[]
  data: any[]
  handleSort: (c: any, b: any) => void
  handleMove: (f: number, t: number) => void
  centerHeaders?: boolean
  filterFromParent?: IListFilterFromParent
}

const styleClass = {
  headerColumn: (centered?: boolean, sort?: boolean) =>
    classNames(
      'flex',
      'flex-row',
      centered ? 'justify-center' : 'justify-between',
      'cursor-move',
      'relative',
      'pl-1',
      'pt-0.5',
      'bg-gray-50',
      'text-left',
      'text-sm',
      'leading-6',
      'font-medium',
      'text-d-black',
      'tracking-wider',
      'max-h-8',
      'flex-no-wrap',
      sort ? 'border-b-4 border-blue-root' : 'border-[#C4C4C4]',
      'hover:bg-blue-50',
      'text-[#6A7380]',
    ),
  filterBox: (type: boolean) =>
    classNames(
      'absolute',
      'bg-white',
      'font-normal',
      'border-gray-300',
      'border',
      'shadow-lg',
      'right-0',
      'py-1',
      'flex',
      !type ? 'flex-col' : 'flex-row',
      !type ? 'justify-start' : 'justify-center',
      'z-30',
    ),
  input: classNames('bg-grey'),
  cover: classNames('fixed', 'inset-x-0', 'inset-y-0', 'z-20'),
}

const ListColumn = (props: IListColumnProps) => {
  const [startDate, setStartDate] = useState<moment.Moment>(moment())
  const [endDate, setEndDate] = useState<moment.Moment>(moment().add(1, 'days'))
  const [focusedInput, setFocusedInput] = useState<
    'startDate' | 'endDate' | null
  >('startDate')
  const [freeText, setFreeText] = useState<string>('')
  const [firstLoad, setFirstLoad] = useState<boolean>()
  const { t } = useTranslation()
  const [openDirection, setOpenDirection] = useState<Direction>(Direction.DOWN)

  const defaultFilterValue = useRef('')
  const freeTextFilterValuse = useRef('')

  const listContext = useContext(ListContext)

  const { column, centerHeaders, handleSort, columns } = props

  useEffect(() => {
    const selectedColumn = listContext.state.selectedColumns.find(
      (sc) => sc.id === column.id,
    )
    if (
      selectedColumn &&
      selectedColumn.filters &&
      selectedColumn.filters.length > 0
    ) {
      const stDateValue = selectedColumn.filters[0].dateValue
        ? selectedColumn.filters[0].dateValue
        : undefined
      const endDateValue =
        selectedColumn.filters.length > 1 && selectedColumn.filters[1].dateValue
          ? selectedColumn.filters[1].dateValue
          : undefined

      setStartDate(stDateValue ? moment(stDateValue[0]) : moment())
      setEndDate(
        endDateValue ? moment(endDateValue[0]) : moment().add(1, 'days'),
      )
      setFreeText(selectedColumn.filters[0].value)
    }
  }, [])

  const onDragStart = (ev: any, index: number) => {
    ev.dataTransfer.setData('index', index)
  }

  const onDragOver = (ev: any) => {
    ev.preventDefault()
  }

  const onDrop = (ev: any, col: any) => {
    const index = ev.dataTransfer.getData('index')
    props.handleMove(index, col)
  }

  const onFilterOpen = (e: any) => {
    const target = e.target.getBoundingClientRect()
    const numberOfOptions = listContext.state.filters.find(
      (filter) => filter.column.id === column.id,
    )?.filterOptions.length
    const filterHeight = numberOfOptions ? numberOfOptions * 30 + 50 : 380
    const closeToBottom =
      window.innerHeight - target.top <
      (filterHeight > 350 ? 350 : filterHeight)
    setOpenDirection(closeToBottom ? Direction.UP : Direction.DOWN)
    const { openFilter } = listContext.actions
    e.stopPropagation()
    openFilter(column)
  }

  const removeFilters = (filter: IListFilter[]) => (e: any) => {
    e.stopPropagation()
    const { clearSingleFilter } = listContext.actions
    const theFilter = filter[0]
    clearSingleFilter(theFilter)
    setStartDate(moment())
    setEndDate(moment().add(1, 'days'))
    setFocusedInput('startDate')
    setFreeText('')
    setFirstLoad(true)
  }

  const startDefaultDebouncer = useDebouncer({
    delay: 700,
    callback: () => {
      listContext.actions.filterSearch(column, defaultFilterValue.current)
    },
  })

  const startFreeTextDebouncer = useDebouncer({
    delay: 700,
    callback: () => {
      listContext.actions.setTextFilter(column, freeTextFilterValuse.current)
    },
  })

  const onFilterSearch = (e: ChangeEvent<HTMLInputElement>) => {
    defaultFilterValue.current = e.target.value
    startDefaultDebouncer()
  }

  const onDateRangeSelect = (range: any) => {
    const { setDateFilter } = listContext.actions
    setStartDate(range.startDate)
    setEndDate(range.endDate)
    setDateFilter(column, range.startDate, range.endDate)
  }

  const onFreeTextChange = (e: any) => {
    const text = e.target.value
    freeTextFilterValuse.current = text
    setFreeText(text)
    startFreeTextDebouncer()
  }

  const renderCustomIcon = () => {
    const { customIcon, onCustomIconClick } = column
    return (
      customIcon && (
        <Icon
          style={{ width: 14, transform: 'translateX(5px)' }}
          className={'opacity-75 hover:opacity-100'}
          icon={Icon.IconType[customIcon]}
          onClick={() => {
            if (onCustomIconClick) {
              onCustomIconClick(column.id)
            }
          }}
        />
      )
    )
  }

  const prepareFilterFromParent = (
    listFilter: IListFilter,
    filterFromParent: IListFilterFromParent,
  ): IListFilter => {
    return {
      ...listFilter,
      filterOptions: filterFromParent.value.map((value: any) => ({
        active: true,
        name: '',
        value,
      })),
    }
  }

  const getTranslatedColumnName = (val: string) => {
    const hasParam = val.match(',')

    if (hasParam) {
      const splittedVal = val.split(',')
      return capFirstLetter(
        t(splittedVal[0], { [splittedVal[1]]: splittedVal[2] }),
      )
    } else {
      return capFirstLetter(t(val))
    }
  }

  const renderFilter = () => {
    const { filterFromParent } = props
    const { filters } = listContext.state
    const listFilter: IListFilter[] = filters.filter(
      (filter: any) => filter.column.id === column.id,
    )
    const thisColumn =
      filterFromParent?.value && firstLoad
        ? prepareFilterFromParent(listFilter[0], filterFromParent)
        : listFilter[0]
    if (thisColumn === undefined) {
      return
    }
    const filterIsActive = thisColumn.filterOptions.filter(
      (option: ISingleFilter) => option.active,
    )
    return (
      <Fragment>
        {filterIsActive.length > 0 ? (
          <div className={'inline-flex flex-row mx-1'}>
            <Icon
              style={{ opacity: 0.3, width: 22 }}
              icon={Icon.IconType.CLOSE}
              onClick={removeFilters(listFilter)}
            />
            <Icon
              style={{ width: 20 }}
              icon={Icon.IconType.FILTER_SELECTED}
              onClick={onFilterOpen}
            />
          </div>
        ) : (
          <div className={'mx-1'} style={{ minWidth: '1.2rem' }}>
            <Icon
              style={{ opacity: 0.3, width: 20 }}
              icon={Icon.IconType.FILTER}
              onClick={onFilterOpen}
            />
          </div>
        )}
        {thisColumn.open && (
          <Fragment>
            <div className={styleClass.cover} onClick={onFilterOpen} />
            <div
              onClick={(e) => e.stopPropagation()}
              className={`${styleClass.filterBox(
                column.filterType === filterType.RANGEDATE,
              )}`}
              style={{
                width:
                  column.filterType === filterType.RANGEDATE ? '350px' : '100%',
                maxWidth:
                  column.filterType === filterType.RANGEDATE
                    ? '245px'
                    : '300px',
                minHeight:
                  column.filterType === filterType.RANGEDATE ||
                  column.filterType === filterType.TEXT
                    ? '40px'
                    : '50px',
                maxHeight:
                  column.filterType === filterType.DEFAULT ? '350px' : '500px',
                overflowY:
                  column.filterType === filterType.RANGEDATE
                    ? undefined
                    : 'auto',
                zIndex: 100,
                top:
                  column.filterType === filterType.DEFAULT &&
                  openDirection === Direction.UP
                    ? thisColumn.filterOptions.length > 11
                      ? '-350px'
                      : `-${thisColumn.filterOptions.length * 28 + 50}px`
                    : '30px',
                left: column.dataField === 'record_id' ? 0 : undefined,
              }}
            >
              {column.filterType === filterType.DEFAULT && (
                <Input
                  type="search"
                  autoFocus={true}
                  value={thisColumn.searchValue}
                  onChange={onFilterSearch}
                  block={true}
                  placeholder={t('search')}
                />
              )}
              {(column.filterType === filterType.NUMBER ||
                column.filterType === filterType.TEXT) && (
                <Input
                  type={
                    column.filterType === filterType.NUMBER
                      ? 'number'
                      : 'search'
                  }
                  autoFocus={true}
                  value={freeText}
                  onChange={onFreeTextChange}
                  block={true}
                  placeholder={t('search')}
                />
              )}
              <div className={'w-full'}>
                {column.filterType === filterType.DEFAULT &&
                  thisColumn.filterOptions
                    ?.filter((filter: ISingleFilter) => {
                      const name = filter.name ? filter.name.toLowerCase() : ''
                      return (
                        name.includes(thisColumn.searchValue.toLowerCase()) ||
                        ['select_all'].includes(name)
                      )
                    })
                    .map((filter: ISingleFilter, key: number) => {
                      return (
                        <FilterItem key={key} filter={filter} column={column} />
                      )
                    })}
                {column.filterType === filterType.RANGEDATE && (
                  <DateRangePicker
                    startDate={startDate}
                    startDateId="date-start"
                    endDate={endDate}
                    endDateId="date-end"
                    onDatesChange={onDateRangeSelect}
                    renderDayContents={renderDayWithoutTimezone}
                    firstDayOfWeek={1}
                    focusedInput={focusedInput}
                    onFocusChange={(fi) => setFocusedInput(fi)}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    noBorder={true}
                    openDirection={openDirection}
                    hideKeyboardShortcutsPanel={true}
                    showDefaultInputIcon={true}
                    isOutsideRange={() => false}
                  />
                )}
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }

  const width = column.size ? `${column.size}px` : undefined

  return (
    <th
      style={{ minWidth: width, maxWidth: width, height: '100%' }}
      onDragOver={onDragOver}
      onDrop={(e) => {
        onDrop(e, props.index)
      }}
      className={'border-l border-solid'}
    >
      <div onDragStart={(e) => onDragStart(e, props.index)} draggable={true}>
        {column.sortingField ? (
          <div className={'relative'}>
            <div
              className={styleClass.headerColumn(
                centerHeaders,
                listContext.state.sorting.field === column.sortingField,
              )}
            >
              <div className={'flex truncate flex-no-wrap'}>
                &nbsp;&nbsp;
                <Icon
                  style={{ opacity: 0.3, width: 14, paddingRight: 1 }}
                  icon={
                    listContext.state.sorting.direction
                      ? Icon.IconType.ASC
                      : Icon.IconType.DESC
                  }
                  onClick={() => handleSort(columns, column)}
                />
                &nbsp;{getTranslatedColumnName(column.name)}&nbsp;&nbsp;
              </div>
              {column.filter && renderFilter()}
            </div>
            <ListResize column={column} />
          </div>
        ) : (
          <div className={styleClass.headerColumn(centerHeaders)}>
            <span className={'flex truncate flex-no-wrap'}>
              &nbsp;{getTranslatedColumnName(column.name)}&nbsp;&nbsp;
            </span>
            {column.customIcon && renderCustomIcon()}
            {column.filter && renderFilter()}
            <ListResize column={column} />
          </div>
        )}
      </div>
    </th>
  )
}

export default memo(ListColumn)
