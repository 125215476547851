import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { editMeetingParticipant, getMeeting } from 'src/service/MeetingService'
import { IMeeting } from 'src/service/OrgTypes'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { IEvent } from 'src/utility/EmailProviderUtil'
import MCard from '../components/MCard'
import MInviteAcceptance from '../components/MInviteAcceptance'
import MMetingInfo from '../components/MMeetingInfo'

const MMeetingDetailPage = () => {
  const { location } = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const userContext = useContext(UserContext).state

  const meetingRsvp = queryParams.get('rsvp')

  const { t } = useTranslation()

  const { meeting_id } = useParams<{ meeting_id: string }>()
  const [loading, setLoading] = useState(true)
  const [meeting, setMeeting] = useState<IMeeting>()

  const userEmail = userContext.user.email

  const attendance = useCallback(async () => {
    const participant = meeting?.single_meeting_participants.find(
      (participant) => participant.email === userEmail,
    )
    if (participant) {
      participant.isPresent = meetingRsvp === 'accept'
      editMeetingParticipant(participant)
    }
  }, [meeting?.single_meeting_participants, userEmail, meetingRsvp])

  useEffect(() => {
    attendance()
  }, [attendance, userEmail])

  const loadData = useCallback(async () => {
    const res = await getMeeting(Number(meeting_id))
    if (res) setMeeting(res)
    setLoading(false)
  }, [meeting_id])

  useEffect(() => {
    loadData()
  }, [loadData])

  const PageLoader = () => (
    <div className={'w-full h-full grid place-items-center relative z-n1'}>
      <SpinnerMobile />
    </div>
  )

  const meetingUrl = (meeting: IMeeting) => {
    const meetingSeriesUrl = meeting.meeting_series_id
      ? `meeting_series/${meeting.meeting_series_id}/`
      : ''
    return `${window.location.origin}/${meetingSeriesUrl}meeting/${meeting.id}`
  }

  const event: IEvent | undefined = useMemo(() => {
    if (!meeting) return undefined
    return {
      id: meeting.id,
      title: meeting.title,
      description: meeting.description,
      location: meeting.meetingPlace,
      start: meeting.meetingDate,
      end: meeting.meetingEndDate,
      url: meetingUrl(meeting),
      participants: meeting.single_meeting_participants.map((p) => {
        return {
          name: `${p.firstName} ${p.lastName}`,
          email: p.email,
        }
      }),
    }
  }, [meeting])

  return (
    <MInviteAcceptance
      event={event}
      title={t('meeting')}
      rsvp={meetingRsvp ?? ''}
    >
      {loading ? (
        <PageLoader />
      ) : (
        <MCard>{meeting && <MMetingInfo meeting={meeting} />}</MCard>
      )}
    </MInviteAcceptance>
  )
}

export default MMeetingDetailPage
