import { classNames } from '../../utility/utils'

interface IMChips {
  text: string
  selected: boolean
  onClick?: () => void
}

const MSelectableChip = ({ text, selected = false, onClick }: IMChips) => {
  const styleClass = {
    root: classNames(
      'my-2',
      'mx-1',
      'rounded-full',
      'border-2',
      selected ? 'border-green-400' : `border-gray-300`,
      selected ? 'font-medium' : 'text-light',
      selected ? 'bg-green-100' : 'bg-gray-200',
      selected ? 'text-green-500' : 'text-gray-400',
      'text-xxs',
      'flex',
      'items-center',
      'pr-1',
    ),
    text: classNames('px-1', 'py-0.5'),
  }
  const checkmark = '✓'

  return (
    <div onClick={onClick} className={styleClass.root}>
      <p className={styleClass.text}>{text}</p>
      <span>{selected && checkmark}</span>
    </div>
  )
}

export default MSelectableChip
