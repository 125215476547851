import { twMerge } from 'tailwind-merge'

export const syntaxLevelColor = (level: number = 0) => {
  const nodeLevel = Math.min(level, 8)
  return twMerge(`bg-tree-level-${nodeLevel}00`, level < 8 && 'text-white')
}

export const treeSyntaxLevelColor = (level: number) => {
  if (level <= 1) return
  if (level > 8) return 'bg-tree-level-800'
  const nodeLevel = level - 1
  return syntaxLevelColor(nodeLevel)
}
