import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import {
  IWithProjectContext,
  withProjectContext,
} from '../../../context/withProjectContext'
import { getProjectConstructionTrains } from '../../../service/ConstructionTrainService'
import { createConstructionWagon } from '../../../service/ConstructionWagonService'
import { getProjectDisciplines } from '../../../service/DisciplineService'
import { IConstructionTrain, IDiscipline } from '../../../service/OrgTypes'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import { renderDayContents } from '../../../utility/Utility'
import { capFirstLetter, classNames } from '../../../utility/utils'
import Selector from '../../selectors/Selector'
import Checkbox from '../../switchHoc/CheckBox'
import CleanZone from '../util/CleanZone'

interface IWagonForm extends IWithProjectContext {
  closeModal: () => void
  parentId: number
  wagonId?: number
  train?: IConstructionTrain
  open: boolean
  startDate?: moment.Moment
  createWagonBefore?: boolean
  reloadParent?: () => void
}

const WagonForm = ({
  parentId,
  closeModal,
  projectContext,
  wagonId: _wagonId,
  open,
  startDate,
  createWagonBefore,
  reloadParent,
}: IWagonForm) => {
  const styleClass = {
    root: classNames('flex', 'flex-col', 'flex-shrink'),
    inputGroup: classNames('flex', 'flex-row', 'w-full'),
    label: classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'font-roboto',
      'my-2',
    ),
  }

  const { t } = useTranslation()
  const [title, setTitle] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [startTime, setStartTime] = useState<moment.Moment>(
    startDate || moment(),
  )
  const [duration, setDuration] = useState<number>(0)
  const [disciplineId, setDisciplineId] = useState<number>(0)
  const [constructionTrain, setConstructionTrain] =
    useState<null | IConstructionTrain>(null)
  const [constructionTrainId, setConstructionTrainId] = useState<number>(
    Number(parentId),
  )
  const [isClean, setIsClean] = useState(CleanZone.notClean())
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [constructionTrains, setConstructionTrains] = useState<
    IConstructionTrain[]
  >([])
  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false)
  const [trainErrorMessage, setTrainErrorMessage] = useState<string>('')
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('')
  const [durationErrorMessage, setDurationErrorMessage] = useState<string>('')
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [ignoreHoliday, setIgnoreHoliday] = useState<boolean>(false)
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const [showGroupModal, setShowGroupModal] = useState<boolean>(false)
  const [applyUpdateToGroup, setApplyUpdateToGroup] = useState<boolean>(false)

  const trainRef = useRef(constructionTrain)

  const projectId = projectContext.state.currentProject.id

  useEffect(() => {
    getProjectDisciplines(projectId).then((res) => {
      setDisciplines(res)
    })
    getProjectConstructionTrains(projectId).then((res) => {
      setConstructionTrains(res)
      const train =
        res
          .filter(
            (thisTrain: IConstructionTrain) =>
              thisTrain.id === Number(parentId),
          )
          .pop() || null
      setConstructionTrain(train)
      setConstructionTrainId(Number(parentId))
    })
    setConstructionTrainId(parentId)
  }, [parentId, projectId])

  useEffect(() => {
    trainRef.current = constructionTrain
  })

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value)
    setTitleErrorMessage('')
  }

  const onChangeSubject = (e: any) => {
    setSubject(e.target.value)
  }

  const setDatePickerFocus = (focused: any) => {
    setDatePickerFocused(focused.focused)
  }

  const onChangeStartTime = (time: moment.Moment) => {
    setStartTime(time || moment().format('L'))
    setDateErrorMessage('')
  }

  const onChangeDuration = (e: any) => {
    const value = e.target.value.replace(/^0+/, '')
    setDuration(value)
    setDurationErrorMessage('')
  }

  const onChangeConstructionTrainId = (trainId: number) => {
    setConstructionTrainId(trainId)
    setTrainErrorMessage('')
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const onChangeDiscipline = (newDisciplineId: number) => {
    setDisciplineId(newDisciplineId)
    setDisciplineErrorMessage('')
  }

  const onSubmitForm = (e: any, considerGroup: boolean) => {
    e.preventDefault()
    setIsLoading(true)
    let error = false

    if (!title) {
      setTitleErrorMessage(t('fill_out_w_param', { param: t('title') }))
      error = true
    }

    if (!startTime) {
      setDateErrorMessage(t('fill_out_w_param', { param: t('start_date') }))
      error = true
    }

    if (!duration || duration <= 0) {
      setDurationErrorMessage(t('fill_out_w_param', { param: t('duration') }))
      error = true
    }

    if (!constructionTrainId) {
      setTrainErrorMessage(t('select_w_param', { param: t('train') }))
      error = true
    }

    if (!disciplineId) {
      setDisciplineErrorMessage(t('select_w_param', { param: t('discipline') }))
      error = true
    }

    if (
      createWagonBefore === undefined &&
      trainRef.current &&
      startTime.isBefore(trainRef.current.startTime, 'day')
    ) {
      setDateErrorMessage(
        t('can_not_be_before_train_w_date', {
          date: moment(trainRef.current.startTime).format('L'),
        }),
      )
      error = true
    }

    if (!error) {
      if (considerGroup) {
        setShowGroupModal(true)
        return
      }
      const updatedWagon = {
        title,
        status,
        startTime,
        duration,
        subject,
        construction_train_id:
          constructionTrainId > 0 ? constructionTrainId : undefined,
        project_id: projectId,
        discipline_id: disciplineId > 0 ? disciplineId : null,
        change_locomotives_in_group:
          startDate !== undefined ? applyUpdateToGroup : false,
        ignore_weekends: ignoreHoliday,
        clean_status: isClean,
      }
      const constructionWagonApiCall = createConstructionWagon(
        updatedWagon,
        projectId,
      )
      constructionWagonApiCall.then(() => {
        setIsLoading(false)
        if (!createMultiple) {
          closeModal()
          reloadParent?.()
        } else {
          setTitle('')
        }
      })
    } else {
      setIsLoading(false)
    }
  }

  const closeForm = () => {
    closeModal()
  }

  return (
    <Modal
      show={open}
      title={t('new_wagon')}
      closeModal={closeForm}
      size={'w-1/2'}
      maxWidth={800}
    >
      <form
        className={styleClass.root}
        onSubmit={(e) => onSubmitForm(e, startDate !== undefined)}
      >
        <div className={styleClass.inputGroup}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <div className={'flex'}>
              <label className={'text-sm font-medium my-2 capitalize'}>
                {t('start_date')} *
              </label>
              {dateErrorMessage ? (
                <label className={'pl-2 text-sm my-2'} style={{ color: 'Red' }}>
                  {dateErrorMessage}
                </label>
              ) : null}
            </div>
            <div className={'flex justify-between w-full'}>
              <SingleDatePicker
                firstDayOfWeek={1}
                date={startTime}
                onDateChange={onChangeStartTime}
                renderDayContents={renderDayContents}
                focused={datePickerFocused}
                onFocusChange={setDatePickerFocus}
                id="datePicker-wagon"
                disabled={createWagonBefore !== undefined}
                small={true}
                isOutsideRange={() => false}
                showDefaultInputIcon={true}
                noBorder={true}
                numberOfMonths={1}
                displayFormat={() =>
                  moment.localeData('no').postformat('DD.MM.YY')
                }
                required={true}
                hideKeyboardShortcutsPanel={true}
              />
            </div>
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-full'}>
            <Input
              block={true}
              label={t('title')}
              onChange={onChangeTitle}
              value={title}
              required={true}
              errorMessage={titleErrorMessage}
              autoFocus={true}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-full'}>
            <Input
              block={true}
              label={t('description')}
              onChange={onChangeSubject}
              value={subject}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Selector
              items={constructionTrains}
              selectedItemId={constructionTrainId}
              onSelect={onChangeConstructionTrainId}
              label={t('train')}
              dataFields={['title']}
              required={true}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={trainErrorMessage}
            />
          </div>

          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('wagon_duration_days')}
              onChange={onChangeDuration}
              value={Math.round(Number(duration) * 100) / 100}
              errorMessage={durationErrorMessage}
              required={true}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Selector
              items={disciplines}
              selectedItemId={disciplineId}
              onSelect={onChangeDiscipline}
              label={t('wagon_discipline')}
              dataFields={['shortName', 'name']}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={disciplineErrorMessage}
              required={true}
            />
          </div>
          <div className={'w-1/2'}>
            <Selector
              items={CleanZone.info(t)}
              selectedItemId={CleanZone.id(isClean)}
              onSelect={(id) => setIsClean(CleanZone.zone(id))}
              label={t('clean_status')}
              dataFields={['name']}
              fontSize={'sm'}
              fontWeight={'bold'}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div
            className={'pl-2 my-4 flex items-center w-1/2'}
            onClick={() => setIgnoreHoliday((n) => !n)}
          >
            <Checkbox
              disableTab={true}
              onChange={() => setIgnoreHoliday((n) => !n)}
              valueProp={ignoreHoliday}
            />
            <p className={`pl-2  text-gray-500 text-sm flex-wrap`}>
              {t('consider_weekends_as_working_days')}
            </p>
          </div>
        </div>

        <ModalFooter>
          <Button onClick={closeForm}>{t('cancel')}</Button>
          {!startDate && (
            <Button
              type={Button.ButtonType.SECONDARY}
              noTextWrap={true}
              onClick={onMultipleCreate}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : t('add_multiple')}
            </Button>
          )}
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={isLoading}
            onClick={onSingleCreate}
          >
            {isLoading ? <Spinner /> : t('add')}
          </Button>
        </ModalFooter>
      </form>
      <DeleteModal
        show={showGroupModal}
        closeModal={() => setShowGroupModal(false)}
        onDelete={(e) => onSubmitForm(e, false)}
        itemName={capFirstLetter(t('wagon'))}
      >
        <div className={styleClass.inputGroup}>
          <div className="m-4 flex items-center ml-2">
            <SwitchHOC
              valueProp={applyUpdateToGroup}
              className="custom-className"
              onChange={() => setApplyUpdateToGroup(!applyUpdateToGroup)}
              defaultColor={false}
            />
            <label
              onClick={() => setApplyUpdateToGroup(!applyUpdateToGroup)}
              className={'mr-2 ml-2 text-sm mb-2'}
            >
              {t(
                'delete_all_corresponding_wagons_in_the_same_control_area_group',
              )}
            </label>
          </div>
        </div>
      </DeleteModal>
    </Modal>
  )
}

export default withProjectContext(WagonForm)
