import 'moment/dist/locale/nb'
import '@taskctrl/react-calendar-timeline/lib/Timeline.css'
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from '@taskctrl/react-calendar-timeline'
import moment from 'moment-timezone'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { getMilestoneTemaCanvasData } from 'src/service/MilestoneService'
import {
  ICanvasDuration,
  IMilestone,
  TimelineFilterTypes,
} from 'src/service/OrgTypes'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import Loader from 'src/ui-elements/loader/Loader'
import { capFirstLetter, classNames } from '../../utility/utils'
import CanvasFloatingFilter, {
  CanvasFilterType,
} from '../canvas-filter/CanvasFloatingFilter'
import HelperIcons from '../canvas-header/HelperIcons'
import TimeLineResetDate from '../canvas-header/TimelineResetDate'
import CustomMonthHeader from '../gantt/CustomMonthHeader'
import MilestoneInspectorPanel from '../milestones/MilestoneInspectorPanel'
import MilestoneTimelineItem from './MilestoneTimelineItem'

const styleClass = {
  rowName: (parent?: boolean) =>
    classNames(
      parent ? 'bg-gray-300' : 'bg-cool-gray-200',
      'flex',
      'flex-row',
      'items-center',
      parent ? 'pl-0.5' : 'pl-4',
      'pr-5',
      'justify-between',
      'text-sm',
      'text-gray-700',
      'border-grey-root-3',
      'border-b-015',
    ),
  dateItem: classNames(
    'sticky',
    'px-4',
    'flex',
    'flex-col',
    'items-center',
    'justify-center',
    'font-bold',
    'bg-white',
    'border-r-01',
    'border-l-01',
    'border-b-04',
    'border-grey-root-3',
    '',
  ),
  sideHeader: classNames(
    'absolute',
    'flex',
    'flex-row',
    'left-header-border',
    'justify-center',
    'items-center',
    'bg-green-wash',
  ),
  gridItem: classNames(
    'flex',
    'flex-1',
    'justify-center',
    'items-center',
    'min-w-19 ',
    'justify-between',
    'text-sm',
    'text-gray-700',
    'font-normal',
    'border-solid',
    'border-r',
  ),
  item: classNames(
    'flex',
    'px-4',
    'overflow-visible',
    'whitespace-nowrap',
    'cursor-pointer',
    'relative',
  ),
  header: classNames('flex', 'w-full', 'flex-row'),
  delay: (late: boolean) =>
    classNames(
      'text-center',
      'text-white',
      'text-sm',
      late ? 'bg-red-one' : 'bg-green-one',
    ),
  dateHeader: classNames(
    'text-sm',
    'capitalize',
    'text-gray-500',
    'whitespace-nowrap',
    'font-normal',
  ),
}

const MilestoneTemaTimeline = () => {
  moment.tz.setDefault('Europe/Oslo')
  moment.locale('nb')

  const defaultTimeStart = moment().add(-2, 'weeks').toDate()
  const defaultTimeEnd = moment().add(4, 'weeks').toDate()
  const [items, setItems] = useState<any[]>([])
  const [groups, setGroups] = useState<any[]>([])
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [selectedMilestone, setSelectedMilestone] = useState<IMilestone>(
    {} as IMilestone,
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [openGroups, setOpenGroup] = useState<any>({})
  const [canvasDuration, setCanvasDuration] = useState<ICanvasDuration>({
    canvasStartTime: defaultTimeStart,
    canvasEndTime: defaultTimeEnd,
  })
  const [showWeek, setShowWeek] = useState<boolean>(true)
  const [reloadTimeline, setReloadTimeline] = useState<boolean>(false)
  const [reloadData, setReloadData] = useState<boolean>(false)

  const { t } = useTranslation()

  const projectContext = useContext(ProjectContext)

  const closeMilestoneModal = () => {
    setShowDetailsModal(false)
    setReloadData((n) => !n)
  }

  const getMilestoneTimelineData = async (filters: any) => {
    const id = projectContext.state.currentProject.id
    const filterData = {
      discipline: filters[CanvasFilterType.DISCIPLINES],
      mile_stone: filters[CanvasFilterType.MILESTONES],
      main_process: filters[CanvasFilterType.PROCESSES],
      team: filters[CanvasFilterType.THEMES],
      responsible: filters[CanvasFilterType.USERS],
      behind_schedule: filters[CanvasFilterType.BACK_PLAN],
      open: filters[CanvasFilterType.OPEN],
    }
    const milestoneData = await getMilestoneTemaCanvasData(id, filterData)

    setItems(milestoneData.items)
    setGroups(milestoneData.groups)
    setLoading(false)
    setReloadTimeline((n) => !n)
  }

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps: _getResizeProps,
  }: any) => {
    return (
      <MilestoneTimelineItem
        getItemProps={getItemProps}
        item={item}
        openMilestone={openMilestone}
        timelineContext={timelineContext}
        alwaysShowLabel={true}
        itemContext={itemContext}
      />
    )
  }

  const toggleGroup = (id: any) => {
    setOpenGroup({
      ...openGroups,
      [id]: !openGroups[id],
    })
  }

  const openMilestone = (milestone: any, timelineContext: any) => {
    setSelectedMilestone(milestone)
    setShowDetailsModal(true)
    setCanvasDuration({
      canvasStartTime: new Date(timelineContext.visibleTimeStart),
      canvasEndTime: new Date(timelineContext.visibleTimeEnd),
    })
  }

  const renderArrows = (parent?: boolean) => {
    return parent ? (
      <Icon styleClass={'w-5'} icon={Icons.ADD_CIRCLE_BLUE} className={'w-6'} />
    ) : (
      <Icon
        styleClass={'w-5'}
        icon={Icons.REMOVE_CIRCLE_BLUE}
        className={'w-6'}
      />
    )
  }

  const getGroups = () => {
    const newGroups = groups
      .filter((g) => g.root || !openGroups[g.parent])
      .map((group) => {
        return Object.assign({}, group, {
          title: group.root ? (
            <div
              style={{ height: 'inherit', fontSize: 14 }}
              className={styleClass.rowName(true)}
            >
              <div
                onClick={() => toggleGroup(group.id)}
                style={{ cursor: 'pointer', display: 'flex' }}
              >
                {renderArrows(openGroups[group.id])}{' '}
                {`${group.record_id ? group.record_id : ''}${
                  group.record_id ? ':' : ''
                } ${
                  group.title &&
                  (group.title.length > 25
                    ? group.title.substring(0, 25) + '...'
                    : group.title)
                }`}
              </div>
            </div>
          ) : (
            <div className={styleClass.header}>
              <div className="border-solid border-r" style={{ flexGrow: 2 }}>
                <div
                  style={{ height: 'inherit', fontSize: 14 }}
                  className={styleClass.rowName()}
                >
                  <span style={{ paddingLeft: 20 }}>
                    {`${group.record_id ? group.record_id : ''}${
                      group.record_id ? ':' : ''
                    } ${
                      group.title &&
                      (group.title.length > 23
                        ? group.title.substring(0, 18) + '...'
                        : group.title)
                    }`}
                  </span>
                </div>
              </div>
            </div>
          ),
        })
      })
    return newGroups
  }

  const resetTimePosition = () => {
    setCanvasDuration({
      canvasStartTime: defaultTimeStart,
      canvasEndTime: defaultTimeEnd,
    })
    setReloadTimeline((n) => !n)
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div key={reloadTimeline ? '1' : '2'}>
          <Timeline
            groups={getGroups()}
            items={items}
            itemsSorted={false}
            itemTouchSendsClick={false}
            stackItems={false}
            sidebarWidth={250}
            itemHeightRatio={0.9}
            showCursorLine={true}
            lineHeight={33}
            canMove={false}
            canResize={false}
            minZoom={30 * 86400 * 1000} // A year
            maxZoom={3 * 365.24 * 86400 * 1000} // three Year
            defaultTimeStart={canvasDuration.canvasStartTime}
            defaultTimeEnd={canvasDuration.canvasEndTime}
            itemRenderer={itemRenderer}
          >
            <TodayMarker>
              {({ styles }: any) => {
                const newStyles = {
                  ...styles,
                  backgroundColor: '#FD7171',
                  width: '6px',
                }
                return <div style={newStyles} />
              }}
            </TodayMarker>
            <TimelineHeaders
              className="sticky"
              style={{ display: 'flex', width: '100%', top: 0 }}
              classNames={styleClass.dateItem}
            >
              <SidebarHeader style={{ width: '375px' }}>
                {({ getRootProps }: any) => {
                  return (
                    <div {...getRootProps()}>
                      <div
                        className={styleClass.sideHeader}
                        style={{
                          width: '375px',
                          maxHeight: '100%',
                          backgroundColor: '#F4F5F7',
                        }}
                      >
                        <div className={styleClass.header}>
                          <div
                            style={{
                              height: `${showWeek ? '90px' : '60px'}`,
                              color: '#6b7280',
                              borderColor: '#cbd5e0',
                              flexGrow: 2.87,
                            }}
                            className={styleClass.gridItem}
                          >
                            <span className="pl-6">
                              {capFirstLetter(t('main_process')) +
                                ' -> ' +
                                capFirstLetter(t('team'))}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </SidebarHeader>
              <DateHeader
                className={styleClass.dateHeader}
                unit="year"
                labelFormat="YYYY"
              />
              <CustomMonthHeader setShowWeek={setShowWeek} />
              {showWeek && (
                <DateHeader
                  className={styleClass.dateHeader}
                  unit="week"
                  labelFormat="w"
                />
              )}
            </TimelineHeaders>
          </Timeline>
        </div>
      )}
      <HelperIcons className="top-20 right-4">
        <>
          <CanvasFloatingFilter
            showProcessSelector={true}
            showThemes={true}
            showBackPlanFilter={true}
            showUser={true}
            showOpenFilter={true}
            filterName={TimelineFilterTypes.MILESTONE_TIMELINE_TEMA}
            reload={reloadData}
            getData={getMilestoneTimelineData}
          />
          <TimeLineResetDate onReset={resetTimePosition} />
        </>
      </HelperIcons>
      {showDetailsModal && selectedMilestone && selectedMilestone.id ? (
        <MilestoneInspectorPanel
          milestoneId={selectedMilestone.id}
          origin={{
            name: t('visual_overview_per_theme'),
            url: '/milestone?tabIndex=4',
          }}
          open={showDetailsModal}
          onClose={closeMilestoneModal}
          onUpdate={() => setReloadData((n) => n)}
        />
      ) : null}
    </>
  )
}

export default MilestoneTemaTimeline
