import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { IConstructionWagon, ITaskData } from '../../service/OrgTypes'
import Modal from '../../ui-elements/modal/Modal'
import { classNames } from '../../utility/utils'
import ConstructionTaskForm from './ConstructionTaskForm'

export interface IConstructionChecklistTaskProps {
  projectId: number
  listSize: (size: number) => void
  submitTask: (task: ITaskData) => void
  taskCount: number
  wagon?: IConstructionWagon
  disable?: boolean
}

const styleClass = {
  root: classNames('md_w-full', 'flex', 'flex-col'),
  inputGroup: classNames('w-full', 'flex', 'row'),
}

const ConstructionChecklistTask = ({
  projectId,
  listSize,
  submitTask,
  taskCount,
  wagon,
  disable = false,
}: IConstructionChecklistTaskProps) => {
  const [newTask, setNewTask] = useState<number>(taskCount)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const { t } = useTranslation()

  const openCreateModal = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (!disable) {
      setShowCreateModal((n) => !n)
    }
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const submitNewTask = (task: ITaskData) => {
    setNewTask((n) => n + 1)
    listSize(taskCount + newTask + 1)
    submitTask(task)
  }

  return (
    <div className={styleClass.root}>
      <div className={'flex flex-col'}>
        {
          <div className={'flex'} style={{ marginBottom: 12 }}>
            {!disable && (
              <Icon
                icon={Icons.PLUS}
                className={
                  'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                }
                onClick={openCreateModal}
              />
            )}
            <span
              className={
                'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border'
              }
            >
              {newTask}
            </span>
          </div>
        }
      </div>

      <Modal
        show={showCreateModal}
        closeModal={closeCreateModal}
        title={
          wagon
            ? t('new_case_for_wagon_w_title', { title: wagon.title })
            : t('new_case')
        }
        maxWidth={800}
      >
        <div className={''}>
          <ConstructionTaskForm
            parentType={'ChecklistParameter'}
            parentId={0}
            projectId={projectId}
            closeModal={closeCreateModal}
            updateList={() => {}}
            submitTask={submitNewTask}
          />
        </div>
      </Modal>
    </div>
  )
}

export default ConstructionChecklistTask
