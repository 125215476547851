import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { IRiskProbability } from '../../service/OrgTypes'
import { editRiskProbability } from '../../service/RiskService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import { capFirstLetter, classNames } from '../../utility/utils'

interface ICreateRiskProbabilityFormProps {
  closeModal: () => void
  editingMode: boolean
  riskProbability: IRiskProbability
}

const RiskProbabilityForm = ({
  closeModal,
  editingMode,
  riskProbability,
}: ICreateRiskProbabilityFormProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'mt-2'),
  }

  const [id] = useState<number>(riskProbability.id)
  const [name, setName] = useState<string>(riskProbability.name)
  const [from, setFrom] = useState<number>(riskProbability.from)
  const [expected, setExpected] = useState<number>(riskProbability.expected)
  const [to, setTo] = useState<number>(riskProbability.to)
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('')
  const [fromErrorMessage, setFromErrorMessage] = useState<string>('')
  const [toErrorMessage, setToErrorMessage] = useState<string>('')
  const [expectedErrorMessage, setExpectedErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false

    setLoading(true)
    // RiskProbability validation
    if (!name) {
      setNameErrorMessage(t('fill_in_the_name'))
      error = true
    }

    if (!from) {
      setFromErrorMessage(t('fill_in_from_value'))
      error = true
    }

    if (!to) {
      setToErrorMessage(t('fill_in_to_value'))
      error = true
    }

    if (!expected || expected > to || expected < from) {
      setExpectedErrorMessage(
        t('expected_value_must_be_between_max_and_min', { min: from, max: to }),
      )
      error = true
    }

    if (!error) {
      if (editingMode) {
        editRiskProbability({ id, name, from, to, expected }).then(() => {
          closeModal()
        })
      }
    }

    setLoading(false)
  }

  const onNameChange = (e: any) => {
    setName(e.target.value)
    setNameErrorMessage('')
  }

  const onFromChange = (e: any) => {
    setFrom(e.target.value)
    setFromErrorMessage('')
    setExpectedErrorMessage('')
    calculateExpected(e.target.value, to)
  }

  const onToChange = (e: any) => {
    setTo(e.target.value)
    setToErrorMessage('')
    setExpectedErrorMessage('')
    calculateExpected(from, e.target.value)
  }

  const calculateExpected = (fromValue: number, toValue: number) => {
    if (fromValue && toValue) {
      setExpected((Number(fromValue) + Number(toValue)) / 2)
    }
  }

  const onExpectedChange = (e: any) => {
    setExpected(e.target.value)
    setExpectedErrorMessage('')
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('name')}
          block={true}
          errorMessage={nameErrorMessage}
          value={name}
          required={true}
          onChange={onNameChange}
        />
      </div>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('from_percent')}
          block={true}
          value={from}
          errorMessage={fromErrorMessage}
          type={'number'}
          required={true}
          onChange={onFromChange}
        />
        <Input
          label={t('to_percent')}
          block={true}
          value={to}
          errorMessage={toErrorMessage}
          type={'number'}
          required={true}
          onChange={onToChange}
        />
      </div>

      <div className={styleClass.inputGroup}>
        <Input
          label={t('expected_value')}
          block={true}
          value={expected}
          errorMessage={expectedErrorMessage}
          type={'number'}
          required={true}
          onChange={onExpectedChange}
        />
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        {editingMode ? (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : capFirstLetter(t('update'))}
          </Button>
        ) : (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : capFirstLetter(t('add'))}
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default RiskProbabilityForm
