import { CustomHeader } from '@taskctrl/react-calendar-timeline'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'src/i18n'
import { IIntervalIndicator } from 'src/service/OrgTypes'
import { classNames } from '../../utility/utils'
import CloseClickOutside from '../click-outside/CloseClickOutside'

export interface ICustomMonthHeader {
  setShowWeek: (value: boolean) => void
  displayIntervals?: IIntervalIndicator[]
  secondaryIntervals?: IIntervalIndicator[]
  intervalHeaderText?: string
  intervalColor?: string
  secondaryIntervalColor?: string
  secondaryHeaderText?: string
  compact?: boolean
}

interface IIntervalPresentation {
  bg: string
  intervalNames: string[]
}

const styleClass = {
  floatLabel: classNames(
    'fixed',
    'bg-white',
    'text-sm',
    'shadow',
    'p-3',
    'flex',
    'z-20',
    'flex-col',
  ),
  floatLabelSmallText: classNames('text-xxs', 'py-1'),
}

const CustomMonthHeader = ({
  setShowWeek,
  displayIntervals,
  secondaryIntervals,
  intervalHeaderText,
  secondaryHeaderText,
  intervalColor,
  secondaryIntervalColor,
  compact,
}: ICustomMonthHeader) => {
  moment.tz.setDefault('Europe/Oslo')
  moment.locale('nb')
  const holidayBgCacheRef = useRef<any>({})
  const secondaryHolidayBgCacheRef = useRef<any>({})
  const [showLabel, setShowLabel] = useState<boolean>(false)
  const [intervalNames, setHolidayNames] = useState<string[]>([])
  const [floatLabel, setFloatLabel] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  })
  const [showPrimary, setShowPrimary] = useState<boolean>(true)
  const timer = useRef({} as any)
  const { i18n } = useTranslation()

  const renderDateString = (
    date: moment.Moment,
    format: string,
    lang: string,
  ) => {
    return date.locale(lang).format(format)
  }

  const renderIntervalItem = (mainString?: string) => {
    return (
      <span>
        {mainString && (
          <div className="text-sm capitalize text-gray-500 font-normal">
            {mainString}
          </div>
        )}
      </span>
    )
  }

  const getIntervalCache = (
    stTime: moment.Moment,
    primary = true,
  ): IIntervalPresentation => {
    const key = moment(stTime).format('L')
    return primary
      ? holidayBgCacheRef.current[key]
      : secondaryHolidayBgCacheRef.current[key]
  }

  const renderInterval = (interval: any, lang: string) => {
    const { labelWidth, startTime } = interval
    setShowWeek(labelWidth > 160)
    if (labelWidth > 85) {
      return renderIntervalItem(renderDateString(startTime, 'MMMM', lang))
    } else if (labelWidth > 44) {
      return renderIntervalItem(renderDateString(startTime, 'MMM', lang))
    } else {
      return renderIntervalItem(renderDateString(startTime, 'MM', lang))
    }
  }

  const getTime = (date: moment.Moment): number => {
    return date.toDate().getTime()
  }

  const getBackgroundAndNames = (
    interval: any,
    primary = true,
  ): IIntervalPresentation => {
    const { startTime, endTime } = interval
    if (typeof getIntervalCache(startTime, primary) !== 'undefined') {
      return getIntervalCache(startTime, primary)
    } else {
      const stEndTime: any[] = []
      const disInterval = primary ? displayIntervals : secondaryIntervals
      const currentInterval = disInterval
        ? disInterval.filter((int) => {
            return (
              moment(int.start_date).isBetween(
                moment(startTime),
                moment(endTime),
                'day',
                '[)',
              ) ||
              moment(int.end_date).isBetween(
                moment(startTime),
                moment(endTime),
                'day',
                '[)',
              )
            )
          })
        : []

      if (currentInterval.length === 0) {
        const emptyInterval: IIntervalPresentation = {
          intervalNames: [],
          bg: '',
        }
        setIntervalCache(startTime, emptyInterval, primary)
        return emptyInterval
      }

      currentInterval.map((int) => {
        stEndTime.push({
          start: true,
          date: getTime(moment(int.start_date).startOf('day')),
        })
        stEndTime.push({
          start: false,
          date: getTime(moment(int.end_date).endOf('day')),
        })
      })
      const sortedTime = _.sortBy(stEndTime, [(st: any) => st.date])
      const bg = ['linear-gradient( to right ']
      const bgColor = primary ? intervalColor : secondaryIntervalColor

      sortedTime.map((st: any) => {
        const { start, date } = st
        const per = getPercentage(getTime(startTime), getTime(endTime), date)
        if (start) {
          bg.push(
            `, #F4F5F7 ${per}%, ${bgColor ? bgColor : '#e57373'} ${per}% , `,
          )
        } else {
          bg.push(` ${bgColor ? bgColor : '#e57373'} ${per}%, #F4F5F7 ${per}% `)
        }
      })

      bg.push(')')
      const intervalPresentation: IIntervalPresentation = {
        bg: bg.join(' '),
        intervalNames: currentInterval.map(
          (h) =>
            `${h.description}: ${moment(h.start_date).format('L')} => ${moment(
              h.end_date,
            ).format('L')}`,
        ),
      }
      setIntervalCache(startTime, intervalPresentation, primary)
      return intervalPresentation
    }
  }

  const onMouseEnter = (e: any, names: string[], prmary = true) => {
    if (names.length === 0) {
      return
    }
    setShowPrimary(prmary)
    const target = e.target.getBoundingClientRect()
    const tmpFloatLabel = { ...floatLabel }
    tmpFloatLabel.left = target.x
    tmpFloatLabel.top = target.top
    timer.current = setTimeout(() => {
      setShowLabel(true)
      setHolidayNames(names)
      setFloatLabel(tmpFloatLabel)
    }, 750)
  }

  const onMouseLeave = () => {
    clearTimeout(timer.current)
    setShowLabel(false)
  }

  const getPercentage = (st: number, end: number, num: number): number => {
    const length = end - st
    const pushedFromSt = num - st
    return (pushedFromSt / length) * 100
  }

  const setIntervalCache = (
    stTime: moment.Moment,
    value: IIntervalPresentation,
    primary = true,
  ) => {
    const key = moment(stTime).format('L')
    const cloneCache = primary
      ? { ...holidayBgCacheRef.current }
      : { ...secondaryHolidayBgCacheRef.current }
    cloneCache[key] = value
    if (primary) {
      holidayBgCacheRef.current = cloneCache
    } else {
      secondaryHolidayBgCacheRef.current = cloneCache
    }
  }

  return (
    <CustomHeader height={compact ? 20 : 30} className="sticky" unit="month">
      {({
        headerContext: { unit: _unit, intervals },
        getRootProps,
        getIntervalProps,
        showPeriod,
        data: _data,
      }: any) => {
        return (
          <div {...getRootProps()}>
            {intervals.map((interval: any, key: any) => {
              const { bg, intervalNames: intervalHolidayNames } =
                getBackgroundAndNames(interval)
              const { bg: secondaryBg, intervalNames: secondaryIntervalNames } =
                getBackgroundAndNames(interval, false)
              const intervalStyle = {
                lineHeight: compact ? '20px' : '30px',
                textAlign: 'center',
                borderLeft: '1px solid #CCC',
                cursor: 'pointer',
                color: '#ccc',
                background: secondaryIntervals ? 'transparent' : bg,
              }
              return (
                <div
                  key={key}
                  onClick={() => {
                    showPeriod(interval.startTime, interval.endTime)
                  }}
                  {...getIntervalProps({
                    interval,
                    style: intervalStyle,
                  })}
                  className={'rct-dateHeader flex-col'}
                >
                  <div
                    style={{
                      position: 'sticky',
                      right: '2px',
                      left: '2px',
                    }}
                    onMouseEnter={(e) => onMouseEnter(e, intervalHolidayNames)}
                    onMouseLeave={onMouseLeave}
                    className="absolute"
                  >
                    {renderInterval(interval, i18n.language)}
                  </div>
                  {secondaryIntervals && (
                    <>
                      <div
                        style={{ background: bg }}
                        onMouseEnter={(e) =>
                          onMouseEnter(e, secondaryIntervalNames)
                        }
                        onMouseLeave={onMouseLeave}
                        className="self-start w-full h-3/6 absolute -z-10 top-0"
                      />
                      <div
                        style={{ background: secondaryBg }}
                        onMouseEnter={(e) =>
                          onMouseEnter(e, secondaryIntervalNames, false)
                        }
                        onMouseLeave={onMouseLeave}
                        className="self-end w-full h-3/6  absolute -z-10 bottom-0"
                      />
                    </>
                  )}
                </div>
              )
            })}
            {showLabel && intervalNames.length > 0 ? (
              <CloseClickOutside onClose={onMouseLeave}>
                <div
                  className={styleClass.floatLabel}
                  style={{ left: floatLabel.left, top: floatLabel.top }}
                >
                  <span className={'float-left'}>
                    {showPrimary ? intervalHeaderText : secondaryHeaderText}
                  </span>
                  {intervalNames.map((name: string, index: number) => {
                    return (
                      <div
                        key={index}
                        className={styleClass.floatLabelSmallText}
                      >
                        {name}
                      </div>
                    )
                  })}
                </div>
              </CloseClickOutside>
            ) : null}
          </div>
        )
      }}
    </CustomHeader>
  )
}

type Interval = {
  startTime: Moment
  endTime: Moment
}

type Quarter = Interval & { quarter: number }

const renderQuarter = (quarter: Interval, lang: string) => {
  return `${quarter.startTime.locale(lang).format('MMM')} - ${quarter.endTime
    .locale(lang)
    .format('MMM')}`
}

export const QuarterHeader = () => {
  return (
    <CustomHeader height={30} className="sticky" unit="month">
      {({
        headerContext: { intervals },
        getRootProps,
        getIntervalProps,
      }: any) => {
        // Convert time intervals to quarters
        const quarterIntervals: Quarter[] = intervals.reduce(
          (quarters: Quarter[], month: Interval) => {
            if (
              quarters.length > 0 &&
              moment(month.startTime).quarter() ===
                quarters[quarters.length - 1].quarter
            ) {
              quarters[quarters.length - 1].endTime = moment(
                month.endTime,
              ).subtract(1, 'day')
              return quarters
            }
            const quarter = {
              ...month,
              quarter: month.startTime.quarter(),
            }
            return [...quarters, quarter]
          },
          [],
        )

        return (
          <div {...getRootProps()}>
            {quarterIntervals.map((interval, key) => {
              const intervalStyle = {
                lineHeight: '30px',
                textAlign: 'center',
                borderLeft: '1px solid #CCC',
                cursor: 'pointer',
                width: '100%',
              }
              const intervalProps = getIntervalProps({
                interval,
                style: intervalStyle,
              })

              intervalProps.style = {
                ...intervalProps.style,
                width: 3 * intervalProps.style.width,
              }
              return (
                <div
                  key={key}
                  {...intervalProps}
                  className={'rct-dateHeader flex-col text-gray-500'}
                >
                  <div
                    style={{
                      position: 'sticky',
                      right: '2px',
                      left: '2px',
                    }}
                    className="absolute"
                  >
                    {renderQuarter(interval, i18n.language)}
                  </div>
                </div>
              )
            })}
          </div>
        )
      }}
    </CustomHeader>
  )
}

export default CustomMonthHeader
