import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getMainProcesses } from 'src/service/ProcessService'

export const useMainProcesses = (options: { enabled?: boolean } = {}) => {
  const projectId = useProjectId()

  return useQuery({
    ...options,
    queryKey: ['process', projectId],
    queryFn: () => getMainProcesses(projectId),
  })
}
