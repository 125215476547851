import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { ISubcontractor } from 'src/fdvu/types/ISubcontractor'
import { GenericFdvuSubcontractorService } from 'src/fdvu-subcontractor/service/GenericFdvuSubcontractorService'

class FdvuSubcontractorService extends GenericFdvuSubcontractorService {
  readonly authenticate = () => {
    return this.doRequest<boolean>({
      url: this.getUrl('authenticate'),
      method: 'GET',
    })
  }

  readonly getCurrentSubcontractor = () => {
    return this.doRequest<ISubcontractor>({
      url: this.getUrl('current'),
      method: 'GET',
    })
  }

  readonly getFileContainers = (hideCompleted: boolean) => {
    const url =
      this.getUrl('file-container/assigned') + `?hideCompleted=${hideCompleted}`
    return this.doRequest<IFdvuFileContainer[]>({
      url,
      method: 'GET',
    })
  }

  readonly getFileContainer = (id: number) => {
    return this.doRequest<IFdvuFileContainer>({
      url: this.getUrl('file-container/file-container-id', id),
      method: 'GET',
    })
  }

  readonly downloadFile = (projectFileId: number) => {
    return this.doRequest({
      url: this.getUrl(`files/download/project-file-id/${projectFileId}`),
      method: 'GET',
      blob: true,
    })
  }

  readonly deleteFile = (projectFileId: number) => {
    return this.doRequest({
      url: this.getUrl(`files/delete/project-file-id/${projectFileId}`),
      method: 'DELETE',
      text: true,
    })
  }
}
export default new FdvuSubcontractorService()
