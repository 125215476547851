import { parse } from 'query-string'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useLocation } from 'react-router-dom'
import MultiTouchContextProvider from 'src/context/MultiTouchContext/MultiTouchProvider'
import ProjectContextProviderAnonymous from 'src/context/ProjectContextProvider/ProjectProviderAnonymous'
import useServiceData from 'src/hooks/useServiceData'
import ErrorPage from 'src/page/error/ErrorPage'
import SharedGanttPage from 'src/page/gantt/SharedGanttPage'
import { validateShareToken } from 'src/service/ShareTokenService'
import Loader from 'src/ui-elements/loader/Loader'
import ReadOnlyMenu from '../components/menu/ReadOnlyMenu'

const SharedPagesRouter = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { shareToken } = parse(search)

  const { data, loading } = useServiceData(
    () => validateShareToken(typeof shareToken === 'string' ? shareToken : ''),
    [shareToken],
  )

  if (loading) return <Loader />

  return data?.valid ? (
    <ProjectContextProviderAnonymous>
      <MultiTouchContextProvider>
        <ReadOnlyMenu>
          <Switch>
            <Route exact path="/share/gantt" component={SharedGanttPage} />
          </Switch>
        </ReadOnlyMenu>
      </MultiTouchContextProvider>
    </ProjectContextProviderAnonymous>
  ) : (
    <ErrorPage>
      <h2 className="font-bold">{t('error_message')}:</h2>
      <span>{data?.message}</span>
    </ErrorPage>
  )
}

export default SharedPagesRouter
