import { useRef } from 'react'
import reactStringReplace from 'react-string-replace'
import { twMerge } from 'tailwind-merge'
import Tooltip from '../tool-tip/Tooltip'
import UserPreview from '../user/UserPreview'

export interface ILinkTextProps {
  value: string
  classNames?: string
}

const mentionsPattern = /(@\[.*?\]\(\d+\))/g
const urlPattern =
  /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)/g

const LinkfyText = ({ value, classNames }: ILinkTextProps) => {
  const userRef = useRef<any>(null)

  const handleUserClick = () => {
    userRef?.current?.handleUserClick?.()
  }

  const replaceMentions = (match: string, index: number) => {
    const userName = match.match(/@\[(.*?)\]/)?.[1]
    const userId = match.match(/((\d+))/)?.[1]
    if (!userName || !userId) return match
    return (
      <span key={index}>
        <Tooltip
          direction={'right'}
          hoveredClassName={'text-blue-root'}
          content={<UserPreview ref={userRef} userId={parseInt(userId)} />}
        >
          <span
            onClick={handleUserClick}
            className="text-blue-root bg-blue-root-tab-wash hover:bg-blue-root-tab-wash font-medium py-0.5 rounded"
          >
            {userName}
          </span>
        </Tooltip>
      </span>
    )
  }

  const replaceUrls = (match: string, index: number) => {
    return (
      <a
        key={index}
        className="text-blue-500 underline"
        target={'_blank'}
        href={match}
        rel="noreferrer"
      >
        {match}
      </a>
    )
  }

  if (typeof value !== 'string') return value

  const textWithReplacedUrls = reactStringReplace(
    value,
    urlPattern,
    replaceUrls,
  )
  const textWithReplacedUrlsAndMentions = reactStringReplace(
    textWithReplacedUrls,
    mentionsPattern,
    replaceMentions,
  )

  return (
    <span className={twMerge('whitespace-pre-wrap', classNames)}>
      {textWithReplacedUrlsAndMentions}
    </span>
  )
}

export default LinkfyText
