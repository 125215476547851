import qs from 'query-string'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import GanttTimeline from 'src/components/gantt/GanttTimeline'
import { getProjectTree } from 'src/service/ProjectService'
import InfoBanner from 'src/ui-elements/info-banner/InfoBanner'
import Loader from 'src/ui-elements/loader/Loader'
import { capFirstLetter } from '../../utility/utils'

export interface IProjectGantt {
  children?: IProjectGantt[]
  id: number
  deadline: string
  start_date?: string
  name: string
  status: string
  type: string
}

const SharedGanttPage = () => {
  const { t } = useTranslation()
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { search } = useLocation()

  const { projectId, shareToken } = qs.parse(search)

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      const res = await getProjectTree(Number(projectId), shareToken as string)
      setData(addParentInfo(res))
      setLoading(false)
    }

    if (Number(projectId) && shareToken && shareToken.length > 0) {
      loadData()
    }
  }, [projectId, shareToken])

  const addParentInfo = (d: any[]): any[] => {
    const keypointMap = {}
    return d.map((da: any) => {
      const { type, parent, record_id: recordId } = da
      const recordIds: string[] = recordId !== null ? recordId.split('-') : []
      da.open = true
      const itemId = recordIds.pop() // remove the item's id from the record_id
      if (type === 'Team' || type === 'MileStone') {
        da.parentHirarcy = parent
      } else if (type === 'KeyPoint') {
        if (parent.charAt(0) === 'M') {
          recordIds.splice(1, 0, parent)
          const copyId: string = itemId || ''
          keypointMap[copyId] = parent
        }
        da.parentHirarcy = recordIds.join('/')
      } else if (type === 'Delivery' || type === 'Task') {
        if (keypointMap[recordIds[1]]) {
          recordIds.splice(1, 0, keypointMap[recordIds[1]])
        }
        da.parentHirarcy = recordIds.join('/')
      }
      return da
    })
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={'bg-grey-lighter'}>
          {data && data.length > 0 ? (
            <div className="mt-16">
              <GanttTimeline ganttData={data} readonly />
            </div>
          ) : (
            <div className={'mt-12'}>
              <InfoBanner text={capFirstLetter(t('no_data_found'))} />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default SharedGanttPage
