import { DependencyList, useEffect, useRef } from 'react'

interface IUseDebouncer {
  delay: number
  callback?: () => void | Promise<void> | unknown
}

export const useDebouncer = ({ delay, callback }: IUseDebouncer) => {
  const localTimeout = useRef<ReturnType<typeof setTimeout>>()

  const nextCallbackTime = useRef(Date.now())

  const callCallback = async () => {
    const now = Date.now()
    if (now < nextCallbackTime.current) {
      nextCallbackTime.current = nextCallbackTime.current - now
    } else {
      if (callback) callback()
    }
  }

  const setNextCallbackTime = (interval: number) => {
    nextCallbackTime.current = Date.now() + interval
    if (localTimeout.current) clearTimeout(localTimeout.current)
    localTimeout.current = setTimeout(callCallback, interval)
  }

  const startDebouncer = (delayinMillis?: number) => {
    setNextCallbackTime(delayinMillis || delay)
  }

  return startDebouncer
}

export const useDebouncedEffect = (
  callback: () => void | (() => void),
  timeout: number,
  deps: DependencyList,
) => {
  const data = useRef<{ firstTime: boolean; clearFunc?: () => void }>({
    firstTime: true,
  })
  useEffect(() => {
    const { firstTime, clearFunc } = data.current

    if (firstTime) {
      data.current.firstTime = false
      return
    }

    const handler = setTimeout(() => {
      if (clearFunc && typeof clearFunc === 'function') {
        clearFunc()
      }
      data.current.clearFunc = callback() ?? undefined
    }, timeout)

    return () => {
      clearTimeout(handler)
    }
  }, [timeout, ...deps])
}
