import { ITrainType, IWagonType } from './OrgTypes'
import { request } from './service-utils'

export const createTrainType = (
  train: Omit<ITrainType, 'id'>,
  projectId: number,
): Promise<ITrainType> => {
  return request(`projects/${projectId}/train_types`, 'POST', train)
}

export const editTrainType = (
  trainType: NullablePartial<ITrainType & { execution_order: number }>,
): Promise<ITrainType> => {
  return request(`train_types/${trainType.id}`, 'PUT', trainType)
}

export const getProjectTrainTypes = (
  projectId: number,
): Promise<ITrainType[]> => {
  return request(`projects/${projectId}/train_types`, 'GET')
}

export const getaSingleTrainType = (
  trainTypeId: number,
): Promise<
  Omit<ITrainType, 'locomotive_types'> & { locomotive_types: IWagonType[] }
> => {
  return request(`/train_types/${trainTypeId}`, 'GET')
}

export const getTrainTypeTree = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/train_types/tree`, 'GET')
}

export const deleteTrainType = (task: ITrainType) => {
  return request(`/train_types/${task.id}`, 'DELETE', {}, true)
}

export const bulkDeleteTrainType = (ids: number[]) => {
  return request('/train_types/bulk_delete', 'POST', { ids }, true)
}

export const trainTypeWagonWorkforce = (
  projectId: number,
  trainTypeId: number,
  disciplineIds?: number[],
) => {
  return request(
    `/projects/${projectId}/train_types/${trainTypeId}/wagon_workforce`,
    'POST',
    { discipline_ids: disciplineIds },
  )
}
