import moment from 'moment/moment'
import * as React from 'react'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { ITestSystemGroup } from 'src/service/OrgTypes'
import { getStatusesForType } from 'src/service/SystemStatusService'
import { editTestSystemGroup } from 'src/service/TestSystemGroupService'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'

interface ITestSystemGroupFields {
  testSystemGroup?: ITestSystemGroup
  setTestSystemGroup: (tsg: ITestSystemGroup) => void
  onUpdate: () => void
  inspectorPanel?: boolean
}

const TestSystemGroupStatusFields: FC<ITestSystemGroupFields> = ({
  testSystemGroup,
  setTestSystemGroup,
  onUpdate,
  inspectorPanel = false,
}) => {
  const projectId = useProjectId()
  const { t } = useTranslation()
  const userContext = useContext(UserContext)
  const writeAccess =
    userContext.actions.hasWriteAccess('object') ||
    testSystemGroup?.responsible_id === userContext.state.user.id

  const { getChangesForUpdate } = useInlineDependencyUpdate<ITestSystemGroup>(
    setTestSystemGroup,
    testSystemGroup,
  )

  const onChangeInput = (update: Partial<ITestSystemGroup>) => {
    const allUpdates = getChangesForUpdate(update)
    editTestSystemGroup({
      ...convertUndefinedToNull(allUpdates),
      id: testSystemGroup?.id,
    }).then(() => {
      onUpdate()
    })
  }
  return (
    <>
      <DateTimeInlineInputComponent
        label="planned_start"
        selectedTime={testSystemGroup?.planned_start}
        onValueSubmitted={(date) => {
          onChangeInput({
            planned_start: date ? moment(date) : undefined,
            planned_end: date ? moment(date).add(6, 'weeks') : undefined,
          })
        }}
        inspectorPanel={inspectorPanel}
        cancelButton
        disabled={!writeAccess}
      />
      <DateTimeInlineInputComponent
        label="planned_end"
        selectedTime={testSystemGroup?.planned_end}
        onValueSubmitted={(date) => {
          onChangeInput({ planned_end: date ? moment(date) : undefined })
        }}
        inspectorPanel={inspectorPanel}
        cancelButton
        disabled={!writeAccess}
      />
      <DateTimeInlineInputComponent
        label="actual_start"
        selectedTime={testSystemGroup?.actual_start}
        onValueSubmitted={(date) => {
          onChangeInput({ actual_start: date ? moment(date) : undefined })
        }}
        inspectorPanel={inspectorPanel}
        cancelButton
        disabled={!writeAccess}
      />
      <DateTimeInlineInputComponent
        label="actual_end"
        selectedTime={testSystemGroup?.actual_end}
        onValueSubmitted={(date) => {
          onChangeInput({ actual_end: date ? moment(date) : undefined })
        }}
        inspectorPanel={inspectorPanel}
        cancelButton
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        getItems={() => getStatusesForType(projectId, 'TestSystemGroup')}
        label="status"
        initialItem={testSystemGroup?.test_system_group_status}
        getItemLabel={(systemStatus) => systemStatus?.name}
        validate={(value) => (value !== undefined ? undefined : t('required'))}
        selectedId={testSystemGroup?.test_system_group_status_id}
        onValueSubmitted={(test_system_group_status_id) => {
          if (test_system_group_status_id)
            onChangeInput({ test_system_group_status_id })
        }}
        inspectorPanel={inspectorPanel}
        disabled={!writeAccess}
      />
      <TextInlineInputCompontent
        label="completed_percent"
        value={testSystemGroup?.percent_completed?.toString()}
        disabled
      />
      <DateTimeInlineInputComponent
        label="created_at"
        selectedTime={testSystemGroup?.created_at}
        onValueSubmitted={() => {}}
        disabled
        inspectorPanel={inspectorPanel}
      />
      <DateTimeInlineInputComponent
        label="updated_at"
        selectedTime={testSystemGroup?.updated_at}
        onValueSubmitted={() => {}}
        disabled
        inspectorPanel={inspectorPanel}
      />
    </>
  )
}

export default TestSystemGroupStatusFields
