import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDiscipline, IStatusTypes } from 'src/service/OrgTypes'
import {
  addKeypointDependency,
  getDependentKeyPoints,
  getDependentOnKeyPoints,
  getProjectKeypoints,
  IProjectKeypointResponse,
  removeKeypointDependency,
  updateKeypoint,
} from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import List from 'src/ui-elements/list/List'
import { filterType, IListColumns } from 'src/ui-elements/list/ListTypes'
import Button from '../../ui-elements/button/Button'
import Modal from '../../ui-elements/modal/Modal'
import {
  concatinateValuesForTable,
  displineComparator,
  userComparator,
} from '../../utility/Utility'
import { classNames } from '../../utility/utils'
import DeleteModal from '../delete-modal/DeleteModal'
import { setColorForIcon } from '../discipline/disciplineUtils'
import MultiSelector from '../multi-selector/MultiSelector'
import TableStatusLabel from '../status-dropdown/TableStatusLabel'
import UserIcon from '../user/UserIcon'
import KeypointInspectorPanel from './KeyPointInspectorPanel'

interface IKeypointDependencyListProps {
  projectId: number
  keyPointId: number
  showDependentOn: boolean
  update?: () => void
}

const KeypointDependencyList = ({
  projectId,
  keyPointId,
  showDependentOn,
  update: _update,
}: IKeypointDependencyListProps) => {
  const { t } = useTranslation()
  const [keypoints, setKeypoints] = useState<IProjectKeypointResponse[]>([])
  const [selectedKeypoint, setSelectedKeypoint] =
    useState<IProjectKeypointResponse>()
  const [selectedKeypoints, setSelectedKeypoints] = useState<number[]>([])
  const [openInspectorPanel, setOpenInspectorPanel] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showAddDependencyModal, setShowAddDependencyModal] =
    useState<boolean>(false)
  const [projectKeypoints, setProjectKeypoints] = useState<
    IProjectKeypointResponse[]
  >([])
  const [keypointLoading, setKeypointLoading] = useState<boolean>(false)

  useEffect(() => {
    loadData()
  }, [projectId, keyPointId])

  const loadData = () => {
    const api = showDependentOn
      ? getDependentOnKeyPoints
      : getDependentKeyPoints
    api(projectId, keyPointId).then((res) => {
      setKeypoints(res)
    })
  }

  const onOpenInspectorPanel = (e: any, data: IProjectKeypointResponse) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedKeypoint(data)
    setOpenInspectorPanel(true)
  }

  const onCloseInspectorPanel = () => {
    setOpenInspectorPanel(false)
    setSelectedKeypoint(undefined)
    loadData()
  }

  const onDeletItemClick = (keypoint: IProjectKeypointResponse) => {
    setSelectedKeypoint(keypoint)
    setShowDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const onDeleteKeypoint = () => {
    if (selectedKeypoint && selectedKeypoint.id) {
      removeKeypointDependency(keyPointId, {
        dependent_on_key_point: [selectedKeypoint.id],
      }).then(() => {
        loadData()
        setSelectedKeypoint(undefined)
      })
    }
    setShowDeleteModal((n) => !n)
  }

  const onStatusSelect = (status: IStatusTypes, key: number, cell: any) => {
    const keypoint = cell
    if (keypoint) {
      keypoint.status = status.id
      updateKeypoint(key, keypoint).then(() => {
        loadData()
      })
    }
  }

  const closeModal = () => {
    setShowAddDependencyModal(false)
    setSelectedKeypoints([])
  }

  const openModal = async () => {
    setKeypointLoading(true)
    setShowAddDependencyModal(true)
    getProjectKeypoints(projectId).then((res) => {
      const unselected = res.filter(
        (keypoint: IProjectKeypointResponse) =>
          !keypoints.find((k) => k.id === keypoint.id) &&
          keypoint.id !== keyPointId,
      )
      setProjectKeypoints(unselected)
      setKeypointLoading(false)
    })
  }

  const onSubmitDependency = async (e: any) => {
    e.preventDefault()
    const selectedKeypointIds = selectedKeypoints.concat(
      keypoints.map((k) => k.id),
    )
    addKeypointDependency(keyPointId, {
      dependent_on_key_point: showDependentOn ? selectedKeypointIds : null,
      dependent_key_point: !showDependentOn ? selectedKeypointIds : null,
    }).then(() => {
      loadData()
    })
    setShowAddDependencyModal(false)
  }

  const columns: IListColumns[] = [
    {
      name: 'id',
      size: '110',
      id: 'recordId',
      sortingField: 'record_id',
      dataField: 'record_id',
    },
    {
      name: 'title',
      size: '300',
      id: 'name',
      sortingField: 'name',
      dataField: 'name',
    },
    {
      name: 'status',
      size: '150',
      id: 'status',
      sortingField: 'status',
      dataField:
        'status||endTime||update_access||expired_children||open_children||behind_schedule_children',
      cell: (cell: any, key: number) => (
        <TableStatusLabel
          statusTypes={statusTypes(t)}
          cell={cell}
          rowId={key}
          onStatusSelect={onStatusSelect}
        />
      ),
    },
    {
      name: 'deadline',
      size: '150',
      id: 'endTime',
      sortingField: 'endTime',
      dataField: 'endTime',
      cell: (endTime: any) => (
        <span>{endTime ? moment(endTime).format('L') : ''}</span>
      ),
    },
    {
      name: 'baseline_date',
      size: '150',
      id: 'baseline',
      sortingField: 'baseline',
      dataField: 'baseline',
      filterType: filterType.RANGEDATE,
      cell: (baseline: any) => (
        <span>{baseline ? moment(baseline).format('L') : ''}</span>
      ),
    },
    {
      name: 'deliveries',
      size: '150',
      id: 'delivery',
      dataField:
        'done_children||open_children||expired_children||behind_schedule_children',
      cell: (keypoint: any) => (
        <span
          className={classNames(
            keypoint.expired_children > 0 ||
              keypoint.behind_schedule_children > 0
              ? 'text-red-two'
              : '',
          )}
        >
          {keypoint.done_children}/
          {keypoint.open_children + keypoint.done_children}
        </span>
      ),
    },
    {
      name: 'responsible',
      size: '200',
      id: 'responsible',
      dataField: 'responsible',
      sortingField: 'responsible',
      filterType: filterType.DEFAULT,
      filterDataField: 'responsible.firstName||responsible.lastName',
      filterDataValue: 'responsible.id',
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? userComparator(a, b, direction) : 0) : 0,
      cell: (responsible: any) =>
        responsible ? (
          <UserIcon
            userId={responsible.id}
            firstName={responsible.firstName}
            lastName={responsible.lastName}
            image_url={responsible.image_url}
          />
        ) : (
          <span />
        ),
    },
    {
      name: 'discipline',
      size: '200',
      sortingField: 'discipline',
      id: 'disiplin',
      dataField: 'discipline',
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? displineComparator(a, b, direction) : 0) : 0,
      cell: (discipline: IDiscipline) => {
        return discipline ? (
          <span className={'flex flex-row'}>
            <span className={'mr-4'}>
              {setColorForIcon({
                color: discipline.color,
                shape: discipline.shape,
              })}
            </span>
            {concatinateValuesForTable(discipline.shortName, discipline.name)}
          </span>
        ) : (
          <span />
        )
      },
    },
    {
      name: 'created_at',
      size: '150',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '150',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]
  return (
    <div className={'pl-4'}>
      <List
        actionButton={
          <Button
            type={Button.ButtonType.PRIMARY}
            size={Button.ButtonSize.SMALL}
            onClick={openModal}
          >
            {t('new_dependency')}
          </Button>
        }
        data={keypoints}
        columns={columns}
        tableName={
          showDependentOn ? 'KeypointDependentOn' : 'KeypointDependent'
        }
        itemsPerPage={0}
        onPreviewClick={onOpenInspectorPanel}
        actionMenu={[
          {
            name: t('delete'),
            action: onDeletItemClick,
          },
        ]}
      />

      {openInspectorPanel && selectedKeypoint && (
        <KeypointInspectorPanel
          keyPointId={selectedKeypoint.id}
          open={openInspectorPanel}
          onClose={onCloseInspectorPanel}
          origin={{
            name: t('key_points'),
            url: `/key_points/${keyPointId}`,
          }}
          onUpdate={loadData}
        />
      )}
      {selectedKeypoint && selectedKeypoint.id && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={onDeleteKeypoint}
          itemIdnType={`${selectedKeypoint.record_id} (${t('keypoint')})`}
          itemName={`${selectedKeypoint.record_id} - ${selectedKeypoint.name}`}
        />
      )}
      {showAddDependencyModal && (
        <Modal
          show={showAddDependencyModal}
          title={t('select_the_key_points_you_want_to_add')}
          closeModal={closeModal}
          size={'w-1/3'}
          maxWidth={600}
        >
          <div
            className={'text-left flex flex-col m-2 pr-4'}
            style={{ minHeight: '100px' }}
          >
            <MultiSelector
              items={projectKeypoints}
              label={t('keypoints')}
              scroll={false}
              dataFields={['record_id', 'name']}
              selectedItems={selectedKeypoints}
              fontWeight={'bold'}
              onSelect={(ids: any[]) => setSelectedKeypoints(ids)}
              noBorder={true}
              loading={keypointLoading}
              disableSelectAll={true}
            />
            <div className={'mt-2'}>
              <Button
                type={Button.ButtonType.PRIMARY}
                onClick={onSubmitDependency}
              >
                {t('save')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default KeypointDependencyList
