import { IDocumentType } from '../types/IFlow'
import { GenericDocumentService } from './GenericDocumentService'

class DocumentTypeService extends GenericDocumentService {
  constructor() {
    super('document_types')
  }

  readonly getProjectDocumentTypes = () => {
    const url = this.getUrl()
    return this.doRequet<IDocumentType[]>({ url })
  }

  readonly getDocumentType = (stepCodeId: number) => {
    const url = this.getUrl(stepCodeId)
    return this.doRequet<IDocumentType>({ url })
  }

  readonly createDocumentType = (step: Omit<IDocumentType, 'id'>) => {
    const url = this.getUrl()
    return this.doRequet<IDocumentType>({ url, body: step, method: 'POST' })
  }

  readonly editDocumentType = (stepCode: IDocumentType) => {
    const url = this.getUrl(stepCode.id)
    return this.doRequet<IDocumentType>({ url, method: 'PUT', body: stepCode })
  }

  readonly deleteDocumentType = (stepCodeId: number) => {
    const url = this.getUrl(stepCodeId)
    return this.doRequet<void>({ url, method: 'DELETE', text: true })
  }
}

export default new DocumentTypeService()
