import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeypointInspectorPanel from 'src/components/key-point/KeyPointInspectorPanel'
import KeypointModal from 'src/components/key-point/KeypointModal'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { useFilteredKeypoints } from 'src/query/planning/keypoints'
import { IProjectKeypointResponse } from 'src/service/ProcessService'
import Button from 'src/ui-elements/button/Button'

interface ImprovementKeypointsSectionTableProps {
  improvementId: number
  projectId: number
}

const ImprovementKeypointsSectionTable = ({
  improvementId,
  projectId,
}: ImprovementKeypointsSectionTableProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [selectedKeypointId, setSelectedKeypointId] = useState<number>()
  const [showCreateNewKeyPointModal, setShowCreateNewKeyPointModal] =
    useState(false)

  const { data: keypoints, isLoading: keypointsLoading } = useFilteredKeypoints(
    { improvement: [improvementId] },
  )

  const reload = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['keypoints', projectId, { improvement: [improvementId] }],
    })
  }, [queryClient, projectId, improvementId])

  return (
    <div>
      <Button
        type={Button.ButtonType.PRIMARY}
        size={Button.ButtonSize.SMALL}
        onClick={() => setShowCreateNewKeyPointModal(true)}
        margin="mb-2"
      >
        {t('new_keypoint')}
      </Button>
      <InspectorSectionTable
        headerColumns={[
          t('id'),
          t('title'),
          t('status'),
          t('deadline'),
          t('responsible'),
        ]}
        rowsData={(keypoints || [])?.map(
          ({ id, record_id, name, status, endTime, responsible }) => ({
            cells: [
              record_id,
              name,
              t(status),
              endTime ? moment(endTime).format('L') : '',
              responsible
                ? `${responsible?.firstName} ${responsible?.lastName}`
                : '',
            ],
            id,
          }),
        )}
        loading={keypointsLoading}
        handleClick={setSelectedKeypointId}
      />
      {selectedKeypointId && (
        <KeypointInspectorPanel
          keyPointId={selectedKeypointId}
          open={selectedKeypointId !== undefined}
          onClose={() => setSelectedKeypointId(undefined)}
          origin={{
            name: t('/improvements'),
            url: '/improvements',
          }}
          onUpdate={reload}
        />
      )}
      <KeypointModal
        improvementId={improvementId}
        show={showCreateNewKeyPointModal}
        createKeypoint
        close={() => setShowCreateNewKeyPointModal(false)}
        keypoint={{} as IProjectKeypointResponse}
        onCreateKeypoint={reload}
      />
    </div>
  )
}

export default ImprovementKeypointsSectionTable
