import { classNames } from 'src/utility/utils'

interface IBlueBadgeProps {
  text: string
  className?: string
  onClick?: () => void
  children?: JSX.Element
}

const BlueBadge = ({
  text,
  className = '',
  onClick,
  children,
}: IBlueBadgeProps) => {
  const styleClass = {
    root: classNames(
      'bg-d-lightblue',
      'p-0.5',
      'px-2',
      'text-blue-root',
      'text-xxs',
      'rounded',
      'mx-1',
      'w-mx-content',
      'whitespace-nowrap',
      'min-w-10',
      'text-center',
      'flex',
      'items-center',
      className,
    ),
  }

  return (
    <div className={styleClass.root} onClick={onClick}>
      {text}
      {children}
    </div>
  )
}

export default BlueBadge
