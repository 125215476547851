import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import Button from '../../../ui-elements/button/Button'
import Modal from '../../../ui-elements/modal/Modal'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import { classNames } from '../../../utility/utils'
import DeleteModal from '../../delete-modal/DeleteModal'

interface ITrainUpdateDeleteConfirmation {
  closeModal: () => void
  openUpdateModal: boolean
  openDeleteModal: boolean
  title: string
  onSubmitForm: (v: boolean, w: boolean) => void
}

const TrainUpdateDeleteConfirmation = ({
  title,
  closeModal,
  openUpdateModal,
  openDeleteModal,
  onSubmitForm,
}: ITrainUpdateDeleteConfirmation) => {
  const styleClass = {
    inputGroup: classNames('w-full', 'flex', 'flex-row', 'justify-end', 'pr-4'),
  }
  const { t } = useTranslation()
  const [applyToAllInGroup, setApplyToAllInGroup] = useState<boolean>(false)
  const [applyToRestInGroup, setApplyToRestInGroup] = useState<boolean>(false)

  const onSubmit = () => {
    onSubmitForm(applyToAllInGroup, applyToRestInGroup)
  }

  const onDelete = () => {
    onSubmitForm(applyToAllInGroup, false)
  }

  return (
    <>
      {openUpdateModal ? (
        <Modal
          show={openUpdateModal}
          closeModal={closeModal}
          maxWidth={600}
          title={t('edit_train', { title })}
        >
          <>
            {
              <div>
                <div className="m-4 flex items-center ml-2">
                  <SwitchHOC
                    valueProp={applyToAllInGroup}
                    className="custom-classname"
                    onChange={() => setApplyToAllInGroup(!applyToAllInGroup)}
                    defaultColor={false}
                  />
                  <label
                    onClick={() => setApplyToAllInGroup(!applyToAllInGroup)}
                    className={'mr-2 ml-2 text-sm'}
                  >
                    {t('update_all_trains_in_the_same_control_area_group')}
                  </label>
                </div>
              </div>
            }
            {
              <div>
                <div className="m-4 flex items-center ml-2">
                  <SwitchHOC
                    valueProp={applyToRestInGroup}
                    className="custom-classname"
                    onChange={() => setApplyToRestInGroup(!applyToRestInGroup)}
                    defaultColor={false}
                  />
                  <label
                    onClick={() => setApplyToRestInGroup(!applyToRestInGroup)}
                    className={'mr-2 ml-2 text-sm'}
                  >
                    {t('update_future_trains_in_the_same_control_area_group')}
                  </label>
                </div>
              </div>
            }
            <ModalFooter>
              <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
                {t('cancel')}
              </Button>
              <Button type={Button.ButtonType.PRIMARY} onClick={onSubmit}>
                {t('update')}
              </Button>
            </ModalFooter>
          </>
        </Modal>
      ) : (
        <DeleteModal
          show={openDeleteModal}
          closeModal={closeModal}
          itemIdnType={t('train_title', { title })}
          onDelete={onDelete}
          itemName={'tog'}
        >
          <div className={styleClass.inputGroup}>
            <div className="m-4 flex items-center ml-6">
              <SwitchHOC
                valueProp={applyToAllInGroup}
                className="custom-classname"
                onChange={() => setApplyToAllInGroup(!applyToAllInGroup)}
                defaultColor={true}
              />
              <label
                onClick={() => setApplyToAllInGroup(!applyToAllInGroup)}
                className={'mr-2 ml-2 text-sm'}
              >
                {t(
                  'delete_all_corresponding_trains_in_the_same_control_area_group',
                )}
              </label>
            </div>
          </div>
        </DeleteModal>
      )}
    </>
  )
}

export default TrainUpdateDeleteConfirmation
