import debounce from 'lodash/debounce'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import Xarrow, { refType } from 'react-xarrows'
import { ISiblingDepType } from 'src/page/delivery/DeliveryTimeline'
import { IDelivery, IKeypoint } from 'src/service/OrgTypes'
import { ICanvasLength } from '../grid/Keypoint/KeypointTimeline'
import { ProcessTimelineDependency } from '../process-timeline/ProcessTimelineItem'

export interface IArrow {
  startItemId: string
  endItemId: string
  isOutOfRange: boolean
  siblingId: number
  type: string
}

type CalculateIsOutOfRangeFn = (type: string, siblingEndTime: string) => boolean

const useCanvasDependencyLines = (
  showArrow: boolean,
  showParent: boolean,
  selectedSiblings: ISiblingDepType[],
  canvasDuration: ICanvasLength,
  parentRef: number,
  leftFurthestItem: { id: number; end_time: string } | null,
  rightFurthestItem: { id: number; end_time: string } | null,
  dependencyType: ProcessTimelineDependency,
  selectedItemRefObject: IKeypoint | IDelivery | null,
  domain: 'keypoint' | 'delivery',
): [IArrow[], (arrow: IArrow) => React.ReactNode] => {
  const debouncedrenderArrows = useCallback(
    debounce((lines: IArrow[]) => {
      setArrows(lines)
    }, 400),
    [],
  )

  const [arrows, setArrows] = useState<IArrow[]>([])

  const calculateIsOutOfRange: CalculateIsOutOfRangeFn = useCallback(
    (type, siblingEndTime) => {
      if (!showParent || !showArrow || !canvasDuration) {
        return true
      }

      const itemsAreFarRight =
        moment(selectedItemRefObject?.end_time).isAfter(
          moment(canvasDuration.canvasTimeEnd),
          'day',
        ) &&
        moment(siblingEndTime).isAfter(
          moment(canvasDuration.canvasTimeEnd),
          'day',
        )

      const itemsAreFarLeft =
        moment(selectedItemRefObject?.end_time).isBefore(
          moment(canvasDuration.canvasTimeStart),
          'day',
        ) &&
        moment(siblingEndTime).isBefore(
          moment(canvasDuration.canvasTimeStart),
          'day',
        )

      if (itemsAreFarLeft || itemsAreFarRight) return true

      const isPastOutOfRange = leftFurthestItem
        ? moment(leftFurthestItem.end_time).isAfter(
            moment(canvasDuration.canvasTimeEnd),
            'day',
          )
        : false

      const isFutureOutOfRange = rightFurthestItem
        ? moment(rightFurthestItem.end_time).isBefore(
            moment(canvasDuration.canvasTimeStart),
            'day',
          )
        : false

      if (dependencyType === ProcessTimelineDependency.ALL_DEPEDENCIES) {
        return isPastOutOfRange || isFutureOutOfRange
      } else if (type === 'past') {
        return isPastOutOfRange
      } else if (type === 'future') {
        return isFutureOutOfRange
      } else {
        return false
      }
    },
    [
      dependencyType,
      leftFurthestItem,
      rightFurthestItem,
      canvasDuration,
      showParent,
      showArrow,
      selectedItemRefObject,
    ],
  )

  useEffect(() => {
    if (showArrow && showParent) {
      const dependencyLines = selectedSiblings.map(
        ({ id: siblingId, type, endTime }) => {
          const arrowStartElement = document.getElementById(
            `${domain}-${parentRef}`,
          )
          const arrowEndElement = document.getElementById(
            `${domain}-${siblingId}`,
          )
          const dummyElementId =
            type === 'future' ? `timeline-right-ref` : `timeline-left-ref`
          const endItemId = arrowEndElement
            ? `${domain}-${siblingId}`
            : dummyElementId
          const startItemId = arrowStartElement
            ? `${domain}-${parentRef}`
            : type === 'future'
              ? `timeline-left-ref`
              : `timeline-right-ref`

          const isOutOfRange = calculateIsOutOfRange(type, endTime)

          return {
            startItemId,
            endItemId,
            isOutOfRange,
            siblingId,
            type,
          }
        },
      )

      debouncedrenderArrows(dependencyLines)
    } else {
      debouncedrenderArrows.cancel()
      setArrows([])
    }
  }, [
    showArrow,
    showParent,
    selectedSiblings,
    parentRef,
    calculateIsOutOfRange,
    selectedItemRefObject,
  ])

  const renderArrows = ({
    startItemId,
    endItemId,
    isOutOfRange,
    type,
    siblingId,
  }: IArrow) => {
    if (!startItemId || !endItemId || isOutOfRange) {
      return <></>
    }

    const arrowColor = type === 'past' ? '#bcaaa4' : '#269bf7'
    const showHead = type === 'future'
    const showTail = type === 'past'
    const dashed = type === 'past'

    return showArrow && showParent ? (
      <Xarrow
        divContainerProps={{ className: 'z-10' }}
        strokeWidth={2}
        dashness={dashed}
        color={arrowColor}
        key={`${siblingId}-${Math.floor(Math.random() * 100)}`}
        showHead={showHead}
        showTail={showTail}
        start={startItemId}
        end={endItemId}
      />
    ) : (
      <></>
    )
  }

  return [arrows, renderArrows]
}

export const renderDependencyLines = (
  startItemId: refType,
  endItemId: refType,
  siblingId: number,
  type: string,
) => {
  const isFuture = type === 'future'
  const isPast = type === 'past'
  const arrowColor = isPast ? '#bcaaa4' : '#269bf7'

  return (
    <Xarrow
      divContainerProps={{ className: 'z-10' }}
      strokeWidth={2}
      dashness={isPast}
      color={arrowColor}
      key={siblingId}
      showHead={isFuture}
      showTail={isPast}
      start={startItemId}
      end={endItemId}
    />
  )
}

export const getElementOrDummyId = (
  elementId: string,
  type: string,
  parent: boolean,
) => {
  const arrowElement = document.getElementById(elementId)
  const dummyElement = parent
    ? type === 'future'
      ? `timeline-left-ref`
      : `timeline-right-ref`
    : type === 'future'
      ? `timeline-right-ref`
      : `timeline-left-ref`
  return arrowElement ? elementId : dummyElement
}

export const calculateIsOutOfRange = (
  type: string,
  siblingEndTime: string,
  leftFurthestItem: { end_time: number } | null,
  rightFurthestItem: { end_time: number } | null,
  canvasDuration: ICanvasLength,
  selectedItemRef: any,
): boolean => {
  if (!canvasDuration) {
    return true
  }

  const endTime = selectedItemRef.current.end_time
  const { canvasTimeEnd, canvasTimeStart } = canvasDuration

  const itemsAreFarRight =
    moment(endTime).isAfter(moment(canvasTimeEnd), 'day') &&
    moment(siblingEndTime).isAfter(moment(canvasTimeEnd), 'day')

  const itemsAreFarLeft =
    moment(endTime).isBefore(moment(canvasTimeStart), 'day') &&
    moment(siblingEndTime).isBefore(moment(canvasTimeStart), 'day')

  if (itemsAreFarLeft || itemsAreFarRight) return true

  const isPastOutOfRange =
    leftFurthestItem &&
    moment(leftFurthestItem.end_time).isAfter(moment(canvasTimeEnd), 'day')

  const isFutureOutOfRange =
    rightFurthestItem &&
    moment(rightFurthestItem.end_time).isBefore(moment(canvasTimeStart), 'day')

  if (type === 'past') {
    return isPastOutOfRange ?? false
  } else if (type === 'future') {
    return isFutureOutOfRange ?? false
  } else {
    return false
  }
}

export default useCanvasDependencyLines
