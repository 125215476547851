import { MouseEvent, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import Tooltip from '../tooltip/Tooltip'

export interface IconButtonProps {
  className?: string
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  onMouseDown?: (e: MouseEvent<HTMLDivElement>) => void
  icon: JSX.Element
  disabled?: boolean
  tooltipText?: string
  tooltipDirection?: 'top' | 'bottom'
  size?: 'small' | 'medium' | 'large'
}

const IconButton = ({
  className,
  onClick,
  onMouseDown,
  icon,
  disabled,
  tooltipText,
  tooltipDirection = 'top',
  size = 'medium',
}: IconButtonProps) => {
  const tooltipOffset = useMemo(() => {
    if (tooltipDirection === 'top') {
      if (size === 'large') return 0.7
      if (size === 'medium') return 0.25
      if (size === 'small') return -0.2
    }
    if (size === 'large') return 1.7
    if (size === 'medium') return 1.2
    return 0.8
  }, [size, tooltipDirection])

  return (
    <div
      className={twMerge(
        'rounded-full cursor-pointer flex justify-center items-center',
        !disabled && 'hover:bg-grey',
        size === 'small' && 'h-3 w-3',
        size === 'medium' && 'h-5 w-5',
        size === 'large' && 'h-7 w-7',
        className,
      )}
      onClick={(e) => {
        e.stopPropagation()
        if (disabled) return
        onClick?.(e)
      }}
      onMouseDown={(e) => {
        e.stopPropagation()
        if (onMouseDown) onMouseDown(e)
      }}
    >
      <Tooltip
        message={tooltipText || ''}
        show={tooltipText !== undefined}
        classNames="flex justify-center items-center h-full w-full"
        offset={tooltipOffset}
        direction={tooltipDirection}
      >
        <icon.type
          {...icon.props}
          className={twMerge(
            'h-5/6 w-5/6',
            icon.props.className,
            disabled && 'fill-gray-400 cursor-not-allowed',
          )}
        />
      </Tooltip>
    </div>
  )
}

export default IconButton
