import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LinkfyText from 'src/components/linkfyText/LinkfyText'
import { MarkdownBold, MarkdownSpan } from 'src/components/markdown/Markdown'
import { ActivityType, IActivityLog } from 'src/service/OrgTypes'
import { isValidDate } from 'src/utility/dateUtils'
import { trimText } from 'src/utility/trimText'
import { capFirstLetter, classNames, openFile } from 'src/utility/utils'

interface ActivityLogItemBodyProps {
  activityLog: IActivityLog
  isForType?: boolean
  className?: string
}

const ActivityLogItemBody = ({
  activityLog,
  isForType = false,
  className = '',
}: ActivityLogItemBodyProps) => {
  const { t } = useTranslation()

  const formatLogValue = (value: unknown) => {
    if (!value && value !== 0 && value !== false) {
      return t('not_set')
    }
    if (typeof value !== 'string') {
      return value
    }
    if (isValidDate(value)) {
      return moment(value).format('L')
    }
    return trimText(value, 25)
  }

  const BodyText = () => {
    if (isForType) {
      if (activityLog.activity_type === ActivityType.CREATE) {
        return (
          <MarkdownBold
            value={t('activity_log_created_modal', {
              itemName: activityLog.parent.record_id,
            })}
          />
        )
      }

      if (activityLog.activity_type === ActivityType.DOCUMENT_UPLOAD) {
        return (
          <div onClick={openFile(activityLog.newValue)}>
            <MarkdownSpan
              value={t('activity_log_upload_modal', {
                fileName: activityLog.filedName,
              })}
              classNames={[
                activityLog.document_id || activityLog.parent_type === 'Project'
                  ? 'text-blue-500 underline cursor-pointer'
                  : 'text-gray-500 line-through',
              ]}
            />
          </div>
        )
      }

      if (activityLog.activity_type === ActivityType.DOCUMENT_DELETE) {
        return (
          <MarkdownBold
            value={t('activity_log_upload_modal_deleted', {
              fileName: activityLog.filedName,
            })}
          />
        )
      }

      if (activityLog.activity_type === ActivityType.CREATED_FMI_SUGGESTION) {
        return (
          <MarkdownBold
            value={t('activity_log_created_fmi_suggestion_modal', {
              value: activityLog.newValue,
            })}
          />
        )
      }

      if (activityLog.activity_type === ActivityType.APPROVED_FMI_SUGGESTION) {
        return (
          <MarkdownBold
            value={t('activity_log_approved_fmi_suggestion_modal', {
              value: activityLog.newValue,
            })}
          />
        )
      }

      if (activityLog.activity_type === ActivityType.DECLINED_FMI_SUGGESTION) {
        return (
          <MarkdownBold
            value={t('activity_log_declined_fmi_suggestion_modal', {
              value: activityLog.newValue,
            })}
          />
        )
      }
    }

    if (
      activityLog.activity_type === ActivityType.UPDATE ||
      activityLog.activity_type === ActivityType.RESPONSIBLE_ASSIGNMENT
    ) {
      if (Array.isArray(activityLog.changed_fields)) {
        return activityLog.changed_fields?.map((item, index) => (
          <div key={index}>
            <MarkdownBold
              value={t('activity_log_updated_modal', {
                fieldName: capFirstLetter(t(item.field_name)),
                from: formatLogValue(item.old_value),
                to: formatLogValue(item.new_value),
              })}
            />
          </div>
        ))
      }
      return (
        <MarkdownBold
          value={t('activity_log_updated_modal', {
            fieldName: capFirstLetter(t(activityLog.filedName)),
            from: formatLogValue(activityLog.oldValue),
            to: formatLogValue(activityLog.newValue),
          })}
        />
      )
    }

    if (
      activityLog.activity_type === ActivityType.COMMENT ||
      activityLog.activity_type === ActivityType.COMMENT_MENTION
    ) {
      return (
        <span>
          <b>{capFirstLetter(t('comment'))}: </b>
          <LinkfyText value={activityLog.newValue} />
        </span>
      )
    }

    if (activityLog.activity_type === ActivityType.SYSTEM_NOTIFICATION) {
      return <span>{activityLog.newValue}</span>
    }

    return null
  }

  return (
    <div className={classNames('text-sm font-thin text-grey-dark', className)}>
      <BodyText />
    </div>
  )
}

export default ActivityLogItemBody
