import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'

export const contentStyle = {
  height: 'calc(100vh - calc(100vh - 100%) - 72px)',
}

export const contentStyleWithTabs = {
  height: 'calc(100vh - calc(100vh - 100%) - 62px)',
}

export const FullPageLoader = () => (
  <div className={'w-full h-full grid place-items-center'}>
    <SpinnerMobile />
  </div>
)

export const FullPageLoaderVh = () => (
  <div
    style={{ minHeight: '100vh', marginTop: '40%' }}
    className={`w-full h-full flex-auto grid justify-center`}
  >
    <SpinnerMobile />
  </div>
)

export const getInfoWrapperStyle = (
  noPadding: boolean,
  onFloatingBtnClick: any,
  isCard: boolean,
  sticky: boolean,
) => {
  return `${
    noPadding
      ? ''
      : onFloatingBtnClick
        ? 'grid grid-cols-1 md:grid-cols-2 gap-4 p-4 '
        : 'px-5 py-4'
  } ${isCard ? 'rounded-md shadow' : ''} ${
    sticky ? 'sticky top-0 z-20' : ''
  } bg-white mb-2`
}

export const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}
