import Close from '@icons/close.svg'
import Download from '@icons/download.svg'
import Draw from '@icons/draw.svg'
import FileCopy from '@icons/file_copy.svg'
import { stringify } from 'query-string'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import Badge from 'src/ui-elements/badge/Badge'
import { FileStatusColorMapper } from 'src/ui-elements/badge/BadgeEnums'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize, ButtonType } from 'src/ui-elements/button/ButtonEnums'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { downloadSingleFile } from 'src/utility/download'
import useProjectId from '../../components/hooks/useProjectId'
import { deleteFile, uploadUsingLink } from '../../service/FileContainerService'
import { classNames } from '../../utility/utils'
import {
  IFileVersion,
  IProjectFile,
  FileStatusEnum,
} from '../types/IFileContainer'
import FolderFilesUpload from './FileUpload/foldersFileUpload/FolderFilesUpload'
import UploadPopUp from './FileUpload/popup/UploadPopup'
import { IPos } from './FileUpload/types'
import FileLinkUploadModal from './FileUpload/upload-link/FileLinkModal'
import PlusButton from './base/PlusButton'

export interface IHistoryFilesProps {
  files: IProjectFile[]
  onNewUpload?: () => void
  fileContainerId?: number
  folderId: number
  block?: boolean
  onDeleteFile?: () => void
  className?: string
  latestFiles?: boolean
  showCopyFile?: boolean
  onFileCopy?: (file_version: IFileVersion) => void
  disabled?: boolean
  documentName?: string
}

const HistoryFiles: React.FC<IHistoryFilesProps> = ({
  files,
  onNewUpload,
  fileContainerId,
  block = false,
  onDeleteFile,
  className,
  latestFiles = false,
  showCopyFile = false,
  onFileCopy,
  disabled,
  folderId,
  documentName,
}) => {
  const { t } = useTranslation()

  const styleClass = {
    root: classNames(
      'flex',
      'items-start',
      block ? 'w-full' : 'max-w-78',
      className,
    ),
    title: classNames(
      'capitalize',
      'text-d-fontgrey',
      'semibold',
      'text-sm',
      'w-22',
      'inline-block',
      'flex-none',
    ),
    right: classNames('flex-auto', 'w-full', 'flex', 'flex-col', 'flex-1'),
    noData: classNames('text-xs', 'text-gray-600', 'pb-2'),
  }

  const [showUpload, setShowUpload] = useState(false)
  const [showFileLink, setUploadLinkModal] = useState(false)
  const [fileToBeDeleted, setFileToBeDeleted] = useState<IFileVersion | null>(
    null,
  )
  const projectId = useProjectId()
  const [importPopUp, setShowImportPopUp] = useState(false)
  const [importPos, setPos] = useState<IPos>({
    left: 0,
    top: 0,
  })

  const history = useHistory()

  const versions = useMemo(() => {
    if (!files) return []

    return files.flatMap((file) => {
      if (latestFiles && file.lastest_version) {
        return {
          name: file.name,
          status: file.status,
          ...file.lastest_version,
        }
      } else {
        return file.file_versions.map((version) => ({
          status: file.status,
          name: file.name,
          ...version,
        }))
      }
    })
  }, [files, latestFiles])

  const openFile = (url: string, name?: string) => {
    downloadSingleFile(url, name || 'Download')
  }

  const removeFile = async (fileId?: number) => {
    if (fileId && fileContainerId) {
      await deleteFile(projectId, fileContainerId, fileId)
      onDeleteFile?.()
    }
  }

  const FileOrLink = (version: IFileVersion, index: number) => {
    if (version.content_type) return FileInfo(version, index)
    return FileLinkInfo(version, index)
  }

  const FileLinkInfo = (version: IFileVersion, index: number) => {
    return (
      <div key={index} className={'flex mb-2 justify-between'}>
        <div className="mb-2 cursor-pointer font-normal items-center text-xs flex">
          <MaterialIcon
            icon="link"
            className="text-base pr-1 text-d-fontgrey"
          />
          <span
            onClick={() => open(version.file_url, '_blank')}
            className="text-blue-root overflow-ellipsis max-w-1/2"
          >
            {trimLink(version.file_url)}
          </span>
          <MaterialIcon
            icon="open_in_new"
            className="text-d-fontgrey text-base px-1"
          />
        </div>
        {!disabled && onDeleteFile && (
          <Button
            type={ButtonType.SECONDARY}
            size={ButtonSize.NARROW}
            toolTipText={t('delete_file')}
            onClick={() => setFileToBeDeleted(version)}
          >
            <Close className={'fill-red'} />
          </Button>
        )}
      </div>
    )
  }

  const trimLink = (link: string) => {
    if (link?.length > 80) {
      return `${link.substring(0, 45)}...${link.substring(link.length - 45)}`
    }
    return link
  }

  const FileInfo = (version: IFileVersion, index: number) => {
    const fileType = version.name?.split('.').pop() || version.content_type

    return (
      <div key={index} className={'flex mb-2 justify-between'}>
        <p className={'text-xs flex truncate items-center'}>
          <span className={'text-black w-auto pr-2 text-left flex-none'}>
            {version.name ?? `${t('name')}: ${t('no_data_found')}`}
          </span>
        </p>
        {!disabled && (
          <div className={'flex items-center'}>
            <Badge
              text={t(version.status ?? FileStatusEnum.NOT_CHECKED)}
              color={
                FileStatusColorMapper[
                  version.status ?? FileStatusEnum.NOT_CHECKED
                ]
              }
              className={'w-max nowrap'}
            />
            {showCopyFile && (
              <Button
                type={ButtonType.SECONDARY}
                size={ButtonSize.NARROW}
                toolTipText={t('requirements_copy_file')}
                onClick={() => onFileCopy && onFileCopy(version)}
              >
                <FileCopy className={'fill-blue-root'} />
              </Button>
            )}
            <Button
              type={ButtonType.SECONDARY}
              size={ButtonSize.NARROW}
              toolTipText={t('download_file')}
              onClick={() => openFile(version.file_url, version.name)}
            >
              <div className="flex items-center gap-1">
                <Download className={'fill-blue-root'} />
                <span className="leading-3">{fileType}</span>
              </div>
            </Button>
            {fileType === 'pdf' && (
              <Button
                type={ButtonType.SECONDARY}
                size={ButtonSize.NARROW}
                toolTipText={t('add_comment_to_file')}
                onClick={() => {
                  const params = { folderId, documentName }
                  history.push(
                    `/fileRevisionHighlight/${fileContainerId}/${version.project_file_id}?${stringify(params)}`,
                  )
                }}
              >
                <div className="flex items-center gap-1">
                  <Draw className={'fill-blue-root'} />
                  <span className="leading-3">{t('comments')}</span>
                </div>
              </Button>
            )}
            {onDeleteFile && (
              <Button
                type={ButtonType.SECONDARY}
                size={ButtonSize.NARROW}
                toolTipText={t('delete_file')}
                onClick={() => setFileToBeDeleted(version)}
              >
                <Close className={'fill-blue-root'} />
              </Button>
            )}
          </div>
        )}
      </div>
    )
  }

  const onUrlSubmit = async (link: string) => {
    if (!fileContainerId) return
    await uploadUsingLink(projectId, fileContainerId, link)
    setUploadLinkModal(false)
    onNewUpload?.()
  }

  const uploadFile = (e: any) => {
    const target = e.target.getBoundingClientRect()
    setPos({
      left: target.x,
      top: target.top + 30,
    })
    setShowImportPopUp(true)
  }

  return (
    <>
      <div className={styleClass.root}>
        {!block && <p className={styleClass.title}>{t('uploads')}</p>}
        <div className={styleClass.right}>
          {versions && versions?.length > 0 && versions?.map(FileOrLink)}
          {onNewUpload && (
            <PlusButton
              text={t('upload_new_file')}
              onClick={uploadFile}
              className={'mb-3'}
            />
          )}
        </div>
      </div>

      {importPopUp && onNewUpload && (
        <UploadPopUp
          show={importPopUp}
          close={() => setShowImportPopUp(false)}
          pos={importPos}
          importFiles={() => setShowUpload(true)}
          addLink={() => setUploadLinkModal(true)}
        />
      )}
      {showUpload && (
        <FolderFilesUpload
          show={showUpload}
          close={() => onNewUpload?.()}
          setMovedSteps={() => {}}
          folderId={fileContainerId ?? -1}
          revisionUpload={true}
        />
      )}
      {showFileLink && (
        <FileLinkUploadModal
          onSubmit={onUrlSubmit}
          show={showFileLink}
          close={() => setUploadLinkModal(false)}
        />
      )}
      {fileToBeDeleted && (
        <DeleteModal
          customTitle={t('are_you_sure_you_want_to_delete_with_param', {
            item: fileToBeDeleted.name ?? '',
          })}
          onDelete={() => {
            removeFile(fileToBeDeleted.project_file_id)
            setFileToBeDeleted(null)
          }}
          show={fileToBeDeleted !== null}
          closeModal={() => setFileToBeDeleted(null)}
          itemName={fileToBeDeleted.name ?? ''}
        />
      )}
    </>
  )
}

export default HistoryFiles
