import { useTranslation } from 'react-i18next'
import Button from 'src/ui-elements/button/Button'
import { capFirstLetter } from '../../utility/utils'

interface IBottomActionBtns {
  onSave?: (e?: any) => void
  onCancel?: () => void
}

const BottomActionBtns = ({ onSave, onCancel }: IBottomActionBtns) => {
  const { t } = useTranslation()

  return (
    <div
      className={
        'px-6 bg-mobile absolute left-0 bottom-0 py-2 z-50 w-full border border-t shadow-md'
      }
    >
      <div className={'flex justify-between'}>
        <Button
          width={'max(120px, 36vw)'}
          onClick={onCancel}
          inMobile={true}
          type={Button.ButtonType.WARNING}
          size={Button.ButtonSize.SMALL}
        >
          &#x2715;&nbsp;{t('cancel')}
        </Button>
        <Button
          width={'max(120px, 36vw)'}
          onClick={onSave}
          inMobile={true}
          type={Button.ButtonType.SUCCESS}
          size={Button.ButtonSize.SMALL}
        >
          {capFirstLetter(t('save'))}&nbsp;&#x2713;
        </Button>
      </div>
    </div>
  )
}

export default BottomActionBtns
