import { PropsWithChildren, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteModalBody } from 'src/components/delete-modal/DeleteModal'
import ConfirmDialogContext from 'src/context/ConfirmModalContext'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface DeleteModalProps {
  itemName?: string
  itemIdnType?: string
  customTitle?: string
  hideDeleteBtn?: boolean
  disableDeleteBtn?: boolean
  isBulk?: boolean
}

export const useDeleteModal = () => {
  const { showConfirmation } = useContext(ConfirmDialogContext)
  const { t } = useTranslation()

  const confirmDelete = ({
    itemName,
    itemIdnType,
    customTitle,
    hideDeleteBtn,
    disableDeleteBtn,
    isBulk,
    children,
  }: PropsWithChildren<DeleteModalProps> = {}) => {
    const deleteModalBody = (
      <DeleteModalBody itemName={itemName} isBulk={isBulk}>
        {children}
      </DeleteModalBody>
    )

    return showConfirmation({
      title: customTitle
        ? customTitle
        : t('confirm_deletion_of', {
            item: `${itemIdnType ? itemIdnType : ''}`,
          }),
      body: deleteModalBody,
      confirmText: t('delete'),
      confirmButtonType: ButtonType.DANGER,
      hideConfirmButton: hideDeleteBtn,
      disableConfirmButton: disableDeleteBtn,
    })
  }

  return { confirmDelete }
}
