export const trainListExportTemplate = (url: string) => {
  return {
    geniusApiRequest: {
      url: `{organizationApiBaseUrl}${url}/construction_trains/pages/1/1000`,
      method: 'POST',
    },
    name: 'Trains',
    displayName: 'Eksport av tog',
    columns: [
      'Id',
      'Tittel',
      'Startdato',
      'Taktområde',
      'Opprettet',
      'Sist endret',
    ],
    rows: [
      'record_id',
      'title',
      'startTime',
      'control_area.title',
      'created_at',
      'updated_at',
    ],
  }
}
