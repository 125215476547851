import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import useAlert from 'src/ui-elements/toast/useAlert'
import Badge from '../../ui-elements/badge/Badge'
import { BadgeColor, BadgeSize } from '../../ui-elements/badge/BadgeEnums'
import { learningTipSeen } from '../../ui-elements/tooltip/LearningTip'
import CloseClickOutside from '../click-outside/CloseClickOutside'

export interface IContextMenuItem {
  icon?: Icons
  title: string
  active: boolean
  onItemClick: (id: number) => void
  disableMessage?: string
  subMenu?: IContextMenuItem[]
  hidden?: boolean
  isNew?: boolean
}

export interface IGroupContextMenu {
  groupId: string
  menus: IContextMenuItem[]
}
export interface IContextMenuProps {
  menus: IContextMenuItem[]
  groupedMenu?: IGroupContextMenu[]
  pos: {
    left: number | undefined
    right?: number | undefined
    top: number | undefined
    bottom?: number | undefined
  }
  show: boolean
  closeMenu: () => void
  itemWidth?: number
  minWidth?: string
}

const ContextMenu = ({
  menus,
  pos,
  show,
  closeMenu,
  groupedMenu,
  itemWidth: itemWidth,
  minWidth,
}: IContextMenuProps) => {
  const { t } = useTranslation()
  const { addAlert } = useAlert()
  const menuLength = groupedMenu
    ? groupedMenu.map((gr) => gr.menus.length).reduce((prev, cur) => prev + cur)
    : menus.length
  const [subMenu, setSubMenu] = useState<IContextMenuItem[]>([])
  const [subMenuPos, setSubMenuPos] = useState<{ left: number; top: number }>({
    left: 0,
    top: 0,
  })
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false)

  const showAlert = (errorMessage: string) => {
    addAlert({
      type: 'warning',
      title: t('can_not_be_clicked'),
      description: errorMessage,
    })
  }

  const onMenuItemClicked = (itemClick: any) => {
    itemClick()
    closeMenu()
  }

  const handleDisabledClick = (e: any, errMessage?: string) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    errMessage && showAlert(errMessage)
  }

  const onMouseEnter = (e: any, submenus?: IContextMenuItem[]) => {
    if (!submenus) {
      return
    }
    const target = e.target.getBoundingClientRect()
    const tmpFloatLabel = { ...subMenuPos }
    tmpFloatLabel.left = target.right
    tmpFloatLabel.top = target.top
    setSubMenuPos(tmpFloatLabel)
    setSubMenu(submenus || [])
    setShowSubMenu(true)
  }

  const renderMenu = (options: IContextMenuItem[]) => {
    return options.map(
      (
        {
          onItemClick,
          title,
          active,
          disableMessage,
          icon,
          hidden = false,
          subMenu: submenus,
          isNew,
        },
        index,
      ) => {
        const newFeature = !learningTipSeen(title) && isNew
        const activeProps = active !== null ? active : true
        return !hidden ? (
          <div
            onMouseEnter={(e) => onMouseEnter(e, submenus)}
            onMouseLeave={() => setShowSubMenu(!!submenus)}
            className={twMerge(
              activeProps
                ? 'contextmenu-button'
                : 'contextmenu-button-disabled',
            )}
            key={index}
            onClick={(e) =>
              activeProps
                ? onMenuItemClicked(onItemClick)
                : handleDisabledClick(e, disableMessage)
            }
          >
            {icon && (
              <Icon icon={icon} className={'w-4 h-4 contextmenu-icon'} />
            )}
            <span className="first-capitalize"> {title}</span>
            {newFeature && (
              <Badge
                text={t('new')}
                color={BadgeColor.BLUE}
                size={BadgeSize.SMALL}
                className={'ml-3'}
              />
            )}
            {!!submenus && (
              <span style={{ marginRight: '-15px' }} className={'ml-auto'}>
                <Icon className="w-4 h-4" icon={Icon.IconType.AFTER} />
              </span>
            )}
          </div>
        ) : null
      },
    )
  }

  const renderGroupMenu = () => {
    return (
      groupedMenu &&
      groupedMenu.map(({ groupId, menus: options }, index) => {
        return [
          ...renderMenu(options),
          index < groupedMenu.length - 1 && (
            <div key={groupId} className="contextmenu-separator" />
          ),
        ]
      })
    )
  }

  const getPos = (): {
    left: number | undefined
    right?: number | undefined
    top: number | undefined
  } => {
    const ret = { ...pos }
    if (pos.left && window.innerWidth < pos.left + 200) {
      ret.left = pos.left - (itemWidth || 0) - 200
    }

    if (pos.right) {
      ret.right = pos.right
    }

    if (pos?.bottom && window.innerHeight < pos?.bottom + menuLength * 30) {
      ret.top = pos?.bottom - menuLength * 30
    }

    if (pos?.top && window.innerHeight < pos?.top + menuLength * 30) {
      ret.top = pos?.top - menuLength * 30
    }
    delete ret.bottom
    return ret
  }

  return (
    <>
      {show ? (
        <CloseClickOutside onClose={closeMenu}>
          <div className={`contextmenu ${minWidth}`} style={getPos()}>
            {groupedMenu ? renderGroupMenu() : renderMenu(menus)}
          </div>
        </CloseClickOutside>
      ) : null}
      {showSubMenu && (
        <div onMouseLeave={() => setShowSubMenu(false)}>
          <ContextMenu
            menus={subMenu}
            pos={subMenuPos}
            show={showSubMenu}
            closeMenu={() => setShowSubMenu(false)}
          />
        </div>
      )}
    </>
  )
}

export default ContextMenu
