import * as React from 'react'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import { ISystemType } from '../../../service/OrgTypes'
import { getCategoryMatchingSystemTypes } from '../../../service/SystemService'
import Button from '../../../ui-elements/button/Button'
import List from '../../../ui-elements/list/List'
import { IListFilter } from '../../../ui-elements/list/ListContextProvider'
import { ISorting } from '../../../ui-elements/list/ListTypes'
import Modal from '../../../ui-elements/modal/Modal'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import { capFirstLetter, constructFilterJson } from '../../../utility/utils'
import { columns } from './ListColumns'

interface ISystemsTypesSelector {
  categoryId: number
  categoryCode: string
  categoryName: string
  closeModal: () => void
  onSubmit: (data: number[]) => void
  showModal: boolean
}

const SystemsTypesSelector: React.FC<ISystemsTypesSelector> = ({
  categoryId,
  categoryCode,
  categoryName,
  showModal,
  closeModal,
  onSubmit,
}) => {
  const pageSize = 30
  const [systemTypes, setSystemTypes] = useState<ISystemType[]>([])
  const [pages, setPages] = useState<number>(1)
  const [selectedSystemTypeIds, setSelectedSystemTypeIds] = useState<number[]>(
    [],
  )

  const { state } = useContext(ProjectContext)
  const projectId = state.currentProject.id
  const { t } = useTranslation()

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    getCategoryMatchingSystemTypes(
      projectId,
      categoryId,
      page,
      pageSize,
      activeFilters,
    ).then((res) => {
      setSystemTypes(res.system_types)
      setPages(res.pages)
    })
  }

  const onSave = () => {
    onSubmit(selectedSystemTypeIds)
    setSelectedSystemTypeIds([])
  }

  return (
    <Modal
      show={showModal}
      title={`${t(
        'system_types_matching_category',
      )} ${categoryName} (${categoryCode})`}
      closeModal={closeModal}
      maxWidth={1600}
    >
      <List
        columns={columns()}
        disableConfigStore={true}
        disableColumnSelector={true}
        data={systemTypes}
        tableName={'systemsTypesSelector'}
        itemsPerPage={pageSize}
        pagination={true}
        totalPages={pages}
        selectedRows={selectedSystemTypeIds}
        onSelectRow={(data) => setSelectedSystemTypeIds(data)}
        isRowSelectable={true}
        sortBackend={true}
        getFilteredData={getFilteredData}
      />
      <ModalFooter className={'mt-4'}>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        <Button type={Button.ButtonType.PRIMARY} onClick={onSave}>
          {capFirstLetter(t('add'))}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SystemsTypesSelector
