import Assignment from '@icons/assignment.svg'
import Comment from '@icons/comment.svg'
import Event from '@icons/event.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import VpnKey from '@icons/vpn_key.svg'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import InspectorSections from 'src/document/components/Inspector/InspectorSections'
import { useImprovement } from 'src/query/improvements'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import ImprovementDeliveriesSectionTable from './ImprovementDeliveriesSectionTable'
import ImprovementForm from './ImprovementForm'
import ImprovementKeypointsSectionTable from './ImprovementKeypointsSectionTable'
import ImprovementTasksSectionTable from './ImprovementTasksSectionTable'

interface ImprovementPanelProps {
  projectId: number
  improvementId: number
  onClose: () => void
  editMode?: boolean
  show: boolean
  newStatus?: string
  newPosition?: number
  onReload?: () => void
  onCancelEdit?: () => void
  notFound?: (id: number) => void
  defaultIndex?: number
}

const ImprovementPanel = ({
  projectId,
  improvementId,
  onClose,
  editMode,
  show,
  newStatus,
  newPosition,
  onReload,
  onCancelEdit,
  notFound,
  defaultIndex,
}: ImprovementPanelProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { data: improvement, isPending } = useImprovement(improvementId)

  const reload = useCallback(async () => {
    onReload && onReload()
    queryClient.invalidateQueries({
      queryKey: ['improvement', improvementId],
    })
  }, [improvementId, onReload, queryClient])

  if (!improvement) {
    if (!isPending && notFound) {
      notFound(improvementId)
    }
    return null
  }

  return (
    <FixedPane
      title={
        <span className="w-120 block truncate">
          {`${improvement.record_id} - ${improvement.title}`}
        </span>
      }
      show={show}
      onClose={onClose}
      className={'w-[700px]'}
      noMainContent
    >
      <div className="h-full flex flex-col">
        <ImprovementForm
          improvement={improvement}
          projectId={projectId}
          newStatus={newStatus}
          newPriority={newPosition}
          editMode={editMode}
          onUpdate={reload}
          onCancelEdit={onCancelEdit}
        />
        <InspectorSections
          defaultIndex={defaultIndex}
          sections={[
            {
              name: t('comments'),
              icon: <Comment />,
              content: (
                <Comments parentType="Improvement" parentId={improvementId} />
              ),
            },
            {
              name: t('change_log'),
              icon: <LegendToggle />,
              content: (
                <ChangeLog parentType="Improvement" parentId={improvementId} />
              ),
            },
            {
              name: t('keypoint'),
              icon: <VpnKey />,
              content: (
                <ImprovementKeypointsSectionTable
                  improvementId={improvementId}
                  projectId={projectId}
                />
              ),
            },
            {
              name: t('delivery'),
              icon: <Event />,
              content: (
                <ImprovementDeliveriesSectionTable
                  improvementId={improvementId}
                  projectId={projectId}
                />
              ),
            },
            {
              name: t('cases'),
              icon: <Assignment />,
              content: (
                <ImprovementTasksSectionTable
                  improvement={improvement}
                  projectId={projectId}
                />
              ),
            },
          ]}
        />
      </div>
    </FixedPane>
  )
}

export default ImprovementPanel
