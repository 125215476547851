import { FC, useContext, useEffect, useCallback, useState } from 'react'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { FileUploadContext } from '../context/FileUploadContext'
import { IUploadResponse, IUploadState, UPLOAD_STATE } from '../types'
import FileUploadProgress from './FileUploadProgress'

interface IUploadStatusProps {
  fileInfo: File
}

const UploadStatus: FC<IUploadStatusProps> = ({ fileInfo }) => {
  const [uploadInfo, setUploadInfo] = useState<IUploadState | null>()
  const [uploadRes, setUploadRes] = useState<IUploadResponse | null>(null)

  const { files, cancelFile, uploadedFiles } = useContext(FileUploadContext)
  const { t } = useMultipleKeysTranslation()

  const loadFileInfo = useCallback(() => {
    const info = files[fileInfo.name]
    if (info) {
      setUploadInfo(info)
    }
  }, [fileInfo.name, files])

  const loadUploadRes = useCallback(() => {
    const res = uploadedFiles[fileInfo.name]
    if (res) {
      setUploadRes(res)
    }
  }, [fileInfo.name, uploadedFiles])

  useEffect(() => {
    loadFileInfo()
  }, [loadFileInfo])

  useEffect(() => {
    loadUploadRes()
  }, [loadUploadRes])

  const onCancel = () => {
    cancelFile(fileInfo)
  }

  // check status of file
  const status = () => {
    if (!uploadInfo) return <></>
    switch (uploadInfo.uploadStatus.status) {
      case UPLOAD_STATE.UPLOADING:
        return <FileUploadProgress onCancel={onCancel} fileInfo={uploadInfo} />
      case UPLOAD_STATE.UPLOADED:
        return uploadRes ? (
          uploadRes.file_container_id ? (
            <div className="flex items-center text-sm">
              <MaterialIcon
                outline={true}
                icon="task_alt"
                className="text-d-green text-sm  mx-1"
              />
              {t('completed')}
            </div>
          ) : (
            <div className="flex items-center">
              <MaterialIcon
                outline={true}
                icon="error"
                className="text-d-danger mx-1 text-sm"
              />
              {t(['error', 'document', 'was_not_found'], {
                extraChars: { 0: ':' },
              })}
            </div>
          )
        ) : (
          <span className="text-gray-500"> {t('completed')}</span>
        )
      case UPLOAD_STATE.ERROR:
        const { error } = uploadInfo.uploadStatus

        return (
          <span className="text-red-danger overflow-scroll">
            <div className="flex items-center">
              <MaterialIcon
                outline={true}
                icon="error"
                className="text-sm mx-1"
              />
              {error?.length ? error : t('couldn_t_upload_file')}
            </div>
          </span>
        )
      default:
        return <></>
    }
  }

  return status()
}

export default UploadStatus
