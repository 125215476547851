import Attachment from '@icons/attachment.svg'
import Close from '@icons/close.svg'
import Send from '@icons/send-fill.svg'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validateFiles } from 'src/document/components/FileUpload/foldersFileUpload/FolderFilesUpload'
import ResolutionHandler from 'src/service/ResolutionHandler'
import IconButton from 'src/ui-elements/button/IconButton'
import MentionTextArea from 'src/ui-elements/textarea/MentionTextArea'
import { classNames, isImageType } from 'src/utility/utils'
import CommentFilesList, { CommentFileItem } from './CommentFilesList'
import EmojiPickerButton from './EmojiPickerButton'

export interface FileItem {
  documentId?: number
  name: string
  imageUrl?: string
  file?: File
  type: string
}

interface CommentInputProps {
  value?: string
  onSave: (comment: string, files: FileItem[]) => void
  onCancel?: () => void
  disabled?: boolean
  existingFiles?: FileItem[]
  editMode?: boolean
  className?: string
  hideFileAttachments?: boolean
}

const CommentInput = ({
  value,
  onSave,
  onCancel,
  disabled = false,
  existingFiles = [],
  editMode = false,
  className = '',
  hideFileAttachments = false,
}: CommentInputProps) => {
  const { t } = useTranslation()

  const [focused, setFocused] = useState(editMode || false)
  const [inputValue, setInputValue] = useState(value || '')
  const [files, setFiles] = useState<FileItem[]>(existingFiles || [])

  const inputFile = useRef<HTMLInputElement | null>(null)

  const saveComment = () => {
    onSave(inputValue, files)
    setInputValue('')
    setFiles([])
    setFocused(false)
  }

  const multiLine = editMode || focused || files.length || inputValue
  const isSubmittable =
    inputValue || files.length || !validateFiles(files).error
  const isMobile = ResolutionHandler.shouldShowMobileVersion()

  const removeFile = (file: FileItem) => {
    const newFiles = files.filter((f) => f.name !== file.name)
    setFiles(newFiles)
  }

  return (
    <div className={classNames(className)}>
      <MentionTextArea
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={
          isMobile && !multiLine
            ? t('write_a_comment')
            : t('write_a_comment_at_to_tag')
        }
        singleLine={!multiLine}
        autoFocus={focused}
        onBlur={() => !isSubmittable && setFocused(false)}
        onClick={() => setFocused(true)}
        onKeyDown={(e) => {
          if (isMobile) {
            return
          }
          if (e.key === 'Enter' && !e.shiftKey && isSubmittable) {
            e.preventDefault()
            saveComment()
          }
          if (e.key === 'Escape') {
            if (onCancel) {
              onCancel()
            } else {
              !isSubmittable && setFocused(false)
            }
          }
        }}
        noBorder
        allowSuggestionsAboveCursor
        className="rounded-2xl"
        backgroundColor="white"
        disabled={disabled}
      />

      {files.length > 0 && (
        <CommentFilesList
          files={files}
          editMode
          onRemoveFile={removeFile}
          className="mx-3 my-4"
        />
      )}
      {multiLine && onCancel && (
        <IconButton
          className="absolute right-2.5 top-2 fill-gray-600 bg-white bg-opacity-90"
          onClick={onCancel}
          icon={<Close />}
          tooltipText="Cancel"
        />
      )}

      <div
        className={classNames(
          'absolute right-2 flex gap-1',
          multiLine ? 'bottom-1' : 'top-0.5',
        )}
        onMouseDown={(e) => e.preventDefault()}
      >
        {!isMobile && (
          <EmojiPickerButton
            onSelect={(emoji) =>
              setInputValue((inputValue) => inputValue + emoji)
            }
          />
        )}
        {!hideFileAttachments && (
          <IconButton
            className="fill-gray-800"
            size="large"
            onClick={() => inputFile?.current?.click()}
            icon={<Attachment />}
            tooltipText="Attach file"
          />
        )}
        <IconButton
          className="fill-blue-500"
          size="large"
          icon={<Send />}
          onClick={saveComment}
          disabled={!isSubmittable}
          tooltipText="Save"
        />
      </div>

      <input
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        multiple
        onChange={(e) => {
          if (e.target.files) {
            const newFiles: CommentFileItem[] = Array.from(e.target.files)
              .filter(
                (file) =>
                  !files.some(
                    (existingFile) => existingFile.name === file.name,
                  ),
              )
              .map((file) => ({
                name: file.name,
                url: isImageType(file.type)
                  ? URL.createObjectURL(file)
                  : undefined,
                file,
                type: file.type,
                error: validateFiles([file]).message,
              }))
            setFiles([...files, ...newFiles])
          }
          e.target.value = ''
        }}
      />
    </div>
  )
}

export default CommentInput
