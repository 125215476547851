import { PropsWithChildren, FC, useContext } from 'react'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import TopMenu from './internals/TopMenu'

const ReadOnlyMenu: FC<PropsWithChildren<object>> = ({ children }) => {
  const projectContext = useContext(ProjectContext)

  const { currentProject, projects } = projectContext.state

  return (
    <div className="root-parent compact sidebar-hidden">
      <div className="header">
        <TopMenu
          hideSearch={true}
          readonly={true}
          projects={projects}
          currentProject={currentProject}
          mainMenuActive={false}
          toggleTopMenu={() => false}
          topMenuExpanded={false}
          toggleMenuVisibility={() => false}
          menuHidden={false}
        />
      </div>

      <div id="navigation-row" />

      <div className={`main-content`}>{children}</div>
    </div>
  )
}

export default ReadOnlyMenu
