import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import DocListFileCount from 'src/document/components/FolderDocument/DocListFileCount'
import { ITestExecution, TestApprovalStatus } from 'src/service/OrgTypes'
import { deleteTestExecution, getTestExecutions } from 'src/service/TestService'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import { classNames } from '../../../utility/utils'
import TestApprovalCount from '../approval/TestApprovalCount'
import TestExecutionInspectorPanel from '../fixed-panel/TestExecutionFixedPanel'

const styleClass = {
  root: classNames('w-full'),
  circle: classNames(
    'mx-2 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border grid place-items-center',
  ),
}

interface ITestExecutionProps {
  testId: number
  onOpenExecution: (id: number) => void
  isTestOwner: boolean
}

export enum TestExecutionStatus {
  NOT_EXECUTED = 'not_executed',
  PASSED = 'passed',
  PASSED_WITH_ISSUE = 'passed_with_issue',
  FAILED = 'failed',
  SET_UP = 'set_up',
  IN_PROGRESS = 'in_progress',
  PLANNING = 'planning',
  SIGNING = 'signing',
}

export const isFinalStatus = (value: string): boolean => {
  switch (value) {
    case TestExecutionStatus.NOT_EXECUTED:
    case TestExecutionStatus.PASSED:
    case TestExecutionStatus.PASSED_WITH_ISSUE:
    case TestExecutionStatus.FAILED:
      return true
    case TestExecutionStatus.SET_UP:
    case TestExecutionStatus.PLANNING:
    case TestExecutionStatus.IN_PROGRESS:
    case TestExecutionStatus.SIGNING:
    default:
      return false
  }
}

export const badgeColor = (value: string): BadgeColor => {
  switch (value) {
    case TestExecutionStatus.IN_PROGRESS:
    case TestExecutionStatus.SIGNING:
      return BadgeColor.BLUE
    case TestExecutionStatus.PASSED:
      return BadgeColor.GREEN
    case TestExecutionStatus.PASSED_WITH_ISSUE:
      return BadgeColor.ORANGE
    case TestExecutionStatus.FAILED:
      return BadgeColor.RED
    case TestExecutionStatus.NOT_EXECUTED:
    case TestExecutionStatus.SET_UP:
    case TestExecutionStatus.PLANNING:
    default:
      return BadgeColor.DEFAULT
  }
}

const TestExecutionList = ({
  testId,
  onOpenExecution,
  isTestOwner,
}: ITestExecutionProps) => {
  const { t } = useTranslation()
  const [testExecutions, setTestExecutions] = useState<ITestExecution[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedTestExecutionId, setSelectedTestExecutionId] = useState<
    number | undefined
  >(undefined)
  const [showFixedPanel, setShowFixedPanel] = useState(false)

  const projectContext = useContext(ProjectContext)

  const projectId = projectContext.state.currentProject.id

  const loadTestExecutions = () => {
    getTestExecutions(projectId, testId).then((res) => {
      setTestExecutions(res)
    })
  }

  useEffect(() => {
    loadTestExecutions()
  }, [testId])

  const columns: IListColumns[] = [
    {
      name: 'id',
      size: '40',
      id: 'recordId',
      dataField: 'record_id',
    },
    {
      name: 'planned',
      size: '90',
      id: 'test_planned',
      dataField: 'test_planned',
      cell: (date: any) => <span>{date ? moment(date).format('L') : ''}</span>,
    },
    {
      name: 'started',
      size: '90',
      id: 'test_started',
      dataField: 'test_started',
      cell: (date: any) => <span>{date ? moment(date).format('L') : ''}</span>,
    },
    {
      name: 'completed',
      size: '90',
      id: 'test_ended',
      dataField: 'test_ended',
      cell: (date: any) => <span>{date ? moment(date).format('L') : ''}</span>,
    },
    {
      name: 'status',
      size: '120',
      id: 'status',
      dataField: 'status',
      cell: (status: string, _id: number) => (
        <Badge
          size={Badge.BadgeSize.LARGE}
          text={t(status)}
          color={badgeColor(status)}
        />
      ),
    },
    {
      name: 'attachment',
      size: '100',
      id: 'documents',
      dataField: 'documents',
      cell: (documents: any) => (
        <div className="flex gap-3 py-2">
          <DocListFileCount
            projectFiles={documents ?? []}
            urlField={'fileUrl'}
          />
        </div>
      ),
    },
    {
      name: 'approved_by',
      size: '100',
      id: 'user_approval',
      dataField: 'user_approval',
      cell: (approval: TestApprovalStatus | undefined, id: number) => (
        <TestApprovalCount
          projectId={projectId}
          executionId={id}
          approval={approval}
        />
      ),
    },
    {
      name: 'closed',
      size: '80',
      id: 'closed',
      dataField: 'step',
      cell: (step) => <span>{step === 'closed' ? t('yes') : t('no')}</span>,
    },
    {
      name: 'closed_date',
      size: '100',
      id: 'closed_date',
      dataField: 'closed_date',
      cell: (date) => <span>{date ? moment(date).format('L') : ''}</span>,
    },
    {
      name: 'issues',
      size: '70',
      id: 'result',
      dataField: 'test_case_executions',
      cell: (data) => (
        <span className={styleClass.circle}>
          {data.reduce((sum: number, d: { tasks: any[] }) => {
            return d?.tasks?.length + sum
          }, 0)}
        </span>
      ),
    },
    {
      name: 'comment',
      size: '300',
      id: 'comment',
      dataField: 'comment',
    },
    {
      name: 'created_at',
      size: '120',
      id: 'createdAt',
      dataField: 'created_at',
      hidden: true,
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '120',
      id: 'updatedAt',
      dataField: 'updated_at',
      hidden: true,
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const removeTestExecution = () => {
    if (selectedTestExecutionId) {
      deleteTestExecution(selectedTestExecutionId).then(() => {
        setShowDeleteModal(false)
      })
    }
  }

  const onDeleteItemClick = (row: ITestExecution) => {
    setShowDeleteModal(!showDeleteModal)
    setSelectedTestExecutionId(row.id)
  }

  const onRowClick = (data: ITestExecution) => {
    if (data.id) {
      onOpenExecution(data.id)
      setSelectedTestExecutionId(data.id)
      setShowFixedPanel(true)
    }
  }
  return (
    <>
      <div className={styleClass.root}>
        <List
          columns={columns}
          data={testExecutions}
          tableName={'TestExecutionTable'}
          itemsPerPage={0}
          selectedRows={
            selectedTestExecutionId ? [selectedTestExecutionId] : []
          }
          pagination={true}
          disableColumnSelector={true}
          onRowClick={onRowClick}
          onRowGrayOut={(data?: any) => !!data.approved}
          actionMenu={
            isTestOwner
              ? [
                  {
                    name: t('delete'),
                    action: onDeleteItemClick,
                  },
                ]
              : undefined
          }
        />

        {selectedTestExecutionId && (
          <DeleteModal
            customTitle={t('are_you_sure_you_want_to_delete_with_param', {
              item: t('test_execution'),
            })}
            show={showDeleteModal}
            closeModal={closeDeleteModal}
            onDelete={removeTestExecution}
            itemName={t('test_completion')}
          />
        )}
      </div>
      {showFixedPanel && selectedTestExecutionId && (
        <TestExecutionInspectorPanel
          testId={testId}
          testExecutionId={selectedTestExecutionId}
          open={showFixedPanel}
          onClose={() => setShowFixedPanel(false)}
          onUpdate={() => {}}
          isTestOwner={isTestOwner}
        />
      )}
    </>
  )
}

export default TestExecutionList
