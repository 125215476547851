import { useQuery } from '@tanstack/react-query'
import { mapValues } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { IFileDate } from 'src/components/explorer/DropDownExplorer'
import DropDownExplorerStorageHandler from 'src/components/explorer/DropDownExplorerStorageHandler'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { queryClient } from 'src/query/client'
import { IBreadCrumb } from 'src/ui-elements/breadcrumb/BreadCrumb'
import { numberOrUndefined } from 'src/utility/utils'

/**
 * Function which encapsulates tree logic shared by all trees. Handles syncing open nodes, breadcrumbs, and active elements with the url.
 * @param routePrefixes The prefixes separating each key in the url. For example ['milestone', 'keypoint', 'delivery', 'task']
 * @param getData Function to fetch the tree data. Reload is used to force reload cached trees
 * @param storageHandlerKey The key under which tree config data is stored in tablekeeper.
 * @param onClick Optional function which is called when a tree element is clicked
 * @param reloadAfterMount
 */
const useTree = (
  routePrefixes: string[],
  getData: (projectId: number, reload?: boolean) => Promise<any>,
  storageHandlerKey: string,
  onClick?: () => void,
  reloadAfterMount?: boolean,
) => {
  const history = useHistory()
  const location = useLocation()
  const { path: basePath } = useRouteMatch()
  const [activeKey, setActiveKey] = useState('1')
  const [socketLoading, setSocketLoading] = useState(false)
  const [openNodes, setOpenNodes] = useState<string[]>(['1'])
  const projectContext = useContext(ProjectContext)
  const { projectName, id: projectId } = projectContext.state.currentProject
  const [treeWidth, setTreeWidth] = useState<number>(
    DropDownExplorerStorageHandler.readConfig(storageHandlerKey).width,
  )
  const [breadCrumbsList, setBreadCrumbList] = useState<IBreadCrumb[]>([
    {
      index: 0,
      text: projectName,
      link: basePath,
    },
  ])

  const reloadCache = useRef(false)

  const routes: string[] = routePrefixes.map((prefix, i) =>
    routePrefixes
      .slice(0, i + 1)
      .reduce(
        (prev, prefix, i) =>
          `${prev}/${prefix}/:id${i}${
            i === routePrefixes.length - 1 ? '+' : ''
          }`,
        basePath,
      ),
  )
  const match = useRouteMatch(routes.reverse())
  const params = mapValues(match?.params ?? {}, numberOrUndefined)

  const {
    data: data = [],
    isFetching,
    isPending,
    isFetchedAfterMount,
  } = useQuery<IFileDate[], Error>({
    refetchOnWindowFocus: false,
    queryKey: [
      ...routePrefixes,
      projectId,
      projectName,
      reloadCache.current,
      reloadAfterMount,
    ],
    queryFn: async () => {
      const shouldReloadCache = reloadCache.current
      reloadCache.current = false
      const res = await getData(projectId, shouldReloadCache)
      if (reloadAfterMount) {
        setSocketLoading(res == null)
      }
      return [
        {
          icon: 'folder',
          iconClass: 'material-symbols-outlined text-gray-400',
          key: `1`,
          label: projectName,
          nodes: res ?? [],
          numbers: '',
        },
      ]
    },
  })

  const reloadTree = () => {
    reloadCache.current = true
    queryClient.invalidateQueries({
      queryKey: [...routePrefixes, projectId, projectName],
    })
  }

  const loadTree = () => {
    queryClient.invalidateQueries({
      queryKey: [...routePrefixes, projectId, projectName],
    })
  }

  useEffect(() => syncTree(data[0]), [location.pathname, data])

  useDidMountEffect(() => history.push(basePath), [projectId])

  const syncTree = (resData: IFileDate | undefined) => {
    const paramslist: string[] = Object.values(match?.params ?? {})
    const lastParam: string | undefined = paramslist.slice(-1)[0]
    if (!lastParam) {
      setActiveKey('1')
      setOpenNodes(['1'])
      setBreadCrumbList([
        { index: 0, text: resData?.label ?? '', link: basePath },
      ])
      return
    }
    const keys = [
      ...paramslist.slice(0, -1),
      ...(lastParam.includes('/') ? lastParam.split('/') : [lastParam]),
    ]
    const allKeys = ['1', ...keys]
    setActiveKey(allKeys.join('/'))
    addOpenNodes(allKeys)
    setBreadCrumbList(recursivelyCreateBreadcrumbs(allKeys, data, []))
  }

  const addOpenNodes = (keys: string[]) => {
    const openNodesToAdd = keys.map((_, i) => keys.slice(0, i + 1).join('/'))
    setOpenNodes(openNodesToAdd)
  }

  const recursivelyCreateBreadcrumbs = (
    keys: string[],
    nodes: IFileDate[],
    breadcrumbs: IBreadCrumb[],
  ): IBreadCrumb[] => {
    const node = nodes.find((node) => String(node.key) === keys[0])
    if (!node) return breadcrumbs
    const index = breadcrumbs.length
    const link =
      breadcrumbs.length === 0
        ? basePath
        : `${breadcrumbs.slice(-1)[0].link}${
            index > routePrefixes.length ? '' : '/' + routePrefixes[index - 1]
          }/${keys[0]}`
    const breadcrumb: IBreadCrumb = {
      index: index,
      text: node.label,
      link: link,
    }
    return recursivelyCreateBreadcrumbs(keys.slice(1), node.nodes ?? [], [
      ...breadcrumbs,
      breadcrumb,
    ])
  }

  const onItemClick = (item: IFileDate) => {
    const keys = item.key.split('/') ?? Array(item.level).fill(0)
    const targetUrl = routePrefixes
      .slice(0, item.level)
      .reduce(
        (prev, routePrefix, i) => `${prev}/${routePrefix}/${keys[i + 1]}`,
        basePath,
      )
    let appendUrl = ''
    if (item.level > routePrefixes.length) {
      appendUrl = keys.slice(routePrefixes.length + 1).join('/')
    }
    history.push(targetUrl + '/' + appendUrl)
    setActiveKey(keys.join('/'))
    onClick?.()
  }

  return {
    projectId,
    onItemClick,
    activeKey,
    openNodes,
    breadCrumbsList,
    syncTree,
    data,
    loading:
      isPending ||
      (isFetchedAfterMount && isFetching) ||
      (reloadAfterMount && (isFetching || socketLoading)),
    reloadTree,
    loadTree,
    treeWidth,
    setTreeWidth,
    params,
    basePath,
  }
}

export default useTree
