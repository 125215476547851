import 'react-toggle/style.css'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ActivityLogItemBody from 'src/components/activity-log/ActivityLogItemBody'
import ActivityLogItemHeader from 'src/components/activity-log/ActivityLogItemHeader'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import history from 'src/history'
import { useActivityLogsForType } from 'src/query/activitylog'
import Spinner from 'src/ui-elements/loader/Spinner'
import {
  getActivityLogModalUrl,
  getItemNameFromActivityLog,
} from 'src/utility/activityLogUtils'
import { classNames } from 'src/utility/utils'

interface ICommentProps {
  parentId: number
  parentType: string
}

const ChangeLog = ({ parentId, parentType }: ICommentProps) => {
  const { t } = useTranslation()
  const modalContext = useContext(ModalContext)

  const {
    data: activityLogs,
    isPending,
    error,
    refetch,
  } = useActivityLogsForType(parentType, parentId)

  useEffect(() => {
    refetch()
  }, [refetch])

  if (error) return null

  return (
    <div className="flex flex-col">
      {isPending ? (
        <Spinner />
      ) : (
        <>
          {activityLogs.length === 0 && (
            <div className="text-center text-gray-500 italic">
              {t('no_recorded_activity')}
            </div>
          )}

          {activityLogs.map((activityLog, index) => (
            <div
              key={activityLog.id}
              className={classNames(
                'border-grey-root bg-white hover:bg-grey-lightest min-h-10 p-1',
                index !== activityLogs.length - 1 && 'border-b',
              )}
            >
              <ActivityLogItemHeader
                user={activityLog.user}
                date={activityLog.created_at}
              />
              {activityLog.parent_type !== parentType && (
                <div
                  className="text-sm text-blue-500 underline mx-4 truncate cursor-pointer"
                  onClick={() => {
                    history.push(getActivityLogModalUrl(activityLog))
                    modalContext.actions.checkURL()
                  }}
                >
                  {getItemNameFromActivityLog(activityLog, t)}
                </div>
              )}
              <ActivityLogItemBody
                activityLog={activityLog}
                isForType
                className="ml-10"
              />
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default ChangeLog
