import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { IPhase } from '../../service/OrgTypes'
import { createPhase, editPhase } from '../../service/PhaseService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import { classNames } from '../../utility/utils'

interface IPhaseFormProps extends WithTranslation {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  phase: IPhase
}

interface IPhaseFormState {
  id: number
  phaseName: string
  description: string
  phaseNameErrorMessage: string
  descriptionErrorMessage: string
  loading: boolean
}

class PhaseForm extends PureComponent<IPhaseFormProps, IPhaseFormState> {
  private static styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  public constructor(props: IPhaseFormProps) {
    super(props)
    this.state = {
      id: props.phase.id,
      phaseName: props.phase.phaseName,
      description: props.phase.description,
      phaseNameErrorMessage: '',
      descriptionErrorMessage: '',
      loading: false,
    }
  }

  private onSubmit = (e: any) => {
    e.preventDefault()
    let error = false
    const { t } = this.props

    this.setState({
      phaseNameErrorMessage: '',
      descriptionErrorMessage: '',
      loading: true,
    })

    if (!this.state.phaseName) {
      this.setState({
        phaseNameErrorMessage: t('fill_in_the_name'),
      })
      error = true
    }

    if (!this.state.description) {
      this.setState({
        descriptionErrorMessage: t('fill_in_the_description'),
      })
      error = true
    }

    if (!error) {
      if (this.props.editingMode) {
        editPhase({
          id: this.state.id,
          phaseName: this.state.phaseName,
          description: this.state.description,
        }).then(() => {
          this.props.closeModal()
        })
      } else {
        createPhase(
          {
            id: 0,
            phaseName: this.state.phaseName,
            description: this.state.description,
          },
          this.props.projectId,
        ).then(() => {
          this.props.closeModal()
        })
      }
    }

    this.setState({
      loading: false,
    })
  }

  private onPhaseNameChange = (e: any) => {
    const phaseNameVal = e.target.value

    this.setState({
      phaseName: phaseNameVal,
      phaseNameErrorMessage: '',
    })
  }

  private onPhaseDescriptionChange = (e: any) => {
    const val = e.target.value

    this.setState({
      description: val,
      descriptionErrorMessage: '',
    })
  }

  public render() {
    const {
      phaseName,
      description,
      phaseNameErrorMessage,
      descriptionErrorMessage,
      loading,
    } = this.state
    const { t } = this.props

    return (
      <form className={PhaseForm.styleClass.root} onSubmit={this.onSubmit}>
        <div className={PhaseForm.styleClass.inputGroup}>
          <Input
            label={t('phase_name')}
            block={true}
            value={phaseName}
            errorMessage={phaseNameErrorMessage}
            required={true}
            onChange={this.onPhaseNameChange}
            autoFocus={true}
          />
        </div>

        <div className={PhaseForm.styleClass.inputGroup}>
          <Textarea
            isValid={false}
            label={t('description')}
            block={true}
            value={description}
            errorMessage={descriptionErrorMessage}
            required={true}
            onChange={this.onPhaseDescriptionChange}
          />
        </div>

        <ModalFooter>
          <Button onClick={this.props.closeModal}>{t('cancel')}</Button>
          {this.props.editingMode ? (
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={loading ? true : false}
            >
              {loading ? <Spinner /> : t('update')}
            </Button>
          ) : (
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={loading ? true : false}
            >
              {loading ? <Spinner /> : t('add')}
            </Button>
          )}
        </ModalFooter>
      </form>
    )
  }
}

export default withTranslation()(PhaseForm)
