import { IMetaField } from '../components/system/system-type-fields/SystemTypeFieldsForm'
import { IMetaValue } from '../document/types/IMetaData'
import { request } from './service-utils'

export interface IMetaFieldResponse {
  user_defined_fields: IMetaField[]
  total_pages: number
}
export const createSystemTypeField = (
  projectId: number,
  systemTypeFields: Omit<IMetaField, 'id'>,
): Promise<IMetaField> => {
  return request(
    `projects/${projectId}/user_defined_fields`,
    'POST',
    systemTypeFields,
  )
}

export const editSystemTypeField = (
  systemTypeField: IMetaField,
): Promise<IMetaField> => {
  return request(
    `user_defined_fields/${systemTypeField.id}`,
    'PUT',
    systemTypeField,
  )
}

export const getProjectSystemTypeField = (
  projectId: number,
  page: number,
  per_page = 50,
  filter: any,
): Promise<IMetaFieldResponse> => {
  return request(
    `projects/${projectId}/user_defined_fields/pages/${page}/${per_page}`,
    'POST',
    filter,
  )
}

export const getProjectUserDefinedField = (
  projectId: number,
): Promise<IMetaField[]> => {
  return request(`projects/${projectId}/user_defined_fields`, 'GET')
}

export const getUserDefinedFieldForDomain = (
  projectId: number,
  domain: string,
): Promise<IMetaField[]> => {
  return request(
    `projects/${projectId}/domain/${domain}/user_defined_fields`,
    'GET',
  )
}

export const getSystemTypeField = (id: number): Promise<IMetaField> => {
  return request(`user_defined_fields/${id}`, 'GET')
}

export const deleteSystemTypeField = (systemTypeFieldId: number) => {
  return request(
    `/user_defined_fields/${systemTypeFieldId}`,
    'DELETE',
    {},
    true,
  )
}

export const systemTypeValidateFromJson = (
  projectId: number,
  systemSettingId: number,
  data: any,
  categoryId?: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/system_types/validate_for_import_job/${
      categoryId ?? ''
    }`,
    'POST',
    data,
  )
}

export const userDefinedFieldsValidateFromJson = (
  projectID: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectID}/user_defined_fields/validate_for_import`,
    'POST',
    data,
  )
}

export const userDefinedFieldsImportFromJson = (
  projectID: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectID}/user_defined_fields/import_from_json`,
    'POST',
    data,
  )
}

export const updateUserDefinedFieldsValue = (
  fieldId: number,
  data: IMetaValue,
): Promise<any> => {
  return request(`user_defined_values/${fieldId}`, 'PUT', data)
}

export const createUserDefinedFieldsValue = (
  projectId: number,
  data: IMetaValue,
): Promise<any> => {
  return request(`projects/${projectId}/user_defined_values`, 'POST', data)
}
