import moment from 'moment'
import { IActivityLog } from './OrgTypes'
import { request } from './service-utils'

export const getActivitiesForType = (
  parentType: string,
  parentId: number,
): Promise<IActivityLog[]> =>
  request(`parents/${parentType}/${parentId}/activity_logs`, 'GET')

export const getActivitiesForProject = (
  projectId: number,
  pageNumber: number,
  max_date?: moment.Moment,
): Promise<IActivityLog[]> => {
  return request(`projects/${projectId}/activity_logs/${pageNumber}`, 'POST', {
    max_date,
  })
}

export const getActivitiesForUser = (
  projectId: number,
  userId: number,
  pageNumber: number,
  max_date?: moment.Moment,
): Promise<IActivityLog[]> => {
  return request(
    `projects/${projectId}/users/${userId}/activity_logs/${pageNumber}`,
    'POST',
    { max_date },
  )
}

export const getActivitiesForUserWithCount = (
  projectId: number,
  userId: number,
  pageNumber: number,
): Promise<{
  activity_logs: IActivityLog[]
  total_count: number
  unread_count: number
  page: number
  total_pages: number
}> => {
  return request(
    `projects/${projectId}/users/${userId}/activity_logs_with_count/${pageNumber}`,
    'GET',
  )
}

export const getSystemNotifications = (
  projectId: number,
): Promise<IActivityLog[]> => {
  return request(`projects/${projectId}/system_notifications`, 'GET')
}
