import moment from 'moment-timezone'
import { Fragment } from 'react'
import tinycolor from 'tinycolor2'
import { IContract, IUserData } from 'src/service/OrgTypes'

export interface IHoverPos {
  left: number | undefined
  right: number | undefined
  top: number | undefined
  bottom: number | undefined
}

export const getHoverItemPosition = (
  floatLabel: IHoverPos,
  e: React.MouseEvent<HTMLElement>,
  wrapperRect: DOMRect,
  vw: number,
  vh: number,
  itemContext: { width: number },
) => {
  const itemTopOffset = e.clientY
  const itemLeftOffset = e.clientX
  const tmpFloatLabel = { ...floatLabel }

  if (vw && vh && itemTopOffset) {
    const midHeight = vh ? Number(vh / 2) : 0
    const midWidth = vw ? Number(vw / 2) : 0
    const alignTop = itemTopOffset <= midHeight
    const alignRight = itemLeftOffset <= midWidth
    tmpFloatLabel.left = alignRight
      ? wrapperRect.x + itemContext.width
      : undefined
    tmpFloatLabel.right = alignRight
      ? undefined
      : vw - wrapperRect.right + itemContext.width
    tmpFloatLabel.bottom = alignTop ? undefined : vh - wrapperRect.bottom
    tmpFloatLabel.top = alignTop ? wrapperRect.top : undefined
  } else {
    tmpFloatLabel.left = wrapperRect?.x + wrapperRect.width
    tmpFloatLabel.top = wrapperRect?.top
  }

  return tmpFloatLabel
}

export const userComparator = (
  a: IUserData,
  b: IUserData,
  direction: boolean,
): number => {
  if (
    a.firstName.toLocaleUpperCase() + ' ' + a.lastName.toLocaleUpperCase() >
    b.firstName.toLocaleUpperCase() + ' ' + b.lastName.toLocaleUpperCase()
  ) {
    return direction ? 1 : -1
  }

  if (
    a.firstName.toLocaleUpperCase() + ' ' + a.lastName.toLocaleUpperCase() <
    b.firstName.toLocaleUpperCase() + ' ' + b.lastName.toLocaleUpperCase()
  ) {
    return direction ? -1 : 1
  }

  return 0
}

export const userEmailComparator = (
  a: any,
  b: any,
  direction: boolean,
): number => {
  if (a.email > b.email) {
    return direction ? 1 : -1
  }

  if (a.email < b.email) {
    return direction ? -1 : 1
  }

  return 0
}

export const contractComparator = (
  a: IContract,
  b: IContract,
  direction: boolean,
): number => {
  if (
    a.contractNumber + ' ' + a.contractName.toLocaleUpperCase() >
    b.contractNumber + ' ' + b.contractName.toLocaleUpperCase()
  ) {
    return direction ? 1 : -1
  }

  if (
    a.contractNumber + ' ' + a.contractName.toLocaleUpperCase() <
    b.contractNumber + ' ' + b.contractName.toLocaleUpperCase()
  ) {
    return direction ? -1 : 1
  }

  return 0
}

export const keypointComparator = (
  a: any,
  b: any,
  direction: boolean,
): number => {
  if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
    return direction ? 1 : -1
  }

  if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
    return direction ? -1 : 1
  }

  return 0
}

export const theamComparator = (a: any, b: any, direction: boolean): number => {
  if (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase()) {
    return direction ? 1 : -1
  }

  if (a.name.toLocaleUpperCase() < b.name.toLocaleUpperCase()) {
    return direction ? -1 : 1
  }

  return 0
}

export const displineComparator = (
  a: any,
  b: any,
  direction: boolean,
): number => {
  if (a.shortName.toLocaleUpperCase() > b.shortName.toLocaleUpperCase()) {
    return direction ? 1 : -1
  }

  if (a.shortName.toLocaleUpperCase() < b.shortName.toLocaleUpperCase()) {
    return direction ? -1 : 1
  }

  return 0
}

export const phaseComparator = (a: any, b: any, direction: boolean): number => {
  if (a.phaseName.toLocaleUpperCase() > b.phaseName.toLocaleUpperCase()) {
    return direction ? 1 : -1
  }

  if (a.phaseName.toLocaleUpperCase() < b.phaseName.toLocaleUpperCase()) {
    return direction ? -1 : 1
  }

  return 0
}

export const titleComparator = (a: any, b: any, direction: boolean): number => {
  if (a.title.toLocaleUpperCase() > b.title.toLocaleUpperCase()) {
    return direction ? 1 : -1
  }

  if (a.title.toLocaleUpperCase() < b.title.toLocaleUpperCase()) {
    return direction ? -1 : 1
  }

  return 0
}

export const stringComparator = (
  a: any,
  b: any,
  direction: boolean,
): number => {
  return a === b ? 0 : a > b && direction ? 1 : -1
}

export const numberComparator = (
  a: number,
  b: number,
  direction: boolean,
): number => {
  return a === b ? 0 : a > b && direction ? 1 : -1
}

export const renderDayContents = (day: any) => {
  const dayTz = moment.tz(day.format(), 'Europe/Oslo')
  return renderDayWithoutTimezone(dayTz)
}

export const renderDayWithoutTimezone = (day: any) => {
  return (
    <Fragment>
      <span className="CalendarDayWeekNumber">{day.format('W')}</span>
      {day.format('D')}
    </Fragment>
  )
}

export const canvasRootStyles = (color: string = 'gray', height: string) => {
  return {
    lineHeight: '18px',
    borderColor: color,
    height: height,
  }
}

export const canvasTitleStyles = (color: string = 'gray') => {
  return {
    backgroundColor: new tinycolor(color).setAlpha(0.3).toString(),
  }
}

export const canvasSubTitleStyles = (color: string = 'gray') => {
  return {
    backgroundColor: new tinycolor(color).setAlpha(0.7).toString(),
  }
}

export const concatinateValuesForTable = (
  firstValue: string,
  secondValue: string,
) => {
  return `${firstValue} - ${secondValue}`
}

export const toFixed = (i: number) => {
  const item = Number(i)
  return item && !isNaN(item) ? (item % 1 === 0 ? item : item.toFixed(2)) : 0
}

export const constructRangeValue = (from: string, to: string, unit: string) => {
  const fromValue = from ? (isNaN(Number(from)) ? from : from + unit) : ''
  const toValue =
    to && to.replace(/\s/g, '').length
      ? isNaN(Number(to))
        ? to
        : to + unit
      : ''

  return fromValue.replace(' ', '') === toValue.replace(' ', '')
    ? fromValue
    : fromValue + ' - ' + toValue
}

export const debounce = <Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
) => {
  let timer: NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
