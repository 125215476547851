import { ITeam } from './OrgTypes'
import { request } from './service-utils'

export const createTeam = (
  teamData: ITeam,
  projectId: number,
): Promise<ITeam> => {
  return request(`projects/${projectId}/teams`, 'POST', teamData)
}

export const editTeam = (team: ITeam): Promise<ITeam> => {
  return request(`teams/${team.id}`, 'PUT', team)
}

export const getProjectTeamsConfig = (projectId: number): Promise<ITeam[]> => {
  return request(`projects/${projectId}/teams_config`, 'GET')
}

export const getProjectTeams = (projectId: number): Promise<ITeam[]> => {
  return request(`projects/${projectId}/teams`, 'GET')
}

export const getMainprocessTeams = (mainprocesId: number): Promise<ITeam[]> => {
  return request(`main_processes/${mainprocesId}/teams`, 'GET')
}

export const swapOrderTeam = (
  teamId: number,
  sequence: number,
): Promise<ITeam[]> => {
  return request(`teams/${teamId}/change_order`, 'PUT', { sequence: sequence })
}

export const getMainProcessTeams = (
  mainProcessesId: number,
): Promise<ITeam[]> => {
  return request(`main_processes/${mainProcessesId}/teams`, 'GET')
}

export const deleteTeam = (task: ITeam) => {
  return request(`/teams/${task.id}`, 'DELETE', {}, true)
}
