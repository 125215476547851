import { RowSelectionState } from '@tanstack/react-table'
import { noop } from 'lodash'
import React, {
  FC,
  MouseEvent,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useTestDocumentTypes } from 'src/query/test'
import { ITestDocument } from 'src/service/OrgTypes'
import { createTestDocumentsByExistingType } from 'src/service/TestService'
import { dateColumn, textColumn } from 'src/ui-elements/Table/Columns'
import TableBody from 'src/ui-elements/Table/TableBody'
import useTable from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import Spinner from 'src/ui-elements/loader/Spinner'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface ISelectTestDocumentsList {
  projectId: number
  closeModal?: () => void
  testId?: number
  isSelectTable?: boolean
  onDeleteClick?: (doc: ITestDocument) => void
  onRowClick?: (doc: ITestDocument) => void
}

const TestDocumentTypeTable: FC<
  PropsWithChildren<ISelectTestDocumentsList>
> = ({
  closeModal,
  testId,
  projectId,
  children,
  isSelectTable = false,
  onRowClick,
  onDeleteClick,
}) => {
  const { data = [] } = useTestDocumentTypes()
  const { t } = useTranslation()
  const [selectionState, setSelectionState] = useState<RowSelectionState>({})
  const [loading, setLoading] = useState<boolean>()

  const handleSelectedTestDocs = (rows: RowSelectionState) => {
    setSelectionState(rows)
  }

  const addExistingTestDocumentTypeToSystems = async (docIds: number[]) => {
    if (!testId) return
    setLoading(true)
    await createTestDocumentsByExistingType(docIds, projectId, testId)
    closeModal?.()
  }

  const selectedItems =
    useMemo(() => {
      return Object.keys(selectionState).map((item) => parseInt(item))
    }, [selectionState]) ?? []

  const columns = [
    textColumn('document_name', { name: 'name' }),
    dateColumn('created_at', { name: t('created_at') }),
    dateColumn('updated_at', { name: t('updated_at') }),
  ]

  const onSubmit = async (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    addExistingTestDocumentTypeToSystems?.(selectedItems)
  }

  const ordering = ['select', 'document_name', 'updated_at', 'created_at']

  const { table } = useTable({
    name: 'selectableTestDocumentTypes',
    data: data,
    columns: columns,
    onRowClick: onRowClick ?? noop,
    defaultOrdering: ordering,
    selectionState: selectionState,
    onSelect: handleSelectedTestDocs,
    selectable: isSelectTable,
    enableSorting: false,
    enableFilters: false,
    onDeleteClick: onDeleteClick,
  })

  return (
    <>
      {children}
      <TableBody table={table} enablePagination={false}></TableBody>
      {isSelectTable && (
        <ModalFooter>
          <Button onClick={closeModal}>{t('cancel')}</Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            onClick={onSubmit}
            disabled={loading || selectedItems.length === 0}
          >
            {loading ? <Spinner /> : t('confirm')}
          </Button>
        </ModalFooter>
      )}
    </>
  )
}

export default TestDocumentTypeTable
