import { useVirtualizer } from '@tanstack/react-virtual'
import { MutableRefObject } from 'react'
import { TreeMenuItem } from 'react-simple-tree-menu'
import { ItemComponent } from 'src/ui-elements/downshift/ItemComponent'

interface ITreeItemListProps {
  containerRef: MutableRefObject<null | HTMLDivElement>
  items: TreeMenuItem[]
  onItemClick: (item: any) => void
  toggleNode: (key: string) => void
  collapsed?: boolean
}

const TreeItemlist = ({
  containerRef,
  items,
  onItemClick,
  toggleNode,
  collapsed,
}: ITreeItemListProps) => {
  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => containerRef.current,
    estimateSize: () => 26,
    overscan: 5,
  })

  return (
    <ul
      style={{ height: rowVirtualizer.getTotalSize() }}
      className="relative w-full"
    >
      {rowVirtualizer.getVirtualItems().map((virtualItem) => {
        const { key, ...props } = items[virtualItem.index]
        return (
          <div
            key={virtualItem.key}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: `${virtualItem.size}px`,
              transform: `translateY(${virtualItem.start}px)`,
            }}
          >
            <span key={key} onClick={() => onItemClick({ key, ...props })}>
              <ItemComponent
                itemKey={key}
                collapsed={collapsed}
                {...props}
                toggleNode={toggleNode}
                key={key}
              />
            </span>
          </div>
        )
      })}
    </ul>
  )
}

export default TreeItemlist
