import {
  useRef,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useState,
  useLayoutEffect,
  PropsWithChildren,
} from 'react'

let isResizing = false
let width = 0
interface IResizableDiv {
  initialWidth: number
  onNewWidth: (newWidth: number) => void
}

const ResizableDiv: React.FC<PropsWithChildren<IResizableDiv>> = ({
  initialWidth,
  onNewWidth,
  children,
}) => {
  const sidebarPanel = useRef<HTMLDivElement | null>(null)
  const [resSizing, setIsResizing] = useState(false)

  const updateWidth = useCallback((newWidth: number) => {
    if (sidebarPanel.current) {
      sidebarPanel.current.style.width = newWidth + 'px'
      width = newWidth
    }
  }, [])

  useLayoutEffect(() => {
    updateWidth(initialWidth)
  }, [updateWidth, initialWidth])

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing) {
      return
    }

    const offsetRight = e.clientX - 75
    const minWidth = 200
    const maxWidth = 900

    if (
      offsetRight > minWidth &&
      offsetRight < maxWidth &&
      sidebarPanel.current
    ) {
      updateWidth(offsetRight)
    }
  }

  const handleMouseUp = () => {
    if (!isResizing) {
      return
    }
    setIsResizing(false)
    isResizing = false
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
    onNewWidth(width)
  }

  const handleMousedown = (e: ReactMouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    setIsResizing(true)
    isResizing = true
  }

  return (
    <div className={'flex flex-none h-full pr-0'}>
      <div ref={sidebarPanel}>{children}</div>
      <div
        className="w-2 -ml-0.5 z-40 cursor-col-resize"
        onMouseDown={handleMousedown}
      >
        <div
          className={`w-1/2 h-full hover:bg-blue-root ${
            resSizing ? 'bg-blue-root' : 'bg-transparent'
          }`}
        />
      </div>
    </div>
  )
}

export default ResizableDiv
