import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { classNames } from '../utility/utils'

const SplashScreen = () => {
  const styleClass = {
    root: classNames(
      'w-screen',
      'h-screen',
      'flex',
      'justify-center',
      'items-end',
      'fixed',
    ),
    icon: classNames('mb-12'),
  }

  const rootStyles = {
    backgroundImage: `url('/splash.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 'calc(100vh - calc(100vh - 100%))',
  }

  return (
    <div style={rootStyles} className={styleClass.root}>
      <div className={styleClass.icon}>
        <SpinnerMobile isBlue={false} />
      </div>
    </div>
  )
}

export default SplashScreen
