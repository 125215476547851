import { Component } from 'react'
import Icon from 'src/ui-elements/icon/Icon'

interface IFileDragOverlayState {
  showOverlay: boolean
}

interface IFileDragOverlayProps {
  onFilesDropped?: (files: FileList) => void
  disabled?: boolean
  t: any
}

class FileDragOverlay extends Component<
  IFileDragOverlayProps,
  IFileDragOverlayState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      showOverlay: false,
    }

    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onFileDrop = this.onFileDrop.bind(this)
  }

  public onDragStart(e: DragEvent) {
    e.preventDefault()
    const { showOverlay } = this.state
    if (showOverlay || this.props.disabled) {
      return
    }

    this.setState({
      showOverlay: true,
    })
  }

  public onDragEnd(e: DragEvent) {
    if (e.target && !e.clientX && !e.clientY) {
      const { showOverlay } = this.state
      if (!showOverlay) {
        return
      }

      this.setState({
        showOverlay: false,
      })
    }
  }

  public onFileDrop(e: DragEvent) {
    e.preventDefault()
    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      if (this.props.onFilesDropped) {
        this.props.onFilesDropped(e.dataTransfer.files)
      }
    }

    this.setState({
      showOverlay: false,
    })
  }

  public onDragOver = (e: DragEvent) => {
    e.preventDefault()
  }

  public UNSAFE_componentWillMount() {
    if (window) {
      window.addEventListener('dragenter', this.onDragStart)
      window.addEventListener('dragleave', this.onDragEnd)
      window.addEventListener('dragover', this.onDragOver)
      window.addEventListener('drop', this.onFileDrop)
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('dragenter', this.onDragStart)
    window.removeEventListener('dragleave', this.onDragEnd)
    window.removeEventListener('dragover', this.onDragOver)
    window.removeEventListener('drop', this.onFileDrop)
  }

  public render() {
    const { showOverlay } = this.state
    if (!showOverlay) {
      return null
    }

    return (
      <div
        style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
        className="fixed top-0 right-0 bottom-0 left-0 z-50 flex flex-col items-center justify-center"
      >
        <div>
          <Icon icon={Icon.IconType.FILE_UPLOAD} styleClass={'w-10'} />
        </div>
        <div className="mt-4 font-bold">
          {this.props.t('drop_your_files_here_to_upload')}
        </div>
      </div>
    )
  }
}

export default FileDragOverlay
