import { PropsWithChildren } from 'react'
import * as React from 'react'
import { classNames } from 'src/utility/utils'

interface IPaginationButton {
  onClick: () => void
  selected?: boolean
  disabled?: boolean
  className?: string
}

const PaginationButton: React.FC<PropsWithChildren<IPaginationButton>> = ({
  onClick,
  selected,
  disabled,
  children,
  className = '',
}) => {
  const styleClass = {
    root: classNames(
      'w-auto',
      'h-5',
      'px-2',
      'flex',
      'justify-center',
      'items-center',
      'border',
      'text-xs',
      disabled ? 'bg-d-grey-lightest text-d-fontgrey' : 'bg-white',
      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      !disabled && 'hover:bg-blue-root hover:text-white',
      selected && 'bg-blue-root text-white',
      selected ? 'border-blue-root' : 'border-gray-300',
      'rounded',
      'mr-0.5',
      className,
    ),
  }

  return (
    <div className={styleClass.root} onClick={onClick}>
      {children}
    </div>
  )
}

export default PaginationButton
