import AttachFile from '@icons/attach_file.svg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { useSubcontractorFileContainers } from 'src/query/fdvu/subcontractor'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { StatusEnum } from 'src/utility/statusEnums'

interface IAssignedRequirementsList {
  selectedFileContainerId?: number
  setSelectedFileContainer: (fileContainer: IFdvuFileContainer) => void
  hideCompleted: boolean
}

const AssignedFileContainerList: FC<IAssignedRequirementsList> = ({
  selectedFileContainerId,
  setSelectedFileContainer,
  hideCompleted,
}) => {
  const { data: fileContainers = [] } =
    useSubcontractorFileContainers(hideCompleted)

  return (
    <div className={'rounded-sm border bg-white'}>
      {fileContainers.map((fileContainer, index) => (
        <div
          key={fileContainer.id}
          onClick={() => {
            setSelectedFileContainer(fileContainer)
          }}
          className={twMerge(
            'flex justify-between w-full text-gray-700 cursor-pointer p-2 hover:bg-blue-100',
            selectedFileContainerId === fileContainer.id &&
              'bg-blue-200 font-medium',
            index >= fileContainers.length - 1 && 'rounded-b-sm',
            index === 0 && 'rounded-t-sm',
          )}
        >
          <div className={'flex items-center gap-2'}>
            <div className="w-24 flex flex-col">
              <RequirementStatus status={fileContainer.status} />
            </div>
            <p className={'truncate max-w-74 first-capitalize'}>
              {fileContainer.recordId}
            </p>
            <p className={'truncate max-w-74 first-capitalize'}>
              {fileContainer.name}
            </p>
            {fileContainer.projectFiles.length > 0 && (
              <div className={'text-xs text-blue-root flex'}>
                <AttachFile className={'fill-blue-root text-lg'} />
                <span className="font-medium">
                  {fileContainer.projectFiles.length}
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export const RequirementStatus: FC<{
  status: StatusEnum
}> = ({ status }) => {
  const { t } = useTranslation()

  const getBadgeConfig = () => {
    switch (status) {
      case StatusEnum.COMPLETED:
        return {
          color: BadgeColor.GREEN,
          text: t('completed'),
        }
      case StatusEnum.IN_PROGRESS:
        return {
          color: BadgeColor.BLUE,
          text: t('in_review'),
        }
      case StatusEnum.DECLINED:
        return {
          color: BadgeColor.RED,
          text: t('declined'),
        }
      default:
        return { color: BadgeColor.YELLOW, text: t('open') }
    }
  }
  const badgeConfig = getBadgeConfig()

  return <Badge text={badgeConfig.text} color={badgeConfig.color} />
}

export default AssignedFileContainerList
