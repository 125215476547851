import { useUnleashContext } from '@unleash/proxy-client-react'
import * as React from 'react'
import { PropsWithChildren, useContext } from 'react'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { ProjectContext } from '../../context/ProjectContextProvider/ProjectContext'
import Menu from '../menu/Menu'

const MenuHOC: React.FC<PropsWithChildren> = ({ children }) => {
  const userContext = useContext(UserContext)
  const projectContext = useContext(ProjectContext)

  const updateContext = useUnleashContext()

  React.useEffect(() => {
    updateContext({
      userId: userContext.state.user.id.toString(),
      properties: {
        projectId: projectContext.state.currentProject.id.toString(),
        projectName: projectContext.state.currentProject.projectName,
      },
    })
  }, [userContext, projectContext, updateContext])

  return projectContext.state.currentProject && userContext.state.user ? (
    <Menu
      user={userContext.state.user}
      projects={projectContext.state.projects}
      currentProject={projectContext.state.currentProject}
    >
      {children}
    </Menu>
  ) : null
}

export default MenuHOC
