import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useImprovementListColumns from 'src/components/improvement/improvement-list/ImprovementListColumns'
import ImprovementPanel from 'src/components/improvement/improvement-panel/ImprovementPanel'
import { useImprovementListFiltered } from 'src/query/improvements'
import { IImprovement } from 'src/service/OrgTypes'
import Table from 'src/ui-elements/Table/Table'
import Modal from 'src/ui-elements/modal/Modal'

const SelectSingleImprovement = ({
  onSelect,
  closeModal,
}: {
  onSelect: (row: IImprovement) => void
  closeModal: () => void
}) => {
  const { standardColumns } = useImprovementListColumns()
  const [selectedImprovement, setSelectedImprovement] = useState<
    IImprovement | undefined
  >(undefined)
  const { t } = useTranslation()
  return (
    <Modal
      show={true}
      title={t('select_improvement')}
      closeModal={closeModal}
      size={'w-9/10'}
      maxWidth={1650}
    >
      <Table
        columns={standardColumns}
        name="SelectSingleImprovementList"
        onRowClick={onSelect}
        onPreviewClick={(row) => setSelectedImprovement(row)}
        useDataQuery={useImprovementListFiltered}
        selectable={false}
        defaultOrdering={['select']}
      />
      {selectedImprovement && (
        <ImprovementPanel
          improvementId={selectedImprovement.id}
          projectId={selectedImprovement.project_id ?? 0}
          onClose={() => setSelectedImprovement(undefined)}
          show={!!selectedImprovement}
        />
      )}
    </Modal>
  )
}

export default SelectSingleImprovement
