import moment from 'moment'
import { PureComponent } from 'react'
import history from '../../history'
import { IUserData } from '../../service/OrgTypes'
import { getContractUsersData } from '../../service/UserService'
import List from '../../ui-elements/list/List'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import { classNames } from '../../utility/utils'
import UserIcon from './UserIcon'

interface IContractUsersListProps {
  contractId: number
}

interface IContractUsersListState {
  users: IUserData[]
  loading: boolean
}

export default class ContractUsersList extends PureComponent<
  IContractUsersListProps,
  IContractUsersListState
> {
  private static styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  public constructor(props: IContractUsersListProps) {
    super(props)
    this.state = {
      users: [],
      loading: false,
    }
  }

  private reload = () => {
    getContractUsersData(this.props.contractId)
      .then((res: any) => {
        this.setState({
          users: res,
        })
      })
      .catch((err) => console.error(err))
  }

  public componentDidMount() {
    this.reload()
  }

  public componentDidUpdate(prevProps: IContractUsersListProps) {
    if (prevProps !== this.props) {
      this.reload()
    }
  }

  private goToUser = (row: IUserData) => {
    history.push(`/users/${row.id}`)
  }

  public render() {
    const { users, loading } = this.state

    const columns: IListColumns[] = [
      {
        name: 'name',
        size: '200',
        id: 'name',
        dataField: 'firstName||lastName||image_url',
        cell: (user: any) =>
          user ? (
            <UserIcon
              userId={user.id}
              firstName={user.firstName}
              lastName={user.lastName}
              image_url={user.image_url}
            />
          ) : (
            <span />
          ),
      },
      {
        name: 'contract_admin',
        size: '150',
        id: 'isContractAdmin',
        dataField: 'isContractAdmin',
        cell: (isContractAdmin: any) => (
          <span>{isContractAdmin ? 'Ja' : 'Nei'}</span>
        ),
      },
      {
        name: 'discipline',
        size: '200',
        id: 'disiplin',
        dataField: 'user_contract_mappers',
        cell: (access: any) => (
          <span className="truncate">
            {access
              ? access
                  .filter((ds: any) => ds.contract_id === this.props.contractId)
                  .filter((ds: any) => ds.discipline)
                  .map((ds: any) => ds.discipline.name)
                  .join(', ')
              : ''}
          </span>
        ),
      },
      {
        name: 'role',
        size: '200',
        id: 'role',
        dataField: 'user_contract_mappers',
        cell: (access: any) => (
          <span className="truncate">
            {access
              ? access
                  .filter((ds: any) => ds.contract_id === this.props.contractId)
                  .filter((ds: any) => ds.role)
                  .map((ds: any) => ds.role.roleName)
                  .join(', ')
              : ''}
          </span>
        ),
      },
      {
        name: 'created_at',
        size: '130',
        id: 'createdAt',
        dataField: 'created_at',
        cell: (created: any) => (
          <span>{created ? moment(created).format('L') : ''}</span>
        ),
      },
      {
        name: 'updated_at',
        size: '130',
        id: 'updatedAt',
        dataField: 'updated_at',
        cell: (updated: any) => (
          <span>{updated ? moment(updated).format('L') : ''}</span>
        ),
      },
    ]

    return (
      <div className={ContractUsersList.styleClass.root}>
        <List
          columns={columns}
          data={users}
          tableName={'contractUsersList'}
          onRowClick={this.goToUser}
          itemsPerPage={0}
        />
        {loading ? <Loader /> : null}
      </div>
    )
  }
}
