import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { getContractDisciplines } from 'src/service/DisciplineService'
import { IContract, IDiscipline } from 'src/service/OrgTypes'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { classNames, modalUrlBuilder } from 'src/utility/utils'
import { editContract, getContract } from '../../service/ContractService'
import FixedPane from '../../ui-elements/fixed-pane/FixedPane'
import DateTimeInlineInputComponent from '../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from '../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import TextInlineInputCompontent from '../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import { setColorForIcon } from '../discipline/disciplineUtils'

interface IContractInspectorPanel {
  contractId: number
  open: boolean
  onClose: () => void
  onUpdate: () => void
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
  greyText: (black?: boolean) =>
    classNames(
      black ? 'text-black' : 'text-d-fontgrey',
      'leading-snug',
      'inline-flex',
    ),
  row: {
    root: (first: boolean, selected: boolean, last: boolean) =>
      classNames(
        first && 'border-t',
        'border-b',
        'border-d-darkgrey',
        'flex',
        'flex-col',
        selected ? 'max-h-[calc(100vh_-_665px)] overflow-auto' : 'flex-none',
        last && '-mb-1',
        'selectorScroll',
      ),
    header: classNames('N/A'),
  },
}

const ContractInspectorPanel: React.FC<IContractInspectorPanel> = ({
  contractId,
  open,
  onClose,
  onUpdate,
}) => {
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const history = useHistory()
  const modalContext = useContext(ModalContext)
  const { t } = useTranslation()
  const [contract, setContract] = useState<IContract>()
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [loading, setLoading] = useState(false)

  const fetchContractDisciplines = useCallback(async () => {
    const newDisciplines = await getContractDisciplines(contractId)
    setDisciplines(newDisciplines)
  }, [contractId])

  const fetchContract = useCallback(async () => {
    setLoading(true)
    const newContract = await getContract(contractId)
    setContract(newContract)
    setLoading(false)
  }, [contractId])

  useEffect(() => {
    fetchContractDisciplines()
    fetchContract()
  }, [fetchContract, fetchContractDisciplines])

  const onDisciplineClick = (id: number) => {
    const modalURL = modalUrlBuilder(projectId, 'discipline', id)
    history.push(modalURL)
    modalContext.actions.checkURL()
  }

  const getRows = (): Array<{
    name: string
    icon: any
    activeIcon: any
    content: JSX.Element
  }> => [
    {
      name: t('disciplines'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorSectionTable
          headerColumns={[t('name'), t('code'), t('icon')]}
          rowsData={
            disciplines
              ? disciplines.map((discipline) => {
                  return {
                    cells: [
                      discipline?.name ?? '',
                      discipline?.shortName ?? '',
                      setColorForIcon({
                        shape: discipline.shape,
                        color: discipline.color,
                      }),
                    ],
                    id: discipline.id,
                  }
                })
              : []
          }
          handleClick={onDisciplineClick}
        />
      ),
    },
  ]

  const toggleExpand = (index: number) => {
    setSelectedIndex(index === selectedIndex ? -1 : index)
  }
  const onChangeInput = (update: Partial<IContract>) => {
    editContract({
      ...update,
      id: contractId,
    }).then(() => {
      onUpdate()
    })
  }

  return (
    <FixedPane
      title={`${contract?.contractNumber ?? 'loading...'} - ${
        contract?.contractName ?? 'loading...'
      }`}
      show={open}
      onClose={() => onClose()}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.CONTRACT,
        ids: {
          contractId: contractId,
        },
      }}
    >
      <div className={styleClass.root}>
        <InlineComponentsWrapper
          loading={loading}
          padding="left"
          border={'right'}
        >
          <TextInlineInputCompontent
            label={t('contract_name')}
            value={contract?.contractName ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ contractName: newValue })
            }}
            validate={(contractName) => {
              if (contractName === undefined || contractName === '') {
                return t('fill_out_w_param', {
                  param: t('contractName'),
                })
              }
              return
            }}
          />
          <TextInlineInputCompontent
            label={t('contract_number')}
            value={contract?.contractNumber ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ contractNumber: newValue })
            }}
          />
          <TextInlineInputCompontent
            label={t('company_name')}
            value={contract?.company_name ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ company_name: newValue })
            }}
          />
          <TextInlineInputCompontent
            label={t('size')}
            value={contract?.contractSize ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ contractSize: newValue })
            }}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={contract?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={contract?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>

        <div className={'flex flex-col flex-1'}>
          {getRows().map((row, i) => (
            <div
              key={i}
              className={styleClass.row.root(
                i === 0,
                selectedIndex === i,
                getRows().length - 1 === i,
              )}
            >
              <div
                onClick={() => toggleExpand(i)}
                className={
                  'flex items-center justify-between px-5 py-4 bg-white sticky left-0 cursor-pointer'
                }
              >
                <p className={styleClass.greyText(selectedIndex === i)}>
                  <Icon
                    className={`mr-4 w-5 h-5`}
                    icon={selectedIndex === i ? row.activeIcon : row.icon}
                  />
                  {row.name}
                </p>
                <Icon
                  onClick={() => toggleExpand(i)}
                  className={`w-5 h-5 flex ${
                    selectedIndex === i ? 'transform -rotate-90' : 'N/A'
                  }`}
                  icon={Icons.ARROW_LEFT}
                />
              </div>
              {selectedIndex === i && (
                <div className={'h-max max-h-full pb-4 px-5'}>
                  {row.content}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </FixedPane>
  )
}
export default ContractInspectorPanel
