import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const demoConfig: IEnvironmentConfig = {
  host: "demo.bygg.io",
  exportBaseUrl: "https://export-demo.bygg.io/",
  frontendBaseUrl: "https://demo.bygg.io/",
  organizationBaseUrl: "https://org-demo.bygg.io/",
  proxyBaseUrl: "https://proxy-demo.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-demo.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-demo.bygg.io/",
  bimServiceBaseUrl: "not set yet",
  kpiBaseUrl: "https://kpi-demo.bygg.io/"
}
