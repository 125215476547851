import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { ITest, ITestType } from 'src/service/OrgTypes'
import {
  editTestType,
  getTestsForTestType,
  getTestType,
} from 'src/service/TestService'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import BooleanInlineInputComponent from 'src/ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import InspectorSections from '../../../document/components/Inspector/InspectorSections'
import FixedPane from '../../../ui-elements/fixed-pane/FixedPane'
import { Icons } from '../../../ui-elements/icon/Icon'
import DateTimeInlineInputComponent from '../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import { capFirstLetter, classNames } from '../../../utility/utils'
import TestInspectorPanel from '../tests/TestInspectorPanel'

interface ITestTypeInspectionPanel {
  testTypeId: number
  open: boolean
  onClose: () => void
  onUpdate: () => void
  disabled?: boolean
  defaultIndex?: number
}

const TestTypeInspectionPanel: React.FC<ITestTypeInspectionPanel> = ({
  testTypeId,
  open,
  onClose,
  onUpdate,
  disabled,
  defaultIndex,
}) => {
  const styleClass = {
    root: classNames('flex', 'flex-col', 'min-h-full'),
  }

  const { t } = useTranslation()
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id
  const [testType, setTestType] = useState<ITestType>()
  const [tests, setTests] = useState<ITest[]>([])
  const [showTestInspectionPanel, setShowTestInspectionPanel] =
    useState<boolean>(false)
  const [selectedTestId, setSelectedTestId] = useState<number | undefined>(
    undefined,
  )

  useEffect(() => {
    reload()
    loadTests()
  }, [projectId, testTypeId])

  const loadTests = () => {
    const activeFilters: IListFilter[] = []
    if (testTypeId) {
      activeFilters['test_type'] = [`${testTypeId}`]
    }

    getTestsForTestType(testTypeId, 1, activeFilters, 10000).then((res) => {
      setTests(res.tests)
    })
  }

  const reload = () => {
    getTestType(testTypeId).then((res) => {
      setTestType(res)
    })
  }

  const onChangeInput = async (update: Partial<ITestType>) => {
    editTestType({
      ...update,
      id: testTypeId,
    }).then(() => {
      reload()
      onUpdate()
    })
  }

  const openTestInspectionPanel = (id: number) => {
    setSelectedTestId(id)
    setShowTestInspectionPanel(true)
  }

  const closeTestInspectionPanel = () => {
    setSelectedTestId(undefined)
    setShowTestInspectionPanel(false)
  }

  const getRows = (): Array<{
    name: string
    icon: any
    activeIcon?: any
    content: JSX.Element
  }> => [
    {
      name: capFirstLetter(t('tests')),
      icon: Icons.CHECK_GRAY,
      activeIcon: Icons.CHECK_GRAY,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('name'),
            t('status'),
            t('deadline'),
            t('responsible'),
          ]}
          rowsData={tests?.map((test) => {
            return {
              cells: [
                test?.record_id ?? '',
                test?.name ?? '',
                test?.status ? t(`${test.status}`) : '',
                test.execution_date
                  ? moment(test.execution_date).format('L')
                  : '',
                test?.responsible
                  ? `${test?.responsible?.firstName} ${test?.responsible?.lastName}`
                  : '',
              ],
              id: test.id,
            }
          })}
          handleClick={openTestInspectionPanel}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={testTypeId} parentType="TestType" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={testTypeId} parentType="TestType" />,
    },
  ]

  return (
    <FixedPane
      title={
        testType?.record_id
          ? testType?.record_id + ' - ' + testType?.name
          : t('loading...')
      }
      show={open}
      onClose={() => onClose()}
      className={'w-[700px]'}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.TEST_TYPE,
        ids: { testTypeId: testType?.id ?? 0 },
      }}
    >
      <div className={styleClass.root}>
        <InlineComponentsWrapper padding="left">
          <TextInlineInputCompontent
            label={'name'}
            value={testType?.name ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            disabled={disabled}
          />

          <TextInlineInputCompontent
            label={'description'}
            value={testType?.description ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ description: newValue })
            }}
            textArea={true}
            disabled={disabled}
          />
          <BooleanInlineInputComponent
            label="is_default_test_type"
            onValueSubmitted={(is_default) => {
              onChangeInput({ is_default })
            }}
            value={testType?.is_default}
            showCheckBox
            disabled={disabled}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={testType?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={testType?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
        <InspectorSections defaultIndex={defaultIndex} sections={getRows()} />
        {selectedTestId && showTestInspectionPanel && (
          <TestInspectorPanel
            disabled={true}
            testId={selectedTestId}
            open={showTestInspectionPanel}
            onClose={closeTestInspectionPanel}
            onUpdate={loadTests}
          />
        )}
      </div>
    </FixedPane>
  )
}
export default TestTypeInspectionPanel
