import moment from 'moment/moment'
import * as React from 'react'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { ITestWorkGroup } from 'src/service/OrgTypes'
import { getStatusesForType } from 'src/service/SystemStatusService'
import { priorities } from 'src/service/SystemValues'
import { editTestWorkGroup } from 'src/service/TestWorkGroupService'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'

interface ITestWorkGroupStatusFields {
  testWorkGroup?: ITestWorkGroup
  setTestWorkGroup: (tsg: ITestWorkGroup) => void
  onUpdate: () => void
  inspectorPanel?: boolean
}

const TestWorkGroupStatusFields: FC<ITestWorkGroupStatusFields> = ({
  testWorkGroup,
  setTestWorkGroup,
  onUpdate,
  inspectorPanel = false,
}) => {
  const projectId = useProjectId()
  const { t } = useTranslation()
  const userContext = useContext(UserContext)
  const writeAccess = userContext.actions.hasWriteAccess('object')

  const { getChangesForUpdate } = useInlineDependencyUpdate<ITestWorkGroup>(
    setTestWorkGroup,
    testWorkGroup,
  )

  const onChangeInput = (update: Partial<ITestWorkGroup>) => {
    const allUpdates = getChangesForUpdate(update)
    editTestWorkGroup({
      ...convertUndefinedToNull(allUpdates),
      id: testWorkGroup?.id,
    }).then(() => {
      onUpdate()
    })
  }
  return (
    <>
      <DateTimeInlineInputComponent
        label="planned_start"
        selectedTime={testWorkGroup?.planned_start}
        onValueSubmitted={(date) => {
          onChangeInput({
            planned_start: date ? moment(date) : undefined,
            planned_end: date ? moment(date).add(6, 'weeks') : undefined,
          })
        }}
        inspectorPanel={inspectorPanel}
        cancelButton
        disabled={!writeAccess}
      />
      <DateTimeInlineInputComponent
        label="planned_end"
        selectedTime={testWorkGroup?.planned_end}
        onValueSubmitted={(date) => {
          onChangeInput({ planned_end: date ? moment(date) : undefined })
        }}
        inspectorPanel={inspectorPanel}
        cancelButton
        disabled={!writeAccess}
      />
      <DateTimeInlineInputComponent
        label="actual_start"
        selectedTime={testWorkGroup?.actual_start}
        onValueSubmitted={(date) => {
          onChangeInput({ actual_start: date ? moment(date) : undefined })
        }}
        inspectorPanel={inspectorPanel}
        cancelButton
        disabled={!writeAccess}
      />
      <DateTimeInlineInputComponent
        label="actual_end"
        selectedTime={testWorkGroup?.actual_end}
        onValueSubmitted={(date) => {
          onChangeInput({ actual_end: date ? moment(date) : undefined })
        }}
        inspectorPanel={inspectorPanel}
        cancelButton
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        items={priorities(t)}
        label="priority"
        getItemLabel={(priority) => priority?.name}
        initialItem={{
          id: testWorkGroup?.priority,
          name: priorities(t).find(
            (priorityData) => priorityData.id === testWorkGroup?.priority,
          )?.name,
        }}
        validate={(value) => {
          if (value === undefined) return t('required')
          return
        }}
        selectedId={testWorkGroup?.priority}
        onValueSubmitted={(priority) => {
          onChangeInput({ priority })
        }}
        inspectorPanel={inspectorPanel}
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        getItems={() => getStatusesForType(projectId, 'TestWorkGroup')}
        getItemLabel={(status) => status?.name}
        label="status"
        initialItem={testWorkGroup?.test_work_group_status}
        validate={(value) => {
          if (value === undefined) return t('required')
          return
        }}
        selectedId={testWorkGroup?.test_work_group_status_id}
        onValueSubmitted={(test_work_group_status_id) => {
          onChangeInput({ test_work_group_status_id })
        }}
        inspectorPanel={inspectorPanel}
        disabled={!writeAccess}
      />
      <TextInlineInputCompontent
        label={'completed_percent'}
        disabled
        value={testWorkGroup?.percent_completed?.toString()}
      />
      <DateTimeInlineInputComponent
        label="created_at"
        selectedTime={testWorkGroup?.created_at}
        onValueSubmitted={() => {}}
        disabled={true}
        inspectorPanel={inspectorPanel}
      />
      <DateTimeInlineInputComponent
        label="updated_at"
        selectedTime={testWorkGroup?.updated_at}
        onValueSubmitted={() => {}}
        disabled={true}
        inspectorPanel={inspectorPanel}
      />
    </>
  )
}

export default TestWorkGroupStatusFields
