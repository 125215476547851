import moment from 'moment'
import * as React from 'react'
import { IProjectMilestoneResponse } from 'src/service/ProcessService'

interface IMilestoneCanvasIconProps {
  date?: moment.Moment
  item: IProjectMilestoneResponse
}

const MilestoneCanvasIcon: React.FunctionComponent<
  IMilestoneCanvasIconProps
> = ({ date: _date, item }: IMilestoneCanvasIconProps) => {
  const getStatus = (): string => {
    const itemDeadline = moment(item.deadline)

    if (item.status === 'done') {
      return '-green'
    } else {
      if (
        itemDeadline.isAfter(moment(), 'day') ||
        itemDeadline.isSame(moment(), 'day')
      ) {
        return (item.expired_children && item.expired_children > 0) ||
          (item.behind_schedule_children && item.behind_schedule_children > 0)
          ? '-yellow'
          : '-black'
      } else {
        return ''
      }
    }
  }
  return <div className={`triangle-down${getStatus()}`} />
}

export default MilestoneCanvasIcon
