import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getProjectDisciplines } from 'src/service/DisciplineService'

export const useProjectDisciplines = (options?: { enabled?: boolean }) => {
  const projectId = useProjectId()

  return useQuery({
    ...options,
    queryKey: ['discipline', projectId],
    queryFn: () => getProjectDisciplines(projectId),
  })
}
