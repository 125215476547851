import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import Spinner from 'src/ui-elements/loader/Spinner'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import RadioItems from 'src/ui-elements/radio/RadioItems'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import { createConstructionTrain } from '../../../service/ConstructionTrainService'
import { getProjectControlAreas } from '../../../service/ControlAreaService'
import {
  IConstructionTrain,
  IControlArea,
  ITrainType,
} from '../../../service/OrgTypes'
import { trainColors } from '../../../service/SystemValues'
import { getProjectTrainTypes } from '../../../service/TrainTypeService'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import { renderDayContents } from '../../../utility/Utility'
import { capFirstLetter, classNames } from '../../../utility/utils'
import Selector from '../../selectors/Selector'
import Checkbox from '../../switchHoc/CheckBox'
import WagonFormForTrain from '../wagon/WagonFormForTrain'

interface ITrainForm {
  closeModal: () => void
  parentId: number
  train?: IConstructionTrain
  startDate?: moment.Moment
  onSave?: () => void
  controlAreaGroupId?: number
}

const TrainForm = ({
  closeModal,
  parentId,
  controlAreaGroupId,
  train,
  startDate,
  onSave,
}: ITrainForm) => {
  const styleClass = {
    root: classNames('flex', 'flex-col'),
    inputGroup: classNames('flex', 'flex-row', 'w-full', 'mb-2', 'pr-1'),
    label: classNames(
      'w-full',
      'font-roboto',
      'text-sm',
      'font-bold',
      'mb-2',
      'pl-1',
    ),
    footerButton: classNames(
      'w-full',
      'flex',
      'flex-row',
      'justify-start',
      'pt-4',
      'pr-4',
    ),
    wagonLabelRoot: classNames('flex', 'flex-col', 'w-full'),
    wagonLabel: classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'font-roboto',
      'my-2',
    ),
  }

  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  useEffect(() => {
    getProjectControlAreas(projectId).then((res) => {
      setControlAreas(res)
      setControlAreaId(parentId)
    })
    setStartTime(startDate || moment())
    fetchTrainTypes()
  }, [projectContext, startDate])

  const { t } = useTranslation()
  const [title, setTitle] = useState<string>('')
  const [wagon, setWagon] = useState<any>()
  const [color, setColor] = useState<string>('')
  const [startTime, setStartTime] = useState<moment.Moment>(moment())
  const [controlAreaId, setControlAreaId] = useState<number>(parentId || 0)
  const [controlAreas, setControlAreas] = useState<IControlArea[]>([])
  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false)
  const [controlAreaErrorMessage, setControlAreaErrorMessage] =
    useState<string>('')
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('')
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')

  const [trainTypeErrorMessage, setTrainTypeErrorMessage] = useState<string>('')
  const [colorErrorMessage, setColorErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [trainCreateMode, setTrainCreateMode] = useState<string>('copy_train')
  const [trainTypes, setTrainTypes] = useState<ITrainType[]>([])
  const [trainTypeId, setTrainTypeId] = useState<number>(0)
  const [numberOfWagons, setNumberOfWagons] = useState<number>(0)
  const [createForGroup, setCreateForGroup] = useState<boolean>(false)
  const [considerWeekends, setConsiderWeekends] = useState<boolean>(false)
  const [ignoreHolidays, setIgnoreHolidays] = useState<boolean>(false)
  const [dateChangeModal, setDateChangeModal] = useState<boolean>(true)
  const [wagonForm, setWagonForm] = useState<boolean>(false)

  const [createMultiple, setCreateMultiple] = useState<boolean>(false)

  const trainCreateModeTypes = [
    {
      id: 'new_train',
      name: <span>{t('create_empty_train')}</span>,
      classNames: 'radio_in_modal',
    },
    {
      id: 'copy_train',
      name: <span>{t('create_new_train_from_a_train_type')}</span>,
      classNames: 'radio_in_modal',
    },
  ]

  const onTrainCreateModeTypeChange = (e: any) => {
    const value = e.target.value
    setTrainCreateMode(value)
    if (value === 'copy_train') {
      setIsLoading(true)
      fetchTrainTypes()
    }
  }

  const fetchTrainTypes = () => {
    getProjectTrainTypes(projectContext.state.currentProject.id).then((res) => {
      setTrainTypes(res)
      setIsLoading(false)
    })
  }

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value)
    setTitleErrorMessage('')
  }

  const onChangeColor = (newColor: string) => {
    setColor(newColor)
    setColorErrorMessage('')
  }

  const setDatePickerFocus = (focused: any) => {
    setDatePickerFocused(focused.focused)
  }

  const outSideRangeDate = () => {
    return false
  }

  const onChangeStartTime = (time: moment.Moment) => {
    setStartTime(time)
    setDateErrorMessage('')
  }

  const onChangeControlAreaId = (trainId: number) => {
    setControlAreaId(trainId)
    setControlAreaErrorMessage('')
  }

  const onChangeTrainTypeId = (trainId: number) => {
    setTrainTypeId(trainId)
    const trainType = trainTypes.find((thisTrain) => thisTrain.id === trainId)
    if (trainType) {
      setTitle(trainType.title)
      setNumberOfWagons(
        trainType.locomotive_types ? trainType.locomotive_types.length : 0,
      )
      setTitleErrorMessage('')
      setTrainTypeErrorMessage('')
    }
  }

  const onCreateForGroupChange = (checked: any) => {
    setCreateForGroup(checked)
  }

  const onConsiderWeekendChange = (checked: any) => {
    setConsiderWeekends(checked)
  }

  const onSubmitForm = (e: any, value?: boolean) => {
    e.preventDefault()
    setIsLoading(true)
    let error = false

    if (!title) {
      setTitleErrorMessage(t('fill_out_w_param', { param: t('title') }))
      error = true
    }

    if (!color) {
      setColorErrorMessage(t('select_train_color'))
      error = true
    }

    if (trainCreateMode === 'copy_train' && trainTypeId === 0) {
      setTrainTypeErrorMessage(
        t('select_w_param', { param: t('wagon_template') }),
      )
      error = true
    }

    if (!startTime) {
      setDateErrorMessage(t('fill_out_w_param', { param: t('start_date') }))
      error = true
    }

    if (!controlAreaId) {
      setControlAreaErrorMessage(
        t('select_w_param', { param: t('control_area') }),
      )
      error = true
    }

    if (trainCreateMode !== 'copy_train' && !wagon) {
      error = true
    }

    if (!dateChangeModal) {
      setDateChangeModal(true)
      setIsLoading(false)
    } else {
      if (!error) {
        const trainId = train ? train.id : undefined
        const updatedTrain: IConstructionTrain = {
          id: trainId,
          title,
          train_color: color,
          status,
          startTime,
          control_area_id: controlAreaId,
          control_area_group_id: createForGroup ? controlAreaGroupId : null,
          project_id: projectId,
          train_type_id: trainTypeId > 0 ? trainTypeId : undefined,
          change_trains_in_group: value ? value : createForGroup,
          ignore_weekends: considerWeekends,
          ignore_holidays: ignoreHolidays,
          locomotive: trainCreateMode !== 'copy_train' ? wagon : undefined,
        }
        const constructionTrainApiCall = createConstructionTrain(
          updatedTrain,
          projectId,
        )
        constructionTrainApiCall.then(() => {
          setIsLoading(false)
          if (onSave) {
            onSave()
          }

          if (!createMultiple) {
            onCloseModal()
          } else {
            setTitle('')
          }
        })
      } else {
        setIsLoading(false)
      }
    }
  }

  const onCloseModal = () => {
    setTitle('')
    setTrainTypeId(0)
    closeModal()
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onAddWagon = () => {
    setWagonForm(true)
  }

  const setLocomotive = (wa: any) => {
    setWagon(wa)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  return (
    <>
      <div className={'flex flex-row'}>
        <div className={`${train !== undefined ? 'w-full' : 'w-full'}`}>
          <form className={styleClass.root} onSubmit={onSubmitForm}>
            <div className={styleClass.inputGroup}>
              <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
                <div className={'flex'}>
                  <label className={'text-sm font-medium my-2 capitalize'}>
                    {t('start_date_required')}
                  </label>
                  {dateErrorMessage ? (
                    <label
                      className={'pl-2 text-sm my-2'}
                      style={{ color: 'Red' }}
                    >
                      {dateErrorMessage}
                    </label>
                  ) : null}
                </div>
                <div className={'flex justify-between w-full'}>
                  <SingleDatePicker
                    firstDayOfWeek={1}
                    date={startTime}
                    onDateChange={onChangeStartTime}
                    renderDayContents={renderDayContents}
                    focused={datePickerFocused}
                    onFocusChange={setDatePickerFocus}
                    id="datePicker-wagon"
                    small={true}
                    isOutsideRange={outSideRangeDate}
                    showDefaultInputIcon={true}
                    noBorder={true}
                    numberOfMonths={1}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    required={true}
                    hideKeyboardShortcutsPanel={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <RadioItems
                items={trainCreateModeTypes}
                onClick={onTrainCreateModeTypeChange}
                value={trainCreateMode}
              />
              <div className={styleClass.inputGroup}>
                {trainCreateMode === 'copy_train' ? (
                  <>
                    <div className={`${trainTypeId > 0 ? 'w-1/2' : 'w-full'}`}>
                      <Selector
                        items={trainTypes}
                        selectedItemId={trainTypeId}
                        onSelect={onChangeTrainTypeId}
                        label={t('train_template')}
                        dataFields={['title']}
                        errorMessage={trainTypeErrorMessage}
                        required={true}
                        fontSize={'sm'}
                        fontWeight={'bold'}
                      />
                    </div>
                    {trainTypeId > 0 && (
                      <div className={'px-2 w-1/2'}>
                        <div className={styleClass.wagonLabelRoot}>
                          <div className={'flex flex-row'}>
                            <p className={styleClass.wagonLabel}>
                              {t('number_of_wagons')}
                            </p>
                          </div>
                          <div className={'flex flex-row'}>
                            <p className={styleClass.wagonLabel}>
                              {numberOfWagons}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </div>

            <div className={styleClass.inputGroup}>
              <div className={'w-full'}>
                <Input
                  block={true}
                  label={t('title')}
                  onChange={onChangeTitle}
                  value={title}
                  required={true}
                  errorMessage={titleErrorMessage}
                  autoFocus={true}
                />
              </div>
            </div>

            <div className={styleClass.inputGroup}>
              <div className={'w-1/2'}>
                <Selector
                  items={controlAreas}
                  selectedItemId={controlAreaId}
                  onSelect={onChangeControlAreaId}
                  label={t('control_area')}
                  dataFields={['title']}
                  required={true}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  errorMessage={controlAreaErrorMessage}
                />
              </div>

              <div className={'w-1/2'}>
                <Selector
                  items={trainColors(t).map((col) => ({
                    ...col,
                    trainColor: col.id,
                  }))}
                  selectedItemId={color}
                  onSelect={onChangeColor}
                  label={t('color')}
                  dataFields={['name']}
                  required={true}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  errorMessage={colorErrorMessage}
                />
              </div>
            </div>

            <div className={styleClass.inputGroup}>
              <div
                className={'pl-2 mt-2 flex items-center w-1/2'}
                onClick={() => onCreateForGroupChange(!createForGroup)}
              >
                <Checkbox
                  disableTab
                  onChange={() => onCreateForGroupChange(!createForGroup)}
                  valueProp={createForGroup}
                />
                <p className={`pl-2  text-gray-500 text-sm text-sm flex-wrap`}>
                  {t('add_all_control_areas_in_the_same_group')}
                </p>
              </div>
              <div
                className={'pl-2 mt-2 flex items-center w-1/2'}
                onClick={() => onConsiderWeekendChange(!considerWeekends)}
              >
                <Checkbox
                  disableTab
                  onChange={() => onConsiderWeekendChange(!considerWeekends)}
                  valueProp={considerWeekends}
                />
                <p className={`pl-2  text-gray-500 text-sm text-sm flex-wrap`}>
                  {t('consider_weekends_as_working_days')}
                </p>
              </div>
              <div
                className={'pl-2 mt-2 flex items-center w-1/2'}
                onClick={() => setIgnoreHolidays(!ignoreHolidays)}
              >
                <Checkbox
                  disableTab
                  onChange={() => setIgnoreHolidays(!ignoreHolidays)}
                  valueProp={ignoreHolidays}
                />
                <p
                  className={`pl-2  text-gray-500 text-sm text-sm flex-wrap first-capitalize`}
                >
                  {t('consider_holidays_as_working_periods')}
                </p>
              </div>
            </div>

            <ModalFooter>
              <Button onClick={closeModal}>{t('cancel')}</Button>
              {(trainCreateMode === 'copy_train' || wagon) && (
                <Button
                  type={Button.ButtonType.SECONDARY}
                  noTextWrap={true}
                  onClick={onMultipleCreate}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : t('add_multiple')}
                </Button>
              )}
              <Button
                type={Button.ButtonType.PRIMARY}
                disabled={isLoading}
                onClick={
                  trainCreateMode === 'copy_train' || wagon
                    ? onSingleCreate
                    : onAddWagon
                }
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  t('add_w_param', {
                    param:
                      trainCreateMode === 'copy_train' || wagon
                        ? ''
                        : capFirstLetter(t('wagon')),
                  })
                )}
              </Button>
            </ModalFooter>
          </form>
        </div>
      </div>
      {wagonForm && (
        <WagonFormForTrain
          open={wagonForm}
          closeModal={() => setWagonForm(false)}
          sendWagonForm={setLocomotive}
        />
      )}
    </>
  )
}

export default TrainForm
