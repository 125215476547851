import { createContext } from 'react'

export interface IMultiTouchContext {
  actions: {
    updateTouchId: (res: number) => void
    clearTouchId: () => void
  }
  state: number | null
}

export type MultiTouchContext = IMultiTouchContext

export const MultiTouchContext = createContext<MultiTouchContext>(
  {} as IMultiTouchContext,
)
