import { isBoolean, isEqual } from 'lodash'
import {
  createRef,
  PureComponent,
  MouseEvent,
  KeyboardEvent,
  ChangeEvent,
} from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Direction } from 'src/components/multi-selector/MultiSelector'
import UserIcon from 'src/components/user/UserIcon'
import ErrorMessage from 'src/ui-elements/error-message/ErrorMessage'
import Icon from 'src/ui-elements/icon/Icon'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import { classNames } from 'src/utility/utils'

interface ITypeFaceProps extends WithTranslation {
  data: any[]
  onSelect: (item: any | null) => void | Promise<any>
  dataFields: any[]
  isLoading?: boolean
  placeholder?: string
  onBlur?: () => void
  autoFocus?: boolean
  icon?: JSX.Element
  userSelector?: boolean
  disable?: boolean
  hasSelectedItem?: boolean
  inMobile?: boolean
  upperCase?: boolean
  openDirection?: Direction
}

interface ITypeFaceState {
  loading: boolean
  error: string
  inputValue: string
  filteredData: any[]
  selectedValueKey: number
  selectedValue: any | undefined
  blurred: boolean
  dropdownPosition: {
    top: number
    right: number
    left: number
    bottom: number
    width: number
    height: number
  }
}

class TypeHead extends PureComponent<ITypeFaceProps, ITypeFaceState> {
  private dropdown: any

  private static styleClass = {
    listWrapper: () => classNames('w-full', 'relative'),
    list: (openDirection: Direction) =>
      classNames(
        'list-reset',
        openDirection === Direction.UP
          ? 'list-floating-dropUp'
          : 'list-floating-dropDown',
        openDirection === Direction.UP ? 'border-b-0' : 'border-t-0',
        'overflow-y-auto',
        'bg-white',
        'border-gray-300',
        'border',
        'absolute',
        'z-20',
        'pt-2',
        'mb-2',
        'selectorScroll',
      ),
    listItem: (isSelected: boolean, color?: string) =>
      classNames(
        'flex',
        'items-center',
        'px-3',
        'py-2',
        'text-sm',
        'leading-5',
        'hover:text-gray-900',
        'focus:outline-none',
        'focus:bg-gray-100',
        'focus:text-gray-900',
        color
          ? `hover:bg-${color}${color !== 'green' ? '-100' : ''}`
          : 'hover:bg-gray-100',
        color && 'bg-' + color + '-400',
        color ? 'text-' + color + '-900' : 'text-gray-700',
        isSelected ? 'text-blue-one' : null,
      ),
    cover: classNames('fixed', 'inset-x-0', 'inset-y-0', 'z-20'),
  }

  public constructor(props: ITypeFaceProps) {
    super(props)
    const data = props.data
    this.state = {
      loading: false,
      error: '',
      inputValue: '',
      filteredData: data,
      selectedValueKey: this.props.hasSelectedItem ? 0 : -1,
      selectedValue: this.props.hasSelectedItem ? data[0] : undefined,
      blurred: true,
      dropdownPosition: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 0,
        height: 0,
      },
    }
    this.dropdown = createRef()
  }

  public componentDidMount() {
    this.setPosition()
  }

  private onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { dataFields } = this.props
    e.preventDefault()
    const value = e.target.value
    const searchData = this.props.data.filter((u) => {
      let filterValue = ''
      dataFields.map((d) => {
        filterValue += `${u[d]}`
      })
      return filterValue
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
    })
    this.setState({
      inputValue: value,
      filteredData: value ? searchData : [],
      selectedValue: value ? searchData[0] : undefined,
    })
    e.stopPropagation()
  }

  private onBlur = () => {
    this.setState({ blurred: true })
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  private onFocus = () => {
    this.setState({ blurred: false })
  }

  private selectValue = (item: any) => {
    const { onSelect } = this.props
    this.setState({
      inputValue: '',
      selectedValue: item,
      selectedValueKey: item?.id,
      filteredData: [],
    })
    onSelect(item)
  }

  private handleKeypress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.selectValue(this.state.selectedValue)
      e.stopPropagation()
    }
  }

  private handleArrowKeys = (e: KeyboardEvent) => {
    const { selectedValueKey, filteredData } = this.state
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      if (selectedValueKey !== filteredData.length - 1) {
        this.setState((prevState) => ({
          selectedValue: prevState.filteredData[prevState.selectedValueKey + 1],
          selectedValueKey: prevState.selectedValueKey + 1,
        }))
      }
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (selectedValueKey !== 0) {
        this.setState((prevState) => ({
          selectedValue: prevState.filteredData[prevState.selectedValueKey - 1],
          selectedValueKey: prevState.selectedValueKey - 1,
        }))
      }
    }
    e.stopPropagation()
  }

  private handleClick = (item: any) => (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    e.nativeEvent.stopImmediatePropagation()
    this.selectValue(item)
  }

  private renderIcon = (item?: any) => {
    const { icon, userSelector } = this.props
    if (item && item.icon) {
      return (
        <div>
          <Icon
            icon={item.icon}
            style={{ width: 18, height: 18, marginRight: 5 }}
          />
        </div>
      )
    }
    if (userSelector && item) {
      return (
        <div className="mr-1.5">
          <UserIcon
            userId={item.id}
            firstName={''}
            lastName={''}
            large={false}
            image_url={item.image_url}
          />
        </div>
      )
    }
    return icon ? <div>{icon}</div> : null
  }

  private listItem = (item: any, key: number) => {
    const { selectedValue } = this.state
    const { dataFields } = this.props
    let itemName = ''
    dataFields.map((d) => {
      itemName += isBoolean(item[d])
        ? item[d]
          ? `(${this.props.t(d)}) `
          : ` `
        : `${item[d]} `
    })
    return (
      <li
        key={`${key}-multiselect`}
        className={TypeHead.styleClass.listItem(
          isEqual(item?.id, selectedValue?.id),
          item.trainColor,
        )}
        onMouseDown={this.handleClick(item)}
      >
        {this.renderIcon(item)}
        <span className="pl-1">
          {this.props.upperCase ? itemName.toUpperCase() : itemName}
        </span>
      </li>
    )
  }

  private setPosition() {
    const clientBound = this.dropdown.current.getBoundingClientRect()
    const tmpState = { ...this.state.dropdownPosition }
    tmpState.bottom = clientBound.bottom
    tmpState.top = clientBound.top
    tmpState.left = clientBound.left
    tmpState.right = clientBound.right
    tmpState.width = clientBound.width
    tmpState.height = clientBound.height

    this.setState({ dropdownPosition: tmpState })
  }

  public render() {
    const {
      error,
      loading,
      inputValue,
      filteredData,
      blurred,
      dropdownPosition,
    } = this.state
    const { placeholder, inMobile, openDirection } = this.props
    return (
      <div
        className={`${TypeHead.styleClass.listWrapper()} ${
          openDirection === Direction.UP ? 'flex !flex-col-reverse' : ''
        }`}
        ref={this.dropdown}
      >
        <div className="z-[40]">
          <Input
            type="text"
            value={inputValue}
            onChange={this.onInputChange}
            onKeyPress={this.handleKeypress}
            disabled={this.props.disable}
            onKeyDown={this.handleArrowKeys}
            block={true}
            noPadding={true}
            autoFocus={!!this.props.autoFocus}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            placeholder={placeholder}
            inMobile={inMobile}
            style={inMobile ? {} : { height: 34 }}
          />
        </div>
        {!blurred && filteredData.length > 0 ? (
          <div
            style={{
              bottom:
                openDirection === Direction.UP
                  ? `${
                      filteredData.length > 3 ? 172 : filteredData.length * 43
                    }px`
                  : 0,
            }}
          >
            <ul
              className={`${TypeHead.styleClass.list(
                openDirection ?? Direction.DOWN,
              )}`}
              style={{
                width: dropdownPosition.width,
                height:
                  filteredData.length > 3 ? 172 : filteredData.length * 43,
              }}
            >
              {filteredData.map((i, key) => {
                return this.listItem(i, key)
              })}
            </ul>
          </div>
        ) : null}
        {loading ? <Spinner /> : null}
        {error ? <ErrorMessage text={error} /> : null}
      </div>
    )
  }
}

export default withTranslation()(TypeHead)
