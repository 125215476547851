import Close from '@icons/close.svg'
import LinkedServices from '@icons/linked_services.svg'
import { useTranslation } from 'react-i18next'
import CircularButton from 'src/components/CircularButton'

export interface IDependencyControlProps {
  showArrows: boolean
  toggleShowArrow: () => void
  clearDependency: () => void
}

const DependencyControl = ({
  showArrows,
  toggleShowArrow,
  clearDependency,
}: IDependencyControlProps) => {
  const { t } = useTranslation()
  return (
    <>
      <CircularButton
        onClick={clearDependency}
        className={'!bg-red-500 fill-white'}
        tooltipText={t('close_dependency_view')}
      >
        <Close fontSize={24} />
      </CircularButton>
      <CircularButton
        onClick={toggleShowArrow}
        active={showArrows}
        tooltipText={
          showArrows ? t('hide_dependency_lines') : t('show_dependency_lines')
        }
      >
        <LinkedServices className={'text-xl'} />
      </CircularButton>
    </>
  )
}

export default DependencyControl
