import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportContext } from 'src/context/ExportContext/ExportContext'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'

const ExportToast = () => {
  const { state } = useContext(ExportContext)
  const { t } = useTranslation()

  return (
    <div className="absolute bottom-20 z-50 left-20 w-auto ">
      {state.pendingExports.length > 0 && (
        <div
          className="flex items-center w-full max-w-xs p-4  text-gray-500 bg-white space-x-4 divide-x divide-gray-500 rounded-lg shadow-lg"
          role="alert"
        >
          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-blue-root rounded-full">
            <MaterialIcon icon="download" />
            <div className="absolute -top-1 -right-1 w-3 h-3 bg-orange-one rounded-full animate-ping" />
          </div>
          <div className="flex flex-col pl-2">
            <div className="text-sm font-medium text-gray-700">
              {t('export_in_progress')}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ExportToast
