import { QueryClient, QueryFunction } from '@tanstack/react-query'
import { request } from 'src/service/service-utils'

const defaultQueryFn: QueryFunction = ({ queryKey }) =>
  request(queryKey.join('/'), 'GET')

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      queryFn: defaultQueryFn,
    },
  },
})
