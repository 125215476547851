import { IRole } from './OrgTypes'
import { request } from './service-utils'

export const createRole = (role: IRole, projectId: number): Promise<IRole> => {
  return request(`projects/${projectId}/roles`, 'POST', role)
}

export const editRole = (taskType: IRole): Promise<IRole> => {
  return request(`roles/${taskType.id}`, 'PUT', taskType)
}

export const getProjectRoles = (projectId: number): Promise<IRole[]> => {
  return request(`projects/${projectId}/roles`, 'GET')
}

export const deleteRole = (task: IRole) => {
  return request(`/roles/${task.id}`, 'DELETE', {}, true)
}
