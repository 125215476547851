import AppCommunicator from './AppCommunicator'

class ResolutionHandler {
  private readonly isMobile: boolean = false

  constructor() {
    this.isMobile = this.checkIsMobile()
  }

  private readonly checkIsMobile = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })
  }

  readonly shouldShowMobileVersion = () => {
    return AppCommunicator.isApp || this.isMobile
  }
}

export default new ResolutionHandler()
