import Close from '@icons/close.svg'
import * as React from 'react'
import { useState, useEffect, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import LinkfyText from 'src/components/linkfyText/LinkfyText'
import { getSystemNotifications } from 'src/service/ActivityLogService'
import { ActivityType, IActivityLog } from 'src/service/OrgTypes'
import { ModalContext } from '../../context/ModalContextProvider/ModalContext'
import { ProjectContext } from '../../context/ProjectContextProvider/ProjectContext'
import history from '../../history'
import { classNames } from '../../utility/utils'

const styleClass = {
  root: (hide: boolean) =>
    classNames(
      hide && 'hidden',
      'bg-light-blue',
      'h-10',
      'flex',
      'justify-between',
      'items-center',
      'px-4',
      'fixed',
      'top-0',
      'left-0',
      'w-screen',
      'z-[1000]',
      'text-center',
    ),
  btn: classNames(
    'bg-white',
    'px-3',
    'py-1',
    'text-blue-root',
    'text-sm',
    'text-semibold',
    'mr-4',
  ),
  title: classNames(
    'leading-5',
    'font-semibold',
    'text-dark-blue',
    'text-xsm',
    'pl-2',
    'whitespace-nowrap',
  ),
  description: classNames(
    'leading-5',
    'font-light',
    'text-white',
    'text-sm',
    'whitespace-nowrap',
    'flex',
  ),
}

const Announcemnet = () => {
  const { t } = useTranslation()
  const [hideAnnouncement, setHideAnnouncement] = useState<boolean>(false)
  const [latestNotifs, setLatestNotifs] = React.useState<IActivityLog>()
  const projectContext = useContext(ProjectContext)
  const modalContext = useContext(ModalContext)
  const projectRef = useRef<number>()

  const fetchActivityLogs = async (projectId: number) => {
    const systemNotifications = await getSystemNotifications(projectId)
    const lastSavedNotfication = Number(
      localStorage.getItem('read_system_notification'),
    )
    const first = systemNotifications.filter(
      (notif) =>
        notif.activity_type === ActivityType.SYSTEM_NOTIFICATION &&
        notif.id > lastSavedNotfication,
    )[0]
    if (first) {
      setLatestNotifs(first)
    }
  }

  const getDescription = (text: string) => {
    return text.length > 200 ? text.substring(0, 200) : text
  }

  const onCancel = () => {
    if (latestNotifs && localStorage) {
      localStorage.setItem(
        'read_system_notification',
        JSON.stringify(latestNotifs.id),
      )
    }
    setHideAnnouncement(true)
  }

  const onReadMore = () => {
    if (latestNotifs) {
      history.push(
        `?modal=` +
          'notification' +
          `&id=` +
          latestNotifs.id +
          `&project=` +
          latestNotifs.project_id +
          '',
      )
      if (modalContext.actions) {
        modalContext.actions.checkURL()
      }
    }
  }

  useEffect(() => {
    if (projectContext.state.currentProject) {
      if (projectContext.state.currentProject.id !== projectRef.current) {
        fetchActivityLogs(projectContext.state.currentProject.id)
        projectRef.current = projectContext.state.currentProject.id
      }
    }
  }, [projectContext])

  return (
    <div>
      {latestNotifs && (
        <div className={styleClass.root(hideAnnouncement)}>
          <div
            className={'flex flex-row items-center justify-center gap-2 w-full'}
          >
            <p className={styleClass.title}>
              {t('system_notification')}:&nbsp;
            </p>
            <p className="text-dark-blue break-words">
              <LinkfyText value={getDescription(latestNotifs.newValue)} />
            </p>
            {latestNotifs.newValue && latestNotifs.newValue.length > 200 && (
              <span
                className={'flex items-center text-blue-900 cursor-pointer'}
              >
                ...
                <a
                  onClick={() => onReadMore()}
                  className={`${styleClass.description} cursor-pointer underline`}
                >
                  {t('read_more')}
                </a>
              </span>
            )}
          </div>

          <div className={'flex items-center'}>
            <Close
              className={
                'h-5 w-5 flex items-center fill-gray-600 cursor-pointer font-bold'
              }
              onClick={() => onCancel()}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Announcemnet
