import NotificationsSvg from '@icons/notifications.svg'
import { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportContext } from 'src/context/ImportContext/ImportContext'
import DocButton from 'src/document/components/base/DocButton'
import useSocket from 'src/hooks/useSocket'
import { useNotifications } from 'src/query/notification'
import ExportService from 'src/service/ExportService'
import { markAllAsRead } from 'src/service/NotificationsService'
import { IActivityLog } from 'src/service/OrgTypes'
import { classNames } from 'src/utility/utils'
import { ExportContext } from '../../context/ExportContext/ExportContext'
import CloseClickOutside from '../click-outside/CloseClickOutside'
import useProjectId from '../hooks/useProjectId'
import ExportNotification from './ExportNotification'
import Notifications from './Notifications'
import useNewNotification from './hook/useNewNotification'
import ImportNotifications from './import/ImportNotification'

interface Props {
  userId: number
  menuExpanded: boolean
}

const styleClass = {
  row: classNames('hover:bg-grey-lightest'),
  img: classNames('rounded-full', 'inline', 'h-6', 'w-6'),
  dropDownContainer: classNames(
    'shadow-md',
    'topmenu-dropdown',
    'inline-block',
    'bg-white',
    'fixed',
    'overflow-hidden',
    'rounded-xs',
  ),
  tabs: (active: boolean) =>
    classNames(
      'inline-block p-4 pb-2 flex items-center justify-center text-blue-root cursor-pointer',
      active && 'active text-black bg-white border-b-2 border-blue-root',
    ),
  nums: (active: boolean, hide: boolean) =>
    classNames(
      hide && 'hidden',
      'ml-2 w-6 h-4 flex justify-center items-center rounded-md text-xxs',
      active ? 'bg-red text-white' : 'bg-gray-200 text-gray-400',
    ),
}

enum NotificationTab {
  Notifications,
  Exports,
  Imports,
}

const NotificationCenter: FC<Props> = ({ userId, menuExpanded }) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<NotificationTab>(
    NotificationTab.Notifications,
  )
  const projectId = useProjectId()
  const { t } = useTranslation()

  const { state, actions } = useContext(ExportContext)
  const { state: importState } = useContext(ImportContext)
  const showNotification = useNewNotification()

  const { exportTasks } = state
  const {
    reload,
    activityLogs,
    totalCount,
    unreadCount,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useNotifications(userId)

  const receivedNotification = (notification: IActivityLog) => {
    showNotification(notification)
    reload()
  }

  useSocket({
    channel: 'NotificationChannel',
    topic: '',
    deps: [projectId, userId],
    received: receivedNotification,
  })

  const toggleDropdown = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setShowDropdown((prev) => !prev)
  }

  const markAllRead = async (e: any) => {
    e.preventDefault()
    try {
      await markAllAsRead(projectId)
      await ExportService.markAllExportTasksRead()
      reload()
      actions.reload()
    } catch (error) {
      console.error(error)
    }
  }

  const onLoadMore = () => {
    fetchNextPage()
  }

  const length =
    (unreadCount ?? 0) + exportTasks.length + importState.importTasks.length
  return (
    <CloseClickOutside
      onClose={() => {
        setShowDropdown(false)
      }}
    >
      <div className="flex items-center justify-between">
        <div
          className="flex items-center justify-center h-full"
          onClick={toggleDropdown}
        >
          <div className={classNames('relative', menuExpanded && 'mr-4')}>
            <NotificationsSvg
              className={classNames('fill-blue-root text-xxl mt-1')}
            />
            {length > 0 ? (
              <span
                className={classNames(
                  'rounded-full ',
                  'bg-red-500',
                  'text-xs',
                  'text-white',
                  'font-bold',
                  'px-1',
                  'absolute',
                  'right-0',
                  'top-0',
                )}
              >
                {length}
              </span>
            ) : null}
          </div>
          {menuExpanded && <div>{t('notifications')}</div>}
        </div>

        {showDropdown && (
          <div
            className={styleClass.dropDownContainer}
            style={{
              top: 60,
              zIndex: 50,
              minWidth: '380px',
              maxWidth: '500px',
              height: 'mx-content',
              maxHeight: 'calc(100vh-20px)',
              width: '32vw',
              left: menuExpanded ? 240 : 60,
              cursor: 'default',
            }}
          >
            <div className="px-6 shadow pt-6">
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-[1rem] font-medium text-center text-gray-600">
                  {t('notifications')}
                </h1>
                <DocButton
                  onClick={markAllRead}
                  flat={true}
                  text={t('mark_everything_as_read')}
                />
              </div>
              <ul className="flex capitalize flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                <li className="mr-2">
                  <div
                    onClick={() => setActiveTab(NotificationTab.Notifications)}
                    className={styleClass.tabs(
                      activeTab === NotificationTab.Notifications,
                    )}
                  >
                    {t('activity_log')}
                  </div>
                </li>
                <li className="mr-2">
                  <div
                    onClick={() => setActiveTab(NotificationTab.Exports)}
                    className={styleClass.tabs(
                      activeTab === NotificationTab.Exports,
                    )}
                  >
                    {t('exports')}
                    <span
                      className={styleClass.nums(
                        activeTab === NotificationTab.Exports,
                        exportTasks.length === 0,
                      )}
                    >
                      {exportTasks.length}
                    </span>
                  </div>
                </li>
                <li className="mr-2">
                  <div
                    onClick={() => setActiveTab(NotificationTab.Imports)}
                    className={styleClass.tabs(
                      activeTab === NotificationTab.Imports,
                    )}
                  >
                    {t('imports')}
                    <span
                      className={styleClass.nums(
                        activeTab === NotificationTab.Imports,
                        importState.importTasks.length === 0,
                      )}
                    >
                      {importState.importTasks.length}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="mt-4 mb-2 min-h-18">
              {activeTab === NotificationTab.Notifications && (
                <Notifications
                  activityLogs={activityLogs}
                  closeDropdown={() => setShowDropdown(false)}
                  paginationLoading={isFetchingNextPage}
                  loading={false}
                  onLoadMore={onLoadMore}
                  totalCount={totalCount}
                  reload={reload}
                  hasNextPage={hasNextPage}
                />
              )}
              {activeTab === NotificationTab.Exports && <ExportNotification />}
              {activeTab === NotificationTab.Imports && <ImportNotifications />}
            </div>
          </div>
        )}
      </div>
    </CloseClickOutside>
  )
}

export default NotificationCenter
