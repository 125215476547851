import Icon from 'src/ui-elements/icon/Icon'

export const othersDependOnThisIcon = () => {
  return (
    <div className="inline-block ml-auto max-w-[36px] min-w-[36px]">
      <div className={'flex'}>
        <Icon icon={Icon.IconType.CIRCLE} className="shrink-0 basis-[18px]" />
        <Icon
          icon={Icon.IconType.ARROW_EAST}
          className="-ml-1 shrink-0 basis-[18px]"
        />
      </div>
    </div>
  )
}

export const dependentOnOthersIcon = () => {
  return (
    <div className="inline-block ml-auto max-w-[36px] min-w-[36px]">
      <span className={'flex'}>
        <Icon
          icon={Icon.IconType.ARROW_EAST}
          className="shrink-0 basis-[18px]"
        />
        <Icon
          icon={Icon.IconType.CIRCLE}
          className="-ml-1 shrink-0 basis-[18px]"
        />
      </span>
    </div>
  )
}

export const twoWayDependencyIcon = () => {
  return (
    <div className="inline-block ml-auto max-w-[50px] min-w-[50px]">
      <div className={'flex'}>
        <Icon
          icon={Icon.IconType.ARROW_EAST}
          className="shrink-0 basis-[18px]"
        />
        <Icon
          icon={Icon.IconType.CIRCLE}
          className="-ml-1 shrink-0 basis-[18px]"
        />
        <Icon
          icon={Icon.IconType.ARROW_EAST}
          className="-ml-1 shrink-0 basis-[18px]"
        />
      </div>
    </div>
  )
}
