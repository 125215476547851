import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import DocumentTypeService from 'src/document/services/DocumentTypeService'
import { IDocumentType } from 'src/document/types/IFlow'
import Button from 'src/ui-elements/button/Button'
import List from 'src/ui-elements/list/List'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import DocumentTypeForm from './DocumentTypeForm'

const DocumentTypeList = () => {
  const { t } = useTranslation()
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([])
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<IDocumentType>()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [reload, setReload] = useState(false)
  const [reloadTable, setReloadTable] = useState(false)

  useEffect(() => {
    DocumentTypeService.getProjectDocumentTypes().then((res) => {
      setDocumentTypes(res)
      setReloadTable((prev) => !prev)
    })
  }, [reload])

  const actionButtons = () => {
    return (
      <Button
        type={Button.ButtonType.PRIMARY}
        onClick={() => setShowCreateModal(true)}
      >
        {t('new_w_param', { param: t('document_type').toLowerCase() })}
      </Button>
    )
  }

  const columns = useMemo(() => {
    return [
      {
        name: 'name',
        size: '200',
        id: 'name',
        dataField: 'name',
      },
      {
        name: 'code',
        size: '200',
        id: 'code',
        dataField: 'code',
      },
      {
        name: 'description',
        size: '400',
        id: 'description',
        dataField: 'description',
      },
    ]
  }, [])

  const onSubmitCreate = () => {
    setReload((prev) => !prev)
    onCancelCreate()
  }

  const onSubmitEdit = () => {
    setReload((prev) => !prev)
    onCancelEdit()
  }

  const deleteSelectedDocumentType = () => {
    if (selectedDocumentType) {
      DocumentTypeService.deleteDocumentType(selectedDocumentType?.id).then(
        () => {
          setReload((prev) => !prev)
          setShowDeleteModal(false)
        },
      )
    }
  }

  const onEditDocumentType = (item: IDocumentType) => {
    setSelectedDocumentType(item)
    setShowEditModal(true)
  }

  const onDeleteDocumentType = (item: IDocumentType) => {
    setSelectedDocumentType(item)
    setShowDeleteModal(true)
  }

  const onCancelCreate = () => {
    setShowCreateModal(false)
    setSelectedDocumentType(undefined)
  }

  const onCancelEdit = () => {
    setShowEditModal(false)
    setSelectedDocumentType(undefined)
  }

  const onCancelDelete = () => {
    setShowDeleteModal(false)
    setSelectedDocumentType(undefined)
  }

  return (
    <div className={TabPageStyleClass.root}>
      <List
        actionButton={actionButtons()}
        columns={columns}
        data={documentTypes}
        tableName={'documentTypesList'}
        disableColumnSelector={true}
        actionMenu={[
          {
            name: t('edit'),
            action: onEditDocumentType,
          },
          {
            name: t('delete'),
            action: onDeleteDocumentType,
          },
        ]}
        reload={reloadTable}
        itemsPerPage={50}
      />
      {showCreateModal && (
        <DocumentTypeForm onSubmit={onSubmitCreate} onCancel={onCancelCreate} />
      )}
      {showEditModal && selectedDocumentType && (
        <DocumentTypeForm
          documentType={selectedDocumentType}
          onSubmit={onSubmitEdit}
          onCancel={onCancelEdit}
        />
      )}
      {showDeleteModal && selectedDocumentType && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={onCancelDelete}
          onDelete={deleteSelectedDocumentType}
          itemIdnType={`${selectedDocumentType.name} (${t('document_type')})`}
          itemName={`${selectedDocumentType.name}`}
        />
      )}
    </div>
  )
}

export default DocumentTypeList
