import { useState, useEffect, useContext, useRef } from 'react'
import { MultiTouchContext } from 'src/context/MultiTouchContext/MultiTouchContext'

const useLongPress = (
  longPressCallback: (e: any) => void,
  ms = 500,
  singlePressCallback?: (e: any) => void,
) => {
  const [startLongPress, setStartLongPress] = useState(false)
  const eventRef = useRef<any>()
  const touchIdRef = useRef<any>()

  const multiTouchContext = useContext(MultiTouchContext)

  const { state: touchId } = multiTouchContext
  const { updateTouchId, clearTouchId } = multiTouchContext.actions

  useEffect(() => {
    let timerId: any
    if (startLongPress) {
      timerId = setTimeout(callback, ms, eventRef.current)
    } else {
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [longPressCallback, ms, startLongPress])

  const callback = (e: any) => {
    if (touchId === touchIdRef.current) {
      longPressCallback(e)
      clearTouchId()
    }
  }

  const setOnMouseEvent = (e: any, long: boolean) => {
    if (long) {
      e.persist()
      eventRef.current = e
      touchIdRef.current = new Date().getTime()
      updateTouchId(touchIdRef.current)
    } else {
      singlePressCallback?.(e)
    }
    setStartLongPress(long)
  }

  return {
    onMouseDown: (e: any) => setOnMouseEvent(e, true),
    onMouseUp: (e: any) => setOnMouseEvent(e, false),
    onTouchStart: (e: any) => setOnMouseEvent(e, true),
    onTouchEnd: (e: any) => setOnMouseEvent(e, false),
  }
}

export default useLongPress
