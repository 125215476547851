import { formatQueryParams, getDomain } from '../utility/utils'
import { tableKeeperRequest } from './service-utils'

const domain = getDomain()

type Options = {
  // Get project-level shared configuration
  projectLevel: boolean
}

export const getTable = <T = unknown>(
  projectId: number,
  tableName: string,
  projectLevel: boolean,
): Promise<T> => {
  const tableType = projectLevel ? 'project-table' : 'table'
  return tableKeeperRequest(
    `/${tableType}/domain/${domain}/projects/${projectId}/tables/${tableName}`,
    'GET',
  )
}

export const setTableConfig = <T = unknown>(
  projectId: number,
  tableName: string,
  data: T,
  options?: Options,
): Promise<T> => {
  const tableType = options?.projectLevel ? 'project-table' : 'table'
  return tableKeeperRequest(
    `/${tableType}/domain/${domain}/projects/${projectId}/tables/${tableName}`,
    'POST',
    data,
  )
}

export const setGanttConfig = <T = unknown>(
  projectId: number,
  data: T,
): Promise<T> => {
  return tableKeeperRequest(
    `/gannt/domain/${domain}/projects/${projectId}/gannt`,
    'POST',
    data,
  )
}

export const getGanttConfig = <T = unknown>(projectId: number): Promise<T> => {
  return tableKeeperRequest(
    `/gannt/domain/${domain}/projects/${projectId}/gannt`,
    'GET',
  )
}

export const clearTables = (): Promise<void> => {
  return tableKeeperRequest(
    `/table/domain/${domain}/clearAll`,
    'DELETE',
    {},
    true,
  )
}

export const getFilters = <T = unknown>(
  projectId: number,
  filterName: string,
): Promise<T> => {
  return tableKeeperRequest(
    `/table/domain/${domain}/projects/${projectId}/filters/${filterName}`,
    'GET',
  )
}

export const setFilters = <T = unknown>(
  projectId: number,
  filterName: string,
  data: T,
): Promise<T> => {
  return tableKeeperRequest(
    `/table/domain/${domain}/projects/${projectId}/filters/${filterName}`,
    'POST',
    data,
  )
}

export const dataDump = (
  key: string,
  data: unknown,
  projectId?: number,
): Promise<void> => {
  return tableKeeperRequest(
    `dataDumpster/domain/${domain}/dataDumpster/${key}${formatQueryParams({ projectId })}`,
    'POST',
    data,
  )
}

export const dataRetrieve = <T>(
  key: string,
  projectId?: number,
): Promise<T> => {
  return tableKeeperRequest(
    `dataDumpster/domain/${domain}/dataDumpster/${key}${formatQueryParams({ projectId })}`,
    'GET',
  )
}

export const dataDelete = (key: string, projectId?: number): Promise<void> => {
  return tableKeeperRequest(
    `dataDumpster/domain/${domain}/dataDumpster/${key}${formatQueryParams({ projectId })}`,
    'DELETE',
    {},
    true,
  )
}
