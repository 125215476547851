import * as React from 'react'

interface IHelperIconsProps {
  className?: string
  children?: React.ReactElement
  hide?: boolean
}

const HelperIcons: React.FC<IHelperIconsProps> = ({
  className,
  children,
  hide,
}: IHelperIconsProps) => {
  return hide ? (
    <></>
  ) : (
    <div
      className={`absolute z-[27] flex flex-row gap-4 items-start print:hidden ${className}`}
    >
      {children}
    </div>
  )
}

export default HelperIcons
