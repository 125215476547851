import { cloneElement } from 'react'
import * as React from 'react'

interface IConditionalWrap {
  children: JSX.Element
  condition: boolean
  wrap: (children: JSX.Element) => JSX.Element
}

const ConditionalWrap: React.FC<IConditionalWrap> = ({
  children,
  condition,
  wrap,
}): JSX.Element => {
  if (condition) return cloneElement(wrap(children))
  else return children
}

export default ConditionalWrap
