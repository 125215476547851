import queryString from 'query-string'
import { Component } from 'react'
import { withRouter } from 'react-router'
import {
  ITabContextState,
  ITabObject,
  ITabProps,
  TabContext,
  TabContextType,
} from './TabContext'

class TabContextProvider extends Component<ITabProps, ITabContextState> {
  public constructor(props: ITabProps) {
    super(props)
    this.state = {
      loading: true,
      tabIndex: props.defaultIndex,
      subTabIndex: props.defaultSubIndex || 0,
    }
  }

  public componentDidMount(): void {
    this.setStartIndexes()
  }

  private setStartIndexes = () => {
    this.setState({ loading: true })
    const currentIndex = this.getIndexFromSearch()
    this.setState(
      {
        tabIndex: currentIndex.mainIndex,
        subTabIndex: currentIndex.subIndex,
      },
      () => {
        this.setState({ loading: false })
      },
    )
  }

  public componentDidUpdate(
    prevProps: Readonly<ITabProps>,
    _prevState: Readonly<ITabContextState>,
  ): void {
    if (prevProps.location.search !== this.props.location.search) {
      this.setStartIndexes()
    }
  }

  private getIndexFromSearch = (): ITabObject => {
    const { location } = this.props
    const params: any = queryString.parse(location.search)
    const tabIndex = params.tabIndex
    const subTabIndex = params.subTabIndex
    const indexes: ITabObject = {
      mainIndex: this.state.tabIndex,
      subIndex: this.state.subTabIndex,
    }
    if (tabIndex !== undefined) {
      indexes.mainIndex = +tabIndex
    }
    if (subTabIndex !== undefined) {
      indexes.subIndex = +subTabIndex
    }
    return indexes
  }

  private setCurrentIndex = (index: number) => {
    const { history, location } = this.props
    this.setState({ tabIndex: index })
    const searchParams =
      this.props.defaultSubIndex !== undefined
        ? `?tabIndex=${index}&subTabIndex=${this.props.defaultSubIndex}`
        : `?tabIndex=${index}`
    history.push({
      pathname: location.pathname,
      search: searchParams,
    })
  }

  private setCurrentSubIndex = (index: number) => {
    const { history, location } = this.props
    const { tabIndex } = this.state
    this.setState({ subTabIndex: index })
    history.push({
      pathname: location.pathname,
      search: `?tabIndex=${tabIndex}&subTabIndex=${index}`,
    })
  }

  public render() {
    const { state } = this
    const context = {
      actions: {
        setCurrentIndex: this.setCurrentIndex,
        setCurrentSubIndex: this.setCurrentSubIndex,
      },
      state,
    }
    return (
      <TabContext.Provider value={context}>
        {this.props.children}
      </TabContext.Provider>
    )
  }
}

export default withRouter<TabContextType, any>(TabContextProvider)
