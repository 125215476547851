import Add from '@icons/add.svg'
import Remove from '@icons/remove.svg'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SystemTaskIcon } from 'src/components/grid/Grid/components/CellType/TaskCell'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { IntermediateSystemTask } from 'src/page/systematic-completion/SystematicCompletionTable/panels/ActorsInterfacePanel'
import {
  SystemTaskTypes,
  SystemTaskTypesTranslations,
} from 'src/service/OrgTypes'
import { classNames } from 'src/utility/utils'

interface ISystemTaskTableProps {
  task_type: SystemTaskTypes
  tasks: Omit<IntermediateSystemTask, 'system'>[]
  setComment: (
    taskType: SystemTaskTypes,
    discipline_id: number,
    comment: string,
  ) => void
  submitComment: (
    taskType: SystemTaskTypes,
    discipline_id: number,
    comment: string,
  ) => void
  toggleMainResponsible: (
    taskType: SystemTaskTypes,
    disciplineId: number,
    is_auxiliary: boolean,
  ) => void
  deleteTask: (taskType: SystemTaskTypes, disciplineId: number) => void
}

const SystemTaskTable = ({
  task_type,
  tasks,
  setComment,
  submitComment,
  toggleMainResponsible,
  deleteTask,
}: ISystemTaskTableProps) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(true)

  return (
    <div className="bg-gray-100 p-2 rounded-sm my-4 ease-in-out">
      <div
        className="flex justify-between mb-2 cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex">
          <SystemTaskIcon taskType={task_type} size={25} />
          <h2 className="ml-2">
            {capitalize(SystemTaskTypesTranslations(task_type, t))}
          </h2>
        </div>
        {expanded ? <Remove /> : <Add />}
      </div>
      <div
        className={classNames(
          expanded ? 'max-h-[2000px]' : 'max-h-0',
          'overflow-hidden',
          'transition-all',
          'ease-in-out',
          'duration-150',
        )}
      >
        <InspectorSectionTable
          headerColumns={[t('main_responsible'), t('discipline'), t('comment')]}
          rowsData={tasks.map((task) => {
            return {
              id: task.id,
              cells: [
                <div
                  key="isMain"
                  className="cursor-pointer"
                  onClick={() =>
                    toggleMainResponsible(
                      task.task_type,
                      task.discipline.id,
                      !task.is_auxiliary,
                    )
                  }
                >
                  <span
                    className={classNames(
                      'w-3 h-3 rounded-full m-auto block',
                      !task.is_auxiliary
                        ? 'bg-blue-root'
                        : 'bg-blue-100 hover:bg-blue-200',
                    )}
                  ></span>
                </div>,
                <span className="text-sm" key="discipline">
                  {task.discipline.shortName}
                </span>,
                <input
                  key="comment"
                  type="text"
                  className="w-full font-light text-sm bg-inherit hover:bg-white focus:bg-white p-1 rounded-s focus:border"
                  onChange={(e) =>
                    setComment(
                      task.task_type,
                      task.discipline.id,
                      e.target.value,
                    )
                  }
                  onBlur={(e) =>
                    submitComment(
                      task.task_type,
                      task.discipline.id,
                      e.target.value,
                    )
                  }
                  value={task.comment}
                  spellCheck="false"
                />,
              ],
            }
          })}
          actionMenu={{
            title: 'delete',
            icon: 'delete',
            className: 'text-red-500',
            onClick: (id) =>
              deleteTask(
                task_type,
                tasks.find((task) => task.id === id)?.discipline.id ?? 0,
              ),
          }}
        />
      </div>
    </div>
  )
}

export default SystemTaskTable
