import * as React from 'react'
import { Subtract } from 'utility-types'
import {
  IRiskContext,
  RiskContextConsumer,
} from './RiskContextProvider/RiskContext'

export interface IWithRiskContext {
  riskContext: IRiskContext
}

export const withRiskContext = <P extends IWithRiskContext>(
  Component: React.ComponentType<P>,
) =>
  class WithContext extends React.PureComponent<Subtract<P, IWithRiskContext>> {
    public render() {
      return (
        <RiskContextConsumer>
          {(riskContext: IRiskContext) => (
            <Component {...(this.props as P)} riskContext={riskContext} />
          )}
        </RiskContextConsumer>
      )
    }
  }
