import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface INoDataFound {
  className?: string
}

const NoDataFound: FC<INoDataFound> = ({ className = '' }) => {
  const { t } = useTranslation()
  return (
    <p className={`text-gray-500 text-sm italic ${className}`}>
      {t('no_data_found')}
    </p>
  )
}

export default NoDataFound
