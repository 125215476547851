import { useTranslation } from 'react-i18next'
import Modal from '../../ui-elements/modal/Modal'
import { classNames } from '../../utility/utils'
import QrDefault from './QrDefaultModal'

export interface IQrModalProps {
  show: boolean
  close: () => void
  value: string
  qrNumber?: string
  title: string
}

const styleClass = {
  title: classNames('text-xl', 'font-bold', 'pt-1', 'pb-2'),
  qrNumber: classNames('text-xl', 'font-bold', 'pt-1'),
}

const QrModal = ({ show, close, value, title, qrNumber }: IQrModalProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal
        show={show}
        title={t('generated_qr_code')}
        closeModal={close}
        maxWidth={1000}
      >
        <QrDefault value={value}>
          <div className={styleClass.qrNumber}>{qrNumber}</div>
          <div className={styleClass.title}>{title}</div>
        </QrDefault>
      </Modal>
    </>
  )
}

export default QrModal
