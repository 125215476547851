import { Fragment, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import Splash from './page/splash/Splash'
import AppCommunicator from './service/AppCommunicator'

AppCommunicator.initialize()

export const browserHandler = {
  ie: () => (
    // padding: 20px; background-color: #FFE57E; position: fixed; left: 0; top: 0; right: 0; z-index: 100000;
    <div
      style={{
        padding: '20px',
        background: '#FFE57E',
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        zIndex: 10000,
      }}
    >
      <p>
        Du bruker en gammel versjon av Internet Explorer. Cogito vil ikkefungere
        i denne nettleseren. Vi anbefaler at du benytter nettleseren Chrome for
        best brukeropplevelse.
      </p>
      Last ned{' '}
      <a href="https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAiAuMTfBRAcEiwAV4SDkVSr8AcFsIBO0dfr1-qbUlJbiU1GpXQtrI0-2OXrecOSrGxVF_CToRoCrM0QAvD_BwE&gclsrc=aw.ds">
        Google Chrome
      </a>
    </div>
  ),
}

const container = document.getElementById('root')
if (!container) {
  console.error('FATAL [in render]: Root element not found')
} else {
  const root = createRoot(container)

  root.render(
    <Fragment>
      <Suspense fallback={<Splash onlyLoader />}>
        <Splash />
      </Suspense>
    </Fragment>,
  )
}
