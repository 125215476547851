import 'react-toggle/style.css'
import { PropsWithChildren } from 'react'
import UserIcon from 'src/components/user/UserIcon'
import { IUserData } from 'src/service/OrgTypes'
import ResolutionHandler from 'src/service/ResolutionHandler'
import { classNames } from 'src/utility/utils'

interface CommentWrapperProps {
  user?: IUserData
  className?: string
  inputMode?: boolean
}

const CommentWrapper = ({
  user,
  className,
  children,
  inputMode = false,
}: PropsWithChildren<CommentWrapperProps>) => {
  return (
    <div className="flex items-start">
      <UserIcon
        userId={user?.id}
        image_url={user?.image_url}
        noBorder
        large
        noTextWrap
      />
      <div
        className={classNames(
          'flex-[1_0_auto] w-0 flex flex-col rounded-2xl relative text-sm text-gray-800',
          inputMode || ResolutionHandler.shouldShowMobileVersion()
            ? 'bg-white border border-gray-300'
            : 'bg-grey-lighter',
          className,
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default CommentWrapper
