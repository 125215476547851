import { useQueryClient } from '@tanstack/react-query'
import { parse } from 'query-string'
import React, { useState, useEffect } from 'react'
import { SplashScreenUtil } from 'src/page/splash/splashScreenStore'
import AppCommunicator from 'src/service/AppCommunicator'
import { IProjectData } from '../../service/OrgTypes'
import { getMyProjects } from '../../service/ProjectService'
import {
  initialState,
  IProjectContextProps,
  IProjectContextState,
  ProjectContext,
} from './ProjectContext'

const ProjectContextProvider: React.FC<IProjectContextProps> = (props) => {
  const [state, setState] = useState<IProjectContextState>(initialState)
  const splashScreenUtil = SplashScreenUtil()

  const queryClient = useQueryClient()

  const getCurrentProject = (projects: IProjectData[]) => {
    if (!projects || projects.length === 0) return
    const queryProject = parse(location.search)['project']
    const localProject = window.localStorage.getItem('currentProject')
    const _qsProject = projects.find(
      (project) => project.project.id === Number(queryProject),
    )
    const _localProject = projects.find(
      (project) => project.project.id === Number(localProject),
    )
    return _qsProject || _localProject || projects[0]
  }

  const reload = () => {
    getMyProjects(props.userId)
      .then((res: IProjectData[]) => {
        const p = getCurrentProject(res)
        if (p) {
          setState({
            ...state,
            projects: res,
            currentProject: p.project,
            isProjectAdmin: p.isProjectAdmin,
            isProjectRiskAdmin: p.isProjectRiskAdmin,
            isBaselineAdmin: p.isBaselineAdmin,
            meetingModuleAccess: p.meetingModuleAccess,
            status_manager: p.status_manager,
            isConstructionManager: p.isConstructionManager,
          })
          window.localStorage.setItem(
            'currentOrganization',
            `${p.project.organization_id}`,
          )
          window.localStorage.setItem('currentProject', `${p.project.id}`)
          splashScreenUtil.removeSplashScreen()
        }
      })
      .catch(console.error)
  }

  const setProject = (projectId: number): void => {
    const { projects } = state
    const selectedProject = projects.find(
      (p: IProjectData) => p.project.id === projectId,
    )
    if (selectedProject) {
      window.localStorage.setItem(
        'currentProject',
        String(selectedProject.project.id),
      )
      window.localStorage.setItem(
        'currentOrganization',
        String(selectedProject.project.organization_id),
      )
      setState({
        ...state,
        currentProject: selectedProject.project,
        isProjectAdmin: selectedProject.isProjectAdmin,
        isProjectRiskAdmin: selectedProject.isProjectRiskAdmin,
        isBaselineAdmin: selectedProject.isBaselineAdmin,
        meetingModuleAccess: selectedProject.meetingModuleAccess,
        status_manager: selectedProject.status_manager,
        isConstructionManager: selectedProject.isConstructionManager,
      })
      queryClient.invalidateQueries()
    }
  }

  useEffect(() => {
    AppCommunicator.addProjectChangedCallback(setProject)
    reload()
  }, [])

  const context = {
    actions: {
      setProject: setProject,
      reload: reload,
    },
    state,
  }

  return (
    <ProjectContext.Provider value={context}>
      {state && state.currentProject && props.children}
    </ProjectContext.Provider>
  )
}

export default ProjectContextProvider
