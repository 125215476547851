import { modularRequest } from 'src/service/service-utils'
import { getCurrentProject, getOrganizationBaseUrl } from 'src/utility/utils'

export class GenericDocumentService {
  private basePath: string

  constructor(basePath: string) {
    this.basePath = basePath
  }

  protected getUrl = (...paths: (string | number)[]) => {
    const projectId = getCurrentProject()
    const baseUrl = `${getOrganizationBaseUrl()}projects/${projectId}`
    if (!projectId) {
      console.error(
        `No projectId available. Request for ${baseUrl} will not work`,
      )
    }
    return `${baseUrl}/${this.basePath}/${paths.join('/')}`
  }

  protected doRequet = <T = any>(
    {
      url,
      method,
      body,
      text,
    }: {
      url?: string
      method?: 'GET' | 'PUT' | 'POST' | 'DELETE'
      body?: any
      text?: boolean
    } = { method: 'GET' },
  ) => {
    let tmpUrl = url
    if (!tmpUrl) tmpUrl = this.getUrl()
    return modularRequest<T>(tmpUrl, { method, body, text })
  }
}
