import * as React from 'react'
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react'
import { IInlineBaseProps } from './IDropDown'
import InlineBaseComponent from './InlineBaseComponent'
import InlineBorderComponent from './InlineBorderComponent'
import InlineErrorMessageComponent from './InlineErrorMessageComponent'
import InlineInputLabelComponent from './InlineInputLabelComponent'

interface ITextLineInputComponent extends IInlineBaseProps {
  label?: string
  value?: string
  labelWidth?: string
  inputWidth?: string
  onValueSubmitted?: (newValue?: string) => void
  setValueInParent?: (newValue?: string) => void
  validate?: (newValue?: string) => string | undefined
  textArea?: boolean
  disabled?: boolean
  className?: string
  type?: 'text' | 'number'
  placeholder?: string
  autoFocus?: boolean
  fullWidth?: boolean
  error?: string
}

const TextInlineInputCompontent: React.FC<ITextLineInputComponent> = ({
  label,
  value,
  validate,
  onValueSubmitted,
  setValueInParent,
  labelWidth,
  inputWidth = 'w-96',
  textArea = false,
  disabled = false,
  className,
  disableTooltip,
  placeholder = '____',
  type = 'text',
  labelTextSize,
  autoFocus,
  fullWidth,
  error,
}) => {
  const [internalValue, setInternalValue] = useState(value)
  const [isFocusing, setIsFocusing] = useState(autoFocus)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setErrorMessage(error ?? '')
  }, [error])

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  const save = async (
    e?: FocusEvent<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    e?.preventDefault()
    setErrorMessage('')
    setValueInParent?.(internalValue)
    if (value && internalValue === value) {
      setIsFocusing(false)
      return
    }

    if (validate) {
      const errorMessage = validate(internalValue)
      if (errorMessage?.length) {
        setErrorMessage(errorMessage)
        return
      }
    }
    setIsFocusing(false)
    if (internalValue !== value) {
      onValueSubmitted?.(internalValue)
    }
  }

  return (
    <InlineBaseComponent>
      {label && (
        <InlineInputLabelComponent
          label={label}
          labelWidth={labelWidth}
          labelTextSize={labelTextSize}
          disableTooltip={disableTooltip}
        />
      )}
      <div className={`flex flex-col w-full`}>
        <InlineErrorMessageComponent errorMessage={errorMessage} />
        <InlineBorderComponent
          isFocusing={isFocusing}
          errorMessage={errorMessage}
          disabled={disabled}
          fullWidth={fullWidth}
        >
          <div
            className={`${
              textArea
                ? 'overflow-hidden text-ellipsis whitespace-pre-wrap'
                : ''
            } ${fullWidth ? '' : inputWidth}`}
          >
            <BaseInputComponent
              value={internalValue}
              textArea={textArea}
              onFocus={() => setIsFocusing(true)}
              onBlur={save}
              onKeyDownCapture={(e) => {
                if (!textArea && e.key === 'Enter') {
                  e.preventDefault()
                  save()
                }
              }}
              onChange={(e) => {
                setErrorMessage('')
                setInternalValue(e.target.value)
              }}
              disabled={disabled}
              className={className}
              placeholder={placeholder}
              type={type}
            />
          </div>
        </InlineBorderComponent>
      </div>
    </InlineBaseComponent>
  )
}

const BaseInputComponent: React.FC<{
  textArea: boolean
  value?: string
  onFocus: () => void
  onBlur: (
    e: FocusEvent<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onKeyDownCapture: (
    e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  disabled: boolean
  type: string
  className?: string
  placeholder: string
}> = (props) => {
  const mergedClassName = `w-full disabled:bg-transparent disabled:cursor-not-allowed ${props.className}`

  if (props.textArea)
    return (
      <textarea
        {...props}
        className={mergedClassName}
        rows={props.value ? 4 : 2}
      />
    )
  return <input {...props} className={mergedClassName} />
}

export default TextInlineInputCompontent
