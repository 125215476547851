import moment from 'moment'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from 'src/components/user/UserIcon'
import { IActivityLog, IUserData } from 'src/service/OrgTypes'
import { formatDateRelativeToNow } from 'src/utility/dateUtils'
import { classNames } from 'src/utility/utils'

interface ActivityLogItemHeaderProps {
  user?: IUserData
  date: string | moment.Moment | Date
  activityLog?: IActivityLog
  isSystemNotification?: boolean
  className?: string
}

const ActivityLogItemHeader = ({
  user,
  date,
  isSystemNotification = false,
  children,
  className = '',
}: PropsWithChildren<ActivityLogItemHeaderProps>) => {
  const { t, i18n } = useTranslation()

  const userData = isSystemNotification
    ? {
        id: undefined,
        firstName: t('system_notification'),
        lastName: undefined,
        image_url: '/icons/settings.svg',
      }
    : user

  return (
    <div className={classNames('flex items-center', className)}>
      <div className="flex-[0_0_auto]">
        <UserIcon
          userId={userData?.id}
          firstName={userData?.firstName}
          lastName={userData?.lastName}
          image_url={userData?.image_url}
          large
        />
      </div>
      {children}
      <div className="text-xxs font-normal text-gray-500 flex-shrink-0 ml-auto">
        {formatDateRelativeToNow(date, i18n.language)}
      </div>
    </div>
  )
}

export default ActivityLogItemHeader
