import { useFlag } from '@unleash/proxy-client-react'
import { create } from 'zustand'

type DraftModeStore = {
  draftMode: boolean
  setDraftMode: (value: boolean) => void
}

const useDraftModeStore = create<DraftModeStore>((set) => ({
  draftMode: false,
  setDraftMode: (value) => set({ draftMode: value }),
}))

const useDraftMode = () => {
  const draftModeFlag = useFlag('draft-mode')
  const setDraftMode = useDraftModeStore((state) => state.setDraftMode)
  const draftMode = useDraftModeStore((state) => state.draftMode)

  const turnDraftModeOn = () => setDraftMode(draftModeFlag)

  return {
    draftMode,
    setDraftMode,
    turnDraftModeOn,
  }
}

export default useDraftMode
