import { IUserAccess, IUserData } from './OrgTypes'
import { request } from './service-utils'

/**
 * User
 */
export const createNewUser = (user: IUserData): Promise<IUserData> => {
  return request(`users`, 'POST', user)
}

export const createProjectUser = (
  user: IUserData,
  projectId: number,
): Promise<IUserData> => {
  return request(`projects/${projectId}/users`, 'POST', user)
}

export const getOwnUser = (): Promise<IUserData> => {
  return request(`user`, 'GET')
}

export const getUser = (userId: number): Promise<IUserData> => {
  return request(`users/${userId}`, 'GET')
}

export const getAllUsers = (): Promise<IUserData[]> => {
  return request(`users`, 'GET')
}

export const getAllUsersDataPagination = (
  filter: any,
  page: number,
  perpage: number,
): Promise<any> => {
  return request(`/users_data/pages/${page}/${perpage}`, 'POST', filter)
}

export const getProjectUsersData = (
  projectId: number,
): Promise<IUserData[]> => {
  return request(`projects/${projectId}/users`, 'GET')
}

export const getProjectSingleUsers = (
  projectId: number,
): Promise<IUserData[]> => {
  return request(`projects/${projectId}/single_users`, 'GET')
}

export const getPaginatedFilterProjectUsersData = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<{ user_access: IUserData[]; pages: number }> => {
  return request(
    `projects/${projectId}/user_access_filter/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getContractUsersData = (
  contractId: number,
): Promise<IUserData[]> => {
  return request(`contracts/${contractId}/users`, 'GET')
}

export const getDisplineUsersData = (
  displineId: number,
): Promise<IUserData[]> => {
  return request(`disciplines/${displineId}/users`, 'GET')
}

export const getProjectUsers = (projectId: number): Promise<IUserData[]> => {
  return request(`projects/${projectId}/project_users`, 'GET')
}

export const getProjectUsersWithDisciplines = (
  projectId: number,
): Promise<IUserData[]> => {
  return request(`projects/${projectId}/users_with_disciplines`, 'GET')
}

export const getDisplineUsers = (displineId: number): Promise<IUserData[]> => {
  return request(`disciplines/${displineId}/discipline_users`, 'GET')
}

export const getProjectDisplineUsers = (
  projectId: number,
  displineId: number,
): Promise<IUserData[]> => {
  return request(
    `projects/${projectId}/disciplines/${displineId}/discipline_users`,
    'GET',
  )
}

export const giveProjectAccess = (
  data: IUserAccess,
  projectId: number,
): Promise<IUserData> => {
  return request(
    `projects/${projectId}/assign_user/${data.user_id}`,
    'PUT',
    data,
  )
}

export const removeProjectAccess = (
  projectId: number,
  userId: number,
  newUserId: any,
  isProject: boolean,
): Promise<any> => {
  const url = isProject
    ? `projects/${projectId}/remove_access/${userId}`
    : `contracts/${projectId}/remove_access/${userId}`
  return request(url, 'DELETE', { newUser_id: newUserId }, true)
}

export const editUser = (user: IUserData | any): Promise<any> => {
  return request(`users/${user.id}`, 'PUT', user)
}

export const removeUser = (user: IUserData, newUserId: any): Promise<any> => {
  return request(`users/${user.id}`, 'DELETE', { newUser_id: newUserId }, true)
}

export const getUserAssignData = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/data`, 'GET')
}

export const getLoginsKPIData = (
  projectId: number,
  filter?: any,
): Promise<any> => {
  return request(`projects/${projectId}/login_stats`, 'POST', filter)
}

export const getUserBySelectedDisciplines = (
  projectId: number,
  filter?: { discipline: number[] },
): Promise<any> => {
  return request(`projects/${projectId}/users_for_selectors`, 'POST', filter)
}
