import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { filterDelivery } from 'src/service/DeliveryService'

export const useFilteredDeliveries = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof filterDelivery>[1]['sort']
  filters: Omit<Parameters<typeof filterDelivery>[1], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'deliveries',
      { projectId: projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      filterDelivery(projectId, { ...filters, sort }, page, pageSize),
    placeholderData: keepPreviousData,
    enabled,
  })
}
