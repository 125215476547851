import { ReactNode, createContext } from 'react'

export interface IBreadCrumbContext {
  actions: {
    setCurrentDepth: (i: number) => void
    updateItem: (i: number, item: ITitleId) => void
    setCrums: (projectId: number, url: string) => void
  }
  state: IBreadCrumbContextState
}

export interface ITitleId {
  title: string
  recordId: string
  id: number
  modelType: string
}

export interface IBreadCrumbContextProps {
  maxDepth: number
  module: string
  children: ReactNode
}

export interface IBreadCrumbContextState {
  maxDepth: number
  currentDepth: number
  crumbs: ITitleId[]
  module: string
}

export const BreadCrumbContext = createContext<IBreadCrumbContext>(
  {} as IBreadCrumbContext,
)
