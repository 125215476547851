import * as React from 'react'
import { IFileContainer } from 'src/document/types/IFileContainer'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import HistoryFiles from '../../HistoryFiles'

interface IFilesInspector {
  document: IFileContainer
  loading: boolean
}

const FilesInspector: React.FC<IFilesInspector> = ({ document, loading }) => {
  const { t } = useMultipleKeysTranslation()

  return (
    <div className={'flex flex-col'}>
      {loading ? (
        <SpinnerMobile className="scale-25 flex self-center" />
      ) : document?.project_files && document?.project_files?.length > 0 ? (
        <HistoryFiles
          fileContainerId={document?.id}
          files={document?.project_files}
          folderId={document.folder_id}
          block
          className={'mt-0.5'}
          latestFiles={false}
        />
      ) : (
        <p className={'text-d-fontgrey font-light text-sm mb-2'}>
          {t('no_data_found')}
        </p>
      )}
    </div>
  )
}

export default FilesInspector
