import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import RiskAreaList from 'src/components/risk/RiskAreaList'
import RiskMatrix from 'src/components/risk/RiskMatrix'
import TabView, { ITabs } from 'src/components/tab-view/TabView'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'

interface IRiskConfigPageProps {
  projectId: number
}

const RiskConfigPage: React.FC<IRiskConfigPageProps> = ({
  projectId,
}: IRiskConfigPageProps) => {
  const { t } = useTranslation()
  const [showDelModal, setshowDelModal] = useState(false)

  const tabs: ITabs = {
    risk_matrix: {
      title: t('risk_matrix'),
      show: true,
      content: (
        <RiskMatrix
          toggleDelModal={() => setshowDelModal(!showDelModal)}
          showDeleteModal={showDelModal}
          projectId={projectId}
        />
      ),
    },
    risk_area: {
      title: t('risk_area'),
      show: true,
      content: (
        <div className={TabPageStyleClass.root}>
          <RiskAreaList isProjectPage={true} projectId={projectId} />
        </div>
      ),
    },
  }

  return <TabView level={2} tabs={tabs} />
}

export default RiskConfigPage
