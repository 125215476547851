import { ExportData } from 'src/context/ExportContext/ExportTypes'

export const deliveriesExportData: ExportData = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/deliveries_filter/pages/0/0',
    method: 'POST',
    body: {},
  },
  name: 'deliveries',
}
