import { mapValues } from 'lodash'
import * as React from 'react'
import { useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import useTree from 'src/hooks/useTree'
import { getContractsTree } from 'src/service/ContractService'
import { IUserData } from 'src/service/OrgTypes'
import { getDisplineUsersData } from 'src/service/UserService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import BreadCrumb from 'src/ui-elements/breadcrumb/BreadCrumb'
import { numberOrUndefined } from 'src/utility/utils'
import { TreePageStyleClass } from '../../ui-elements/tabs/Utils'
import ContractsList from '../contract/ContractsList'
import DropDownExplorer from '../explorer/DropDownExplorer'
import ContractDisciplineDetailPage from './ContractDisciplineDetails'
import DisciplineRoleDetailsPage from './DisciplineRoleDetails'
import UserInspectorPanel from './UserInspectorPanel'

interface IContractTree {
  projectId: number
}

const ContractTree: React.FC<IContractTree> = ({ projectId }) => {
  const {
    activeKey,
    openNodes,
    onItemClick,
    reloadTree,
    data,
    breadCrumbsList,
    loading,
  } = useTree(
    ['contracts', 'disciplines', 'users'],
    getContractsTree,
    'Contract',
  )

  const { t } = useMultipleKeysTranslation()
  const [openUserInspection, setOpenUserInspection] = useState(true)
  const [users, setUsers] = useState<IUserData[]>([])
  const { path: basePath } = useRouteMatch()

  const onCloseUserInpectionPanel = () => {
    setOpenUserInspection(false)
  }

  const onOpenUserInpectorPanel = async (disciplineId: number) => {
    setOpenUserInspection(true)
    if (users.length) return
    const disciplineUsers = await getDisplineUsersData(disciplineId)
    setUsers(disciplineUsers)
  }

  return (
    <div className={TreePageStyleClass.root}>
      <DropDownExplorer
        data={data}
        header={{
          title: t('contracts'),
        }}
        onItemClick={onItemClick}
        initialActiveKey={activeKey}
        initialOpenNodes={openNodes}
        currentPage={1}
        totalPages={1}
        pagination
        loading={loading}
        keyName={'Contract'}
      />
      <div className={TreePageStyleClass.content}>
        <BreadCrumb breadCrumbs={breadCrumbsList} />
        <Route path={basePath} exact>
          <ContractsList
            projectId={projectId}
            reloadParent={reloadTree}
            onTreeSelect={() => undefined}
          />
        </Route>
        <Route
          path={`${basePath}/contracts/:contractId`}
          exact
          render={({ match }) => {
            const { contractId } = mapValues(match.params, numberOrUndefined)
            if (!contractId) return
            return (
              <ContractDisciplineDetailPage
                projectId={projectId}
                contractId={contractId}
                refreshTree={reloadTree}
              />
            )
          }}
        />
        <Route
          path={`${basePath}/contracts/:contractId/disciplines/:disciplineId`}
          render={({ match }) => {
            const { disciplineId } = mapValues(match.params, numberOrUndefined)
            if (!disciplineId) return
            return (
              <DisciplineRoleDetailsPage
                projectId={projectId}
                disciplineId={disciplineId}
                onOpenUserInpectorPanel={onOpenUserInpectorPanel}
              />
            )
          }}
        />
        <Route
          path={`${basePath}/contracts/:contractId/disciplines/:disciplineId/users/:userId`}
          render={({ match }) => {
            const { disciplineId, userId } = mapValues(
              match.params,
              numberOrUndefined,
            )
            if (!disciplineId) return
            onOpenUserInpectorPanel(disciplineId)
            const user = users.find((user) => user.id === userId)
            if (!user) return
            return (
              <UserInspectorPanel
                user={user}
                open={openUserInspection}
                onClose={onCloseUserInpectionPanel}
              />
            )
          }}
        />
      </div>
    </div>
  )
}

export default ContractTree
