import { IFileContainer } from 'src/document/types/IFileContainer'

export interface IPos {
  left?: number
  top?: number
}

export interface IFormatData {
  [key: string]: {
    errors: string[]
    value: string | object | string[] | object[]
  }
}

export interface IValidationResponse {
  error_count: number
  formatted_data: IFormatData[]
  headers: string[]
}

export interface ISystemValidationResponse extends IValidationResponse {
  systems?: IFormatData[]
}
export interface IDocValidationResponse extends IValidationResponse {
  file_containers?: IFormatData[]
}

export interface ITestWorkGroupValidationResponse extends IValidationResponse {
  test_work_groups?: IFormatData[]
}
export interface ITestSystemGroupValidationResponse
  extends IValidationResponse {
  test_system_groups?: IFormatData[]
}

export interface ISystemTypeValidationResponse extends IValidationResponse {
  system_types?: IFormatData[]
}

export interface IUserDefinedFieldValidationResponse
  extends IValidationResponse {
  user_defined_fields?: IFormatData[]
}

export enum UPLOAD_TYPES {
  DOCUMENT_UPLOAD,
  FILES_UPLOAD,
  OBJECT_UPLOAD,
  OBJECT_TYPE_UPLOAD,
}

export enum UPLOAD_STATE {
  UPLOADING,
  UPLOADED,
  ERROR,
}

export enum NEXT_STEP {
  CONTINUE,
  CLOSE,
  STAY,
}

export interface IUploadStat {
  status: UPLOAD_STATE
  progress: number
  loaded: number
  size: number
  error?: string
}

export interface MultiValidate {
  row: number
  column: string
  value: string
}

export interface IUploadState {
  fileName: string
  uploadStatus: IUploadStat
  request: XMLHttpRequest
}

export interface IUploadResponse {
  created_at: string
  created_by_id: number
  file_container_id: number | null
  file_container: IFileContainer | null
  id: number
  name: string
  updated_at: string
}

export interface MovedSteps {
  current_step_id: number
  stepName?: string
  stepSequence?: number
  reason?: string
  code?: string
  revision_name?: string
  start_date?: string
  file_container_ids: number[]
}
