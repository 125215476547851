import { t } from 'i18next'
import { capitalize } from 'lodash'
import { MutableRefObject } from 'react'
import { dualSelectorColumn } from 'src/ui-elements/Table/Columns'
import { IMainProcess, ITeam } from '../../service/OrgTypes'
import { getProjectProcesses } from '../../service/ProcessService'
import { getMainProcessTeams, getProjectTeams } from '../../service/TeamService'
import { filterType } from '../../ui-elements/list/ListTypes'
import useListHelper from '../../ui-elements/list/UseListHelper'
import { keypointComparator, theamComparator } from '../../utility/Utility'
import DualTableSelector from '../table-ResponsibleEditor/DualTableSelector'
import TableSelectorEditor from '../table-ResponsibleEditor/TableSelectorEditor'

const getTema = async (
  projectId: number,
  themes: any,
  id?: number,
): Promise<ITeam[]> => {
  if (id) {
    return getMainProcessTeams(id)
  }
  const team = themes.current
  if (team.length > 0) {
    return Promise.resolve(team)
  } else {
    const pUsers = await getProjectTeams(projectId)
    themes.current = pUsers
    return Promise.resolve(pUsers)
  }
}

const getProcesses = async (
  projectId: number,
  mainProcesses: any,
  _id?: number,
): Promise<IMainProcess[]> => {
  const processes = mainProcesses.current
  if (processes.length > 0) {
    return Promise.resolve(processes)
  } else {
    const pUsers = await getProjectProcesses(projectId)
    mainProcesses.current = pUsers
    return Promise.resolve(pUsers)
  }
}

export const TeamColumn = (
  projectId: number,
  themes: any,
  updateTema: (id: number, temaId: number, mainProcessId: number) => void,
) => {
  const { getThemeFilter } = useListHelper()
  return {
    name: 'team',
    size: '280',
    id: 'team',
    sortingField: 'team',
    dataField: 'team||main_process',
    filterType: filterType.DEFAULT,
    filterDataField: 'team.name',
    filterDataValue: 'team.id',
    filter: [],
    getFilter: getThemeFilter,
    comparator: (a: any, b: any, direction: boolean) =>
      a ? (b ? theamComparator(a, b, direction) : 0) : 0,
    cell: ({ main_process: mainProcess, team }: any, row: number) => (
      <TableSelectorEditor
        rowId={row}
        selected={team}
        getData={() => getTema(projectId, themes, mainProcess?.id)}
        dataFields={['name']}
        isUserSelector={false}
        showCancelButton={true}
        onDataSelected={(value) =>
          updateTema(
            row,
            value.id ? value.id : null,
            mainProcess?.id ? mainProcess?.id : null,
          )
        }
        displayContent={<span>{team?.name}</span>}
      />
    ),
  }
}

export const MainProcessAndThemeColumn = (
  projectId: number,
  mainProcesses: MutableRefObject<IMainProcess[]>,
  themes: MutableRefObject<ITeam[]>,
  updateMainProcess: (
    id: number,
    mainProcessId: number,
    temaId: number,
  ) => void,
) => {
  const { getProcessFilter } = useListHelper()

  return dualSelectorColumn(
    'main_process',
    'team',
    {
      name: capitalize(t('main_process')),
      getFilter: getProcessFilter,
      field: 'main_process.name',
    },
    ['name'],
    ['name'],
    {
      primary: (id: number) => getProcesses(projectId, mainProcesses, id),
      secondary: (id: number) => getTema(projectId, themes, id),
    },
    updateMainProcess,
    undefined,
    {
      primary: false,
      secondary: true,
    },
  )
}

/**
 * @deprecated Use MainProcessAndThemeColumn instead
 */
export const MainProcessColumn = (
  projectId: number,
  mainProcesses: any,
  themes: any,
  updateMainProcess: (
    id: number,
    mainProcessId: number,
    temaId: number,
  ) => void,
) => {
  const { getProcessFilter } = useListHelper()
  return {
    name: 'main_process',
    size: '300',
    id: 'main_process',
    sortingField: 'main_process',
    dataField: 'main_process||team',
    filterType: filterType.DEFAULT,
    filterDataField: 'main_process.name',
    filterDataValue: 'main_process.id',
    getFilter: getProcessFilter,
    filter: [],
    comparator: (a: any, b: any, direction: boolean) =>
      a ? (b ? keypointComparator(a, b, direction) : 0) : 0,
    cell: ({ main_process: mainProcess, team }: any, row: number) => (
      <DualTableSelector
        rowId={row}
        selected={{
          primary: mainProcess,
          secondary: team,
        }}
        getAPI={{
          primary: (id) => getProcesses(projectId, mainProcesses, id),
          secondary: (id) => getTema(projectId, themes, id),
        }}
        label={{ primary: 'mainprocess', secondary: 'team' }}
        dataFields={{
          primary: ['name'],
          secondary: ['name'],
        }}
        fieldsOptional={{
          primary: false,
          secondary: true,
        }}
        onDataSelected={updateMainProcess}
        displayContent={<span>{mainProcess?.name}</span>}
      />
    ),
  }
}
