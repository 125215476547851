import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StatusColumn,
  SystemNumberColumn,
} from 'src/components/TableColumns/Columns'
import { ISystem, ITestSetup, TestSetupStatuses } from 'src/service/OrgTypes'
import { getTestSetupForTest, updateTestSetup } from 'src/service/TestService'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import { capFirstLetter } from 'src/utility/utils'

interface IMcListProps {
  testId: number
  projectId: number
  systems: ISystem[]
}

export const mcStatusBadgeColorMapper: {
  [status in TestSetupStatuses]: BadgeColor
} = {
  [TestSetupStatuses.NOT_CHECKED]: BadgeColor.YELLOW,
  [TestSetupStatuses.FAILED]: BadgeColor.RED,
  [TestSetupStatuses.NOT_APPLICABLE]: BadgeColor.DEFAULT,
  [TestSetupStatuses.APPROVED]: BadgeColor.GREEN,
}

const MCList = ({ testId, systems: _systems, projectId }: IMcListProps) => {
  const [testSetups, setTestSetups] = useState<ITestSetup[]>([])

  const { t } = useTranslation()

  const MCStatuses = Object.values(TestSetupStatuses).map((status) => ({
    id: status,
    name: capFirstLetter(t(status)),
  }))

  useEffect(() => {
    reload()
  }, [])

  const reload = async () => {
    const testSetups = await getTestSetupForTest(testId)
    setTestSetups(testSetups)
  }

  const columns: IListColumns[] = [
    SystemNumberColumn(),
    {
      name: 'mc',
      size: '300',
      id: 'setupName',
      dataField: 'setup_name',
      sortingField: 'setup_name',
    },
    StatusColumn(
      'status||id',
      MCStatuses,
      (status, key) => {
        updateTestSetup(projectId, key, {
          status: status.id as TestSetupStatuses,
        }).then(() => reload())
      },
      (cell) => mcStatusBadgeColorMapper[cell.status],
    ),
  ]
  return (
    <List
      columns={columns}
      data={testSetups}
      itemsPerPage={0}
      tableName="TestSetupList"
    />
  )
}

export default MCList
