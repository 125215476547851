import * as React from 'react'
import { Subtract } from 'utility-types'
import {
  AlertContextConsumer,
  IAlertContext,
} from '../ui-elements/toast/AlertContext'

export interface IIwithAlartContext {
  alartContext: IAlertContext
}

export const withAlartContext = <P extends IIwithAlartContext>(
  Component: React.ComponentType<P>,
) =>
  class WithContext extends React.PureComponent<
    Subtract<P, IIwithAlartContext>
  > {
    public render() {
      return (
        <AlertContextConsumer>
          {(alartContext: IAlertContext) => (
            <Component {...(this.props as P)} alartContext={alartContext} />
          )}
        </AlertContextConsumer>
      )
    }
  }
