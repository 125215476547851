import Download from '@icons/download.svg'
import { FC, useState } from 'react'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import { IProjectFile } from 'src/document/types/IFileContainer'
import { updateWallToken } from 'src/service/TheWallService'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { delay } from 'src/utility/delay'
import { downloadSingleFile } from 'src/utility/download'
import { openFileExternal } from 'src/utility/utils'

interface IDocListFileCountProps {
  projectFiles: IProjectFile[]
  urlField?: string
}

const DocListFileCount: FC<IDocListFileCountProps> = ({
  projectFiles,
  urlField,
}: IDocListFileCountProps) => {
  const [expandMore, setExpandMore] = useState<boolean>(false)

  const downloadFiles = async () => {
    const token = await updateWallToken()
    for (const file of projectFiles) {
      const fileUrl: string = urlField ? file[urlField] : file.file_url
      if (fileUrl.includes('bygg.io'))
        await downloadSingleFile(fileUrl, file.name, token)
      else open(fileUrl, '_blank')
      await delay(1000)
    }
  }

  const onChangeExpanded = () => {
    setExpandMore(!expandMore)
  }

  return projectFiles.length > 0 ? (
    <CloseClickOutside onClose={() => setExpandMore(false)}>
      <div
        className={'flex text-blue-root items-center relative px-1'}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <span
          className="bg-d-lightblue w-12 h-5 text-xs flex px-2 items-center justify-center rounded-md"
          onClick={downloadFiles}
        >
          <span>{projectFiles.length > 0 ? projectFiles.length : ''}</span>
          <MaterialIcon className=" mx-1 text-xs font-thin" icon="download" />
        </span>
        {projectFiles.length > 1 && (
          <MaterialIcon
            className=" mx-1 text-sm"
            icon={expandMore ? 'expand_less' : 'expand_more'}
            onClick={onChangeExpanded}
          />
        )}
        {expandMore && (
          <div
            className={
              'absolute w-max top-[20px] left-0 z-50 rounded-sm bg-white border border-b-0 boder-gray-100'
            }
          >
            {projectFiles.map((pf, index) => (
              <div
                className="flex flex-row items-center px-4 py-1 border-b   hover:bg-blue-light-100"
                key={index}
                onClick={openFileExternal(
                  urlField ? pf[urlField] : pf.file_url,
                )}
              >
                <span className="pr-2 max-w-xs truncate">
                  {pf?.name ?? pf.fileName}
                </span>
                <Download className="text-sm" />
              </div>
            ))}
          </div>
        )}
      </div>
    </CloseClickOutside>
  ) : (
    <span>{''}</span>
  )
}

export default DocListFileCount
