import * as React from 'react'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'

interface IStatCard {
  title: string | JSX.Element
  icon?: Icons
  svg?: React.FC<React.SVGProps<SVGElement>> & {
    title?: string
  }
  value: number | string
  onClick: () => void
  bg?: string
  textColor?: string
  fillColor?: string
}

const StatCard = ({
  title,
  icon,
  svg: SVG,
  value,
  onClick,
  bg,
  textColor,
  fillColor,
}: IStatCard) => {
  const superScriptStyle: React.CSSProperties = {
    position: 'absolute',
    top: '10px',
    zIndex: 500,
    right: '-7px',
    fontSize: 20,
  }
  const getValue = (): JSX.Element => {
    return (
      <span className={'relative'} style={{ transform: 'scale(0.92)' }}>
        {typeof value === 'number' && value > 999 ? 999 : value}{' '}
        {typeof value === 'number' && value > 999 && (
          <sup style={superScriptStyle}>+</sup>
        )}
      </span>
    )
  }
  return (
    <div onClick={() => onClick()}>
      <div
        className={`w-full bg-white flex-col rounded-md shadow-md overflow-hidden`}
      >
        <div
          className={`${
            bg ? `bg-${bg}` : 'bg-blue-100'
          } flex flex-1 justify-around items-center p-2 pb-0`}
        >
          {icon ? (
            <Icon
              icon={icon}
              className={
                'ml-1 h-11 w-11 md:h-20 md:w-20 grid place-items-center rounded-md'
              }
              styleClass={'w-full h-auto'}
            />
          ) : SVG ? (
            <SVG className={`text-2xl md:text-4xl ${fillColor} ml-1`} />
          ) : (
            <></>
          )}
          <p
            className={`flex flex-auto justify-end font-semibold text-2xl ${
              textColor ? `text-${textColor}` : 'text-blue-root'
            }`}
          >
            {getValue()}
          </p>
        </div>
        <p className={'p-2 text-xs md:text-base text-gray-400 font-light'}>
          {title}
        </p>
      </div>
    </div>
  )
}

export default StatCard
