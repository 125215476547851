import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { capFirstLetter, classNames } from '../../utility/utils'

const styleClass = {
  root: classNames(
    'flex',
    'flex-col',
    'align-middle',
    'min-w-full',
    'overflow-hidden',
  ),
  table: {
    root: classNames('min-w-full', 'divide-y'),
    head: classNames(
      'py-3',
      'px-4',
      'text-center',
      'text-sm',
      'leading-4',
      'text-gray-500',
      'tracking-wider',
      'border-b-gray-200',
    ),
    body: classNames('bg-white', 'divide-y'),
    row: {
      isTitle: (title: boolean) =>
        classNames(
          'py-3',
          'px-4',
          'h-51',
          'mn-h-51',
          'whitespace-nowrap',
          'text-sm',
          'leading-4',
          'border-b-gray-300',
          'cursor-pointer',
          title ? 'text-gray-900' : 'text-center text-gray-500',
        ),
    },
  },
}

interface IBadgeData {
  cellText: number
  color?: BadgeColor
  toList?: (statVal?: string) => void
}

interface ITableData {
  rowTitle: string
  data: IBadgeData[]
}

interface IProps {
  tableHeaders: string[]
  tableContent?: ITableData[]
  isLoading: boolean
}

const InformationTableComponent = ({
  tableHeaders,
  tableContent,
  isLoading,
}: IProps) => {
  const getColumnCount = tableHeaders.length

  const toFixedOne = (i: number) => {
    const item = Number(i)
    return item && !isNaN(item) ? (item % 1 === 0 ? item : item.toFixed(1)) : 0
  }

  return (
    <div className={styleClass.root}>
      <table className={styleClass.table.root}>
        <thead>
          <tr>
            <th className={styleClass.table.head} />
            {tableHeaders.map((title, index) => (
              <th key={index} className={styleClass.table.head}>
                {capFirstLetter(title)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styleClass.table.body}>
          {tableContent &&
            tableContent.map(({ rowTitle, data }, index) => (
              <tr key={index}>
                <td
                  key={index + rowTitle}
                  className={styleClass.table.row.isTitle(true)}
                >
                  {capFirstLetter(rowTitle)}
                </td>
                {data
                  .slice(0, getColumnCount)
                  .map(({ cellText, color, toList }, i) => (
                    <td
                      key={i}
                      className={styleClass.table.row.isTitle(false)}
                      onClick={() => {
                        if (toList) {
                          i === 0
                            ? toList('key_point')
                            : i === 1
                              ? toList('delivery')
                              : toList('action')
                        }
                      }}
                    >
                      {color ? (
                        isLoading ? (
                          <div className={'animate-loading h-4'} />
                        ) : (
                          <Badge
                            key={i + cellText}
                            text={toFixedOne(cellText).toString()}
                            color={color}
                          />
                        )
                      ) : isLoading ? (
                        <div className={'animate-loading h-4'} />
                      ) : (
                        <p>{toFixedOne(cellText)}</p>
                      )}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default InformationTableComponent
