import { classNames } from '../../utility/utils'

export const TreePageStyleClass = {
  root: classNames(
    'flex',
    'flex-row',
    'w-full',
    'h-full',
    'relative',
    'overflow-auto',
    'px-2',
  ),

  content: classNames('flex', 'flex-col', 'overflow-auto', 'w-full'),
}

export const TabPageStyleClass = {
  root: classNames('px-2'),
}

export const initialNodeRecursion = (
  i: number,
  currentIndex: number,
  initialValue: string,
  result: string[],
  initialArray: string[],
): string[] => {
  if (currentIndex + i >= initialArray.length) {
    return result
  } else {
    result.push(initialValue + '/' + initialArray[currentIndex + i])
    if (i >= currentIndex) {
      return initialNodeRecursion(
        i,
        currentIndex + 1,
        initialValue + '/' + initialArray[currentIndex + i],
        result,
        initialArray,
      )
    } else {
      return initialNodeRecursion(
        i + 1,
        currentIndex,
        initialValue + '/' + initialArray[currentIndex + i],
        result,
        initialArray,
      )
    }
  }
}

export const ProTabsStyles = {
  tabs: classNames('w-auto'),
  tabList: (tabListStyle: string) =>
    classNames(
      'flex',
      'items-center',
      'flex-wrap',
      'w-full',
      'pb-1',
      tabListStyle ? tabListStyle : '',
    ),
  tab: classNames(
    'hover:text-blue-root',
    'inline-block',
    'relative',
    'text-sm',
    'font-normal',
    'ml-3',
    'list-none',
    'cursor-pointer',
    'text-gray-500',
    'whitespace-nowrap',
  ),
  selectedTab: classNames(
    'text-blue-root',
    'font-medium',
    'border-b-2',
    'border-blue-root',
    'text-blue-root',
    'hover:text-gray-700 hover:border-gray-300 focus:outline-none',
    'focus:outline-none focus:text-blue-root focus:border-blue-root',
  ),
  content: (otherStyles: string) =>
    classNames('px-1 pt-0', otherStyles ? otherStyles : ''),
}

export const isEmpty = (obj: object) => {
  if (!obj || typeof obj !== 'object') {
    return
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const _i in obj) {
    if (obj) {
      return false
    }
  }
  return true
}

export const getSourceFilter = () => {
  return [
    {
      name: 'delivery',
      value: 'Delivery',
      active: false,
    },
    {
      name: 'meeting',
      value: 'Meeting',
      active: false,
    },
    {
      name: 'topic',
      value: 'Topic',
      active: false,
    },
    {
      name: 'project',
      value: 'Project',
      active: false,
    },
  ]
}
