import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useReactToPrint } from 'react-to-print'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import Button from 'src/ui-elements/button/Button'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { classNames } from '../../utility/utils'

const styleClass = {
  qr: {
    canvas: classNames('flex', 'items-center', 'pt-12', 'flex-col'),
    title: classNames('text-xl', 'font-bold', 'pt-1', 'pb-1'),
  },
  inputGroup: classNames(
    'w-full',
    'flex',
    'flex-row',
    'flex-wrap',
    'justify-end',
    'pt-4',
    'pr-4',
  ),
}

const QrDefault = (props: any) => {
  const ref = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  })
  const { t } = useTranslation()

  const projectContext = useContext(ProjectContext)
  const { logo } = projectContext.state.currentProject

  return (
    <>
      <div ref={ref} className={styleClass.qr.canvas}>
        <div className="flex justify-between min-w-176  my-4">
          <img
            style={{ maxHeight: 80, width: 'auto' }}
            className="hidden show-print mb-2 cursor-pointer pr-4 pl-2 md:pl-0 py-1"
            src="/taskctr-logomork.png"
            width="auto"
            height="80"
          />
          {logo && (
            <img
              style={{ maxHeight: 80, width: 'auto' }}
              className="hidden show-print mb-2 cursor-pointer pr-4 pl-2 md:pl-0 py-1"
              src={logo}
              width="auto"
              height="80"
            />
          )}
        </div>

        <QRCode size={props.size ? props.size : 400} value={props.value} />
        {props.children}
      </div>
      <ModalFooter>
        <Button type={Button.ButtonType.PRIMARY} onClick={handlePrint}>
          {t('print_this_page')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default QrDefault
