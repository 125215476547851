import { useTranslation } from 'react-i18next'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { capFirstLetter, classNames } from '../../utility/utils'

interface IFloatingButton {
  onClick: () => void
  floating?: boolean
  fullWidth?: boolean
  isTavlamote?: boolean
  isWorkOps?: boolean
}

const FloatingButton = ({
  onClick,
  floating = true,
  fullWidth,
  isTavlamote,
  isWorkOps,
}: IFloatingButton) => {
  const { t } = useTranslation()
  const styleClass = {
    root: classNames(
      floating ? 'fixed top-22 right-1 z-50' : '',
      fullWidth ? 'sticky top-0 px-5 py-3 bg-mobile' : '',
      'border-gray-300',
    ),
    icon: classNames(
      !floating ? 'w-full' : 'w-auto',
      'h-8',
      'rounded-full',
      isTavlamote || isWorkOps ? 'bg-blue-root' : 'bg-red-500',
      'text-white',
      'flex',
      'justify-center',
      'items-center',
      'cursor-pointer',
      'shadow-md',
    ),
  }

  const getTitlewithIcon = () => {
    if (isTavlamote) {
      return (
        <span className={'flex items-center'}>
          <Icon
            icon={Icons.TAVLAMOTE_WHITE}
            className={'w-4 h-4 flex items-center mr-1'}
          />
          {capFirstLetter(t('add_board_meeting'))}
        </span>
      )
    }
    if (isWorkOps) {
      return (
        <span className={'flex items-center'}>
          <Icon
            icon={Icons.WORK_OPS_WHITE}
            className={'w-4 h-4 flex items-center mr-1 opacity-75'}
          />
          {capFirstLetter(t('add_construction_task'))}
        </span>
      )
    }
    return (
      <span className={'flex items-center'}>
        <Icon
          icon={Icons.ISSUE_WHITE}
          className={'w-4 h-4 flex items-center mr-1 opacity-75'}
        />
        {capFirstLetter(t('add_case'))}
      </span>
    )
  }

  return (
    <div onClick={onClick} className={styleClass.root}>
      <div className={styleClass.icon}>
        <span className={`p-1 text-xs font-medium`}>
          {floating ? (
            `+${t('case')}`
          ) : (
            <span className={'flex items-center'}>{getTitlewithIcon()}</span>
          )}
        </span>
      </div>
    </div>
  )
}

export default FloatingButton
