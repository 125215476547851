import { Required } from 'utility-types'
import {
  IConstructionWagon,
  IPaginatedConstructionWagons,
  IPaginatedTasks,
  Create,
} from './OrgTypes'
import { request } from './service-utils'

export const createConstructionWagon = (
  wagon: Create<IConstructionWagon>,
  projectId: number,
): Promise<IConstructionWagon> => {
  return request(
    `projects/${projectId}/construction_locomotives`,
    'POST',
    wagon,
  )
}

export const editConstructionWagon = (wagon: any) => {
  return request(`construction_locomotives/${wagon.id}`, 'PUT', wagon)
}

export const editConstructionLocomotive = (
  wagon: IConstructionWagon,
): Promise<IConstructionWagon> => {
  return request(`construction_locomotives/${wagon.id}`, 'PUT', wagon)
}

export const getConstructionWagon = (
  id: number,
  draft = false,
): Promise<IConstructionWagon> => {
  return request(`construction_locomotives/${id}?drafting=${draft}`, 'GET')
}

export const getProjectConstructionWagons = (
  projectId: number,
): Promise<IConstructionWagon[]> => {
  return request(`projects/${projectId}/construction_locomotives`, 'GET')
}

export const getCanvasConstructionWagons = (
  projectId: number,
  filters: { [key: string]: number | number[] | string },
  draftMode = false,
): Promise<
  Required<IConstructionWagon, 'control_area_id' | 'construction_train_id'>[]
> => {
  return request(
    `projects/${projectId}/construction_locomotives_canvas?drafting=${draftMode}`,
    'POST',
    { filters },
  )
}

export const getCurrentConstructionWagons = (
  projectId: number,
): Promise<IConstructionWagon[]> => {
  return request(
    `projects/${projectId}/this_weeks_construction_locomotives`,
    'GET',
  )
}

export const getPrevNextWagon = (
  wagonId: number,
  direction: 'previous' | 'next',
): Promise<any> => {
  return request(
    `construction_locomotives/${wagonId}/next_or_previous/${direction}`,
    'GET',
  )
}

export const getPrevNextWagonDetails = (
  wagonId: number,
  direction: 'previous' | 'next',
): Promise<any> => {
  return request(
    `construction_locomotives/${wagonId}/next_or_previous_details/${direction}`,
    'GET',
  )
}

export const getConstructionTrainWagons = (
  trainId: number,
  draftMode = false,
): Promise<IConstructionWagon[]> => {
  return request(
    `construction_trains/${trainId}/construction_locomotives?drafting=${draftMode}`,
    'GET',
  )
}

export const deleteConstructionWagon = (task: IConstructionWagon) => {
  return request(`/construction_locomotives/${task.id}`, 'DELETE', {}, true)
}

export const createCheckInWagon = (wagonId: number, data: any) => {
  return request(`/construction_locomotives/${wagonId}/check_ins`, 'POST', data)
}

export const editCheckInOut = (checkInOutId: number, data: any) => {
  return request(`/check_ins/${checkInOutId}`, 'PUT', data)
}

export const getWagonCheckListsWithValues = (wagonId: number) => {
  return request(
    `/construction_locomotives/${wagonId}/checklists_with_total_values`,
    'GET',
  )
}

export const getWagonCheckInOuts = (wagonId: number) => {
  return request(`/construction_locomotives/${wagonId}/check_ins`, 'GET')
}

export const getPaginatedProjectConstructionwagons = (
  projectId: number,
  page: number,
  filter?: any,
  perPage?: number,
  draftMode: boolean = false,
): Promise<IPaginatedConstructionWagons> => {
  return request(
    `projects/${projectId}/construction_locomotives/pages/${page}/${
      perPage ? perPage : 100000
    }?drafting=${draftMode}`,
    'Post',
    filter,
  )
}

export const getPaginatedTrainConstructionwagons = (
  trainId: number,
  page: number,
  filter?: any,
  perPage?: number,
  draftMode: boolean = false,
): Promise<IPaginatedConstructionWagons> => {
  return request(
    `construction_trains/${trainId}/construction_locomotives/pages/${page}/${
      perPage ? perPage : 100000
    }?drafting=${draftMode}`,
    'Post',
    filter,
  )
}

export const getPaginatedWagonsIssues = (
  wagonId: number,
  page: number,
  filter?: any,
  perPage?: number,
): Promise<IPaginatedTasks> => {
  return request(
    `construction_locomotives/${wagonId}/tasks/pages/${page}/${
      perPage ? perPage : 100000
    }`,
    'Post',
    filter,
  )
}
