import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ITag } from 'src/service/OrgTypes'
import { createTag, editTag } from 'src/service/TagService'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import { classNames } from '../../utility/utils.js'

interface ITagFromProps {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  tag: ITag
}

const TagForm = ({
  projectId,
  closeModal,
  editingMode,
  tag,
}: ITagFromProps) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  const { t } = useTranslation()
  const [name, setName] = useState<string>(tag ? tag.name : '')
  const [description, setDescription] = useState<string>(
    tag && tag.description ? tag.description : '',
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('')

  const onSubmit = (e: any) => {
    let error = false
    e.preventDefault()

    setLoading(false)
    setNameErrorMessage('')

    if (!name) {
      setNameErrorMessage(t('fill_in_the_name'))
      error = true
    }

    if (!error) {
      if (editingMode && tag && tag.id) {
        const editedTag = {
          id: tag.id,
          name,
          description,
        }
        editTag(editedTag).then(() => {
          closeModal()
        })
      } else {
        const createThisTag = {
          name,
          description,
        }
        createTag(createThisTag, projectId).then(() => {
          closeModal()
        })
      }
    }

    setLoading(false)
  }

  const onNameChange = (e: any) => {
    setName(e.target.value)
    setNameErrorMessage('')
  }

  const onDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('name')}
          block={true}
          value={name}
          onChange={onNameChange}
          required={true}
          errorMessage={nameErrorMessage}
          autoFocus={true}
        />
      </div>

      <div className={styleClass.inputGroup}>
        <Textarea
          label={t('description')}
          value={description}
          isValid={false}
          autoFocus={false}
          onChange={onDescriptionChange}
          block={true}
          required={false}
        />
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        {editingMode ? (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : t('update')}
          </Button>
        ) : (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : t('add')}
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default TagForm
