import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import { getConstructionTrainWagons } from 'src/service/ConstructionWagonService'
import { IConstructionTrain, IConstructionWagon } from 'src/service/OrgTypes'
import { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { modalUrlBuilder } from 'src/utility/utils'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import {
  editConstructionTrain,
  getConstructionTrain,
} from '../../../service/ConstructionTrainService'
import { getProjectControlAreas } from '../../../service/ControlAreaService'
import { trainColors } from '../../../service/SystemValues'
import FixedPane from '../../../ui-elements/fixed-pane/FixedPane'
import BooleanInlineInputComponent from '../../../ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import DateTimeInlineInputComponent from '../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from '../../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from '../../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import { toFixed } from '../../../utility/Utility'

interface ITrainInspectorPanel {
  trainId: number
  open: boolean
  onClose: () => void
  onUpdate: () => void
  defaultIndex?: number
}

const TrainInspectorPanel: React.FC<ITrainInspectorPanel> = ({
  trainId,
  open,
  onClose,
  onUpdate,
  defaultIndex = 0,
}) => {
  const { t } = useTranslation()
  const projectContext = useContext(ProjectContext)
  const history = useHistory()
  const modalContext = useContext(ModalContext)
  const { id: projectId } = projectContext.state.currentProject
  const [wagons, setWagons] = useState<IConstructionWagon[]>()
  const [train, setTrain] = useState<IConstructionTrain>()

  const fetchTrain = useCallback(() => {
    getConstructionTrain(trainId).then((res) => {
      setTrain(res)
    })
  }, [trainId])

  useEffect(() => {
    fetchTrain()
  }, [fetchTrain, trainId])

  const fetchWagons = () => {
    getConstructionTrainWagons(trainId).then((res) => {
      setWagons(res)
    })
  }
  const onWagonsClick = (id: number) => {
    const modalURL = modalUrlBuilder(projectId, 'constructionlocomotive', id)
    history.push(modalURL)
    modalContext.actions.checkURL()
  }

  const sections: IInspectorSection[] = [
    {
      name: 'Wagons',
      icon: Icons.LOCOMOTIVE,
      activeIcon: Icons.LOCOMOTIVE,
      onClick: fetchWagons,
      content: wagons ? (
        <InspectorSectionTable
          headerColumns={[
            t('title'),
            t('start_date'),
            t('discipline'),
            t('duration_days'),
            t('working_hours'),
            t('number_of_workers'),
          ]}
          rowsData={
            wagons?.map((wagon) => {
              return {
                cells: [
                  wagon?.title ?? '',
                  wagon?.startTime ? moment(wagon?.startTime).format('L') : '',
                  wagon?.discipline
                    ? wagon?.discipline?.shortName +
                      ' ' +
                      wagon?.discipline?.name
                    : '',
                  wagon?.duration ? toFixed(wagon?.duration) : '',
                  wagon?.planned_execution_hours
                    ? toFixed(wagon?.planned_execution_hours)
                    : '',
                  wagon?.planned_staffing
                    ? toFixed(wagon?.planned_staffing)
                    : '',
                ],
                id: wagon.id,
              }
            }) ?? []
          }
          handleClick={onWagonsClick}
        />
      ) : (
        <div className={'min-h-full w-full grid place-items-center'}>
          <SpinnerMobile />
        </div>
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: train?.id ? (
        <Comments parentId={train.id} parentType="ConstructionTrain" />
      ) : (
        <span className="text-sm py-1 text-gray-400">{t('no_data_found')}</span>
      ),
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: train?.id ? (
        <ChangeLog parentId={train.id} parentType="ConstructionTrain" />
      ) : (
        <span className="text-sm py-1 text-gray-400">{t('no_data_found')}</span>
      ),
    },
  ]

  const onChangeInput = (update: Partial<IConstructionTrain>) => {
    editConstructionTrain({
      ...update,
      id: trainId,
    }).then(() => {
      fetchTrain()
      onUpdate()
    })
  }

  return (
    <FixedPane
      title={train?.record_id + '-' + (train?.title ?? '')}
      show={open}
      onClose={() => onClose()}
      className={'w-[700px]'}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.TRAIN,
        ids: {
          cagId: train?.control_area_group_id || undefined,
          caId: train?.control_area_id,
          trainId: train?.id,
        },
      }}
    >
      <>
        <InlineComponentsWrapper padding="left" inputWidth={'w-64'}>
          <TextInlineInputCompontent
            label="title"
            disabled
            value={train?.title}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('fill_out_w_param', { param: t('title') })
              }
              return undefined
            }}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ title: newValue })
            }}
          />

          <DateTimeInlineInputComponent
            label="start_date"
            disabled
            selectedTime={`${train?.startTime}`}
            onValueSubmitted={(startTime: moment.MomentInput) => {
              if (startTime) onChangeInput({ startTime: moment(startTime) })
            }}
            validate={(newValue) => {
              if (!newValue) {
                return t('fill_out_w_param', { param: t('start_date') })
              }
              return undefined
            }}
            inspectorPanel={true}
          />

          <SelectorInlineInputComponent
            getItems={() => getProjectControlAreas(projectId)}
            label={'control_area'}
            disabled
            selectedId={train?.control_area_id}
            getItemLabel={(controlAreaGr) =>
              `${controlAreaGr?.record_id} - ${controlAreaGr?.title}`
            }
            validate={(newValue) => {
              if (newValue === undefined) {
                return t('fill_out_w_param', { param: t('control_area') })
              }
              return
            }}
            initialItem={train?.control_area}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ control_area_id: +newValue })
            }}
            inspectorPanel={true}
          />

          <TextInlineInputCompontent
            label="number_of_wagons"
            disabled
            value={`${train?.number_of_locomotives ?? 0}`}
            type={'number'}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ number_of_locomotives: +newValue })
            }}
          />

          <TextInlineInputCompontent
            label="number_of_activities"
            disabled
            value={`${train?.number_of_work_operations ?? 0}`}
            type={'number'}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ number_of_work_operations: +newValue })
            }}
          />

          <TextInlineInputCompontent
            label="duration_days"
            disabled
            value={`${
              train && train.duration ? Math.round(train.duration) : ''
            }`}
            type={'number'}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ duration: +newValue })
            }}
          />
          <TextInlineInputCompontent
            label="working_hours"
            disabled
            value={`${
              train?.planned_execution_hours
                ? Math.round(train?.planned_execution_hours)
                : ''
            }`}
            type={'number'}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ planned_execution_hours: +newValue })
            }}
          />
          <SelectorInlineInputComponent
            items={trainColors(t).map((col) => ({
              ...col,
              trainColor: col.id,
            }))}
            label={'color'}
            disabled
            selectedId={train?.train_color}
            getItemLabel={(trainColor) => `${trainColor?.name}`}
            validate={(newValue) => {
              if (newValue === undefined) {
                return t('fill_out_w_param', { param: t('color') })
              }
              return
            }}
            initialItem={
              trainColors(t).filter((item) => item.id === train?.train_color)[0]
            }
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ train_color: newValue })
            }}
            inspectorPanel={true}
          />

          <BooleanInlineInputComponent
            label="consider_weekends_as_working_days"
            disabled
            onValueSubmitted={(ignore_weekends) => {
              onChangeInput({ ignore_weekends })
            }}
            value={train?.ignore_weekends}
            showCheckBox
          />
          <BooleanInlineInputComponent
            label="consider_holidays_as_working_periods"
            disabled
            onValueSubmitted={(ignore_holidays) => {
              onChangeInput({ ignore_holidays })
            }}
            value={train?.ignore_holidays}
            showCheckBox
          />

          <DateTimeInlineInputComponent
            label="created_at"
            disabled
            selectedTime={train?.created_at}
            onValueSubmitted={() => {}}
            inspectorPanel={true}
          />

          <DateTimeInlineInputComponent
            label="updated_at"
            disabled
            selectedTime={train?.updated_at}
            onValueSubmitted={() => {}}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
        <InspectorSections defaultIndex={defaultIndex} sections={sections} />
      </>
    </FixedPane>
  )
}
export default TrainInspectorPanel
