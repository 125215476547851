import * as React from 'react'
import Icon from 'src/ui-elements/icon/Icon'
import { capFirstLetter, classNames } from '../../utility/utils'
import { contentStyleWithTabs } from '../MUtils'

interface IMTabsProps {
  tabItems: IMTabItem[]
  tabIndex: number
  setTabIndex: (id: number) => void
  tabContentStyles?: string
  disabledIndexs?: number[]
}
export interface IMTabItem {
  name: string
  content: Element | React.ReactElement
  icon: string
  activeIcon: string
  disabledIcon: string
}

const ProTabsStyles = {
  tabs: classNames('w-auto'),
  tabList: (tabListStyle: string) =>
    classNames(
      'flex',
      'items-center',
      'w-full',
      'bg-white',
      'shadow-inner',
      tabListStyle ? tabListStyle : '',
    ),
  tab: (disabled: boolean) =>
    classNames(
      !disabled ? 'hover:text-blue-root' : '',
      'inline-block',
      'relative',
      'text-sm',
      'font-regular',
      'p-1',
      'list-none',
      !disabled ? 'cursor-pointer' : 'cursor-not-allowed',
      'text-gray-500',
      !disabled ? 'focus:text-gray-700 focus:border-gray-300' : '',
      'whitespace-nowrap',
      'flex',
      'flex-col',
      'items-center',
      'flex-1',
      'text-center',
    ),
  selectedTab: classNames(
    'text-blue-root',
    'font-medium',
    'text-blue-root',
    'focus:outline-none',
    'focus:text-blue-root',
  ),
  content: (otherStyles: string) =>
    classNames('pt-0', 'overflow-y-auto', otherStyles ? otherStyles : ''),
}

const MTabs = ({
  tabIndex,
  setTabIndex,
  tabItems,
  tabContentStyles = '',
  disabledIndexs,
}: IMTabsProps) => {
  const { selectedTab, tab } = ProTabsStyles

  const isDisabled: (item: IMTabItem, i: number) => boolean = (
    item: IMTabItem,
    i: number,
  ) => {
    return item.disabledIcon && disabledIndexs
      ? disabledIndexs.includes(i)
      : false
  }

  const handleClick = (item: IMTabItem, i: number) => {
    if (!isDisabled(item, i)) {
      setTabIndex(i)
    }
  }

  return (
    <>
      {tabIndex >= 0 && (
        <div
          style={contentStyleWithTabs}
          className={ProTabsStyles.content(tabContentStyles)}
        >
          {Object.keys(tabItems).map((key) => {
            return +key === tabIndex ? (
              <div key={key}>{tabItems[key].content}</div>
            ) : null
          })}
        </div>
      )}
      <ul className={ProTabsStyles.tabList('')}>
        {tabItems &&
          tabItems.map((item: IMTabItem, i) => (
            <li
              key={i}
              onClick={() => handleClick(item, i)}
              className={
                i === tabIndex
                  ? `${tab(isDisabled(item, i))} ${selectedTab}`
                  : `${tab(isDisabled(item, i))}`
              }
            >
              {isDisabled(item, i) ? (
                <Icon
                  icon={Icon.IconType[item.disabledIcon]}
                  className={'w-6 h-6'}
                />
              ) : (
                <Icon
                  icon={
                    i === tabIndex
                      ? Icon.IconType[item.activeIcon]
                      : Icon.IconType[item.icon]
                  }
                  className={'w-6 h-6'}
                />
              )}
              <span
                className={`${
                  isDisabled(item, i) ? 'text-gray-100' : ''
                } block mt-1`}
              >
                {capFirstLetter(item.name)}
              </span>
            </li>
          ))}
      </ul>
    </>
  )
}

export default MTabs
