import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import MAddIssue from './MAddIssue'

const MAddGlobalIssue = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject

  const { pid: parentId, parent } = useParams<{ pid: string; parent: string }>()

  const getTitle = () => {
    return parent === 'Room'
      ? t('add_room_issue')
      : parent === 'System'
        ? t('add_system_issue')
        : t('add_control_area_issue')
  }

  return (
    <MAddIssue
      projectId={+projectId}
      onClose={() => history.goBack()}
      submitTask={() => {}}
      parentId={+parentId}
      parentType={parent}
      title={getTitle()}
      controlAreaId={parent === 'ControlArea' ? +parentId : undefined}
    />
  )
}

export default MAddGlobalIssue
