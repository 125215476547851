import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { capFirstLetter, classNames } from '../../utility/utils'

interface ITextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    WithTranslation {
  label?: string
  errorMessage?: string
  isValid?: boolean
  description?: string
  block?: boolean | undefined
  className?: string
  required?: boolean
  hidelabel?: boolean
  noPaddingX?: boolean
  inMobile?: boolean
  bgColor?: string
  rows?: number
}

interface ITextareaState {
  value: string | number | readonly string[]
}

class Textarea extends React.PureComponent<ITextareaProps, ITextareaState> {
  public static displayName = 'Input'

  private static styleClass = {
    root: (
      block: boolean | undefined,
      className: string | undefined,
      noPadding?: boolean,
    ) =>
      classNames(
        className ? className : null,
        block && !className ? 'md:w-full' : 'md:w-1/3',
        'w-full',
        noPadding ? '' : 'px-2',
      ),
    input: (
      hasError: boolean,
      hasValue: string | boolean,
      bg: string,
      isMobile: boolean,
    ) =>
      classNames(
        'w-full',
        !isMobile ? 'form-input' : 'p-2 text-gray-800 border border-gray-300',
        'block',
        'sm:text-sm',
        'sm:leading-5',
        'rounded-sm',
        'focus:border-blue-300',
        hasError &&
          'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red',
        bg ? `bg-${bg}` : '',
      ),
    label: classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'my-2',
    ),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'ml-2',
      'text-sm',
      'font-normal',
    ),
  }

  constructor(props: ITextareaProps) {
    super(props)
    this.state = {
      value: props.value ? props.value : '',
    }
  }

  public UNSAFE_componentWillReceiveProps(props: ITextareaProps) {
    this.setState({
      value: props.value ? props.value : '',
    })
  }

  private onChange = (event: any) => {
    event.preventDefault()

    const { onChange } = this.props

    if (onChange) {
      onChange(event)
    }
    this.setState({ value: event.target.value })
  }

  private mobileStyles = {
    fontSize: 13,
    borderRadius: 8,
    borderColor: '#E7ECF3',
    borderWidth: 1,
  }

  public render() {
    const {
      label,
      errorMessage,
      block,
      placeholder,
      className,
      required,
      hidelabel,
      noPaddingX,
      t,
      inMobile = false,
      bgColor = '',
      rows,
      // eslint-disable @typescript-eslint/no-unused-vars
      children,
      onChange,
      description,
      tReady, // // unused var to exclude from ...rest. It'll give an unrecognized warning if included in rest. https://taskctrl.atlassian.net/browse/TAS-2853
      // eslint-enable @typescript-eslint/no-unused-vars
      ...rest
    } = this.props
    const { value } = this.state
    const hasError = !!errorMessage
    const hasValue =
      typeof value === 'number' || (typeof value === 'string' && value)
    const placeHolder = placeholder
      ? placeholder
      : label
        ? capFirstLetter(t('write_in')) + ' ' + label?.toLowerCase()
        : ''

    return (
      <div className={Textarea.styleClass.root(block, className, noPaddingX)}>
        <div className={'flex flex-row'}>
          {!hidelabel && label && (
            <label className={Textarea.styleClass.label}>
              {capFirstLetter(label)}
              {required ? ' *' : ''}
            </label>
          )}
          {hasError && (
            <p className={Textarea.styleClass.errorMessage}>{errorMessage}</p>
          )}
        </div>
        <textarea
          {...rest}
          value={value}
          className={Textarea.styleClass.input(
            hasError,
            hasValue,
            bgColor,
            inMobile,
          )}
          placeholder={placeHolder}
          onChange={this.onChange}
          rows={rows ? rows : 4}
          style={inMobile ? this.mobileStyles : {}}
        />
      </div>
    )
  }
}

export default withTranslation()(Textarea)
