import * as Sentry from '@sentry/react'
import { PropsWithChildren } from 'react'
import ErrorPage from 'src/page/error/ErrorPage'

const ErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage is404={false} />}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
