import * as React from 'react'

interface IconProp {
  color?: string
}

const CircleIcon: React.FC<IconProp> = (prop) => {
  const { color } = prop
  return (
    <svg className="w-[11px] h-[11px] mr-1 rounded-full" viewBox="0 0 32 32">
      <circle
        cx="16"
        cy="16"
        r="15"
        fill={color}
        stroke="white"
        strokeWidth="7"
      />
    </svg>
  )
}

export default CircleIcon
