import Edit from '@icons/edit.svg'
import { capitalize } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CircularButton from 'src/components/CircularButton'
import UserIcon from 'src/components/user/UserIcon'
import { BadgeColorMapper } from 'src/ui-elements/badge/BadgeEnums'
import Modal from 'src/ui-elements/modal/Modal'
import { IRadioItems } from 'src/ui-elements/radio/RadioItems'
import { ICheckInOut, ITaskData, IUserData } from '../../../service/OrgTypes'
import { capFirstLetter, classNames } from '../../../utility/utils'
import ConstructionIssuesList from '../construction-issues/ConstructionIssuesList'
import { checkInText, checkOutText, statusTypes } from './WagonCheckInOut'

export interface ICheckInOutDetailProps {
  checkedIn: boolean
  checkInOut: ICheckInOut
  wagonId: number
  actualStaffing?: number
  actualWorkHours?: number
  onEdit?: () => void
  reload: () => void
}

const CheckInOutDetail = ({
  checkedIn,
  checkInOut,
  actualStaffing,
  actualWorkHours,
  onEdit,
  reload,
}: ICheckInOutDetailProps) => {
  const styleClass = {
    label: classNames(
      'flex',
      'items-center',
      'text-sm',
      'text-gray-500',
      'p-1 pl-0',
    ),
    row: classNames('flex', 'flex-nowrap', 'pl-2', 'pr-6', 'items-start'),
    span: (bgcolor: string) =>
      classNames(
        'justify-center',
        'items-center',
        'w-10',
        'h-6',
        'rounded-full',
        'font-normal',
        'text-sm',
        'shadow',
        'flex',
        bgcolor,
        !bgcolor.includes('green') && 'cursor-pointer',
      ),
  }

  interface ICheckItem {
    id: number
    title: string
    color: string
    done: number
    total: number
    tasks: ITaskData[]
  }

  const { t } = useTranslation()
  const [showImgModal, setImgModal] = useState<boolean>(false)
  const [signee, setSignee] = useState<IUserData>(checkInOut.check_in_by)
  const [signature, setSignature] = useState<string>(checkInOut.signature)
  const [signDate, setSignDate] = useState<moment.Moment>(
    moment(checkInOut.check_in_date),
  )
  const [checkItem, setCheckItem] = useState<ICheckItem[]>([])
  const [selectedCheckItem, setSelectedCheckItem] = useState<ICheckItem>()
  const [showIssueModal, setShowIssueModal] = useState<boolean>(false)

  useEffect(() => {
    const noOutstandingWork = {
      id: checkInOut.no_outstanding_work_id,
      title: t('no_outstanding_job'),
      color: checkInOut.no_outstanding_work?.color,
      tasks: checkInOut.no_outstanding_work?.tasks,
      done:
        checkInOut.no_outstanding_work?.tasks.filter(
          (task) => task.status === 'done',
        ).length || 0,
      total: checkInOut.no_outstanding_work?.tasks.length || 0,
    }
    const noEquipmentLeft = {
      id: checkInOut.no_equipment_left_id,
      title: t('no_equipment_left'),
      color: checkInOut.no_equipment_left?.color,
      tasks: checkInOut.no_equipment_left?.tasks,
      done:
        checkInOut.no_equipment_left?.tasks.filter(
          (task) => task.status === 'done',
        ).length || 0,
      total: checkInOut.no_equipment_left?.tasks.length || 0,
    }
    const clearedAndVacuumed = {
      id: checkInOut.cleared_and_vacuumed_id,
      title: t('cleaned_and_vacuumed'),
      color: checkInOut.cleared_and_vacuumed?.color,
      tasks: checkInOut.cleared_and_vacuumed?.tasks,
      done:
        checkInOut.cleared_and_vacuumed?.tasks.filter(
          (task) => task.status === 'done',
        ).length || 0,
      total: checkInOut.cleared_and_vacuumed?.tasks.length || 0,
    }
    const safeToWorkIn = {
      id: checkInOut.safe_to_work_in_id,
      title: t('safe_to_work_in'),
      color: checkInOut.safe_to_work_in?.color,
      tasks: checkInOut.safe_to_work_in?.tasks,
      done:
        checkInOut.safe_to_work_in?.tasks.filter(
          (task) => task.status === 'done',
        ).length || 0,
      total: checkInOut.safe_to_work_in?.tasks.length || 0,
    }
    const damageIsRegistered = {
      id: checkInOut.damage_is_registered_id,
      title: t('damages_are_registered'),
      color: checkInOut.damage_is_registered?.color,
      tasks: checkInOut.damage_is_registered?.tasks,
      done:
        checkInOut.damage_is_registered?.tasks.filter(
          (task) => task.status === 'done',
        ).length || 0,
      total: checkInOut.damage_is_registered?.tasks.length || 0,
    }

    setCheckItem([
      noOutstandingWork,
      noEquipmentLeft,
      clearedAndVacuumed,
      safeToWorkIn,
      damageIsRegistered,
    ])

    setSignee(checkInOut.check_in_by)
    setSignature(checkInOut.signature)
    setSignDate(moment(checkInOut.check_in_date))
  }, [checkInOut, t])

  const handleShowTasks = (item: ICheckItem) => {
    if (item.tasks.length > 0) {
      setSelectedCheckItem(item)
      setShowIssueModal(true)
    }
  }

  const getVal = (item: ICheckItem, stat: IRadioItems) => (
    <div
      onClick={() => handleShowTasks(item)}
      className={styleClass.span(BadgeColorMapper()[stat.id ?? ''])}
    >
      {item.done}/{item.total}
    </div>
  )

  return (
    <>
      <div className={'pb-2 px-2'}>
        <div className={`${styleClass.row} items-center gap-2`}>
          <label className={`${styleClass.label} text-base font-medium`}>
            {capitalize(
              checkedIn ? t('check_in_detail') : t('check_out_detail'),
            )}
          </label>
          <CircularButton onClick={onEdit}>
            <Edit className={'font-xl'} />
          </CircularButton>
        </div>
        {checkItem.map((item, index) => (
          <div className={`${styleClass.row} justify-between`} key={index}>
            <label className={styleClass.label}>
              <li>
                {checkedIn ? checkInText(t)[index] : checkOutText(t)[index]}
              </li>
            </label>
            <div
              className={'flex items-center justify-between w-mx-content pr-2'}
            >
              {getVal(
                item,
                statusTypes(true).filter((stat) => stat.id === item.color)[0],
              )}
            </div>
          </div>
        ))}
        {!checkedIn && (
          <>
            <div className={`${styleClass.row}`}>
              <label className={`${styleClass.label} w-1/2`}>
                {t('actual_working_hours')}
              </label>
              <div className={styleClass.label}>
                {actualWorkHours ? actualWorkHours : 0}
              </div>
            </div>
            <div className={`${styleClass.row}`}>
              <label className={`${styleClass.label} w-1/2`}>
                {t('actual_staffing')}
              </label>
              <div className={styleClass.label}>
                {actualStaffing ? actualStaffing : 0}
              </div>
            </div>
          </>
        )}
        <div className={`${styleClass.row}`}>
          <label className={`${styleClass.label} w-1/2 capitalize`}>
            {t('date')}
          </label>
          <div className={styleClass.label}>{signDate.format('L LT')}</div>
        </div>
        <div className={`${styleClass.row} my-2`}>
          <label className={`${styleClass.label} w-1/2`}>
            {t('signature')}
          </label>
          <img
            style={{ maxHeight: 100 }}
            onClick={() => setImgModal((n) => !n)}
            className="my-1 h-5 w-24 object-contain"
            src={signature}
            alt={`${signee.firstName} signature`}
          />
        </div>
        <div className={`${styleClass.row}`}>
          <label className={`${styleClass.label} w-1/2`}>
            {t('signed_by')}
          </label>
          <UserIcon
            userId={signee.id}
            firstName={signee.firstName}
            lastName={signee.lastName}
            image_url={signee.image_url}
          />
        </div>
      </div>
      <Modal
        show={showImgModal}
        closeModal={() => setImgModal((n) => !n)}
        title={capFirstLetter(t('signature'))}
        size={'w-1/2'}
        maxWidth={800}
      >
        <div className={'pb-2 px-2'}>
          <div className={`${styleClass.row} items-center`}>
            <label className={`${styleClass.label} w-32`}>{t('date')}</label>
            <div className={styleClass.label}>{signDate.format('L LT')}</div>
          </div>
          <div className={`${styleClass.row} items-center`}>
            <label className={`${styleClass.label} w-32`}>
              {t('signature')}
            </label>
            <img
              onClick={() => setImgModal((n) => !n)}
              width={'100'}
              height={'100'}
              src={signature}
              alt={`${signee.firstName} signature`}
            />
          </div>
          <div className={`${styleClass.row} items-center`}>
            <label className={`${styleClass.label} w-32`}>
              {t('signed_by')}
            </label>
            <UserIcon
              userId={signee.id}
              firstName={signee.firstName}
              lastName={signee.lastName}
              image_url={signee.image_url}
            />
          </div>
        </div>
      </Modal>
      <Modal
        show={showIssueModal}
        closeModal={() => {
          setShowIssueModal(false)
          reload()
        }}
        title={t('issue_title', { title: selectedCheckItem?.title })}
        size={'w-9/10'}
        maxWidth={2800}
        minHeight={'400px'}
      >
        <div className={'mr-4'}>
          <ConstructionIssuesList
            tableName={'wagonIssuesList'}
            parentId={selectedCheckItem?.id}
            parentType={checkedIn ? 'CheckInParameter' : 'CheckOutParameter'}
          />
        </div>
      </Modal>
    </>
  )
}

export default CheckInOutDetail
