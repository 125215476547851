import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import ConstructionModalToolbar from 'src/components/construction/toolbars/ConstructionModalToolbar'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import QrModal from 'src/components/qr/QrModal'
import useDraftMode from 'src/hooks/useDraftMode'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Loader from 'src/ui-elements/loader/Loader'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import {
  deleteConstructionWagon,
  getConstructionWagon,
} from '../../../service/ConstructionWagonService'
import {
  IConstructionCanvasControlArea,
  IConstructionWagon,
} from '../../../service/OrgTypes'
import FixedHorizintalPane from '../../../ui-elements/fixed-pane/FixedHorizontalPane'
import { capFirstLetter, classNames } from '../../../utility/utils'
import ConstructionChecklistList from '../checklist/ConstructionChecklistsList'
import ConstructionIssuesList from '../construction-issues/ConstructionIssuesList'
import ConstructionTaskList from '../tasks/ConstructionTaskList'
import TrainModal from '../train/TrainModal'
import CheckListDetail from './CheckListDetail'
import WagonModalDetail from './WagonModalDetail'

interface IWagonModal {
  open: boolean
  onClose: () => void
  onUpdate: () => void
  wagonId: number
  controlArea?: IConstructionCanvasControlArea
  projectId: number
  defaultIndex?: number
  className?: string
}

const WagonModal = ({
  defaultIndex = 0,
  open,
  onClose,
  onUpdate,
  projectId,
  wagonId,
  controlArea,
  className,
}: IWagonModal) => {
  const styleClass = {
    inputGroup: classNames('w-full', 'flex', 'flex-row', 'justify-end', 'pr-4'),
    tab: (selected: boolean) =>
      classNames(
        selected ? 'bg-blue-root-tab-wash' : 'bg-white',
        'hover:bg-blue-root-tab-wash',
        'hover:text-blue-root',
        selected ? 'text-blue-root' : 'text-gray-700',
        'inline-block',
        'relative',
        'text-sm',
        'font-normal',
        'px-3',
        'py-1',
        'list-none',
        'cursor-pointer',
        'rounded',
      ),
  }

  const { t } = useTranslation()
  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [trainModal, setTrainModal] = useState<boolean>(false)
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [qrString, setQrString] = useState<string>('')
  const [showQrModal, setQrModal] = useState<boolean>(false)

  const { draftMode, turnDraftModeOn } = useDraftMode()

  const wagonPublished =
    (wagon && (wagon.published_at !== null || !draftMode)) ?? false
  const [tabIndex, setTabIndex] = useState<number>(
    defaultIndex && wagonPublished ? defaultIndex : 0,
  )
  const getWagon = useCallback(() => {
    getConstructionWagon(wagonId, draftMode).then((res) => {
      setWagon(res)
      setLoading(false)
    })
  }, [draftMode, wagonId])

  useEffect(() => {
    getWagon()
  }, [wagonId, projectId, getWagon])

  useEffect(() => {
    setTabIndex(defaultIndex && wagonPublished ? defaultIndex : 0)
  }, [defaultIndex, wagonId, wagonPublished])

  const openDeleteModal = () => {
    turnDraftModeOn()
    setDeleteModal(true)
  }

  const deleteWagon = () => {
    deleteConstructionWagon({ id: wagon.id } as IConstructionWagon).then(() => {
      onClose()
    })
  }

  const openTrainModal = () => {
    setTrainModal(true)
  }

  const getTitle = (): string => {
    return wagon
      ? capFirstLetter(
          t('wagon_title', { title: wagon.record_id + ' - ' + wagon.title }),
        )
      : t('loading...')
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const copyURL =
      url +
      '?modal=constructionlocomotive&id=' +
      wagon.id +
      '&project=' +
      projectId
    setQrString(copyURL)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setQrModal((n) => !n)
  }

  const getModalTitle = (): string => {
    return wagon.title
      ? capFirstLetter(t('wagon_title', { title: wagon.title }))
      : 'Loading...'
  }

  const tabs: { title: string; active: boolean; content: JSX.Element }[] = [
    {
      title: t('wagon'),
      active: true,
      content: loading ? (
        <Loader />
      ) : (
        <WagonModalDetail
          onUpdate={() => {
            getWagon()
            onUpdate()
          }}
          wagon={wagon}
          onSave={false}
        />
      ),
    },
    {
      title: t('check_in_check_out'),
      active: wagonPublished,
      content: (
        <div className="pt-4 overflow-x-auto">
          <CheckListDetail
            wagon={wagon}
            onUpdate={() => {
              getWagon()
              onUpdate()
            }}
          />
        </div>
      ),
    },
    {
      title: t('board_meeting'),
      active: wagonPublished,
      content: (
        <div className="pt-2 pl-3">
          <ConstructionChecklistList
            projectId={projectId}
            controlAreaId={wagon.control_area_id}
            wagon={wagon}
            reloadParent={getWagon}
            modalTitle={
              wagon
                ? (wagon.control_area && wagon.control_area.title
                    ? t('control_area_title', {
                        title: wagon.control_area.title,
                      }) + ' - '
                    : '') +
                  (wagon.title
                    ? capFirstLetter(t('wagon_title', { title: wagon.title }))
                    : t('loading...'))
                : ''
            }
          />
        </div>
      ),
    },
    {
      title: t('activities'),
      active: true,
      content: (
        <div className="pr-2 pt-1 pl-2 overflow-x-auto min-h-[290px]">
          {wagon && wagon.id && (
            <ConstructionTaskList
              reloadParent={getWagon}
              constructionWagonId={wagon.id}
              isProjectLevelList={false}
            />
          )}
        </div>
      ),
    },
    {
      title: t('cases'),
      active: wagonPublished,
      content: (
        <div className="pr-2 pt-1 pl-2 overflow-x-auto min-h-[290px]">
          {wagon?.id && (
            <ConstructionIssuesList
              tableName={'wagonIssuesList'}
              wagonId={wagon.id}
            />
          )}
        </div>
      ),
    },
    {
      title: t('comments'),
      active: true,
      content: (
        <div className="px-4 w-1/3">
          {wagon && wagon.id && (
            <Comments parentType="ConstructionLocomotive" parentId={wagon.id} />
          )}
        </div>
      ),
    },
    {
      title: t('change_log'),
      active: true,
      content: (
        <div className="px-4 w-1/3">
          {wagon && wagon.id && (
            <ChangeLog
              parentType="ConstructionLocomotive"
              parentId={wagon.id}
            />
          )}
        </div>
      ),
    },
  ].filter((tab) => tab.active)

  return (
    <FixedHorizintalPane
      title={getTitle()}
      closeClickOutside={false}
      show={open}
      onClose={onClose}
      className={className || 'h-[600px]'}
      detailPageData={{
        key: DetailPageKeys.WAGON,
        ids: {
          cagId: wagon?.control_area?.control_area_group_id || undefined,
          caId: wagon?.control_area_id,
          trainId: wagon?.construction_train_id,
          wagonId: wagon?.id,
        },
      }}
    >
      <div>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index: number) => setTabIndex(index)}
          className={'w-full react-tabs border-react-tabs modal-tabs h-4'}
        >
          <div
            style={{
              position: 'sticky',
              top: 0,
              left: 0,
              zIndex: 50,
              paddingRight: 15,
              width: '100%',
            }}
          >
            <div className="w-full bg-white flex border-b border-gray-200 flex-row flex-wrap justify-between px-2 pb-2">
              <TabList className={'flex items-center flex-wrap gap-2'}>
                {tabs
                  .filter((tab) => tab.active)
                  .map((tab, i) => (
                    <Tab
                      key={tab.title}
                      className={styleClass.tab(tabIndex === i)}
                    >
                      <p className="first-capitalize text-nowrap">
                        {tab.title}
                      </p>
                    </Tab>
                  ))}
              </TabList>
              <ConstructionModalToolbar
                params={{
                  modal: 'constructionlocomotive',
                  id: wagon.id,
                }}
                toggleQrModal={toggleQrModal}
                openDeleteModal={openDeleteModal}
              >
                <Button
                  size={Button.ButtonSize.SMALL}
                  onClick={openTrainModal}
                  type={ButtonType.BOUNDARY_WHITE}
                >
                  {t('see_train')}
                </Button>
              </ConstructionModalToolbar>
            </div>
          </div>
          {tabs.map((tab) => (
            <TabPanel key={tab.title} className={'w-full noTabPanelPadding'}>
              {tab.content}
            </TabPanel>
          ))}
          {trainModal && wagon.construction_train_id ? (
            <TrainModal
              open={trainModal}
              trainId={wagon.construction_train_id}
              parentId={controlArea ? controlArea.id : 0}
              onClose={() => {
                setTrainModal(false)
                getWagon()
              }}
              onUpdate={getWagon}
              className={'h-[350px]'}
            />
          ) : null}

          {!isEmpty(wagon) && (
            <DeleteModal
              show={showDeleteModal}
              closeModal={() => setDeleteModal(false)}
              onDelete={deleteWagon}
              itemIdnType={`${wagon.record_id} (${t('wagon')})`}
              itemName={`${wagon.record_id} - ${wagon.title}`}
            />
          )}
        </Tabs>

        {showQrModal && (
          <QrModal
            show={showQrModal}
            close={toggleQrModal}
            title={getModalTitle()}
            value={qrString}
          />
        )}
      </div>
    </FixedHorizintalPane>
  )
}

export default WagonModal
