import CheckCircle from '@icons/check_circle.svg'
import Close from '@icons/close.svg'
import Info from '@icons/info.svg'
import Notification from '@icons/notifications.svg'
import Warning from '@icons/warning.svg'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ResolutionHandler from '../../service/ResolutionHandler'
import { classNames } from '../../utility/utils'
import { AlertContext } from './AlertContext'

export type IAlertType = 'success' | 'error' | 'info' | 'warning'
export interface IAlertProps {
  type: 'success' | 'error' | 'info' | 'warning'
  title?: string
  description?: string
  clearFunc?: () => void
  autoClose?: boolean
  alertId?: number
  onClick?: () => void
}

export type IAlert = IAlertProps

export const Alert = ({
  type,
  title,
  description = '',
  clearFunc,
  autoClose = true,
  alertId,
  onClick,
}: IAlertProps) => {
  const inMobile = ResolutionHandler.shouldShowMobileVersion()
  const styleClass = {
    container: (alertType: string) =>
      classNames(
        'shadow-xsm',
        'overflow-hidden',
        alertType === 'success' && 'bg-light-green',
        alertType === 'error' && 'bg-pink',
        alertType === 'info' && 'bg-light-blue',
        alertType === 'warning' && 'bg-light-yellow',
      ),
    description: classNames(
      'mt-1 leading-4 text-gray-800 font-light first-capitalize',
      inMobile ? 'text-xs' : 'text-sm',
    ),
    progress: (alertType: string) =>
      classNames(
        'absolute bottom-0 left-0 h-0.5 relative',
        alertType === 'success' && 'bg-fo-green',
        alertType === 'error' && 'bg-d-danger',
        alertType === 'info' && 'bg-blue-root',
        alertType === 'warning' && 'bg-fo-yellow',
      ),
  }

  const alertContext = useContext(AlertContext)
  const { removeAlert } = alertContext.actions
  const duration = 10000
  const intervalSet = useRef(false)
  const [progress, setProgress] = useState(100)

  const remove = useCallback(
    (id: number) => {
      clearFunc?.()
      removeAlert(id)
    },
    [clearFunc, removeAlert],
  )

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (!intervalSet.current && autoClose && alertId) {
      intervalSet.current = true
      const startTime = Date.now()

      const updateProgress = () => {
        const elapsed = Date.now() - startTime
        const remaining = Math.max(0, duration - elapsed)
        setProgress((remaining / duration) * 100)

        if (remaining === 0) {
          remove(alertId)
          clearInterval(timer)
        }
      }

      timer = setInterval(updateProgress, 100)
    }
  }, [alertId, autoClose, remove])

  const renderSwitch = (alertType: IAlertType) => {
    switch (alertType) {
      case 'success':
        return <CheckCircle className="w-6 h-6 mt-1 fill-fo-green" />
      case 'info':
        return <Notification className="w-6 h-6 mt-1 fill-blue-root" />
      case 'warning':
        return <Warning className="w-6 h-6 mt-1 fill-fo-yellow" />
      case 'error':
        return <Info className="w-6 h-6 mt-1 fill-d-danger" />
      default:
        return <Info className="w-6 h-6 mt-1 fill-d-danger" />
    }
  }

  return (
    <div
      style={{ maxWidth: '415px' }}
      className="z-55  w-full cursor-pointer shadow-xsm pointer-events-auto mt-4 rounded overflow-hidden transition ease-in-out duration-200"
      onClick={onClick}
    >
      <div className={styleClass.container(type || 'info')}>
        <div className={`${inMobile ? 'px-2 py-1' : 'p-3'}`}>
          <div className="flex ">
            <div className={'flex'}>{renderSwitch(type || 'info')}</div>
            <div style={{ marginTop: 2 }} className="ml-2.5 flex-1 pt-0.5">
              <p className="first-capitalize font-bold leading-5 text-base  text-gray-800">
                {title}
              </p>
              <p className={styleClass.description}>{description}</p>
            </div>
            <div className="ml-2.5 flex-shrink-0 flex">
              <button
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (alertId) {
                    remove(alertId)
                  }
                }}
                className="inline-flex text-white focus:outline-none"
              >
                <Close className="text-bold" fontSize={24} />
              </button>
            </div>
          </div>
        </div>
        {autoClose && (
          <div className="relative">
            <div
              className={styleClass.progress(type)}
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        )}
      </div>
    </div>
  )
}
