import * as React from 'react'
import { IMetaValue } from 'src/document/types/IMetaData'
import SelectorPageDisplay from 'src/ui-elements/page-display/SelectorPageDisplay'
import Selector from '../../../components/selectors/Selector'

interface IDocumentMetaDataStringInput {
  field: IMetaValue
  onChange: (newValue?: string) => void
  isPage: boolean
  editMode: boolean
}

const DocumentMetaDataEnumInput: React.FC<IDocumentMetaDataStringInput> = ({
  field,
  onChange,
  isPage,
  editMode,
}) => {
  const items = Array.isArray(field.pattern)
    ? field.pattern.map((pattern: string) => ({
        id: pattern,
        value: pattern,
      }))
    : []

  return (
    <div className="w-full">
      {isPage ? (
        <SelectorPageDisplay
          items={items}
          selectedItemId={field.data_value as string}
          onSelect={(item) => onChange(item)}
          label={field.name}
          dataFields={['value']}
          fontSize={'sm'}
          fontWeight={'bold'}
          required={field.is_required}
          errorMessage={field.error}
          editingMode={editMode}
          displayData={`${
            items.find((i) => `${i.id}` === `${field.data_value}`)?.value ?? ''
          }`}
        />
      ) : (
        <Selector
          items={items}
          selectedItemId={field.data_value as string}
          onSelect={(item) => onChange(item)}
          label={field.name}
          dataFields={['value']}
          fontSize={'sm'}
          fontWeight={'bold'}
          required={field.is_required}
          errorMessage={field.error}
          disabled={!editMode}
          cancelButton={editMode}
          onCancel={() => onChange(undefined)}
        />
      )}
    </div>
  )
}

export default DocumentMetaDataEnumInput
