import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateTaskForm from 'src/components/task/CreateTaskForm'
import TaskInspectorPanel from 'src/components/task/TaskInspectorPanel'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { useImprovementTasks } from 'src/query/improvements'
import { IImprovement } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'

interface ImprovementTasksSectionTableProps {
  improvement: IImprovement
  projectId: number
}

const ImprovementTasksSectionTable = ({
  improvement,
  projectId,
}: ImprovementTasksSectionTableProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [selectedTaskId, setSelectedTaskId] = useState<number>()
  const [showCreateNewTaskModal, setShowCreateNewKeyTaskModal] = useState(false)

  const { data: tasks, isLoading: tasksLoading } = useImprovementTasks(
    improvement.id,
  )

  const reload = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['improvement/tasks', improvement.id],
    })
  }, [queryClient, improvement])

  return (
    <div>
      <Button
        type={Button.ButtonType.PRIMARY}
        size={Button.ButtonSize.SMALL}
        onClick={() => setShowCreateNewKeyTaskModal(true)}
        margin="mb-2"
      >
        {t('new_action')}
      </Button>
      <InspectorSectionTable
        headerColumns={[
          t('id'),
          t('title'),
          t('status'),
          t('deadline'),
          t('task_type'),
        ]}
        rowsData={(tasks || [])?.map(
          ({ id, title, status, deadline, task_type }) => ({
            cells: [
              id,
              title,
              t(status),
              moment(deadline).format('L'),
              t(task_type?.taskTypeName || ''),
            ],
            id,
          }),
        )}
        loading={tasksLoading}
        handleClick={setSelectedTaskId}
      />
      {selectedTaskId && (
        <TaskInspectorPanel
          taskId={selectedTaskId}
          open={selectedTaskId !== undefined}
          onClose={() => setSelectedTaskId(undefined)}
          projectId={projectId}
          onUpdate={reload}
        />
      )}
      {showCreateNewTaskModal && (
        <CreateTaskForm
          lastAllowedDeadline={moment(improvement.deadline)}
          deadlineErrorMessage={t(
            'the_action_must_be_before_the_deadline_for_the_associated_improvement',
          )}
          defaultTask={{
            improvement_id: improvement.id,
            improvement: improvement,
            main_process: improvement.main_process,
            main_process_id: improvement.main_process_id,
          }}
          closeModal={() => {
            setShowCreateNewKeyTaskModal(false)
            reload()
          }}
        />
      )}
    </div>
  )
}

export default ImprovementTasksSectionTable
