import i18next from 'i18next'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import LanguageSelector from 'src/components/language-selector/LanguageSelector'
import { useCurrentSubcontractor } from 'src/query/fdvu/subcontractor'

const FdvuSubcontractorTopMenu: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const { data: currentSubcontractor } = useCurrentSubcontractor()

  const setLanguageLocalStorage = (language: string) => {
    localStorage.setItem('i18Lng', language)
  }

  const setDefaultLanguage = useCallback(async () => {
    const language = localStorage.getItem('i18Lng')
    if (language) {
      await i18next.changeLanguage(language)
    }
  }, [])

  useEffect(() => {
    setDefaultLanguage()
  }, [setDefaultLanguage])

  if (!currentSubcontractor) return

  return (
    <div className="bg-white shadow flex items-center justify-between h-16 px-6">
      <div className="flex items-center">
        <img
          style={{ maxHeight: 35, width: 'auto' }}
          onClick={() => history.push('/subcontractor')}
          className="cursor-pointer pr-4 pl-2 md:pl-0 py-1"
          src="/taskctr-logomork.png"
          width="auto"
          height="40"
        />
        {currentSubcontractor.project?.logo ? (
          <img
            style={{ maxHeight: 35, width: 'auto' }}
            className="pr-4 pl-2 md:pl-0 py-1"
            src={currentSubcontractor.project.logo}
            width="auto"
            height="40"
          />
        ) : (
          <span className="font-light">
            {currentSubcontractor?.project?.projectName}
          </span>
        )}
      </div>
      <span className="flex gap-1 items-baseline">
        <span className="font-light">{t('document_portal')}</span>
        <span className="font-normal text-lg">{currentSubcontractor.name}</span>
      </span>
      <LanguageSelector onLanguageSelected={setLanguageLocalStorage} />
    </div>
  )
}

export default FdvuSubcontractorTopMenu
