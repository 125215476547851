import * as Sentry from '@sentry/react'
import { Component } from 'react'
import { IUser, Module } from '../../service/OrgTypes'
import { getOwnUser } from '../../service/UserService'
import { initialState, UserContext } from './UserContext'

export default class UserContextProvider extends Component<any, IUser> {
  private reload = () => {
    return getOwnUser()
      .then((res: any) => {
        this.setState(res, () => {
          localStorage.setItem(
            'loggedInUser',
            JSON.stringify(this.cleanUpUser(res.user)),
          )
        })

        Sentry.setUser({
          id: res.user.id,
          username: `${res.user.firstName} ${res.user.lastName}`,
          email: res.user.email,
        })
      })
      .catch((err) => console.error(err))
  }

  private hasReadAccess = (module: Module) => {
    const permission = this.state.user.role_permissions?.[module]?.permission
    return permission !== 'none'
  }

  private hasWriteAccess = (module: Module) => {
    const permission = this.state.user.role_permissions?.[module]?.permission
    return (
      permission === 'admin' ||
      permission === 'contract' ||
      permission === 'discipline' ||
      this.state.user.isSystemAdmin ||
      this.state.user.isProjectAdmin ||
      false
    )
  }

  private hasAdminAccess = (module: Module) => {
    const permission = this.state.user.role_permissions?.[module]?.permission
    return (
      permission === 'admin' ||
      this.state.user.isSystemAdmin ||
      this.state.user.isProjectAdmin ||
      false
    )
  }

  public constructor(props: any, context?: any) {
    super(props, context)
    this.state = initialState.state
  }

  public componentDidMount() {
    this.reload()
  }

  private cleanUpUser = (user: any) => {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      organization_id: user.organization_id,
    }
  }

  public render() {
    const { state } = this
    const actions = {
      reload: this.reload,
      hasReadAccess: this.hasReadAccess,
      hasWriteAccess: this.hasWriteAccess,
      hasAdminAccess: this.hasAdminAccess,
    }
    const context = {
      actions,
      state,
    }
    return (
      <UserContext.Provider value={context}>
        {this.state && this.state?.user?.id && this.props.children}
      </UserContext.Provider>
    )
  }
}
