import { classNames } from '../../utility/utils'

const Spinner = (): JSX.Element => (
  <div className={classNames('loader_container')}>
    <span className={classNames('loader_dot')} />
    <span className={classNames('loader_dot')} />
    <span className={classNames('loader_dot')} />
  </div>
)

export default Spinner
