export enum StatusEnum {
  EMPTY = 'EMPTY',
  OVERDUE = 'OVERDUE',
  DECLINED = 'DECLINED',
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  APPROVED_WITH_ISSUES = 'APPROVED_WITH_ISSUES',
  SUGGESTED = 'SUGGESTED',
  UPLOADED = 'UPLOADED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  DONE_WITH_ISSUES = 'DONE_WITH_ISSUES',
  NOT_RELEVANT = 'NOT_RELEVANT',
  ERROR = 'ERROR',
  NOT_SET = 'NOT_SET',
}
