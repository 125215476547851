import Attachment from '@icons/attachment.svg'
import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import AttachmentsInspectorSectionTableWithUpload from 'src/components/inspector-section-tables/AttachmentsInspectorSectionTableWithUpload'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import { IMetaValue } from 'src/document/types/IMetaData'
import { useTaskDetailPageProp } from 'src/hooks/useTaskDetailPageProp'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { IImprovement, ITaskData } from 'src/service/OrgTypes'
import { getSystems } from 'src/service/SystemService'
import { actionStatus } from 'src/service/SystemValues'
import { editTask, getProjectTaskTypes, getTask } from 'src/service/TaskService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import { getErrorMessage, ValidationError } from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter, classNames } from 'src/utility/utils'
import InspectorSectionTable from '../../document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from '../../document/components/Inspector/InspectorSections'
import history from '../../history'
import CopyUrl from '../copy/CopyUrl'
import ImprovementPanel from '../improvement/improvement-panel/ImprovementPanel'
import QrModal from '../qr/QrModal'
import RiskInspectorPanel from '../risk/RiskInspectorPanel'
import { getMetaDataValues, loadMetaValues } from '../system/SystemUtil'

interface ITaskTypeInspector {
  taskId: number
  open: boolean
  onClose: () => void
  projectId: number
  hideSystem?: boolean
  notFound?: (id: number) => void
  onUpdate: () => void
  defaultIndex?: number
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
}

const TaskTypeInspector: React.FC<ITaskTypeInspector> = ({
  taskId,
  open,
  onClose,
  projectId,
  hideSystem,
  notFound,
  onUpdate,
  defaultIndex = 0,
}) => {
  const { t } = useTranslation()
  const [taskData, setTaskData] = useState<ITaskData>({} as ITaskData)
  const [loading, setLoading] = useState<boolean>(true)
  const projectContext = useContext(ProjectContext)
  const [projectEndDate, setProjectEndDate] = useState<moment.Moment>(moment())
  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<ITaskData>(setTaskData, taskData)
  const [improvements, setImprovments] = useState<IImprovement[]>([])
  const [qrString, setQrString] = useState<string>('')
  const [showQrModal, setQrModal] = useState<boolean>(false)
  const [showImprovementPanel, setShowImprovementPanel] =
    useState<boolean>(false)
  const [openRiskInspectionPanel, setOpenRiskInspectionPanel] =
    useState<boolean>(false)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])

  useEffect(() => {
    loadTask()
  }, [taskId, projectId])

  const loadTask = () => {
    const { currentProject } = projectContext.state
    setLoading(true)
    getTask(projectId, taskId).then((aTask) => {
      if (aTask.status === 404 && notFound) {
        notFound(taskId)
      }
      setTaskData(aTask)
      setImprovments(aTask.improvement ? [aTask.improvement] : [])
      setProjectEndDate(moment(currentProject.endDate))
      const metaData = getMetaDataValues(aTask.meta_data)
      setOptionalFields(
        loadMetaValues(
          taskId,
          'Task',
          aTask.task_type?.optional_fields ?? [],
          metaData,
        ),
      )
      setLoading(false)
    })
  }

  const onStatusSelect = () => {
    const updatedtask = { ...taskData, status: 'done' }
    editTask(updatedtask).then(() => {
      loadTask()
      onUpdate()
    })
  }

  const parentIsMeeting = (): boolean => {
    return taskData.parent && taskData.parent.meeting_series_id
  }

  const isEndTimeValid = (dealineVal: moment.Moment) => {
    const parent = taskData.parent

    if (taskData && (!dealineVal || !moment(dealineVal).isValid())) {
      return t('select_w_param', { param: t('deadline') })
    }

    const improvement = improvements.find(
      (u) => u.id === taskData.improvement_id,
    )
    if (improvement) {
      if (moment(dealineVal).isAfter(moment(improvement.deadline), 'day')) {
        return (
          t(
            'deadline_for_the_task_must_be_before_the_deadline_for_the_corresponding_improvement',
          ) +
          ' (' +
          moment(improvement.deadline).format('L') +
          ')'
        )
      }
    }
    if (
      parent &&
      dealineVal &&
      ![
        'CheckInParameter',
        'ChecklistParameter',
        'ConstructionLocomotive',
        'ControlArea',
      ].includes(parent)
    ) {
      const endTime = parent === 'Risk' ? parent.deadline : parent.endTime
      const message =
        parent === 'Risk'
          ? t('deadline_must_be_within_the_risk_deadline') + ' ('
          : t('deadline_must_be_within_the_delivery_deadline') + ' ('
      if (endTime && moment(dealineVal).isAfter(moment(endTime), 'day')) {
        return message + moment(endTime).format('L') + ')'
      }

      if (
        taskData &&
        parent === 'Project' &&
        taskData.parent_id === projectId
      ) {
        if (moment(dealineVal).isAfter(moment(projectEndDate), 'day')) {
          return (
            t('deadline_must_be_within_the_project_deadline') +
            ' (' +
            moment(projectEndDate).format('L') +
            ')'
          )
        }
      }
    }
    return
  }

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4 -mt-2'}>
      {optionalFields.length > 0 && (
        <MetaDataFieldsForPage
          onFieldsUpdate={onTaskTypeUpdate}
          fields={optionalFields}
          required={false}
          editMode={false}
          inspectorPanel={true}
        />
      )}
    </div>
  )

  const getRows = (): IInspectorSection[] => [
    {
      name: t('additional_information'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      hidden: optionalFields?.length === 0,
      content: getMetaDataContent(),
      overflowVisible: true,
    },
    {
      name: t('related_tasks'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorSectionTable
          headerColumns={[t('id'), t('title'), t('status'), t('deadline')]}
          rowsData={
            taskData?.related_tasks
              ? taskData?.related_tasks.map((issue) => {
                  return {
                    cells: [
                      issue?.record_id ?? '',
                      issue.title,
                      t(`${issue.status}`),
                      moment(issue.deadline).format('L'),
                    ],
                    id: issue.id,
                  }
                })
              : []
          }
        />
      ),
    },
    {
      name: capFirstLetter(t('attachments')),
      icon: <Attachment />,
      content: (
        <AttachmentsInspectorSectionTableWithUpload
          parentId={taskId}
          parentType="Task"
          readonly={false}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={taskId} parentType="Task" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={taskId} parentType="Task" />,
    },
  ]

  const onChangeInput = (update: Partial<ITaskData>) => {
    if (taskData?.id && projectId) {
      const allUpdates = getChangesForUpdate(update)
      setTaskData({ ...taskData, ...allUpdates })
      editTask({
        ...convertUndefinedToNull(allUpdates),
        id: taskId,
        project_id: projectId,
      }).then(() => {
        onTaskTypeUpdate()
      })
    }
  }

  const onTaskTypeUpdate = () => {
    loadTask()
    onUpdate?.()
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const copyURL =
      url + '?modal=task&id=' + taskData.id + '&project=' + projectId
    setQrString(copyURL)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setQrModal((n) => !n)
  }

  const extraButtons = (): JSX.Element => {
    return (
      <div className="flex flex-row justify-end items-center">
        {taskData.status && taskData.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}
        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <CopyUrl
            params={{
              modal: 'task',
              id: taskData.id.toString(),
            }}
          />
        </span>
        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <a title={t('generate_qr_code')} className="flex items-center">
            <Icon
              style={{ width: 20, height: 20 }}
              icon={Icon.IconType.QR}
              onClick={toggleQrModal}
            />
          </a>
        </span>
        {parent === 'Risk' ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={() => setOpenRiskInspectionPanel(true)}
          >
            {t('see_risk')}
          </Button>
        ) : null}
        {false && parentIsMeeting() ? (
          <Button size={Button.ButtonSize.SMALL} onClick={gotoMeeting}>
            {t('see_meeting')}
          </Button>
        ) : null}
        {taskData.improvement_id ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={toggleImprovementPanel}
          >
            {t('see_improvement_measures')}
          </Button>
        ) : null}
      </div>
    )
  }

  const gotoMeeting = () => {
    if (taskData.parent) {
      const meetingID = taskData.parent.meeting_id
      const meetingSeriesId = taskData.parent.meeting_series_id
      if (meetingID && meetingSeriesId) {
        history.push(`/meeting-series/${meetingSeriesId}/meetings/${meetingID}`)
      } else if (meetingSeriesId) {
        history.push(`/meeting-series/${meetingSeriesId}`)
      }
    }
  }

  const toggleImprovementPanel = () => {
    setShowImprovementPanel((v) => !v)
  }

  const parent = useMemo(() => {
    return taskData?.parent_type ?? ''
  }, [taskData])

  const taskDetailPageProp = useTaskDetailPageProp(taskData)

  return (
    <FixedPane
      title={
        taskData?.title
          ? `${taskData?.record_id} - ${taskData?.title}`
          : t('loading...')
      }
      show={open}
      onClose={() => onClose()}
      className={'w-[600px]'}
      detailPageData={taskDetailPageProp}
    >
      {taskData.id ? (
        <div className={styleClass.root}>
          {extraButtons()}
          <InlineComponentsWrapper loading={loading} padding="left">
            <TextInlineInputCompontent
              label={t('title')}
              value={taskData?.title ?? ''}
              onValueSubmitted={(newValue) => {
                if (newValue) onChangeInput({ title: newValue })
              }}
              validate={(value) => {
                if (value === undefined || value === '') {
                  return getErrorMessage(ValidationError.MISSING_TITLE, t)
                }
                return
              }}
            />

            <SelectorInlineInputComponent
              items={actionStatus(t)}
              label={t('status')}
              getItemLabel={(stat) => stat?.name}
              initialItem={{
                id: taskData?.status ?? '',
                name:
                  actionStatus(t).find(
                    (statData) => statData.id === taskData?.status,
                  )?.name ?? '',
              }}
              validate={() => {
                if (
                  taskData.parent &&
                  taskData.parent.status &&
                  (parent === 'Risk' || parent === 'Delivery')
                ) {
                  if (
                    taskData.parent.status === 'done' &&
                    taskData.status !== 'done'
                  ) {
                    const message =
                      parent === 'Risk'
                        ? t('associated_risk_is_closed')
                        : t('associated_delivery_is_closed')
                    return message
                  }
                }
                return
              }}
              selectedId={taskData?.status}
              onValueSubmitted={(stat) => {
                onChangeInput({ status: stat })
              }}
              inspectorPanel={true}
            />

            <DateTimeInlineInputComponent
              label={t('deadline')}
              selectedTime={`${taskData?.deadline}`}
              onValueSubmitted={(deadlineValue) => {
                onChangeInput({ deadline: moment(deadlineValue) })
              }}
              validate={(value) => {
                if (value) {
                  return isEndTimeValid(value)
                }
                return
              }}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() => getProjectDisciplines(projectId)}
              label="discipline"
              initialItem={taskData?.discipline}
              getItemLabel={(discipline) =>
                `${discipline?.shortName} - ${discipline?.name}`
              }
              validate={(value) => {
                if (value === undefined)
                  return t('fill_out_w_param', {
                    param: t('discipline'),
                  })
                return
              }}
              selectedId={taskData?.discipline_id}
              onValueSubmitted={(discipline_id) => {
                addChangesToAppendWhenKeyUpdates('responsible_id', {
                  discipline_id,
                })
              }}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() =>
                taskData?.discipline_id
                  ? getDisplineUsers(taskData?.discipline_id)
                  : getProjectUsersWithDisciplines(projectId)
              }
              label="responsible"
              getItemLabel={(responsible) =>
                `${responsible?.firstName} ${responsible?.lastName}`
              }
              initialItem={taskData?.responsible}
              validate={(value) => {
                if (value === undefined)
                  return t('fill_out_w_param', {
                    param: t('responsible'),
                  })
                return
              }}
              selectedId={taskData?.responsible_id ?? 0}
              onValueSubmitted={(responsible_id) => {
                onChangeInput({ responsible_id })
              }}
              dependencies={[taskData?.discipline_id]}
              inspectorPanel={true}
            />
            <SelectorInlineInputComponent
              label={'contract'}
              disabled={true}
              selectedId={taskData?.contract_id ?? ''}
              getItemLabel={(contract) =>
                `${contract?.contractNumber} - ${contract?.contractName}`
              }
              initialItem={taskData?.contract}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() => getProjectTaskTypes(projectId)}
              label={t('task_type')}
              getItemLabel={(taskType) => taskType?.taskTypeName}
              initialItem={taskData?.task_type}
              selectedId={taskData?.task_type_id ?? 0}
              onValueSubmitted={(val) => {
                onChangeInput({ task_type_id: val })
              }}
              inspectorPanel={true}
            />

            <TextInlineInputCompontent
              label={t('description')}
              value={taskData?.description ?? ''}
              onValueSubmitted={(newValue) => {
                if (newValue) onChangeInput({ description: newValue })
              }}
              textArea={true}
            />

            {taskData.taskType === 'Aksjon' && !hideSystem && (
              <SelectorInlineInputComponent
                getItems={() => getSystems(projectId)}
                label={t('system')}
                getItemLabel={(system) =>
                  `${system?.record_id} ${system?.name}`
                }
                initialItem={taskData?.system}
                selectedId={taskData?.system_id ?? 0}
                onValueSubmitted={(val) => {
                  onChangeInput({ system_id: val })
                }}
                inspectorPanel={true}
              />
            )}

            <TextInlineInputCompontent
              label={t('reporter')}
              value={`${taskData?.reporter?.firstName} ${taskData?.reporter?.lastName}`}
              disabled={true}
            />
            <DateTimeInlineInputComponent
              label="created_at"
              selectedTime={taskData?.created_at}
              onValueSubmitted={() => {}}
              disabled={true}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label="updated_at"
              selectedTime={taskData?.updated_at}
              onValueSubmitted={() => {}}
              disabled={true}
              inspectorPanel={true}
            />
          </InlineComponentsWrapper>
          <InspectorSections defaultIndex={defaultIndex} sections={getRows()} />
          {openRiskInspectionPanel &&
            parent === 'Risk' &&
            taskData.parent_id && (
              <RiskInspectorPanel
                riskId={taskData.parent_id}
                open={openRiskInspectionPanel}
                onClose={() => setOpenRiskInspectionPanel(false)}
                onUpdate={() => loadTask()}
              />
            )}

          {showQrModal && (
            <QrModal
              show={showQrModal}
              close={toggleQrModal}
              title={`${taskData?.record_id} - ${taskData?.title}`}
              value={qrString}
            />
          )}

          {showImprovementPanel && taskData.improvement_id && (
            <ImprovementPanel
              projectId={projectId}
              improvementId={taskData.improvement_id}
              onClose={toggleImprovementPanel}
              show={showImprovementPanel}
            />
          )}
        </div>
      ) : (
        <span />
      )}
    </FixedPane>
  )
}
export default TaskTypeInspector
