import Add from '@icons/add.svg'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { useDeliveryColumns } from 'src/components/process/delivery/useDeliveryColumns'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { deliveriesExportData } from 'src/export-templates/DeliveryExports'
import { deliveryImportTemplate } from 'src/export-templates/DeliveryImportTemplate'
import { useFilteredDeliveries } from 'src/query/planning/deliveries'
import { useFreezePlan } from 'src/query/planning/freezePlan'
import {
  deleteBulkDeliveries,
  deleteDeliveryById,
  updateDelivery,
} from 'src/service/DeliveryService'
import {
  IDelivery,
  IDiscipline,
  IImportItemList,
  IKeypoint,
  IMainProcess,
  ISystemTypeGroup,
  ITeam,
  IUserData,
} from 'src/service/OrgTypes'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import Table from 'src/ui-elements/Table/Table'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import useAlert from 'src/ui-elements/toast/useAlert'
import { addUserDefinedInfoToImport } from 'src/utility/exportUtils'
import { classNames } from 'src/utility/utils'
import { userDefinedColumns } from '../../TableColumns/Columns'

import KeypointChangeLogModal from '../main-process/KeypointChangeLogModal'
import DeliveryForm from './DeliveryForm'
import DeliveryInspectorPanel from './DeliveryInspectorPanel'

interface IProjectDeliveriesListProps {
  keypoint?: IKeypoint
  isExpandedElement?: boolean
  disableAdd?: boolean
  tableName?: string
  improvementId?: number
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  reloadTree?: () => void
  systemTypeGroup?: ISystemTypeGroup
}

const ProjectDeliveriesList = ({
  keypoint,
  isExpandedElement,
  disableAdd,
  improvementId,
  systemTypeGroup,
  onOpenItem,
  reloadTree,
  tableName = 'projectDeliveriesList',
}: IProjectDeliveriesListProps) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
    add: classNames('py-1', 'text-center', 'w-6'),
  }

  const { t } = useTranslation()
  const projectContext = useContext(ProjectContext)
  const userContext = useContext(UserContext)
  const { id: projectId } = projectContext.state.currentProject

  const [selectedDelivery, setSelectedDelivery] = useState<IDelivery>(
    {} as IDelivery,
  )
  const [newDeliveryModal, setNewDeliveryModal] = useState<boolean>(false)
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false)
  const [copyDelivery, setCopyDelivery] = useState<Partial<IDelivery>>({})
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [changeLogModal, setChangeLogModal] = useState<boolean>(false)
  const [changeLogReason, setChangeLogReason] = useState<string>('')
  const { addAlert } = useAlert()

  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])
  const mainProcesses = useRef<IMainProcess[]>([])
  const themes = useRef<ITeam[]>([])
  const [openInspectorPanel, setOpenInspection] = useState(false)
  const [selectedDeliveryId, setSelectedDeliveryId] = useState(0)

  const queryClient = useQueryClient()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['deliveries'] })
  }

  const importDeliveryTemplate: IImportItemList = {
    title: t('upload_deliveries'),
    templateJson: addUserDefinedInfoToImport(
      deliveryImportTemplate,
      [],
      systemTypeGroup?.optional_fields ?? [],
    ),
    type: 'deliveries',
    reload,
  }

  const { data: freezePlan } = useFreezePlan('delivery')

  const onRowClick = (row: IDelivery) => {
    if (onOpenItem) {
      onOpenItem(row.id, 'delivery')
    } else {
      setOpenInspection(true)
      setSelectedDelivery(row)
    }
  }

  const onCloneItem = (row: IDelivery) => {
    const cDelivery: Partial<IDelivery> = {
      ...row,
      name: `Copy of ${row.name}`,
      copy_from_id: row.id,
      id: undefined,
    }
    setCopyModalOpen(true)
    setCopyDelivery(cDelivery)
  }

  const openCreateModal = () => {
    setNewDeliveryModal((n) => !n)
  }

  const copyModalClose = () => {
    setCopyModalOpen(false)
  }

  const closeCreateModal = () => {
    setNewDeliveryModal((n) => !n)
  }

  const saveDelivery = (delivery: Partial<IDelivery>, deliveryId: number) => {
    updateDelivery(delivery, deliveryId)
      .then(() => {
        setSelectedDelivery({} as IDelivery)
      })
      .finally(reload)
  }

  const deliveryUpdate = (newDelivery: IDelivery) => {
    if (newDelivery.id) {
      saveDelivery(newDelivery, newDelivery.id)
    }
  }

  const onDateChange = (date: string, delivery: IDelivery) => {
    const forwardInTime = moment(date).isAfter(delivery.endTime, 'day')
    const freezeUntil =
      freezePlan?.plan_freeze_period && freezePlan?.plan_freeze_period > 0
        ? moment()
            .add(freezePlan.plan_freeze_period - 1, 'weeks')
            .endOf('week')
        : moment().startOf('day')
    if (
      forwardInTime &&
      moment(delivery.endTime).isBefore(freezeUntil, 'day')
    ) {
      setSelectedDelivery({ ...delivery, endTime: date })
      setChangeLogReason('')
      setChangeLogModal(true)
    } else saveDelivery({ endTime: date }, delivery.id)
  }

  const onChangelogReasonChange = (value: string) => {
    setChangeLogReason(value)
  }

  const onChangeLogModalClose = () => {
    setChangeLogModal(false)
    setSelectedDelivery({} as IDelivery)
  }

  const onChangeLogSubmit = () => {
    setChangeLogModal((prevState) => !prevState)
    if (selectedDelivery.id) {
      saveDelivery(
        { ...selectedDelivery, change_reason: changeLogReason },
        selectedDelivery.id,
      )
    }
  }

  const onDeleteItemClick = (row: IDelivery) => {
    setShowDeleteModal(!showDeleteModal)
    setSelectedDelivery(row)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const removeDelivery = () => {
    if (selectedDelivery && selectedDelivery.id) {
      deleteDeliveryById(selectedDelivery.id)
        .then(() => {
          setShowDeleteModal(!showDeleteModal)
          reload()
          if (reloadTree) {
            reloadTree()
          }
        })
        .catch((err) => console.error(err))
    }
  }

  const deleteSelectedDeliveries = (deliveries: number[]) => {
    deleteBulkDeliveries(projectId, deliveries).then(() => {
      addAlert({
        type: 'success',
        title: t('successfully_deleted'),
        description: t('selected_deliveries_are_deleted'),
        autoClose: true,
      })
      reload()
      if (reloadTree) {
        reloadTree()
      }
    })
  }

  const onUpdateProjectDeliveriesList = () => {
    reload()
    if (reloadTree) {
      reloadTree()
    }
  }

  const updateMetaField = useCallback((data: IMetaValue) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload()
      })
    }
  }, [])

  const userDefinedAttributesColumns = useMemo(() => {
    if (systemTypeGroup) {
      return userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateMetaField,
        'meta_data',
      )
    } else {
      return []
    }
  }, [systemTypeGroup, updateMetaField])

  const handlePreviewClick = (data: IDelivery) => {
    setOpenInspection(true)
    if (data.id) {
      setSelectedDeliveryId(data.id)
    }
  }

  const onCloseInpectionPanel = () => {
    setOpenInspection(false)
  }

  const customFilter = useMemo(
    () => ({
      ...(keypoint && { key_point: [keypoint.id] }),
      ...(improvementId && { improvement: [improvementId] }),
    }),
    [improvementId, keypoint],
  )

  const columns = useDeliveryColumns(
    saveDelivery,
    deliveryUpdate,
    projectUsers,
    projectDiscipline,
    mainProcesses,
    themes,
    onDateChange,
  )

  const defaultOrdering = [
    'select',
    'record_id',
    'name',
    'status',
    'responsible',
    'endTime',
    'duration',
    'delay',
    'closed_date',
    'open_children',
    'key_point',
    'mile_stone',
    'main_process',
    'team',
    'tag',
    'improvement',
    'discipline',
    'contract',
    'user',
    'baseline',
    'created_at',
    'updated_at',
  ]

  return (
    <div className={styleClass.root}>
      <Table
        name={tableName}
        useDataQuery={useFilteredDeliveries}
        initialFilter={customFilter}
        legacyColumns={userDefinedAttributesColumns}
        onPreviewClick={handlePreviewClick}
        onDeleteClick={onDeleteItemClick}
        onCopyClick={onCloneItem}
        onRowClick={onRowClick}
        tableButtons={
          !isExpandedElement
            ? () => ({
                exportData: deliveriesExportData,
                onBulkDelete: deleteSelectedDeliveries,
                importItem: importDeliveryTemplate,
                customButtons: !disableAdd
                  ? [
                      <Button
                        key="new_delivery"
                        onClick={openCreateModal}
                        type={ButtonType.PRIMARY}
                      >
                        <Add className={'fill-white text-xl'} />
                        {t('new_delivery')}
                      </Button>,
                    ]
                  : undefined,
              })
            : undefined
        }
        columns={columns}
        defaultOrdering={defaultOrdering}
      />
      {newDeliveryModal ? (
        <DeliveryForm
          projectId={projectId}
          user={userContext.state.user}
          show={newDeliveryModal}
          selectedKeypoint={keypoint}
          onDeliveryCreated={onUpdateProjectDeliveriesList}
          isNotFromProcess={true}
          closeModal={closeCreateModal}
        />
      ) : null}

      {copyModalOpen ? (
        <DeliveryForm
          projectId={projectId}
          user={userContext.state.user}
          show={copyModalOpen}
          selectedKeypoint={keypoint}
          onDeliveryCreated={onUpdateProjectDeliveriesList}
          isNotFromProcess={true}
          delivery={copyDelivery}
          closeModal={copyModalClose}
        />
      ) : null}

      {changeLogModal && selectedDelivery.id ? (
        <KeypointChangeLogModal
          show={changeLogModal}
          toggleChangeLogModal={onChangeLogModalClose}
          onChangeLogSubmit={onChangeLogSubmit}
          onChangeReasonLog={onChangelogReasonChange}
        />
      ) : null}

      {!isEmpty(selectedDelivery) && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          customTitle={
            selectedDelivery.delete_access
              ? t('are_you_sure_you_want_to_delete_with_param', {
                  item: selectedDelivery.record_id,
                })
              : t('you_are_not_entitled_to_that_operation')
          }
          onDelete={removeDelivery}
          itemIdnType={`${selectedDelivery.record_id} (${t('delivery')})`}
          itemName={`${selectedDelivery.record_id} - ${selectedDelivery.name}`}
        />
      )}

      {openInspectorPanel && selectedDeliveryId ? (
        <DeliveryInspectorPanel
          deliveryId={selectedDeliveryId}
          open={openInspectorPanel}
          onClose={onCloseInpectionPanel}
          projectId={projectId}
          onOpenItem={onOpenItem}
          onUpdate={reload}
        />
      ) : undefined}
    </div>
  )
}

export default ProjectDeliveriesList
