import { useEffect, useCallback } from 'react'

const usePull = (callBack: () => Promise<void>, deps: number[]) => {
  const pull = useCallback(() => {
    callBack()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  useEffect(() => {
    pull()
  }, [pull])
}

export default usePull
