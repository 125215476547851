import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import TaskInspectorPanel from 'src/components/task/TaskInspectorPanel'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IDiscipline,
  IStatusTypes,
  ITask,
  ITaskData,
  IUserData,
} from 'src/service/OrgTypes'
import { actionStatus } from 'src/service/SystemValues'
import {
  addRelatedTasks,
  deleteTask,
  editTask,
  getFilterTasks,
  removeRelatedTasks,
} from 'src/service/TaskService'
import {
  getTaskErrorMessage,
  StructureValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import List from 'src/ui-elements/list/List'
import {
  IListFilter,
  ResourceType,
} from 'src/ui-elements/list/ListContextProvider'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import useAlert from 'src/ui-elements/toast/useAlert'
import {
  filterType,
  IListColumns,
  ISorting,
} from '../../ui-elements/list/ListTypes'
import {
  capFirstLetter,
  classNames,
  constructFilterJson,
  IActiveFilter,
} from '../../utility/utils'
import {
  ClosedColumn,
  CreatedAtColumn,
  DeadlineColumn,
  DurationColumn,
  MainprocessColumn,
  RecordIdColumn,
  ReporterColumn,
  TagColumn,
  TaskTypeColumn,
  TitleColumn,
  UpdatedAtColumn,
} from '../TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumn,
  ResponsibleColumn,
} from '../TableColumns/DisciplineResponsibleColumns'
import TableStatusLabel from '../status-dropdown/TableStatusLabel'
import TaskSelectorList from './TaskSelectorList'

interface IRelatedTasksProps {
  taskId: number
}

const styleClass = {
  root: classNames('w-full', 'flex', 'flex-col', 'pt-0', 'pl-0'),
  inputGroup: classNames('w-full', 'flex', 'row'),
}

const RelatedTasks = ({ taskId }: IRelatedTasksProps) => {
  const { t } = useTranslation()
  const [tasks, setTasks] = useState<ITask[]>([])
  const [selectedTask, setSelectedTask] = useState<ITaskData>({} as ITaskData)
  const [selectedTaskIds, setSelectedTaskIds] = useState<number[]>([])
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [showTasksModal, setShowTasksModal] = useState(false)
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])
  const { addAlert } = useAlert()

  const projectContext: IProjectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [relatedTaskIds, setRelatedTaskIds] = useState<number[]>([])
  const [showInspector, setShowInspector] = useState(false)
  const tasksRef = useRef(tasks)

  useEffect(() => {
    reload()
  }, [taskId])

  const reload = () => {
    setReloadTable((n) => !n)
  }

  const onSaveSelectedTasks = async () => {
    addRelatedTasks(projectId, taskId, relatedTaskIds).then(() => {
      setShowTasksModal(false)
      reload()
    })
  }

  const onCancelSelectedTasks = async () => {
    setShowTasksModal(false)
    reload()
  }

  const onBulkDelete = () => {
    removeRelatedTasks(projectId, taskId, selectedTaskIds).then(() => {
      setShowTasksModal(false)
      setSelectedTaskIds([])
      reload()
    })
  }

  const onShowTaskModal = (row: any) => {
    setSelectedTask(row)
    setShowInspector(true)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting

    activeFilters['related_to_task'] = [taskId]

    taskFilter(activeFilters, page)
  }

  const taskFilter = (activeFilters: IActiveFilter, page: number) => {
    getFilterTasks(projectId, activeFilters, page, 15, 'Aksjon').then((res) => {
      setTasks(res.tasks)
      setRelatedTaskIds(res.tasks.map((t: ITask) => t.id))
      setTotalPages(res.pages)
    })
  }

  const onDateChange = (date: moment.Moment, key: number) => {
    const oldTasks = [...tasksRef.current]

    const task = oldTasks.filter((d) => d.id === key).pop()
    if (
      task &&
      task.parent &&
      ![
        'CheckInParameter',
        'ChecklistParameter',
        'ConstructionLocomotive',
        'ControlArea',
      ].includes(task.parent_type)
    ) {
      if (task.parent.deadline || task.parent.endTime || task.parent.endDate) {
        const deadline = moment(
          task.parent.deadline || task.parent.endTime || task.parent.endDate,
        )
        if (deadline.isBefore(date, 'day')) {
          showAlert(
            `${getTaskErrorMessage(
              StructureValidationError.PAST_PARENT_DEADLINE_TASK,
              t,
            )} ( ${moment(deadline).format('L')} )`,
          )
          return
        }
      }
      const projectEndDate = projectContext.state.currentProject.endDate
      if (moment(date).isAfter(moment(projectEndDate), 'day')) {
        showAlert(
          `${getTaskErrorMessage(
            StructureValidationError.PAST_PROJECT_DEADLINE,
            t,
          )} ( ${moment(projectEndDate).format('L')} )`,
        )
        return
      }
    }

    if (task && task.improvement && task.improvement.deadline) {
      if (moment(task.improvement.deadline).isBefore(date, 'day')) {
        showAlert(
          `${getTaskErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
          )} ( ${moment(task.improvement.deadline).format('L')} )`,
        )
        return
      }
    }
    editTask({ id: key, deadline: date } as ITask).then(() => {
      reload()
    })
  }

  const showAlert = (text: string) => {
    addAlert({
      type: 'error',
      title: t('an_error_occurred'),
      description: text,
    })
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const dataField = {
      id,
      discipline_id: disciplineId,
      responsible_id: responsibleId,
    } as ITask
    saveTask(dataField)
  }

  const saveTask = (value: ITask) => {
    editTask(value).then(() => {
      reload()
    })
  }

  const updateUser = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const dataField = {
      id,
      discipline_id: disciplineId,
      responsible_id: responsibleId,
    } as ITask
    saveTask(dataField)
  }

  const colForPage: IListColumns[] = [
    RecordIdColumn('200'),
    TitleColumn('title', '480', (key, value) =>
      onFieldChange('title', key, value),
    ),
    MainprocessColumn(
      projectId,
      (key, value) => onFieldChange('main_process_id', key, value),
      false,
    ),
    TaskTypeColumn(projectId, (key, value) =>
      onFieldChange('task_type_id', key, value),
    ),
    {
      name: 'status',
      size: '200',
      id: 'status',
      sortingField: 'status',
      dataField: 'status||deadline',
      filterType: filterType.DEFAULT,
      filter: [
        {
          name: 'open',
          value: 'open',
          active: false,
        },
        {
          name: 'in_progress',
          value: 'in_progress',
          active: false,
        },
        {
          name: 'done',
          value: 'done',
          active: false,
        },
      ],
      cell: (cell: any, key: number) => (
        <TableStatusLabel
          statusTypes={actionStatus(t)}
          cell={cell}
          rowId={key}
          onStatusSelect={onStatusSelect}
        />
      ),
    },
    DeadlineColumn('deadline', 'deadline||parent||parent_type', onDateChange),
    DurationColumn,
    ClosedColumn,
    ResponsibleColumn(projectId, projectUsers, projectDiscipline, updateUser),
    DisciplineColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      updateDiscipline,
    ),
    ContractColumn(),
    TagColumn(projectId, (key, value) => onFieldChange('tag_id', key, value)),
    ReporterColumn('reporter'),
    CreatedAtColumn(),
    UpdatedAtColumn(),
  ]

  const onStatusSelect = (status: IStatusTypes, key: number, cell: any) => {
    const dataField: ITask = cell
    if (status.id === 'done') {
      dataField.closed_date = moment()
    } else {
      if (dataField.status === 'done') {
        dataField.closed_date = null
      }
    }
    dataField.status = status.id
    dataField.id = key
    editTask(dataField).then(() => {
      reload()
    })
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const taskList = [...tasksRef.current]
    const task = taskList.find((d) => d.id === key)
    if (task) {
      task[field] = value
      saveTask(task)
    }
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
    reload()
  }

  const removeTask = () => {
    deleteTask(selectedTask)
      .then(() => {
        setShowDeleteModal(!showDeleteModal)
        reload()
      })
      .catch((err) => console.error(err))
  }
  const getActionButtons = () => {
    return (
      <Button
        type={Button.ButtonType.BOUNDARY_WHITE}
        dontSubmit={true}
        onClick={() => setShowTasksModal(true)}
      >
        {t('select_related_tasks')}
      </Button>
    )
  }

  const handlePreviewClick = (e: any, data: ITaskData) => {
    e.preventDefault()
    e.stopPropagation()
    setShowInspector(true)
    if (data.id) {
      setSelectedTask(data)
    }
  }

  return (
    <div className={styleClass.root}>
      <List
        columns={colForPage}
        actionButton={getActionButtons()}
        data={tasks}
        tableName={'RelatedTasks'}
        itemsPerPage={50}
        pagination={true}
        resourceType={ResourceType.TASK}
        onRowClick={onShowTaskModal}
        sortBackend={true}
        getFilteredData={getFilteredData}
        disableColumnSelector={true}
        onPreviewClick={handlePreviewClick}
        reload={reloadTable}
        totalPages={totalPages}
        isRowSelectable={true}
        selectedRows={selectedTaskIds}
        onBulkDelete={onBulkDelete}
        bulkDelete={true}
        onSelectRow={(data) => setSelectedTaskIds(data)}
      />

      {!isEmpty(selectedTask) && showInspector && (
        <TaskInspectorPanel
          taskId={selectedTask.id}
          open={showInspector}
          onClose={() => {
            setShowInspector(false)
            setSelectedTask({} as ITaskData)
          }}
          projectId={projectId}
          onUpdate={reload}
        />
      )}
      {showTasksModal && (
        <Modal
          title={t('related_tasks')}
          closeModal={() => setShowTasksModal(false)}
          maxWidth={1280}
          show={showTasksModal}
        >
          <div className="pl-1 min-h-[68vh]">
            <TaskSelectorList
              projectId={projectId}
              onSelectIds={setRelatedTaskIds}
              selectedIds={relatedTaskIds}
            />
          </div>
          <ModalFooter>
            <Button onClick={onCancelSelectedTasks}>
              {capFirstLetter(t('cancel'))}
            </Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              noTextWrap={true}
              onClick={onSaveSelectedTasks}
            >
              {capFirstLetter(t('save'))}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {!isEmpty(selectedTask) && (
        <DeleteModal
          customTitle={
            selectedTask.delete_access
              ? t('are_you_sure_you_want_to_delete_no_question_mark') +
                ' ' +
                selectedTask.record_id +
                '?'
              : t('you_are_not_entitled_to_that_operation')
          }
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={removeTask}
          itemIdnType={`${selectedTask.record_id} (${t('task')})`}
          itemName={`${selectedTask.record_id} - ${selectedTask.title}`}
        />
      )}
    </div>
  )
}
export default RelatedTasks
