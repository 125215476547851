import Edit from '@icons/edit.svg'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { IFileContainer } from 'src/document/types/IFileContainer'
import { IMetaValue } from 'src/document/types/IMetaData'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineCompontent from 'src/ui-elements/page-display/inline-components/InlineComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import SystemInspectorPanel from '../../../components/system/SystemInspectorPanel'
import {
  getMetaDataValues,
  initializeMetaValues,
  setExistingValues,
} from '../../../components/system/SystemUtil'
import { queryClient } from '../../../query/client'
import { useDocumentType } from '../../../query/documents/documentType'
import {
  fileContainerKey,
  useFileContainer,
} from '../../../query/documents/fileContainer'
import { getProjectDisciplines } from '../../../service/DisciplineService'
import {
  editDocument,
  validateDocumentNumber,
} from '../../../service/FileContainerService'
import Button from '../../../ui-elements/button/Button'
import { ButtonType } from '../../../ui-elements/button/ButtonEnums'
import FixedPane from '../../../ui-elements/fixed-pane/FixedPane'
import DocListStage from '../FolderDocument/DocListStatus'
import FilesInspector from '../Folders/FolderInspectorSections/FilesInspector'
import RevisionInspector from '../Folders/FolderInspectorSections/RevisionInspector'
import MetaDataBox from '../MetaDataBox'
import InspectorSectionTable from './InspectorSectionTable'
import InspectorSections, { IInspectorSection } from './InspectorSections'

interface IInspectorPanel {
  documentId: number
  open: boolean
  showRevision?: boolean

  onClose: () => void
  onUpdate?: () => void
}

const DocumentInspectorPanel: React.FC<IInspectorPanel> = ({
  documentId,
  open,
  showRevision,
  onClose,
  onUpdate,
}) => {
  const { t } = useMultipleKeysTranslation()

  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const [loading, setLoading] = useState<boolean>(false)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [requiredFields, setRequiredFields] = useState<IMetaValue[]>([])
  const [selectedSystem, setSelectedSystem] = useState<number>()
  const [showSystemInspectorPanel, setShowSystemInspectorPanel] =
    useState(false)
  const [newRecordId, setNewRecordId] = useState<string>()
  const [newRecordIdError, setNewRecordIdError] = useState<string>()
  const [showRecordIdUpdate, setShowRecordIdUpdate] = useState(false)
  const { data: document, isFetching } = useFileContainer(documentId)
  const { data: documentTypes } = useDocumentType()

  const writeAccess = document?.can_edit

  useEffect(() => {
    setLoading(true)
    if (document) {
      const metaData = getMetaDataValues(document.meta_data)
      const optional = initializeMetaValues(
        document?.optional_fields ?? [],
        'FileContainer',
        document.id,
      )
      setOptionalFields(setExistingValues(metaData, optional))
      const required = initializeMetaValues(
        document?.required_fields ?? [],
        'FileContainer',
        document.id,
      )
      setRequiredFields(setExistingValues(metaData, required))
      setLoading(false)
    }
  }, [document])

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4'}>
      <MetaDataBox
        disabled={!document?.can_edit}
        requiredFields={requiredFields}
        optionalFields={optionalFields}
        inInspector={true}
        onOptionalFieldsUpdate={onDocumentUpdate}
        onRequiredFieldsUpdate={onRequiredMetadataUpdate}
      />
    </div>
  )

  const onChangeInput = (update: Partial<IFileContainer>) => {
    editDocument(
      projectId,
      documentId,

      {
        ...update,
        id: documentId,
      },
    ).then((_res: IFileContainer) => {
      onDocumentUpdate()
    })
  }

  const onDocumentUpdate = () => {
    onReloadDocument()
    onUpdate?.()
  }

  const onRequiredMetadataUpdate = () => {
    onChangeInput({ id: documentId })
  }

  const onReloadDocument = () => {
    queryClient.invalidateQueries({ queryKey: [fileContainerKey] })
  }

  const onRecordIdUpdate = () => {
    if (documentId && newRecordId && newRecordId !== document?.record_id) {
      validateDocumentNumber(projectId, documentId, newRecordId).then((res) => {
        if (res.errors) {
          setNewRecordIdError(res.errors)
        } else {
          onRecordIdCancel()
          onUpdate?.()
        }
      })
    } else onRecordIdCancel()
  }

  const onRecordIdCancel = () => {
    setShowRecordIdUpdate(false)
    setNewRecordIdError(undefined)
    onReloadDocument()
  }

  const onSystemClick = (id: number) => {
    setSelectedSystem(id)
    setShowSystemInspectorPanel(true)
  }

  const onSystemClose = () => {
    setSelectedSystem(undefined)
    setShowSystemInspectorPanel(false)
  }

  const getMainContent = () => {
    return (
      <div className="mt-2 pt-2">
        {document && (
          <InlineComponentsWrapper inputWidth="w-64" labelWidth="w-32">
            <div className={'flex items-center'}>
              <TextInlineInputCompontent
                key={isFetching ? '1' : '2'}
                label="document_number"
                value={document?.record_id}
                setValueInParent={setNewRecordId}
                validate={(newValue) => {
                  if (!newValue?.length) {
                    return t('required')
                  }
                  return undefined
                }}
                disabled={!showRecordIdUpdate}
                autoFocus={showRecordIdUpdate}
                error={newRecordIdError ? t(newRecordIdError) : undefined}
              />
              {!showRecordIdUpdate && writeAccess && (
                <Button
                  onClick={() => setShowRecordIdUpdate(true)}
                  type={ButtonType.SECONDARY}
                >
                  <Edit className={'text-lg'} />
                </Button>
              )}
              {showRecordIdUpdate && (
                <>
                  <Button onClick={onRecordIdUpdate} type={ButtonType.PRIMARY}>
                    {t('save')}
                  </Button>
                  <Button
                    onClick={onRecordIdCancel}
                    type={ButtonType.SECONDARY}
                  >
                    {t('cancel')}
                  </Button>
                </>
              )}
            </div>
            <TextInlineInputCompontent
              disabled={!writeAccess}
              label="document_name"
              value={document?.name}
              onValueSubmitted={(name) => {
                if (name) onChangeInput({ name })
              }}
            />
            <SelectorInlineInputComponent
              items={documentTypes}
              disabled={!writeAccess}
              label="document_type"
              getItemLabel={(docType) => docType?.name}
              initialItem={document?.document_type}
              validate={(value) => {
                if (value === undefined) return t('required')
                return
              }}
              selectedId={document?.document_type_id}
              onValueSubmitted={(document_type_id) => {
                onChangeInput({ document_type_id })
              }}
              inspectorPanel={true}
            />

            <InlineCompontent
              disabled={!writeAccess}
              label="step"
              content={
                document.current_step ? (
                  <DocListStage stage={document?.current_step} />
                ) : (
                  <span />
                )
              }
              className="max-w-[360px] w-[360px] truncate"
            />
            <TextInlineInputCompontent
              label="revision_name"
              value={document?.current_revision?.name}
              onValueSubmitted={(name) => {
                if (name) onChangeInput({ name })
              }}
              disabled={true}
            />
            <DateTimeInlineInputComponent
              label="revision_date"
              selectedTime={document?.current_revision?.start_time}
              onValueSubmitted={() => {}}
              disabled={true}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label="deadline"
              selectedTime={document.current_file_container_step?.duedate}
              onValueSubmitted={() => {}}
              disabled={true}
              inspectorPanel={true}
            />
            <SelectorInlineInputComponent
              getItems={() => getProjectDisciplines(projectId)}
              disabled={!writeAccess}
              label="discipline"
              initialItem={document?.discipline}
              getItemLabel={(discipline) =>
                `${discipline?.shortName} - ${discipline?.name}`
              }
              selectedId={document?.discipline_id}
              onValueSubmitted={(discipline_id) => {
                onChangeInput({ discipline_id })
              }}
              inspectorPanel={true}
            />

            <SelectorInlineInputComponent
              getItems={() =>
                document?.discipline_id
                  ? getDisplineUsers(document?.discipline_id)
                  : getProjectUsersWithDisciplines(projectId)
              }
              disabled={!writeAccess}
              label="responsible"
              getItemLabel={(responsible) =>
                `${responsible?.firstName ?? ''} ${responsible?.lastName ?? ''}`
              }
              initialItem={document?.responsible}
              selectedId={document?.responsible_id}
              onValueSubmitted={(responsible_id) => {
                onChangeInput({ responsible_id })
              }}
              inspectorPanel={true}
            />
            <SelectorInlineInputComponent
              label={'contract'}
              disabled={true}
              selectedId={document?.contract_id ?? ''}
              getItemLabel={(contract) =>
                `${contract?.contractNumber} - ${contract?.contractName}`
              }
              initialItem={document?.contract}
              inspectorPanel={true}
            />

            <DateTimeInlineInputComponent
              label="created_at"
              selectedTime={document?.created_at}
              onValueSubmitted={() => {}}
              disabled={true}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label="updated_at"
              selectedTime={document?.updated_at}
              onValueSubmitted={() => {}}
              disabled={true}
              inspectorPanel={true}
            />
          </InlineComponentsWrapper>
        )}
      </div>
    )
  }

  const sections = (data: IFileContainer): IInspectorSection[] => {
    return [
      {
        name: t('document_details'),
        icon: Icons.ACTIVITY_GREY,
        activeIcon: Icons.ACTIVITY,
        content: getMainContent(),
      },
      {
        name: t('systems'),
        icon: Icons.SYSTEM_GRAY,
        activeIcon: Icons.SYSTEM_BLACK,
        content: (
          <InspectorSectionTable
            headerColumns={[
              t('system_number'),
              t('system_name'),
              t('system_type'),
              t('status'),
            ]}
            rowsData={
              data?.systems?.map((system) => {
                return {
                  cells: [
                    system?.record_id ?? '',
                    system?.name ?? '',
                    `${system?.system_type?.type_code ?? ''} - ${
                      system?.system_type?.name ?? ''
                    }`,
                    system?.system_status?.name ?? '',
                  ],
                  id: system.id,
                }
              }) ?? []
            }
            handleClick={onSystemClick}
          />
        ),
      },
      {
        name: t('revisions'),
        icon: Icons.ACTIVITY_GREY,
        activeIcon: Icons.ACTIVITY,
        content: <RevisionInspector document={data} />,
      },
      {
        name: t('files'),
        icon: Icons.FOLDER_GREY,
        activeIcon: Icons.FOLDER,
        content: <FilesInspector document={data} loading={loading} />,
      },
      {
        name: 'Metadata',
        icon: Icons.DATABASE_GREY,
        activeIcon: Icons.DATABASE,
        content: getMetaDataContent(),
      },
    ]
  }

  return (
    <FixedPane
      title={`${document?.record_id ?? ''} ${document?.record_id ? '-' : ''}  ${
        document?.name ?? ''
      }`}
      show={open}
      onClose={() => onClose()}
      className={'w-[700px]'}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.DOCUMENT,
        ids: {
          folderId: document?.folder_id,
          documentId: documentId,
        },
      }}
    >
      <>
        {document && (
          <InspectorSections
            sections={sections(document)}
            noMainContent={true}
            defaultIndex={showRevision ? 2 : 0}
          />
        )}
        {selectedSystem && showSystemInspectorPanel && (
          <SystemInspectorPanel
            systemId={selectedSystem}
            open={showSystemInspectorPanel}
            onUpdate={onSystemClose}
            onClose={onSystemClose}
          />
        )}
      </>
    </FixedPane>
  )
}
export default DocumentInspectorPanel
