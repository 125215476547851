import {
  PropsWithChildren,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as React from 'react'

type InspectorContextType = {
  actions: {
    getInspectorIndex: (inspectorId: number) => number | null
    addInspectorId: (inspectorId: number) => void
    removeInspectorId: (inspectorId: number) => void
  }
  state: { inspectorIds: number[] }
}

export const InspectorContext = createContext<InspectorContextType>({
  state: { inspectorIds: [] },
  actions: {
    getInspectorIndex: () => null,
    addInspectorId: () => undefined,
    removeInspectorId: () => undefined,
  },
})

export const InspectorContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [inspectorIds, setInspectorIds] = useState<number[]>([])
  const inspectorIdsRef = useRef<number[]>([])

  useEffect(() => {
    return () => {
      inspectorIdsRef.current = []
      setInspectorIds([])
    }
  }, [])

  const getInspectorIndex = (inspectorId: number): number | null => {
    const index = inspectorIdsRef.current.indexOf(inspectorId)
    return index !== -1 ? index : null
  }

  const addInspectorId = (inspectorId: number): void => {
    inspectorIdsRef.current = [...inspectorIds, inspectorId]
    setInspectorIds(inspectorIdsRef.current)
  }

  const removeInspectorId = (inspectorId: number): void => {
    const index = inspectorIdsRef.current.indexOf(inspectorId)
    if (index !== null) {
      inspectorIdsRef.current = inspectorIdsRef.current.filter(
        (val, i) => index !== i,
      )
      setInspectorIds(inspectorIdsRef.current)
    }
  }

  const actions = {
    addInspectorId: (index: number) => addInspectorId(index),
    removeInspectorId: (index: number) => removeInspectorId(index),
    getInspectorIndex: (inspectorId: number) => getInspectorIndex(inspectorId),
  }
  const context = { actions, state: { inspectorIds } }

  return (
    <InspectorContext.Provider value={context}>
      {children}
    </InspectorContext.Provider>
  )
}
