import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckBox from 'src/components/switchHoc/CheckBox'
import { ITestDocument } from 'src/service/OrgTypes'
import {
  createTestDocumentTypes,
  createTestDocumentTypesByTest,
  updateTestDocumentTypes,
} from 'src/service/TestService'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { classNames } from 'src/utility/utils'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import Spinner from '../../../ui-elements/loader/Spinner'

interface ITestDocumentFromProps {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  testDocument: Omit<ITestDocument, 'id'>
  testId?: number
}

const TestDocumentForm = ({
  projectId,
  closeModal,
  editingMode,
  testDocument,
  testId,
}: ITestDocumentFromProps) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  const { t } = useTranslation()
  const [name, setName] = useState(
    testDocument ? testDocument.document_name : '',
  )
  const [isActive, setIsActive] = useState(!!testDocument.is_active)
  const [loading, setLoading] = useState(false)
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const onSubmit = (e: React.FormEvent) => {
    let error = false
    e.preventDefault()

    setLoading(true)
    setNameErrorMessage('')

    if (!name) {
      setNameErrorMessage(t('fill_in_the_name'))
      error = true
    }

    if (!error) {
      if (testId) setShowConfirmModal(true)
      else onConfirm()
    }

    setLoading(false)
  }

  const onConfirm = async () => {
    const newTestDocumentType = {
      document_name: name,
      is_active: isActive,
    }
    if (editingMode) {
      const updatedTestDocument = {
        ...testDocument,
        ...newTestDocumentType,
      }
      await updateTestDocumentTypes(updatedTestDocument, projectId)
    } else if (testId) {
      await createTestDocumentTypesByTest(
        newTestDocumentType,
        projectId,
        testId,
      )
    } else {
      await createTestDocumentTypes(newTestDocumentType, projectId)
    }
    closeModal()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
    setNameErrorMessage('')
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('name')}
          block={true}
          value={name}
          onChange={onNameChange}
          required={true}
          errorMessage={nameErrorMessage}
          autoFocus={true}
        />
      </div>
      <div className={styleClass.inputGroup}>
        <div
          className={'pl-2 flex items-center w-1/2'}
          onClick={() => setIsActive(!isActive)}
        >
          <CheckBox
            disableTab={true}
            onChange={() => setIsActive(!isActive)}
            valueProp={isActive}
          />
          <p className={`pl-2  text-gray-500 text-sm`}>{t('is_active')}</p>
        </div>
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        {editingMode ? (
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : t('update')}
          </Button>
        ) : (
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : t('add')}
          </Button>
        )}
      </ModalFooter>
      <Modal
        show={showConfirmModal}
        title={t('confirm')}
        closeModal={() => setShowConfirmModal(false)}
        maxWidth={600}
      >
        <div className="m-2">
          <span>
            {t(
              'creating_a_new_test_document_will_apply_for_all_systems_related_to_the_test',
            )}
          </span>
        </div>
        <ModalFooter>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={() => setShowConfirmModal(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading}
            onClick={onConfirm}
          >
            {loading ? <Spinner /> : t('confirm')}
          </Button>
        </ModalFooter>
      </Modal>
    </form>
  )
}

export default TestDocumentForm
