import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { IMainProcess, ITeam } from '../../service/OrgTypes'
import { getMainProcesses } from '../../service/ProcessService'
import { createTeam, editTeam } from '../../service/TeamService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import { classNames } from '../../utility/utils'
import Selector from '../selectors/Selector'

interface ITeamFormProps extends WithTranslation {
  projectId: number
  closeModal: () => void
  reloadList: () => void
  editingMode: boolean
  team: ITeam
}

interface ITeamFormState {
  id: number
  name: string
  sequence: number
  main_process_id: number
  mainProcesses: IMainProcess[]
  nameErrorMessage: string
  mainProcessErrorMessage: string
  sequenceErrorMessage: string
  loading: boolean
  createMultiple: boolean
}

class TeamForm extends PureComponent<ITeamFormProps, ITeamFormState> {
  private static styleClass = {
    root: classNames('w-full', 'flex', 'flex-col', 'min-h-full'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  public constructor(props: ITeamFormProps) {
    super(props)
    this.state = {
      id: props.team.id,
      name: props.team.name,
      sequence: props.team.sequence,
      main_process_id: props.team.main_process_id
        ? props.team.main_process_id
        : 0,
      mainProcesses: [],
      nameErrorMessage: '',
      mainProcessErrorMessage: '',
      sequenceErrorMessage: '',
      loading: false,
      createMultiple: false,
    }
  }

  private reload() {
    getMainProcesses(this.props.projectId)
      .then((res: IMainProcess[]) => {
        this.setState({
          mainProcesses: res,
        })
      })
      .catch((err) => console.error(err))
  }

  public componentDidMount() {
    this.reload()
  }

  private onSingleCreate = () => {
    this.setState({
      createMultiple: false,
    })
  }

  private onMultipleCreate = () => {
    this.setState({
      createMultiple: true,
    })
  }

  private onSubmit = (e: any) => {
    e.preventDefault()
    let error = false
    const { t } = this.props
    this.setState({
      nameErrorMessage: '',
      mainProcessErrorMessage: '',
      sequenceErrorMessage: '',
      loading: true,
    })

    if (!this.state.name) {
      this.setState({
        nameErrorMessage: t('fill_in_the_title'),
      })
      error = true
    }

    if (this.state.main_process_id === 0) {
      this.setState({
        mainProcessErrorMessage: t('select_main_process'),
      })
      error = true
    }

    if (!this.state.sequence) {
      this.setState({
        sequenceErrorMessage: t('select_order'),
      })
      error = true
    }

    if (!error) {
      if (this.props.editingMode) {
        editTeam({
          id: this.state.id,
          name: this.state.name,
          sequence: this.state.sequence,
          main_process_id:
            this.state.main_process_id > 0
              ? this.state.main_process_id
              : undefined,
        }).then(() => {
          if (!this.state.createMultiple) {
            this.props.closeModal()
          }
        })
      } else {
        createTeam(
          {
            id: 0,
            name: this.state.name,
            sequence: this.state.sequence,
            main_process_id:
              this.state.main_process_id > 0
                ? this.state.main_process_id
                : undefined,
          },
          this.props.projectId,
        ).then(() => {
          if (!this.state.createMultiple) {
            this.props.closeModal()
            this.setState({
              name: '',
              main_process_id: 0,
              loading: false,
            })
          } else {
            this.props.reloadList()
            this.setState({
              name: '',
              createMultiple: false,
              loading: false,
            })
          }
        })
      }
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  private onNameChange = (e: any) => {
    const nameVal = e.target.value
    this.setState({
      name: nameVal,
      nameErrorMessage: '',
    })
  }

  private onMainProcessChange = (mainProcessId: number) => {
    this.setState({
      main_process_id: mainProcessId,
      mainProcessErrorMessage: '',
    })
  }

  public render() {
    const {
      name,
      main_process_id,
      mainProcesses,
      nameErrorMessage,
      mainProcessErrorMessage,
      loading,
    } = this.state
    const { t } = this.props

    return (
      <form className={TeamForm.styleClass.root} onSubmit={this.onSubmit}>
        <div className={TeamForm.styleClass.inputGroup}>
          <Input
            label={t('title')}
            block={true}
            value={name}
            errorMessage={nameErrorMessage}
            onChange={this.onNameChange}
            required={true}
            autoFocus={true}
          />
        </div>
        <div className={TeamForm.styleClass.inputGroup}>
          <div className={'w-full'}>
            <Selector
              items={mainProcesses}
              selectedItemId={main_process_id}
              onSelect={this.onMainProcessChange}
              label={t('main_process')}
              dataFields={['name']}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={mainProcessErrorMessage}
              required={true}
            />
          </div>
        </div>

        <ModalFooter>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={this.props.closeModal}
          >
            {t('cancel')}
          </Button>
          {this.props.editingMode ? (
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={loading ? true : false}
            >
              {loading ? <Spinner /> : t('update')}
            </Button>
          ) : (
            <>
              <Button
                type={Button.ButtonType.SECONDARY}
                disabled={loading ? true : false}
                onClick={this.onMultipleCreate}
              >
                {loading ? <Spinner /> : t('add_multiple')}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                disabled={loading ? true : false}
                onClick={this.onSingleCreate}
              >
                {loading ? <Spinner /> : t('add')}
              </Button>
            </>
          )}
        </ModalFooter>
      </form>
    )
  }
}

export default withTranslation()(TeamForm)
