import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import UserIcon from 'src/components/user/UserIcon'
import {
  getConstructionWagon,
  getPrevNextWagonDetails,
} from 'src/service/ConstructionWagonService'
import { IConstructionWagon } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import Button from 'src/ui-elements/button/Button'
import Icon from 'src/ui-elements/icon/Icon'
import useAlert from 'src/ui-elements/toast/useAlert'
import { capFirstLetter, classNames } from '../../utility/utils'
import { FullPageLoaderVh } from '../MUtils'
import FloatingButton from '../components/FloatingButton'
import MCard from '../components/MCard'

enum ICheckInStatus {
  checkedIn = 'checkedIn',
  checkedInWithIssues = 'checkedInWithIssues',
  notCheckedIn = 'notCheckedIn',
}

enum ICheckOutStatus {
  checkedOut = 'checkedOut',
  checkedOutWithIssues = 'checkedOutWithIssues',
  notCheckedOut = 'notCheckedOut',
}

interface IMWagonDetail {
  onAddIssue: () => void
  onWagonChange: (w: IConstructionWagon) => void
}

const MWagonDetail = ({ onAddIssue, onWagonChange }: IMWagonDetail) => {
  const styleClass = {
    root: classNames('bg-mobile', 'relative'),
  }

  const { t } = useTranslation()
  const { addAlert } = useAlert()
  const history = useHistory()
  const { vid } = useParams<{ caid: string; vid: string }>()

  useEffect(() => {
    fetchWagon(vid)
  }, [])

  const getCheckStatus = (val: any, type: string) => {
    if (val !== null && val?.with_issue === true) {
      return type === 'checkIn'
        ? ICheckInStatus.checkedInWithIssues
        : ICheckOutStatus.checkedOutWithIssues
    }
    if (val !== null && val?.with_issue === false) {
      return type === 'checkIn'
        ? ICheckInStatus.checkedIn
        : ICheckOutStatus.checkedOut
    }
    return type === 'checkIn'
      ? ICheckInStatus.notCheckedIn
      : ICheckOutStatus.notCheckedOut
  }

  const fetchWagon = async (id: string) => {
    if (id) {
      setLoading(true)
      const wagon = await getConstructionWagon(+id)
      const dataRow = [
        { id: wagon?.control_area_id },
        { left: t('control_area'), right: `${wagon?.control_area?.title}` },
        { left: t('area'), right: `${wagon?.control_area?.total_area}` },
        {
          left: t('control_area_group'),
          right: `${wagon?.control_area?.control_area_group?.title}`,
        },
      ]
      setData(dataRow)
      setWagonData(wagon)
      setLoading(false)
    }
  }

  const setWagonData = (wagon: IConstructionWagon) => {
    const checkedIn = getCheckStatus(wagon.check_in, 'checkIn')
    const checkedOut = getCheckStatus(wagon.check_out, 'checkOut')
    setCheckInStatus(checkedIn)
    setCheckOutStatus(checkedOut)
    setWagonRes(wagon)
    prepareCheckDetailData(wagon)
    const wagonRow = prepareWagonData(wagon)
    setChildData(wagonRow)
    onWagonChange(wagon)
  }
  const prepareWagonData = (wagon: IConstructionWagon) => {
    const checkedIn = getCheckStatus(wagon.check_in, 'checkIn')
    const checkedOut = getCheckStatus(wagon.check_out, 'checkOut')
    return [
      { id: wagon.id },
      { left: t('control_area'), right: `${wagon.control_area?.title}` },
      { left: t('wagon'), right: `${wagon.title}` },
      { left: t('description'), right: `${wagon.subject}` },
      {
        left: t('start_date'),
        right: moment(wagon.startTime).format('ddd MMM DD YYYY'),
      },
      {
        left: t('end_date'),
        right: moment(wagon?.startTime)
          .add(
            wagon?.extended_duration ? wagon.extended_duration - 1 : 0,
            'days',
          )
          .format('ddd MMM DD YYYY'),
      },
      { left: t('duration'), right: wagon.duration + ` (${t('days')})` },
      {
        left: t('working_hours'),
        right: !Number.isNaN(wagon?.planned_staffing)
          ? Number(wagon?.planned_execution_hours).toFixed(1)
          : 0,
      },
      {
        left: t('number_of_workers'),
        right: !Number.isNaN(wagon?.planned_staffing)
          ? Number(wagon?.planned_staffing).toFixed(1)
          : '0',
      },
      {
        left: t('checked_in'),
        right: (
          <Badge
            text={
              checkedIn !== ICheckInStatus.notCheckedIn ? t('yes') : t('no')
            }
            color={
              checkedIn === ICheckInStatus.checkedInWithIssues
                ? Badge.BadgeColor.YELLOW
                : checkedIn === ICheckInStatus.checkedIn
                  ? Badge.BadgeColor.GREEN
                  : Badge.BadgeColor.BLUE
            }
            size={Badge.BadgeSize.SMALL}
          />
        ),
      },
      {
        left: t('checked_out'),
        right: (
          <Badge
            text={
              checkedOut !== ICheckOutStatus.notCheckedOut ? t('yes') : t('no')
            }
            color={
              checkedOut === ICheckOutStatus.checkedOutWithIssues
                ? Badge.BadgeColor.YELLOW
                : checkedOut === ICheckOutStatus.checkedOut
                  ? Badge.BadgeColor.GREEN
                  : Badge.BadgeColor.BLUE
            }
            size={Badge.BadgeSize.SMALL}
          />
        ),
      },
      {
        left: t('status'),
        right: (
          <Badge
            text={t(
              wagon?.last_checklist_color
                ? wagon?.last_checklist_color
                : t('unknown'),
            )}
            color={
              wagon?.last_checklist_color === 'green'
                ? Badge.BadgeColor.GREEN
                : wagon?.last_checklist_color === 'yellow'
                  ? Badge.BadgeColor.YELLOW
                  : wagon?.last_checklist_color === 'red'
                    ? Badge.BadgeColor.RED
                    : Badge.BadgeColor.DEFAULT
            }
            size={Badge.BadgeSize.SMALL}
          />
        ),
      },
      {
        left: t('discipline'),
        right: `${wagon?.discipline?.shortName} ${wagon?.discipline?.name}`,
      },
      {
        left: t('contract'),
        right: `${wagon?.contract?.contractNumber} ${wagon?.contract?.contractName}`,
      },
    ]
  }

  const getSignatureStyles = (signature: any) => {
    return {
      width: '100%',
      height: '120px',
      background: `url(${signature})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }
  }

  const prepareCheckDetailData = (wagonObj: IConstructionWagon) => {
    if (wagonObj.check_in) {
      const {
        signature,
        date: signDate,
        checked_in_by: checkedInBy,
      } = wagonObj.check_in
      const indetail = [
        { left: t('status'), right: t('checked_in') },
        {
          left: t('signed_by'),
          right: (
            <UserIcon
              userId={checkedInBy.id}
              firstName={checkedInBy.firstName}
              lastName={checkedInBy.lastName}
              image_url={checkedInBy.image_url}
            />
          ),
        },
        {
          left: t('date'),
          right: signDate ? moment(signDate).format('ddd MMM DD YYYY') : '',
        },
        {
          left: t('signature'),
          right: <div style={getSignatureStyles(signature)} />,
        },
      ]
      setCheckInDetail(indetail)
    }

    if (wagonObj.check_out) {
      const {
        signature,
        date: signDate,
        checked_in_by: checkedOutBy,
      } = wagonObj.check_out
      const outDetail = [
        { left: t('status'), right: t('checked_out') },
        {
          left: t('signed_by'),
          right: (
            <UserIcon
              userId={checkedOutBy.id}
              firstName={checkedOutBy.firstName}
              lastName={checkedOutBy.lastName}
              image_url={checkedOutBy.image_url}
            />
          ),
        },
        {
          left: t('date'),
          right: signDate ? moment(signDate).format('ddd MMM DD YYYY') : '',
        },
        {
          left: t('signature'),
          right: <div style={getSignatureStyles(signature)} />,
        },
      ]
      setCheckOutDetail(outDetail)
    }
  }

  const [wagonRes, setWagonRes] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const [childData, setChildData] = useState<any[]>([])
  const [checkInDetail, setCheckInDetail] = useState<any>()
  const [checkOutDetail, setCheckOutDetail] = useState<any>()
  const [checkedInStatus, setCheckInStatus] = useState<
    ICheckInStatus | ICheckOutStatus
  >(ICheckInStatus.notCheckedIn)
  const [checkedOutStatus, setCheckOutStatus] = useState<
    ICheckInStatus | ICheckOutStatus
  >(ICheckOutStatus.notCheckedOut)

  const NoVognErrorMessage = (): JSX.Element => (
    <div
      className={
        'shadow-sm p-3 rounded-xl bg-m-warning-yellow text-sm text-gray-600 flex font-light'
      }
    >
      <Icon icon={Icon.IconType.WARNING_YELLOW} className={'w-4 h-4 mr-3'} />
      <span className={'font-medium'}>{t('note')}:&nbsp;</span>&nbsp;
      {capFirstLetter(t('wagon'))}
      <span className={'font-semibold'}>&nbsp;{t('not_caps')}&nbsp;</span>{' '}
      {t('found_for_this_week')}
    </div>
  )

  const CheckInOutBar = (): JSX.Element => (
    <div
      className={
        'px-6 bg-mobile sticky bottom-0 py-1 z-50 border-t border-gray-300'
      }
    >
      <div className={'flex justify-between'}>
        <Button
          width={'max(120px, 36vw)'}
          onClick={handleCheckIn}
          inMobile={true}
          type={getCheckInBtnStyle(checkedInStatus)}
          size={Button.ButtonSize.SMALL}
        >
          {getCheckInTitle()}
        </Button>
        <Button
          width={'max(120px, 36vw)'}
          disabled={checkedInStatus === ICheckInStatus.notCheckedIn}
          onClick={handleCheckOut}
          inMobile={true}
          type={getCheckOutBtnStyle(checkedOutStatus)}
          size={Button.ButtonSize.SMALL}
        >
          {getCheckOutTitle()}
        </Button>
      </div>
      {(checkedInStatus === ICheckInStatus.checkedInWithIssues ||
        checkedOutStatus === ICheckOutStatus.checkedOutWithIssues) && (
        <p className={'pl-2 text-xxs text-yellow-400 font-light'}>
          {t('checked_in_or_out_with_issues_(go_to_issues_to_see)')}
        </p>
      )}
    </div>
  )

  const handleCheckIn = () => {
    if (checkedInStatus === ICheckInStatus.notCheckedIn) {
      history.push(`/mobile/check-in-out/${wagonRes.id}`, { isCheckIn: true })
      return
    }
    history.push(`/mobile/${wagonRes.id}/checks`, { isCheckIn: true })
  }

  const handleCheckOut = () => {
    if (checkedInStatus === ICheckInStatus.notCheckedIn) {
      addAlert({
        type: 'error',
        title: t('an_error_occurred'),
        description: t('it_should_be_checked_in_first'),
        autoClose: true,
      })
      return
    }

    if (checkedOutStatus === ICheckOutStatus.notCheckedOut) {
      if (wagonRes.status_color === null) {
        addAlert({
          type: 'error',
          title: t('an_error_occurred'),
          description: t('the_wagon_must_have_status_meeting'),
          autoClose: true,
        })
        return
      }

      history.push(`/mobile/check-in-out/${wagonRes.id}`, { isCheckIn: false })
      return
    }

    history.push(`/mobile/${wagonRes.id}/checks`, { isCheckIn: false })
  }

  const getCheckInBtnStyle = (val: ICheckInStatus | ICheckOutStatus) => {
    if (val === ICheckInStatus.checkedIn) {
      return Button.ButtonType.SUCCESS
    }
    if (val === ICheckInStatus.checkedInWithIssues) {
      return Button.ButtonType.WARNING
    }
    if (val === ICheckInStatus.notCheckedIn) {
      return Button.ButtonType.PRIMARY
    }
    return Button.ButtonType.PRIMARY
  }

  const getCheckOutBtnStyle = (val: ICheckInStatus | ICheckOutStatus) => {
    if (val === ICheckOutStatus.checkedOut) {
      return Button.ButtonType.SUCCESS
    }
    if (val === ICheckOutStatus.checkedOutWithIssues) {
      return Button.ButtonType.WARNING
    }
    if (val === ICheckOutStatus.notCheckedOut) {
      return Button.ButtonType.PRIMARY
    }
    return Button.ButtonType.PRIMARY
  }

  const checkmark = '✔'

  const getCheckInTitle = (): JSX.Element => {
    if (checkedInStatus === ICheckInStatus.checkedInWithIssues) {
      return (
        <span>
          <sup>*</sup>
          {`${t('checked_in')} ${checkmark}`}
        </span>
      )
    }
    if (checkedInStatus === ICheckInStatus.checkedIn) {
      return (
        <span>
          {`${t('checked_in')}`} {checkmark}
        </span>
      )
    }
    return <span>{`${t('check_in')}`}</span>
  }

  const getCheckOutTitle = (): JSX.Element => {
    if (checkedOutStatus === ICheckOutStatus.checkedOutWithIssues) {
      return (
        <span>
          <sup>*</sup>
          {`${t('checked_out')} ${checkmark}`}
        </span>
      )
    }
    if (checkedOutStatus === ICheckOutStatus.checkedOut) {
      return (
        <span>
          {`${t('checked_out')}`} {checkmark}
        </span>
      )
    }
    return <span>{`${t('check_out')}`}</span>
  }

  const getNeighbouringWagon = async (
    wagon: IConstructionWagon,
    prev: boolean,
  ) => {
    if (wagon.id) {
      if (
        (prev && wagon.has_previous === false) ||
        (!prev && wagon.has_next === false)
      ) {
        addAlert({
          type: 'error',
          title: t('an_error_occurred'),
          description: t('no_wagon_found'),
          autoClose: true,
        })
        return
      } else {
        setLoading(true)
        getPrevNextWagonDetails(wagon.id, prev ? 'previous' : 'next').then(
          (res) => {
            if (res && res?.message === 'Item not found') {
              addAlert({
                type: 'error',
                title: t('an_error_occurred'),
                description: t('no_wagon_found'),
                autoClose: true,
              })
              return
            }
            setWagonData(res)
            setLoading(false)
            history.push(`/mobile/wagons/${res.id}?tabIndex=0`)
          },
        )
      }
    }
  }

  const footer = (wagon: IConstructionWagon) => (
    <div className={'mt-2'}>
      <div className={'w-full flex justify-between'}>
        <Button
          width={'40%'}
          disabled={wagon.has_previous === false}
          onClick={() => getNeighbouringWagon(wagon, true)}
          inMobile={true}
          size={Button.ButtonSize.XSMALL}
          type={Button.ButtonType.SECONDARY}
        >
          ❮ {t('previous')}
        </Button>
        <Button
          width={'40%'}
          disabled={wagon.has_next === false}
          onClick={() => getNeighbouringWagon(wagon, false)}
          inMobile={true}
          size={Button.ButtonSize.XSMALL}
          type={Button.ButtonType.SECONDARY}
        >
          {t('next')} ❯
        </Button>
      </div>
    </div>
  )

  return (
    <div className={styleClass.root}>
      <div className={'w-full md:w-1/2 lg:w-1/3'}>
        <FloatingButton
          fullWidth={true}
          floating={false}
          onClick={onAddIssue}
        />
      </div>
      <div
        className={
          'flex flex-col p-6 pt-0 flex-auto min-w-0 overflow-y-auto md:min-h-80vh lg:min-h-90vh'
        }
      >
        {loading ? (
          <FullPageLoaderVh />
        ) : data?.length > 0 ? (
          <>
            <p className={'block text-gray-600 text-sm mb-2'}>
              {capFirstLetter(t('selected_control_area'))}
            </p>
            <MCard data={data} />
            <div className={'mt-4 mb-2'}>
              {childData.length > 0 ? (
                <>
                  <p className={'text-gray-600 text-sm mb-1'}>
                    {capFirstLetter(t('selected_wagon'))}
                  </p>
                  <div className={'mt-2 mb-1'}>
                    <MCard data={childData} footer={footer(wagonRes)} />
                  </div>
                </>
              ) : (
                <NoVognErrorMessage />
              )}
            </div>
          </>
        ) : (
          <div className={'m-4 flex justify-center'}>
            <Badge
              text={t('no_results_found')}
              size={Badge.BadgeSize.LARGE}
              color={Badge.BadgeColor.TEAL}
            />
          </div>
        )}
        <div className={'md:flex mb-2'}>
          {checkedInStatus !== ICheckInStatus.notCheckedIn && (
            <div className={'my-2 md:w-1/2 md:pr-2'}>
              <p className={'text-gray-600 text-sm mb-2 pl-1 capitalize'}>
                {capFirstLetter(t('check_in_detail'))}
              </p>
              <MCard data={checkInDetail} />
            </div>
          )}

          {checkedOutStatus !== ICheckOutStatus.notCheckedOut && (
            <div className={'my-2 md:w-1/2 md:pl-2'}>
              <p className={'text-gray-600 text-sm mb-2 pl-1 capitalize'}>
                {capFirstLetter(t('check_out_detail'))}
              </p>
              <MCard data={checkOutDetail} />
            </div>
          )}
        </div>
      </div>
      {childData.length > 0 && <CheckInOutBar />}
    </div>
  )
}

export default MWagonDetail
