import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { getCurrentControlAreas } from 'src/service/ControlAreaService'
import { IConstructionCanvasControlArea } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import Button from 'src/ui-elements/button/Button'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { capFirstLetter } from '../../utility/utils'
import MCard from '../components/MCard'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'

const MControlAreaList = (_props: any) => {
  moment.tz.setDefault('Europe/Oslo')
  const history = useHistory()
  const projectContext = useContext<IProjectContext>(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject

  useEffect(() => {
    fetchControlAreas()
  }, [])

  const fetchControlAreas = async () => {
    setLoading(true)
    const controlAreas: IConstructionCanvasControlArea[] =
      await getCurrentControlAreas(projectId)
    const dataRow = controlAreas.map(
      (controlArea: IConstructionCanvasControlArea) => [
        { id: controlArea.id },
        { currentWeekWagons: controlArea.this_weeks_locomotives },
        { left: t('control_area'), right: controlArea.title },
        { left: t('area'), right: controlArea.total_area },
      ],
    )
    setData(dataRow)
    setLoading(false)
  }

  const { t } = useTranslation()
  const userContext: IUserContext = useContext(UserContext)
  const { user } = userContext.state
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const header = () => (
    <div className={'flex justify-end mb-2'}>
      <div
        className={
          'rounded-full border-2 border-blue-root font-medium bg-blue-root-wash text-blue-root text-xs'
        }
      >
        <p className={'px-1 py-0.5'}>{t('have_current_week_wagon')}</p>
      </div>
    </div>
  )

  const child = (childItems: any[], caId: string) => {
    return (
      <div className={'w-full mt-2 border-t-green-300 pl-2'}>
        <p className={'text-xs -ml-2 font-light'}>
          {t('wagons_in_the_control_area_this_week')}
        </p>
        {childItems.map((childItem, key) => (
          <details
            key={key}
            className={'-ml-2 border border-gray-300 rounded-md my-2 py-1.5'}
          >
            <summary className={'font-semibold text-gray-600 text-xs px-3'}>
              {childItem.title}
            </summary>
            <div
              className={
                'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
              }
            >
              <div className={'w-1/2 font-medium'}>
                {capFirstLetter(t('description'))}
              </div>
              <div className={'w-1/2 font-light pl-2'}>
                {childItem?.subject}
              </div>
            </div>
            <div
              className={
                'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
              }
            >
              <div className={'w-1/2 font-medium capitalize'}>
                {t('start_date')}
              </div>
              <div className={'w-1/2 font-light pl-2 capitalize'}>
                {moment(childItem?.startTime).format('ddd MMM DD YYYY')}
              </div>
            </div>
            <div
              className={
                'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
              }
            >
              <div className={'w-1/2 font-medium'}>{t('end_date')}</div>
              <div className={'w-1/2 font-light pl-2 capitalize'}>
                {moment(childItem?.startTime)
                  .add(
                    childItem?.extended_duration
                      ? childItem.extended_duration - 1
                      : 0,
                    'days',
                  )
                  .format('ddd MMM DD YYYY')}
              </div>
            </div>
            <div
              className={
                'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
              }
            >
              <div className={'w-1/2 font-medium'}>{t('checked_in')}</div>
              <div className={'w-1/2 pl-2'}>
                <Badge
                  text={childItem.checked_in ? t('yes') : t('no')}
                  color={
                    childItem.checked_in_with_issue
                      ? Badge.BadgeColor.YELLOW
                      : childItem.checked_in
                        ? Badge.BadgeColor.GREEN
                        : Badge.BadgeColor.BLUE
                  }
                  size={Badge.BadgeSize.SMALL}
                />
              </div>
            </div>
            <div
              className={
                'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
              }
            >
              <div className={'w-1/2 font-medium'}>{t('checked_out')}</div>
              <div className={'w-1/2 pl-2'}>
                <Badge
                  text={childItem.checked_in ? t('yes') : t('no')}
                  color={
                    childItem.checked_out_with_issue
                      ? Badge.BadgeColor.YELLOW
                      : childItem.checked_out
                        ? Badge.BadgeColor.GREEN
                        : Badge.BadgeColor.BLUE
                  }
                  size={Badge.BadgeSize.SMALL}
                />
              </div>
            </div>
            <div className={'mt-2 px-2'}>
              <Button
                width={'100%'}
                onClick={() => handleWagonClick(caId, childItem.id)}
                inMobile={true}
                size={Button.ButtonSize.XSMALL}
                type={Button.ButtonType.SECONDARY}
              >
                {t('go_to_wagon')} &gt;
              </Button>{' '}
            </div>
          </details>
        ))}
      </div>
    )
  }

  const handleCardClick = (item: any) => {
    history.push(`/mobile/control-areas/${item[0].id}?tabIndex=0`)
  }

  const handleWagonClick = (caId: string, vid: string) => {
    history.push(`/mobile/wagons/${vid}?tabIndex=0`)
  }

  const getHeader = (item: any) => {
    return item && item[1]?.currentWeekWagons.length > 0 ? header : undefined
  }

  const getSecondaryData = (item: any) => {
    return item && item[1]?.currentWeekWagons.length > 0
      ? child(item[1].currentWeekWagons, item[0].id)
      : undefined
  }

  const footer = (card: any) => (
    <div className={'mt-2'}>
      <Button
        width={'100%'}
        onClick={() => handleCardClick(card)}
        inMobile={true}
        size={Button.ButtonSize.XSMALL}
        type={Button.ButtonType.SECONDARY}
      >
        {t('go_to_control_area')} &gt;{' '}
      </Button>
    </div>
  )

  return (
    <MPage>
      <>
        <MHeader onBackClick={() => history.goBack()} user={user} />
        {loading ? (
          <div className={'w-full h-full grid place-items-center'}>
            <SpinnerMobile />
          </div>
        ) : (
          <>
            <div className={'flex flex-col p-6 h-full overflow-y-auto pb-1/2'}>
              <p className={'text-gray-600 text-sm mb-1'}>
                {t('control_areas_active_w_week', { week: moment().isoWeek() })}
              </p>
              {data.map((item, key) => (
                <div key={key} className={'my-4'}>
                  <MCard
                    data={item}
                    header={getHeader(item)}
                    secondaryData={getSecondaryData(item)}
                    footer={footer(item)}
                  />
                </div>
              ))}
              {data?.length === 0 && (
                <Badge
                  text={t('no_results_found')}
                  size={Badge.BadgeSize.LARGE}
                  color={Badge.BadgeColor.YELLOW}
                />
              )}
            </div>
          </>
        )}
      </>
    </MPage>
  )
}

export default MControlAreaList
