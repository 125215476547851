import { Moment } from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import ConstructionModalToolbar from 'src/components/construction/toolbars/ConstructionModalToolbar'
import QrModal from 'src/components/qr/QrModal'
import useDraftMode from 'src/hooks/useDraftMode'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import {
  deleteConstructionTrain,
  editConstructionTrain,
  getConstructionTrain,
} from '../../../service/ConstructionTrainService'
import { getProjectControlAreas } from '../../../service/ControlAreaService'
import { IConstructionTrain } from '../../../service/OrgTypes'
import { trainColors } from '../../../service/SystemValues'
import Button from '../../../ui-elements/button/Button'
import FixedHorizintalPane from '../../../ui-elements/fixed-pane/FixedHorizontalPane'
import Loader from '../../../ui-elements/loader/Loader'
import BooleanInlineInputComponent from '../../../ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import DateTimeInlineInputComponent from '../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from '../../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from '../../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import { capFirstLetter, classNames } from '../../../utility/utils'
import WagonList from '../wagon/WagonList'
import TrainBoard from './TrainBoard'
import TrainUpdateDeleteConfirmation from './TrainUpdateDeleteConfirmation'

interface ITrainModal {
  open: boolean
  parentId: number
  trainId: number
  onUpdate: () => void
  onClose: () => void
  className?: string
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'pr-5', 'lg:pr-2'),
  tab: (selected: boolean) =>
    classNames(
      selected ? 'bg-blue-root-tab-wash' : 'bg-white',
      'hover:bg-blue-root-tab-wash',
      selected ? 'text-blue-root' : 'text-gray-700 hover:text-blue-root',
      'inline-block',
      'relative',
      'text-sm',
      'font-normal',
      'px-3',
      'py-1',
      'list-none',
      'cursor-pointer',
      'rounded',
    ),
  inputGroup: classNames('flex', 'flex-row', 'w-full', 'mb-2'),
  label: classNames(
    'w-full',
    'font-roboto',
    'text-sm',
    'font-bold',
    'mb-2',
    'pl-1',
  ),
  form: {
    root: classNames('flex', 'flex-col', 'pl-3', 'pr-5', 'lg:pr-2'),
    inputGroup: classNames(
      'w-full',
      'flex',
      'flex-row',
      'justify-end',
      'pr-4',
      'absolute',
      'bottom-2',
      'right-0',
    ),
  },
}

const TrainModal = ({
  trainId,
  open,
  parentId,
  onUpdate,
  onClose,
  className,
}: ITrainModal) => {
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false)
  const [train, setTrain] = useState<IConstructionTrain>()
  const [scopeModal, setScopeModal] = useState<boolean>(false)
  const [qrString, setQrString] = useState<string>('')
  const [showQrModal, setQrModal] = useState<boolean>(false)
  const { t } = useTranslation()
  const { isConstructionManager } = projectContext.state

  const { turnDraftModeOn } = useDraftMode()

  const loadConstructionTrain = useCallback(() => {
    getConstructionTrain(trainId).then((res) => {
      setTrain(res)
    })
  }, [trainId])

  useEffect(() => {
    loadConstructionTrain()
  }, [loadConstructionTrain, projectId, trainId])

  const openDeleteModal = () => {
    setDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setDeleteModal(false)
  }

  const deleteTrain = (applyToAll: boolean, _applyToRest: boolean) => {
    setDeleteModal(false)
    if (train) {
      deleteConstructionTrain(train, applyToAll).then(() => {
        setDeleteModal(false)
        onClose()
      })
    }
  }

  const onChangeInput = (
    field: string,
    value: string | boolean | number | Moment | null,
  ) => {
    if (train) {
      setTrain({ ...train, [field]: value })
    }
  }

  const onSubmitForm = (applyToAll: boolean, applyToRest: boolean) => {
    setScopeModal(false)

    if (train) {
      setIsLoading(true)
      const updatedTrain: IConstructionTrain = {
        id: trainId,
        title: train.title,
        train_color: train.train_color,
        status: train.status,
        startTime: train.startTime,
        control_area_id: train.control_area_id,
        project_id: projectId,
        change_trains_in_group: applyToAll,
        change_only_future_trains_in_group: applyToRest,
        ignore_weekends: train.ignore_weekends,
        ignore_holidays: train.ignore_holidays,
      }

      editConstructionTrain(updatedTrain).then(() => {
        onUpdate()
        loadConstructionTrain()
        setIsLoading(false)
      })
    }
  }

  const closeScopeModal = () => {
    setScopeModal(false)
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const copyURL =
      url +
      '?modal=constructiontrain&id=' +
      (train ? train.id : 0) +
      '&project=' +
      projectId
    setQrString(copyURL)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setQrModal((n) => !n)
  }

  const getModalTitle = (): string => {
    return train && train.title
      ? t('train_title', { title: train.title })
      : 'Loading...'
  }

  return (
    <FixedHorizintalPane
      show={open}
      title={
        train
          ? t('train_title', { title: train.record_id + ' - ' + train.title })
          : t('loading...')
      }
      closeClickOutside={false}
      onClose={onClose}
      className={className || 'h-[600px]'}
      detailPageData={{
        key: DetailPageKeys.TRAIN,
        ids: {
          cagId: train?.control_area_group_id || undefined,
          caId: train?.control_area_id,
          trainId: train?.id,
        },
      }}
    >
      <div className="trainmodal">
        <Tabs
          defaultIndex={tabIndex}
          onSelect={(index: number) => setTabIndex(index)}
          className={'w-full react-tabs border-react-tabs modal-tabs'}
        >
          <div
            style={{
              position: 'sticky',
              top: 0,
              left: 0,
              zIndex: 50,
              paddingRight: 15,
              width: '100%',
            }}
          >
            <div className="w-full bg-white flex border-b border-gray-200 flex-row flex-wrap justify-between px-2 pb-2">
              <TabList className={'flex items-center flex-wrap gap-2'}>
                <Tab className={styleClass.tab(tabIndex === 0)}>
                  {capFirstLetter(t('train'))}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 1)}>
                  {t('wagons_visual')}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 2)}>
                  {t('wagons')}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 3)}>
                  {t('comments')}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 4)}>
                  {t('change_log')}
                </Tab>
              </TabList>
              <ConstructionModalToolbar
                openDeleteModal={openDeleteModal}
                toggleQrModal={toggleQrModal}
                params={{
                  modal: 'constructiontrain',
                  id: trainId,
                }}
              />
            </div>
          </div>
          <TabPanel className={'w-full noTabPanelPadding'}>
            {!train ? (
              <Loader />
            ) : (
              <>
                <div className="flex">
                  <InlineComponentsWrapper padding="left" border={'right'}>
                    <TextInlineInputCompontent
                      label="title"
                      value={train?.title}
                      onValueSubmitted={(newValue) => {
                        if (newValue) onChangeInput('title', newValue)
                      }}
                      validate={(newValue) => {
                        if (!newValue?.length) {
                          return t('fill_out_w_param', { param: t('title') })
                        }
                        return undefined
                      }}
                      disabled={!isConstructionManager}
                    />
                    <DateTimeInlineInputComponent
                      label="start_date"
                      selectedTime={`${train.startTime}`}
                      onValueSubmitted={(startTime) => {
                        if (startTime) {
                          onChangeInput('startTime', startTime)
                        }
                      }}
                      validate={(startTime) => {
                        if (!startTime) {
                          return t('fill_out_w_param', {
                            param: t('start_date'),
                          })
                        }

                        return
                      }}
                      inspectorPanel={false}
                      disabled={!isConstructionManager}
                    />
                    <SelectorInlineInputComponent
                      getItems={() => getProjectControlAreas(projectId)}
                      label="control_area"
                      getItemLabel={(ControlAreaGroup) =>
                        `${ControlAreaGroup?.record_id} ${ControlAreaGroup?.title}`
                      }
                      initialItem={train?.control_area}
                      selectedId={train?.control_area_id}
                      onValueSubmitted={(newValue) => {
                        if (newValue) onChangeInput('control_area_id', newValue)
                      }}
                      validate={(control_area) => {
                        if (!control_area) {
                          return t('fill_out_w_param', {
                            param: t('control_area'),
                          })
                        }

                        return
                      }}
                      inspectorPanel={false}
                      selectorHeight={'!max-h-[180px]'}
                      disabled={!isConstructionManager}
                    />

                    <SelectorInlineInputComponent
                      items={trainColors(t)}
                      label={t('color')}
                      getItemLabel={(stat) => stat?.name}
                      initialItem={{
                        id: train?.train_color ?? '',
                        name:
                          trainColors(t).find(
                            (statData) => statData.id === train?.train_color,
                          )?.name ?? '',
                      }}
                      validate={() => {
                        if (!train.train_color) {
                          return t('fill_out_w_param', {
                            param: t('train_color'),
                          })
                        }
                        return
                      }}
                      selectedId={train?.train_color}
                      onValueSubmitted={(color) => {
                        if (color) onChangeInput('train_color', color)
                      }}
                      selectorHeight={'!max-h-[150px]'}
                      inspectorPanel={false}
                      disabled={!isConstructionManager}
                    />
                    <BooleanInlineInputComponent
                      label="consider_weekends_as_working_days"
                      onValueSubmitted={(ignore_weekends) => {
                        if (ignore_weekends !== undefined)
                          onChangeInput('ignore_weekends', ignore_weekends)
                      }}
                      value={train.ignore_weekends}
                      showCheckBox
                      disabled
                    />
                    <BooleanInlineInputComponent
                      label="consider_holidays_as_working_periods"
                      onValueSubmitted={(ignore_holidays) => {
                        if (ignore_holidays !== undefined)
                          onChangeInput('ignore_holidays', ignore_holidays)
                      }}
                      value={train.ignore_holidays}
                      showCheckBox
                      disabled
                    />
                  </InlineComponentsWrapper>

                  <InlineComponentsWrapper padding="left" border={'right'}>
                    <TextInlineInputCompontent
                      label="duration_days"
                      type={'number'}
                      value={`${
                        Math.round(Number(train.duration) * 100) / 100
                      }`}
                      disabled={true}
                    />
                    <TextInlineInputCompontent
                      label="number_of_wagons"
                      type={'number'}
                      value={`${
                        Math.round(Number(train.number_of_locomotives) * 100) /
                        100
                      }`}
                      disabled={true}
                    />
                    <TextInlineInputCompontent
                      label="number_of_activities"
                      type={'number'}
                      value={`${
                        Math.round(
                          Number(train.number_of_work_operations) * 100,
                        ) / 100
                      }`}
                      disabled={true}
                    />
                    <TextInlineInputCompontent
                      label="working_hours"
                      type={'number'}
                      value={`${
                        Math.round(
                          Number(train.planned_execution_hours) * 100,
                        ) / 100
                      }`}
                      disabled={true}
                    />
                    <DateTimeInlineInputComponent
                      label="created_at"
                      selectedTime={train?.created_at}
                      onValueSubmitted={() => {}}
                      disabled={true}
                      inspectorPanel={false}
                    />
                    <DateTimeInlineInputComponent
                      label="updated_at"
                      selectedTime={train?.updated_at}
                      onValueSubmitted={() => {}}
                      disabled={true}
                      inspectorPanel={false}
                    />
                  </InlineComponentsWrapper>
                </div>
                <ModalFooter className="p-4">
                  <Button
                    type={Button.ButtonType.DEFAULT}
                    onClick={() => loadConstructionTrain()}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    type={Button.ButtonType.PRIMARY}
                    disabled={isLoading}
                    onClick={() => {
                      setScopeModal(true)
                      turnDraftModeOn()
                    }}
                  >
                    {t('update')}
                  </Button>
                </ModalFooter>
              </>
            )}
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div className="pt-1 pl-4 pr-4 mr-4 overflow-x-auto overflow-y-hidden min-h-[340px]">
              <TrainBoard
                trainId={trainId}
                projectId={projectId}
                reloadParent={loadConstructionTrain}
              />
            </div>
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div className="mt-1 pt-1 pl-5 pr-4 mr-4 overflow-x-auto overflow-y-hidden min-h-[340px]">
              <WagonList
                controlAreaId={parentId}
                inModal={true}
                trainId={trainId}
                train={train}
                reloadParent={loadConstructionTrain}
              />
            </div>
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div className="px-4 w-1/3">
              <Comments parentType="ConstructionTrain" parentId={trainId} />
            </div>
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div className="px-4 w-1/3">
              <ChangeLog parentType="ConstructionTrain" parentId={trainId} />
            </div>
          </TabPanel>
        </Tabs>

        {scopeModal && train && (
          <TrainUpdateDeleteConfirmation
            openUpdateModal={scopeModal}
            openDeleteModal={false}
            closeModal={closeScopeModal}
            title={train.title}
            onSubmitForm={onSubmitForm}
          />
        )}

        {showDeleteModal && train && (
          <TrainUpdateDeleteConfirmation
            openUpdateModal={false}
            openDeleteModal={showDeleteModal}
            closeModal={closeDeleteModal}
            title={train.title}
            onSubmitForm={deleteTrain}
          />
        )}
        {showQrModal && (
          <QrModal
            show={showQrModal}
            close={toggleQrModal}
            title={getModalTitle()}
            value={qrString}
          />
        )}
      </div>
    </FixedHorizintalPane>
  )
}

export default TrainModal
