import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import FdvuService from 'src/fdvu/services/FdvuService'
import FdvuSubcontractorService from 'src/fdvu-subcontractor/service/FdvuSubcontractorService'

export const useSubcontractor = (
  subcontractorId: string,
  options: {
    enabled?: boolean
  } = {},
) =>
  useQuery({
    ...options,
    queryKey: ['fdvu', 'subcontractor', subcontractorId],
    queryFn: () => FdvuService.getSubcontractor(subcontractorId),
  })

export const useSubcontractors = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['fdvu', 'subcontractors', projectId],
    queryFn: FdvuService.getProjectSubcontractors,
  })
}

export const useCurrentSubcontractor = () =>
  useQuery({
    queryKey: ['fdvu', 'subcontractor'],
    queryFn: () => FdvuSubcontractorService.getCurrentSubcontractor(),
  })

export const useSubcontractorFileContainers = (hideCompleted: boolean) =>
  useQuery({
    queryKey: ['fdvu', 'subcontractor', 'fileContainers', hideCompleted],
    queryFn: () => FdvuSubcontractorService.getFileContainers(hideCompleted),
  })

export const useSubcontractorFileContainer = (fileContainerId: number) =>
  useQuery({
    queryKey: ['fdvu', 'subcontractor', 'fileContainer', fileContainerId],
    queryFn: () => FdvuSubcontractorService.getFileContainer(fileContainerId),
  })
