import { create } from 'zustand'

type IGetSet<T> = [T, (value: T) => void]
interface SplashLoading {
  loading: IGetSet<boolean>
}

export const allLoadedStore = create<SplashLoading>((set) => ({
  loading: [true, (v) => set((state) => ({ loading: [v, state.loading[1]] }))],
}))

export const SplashScreenUtil = () => {
  const [, setLoading] = allLoadedStore().loading
  const removeSplashScreen = () => setLoading(false)
  return { removeSplashScreen }
}
