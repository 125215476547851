import Add from '@material-symbols/svg-500/rounded/add.svg'
import { FC, useState } from 'react'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { classNames } from 'src/utility/utils'

interface ICollapsableCard {
  title: string
  className?: string
  titleClassName?: string
  reverseTitleOrder?: boolean
  addIcon?: boolean
  onAdd?: () => void
  CustomIcon?: JSX.Element
}

const CollapsableCard: FC<React.PropsWithChildren<ICollapsableCard>> = ({
  title,
  children,
  className,
  titleClassName,
  addIcon,
  reverseTitleOrder,
  onAdd,
  CustomIcon,
}) => {
  const [show, setShow] = useState(true)

  const styleClass = {
    root: classNames(
      'px-4',
      'py-[14px]',
      'flex',
      'flex-col',
      'border',
      'border-d-grey-lightest',
      'rounded',
      'mb-4',
      className,
    ),
    title: classNames(
      'items-center',
      addIcon ? 'flex justify-between' : '',
      !show ? '' : 'mb-[14px]',
      !show ? '' : titleClassName,
    ),
  }

  return (
    <div className={styleClass.root}>
      <div className={styleClass.title}>
        <div
          className={twMerge(
            'cursor-pointer',
            reverseTitleOrder
              ? `flex flex-row-reverse justify-end`
              : 'flex justify-between',
          )}
          onClick={() => setShow((prev) => !prev)}
        >
          <p className={'text-gray-600 font-bold text-[16px]'}>{title}</p>
          <MaterialIcon
            icon={!show ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
            className={
              'flex text-d-fontgrey w-6 h-6 items-center justify-center'
            }
            outline={true}
          />
        </div>
        {addIcon
          ? (CustomIcon ?? (
              <div className="w-4 h-4 bg-blue-100 hover:bg-blue-200 flex justify-center items-center rounded-full mr-2 cursor-pointer">
                <Add onClick={onAdd} className="fill-blue-root h-3 w-3" />
              </div>
            ))
          : null}
      </div>
      {show && children}
    </div>
  )
}

export default CollapsableCard
