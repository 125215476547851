import { createContext } from 'react'
import { ExportTask } from './ExportTypes'

interface IExportContext {
  actions: {
    addExportTask: (res: ExportTask) => void
    updateExportTask: (res: ExportTask) => void
    removeExportTask: (res: ExportTask) => void
    reload: () => void
    addPendingExport: (id: string) => void
  }
  state: IExportContextState
}

interface IExportContextState {
  exportTasks: ExportTask[]
  pendingExports: string[]
}

export const ExportContext = createContext<IExportContext>({} as IExportContext)
