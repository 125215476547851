import { classNames } from '../../utility/utils'

export const PageDisplayStyleClass = {
  tableRow: (grayFill: boolean, isEditing: boolean, spansTwoColums: boolean) =>
    classNames(
      'px-4',
      'py-1',
      spansTwoColums ? 'sm:col-span-2' : 'sm:col-span-1',
      grayFill && !isEditing ? 'bg-gray-50' : '',
    ),
  tableLeftText: (_editingMode: boolean) =>
    classNames('items-start', 'text-sm', 'leading-5', 'font-medium'),
  tableCellRight: (editingMode: boolean) =>
    classNames(
      'mt-1',
      'text-sm',
      'leading-5',
      editingMode ? 'text-gray-900' : '',
    ),
  displayText: classNames('block', 'break-words', 'text-gray-500'),
  errorMessage: classNames(
    'text-red-600',
    'my-2',
    'ml-2',
    'text-sm',
    'font-normal',
  ),
}
