import { PropsWithChildren, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmDialogContext from 'src/context/ConfirmModalContext'
import { ConfirmModalBody } from 'src/reviewed-ui-elements/ConfirmModal'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface ConfirmModalProps {
  title?: string
  message?: string
  iconColor?: string
  confirmButtonType?: ButtonType
  confirmText?: string
  cancelText?: string
  controlClose?: (arg: () => void) => void
}

export const useConfirmModal = () => {
  const { showConfirmation } = useContext(ConfirmDialogContext)
  const { t } = useTranslation()

  const confirm = ({
    title,
    message,
    iconColor,
    confirmText,
    cancelText,
    confirmButtonType,
    children,
    controlClose,
  }: PropsWithChildren<ConfirmModalProps> = {}) => {
    const confirmModalBody = (
      <ConfirmModalBody confirmMessage={message} iconColor={iconColor}>
        {children}
      </ConfirmModalBody>
    )

    return showConfirmation({
      title: title || t('confirm_change'),
      body: confirmModalBody,
      confirmText,
      cancelText,
      confirmButtonType,
      controlClose,
    })
  }

  return { confirm }
}
