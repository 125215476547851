import Moment from 'moment'
import { memo, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import Checkbox from 'src/components/switchHoc/CheckBox'
import { capFirstLetter, classNames } from '../../utility/utils'
import Icon, { Icons } from '../icon/Icon'
import MaterialIcon from '../icon/materialIcon'
import { IListStoreData, ListContext } from './ListContextProvider'
import { IListActionMenuItem, IListColumns } from './ListTypes'

interface IListItemProps {
  onClick: (e: any) => void
  dataFields: any[]
  actionMenu?: IListActionMenuItem[]
  data: any
  selectedColumns: IListStoreData[]
  selectedRow: number
  index: number
  expandedElement?: (data: any, id?: number, key?: number) => JSX.Element
  isRowDraggable?: boolean
  swapOrder?: (id: number, sequence: number) => void
  isRowSelectable?: boolean
  onSelectRow?: (data: number[], add: boolean, shift?: boolean) => void
  selectedRows?: number[]
  highlightedRows?: number[]
  clickExpand?: boolean
  onPreviewClick?: (e: any, data: any) => void
  onEditClick?: (e: any, data: any) => void
  disable?: boolean
  onRowGrayOut?: (data: any) => boolean
}

const styleClass = {
  listItem: (selected: boolean, isDrop: boolean, disabled: boolean) =>
    twMerge(
      'flex',
      'flex-row',
      'pl-0',
      'relative',
      'list-item--row',
      'hover:bg-blue-50',
      'hover:cursor-pointer',
      selected ? 'bg-blue-50' : 'bg-white',
      'border-b',
      'border-gray-200',
      'h-7s',
      isDrop ? 'bg-blue-50' : '',
      disabled ? 'pointer-events-none opacity-25' : '',
    ),
  ListItemCol: classNames(
    'pl-2',
    'whitespace-nowrap',
    'leading-5',
    'text-gray-700',
    'items-center',
    'font-normal',
    'text-sm',
    'flex',
    'border-l',
    'border-[#e1e1e1]',
    'pr-0',
  ),
  actionMenu: {
    container: classNames('relative', 'flex', 'mx-2'),
    menu: classNames(
      'flex',
      'flex-row',
      'flex-nowrap',
      'h-full',
      'items-center',
    ),
    item: (isEdit: boolean, isDelete: boolean) =>
      classNames(
        'mx-1',
        'flex',
        'items-center',
        'justify-center',
        'bg-white',
        'rounded-full',
        'w-5',
        'h-5',
        'p-0.5',
        'hover:cursor-pointer',
        isEdit ? 'bg-blue-100' : 'bg-white',
        isDelete && 'bg-red-100',
      ),
  },
}

const ListItem = ({
  onClick,
  dataFields,
  actionMenu,
  data,
  selectedColumns,
  index,
  expandedElement,
  isRowDraggable,
  swapOrder,
  isRowSelectable,
  onSelectRow,
  selectedRows,
  highlightedRows,
  clickExpand,
  onPreviewClick,
  onEditClick,
  disable,
  onRowGrayOut,
}: IListItemProps) => {
  const { expandedRows } = useContext(ListContext).state
  const { t } = useTranslation()
  const [expandTable, setExpandTable] = useState<boolean>(
    expandedRows?.includes(data.id) ?? false,
  )
  const [dropOn, setDropOn] = useState<boolean>(false)
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false)
  const [selected, setSelected] = useState<boolean>(false)

  const isDate = (d: any, format?: string) => {
    return format ? Moment(d).format(format) : d
  }

  useDidMountEffect(() => {
    setExpandTable(false)
  }, [data.id])

  useDidMountEffect(() => {
    const isExpanded = expandedRows?.includes(data.id) ?? false
    if (isExpanded && !expandTable) {
      setExpandTable(true)
    }
  }, [expandedRows])

  useEffect(() => {
    if (isRowSelectable) {
      selectedRows && selectedRows.find((row) => row === data.id)
        ? setCheckboxValue(true)
        : setCheckboxValue(false)
    } else {
      selectedRows && selectedRows.find((row) => row === data.id)
        ? setSelected(true)
        : setSelected(false)
    }
  }, [data.id, isRowSelectable, selectedRows])

  const renderData = () => {
    return dataFields.map((f: IListColumns, key: number) => {
      /**
       * Check if a JSX.element is passed to the list Item
       */
      const selectedColumn = selectedColumns.find((sc) => sc.id === f.id)
      const active = selectedColumn !== undefined && selectedColumn.active
      if (!active) {
        return
      }
      if (f.cell) {
        const splitField: string[] = f.dataField.split('||')
        let fieldObj: Record<string, any> | string = {}
        if (splitField.length > 1) {
          splitField.map((ssf: any) => {
            fieldObj[ssf] = data[ssf]
          })
        } else {
          fieldObj = data[f.dataField]
          if (f.nestedDataField) fieldObj = fieldObj[f.nestedDataField]
        }
        return (
          <td
            key={`item-${key}`}
            style={{
              minWidth: f.size ? `${f.size}px` : undefined,
              maxWidth: f.size ? `${f.size}px` : undefined,
              backgroundColor:
                onRowGrayOut && onRowGrayOut(data) ? '#E7E7E7' : 'inherit',
            }}
            className={styleClass.ListItemCol}
          >
            {f.cell(fieldObj, data['id'], index)}
          </td>
        )
      } else {
        const splitField: string[] = f.dataField.split('||')
        let fieldString = ''
        if (splitField.length > 1) {
          splitField.map((sf: any, i: number) => {
            fieldString += splitField.length - 1 > i ? data[sf] + ' ' : data[sf]
          })
          const field = fieldString
          return (
            <td
              key={`item-${key}`}
              style={{
                minWidth: f.size ? `${f.size}px` : undefined,
                maxWidth: f.size ? `${f.size}px` : undefined,
                backgroundColor:
                  onRowGrayOut && onRowGrayOut(data) ? '#E7E7E7' : 'inherit',
              }}
              className={`${styleClass.ListItemCol} py-1`}
            >
              <span className="ellipsis">{field}</span>
            </td>
          )
        } else {
          const field = f.dataField.split('.')
          return (
            <td
              key={`item-${key}`}
              style={{
                minWidth: f.size ? `${f.size}px` : undefined,
                maxWidth: f.size ? `${f.size}px` : undefined,
                backgroundColor:
                  onRowGrayOut && onRowGrayOut(data) ? '#E7E7E7' : 'inherit',
              }}
              className={styleClass.ListItemCol}
            >
              <p className={'truncate'}>
                {field.length > 1
                  ? isDate(data[field[0]][field[1]], f.dateFormat)
                  : isDate(data[field[0]], f.dateFormat)}
              </p>
            </td>
          )
        }
      }
    })
  }

  const openActionMenu = (e: any) => {
    e.stopPropagation()
  }

  const onMenuItemActionClick = (action: any) => (e: any) => {
    e.preventDefault()
    openActionMenu(e)
    action(JSON.parse(e.currentTarget.getAttribute('data-actionrow')), index)
  }

  const onListItemClick = (e: any) => {
    if (clickExpand) {
      setExpandTable((n) => !n)
    } else {
      onClick(e)
    }
  }

  const onDragStart = (ev: any, i: number) => {
    ev.dataTransfer.setData('index', i)
  }

  const onDrop = (ev: any, row: any) => {
    const i = ev.dataTransfer.getData('index')
    if (swapOrder) {
      swapOrder(i, ++row)
    }
    setDropOn(false)
  }

  const onDragOver = (ev: any) => {
    ev.preventDefault()
  }

  const onCheckboxClick = (ev: any, shift: boolean) => {
    if (onSelectRow) {
      onSelectRow([data.id], !checkboxValue, shift)
    }
    setCheckboxValue((n) => !n)
  }

  const onDragEnter = (ev: any) => {
    ev.preventDefault()
    setDropOn(true)
  }

  const onDragLeave = (ev: any) => {
    ev.preventDefault()
    setDropOn(false)
  }

  const renderActionMenu = (am: IListActionMenuItem[]) => {
    return (
      <td className={'flex items-center border-l border-[#e1e1e1]'}>
        <div
          className={styleClass.actionMenu.container}
          onClick={openActionMenu}
        >
          <div className={styleClass.actionMenu.menu}>
            {am &&
              am.map((item: IListActionMenuItem, key: number) => {
                const isDelete =
                  item.name === t('delete') ||
                  item.name === 'Slett' ||
                  item.type === 'delete' ||
                  item.name === 'Fjern prosjekt tilgang'
                const isEdit =
                  item.name === 'Rediger' ||
                  item.name === t('edit') ||
                  item.name === capFirstLetter(t('edit'))
                const isClone =
                  item.name === 'Dupliser' || item.name === t('duplicate')
                const isMassClone = item.name === 'massClone'
                const isPassWordReset = item.name === t('reset_password')
                const isExit =
                  item.name === t('redirect') || item.name === t('exit')

                return (
                  <div style={{ visibility: 'visible' }} key={key}>
                    {isDelete ||
                    isEdit ||
                    isClone ||
                    isPassWordReset ||
                    isExit ||
                    !!item.icon ||
                    isMassClone ? (
                      <span
                        title={item.name}
                        data-actionrow={JSON.stringify(data)}
                        key={key}
                        onClick={onMenuItemActionClick(item.action)}
                        className={styleClass.actionMenu.item(isEdit, isDelete)}
                      >
                        {!isMassClone && !item.icon ? (
                          <Icon
                            icon={
                              isDelete
                                ? Icon.IconType.DELETE_RED
                                : isEdit
                                  ? Icon.IconType.EDIT_BLUE
                                  : isClone
                                    ? Icon.IconType.COPY_TO_CLIPBOARD
                                    : isPassWordReset
                                      ? Icon.IconType.PASSWORD_RESET
                                      : Icon.IconType.EXIT_BLUE
                            }
                          />
                        ) : (
                          <MaterialIcon
                            icon={item.icon ?? 'file_copy'}
                            className={
                              'text-blue-root text-base material-symbols-outlined w-full'
                            }
                          />
                        )}
                      </span>
                    ) : (
                      <div
                        data-actionrow={JSON.stringify(data)}
                        key={key}
                        onClick={onMenuItemActionClick(item.action)}
                        className={
                          'cursor-pointer rounded-sm border border-gray-100 p-1 bg-white text-gray-800'
                        }
                      >
                        {item.name}
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </td>
    )
  }
  return (
    <>
      <tr
        onDragStart={(e) => onDragStart(e, index)}
        draggable={isRowDraggable}
        className={styleClass.listItem(
          checkboxValue || selected,
          dropOn,
          disable ?? false,
        )}
        onClick={onListItemClick}
        data-row={JSON.stringify(data)}
        onDrop={(e) => onDrop(e, index)}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
      >
        {expandedElement && (
          <td className={'px-1.5'}>
            <Icon
              styleClass={'w-5'}
              icon={
                expandTable ? Icons.REMOVE_CIRCLE_BLUE : Icons.ADD_CIRCLE_BLUE
              }
              className={'py-1 text-center w-6'}
              onClick={(e) => {
                e.stopPropagation()
                setExpandTable((n) => !n)
              }}
            />
          </td>
        )}

        {isRowDraggable && (
          <td className="w-8 flex flex-row items-center justify-center pointer-events-none">
            <Icon
              styleClass={'w-2 h-2 pointer-events-none'}
              icon={Icons.DRAG_NEW}
              className={
                'flex items-center w-full h-full ml-2.5 mr-2 pointer-events-none'
              }
              noPointerEvent={true}
            />
          </td>
        )}

        {isRowSelectable && (
          <td
            className="w-8 flex flex-row items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <Checkbox valueProp={checkboxValue} onChange={onCheckboxClick} />
          </td>
        )}
        {highlightedRows && (
          <td className="w-8 flex flex-row items-center justify-center">
            {highlightedRows.find((row) => row === data.id) && (
              <div className="rounded-full bg-blue-root w-2 h-2" />
            )}
          </td>
        )}

        {onEditClick && (
          <td
            className="w-8 flex flex-row items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <MaterialIcon
              onClick={(e) => onEditClick(e, data)}
              className={'text-blue-root text-xl'}
              icon={'edit'}
            />
          </td>
        )}

        {onPreviewClick && (
          <td className="w-8 flex flex-row items-center justify-center pointer-events-auto">
            <MaterialIcon
              onClick={(e) => onPreviewClick(e, data)}
              className={'text-blue-root text-xl'}
              icon={'preview'}
            />
          </td>
        )}

        {renderData()}
        {actionMenu ? renderActionMenu(actionMenu) : null}
      </tr>
      {expandTable && expandedElement && (
        <tr>
          <td>{expandedElement(data)}</td>
        </tr>
      )}
    </>
  )
}
export default memo(ListItem)
