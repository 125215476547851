import { PureComponent } from 'react'
import { classNames } from '../../utility/utils'
import { IListContext } from './ListContextProvider'
import { IListColumns } from './ListTypes'
import { withListContext } from './withListContext'

const styleClass = {
  root: (_mouseDown: boolean) =>
    classNames('absolute', 'inset-y-0', 'border-r-1', 'w-2', 'border-gray-400'),
}

interface IListResizeProps extends IListContext {
  column: IListColumns
}

interface IListResizeState {
  mouseDown: boolean
  startOffset: number
}

class ListResize extends PureComponent<IListResizeProps, IListResizeState> {
  constructor(props: IListResizeProps) {
    super(props)
    this.state = {
      mouseDown: false,
      startOffset: 0,
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('mousemove', this.onDrag)
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  private onMouseDown = (e: any) => {
    const th = e.target
    document.addEventListener('mousemove', this.onDrag)
    document.addEventListener('mouseup', this.onMouseUp)
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      mouseDown: true,
      startOffset: th.offsetWidth - -Number(this.props.column.size) - e.pageX,
    })
  }

  private onDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.mouseDown) {
      const size = this.state.startOffset + e.pageX
      const { updateColumnSize } = this.props.actions
      const { column } = this.props
      if (size > 50) {
        updateColumnSize(column, size.toString())
      }
    }
  }

  private onMouseUp = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    document.removeEventListener('mousemove', this.onDrag)
    this.setState({ mouseDown: false })
    this.props.actions.saveTableConfig()
    document.removeEventListener('mouseup', this.onMouseUp)
  }

  public render() {
    return (
      <div
        className={styleClass.root(this.state.mouseDown)}
        style={{ right: '-2px', cursor: 'col-resize' }}
        onMouseDown={this.onMouseDown}
      />
    )
  }
}

export default withListContext(ListResize)
