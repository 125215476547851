import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import MConstructionTaskItem from 'src/mobile/components/MConstructionTaskItem'
import MContent from 'src/mobile/components/MContent'
import MHeader from 'src/mobile/components/MHeader'
import MPage from 'src/mobile/components/MPage'
import { useUpcomingConstructionTasks } from 'src/query/construction/construction'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'

const Content = () => {
  const { data: tasks, isLoading } = useUpcomingConstructionTasks()
  const { t } = useTranslation()
  const userContext: IUserContext = useContext(UserContext)
  const { user } = userContext.state
  if (isLoading || !tasks)
    return (
      <div className="w-full h-full flex items-center justify-center">
        <SpinnerMobile />
      </div>
    )
  return (
    <>
      <MHeader user={user} onBackClick={() => history.back()} />
      <p className={'text-gray-600 text-sm p-4'}>
        {t('this_weeks_activities_in_my_discipline')}
      </p>
      {tasks.this_weeks_construction_tasks.map(
        (task) =>
          task.construction_locomotive && (
            <MConstructionTaskItem
              key={task.id}
              task={task}
              wagon={task.construction_locomotive}
            />
          ),
      )}
      <p className={'text-gray-600 text-sm p-4'}>
        {t('next_weeks_activities_in_my_discipline')}
      </p>
      {tasks.next_weeks_construction_tasks.map(
        (task) =>
          task.construction_locomotive && (
            <MConstructionTaskItem
              key={task.id}
              task={task}
              wagon={task.construction_locomotive}
            />
          ),
      )}
    </>
  )
}

export const MComingActivites = ({ pid: _pid }: { pid: string }) => {
  return (
    <MPage bgColor={'mobile'}>
      <MContent setVh={true}>
        <Content />
      </MContent>
    </MPage>
  )
}
