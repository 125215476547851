import { ComponentProps, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import Button from 'src/ui-elements/button/Button'

const Separator = () => <span className="border-x border-x-gray-200 w-0.5" />

type TimeScaleButtonProps = PropsWithChildren<ComponentProps<typeof Button>> & {
  active?: boolean
}

const TimeScaleButton = ({
  children,
  active,
  ...props
}: TimeScaleButtonProps) => (
  <Button
    className={twMerge(
      'border-none shadow-none text-grey-dark hover:bg-blue-100 hover:text-blue-root focus:text-blue-root active:text-blue-root',
      active && 'bg-blue-100',
      active && 'text-blue-root',
    )}
    {...props}
  >
    {children}
  </Button>
)

type ZoomLevel = 'week' | 'month' | 'quarter' | 'project'

type TimeScaleProps = {
  onScaleClick: (scale: ZoomLevel) => void
  activeScale: ZoomLevel
}

const TimeScale = ({ onScaleClick, activeScale }: TimeScaleProps) => {
  const { t } = useTranslation()
  return (
    <div className="fixed bottom-10 right-10 z-30 rounded bg-white shadow-lg p-1 flex">
      <TimeScaleButton
        active={activeScale === 'project'}
        onClick={() => onScaleClick('project')}
      >
        {t('project')}
      </TimeScaleButton>
      <Separator />
      <TimeScaleButton
        active={activeScale === 'week'}
        onClick={() => onScaleClick('week')}
      >
        {t('weeks')}
      </TimeScaleButton>
      <TimeScaleButton
        active={activeScale === 'month'}
        onClick={() => onScaleClick('month')}
      >
        {t('months')}
      </TimeScaleButton>
      <TimeScaleButton
        active={activeScale === 'quarter'}
        onClick={() => onScaleClick('quarter')}
      >
        {t('quarters')}
      </TimeScaleButton>
    </div>
  )
}

export default TimeScale
