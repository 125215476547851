import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import CopyUrl from 'src/components/copy/CopyUrl'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import {
  initializeMetaValues,
  setExistingValues,
} from 'src/components/system/SystemUtil'
import DocumentMetaDataFields from 'src/document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from 'src/document/types/IMetaData'
import useDraftMode from 'src/hooks/useDraftMode'
import { getConstructionWagon } from 'src/service/ConstructionWagonService'
import { getProjectSystemTypeGroups } from 'src/service/SystemTypeGroupService'
import Spinner from 'src/ui-elements/loader/Spinner'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import {
  IWithProjectContext,
  withProjectContext,
} from '../../../context/withProjectContext'
import {
  createConstructionTask,
  editConstructionTask,
} from '../../../service/ConstructionTaskService'
import { getProjectDisciplines } from '../../../service/DisciplineService'
import {
  IConstructionTask,
  IConstructionWagon,
  IDiscipline,
  ISystemTypeGroup,
} from '../../../service/OrgTypes'
import { constructionStatus, taskUnits } from '../../../service/SystemValues'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import Modal from '../../../ui-elements/modal/Modal'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import { renderDayContents, toFixed } from '../../../utility/Utility'
import { capFirstLetter, classNames } from '../../../utility/utils'
import Selector from '../../selectors/Selector'

interface IConstructionTaskForm extends IWithProjectContext {
  open: boolean
  closeModal: () => void
  updateList: () => void
  isEditing: boolean
  constructionWagonIdProps: number
  constructionTask?: IConstructionTask
}

const ConstructionTaskForm = ({
  open,
  closeModal,
  projectContext,
  constructionWagonIdProps,
  isEditing,
  constructionTask,
}: IConstructionTaskForm) => {
  const styleClass = {
    root: classNames('flex', 'flex-col'),
    inputGroup: classNames('flex', 'flex-row', 'w-full'),
    label: classNames(
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'font-roboto',
      'my-2',
    ),
  }

  const projectId = projectContext.state.currentProject.id

  useEffect(() => {
    getWagon()

    setConstructionWagonId(constructionWagonIdProps)

    getProjectDisciplines(projectContext.state.currentProject.id).then(
      (res) => {
        setDisciplines(res)
      },
    )

    if (isEditing && constructionTask) {
      setTitle(constructionTask.title)
      setStatus(constructionTask.status)
      setDeadline(moment(constructionTask.deadline))
      setConstructionWagonId(constructionTask.construction_locomotive_id)
      setDisciplineId(constructionTask.discipline_id)
      setUnit(constructionTask.unit)
      setHoursPerUnit(constructionTask.hours_per_unit)
      setTotalUnits(constructionTask.total_units)
      setPlannedExecutionHours(constructionTask.planned_execution_hours)
      setProductivity(constructionTask.productivity)
      setNumberOfWorkers(constructionTask.number_of_workers)
      setTotalWorkHour(
        constructionTask.total_work_hours
          ? constructionTask.total_work_hours
          : 0,
      )

      if (systemTypeGroup) {
        const metaFields = initializeMetaValues(
          systemTypeGroup?.optional_fields ?? [],
          'ConstructionTask',
          constructionTask?.id,
        )

        setOptionalFields(
          setExistingValues(constructionTask.optional_fields ?? [], metaFields),
        )
      }
    }
  }, [projectContext])

  const { t } = useTranslation()
  const [title, setTitle] = useState<string>('')
  const [status, setStatus] = useState<string>('open')
  const [deadline, setDeadline] = useState<any>()
  const [constructionWagonId, setConstructionWagonId] = useState<number>(0)
  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false)

  const [isEstimate, setIsEstimate] = useState<boolean>(true)

  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [statusErrorMessage, setStatusErrorMessage] = useState<string>('')
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('')
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [disciplineId, setDisciplineId] = useState<number>(0)

  const [unit, setUnit] = useState<string>('')
  const [unitErrorMessage, setUnitErrorMessage] = useState<string>('')
  const [hoursPerUnit, setHoursPerUnit] = useState<number | undefined>(
    undefined,
  )
  const [hoursPerUnitErrorMessage, setHoursPerUnitErrorMessage] =
    useState<string>('')
  const [totalUnits, setTotalUnits] = useState<number | undefined>(undefined)
  const [totalUnitsErrorMessage, setTotalUnitsErrorMessage] =
    useState<string>('')
  const [totalWorkHour, setTotalWorkHour] = useState<number | undefined>(
    undefined,
  )
  const [totalWorkHourErrorMessage, setTotalWorkHourErrorMessage] =
    useState<string>('')
  const [plannedExecutionHours, setPlannedExecutionHours] = useState<
    number | undefined
  >(undefined)
  const [
    plannedExecutionHoursErrorMessage,
    setPlannedExecutionHoursErrorMessage,
  ] = useState<string>('')
  const [productivity, setProductivity] = useState<number>(1.0)
  const [productivityError, setProductivityError] = useState<string>('')
  const [numberOfWorkers, setNumberOfWorkers] = useState<number | undefined>(
    undefined,
  )
  const [numberOfWorkersErrorMessage, setNumberOfWorkersErrorMessage] =
    useState<string>('')
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const { addAlert } = useAlert()
  const [systemTypeGroup, setSystemTypeGroup] = useState<ISystemTypeGroup>()
  const { draftMode } = useDraftMode()

  const calculate = (
    productivityFactor: number,
    total?: number,
    duration?: number,
    workers?: number,
  ) => {
    if (isEstimate) {
      if (workers && duration && !total) {
        setTotalWorkHour(workers * duration * productivityFactor)
        setTotalWorkHourErrorMessage('')
      }
    }

    if (total && duration) {
      setNumberOfWorkers(total / (duration * productivityFactor))
      setNumberOfWorkersErrorMessage('')
    }

    if (total && workers && !duration) {
      setPlannedExecutionHours(total / (workers * productivityFactor))
      setPlannedExecutionHoursErrorMessage('')
    }
  }

  const fetchSystemGroupType = useCallback(() => {
    getProjectSystemTypeGroups(projectId).then((res: ISystemTypeGroup[]) => {
      const value = res.find((item) => item.domain === 'ConstructionTask')
      setSystemTypeGroup(value)
      if (value) {
        const metaFields = initializeMetaValues(
          value.optional_fields ?? [],
          'ConstructionTask',
          constructionTask?.id,
        )
        setOptionalFields(
          setExistingValues(
            constructionTask?.optional_fields ?? [],
            metaFields,
          ),
        )
      }
    })
  }, [])

  useEffect(() => {
    fetchSystemGroupType()
  }, [
    projectContext.state.currentProject.id,
    constructionTask?.optional_fields,
    constructionTask?.id,
    fetchSystemGroupType,
  ])

  const calculateTotalWorkHour = (
    productivityFactor: number,
    hours?: number,
    size?: number,
  ) => {
    let total
    if (!isEstimate && hours && size) {
      total = (hours * size) / productivityFactor
      setTotalWorkHour(total)
      setTotalWorkHourErrorMessage('')
    }
    calculate(productivityFactor, total, plannedExecutionHours, numberOfWorkers)
  }

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value)
    setTitleErrorMessage('')
  }

  const getWagon = async () => {
    setWagon(await getConstructionWagon(constructionWagonIdProps, draftMode))
  }

  const onChangeStatus = (newStatus: string) => {
    setStatus(newStatus)
    setStatusErrorMessage('')
  }

  const setDatePickerFocus = (focused: any) => {
    setDatePickerFocused(focused.focused)
  }

  const outSideRangeDate = () => {
    return false
  }

  const onChangeDeadline = (time: moment.Moment) => {
    setDeadline(time)
    setDateErrorMessage('')
  }

  const onChangeDiscipline = (id: number) => {
    setDisciplineId(id)
    setDisciplineErrorMessage('')
  }

  const onChangeProductivity = (e: any) => {
    setProductivity(e.target.value)
    setProductivityError('')
    calculateTotalWorkHour(e.target.value, hoursPerUnit, totalUnits)
  }

  const onChangeUnit = (value: string) => {
    setUnit(value)
    setUnitErrorMessage('')
  }

  const onChangeTotalUnits = (e: any) => {
    setTotalUnits(e.target.value)
    setTotalUnitsErrorMessage('')
    calculateTotalWorkHour(productivity, hoursPerUnit, e.target.value)
  }

  const onChangeHoursPerUnit = (e: any) => {
    setHoursPerUnit(e.target.value)
    setHoursPerUnitErrorMessage('')
    calculateTotalWorkHour(productivity, e.target.value, totalUnits)
  }

  const onChangeIsEstimate = (value: any) => {
    setIsEstimate(value)
    if (value) {
      setTotalUnits(0)
      setHoursPerUnit(0)
      setUnit('')
    }
  }

  const onChangeNumberOfWorkersCalculation = (e: any) => {
    setNumberOfWorkers(e.target.value)
    setNumberOfWorkersErrorMessage('')

    if (!totalWorkHour && plannedExecutionHours) {
      setTotalWorkHour(plannedExecutionHours * e.target.value * productivity)
      setTotalWorkHourErrorMessage('')
    }

    if (totalWorkHour) {
      setPlannedExecutionHours(totalWorkHour / (e.target.value * productivity))
      setPlannedExecutionHoursErrorMessage('')
    }
  }

  const onChangePlannedExecutionHoursCalculation = (e: any) => {
    setPlannedExecutionHours(e.target.value)
    setPlannedExecutionHoursErrorMessage('')

    if (!totalWorkHour && numberOfWorkers) {
      setTotalWorkHour(numberOfWorkers * e.target.value * productivity)
      setTotalWorkHourErrorMessage('')
    }

    if (totalWorkHour) {
      setNumberOfWorkers(totalWorkHour / (e.target.value * productivity))
      setNumberOfWorkersErrorMessage('')
    }
  }

  const onChangeTotalWorkHourCalculation = (e: any) => {
    setTotalWorkHour(e.target.value)
    setTotalWorkHourErrorMessage('')
    if (
      isEstimate ||
      (totalWorkHour && toFixed(totalWorkHour) === toFixed(e.target.value))
    ) {
      calculate(
        productivity,
        e.target.value,
        plannedExecutionHours,
        numberOfWorkers,
      )
    }
  }

  const onSubmitForm = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    let error = false

    if (!title) {
      setTitleErrorMessage(t('fill_out_w_param', { param: t('title') }))
      error = true
    }

    if (
      !moment(deadline).isBetween(
        moment(wagon.startTime),
        moment(wagon.startTime).add(wagon.extended_duration, 'days'),
        'day',
        '[]',
      )
    ) {
      setDateErrorMessage(
        t('date_must_be_between_dates', {
          startDate: moment(wagon.startTime).format('L'),
          endDate: moment(wagon.startTime)
            .add(wagon.extended_duration, 'days')
            .format('L'),
        }),
      )
      error = true
    }

    if (!deadline) {
      setDateErrorMessage(t('select_w_param', { param: t('deadline') }))
      error = true
    }

    if (!disciplineId) {
      setDisciplineErrorMessage(t('select_w_param', { param: t('discipline') }))
      error = true
    }

    if (productivity <= 0 || productivity > 1) {
      setProductivityError(t('the_value_must_be_between_0_and_1'))
      error = true
    }

    if (!isEstimate && !unit) {
      setUnitErrorMessage(t('fill_out_w_param', { param: t('unit') }))
      error = true
    }

    if (!isEstimate && !totalUnits) {
      setTotalUnitsErrorMessage(
        t('fill_out_w_param', { param: t('total_units') }),
      )
      error = true
    }

    if (!isEstimate && !hoursPerUnit) {
      setHoursPerUnitErrorMessage(
        t('fill_out_w_param', { param: t('hours_per_unit') }),
      )
      error = true
    }

    if (!totalWorkHour) {
      setTotalWorkHourErrorMessage(
        t('fill_out_w_param', { param: t('working_hours') }),
      )
      error = true
    }

    if (!numberOfWorkers) {
      setNumberOfWorkersErrorMessage(
        t('fill_out_w_param', { param: t('number_of_workers') }),
      )
      error = true
    }

    if (!plannedExecutionHours) {
      setPlannedExecutionHoursErrorMessage(
        t('fill_out_w_param', { param: t('duration') }),
      )
      error = true
    }

    if (plannedExecutionHours && plannedExecutionHours > wagon.duration * 7.5) {
      setPlannedExecutionHoursErrorMessage(
        t('duration_is_greater_than_wagon_duration'),
      )
      error = true
    }

    if (!error) {
      const constructionTaskId =
        isEditing && constructionTask ? constructionTask.id : undefined
      const updatedConstructionTask: IConstructionTask = {
        id: constructionTaskId,
        title,
        status,
        deadline,
        unit,
        is_estimate: isEstimate,
        hours_per_unit: hoursPerUnit ? hoursPerUnit : 0,
        total_units: totalUnits ? totalUnits : 0,
        planned_execution_hours: plannedExecutionHours
          ? plannedExecutionHours
          : 0,
        number_of_workers: numberOfWorkers ? numberOfWorkers : 0,
        productivity,
        discipline_id: disciplineId,
        construction_locomotive_id: constructionWagonId,
        project_id: projectId,
        total_work_hours: totalWorkHour,
        optional_fields: optionalFields,
        system_type_group_id: systemTypeGroup?.id,
      }

      const constructionConstructionTaskApiCall = isEditing
        ? editConstructionTask(updatedConstructionTask)
        : createConstructionTask(updatedConstructionTask, constructionWagonId)
      constructionConstructionTaskApiCall.then(() => {
        if (createMultiple) {
          setIsLoading(false)
          setTitle('')
          showAlert(
            'success',
            t('creation_succeeded'),
            t('activity_has_been_created'),
          )
        } else {
          closeModal()
        }
      })
    } else {
      setIsLoading(false)
    }
  }

  const showAlert = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setOptionalFields(values)
  }

  return (
    <Modal
      show={open}
      title={
        isEditing && constructionTask
          ? t('activity_title', { title: constructionTask.title })
          : t('new_activity')
      }
      closeModal={closeModal}
      size={'w-2/3'}
      maxWidth={800}
    >
      <form className={styleClass.root} onSubmit={onSubmitForm}>
        <div className={styleClass.inputGroup}>
          <div className={'flex flex-col px-2 pb-1 items-start '}>
            <div className={'flex  flex-row'}>
              <label
                className={'text-sm font-medium text-gray-700 my-2'}
              >{`${capFirstLetter(t('deadline'))} *`}</label>
              {dateErrorMessage ? (
                <label className={'pl-2 text-sm my-2'} style={{ color: 'Red' }}>
                  {dateErrorMessage}
                </label>
              ) : null}
            </div>
            <div className={'flex justify-between w-full'}>
              <SingleDatePicker
                firstDayOfWeek={1}
                date={deadline}
                onDateChange={onChangeDeadline}
                renderDayContents={renderDayContents}
                focused={datePickerFocused}
                onFocusChange={setDatePickerFocus}
                id="datepicker-wagon"
                small={true}
                isOutsideRange={outSideRangeDate}
                showDefaultInputIcon={true}
                noBorder={true}
                numberOfMonths={1}
                displayFormat={() =>
                  moment.localeData('no').postformat('DD.MM.YY')
                }
                required={true}
                hideKeyboardShortcutsPanel={true}
              />
            </div>
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className="pl-2 mb-2 w-1/2">
            <label className={'text-sm font-medium text-gray-700'}>
              {capFirstLetter(t('wagon'))}{' '}
            </label>
            <span className="block text-sm leading-5  text-gray-900">
              {wagon.title ? `${wagon.title}` : ''}
            </span>
          </div>

          <div className="pl-2 mb-2 w-1/2">
            <label className={'text-sm font-medium text-gray-700'}>
              {t('wagon_duration_hours')}
            </label>
            <span className="block text-sm leading-5  text-gray-900">
              {wagon && wagon.duration ? wagon.duration * 7.5 : ''}
            </span>
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className="pl-2 mb-2 w-1/2">
            <label className={'text-sm font-medium text-gray-700'}>
              {t('wagon_start_date')}
            </label>
            <span className="block text-sm leading-5  text-gray-900">
              {wagon && wagon.startTime
                ? `${moment(wagon.startTime).format('L')}`
                : ''}
            </span>
          </div>

          <div className="pl-2 mb-2 w-1/2">
            <label className={'text-sm font-medium text-gray-700'}>
              {t('wagon_end_date')}
            </label>
            <span className="block text-sm leading-5  text-gray-900">
              {wagon && wagon.startTime
                ? `${moment(wagon.startTime)
                    .add(wagon.extended_duration, 'days')
                    .format('L')}`
                : ''}
            </span>
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          {isEditing ? (
            <div className={'w-1/2'}>
              <Selector
                items={constructionStatus(t)}
                selectedItemId={status}
                onSelect={onChangeStatus}
                label={t('status')}
                dataFields={['name']}
                required={true}
                fontSize={'sm'}
                fontWeight={'bold'}
                userSelector={false}
                errorMessage={statusErrorMessage}
              />
            </div>
          ) : null}
        </div>

        <div className={styleClass.inputGroup}>
          <Input
            block={true}
            label={t('title')}
            onChange={onChangeTitle}
            value={title}
            errorMessage={titleErrorMessage}
            autoFocus={true}
            required={true}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <Selector
            items={disciplines}
            selectedItemId={disciplineId}
            onSelect={onChangeDiscipline}
            label={t('discipline')}
            dataFields={['shortName', 'name']}
            required={true}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={disciplineErrorMessage}
          />
        </div>

        <div className={styleClass.inputGroup}>
          <div className={' p-2 pb-0 w-1/2 flex flex-row items-center'}>
            <label
              onClick={() => onChangeIsEstimate(!isEstimate)}
              className={styleClass.label}
            >
              {t('suggested_values')}
            </label>
            <span className={'p-2 pb-0'}>
              <SwitchHOC
                valueProp={isEstimate}
                height={20}
                width={40}
                className="custom-classname"
                onChange={onChangeIsEstimate}
              />
            </span>
          </div>
        </div>
        {!isEstimate ? (
          <div className={styleClass.inputGroup}>
            <div className={'w-1/2'}>
              <Selector
                items={taskUnits(t)}
                selectedItemId={unit}
                onSelect={onChangeUnit}
                label={t('unit')}
                dataFields={['name']}
                fontSize={'sm'}
                fontWeight={'bold'}
                errorMessage={unitErrorMessage}
              />
            </div>
          </div>
        ) : null}

        {!isEstimate ? (
          <div className={styleClass.inputGroup}>
            <div className={'w-1/2'}>
              <Input
                block={true}
                label={t('total_units')}
                onChange={onChangeTotalUnits}
                value={totalUnits ? toFixed(totalUnits) : undefined}
                type={'number'}
                errorMessage={totalUnitsErrorMessage}
              />
            </div>
            <div className={'w-1/2'}>
              <Input
                block={true}
                label={t('hours_per_unit')}
                onChange={onChangeHoursPerUnit}
                value={hoursPerUnit ? toFixed(hoursPerUnit) : undefined}
                type={'number'}
                errorMessage={hoursPerUnitErrorMessage}
              />
            </div>
          </div>
        ) : null}

        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('total_work_hours')}
              onChange={onChangeTotalWorkHourCalculation}
              value={totalWorkHour ? toFixed(totalWorkHour) : undefined}
              type={'number'}
              disabled={!isEstimate}
              errorMessage={totalWorkHourErrorMessage}
              min={0}
            />
          </div>

          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('productivity_factor')}
              onChange={onChangeProductivity}
              value={toFixed(productivity)}
              errorMessage={productivityError}
              type={'number'}
              max={1}
              min={0}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('duration_hours')}
              onChange={onChangePlannedExecutionHoursCalculation}
              value={
                plannedExecutionHours
                  ? toFixed(plannedExecutionHours)
                  : undefined
              }
              type={'number'}
              errorMessage={plannedExecutionHoursErrorMessage}
            />
          </div>

          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('number_of_workers')}
              onChange={onChangeNumberOfWorkersCalculation}
              value={numberOfWorkers ? toFixed(numberOfWorkers) : undefined}
              type={'number'}
              errorMessage={numberOfWorkersErrorMessage}
            />
          </div>
        </div>
        {optionalFields.length > 0 && (
          <div className="pb-8">
            <DocumentMetaDataFields
              title={''}
              onFieldsUpdate={onOptionalFieldsUpdate}
              fields={optionalFields}
              required={false}
            />
          </div>
        )}

        <ModalFooter>
          <Button onClick={closeModal}>{t('cancel')}</Button>
          {isEditing ? (
            isLoading ? (
              <Spinner />
            ) : (
              <Button
                type={Button.ButtonType.PRIMARY}
                disabled={isLoading}
                onClick={onSubmitForm}
              >
                {t('update')}
              </Button>
            )
          ) : (
            <>
              <Button
                type={Button.ButtonType.SECONDARY}
                onClick={onMultipleCreate}
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : t('add_multiple')}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                onClick={onSingleCreate}
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : t('save')}
              </Button>
            </>
          )}
          {isEditing && (
            <CopyUrl
              params={{
                modal: 'constructiontask',
                id: constructionTask?.id ?? '',
              }}
            />
          )}
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default withProjectContext(ConstructionTaskForm)
