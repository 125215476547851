import { classNames } from '../../utility/utils'

interface IMChips {
  text: string
  color: string
}

const MChips = ({ text, color }: IMChips) => {
  const styleClass = {
    root: classNames(
      'mx-2',
      'rounded-full',
      'border-2',
      `border-${color}-400`,
      'font-medium',
      `bg-${color}-100`,
      `text-${color}-500`,
      'text-xs',
    ),
    text: classNames('px-2', 'py-0.5'),
  }
  return (
    <div className={styleClass.root}>
      <p className={styleClass.text}>{text}</p>
    </div>
  )
}

export default MChips
