import { TFunction } from 'i18next'

export enum CLEAN_STATUS {
  NOT_CLEAN_ZONE = 'not_clean_zone',
  RED_CLEAN_ZONE = 'red_clean_zone',
  YELLOW_CLEAN_ZONE = 'yellow_clean_zone',
}

class CleanStatus {
  public static info = (t: TFunction) => {
    return Object.keys(CLEAN_STATUS).map((c) => ({
      id: c.toLowerCase(),
      name: t(CLEAN_STATUS[c]),
    }))
  }

  public static zone = (id?: string): CLEAN_STATUS => {
    if (!id) return CLEAN_STATUS.NOT_CLEAN_ZONE
    return CLEAN_STATUS[id.toUpperCase()]
  }

  public static text = (t: TFunction, id?: string) => {
    return t(this.zone(id))
  }

  public static selected = (
    t: TFunction,
    id?: string,
  ): { id: string; name: string } => {
    const zone = this.zone(id)
    return {
      id: zone.toLocaleLowerCase(),
      name: t(zone),
    }
  }

  public static notClean = () => CLEAN_STATUS.NOT_CLEAN_ZONE

  public static id = (id?: string) => {
    return this.zone(id).toLocaleLowerCase()
  }

  public static border = (id?: string) => {
    const status = this.zone(id)
    switch (status) {
      case CLEAN_STATUS.RED_CLEAN_ZONE:
        return 'border-red-500'
      case CLEAN_STATUS.YELLOW_CLEAN_ZONE:
        return 'border-yellow-500'
      case CLEAN_STATUS.NOT_CLEAN_ZONE:
        return 'border-transparent'
      default:
        return 'border-transparent'
    }
  }

  public static textColor = (id?: string) => {
    const status = this.zone(id)
    switch (status) {
      case CLEAN_STATUS.RED_CLEAN_ZONE:
        return 'text-red pl-1'
      case CLEAN_STATUS.YELLOW_CLEAN_ZONE:
        return 'text-yellow pl-1'
      case CLEAN_STATUS.NOT_CLEAN_ZONE:
        return 'text-transparent pl-1'
      default:
        return 'text-transparent'
    }
  }
}

export default CleanStatus
