import { ReactNode, useRef } from 'react'
import { classNames } from '../../utility/utils'

interface IPageRootProps {
  children: ReactNode
  className?: string
  hasCrumPage?: boolean
}

const styleClass = {
  root: (moreClasses: string) =>
    classNames(
      'bg-white',
      'border',
      'border-gray-300',
      'flex',
      'flex-col',
      'w-full',
      'h-full',
      'treeScroll',
      moreClasses,
    ),
}

const PageRoot = ({ children, className = '' }: IPageRootProps) => {
  const pageRootRef = useRef<HTMLDivElement>(null)

  return (
    <div className={styleClass.root(className)} ref={pageRootRef}>
      {children}
    </div>
  )
}

export default PageRoot
