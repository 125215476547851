import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  IBarData,
  IDelivery,
  IKeypoint,
  ILineData,
  ITask,
} from 'src/service/OrgTypes'
import { capFirstLetter, classNames } from '../../utility/utils'

export const barSize = 30

export const maxBarWidth = 35

export const cartesianGridStroke = '#d6d9da'

export const cartesianDashArray = '3 3'

export const selectorViews: Array<{
  id: number
  text_key: string
  value: string
}> = [
  { id: 1, text_key: 'total', value: 'project' },
  { id: 2, text_key: 'keypoints', value: 'key_point' },
  { id: 3, text_key: 'deliveries', value: 'delivery' },
  { id: 4, text_key: 'tasks', value: 'task' },
]

export const ppuSelectorViews: Array<{
  id: number
  text_key: string
  value: string
}> = [
  { id: 1, text_key: 'keypoints', value: 'key_point' },
  { id: 2, text_key: 'deliveries', value: 'delivery' },
  { id: 3, text_key: 'tasks', value: 'action' },
]

export const graphMargin = { top: 20, right: 30, bottom: 20, left: 32 }

export type ICommonPPUResponse =
  | IProjectPPUResponse
  | IKeypointPPUResponse
  | IDeliveryPPUResponse
  | ITaskPPUResponse

export type BarChartDataType =
  | ICumulativeReasonsResponse[]
  | IPerDisciplinePPUData[]
  | ICommonPPUResponse[]

export interface IKPIFiltersData {
  startDate: moment.Moment
  setStartDate: (newDate: moment.Moment) => void
  endDate: moment.Moment
  setEndDate: (newDate: moment.Moment) => void
  selectedView: number
  setSelectedView?: (newId: number) => void
  selectedDisciplines?: number[]
  setSelectedDisciplines?: (newIds: number[]) => void
  selectedContracts?: number[]
  setSelectedContracts?: (newIds: number[]) => void
  selectedControlAreaGroups?: number[]
  setSelectedControlAreaGroups?: (newIds: number[]) => void
  selectedControlAreas?: number[]
  setSelectedControlAreas?: (newIds: number[]) => void
  selectedProcesses?: number[]
  setSelectedProcesses?: (newIds: number[]) => void
  selectedThemes?: number[]
  setSelectedThemes?: (newIds: number[]) => void
  selectedTags?: number[]
  setSelectedTags?: (newIds: number[]) => void
  selectedType?: string
  setSelectedType?: (newType: string) => void
}

export interface IKPISelectedFilters {
  contractNums: string
  disciplineNames: string
}

export interface IProjectPPUResponse {
  average_percent_done: number
  deliveries: IDelivery[]
  goal_percent_done: number
  key_points: IKeypoint[]
  percent_done: number
  ppc_done: number
  ppc_open: number
  ppc_week: number
  tasks: ITask[]
  week: number
  year: number
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export interface IKeypointPPUResponse
  extends Omit<IProjectPPUResponse, 'deliveries' | 'tasks'> {
  created_at: moment.Moment
  cumulative: number
  data_type: string
  id: number
  key_points: IKeypoint[]
  lack_of_description: number
  lack_of_interdisciplinery_collaboration: number
  lack_of_resources: number
  lack_of_tools: number
  low_priority: number
  placed_wrong: number
  proceeding_activities_not_complete: number
  project_id: number
  scope: number
  task_planned_to_early: number
  updated_at: moment.Moment
}

export interface IDeliveryPPUResponse
  extends Omit<IProjectPPUResponse, 'key_points' | 'tasks'> {
  created_at: moment.Moment
  cumulative: number
  data_type: string
  deliveries: IDelivery[]
  goal_percent_done: number
  id: number
  lack_of_description: number
  lack_of_interdisciplinery_collaboration: number
  lack_of_resources: number
  lack_of_tools: number
  low_priority: number
  placed_wrong: number
  proceeding_activities_not_complete: number
  project_id: number
  scope: number
  task_planned_to_early: number
  updated_at: moment.Moment
}

export interface ITaskPPUResponse
  extends Omit<IProjectPPUResponse, 'key_points' | 'deliveries'> {
  created_at: moment.Moment
  cumulative: number
  data_type: string
  goal_percent_done: number
  id: number
  lack_of_description: number
  lack_of_interdisciplinery_collaboration: number
  lack_of_resources: number
  lack_of_tools: number
  low_priority: number
  placed_wrong: number
  proceeding_activities_not_complete: number
  project_id: number
  scope: number
  task_planned_to_early: number
  tasks: ITask[]
  updated_at: moment.Moment
}

export interface IChartDataResponse {
  commulative: number
  commulative_done: number
  commulative_open: number
  done: number
  in_progress: number
  open: number
  total: number
  week: number
  year: number
}

export interface ICumulativeReasonsResponse {
  lack_of_description: number
  lack_of_interdisciplinery_collaboration: number
  lack_of_resources: number
  lack_of_tools: number
  low_priority: number
  placed_wrong: number
  proceeding_activities_not_complete: number
  scope: number
  task_planned_to_early: number
  week: number
  year: number
}

export interface IPerDisciplinePPUData {
  id: number
  name: string
  ppc_open: number
  ppc_done: number
}

export const ReasonsGraphBarData = (t: any): IBarData[] => [
  {
    dataKey: 'lack_of_description',
    name: t('lack_of_description'),
    stackId: 'a',
    fill: '#3dc87d',
  },
  {
    dataKey: 'lack_of_resources',
    name: t('lack_of_staff'),
    stackId: 'a',
    fill: '#fd7171',
  },
  {
    dataKey: 'lack_of_tools',
    name: t('lack_of_tools'),
    stackId: 'a',
    fill: '#fcf302',
  },
  {
    dataKey: 'scope',
    name: t('underestimated_extent'),
    stackId: 'a',
    fill: '#d6d5cf',
  },
  {
    dataKey: 'proceeding_activities_not_complete',
    name: t('proceeding_activities_not_complete'),
    stackId: 'a',
    fill: '#2104f7',
  },
  {
    dataKey: 'lack_of_interdisciplinery_collaboration',
    name: t('lack_of_interdisciplinery_collaboration'),
    stackId: 'a',
    fill: '#A04000',
  },
  {
    dataKey: 'task_planned_to_early',
    name: t('task_planned_to_early'),
    stackId: 'a',
    fill: '#CCEEFF',
  },
  {
    dataKey: 'low_priority',
    name: t('low_priority'),
    stackId: 'a',
    fill: '#808080',
  },
  {
    dataKey: 'placed_wrong',
    name: t('placed_wrong'),
    stackId: 'a',
    fill: '#ef921b',
  },
  {
    dataKey: 'change_decision',
    name: t('decided_change'),
    stackId: 'a',
    fill: '#8a20ff',
  },
  {
    dataKey: 'lack_of_skills',
    name: t('lack_of_competence'),
    stackId: 'a',
    fill: '#0e063e',
  },
  {
    dataKey: 'lack_of_background',
    name: t('missing_surface_not_leveled'),
    stackId: 'a',
    fill: '#43573c',
  },
  {
    dataKey: 'low_priority_another_plan',
    name: t('down_prioritized_for_another_task_in_the_plan'),
    stackId: 'a',
    fill: '#175754',
  },
]

export const getPerDisciplineGraphBarData = (
  dataType: string,
  t: any,
): IBarData[] => {
  return [
    {
      dataKey: 'ppc_done',
      name: t('type_reliability_per_discipline', {
        type: capFirstLetter(t(dataType)),
        status: t('done'),
      }),
      stackId: 'a',
      fill: PPUColors.DONE,
    },
    {
      dataKey: 'ppc_open',
      name: t('type_reliability_per_discipline', {
        type: capFirstLetter(t(dataType)),
        status: t('open'),
      }),
      stackId: 'a',
      fill: PPUColors.OPEN,
    },
  ]
}

export const StatPageLineData = (t: any): ILineData[] => [
  {
    type: 'monotone',
    dataKey: 'commulative',
    name: capFirstLetter(t('cumulative_total')),
    stroke: '#97a6ba',
  },
  {
    type: 'monotone',
    dataKey: 'commulative_done',
    name: capFirstLetter(t('cumulative_done')),
    stroke: '#3dc87d',
  },
  {
    type: 'monotone',
    dataKey: 'commulative_open',
    name: capFirstLetter(t('cumulative_open')),
    stroke: '#fd7171',
  },
]

export const StatPageBarData = (t: any): IBarData[] => [
  {
    dataKey: 'done',
    name: t('done'),
    stackId: 'a',
    fill: '#3dc87d',
  },
  {
    dataKey: 'open',
    name: t('open'),
    stackId: 'a',
    fill: '#fd7171',
  },
]

export const StatPageAnticipatedLineData = (t: any): ILineData[] => [
  {
    type: 'monotone',
    dataKey: 'task',
    name: capFirstLetter(t('tasks')),
    stroke: '#C0C0C0',
  },
  {
    type: 'monotone',
    dataKey: 'key_point',
    name: capFirstLetter(t('keypoints')),
    stroke: '#3dc87d',
  },
  {
    type: 'monotone',
    dataKey: 'delivery',
    name: capFirstLetter(t('deliveries')),
    stroke: '#fd7171',
  },
]

export const StatNewRegistrationLineData = (t: any): ILineData[] => [
  {
    type: 'monotone',
    dataKey: 'task',
    name: capFirstLetter(t('tasks')),
    stroke: '#C0C0C0',
  },
  {
    type: 'monotone',
    dataKey: 'key_point',
    name: capFirstLetter(t('keypoints')),
    stroke: '#3dc87d',
  },
  {
    type: 'monotone',
    dataKey: 'delivery',
    name: capFirstLetter(t('deliveries')),
    stroke: '#fd7171',
  },
]

export const getOversiktDataByType = (type: string, t: any) => {
  const typeCaps = capFirstLetter(type)
  return [
    {
      dataItems: 'ppc_items',
      dataKey: 'ppc_week',
      name: t('scheduled_for_week', { type: typeCaps }),
      hide: false,
    },
    {
      dataItems: 'ppc_items',
      dataKey: 'ppc_done',
      name: t('completed_for_week', { type: typeCaps }),
      hide: false,
    },
    {
      dataItems: 'ppc_items',
      dataKey: 'ppc_open',
      name: t('not_performed_for_week', { type: typeCaps }),
      hide: false,
    },
    {
      dataItems: 'ppc_items',
      dataKey: 'ppc_moved',
      name: t('moved_for_week', { type: typeCaps }),
      hide: type === 'Aksjoner',
    },
    {
      dataItems: 'ppc_items',
      dataKey: 'cumulative',
      name: t('accumulated_planned', { type: type.toLowerCase() }),
      hide: false,
    },
    {
      dataItems: 'ppc_items',
      dataKey: 'cumulative_open',
      name: t('accumulated_not_performed', { type: type.toLowerCase() }),
      hide: false,
    },
    {
      dataItems: 'ppc_items',
      dataKey: 'cumulative_done',
      name: t('accumulated_completed', { type: type.toLowerCase() }),
      hide: false,
    },
  ]
}

export const ppuOversiktLineData = (t: any): ILineData[] => [
  {
    type: 'monotone',
    dataKey: 'percent_done',
    name: t('ppu_percent'),
    stroke: '#3dc87d',
  },
  {
    type: 'monotone',
    dataKey: 'average_percent_done',
    name: t('average_ppu'),
    stroke: '#fd7171',
  },
  {
    type: 'monotone',
    dataKey: 'goal_percent_done',
    name: t('target_ppu'),
    stroke: '#000000',
  },
]

const noDataStyleClass = {
  WebkitFilter: 'blur(0.2px) saturate(2)',
  minHeight: 360,
}

export const NotifyNoDisplay = () => {
  const { t } = useTranslation()
  return (
    <div
      className={
        'w-full flex justify-center items-center text-sm text-gray-700'
      }
      style={noDataStyleClass}
    >
      {t('no_data_found')}
    </div>
  )
}

export const toolTipStyle = {
  cursor: { fill: 'rgba(207,216,227,0.2)' },
  contentStyle: {
    borderWidth: 1,
    borderColor: '#e5e7eb',
    borderRadius: '8px',
    fontSize: 13.5,
    boxShadow: '0px 1px 2px 0 rgba(0,0,0,0.07)',
  },
  formatter: (value: number) => {
    return value ? `${value?.toFixed(2)}` : ''
  },
  noDescimalTrailingZeroes: (value: number) => {
    return `${value}`
  },
}

export enum BarColors {
  OPEN = '#FDE68A',
  DONE = '#34D399',
  DONE_WITH_ISSUES = '#F58216',
  IN_PROGRESS = '#60A5FA',
  NOT_RELEVANT = '#D1D5DB',
  NOT_SET = '#9DBEC4',
  ERROR = '#EF4444',
}

export const legendStyle = {
  wrapper: { paddingTop: 5, fontSize: 14, color: '#374151' },
}

export const PPUColors = {
  OPEN: 'rgba(210,214,220,1)',
  DONE: '#3dc87d',
}

export interface IPPUWeekFilterValues {
  dataType: string
  year: number
  week: number
  body: {
    status: 'open' | 'done' | 'moved' | ''
    discipline: number[]
    team: number[]
    main_process: number[]
    reason?: string | null
  }
}

export const KPIFilterStyles = {
  root: classNames('flex', 'flex-col', 'w-full'),
  fullWidth: { width: 'calc(100% - 12px)' },
  filtersRow: {
    container: (ppu?: boolean) =>
      classNames(
        'w-full',
        'flex',
        'flex-row',
        'flex-wrap',
        ppu ? 'justify-start' : 'justify-between',
      ),
    row: classNames('flex', 'flex-row', 'flex-wrap', 'items-center', 'pr-2'),
    wrapper: (sm?: boolean) =>
      classNames(
        'flex',
        'flex-row',
        'items-center',
        'p-2',
        sm ? 'min-w-52 max-w-52' : 'min-w-104 max-w-s mr-1',
      ),
    label: classNames(
      'text-sm',
      'leading-5',
      'font-medium',
      'text-gray-600',
      'pr-2',
      'w-mx-content',
      'my-2',
    ),
  },
}
