import { updateWallToken } from 'src/service/TheWallService'
import { delay } from './delay'
import encodeUrlParams from './encodeUrlParams'

export const downloadSingleFile = async (
  url: string,
  name: string,
  token?: string,
  getBlob?: boolean,
) => {
  let _token = token
  if (!token) {
    _token = await updateWallToken()
  }
  const authUrl = `${encodeUrlParams(url)}&token=${_token}`

  const res = await fetch(authUrl, { method: 'GET' })
  const blob = await res.blob()
  if (getBlob) {
    return blob
  }
  const blobUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.onclick = (e) => {
    e.stopImmediatePropagation()
  }
  a.download = name || 'task_ctrl_file'
  a.href = blobUrl
  a.style.display = 'none'
  document.body.append(a)
  a.click()
  await delay(100)
  a.remove()
  return
}
