import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getImprovementDeliveries } from 'src/service/DeliveryService'
import {
  getFilteredProjectImprovementsWithPagination,
  getImprovement,
  getImprovementBoard,
} from 'src/service/ImprovementsService'
import { getImprovementTasks } from 'src/service/TaskService'

export const useImprovement = (improvementId: number) =>
  useQuery({
    queryKey: ['improvement', improvementId],
    queryFn: () => getImprovement(improvementId),
    refetchOnMount: 'always',
  })

export const useImprovementBoard = (filter: any) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['improvements', projectId, filter],
    queryFn: () => getImprovementBoard(projectId, filter),
  })
}

export const useImprovementTasks = (improvementId: number) =>
  useQuery({
    queryKey: ['improvement/tasks', improvementId],
    queryFn: () => getImprovementTasks(improvementId),
  })

export const useImprovementDeliveries = (improvementId: number) =>
  useQuery({
    queryKey: ['improvement/deliveries', improvementId],
    queryFn: () => getImprovementDeliveries(improvementId),
  })

export const useImprovementListFiltered = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<
    typeof getFilteredProjectImprovementsWithPagination
  >[1]['sort']
  filters: Omit<
    Parameters<typeof getFilteredProjectImprovementsWithPagination>[1],
    'sort'
  >
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'improvementListFiltered',
      { projectId: projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getFilteredProjectImprovementsWithPagination(
        projectId,
        { ...filters, sort },
        page,
        pageSize,
      ),
    placeholderData: keepPreviousData,
    enabled,
  })
}
