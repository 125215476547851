import { FC } from 'react'
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Bar,
  Line,
  Legend,
  ResponsiveContainer,
  Area,
} from 'recharts'
import {
  ICommonPPUResponse,
  graphMargin,
  IChartDataResponse,
  cartesianGridStroke,
  cartesianDashArray,
  ICumulativeReasonsResponse,
  BarChartDataType,
} from 'src/components/KPI/KPIUtils'
import { IDataPoint } from 'src/components/charts/dashboard/util'
import LegendContent from 'src/components/statistics/graph-components/LegendContent'
import TooltipContent from 'src/components/statistics/graph-components/TooltipContent'
import { IFdvuDeliveryProgressStatistics } from 'src/fdvu/types/IRequirement'
import { IBarData, ILineData, IAreaData } from 'src/service/OrgTypes'

type ChartData =
  | ICommonPPUResponse[]
  | IFdvuDeliveryProgressStatistics[]
  | IChartDataResponse[]
  | ICumulativeReasonsResponse[]
  | BarChartDataType
  | IDataPoint[]

const ComposedChartGraph: FC<{
  chartData: ChartData
  barDataKeys?: IBarData[]
  showLegend?: boolean
  lineDataKeys?: ILineData[]
  areaDataKeys?: IAreaData[]
  xDataKey?: string
  yDataKey?: string
  yUnit?: string
  height?: number | string
  xType?: 'category' | 'number'
  yType?: 'category' | 'number'
  layout?: 'horizontal' | 'vertical'
}> = ({
  chartData,
  showLegend = true,
  barDataKeys,
  lineDataKeys,
  areaDataKeys,
  xDataKey,
  yDataKey,
  xType,
  yType,
  yUnit,
  height,
  layout,
}) => {
  if (!chartData.length || (!barDataKeys && !lineDataKeys && !areaDataKeys))
    return null
  return (
    <ResponsiveContainer height={height}>
      <ComposedChart data={chartData} margin={graphMargin} layout={layout}>
        <XAxis dataKey={xDataKey} tickLine={false} type={xType} />
        <YAxis
          dataKey={yDataKey}
          width={30}
          tickLine={false}
          allowDecimals={false}
          unit={yUnit}
          type={yType}
        />
        <CartesianGrid
          stroke={cartesianGridStroke}
          strokeDasharray={cartesianDashArray}
        />
        {barDataKeys?.map((barData) => (
          <Bar
            key={barData.dataKey}
            maxBarSize={25}
            barSize={20}
            stackId={barData.stackId}
            name={barData.name}
            dataKey={barData.dataKey}
            fill={barData.fill}
          />
        ))}
        {lineDataKeys?.map((lineData) => (
          <Line
            key={lineData.dataKey}
            dot={false}
            type={lineData.type}
            dataKey={lineData.dataKey}
            name={lineData.name}
            stroke={lineData.stroke}
            strokeDasharray={lineData.strokeDash}
            strokeWidth={2}
          />
        ))}
        {areaDataKeys?.map((areaData) => (
          <Area
            key={areaData.dataKey}
            type={'monotone'}
            name={areaData.name}
            dataKey={areaData.dataKey}
            fill={areaData.fill}
            stroke={areaData.stroke}
          />
        ))}
        <Tooltip
          content={({ active, payload }) => (
            <TooltipContent payload={payload} active={active} />
          )}
        />
        {showLegend && (
          <Legend
            align="center"
            content={({ payload }) => <LegendContent payload={payload} />}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default ComposedChartGraph
