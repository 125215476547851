import { useState } from 'react'
import { MultiTouchContext } from './MultiTouchContext'

const MultiTouchContextProvider = (props: any) => {
  const [touchId, setTouchId] = useState<number | null>(null)

  const updateTouchId = (id: number) => {
    if (touchId === null) {
      setTouchId(id)
    } else {
      setTouchId(null)
    }
  }

  const actions = {
    updateTouchId,
    clearTouchId: () => setTouchId(null),
  }

  const context = {
    actions,
    state: touchId,
  }

  return (
    <MultiTouchContext.Provider value={context}>
      {props.children}
    </MultiTouchContext.Provider>
  )
}

export default MultiTouchContextProvider
