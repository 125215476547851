import { PureComponent } from 'react'
import { classNames } from '../../utility/utils'

interface IInfoBannerProps {
  text: string
  clear?: () => void
}

export default class InfoBanner extends PureComponent<IInfoBannerProps, any> {
  private static styleClass = {
    root: classNames(
      'bg-white',
      'py-2',
      'rounded',
      'flex',
      'flex-row',
      'items-center',
      'text-center',
    ),
    text: classNames('w-5/6'),
  }

  public render() {
    const { text } = this.props
    return (
      <div className={InfoBanner.styleClass.root}>
        <p
          dangerouslySetInnerHTML={{ __html: text }}
          className={InfoBanner.styleClass.text}
        />
      </div>
    )
  }
}
