import { Redirect, Route, Switch } from 'react-router-dom'
import TabContextProvider from 'src/context/TabContextProvider/TabProvider'
import MAddIssue from 'src/mobile/pages/MAddIssue'
import MAddTavlamote from 'src/mobile/pages/MAddTavlamote'
import { Languages } from 'src/service/OrgTypes'
import MTestInviteAcceptance from '../components/MTestInviteAcceptance'
import MAddGlobalIssue from '../pages/MAddGlobalIssue'
import MAddWorkops from '../pages/MAddWorkops'
import MCheckInOut from '../pages/MCheckInOut'
import MChecksDetail from '../pages/MChecksDetail'
import { MComingActivites } from '../pages/MComingActivites'
import MControlArea from '../pages/MControlArea'
import MControlAreaList from '../pages/MControlAreaList'
import MHome from '../pages/MHome'
import MIssueDetail from '../pages/MIssueDetail'
import MIssueList from '../pages/MIssueList'
import MMeetingDetailPage from '../pages/MMeetingDetail'
import MRoom from '../pages/MRoom'
import MSearch from '../pages/MSearch'
import MSystem from '../pages/MSytem'
import MTavlamoteDetail from '../pages/MTavlamoteDetail'
import MWagon from '../pages/MWagon'
import MWagonList from '../pages/MWagonList'
import MWorkopsDetail from '../pages/MWorkopsDetail'

interface IMRouterProps {
  path: string
  lang: Languages
  changeLang: (e: Languages) => void
}

const MRouter = ({ path, lang, changeLang }: IMRouterProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${path}/`}
        render={(routeProps) => {
          return <MHome {...routeProps} lang={lang} changeLang={changeLang} />
        }}
      />
      <Route exact={true} path={`${path}/search/:text`} component={MSearch} />
      <Route
        exact={true}
        path={`${path}/control-areas/:caid`}
        render={(routeProps) => {
          return (
            <TabContextProvider location={routeProps} defaultIndex={0}>
              <MControlArea />
            </TabContextProvider>
          )
        }}
      />
      <Route
        exact={true}
        path={`${path}/rooms/:rid`}
        render={(routeProps) => {
          return (
            <TabContextProvider location={routeProps} defaultIndex={0}>
              <MRoom />
            </TabContextProvider>
          )
        }}
      />
      <Route
        exact={true}
        path={`${path}/systems/:sid`}
        render={(routeProps) => {
          return (
            <TabContextProvider location={routeProps} defaultIndex={0}>
              <MSystem />
            </TabContextProvider>
          )
        }}
      />
      <Route exact={true} path={`${path}/:pid/wagons`} component={MWagonList} />
      <Route
        exact={true}
        path={`${path}/:pid/activities/upcoming`}
        component={MComingActivites}
      />
      <Route
        exact={true}
        path={`${path}/:pid/control-areas`}
        component={MControlAreaList}
      />
      <Route
        exact={true}
        path={`${path}/:pid/issues/:size`}
        component={MIssueList}
      />
      <Route
        exact={true}
        path={`${path}/wagons/:vid`}
        render={(routeProps) => {
          return (
            <TabContextProvider location={routeProps} defaultIndex={0}>
              <MWagon />
            </TabContextProvider>
          )
        }}
      />
      <Route
        exact={true}
        path={`${path}/tavlamotes/:tid`}
        component={MTavlamoteDetail}
      />
      <Route
        exact={true}
        path={`${path}/:wid/checks`}
        component={MChecksDetail}
      />
      <Route
        exact={true}
        path={`${path}/workops/:wid/:wopsid`}
        component={MWorkopsDetail}
      />
      <Route
        exact={true}
        path={`${path}/issues/:wid/:iid`}
        component={MIssueDetail}
      />
      <Route
        exact={true}
        path={`${path}/add-tavlamote/:vid`}
        component={MAddTavlamote}
      />
      <Route
        exact={true}
        path={`${path}/add-workops/:wopsid/:vid`}
        component={MAddWorkops}
      />
      <Route
        exact={true}
        path={`${path}/add-issue/:vid`}
        component={MAddWorkops}
      />
      <Route
        exact={true}
        path={`${path}/add-global-issue/:parent/:pid`}
        component={MAddGlobalIssue}
      />
      <Route exact={true} path={`${path}/add-issue`} component={MAddIssue} />
      <Route
        exact={true}
        path={`${path}/check-in-out/:vid`}
        component={MCheckInOut}
      />
      <Route
        exact={true}
        path={`/meeting_series/:series_id/meetings/:meeting_id`}
        component={MMeetingDetailPage}
      />
      <Route
        exact={true}
        path={`/systematiccompletion/test-types/:testTypeId/tests/:testId`}
        component={MTestInviteAcceptance}
      />
      <Route
        render={(_routeProps) => {
          return <Redirect to={`${path}`} />
        }}
      />
    </Switch>
  )
}

export default MRouter
