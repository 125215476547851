import { Consumer, Subscription } from '@rails/actioncable'
import { DependencyList, useContext, useEffect } from 'react'
import getConsumer from '../cable'
import { ProjectContext } from '../context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from '../context/UserContextProvider/UserContext'

export interface ISocket<T> {
  channel: string
  topic: string
  deps: DependencyList
  received: (data: T) => void
}

const useSocket = <T,>({ channel, topic, received, deps }: ISocket<T>) => {
  const userContext: IUserContext = useContext(UserContext)
  const user = userContext.state.user
  const projectContext = useContext(ProjectContext)
  const project = projectContext.state.currentProject
  useEffect(() => {
    let subscription: Subscription<any> | undefined
    let consumer: Consumer | undefined
    const func = async () => {
      consumer = await getConsumer()
      subscription = consumer.subscriptions.create(
        {
          channel: channel,
          topic: topic,
          project_id: project.id,
          user_id: user.id,
        },
        {
          received,
        },
      )
    }
    func()
    return () => {
      subscription?.unsubscribe()
      consumer?.disconnect()
    }
  }, deps)
}

export default useSocket
