import { useRef, useState } from 'react'
import { TreeNodeInArray } from 'react-simple-tree-menu'
import Pagination from 'src/components/explorer/Pagination/Pagination'
import DocButton from 'src/document/components/base/DocButton'
import FileSelector from 'src/ui-elements/downshift/FileExplorer'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { classNames } from 'src/utility/utils'
import Loader from '../../ui-elements/loader/Loader'
import ResizableDiv from '../resizable-div/ResizableDiv'
import DropDownExplorerStorageHandler from './DropDownExplorerStorageHandler'

interface IDropDownExplorer {
  header?: {
    title: string
    button?: {
      onClick: () => void
      buttonText: string
    }
    buttonComponent?: JSX.Element
  }
  data: IFileDate[] | TreeNodeInArray[]
  onItemClick: (item: any) => void
  loading?: boolean
  className?: string
  initialActiveKey?: string
  activeKey?: string
  pagination?: boolean
  currentPage?: number
  changePage?: (newPage: number) => Promise<void>
  totalPages?: number
  initialOpenNodes?: string[]
  resetOpenNodesOnDataUpdate?: boolean
  onResize?: (size: number) => void
  keyName?: string
  headerClassName?: string
  defaultCollapsed?: boolean
  onToggleNode?: (keys: string[]) => void
}

export interface IFileDate {
  key: string
  path?: string
  label: string
  numbers: string
  [name: string]: any
  nodes?: IFileDate[]
  data_type?: string
  is_Active?: boolean
}

const DropDownExplorer = ({
  header,
  data,
  onItemClick,
  loading,
  className = '',
  initialActiveKey,
  pagination = false,
  currentPage = 0,
  totalPages,
  changePage,
  initialOpenNodes,
  resetOpenNodesOnDataUpdate = false,
  onResize,
  keyName = 'dropDownExplorer',
  headerClassName = '',
  defaultCollapsed = false,
  onToggleNode,
}: IDropDownExplorer) => {
  const styleClass = {
    root: () =>
      classNames(
        'flex',
        'flex-col',
        'bg-white',
        'border',
        'border-gray-300',
        'overflow-scroll',
        'h-full',
        className,
      ),
    header: (isCollapsed: boolean) =>
      classNames(
        'flex',
        'bg-white',
        'justify-between',
        headerClassName,
        isCollapsed ? 'border-none cursor-pointer' : 'border-b border-gray-300',
        isCollapsed ? 'mb-[-12px] py-[7px] pl-[3.5px] pr-[5px]' : 'px-4 py-3.5',
        !isCollapsed && 'min-h-[50px]',
      ),
    tree: () =>
      classNames('pt-3', 'flex-1', 'h-full', 'treeScroll', 'visible opacity-1'),
  }
  const key = keyName
  const keyTitle = `collapsed ${header?.title ?? ''}`
  const storedCollapsed = localStorage?.getItem(keyTitle)
  const initialCollapsed = storedCollapsed
    ? JSON.parse(storedCollapsed)
    : defaultCollapsed
  const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed)
  const [{ width }] = useState(() =>
    DropDownExplorerStorageHandler.readConfig(key),
  )
  const showPagination = !!(pagination && totalPages && changePage)

  const updateSize = (newWidth: number) => {
    DropDownExplorerStorageHandler.updateConfig(key, { width: newWidth })
    onResize?.(newWidth)
  }

  const treeContainerRef = useRef(null)

  const onToggleCollapse = () => {
    localStorage.setItem(keyTitle, JSON.stringify(!collapsed))
    setCollapsed((prev) => !prev)
  }
  if (collapsed)
    return (
      <div
        className={
          'w-[20px] h-[28px] border border-gray-300 bg-white rounded-sm flex items-center justify-center px-4 py-5 cursor-pointer mr-2'
        }
        onClick={onToggleCollapse}
      >
        <Icon
          icon={Icons.ARROW_RIGHT_BLUE}
          className={'w-3 h-4 flex-shrink-0 flex justify-center items-center'}
        />
      </div>
    )

  return (
    <ResizableDiv initialWidth={width} onNewWidth={updateSize}>
      <div className={styleClass.root()}>
        {header && (
          <div
            className={styleClass.header(collapsed)}
            onClick={collapsed ? onToggleCollapse : undefined}
          >
            <span
              className={`flex items-center text-sm font-medium leading-5 break-all`}
            >
              <p className={'w-full truncate'}>{header.title}</p>
            </span>

            <div className={'flex items-center gap-2'}>
              <Icon
                onClick={onToggleCollapse}
                icon={Icons.ARROW_LEFT_BLUE}
                className={
                  'w-3 h-3 cursor-pointer flex-shrink-0 flex justify-center items-center mr-2'
                }
              />
              {header.buttonComponent
                ? header.buttonComponent
                : header.button && (
                    <DocButton
                      onClick={header.button.onClick}
                      text={header.button.buttonText}
                      materialIcon={'add'}
                    />
                  )}
            </div>
          </div>
        )}

        <div
          className={styleClass.tree()}
          id="fileSelectorcontainer"
          ref={treeContainerRef}
        >
          {loading ? (
            <Loader centerLoader={false} />
          ) : (
            <FileSelector
              data={data}
              onItemClick={onItemClick}
              activeKey={initialActiveKey}
              containerRef={treeContainerRef}
              initialOpenNodes={initialOpenNodes}
              resetOpenNodesOnDataUpdate={resetOpenNodesOnDataUpdate}
              collapsed={collapsed}
              onToggleNode={onToggleNode}
            />
          )}
        </div>
        {showPagination && (
          <div className="h-auto border-t border-gray-300 py-2.5 w-full flex justify-center bg-white">
            <Pagination
              currentPage={currentPage}
              onPageUpdate={changePage}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
    </ResizableDiv>
  )
}

export default DropDownExplorer
