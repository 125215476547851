import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { deleteBulkSystemTypeFields } from 'src/service/SystemService'
import {
  deleteSystemTypeField,
  getProjectSystemTypeField,
} from 'src/service/SystemTypeFieldService'
import Button from 'src/ui-elements/button/Button'
import List from 'src/ui-elements/list/List'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import { ISorting } from 'src/ui-elements/list/ListTypes'
import Loader from 'src/ui-elements/loader/Loader'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import useAlert from 'src/ui-elements/toast/useAlert'
import { constructFilterJson, IActiveFilter } from 'src/utility/utils'
import { SystemTypeFieldListColumns } from './SystemTypeFieldListColumns'
import SystemTypeFieldForm, { IMetaField } from './SystemTypeFieldsForm'

interface ISystemTypeFieldsProps {
  projectId: number
}

const SystemTypeFieldsList = ({ projectId }: ISystemTypeFieldsProps) => {
  const { t } = useTranslation()
  const { showAlert } = useAlert()
  const pageSize = 30
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState(1)
  const [reload, setReload] = useState(false)
  const [systemFields, setSystemFields] = useState<IMetaField[]>([])
  const [selectedSystemField, setSelectedSystemField] = useState<IMetaField>()
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [selectedSystemTypeFieldIds, setSelectedSystemTypeFieldIds] = useState<
    number[]
  >([])

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    filterFieldsList(activeFilters, page)
  }

  const filterFieldsList = (activeFilters: IActiveFilter, page: number) => {
    setLoading(true)
    getProjectSystemTypeField(projectId, page, pageSize, activeFilters).then(
      (res) => {
        setSystemFields(res.user_defined_fields)
        setPages(res.total_pages)
      },
    )
    setLoading(false)
  }

  useEffect(() => {
    setReload((n) => !n)
  }, [projectId])

  const onEditItemClick = (systemGroup: IMetaField) => {
    setShowEditModal(true)
    setSelectedSystemField(systemGroup)
  }

  const onDeletItemClick = (systemGroup: IMetaField) => {
    setShowDeleteModal(true)
    setSelectedSystemField(systemGroup)
  }

  const closeEditModal = () => {
    setSelectedSystemField(undefined)
    setShowEditModal(false)
    setReload((n) => !n)
  }

  const closeDeleteModal = () => {
    setSelectedSystemField(undefined)
    setShowDeleteModal(false)
  }

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const closeCreateModal = () => {
    setShowCreateModal(false)
    setReload((n) => !n)
  }

  const deleteSystemGroup = async () => {
    if (selectedSystemField?.id) {
      await deleteSystemTypeField(Number(selectedSystemField?.id))
      setShowDeleteModal((n) => !n)
    }
  }

  const _getPatternValue = (v?: string) => {
    if (!v) return []
    if (v.split(';').length > 1) {
      return v.split(';')
    }
    return v
  }

  const deleteSelectedSystemFields = () => {
    if (selectedSystemTypeFieldIds?.length > 0) {
      deleteBulkSystemTypeFields(projectId, selectedSystemTypeFieldIds).then(
        () => {
          showAlert(
            'success',
            t('successfully_deleted'),
            t('selected_items_deleted', { items: t('milestones') }),
          )
          setReload((n) => !n)
          setSelectedSystemTypeFieldIds([])
        },
      )
    }
  }

  return (
    <div className={TabPageStyleClass.root}>
      {loading ? (
        <Loader centerLoader={true} />
      ) : (
        <List
          actionButton={
            <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
              {t('new_user_defined_field')}
            </Button>
          }
          data={systemFields}
          columns={SystemTypeFieldListColumns()}
          tableName={'SystemTypeFieldsList'}
          disableColumnSelector={true}
          isRowSelectable={true}
          onSelectRow={(data) => setSelectedSystemTypeFieldIds(data)}
          selectedRows={selectedSystemTypeFieldIds}
          onRowClick={onEditItemClick}
          bulkDelete={true}
          onBulkDelete={deleteSelectedSystemFields}
          itemsPerPage={pageSize}
          pagination={true}
          totalPages={pages}
          reload={reload}
          sortBackend={true}
          filterResetOption={true}
          getFilteredData={getFilteredData}
          actionMenu={[
            {
              name: t('delete'),
              action: onDeletItemClick,
            },
          ]}
        />
      )}

      {selectedSystemField && showEditModal && (
        <SystemTypeFieldForm
          open={showEditModal}
          editingMode={true}
          systemTypeFieldId={selectedSystemField.id}
          projectId={projectId}
          closeModal={closeEditModal}
        />
      )}

      {selectedSystemField && showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          onDelete={deleteSystemGroup}
          itemIdnType={`${selectedSystemField.name}`}
          itemName={`${selectedSystemField.name}`}
        />
      )}

      {showCreateModal && (
        <SystemTypeFieldForm
          open={showCreateModal}
          editingMode={false}
          projectId={projectId}
          closeModal={closeCreateModal}
        />
      )}
    </div>
  )
}

export default SystemTypeFieldsList
