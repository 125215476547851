export const actionImportTemplate = {
  name: 'actions_import_template',
  displayName: 'actions_import_template',
  columns: [
    'id',
    'title',
    'description',
    'task_type',
    'deadline',
    'responsible',
    'discipline',
    'object',
    'status',
  ],
  templateColumns: ['name', 'required', 'value', 'description', 'example'],
  template: [
    {
      name: 'record_id',
      required: false,
      value: 'text',
      description: 'record_id_of_task',
      example: 'A1931',
    },
    {
      name: 'title',
      required: true,
      value: 'text',
      description: 'title_of_task',
      example: 'title',
    },
    {
      name: 'description',
      required: false,
      value: 'text',
      description: 'description_of_task',
      example: 'description_of_task',
    },
    {
      name: 'task_type',
      required: true,
      value: 'text',
      description: 'task_type_desc',
      example: 'Avvik',
    },
    {
      name: 'deadline',
      required: true,
      value: 'date_mm_dd_yyyy',
      description: 'deadline_of_task',
      example: '09.11.2024',
    },
    {
      name: 'responsible',
      required: true,
      value: 'text',
      description: 'responsible_of_task',
      example: 'Ola Nordmann',
    },
    {
      name: 'discipline',
      required: true,
      value: 'text',
      description: 'discipline_of_task',
      example: 'RIV',
    },
    {
      name: 'object',
      required: false,
      value: 'text',
      description: 'reporter_of_task',
      example: '-A2',
    },
    {
      name: 'status',
      required: true,
      value: 'status_types',
      description: 'status_of_task',
      example: 'Åpen',
    },
  ],
}
