import { FC, Fragment, useContext, useEffect, useState } from 'react'
import SystemUploadSummary from 'src/components/system/SystemUploadSummary'
import ImportUploadWithVerification from 'src/components/upload-item/ImportWithValidation'
import { useJobId } from 'src/context/ImportContext/hook/useJobId'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import DataSelectors, {
  DATA_TYPES,
  ImportFiledTypes,
} from 'src/document/components/FileUpload/selectors/DataSelectors'
import {
  IFormatData,
  ISystemValidationResponse,
} from 'src/document/components/FileUpload/types'
import { objectImportTemplate } from 'src/export-templates/ObjectImportTemplate'
import usePull from 'src/hooks/usePull'
import { systemImportFromJson } from 'src/service/SystemService'
import { getSingleSystemSyntax } from 'src/service/SystemSyntaxService'
import { ImportContext } from '../../../context/ImportContext/ImportContext'
import { ImportStatus } from '../../../context/ImportContext/ImportTypes'
import { addUserDefinedInfoToImport } from '../../../utility/exportUtils'
import { IMPORT_TYPE } from '../../notifications/import/ImportNotificationItem'
import SyntaxObjectConverter from '../SystemSyntax/context/SyntaxObjectConverter'
import { mergeMultipleChanges } from '../SystemUtil'
import { IMetaField } from '../system-type-fields/SystemTypeFieldsForm'

interface ObjectImportsProps {
  show: boolean
  close: () => void
  useDefinedFiled?: IMetaField[]
  systemSettingId: number
  JobId?: number
}

const importColumns = [
  DataSelectors.defineDataField('record_id', DATA_TYPES.string),
  DataSelectors.defineDataField('name', DATA_TYPES.string),
  DataSelectors.defineDataField('description', DATA_TYPES.string),
  DataSelectors.getDataField(ImportFiledTypes.DISCIPLINES),
  DataSelectors.defineDataField('location', DATA_TYPES.string),
  DataSelectors.defineDataField('location_floor', DATA_TYPES.string),
  DataSelectors.defineDataField('location_room', DATA_TYPES.string),
  DataSelectors.defineDataField('coverage_floor', DATA_TYPES.string),
  DataSelectors.defineDataField('coverage_room', DATA_TYPES.string),
  DataSelectors.defineDataField('is_active', DATA_TYPES.boolean),
  DataSelectors.getDataField(ImportFiledTypes.SYSTEM_MMI),
  DataSelectors.getDataField(ImportFiledTypes.SYSTEM_FMI),
  DataSelectors.getDataField(ImportFiledTypes.SYSTEM_STATUSES),
  DataSelectors.getDataField(ImportFiledTypes.TEST_GROUP_ID),
]

const ObjectImports: FC<ObjectImportsProps> = ({
  show,
  close,
  useDefinedFiled,
  systemSettingId,
  JobId,
}) => {
  const [title, setTitle] = useState('upload_objects')
  const [jobId, setJobId] = useState<number | undefined>(JobId)
  const [currentStep, setCurrentStep] = useState<number | undefined>()
  const [pollingResponse, setPollingResponse] =
    useState<ISystemValidationResponse>()

  const { status, data } = useJobId(jobId)
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject

  const { addImportTask } = useContext(ImportContext).actions

  useEffect(() => {
    if (status && status !== ImportStatus.IN_PROGRESS) {
      setPollingResponse(data as ISystemValidationResponse)
      setCurrentStep(status === ImportStatus.SUCCESS ? 2 : 1)
    }
  }, [status, data])

  const formatResponseData = (responseData: any) => {
    const job = responseData.id
    setJobId(job)
    addImportTask(responseData)

    return responseData.job_data as ISystemValidationResponse
  }

  const validateMultiple = async (
    newValues: Record<string, string | string[] | null>[],
    validRes: ISystemValidationResponse,
  ) => {
    const systems = mergeMultipleChanges(newValues, validRes.systems ?? [])
    const res = await systemImportFromJson(projectId, systemSettingId, {
      systems: systems,
      job_id: jobId,
    })
    addImportTask(res)
    return res
  }

  const uploadObjectToRemote = async (systems: IFormatData[]) => {
    const res = await systemImportFromJson(projectId, systemSettingId, {
      systems: systems,
      job_id: jobId,
    })

    return addImportTask(res)
  }

  usePull(async () => {
    const syntax = await getSingleSystemSyntax(systemSettingId)
    if (!syntax) return
    if (syntax.has_no_syntax) {
      setTitle(syntax.name ?? '')
    } else {
      const code = SyntaxObjectConverter.getSyntaxCode(
        syntax.system_syntax_categories,
      )
      setTitle(syntax.name ? `${syntax.name} (${code})` : code)
    }
  }, [systemSettingId])

  if (status === ImportStatus.FAILED) {
    close()
    return <Fragment />
  }

  if (status === ImportStatus.IN_PROGRESS) {
    return <Fragment />
  }

  return (
    <ImportUploadWithVerification
      show={show}
      close={close}
      uploadUrl={`{ORG_URL}system_settings/${systemSettingId}/systems/validate_for_import_job`}
      importTemplate={addUserDefinedInfoToImport(
        objectImportTemplate,
        [],
        useDefinedFiled ?? [],
      )}
      useDefinedFiled={useDefinedFiled}
      formatResponseData={formatResponseData}
      validateMultiple={validateMultiple}
      parentId={projectId}
      title={title}
      type={'systems'}
      currentStep={currentStep}
      columns={importColumns}
      responseData={pollingResponse}
      uploadData={uploadObjectToRemote}
      confirmStep={(uploadRef) => {
        if (!uploadRef) return <span />
        return (
          <SystemUploadSummary
            importType={IMPORT_TYPE.SYSTEM_IMPORT}
            summary={uploadRef}
          />
        )
      }}
    />
  )
}

export default ObjectImports
