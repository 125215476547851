import { useState, PropsWithChildren } from 'react'
import DropdownMenu, {
  IDropDownItem,
} from 'src/document/components/DocumentButtonWithDropdown/DropdownMenu'
import Button, { IButtonProps } from 'src/ui-elements/button/Button'

interface ButtonWithDropdownMenuProps extends Omit<IButtonProps, 'onClick'> {
  items: IDropDownItem[]
  showOnLeft?: boolean
  customStyling?: boolean
}

const ButtonWithDropdownMenu = (
  props: PropsWithChildren<ButtonWithDropdownMenuProps>,
) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <div>
      <Button
        {...props}
        title={props.title}
        type={Button.ButtonType.SECONDARY}
        className={props.customStyling ? props.className : 'p-1 w-8 h-8'}
        onClick={(e) => {
          e.preventDefault()
          setMenuIsOpen(true)
        }}
      >
        {props.children}
      </Button>
      <DropdownMenu
        isOpen={menuIsOpen}
        onClose={() => setMenuIsOpen(false)}
        items={props.items}
        position={props.showOnLeft ? 'left' : 'right'}
      />
    </div>
  )
}

export default ButtonWithDropdownMenu
