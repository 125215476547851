import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageSelector from 'src/components/language-selector/LanguageSelector'

import { SplashScreenUtil } from 'src/page/splash/splashScreenStore'
import { Languages } from 'src/service/OrgTypes'
import {
  changePassword,
  forgotPassword,
  loginUser,
} from 'src/service/TheWallService'
import TokenService from 'src/service/TokenService'
import Button from 'src/ui-elements/button/Button'
import Icon from 'src/ui-elements/icon/Icon'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { capFirstLetter, hasEmptyString, validation } from 'src/utility/utils'

interface INewPasswordValidators {
  type: 'minLength' | 'specialCharacter' | 'hasNumber' | 'match'
  message: string
  text_value: string
  active: boolean
}

const Login = () => {
  const { t, i18n } = useTranslation()
  const resetEmail = new URLSearchParams(location.search).get('email')
  const resetPassword = new URLSearchParams(location.search).get('password')
  const initialValidators: INewPasswordValidators[] = [
    {
      type: 'minLength',
      text_value: 'the_password_must_contain_at_least_8_characters',
      message: 'the_password_must_contain_at_least_8_characters',
      active: true,
    },
    {
      type: 'specialCharacter',
      text_value: 'the_password_must_contain_at_least_one_special_character',
      message: 'the_password_must_contain_at_least_one_special_character',
      active: true,
    },
    {
      type: 'hasNumber',
      text_value: 'the_password_must_contain_at_least_one_number',
      message: 'the_password_must_contain_at_least_one_number',
      active: true,
    },
    {
      type: 'match',
      text_value: 'password_does_not_match',
      message: 'password_does_not_match',
      active: true,
    },
  ]

  const splashScreenUtil = SplashScreenUtil()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [resetPasswordToken, setResetPasswordToken] = useState<string>('')
  const [newPasswordErrors, setNewPasswordErrors] = useState<
    INewPasswordValidators[]
  >([])
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false)
  const [initialPasswordReset, setInitialPasswordReset] =
    useState<boolean>(false)
  const [selectedLanguage, setSelectedLanguage] = useState<Languages>('no')
  const { addAlert } = useAlert()
  useEffect(() => {
    splashScreenUtil.removeSplashScreen()
    const err =
      newPasswordErrors.length > 0 ? newPasswordErrors : initialValidators
    setNewPasswordErrors(
      err.map((validator) => ({
        ...validator,
        message: t(validator.text_value),
      })),
    )
    if (resetEmail && resetPassword) {
      const user = {
        email: resetEmail,
        password: resetPassword,
      }
      loginUser(user)
        .then((res) => {
          if (res.statusCode === 403) {
            setInitialPasswordReset(true)
            setResetPasswordToken(res.body.message.token)
          } else if (res.statusCode !== 200) {
            setIsLoading(false)
            showAlert('error', t('something_went_wrong'), res.body.message.text)
          } else {
            setEmail('')
            setPassword('')
            TokenService.setRefreshToken = res.body
            window.location.reload()
          }
          setIsLoading(false)
        })
        .catch((err) => {
          if (err.body) {
            setIsLoading(false)
            showAlert('error', t('an_error_occurred'), err.body.message.text)
          } else {
            setIsLoading(false)
            showAlert(
              'error',
              t('an_error_occurred'),
              t('no_internet_connection_second'),
            )
          }
        })
    }
  }, [i18n.language, resetEmail, resetPassword])

  const showAlert = (type: IAlertType, title: string, description: string) => {
    addAlert({ type, title, description, autoClose: true })
  }

  const onLogin = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const user = {
      email,
      password,
    }

    if (user.email === '') {
      setIsLoading(false)
      showAlert(
        'error',
        t('an_error_occurred'),
        t('you_must_enter_an_email_address'),
      )
      return
    }

    if (user.password === '') {
      setIsLoading(false)
      showAlert('error', t('an_error_occurred'), t('you_must_enter_a_password'))
      return
    }

    loginUser(user)
      .then((res) => {
        if (res.statusCode === 403) {
          setInitialPasswordReset(true)
          setResetPasswordToken(res.body.message.token)
        } else if (res.statusCode !== 200) {
          setIsLoading(false)
          showAlert('error', t('something_went_wrong'), res.body.message.text)
        } else {
          setEmail('')
          setPassword('')
          TokenService.setRefreshToken = res.body
          window.location.reload()
        }
        setIsLoading(false)
      })
      .catch((err) => {
        if (err.body) {
          setIsLoading(false)
          showAlert('error', t('an_error_occurred'), err.body.message.text)
        } else {
          setIsLoading(false)
          showAlert(
            'error',
            t('an_error_occurred'),
            t('no_internet_connection_second'),
          )
        }
      })
  }

  const onChangePassword = (e: any) => {
    e.preventDefault()
    TokenService.setRefreshToken = resetPasswordToken
    changePassword(resetPassword ?? password, newPassword)
      .then((response) => response.json())
      .then((res) => {
        if (res.statusCode !== 200) {
          setIsLoading(false)
        } else {
          setIsLoading(false)
          showAlert(
            'success',
            t('success'),
            t('your_new_password_has_been_saved'),
          )
          setTimeout(() => {
            window.location.href = window.location.origin
          }, 2000)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        showAlert('error', t('an_error_occurred'), err.body.message.text)
      })
  }

  const onForgotPassword = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setIsLoading(true)
    forgotPassword(email, selectedLanguage)
      .then(() => {
        setIsLoading(false)
        showAlert(
          'success',
          t('success'),
          t(
            'we_have_sent_you_an_email_with_information_about_your_new_password',
          ),
        )
      })
      .catch((err) => {
        setIsLoading(false)
        showAlert('error', t('an_error_occurred'), err.body.message.text)
      })
  }

  const onEmailChange = (e: any) => {
    const value = e.target.value
    setEmail(value)
  }

  const onPasswordChange = (e: any) => {
    const value = e.target.value
    setPassword(value)
  }

  const onNewPasswordChange = (e: any) => {
    const value = e.target.value
    setNewPassword(value)
    setNewPasswordErrors((prevState) => {
      const updatedErrors = [...prevState]
      updatedErrors.map((item) => {
        if (item.type === 'minLength') {
          item.active = !(value.length >= 8)
        }
        if (item.type === 'hasNumber') {
          item.active = !validation.hasNumber(value)
        }
        if (item.type === 'specialCharacter') {
          item.active = !validation.hasSpecialCharacter(value)
        }
        if (item.type === 'match') {
          item.active = !(
            confirmPassword === value &&
            !hasEmptyString([confirmPassword, value])
          )
        }
      })
      return updatedErrors
    })
  }

  const onConfirmPasswordChange = (e: any) => {
    const value = e.target.value
    setConfirmPassword(value)
    setNewPasswordErrors((prevState) => {
      const updatedErrors = [...prevState]
      updatedErrors.map((item) => {
        if (item.type === 'match') {
          item.active = !(
            newPassword === value && !hasEmptyString([newPassword, value])
          )
        }
      })
      return updatedErrors
    })
  }

  const toggleForgotPassword = () => {
    setShowForgotPassword((prevState) => !prevState)
    clean()
  }

  const clean = () => {
    setPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  const changeLanguage = (val: any) => {
    if (val) {
      i18n.changeLanguage(val).then(() => {
        setSelectedLanguage(val)
      })
    }
  }

  return (
    <div
      style={{ backgroundImage: `url(/login-0.jpg)` }}
      className={'pg__bg-center h-screen flex justify-center items-center'}
    >
      <div
        className={
          'w-5/6 md:w-1/2 xl:w-1/4 bg-white md:p-6 py-6 rounded-lg opacity-90 max-w-sm'
        }
      >
        <div className={'w-full flex justify-between items-center'}>
          <div className={'text-center text-gray-800 text-lg font-bold pl-2'}>
            <h3 className="text-left">
              {initialPasswordReset
                ? t('create_new_password')
                : showForgotPassword
                  ? t('forgot_your_password')
                  : t('login')}
            </h3>
          </div>

          <LanguageSelector onLanguageSelected={changeLanguage} />
        </div>
        {!initialPasswordReset && showForgotPassword && (
          <p className={'mt-2 p-2 text-left text-sm font-medium text-gray-500'}>
            {t(
              'enter_the_e_mail_address_you_registered_with_and_we_will_send_you_a_new_password',
            )}
          </p>
        )}

        <form>
          <Input
            block={true}
            label={t('email')}
            onChange={onEmailChange}
            value={email}
            type={initialPasswordReset ? 'hidden' : 'text'}
            hidden={initialPasswordReset}
            hideLabel={initialPasswordReset}
            autoComplete={'username'}
          />

          {initialPasswordReset ? (
            <Fragment>
              <div className={'text-gray-500 text-xs my-2 pl-2'}>
                {newPasswordErrors &&
                  newPasswordErrors.map((err, key) => (
                    <div key={key} className={'flex items-center mb-1'}>
                      <Icon
                        className={'w-4 h-4 flex items-center'}
                        icon={
                          err.active
                            ? Icon.IconType.ERROR_RED
                            : Icon.IconType.CHECK_GREEN
                        }
                      />
                      <span className={'pl-1'}>{err.message}</span>
                    </div>
                  ))}
              </div>
              <Input
                block={true}
                label={t('password')}
                type={'password'}
                onChange={onNewPasswordChange}
                value={newPassword}
                autoComplete={'new-password'}
              />
              <Input
                block={true}
                label={t('confirm_password')}
                type={'password'}
                onChange={onConfirmPasswordChange}
                value={confirmPassword}
                autoComplete={'new-password'}
              />
              <div className={'mt-4'}>
                <Button
                  onClick={onChangePassword}
                  disabled={
                    isLoading ||
                    newPasswordErrors.filter((item) => item.active).length > 0
                  }
                  loading={isLoading}
                >
                  {capFirstLetter(t('confirm'))}
                </Button>
              </div>
            </Fragment>
          ) : !showForgotPassword ? (
            <Fragment>
              <Input
                block={true}
                label={t('password')}
                type={'password'}
                onChange={onPasswordChange}
                value={password}
                autoComplete={'current-password'}
              />
              <div className={'mt-4'}>
                <Button
                  type={Button.ButtonType.PRIMARY}
                  onClick={onLogin}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : capFirstLetter(t('login'))}
                </Button>
                <span
                  className={'pl-2 text-gray-600 cursor-pointer underline'}
                  onClick={toggleForgotPassword}
                >
                  {t('forgot_your_password')}
                </span>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={'mt-4'}>
                <Button
                  type={Button.ButtonType.PRIMARY}
                  onClick={onForgotPassword}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : t('get_a_new_password')}
                </Button>
                <span
                  className={'pl-2 font-gray-600 cursor-pointer underline'}
                  onClick={toggleForgotPassword}
                >
                  {capFirstLetter(t('login'))}
                </span>
              </div>
            </Fragment>
          )}
        </form>
      </div>
    </div>
  )
}

export default Login
