import { TFunction } from 'i18next'
import { PureComponent, MouseEvent } from 'react'
import { withTranslation } from 'react-i18next'
import { changeReasonList } from 'src/utility/utils'
import Button from '../../../ui-elements/button/Button'
import Modal from '../../../ui-elements/modal/Modal'
import Selector from '../../selectors/Selector'

interface IKeypointChangeLogModalProps {
  show: boolean
  toggleChangeLogModal: () => void
  onChangeLogSubmit: (e: MouseEvent<HTMLButtonElement>) => void
  onChangeReasonLog: (value: string) => void
  t: TFunction<'translation', undefined>
}

interface IKeypointChangeLogModalState {
  reason: string
  reasonErrorMessage: string
}

class KeypointChangeLogModal extends PureComponent<
  IKeypointChangeLogModalProps,
  IKeypointChangeLogModalState
> {
  public constructor(props: IKeypointChangeLogModalProps) {
    super(props)
    this.state = {
      reason: '',
      reasonErrorMessage: '',
    }
  }

  public componentDidUpdate(prevProps: IKeypointChangeLogModalProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        reason: '',
        reasonErrorMessage: '',
      })
    }
  }

  private onReasonChange = (value: string) => {
    this.setState({
      reason: value,
      reasonErrorMessage: '',
    })
    this.props.onChangeReasonLog(value)
  }

  private submitChangeReason = (e: MouseEvent<HTMLButtonElement>) => {
    const { t } = this.props
    let error = false
    e.preventDefault()
    const { reason } = this.state

    if (!reason) {
      this.setState({
        reasonErrorMessage: t('fill_out_w_param', {
          param: t('reason_for_relocation'),
        }),
      })

      error = true
    }

    if (!error) {
      this.setState({
        reasonErrorMessage: '',
      })
      this.props.onChangeLogSubmit(e)
    }
  }

  public render() {
    const { show, toggleChangeLogModal, t } = this.props
    const { reason, reasonErrorMessage } = this.state
    return (
      <Modal
        show={show}
        title={t('reason_for_relocation')}
        closeModal={toggleChangeLogModal}
        size={'w-1/3'}
        maxWidth={600}
        notClipContent
      >
        <form
          className={'text-left flex flex-col m-2'}
          style={{ minHeight: '100px' }}
        >
          <div className={'pr-4'}>
            <Selector
              items={changeReasonList.map(
                (item: { id: string; name: string }) => ({
                  ...item,
                  name: t(item.id),
                }),
              )}
              selectedItemId={reason}
              onSelect={this.onReasonChange}
              label={t('reason_for_relocation')}
              dataFields={['name']}
              required={true}
              fontSize={'sm'}
              userSelector={false}
              fontWeight={'bold'}
              errorMessage={reasonErrorMessage}
            />
          </div>
          <div style={{ marginLeft: '-2px' }} className={'mt-2'}>
            <Button
              type={Button.ButtonType.PRIMARY}
              onClick={this.submitChangeReason}
            >
              {t('save')}
            </Button>
          </div>
        </form>
      </Modal>
    )
  }
}

export default withTranslation()(KeypointChangeLogModal)
