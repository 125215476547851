import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  ITabContext,
  TabContext,
} from 'src/context/TabContextProvider/TabContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { IRoom } from 'src/service/OrgTypes'
import { getRoom } from 'src/service/RoomService'
import Badge from 'src/ui-elements/badge/Badge'
import Icon from 'src/ui-elements/icon/Icon'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { capFirstLetter } from '../../utility/utils'
import { contentStyle, FullPageLoader } from '../MUtils'
import MCard from '../components/MCard'
import MChips from '../components/MChips'
import MContent from '../components/MContent'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MTabs, { IMTabItem } from '../components/MTabs'
import MCustomIssues, { CustomIssueTypes } from './MCustomIssues'

interface ILocation {
  fromHome: boolean
}

const MRoom = (_props: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userContext: IUserContext = useContext(UserContext)
  const { user } = userContext.state
  const { rid } = useParams<{ rid: string }>()
  const location = useLocation<ILocation>()
  const [loading, setLoading] = useState<boolean>(false)
  const [roomRes, setRoomRes] = useState<IRoom>({} as IRoom)
  const [roomData, setRoomData] = useState<any>({})
  const [floor, setFloor] = useState<any>({})
  const [section, setSection] = useState<any>({})
  const [showBackBtn, setShowBackBtn] = useState<boolean>(true)
  const tabContext: ITabContext = useContext(TabContext)
  const { tabIndex } = tabContext.state
  const { setCurrentIndex } = tabContext.actions

  useEffect(() => {
    fetchRoom(rid)
    const showBtn = location?.state?.fromHome ? false : true
    setShowBackBtn(showBtn)
  }, [rid])

  const fetchRoom = async (id: string) => {
    if (id && +id > 0) {
      setLoading(true)
      const room = await getRoom(+id)
      setRoomRes(room)
      const roomDataArr = [
        { id: room.id },
        { left: t('control_area'), right: `${room?.control_area?.title}` },
        { left: t('room'), right: `${room.room_name}` },
        { left: t('function'), right: `${room.function_name}` },
        { left: t('function') + ' nr', right: `${room.function_number}` },
        { left: t('main_function'), right: `${room.main_function_name}` },
        { left: t('part_function'), right: `${room.part_function_name}` },
        { left: t('sub_function'), right: `${room.sub_function_name}` },
        {
          left: t('sub_function') + ' nr',
          right: `${room.sub_function_number}`,
        },
        { left: t('area'), right: `${room.built_area}` },
      ]
      setRoomData(roomDataArr)

      if (room.floor) {
        const floorData = [
          { id: room.id },
          { left: t('name'), right: `${room.floor.name}` },
          { left: t('number'), right: `${room.floor.floor_number}` },
        ]
        setFloor(floorData)
      }
      if (room.section) {
        const sectionData = [
          { id: room.id },
          { left: t('name'), right: `${room.section.name}` },
          { left: t('number'), right: `${room.section.section_number}` },
        ]
        setSection(sectionData)
      }
      setLoading(false)
    }
  }

  const NoDataErrorMessage = (type: string): JSX.Element => (
    <div
      className={
        'shadow-sm p-3 rounded-xl bg-m-warning-yellow text-sm text-gray-600 flex font-light'
      }
    >
      <Icon icon={Icon.IconType.WARNING_YELLOW} className={'w-4 h-4 mr-3'} />
      <span className={'flex'}>
        <span className={'font-semibold'}>
          &nbsp;{t('no_data_found')}&nbsp;
        </span>{' '}
        {t('for')} {type}
      </span>
    </div>
  )

  const getHeader = (): JSX.Element => {
    return (
      <div className={'flex justify-end mb-2'}>
        <MChips text={t('room')} color={'green'} />
      </div>
    )
  }

  const RoomDetail = () =>
    loading ? (
      <FullPageLoader />
    ) : (
      <MContent>
        <>
          {!isEmpty(roomRes) ? (
            <div className={'p-6 pt-4'}>
              <p className={'text-gray-600 text-sm mb-1'}>
                {capFirstLetter(t('section'))}
              </p>
              {!isEmpty(section) ? (
                <MCard data={section} />
              ) : (
                NoDataErrorMessage(capFirstLetter(t('section')))
              )}
              <p className={'text-gray-600 text-sm pt-3 mb-1'}>
                {capFirstLetter(t('floor'))}
              </p>
              {!isEmpty(floor) ? (
                <MCard data={floor} />
              ) : (
                NoDataErrorMessage(capFirstLetter(t('floor')))
              )}
              {roomData ? (
                <>
                  <p className={'text-gray-600 text-sm pt-3 mb-1'}>
                    {t('room')}
                  </p>
                  <MCard data={roomData} header={getHeader()} />
                </>
              ) : null}
            </div>
          ) : (
            <div className={'flex justify-center m-8'}>
              <Badge
                text={t('no_results_found')}
                size={Badge.BadgeSize.LARGE}
                color={Badge.BadgeColor.TEAL}
              />
            </div>
          )}
        </>
      </MContent>
    )

  const tabItems: IMTabItem[] = [
    {
      name: capFirstLetter(t('room')),
      content: <RoomDetail />,
      icon: 'ROOM_GRAY',
      activeIcon: 'ROOM_BLUE',
      disabledIcon: 'ROOM',
    },
    {
      name: t('cases_native'),
      content: (
        <MCustomIssues parentId={+rid} parentType={CustomIssueTypes.Room} />
      ),
      icon: 'ISSUE',
      activeIcon: 'ISSUE_ACTIVE',
      disabledIcon: 'ISSUE_DISABLED',
    },
  ]

  return (
    <MPage>
      <>
        <MHeader
          onBackClick={showBackBtn ? () => history.goBack() : undefined}
          title={capFirstLetter(t('room_details'))}
          user={user}
        />
        <div
          style={tabIndex >= 0 ? contentStyle : {}}
          className={`w-full ${tabIndex >= 0 ? 'absolute bottom-0 z-20' : ''}`}
        >
          <MTabs
            tabIndex={tabIndex}
            tabItems={tabItems}
            setTabIndex={(index) => setCurrentIndex(index)}
          />
        </div>
      </>
    </MPage>
  )
}

export default MRoom
