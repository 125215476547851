import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const ncsConfig: IEnvironmentConfig = {
  host: "ncs.bygg.io",
  exportBaseUrl: "https://export-ncs.bygg.io/",
  frontendBaseUrl: "https://ncs.bygg.io/",
  organizationBaseUrl: "https://org-ncs.bygg.io/",
  proxyBaseUrl: "https://proxy-ncs.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-ncs.bygg.io/api/",
  fdvuBaseUrl: "not set yet",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "not yet set"
}
