import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Board from 'react-trello'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import useDraftMode from 'src/hooks/useDraftMode'
import { IConstructionTask, IConstructionWagon } from 'src/service/OrgTypes'
import {
  deleteConstructionTask,
  editConstructionTask,
} from '../../../service/ConstructionTaskService'
import {
  deleteConstructionWagon,
  getConstructionTrainWagons,
} from '../../../service/ConstructionWagonService'
import { closedStatusTypes, statusTypes } from '../../../service/SystemValues'
import Badge from '../../../ui-elements/badge/Badge'
import Button from '../../../ui-elements/button/Button'
import { ButtonType } from '../../../ui-elements/button/ButtonEnums'
import CustomCard from '../../ConstructionCanvas/CustomCard'
import CustomLaneHeader from '../../ConstructionCanvas/CustomLaneHeader'
import DeleteModal from '../../delete-modal/DeleteModal'
import ConstructionTaskForm from '../tasks/ConstructionTaskForm'
import WagonForm from '../wagon/WagonForm'
import WagonModal from '../wagon/WagonModal'

interface ITrainBoardProps {
  trainId: number
  projectId: number
  reloadParent?: () => void
}

const TrainBoard = ({ projectId, trainId, reloadParent }: ITrainBoardProps) => {
  const { t, i18n } = useTranslation()
  const [locomotives, setLocomotives] = useState<IConstructionWagon[]>([])
  const [constructionTasks, setConstructionTasks] = useState<
    IConstructionTask[]
  >([])
  const [boardData, setBoardData] = useState<any>({ lanes: [] })
  const [constructionTaskModal, setConstructionTaskModal] = useState(false)
  const [constructionTaskDeleteModal, setConstructionTaskDeleteModal] =
    useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedConstructionWagonId, setSelectedConstructionWagonId] =
    useState(0)
  const [selectedConstructionTask, setSelectedConstructionTask] =
    useState<any>(undefined)
  const [selectedWagon, setSelectedWagon] = useState<IConstructionWagon>()
  const [wagonDeleteModal, setWagonDeleteModal] = useState(false)
  const [showEditWagonModal, setShowEditWagonModal] = useState(false)
  const [showCreateWagonModal, setShowCreateWagonModal] = useState(false)
  const projectContext = useContext(ProjectContext)
  const { isConstructionManager } = projectContext.state

  const { turnDraftModeOn, draftMode } = useDraftMode()

  useEffect(() => {
    createTrainCards(locomotives)
  }, [locomotives, i18n.language])

  const constrictionTasks = (
    locs: IConstructionWagon[],
  ): IConstructionTask[] => {
    const ret: IConstructionTask[] = []
    locs.map((loc) => {
      ret.push(...(loc.construction_tasks ? loc.construction_tasks : []))
    })
    return ret
  }

  const reload = useCallback(() => {
    getConstructionTrainWagons(trainId, draftMode).then((res) => {
      setLocomotives(res)
      setConstructionTasks(constrictionTasks(res))
    })
    reloadParent?.()
  }, [reloadParent, trainId, draftMode])

  useEffect(() => {
    reload()
  }, [reload])

  const createTrainCards = (wagons: any) => {
    const wagonCards = wagons.map((wagon: any) => {
      const tasks = wagon.construction_tasks.map((task: any) => {
        return {
          id: `${task.id}`,
          laneId: `${wagon.id}`,
          wagon,
          title: task.title,
          editable: true,
          description: t('deadline_with_date', {
            date: moment(task.deadline).format('L'),
          }),
          draggable: true,
          cardStyle: { backgroundColor: 'blue' },
          style: {
            maxWidth: 180,
            minWidth: 180,
            paddingTop: '5px',
            paddingBottom: '5px',
          },
          tags: [
            {
              bgcolor: closedStatusTypes.some((x) => x === task.status)
                ? Badge.BadgeColor.GREEN
                : moment(task.deadline).isBefore(moment(), 'day')
                  ? Badge.BadgeColor.RED
                  : Badge.BadgeColor.BLUE,
              color: 'white',
              title: statusTypes(t)
                .filter((st: any) => st.id === task.status)
                .map((st: any) => st.name)
                .pop(),
            },
          ],
        }
      })

      const doneTasks = wagon.construction_tasks.filter(
        (task: any) => task.status === 'done',
      )

      return {
        id: `${wagon.id}`,
        title: t('wagon_title', { title: wagon.title }),
        label: `${doneTasks.length}/${tasks.length}`,
        addCardLink: (
          <span
            key={`${wagon.id} ${t('add_more_task')}`}
            className="add_card"
            onClick={(e: any) => onCreateTask(e, wagon.id)}
          >
            {t('new_activity')}
          </span>
        ),
        editable: true,
        cards: tasks,
        style: { maxWidth: 200, minWidth: 200 },
      }
    })

    setBoardData({ lanes: wagonCards })
  }

  const openEditModal = (cardId: string, metadata: any, laneId: string) => {
    if (!isConstructionManager) return
    const locomotive = locomotives.find((i) => `${i.id}` === laneId)
    const task =
      locomotive && locomotive.construction_tasks
        ? locomotive.construction_tasks.find((i) => `${i.id}` === cardId)
        : undefined
    if (task) {
      setSelectedConstructionWagonId(locomotive?.id || 0)
      setSelectedConstructionTask(task)
      setConstructionTaskModal(true)
      setIsEditing(true)
    }
  }

  const openDeleteModal = (cardId: string, laneId: string) => {
    if (!isConstructionManager) return
    const locomotive = locomotives.find((i) => `${i.id}` === laneId)
    const task =
      locomotive && locomotive.construction_tasks
        ? locomotive.construction_tasks.find((i) => `${i.id}` === cardId)
        : undefined
    if (task) {
      setSelectedConstructionTask(task)
      setConstructionTaskDeleteModal(true)
    }
  }

  const onCardDelete = async (task: IConstructionTask) => {
    await deleteConstructionTask(task)
    setConstructionTaskDeleteModal(false)
    reload()
  }

  const closeEditModal = () => {
    setConstructionTaskModal(false)
    setSelectedConstructionTask(undefined)
    setSelectedConstructionWagonId(0)
    reload()
  }

  const onCardDrop = (
    cardId: number,
    sourceLaneId: number,
    targetLaneId: number,
  ) => {
    editConstructionTask({
      id: cardId,
      construction_locomotive_id: targetLaneId,
    } as IConstructionTask).then(() => {
      reload()
    })
  }

  const onCreateTask = (e: any, wagonId: number) => {
    e.preventDefault()
    e.stopPropagation()
    setConstructionTaskModal(true)
    setIsEditing(false)
    setSelectedConstructionWagonId(wagonId)
  }

  const toggleDelete = (wagon: IConstructionWagon) => {
    if (!isConstructionManager) return
    turnDraftModeOn()
    setSelectedWagon(wagon)
    setWagonDeleteModal(true)
  }

  const onLaneDelete = async (wagon: IConstructionWagon) => {
    await deleteConstructionWagon(wagon)
    setWagonDeleteModal(false)
    setSelectedWagon(undefined)
    reload()
  }

  const openEditWagonModal = (wagon: any) => {
    setSelectedWagon(wagon)
    setShowEditWagonModal(true)
  }

  return (
    <div className="h-full">
      <div className="flex h-full">
        <div className="h-full min-w-fit trainBoard" id="train-board">
          {isConstructionManager && (
            <Button
              type={ButtonType.PRIMARY}
              onClick={() => {
                setShowCreateWagonModal(true)
                turnDraftModeOn()
              }}
            >
              {t('new_wagon')}
            </Button>
          )}
          <Board
            style={{ backgroundColor: 'transparent', height: 'auto' }}
            components={{
              NewCardForm: ConstructionTaskForm,
              NewLaneForm: WagonForm,
            }}
            onCardClick={openEditModal}
            onCardDelete={openDeleteModal}
            laneDraggable={false}
            canAddLanes={false}
            draggable={isConstructionManager}
            editable={isConstructionManager}
            hideCardDeleteIcon={!isConstructionManager}
            handleDragEnd={onCardDrop}
            data={boardData}
            customLaneHeader={
              <CustomLaneHeader
                onWagonDelete={toggleDelete}
                locomotives={locomotives}
                openWagonModal={openEditWagonModal}
              />
            }
            customCardLayout={true}
          >
            <CustomCard tasks={constructionTasks} />
          </Board>
        </div>
      </div>

      <div className={showEditWagonModal ? 'h-[380px]' : ''}>
        {selectedWagon && showEditWagonModal && (
          <WagonModal
            className={'h-[380px]'}
            open={showEditWagonModal}
            onClose={() => {
              setShowEditWagonModal(false)
              setSelectedWagon(undefined)
              reload()
            }}
            onUpdate={reload}
            defaultIndex={0}
            wagonId={selectedWagon.id ? selectedWagon.id : 0}
            projectId={projectId}
          />
        )}
      </div>

      {selectedConstructionWagonId > 0 && (
        <ConstructionTaskForm
          open={constructionTaskModal}
          isEditing={isEditing}
          constructionTask={isEditing && selectedConstructionTask}
          constructionWagonIdProps={selectedConstructionWagonId}
          closeModal={closeEditModal}
          updateList={reload}
        />
      )}

      {selectedConstructionTask && (
        <DeleteModal
          show={constructionTaskDeleteModal}
          closeModal={() => {
            setConstructionTaskDeleteModal(false)
            reload()
          }}
          onDelete={() => onCardDelete(selectedConstructionTask)}
          itemIdnType={`${selectedConstructionTask.record_id} (${t(
            'activity',
          )})`}
          itemName={`${selectedConstructionTask.record_id} - ${selectedConstructionTask.title}`}
        />
      )}

      {selectedWagon && (
        <DeleteModal
          show={wagonDeleteModal}
          closeModal={() => {
            setWagonDeleteModal(false)
            reload()
          }}
          onDelete={() => onLaneDelete(selectedWagon)}
          itemIdnType={`${selectedWagon.title} (${t('wagon')})`}
          itemName={`${selectedWagon.title}`}
        />
      )}

      {showCreateWagonModal && (
        <WagonForm
          open={showCreateWagonModal}
          parentId={trainId}
          reloadParent={reload}
          closeModal={() => {
            setShowCreateWagonModal(false)
          }}
        />
      )}
    </div>
  )
}

export default TrainBoard
