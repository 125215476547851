import { request } from './service-utils'

export const breadCrumbRequest = (
  projectId: number,
  url: string,
): Promise<any> => {
  return request(`/projects/${projectId}/breadcrumb`, 'POST', {
    url_string: url,
  })
}
