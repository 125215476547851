import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import {
  useCreateConstructionTaskType,
  useEditConstructionTaskType,
} from 'src/query/construction/queries'
import Spinner from 'src/ui-elements/loader/Spinner'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import {
  IWithProjectContext,
  withProjectContext,
} from '../../../context/withProjectContext'
import { getProjectDisciplines } from '../../../service/DisciplineService'
import { IConstructionTaskType, IDiscipline } from '../../../service/OrgTypes'
import { taskUnits } from '../../../service/SystemValues'
import Button from '../../../ui-elements/button/Button'
import Input from '../../../ui-elements/input/Input'
import Modal from '../../../ui-elements/modal/Modal'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import { toFixed } from '../../../utility/Utility'
import { classNames } from '../../../utility/utils'
import Selector from '../../selectors/Selector'

interface IConstructionTaskTypeTypeForm extends IWithProjectContext {
  open: boolean
  closeModal: () => void
  updateList: () => void
  isEditing: boolean
  parentId?: number
  constructionTaskType?: IConstructionTaskType
  defaultDisciplineId?: number
}

const ConstructionTaskTypeForm = ({
  open,
  closeModal,
  updateList: _updateList,
  projectContext,
  parentId,
  isEditing,
  constructionTaskType,
  defaultDisciplineId,
}: IConstructionTaskTypeTypeForm) => {
  const styleClass = {
    root: classNames('flex', 'flex-col'),
    inputGroup: classNames('flex', 'flex-row', 'w-full'),
    label: classNames(
      'text-sm',
      'font-medium',
      'text-gray-700',
      'first-capitalize',
    ),
  }
  const editConstructionTaskType = useEditConstructionTaskType()
  const createConstructionTaskType = useCreateConstructionTaskType()

  useEffect(() => {
    if (parentId) {
      setWagonTypeId(parentId)
    }
    setProjectId(projectContext.state.currentProject.id)

    getProjectDisciplines(projectContext.state.currentProject.id).then(
      (res) => {
        setDisciplines(res)
      },
    )

    if (isEditing && constructionTaskType) {
      setTitle(constructionTaskType.title)
      setWagonTypeId(
        constructionTaskType.locomotive_type_id
          ? constructionTaskType.locomotive_type_id
          : -1,
      )
      setDisciplineId(constructionTaskType.discipline_id)
      setUnit(constructionTaskType.unit)
      setHoursPerUnit(constructionTaskType.hours_per_unit)
      setTotalUnits(constructionTaskType.total_units)
      setNumberOfWorkers(constructionTaskType.number_of_workers)
      setPlannedExecutionHours(constructionTaskType.planned_execution_hours)
      setProductivity(constructionTaskType.productivity)
      setTotalWorkHour(constructionTaskType.total_work_hours ?? 0)
    }
  }, [projectContext])

  const { t } = useTranslation()
  const [title, setTitle] = useState<string>('')
  const [wagonTypeId, setWagonTypeId] = useState<number>(0)
  const [projectId, setProjectId] = useState<number>(0)
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [disciplineId, setDisciplineId] = useState<number>(
    defaultDisciplineId ?? 0,
  )
  const [isEstimate, setIsEstimate] = useState<boolean>(isEditing)
  const [unit, setUnit] = useState<string>('')
  const [hoursPerUnit, setHoursPerUnit] = useState<number>(0)
  const [totalUnits, setTotalUnits] = useState<number>(0)
  const [numberOfWorkers, setNumberOfWorkers] = useState<number>(0)
  const [plannedExecutionHours, setPlannedExecutionHours] = useState<number>(0)
  const [productivity, setProductivity] = useState<number>(1.0)
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [productivityError, setProductivityError] = useState<string>('')
  const [workHourErrorMessage, setWorkHourErrorMessage] = useState<string>('')
  const [totalWorkHour, setTotalWorkHour] = useState<number>(0)

  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const { addAlert } = useAlert()

  const calculatedWorkHourRef = useRef<number>(0)

  useEffect(() => {
    const hours =
      hoursPerUnit > 0 && productivity > 0
        ? (hoursPerUnit * totalUnits) / productivity
        : 0.0
    calculatedWorkHourRef.current = hours
    if (hours > 0 && toFixed(hours) !== toFixed(totalWorkHour)) {
      setWorkHourErrorMessage(
        t('work_hour_needs_to_match_calculated_hour_w_param', {
          param: toFixed(hours),
        }),
      )
    } else {
      setWorkHourErrorMessage('')
    }
  }, [productivity, totalUnits, hoursPerUnit])

  useDidMountEffect(() => {
    const workers =
      plannedExecutionHours > 0 && productivity > 0
        ? totalWorkHour / (plannedExecutionHours * productivity)
        : 0.0
    setNumberOfWorkers(workers)
  }, [totalWorkHour, productivity])

  const onChangeTitle = (e: any) => {
    setTitle(e.target.value)
    setTitleErrorMessage('')
  }

  const onChangeDiscipline = (id: number) => {
    setDisciplineId(id)
    setDisciplineErrorMessage('')
  }

  const onChangeTotalUnits = (e: any) => {
    setTotalUnits(e.target.value)
    calculateTotalWorkHour(hoursPerUnit, e.target.value, productivity)
  }

  const onChangeNumberOfWorkers = (e: any) => {
    const workers = e.target.value
    const hours =
      workers > 0 && productivity > 0
        ? totalWorkHour / (workers * productivity)
        : 0.0
    setTotalWorkHour(hours)
    setNumberOfWorkers(workers)
  }

  const onChangeHoursPerUnit = (e: any) => {
    setHoursPerUnit(e.target.value)
    calculateTotalWorkHour(e.target.value, totalUnits, productivity)
  }

  const onChangePlannedExecutionHours = (e: any) => {
    const hours = e.target.value
    const workers =
      hours > 0 && productivity > 0
        ? totalWorkHour / (hours * productivity)
        : 0.0
    setPlannedExecutionHours(hours)
    setNumberOfWorkers(workers)
  }

  const onChangeProductivity = (e: any) => {
    setProductivity(e.target.value)
    setProductivityError('')
    calculateTotalWorkHour(hoursPerUnit, totalUnits, e.target.value)
  }

  const onChangeIsEstimate = (value: boolean) => {
    setIsEstimate(!value)
    if (value) {
      setTotalUnits(0)
      setHoursPerUnit(0)
      setUnit('')
    }
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const onSubmitForm = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    let error = false

    if (!title) {
      setTitleErrorMessage(t('fill_out_w_param', { param: t('title') }))
      error = true
    }

    if (!disciplineId) {
      setDisciplineErrorMessage(t('select_w_param', { param: t('discipline') }))
      error = true
    }

    if (productivity <= 0 || productivity > 1) {
      setProductivityError(t('the_value_must_be_between_0_and_1'))
      error = true
    }

    if (workHourErrorMessage !== '') {
      error = true
    }

    if (!error) {
      const constructionTaskTypeId =
        isEditing && constructionTaskType?.id && constructionTaskType?.id > 0
          ? constructionTaskType.id
          : undefined
      const constructionTaskTypeData: IConstructionTaskType = {
        id: constructionTaskTypeId,
        title,
        unit,
        is_estimate: isEstimate,
        planned_execution_hours: plannedExecutionHours,
        hours_per_unit: hoursPerUnit,
        total_units: totalUnits,
        number_of_workers: numberOfWorkers,
        discipline_id: disciplineId,
        locomotive_type_id: wagonTypeId > 0 ? wagonTypeId : undefined,
        project_id: projectId,
        productivity,
        total_work_hours: totalWorkHour,
      }

      const constructionConstructionTaskApiCall = isEditing
        ? editConstructionTaskType.mutateAsync(constructionTaskTypeData)
        : createConstructionTaskType.mutateAsync(constructionTaskTypeData)
      constructionConstructionTaskApiCall.then(() => {
        if (createMultiple) {
          setIsLoading(false)
          setTitle('')
          showAlert(
            'success',
            t('creation_succeeded'),
            t('activity_template_has_been_created'),
          )
        } else {
          closeModal()
        }
      })
    } else {
      setIsLoading(false)
    }
  }

  const showAlert = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const calculate = (
    productivityFactor: number,
    total: number,
    duration: number,
    workers: number,
  ) => {
    if (isEstimate) {
      setTotalWorkHour(workers * duration * productivityFactor)
      setWorkHourErrorMessage('')
    }
    setNumberOfWorkers(total / (duration * productivityFactor))
    setPlannedExecutionHours(total / (workers * productivityFactor))
  }

  const calculateTotalWorkHour = (
    hours: number,
    size: number,
    productivityFactor: number,
  ) => {
    const total = (hours * size) / productivityFactor
    setTotalWorkHour(total)
    setWorkHourErrorMessage('')
    calculate(productivityFactor, total, plannedExecutionHours, numberOfWorkers)
  }

  const addWorkOperationHour = (e: any) => {
    const workHour = e.target.value
    const calculatedHour = calculatedWorkHourRef.current
    setTotalWorkHour(workHour)
    if (calculatedHour > 0 && toFixed(calculatedHour) !== toFixed(workHour)) {
      setWorkHourErrorMessage(
        t('work_hour_needs_to_match_calculated_hour_w_param', {
          param: toFixed(calculatedHour),
        }),
      )
    } else {
      setWorkHourErrorMessage('')
    }
  }

  return (
    <Modal
      show={open}
      title={
        isEditing && constructionTaskType
          ? t('activity_title', { title: constructionTaskType.title })
          : t('new_activity')
      }
      closeModal={closeModal}
      size={'w-1/2'}
      maxWidth={700}
    >
      <form className={styleClass.root} onSubmit={onSubmitForm}>
        <div className={styleClass.inputGroup}>
          <Input
            block={true}
            label={t('title')}
            onChange={onChangeTitle}
            value={title}
            required={true}
            errorMessage={titleErrorMessage}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Selector
              items={disciplines}
              selectedItemId={disciplineId}
              onSelect={onChangeDiscipline}
              label={t('discipline')}
              dataFields={['shortName', 'name']}
              required={true}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={disciplineErrorMessage}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'p-2 pb-0 w-1/2 flex items-center'}>
            <label
              onClick={() => onChangeIsEstimate(!isEstimate)}
              className={styleClass.label}
            >
              {t('work_hours_calculator')}
            </label>
            <span className={'p-2 pb-0'}>
              <SwitchHOC
                valueProp={!isEstimate}
                height={20}
                width={40}
                className="custom-classname"
                onChange={onChangeIsEstimate}
              />
            </span>
          </div>
        </div>

        {!isEstimate && (
          <div className={styleClass.inputGroup}>
            <div className={'w-1/2'}>
              <Selector
                items={taskUnits(t)}
                selectedItemId={unit}
                onSelect={setUnit}
                label={t('unit')}
                dataFields={['name']}
                fontSize={'sm'}
                fontWeight={'bold'}
              />
            </div>
            <div className={'w-1/2'}>
              <Input
                block={true}
                label={t('total_units')}
                onChange={onChangeTotalUnits}
                value={toFixed(totalUnits)}
                type={'number'}
              />
            </div>
          </div>
        )}

        {!isEstimate && (
          <div className={styleClass.inputGroup}>
            <div className={'w-1/2'}>
              <Input
                block={true}
                label={t('hours_per_unit')}
                onChange={onChangeHoursPerUnit}
                value={Math.round(Number(hoursPerUnit) * 100) / 100}
                type={'number'}
              />
            </div>
          </div>
        )}

        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('total_work_hours')}
              onChange={addWorkOperationHour}
              value={toFixed(totalWorkHour)}
              type={'number'}
              disabled={!isEstimate}
              errorMessage={workHourErrorMessage}
            />
          </div>
          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('productivity_factor')}
              onChange={onChangeProductivity}
              value={productivity ? toFixed(productivity) : productivity}
              errorMessage={productivityError}
              type={'number'}
              max={1}
              min={0}
            />
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('duration_hours')}
              onChange={onChangePlannedExecutionHours}
              value={
                plannedExecutionHours
                  ? toFixed(plannedExecutionHours)
                  : plannedExecutionHours
              }
              type={'number'}
            />
          </div>
          <div className={'w-1/2'}>
            <Input
              block={true}
              label={t('number_of_workers')}
              onChange={onChangeNumberOfWorkers}
              value={Math.round(Number(numberOfWorkers) * 100) / 100}
              type={'number'}
            />
          </div>
        </div>

        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
            {t('cancel')}
          </Button>
          {isEditing ? (
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={isLoading}
              onClick={onSubmitForm}
            >
              {t('update')}
            </Button>
          ) : (
            <>
              <Button
                type={Button.ButtonType.SECONDARY}
                onClick={onMultipleCreate}
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : t('add_multiple')}
              </Button>
              <Button
                type={Button.ButtonType.SECONDARY}
                onClick={onSingleCreate}
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : t('save')}
              </Button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default withProjectContext(ConstructionTaskTypeForm)
