export const onLogout = async () => {
  const appVersion = window.localStorage.getItem('appVersion')
  const rnwLastOpened = localStorage.getItem('rnw-last-opened')
  localStorage.clear()
  if (rnwLastOpened) {
    localStorage.setItem('rnw-last-opened', rnwLastOpened)
  }
  if (appVersion) {
    window.localStorage.setItem('appVersion', appVersion)
  }
  window.location.reload()
}
