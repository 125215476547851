import jwtDecode from 'jwt-decode'
import { getProxyBaseUrl } from 'src/utility/utils'

interface IJwtToken {
  exp: number
  iat: number
}

const ACCESS_KEY = 'token'
const REFRESH_KEY = 'wall-token'

class TokenService {
  private accessToken: string | null = null
  private refreshToken: string | null = null

  constructor() {
    this.accessToken = localStorage.getItem(ACCESS_KEY)
    this.refreshToken = localStorage.getItem(REFRESH_KEY)

    this.getAccessToken = this.getAccessToken.bind(this)
    this.isTokenValid = this.isTokenValid.bind(this)
  }

  private async logout() {
    window.localStorage.clear()
    window.location.reload()
    return 'logged-out'
  }

  private async getAccessTokenFromServer(): Promise<string> {
    try {
      if (this.refreshToken === null) throw new Error('No refresh token found')
      const response = await fetch(`${getProxyBaseUrl()}/issue-new-token/`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.refreshToken,
        },
      })
      const res = await response.json()
      if (res.statusCode === 200) {
        const { accessToken, refreshToken } = res.body
        this.setAccessToken = accessToken
        this.setRefreshToken = refreshToken
        return refreshToken
      } else if (res.statusCode >= 400 && res.statusCode < 500) {
        return this.logout()
      } else {
        return 'Something went wrong'
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  private shouldUpdateToken(decodedToken: { iat: number }): boolean {
    // check if its been 4 minutes since the token was issued
    return Math.abs(Math.floor(Date.now() / 1000) - decodedToken.iat) >= 240
  }

  set setAccessToken(access: string) {
    this.accessToken = access
    localStorage.setItem(ACCESS_KEY, access)
  }

  set setRefreshToken(refresh: string) {
    localStorage.setItem(REFRESH_KEY, refresh)
    this.refreshToken = refresh
  }

  public get getRefreshToken() {
    return this.refreshToken
  }

  public async getAccessToken(forceUpdate?: boolean): Promise<string> {
    const token = this.accessToken
    if (token) {
      const decodedToken: IJwtToken = jwtDecode(token)
      const shouldUpdateToken = this.shouldUpdateToken(decodedToken)
      if (shouldUpdateToken || forceUpdate)
        return this.getAccessTokenFromServer()
      return token
    } else return this.getAccessTokenFromServer()
  }

  public async isTokenValid(): Promise<boolean> {
    const token = await this.getAccessToken()
    if (token === 'logged-out') return false
    return true
  }
}

export default new TokenService()
