import moment from 'moment'
import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IWithProjectContext,
  withProjectContext,
} from 'src/context/withProjectContext'
import { keypointListImportTemplate } from 'src/export-templates/keypointImportTemplate'
import { useFreezePlan } from 'src/query/planning/freezePlan'
import {
  IDiscipline,
  IImportItemList,
  IKeypoint,
  IListFilterFromParent,
  IMainProcess,
  IStatusTypes,
  ITeam,
  IUserData,
} from 'src/service/OrgTypes'
import {
  deleteBulkKeypoints,
  deleteKeyPoint,
  getProjectKeypointsFilteredWithPagination,
  IModalOrigin,
  IProjectKeypointResponse,
  updateKeypoint,
} from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import List from 'src/ui-elements/list/List'
import { IListColumns, ISorting } from 'src/ui-elements/list/ListTypes'
import Loader from 'src/ui-elements/loader/Loader'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import useAlert from 'src/ui-elements/toast/useAlert'
import { IMetaValue } from '../../document/types/IMetaData'
import { keypointListExportTemplate } from '../../export-templates/KeypointExports'
import { useSystemTypeGroupForDomain } from '../../query/systemTypeGroups'
import { updateUserDefinedFieldsValue } from '../../service/SystemTypeFieldService'
import {
  getKeyPointErrorMessage,
  StructureValidationError,
} from '../../service/ValidationErrors'
import {
  IListFilter,
  ResourceType,
} from '../../ui-elements/list/ListContextProvider'
import {
  classNames,
  constructFilterJson,
  IActiveFilter,
} from '../../utility/utils'
import {
  BaselineColumn,
  ClosedColumn,
  CreatedAtColumn,
  DeadlineColumn,
  DelayColumn,
  DeliveriesColumn,
  DurationColumn,
  ImprovementColumn,
  MilestoneColumn,
  RecordIdColumn,
  ReporterColumn,
  StatusColumn,
  TagColumn,
  TitleColumn,
  UpdatedAtColumn,
  userDefinedColumns,
} from '../TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumn,
  ResponsibleColumn,
} from '../TableColumns/DisciplineResponsibleColumns'
import {
  MainProcessColumn,
  TeamColumn,
} from '../TableColumns/MainProcessTemaColumns'
import DeleteModal from '../delete-modal/DeleteModal'
import KeypointChangeLogModal from '../process/main-process/KeypointChangeLogModal'
import KeypointInspectorPanel from './KeyPointInspectorPanel'
import KeypointModal from './KeypointModal'

interface IKeypointListProps extends IWithProjectContext {
  isMilestone?: boolean
  milestoneId?: number
  improvementId?: number
  inModal?: boolean
  doneParent?: boolean
  isExpandedElement?: boolean
  tableName?: string
  importKeypoint?: boolean
  origin: IModalOrigin
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  reloadTree?: () => void
}

const KeypointList = ({
  doneParent,
  isExpandedElement,
  isMilestone,
  milestoneId,
  projectContext,
  inModal: _inModal = false,
  tableName,
  importKeypoint,
  improvementId,
  origin,
  onOpenItem,
  reloadTree,
}: IKeypointListProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  const { t } = useTranslation()
  const projectId = projectContext.state.currentProject.id
  const [keypoints, setKeypoints] = useState<IProjectKeypointResponse[]>([])
  const [selectedKeypoint, setSelectedKeypoint] =
    useState<IProjectKeypointResponse>({} as IProjectKeypointResponse)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false)
  const [createKeypoint, setCreateKeypoint] = useState<boolean>(false)
  const [copyKeyPoint, setCopyKeyPoint] = useState<IProjectKeypointResponse>(
    {} as IProjectKeypointResponse,
  )
  const [totalPages, setTotalPages] = useState<number>(0)
  const pageSize = 30

  const [filterFromParent, setFilterFromParent] = useState<
    IListFilterFromParent[]
  >([])
  const [changeLogModal, setChangeLogModal] = useState<boolean>(false)
  const [changeLogReason, setChangeLogReason] = useState<string>('')
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const { addAlert } = useAlert()
  const [selectedKeypoints, setSelectedKeypoints] = useState<number[]>([])

  const keypointRef = useRef(keypoints)
  const projectRef = useRef(projectContext.state.currentProject.id)
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])
  const mainProcesses = useRef<IMainProcess[]>([])
  const themes = useRef<ITeam[]>([])
  const [openInspectorPanel, setOpenInspection] = useState(false)

  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('KeyPoint')
  const { data: planFreezeDuration } = useFreezePlan('key_point')

  useEffect(() => {
    setLoading(true)
    setKeypoints([])
    reload()
  }, [projectContext.state, milestoneId])

  useEffect(() => {
    projectRef.current = projectContext.state.currentProject.id
    keypointRef.current = keypoints
  })

  const onCloseInpectionPanel = () => {
    setOpenInspection(false)
  }

  const handlePreviewClick = (e: any, data: IProjectKeypointResponse) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenInspection(true)
    setSelectedKeypoint(data)
  }

  const reload = () => {
    setReloadTable((n) => !n)
  }

  const importKeypointTemplate: IImportItemList = {
    title: t('upload_key_points'),
    templateJson: keypointListImportTemplate,
    type: 'key_points',
    reload,
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    keypointFilter(activeFilters, page)
  }

  const keypointFilter = (activeFilters: IActiveFilter, page: number) => {
    const copyFilterFromParent = [...filterFromParent]

    if (isMilestone) {
      activeFilters['mile_stone'] = []
      activeFilters['mile_stone'].push(milestoneId)
      copyFilterFromParent.push({
        id: 'milestone',
        filed: 'mile_stone',
        value: [milestoneId],
      })
    }

    if (improvementId) {
      copyFilterFromParent.push({
        id: 'improvement',
        filed: 'improvement',
        value: [improvementId],
      })
      activeFilters['improvement'] = []
      activeFilters['improvement'].push(improvementId)
    }
    setFilterFromParent(copyFilterFromParent)
    getProjectKeypointsFilteredWithPagination(
      projectId,
      activeFilters,
      page,
      pageSize,
    ).then((res) => {
      setKeypoints(res.key_points)
      setTotalPages(res.pages)
      setLoading(false)
    })
  }

  const openKeypoint = (keypoint: IProjectKeypointResponse) => {
    if (onOpenItem) onOpenItem(keypoint.id, 'keypoint')
  }

  const onDeleteItemClick = (keypoint: IProjectKeypointResponse) => {
    setSelectedKeypoint(keypoint)
    setShowDeleteModal(true)
  }

  const getActionButton = () => {
    return !doneParent ? (
      isExpandedElement ? (
        <Icon
          icon={Icons.PLUS}
          className={'py-1 text-center w-6'}
          onClick={openCreateModal}
        />
      ) : (
        <Button
          type={Button.ButtonType.PRIMARY}
          size={Button.ButtonSize.SMALL}
          onClick={openCreateModal}
        >
          {t('new_keypoint')}
        </Button>
      )
    ) : undefined
  }

  const closeDeleteModal = () => {
    setShowDeleteModal((n) => !n)
  }

  const deleteKeypoint = () => {
    deleteKeyPoint(selectedKeypoint.id).then(() => {
      reload()
      if (reloadTree) {
        reloadTree()
      }
    })
    closeDeleteModal()
  }

  const onCloseCreateModal = () => {
    setCreateKeypoint(false)
  }

  const showAlert = (text: string) => {
    addAlert({
      type: 'error',
      title: t('an_error_occurred'),
      description: text,
    })
  }

  const onCreateKeypoint = () => {
    reload()
    if (reloadTree) {
      reloadTree()
    }
  }

  const openCreateModal = () => {
    setCreateKeypoint(true)
  }

  const closeCopyModal = () => {
    setCopyModalOpen(false)
  }

  const onCloneItem = (row: any) => {
    const cKeypoint: IProjectKeypointResponse = { ...copyKeyPoint }
    cKeypoint.name = `${row.name}-Copy`
    cKeypoint.main_process_id = row.main_process_id
    cKeypoint.discipline_id = row.discipline_id
    cKeypoint.responsible_id = row.responsible_id
    cKeypoint.team_id = row.team_id
    cKeypoint.record_id = row.record_id
    cKeypoint.mile_stone_id = row.mile_stone_id
    cKeypoint.description = row.description
    cKeypoint.assumptions = row.assumptions
    cKeypoint.tag_id = row.tag_id
    cKeypoint.tag = row.tag
    cKeypoint.copy_from_id = row.id
    setCopyModalOpen(true)
    setCopyKeyPoint(cKeypoint)
  }

  const updateResponsible = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const keypoint = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IKeypoint
    keypointUpdate(keypoint)
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const keypoint = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IKeypoint
    keypointUpdate(keypoint)
  }

  const updateMainProcess = (
    id: number,
    mainProcessId: number,
    temaId: number,
  ) => {
    const keypoint = {
      id,
      main_process_id: mainProcessId,
      team_id: temaId,
    } as IKeypoint
    keypointUpdate(keypoint)
  }

  const updateTema = (id: number, temaId: number, mainProcessId: number) => {
    const keypoint = {
      id,
      main_process_id: mainProcessId,
      team_id: temaId,
    } as IKeypoint
    keypointUpdate(keypoint)
  }

  const keypointUpdate = (keypoint: IKeypoint) => {
    saveKeypoint(keypoint, keypoint.id)
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const keypoint = { id: key, [field]: value }
    saveKeypoint(keypoint, key)
  }

  const onStatusSelect = (status: IStatusTypes, key: number) => {
    const oldKeypoints = [...keypointRef.current]

    const keypoint = oldKeypoints.filter((d) => d.id === key).pop()

    if (keypoint) {
      if (
        keypoint.open_children &&
        keypoint.open_children > 0 &&
        status.id === 'done'
      ) {
        showAlert(
          getKeyPointErrorMessage(
            StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
            t,
          ),
        )
        return
      }
      keypoint.status = status.id
      saveKeypoint(keypoint, key)
    }
  }

  const onDateChange = (date: moment.Moment, key: number) => {
    const oldKeypoints = [...keypointRef.current]
    const keypoint = oldKeypoints.filter((d) => d.id === key).pop()

    if (keypoint) {
      const movedToTheFuture = moment(date).isAfter(keypoint.endTime, 'day')
      const projectEndDate = projectContext.state.currentProject.endDate
      if (
        movedToTheFuture &&
        keypoint.mile_stone &&
        moment(keypoint.mile_stone.deadline).isBefore(date, 'day')
      ) {
        showAlert(
          `${getKeyPointErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE,
            t,
            moment(keypoint.mile_stone.deadline).format('L'),
          )}`,
        )
        return
      }
      if (
        movedToTheFuture &&
        keypoint.improvement &&
        moment(keypoint.improvement.deadline).isBefore(date, 'day')
      ) {
        showAlert(
          `${getKeyPointErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
            moment(keypoint.improvement.deadline).format('L'),
          )}`,
        )
        return
      }
      if (
        movedToTheFuture &&
        moment(date).isAfter(moment(projectEndDate), 'day')
      ) {
        showAlert(
          getKeyPointErrorMessage(
            StructureValidationError.PAST_PROJECT_DEADLINE,
            t,
          ) +
            ' (' +
            moment(projectEndDate).format('L') +
            ')',
        )
        return
      }
      const freezeUntil =
        planFreezeDuration > 0
          ? moment()
              .startOf('week')
              .add(planFreezeDuration - 1, 'weeks')
              .endOf('week')
          : moment().startOf('day')
      if (
        movedToTheFuture &&
        moment(keypoint.endTime).isBefore(freezeUntil, 'day')
      ) {
        keypoint.endTime = date.toISOString()
        setSelectedKeypoint(keypoint)
        setChangeLogReason('')
        setChangeLogModal(true)
      } else {
        saveKeypoint({ endTime: date }, key)
      }
    }
  }

  const deleteSelectedKeypoints = () => {
    deleteBulkKeypoints(projectRef.current, selectedKeypoints).then(() => {
      addAlert({
        type: 'success',
        title: t('successfully_deleted'),
        description: t('selected_items_deleted', { items: t('keypoints') }),
        autoClose: true,
      })
      reload()
      if (reloadTree) {
        reloadTree()
      }
      setSelectedKeypoints([])
    })
  }

  const onChangelogReasonChange = (value: any) => {
    setChangeLogReason(value)
  }

  const onChangeLogModalClose = () => {
    setChangeLogModal(false)
    setSelectedKeypoint({} as IProjectKeypointResponse)
  }

  const onChangeLogSubmit = () => {
    setChangeLogModal((prevState) => !prevState)
    if (selectedKeypoint.id) {
      saveKeypoint(
        { ...selectedKeypoint, change_reason: changeLogReason },
        selectedKeypoint.id,
      )
    }
  }

  const saveKeypoint = (keypoint: any, keypointId: number) => {
    updateKeypoint(keypointId, keypoint)
      .then(() => {
        setSelectedKeypoint({} as IProjectKeypointResponse)
      })
      .finally(reload)
  }

  const updateUserDefinedField = (data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        setReloadTable((n) => !n)
      })
    }
  }

  const userDefinedAttributesColumns = systemTypeGroup
    ? userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateUserDefinedField,
        'meta_data',
        true,
      )
    : []

  const getColumns = (): IListColumns[] => {
    return [
      RecordIdColumn('150'),
      TitleColumn('name', '450', (key, value) =>
        onFieldChange('name', key, value),
      ),
      MilestoneColumn(projectId, (key, value) =>
        onFieldChange('mile_stone_id', key, value),
      ),
      MainProcessColumn(projectId, mainProcesses, themes, updateMainProcess),
      TeamColumn(projectId, themes, updateTema),
      TagColumn(projectId, (key, value) => onFieldChange('tag_id', key, value)),
      StatusColumn(
        'status||endTime||expired_children||open_children||behind_schedule_children',
        statusTypes(t),
        onStatusSelect,
      ),
      ImprovementColumn(projectId, (key, value) =>
        onFieldChange('improvement_id', key, value),
      ),
      DeadlineColumn('endTime', 'endTime||milestone', onDateChange),
      ClosedColumn,
      BaselineColumn,
      DurationColumn,
      DelayColumn,
      DeliveriesColumn,
      ResponsibleColumn(
        projectId,
        projectUsers,
        projectDiscipline,
        updateResponsible,
      ),
      DisciplineColumn(
        projectId,
        projectDiscipline,
        projectUsers,
        updateDiscipline,
      ),
      ContractColumn(),
      ReporterColumn('user'),
      ...userDefinedAttributesColumns,
      CreatedAtColumn(),
      UpdatedAtColumn(),
    ]
  }
  return (
    <>
      <div className={styleClass.root}>
        <List
          data={keypoints}
          isExpandedElement={isExpandedElement}
          actionButton={getActionButton()}
          exportTemplate={keypointListExportTemplate}
          columns={getColumns()}
          resourceType={ResourceType.KEYPOINT}
          tableName={tableName ? tableName : 'keypointList'}
          itemsPerPage={pageSize}
          pagination={true}
          filterParent={'keypoint'}
          onRowClick={openKeypoint}
          getFilteredData={getFilteredData}
          reload={reloadTable}
          filterFromParent={filterFromParent}
          totalPages={totalPages}
          sortBackend={true}
          filterResetOption={true}
          isRowSelectable={true}
          onSelectRow={(data) => setSelectedKeypoints(data)}
          selectedRows={selectedKeypoints}
          bulkDelete={true}
          onBulkDelete={deleteSelectedKeypoints}
          importItem={importKeypoint ? importKeypointTemplate : undefined}
          onPreviewClick={handlePreviewClick}
          actionMenu={[
            {
              name: t('duplicate'),
              action: onCloneItem,
            },
            {
              name: t('delete'),
              action: onDeleteItemClick,
            },
          ]}
        />

        {createKeypoint ? (
          <KeypointModal
            show={createKeypoint}
            createKeypoint={createKeypoint}
            close={onCloseCreateModal}
            keypoint={
              milestoneId
                ? ({ mile_stone_id: milestoneId } as IProjectKeypointResponse)
                : ({} as IProjectKeypointResponse)
            }
            onCreateKeypoint={onCreateKeypoint}
          />
        ) : null}

        {copyModalOpen ? (
          <KeypointModal
            show={copyModalOpen}
            createKeypoint={copyModalOpen}
            close={closeCopyModal}
            keypoint={copyKeyPoint}
            onCreateKeypoint={onCreateKeypoint}
          />
        ) : null}

        {changeLogModal && selectedKeypoint.id ? (
          <KeypointChangeLogModal
            show={changeLogModal}
            toggleChangeLogModal={onChangeLogModalClose}
            onChangeLogSubmit={onChangeLogSubmit}
            onChangeReasonLog={onChangelogReasonChange}
          />
        ) : null}

        {!isEmpty(selectedKeypoint) && (
          <DeleteModal
            show={showDeleteModal}
            closeModal={closeDeleteModal}
            onDelete={deleteKeypoint}
            itemIdnType={`${selectedKeypoint.record_id} (${t('keypoint')})`}
            itemName={`${selectedKeypoint.record_id} - ${selectedKeypoint.name}`}
          />
        )}

        {openInspectorPanel && selectedKeypoint && selectedKeypoint.id && (
          <KeypointInspectorPanel
            keyPointId={selectedKeypoint.id}
            open={openInspectorPanel}
            onClose={onCloseInpectionPanel}
            origin={origin}
            onOpenItem={onOpenItem}
            onUpdate={reload}
          />
        )}

        {loading ? <Loader /> : null}
      </div>
    </>
  )
}

export default memo(withProjectContext(KeypointList))
