import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { draftRecordIdColumn } from 'src/components/TableColumns/Columns'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { trainListExportTemplate } from 'src/export-templates/TrainExports'
import { trainImportTemplate } from 'src/export-templates/TrainImportTemplate'
import useDraftMode from 'src/hooks/useDraftMode'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import history from '../../../history'
import {
  deleteConstructionTrain,
  editConstructionTrain,
  getPaginatedControlAreaConstructionTrains,
} from '../../../service/ConstructionTrainService'
import { IConstructionTrain, IImportItemList } from '../../../service/OrgTypes'
import Button from '../../../ui-elements/button/Button'
import List from '../../../ui-elements/list/List'
import {
  filterType,
  IListColumns,
  ISorting,
} from '../../../ui-elements/list/ListTypes'
import Loader from '../../../ui-elements/loader/Loader'
import { titleComparator } from '../../../utility/Utility'
import { constructFilterJson, IActiveFilter } from '../../../utility/utils'
import TableDatePicker from '../../datepicker/TableDatePicker'
import WagonList from '../wagon/WagonList'
import TrainForm from './TrainForm'
import TrainModal from './TrainModal'

interface ITrainList {
  controlAreaId: number
  controlAreaGroupId?: number
  inModal?: boolean
  isExpandedElement?: boolean
  inTree?: boolean
  location?: string
}

const TrainList = ({
  controlAreaId,
  controlAreaGroupId,
  inModal,
  isExpandedElement,
  inTree,
  location,
}: ITrainList) => {
  /**
   * Initial load of component. fetches the trains, refetches if porjectConstext changes.
   */
  const projectContext = useContext(ProjectContext)
  const { getControlAreaFilter } = useListHelper()

  const { t } = useTranslation()
  const [trains, setTrains] = useState<IConstructionTrain[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [newFormModal, setFormModal] = useState<boolean>(false)
  const [selectedTrain, setSelectedTrain] = useState<IConstructionTrain>(
    {} as IConstructionTrain,
  )
  const [totalPages, setTotalPages] = useState<number>(0)
  const [reloadTable, setReloadTable] = useState<boolean>(false)

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showInspectorPanel, setShowInspectorPanel] = useState<boolean>(false)
  const { isConstructionManager } = projectContext.state
  const { draftMode, turnDraftModeOn } = useDraftMode()

  const reload = () => {
    setReloadTable((n) => !n)
  }

  useEffect(() => {
    setLoading(true)
    reload()
  }, [projectContext, controlAreaId, draftMode])

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    trainFilter(activeFilters, page)
  }

  const trainFilter = (activeFilters: IActiveFilter, page: number) => {
    getPaginatedControlAreaConstructionTrains(
      controlAreaId,
      page,
      draftMode,
      activeFilters,
      50,
    ).then((res) => {
      setTrains(res.construction_trains)
      setTotalPages(res.pages)
      setLoading(false)
    })
  }

  const openCreateModal = () => {
    turnDraftModeOn()
    setFormModal(true)
  }

  const onDelete = (item: IConstructionTrain) => {
    setSelectedTrain(item)
    setShowDeleteModal(true)
    turnDraftModeOn()
  }

  const onDeleteItemClick = () => {
    if (!isEmpty(selectedTrain)) {
      setLoading(true)
      deleteConstructionTrain(selectedTrain).then(() => {
        reload()
        setShowDeleteModal(false)
      })
    }
  }

  const onClick = (item: IConstructionTrain) => {
    if (inTree) {
      location === 'control-area'
        ? history.push(
            `/constructions/control_areas/control_areas/control_areas/${item.control_area_id}/trains/${item.id}`,
          )
        : history.push(
            `/constructions/control_areas/control_area_groups/control_area_groups/${
              item.control_area?.control_area_group_id ?? 0
            }/control_areas/${item.control_area_id}/trains/${item.id}`,
          )
    }
  }

  const onStartDateChange = (date: moment.Moment, key: number) => {
    const dataField = trains.filter((d) => d.id === key).pop()
    if (dataField) {
      dataField.startTime = date
      editConstructionTrain(dataField).then(() => {
        setLoading(true)
        reload()
      })
    }
  }

  const getActionButton = () => {
    return isConstructionManager ? (
      isExpandedElement ? (
        <Icon
          icon={Icons.PLUS}
          className={'py-1 text-center w-6'}
          onClick={openCreateModal}
        />
      ) : (
        <Button type={Button.ButtonType.PRIMARY} onClick={openCreateModal}>
          {t('new_train')}
        </Button>
      )
    ) : undefined
  }

  const importTrainTemplate: IImportItemList = {
    title: t('upload_trains'),
    templateJson: trainImportTemplate,
    type: `construction_trains/${controlAreaId}`,
    label: 'construction_trains',
    reload,
  }

  const columns: IListColumns[] = [
    draftRecordIdColumn(draftMode, t),
    {
      name: 'title',
      size: '300',
      id: 'title',
      dataField: 'title',
      sortingField: 'title',
      filterType: filterType.TEXT,
      filter: [],
    },
    {
      name: 'start_date',
      size: '200',
      id: 'startTime',
      sortingField: 'startTime',
      dataField: 'startTime||update_access',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (data: any, row: number) => (
        <TableDatePicker
          date={data.startTime}
          rowId={row}
          inModal={inModal}
          onDateSubmit={onStartDateChange}
        />
      ),
    },
    {
      name: 'duration_days',
      size: '150',
      id: 'duration',
      dataField: 'duration',
      sortingField: 'duration',
      filterType: filterType.NUMBER,
      filter: [],
    },
    {
      name: 'number_of_wagons',
      size: '200',
      id: 'number_of_locomotives',
      dataField: 'number_of_locomotives',
      sortingField: 'number_of_locomotives',
      filterType: filterType.NUMBER,
      filter: [],
    },
    {
      name: 'number_of_activities',
      size: '200',
      id: 'number_of_work_operations',
      dataField: 'number_of_work_operations',
      sortingField: 'number_of_work_operations',
      filterType: filterType.NUMBER,
      filter: [],
    },
    {
      name: 'working_hours',
      size: '200',
      id: 'planned_execution_hours',
      dataField: 'planned_execution_hours',
      sortingField: 'planned_execution_hours',
      filterType: filterType.NUMBER,
      filter: [],
    },
    {
      name: 'created_at',
      size: '150',
      id: 'createdAt',
      dataField: 'created_at',
      sortingField: 'created_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (date) => <span>{moment(date).format('L')}</span>,
    },
    {
      name: 'updated_at',
      size: '150',
      id: 'updatedAt',
      dataField: 'updated_at',
      sortingField: 'updated_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const controlAreaColumn = {
    name: 'control_area',
    size: '240',
    id: 'controlArea',
    dataField: 'control_area',
    sortingField: 'control_area',
    filterType: filterType.DEFAULT,
    filter: [],
    getFilter: getControlAreaFilter,
    filterDataField: 'control_area.title',
    filterDataValue: 'control_area.id',
    comparator: (a: any, b: any, direction: boolean) =>
      a ? (b ? titleComparator(a, b, direction) : 0) : 0,
    cell: (controlArea: any) =>
      controlArea ? <span>{controlArea.title}</span> : <span />,
  }

  const getColumns = () => {
    if (!controlAreaId) {
      columns.splice(columns.length - 2, 0, controlAreaColumn)
    }
    return columns
  }

  const onPreviewClick = (e: any, row: any) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedTrain(row)
    setShowInspectorPanel(true)
  }

  return (
    <div className={'flex flex-col'}>
      <List
        actionButton={getActionButton()}
        columns={getColumns()}
        tableName={'trainTable'}
        data={trains}
        exportTemplate={
          controlAreaId
            ? trainListExportTemplate(`control_areas/${controlAreaId}`)
            : trainListExportTemplate(
                `projects/${projectContext.state.currentProject.id}`,
              )
        }
        selectedRows={selectedTrain?.id ? [selectedTrain?.id] : []}
        isExpandedElement={isExpandedElement}
        getFilteredData={getFilteredData}
        totalPages={totalPages}
        pagination={true}
        reload={reloadTable}
        importItem={isConstructionManager ? importTrainTemplate : undefined}
        sortBackend={true}
        filterResetOption={true}
        onRowClick={onClick}
        onPreviewClick={inTree ? onPreviewClick : undefined}
        itemsPerPage={0}
        expandedElement={
          !inTree
            ? (train: any) => {
                return (
                  <div className="p-4">
                    <WagonList
                      controlAreaId={
                        controlAreaId
                          ? controlAreaId
                          : train.control_area.id
                            ? train.control_area.id
                            : 0
                      }
                      isExpandedElement={true}
                      inModal={true}
                      trainId={train.id ? train.id : 0}
                      train={train}
                      reloadParent={reload}
                    />
                  </div>
                )
              }
            : undefined
        }
        actionMenu={
          isConstructionManager
            ? [
                {
                  name: t('delete'),
                  action: onDelete,
                },
              ]
            : undefined
        }
      />

      {newFormModal ? (
        <TrainForm
          open={newFormModal}
          train={selectedTrain}
          parentId={controlAreaId ? controlAreaId : 0}
          controlAreaGroupId={controlAreaGroupId}
          closeModal={() => {
            reload()
            setFormModal(false)
          }}
        />
      ) : null}

      <div className={showInspectorPanel ? 'h-[380px]' : ''}>
        {showInspectorPanel && selectedTrain?.id && (
          <TrainModal
            open={showInspectorPanel}
            trainId={selectedTrain.id}
            parentId={0}
            onClose={() => {
              setShowInspectorPanel(false)
              reload()
            }}
            onUpdate={reload}
            className={'h-[350px]'}
          />
        )}
      </div>

      {!isEmpty(selectedTrain) && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={() => setShowDeleteModal(false)}
          onDelete={onDeleteItemClick}
          itemIdnType={`${selectedTrain.record_id} - ${
            selectedTrain.title
          } (${t('train')})`}
          itemName={`${selectedTrain.record_id} - ${selectedTrain.title}`}
        />
      )}

      {loading && !isExpandedElement ? <Loader /> : null}
    </div>
  )
}

export default TrainList
