import Close from '@icons/close.svg'
import Filter from '@icons/filter_alt.svg'
import React, { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import CircularButton from '../CircularButton'

interface IFilterIcon {
  filtersApplied: boolean
  open: boolean
  setOpen: (v: boolean, e?: MouseEvent) => void
}

const FilterIcon: React.FC<IFilterIcon> = ({
  filtersApplied,
  open,
  setOpen,
}) => {
  const { t } = useTranslation()

  return (
    <CircularButton
      onClick={(e) => setOpen(!open, e)}
      tooltipText={t('filters')}
      active={open || filtersApplied}
    >
      {open ? <Close /> : <Filter />}
    </CircularButton>
  )
}

export default FilterIcon
