import moment from 'moment'
import { IEvent } from './EmailProviderUtil'

class ICalGenerator {
  static generateRandomStr(input: number): string {
    const str = String(input)
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash |= 0
    }
    return String(Math.abs(hash))
  }

  static generateICalForMeeting(meeting: IEvent, RRULE: string | null): string {
    const startDate = moment(meeting.start).utc().format('YYYYMMDDTHHmmss[Z]')
    const endDate = moment(meeting.end).utc().format('YYYYMMDDTHHmmss[Z]')
    const attendees = meeting.participants
      .map(
        (participant) =>
          `ATTENDEE;ROLE=REQ-PARTICIPANT;CN="${participant.name}":MAILTO:${participant.email}`,
      )
      .join('\n')

    return `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//taskctrl.no//ical-generator//EN
NAME:${meeting.title}
X-WR-CALNAME:${meeting.title}
METHOD:REQUEST
BEGIN:VEVENT
UID:${this.generateRandomStr(meeting.id)}
SEQUENCE:0
DTSTAMP:${moment().utc().format('YYYYMMDDTHHmmss[Z]')}
DTSTART:${startDate}
DTEND:${endDate}
${RRULE ? RRULE : ''}
SUMMARY:${meeting.title}
DESCRIPTION:${meeting.description}
${attendees}
URL;VALUE=URI:${meeting.url}
STATUS:CONFIRMED
END:VEVENT
END:VCALENDAR`
  }
}

export default ICalGenerator
