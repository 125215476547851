import { TableFilter } from 'src/ui-elements/Table/useTable'
import { IWagonType } from './OrgTypes'
import { request } from './service-utils'

export const createWagonType = (
  wagon: IWagonType,
  projectId: number,
): Promise<IWagonType> => {
  return request(`projects/${projectId}/locomotive_types`, 'POST', wagon)
}

export const editWagonType = (
  taskType: NullablePartial<IWagonType>,
): Promise<IWagonType> => {
  return request(`locomotive_types/${taskType.id}`, 'PUT', taskType)
}

export const getWagonType = (id: number): Promise<IWagonType> => {
  return request(`locomotive_types/${id}`, 'GET')
}

export const getTrainTypeWagons = (trainId: number): Promise<IWagonType[]> => {
  return request(`train_types/${trainId}/locomotive_types`, 'GET')
}

export const swapOrderTrainTypeWagons = (
  wagonTypeId: number,
  executionOrder: number,
): Promise<IWagonType[]> => {
  return request(`locomotive_types/${wagonTypeId}/change_order`, 'PUT', {
    execution_order: executionOrder,
  })
}

export const deleteWagonType = (task: IWagonType) => {
  return request(`/locomotive_types/${task.id}`, 'DELETE', {}, true)
}

export const getProjectWagonTemplate = (
  projectId: number,
): Promise<IWagonType[]> => {
  return request(`/projects/${projectId}/locomotive_type_templates`, 'GET')
}

export const getProjectWagonTypes = (
  projectId: number,
  page: number,
  perPage: number,
  filters: TableFilter,
): Promise<{ locomotive_types: IWagonType[]; pages: number }> => {
  return request(
    `/projects/${projectId}/locomotive_types/pages/${page}/${perPage}`,
    'POST',
    { ...filters },
  )
}

export const getAllProjectWagonTypes = (
  projectId: number,
): Promise<IWagonType[]> =>
  request(`/projects/${projectId}/locomotive_types`, 'GET')

export const getWagonTypeTree = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/locomotive_types/tree`, 'GET')
}

export const bulkDeleteWagonTypes = (ids: number[]) => {
  return request('locomotive_types/bulk_delete', 'POST', { ids })
}
