export enum ImportStatus {
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'completed',
  FAILED = 'failed',
  ERROR = 'data_error',
}

export interface ImportTask {
  id: number
  job_name: string
  job_id: string
  status: ImportStatus
  job_data: any
  error: any
  project_id: number
  created_at: string
  updated_at: string
  user_id: number
  active: boolean
  data?: Record<string, string>
}
