import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const test2Config: IEnvironmentConfig = {
  host: "test2.bygg.io",
  exportBaseUrl: "https://export-test2.bygg.io/",
  frontendBaseUrl: "https://test2.bygg.io/",
  organizationBaseUrl: "https://org-test2.bygg.io/",
  proxyBaseUrl: "https://proxy-test2.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-test2.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-test2.bygg.io/",
  bimServiceBaseUrl: "https://bim-service-test.bygg.io/",
  kpiBaseUrl: "https://kpi-test2.bygg.io/",
}
