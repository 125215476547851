import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getActivitiesForUserWithCount } from 'src/service/ActivityLogService'
import { IActivityLog } from 'src/service/OrgTypes'

export interface IActivityLogsResponseData {
  activity_logs: IActivityLog[]
  total_count: number
  unread_count: number
  page: number
  total_pages: number
}

export const useNotifications = (userId: number) => {
  const projectId = useProjectId()
  const queryClient = useQueryClient()

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery<IActivityLogsResponseData>({
    queryKey: ['notifications', projectId, userId],
    queryFn: ({ pageParam = 1 }: any) =>
      getActivitiesForUserWithCount(projectId, userId, pageParam),
    getNextPageParam: (lastPage) =>
      lastPage.page < lastPage.total_pages ? +lastPage.page + 1 : null,
    initialPageParam: 1,
  })

  const reload = () => {
    queryClient.invalidateQueries({
      queryKey: ['notifications', projectId, userId],
    })
  }

  const totalCount = data?.pages[0]?.total_count ?? 0
  const unreadCount = data?.pages[0]?.unread_count ?? 0

  const activityLogs = data?.pages.flatMap((page) => page.activity_logs) ?? []

  return {
    isLoading: isLoading || false,
    isFetchingNextPage: isFetchingNextPage || false,
    reload,
    activityLogs,
    totalCount,
    unreadCount,
    hasNextPage,
    fetchNextPage,
    refetch,
  }
}
