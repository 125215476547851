import * as React from 'react'
import ConditionalWrap from 'src/utility/ConditionalWrap'
import { classNames } from 'src/utility/utils'
import MaterialIcon from '../icon/materialIcon'
import Tooltip from '../tooltip/Tooltip'

interface ListActionButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: string
  disable?: boolean | string
}

const styleClass = {
  root: (disabled = false) =>
    classNames(
      'flex w-full items-center text-blue-root bg-blue-root-tab-wash hover:bg-blue-root-focus hover:text-white focus:outline-none active:bg-blue-root-focus text-sm font-normal outline-0  whitespace-nowrap transition ease-in-out duration-150 shadow',
      disabled
        ? 'cursor-not-allowed opacity-50'
        : 'cursor-pointer hover:scale-105',
    ),
}

const ListActionButton: React.FC<ListActionButtonProps> = ({
  icon,
  children,
  disable,
  className,
  ...rest
}) => {
  const _onClick = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    if (disable) return
    rest.onClick?.(e)
  }

  return (
    <div
      className={
        'flex items-center justify-center w-full h-full ' + (className ?? '')
      }
    >
      <ConditionalWrap
        condition={typeof disable === 'string'}
        wrap={(c) => (
          <Tooltip
            classNames="w-full"
            message={`${disable}`}
            minWidth={'min-w-48'}
          >
            {c}
          </Tooltip>
        )}
      >
        <button
          className={styleClass.root(!!disable)}
          {...rest}
          onClick={_onClick}
        >
          <span className="flex justify-center items-center">
            {icon && <MaterialIcon className="text-sm mx-2" icon={icon} />}
          </span>
          <span className="inline-block text-sm mr-2 h-full align-middle">
            {children}
          </span>
        </button>
      </ConditionalWrap>
    </div>
  )
}

export default ListActionButton
