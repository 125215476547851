import { PropsWithChildren, FC } from 'react'
import PopUp, { PopUpProps } from './Popup'

const ErrorPopUp: FC<PropsWithChildren<PopUpProps>> = ({
  pos,
  show,
  close,
  children,
}) => {
  return (
    <PopUp
      className="bg-white shadow rounded border-l-4 text-black font-normal text-xs border-red-danger px-2 z-40"
      show={show}
      close={close}
      pos={pos}
    >
      {children}
    </PopUp>
  )
}

export default ErrorPopUp
