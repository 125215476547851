import {
  ISystemSyntaxElement,
  ISystemSyntaxSpacer,
} from 'src/document/types/ISystemSyntax'

export const syntaxes: ISystemSyntaxElement[] = [
  {
    id: 'category',
    readableNameKey: 'category',
    numberLength: 0,
    type: 'syntax',
    variant: 'category',
  },
  {
    id: 'id',
    readableNameKey: 'id',
    numberLength: 0,
    type: 'syntax',
    variant: 'id',
  },
  {
    id: 'free_string',
    readableNameKey: 'free_string',
    numberLength: -1,
    type: 'syntax',
    variant: 'free_string',
  },
  {
    id: 'string',
    readableNameKey: 'string',
    numberLength: 0,
    type: 'syntax',
    variant: 'string',
  },
]

export const spacers: ISystemSyntaxSpacer[] = [
  {
    id: 'dot',
    readableNameKey: '.',
    type: 'spacer',
    variant: 'dot',
    visualSymbol: '.',
  },
  {
    id: 'equals',
    readableNameKey: '=',
    type: 'spacer',
    variant: 'equals',
    visualSymbol: '=',
  },
  {
    id: 'plus',
    readableNameKey: '+',
    type: 'spacer',
    variant: 'plus',
    visualSymbol: '+',
  },
  {
    id: 'dash',
    readableNameKey: '-',
    type: 'spacer',
    variant: 'dash',
    visualSymbol: '-',
  },
]

export const possibleSyntaxElements = [
  {
    title: 'syntax',
    elements: syntaxes,
  },
  {
    title: 'spacer',
    elements: spacers,
  },
]
