import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { InspectorTopRow } from 'src/document/components/Inspector/InspectorHelper'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { IUserData } from 'src/service/OrgTypes'
import { getUser } from 'src/service/UserService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { classNames } from 'src/utility/utils'
import FixedPane from '../../ui-elements/fixed-pane/FixedPane'

interface IUserInspectorPanel {
  user: Partial<IUserData>
  open: boolean
  onClose: () => void
  disableClickOutside?: boolean
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
  greyText: (black?: boolean) =>
    classNames(
      black ? 'text-black' : 'text-d-fontgrey',
      'leading-snug',
      'inline-flex',
    ),
  row: {
    root: (first: boolean, selected: boolean, last: boolean) =>
      classNames(
        first && 'border-t',
        'border-b',
        'border-d-darkgrey',
        'flex',
        'flex-col',
        selected ? 'max-h-[calc(100vh_-_665px)] overflow-auto' : 'flex-none',
        last && '-mb-1',
        'selectorScroll',
      ),
    header: classNames('N/A'),
  },
}

const UserInspectorPanel: React.FC<IUserInspectorPanel> = ({
  user,
  open,
  onClose,
  disableClickOutside,
}) => {
  const history = useHistory()
  const { t } = useMultipleKeysTranslation()
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const [userData, setUserData] = useState(user)

  const onContractClick = (id: number) => {
    history.push(`/project/contracts/contracts/${id}`)
  }

  useEffect(() => {
    if (user.id) {
      getUser(user.id).then((res) => setUserData(res))
    }
  }, [user.id])

  const getRows = (): Array<{
    name: string
    icon: any
    activeIcon: any
    content: JSX.Element
  }> => [
    {
      name: t('contracts'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorSectionTable
          headerColumns={[t('contract'), t('role'), t('contract_admin')]}
          rowsData={
            user.user_contract_mappers
              ? user.user_contract_mappers.map((contract) => {
                  return {
                    cells: [
                      contract?.contract.contractNumber +
                        ' - ' +
                        (contract?.contract.contractName ?? ''),
                      contract?.role.roleName ?? '',
                      contract.isContractAdmin ? t('yes') : t('no'),
                    ],
                    id: contract.id,
                  }
                })
              : []
          }
          handleClick={onContractClick}
        />
      ),
    },
  ]

  const toggleExpand = (index: number) => {
    setSelectedIndex(index === selectedIndex ? -1 : index)
  }

  return (
    <FixedPane
      title={`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}
      show={open}
      onClose={() => onClose()}
      disableOutsideClose={disableClickOutside}
    >
      <div className={styleClass.root}>
        <div className="py-2 mb-6 flex justify-center">
          <img
            src={user.image_url ?? '/user_profile.png'}
            className="w-64 h-64 rounded-lg shadow-md antialiased"
          />
        </div>
        <InspectorTopRow
          left={{
            label: t('firstName'),
            value: userData?.firstName,
          }}
          right={{
            label: t('lastName'),
            value: userData?.lastName,
          }}
        />
        <InspectorTopRow
          left={{
            label: t('active'),
            value: userData?.isActive ? 'yes' : 'No',
          }}
          right={{
            label: t('system_admin'),
            value: userData?.isSystemAdmin ? 'yes' : 'No',
          }}
        />
        <InspectorTopRow
          left={{
            label: t('email'),
            value: userData?.email,
          }}
        />
        <InspectorTopRow
          left={{
            label: t('telephone'),
            value: userData?.telephoneNumber,
          }}
        />
        <div className={'flex flex-col flex-1'}>
          {getRows().map((row, i) => (
            <div
              key={i}
              className={styleClass.row.root(
                i === 0,
                selectedIndex === i,
                getRows().length - 1 === i,
              )}
              onClick={() => toggleExpand(i)}
            >
              <div
                className={
                  'flex items-center justify-between px-5 py-4 bg-white sticky cursor-pointer'
                }
              >
                <p className={styleClass.greyText(selectedIndex === i)}>
                  <Icon
                    className={`mr-4 w-5 h-5`}
                    icon={selectedIndex === i ? row.activeIcon : row.icon}
                  />
                  {row.name}
                </p>
                <Icon
                  onClick={() => toggleExpand(i)}
                  className={`w-5 h-5 flex ${
                    selectedIndex === i ? 'transform -rotate-90' : 'N/A'
                  }`}
                  icon={Icons.ARROW_LEFT}
                />
              </div>
              {selectedIndex === i && (
                <div className={'h-max max-h-full pb-4 px-5'}>
                  {row.content}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </FixedPane>
  )
}
export default UserInspectorPanel
