import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'
import { BASE_METADATA_TYPES } from 'src/document/types/FolderMetadataTypes'
import { IMetaData } from 'src/document/types/IMetaData'
import { IExport } from 'src/service/OrgTypes'
import { cloneObj } from './utils'

export interface Template {
  name: string
  required: boolean
  value: string
  description: string
  example: string
}

export interface ImportTemplate {
  name: string
  displayName: string
  columns: string[]
  templateColumns: string[]
  template: Template[]
}

const exportUserDefineColumns = (
  userDefinedField: IMetaData[] | IMetaField[],
  fieldName: string,
) => {
  const columns = []
  const rows = []
  for (let i = 0; i < userDefinedField.length; i++) {
    const udf = userDefinedField[i]
    columns.push(udf.name)
    rows.push(`${fieldName}_${udf.id}`)
  }

  return [columns, rows]
}

export const addUserDefinedInfoToImport = (
  template: ImportTemplate,
  required: IMetaData[] | IMetaField[],
  optional: IMetaData[] | IMetaField[],
) => {
  const cloneTemplate: ImportTemplate = cloneObj(template)
  const [requiredCols] = exportUserDefineColumns(required, 'required_fields')
  const [optionalCols] = exportUserDefineColumns(optional, 'optional_fields')
  cloneTemplate.columns = [
    ...cloneTemplate.columns,
    ...requiredCols,
    ...optionalCols,
  ]

  const templateEg: Template[] = [...required, ...optional].map((r) => {
    return {
      name: r.name,
      required: r.is_required,
      value: r.data_type,
      description: Array.isArray(r.data_type) ? r.data_type[0] : r.data_type,
      example: Array.isArray(r.pattern) ? r.pattern.join(', ') : r.pattern,
    }
  })

  cloneTemplate.template = [...cloneTemplate.template, ...templateEg]

  return cloneTemplate
}
const exportMetaDataDefinitions = (metaData: IMetaField[]) => {
  if (!metaData) return [[], []]
  const columns = []
  const rows = []
  for (const m of metaData) {
    if (m.id) {
      const data_key =
        m.data_type === BASE_METADATA_TYPES.multiEnum
          ? 'data_value_list'
          : 'data_value'
      columns.push(m.name)
      rows.push(`meta_data['${m.id}']['${data_key}']`)
    }
  }

  return [columns, rows]
}

export const addMetaDataInfo = (template: IExport, optional: IMetaField[]) => {
  const cloneTemplate = cloneObj(template)
  const [optionalCols, optionalRows] = exportMetaDataDefinitions(optional)
  cloneTemplate.columns = [...cloneTemplate.columns, ...optionalCols]
  cloneTemplate.rows = [...cloneTemplate.rows, ...optionalRows]
  return cloneTemplate
}
