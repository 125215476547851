import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import { getSystemsWithPage } from 'src/service/SystemService'
import {
  getProjectTestWorkGroup,
  IFilter,
  plannedTestWorkGroupsSummary,
} from 'src/service/TestWorkGroupService'
import { monthNames } from 'src/utility/dateUtils'

export const useFilteredTestWorkGroups = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof getSystemsWithPage>[3]['sort']
  filters: Omit<Parameters<typeof getSystemsWithPage>[3], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'testWorkGroupsFiltered',
      { projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getProjectTestWorkGroup(projectId, page, { ...filters, sort }, pageSize),
    placeholderData: keepPreviousData,
    enabled,
  })
}

export const usePlannedTestWorkGroupsSummary = (filters: IFilter) => {
  const projectId = useProjectId()
  const { t } = useTranslation()

  return useQuery({
    queryKey: ['plannedTestWorkGroupsGraph', projectId, filters],
    queryFn: () =>
      plannedTestWorkGroupsSummary(projectId, filters).then((res) =>
        Object.keys(res)
          .map((year) =>
            Object.keys(res[year]).map((month) => {
              return {
                monthAndYear: `${capitalize(t(monthNames[month]))} '${year.slice(2)}`,
                month,
                year,
                ...res[year][month].status_count,
              }
            }),
          )
          .flat(),
      ),
  })
}
