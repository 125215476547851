import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { IImprovement } from 'src/service/OrgTypes'
import {
  dateColumn,
  editableDateColumn,
  mainProcessColumn,
  statusColumn,
  textColumn,
  userColumn,
} from 'src/ui-elements/Table/Columns'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'

const useImprovementListColumns = (
  onStatusUpdate?: Parameters<typeof statusColumn>[4],
  onDateChange?: Parameters<typeof editableDateColumn>[2],
) => {
  const { t } = useTranslation()
  const standardColumns = [
    textColumn('record_id', { name: capitalize(t('id')) }),
    textColumn('title', { name: capitalize(t('title')) }),
    statusColumn(
      'status',
      ['status', 'id'],
      { name: capitalize(t('status')) },
      [
        {
          name: 'new_suggestions',
          id: 'open_improvement',
        },
        {
          name: 'in_progress',
          id: 'in_progress',
        },
        {
          name: 'completed',
          id: 'completed',
        },
      ],
      onStatusUpdate,
      (cell) => {
        if (cell.status === 'completed') return BadgeColor.GREEN
        if (cell.status === 'in_progress') return BadgeColor.YELLOW
        return BadgeColor.BLUE
      },
    ),
    onDateChange
      ? editableDateColumn(
          'deadline',
          { name: capitalize(t('deadline')) },
          onDateChange,
        )
      : dateColumn('deadline', { name: capitalize(t('deadline')) }),
    dateColumn('closed_date', { name: capitalize(t('closed_date')) }),
    textColumn('background', { name: capitalize(t('background')) }),
    textColumn('current_situation', {
      name: capitalize(t('current_situation')),
    }),
    textColumn('profitability', { name: capitalize(t('profitability')) }),
    textColumn('effect', { name: capitalize(t('effect')) }),
    textColumn('countermeasures', { name: capitalize(t('countermeasures')) }),
    textColumn('plan', { name: capitalize(t('plan')) }),
    mainProcessColumn('main_process', { name: capitalize(t('main_process')) }),
    userColumn('responsible', { name: capitalize(t('responsible')) }),
    dateColumn('created_at', { name: capitalize(t('created_at')) }),
    dateColumn('updated_at', { name: capitalize(t('updated_at')) }),
  ] as ColumnDef<IImprovement>[]
  return { standardColumns }
}

export default useImprovementListColumns
