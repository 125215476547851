import { convertToHTML } from 'draft-convert'
import { ContentState, convertFromHTML, EditorState } from 'draft-js'
import { useContext, useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { ITestParticipant } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { capFirstLetter } from '../../../utility/utils'

export interface ITestSendInvitationProps {
  show: boolean
  closeModal: () => void
  itemId: number | number[]
  reload?: () => void
  numberOfUsers?: number
  api: (
    id: number | number[],
    sendInvite: boolean,
    projectId?: number,
    invitationSubject?: string,
    invitationBody?: string,
    data_type?: string,
    selectedRecipientIds?: {
      email: string
      id: number
      userId?: number
    }[],
  ) => Promise<unknown>
  defaultInvitationBody?: string
  defaultInvitationSubject?: string
  participants: ITestParticipant[]
  title: string
  dataType?: string
}

const TestSendInvitation = ({
  show,
  closeModal,
  itemId,
  reload,
  api,
  defaultInvitationSubject,
  defaultInvitationBody,
  participants,
  title,
  dataType,
}: ITestSendInvitationProps) => {
  const { t } = useTranslation()
  const [invitationBody, setInvitationBody] = useState<string>(
    defaultInvitationBody ? defaultInvitationBody : '',
  )
  const [invitationSubject, setInvitationSubject] = useState<string>(
    defaultInvitationSubject ? defaultInvitationSubject : '',
  )
  const [invitationSubjectError, setInvitationSubjectError] =
    useState<string>('')

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )
  const [testParticipants, setTestParticipants] = useState<ITestParticipant[]>(
    participants ?? [],
  )

  useEffect(() => {
    setTestParticipants(participants)
  }, [participants])

  const projectContext = useContext(ProjectContext)
  const { id } = projectContext.state.currentProject

  const clearFields = () => {
    setInvitationBody('')
    setInvitationSubject('')
    setInvitationSubjectError('')
    setEditorState(EditorState.createEmpty())
  }

  useEffect(() => {
    setInvitationBody(defaultInvitationBody ? defaultInvitationBody : '')
    const blocksFromHTML = convertFromHTML(
      defaultInvitationBody ? defaultInvitationBody : '',
    )
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )
    setEditorState(EditorState.createWithContent(state))
  }, [defaultInvitationBody])

  useEffect(() => {
    setInvitationSubject(
      defaultInvitationSubject ? defaultInvitationSubject : '',
    )
  }, [defaultInvitationSubject])

  const sendInvite = async () => {
    const ids = testParticipants.map((tp) => ({
      email: tp.email,
      id: tp.id ?? 0,
      userId: tp.user_id,
    }))
    await api(
      itemId,
      true,
      id,
      invitationSubject,
      invitationBody,
      dataType,
      ids,
    )
    reload?.()
    clearFields()
    closeModal()
  }

  const onSubmitClicked = () => {
    if (!invitationSubject) {
      setInvitationSubjectError(t('fill_in_the_subject'))
    } else {
      sendInvite()
    }
  }

  useEffect(() => {
    const html = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a
              style={{ color: '#3077F9', textDecoration: 'underline' }}
              target={'_blank'}
              href={entity.data.url}
              rel="noreferrer"
            >
              {originalText}
            </a>
          )
        }
        return originalText
      },
    })(editorState.getCurrentContent())
    setInvitationBody(html)
  }, [editorState])

  const onSubjectChange = (e: any) => {
    setInvitationSubject(e.target.value)
    setInvitationSubjectError('')
  }

  const cancelInvitation = () => {
    clearFields()
    closeModal()
  }

  const removeParticipantFromEmail = (index: number) => {
    const users = JSON.parse(JSON.stringify(testParticipants))
    users.splice(index, 1)
    setTestParticipants(users)
  }
  return (
    <Modal
      show={show}
      closeModal={cancelInvitation}
      title={t(title)}
      maxWidth={700}
    >
      <div className="flex flex-col">
        <div className="flex flex-row items-center flex-warp mb-3">
          <label className={'text-sm font-medium text-gray-700 mr-5'}>
            {`${capFirstLetter(t('to'))}:`}{' '}
          </label>
          <span className="flex flex-row flex-wrap pro text-sm leading-5  text-gray-900 my-2">
            {testParticipants?.length > 0 ? (
              testParticipants?.map((p, i) => (
                <span
                  className="rounded-md bg-gray-100 border text-gray-500 m-1 px-1 flex flex-nowrap"
                  key={i}
                >
                  <span>{p.email ?? `${p.firstName} ${p.lastName}`}</span>{' '}
                  {testParticipants && (
                    <Icon
                      icon={Icons.CLOSE_GRAY}
                      className="w-4 h-4 ml-2 opacity-60"
                      onClick={() => removeParticipantFromEmail(i)}
                    />
                  )}
                </span>
              ))
            ) : (
              <p className="text-red text-sm">
                {t('please_choose_participants_first')}
              </p>
            )}
          </span>
        </div>
        <div className="flex flex-row items-center flex-warp">
          <label className={'text-sm font-medium text-gray-700'}>
            {`${capFirstLetter(t('subject'))}:`}{' '}
          </label>

          <Input
            block={true}
            value={invitationSubject}
            onChange={onSubjectChange}
            required={true}
            errorMessage={invitationSubjectError}
          />
        </div>
        <div className="border my-4 mr-2">
          <Editor
            editorState={editorState}
            onEditorStateChange={(e) => setEditorState(e)}
            editorClassName={'px-1 min-h-[220px] max-h-[500px] border'}
            toolbar={{
              options: ['inline', 'blockType', 'list', 'history', 'link'],
              inline: {
                inDropdown: false,
                options: ['bold', 'underline', 'italic', 'monospace'],
              },
              blockType: {
                options: [
                  'Normal',
                  'H1',
                  'H2',
                  'H3',
                  'H4',
                  'H5',
                  'H6',
                  'Blockquote',
                ],
              },
            }}
          />
        </div>
      </div>
      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={cancelInvitation}>
          {capFirstLetter(t('cancel'))}
        </Button>
        <Button type={Button.ButtonType.PRIMARY} onClick={onSubmitClicked}>
          {t('send')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default TestSendInvitation
