import { useTranslation } from 'react-i18next'
import DocButton from 'src/document/components/base/DocButton'
import { IActivityLog } from 'src/service/OrgTypes'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import NotificationItem from './NotificationItem'

interface INotificationsProps {
  activityLogs: IActivityLog[]
  closeDropdown: () => void
  loading: boolean
  paginationLoading: boolean
  onLoadMore: () => void
  totalCount: number
  reload: () => void
  hasNextPage: boolean
}

const Notifications = ({
  activityLogs,
  closeDropdown,
  paginationLoading,
  onLoadMore,
  hasNextPage,
  reload,
}: INotificationsProps) => {
  const { t } = useTranslation()

  return (
    <div
      className="notifsCont relative z-0 overflow-y-auto p-6 pr-3 pt-0 pb-2"
      style={{
        height: 'calc(100% - 190px)',
        maxHeight: 'calc(100vh - 190px)',
      }}
    >
      {activityLogs.map((activity, i) => (
        <NotificationItem
          isLastItem={i === activityLogs.length - 1}
          onItemClicked={closeDropdown}
          reload={reload}
          key={activity.id}
          data={activity}
        />
      ))}
      {activityLogs.length === 0 && (
        <div className="flex justify-center items-center w-full min-h-12">
          <p className="text-gray-500 font-thin text-sm italic">
            {t('no_notifications_found')}
          </p>
        </div>
      )}
      {hasNextPage && (
        <div className={'w-full flex justify-center mt-2'}>
          {paginationLoading ? (
            <SpinnerMobile className={'scale-50'} />
          ) : (
            <DocButton
              className={'scale-90'}
              onClick={onLoadMore}
              flat={true}
              text={t('read_more')}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default Notifications
