import Delete from '@icons/delete.svg'
import QrCode from '@icons/qr_code.svg'
import { noop } from 'lodash'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import CircularButton from 'src/components/CircularButton'
import CopyUrl, { CopyUrlProps } from 'src/components/copy/CopyUrl'

interface IConstructionModalToolbar extends CopyUrlProps {
  toggleQrModal: () => void
  openDeleteModal?: () => void
}

const ConstructionModalToolbar: React.FC<
  PropsWithChildren<IConstructionModalToolbar>
> = ({ params, toggleQrModal, openDeleteModal, children }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row flex-wrap items-center gap-2">
      <CircularButton onClick={noop} tooltipText={t('copy_link_to_clipboard')}>
        <CopyUrl params={params} />
      </CircularButton>
      <CircularButton
        onClick={toggleQrModal}
        tooltipText={t('generate_qr_code')}
      >
        <QrCode />
      </CircularButton>
      {openDeleteModal && (
        <CircularButton onClick={openDeleteModal} tooltipText={t('delete')}>
          <Delete />
        </CircularButton>
      )}
      {children}
    </div>
  )
}

export default ConstructionModalToolbar
