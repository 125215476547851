import * as React from 'react'
import { capFirstLetter, classNames } from '../../utility/utils'

interface ITextDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  text?: string
  noBorder?: boolean
  renderChildern?: boolean
  noPaddingX?: boolean
  lowerCase?: boolean
  labelIsWide?: boolean
  isVertical?: boolean
  editingMode?: boolean
  noPaddingY?: boolean
}

export default class TextDisplay extends React.PureComponent<
  ITextDisplayProps,
  any
> {
  private styleClass = {
    tableRow: (
      noBorder: boolean,
      noPaddingX: boolean,
      isVertical: boolean,
      noPaddingY: boolean,
    ) =>
      classNames(
        !isVertical ? 'sm:grid' : 'grid',
        !isVertical ? 'sm:grid-cols-3' : 'grid-rows-2',
        !isVertical ? 'sm:gap-4' : 'grap-1',
        !noPaddingX ? 'px-6' : 'pl-0',
        !isVertical ? 'sm:pr-1' : '',
        !noPaddingY ? (!isVertical ? 'sm:py-4' : 'py-3') : 'py-0',
        !isVertical ? 'my-1' : '',
        !noBorder && 'border-b',
        !noBorder && 'border-gray-200',
      ),
    tableLeftText: (
      noPaddingX: boolean,
      labelIsWide: boolean,
      isVerical: boolean,
      editingMode: boolean,
    ) =>
      classNames(
        'flex',
        'items-center',
        isVerical ? 'flex-wrap' : '',
        'text-sm',
        'leading-5',
        'font-normal',
        editingMode ? 'text-gray-700' : 'text-gray-500',
        !noPaddingX ? (!isVerical ? 'pl-1' : 'pl-0') : 'pl-0',
        isVerical ? 'pb-1' : 'py-2',
        labelIsWide && 'sm:col-span-2',
      ),
    tableCellRight: (labelIsWide: boolean, isVertical: boolean) =>
      classNames(
        'flex',
        'items-center',
        'mt-1',
        'text-sm',
        'leading-5',
        'sm:mt-0',
        !labelIsWide && 'sm:col-span-2',
        !isVertical ? 'pl-1' : '',
      ),
    displayText: (lowerCase: boolean) =>
      classNames('block', !lowerCase ? 'capitalize' : '', 'text-gray-500'),
  }

  public render() {
    const {
      label,
      text,
      noBorder = false,
      renderChildern,
      noPaddingX = false,
      lowerCase = false,
      labelIsWide = false,
      isVertical = false,
      editingMode = false,
      noPaddingY = false,
    } = this.props
    return (
      <div
        className={this.styleClass.tableRow(
          noBorder,
          noPaddingX,
          isVertical,
          noPaddingY,
        )}
      >
        <dt
          className={this.styleClass.tableLeftText(
            noPaddingX,
            labelIsWide,
            isVertical,
            editingMode,
          )}
        >
          {capFirstLetter(label)}
        </dt>
        <dd className={this.styleClass.tableCellRight(labelIsWide, isVertical)}>
          <div className="w-full">
            {renderChildern ? (
              this.props.children
            ) : (
              <span className={this.styleClass.displayText(lowerCase)}>
                {text}
              </span>
            )}
          </div>
        </dd>
      </div>
    )
  }
}
