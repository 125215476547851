import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { capFirstLetter, classNames } from '../../utility/utils'
import Icon from '../icon/Icon'
import { IListContext, ISingleFilter } from './ListContextProvider'
import { IListColumns } from './ListTypes'
import { withListContext } from './withListContext'

interface IFilterItem extends IListContext, WithTranslation {
  filter: ISingleFilter
  column: IListColumns
}

const styleClass = {
  filterItem: classNames(
    'w-full',
    'flex',
    'flex-row',
    'items-center',
    'px-2',
    'py-1',
    'text-sm',
    'leading-5',
    'text-gray-500',
    'hover:bg-gray-100',
    'hover:text-gray-900',
    'focus:bg-gray-100',
    'focus:text-gray-900',
    'w-full',
    'filterItemScroll',
  ),
}

class FilterItem extends PureComponent<IFilterItem> {
  private onItemClick = () => {
    this.props.actions.setFilter(this.props.column, this.props.filter)
  }

  public render() {
    const { name, active } = this.props.filter
    const { t } = this.props
    return (
      <div
        className={styleClass.filterItem}
        style={{ cursor: 'pointer' }}
        onClick={this.onItemClick}
      >
        <Icon
          styleClass={'inline-block mr-2'}
          icon={
            !active ? Icon.IconType.CHECK_BOX_TW : Icon.IconType.CHECKED_BOX_TW
          }
        />
        <span className={'ml-1'}>{capFirstLetter(t(name))}</span>
      </div>
    )
  }
}

export default withTranslation()(withListContext(FilterItem))
