import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Selector from 'src/components/selectors/Selector'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import history from 'src/history'
import {
  createConstructionTask,
  editConstructionTask,
  getConstructionTask,
} from 'src/service/ConstructionTaskService'
import { getConstructionWagon } from 'src/service/ConstructionWagonService'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import {
  IConstructionTask,
  IConstructionWagon,
  IDiscipline,
} from 'src/service/OrgTypes'
import { taskUnits } from 'src/service/SystemValues'
import Input from 'src/ui-elements/input/Input'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { renderDayContents } from 'src/utility/Utility'
import { classNames } from '../../utility/utils'
import { contentStyle } from '../MUtils'
import BottomActionBtns from '../components/BottomActionBtns'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MVognInfo from './MVognInfo'

const MAddWorkops = () => {
  const styleClass = {
    root: classNames('bg-white', 'w-screen', 'h-screen'),
    container: classNames('px-6', 'py-4', 'overflow-y-auto'),
  }

  const { t } = useTranslation()
  const { vid, wopsid: workOpsId } = useParams<{
    vid: string
    wopsid: string
  }>()
  const userContext = useContext(UserContext)
  const projectContext = useContext(ProjectContext)
  const { id: currprojectId } = projectContext.state.currentProject
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')
  const [deadline, setDeadline] = useState<any>()
  const [constructionWagonId, setConstructionWagonId] = useState<number>(0)

  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false)
  const [isEstimate, setIsEstimate] = useState<boolean>(false)

  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('')
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>('')
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [disciplineId, setDisciplineId] = useState<number>(0)
  const [selectedStatus, setSelectedStatus] = useState<string>('open')
  const [unit, setUnit] = useState<string>('')
  const [hoursPerUnit, setHoursPerUnit] = useState<number>(0)
  const [totalUnits, setTotalUnits] = useState<number>(0)
  const [plannedExecutionHours, setPlannedExecutionHours] = useState<number>(0)
  const [productivity, setProductivity] = useState<number>(1.0)
  const [numberOfWorkers, setNumberOfWorkers] = useState<number>(0)

  const onBackClick = () => {
    history.goBack()
  }

  useEffect(() => {
    if (+workOpsId > 0) {
      setIsEditing(true)
      setIsLoading(true)
      getConstructionTask(+workOpsId).then((res) => {
        setTitle(res?.title)
        setDeadline(res?.deadline ? moment(res?.deadline) : moment())
        setDisciplineId(res?.discipline_id)
        setIsEstimate(res?.is_estimate ? res?.is_estimate : false)
        setUnit(res?.unit)
        setHoursPerUnit(res?.hours_per_unit)
        setTotalUnits(res?.total_units)
        setPlannedExecutionHours(res?.planned_execution_hours)
        setProductivity(res?.productivity)
        setNumberOfWorkers(res?.number_of_workers)
        setSelectedStatus(res?.status)
        setIsLoading(false)
      })
    }
  }, [workOpsId])

  useEffect(() => {
    getWagon()
    setConstructionWagonId(+vid)
    getProjectDisciplines(currprojectId).then((res) => {
      setDisciplines(res)
    })
  }, [currprojectId])

  const onChangetitle = (e: any) => {
    setTitle(e.target.value)
    setTitleErrorMessage('')
  }

  const getWagon = async () => {
    setWagon(await getConstructionWagon(+vid))
  }

  const setDatepickerFocus = (focused: any) => {
    setDatePickerFocused(focused.focused)
  }

  const outSideRangeDate = () => {
    return false
  }

  const onChangeDeadline = (time: moment.Moment) => {
    setDeadline(time)
    setDateErrorMessage('')
  }

  const onChangeDisiplin = (id: number) => {
    setDisciplineId(id)
    setDisciplineErrorMessage('')
  }

  const onChangeProductivity = (e: any) => {
    setProductivity(e.target.value)
    setTimeout(() => calculateExecutionHours(), 1000)
  }

  const onChangeTotalUnits = (e: any) => {
    setTotalUnits(e.target.value)
    calculateExecutionHours()
  }

  const onChangeNumberOfWorkers = (e: any) => {
    if (!isEstimate) {
      const workers = e.target.value
      const hours =
        workers > 0 && productivity > 0
          ? (hoursPerUnit * totalUnits) / (workers * productivity)
          : 0.0
      setPlannedExecutionHours(hours)
      setNumberOfWorkers(workers)
    } else {
      setNumberOfWorkers(e.target.value)
    }
  }

  const calculateExecutionHours = () => {
    const hours =
      numberOfWorkers > 0 && productivity > 0.0
        ? (hoursPerUnit * totalUnits) / (numberOfWorkers * productivity)
        : 0.0
    const workers =
      plannedExecutionHours > 0 && productivity > 0.0
        ? (hoursPerUnit * totalUnits) / (plannedExecutionHours * productivity)
        : 0.0
    setPlannedExecutionHours(hours)
    setNumberOfWorkers(workers)
  }

  const onChangeHoursPerUnit = (e: any) => {
    setHoursPerUnit(e.target.value)
    calculateExecutionHours()
  }

  const onChangeIsEstimate = (e: any) => {
    setIsEstimate(e)
  }

  const onChangePlannedExecutionHours = (e: any) => {
    if (!isEstimate) {
      const hours = e.target.value
      const workers =
        hours > 0 && productivity > 0
          ? (hoursPerUnit * totalUnits) / (hours * productivity)
          : 0.0
      setPlannedExecutionHours(hours)
      setNumberOfWorkers(workers)
    } else {
      setPlannedExecutionHours(e.target.value)
    }
  }

  const onSubmitForm = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    let error = false

    if (!title) {
      setTitleErrorMessage(t('fill_out_w_param', { param: t('title') }))
      error = true
    }

    if (
      !moment(deadline).isBetween(
        moment(wagon.startTime),
        moment(wagon.startTime).add(wagon.extended_duration, 'days'),
        'day',
        '[]',
      )
    ) {
      setDateErrorMessage(
        t('date_must_be_between_dates', {
          startDate: moment(wagon.startTime).format('ddd MMM DD YYYY'),
          endDate: moment(wagon.startTime)
            .add(wagon.extended_duration, 'days')
            .format('ddd MMM DD YYYY'),
        }),
      )
      error = true
    }

    if (!deadline) {
      setDateErrorMessage(t('select_w_param', { param: t('deadline') }))
      error = true
    }

    if (!disciplineId) {
      setDisciplineErrorMessage(t('select_w_param', { param: t('discipline') }))
      error = true
    }

    if (!error) {
      const constructionTaskId = isEditing ? +workOpsId : undefined
      const constructionTask: IConstructionTask = {
        id: constructionTaskId,
        title,
        status: selectedStatus,
        deadline,
        unit,
        is_estimate: isEstimate,
        hours_per_unit: hoursPerUnit,
        total_units: totalUnits,
        planned_execution_hours: plannedExecutionHours,
        number_of_workers: numberOfWorkers,
        productivity,
        discipline_id: disciplineId,
        construction_locomotive_id: constructionWagonId,
        project_id: currprojectId,
      }

      const constructionConstructionTaskApiCall = isEditing
        ? editConstructionTask(constructionTask)
        : createConstructionTask(constructionTask, constructionWagonId)
      constructionConstructionTaskApiCall.then(() => {
        history.goBack()
      })
    } else {
      setIsLoading(false)
    }
  }

  const statuses = [
    { id: 'open', name: t('open') },
    { id: 'in_progress', name: t('in_progress') },
    { id: 'done', name: t('done') },
  ]

  return (
    <MPage bgColor={'white'}>
      <>
        <MHeader
          showHomeBtn={true}
          showUser={false}
          user={userContext.state.user}
        />
        <div style={contentStyle} className={styleClass.container}>
          {isLoading ? (
            <div className={'w-full h-full grid place-items-center'}>
              <SpinnerMobile />
            </div>
          ) : (
            <>
              {wagon && (
                <div className={'w-full md:w-1/2'}>
                  <MVognInfo wagon={wagon} noPadding={true} />
                </div>
              )}
              <div className={'md:flex md:items-center'}>
                <div className={'my-4 md:mb-2 w-full md:w-1/2 md:pr-2'}>
                  <Input
                    block={true}
                    label={t('title')}
                    onChange={onChangetitle}
                    value={title}
                    errorMessage={titleErrorMessage}
                    autoFocus={true}
                    noPadding={true}
                    inMobile={true}
                    bgColor={'mobile'}
                    hideLabel={true}
                    placeholder={t('fill_out_w_param', { param: t('title') })}
                  />
                </div>

                <div
                  className={
                    'flex flex-col my-4 md:mb-2 w-full md:w-1/2 md:pl-2'
                  }
                >
                  {dateErrorMessage ? (
                    <label className={'text-xs my-2 text-red-600'}>
                      {dateErrorMessage}
                    </label>
                  ) : null}
                  <SingleDatePicker
                    date={deadline}
                    onDateChange={onChangeDeadline}
                    renderDayContents={renderDayContents}
                    focused={datePickerFocused}
                    onFocusChange={setDatepickerFocus}
                    id="datepicker-wagon"
                    small={true}
                    isOutsideRange={outSideRangeDate}
                    showDefaultInputIcon={true}
                    noBorder={true}
                    numberOfMonths={1}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    required={true}
                    placeholder={t('deadline_required')}
                    hideKeyboardShortcutsPanel={true}
                  />
                </div>
              </div>

              <div className={'md:flex md:items-center'}>
                <div className={'my-4 md:w-1/2'}>
                  <Selector
                    items={disciplines}
                    selectedItemId={disciplineId}
                    onSelect={onChangeDisiplin}
                    label={t('discipline')}
                    dataFields={['shortName', 'name']}
                    required={true}
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    errorMessage={disciplineErrorMessage}
                    hidelabel={false}
                    inMobile={true}
                    bgColor={'mobile'}
                  />
                </div>
                {isEditing && (
                  <div className={'my-4 md:w-1/2 md:pl-2 first-capitalize'}>
                    <Selector
                      items={statuses}
                      selectedItemId={selectedStatus}
                      onSelect={setSelectedStatus}
                      label={t('status')}
                      dataFields={['name']}
                      required={true}
                      fontSize={'sm'}
                      fontWeight={'bold'}
                      hidelabel={false}
                      inMobile={true}
                      bgColor={'mobile'}
                    />
                  </div>
                )}
              </div>

              <div className={'flex items-center'}>
                <div
                  onClick={() => onChangeIsEstimate(!isEstimate)}
                  className={'w-1/2 pr-2'}
                >
                  <p className={'text-gray-500 text-xs font-medium '}>
                    {t('suggested_values')}
                  </p>
                </div>

                <div className={'w-1/2 pl-2'}>
                  <SwitchHOC
                    valueProp={isEstimate}
                    height={20}
                    width={40}
                    className="custom-classname"
                    onChange={onChangeIsEstimate}
                  />
                </div>
              </div>

              <div className={'my-4 flex items-center'}>
                <div className={'w-1/2 pr-2'}>
                  <Selector
                    items={taskUnits(t)}
                    selectedItemId={unit}
                    onSelect={setUnit}
                    label={t('unit')}
                    dataFields={['name']}
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    inMobile={true}
                    bgColor={'mobile'}
                  />
                </div>
                <div className={'w-1/2 pl-2'}>
                  <Input
                    block={true}
                    label={t('total_units')}
                    onChange={onChangeTotalUnits}
                    value={Math.round(Number(totalUnits) * 100) / 100}
                    type={'number'}
                    noPadding={true}
                    inMobile={true}
                    bgColor={'mobile'}
                  />
                </div>
              </div>

              {!isEstimate ? (
                <div className={'my-4 flex items-center'}>
                  <div className={'w-1/2 pr-2'}>
                    <Input
                      block={true}
                      label={t('hours_per_unit')}
                      onChange={onChangeHoursPerUnit}
                      value={Math.round(Number(hoursPerUnit) * 100) / 100}
                      type={'number'}
                      noPadding={true}
                      inMobile={true}
                      bgColor={'mobile'}
                    />
                  </div>
                  <div className={'w-1/2 pl-2'}>
                    <Input
                      block={true}
                      label={t('productivity_factor')}
                      onChange={onChangeProductivity}
                      value={Math.round(Number(productivity) * 100) / 100}
                      type={'number'}
                      noPadding={true}
                      inMobile={true}
                      bgColor={'mobile'}
                    />
                  </div>
                </div>
              ) : null}

              {!isEstimate ? (
                <div className={'my-4'}>
                  <Input
                    block={true}
                    label={t('working_hours')}
                    onChange={() => {
                      /* noop */
                    }}
                    value={
                      Math.round(Number(hoursPerUnit * totalUnits) * 100) / 100
                    }
                    type={'number'}
                    disabled={true}
                    noPadding={true}
                    inMobile={true}
                    bgColor={'mobile'}
                  />
                </div>
              ) : null}
              <div className={'my-4 flex items-center'}>
                <div className={'w-1/2 pr-2'}>
                  <Input
                    block={true}
                    label={t('duration_hours')}
                    onChange={onChangePlannedExecutionHours}
                    value={
                      Math.round(Number(plannedExecutionHours) * 100) / 100
                    }
                    type={'number'}
                    noPadding={true}
                    inMobile={true}
                    bgColor={'mobile'}
                  />
                </div>
                <div className={'w-1/2 pl-2'}>
                  <Input
                    block={true}
                    label={t('number_of_workers')}
                    onChange={onChangeNumberOfWorkers}
                    value={Math.round(Number(numberOfWorkers) * 100) / 100}
                    type={'number'}
                    noPadding={true}
                    inMobile={true}
                    bgColor={'mobile'}
                  />
                </div>
              </div>
              <BottomActionBtns onSave={onSubmitForm} onCancel={onBackClick} />
            </>
          )}
        </div>
      </>
    </MPage>
  )
}

export default MAddWorkops
