import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = PropsWithChildren<{
  header?: string
}>

const FieldGroup = ({ header, children }: Props) => (
  <fieldset>
    <label className="text-d-fontgrey uppercase">{header}</label>
    <div
      className={twMerge(
        'my-2 pl-2 w-2/3 border-l-gray-200 border-l-4',
        header && 'mt-0',
      )}
    >
      {children}
    </div>
  </fieldset>
)

export default FieldGroup
