import * as React from 'react'
import { WithTranslation } from 'react-i18next'
import { IRisk } from '../../service/OrgTypes'
import { IIwithAlartContext } from '../withAlartContext'
import { IWithProjectContext } from '../withProjectContext'

// tslint:disable-next-line
export interface IModalProps
  extends IWithProjectContext,
    IIwithAlartContext,
    WithTranslation {
  closeModal?: () => any
  children?: React.ReactNode
}

export interface IModalState {
  project: number
  riskGroupId: number
  data: any
  risk: IRisk
  modalText: string
  modalType: string
  loading: boolean
  showLogsDefault: boolean
}

export interface IModalContext {
  actions: {
    checkURL: (res: any) => void
    openModal: (
      projectId: number,
      modal: string,
      id: number,
      additionalParams?: string,
    ) => void
  }
  state: IModalState | any
}

export type ModalContext = IModalContext | any

export const ModalContext = React.createContext<ModalContext>({})
