import CheckCircle from '@icons/check_circle.svg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import DocUploadSummery from 'src/document/components/FileUpload/fix-upload/DocUploadSummery'
import { ISystem, ITestSystemGroup, ITestWorkGroup } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { IMPORT_TYPE } from '../notifications/import/ImportNotificationItem'
import SystemUploadList from './SystemUploadList'

export interface ISystemUploadSummary {
  new_records: ISystem[] | ITestSystemGroup[] | ITestWorkGroup[]
  updated_records: ISystem[] | ITestSystemGroup[] | ITestWorkGroup[]
}

interface SystemUploadSummary {
  summary: ISystemUploadSummary
  importType: IMPORT_TYPE
}

const SystemUploadSummary: FC<SystemUploadSummary> = ({
  summary,
  importType,
}) => {
  const { t } = useTranslation()

  const countActive = (records: ISystem[]) => {
    return records.filter((s: ISystem) => s.is_active).length
  }
  const countInactive = (records: ISystem[]) => {
    return records.filter((s: ISystem) => !s.is_active).length
  }
  const isSystemImport = importType === IMPORT_TYPE.SYSTEM_IMPORT
  return (
    <div className="flex flex-col min-h-70vh my-4">
      <Badge
        text={t('import_successfully')}
        icon={<CheckCircle className={'fill-green-900 text-xl mr-1'} />}
        color={BadgeColor.GREEN}
        className={'mb-4'}
      />
      <DocUploadSummery
        reject={false}
        quantity={summary.new_records.length}
        objectImport={{
          isSystemImport: isSystemImport,
          newComponents: true,
          active: isSystemImport
            ? countActive(summary.new_records as ISystem[])
            : summary.new_records.length,
          inactive: isSystemImport
            ? countInactive(summary.new_records as ISystem[])
            : -1,
        }}
      >
        <div className="my-2">
          <SystemUploadList
            importType={importType}
            items={summary.new_records}
          />
        </div>
      </DocUploadSummery>
      <DocUploadSummery
        reject={true}
        quantity={summary.updated_records.length}
        objectImport={{
          isSystemImport: isSystemImport,
          newComponents: false,
          active: isSystemImport
            ? countActive(summary.updated_records as ISystem[])
            : summary.updated_records.length,
          inactive: isSystemImport
            ? countInactive(summary.updated_records as ISystem[])
            : -1,
        }}
      >
        <div className="my-2">
          <SystemUploadList
            items={summary.updated_records}
            importType={importType}
          />
        </div>
      </DocUploadSummery>
    </div>
  )
}

export default SystemUploadSummary
