import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import useDraftMode from 'src/hooks/useDraftMode'
import { getConstructionWagonTasks } from 'src/service/ConstructionTaskService'
import {
  editConstructionWagon,
  getConstructionWagon,
} from 'src/service/ConstructionWagonService'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { trainColors } from 'src/service/SystemValues'
import { createTask, getFilterTasks } from 'src/service/TaskService'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import { Icons } from 'src/ui-elements/icon/Icon'
import Modal from 'src/ui-elements/modal/Modal'
import BooleanInlineInputComponent from 'src/ui-elements/page-display/inline-components/BooleanInlineInputComponent'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { modalUrlBuilder } from 'src/utility/utils'
import {
  IConstructionTask,
  IConstructionWagon,
  ITaskData,
} from '../../service/OrgTypes'
import ConstructionTaskForm from '../construction/tasks/ConstructionTaskForm'
import CleanZone from '../construction/util/CleanZone'
import CheckListDetail from '../construction/wagon/CheckListDetail'
import ConstructionIssueForm from '../task/ConstructionTaskForm'

interface IWagonInspectorPanel {
  wagonId: number
  projectId: number
  open: boolean
  onClose: () => void
  onUpdate: () => void
  defaultIndex: number
}

const WagonInspectorPanel: React.FC<IWagonInspectorPanel> = ({
  open,
  onClose,
  onUpdate,
  wagonId,
  projectId,
  defaultIndex,
}) => {
  const { t } = useTranslation()
  const [wagon, setWagon] = useState<IConstructionWagon>()
  const { getChangesForUpdate } = useInlineDependencyUpdate<IConstructionWagon>(
    setWagon,
    wagon,
  )
  const [cartModal, setCartModal] = useState<boolean>(defaultIndex === 2)
  const [issues, setIssues] = useState<ITaskData[]>()
  const [constructionTasks, setConstructionTasks] = useState<
    IConstructionTask[]
  >([])
  const [createTaskFormModal, setCreateTaskFormModal] = useState(false)
  const createTaskFormModalRef = useRef(createTaskFormModal)
  const [createIssueFormModal, setCreateIssueFormModal] = useState(false)
  const createIssueFormModalRef = useRef(createIssueFormModal)

  const history = useHistory()
  const modalContext = useContext(ModalContext)

  const { draftMode } = useDraftMode()

  const loadWagon = useCallback(() => {
    getConstructionWagon(wagonId, draftMode).then((res) => {
      setWagon(res)
    })
  }, [draftMode, wagonId])

  const loadTasks = () => {
    getConstructionWagonTasks(wagonId).then((res) => {
      setConstructionTasks(res)
    })
  }
  useEffect(() => {
    loadWagon()
  }, [loadWagon])

  const loadIssues = () => {
    const activeFilters = {
      parent_type: ['ConstructionLocomotive'],
      parent_id: [wagonId],
    }
    getFilterTasks(projectId, activeFilters, 1, 500, 'Aksjon').then((res) => {
      setIssues(res.tasks)
    })
  }

  const onChangeInput = async (update: Partial<IConstructionWagon>) => {
    const allUpdates = getChangesForUpdate(update)
    editConstructionWagon({
      ...allUpdates,
      id: wagonId,
    }).then(() => {
      loadWagon()
      onUpdate()
    })
  }

  const onBoardMeetingClick = () => {
    setCartModal(true)
  }

  const onAddConstructionTask = () => {
    setCreateTaskFormModal(true)
    createTaskFormModalRef.current = true
  }

  const onAddIssue = () => {
    setCreateIssueFormModal(true)
    createIssueFormModalRef.current = true
  }

  const onCloseConstructionTaskModal = () => {
    setCreateTaskFormModal(false)
    createTaskFormModalRef.current = false
    loadTasks()
  }

  const onCloseConstructionIssueModal = () => {
    setCreateIssueFormModal(false)
    createIssueFormModalRef.current = false
    loadIssues()
  }
  const getWagonDetail = () => {
    return (
      <InlineComponentsWrapper padding="left">
        <TextInlineInputCompontent
          label="title"
          disabled
          value={wagon?.title}
          validate={(newValue) => {
            if (!newValue?.length) {
              return t('fill_out_w_param', { param: t('title') })
            }
            return undefined
          }}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ title: newValue })
          }}
        />
        <TextInlineInputCompontent
          label="description"
          disabled
          value={wagon?.subject ?? ''}
          validate={(newValue) => {
            if (!newValue?.length) {
              return t('fill_out_w_param', {
                param: t('description'),
              })
            }
            return undefined
          }}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ subject: newValue })
          }}
          textArea={true}
        />

        <DateTimeInlineInputComponent
          label="start_date"
          disabled
          selectedTime={`${wagon?.startTime}`}
          onValueSubmitted={(startTime: moment.MomentInput) => {
            if (startTime) onChangeInput({ startTime: moment(startTime) })
          }}
          validate={(newValue) => {
            if (!newValue) {
              return t('fill_out_w_param', {
                param: t('start_date'),
              })
            }
            if (
              wagon?.construction_train &&
              newValue.isBefore(wagon?.construction_train.startTime, 'day')
            ) {
              return t('can_not_be_before_train_w_date', {
                date: moment(wagon?.construction_train.startTime).format('L'),
              })
            }
            return undefined
          }}
          inspectorPanel={true}
        />

        <TextInlineInputCompontent
          label="wagon_duration_days"
          disabled
          type="number"
          value={`${Math.round(Number(wagon?.duration) * 100) / 100}`}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ duration: +newValue })
          }}
        />

        <TextInlineInputCompontent
          label="working_hours"
          disabled
          value={`${
            Math.round(Number(wagon?.planned_execution_hours) * 100) / 100
          }`}
          type="number"
          validate={(newValue) => {
            if (!newValue?.length) {
              return t('fill_out_w_param', {
                param: t('working_hours'),
              })
            }
            if (Number() / 37.5 > Number(newValue)) {
              return ' '
            }
            return undefined
          }}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ planned_execution_hours: +newValue })
          }}
        />

        <TextInlineInputCompontent
          label="average_staffing"
          disabled
          value={`${Math.round(Number(wagon?.planned_staffing) * 100) / 100}`}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ planned_staffing: +newValue })
          }}
        />

        <TextInlineInputCompontent
          label="actual_consumption_working_hours"
          disabled
          type="number"
          value={`${Math.round(Number(wagon?.actual_work_hours) * 100) / 100}`}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ actual_work_hours: +newValue })
          }}
        />

        <TextInlineInputCompontent
          label="actual_staffing"
          disabled
          type="number"
          value={`${Math.round(Number(wagon?.actual_staffing) * 100) / 100}`}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ actual_staffing: +newValue })
          }}
        />

        <SelectorInlineInputComponent
          items={wagon?.construction_train ? [wagon?.construction_train] : []}
          label={'train'}
          disabled
          selectedId={wagon?.construction_train_id}
          getItemLabel={(constructionTrain) => `${constructionTrain?.title}`}
          validate={(newValue) => {
            if (newValue === undefined) {
              return t('fill_out_w_param', { param: t('train') })
            }
            return
          }}
          initialItem={wagon?.construction_train}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ construction_train_id: +newValue })
          }}
          inspectorPanel={true}
        />

        {wagon?.control_area && (
          <TextInlineInputCompontent
            label="control_area"
            disabled
            value={wagon?.control_area?.title}
            validate={(newValue) => {
              if (!newValue?.length) {
                return t('fill_out_w_param', {
                  param: t('control_area'),
                })
              }
              return undefined
            }}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ title: newValue })
            }}
          />
        )}

        <SelectorInlineInputComponent
          items={CleanZone.info(t)}
          label={'clean_status'}
          disabled
          selectedId={CleanZone.id(wagon?.clean_status)}
          getItemLabel={(status) => `${status?.name}`}
          initialItem={CleanZone.selected(t, wagon?.clean_status)}
          onValueSubmitted={(newValue) => {
            if (newValue)
              onChangeInput({
                clean_status: CleanZone.zone(newValue),
              })
          }}
          inspectorPanel={true}
        />

        <BooleanInlineInputComponent
          label="consider_weekends_as_working_days"
          disabled
          onValueSubmitted={(ignore_weekends) => {
            onChangeInput({ ignore_weekends })
          }}
          value={wagon?.ignore_weekends}
          showCheckBox
        />
        <BooleanInlineInputComponent
          label="consider_holidays_as_working_periods"
          disabled
          onValueSubmitted={(ignore_holidays) => {
            onChangeInput({ ignore_holidays })
          }}
          value={wagon?.ignore_holidays}
          showCheckBox
        />

        <SelectorInlineInputComponent
          getItems={() => getProjectDisciplines(projectId)}
          label={'discipline'}
          disabled
          selectedId={wagon?.discipline_id}
          getItemLabel={(discip) => `${discip?.name}`}
          initialItem={wagon?.discipline}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ discipline_id: newValue })
          }}
          dependencies={[wagon?.contract_id]}
          inspectorPanel={true}
        />

        <TextInlineInputCompontent
          label="number_of_Board_meetings"
          disabled
          type="number"
          value={`${
            Math.round(Number(wagon?.number_of_checklists) * 100) / 100
          }`}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ number_of_checklists: +newValue })
          }}
        />

        <SelectorInlineInputComponent
          items={trainColors(t).map((col) => ({
            ...col,
            trainColor: col.id,
          }))}
          label={'color'}
          disabled
          selectedId={wagon?.train_color}
          getItemLabel={(trainColor) => `${trainColor?.name}`}
          validate={(newValue) => {
            if (newValue === undefined) {
              return t('fill_out_w_param', { param: t('color') })
            }
            return
          }}
          initialItem={
            trainColors(t).filter(
              (item) => item.id === wagon?.construction_train?.train_color,
            )[0]
          }
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ color: newValue })
          }}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="created_at"
          disabled
          selectedTime={wagon?.created_at}
          onValueSubmitted={() => {}}
          inspectorPanel={true}
        />

        <DateTimeInlineInputComponent
          label="updated_at"
          disabled
          selectedTime={wagon?.updated_at}
          onValueSubmitted={() => {}}
          inspectorPanel={true}
        />
      </InlineComponentsWrapper>
    )
  }

  const onActivityClick = (id: number) => {
    const modalURL = modalUrlBuilder(projectId, 'constructiontask', id)
    history.push(modalURL)
    modalContext.actions.checkURL()
  }

  const onIssueClick = (id: number) => {
    const modalURL = modalUrlBuilder(projectId, 'task', id)
    history.push(modalURL)
    modalContext.actions.checkURL()
  }

  const getRows = (): IInspectorSection[] => {
    return [
      {
        name: t('activities'),
        icon: 'spellcheck',
        activeIcon: 'spellcheck',
        isMaterialIcon: true,
        onClick: loadTasks,
        content: (
          <InspectorSectionTable
            headerColumns={[
              t('title'),
              t('status'),
              t('deadline'),
              t('discipline'),
            ]}
            rowsData={constructionTasks?.map((constructionTask) => {
              return {
                cells: [
                  constructionTask?.title ?? '',
                  t(constructionTask.status),
                  moment(constructionTask.deadline).format('L'),
                  constructionTask?.discipline
                    ? constructionTask?.discipline?.shortName +
                      ' ' +
                      constructionTask?.discipline?.name
                    : '',
                ],
                id: constructionTask.id,
              }
            })}
            handleClick={onActivityClick}
          />
        ),
        onAddItem: () => onAddConstructionTask(),
      },
      {
        name: t('cases'),
        icon: Icons.FOLDER_GREY,
        activeIcon: Icons.FOLDER,
        onClick: loadIssues,
        content: (
          <InspectorSectionTable
            headerColumns={[
              t('id'),
              t('title'),
              t('status'),
              t('deadline'),
              t('responsible'),
              t('discipline'),
            ]}
            rowsData={
              issues && issues?.length > 0
                ? issues.map((issue) => {
                    return {
                      cells: [
                        issue?.record_id ?? '',
                        issue.title,
                        t(issue.status),
                        moment(issue.deadline).format('L'),
                        issue?.responsible
                          ? issue?.responsible?.firstName +
                            ' ' +
                            issue?.responsible?.lastName
                          : '',
                        issue?.discipline
                          ? issue?.discipline?.shortName +
                            ' ' +
                            issue?.discipline?.name
                          : '',
                      ],
                      id: issue.id,
                    }
                  })
                : []
            }
            handleClick={onIssueClick}
          />
        ),
        onAddItem: () => onAddIssue(),
      },
      {
        name: t('board_meeting'),
        icon: Icons.FOLDER_GREY,
        onClick: onBoardMeetingClick,
        activeIcon: Icons.FOLDER,
      },
      {
        name: t('comments'),
        icon: <Comment />,
        content: wagonId ? (
          <Comments parentId={wagonId} parentType="ConstructionLocomotive" />
        ) : (
          <span className="text-sm py-1 text-gray-400">
            {t('no_data_found')}
          </span>
        ),
      },
      {
        name: t('change_log'),
        icon: <LegendToggle />,
        content: wagonId ? (
          <ChangeLog parentId={wagonId} parentType="ConstructionLocomotive" />
        ) : (
          <span className="text-sm py-1 text-gray-400">
            {t('no_data_found')}
          </span>
        ),
      },
    ]
  }

  const onCloseInspector = () => {
    if (createTaskFormModalRef.current || createIssueFormModalRef.current) {
      return
    } else {
      onClose()
    }
  }

  const createTaskForConstructionIssues = async (taskData: ITaskData) => {
    createTask(taskData).then(() => {
      loadIssues()
      setCreateIssueFormModal(false)
    })
  }

  return (
    <FixedPane
      title={
        wagon?.record_id
          ? wagon?.record_id + ' - ' + wagon?.title
          : t('loading...')
      }
      show={open}
      onClose={onCloseInspector}
      className={'w-[700px]'}
      disableOutsideClose={true}
      detailPageData={{
        key: DetailPageKeys.WAGON,
        ids: {
          cagId: wagon?.control_area?.control_area_group_id || undefined,
          caId: wagon?.control_area_id,
          trainId: wagon?.construction_train_id,
          wagonId: wagon?.id,
        },
      }}
    >
      <>
        {getWagonDetail()}
        <InspectorSections sections={getRows()} defaultIndex={defaultIndex} />

        {cartModal && wagon ? (
          <Modal
            title={t('board_meeting')}
            show={cartModal}
            size={'w-9/10'}
            maxWidth={null}
            closeModal={() => {
              setCartModal(false)
            }}
          >
            <div className="py-4">
              <CheckListDetail wagon={wagon} onUpdate={() => {}} />
            </div>
          </Modal>
        ) : null}

        {createTaskFormModal ? (
          <ConstructionTaskForm
            open={createTaskFormModal}
            isEditing={false}
            constructionWagonIdProps={wagonId}
            closeModal={onCloseConstructionTaskModal}
            updateList={() => {}}
          />
        ) : null}

        {createIssueFormModal && (
          <Modal
            show={createIssueFormModal}
            closeModal={onCloseConstructionIssueModal}
            title={t('new_case')}
            maxWidth={800}
          >
            <ConstructionIssueForm
              projectId={projectId}
              parentType={'ConstructionLocomotive'}
              parentId={wagonId}
              closeModal={onCloseConstructionIssueModal}
              updateList={() => {}}
              submitTask={createTaskForConstructionIssues}
            />
          </Modal>
        )}
      </>
    </FixedPane>
  )
}
export default WagonInspectorPanel
