import * as React from 'react'
import { useState, useEffect, useContext } from 'react'
import FileDrop from 'src/document/components/FileUpload/FileDrop'
import UploadStatus from 'src/document/components/FileUpload/Status/UploadStatus'
import {
  FileUploadContext,
  UploadedFiles,
} from 'src/document/components/FileUpload/context/FileUploadContext'
import { UPLOAD_STATE } from 'src/document/components/FileUpload/types'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import GenericList from '../List/GenericList'

interface IFileUpload {
  multiple?: boolean
  uploadUrl: string
  accessToken?: string
  onUploadComplete: (uploadedFiles: UploadedFiles) => void
  disabled?: boolean
  type?: string
}

const FileUpload: React.FC<IFileUpload> = ({
  multiple,
  uploadUrl,
  accessToken,
  onUploadComplete,
  disabled,
  type,
}) => {
  const { clearQueue, files, uploadedFiles } = useContext(FileUploadContext)
  const [reloadKey, setReloadKey] = useState(0)

  useEffect(() => {
    const filesUploaded = Object.keys(uploadedFiles).length > 0
    const allUploaded = Object.values(files).every(
      (file) => file.uploadStatus.status !== UPLOAD_STATE.UPLOADING,
    )
    if (filesUploaded && allUploaded) {
      setTimeout(() => {
        clearQueue()
        onUploadComplete(uploadedFiles)
        setReloadKey(reloadKey + 1)
      }, 500)
    }
  }, [uploadedFiles, files, clearQueue, reloadKey, onUploadComplete])

  return (
    <div className="pl-4 py-2 overflow-hidden">
      <FileDrop
        key={reloadKey}
        multiple={multiple}
        uploadUrl={uploadUrl}
        accessToken={accessToken}
        CustomFileUploadingLists={UploadingFilesList}
        height="min-h-[250px]"
        disabled={disabled}
        type={type}
      />
    </div>
  )
}

const UploadingFilesList: React.FC<{
  files: File[]
}> = ({ files }) => {
  const columns: IListColumns[] = [
    {
      name: 'name',
      size: '200',
      id: 'name',
      dataField: 'name',
      cell: (name: string) => {
        return <span>{name}</span>
      },
    },
    {
      name: 'Status',
      size: '278',
      id: 'status',
      dataField: 'name',
      cell: (name: string, _: number) => {
        const file = files.find((f) => f.name === name)
        return file ? <UploadStatus fileInfo={file} /> : <></>
      },
    },
  ]

  return <GenericList data={files} columns={columns} />
}

export default FileUpload
