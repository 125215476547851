import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { IUploadResponse } from 'src/document/components/FileUpload/types'
import { IDocument, PDFHighlightParentInfo } from 'src/service/OrgTypes'
import {
  dataDelete,
  dataDump,
  dataRetrieve,
} from 'src/service/TableKeeperService'
import { getRandomId } from 'src/utility/getRandomId'
import useProjectId from '../hooks/useProjectId'
import { CommentedHighlight } from './types'

export const usePlanPdf = () => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['planPdf', { projectId }],
    queryFn: () => dataRetrieve<IDocument>('planPdf', projectId),
  })
}

export const useHighlights = () => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['highlights', { projectId }],
    queryFn: () => dataRetrieve<CommentedHighlight[]>('highlights', projectId),
  })
}

export const useStorePlanPdf = () => {
  const projectId = useProjectId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: IUploadResponse) => dataDump('planPdf', data, projectId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['planPdf', { projectId }],
      })
    },
  })
}

export const useStoreHighlights = () => {
  const projectId = useProjectId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: CommentedHighlight[]) =>
      dataDump('highlights', data, projectId),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['highlights', { projectId }],
      })
    },
  })
}

export const useRemovePdf = () => {
  const projectId = useProjectId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      await dataDelete('planPdf', projectId)
      return dataDelete('highlights', projectId)
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['planPdf', { projectId }],
      })
      queryClient.invalidateQueries({
        queryKey: ['highlights', { projectId }],
      })
    },
  })
}

export const buildPdfReviewUrl = ({
  highlight_id,
  parent_info,
}: PDFHighlightParentInfo) => {
  const { id, file_container_id, folder_id, file_container_name } = parent_info
  return `/fileRevisionHighlight/${file_container_id}/${id}?documentName=${file_container_name}&folderId=${folder_id}&highlightId=${highlight_id}&key=${getRandomId()}`
}
