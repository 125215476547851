import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPanel } from 'react-tabs'
import { getProjectContracts } from 'src/service/ContractService'
import {
  editDiscipline,
  getDiscipline,
  getProjectDisciplines,
} from 'src/service/DisciplineService'
import { IDiscipline } from 'src/service/OrgTypes'
import PageHeader from 'src/ui-elements/page-display/PageHeader'
import PageRoot from 'src/ui-elements/page-display/PageRoot'
import Select from 'src/ui-elements/select/Select'
import { ContentTabsWrapper } from 'src/ui-elements/tabs/ContentTabs'
import { capFirstLetter } from 'src/utility/utils'
import DateTimeInlineInputComponent from '../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineCompontent from '../../ui-elements/page-display/inline-components/InlineComponent'
import InlineComponentsWrapper from '../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from '../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import { generateShapeList, IShape } from '../discipline/disciplineUtils'
import DisciplineUsersList from '../user/DisciplineUsersList'

interface IDisciplineRoleDetails {
  projectId: number
  disciplineId: number
  onOpenUserInpectorPanel?: (disciplineId: number, userId: number) => void
  refreshTree?: () => void
}

const DisciplineRoleDetailsPage = ({
  projectId,
  disciplineId,
  onOpenUserInpectorPanel,
  refreshTree,
}: IDisciplineRoleDetails) => {
  const { t } = useTranslation()
  const [discipline, setDiscipline] = useState<IDiscipline>()
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])

  const reload = useCallback(() => {
    getDiscipline(disciplineId).then((res) => {
      setDiscipline(res)
    })

    getProjectDisciplines(projectId).then((res) => {
      setDisciplines(res)
    })
  }, [disciplineId, projectId])

  useEffect(() => {
    reload()
  }, [reload])

  const onChangeInput = (update: Partial<IDiscipline>) => {
    editDiscipline({
      ...update,
      id: disciplineId,
    }).then(() => {
      reload()
    })
    if (update.name) refreshTree?.()
  }

  const [tabIndex, setTabIndex] = useState<number>(0)
  const tabItems: string[] = [capFirstLetter(t('users'))]

  const onShapeChange = (item: IShape) => {
    onChangeInput({ color: item.color, shape: item.shape })
  }

  return (
    <PageRoot>
      <PageHeader
        title={
          discipline
            ? `${discipline?.shortName} - ${discipline?.name}`
            : t('loading...')
        }
      />
      <div className="flex pl-3">
        <InlineComponentsWrapper padding="right" border={'right'}>
          <TextInlineInputCompontent
            label={t('name')}
            value={discipline?.name ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            validate={(name) => {
              if (name === undefined || name === '') {
                return t('fill_out_w_param', {
                  param: t('name'),
                })
              }
              return
            }}
          />
          <TextInlineInputCompontent
            label={t('code')}
            value={discipline?.shortName ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ shortName: newValue })
            }}
            validate={(shortName) => {
              if (shortName === undefined || shortName === '') {
                return t('fill_out_w_param', {
                  param: t('code'),
                })
              }
              return
            }}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectContracts(projectId)}
            label="contract"
            initialItem={discipline?.contract}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            validate={(value) => {
              if (value === undefined) return t('fill_out_contract')
              return
            }}
            selectedId={discipline?.contract_id}
            onValueSubmitted={(contract_id) => onChangeInput({ contract_id })}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>

        <InlineComponentsWrapper padding="left">
          <InlineCompontent
            label="icon"
            content={
              <div className={'w-72 mb-2'}>
                <Select
                  items={generateShapeList(disciplines)}
                  selectedCustomItem={
                    discipline && {
                      shape: discipline?.shape,
                      color: discipline?.color,
                    }
                  }
                  isIconSelector={true}
                  required={true}
                  onItemSelect={onShapeChange}
                />
              </div>
            }
            errorMessage={
              !discipline?.color && !discipline?.shape
                ? t('select_discipline_icon')
                : ''
            }
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={discipline?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={discipline?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      </div>
      <div className={`ml-4`}>
        <ContentTabsWrapper
          tabIndex={tabIndex}
          tabItems={tabItems}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabPanel>
            <div
              style={{ minHeight: 350 }}
              className={'ml-3 mr-4 w-auto max-w-lg'}
            >
              <DisciplineUsersList
                disciplineId={disciplineId}
                contractId={discipline?.contract_id}
                projectId={projectId}
                onOpenUserInpectorPanel={onOpenUserInpectorPanel}
                OpenUserInpectorPanel={true}
              />
            </div>
          </TabPanel>
        </ContentTabsWrapper>
      </div>
    </PageRoot>
  )
}

export default DisciplineRoleDetailsPage
