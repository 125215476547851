import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TabPanel } from 'react-tabs'
import KeypointList from 'src/components/key-point/KeypointList'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import { IMetaValue } from 'src/document/types/IMetaData'
import history from 'src/history'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { editMilestone, getMilestone } from 'src/service/MilestoneService'
import { getProjectProcesses, IModalOrigin } from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getErrorMessage,
  getMileStoneErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import PageHeader from 'src/ui-elements/page-display/PageHeader'
import PageRoot from 'src/ui-elements/page-display/PageRoot'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { ContentTabsWrapper } from 'src/ui-elements/tabs/ContentTabs'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter } from 'src/utility/utils'
import { IMilestone, ISystemTypeGroup } from '../../service/OrgTypes'
import { getProjectSystemTypeGroupsPerDomain } from '../../service/SystemTypeGroupService'
import ChangeLog from '../changelog/Changelog'
import Comments from '../comment/Comments'
import CopyUrl from '../copy/CopyUrl'
import DocumentsList from '../document/DocumentsList'
import QrModal from '../qr/QrModal'
import { getMetaDataValues, loadMetaValues } from '../system/SystemUtil'

interface IMilestoneDetailPageProps {
  projectId: number
  milestoneId: number
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  reloadTree?: () => void
}

const MilestoneDetailsPage = ({
  projectId,
  milestoneId,
  onOpenItem,
  reloadTree,
}: IMilestoneDetailPageProps) => {
  const { t } = useTranslation()
  const [milestone, setMilestone] = useState<IMilestone>()
  const [hasOpenKeypoint, setHasOpenKeypoint] = useState(false)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)

  const tabItems: string[] = [
    capFirstLetter(t('keypoints')),
    t('attachment'),
    t('comments'),
    t('change_log'),
  ]

  const detailItems: string[] = [
    capFirstLetter(t('details')),
    capFirstLetter(t('metadata')),
  ]
  const [detailTabIndex, setDetailTabIndex] = useState<number>(0)
  const projectContext = useContext(ProjectContext)
  const { isBaselineAdmin } = projectContext.state

  const { addChangesToAppendWhenKeyUpdates, getChangesForUpdate } =
    useInlineDependencyUpdate<IMilestone>(setMilestone, milestone)

  const { addAlert } = useAlert()

  const [qrString, setQrString] = useState('')
  const [showQrModal, setShowQrModal] = useState(false)

  const origin: IModalOrigin = {
    name: t('table_overview'),
    url: '/milestone?tabIndex=0',
  }

  useEffect(() => {
    getProjectSystemTypeGroupsPerDomain(projectId, 'MileStone').then(
      (resp: ISystemTypeGroup[]) => {
        const value = resp.pop()
        setSystemTypeGroup(value)
        prepareMilestone(value)
      },
    )
  }, [milestoneId])

  const prepareMilestone = (value?: ISystemTypeGroup) => {
    getMilestone(projectId, milestoneId).then(async (milestone) => {
      setMilestone(milestone)
      const metaData = getMetaDataValues(milestone.meta_data)
      setHasOpenKeypoint(
        (milestone.open_children && milestone.open_children > 0) || false,
      )
      if (value) {
        setOptionalFields(
          loadMetaValues(
            milestoneId,
            'MileStone',
            value.optional_fields ?? systemTypeGroup?.optional_fields,
            metaData,
          ),
        )
      }
    })
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const qrString = url
    setQrString(qrString)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setShowQrModal(true)
  }

  const showAlart = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const onChangeInput = (update: Partial<IMilestone>) => {
    const allUpdates = getChangesForUpdate(update)
    editMilestone({
      ...convertUndefinedToNull(allUpdates),
      id: milestone?.id,
    }).then(() => {
      prepareMilestone()
      if (update.name) reloadTree?.()
    })
  }

  const getModalTitle = (): string => {
    return milestone?.record_id
      ? milestone?.record_id + ' - ' + milestone.name
      : t('loading...')
  }

  const onStatusSelect = () => {
    if (milestone && milestone.open_children && milestone.open_children > 0) {
      showAlart(
        'error',
        t('an_error_occurred'),
        getMileStoneErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    const updateMilestone = {
      id: milestoneId,
      status: 'done',
    }

    editMilestone(updateMilestone as IMilestone).then(() => {
      prepareMilestone()
    })
  }

  const additionalButtons = () => {
    return (
      <div className="flex flex-row flex-wrap items-center">
        {milestone && milestone.status && milestone.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}

        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <CopyUrl />
        </span>
        <span
          className={
            'mx-2 w-8 h-8 p-0.5 flex justify-center items-center border-gray-300 border rounded-full hover:cursor-pointer'
          }
        >
          <a title={t('generate_qr_code')} className="flex items-center">
            <Icon
              style={{ width: 20, height: 20 }}
              icon={Icon.IconType.QR}
              onClick={toggleQrModal}
            />
          </a>
        </span>
        <Link
          to={`/main-process/milestone/${milestoneId}?rootUrl=${origin?.url}&rootName=${origin?.name}&showHome=${!!origin?.showHome}${addProjectToUrl()}`}
        >
          <Button size={Button.ButtonSize.SMALL}>
            {t('view_or_plan_keypoints')}
          </Button>
        </Link>
      </div>
    )
  }

  const addProjectToUrl = (): string => {
    const params = new URLSearchParams(history.location.search)
    if (params.has('project')) {
      return `&project=${params.get('project')}`
    }
    return ''
  }

  const metaDataSection = () => {
    const rows = []
    for (let i = 0; i < optionalFields.length; i += 9) {
      const endIndex = i + 9
      rows.push(
        <MetaDataFieldsForPage
          onFieldsUpdate={prepareMilestone}
          fields={optionalFields.slice(i, endIndex)}
          required={false}
          editMode={false}
          border={endIndex < optionalFields.length ? 'right' : undefined}
          inputWidth={'w-64'}
          inspectorPanel={false}
        />,
      )
    }

    return rows
  }

  const mainSection = () => {
    return (
      <div className="flex">
        <InlineComponentsWrapper padding="left" border={'right'}>
          <TextInlineInputCompontent
            label={t('title')}
            value={milestone?.name}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            validate={(value) => {
              if (value === undefined || value === '')
                return getErrorMessage(ValidationError.MISSING_TITLE, t)
              return
            }}
          />
          <SelectorInlineInputComponent
            items={statusTypes(t)}
            label={t('status')}
            getItemLabel={(stat) => stat?.name}
            initialItem={{
              id: milestone?.status ?? '',
              name:
                statusTypes(t).find(
                  (statData) => statData.id === milestone?.status,
                )?.name ?? '',
            }}
            validate={(value) => {
              if (value === undefined)
                return getErrorMessage(ValidationError.MISSING_STATUS, t)
              if (hasOpenKeypoint && value === 'done') {
                return getMileStoneErrorMessage(
                  StructureValidationError.HAS_OPEN_CHILDREN,
                  t,
                )
              }
              return
            }}
            selectedId={milestone?.status}
            onValueSubmitted={(stat) => {
              onChangeInput({ status: stat })
            }}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label={t('deadline')}
            selectedTime={`${milestone?.deadline}`}
            onValueSubmitted={(deadline) => {
              onChangeInput({ deadline: moment(deadline) })
            }}
            validate={(value) => {
              if (!value || !moment(value).isValid()) {
                return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
              }
              return
            }}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="closed_date"
            selectedTime={`${milestone?.closed_date}}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label={t('baseline_date')}
            selectedTime={`${milestone?.baseline}`}
            onValueSubmitted={(baseline) => {
              onChangeInput({ baseline: moment(baseline) })
            }}
            validate={(value) => {
              if (!value || !moment(value).isValid()) {
                return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
              }
              return
            }}
            disabled={!isBaselineAdmin}
            inspectorPanel={false}
          />
          <TextInlineInputCompontent
            label={t('duration_days')}
            value={`${milestone?.duration ?? ''}`}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({
                  duration: parseInt(`${newValue}`, 10),
                })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return undefined
            }}
          />
          <TextInlineInputCompontent
            label={t('delay_days')}
            disabled={true}
            value={`${milestone?.delay ?? 0}`}
          />
          <TextInlineInputCompontent
            label={t('description')}
            textArea={true}
            value={milestone?.description}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ description: newValue })
            }}
          />
        </InlineComponentsWrapper>
        <InlineComponentsWrapper padding="left" border={undefined}>
          <TextInlineInputCompontent
            label={t('keypoints')}
            disabled={true}
            value={`${milestone?.done_children || 0}/${
              (milestone?.open_children || 0) + (milestone?.done_children || 0)
            }`}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={milestone?.discipline}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={milestone?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              addChangesToAppendWhenKeyUpdates('responsible_id', {
                discipline_id,
              })
            }}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              milestone?.discipline_id
                ? getDisplineUsers(milestone?.discipline_id)
                : getProjectUsersWithDisciplines(projectId)
            }
            label="responsible"
            getItemLabel={(responsible) =>
              `${responsible?.firstName} ${responsible?.lastName}`
            }
            initialItem={milestone?.responsible}
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('responsible'),
                })
              return
            }}
            selectedId={milestone?.responsible_id}
            onValueSubmitted={(responsible_id) => {
              onChangeInput({ responsible_id })
            }}
            dependencies={[milestone?.discipline_id]}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            label={'contract'}
            disabled={true}
            selectedId={milestone?.contract_id ?? ''}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            initialItem={milestone?.contract}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectProcesses(projectId)}
            label={t('main_process')}
            getItemLabel={(process) =>
              `${process?.record_id} - ${process?.name}`
            }
            initialItem={milestone?.main_process}
            validate={(value) => {
              if (!value || parseInt(`${value}`, 10) === 0) {
                return getErrorMessage(ValidationError.MISSING_MAIN_PROCESS, t)
              }
              return
            }}
            selectedId={milestone?.main_process_id}
            onValueSubmitted={(val) => {
              onChangeInput({
                main_process_id: val,
                team_id: undefined,
              })
            }}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              milestone?.main_process_id
                ? getMainprocessTeams(milestone?.main_process_id)
                : getProjectTeams(projectId)
            }
            label={t('team')}
            getItemLabel={(team) => team?.name}
            initialItem={milestone?.team}
            selectedId={milestone?.team_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ team_id: val })
            }}
            cancelButton={true}
            dependencies={[milestone?.main_process_id]}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectTags(projectId)}
            label={t('type')}
            getItemLabel={(tag) => tag?.name}
            initialItem={milestone?.tag}
            selectedId={milestone?.tag_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ tag_id: val })
            }}
            cancelButton={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={`${milestone?.created_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={`${milestone?.updated_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }

  return (
    <>
      {milestoneId && (
        <PageRoot>
          <PageHeader
            title={
              milestone?.name
                ? `${milestone?.record_id} - ${milestone?.name}`
                : t('loading...')
            }
            subTitle={''}
            additionalButtons={additionalButtons()}
          />
          <div className="mt-2">
            {optionalFields.length > 0 ? (
              <ContentTabsWrapper
                tabIndex={detailTabIndex}
                tabItems={detailItems}
                onSelect={(index) => setDetailTabIndex(index)}
              >
                <TabPanel>{mainSection()}</TabPanel>
                <TabPanel>
                  <div className="flex">{metaDataSection()}</div>
                </TabPanel>
              </ContentTabsWrapper>
            ) : (
              <>{mainSection()}</>
            )}
          </div>
          {milestoneId && (
            <ContentTabsWrapper
              tabIndex={tabIndex}
              tabItems={tabItems}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabPanel>
                <div className={'mx-4'}>
                  <KeypointList
                    origin={{
                      name: t('milestone'),
                      url: `milestone/overivew/milestone/${milestoneId}`,
                    }}
                    doneParent={milestone ? milestone.status === 'done' : false}
                    isMilestone={true}
                    milestoneId={milestoneId}
                    tableName="MilestoneKeypointTable"
                    onOpenItem={onOpenItem}
                    reloadTree={reloadTree}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className={'mx-4'}>
                  <DocumentsList
                    projectId={projectId}
                    parentId={milestoneId}
                    parentType={'MileStone'}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div className={'mx-4'}>
                  <Comments parentId={milestoneId} parentType={'MileStone'} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className={'mx-4'}>
                  <ChangeLog parentId={milestoneId} parentType={'MileStone'} />
                </div>
              </TabPanel>
            </ContentTabsWrapper>
          )}
          {showQrModal && (
            <QrModal
              show={showQrModal}
              close={() => setShowQrModal(false)}
              title={getModalTitle()}
              value={qrString}
            />
          )}
        </PageRoot>
      )}
    </>
  )
}

export default MilestoneDetailsPage
