import { FC, useState, PropsWithChildren } from 'react'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'

interface DocUploadSummeryProps {
  reject: boolean
  quantity: number
  objectImport?: ObjectSummery
}

interface ObjectSummery {
  isSystemImport: boolean
  newComponents: boolean
  active: number
  inactive: number
}

const DocUploadSummery: FC<PropsWithChildren<DocUploadSummeryProps>> = ({
  reject,
  quantity,
  children,
  objectImport,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const { t } = useMultipleKeysTranslation()

  const label = () => {
    if (objectImport) {
      if (objectImport.newComponents) {
        return t('new')
      } else {
        return t('updated')
      }
    } else {
      if (reject) {
        return t('documents_not_fit')
      } else {
        return t('documents_fit')
      }
    }
  }

  return (
    <div className="w-full">
      <div
        className={
          'flex uppercase flex-col rounded-tr rounded-tl border-x border-t border-d-grey-lightest bg-[#F4F6F7] ' +
          (!show && 'border-b rounded')
        }
      >
        <div className="flex px-4  justify-between w-full h-14 items-center">
          <span className="text-d-fontchip font-semibold text-base">
            {label()}
          </span>
          <div className="flex items-center">
            {!objectImport ? (
              <>
                <span className="text-sm capitalize font-normal">
                  {reject ? t('rejected') : t('import')}
                </span>
                <span className="mx-2 px-2.5 py-1.5 aspect-square flex justify-center items-center rounded-xl text-center text-sm font-normal bg-d-grey-lightest">
                  {quantity}
                </span>
              </>
            ) : (
              <>
                <span className="text-sm capitalize font-normal">
                  {t('active')}
                </span>
                <span className="mx-2 px-2.5 py-1.5 aspect-square flex justify-center items-center rounded-xl text-center text-sm font-normal bg-d-grey-lightest">
                  {objectImport.active}
                </span>
                {objectImport.isSystemImport && (
                  <>
                    <span className="px-4 text-sm capitalize font-normal">
                      {t('inactive')}
                    </span>
                    <span className="mx-2 px-2.5 py-1.5 aspect-square flex justify-center items-center rounded-xl text-center text-sm font-normal bg-d-grey-lightest">
                      {objectImport.inactive}
                    </span>
                  </>
                )}
              </>
            )}
            <MaterialIcon
              onClick={() => {
                if (quantity > 0) {
                  setShow(!show)
                }
              }}
              icon={!show ? 'expand_more' : 'expand_less'}
              key={show ? '1' : '2'}
              className="text-d-fontchip pl-8 text-lg cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className={!show ? 'hidden' : 'pb-4'}>{children}</div>
      {!show && <div className="w-[1140px] mb-4" />}
    </div>
  )
}

export default DocUploadSummery
