import { Component, createContext } from 'react'
import { IAlert, IAlertProps, IAlertType } from './Alert'

export interface IAlertContext {
  actions: {
    addAlert: (alert: any) => number | undefined
    removeAlert: (alert: number) => void
    showAlert: (type: IAlertType, title: string, description: string) => void
  }
  state: { alerts: IAlert[] }
}

interface IAlertContextState {
  alerts: IAlert[]
}

export type AlertContextType = IAlertContext

export const AlertContext = createContext<AlertContextType>(
  {} as AlertContextType,
)
export const AlertContextConsumer = AlertContext.Consumer

export default class AlertContextProvider extends Component<
  any,
  IAlertContextState
> {
  public constructor(props: any, context?: any) {
    super(props, context)
    this.state = {
      alerts: [],
    }
  }

  private getRandomId = (): number => {
    return Math.floor(100000 + Math.random() * 900000)
  }

  private addAlert = (newAlert: IAlertProps) => {
    const alert: IAlertProps = { ...newAlert, alertId: this.getRandomId() }
    const alerts = [...this.state.alerts, alert]
    this.setState({ alerts })
    return alert.alertId
  }

  private removeAlert = (id: number) => {
    const alerts = this.state.alerts
    const index = alerts.findIndex((al) => al.alertId === id)
    alerts.splice(index, 1)
    this.setState({ alerts })
  }

  private showAlert = (
    type: IAlertType,
    title: string,
    description: string,
  ) => {
    this.addAlert({ type, title, description })
  }

  public render() {
    const { state } = this
    const actions = {
      addAlert: (newAlert: IAlert) => this.addAlert(newAlert),
      removeAlert: (index: number) => this.removeAlert(index),
      showAlert: (type: IAlertType, title: string, description: string) =>
        this.showAlert(type, title, description),
    }
    const context = { actions, state }

    return (
      <AlertContext.Provider value={context}>
        {this.props.children}
      </AlertContext.Provider>
    )
  }
}
