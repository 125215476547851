import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import MultiSelector from 'src/components/multi-selector/MultiSelector'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import {
  getFilterValues,
  setFilterValues,
} from 'src/service/CanavasFilterService'
import { getProjectContracts } from 'src/service/ContractService'
import { getProjectControlAreas } from 'src/service/ControlAreaService'
import { getProjectContractDisciplines } from 'src/service/DisciplineService'
import {
  IContract,
  IControlArea,
  IDiscipline,
  IRoom,
  ITag,
  IUserData,
} from 'src/service/OrgTypes'
import { getProjectRooms } from 'src/service/RoomService'
import { getProjectTags } from 'src/service/TagService'
import { getProjectUsers } from 'src/service/UserService'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import useWindowDimensions from 'src/ui-elements/page-display/UseWindowDimensions'
import { classNames } from '../../utility/utils'

export interface IIssueListData {
  selectedDisplineIds: number[]
  selectedContractIds: number[]
  selectedUserIds: number[]
  selectedTagsIds: number[]
  selectedControlAreaIds: number[]
  selectedRoomIds: number[]
  showOpenItems: boolean
  showDelayedItems: boolean
}

interface IMIssueFilterProps {
  projectId: number
  showUser: boolean
  onFiltersChange: (f: IIssueListData) => void
  onFilterClear?: () => void
  onOpen: (val: boolean) => void
  inWagonTab?: boolean
  filterName: string
  myDisciplinesOnly: boolean
  myFilters?: { myDisciplines: IDiscipline[]; myContracts: IContract[] }
}

const styleClass = {
  root: classNames('z-40', 'mx-2', 'my-1'),
  rootFloating: (hide?: boolean) =>
    classNames(
      'absolute',
      'top-20',
      'right-16',
      'z-40',
      hide ? 'invisible' : '',
    ),
  icon: (opened: boolean) =>
    classNames(
      'w-7',
      'h-7',
      opened ? 'rounded-full shadow-md' : '',
      opened ? 'bg-gray-500 mt-1' : '',
      'text-white',
      'flex',
      'justify-center',
      'items-center',
      'cursor-pointer',
      'relative',
    ),
  filter: classNames(
    'flex',
    'flex-col',
    'absolute',
    'right-0',
    'bg-white',
    'shadow-xl',
    'w-screen',
    'top-11',
    'rounded-t-xl',
  ),
  rootFilter: classNames(
    'flex',
    'flex-col',
    'items-left',
    'justify-between',
    'flex-nowrap',
    'overflow-y-auto',
    'w-full',
    'bg-white',
  ),
  filterSelector: classNames('mr-6', 'mb-2', 'w-full'),
  switch: (halfWidth: boolean, right?: boolean) =>
    classNames(
      right ? '' : 'mr-5',
      'w-full',
      'flex',
      right ? 'justify-end' : 'justify-start',
      'items-end',
      halfWidth ? 'w-1/2' : 'sm:w-1/2 lg:w-1/3',
      'py-2',
      'mb-0.8',
      'max-w-30',
    ),
  booleanFilters: (noBooleanFilters: boolean) =>
    classNames(
      'flex',
      'justify-between',
      'w-full',
      noBooleanFilters ? 'my-3' : '',
    ),
  label: {
    root: classNames(
      'absolute',
      'p-1',
      'rounded',
      'bg-black',
      'text-xxs',
      'z-10',
      'font-normal',
      'text-white',
    ),
    label: classNames(
      'text-sm',
      'leading-5',
      'font-medium',
      'text-gray-600',
      'pr-2',
      'w-mx-content',
      'my-2',
    ),
  },
  filterBox: classNames(
    'bg-white',
    'font-normal',
    'border-gray-300',
    'border',
    'shadow-sm',
    'flex',
    'flex-col',
    'justify-start',
    'text-capitalize',
    'px-1',
  ),
}

const MIssueFilter: React.FC<IMIssueFilterProps> = ({
  projectId,
  onFiltersChange,
  onOpen,
  onFilterClear,
  showUser,
  filterName,
  inWagonTab = false,
  myFilters,
}: IMIssueFilterProps) => {
  const { t } = useTranslation()
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [contracts, setContracts] = useState<IContract[]>([])
  const [users, setUsers] = useState<IUserData[]>([])
  const [tags, setTags] = useState<ITag[]>([])
  const [controlAreas, setControlAreas] = useState<IControlArea[]>([])
  const [rooms, setRooms] = useState<IRoom[]>([])

  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([])
  const [selectedContracts, setSelectedContracts] = useState<number[]>([])
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [selectedTags, setSelectedTags] = useState<number[]>([])
  const [selectedControlAreas, setSelectedControlAreas] = useState<number[]>([])
  const [selectedRooms, setSelectedRooms] = useState<number[]>([])
  const [openSelected, setOpenSelected] = useState<boolean>(false)
  const [delayedSelected, setDelayedSelected] = useState<boolean>(false)

  const [isContractFirst, setIsContractFirst] = useState<boolean>(true)
  const [isdisciplineFirst, setIsDisciplineFirst] = useState<boolean>(true)
  const [isUserFirst, setIsUserFirst] = useState<boolean>(true)
  const [isTagFirst, setIsTagFirst] = useState<boolean>(true)
  const [isControlAreaFirst, setIsControlAreaFirst] = useState<boolean>(true)
  const [isRoomFirst, setIsRoomFirst] = useState<boolean>(true)

  const [contractLoading, setContractLoading] = useState<boolean>(false)
  const [disciplineLoading, setDisciplineLoading] = useState<boolean>(false)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [tagLoading, setTagLoading] = useState<boolean>(false)
  const [controlAreaLoading, setControlAreaLoading] = useState<boolean>(false)
  const [roomLoading, setRoomLoading] = useState<boolean>(false)

  const [open, setOpen] = useState<boolean>(false)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const { width } = useWindowDimensions()

  const initState = useRef<IIssueListData>({
    selectedContractIds: [],
    selectedDisplineIds: [],
    selectedUserIds: [],
    selectedControlAreaIds: [],
    selectedRoomIds: [],
    showDelayedItems: false,
    showOpenItems: filterName === 'GlobalIssueListFilter' ? true : false,
    selectedTagsIds: [],
  })

  useEffect(() => {
    setSelectedContracts([])
    setSelectedDisciplines([])
    setSelectedUsers([])
    setSelectedTags([])
    setSelectedControlAreas([])
    setSelectedRooms([])
    setOpenSelected(false)
    setDelayedSelected(false)
    loadCache()
  }, [projectId])

  const onClear = () => {
    setIsDisciplineFirst(true)
    setIsContractFirst(true)
    setIsUserFirst(true)
    setIsControlAreaFirst(true)
    setIsRoomFirst(true)
    setSelectedContracts(initState.current.selectedContractIds)
    setSelectedDisciplines(initState.current.selectedDisplineIds)
    setSelectedUsers(initState.current.selectedUserIds)
    setSelectedTags(initState.current.selectedTagsIds)
    setSelectedControlAreas(initState.current.selectedControlAreaIds)
    setSelectedRooms(initState.current.selectedRoomIds)
    setOpenSelected(false)
    setDelayedSelected(initState.current.showDelayedItems)
    setFiltersApplied(false)
    if (onFilterClear) {
      onFilterClear()
    }
    onFiltersChange({ ...initState.current, showOpenItems: false })
    setFilterValues(projectId, filterName, initState.current)
    onToggleClose()
  }

  useEffect(() => {
    if (
      selectedContracts?.length > 0 ||
      selectedDisciplines?.length > 0 ||
      selectedUsers?.length > 0 ||
      openSelected ||
      delayedSelected
    ) {
      setFiltersApplied(true)
    } else {
      setFiltersApplied(false)
    }
  }, [
    selectedUsers,
    selectedContracts,
    selectedDisciplines,
    openSelected,
    delayedSelected,
  ])

  const setFilterToTableKeeper = (filterData: IIssueListData) => {
    setFilterValues(projectId, filterName, filterData)
  }

  const loadCache = async () => {
    const cachedFilters = await getFilterValues(projectId, filterName)
    const filters: IIssueListData =
      cachedFilters && !Array.isArray(cachedFilters)
        ? cachedFilters
        : initState.current
    if (filters) {
      setSelectedUsers(filters?.selectedUserIds ?? [])
      setSelectedTags(filters?.selectedTagsIds ?? [])
      setOpenSelected(filters?.showOpenItems ?? false)
      setDelayedSelected(filters?.showDelayedItems ?? false)
      setSelectedControlAreas(filters?.selectedControlAreaIds ?? [])
      setSelectedRooms(filters?.selectedRoomIds ?? [])
      if (myFilters) {
        setDisciplines(myFilters.myDisciplines)
        setContracts(myFilters.myContracts)
        const disciplineIds: number[] = myFilters.myDisciplines.map(
          (disp) => disp.id,
        )
        const contractIds: number[] = myFilters.myContracts.map(
          (contract) => contract.id,
        )
        setSelectedDisciplines(disciplineIds)
        setSelectedContracts(contractIds)
        onFiltersChange({
          ...filters,
          selectedDisplineIds: disciplineIds,
          selectedContractIds: contractIds,
        })
      } else {
        setSelectedContracts(filters?.selectedContractIds ?? [])
        setSelectedDisciplines(filters?.selectedDisplineIds ?? [])
        onFiltersChange(filters)
      }
    }
  }

  const getCurrentFilters = (): IIssueListData => {
    return {
      selectedDisplineIds: selectedDisciplines,
      selectedContractIds: selectedContracts,
      selectedUserIds: selectedUsers,
      showOpenItems: openSelected,
      showDelayedItems: delayedSelected,
      selectedTagsIds: selectedTags,
      selectedControlAreaIds: selectedControlAreas,
      selectedRoomIds: selectedRooms,
    }
  }

  const onOpenContract = () => {
    return new Promise<void>(async (resolve) => {
      if (isContractFirst) {
        setContractLoading(true)
        const allContracts = await getProjectContracts(projectId)
        setContracts(allContracts)
        setContractLoading(false)
        setIsContractFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenDiscipline = () => {
    return new Promise<void>(async (resolve) => {
      if (isdisciplineFirst) {
        setDisciplineLoading(true)
        const allDisciplines = await getProjectContractDisciplines(projectId, {
          contract: selectedContracts,
        })
        setDisciplines(allDisciplines)
        setDisciplineLoading(false)
        setIsDisciplineFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenUser = () => {
    return new Promise<void>(async (resolve) => {
      if (isUserFirst) {
        setUserLoading(true)
        const allUsers = await getProjectUsers(projectId)
        setUsers(allUsers)
        setUserLoading(false)
        setIsUserFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenTag = () => {
    return new Promise<void>(async (resolve) => {
      if (isTagFirst) {
        setTagLoading(true)
        const allTags = await getProjectTags(projectId)
        setTags(allTags)
        setTagLoading(false)
        setIsTagFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenControlArea = () => {
    return new Promise<void>(async (resolve) => {
      if (isControlAreaFirst) {
        setControlAreaLoading(true)
        const allCAreas = await getProjectControlAreas(projectId)
        setControlAreas(allCAreas)
        setControlAreaLoading(false)
        setIsControlAreaFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onOpenRoom = () => {
    return new Promise<void>(async (resolve) => {
      if (isRoomFirst) {
        setRoomLoading(true)
        const allRooms = await getProjectRooms(projectId)
        setRooms(allRooms)
        setRoomLoading(false)
        setIsRoomFirst(false)
        resolve()
      }
      resolve()
    })
  }

  const onChangeContract = async (selectedids: number[]) => {
    setSelectedContracts(selectedids)
    setDisciplineLoading(true)
    const allDisciplines = await getProjectContractDisciplines(projectId, {
      contract: selectedids,
    })
    setDisciplines(allDisciplines)
    if (selectedids.length > 0) {
      const newSelectedDiscipline = allDisciplines.map(
        (discipline: any) => discipline.id,
      )
      setSelectedDisciplines(newSelectedDiscipline)
      const newFilter = getCurrentFilters()
      newFilter.selectedContractIds = selectedids
      newFilter.selectedDisplineIds = newSelectedDiscipline
      setFilterToTableKeeper(newFilter)
      onFiltersChange(newFilter)
    } else {
      setSelectedDisciplines([])
      const newFilter = getCurrentFilters()
      newFilter.selectedContractIds = selectedids
      newFilter.selectedDisplineIds = []
      setFilterToTableKeeper(newFilter)
      onFiltersChange(newFilter)
    }
    setDisciplineLoading(false)
  }

  const onChangeDiscipline = async (selectedids: number[]) => {
    setSelectedDisciplines(selectedids)

    if (selectedids.length === 0) {
      setSelectedContracts([])
      const allDisciplines = await getProjectContractDisciplines(projectId, {
        contract: [],
      })
      setDisciplineLoading(true)
      setDisciplines(allDisciplines)
      setDisciplineLoading(false)
      return
    }
    const selectedContractIds = new Set<number>()
    const selectedDisciplineObj = disciplines.filter(
      (discipline) => selectedids.indexOf(discipline.id) !== -1,
    )
    contracts.filter((contract) => {
      selectedDisciplineObj.map((disp) => {
        if (disp.contract_id === contract.id) {
          selectedContractIds.add(contract.id)
        }
      })
    })
    const contractsByDiscipline = contracts
      .filter((contract: IContract) => selectedContractIds.has(contract.id))
      .map((contr) => contr.id)
    setSelectedContracts(contractsByDiscipline)

    const newFilter = getCurrentFilters()
    newFilter.selectedDisplineIds = selectedids
    newFilter.selectedContractIds = contractsByDiscipline
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onChangeUser = async (selectedids: number[]) => {
    setSelectedUsers(selectedids)
    const newFilter = getCurrentFilters()
    newFilter.selectedUserIds = selectedids
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onChangeTag = async (selectedids: number[]) => {
    setSelectedTags(selectedids)
    const newFilter = getCurrentFilters()
    newFilter.selectedTagsIds = selectedids
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onChangeControlArea = async (selectedids: number[]) => {
    setSelectedControlAreas(selectedids)
    const newFilter = getCurrentFilters()
    newFilter.selectedControlAreaIds = selectedids
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onChangeRoom = async (selectedids: number[]) => {
    setSelectedRooms(selectedids)
    const newFilter = getCurrentFilters()
    newFilter.selectedRoomIds = selectedids
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onDoneChange = async (v: boolean) => {
    setDelayedSelected(v)
    const newFilter = getCurrentFilters()
    if (v && openSelected) {
      setOpenSelected(false)
      newFilter.showOpenItems = false
    }
    newFilter.showDelayedItems = v
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onOpenChange = async (v: boolean) => {
    setOpenSelected(v)
    const newFilter = getCurrentFilters()
    if (v && delayedSelected) {
      setDelayedSelected(false)
      newFilter.showDelayedItems = false
    }
    newFilter.showOpenItems = v
    setFilterToTableKeeper(newFilter)
    onFiltersChange(newFilter)
  }

  const onClose = (e: React.MouseEvent) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const onToggleClose = () => {
    setOpen((n) => {
      onOpen(false)
      return !n
    })
  }

  const filterContainerStyles = {
    background: 'rgba(0,0,0,0.3)',
    height: 'calc(100vh - 72px)',
  }

  const getRootHeight = () =>
    width >= 768
      ? { height: 'auto' }
      : inWagonTab
        ? { height: 'calc(100vh - 179px)' }
        : { height: 'calc(100vh - 117px' }

  return (
    <div className={styleClass.root}>
      <div onClick={() => onToggleClose()} className={styleClass.icon(open)}>
        <span className={`w-2/3 h-2/3 ${open ? 'flex' : ''}`}>
          <Icon
            icon={
              open
                ? Icon.IconType.CLOSE_WHITE
                : filtersApplied
                  ? Icon.IconType.FILTER_BLUE_FILLED
                  : Icon.IconType.FILTER_OUTLINE
            }
          />
        </span>
      </div>
      {open && (
        <div style={filterContainerStyles} className={styleClass.filter}>
          <CloseClickOutside onClose={onClose}>
            <div style={getRootHeight()} className={styleClass.rootFilter}>
              <div className={'px-5 pt-4 pb-5'}>
                <div className={styleClass.filterSelector}>
                  <div className={'w-full'}>
                    <MultiSelector
                      items={contracts}
                      onOpenList={onOpenContract}
                      onSelect={onChangeContract}
                      label={t('contracts')}
                      hidelabel={false}
                      selectedItems={selectedContracts}
                      dataFields={['contractNumber', 'contractName']}
                      fontWeight={'bold'}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                      inMobile={true}
                      loading={contractLoading}
                    />
                  </div>
                </div>
                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={disciplines}
                    onOpenList={onOpenDiscipline}
                    label={t('disciplines')}
                    hidelabel={false}
                    dataFields={['shortName', 'name']}
                    selectedItems={selectedDisciplines}
                    fontWeight={'bold'}
                    onSelect={onChangeDiscipline}
                    loading={disciplineLoading}
                    scroll={true}
                    noBorder={true}
                    bgColor={'white'}
                    inMobile={true}
                  />
                </div>

                {showUser && (
                  <div className={styleClass.filterSelector}>
                    <MultiSelector
                      items={users}
                      label={t('responsible')}
                      onOpenList={onOpenUser}
                      dataFields={['firstName', 'lastName']}
                      selectedItems={selectedUsers}
                      fontWeight={'bold'}
                      onSelect={onChangeUser}
                      loading={userLoading}
                      scroll={true}
                      noBorder={true}
                      bgColor={'white'}
                      inMobile={true}
                    />
                  </div>
                )}

                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={tags}
                    label={t('type')}
                    onOpenList={onOpenTag}
                    dataFields={['name']}
                    selectedItems={selectedTags}
                    fontWeight={'bold'}
                    onSelect={onChangeTag}
                    loading={tagLoading}
                    scroll={true}
                    noBorder={true}
                    bgColor={'white'}
                    inMobile={true}
                  />
                </div>

                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={controlAreas}
                    label={t('control_areas')}
                    onOpenList={onOpenControlArea}
                    dataFields={['title']}
                    selectedItems={selectedControlAreas}
                    fontWeight={'bold'}
                    onSelect={onChangeControlArea}
                    loading={controlAreaLoading}
                    scroll={true}
                    noBorder={true}
                    bgColor={'white'}
                    inMobile={true}
                  />
                </div>

                <div className={styleClass.filterSelector}>
                  <MultiSelector
                    items={rooms}
                    label={t('rooms')}
                    onOpenList={onOpenRoom}
                    dataFields={['room_name']}
                    selectedItems={selectedRooms}
                    fontWeight={'bold'}
                    onSelect={onChangeRoom}
                    loading={roomLoading}
                    scroll={true}
                    noBorder={true}
                    bgColor={'white'}
                    inMobile={true}
                  />
                </div>

                <div className={styleClass.booleanFilters(false)}>
                  <div className={styleClass.switch(true)}>
                    <span className={'mx-2  text-sm font-medium text-gray-600'}>
                      {t('delayed')}
                    </span>
                    <SwitchHOC
                      valueProp={delayedSelected}
                      className="custom-classname"
                      onChange={(v) => onDoneChange(v)}
                    />
                  </div>

                  <div className={styleClass.switch(true, true)}>
                    <span className={'mx-2 text-sm font-medium text-gray-600'}>
                      {t('open')}
                    </span>
                    <SwitchHOC
                      valueProp={openSelected}
                      className="custom-classname"
                      onChange={(v) => onOpenChange(v)}
                    />
                  </div>
                </div>
              </div>
              <div
                className={'w-full flex justify-between px-4 py-2 bg-gray-100'}
              >
                <Button
                  onClick={onClear}
                  type={Button.ButtonType.WARNING}
                  size={ButtonSize.XSMALL}
                  noTextWrap={true}
                  inMobile={true}
                >
                  {t('reset')}
                  <Icon
                    icon={Icon.IconType.CLOSE_GRAY}
                    className={'ml-2 w-4 h-4 flex'}
                  />
                </Button>
                <Button
                  onClick={onToggleClose}
                  type={Button.ButtonType.SUCCESS}
                  inMobile={true}
                  size={ButtonSize.XSMALL}
                  noTextWrap={true}
                >
                  {t('done')}
                  <Icon
                    icon={Icon.IconType.CHECK_WHITE_CIRLCE}
                    className={'ml-2 w-4 h-4 flex'}
                  />
                </Button>
              </div>
            </div>
          </CloseClickOutside>
        </div>
      )}
    </div>
  )
}

export default MIssueFilter
