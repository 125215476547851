import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CircularButton from 'src/components/CircularButton'
import QrModal from 'src/components/qr/QrModal'
import { IImprovement } from 'src/service/OrgTypes'
import useAlert from 'src/ui-elements/toast/useAlert'

interface ImprovementSharingButtonsProps {
  improvement: IImprovement
  projectId: number
}

const ImprovementSharingButtons = ({
  improvement,
  projectId,
}: ImprovementSharingButtonsProps) => {
  const { t } = useTranslation()
  const { addAlert } = useAlert()

  const [qrString, setQrString] = useState<string>()

  const generateImprovementUrl = () => {
    const url = window.location.href.split('?')[0]
    return (
      url + '?modal=improvement&id=' + improvement.id + '&project=' + projectId
    )
  }

  const copyImprovementUrlToClipBoard = () => {
    const url = generateImprovementUrl()
    navigator.clipboard.writeText(url)
    addAlert({
      type: 'info',
      title: t('copy_link_to_clipboard'),
      description: '',
    })
  }

  return (
    <>
      <CircularButton
        mdIcon={'qr_code'}
        onClick={() => setQrString(generateImprovementUrl())}
        tooltipText={t('generate_qr_code')}
      />
      <CircularButton
        mdIcon={'share'}
        onClick={copyImprovementUrlToClipBoard}
        tooltipText={t('copy_link_to_clipboard')}
      />
      <QrModal
        show={qrString !== undefined}
        close={() => setQrString(undefined)}
        title={`${improvement.record_id} - ${improvement.title}`}
        value={qrString || ''}
      />
    </>
  )
}

export default ImprovementSharingButtons
