import { useTranslation } from 'react-i18next'
import { ISystemTaskTypeRequirement } from 'src/fdvu/types/IRequirementStatus'
import { SystemTaskTypes } from 'src/service/OrgTypes'
import { classNames } from 'src/utility/utils'

const taskTypeColorMapper: { [key in SystemTaskTypes]: string } = {
  [SystemTaskTypes.MARKING]: 'bg-blue-root',
  [SystemTaskTypes.CABLING]: 'bg-violet-500',
  [SystemTaskTypes.DELIVERY]: 'bg-yellow',
  [SystemTaskTypes.PROJECT_PLANNING]: 'bg-cyan-400',
  [SystemTaskTypes.FUNCTION_RESPONSIBLE]: 'bg-pink-400',
  [SystemTaskTypes.INTEGRATION]: 'bg-green-800',
}

const auxilaryTaskTypeColorMapper: { [key in SystemTaskTypes]: string } = {
  [SystemTaskTypes.MARKING]: 'from-blue-root',
  [SystemTaskTypes.CABLING]: 'from-violet-500',
  [SystemTaskTypes.DELIVERY]: 'from-yellow',
  [SystemTaskTypes.PROJECT_PLANNING]: 'from-cyan-400',
  [SystemTaskTypes.FUNCTION_RESPONSIBLE]: 'from-pink-400',
  [SystemTaskTypes.INTEGRATION]: 'from-green-800',
}

export const SystemTaskIcon = ({
  taskType,
  isAuxiliary,
  size = 24,
}: {
  taskType: SystemTaskTypes
  isAuxiliary?: boolean
  size?: number
}) => {
  const { t } = useTranslation()

  return (
    <p
      key={taskType}
      style={{
        width: size,
        height: size,
      }}
      className={classNames(
        'text-center text-neutral-50 font-bold',
        isAuxiliary && `bg-gradient-to-br to-50% to-black from-50%`,
        isAuxiliary
          ? auxilaryTaskTypeColorMapper[taskType]
          : taskTypeColorMapper[taskType],
      )}
    >
      {t(taskType).charAt(0).toUpperCase()}
    </p>
  )
}

const TaskCell = ({ value = [] }: { value: ISystemTaskTypeRequirement[] }) => {
  const items = value.length > 4 ? value.slice(0, 3) : value.slice(0, 4)
  return (
    <div
      className={
        'grid grid-cols-2 grid-rows-2 justify-items-center items-center text-[10px] font-bold h-[28px] leading-[11px]'
      }
    >
      {items.map((task) => (
        <SystemTaskIcon
          size={12}
          key={task.taskType}
          taskType={task.taskType}
          isAuxiliary={task.isAuxiliary}
        />
      ))}
      {value.length > 4 && (
        <p className="text-center w-[12px] h-[12px] text-neutral-50 font-bold bg-gray-400">
          ...
        </p>
      )}
    </div>
  )
}

export default TaskCell
