import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import DocumentTypeService from '../../document/services/DocumentTypeService'

export const useDocumentType = () => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['document-type', projectId],
    queryFn: () => DocumentTypeService.getProjectDocumentTypes(),
  })
}
