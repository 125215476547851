import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPanel } from 'react-tabs'
import PageHeader from 'src/ui-elements/page-display/PageHeader'
import PageRoot from 'src/ui-elements/page-display/PageRoot'
import { ContentTabsWrapper } from 'src/ui-elements/tabs/ContentTabs'
import { capFirstLetter } from 'src/utility/utils'
import { editContract, getContract } from '../../service/ContractService'
import { IContract } from '../../service/OrgTypes'
import DateTimeInlineInputComponent from '../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from '../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import TextInlineInputCompontent from '../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import DisciplinesList from '../discipline/DisciplinesList'

interface IContractsDetailPageProps {
  projectId: number
  contractId: number
  refreshTree?: () => void
  onTreeSelect?: (id: number, type: string) => void
}

const ContractDisciplineDetailPage = ({
  projectId,
  contractId,
  refreshTree,
  onTreeSelect,
}: IContractsDetailPageProps) => {
  const { t } = useTranslation()
  const [contract, setContract] = useState<IContract>()

  const reload = () => {
    getContract(contractId).then((res) => {
      setContract(res)
    })
  }

  useEffect(() => {
    reload()
  }, [projectId, contractId])

  const onChangeInput = (update: Partial<IContract>) => {
    editContract({
      ...update,
      id: contractId,
    }).then(() => {
      reload()
    })
    if (update.contractName || update.contractNumber) refreshTree?.()
  }

  const [tabIndex, setTabIndex] = useState<number>(0)
  const tabItems: string[] = [capFirstLetter(t('disciplines'))]

  return (
    <PageRoot>
      <PageHeader
        title={
          contract
            ? `${contract?.contractNumber} - ${contract?.contractName}`
            : t('loading...')
        }
      />
      <div className="flex pl-3">
        <InlineComponentsWrapper padding="right" border={'right'}>
          <TextInlineInputCompontent
            label={t('contract_name')}
            value={contract?.contractName ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ contractName: newValue })
            }}
            validate={(contractName) => {
              if (contractName === undefined || contractName === '') {
                return t('fill_out_w_param', {
                  param: t('contractName'),
                })
              }
              return
            }}
          />
          <TextInlineInputCompontent
            label={t('contract_number')}
            value={contract?.contractNumber ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ contractNumber: newValue })
            }}
          />
          <TextInlineInputCompontent
            label={t('company_name')}
            value={contract?.company_name ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ company_name: newValue })
            }}
          />
        </InlineComponentsWrapper>

        <InlineComponentsWrapper padding="left">
          <TextInlineInputCompontent
            label={t('size')}
            value={contract?.contractSize ?? ''}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ contractSize: newValue })
            }}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={contract?.created_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={contract?.updated_at}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      </div>
      {contractId && (
        <ContentTabsWrapper
          tabIndex={tabIndex}
          tabItems={tabItems}
          onSelect={(index) => setTabIndex(index)}
        >
          <TabPanel>
            <div className={'mx-4'}>
              <DisciplinesList
                parentType={'Contract'}
                parentId={contractId}
                reloadParent={refreshTree}
                onTreeSelect={onTreeSelect}
                readonly={false}
              />
            </div>
          </TabPanel>
        </ContentTabsWrapper>
      )}
    </PageRoot>
  )
}

export default ContractDisciplineDetailPage
