import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getControlArea } from 'src/service/ControlAreaService'
import { getRoom } from 'src/service/RoomService'
import { getSystem } from 'src/service/SystemService'
import { FullPageLoader } from '../MUtils'
import MControlAreaInfo from '../components/MControlAreaInfo'
import MRoomInfo from '../components/MRoomInfo'
import MSystemInfo from '../components/MSystemInfo'
import MIssueList from './MIssueList'

interface IMCustomIssues {
  parentType: CustomIssueTypes
  parentId?: number
}

export enum CustomIssueTypes {
  CheckInParameter = 'CheckInParameter',
  ChecklistParameter = 'ChecklistParameter',
  ConstructionLocomotive = 'ConstructionLocomotive',
  ControlArea = 'ControlArea',
  Room = 'Room',
  System = 'System',
}

const MCustomIssues = ({ parentId, parentType }: IMCustomIssues) => {
  useEffect(() => {
    getData()
  }, [parentType, parentId])

  const getData = async () => {
    setLoading(true)
    if (parentType === CustomIssueTypes.Room && parentId) {
      const room = await getRoom(parentId)
      setParentInfo(
        room ? (
          <MRoomInfo
            room={room}
            onFloatingBtnClick={onAddIssueClick}
            sticky={false}
          />
        ) : (
          <div />
        ),
      )
      setLoading(false)
    }
    if (parentType === CustomIssueTypes.System && parentId) {
      const system = await getSystem(parentId)
      setParentInfo(
        system ? (
          <MSystemInfo
            system={system}
            onFloatingBtnClick={onAddIssueClick}
            sticky={false}
          />
        ) : (
          <div />
        ),
      )
      setLoading(false)
    }
    if (parentType === CustomIssueTypes.ControlArea && parentId) {
      const controlArea = await getControlArea(parentId)
      setParentInfo(
        controlArea ? (
          <MControlAreaInfo
            controlArea={controlArea}
            onFloatingBtnClick={onAddIssueClick}
            sticky={false}
          />
        ) : (
          <div />
        ),
      )
      setLoading(false)
    }
  }

  const history = useHistory()
  const [parentInfo, setParentInfo] = useState<JSX.Element>(<div />)
  const [loading, setLoading] = useState<boolean>(false)

  const onAddIssueClick = () => {
    if (parentType === CustomIssueTypes.Room) {
      history.push(`/mobile/add-global-issue/Room/${parentId}`)
      return
    }
    if (parentType === CustomIssueTypes.System) {
      history.push(`/mobile/add-global-issue/System/${parentId}`)
      return
    }
    if (parentType === CustomIssueTypes.ControlArea) {
      history.push(`/mobile/add-global-issue/ControlArea/${parentId}`)
      return
    }
    if (parentId) {
      history.push(`/mobile/add-global-issue/Project/${parentId}`)
    }
  }

  return loading ? (
    <FullPageLoader />
  ) : (
    <MIssueList
      parentId={parentId}
      parentType={parentType}
      parentInfo={parentInfo}
      onAddIssue={onAddIssueClick}
    />
  )
}

export default MCustomIssues
