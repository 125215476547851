import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import ExportService from 'src/service/ExportService'
import ExportToast from 'src/ui-elements/toast/ExportToast'
import useAlert from 'src/ui-elements/toast/useAlert'
import useInterval from '../../components/hooks/UseInterval'
import { ExportContext } from './ExportContext'
import { ExportStatus, ExportTask } from './ExportTypes'

const PULL_INTERVAL = 5000

const ExportContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [exportTasks, setExportTasks] = useState<ExportTask[]>([])
  const [pullInterval, setPullInterval] = useState<number | null>(null)
  const [pendingExports, setPendingExports] = useState<string[]>([])

  const { showAlert } = useAlert()
  const { t } = useTranslation()

  const { state } = useContext(ProjectContext)

  useEffect(() => {
    const pull = async () => {
      pendingExports.forEach(async (uuid) => {
        const _res = exportTasks.find((a) => a.uuId === uuid)
        if (_res && _res.status === ExportStatus.Success && _res.result) {
          setPendingExports(pendingExports.filter((a) => a !== uuid))
          const extension = _res.fileName.split('.').pop() ?? 'docx'
          ExportService.downloadExportFile(
            _res.result,
            _res.fileName,
            extension,
          )
          showAlert('success', t('export_success'), '')
        } else if (_res && _res.status === ExportStatus.Failed) {
          setPendingExports(pendingExports.filter((a) => a !== uuid))
          showAlert('error', t('export_failed'), '')
        }
      })
    }
    pull()
  }, [exportTasks, pendingExports, showAlert, t])

  const pullData = useCallback(async () => {
    const _res = await ExportService.getUserExportTasks()
    if (_res.findIndex((a) => a.status === ExportStatus.Processing) > -1) {
      setPullInterval(PULL_INTERVAL)
    } else {
      setPullInterval(null)
    }
    setExportTasks(_res)
  }, [state.currentProject.id])

  useEffect(() => {
    pullData()
  }, [pullData])

  useInterval(() => {
    pullData()
  }, pullInterval)

  const addExportTask = (res: ExportTask) => {
    if (exportTasks.find((task) => task.uuId === res.uuId)) {
      updateExportTask(res)
    } else {
      setExportTasks([...exportTasks, res])
    }
  }

  const updateExportTask = (res: ExportTask) => {
    const _res = exportTasks.map((task) => {
      if (task.uuId === res.uuId) {
        return res
      }
      return task
    })
    setExportTasks(_res)
  }

  const removeExportTask = (res: ExportTask) => {
    const _res = exportTasks.filter((task) => task.uuId !== res.uuId)
    setExportTasks(_res)
  }

  const addPendingExport = (uuid: string) => {
    setPendingExports([...pendingExports, uuid])
    pullData()
  }

  const context = {
    actions: {
      addExportTask,
      updateExportTask,
      removeExportTask,
      reload: pullData,
      addPendingExport,
    },
    state: {
      exportTasks,
      pendingExports,
    },
  }

  return (
    <ExportContext.Provider value={context}>
      <>
        {children}
        <ExportToast />
      </>
    </ExportContext.Provider>
  )
}

export default ExportContextProvider
