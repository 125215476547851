import i18next from 'i18next'
import { useContext, useEffect, useState } from 'react'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import MRouter from 'src/mobile/router/MRouter'
import { IUserData, Languages } from 'src/service/OrgTypes'
import { editUser } from 'src/service/UserService'
import 'typeface-poppins'

const Root = () => {
  const userContext: IUserContext = useContext(UserContext)
  const user: IUserData = userContext.state.user

  const [selectedLanguage, setSelectedLanguage] = useState<Languages>('no')

  const changeLanguage = (lang: Languages) => {
    if (lang) {
      i18next.changeLanguage(lang).then(() => {
        setSelectedLanguage(lang)
        const userObj = Object.assign(
          {},
          {
            id: user.id,
            language: lang,
          },
        )
        editUser(userObj).catch((err) => console.error(err))
      })
    }
  }

  const setLanguage = () => {
    setSelectedLanguage(user.language ? (user.language as Languages) : 'no')
    if (user.language && selectedLanguage !== user.language) {
      changeLanguage(user.language as Languages)
    }
  }

  useEffect(() => {
    setLanguage()
  }, [])

  return (
    <div className={'mobile-root'}>
      <MRouter
        path={'/mobile'}
        lang={selectedLanguage}
        changeLang={changeLanguage}
      />
    </div>
  )
}

export default Root
