import { IEnvironmentConfig } from './IEnvironmentConfig'

export const e6bvConfig: IEnvironmentConfig = {
  host: "e6bv.bygg.io",
  exportBaseUrl: "https://export-e6bv.bygg.io/",
  frontendBaseUrl: "https://e6bv.bygg.io/",
  organizationBaseUrl: "https://org-e6bv.bygg.io/",
  proxyBaseUrl: "https://proxy-e6bv.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-e6bv.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-e6bv.bygg.io/",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "https://kpi-e6bv.bygg.io/"
}
