import { FC } from 'react'
import { IFileContainer } from '../../../types/IFileContainer'
import DocumentUploadList from './DocumentUploadList'
import DocumentUploadSummeryItem from './DocumentUploadSummeryItem'

interface DocumentUploadSummeryProps {
  summery: IDocumentUploadSummery
}

export interface IDocumentUploadSummery {
  new_records: IFileContainer[]
  updated_records: IFileContainer[]
}

const DocumentUploadSummery: FC<DocumentUploadSummeryProps> = ({ summery }) => {
  return (
    <div className="min-h-70vh w-fit my-4">
      <DocumentUploadSummeryItem
        newDocuments={true}
        documentImportCount={summery.new_records.length}
      >
        <DocumentUploadList file_containers={summery.new_records} />
      </DocumentUploadSummeryItem>
      <DocumentUploadSummeryItem
        newDocuments={false}
        documentImportCount={summery.updated_records.length}
      >
        <DocumentUploadList file_containers={summery.updated_records} />
      </DocumentUploadSummeryItem>
    </div>
  )
}

export default DocumentUploadSummery
