import moment from 'moment'
import { PureComponent } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { isEmpty, TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import { IPhase } from '../../service/OrgTypes'
import {
  deletePhase,
  getProjectPhases,
  swapOrderPhase,
} from '../../service/PhaseService'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import DeleteModal from '../delete-modal/DeleteModal'
import PhaseForm from './PhaseForm'

interface IPhasesListProps extends WithTranslation {
  projectId: number
}

interface IPhasesListState {
  phases: IPhase[]
  selectedPhase: IPhase
  loading: boolean
  showEditModal: boolean
  showDeleteModal: boolean
  showCreateModal: boolean
}

class PhasesList extends PureComponent<IPhasesListProps, IPhasesListState> {
  public constructor(props: IPhasesListProps) {
    super(props)
    this.state = {
      phases: [],
      selectedPhase: {} as IPhase,
      loading: true,
      showEditModal: false,
      showDeleteModal: false,
      showCreateModal: false,
    }
  }

  private swapPhase = (id: number, seq: number) => {
    const fromPhase: any = this.state.phases[id].id

    swapOrderPhase(fromPhase, seq).then(() => {
      this.reload()
    })
  }

  private reload() {
    getProjectPhases(this.props.projectId)
      .then((res: IPhase[]) => {
        this.setState({
          phases: res,
          loading: false,
        })
      })
      .catch((err) => console.error(err))
  }

  public componentDidMount() {
    this.reload()
  }

  public componentDidUpdate(prevProps: IPhasesListProps) {
    if (prevProps !== this.props) {
      this.setState(
        {
          phases: [],
          loading: true,
        },
        () => {
          this.reload()
        },
      )
    }
  }

  private onEditItemClick = (taskType: IPhase) => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
      selectedPhase: taskType,
    }))
  }

  private onDeletItemClick = (taskType: IPhase) => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
      selectedPhase: taskType,
    }))
  }

  private openEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }))
  }

  private closeEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }))

    this.reload()
  }

  private closeDeleteModal = () => {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }))
  }

  private openCreateModal = () => {
    this.setState((prevState) => ({
      showCreateModal: !prevState.showCreateModal,
    }))
  }

  private closeCreateModal = () => {
    this.setState((prevState) => ({
      showCreateModal: !prevState.showCreateModal,
    }))

    this.reload()
  }

  private deletePhase = () => {
    deletePhase(this.state.selectedPhase)
      .then(() => {
        this.reload()
      })
      .catch((err) => console.error(err))

    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }))
  }

  public render() {
    const { phases, loading, showDeleteModal, showCreateModal, showEditModal } =
      this.state

    const { t } = this.props

    const columns: IListColumns[] = [
      {
        name: 'phase_name',
        size: '400',
        id: 'phaseName',
        dataField: 'phaseName',
        sortingField: 'phaseName',
      },
      {
        name: 'description',
        size: '600',
        id: 'description',
        dataField: 'description',
        sortingField: 'description',
      },
      {
        name: 'created_at',
        size: '130',
        id: 'createdAt',
        sortingField: 'created_at',
        dataField: 'created_at',
        cell: (created: any) => (
          <span>{created ? moment(created).format('L') : ''}</span>
        ),
      },
      {
        name: 'updated_at',
        size: '130',
        id: 'updatedAt',
        sortingField: 'updated_at',
        dataField: 'updated_at',
        cell: (updated: any) => (
          <span>{updated ? moment(updated).format('L') : ''}</span>
        ),
      },
      {
        name: 'order',
        size: '150',
        id: 'sequence',
        dataField: 'sequence',
        sortingField: 'sequence',
      },
    ]

    return (
      <div className={TabPageStyleClass.root}>
        <List
          actionButton={
            <Button
              onClick={this.openCreateModal}
              type={Button.ButtonType.PRIMARY}
            >
              {t('new_phase')}
            </Button>
          }
          data={phases}
          columns={columns}
          tableName={'phasesList'}
          itemsPerPage={0}
          onRowClick={this.onEditItemClick}
          actionMenu={[
            {
              name: t('delete'),
              action: this.onDeletItemClick,
            },
          ]}
          isRowDraggable={true}
          swapOrder={this.swapPhase}
        />

        <Modal
          size={'w-1/3'}
          show={showEditModal}
          closeModal={this.openEditModal}
          title={t('edit_phase')}
          maxWidth={600}
        >
          <PhaseForm
            editingMode={true}
            phase={this.state.selectedPhase}
            projectId={this.props.projectId}
            closeModal={this.closeEditModal}
          />
        </Modal>

        {!isEmpty(this.state.selectedPhase) && (
          <DeleteModal
            show={showDeleteModal}
            closeModal={this.closeDeleteModal}
            onDelete={this.deletePhase}
            itemIdnType={t('phase_order', {
              order: this.state.selectedPhase.sequence,
            })}
            itemName={`${t('order')}: ${this.state.selectedPhase.sequence} - ${
              this.state.selectedPhase.phaseName
            }`}
          />
        )}

        <Modal
          size={'w-1/3'}
          show={showCreateModal}
          closeModal={this.openCreateModal}
          title={t('add_phase')}
          maxWidth={600}
        >
          <PhaseForm
            editingMode={false}
            phase={{ id: 0, phaseName: '', description: '' }}
            projectId={this.props.projectId}
            closeModal={this.closeCreateModal}
          />
        </Modal>

        {loading ? <Loader /> : null}
      </div>
    )
  }
}

export default withTranslation()(PhasesList)
