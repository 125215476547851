import { TestApproval } from './OrgTypes'
import { request } from './service-utils'

export const getTestApprovals = (
  projectId: number,
  testExecutionId: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/test_executions/${testExecutionId}/test_approvals`,
    'GET',
  )
}

export const addSignatureToTestApproval = (
  projectId: number,
  id: number,
  signature: string,
): Promise<any> => {
  return request(
    `projects/${projectId}/parents/TestApproval/${id}/signatures`,
    'POST',
    {
      signature,
    },
  )
}

export const updateTestApproval = (
  projectId: number,
  approvalId: number,
  testApproval: Partial<TestApproval>,
): Promise<any> => {
  return request(
    `projects/${projectId}/test_approvals/${approvalId}`,
    'PUT',
    testApproval,
  )
}
