import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { IMatrixCellConfig } from '../../service/OrgTypes'
import Button from '../../ui-elements/button/Button'
import Spinner from '../../ui-elements/loader/Spinner'
import { classNames } from '../../utility/utils'
import Selector from '../selectors/Selector'

interface IRiskMatrixConfigFormProps {
  matrixType: string
  updateModal: (updatedCellConfig: IMatrixCellConfig) => void
  closeModal: () => void
  matrixCellConfig: IMatrixCellConfig
}

const RiskMatrixConfigForm = ({
  matrixType,
  updateModal,
  closeModal,
  matrixCellConfig,
}: IRiskMatrixConfigFormProps) => {
  const styleClass = {
    root: classNames('md:w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'my-1', 'mx-2'),
  }

  const { t } = useTranslation()
  const threatColors = [
    {
      id: 1,
      color: 'bg-green-two',
      name: t('green'),
    },
    {
      id: 2,
      color: 'bg-yellow-300',
      name: t('yellow'),
    },
    {
      id: 3,
      color: 'bg-red-two',
      name: t('red'),
    },
  ]

  const possibilityColors = [
    {
      id: 1,
      color: 'bg-blue-four',
      name: t('bright_blue'),
    },
    {
      id: 2,
      color: 'bg-blue-two',
      name: t('blue'),
    },
    {
      id: 3,
      color: 'bg-blue-one',
      name: t('dark_blue'),
    },
  ]

  const [selectedColor, setSelectedColor] = useState<any | undefined>(0)
  const [colors, setColors] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedColorErrorMessage, setSelectedColorErrorMessage] =
    useState<string>('')

  useEffect(() => {
    const colorsOptions =
      matrixType === 'threat' ? threatColors : possibilityColors

    const thisSelectedColor = colorsOptions.find(
      (d) => d.color === matrixCellConfig.color,
    )
    setSelectedColor(thisSelectedColor ? thisSelectedColor : 0)
    setColors(colorsOptions)
    setLoading(false)
    setSelectedColorErrorMessage('')
  }, [])

  const onSubmit = (e: any) => {
    e.preventDefault()
    const error = []
    if (!selectedColor.color) {
      error.push('true')
      setSelectedColorErrorMessage(t('select_color'))
    }
    if (error.length === 0) {
      const cellConfig = matrixCellConfig
      cellConfig.color = selectedColor.color
      updateModal(cellConfig)
    }
  }

  const onColorChange = (id: number) => {
    setSelectedColor(colors.filter((d) => d.id === id).pop())
    setSelectedColorErrorMessage('')
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={`${styleClass.inputGroup} pr-4`}>
        <Selector
          items={colors}
          selectedItemId={selectedColor ? selectedColor.id : 0}
          onSelect={onColorChange}
          label={t('color')}
          errorMessage={selectedColorErrorMessage}
          dataFields={['name']}
          required={true}
          fontSize={'sm'}
          fontWeight={'bold'}
        />
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
          {loading ? <Spinner /> : t('update')}
        </Button>
      </ModalFooter>
    </form>
  )
}

export default RiskMatrixConfigForm
