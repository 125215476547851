import { FC, FormEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useServiceData from 'src/hooks/useServiceData'
import { IShareToken } from 'src/service/OrgTypes'
import {
  createShareToken,
  editShareToken,
  getShareTokenResourceTypes,
} from 'src/service/ShareTokenService'
import Button from 'src/ui-elements/button/Button'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { capFirstLetter } from 'src/utility/utils'
import Selector from '../selectors/Selector'

interface IShareTokenFormProps {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  shareToken?: IShareToken
}

const resourceNameMapping = {
  project_gantt: 'Gantt chart',
}

const resourceName = (resource: string) =>
  resourceNameMapping[resource] ?? resourceName

const ShareTokenForm: FC<IShareTokenFormProps> = ({
  projectId,
  closeModal,
  editingMode,
  shareToken,
}) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState<Partial<IShareToken>>(
    shareToken ?? { project_id: projectId },
  )

  const { data, loading } = useServiceData(getShareTokenResourceTypes, [])

  const resourceTypes = data ?? []

  const resourceTypesForSelector =
    resourceTypes?.map((r) => ({
      id: r,
      name: resourceName(r),
    })) ?? []

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()

    if (!formData.resource) {
      return
    }

    if (editingMode) {
      await editShareToken({ id: formData.id, comment: formData.comment })
      closeModal()
    } else {
      await createShareToken(formData)
      closeModal()
    }
  }

  const onFormChange = (data: Partial<IShareToken>) => {
    setFormData({ ...formData, ...data })
  }

  return (
    <form onSubmit={onSubmit}>
      <Selector
        loading={loading}
        items={resourceTypesForSelector}
        selectedItemId={formData.resource || ''}
        onSelect={(id) => onFormChange({ resource: id })}
        label={t('resource')}
        dataFields={['name']}
        required={true}
        fontSize={'sm'}
        fontWeight={'bold'}
      />
      <Textarea
        isValid={false}
        label={t('description')}
        block={true}
        value={formData?.comment}
        errorMessage={''}
        required={true}
        onChange={(e) => onFormChange({ comment: e.target.value })}
      />

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        <Button
          type={Button.ButtonType.PRIMARY}
          loading={false}
          buttonType="submit"
        >
          {capFirstLetter(editingMode ? t('update') : t('create'))}
        </Button>
      </ModalFooter>
    </form>
  )
}

export default ShareTokenForm
