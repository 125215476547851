import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Selector from 'src/components/selectors/Selector'
import { SplashScreenUtil } from 'src/page/splash/splashScreenStore'
import {
  changePassword,
  forgotPassword,
  loginUser,
} from 'src/service/TheWallService'
import TokenService from 'src/service/TokenService'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import Input from 'src/ui-elements/input/Input'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { classNames, hasEmptyString, validation } from '../../utility/utils'

interface INewPasswordValidators {
  type: 'minLength' | 'specialCharacter' | 'hasNumber' | 'match'
  message: string
  active: boolean
}

const MLogin = () => {
  const { t, i18n } = useTranslation()
  const styleClass = {
    root: classNames(
      'w-screen',
      'h-screen',
      'flex',
      'justify-center',
      'items-end',
      'sm:items-center',
      'fixed',
      'm-login-wrapper',
    ),
    card: classNames(
      'w-screen',
      'sm:w-[400px]',
      'h-70vh',
      'rounded-t-lg',
      'sm:rounded-lg',
      'bg-white',
      'flex',
      'flex-col',
      'p-6',
    ),
    title: classNames('text-l', 'text-gray-500', 'font-semibold'),
    btn: classNames(
      'bg-blue-root',
      'rounded-full',
      'text-white',
      'text-md',
      'py-2',
      'mt-2',
      'font-medium',
    ),
  }

  const resetEmail = new URLSearchParams(location.search).get('email')
  const resetPassword = new URLSearchParams(location.search).get('password')
  const forgotPasswordUrl = new URLSearchParams(location.search).has('forgot')

  const initialValidators: INewPasswordValidators[] = [
    {
      type: 'minLength',
      message: t('the_password_must_contain_at_least_8_characters'),
      active: true,
    },
    {
      type: 'specialCharacter',
      message: t('the_password_must_contain_at_least_one_special_character'),
      active: true,
    },
    {
      type: 'hasNumber',
      message: t('the_password_must_contain_at_least_one_number'),
      active: true,
    },
    {
      type: 'match',
      message: t('password_does_not_match'),
      active: true,
    },
  ]

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [initialPasswordReset, setInitialPasswordReset] =
    useState<boolean>(false)
  const [resetPasswordToken, setResetPasswordToken] = useState<string>('')
  const [newPasswordErrors, setNewPasswordErrors] =
    useState<INewPasswordValidators[]>(initialValidators)
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [selectedLanguage, setSelectedLanguage] = useState<'no' | 'en'>('no')

  const { addAlert } = useAlert()

  const splashScreenUtil = SplashScreenUtil()

  useEffect(() => {
    splashScreenUtil.removeSplashScreen()
    if (resetEmail && resetPassword) {
      const user = {
        email: resetEmail,
        password: resetPassword,
      }
      loginUser(user)
        .then((res) => {
          if (res.statusCode === 403) {
            setInitialPasswordReset(true)
            setResetPasswordToken(res.body.message.token)
          } else if (res.statusCode !== 200) {
            setIsLoading(false)
            showAlert('error', t('something_went_wrong'), res.body.message.text)
          } else {
            setEmail('')
            setPassword('')
            TokenService.setRefreshToken = res.body
            window.location.reload()
          }
          setIsLoading(false)
        })
        .catch((err) => {
          if (err.body) {
            setIsLoading(false)
            showAlert('error', t('an_error_occurred'), err.body.message.text)
          } else {
            setIsLoading(false)
            showAlert(
              'error',
              t('an_error_occurred'),
              t('no_internet_connection_second'),
            )
          }
        })
    } else if (forgotPasswordUrl) {
      setShowForgotPassword(true)
    }
  }, [i18n.language, resetEmail, resetPassword])

  const showAlert = (type: IAlertType, title: string, description: string) => {
    addAlert({ type, title, description, autoClose: true })
  }

  const onEmailChange = (e: any) => {
    const value = e.target.value
    setEmail(value)
  }

  const onPasswordChange = (e: any) => {
    const value = e.target.value
    setPassword(value)
  }

  const onLogin = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const user = {
      email,
      password,
    }

    if (user.email === '') {
      setIsLoading(false)
      showAlert(
        'error',
        t('an_error_occurred'),
        t('you_must_enter_an_email_address'),
      )
      return
    }

    if (user.password === '') {
      setIsLoading(false)
      showAlert('error', t('an_error_occurred'), t('you_must_enter_a_password'))
    }

    loginUser(user)
      .then((res) => {
        if (res.statusCode === 403) {
          setInitialPasswordReset(true)
          setResetPasswordToken(res.body.message.token)
        } else if (res.statusCode !== 200) {
          setIsLoading(false)
          showAlert('error', t('an_error_occurred'), res.body.message.text)
        } else {
          setEmail('')
          setPassword('')
          TokenService.setRefreshToken = res.body
          window.location.reload()
        }
        setIsLoading(false)
      })
      .catch((err) => {
        if (err.body) {
          setIsLoading(false)
          showAlert('error', t('an_error_occurred'), err.body.message.text)
        } else {
          setIsLoading(false)
          showAlert(
            'error',
            t('an_error_occurred'),
            t('no_internet_connection'),
          )
        }
      })
  }

  const onForgotPassword = () => {
    setIsLoading(true)
    forgotPassword(email, selectedLanguage)
      .then(() => {
        setIsLoading(false)
        showAlert(
          'success',
          t('success'),
          t(
            'we_have_sent_you_an_email_with_information_about_your_new_password',
          ),
        )
      })
      .catch((err) => {
        setIsLoading(false)
        showAlert('error', t('an_error_occurred'), err.body.message.text)
      })
  }

  const onChangePassword = (e: any) => {
    e.preventDefault()
    TokenService.setRefreshToken = resetPasswordToken
    changePassword(resetPassword ?? '', newPassword)
      .then(() => {
        setIsLoading(false)
        showAlert(
          'success',
          t('success'),
          t('your_new_password_has_been_saved'),
        )
        setTimeout(() => {
          window.location.href = window.location.origin
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        showAlert('error', t('an_error_occurred'), err.body.message.text)
      })
  }

  const onNewPasswordChange = (e: any) => {
    const value = e.target.value
    setNewPassword(value)
    setNewPasswordErrors((prevState) => {
      const updatedErrors = [...prevState]
      updatedErrors.map((item) => {
        if (item.type === 'minLength') {
          item.active = value.length < 8
        }
        if (item.type === 'hasNumber') {
          item.active = !validation.hasNumber(value)
        }
        if (item.type === 'specialCharacter') {
          item.active = !validation.hasSpecialCharacter(value)
        }
        if (item.type === 'match') {
          item.active = !(
            confirmPassword === value &&
            !hasEmptyString([confirmPassword, value])
          )
        }
      })
      return updatedErrors
    })
  }

  const onConfirmPasswordChange = (e: any) => {
    const value = e.target.value
    setConfirmPassword(value)
    setNewPasswordErrors((prevState) => {
      const updatedErrors = [...prevState]
      updatedErrors.map((item) => {
        if (item.type === 'match') {
          item.active = !(
            newPassword === value && !hasEmptyString([newPassword, value])
          )
        }
      })
      return updatedErrors
    })
  }

  const toggleForgotPassword = () => {
    setShowForgotPassword((prevState) => !prevState)
    clean()
  }

  const clean = () => {
    setPassword('')
    setNewPassword('')
    setConfirmPassword('')
    setNewPassword('')
  }

  const getLanguageIcon = (selectedId: string) => {
    return selectedId === 'no' ? Icons.NORWEGIAN : Icons.ENGLISH
  }

  const languages = [
    { id: 'no', name: 'no', icon: Icons.NORWEGIAN },
    { id: 'en', name: 'en', icon: Icons.ENGLISH },
  ]

  const changeLanguage = (val: any) => {
    if (val) {
      i18n.changeLanguage(val).then(() => {
        setSelectedLanguage(val)
      })
    }
  }

  return (
    <div className={styleClass.root}>
      <div className={styleClass.card}>
        <div className={'w-full max-w-[350px] flex flex-col self-center'}>
          <div
            className={'w-28 self-end max-w-32 md:px-2 py-1 ml-2 flex-shrink-0'}
          >
            <Selector
              inTopMenu={true}
              hidelabel={true}
              label={''}
              items={languages}
              selectedItemId={selectedLanguage}
              onSelect={changeLanguage}
              dataFields={['name']}
              scroll={true}
              icon={getLanguageIcon(selectedLanguage)}
              disabled={false}
              inMobile={true}
              required={true}
            />
          </div>
          {initialPasswordReset && (
            <h2 className={styleClass.title}>{t('create_new_password')}</h2>
          )}

          {showForgotPassword && (
            <div>
              <h2 className={styleClass.title}>{t('forgot_your_password')}</h2>
              <p className={'my-2 text-s text-gray-500'}>
                {t(
                  'enter_the_e_mail_address_you_registered_with_and_we_will_send_you_a_new_password',
                )}
              </p>
            </div>
          )}

          {!showForgotPassword && !initialPasswordReset && (
            <h2 className={styleClass.title}>{t('login')}</h2>
          )}

          {initialPasswordReset ? (
            <>
              <div className={'text-gray-500 text-xs mb-2 pl-1'}>
                {newPasswordErrors &&
                  newPasswordErrors.map((err, key) => (
                    <div key={key} className={'flex items-center mb-1'}>
                      <Icon
                        className={'w-4 h-4 flex items-center'}
                        icon={
                          err.active
                            ? Icon.IconType.ERROR_RED
                            : Icon.IconType.CHECK_GREEN
                        }
                      />
                      <span className={'pl-1'}>{err.message}</span>
                    </div>
                  ))}
              </div>
              <Input
                inMobile={true}
                noPadding={true}
                bgColor={'mobile'}
                placeholder={t('password')}
                value={newPassword}
                onChange={onNewPasswordChange}
                type={showPassword ? 'text' : 'password'}
                block={true}
              />
              <Input
                inMobile={true}
                noPadding={true}
                bgColor={'mobile'}
                label={t('confirm_password')}
                value={confirmPassword}
                onChange={onConfirmPasswordChange}
                type={showPassword ? 'text' : 'password'}
                block={true}
              />
              {newPasswordErrors &&
                newPasswordErrors.filter((item) => item.active).length ===
                  0 && (
                  <>
                    {isLoading ? (
                      <div className={'w-full flex justify-center'}>
                        <SpinnerMobile />
                      </div>
                    ) : (
                      <button
                        onClick={onChangePassword}
                        disabled={isLoading}
                        className={styleClass.btn}
                      >
                        {t('confirm')}
                      </button>
                    )}
                  </>
                )}
            </>
          ) : !showForgotPassword ? (
            <>
              <div className={'mb-2 mt-4'}>
                <Input
                  inMobile={true}
                  noPadding={true}
                  bgColor={'mobile'}
                  placeholder={t('email')}
                  value={email}
                  onChange={onEmailChange}
                  block={true}
                />
              </div>
              <div className={'mt-2 mb-8 relative'}>
                <Input
                  inMobile={true}
                  noPadding={true}
                  bgColor={'mobile'}
                  placeholder={t('password')}
                  value={password}
                  onChange={onPasswordChange}
                  type={showPassword ? 'text' : 'password'}
                  block={true}
                />
                <div className={'absolute right-2 top-2'}>
                  <Icon
                    onClick={() => setShowPassword((prev) => !prev)}
                    icon={!showPassword ? Icons.EYE_GRAY : Icons.EYE_BLUE}
                    className={'w-6 h-6'}
                  />
                </div>
              </div>
              {isLoading ? (
                <div className={'w-full flex justify-center'}>
                  <SpinnerMobile />
                </div>
              ) : (
                <button
                  onClick={onLogin}
                  disabled={isLoading}
                  className={styleClass.btn}
                >
                  {t('login')}
                </button>
              )}

              <button
                onClick={toggleForgotPassword}
                disabled={isLoading}
                className={'mt-4 text-blue-root'}
              >
                {t('forgot_your_password')}
              </button>
            </>
          ) : (
            <>
              <div className={'mb-8 mt-4'}>
                <Input
                  inMobile={true}
                  noPadding={true}
                  bgColor={'mobile'}
                  placeholder={t('email')}
                  value={email}
                  onChange={onEmailChange}
                  block={true}
                />
              </div>
              {isLoading ? (
                <div className={'w-full flex justify-center'}>
                  <SpinnerMobile />
                </div>
              ) : (
                <button
                  onClick={onForgotPassword}
                  disabled={isLoading}
                  className={styleClass.btn}
                >
                  {t('get_a_new_password')}
                </button>
              )}

              <button
                onClick={toggleForgotPassword}
                disabled={isLoading}
                className={'mt-4 text-blue-root'}
              >
                {t('login')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default MLogin
