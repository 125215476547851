import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const taskctrlConfig: IEnvironmentConfig = {
  host: "taskctrl.bygg.io",
  exportBaseUrl: "https://export-taskctrl.bygg.io/",
  frontendBaseUrl: "https://taskctrl.bygg.io/",
  organizationBaseUrl: "https://org-taskctrl.bygg.io/",
  proxyBaseUrl: "https://proxy-taskctrl.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-taskctrl.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-taskctrl.bygg.io/",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "https://kpi-taskctrl.bygg.io/"
}
