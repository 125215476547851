import { useEffect, useState } from 'react'

const useDebouncedValue = <T>(
  value: T,
  delay: number,
  skipDelay?: (val: T) => boolean,
) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    if (skipDelay?.(value)) {
      setDebouncedValue(value)
      return
    }
    const timeout = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(timeout)
  }, [value, delay, skipDelay])

  return debouncedValue
}

export default useDebouncedValue
