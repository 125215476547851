import * as React from 'react'
import { classNames } from '../../utility/utils'

interface IMPage {
  children: React.ReactElement | JSX.Element
  bgColor?: string
}

const MPage = ({ children, bgColor = '' }: IMPage) => {
  const styleClass = {
    root: (bg: string) =>
      classNames(
        'w-screen',
        'overflow-hidden',
        'flex',
        'flex-col',
        'fixed',
        'top-0',
        'left-0',
        `bg-${bg}`,
      ),
  }

  const getStyleClass = () => {
    return {
      height: 'calc(100vh - calc(100vh - 100%))',
      minHeight: 'calc(100vh - calc(100vh - 100%))',
    }
  }

  return (
    <div style={getStyleClass()} className={styleClass.root(bgColor)}>
      {children}
    </div>
  )
}

export default MPage
