import { useTranslation } from 'react-i18next'
import { ISystem } from '../../service/OrgTypes'
import { capFirstLetter } from '../../utility/utils'
import { getInfoWrapperStyle } from '../MUtils'
import FloatingButton from '../components/FloatingButton'

interface IMRoomInfo {
  system: ISystem
  noPadding?: boolean
  isCard?: boolean
  onFloatingBtnClick?: () => void
  sticky?: boolean
}

const MSystemInfo = ({
  system,
  noPadding = false,
  isCard = false,
  onFloatingBtnClick,
  sticky = true,
}: IMRoomInfo) => {
  const { t } = useTranslation()

  return (
    <div
      className={getInfoWrapperStyle(
        noPadding,
        onFloatingBtnClick,
        isCard,
        sticky,
      )}
    >
      {onFloatingBtnClick && (
        <FloatingButton floating={false} onClick={onFloatingBtnClick} />
      )}
      <div className={`w-full border-t-green-300 pl-2`}>
        <details className={'-ml-2 border border-gray-300 rounded-md py-1'}>
          <summary className={'font-semibold text-gray-600 text-xs px-2'}>
            {capFirstLetter(t('system_details'))}
          </summary>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/3 font-medium capitalize'}>{t('system')}</div>
            <div className={'w-2/3 break-all font-light pl-2'}>
              {system?.name ?? ''}{' '}
              {system?.record_id ? '-' + system?.record_id : ''}
            </div>
          </div>
          {system?.room && (
            <div
              className={
                'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
              }
            >
              <div className={'w-1/3 font-medium capitalize'}>
                {t('section')}
              </div>
              <div className={'w-2/3 break-all font-light pl-2'}>
                {system?.room?.room_name ?? ''}-
                {system?.room?.functional_room_number ?? ''}
              </div>
            </div>
          )}
        </details>
      </div>
    </div>
  )
}

export default MSystemInfo
