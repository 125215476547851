export const getMileStoneErrorMessage = (
  errorType: StructureValidationError,
  t: any,
) => {
  switch (errorType) {
    case StructureValidationError.HAS_OPEN_CHILDREN:
      return t('milestone_has_open_key_points')
    case StructureValidationError.HAS_OPEN_CHILDREN_DETAIL:
      return t(
        'not_possible_to_close_a_milestone_when_one_or_more_underlying_key_points_are_open',
      )
    case StructureValidationError.PAST_PARENT_DEADLINE:
      return t(
        'the_deadline_for_the_milestone_must_be_before_the_deadline_for_the_project',
      )
    default:
      return ''
  }
}

export const getKeyPointErrorMessage = (
  errorType: StructureValidationError,
  t: any,
  values?: any,
) => {
  switch (errorType) {
    case StructureValidationError.HAS_OPEN_CHILDREN:
      return t('key_point_has_open_deliveries')
    case StructureValidationError.HAS_OPEN_CHILDREN_DETAIL:
      return t(
        'not_possible_to_close_a_key_point_when_one_or_more_underlying_deliveries_are_open',
      )
    case StructureValidationError.PAST_PARENT_DEADLINE:
      return t(
        'deadline_for_the_key_point_must_be_before_the_deadline_for_the_corresponding_milestone',
        { date: values },
      )
    case StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT:
      return t(
        'deadline_for_the_key_point_must_be_before_the_deadline_for_the_corresponding_improvement',
        { date: values },
      )
    case StructureValidationError.PAST_PROJECT_DEADLINE:
      return t(
        'the_deadline_for_the_keypoint_must_be_before_the_end_of_the_project',
      )
    case StructureValidationError.PARENT_IS_CLOSED:
      return t('associated_milestone_is_closed')
    case StructureValidationError.OPEN_DEPENDENCY:
      return t('all_dependencies_must_be_closed_to_close_the_key_point')
    case StructureValidationError.PAST_DEPENDENT_DEADLINE:
      return t('keypoint_passed_dependency', { date: values })
    case StructureValidationError.BEFORE_DEPENDENT_DEADLINE:
      return t('keypoint_after_dependency', { date: values })
    default:
      return ''
  }
}

export const getDeliveryErrorMessage = (
  errorType: StructureValidationError,
  t: any,
  values?: any,
) => {
  switch (errorType) {
    case StructureValidationError.HAS_OPEN_CHILDREN:
      return t('delivery_has_open_tasks')
    case StructureValidationError.HAS_OPEN_CHILDREN_DETAIL:
      return t(
        'not_possible_to_close_a_delivery_when_one_or_more_underlying_actions_are_open',
      )
    case StructureValidationError.PAST_PARENT_DEADLINE:
      return t(
        'deadline_for_delivery_must_be_before_the_deadline_for_the_corresponding_key_point',
        { date: values },
      )
    case StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT:
      return t(
        'deadline_for_delivery_must_be_before_the_deadline_for_the_corresponding_improvement',
        { date: values },
      )
    case StructureValidationError.PARENT_IS_CLOSED:
      return t('associated_key_point_is_closed')
    case StructureValidationError.OPEN_DEPENDENCY:
      return t('all_dependencies_must_be_closed_to_close_the_delivery')
    case StructureValidationError.PAST_DEPENDENT_DEADLINE:
      return t('delivery_passed_dependency', { date: values })
    case StructureValidationError.BEFORE_DEPENDENT_DEADLINE:
      return t('delivery_after_dependency', { date: values })
    default:
      return ''
  }
}

export const getTaskErrorMessage = (
  errorType: StructureValidationError,
  t: any,
) => {
  switch (errorType) {
    case StructureValidationError.PAST_PARENT_DEADLINE:
      return t(
        'the_deadline_for_the_action_must_be_before_the_deadline_for_the_associated_delivery',
      )
    case StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT:
      return t(
        'deadline_for_the_task_must_be_before_the_deadline_for_the_corresponding_improvement',
      )
    case StructureValidationError.PAST_PARENT_DEADLINE_TASK:
      return t(
        'the_deadline_for_the_task_must_be_before_the_deadline_for_the_corresponding_item',
      )
    case StructureValidationError.PAST_PROJECT_DEADLINE:
      return t(
        'the_deadline_for_the_task_must_be_before_the_end_of_the_project',
      )
    case StructureValidationError.PARENT_IS_CLOSED:
      return t('associated_delivery_is_closed')
    default:
      return ''
  }
}

export const getRiskErrorMessage = (errorType: StructureValidationError) => {
  switch (errorType) {
    case StructureValidationError.HAS_OPEN_CHILDREN:
      return 'milepælen har åpne nøkkelpunkter'
    case StructureValidationError.PAST_PARENT_DEADLINE:
      return 'Fristen til risikoen må være før slutten av prosjektet'
    default:
      return ''
  }
}

export const getImprovementErrorMessage = (
  errorType: StructureValidationError,
) => {
  switch (errorType) {
    case StructureValidationError.PAST_PARENT_DEADLINE:
      return 'Fristen til forbedringen må være før slutten av prosjektet'
    default:
      return ''
  }
}

export enum StructureValidationError {
  HAS_OPEN_CHILDREN,
  HAS_OPEN_CHILDREN_DETAIL,
  PAST_PARENT_DEADLINE,
  PAST_PARENT_DEADLINE_IMPROVEMENT,
  PAST_PARENT_DEADLINE_TASK,
  PARENT_IS_CLOSED,
  OPEN_DEPENDENCY,
  PAST_PROJECT_DEADLINE,
  PAST_DEPENDENT_DEADLINE,
  BEFORE_DEPENDENT_DEADLINE,
}

export enum ValidationError {
  MISSING_DEADLINE,
  MISSING_DISCIPLINE,
  MISSING_MAIN_PROCESS,
  MISSING_RESPONSIBLE,
  MISSING_TITLE,
  MISSING_KEY_POINT,
  MISSING_STATUS,
  NEGATIVE_DURATION,
  NO_DISCIPLINE_USERS,
}

export const getErrorMessage = (errorType: ValidationError, t: any) => {
  switch (errorType) {
    case ValidationError.MISSING_DEADLINE:
      return t('select_w_param', { param: t('deadline') })
    case ValidationError.MISSING_DISCIPLINE:
      return t('select_w_param', { param: t('discipline') })
    case ValidationError.MISSING_MAIN_PROCESS:
      return t('select_w_param', { param: t('main_process') })
    case ValidationError.MISSING_RESPONSIBLE:
      return t('select_w_param', { param: t('responsible') })
    case ValidationError.MISSING_TITLE:
      return t('fill_out_w_param', { param: t('title') })
    case ValidationError.MISSING_KEY_POINT:
      return t('select_w_param', { param: t('keypoint') })
    case ValidationError.MISSING_STATUS:
      return t('select_w_param', { param: t('status') })
    case ValidationError.NEGATIVE_DURATION:
      return t('duration_can_not_be_negative')
    case ValidationError.NO_DISCIPLINE_USERS:
      return t('no_users_found_for_this_discipline')
    default:
      return ''
  }
}
