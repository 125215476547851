import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { classNames } from 'src/utility/utils'

interface IPlusButton {
  text: string
  onClick: (e: any) => void
  className?: string
}

const PlusButton = ({ text, onClick, className = '' }: IPlusButton) => {
  const styleClass = {
    root: classNames('flex', 'items-center', className),
    icon: classNames('w-5', 'h-5', 'flex'),
    linkBtn: classNames(
      'text-sm',
      'underline',
      'text-blue-root',
      'inline-block',
      'cursor-pointer',
      'pl-1.5',
    ),
  }

  return (
    <div className={styleClass.root}>
      <Icon icon={Icons.PLUS_ICON_BLUE} className={styleClass.icon} />
      <p className={styleClass.linkBtn} onClick={onClick}>
        {text}
      </p>
    </div>
  )
}

export default PlusButton
