import { IActiveFilter } from '../utility/utils'
import { IConstructionTaskType } from './OrgTypes'
import { request } from './service-utils'

export const createConstructionTaskType = (
  task: IConstructionTaskType,
  projectId: number,
): Promise<IConstructionTaskType> => {
  return request(`projects/${projectId}/construction_task_types`, 'POST', task)
}

export const editConstructionTaskType = (
  taskType: NullablePartial<IConstructionTaskType>,
): Promise<IConstructionTaskType> => {
  return request(`construction_task_types/${taskType.id}`, 'PUT', taskType)
}

export const getProjectConstructionTaskTypes = (
  projectId: number,
  page: number,
  filter: IActiveFilter,
  perPage: number,
): Promise<{ tasks: IConstructionTaskType[]; pages: number }> => {
  return request(
    `projects/${projectId}/construction_task_types/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const getConstructionWagonTasks = (
  wagonId: number,
): Promise<IConstructionTaskType[]> => {
  return request(`locomotive_types/${wagonId}/construction_task_types`, 'GET')
}

export const deleteConstructionTaskType = (task: IConstructionTaskType) => {
  return request(`/construction_task_types/${task.id}`, 'DELETE', {}, true)
}

export const bulkDeleteConstructionTaskTypes = (ids: number[]) => {
  return request('/construction_task_types/bulk_delete', 'POST', { ids })
}
