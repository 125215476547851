import * as React from 'react'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { ITestWorkGroup } from 'src/service/OrgTypes'
import { editTestWorkGroup } from 'src/service/TestWorkGroupService'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'

interface ITestWorkGroupFields {
  testWorkGroup?: ITestWorkGroup
  setTestWorkGroup: (tsg: ITestWorkGroup) => void
  onUpdate: () => void
  refreshTree?: () => void
  inspectorPanel?: boolean
}

const TestWorkGroupFields: FC<ITestWorkGroupFields> = ({
  testWorkGroup,
  setTestWorkGroup,
  onUpdate,
  refreshTree,
  inspectorPanel = false,
}) => {
  const projectId = useProjectId()
  const { t } = useTranslation()
  const userContext = useContext(UserContext)
  const writeAccess = userContext.actions.hasWriteAccess('object')

  const { getChangesForUpdate } = useInlineDependencyUpdate<ITestWorkGroup>(
    setTestWorkGroup,
    testWorkGroup,
  )

  const onChangeInput = (update: Partial<ITestWorkGroup>) => {
    const allUpdates = getChangesForUpdate(update)
    editTestWorkGroup({
      ...convertUndefinedToNull(allUpdates),
      id: testWorkGroup?.id,
    }).then(() => {
      onUpdate()
      if (update.title || update.record_id) refreshTree?.()
    })
  }

  return (
    <>
      <TextInlineInputCompontent
        label="title"
        value={testWorkGroup?.title}
        validate={(newValue) => (newValue?.length ? undefined : t('required'))}
        onValueSubmitted={(newValue) => {
          if (newValue) onChangeInput({ title: newValue })
        }}
        disabled={!writeAccess}
      />
      <TextInlineInputCompontent
        label="test_work_group_id"
        value={testWorkGroup?.record_id}
        validate={(newValue) => (newValue?.length ? undefined : t('required'))}
        onValueSubmitted={(record_id) => {
          if (record_id) onChangeInput({ record_id })
        }}
        disabled={!writeAccess}
      />
      <TextInlineInputCompontent
        label={'location'}
        onValueSubmitted={(location) => {
          onChangeInput({ location })
        }}
        value={testWorkGroup?.location}
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        getItems={() => getProjectDisciplines(projectId)}
        label="discipline"
        initialItem={testWorkGroup?.discipline}
        getItemLabel={(discipline) =>
          `${discipline?.shortName} - ${discipline?.name}`
        }
        validate={(value) => (value !== undefined ? undefined : t('required'))}
        inspectorPanel={inspectorPanel}
        selectedId={testWorkGroup?.discipline_id}
        onValueSubmitted={(discipline_id) => {
          onChangeInput({ discipline_id })
        }}
        disabled={!writeAccess}
      />
      <SelectorInlineInputComponent
        label={'contract'}
        disabled
        selectedId={testWorkGroup?.contract_id}
        getItemLabel={(contract) =>
          `${contract?.contractNumber} - ${contract?.contractName}`
        }
        initialItem={testWorkGroup?.contract}
        inspectorPanel={inspectorPanel}
      />
      <TextInlineInputCompontent
        label="description"
        value={testWorkGroup?.description}
        onValueSubmitted={(description) => {
          if (description) onChangeInput({ description })
        }}
        textArea
        disabled={!writeAccess}
      />
    </>
  )
}

export default TestWorkGroupFields
