import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { getProjectContracts } from '../../service/ContractService'
import {
  createDiscipline,
  editDiscipline,
} from '../../service/DisciplineService'
import { IContract, IDiscipline } from '../../service/OrgTypes'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Loader from '../../ui-elements/loader/Loader'
import Spinner from '../../ui-elements/loader/Spinner'
import Select from '../../ui-elements/select/Select'
import { capFirstLetter, classNames } from '../../utility/utils'
import Selector from '../selectors/Selector'
import { generateShapeList, IShape } from './disciplineUtils'

interface ICreateDisciplineForm {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  discipline: IDiscipline
  disciplines: IDiscipline[]
}

const DisciplineForm = ({
  projectId,
  closeModal,
  editingMode,
  discipline,
  disciplines,
}: ICreateDisciplineForm) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'my-1'),
  }

  useEffect(() => {
    reload()
    setLoading(false)
  }, [projectId])

  const { t } = useTranslation()
  const [disciplineId] = useState<number>(discipline.id)
  const [name, setName] = useState<string>(discipline.name)
  const [shortName, setShortName] = useState<string>(discipline.shortName)
  const [color, setColor] = useState<string>(discipline.color)
  const [shape, setShape] = useState<string>(discipline.shape)
  const [sequence] = useState<number>(discipline.sequence)
  const [contractId, setContractId] = useState<number>(
    discipline.contract_id ? discipline.contract_id : 0,
  )
  const [contracts, setContracts] = useState<IContract[]>([])
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('')
  const [shortNameErrorMessage, setShortNameErrorMessage] = useState<string>('')
  const [contractErrorMessage, setContractErrorMessage] = useState<string>('')
  const [iconErrorMessage, setIconErrorMessage] = useState<string>('')
  const [, setSequenceErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)

  const reload = () => {
    getProjectContracts(projectId)
      .then((res: IContract[]) => {
        setContracts(res)
      })
      .catch((err) => console.error(err))
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false
    setNameErrorMessage('')
    setShortNameErrorMessage('')
    setContractErrorMessage('')
    setSequenceErrorMessage('')
    setSaving(true)
    if (!name) {
      setNameErrorMessage(t('fill_in_the_discipline_name'))
      error = true
    }

    if (!shortName) {
      setShortNameErrorMessage(t('fill_in_the_discipline_code'))
      error = true
    }

    if (!contractId) {
      setContractErrorMessage(t('select_contract'))
      error = true
    }

    if (!color || !shape) {
      setIconErrorMessage(t('select_discipline_icon'))
      error = true
    }

    if (!error) {
      if (editingMode) {
        editDiscipline({
          id: disciplineId,
          name,
          shortName,
          color,
          shape,
          contract_id: contractId,
          sequence,
        }).then(() => {
          closeModal()
        })
      } else {
        createDiscipline(
          {
            id: 0,
            name,
            shortName,
            color,
            shape,
            contract_id: contractId,
            sequence,
          },
          projectId,
        ).then(() => {
          if (!createMultiple) {
            closeModal()
          } else {
            setName('')
            setShortName('')
            setColor('')
            setShape('')
          }
        })
      }
    }
    setSaving(false)
  }

  const onNameChange = (e: any) => {
    const nameVal = e.target.value
    setName(nameVal)
    setNameErrorMessage('')
  }

  const onShortNameChange = (e: any) => {
    const shortNameVal = e.target.value
    setShortName(shortNameVal)
    setShortNameErrorMessage('')
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const onShapeChange = (item: IShape) => {
    setShape(item.shape)
    setColor(item.color)
    setIconErrorMessage('')
  }

  const onContractChange = (contract: number) => {
    setContractId(contract)
    setContractErrorMessage('')
  }

  return loading ? (
    <Loader />
  ) : (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('discipline_name')}
          block={true}
          value={name}
          errorMessage={nameErrorMessage}
          onChange={onNameChange}
          autoFocus={true}
          required={true}
        />
      </div>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('discipline_code')}
          block={true}
          value={shortName}
          errorMessage={shortNameErrorMessage}
          onChange={onShortNameChange}
          required={true}
        />
      </div>
      <div className={`${styleClass.inputGroup}`}>
        <Selector
          items={contracts}
          selectedItemId={Number(contractId)}
          onSelect={onContractChange}
          label={t('contract')}
          dataFields={['contractNumber', 'contractName']}
          required={true}
          fontSize={'sm'}
          fontWeight={'bold'}
          errorMessage={contractErrorMessage}
        />
      </div>

      <div className={styleClass.inputGroup}>
        <Fragment>
          <Select
            label={t('icon')}
            items={generateShapeList(disciplines)}
            selectedCustomItem={{ shape, color }}
            isIconSelector={true}
            required={true}
            errorMessage={iconErrorMessage}
            onItemSelect={onShapeChange}
          />
        </Fragment>
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        {editingMode ? (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {saving ? <Spinner /> : capFirstLetter(t('update'))}
          </Button>
        ) : (
          <>
            <Button
              type={Button.ButtonType.SECONDARY}
              noTextWrap={true}
              onClick={onMultipleCreate}
              disabled={loading}
            >
              {loading ? <Spinner /> : capFirstLetter(t('add_multiple'))}
            </Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              onClick={onSingleCreate}
              disabled={loading ? true : false}
            >
              {saving ? <Spinner /> : capFirstLetter(t('add'))}
            </Button>
          </>
        )}
      </ModalFooter>
    </form>
  )
}

export default DisciplineForm
