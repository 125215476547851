import { ITag } from './OrgTypes'
import { request } from './service-utils'

export const createTag = (tagData: ITag, projectId: number): Promise<ITag> => {
  return request(`projects/${projectId}/tags`, 'POST', tagData)
}

export const editTag = (tag: ITag): Promise<ITag> => {
  return request(`tags/${tag.id}`, 'PUT', tag)
}

export const getProjectTags = (projectId: number): Promise<ITag[]> => {
  return request(`projects/${projectId}/tags`, 'GET')
}

export const swapOrderTag = (
  tagId: number,
  sequence: number,
): Promise<ITag[]> => {
  return request(`tags/${tagId}/change_order`, 'PUT', { sequence: sequence })
}

export const deleteTag = (tag: ITag) => {
  return request(`/tags/${tag.id}`, 'DELETE', {}, true)
}
