import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const osloConfig: IEnvironmentConfig = {
  host: "oslo.bygg.io",
  exportBaseUrl: "https://export-oslo.bygg.io/",
  frontendBaseUrl: "https://oslo.bygg.io/",
  organizationBaseUrl: "https://org-oslo.bygg.io/",
  proxyBaseUrl: "https://proxy-oslo.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-oslo.bygg.io/api/",
  fdvuBaseUrl: "not set yet",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "not yet set"
}
