import Check from '@icons/check.svg'
import Close from '@icons/close.svg'
import Download from '@icons/download.svg'
import { useContext, useEffect, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import history from 'src/history'
import Button from 'src/ui-elements/button/Button'
import AddEventToCalendar from 'src/utility/AddEventToCalender'
import EmailUtil, { EmailProvider, IEvent } from 'src/utility/EmailProviderUtil'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'

interface MInviteAcceptanceProps {
  event?: IEvent
  title: string
  rsvp: string
  children: React.ReactNode
}

const MInviteAcceptance: FC<MInviteAcceptanceProps> = ({
  event,
  title,
  rsvp,
  children,
}) => {
  const { t } = useTranslation()
  const userContext = useContext(UserContext).state

  const [emailProvider, setEmailProvider] = useState<EmailProvider | null>(null)

  const userEmail = userContext.user.email

  useEffect(() => {
    EmailUtil.emailProvider(userEmail).then((res) => {
      setEmailProvider(res)
    })
  }, [userEmail])

  const onClick = (provider: EmailProvider, meeting: IEvent) => {
    new AddEventToCalendar(provider).addToCalendar(meeting)
  }

  const downloadICS = (meeting: IEvent) => {
    new AddEventToCalendar({} as EmailProvider).saveICalFile(meeting)
  }

  return (
    <MPage bgColor={'white'}>
      <>
        <MHeader
          user={userContext.user}
          onBackClick={() => history.goBack()}
          title={title}
        />
        {children}
        <div className="flex flex-col gap-6">
          <div className="p-1 flex">
            {rsvp === 'accept' ? (
              <div className="flex flex-col items-center gap-4 w-full py-5">
                <div className="flex items-center gap-4 mb-5">
                  <div className="bg-green-300 w-10 h-10 rounded-full flex items-center justify-center">
                    <Check className="fill-white" />
                  </div>
                  <div className="font-medium">{t('you_accepted_invite')}</div>
                </div>
                <div className="flex gap-4 ">
                  {emailProvider && event && (
                    <Button
                      type={Button.ButtonType.PRIMARY}
                      onClick={() => onClick(emailProvider, event)}
                    >
                      {t('add_calendar')}
                    </Button>
                  )}

                  <Button
                    type={Button.ButtonType.SECONDARY}
                    onClick={event ? () => downloadICS(event) : () => {}}
                  >
                    <Download className={'w-5 h-4 fill-blue-root'} />
                    (.ics)
                  </Button>
                </div>
              </div>
            ) : (
              <div className="w-full py-5">
                <div className="flex flex-col items-center gap-4 mb-5 w-full justify-center">
                  <div className="bg-gray-300 w-10 h-10 rounded-full flex items-center justify-center">
                    <Close className="fill-white" />
                  </div>
                  <div className="font-medium">{t('you_declined')}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </MPage>
  )
}

export default MInviteAcceptance
