import moment from 'moment'
import { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { getDeliveryChangeLog } from 'src/service/DeliveryService'
import { getKeypointChangeLog } from 'src/service/ProcessService'
import { IChangeLog } from '../../../service/OrgTypes'
import List from '../../../ui-elements/list/List'
import { IListColumns } from '../../../ui-elements/list/ListTypes'
import Loader from '../../../ui-elements/loader/Loader'
import { classNames } from '../../../utility/utils.js'

interface IChangeLogListProps {
  id: number
  parentType: string
  t: any
}

interface IChangeLogListState {
  changeLog: IChangeLog[]
  loading: boolean
}

class ChangeLogList extends PureComponent<
  IChangeLogListProps,
  IChangeLogListState
> {
  private static styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col', 'px-4', 'mr-4'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  public constructor(props: IChangeLogListProps) {
    super(props)
    this.state = {
      changeLog: [],
      loading: false,
    }
  }

  private reload() {
    if (this.props.parentType === 'KeyPoint') {
      getKeypointChangeLog(this.props.id)
        .then((res: IChangeLog[]) => {
          this.setState({
            changeLog: res.filter((v) => v.reason !== null).reverse(),
          })
        })
        .catch((err) => console.error(err))
    }
    if (this.props.parentType === 'Delivery') {
      getDeliveryChangeLog(this.props.id)
        .then((res: IChangeLog[]) => {
          this.setState({
            changeLog: res.filter((v) => v.reason !== null).reverse(),
          })
        })
        .catch((err) => console.error(err))
    }
  }

  public componentDidMount() {
    this.reload()
  }

  public componentDidUpdate(prevProps: IChangeLogListProps) {
    if (prevProps !== this.props) {
      this.reload()
    }
  }

  public render() {
    const { changeLog, loading } = this.state

    const { t } = this.props

    const columns: IListColumns[] = [
      {
        name: 'moved_from_date',
        id: 'oldEndTime',
        size: '150',
        dataField: 'oldEndTime',
        cell: (deadline) => (
          <span>{deadline ? moment(deadline).format('L') : ''}</span>
        ),
      },
      {
        name: 'moved_to_date',
        id: 'newEndTime',
        size: '150',
        dataField: 'newEndTime',
        cell: (deadline) => (
          <span>{deadline ? moment(deadline).format('L') : ''}</span>
        ),
      },
      {
        name: 'cause',
        id: 'reason',
        size: '400',
        dataField: 'reason',
        cell: (reason) => {
          return <span>{t(reason)}</span>
        },
      },
      {
        name: 'created_at',
        id: 'createdAt',
        size: '150',
        dataField: 'created_at',
        cell: (created) => (
          <span>{created ? moment(created).format('L') : ''}</span>
        ),
      },
      {
        name: 'updated_at',
        size: '150',
        id: 'updatedAt',
        dataField: 'updated_at',
        cell: (updated) => (
          <span>{updated ? moment(updated).format('L') : ''}</span>
        ),
      },
    ]

    return (
      <div className={'pr-4 pl-2 mr-4'}>
        <div className={ChangeLogList.styleClass.root}>
          {loading ? <Loader /> : null}

          <List
            data={changeLog}
            tableName={'changeLogList'}
            columns={columns}
            itemsPerPage={0}
          />
        </div>
      </div>
    )
  }
}

export default withTranslation()(ChangeLogList)
