import PersonWhite from '@icons/account_circle.svg'
import AccountTree from '@icons/account_tree.svg'
import AddCircle from '@icons/add_circle.svg'
import Analytics from '@icons/analytics-fill.svg'
import Assignment from '@icons/assignment-fill.svg'
import Build from '@icons/build.svg'
import Construction from '@icons/construction-fill.svg'
import DashboardCustomize from '@icons/dashboard_customize.svg'
import Organization from '@icons/domain.svg'
import DoneAll from '@icons/done_all.svg'
import EmojiFlags from '@icons/emoji_flags-fill.svg'
import Event from '@icons/event-fill.svg'
import EventNote from '@icons/event_note.svg'
import Folder from '@icons/folder.svg'
import Group from '@icons/group.svg'
import Layers from '@icons/layers.svg'
import MeetingRoom from '@icons/meeting_room-fill.svg'
import Moving from '@icons/moving.svg'
import Notifications from '@icons/notifications.svg'
import Report from '@icons/report.svg'
import RuleFolder from '@icons/rule_folder.svg'
import Settings from '@icons/settings.svg'
import ShowChart from '@icons/show_chart-fill.svg'
import ViewColumn from '@icons/view_column-fill.svg'
import ViewList from '@icons/view_list-fill.svg'
import VpnKey from '@icons/vpn_key-fill.svg'
import { useEffect, useMemo, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { IMenuItem, IMenuProps } from '../components/menu/Menu'
import { capFirstLetter } from '../utility/utils'

interface IIndexedMenuItem {
  isMainItem: boolean
  link: string
  index: number
  subIndex: number
  children?: IMenuItem[]
}

export const useMenuItems = ({
  currentProject,
  user,
}: Pick<IMenuProps, 'currentProject' | 'user'>) => {
  const { t } = useTranslation()
  const location = useLocation()

  const userContext = useContext(UserContext)

  const [activeItem, setActiveItem] = useState<IIndexedMenuItem>()

  const menuItems: IMenuItem[] = useMemo(
    () => [
      {
        name: `${user.firstName} ${user.lastName}`,
        link: `/users/${user.id}`,
        icon: PersonWhite,
        activeIcon: PersonWhite,
        show: true,
        componentData: {
          name: 'UserIcon',
          props: {
            user: {
              image_url: user.image_url,
              id: user.id,
            },
          },
        },
      },
      {
        name: '',
        link: '/',
        icon: Notifications,
        activeIcon: Notifications,
        show: true,
        componentData: {
          name: 'Notifications',
          props: {
            user: {
              id: user.id,
            },
            project: {
              id: currentProject.id,
            },
          },
        },
      },
      {
        name: t('portfolio'),
        link: '/organization',
        icon: Organization,
        activeIcon: Organization,
        show: true,
      },
      {
        name: t('dashboard'),
        link: '/',
        icon: DashboardCustomize,
        activeIcon: DashboardCustomize,
        show: true,
      },
      {
        name: t('project_planning'),
        link: '/kpi',
        icon: EventNote,
        activeIcon: EventNote,
        show: currentProject.planning_module,
        children: [
          {
            name: 'KPI',
            link: '/kpi/overview',
            icon: Analytics,
            activeIcon: Analytics,
            show: true,
          },
          {
            name: capFirstLetter(t('milestones')),
            link: '/milestone/overview',
            icon: EmojiFlags,
            activeIcon: EmojiFlags,
            show: true,
          },
          {
            name: capFirstLetter(t('keypoints')),
            link: '/keypoint/table_overview',
            icon: VpnKey,
            activeIcon: VpnKey,
            show: true,
          },
          {
            name: t('deliveries'),
            link: '/delivery',
            icon: Event,
            activeIcon: Event,
            show: true,
          },
          {
            name: t('tasks'),
            link: '/actions',
            icon: Assignment,
            activeIcon: Assignment,
            show: true,
          },
          {
            name: t('main_table_overview'),
            link: '/main-table',
            icon: ViewList,
            activeIcon: ViewList,
            show: true,
          },
          {
            name: t('main_visual_overview'),
            link: '/main-process-canvas',
            icon: ViewColumn,
            activeIcon: ViewColumn,
            show: true,
          },
          {
            name: t('gantt_chart'),
            link: '/gantt',
            icon: ShowChart,
            activeIcon: ShowChart,
            show: true,
          },
        ],
      },
      {
        name: t('cases'),
        link: '/tasks',
        icon: Layers,
        activeIcon: Layers,
        show: currentProject.task_module,
      },
      {
        name: t('construction'),
        link: '/constructions',
        icon: Build,
        activeIcon: Build,
        show: currentProject.construction_module,
        children: [
          {
            name: t('pace_planning'),
            link: '/constructions',
            icon: Construction,
            activeIcon: Construction,
            show: true,
          },
          {
            name: capFirstLetter(t('room')),
            link: '/rooms',
            icon: MeetingRoom,
            activeIcon: MeetingRoom,
            show: true,
          },
        ],
      },
      {
        name: t('object_module'),
        link: '/systems',
        icon: AccountTree,
        activeIcon: AccountTree,
        show:
          currentProject.systems_module &&
          userContext.actions.hasReadAccess('object'),
      },
      {
        name: t('systematic_completion'),
        link: '/systematiccompletion',
        icon: DoneAll,
        activeIcon: DoneAll,
        show: currentProject.systematic_completion_module,
      },
      {
        name: capFirstLetter(t('risk')),
        link: '/risk_groups',
        icon: Report,
        activeIcon: Report,
        show: currentProject.risk_module,
      },
      {
        name: t('continuous_improvements'),
        link: '/improvements',
        icon: Moving,
        activeIcon: Moving,
        show: currentProject.improvement_module,
      },
      {
        name: t('meetings'),
        link: '/meetings',
        icon: Group,
        activeIcon: Group,
        show: currentProject.meeting_module,
      },
      {
        name: t('documents'),
        link: '/documents',
        icon: Folder,
        activeIcon: Folder,
        show:
          currentProject.document_control_module &&
          userContext.actions.hasReadAccess('document'),
      },
      {
        name: t('fdvu_collection'),
        link: '/fdvu',
        icon: RuleFolder,
        activeIcon: RuleFolder,
        show: currentProject.fdvu_module,
      },
      {
        name: 'Bimsync',
        link: '/bimsync',
        icon: AddCircle,
        activeIcon: AddCircle,
        show: false,
      },
      {
        name: t('project_configuration'),
        link: '/project',
        icon: Settings,
        activeIcon: Settings,
        show: true,
      },
    ],
    [t, currentProject, user],
  )

  const menuItemsWithIndexes: IIndexedMenuItem[] = useMemo(() => {
    const allMenus = menuItems.map((item, index) => {
      if (!item?.children) {
        return {
          isMainItem: true,
          link: item?.link,
          index,
          subIndex: 0,
          children: item?.children,
        }
      }
      return item?.children?.map((subItem, subIndex) => {
        return { isMainItem: false, link: subItem?.link, index, subIndex }
      })
    })
    return allMenus?.flat()
  }, [menuItems])

  useEffect(() => {
    setActiveItem(
      menuItemsWithIndexes.find(
        (item) =>
          item.link !== '/' && location.pathname.includes(item.link ?? ''),
      ),
    )
  }, [location.pathname, menuItemsWithIndexes])

  return {
    menuItems,
    activeItem,
  }
}
