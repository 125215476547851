import { useRef, useState } from 'react'

import {
  classNames,
  constructFilterJson,
  IActiveFilter,
} from 'src/utility/utils'
import { IDiscipline, ITask, IUserData } from '../../service/OrgTypes'
import { getFilterTasks } from '../../service/TaskService'
import List from '../../ui-elements/list/List'
import {
  IListFilter,
  ResourceType,
} from '../../ui-elements/list/ListContextProvider'
import { IListColumns, ISorting } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import {
  RecordIdColumn,
  TaskTypeColumn,
  TitleColumn,
} from '../TableColumns/Columns'
import {
  DisciplineColumnWithOutEditing,
  ResponsibleColumn,
} from '../TableColumns/DisciplineResponsibleColumns'
import TaskInspectorPanel from './TaskInspectorPanel'

interface ITaskSelectorListProps {
  projectId: number
  parentType?: string
  parentId?: number
  parentIds?: number[]
  selectedIds?: number[]
  onSelectIds?: (ids: number[]) => void
}

const TaskSelectorList = ({
  projectId,
  parentType,
  parentId,
  parentIds,
  selectedIds,
  onSelectIds,
}: ITaskSelectorListProps) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col', 'pb-0', 'pl-0'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  const [tasks, setTasks] = useState<ITask[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [selectedTasks, setSelectedTasks] = useState<number[]>(
    selectedIds ?? [],
  )
  const [openInspectorPanel, setOpenInspection] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState(0)
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])

  const reload = () => {
    setReloadTable((n) => !n)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    taskFilter(activeFilters, page)
  }

  const taskFilter = (activeFilters: IActiveFilter, page: number) => {
    setLoading(true)

    if (parentId) {
      activeFilters['parent_id'] = []
      activeFilters['parent_id'].push(parentId)
    }

    if (parentIds) {
      activeFilters['parent_id'] = parentIds
    }

    if (parentType) {
      activeFilters['parent_type'] = []
      activeFilters['parent_type'].push(parentType)
    }

    if (parentType === 'Delivery') {
      activeFilters['parent_type'] = undefined
      activeFilters['parent_id'] = undefined
      activeFilters['delivery'] = []
      activeFilters['delivery'].push(parentId)
    }

    getFilterTasks(projectId, activeFilters, page, 20, 'Aksjon').then((res) => {
      setTasks(res.tasks)
      setTotalPages(res.pages)
      setLoading(false)
    })
  }

  const filterCol: IListColumns[] = [
    RecordIdColumn('200'),
    TitleColumn('title', '450', () => {}),
    TaskTypeColumn(projectId, () => {}),
    ResponsibleColumn(
      projectId,
      projectUsers,
      projectDiscipline,
      () => {},
      true,
    ),
    DisciplineColumnWithOutEditing(),
  ]

  const handlePreviewClick = (e: any, data: ITask) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenInspection(true)
    if (data.id) {
      setSelectedTaskId(data.id)
    }
  }

  const onCloseInpectionPanel = () => {
    setOpenInspection(false)
  }

  return (
    <div>
      <div className={styleClass.root}>
        <List
          columns={filterCol}
          data={tasks}
          tableName={'TaskSelectorList'}
          itemsPerPage={50}
          pagination={true}
          resourceType={ResourceType.TASK}
          filterParent={'task'}
          getFilteredData={getFilteredData}
          reload={reloadTable}
          totalPages={totalPages}
          parent_type={parentType}
          isRowSelectable={true}
          onSelectRow={(data) => {
            setSelectedTasks(data)
            if (onSelectIds) {
              onSelectIds(data)
            }
          }}
          selectedRows={selectedTasks}
          disableConfigStore={true}
          disableColumnSelector={true}
          parentId={parentId || parentIds}
          sortBackend={true}
          actionMenu={undefined}
          onPreviewClick={handlePreviewClick}
        />
      </div>

      {openInspectorPanel && selectedTaskId && (
        <TaskInspectorPanel
          taskId={selectedTaskId}
          open={openInspectorPanel}
          onClose={onCloseInpectionPanel}
          projectId={projectId}
          onUpdate={reload}
        />
      )}

      {loading ? <Loader /> : null}
    </div>
  )
}

export default TaskSelectorList
