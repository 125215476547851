import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SystemRecordIDColumn,
  SystemStatusColumn,
  SystemTypeColumn,
  TestSystemGroupsColumn,
  TextColumn,
} from 'src/components/TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumnWithOutEditing,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import { IPaginateResult, ISystem } from 'src/service/OrgTypes'
import { getSystemsWithPage } from 'src/service/SystemService'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import {
  filterType,
  IListColumns,
  ISorting,
} from 'src/ui-elements/list/ListTypes'
import {
  classNames,
  constructFilterJson,
  IActiveFilter,
} from 'src/utility/utils'
import SystemInspectorPanel from '../../components/system/SystemInspectorPanel'
import List from '../../ui-elements/list/List'
import Modal from '../../ui-elements/modal/Modal'

export enum Target {
  YES = 'yes',
  NO = 'no',
}

interface ISelectSingleSystemsListProps {
  projectId: number
  onSelectSystem?: (system: ISystem) => void
  closeModal: () => void
  currentSystemId?: number
  selectedSystemId?: number | null | undefined
  systemSettingId?: number
}

const SelectSingleSystemsList: FC<ISelectSingleSystemsListProps> = ({
  projectId,
  onSelectSystem,
  closeModal,
  currentSystemId,
  selectedSystemId,
  systemSettingId,
}) => {
  const styleClass = {
    root: classNames('min-h-[670px]', 'px-2', 'z-[80]'),
  }

  const [systems, setSystems] = useState<ISystem[]>([])
  const [selectedSystem, setSelectedSystem] = useState<ISystem | undefined>(
    undefined,
  )
  const [pages, setPages] = useState<number>(1)
  const [showInspector, setShowInspector] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)

  const { t } = useTranslation()

  const pageSize = 30

  const handlePreviewClick = (e: any, data: ISystem) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedSystem(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedSystem(undefined)
    setReload((n) => !n)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting

    if (systemSettingId) {
      activeFilters['system_setting'] = [systemSettingId]
    }

    filterSystems(activeFilters, page)
  }

  const filterSystems = (activeFilters: IActiveFilter, page: number) => {
    getSystemsWithPage(projectId, page, pageSize, activeFilters).then(
      (res: IPaginateResult<ISystem>) => {
        setSystems(res.items)
        setPages(res.pages)
      },
    )
  }

  const columns: IListColumns[] = [
    SystemRecordIDColumn(),
    TextColumn('name', 'name', '300', (_key, _value) => undefined, true),
    SystemTypeColumn(),
    SystemStatusColumn(),
    {
      name: 'location',
      id: 'location',
      size: '200',
      dataField: 'location',
      sortingField: 'location',
      filter: [],
      filterType: filterType.TEXT,
    },
    TestSystemGroupsColumn(() => {}, true),
    DisciplineColumnWithOutEditing(),
    ContractColumn(),
  ]

  return (
    <Modal
      show={true}
      title={t('select_items_you_want_to_add_w_param', {
        param: t('systems').toLowerCase(),
      })}
      closeModal={closeModal}
      size={'w-9/10'}
      maxWidth={1650}
    >
      <div className={styleClass.root}>
        <List
          columns={columns}
          data={systems}
          tableName={'SystemsSelectorList'}
          itemsPerPage={pageSize}
          onRowDisable={(id) => id === currentSystemId}
          pagination={true}
          totalPages={pages}
          reload={reload}
          selectedRows={selectedSystemId ? [selectedSystemId] : []}
          filterResetOption={true}
          onRowClick={onSelectSystem}
          getFilteredData={getFilteredData}
          onPreviewClick={handlePreviewClick}
        />

        {selectedSystem && showInspector && (
          <SystemInspectorPanel
            onUpdate={() => setReload((n) => !n)}
            systemId={selectedSystem.id}
            open={showInspector}
            onClose={onCloseInspector}
          />
        )}
      </div>
    </Modal>
  )
}

export default SelectSingleSystemsList
