import Check from '@icons/check.svg'
import Delete from '@icons/delete.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CircularButton from 'src/components/CircularButton'
import DropdownMenu from 'src/document/components/DocumentButtonWithDropdown/DropdownMenu'
import { classNames } from 'src/utility/utils'

interface MarkNotificationAsReadProps {
  isRead: boolean
  markAsRead: () => void
  markAsUnread: () => void
  removeNotification: () => void
  showOptionsButton: boolean
  className?: string
}

const MarkNotificationAsRead = ({
  isRead,
  markAsRead,
  markAsUnread,
  removeNotification,
  showOptionsButton,
  className = '',
}: MarkNotificationAsReadProps) => {
  const { t } = useTranslation()
  const [showDropdownMenu, setShowDropdownmenu] = useState(false)

  return (
    <div className={classNames('relative z-50 h-3 w-3 mx-2', className)}>
      {!isRead && (
        <div
          className="w-3 h-3 rounded-full bg-blue-500"
          onClick={(e) => {
            e.stopPropagation()
            markAsRead()
          }}
        />
      )}
      <div className="absolute right-5 top-1.5 -translate-y-1/2">
        {(showOptionsButton || showDropdownMenu) && (
          <CircularButton
            onClick={(e) => {
              e.stopPropagation()
              setShowDropdownmenu((isOpen) => !isOpen)
            }}
            mdIcon="more_horiz"
          />
        )}
        <DropdownMenu
          isOpen={showDropdownMenu}
          onClose={() => setShowDropdownmenu(false)}
          items={[
            {
              icon: <Check />,
              title: isRead ? t('mark_as_unread') : t('mark_as_read'),
              onClick: isRead ? markAsUnread : markAsRead,
            },
            {
              icon: <Delete />,
              title: t('remove_this_notification'),
              onClick: removeNotification,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default MarkNotificationAsRead
