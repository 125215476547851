import { request } from './service-utils'

export const search = (
  projectId: number,
  query: string,
  modules: string[],
  page = 1,
  perPage = 10,
): Promise<any> => {
  return request(
    `/projects/${projectId}/search/pages/${page}/${perPage}`,
    'POST',
    { query_string: query, domain: modules },
  )
}
