import { useState } from 'react'
import * as React from 'react'

import { useMultipleKeysTranslation } from '../../../../service/useMultipleKeysTranslation'
import { classNames } from '../../../../utility/utils'
import {
  BASE_METADATA_TYPES,
  IMetadataBaseType,
  IMetaDataType,
} from '../../../types/FolderMetadataTypes'
import FolderMetadataIconWrapper from './FolderMetadataIconWrapper'

interface IFolderMetadataFieldDropdown {
  value: IMetaDataType
  possibleMetadataTypes: Record<string, IMetadataBaseType>
  onClick: (newType: IMetaDataType) => void
  menuOnTheLeft?: boolean
}

const FolderMetadataFieldDropdown: React.FC<IFolderMetadataFieldDropdown> = ({
  value,
  onClick,
  menuOnTheLeft,
  possibleMetadataTypes,
}) => {
  const [selectedType, setSelectedType] = useState<BASE_METADATA_TYPES>(
    value.data_type,
  )
  const { t } = useMultipleKeysTranslation()

  const styleClass = {
    root: (menuOnTheLeft: boolean) =>
      classNames(
        'bg-white',
        'absolute',
        'top-1',
        menuOnTheLeft && '-right-0',
        'shadow',
        'py-2',
        'z-50',
        'text-sm',
        'border border-gray-300',
      ),
    topMenu: classNames('flex', 'justify-between', 'pb-2', 'px-2'),
    topMenuItem: (isActive: boolean) =>
      classNames(
        'flex',
        'items-center',
        'cursor-pointer',
        'px-2',
        'py-1',
        'w-mx-content',
        'hover:bg-blue-50',
        isActive ? 'bg-d-lightblue text-sm text-blue-root' : 'bg-white',
      ),
    bottomMenu: classNames('rounded-b-lg', 'pt-1', 'px-2'),
    bottomMenuItem: (isActive: boolean) =>
      classNames(
        'flex',
        'items-center',
        'cursor-pointer',
        'hover:bg-blue-50',
        'p-1',
        'rounded',
        isActive ? 'bg-d-lightblue' : '',
      ),
  }

  const isActiveTopMenu = (typeToCheck: BASE_METADATA_TYPES) =>
    typeToCheck === selectedType

  const isActiveBottomMenu = (typeToCheck: string | string[]) =>
    typeToCheck === value.pattern

  const changeType = (type: BASE_METADATA_TYPES) => {
    setSelectedType(type)
  }

  const selectMetadatatype = (metaDataType: IMetaDataType) => {
    onClick(metaDataType)
  }

  return (
    <div className={styleClass.root(!!menuOnTheLeft)}>
      <div className={styleClass.topMenu}>
        {Object.values(possibleMetadataTypes)
          .filter((baseType) => !baseType.hide)
          .map((baseType) => (
            <div
              key={baseType.type}
              className={styleClass.topMenuItem(isActiveTopMenu(baseType.type))}
              onClick={() => changeType(baseType.type)}
            >
              <div className="pr-1">
                <FolderMetadataIconWrapper type={baseType.type} />
              </div>
              {isActiveTopMenu(baseType.type) && (
                <div>
                  {baseType.readableNameKey
                    ? t(baseType.readableNameKey)
                    : baseType.pattern}
                </div>
              )}
            </div>
          ))}
      </div>
      <hr />
      <div className={styleClass.bottomMenu}>
        {Object.values(possibleMetadataTypes[selectedType].children)
          .filter((metaDataType) => !metaDataType.exclude)
          .map((metaDataType: IMetaDataType, index) => (
            <div
              className={styleClass.bottomMenuItem(
                isActiveBottomMenu(metaDataType.pattern),
              )}
              key={index}
              onClick={() => selectMetadatatype(metaDataType)}
            >
              <div className="pr-2">
                <FolderMetadataIconWrapper type={selectedType} />
              </div>
              {metaDataType.readableNameKey
                ? t(metaDataType.readableNameKey)
                : metaDataType.pattern}
            </div>
          ))}
      </div>
    </div>
  )
}

export default FolderMetadataFieldDropdown
