import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import {
  IWithProjectContext,
  withProjectContext,
} from 'src/context/withProjectContext'
import DocumentMetaDataFields from 'src/document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from 'src/document/types/IMetaData'
import {
  getMainProcesses,
  IMainProcessResponse,
} from 'src/service/ProcessService'
import { getProjectSystemTypeGroupsPerDomain } from 'src/service/SystemTypeGroupService'
import { getProjectTags } from 'src/service/TagService'
import { getMainProcessTeams } from 'src/service/TeamService'
import {
  getProjectDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { getDisciplines } from '../../service/DisciplineService'
import { createMilestone, getMilestone } from '../../service/MilestoneService'
import {
  IDisciplineData,
  IMilestone,
  ISystemTypeGroup,
  ITag,
  ITeam,
  IUserData,
} from '../../service/OrgTypes'
import {
  getErrorMessage,
  ValidationError,
} from '../../service/ValidationErrors'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import { renderDayContents } from '../../utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'
import Selector from '../selectors/Selector'
import { initializeMetaValues, setExistingValues } from '../system/SystemUtil'

interface IEditMilestoneFormProps extends IWithProjectContext {
  projectId: number
  closeModal: () => void
  reloadList?: () => void
  milestone: IMilestone
  copy?: boolean
}

const MilestoneForm = (props: IEditMilestoneFormProps) => {
  const styleClass = {
    root: classNames('flex', 'flex-col'),
    inputGroup: classNames('w-full', 'sm:w-full', 'flex'),
    label: classNames(
      'flex',
      'items-center',
      'w-full',
      'font-roboto',
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'my-2',
      'pl-2',
    ),
    Group: classNames('w-full', 'flex', 'my-1'),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'ml-2',
      'text-sm',
      'font-normal',
    ),
  }
  const [projectId, setProjectId] = useState<number>(
    props.projectContext ? props.projectContext.state.currentProject.id : 0,
  )
  const [name, setName] = useState<string>(
    props.milestone ? props.milestone.name : '',
  )
  const [status] = useState<string>(
    props.milestone.status ? props.milestone.status : 'open',
  )
  const [description, setDescription] = useState<string>(
    props.milestone ? props.milestone.description : '',
  )
  const [responsibleId, setResponsibleId] = useState<number>(
    props.milestone ? props.milestone.responsible_id : 0,
  )
  const [disciplineId, setDisciplineId] = useState<number>(
    props.milestone ? props.milestone.discipline_id : 0,
  )
  const [contractId, setContractId] = useState<number | null>(
    props.milestone && props.milestone.contract_id
      ? props.milestone.contract_id
      : null,
  )
  const [mainProcessId, setMainProcessId] = useState<number>(
    props.milestone ? props.milestone.main_process_id : 0,
  )
  const [deadline, setDeadline] = useState<any>(
    props.milestone && props.milestone.deadline
      ? moment(props.milestone.deadline)
      : null,
  )
  const [tags, setTags] = useState<ITag[]>(
    props.milestone && props.milestone.tag ? [props.milestone.tag] : [],
  )
  const [tagId, setTagId] = useState<number | null>(
    props.milestone && props.milestone.tag_id ? props.milestone.tag_id : null,
  )
  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [themeId, setThemeId] = useState<number>(0)
  const [themes, setThemes] = useState<ITeam[]>([])
  const [disciplines, setDiscipline] = useState<IDisciplineData[]>([])
  const [mainProcess, setMainProcess] = useState<IMainProcessResponse[]>(
    props.milestone && props.milestone.main_process
      ? [props.milestone.main_process as IMainProcessResponse]
      : [],
  )
  const [users, setUsers] = useState<IUserData[]>([])
  const [duration, setDuration] = useState<number>(0)
  const [datoErrorMessage, setDatoErrorMessage] = useState<string>('')
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('')
  const [processErrorMessage, setProcessErrorMessage] = useState<string>('')
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [theamErrorMessage, setTheamErrorMessage] = useState<string>('')
  const [responsibleErrorMessage, setResponsibleErrorMessage] =
    useState<string>('')
  const [selectLoading, setSelectLoading] = useState<boolean>(false)
  const [usersLoading, setUsersLoading] = useState<boolean>(false)
  const [processLoading, setProcessLoading] = useState<boolean>(false)
  const [disciplineLoading, setDisciplineLoading] = useState<boolean>(false)
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<ISystemTypeGroup>()

  const { t } = useTranslation()

  const fetchSystemGroupType = useCallback(() => {
    getProjectSystemTypeGroupsPerDomain(projectId, 'Milestone').then(
      (res: ISystemTypeGroup[]) => {
        const value = res.pop()
        setSystemTypeGroup(value)
        if (value) {
          const metaFields = initializeMetaValues(
            value.optional_fields ?? [],
            'Milestone',
            props.milestone?.id,
          )
          setOptionalFields(
            setExistingValues(
              props.milestone?.optional_fields ?? [],
              metaFields,
            ),
          )
        }
      },
    )
  }, [])

  useEffect(() => {
    setProjectId(
      props.projectContext ? props.projectContext.state.currentProject.id : 0,
    )
    const { milestone, copy } = props
    if (milestone.responsible_id) {
      getUsers()
    }
    if (copy) {
      getCopiedMilestone(milestone)
    }
    fetchSystemGroupType()
  }, [props])

  const getCopiedMilestone = async (milestone: any) => {
    const currentMilestone = await getMilestone(projectId, milestone.id)
    setDeadline(moment(milestone.date))
    setName(currentMilestone.name)
    setDuration(currentMilestone.duration ? currentMilestone.duration : 0)
    setDescription(currentMilestone.description)
    setMainProcessId(milestone.mainProcess_id)
    onMainProcessChange(milestone.mainProcess_id)
    setThemeId(
      currentMilestone && currentMilestone.team_id
        ? currentMilestone.team_id
        : 0,
    )
    setResponsibleId(currentMilestone.responsible_id)
    setDisciplineId(currentMilestone.discipline_id)
    setContractId(
      currentMilestone.contract_id ? currentMilestone.contract_id : null,
    )
    setTagId(currentMilestone.tag_id ? currentMilestone.tag_id : 0)
    setTags(currentMilestone.tag ? [currentMilestone.tag] : [])
    onOpenMainProcess()
    onDisciplineOpen()
    getUsers()
  }

  const onOpenMainProcess = () => {
    setProcessLoading(true)
    getMainProcesses(projectId)
      .then((res) => {
        setMainProcess(res)
        setProcessLoading(false)
      })
      .catch((err) => console.error(err))
  }

  const onTagChange = (value: number) => {
    setTagId(value)
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const getTags = () => {
    setSelectLoading(true)
    getProjectTags(projectId).then((res) => {
      if (res) {
        setTags(res)
        setSelectLoading(false)
      }
    })
  }

  const onDisciplineOpen = () => {
    setDisciplineLoading(true)
    getDisciplines(projectId)
      .then((res) => {
        setDiscipline(res)
        setDisciplineLoading(false)
      })
      .catch((err) => console.error(err))
  }

  const getUsers = () => {
    setUsersLoading(true)
    if (disciplineId > 0) {
      getProjectDisplineUsers(projectId, disciplineId).then((res) => {
        setUsers(res)
        setUsersLoading(false)
      })
    } else {
      getProjectUsersWithDisciplines(projectId).then((res) => {
        setUsers(res)
        setUsersLoading(false)
      })
    }
  }

  const onNameChange = (e: any) => {
    setName(e.target.value)
    setNameErrorMessage('')
  }

  const onMainProcessChange = (value: number) => {
    getMainProcessTeams(value).then((res) => {
      setMainProcessId(value)
      setThemes(res)
      setProcessErrorMessage('')
    })
  }

  const onDisciplineChange = async (value: number) => {
    const dis = disciplines
      ? disciplines.find((v) => v.id === value)
      : undefined
    if (responsibleId <= 0) {
      const usersList = await getProjectDisplineUsers(projectId, value)
      setResponsibleId(0)
      setUsers(usersList)
    }

    setDisciplineId(value)
    setContractId(dis && dis.contract_id ? dis.contract_id : null)

    setResponsibleErrorMessage('')
    setDisciplineErrorMessage('')
  }

  const onResponsibleChange = (userId: number) => {
    const selectedUser: IUserData | undefined = users
      .filter((u) => u.id === userId)
      .pop()
    const userDisciplines =
      selectedUser && selectedUser.disciplines
        ? selectedUser.disciplines.filter((d) => d.project_id === projectId)
        : undefined
    const discipline =
      userDisciplines && userDisciplines.length === 1
        ? userDisciplines[0]
        : undefined

    setResponsibleId(userId)
    setDiscipline(userDisciplines ? userDisciplines : [])
    if (discipline) {
      setDisciplineId(discipline.id)
      setContractId(discipline.contract_id)
    }

    setResponsibleErrorMessage('')
    setDisciplineErrorMessage('')
  }

  const onDisciplineUserClear = async () => {
    setDisciplineId(0)
    setResponsibleId(0)
    const allProjectDis = await getDisciplines(projectId)
    const allUsers = await getProjectUsersWithDisciplines(projectId)
    setDiscipline(allProjectDis)
    setUsers(allUsers)
  }

  const onDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const onDurationChange = (e: any) => {
    const value = +e.target.value
    setDuration(value)
  }

  const onSubmit = (e: any) => {
    let error = false
    e.preventDefault()
    setLoading(true)
    setDatoErrorMessage('')
    setNameErrorMessage('')
    setProcessErrorMessage('')
    setDisciplineErrorMessage('')
    setResponsibleErrorMessage('')
    setTheamErrorMessage('')

    if (!deadline || !moment(deadline).isValid()) {
      setDatoErrorMessage(getErrorMessage(ValidationError.MISSING_DEADLINE, t))
      error = true
    }

    if (!name || name === '') {
      setNameErrorMessage(getErrorMessage(ValidationError.MISSING_TITLE, t))
      error = true
    }

    if (!disciplineId || disciplineId === 0) {
      setDisciplineErrorMessage(
        getErrorMessage(ValidationError.MISSING_DISCIPLINE, t),
      )
      error = true
    }

    if (!mainProcessId || mainProcessId === 0) {
      setProcessErrorMessage(
        getErrorMessage(ValidationError.MISSING_MAIN_PROCESS, t),
      )
      error = true
    }

    if (!responsibleId || responsibleId === 0) {
      setResponsibleErrorMessage(
        getErrorMessage(ValidationError.MISSING_RESPONSIBLE, t),
      )
      error = true
    }

    if (!error) {
      e.preventDefault()
      const milestone: IMilestone = {
        id: props.milestone.id,
        name,
        status,
        main_process_id: mainProcessId,
        responsible_id: responsibleId,
        contract_id: contractId,
        discipline_id: disciplineId,
        team_id: themeId > 0 ? themeId : undefined,
        description,
        deadline,
        project_id: props.projectId,
        tag_id: tagId ? tagId : null,
        duration,
        system_type_group_id: systemTypeGroup?.id,
        optional_fields: optionalFields,
      }

      createMilestone(milestone, props.projectId)
        .then(() => {
          if (!createMultiple) {
            props.closeModal()
            setLoading(false)
          } else {
            if (props.reloadList) {
              props.reloadList()
            }
            setName('')
            setLoading(false)
            setCreateMultiple(false)
          }
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  const setKeypointDatePickerValue = (data: moment.Moment) => {
    setDeadline(data)
    setDatoErrorMessage('')
  }

  const setDatePickerFocus = (focused: any) => {
    setDatePickerFocused(focused.focused)
  }

  const outSideRangeDate = () => {
    return false
  }

  const onThemeChange = (value: number) => {
    const selectedTheam = themes
      .filter((team: ITeam) => team.id === value)
      .pop()
    setThemeId(value)
    setMainProcessId(
      selectedTheam
        ? selectedTheam.main_process_id
          ? selectedTheam.main_process_id
          : mainProcessId
        : mainProcessId,
    )
    setTheamErrorMessage('')
  }

  const themesCheck = themes.filter(
    (theme: any) => theme.main_process_id === mainProcessId,
  )

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setOptionalFields(values)
  }

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={`${styleClass.inputGroup} flex-wrap items-center`}>
        <div className={'w-mx-content flex flex-col mb-2'}>
          <label className={styleClass.label}>
            {capFirstLetter(t('deadline'))} *
            {datoErrorMessage && (
              <span
                className={styleClass.errorMessage}
              >{`  ${datoErrorMessage}`}</span>
            )}
          </label>
          <div className={'pl-2 mb-1 z-20 px-2'}>
            <SingleDatePicker
              firstDayOfWeek={1}
              date={deadline}
              onDateChange={setKeypointDatePickerValue}
              renderDayContents={renderDayContents}
              focused={datePickerFocused}
              onFocusChange={setDatePickerFocus}
              id="datePicker-milestone"
              small={true}
              isOutsideRange={outSideRangeDate}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
      </div>
      <div className={'mb-3 pt-px w-36'}>
        <Input
          label={t('duration_days')}
          value={duration}
          onChange={onDurationChange}
          block={true}
          required={false}
          type={'number'}
          minValue={0}
          step={'any'}
        />
      </div>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('title')}
          value={name}
          onChange={onNameChange}
          block={true}
          required={true}
          errorMessage={nameErrorMessage}
          autoFocus={true}
        />
      </div>

      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full md:w-1/2'}>
          <Selector
            items={mainProcess}
            selectedItemId={mainProcessId}
            onSelect={onMainProcessChange}
            onOpenSelectFunction={onOpenMainProcess}
            loading={processLoading}
            label={t('main_process')}
            dataFields={['name']}
            required={true}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={processErrorMessage}
          />
        </div>
        {themesCheck.length > 0 ? (
          <div className={'w-full md:w-1/2'}>
            <Selector
              items={themes.filter(
                (theme: any) => theme.main_process_id === mainProcessId,
              )}
              selectedItemId={themeId}
              loading={selectLoading}
              onSelect={onThemeChange}
              label={t('team')}
              dataFields={['name']}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={theamErrorMessage}
            />
          </div>
        ) : null}
      </div>

      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full md:w-1/2'}>
          <Selector
            items={disciplines}
            selectedItemId={disciplineId}
            onOpenSelectFunction={onDisciplineOpen}
            onSelect={onDisciplineChange}
            label={t('discipline')}
            dataFields={['shortName', 'name']}
            required={true}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={disciplineErrorMessage}
            loading={disciplineLoading}
            onCancel={onDisciplineUserClear}
            cancelButton={true}
          />
        </div>
        <div className={'w-full md:w-1/2'}>
          <Selector
            items={users}
            selectedItemId={responsibleId}
            onSelect={onResponsibleChange}
            onOpenSelectFunction={getUsers}
            loading={usersLoading}
            label={t('responsible')}
            dataFields={['firstName', 'lastName']}
            required={true}
            fontSize={'sm'}
            userSelector={true}
            fontWeight={'bold'}
            errorMessage={responsibleErrorMessage}
            onCancel={onDisciplineUserClear}
            cancelButton={true}
          />
        </div>
      </div>
      <div className={`${styleClass.Group}`}>
        <div className={'w-full md:w-1/2'}>
          <Selector
            items={tags}
            selectedItemId={tagId ? tagId : 0}
            loading={selectLoading}
            onSelect={onTagChange}
            onOpenSelectFunction={getTags}
            label={t('type')}
            dataFields={['name']}
            fontSize={'sm'}
            fontWeight={'bold'}
            cancelButton={true}
            onCancel={() => setTagId(null)}
          />
        </div>
      </div>
      <div className={styleClass.inputGroup}>
        <div className={'mt-2 w-full mb-2'}>
          <Textarea
            isValid={false}
            label={t('description')}
            value={description}
            onChange={onDescriptionChange}
            block={true}
            required={false}
          />
        </div>
      </div>
      {optionalFields.length > 0 && (
        <div className="pb-8">
          <DocumentMetaDataFields
            title={''}
            onFieldsUpdate={onOptionalFieldsUpdate}
            fields={optionalFields}
            required={false}
          />
        </div>
      )}
      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={props.closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        <Button type={Button.ButtonType.SECONDARY} onClick={onMultipleCreate}>
          {loading ? <Spinner /> : capFirstLetter(t('add_multiple'))}
        </Button>
        <Button type={Button.ButtonType.PRIMARY} onClick={onSingleCreate}>
          {loading ? <Spinner /> : capFirstLetter(t('add'))}
        </Button>
      </ModalFooter>
    </form>
  )
}

export default withProjectContext(MilestoneForm)
