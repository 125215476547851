import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import useProjectId from 'src/components/hooks/useProjectId'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { ITestExecution } from 'src/service/OrgTypes'
import {
  editTestParticipant,
  getCurrentTestExecution,
} from 'src/service/TestService'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { IEvent } from 'src/utility/EmailProviderUtil'
import MCard from './MCard'
import MInviteAcceptance from './MInviteAcceptance'
import MTestExecution from './MTestExecution'

const MTestInviteAcceptance = () => {
  const { location } = useHistory()
  const queryParams = new URLSearchParams(location.search)
  const userContext = useContext(UserContext).state

  const rsvp = queryParams.get('rsvp')

  const projectId = useProjectId()

  const { testId } = useParams<{ testId: string }>()
  const [loading, setLoading] = useState(true)
  const [execution, setExecutions] = useState<ITestExecution>()

  const userEmail = userContext.user.email

  const { t } = useTranslation()

  const attendance = useCallback(async () => {
    const participant = execution?.single_test_participants?.find(
      (participant) => participant.email === userEmail,
    )
    if (participant) {
      participant.is_present = rsvp === 'accept'
      editTestParticipant(participant)
    }
  }, [execution?.single_test_participants, userEmail, rsvp])

  useEffect(() => {
    attendance()
  }, [attendance, userEmail])

  const loadData = useCallback(async () => {
    const res = await getCurrentTestExecution(projectId, Number(testId))
    setExecutions(res)
    setLoading(false)
  }, [projectId, testId])

  useEffect(() => {
    loadData()
  }, [loadData])

  const PageLoader = () => (
    <div className={'w-full h-full grid place-items-center relative z-n1'}>
      <SpinnerMobile />
    </div>
  )

  const event: IEvent | undefined = useMemo(() => {
    if (!execution) return undefined
    return {
      id: execution.test.id,
      title: execution.test.name,
      description: execution.test.description,
      location: execution.location,
      start: execution.test_planned_start ?? new Date().toISOString(),
      participants:
        execution.test?.single_test_participants?.map((p) => {
          return {
            name: `${p.firstName} ${p.lastName}`,
            email: p.email,
            isPresent: p.is_present,
          }
        }) ?? [],
      end: execution.test_planned_end ?? new Date().toISOString(),
    }
  }, [execution])

  return (
    <MInviteAcceptance
      event={event}
      title={t('test_execution')}
      rsvp={rsvp ?? ''}
    >
      {loading ? (
        <PageLoader />
      ) : (
        <MCard>{execution && <MTestExecution execution={execution} />}</MCard>
      )}
    </MInviteAcceptance>
  )
}

export default MTestInviteAcceptance
