import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const qaConfig: IEnvironmentConfig = {
  host: "qa.bygg.io",
  exportBaseUrl: "https://export-qa.bygg.io/",
  frontendBaseUrl: "https://qa.bygg.io/",
  organizationBaseUrl: "https://org-qa.bygg.io/",
  proxyBaseUrl: "https://proxy-qa.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-qa.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-qa.bygg.io/",
  bimServiceBaseUrl: "https://bim-service-qa.bygg.io/",
  kpiBaseUrl: "https://kpi-qa.bygg.io/"
}
