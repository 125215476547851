import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getProjectKeypoints,
  getProjectKeypointsFiltered,
} from 'src/service/ProcessService'

export const useKeypoints = () => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['keypoints', projectId],
    queryFn: () => getProjectKeypoints(projectId),
  })
}

export const useFilteredKeypoints = (filter: any) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['keypoints', projectId, filter],
    queryFn: () => getProjectKeypointsFiltered(projectId, filter),
  })
}
