export interface IMarkdownProps {
  value: any
  classNames?: string[]
}

const MarkdownBold = ({ value, classNames }: IMarkdownProps) => {
  if (typeof value === 'string') {
    const bold = /\*\*(.*?)\*\*/gm
    const splitedString = value.split(bold)
    const updatedValue = splitedString.map((ss: any, i: any) =>
      i % 2 === 0 ? (
        ss
      ) : (
        <b className={classNames && classNames[(i - 1) / 2]} key={i}>
          {ss}
        </b>
      ),
    )
    return updatedValue
  } else {
    return value ? value : ''
  }
}

const MarkdownSpan = ({ value, classNames }: IMarkdownProps) => {
  if (typeof value !== 'string') {
    return value || ''
  }

  const spanRegex = /<markdownspan[^>]*>/gm
  const splitString = value.split(spanRegex)

  const updatedValue = splitString.map((substring: string, index: number) =>
    index % 2 === 0 ? (
      substring
    ) : (
      <span className={classNames && classNames[(index - 1) / 2]} key={index}>
        {substring}
      </span>
    ),
  )

  return updatedValue
}

export { MarkdownBold, MarkdownSpan }
