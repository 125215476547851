import Close from '@icons/close.svg'
import Download from '@icons/download.svg'
import { partition } from 'lodash'
import FileUploadProgress from 'src/document/components/FileUpload/Status/FileUploadProgress'
import { IUploadState } from 'src/document/components/FileUpload/types'
import IconButton from 'src/ui-elements/button/IconButton'
import { classNames, isImageType, openFileExternal } from 'src/utility/utils'
import ImagePreview from './ImagePreview'

export interface CommentFileItem {
  documentId?: number
  name: string
  url?: string
  file?: File
  type: string
  error?: string
}

interface CommentFilesListProps {
  files: CommentFileItem[]
  pendingFiles?: { name: string; fileInfo: IUploadState }[]
  editMode?: boolean
  onRemoveFile?: (file: CommentFileItem) => void
  className?: string
}

const CommentFilesList: React.FC<CommentFilesListProps> = ({
  files,
  pendingFiles,
  editMode = false,
  onRemoveFile,
  className = '',
}) => {
  const [imageFiles, otherFiles] = partition(
    files,
    (file) => isImageType(file.type) && !file.error,
  )

  return (
    <div className={classNames('flex flex-col gap-4', className)}>
      <div className="flex flex-col gap-2">
        {otherFiles.map((file, i) => (
          <div
            key={i}
            className={classNames(
              'bg-gray-300 py-1 px-2 flex gap-2 items-center rounded-lg mr-auto max-w-full',
              !editMode && 'hover:bg-gray-400 cursor-pointer',
            )}
            onClick={!editMode ? openFileExternal(file.url) : undefined}
          >
            <span className="text-black font-medium text-xs truncate">
              {file.name}
            </span>
            {file.error && (
              <span className="text-red font-medium text-xs truncate">
                {file.error}
              </span>
            )}
            {!editMode ? (
              <Download />
            ) : (
              <IconButton
                onClick={() => onRemoveFile && onRemoveFile(file)}
                icon={<Close />}
                className="bg-white"
              />
            )}
          </div>
        ))}
      </div>
      {imageFiles.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-4">
          {imageFiles.map((imageFile, i) => (
            <ImagePreview
              key={i}
              imageUrl={imageFile.url}
              imageName={imageFile.name}
              openOnClick={!editMode}
              onDelete={
                editMode
                  ? () => onRemoveFile && onRemoveFile(imageFile)
                  : undefined
              }
            />
          ))}
        </div>
      )}
      {pendingFiles?.map((file) => (
        <div key={file.name} className="text-xs flex w-full">
          <span className="w-48 truncate">{file.name}</span>
          <FileUploadProgress fileInfo={file.fileInfo} />
        </div>
      ))}
    </div>
  )
}

export default CommentFilesList
