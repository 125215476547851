export const wagonListQRExportTemplate = (url: string) => {
  return {
    geniusApiRequest: {
      url: `{organizationApiBaseUrl}${url}/construction_locomotives/pages/1/1000`,
      method: 'POST',
    },
    name: 'Wagon',
    displayName: 'Vogner',
    rows: ['id', 'title'],
    columns: ['Id', 'Vogn'],
    qrUrlTemplate:
      '{currentUrl}?modal=constructionlocomotive&id=$id&project={projectId}',
  }
}
