import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRiskThreat } from '../../service/OrgTypes'
import List from '../../ui-elements/list/List'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import { classNames } from '../../utility/utils.js'
import RiskThreatForm from './RiskThreatForm'

interface IRiskThreatListProps {
  riskThreats: IRiskThreat[]
  quantityUnit: string
  reload: () => void
}

const RiskThreatList = ({
  riskThreats,
  quantityUnit,
  reload,
}: IRiskThreatListProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  const [selectedRiskThreat, setSelectedRiskThreat] = useState<
    IRiskThreat | undefined
  >(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)

  const { t } = useTranslation()

  const onEditItemClick = (riskThreat: IRiskThreat) => {
    setShowEditModal(true)
    setSelectedRiskThreat(riskThreat)
  }

  const openEditModal = () => {
    setShowEditModal(true)
  }

  const closeEditModal = () => {
    setShowEditModal(false)
    setLoading(true)
    reload()
    setLoading(false)
    setSelectedRiskThreat(undefined)
  }

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    setLoading(true)
    reload()
    setLoading(false)
  }

  const columns: IListColumns[] = [
    {
      name: 'name',
      id: 'name',
      size: '200',
      dataField: 'name',
    },
    {
      name: `from_value,quantityUnit,${quantityUnit}`,
      size: '200',
      id: 'from',
      dataField: 'from',
    },
    {
      name: `to_value,quantityUnit,${quantityUnit}`,
      size: '200',
      id: 'to',
      dataField: 'to',
    },
    {
      name: 'order',
      size: '150',
      id: 'riskOrder',
      dataField: 'order',
    },
    {
      name: 'created_at',
      size: '130',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  return (
    <div className={styleClass.root}>
      <List
        data={riskThreats}
        title={t('threats')}
        columns={columns}
        tableName={'riskThreatList'}
        itemsPerPage={0}
        onRowClick={onEditItemClick}
      />
      {selectedRiskThreat ? (
        <Modal
          show={showEditModal}
          closeModal={openEditModal}
          title={t('edit_threat')}
          maxWidth={800}
        >
          <div>
            <RiskThreatForm
              editingMode={true}
              riskThreat={selectedRiskThreat}
              closeModal={closeEditModal}
              quantityUnit={quantityUnit}
            />
          </div>
        </Modal>
      ) : null}

      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_threat')}
        maxWidth={800}
      >
        <div>
          <RiskThreatForm
            editingMode={false}
            riskThreat={{ id: 0, name: '', order: 0, from: '', to: '' }}
            closeModal={closeCreateModal}
            quantityUnit={quantityUnit}
          />
        </div>
      </Modal>

      {loading ? <Loader /> : null}
    </div>
  )
}

export default RiskThreatList
