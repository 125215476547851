import * as React from 'react'
import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

interface IToolTip {
  message: string | JSX.Element
  header?: string
  offset?: number
  classNames?: string
  classNamesHoverBox?: string
  minWidth?: string
  show?: boolean
  direction?: 'top' | 'bottom' | 'right' | 'left'
}

const Tooltip: React.FC<PropsWithChildren<IToolTip>> = ({
  message,
  header,
  offset = 0,
  children,
  classNames = '',
  minWidth,
  show = true,
  direction = 'top',
}) => {
  const getOppositeDirection = () => {
    switch (direction) {
      case 'top':
        return 'bottom'
      case 'bottom':
        return 'top'
      case 'right':
        return 'left'
      case 'left':
        return 'right'
    }
  }
  return (
    <div
      className={twMerge(
        'relative flex flex-col items-center group',
        classNames,
      )}
    >
      {children}
      {show && (
        <div
          className={twMerge(
            'absolute flex-col items-center hidden mb-4 group-hover:flex z-50',
            `min-w-${minWidth}`,
            direction === 'bottom' && 'origin-top',
          )}
          style={{
            [getOppositeDirection()]: `${offset}rem`,
          }}
        >
          {direction === 'bottom' && (
            <div className={`w-3 h-3 -mb-2 rotate-45 bg-grey-selector`} />
          )}
          <span
            className={`relative z-10 p-2 text-xs leading-none shadow-lg bg-grey-selector rounded-sm`}
          >
            {header && <p className="text-white font-bold mb-1">{header}</p>}
            <p className="text-m text-white">{message}</p>
          </span>
          {direction === 'top' && (
            <div className={`w-3 h-3 -mt-2 rotate-45 bg-grey-selector`} />
          )}
        </div>
      )}
    </div>
  )
}

export default Tooltip
