import * as React from 'react'

interface IMaterialIconProps extends React.HTMLProps<HTMLSpanElement> {
  icon: string
  outline?: boolean
}

/**
 *
 * @deprecated Import the icon svg directly (e.g. import Tune from '@icons/tune.svg')
 */
const MaterialIcon = ({
  icon,
  outline = false,
  className,
  ...rest
}: IMaterialIconProps) => {
  const iconKey = React.useMemo(() => 'icon-' + icon, [icon])

  const iconClass =
    `${className}` + (outline ? ' material-icons-outlined' : ' material-icons')
  return (
    <span key={iconKey} {...rest} className={iconClass}>
      {icon}
    </span>
  )
}

export default MaterialIcon
