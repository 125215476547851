import * as React from 'react'
import { twMerge } from 'tailwind-merge'

interface IDropdownArrowIconProps {
  isOpen?: boolean
  bold?: boolean
  onClick?: (e: any) => void
  onlyHalfRotation?: boolean
  className?: string
}

const DropdownArrowIcon: React.FC<IDropdownArrowIconProps> = ({
  isOpen,
  bold,
  onClick,
  onlyHalfRotation,
  className = '',
}) => {
  const strokeWidth = bold ? 2 : 1

  const styleClass = {
    root: twMerge(onClick && 'cursor-pointer', 'p-1', className),
  }

  return (
    <div onClick={onClick} className={styleClass.root}>
      {isOpen ? (
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 5L5 1L1 5"
            strokeWidth={strokeWidth}
            stroke="#6C737F"
            strokeLinecap="round"
          />
        </svg>
      ) : onlyHalfRotation ? (
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9L5 5L1 1"
            stroke="#6C737F"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        </svg>
      ) : (
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5 5L9 1"
            strokeWidth={strokeWidth}
            stroke="#6C737F"
            strokeLinecap="round"
          />
        </svg>
      )}
    </div>
  )
}

export default DropdownArrowIcon
