import { createContext } from 'react'
import { RouterProps } from 'react-router'

export interface ITabProps extends RouterProps {
  location: Location
  defaultIndex: number
  defaultSubIndex?: number
}

export interface ITabContext {
  actions: {
    setCurrentIndex: (index: number) => void
    setCurrentSubIndex: (index: number) => void
  }
  state: ITabContextState
}

export interface ITabContextState {
  tabIndex: number
  subTabIndex: number
  loading: boolean
}

export interface ITabObject {
  mainIndex: number
  subIndex: number
}

export type TabContextType = ITabContext | any

export const TabContext = createContext<TabContextType>({
  state: { tabIndex: 0 },
})
