import moment from 'moment'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import {
  IMatrixData,
  IRisk,
  IRiskArea,
  IRiskAssessmentData,
} from 'src/service/OrgTypes'
import {
  getRiskAreaMatrixData,
  getRiskAssessments,
  getRiskMatrix,
} from 'src/service/RiskService'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import RiskMatrixComponent from './RiskMatrixComponent'

interface IRiskAssesmentPanelContent {
  riskGroupId: number
  riskId: number
}

export const RiskAssesmentPanelContent = ({
  riskGroupId,
  riskId,
}: IRiskAssesmentPanelContent) => {
  const [risk, setRisk] = useState<IRisk>({} as IRisk)
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const [matrixData, setMatrixData] = useState<IMatrixData>({} as IMatrixData)
  const [riskArea, setRiskArea] = useState<IRiskArea | undefined>(undefined)
  const [assessments, setAssessments] = useState<IRiskAssessmentData[]>([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const loadRiskAreaMatrixData = useCallback(
    (value: IRiskAssessmentData[]) => {
      const riskAreaId = getRiskAreaId(value)
      if (riskAreaId) {
        getRiskAreaMatrixData(riskGroupId, riskAreaId)
          .then((res: IRiskArea) => {
            setRiskArea(res)
          })
          .catch((err) => console.error(err))
      }
    },
    [riskGroupId],
  )

  const fetchData = useCallback(async () => {
    setLoading(true)
    const riskMarix = await getRiskMatrix(projectId)
    const riskData = await getRiskAssessments(riskId)
    setMatrixData(riskMarix)
    setRisk(riskData)
    setAssessments(riskData.assessments)
    loadRiskAreaMatrixData(riskData.assessments)
    setLoading(false)
  }, [loadRiskAreaMatrixData, projectId, riskId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const getRiskAreaId = (value: IRiskAssessmentData[]) => {
    return value && value.length > 0 ? value[value.length - 1].risk_area_id : 0
  }

  const threatOrPossibility = useMemo(() => {
    const assessment =
      assessments && assessments.length > 0 ? assessments[0] : null
    if (assessment) {
      if (assessment.risk_threat_id) {
        return 'threat'
      }
      if (assessment.risk_possibility_id) {
        return 'possibility'
      }
    }
    return null
  }, [assessments])

  const setTypeOfAssessment = (assessment: IRiskAssessmentData) => {
    const index = assessments.findIndex((item) => item.id === assessment.id)
    return `P${index + 1}`
  }

  const assessmentLogCols = useMemo(() => {
    return [
      t('assessment'),
      t('probability_title', {
        val: threatOrPossibility ? t(threatOrPossibility) : '',
      }),
      t('assessment_comment'),
      t('created_at'),
      t('created_by'),
    ]
  }, [t, threatOrPossibility])

  return loading ? (
    <div className={'w-full flex items-center justify-center'}>
      <SpinnerMobile className={'scale-50'} />
    </div>
  ) : (
    <div className={'flex flex-col gap-y-4'}>
      {threatOrPossibility === 'threat' && (
        <RiskMatrixComponent
          title={t('threat')}
          matrixType={threatOrPossibility}
          columnsProp={matrixData.columnSize}
          rowsProp={matrixData.rowSize}
          configArray={matrixData.matrixThreatConfigData}
          risk={risk}
        />
      )}
      {threatOrPossibility === 'possibility' && (
        <RiskMatrixComponent
          title={t('possibility')}
          matrixType={threatOrPossibility}
          columnsProp={matrixData.columnSize}
          rowsProp={matrixData.rowSize}
          configArray={matrixData.matrixPossibilityConfigData}
          risk={risk}
        />
      )}

      {false && (
        <div className={'px-2 flex gap-2'}>
          {riskArea?.risk_probabilities && (
            <InspectorSectionTable
              headerColumns={[t('index'), t('probability_values')]}
              rowsData={
                riskArea?.risk_probabilities?.map((riskProb) => {
                  return {
                    cells: [
                      `${riskProb.order}`,
                      `${
                        riskProb.from !== null && riskProb.to !== null
                          ? `${riskProb.name} (${riskProb.from}% - ${riskProb.to}%)`
                          : riskProb.name
                      }`,
                    ],
                  }
                }) ?? []
              }
            />
          )}

          {threatOrPossibility === 'threat' ? (
            <InspectorSectionTable
              headerColumns={[t('index'), t('threat_values')]}
              rowsData={
                riskArea?.risk_threats?.map((riskThreat) => {
                  return {
                    cells: [`${riskThreat.order}`, riskThreat?.name],
                  }
                }) ?? []
              }
            />
          ) : (
            <InspectorSectionTable
              headerColumns={[t('index'), t('possibility_values')]}
              rowsData={
                riskArea?.risk_possibilities?.map((riskPossibility) => {
                  return {
                    cells: [`${riskPossibility.order}`, riskPossibility?.name],
                  }
                }) ?? []
              }
            />
          )}
        </div>
      )}

      <div className={'px-2'}>
        {threatOrPossibility && (
          <InspectorSectionTable
            headerColumns={assessmentLogCols}
            rowsData={
              assessments?.map((assessment) => {
                return {
                  cells: [
                    `${setTypeOfAssessment(assessment)}`,
                    `${assessment.messureIndex ?? '0'}/${
                      assessment.possibilityIndex
                    }`,
                    assessment.description ?? '',
                    assessment ? moment(assessment.created_at).format('L') : '',
                    assessment?.user
                      ? `${assessment.user.firstName} ${assessment.user.lastName}`
                      : '',
                  ],
                }
              }) ?? []
            }
          />
        )}
      </div>
    </div>
  )
}
