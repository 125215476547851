import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { setColorForIcon } from 'src/components/discipline/disciplineUtils'
import MultiSelector from 'src/components/multi-selector/MultiSelector'
import TableStatusLabel from 'src/components/status-dropdown/TableStatusLabel'
import UserIcon from 'src/components/user/UserIcon'
import { IDelivery, IDiscipline, IStatusTypes } from 'src/service/OrgTypes'
import { statusTypes } from 'src/service/SystemValues'
import List from 'src/ui-elements/list/List'
import { filterType, IListColumns } from 'src/ui-elements/list/ListTypes'
import Modal from 'src/ui-elements/modal/Modal'
import {
  concatinateValuesForTable,
  displineComparator,
  userComparator,
} from 'src/utility/Utility'
import {
  addDeliveryDependency,
  getAllProjectDeliveries,
  getDependentDeliveries,
  getDependentOnDeliveries,
  removeDeliveryDependency,
  updateDelivery,
} from '../../../service/DeliveryService'
import Button from '../../../ui-elements/button/Button'
import { classNames } from '../../../utility/utils'
import DeliveryInspectorPanel from './DeliveryInspectorPanel'

export interface IDependencyDeliveryProps {
  projectId: number
  deliveryId: number
  ErrorMessage?: string
  showDependentOn: boolean
}

const DependencyDelivery = ({
  projectId,
  deliveryId,
  ErrorMessage,
  showDependentOn,
}: IDependencyDeliveryProps) => {
  const { t } = useTranslation()
  const [showAddDependencyModal, setShowAddDependencyModal] =
    useState<boolean>(false)
  const [deliveries, setDeliveries] = useState<IDelivery[]>([])
  const [projectDeliveries, setProjectDeliveries] = useState<IDelivery[]>([])
  const [selectedDeliveries, setSelectedDeliveries] = useState<any[]>([])
  const [deliveryLoading, setDeliveryLoading] = useState<boolean>(false)
  const [modalFirstTime, setFirstTime] = useState<boolean>(true)
  const [selectedDependency, setSelectedDependency] = useState<any>(0)
  const [selectedDelivery, setSelectedDelivery] = useState<
    IDelivery | undefined
  >(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>(
    ErrorMessage ? ErrorMessage : '',
  )
  const [reloadTable, setReloadTable] = useState(false)
  const [openInspectorPanel, setOpenInspection] = useState(false)

  const loadDependencies = useCallback(() => {
    if (showDependentOn) {
      getDependentOnDeliveries(projectId, deliveryId).then((res) => {
        setDeliveries(res)
      })
    } else {
      getDependentDeliveries(projectId, deliveryId).then((res) => {
        setDeliveries(res)
      })
    }
  }, [deliveryId, showDependentOn, projectId])

  useEffect(() => {
    setReloadTable((prev) => !prev)
    loadDependencies()
  }, [deliveryId, loadDependencies])

  const closeModal = () => {
    setShowAddDependencyModal(false)
    setErrorMessage('')
    setSelectedDeliveries([])
  }

  useEffect(() => {
    setErrorMessage(ErrorMessage ? ErrorMessage : '')
  }, [ErrorMessage])

  const onSubmitDependency = async (e: any) => {
    e.preventDefault()
    setErrorMessage('')
    const selectedDeliveryIds = selectedDeliveries.concat(
      deliveries.map((k) => k.id),
    )
    await addDeliveryDependency(deliveryId, {
      dependent_on_delivery: showDependentOn ? selectedDeliveryIds : null,
      dependent_delivery: !showDependentOn ? selectedDeliveryIds : null,
    })
    setFirstTime(true)
    setShowAddDependencyModal(false)
    loadDependencies()
  }

  const openModal = async () => {
    if (modalFirstTime) {
      setDeliveryLoading(true)
      setShowAddDependencyModal(true)
      const allDeliveries = await getAllProjectDeliveries(projectId)
      const unselected = allDeliveries.filter(
        (delivery: IDelivery) =>
          !deliveries.find((d) => d.id === delivery.id) &&
          delivery.id !== deliveryId,
      )
      setProjectDeliveries(unselected)
      setDeliveryLoading(false)
      setFirstTime(false)
    }
    setShowAddDependencyModal(true)
  }

  const deleteDelivery = async () => {
    const cpySelected = [...selectedDeliveries]
    cpySelected.splice(cpySelected.indexOf(selectedDependency), 1)
    setSelectedDeliveries(cpySelected)
    await removeDeliveryDependency(deliveryId, {
      dependent_on_delivery: [selectedDependency],
    })
    setSelectedDelivery(undefined)
    setShowDeleteModal(false)
    loadDependencies()
  }

  const onStatusSelect = (status: IStatusTypes, key: number, cell: any) => {
    const delivery = cell
    if (delivery) {
      delivery.status = status.id
      updateDelivery(delivery, key).then(() => {
        loadDependencies()
      })
    }
  }

  const onCloseInpectionPanel = () => {
    setOpenInspection(false)
    loadDependencies()
  }

  const columns: IListColumns[] = [
    {
      name: 'id',
      size: '110',
      id: 'recordId',
      sortingField: 'record_id',
      dataField: 'record_id',
    },
    {
      name: 'title',
      size: '300',
      id: 'name',
      sortingField: 'name',
      dataField: 'name',
    },
    {
      name: 'status',
      size: '150',
      id: 'status',
      sortingField: 'status',
      dataField:
        'status||endTime||update_access||expired_children||open_children||behind_schedule_children',
      cell: (cell: any, key: number) => (
        <TableStatusLabel
          statusTypes={statusTypes(t)}
          cell={cell}
          rowId={key}
          onStatusSelect={onStatusSelect}
        />
      ),
    },
    {
      name: 'deadline',
      size: '150',
      id: 'endTime',
      sortingField: 'endTime',
      dataField: 'endTime',
      cell: (endTime: any) => (
        <span>{endTime ? moment(endTime).format('L') : ''}</span>
      ),
    },
    {
      name: 'baseline_date',
      size: '150',
      id: 'baseline',
      sortingField: 'baseline',
      dataField: 'baseline',
      filterType: filterType.RANGEDATE,
      cell: (baseline: any) => (
        <span>{baseline ? moment(baseline).format('L') : ''}</span>
      ),
    },
    {
      name: 'tasks',
      size: '150',
      id: 'task',
      dataField: 'done_children||open_children||expired_children',
      cell: (delivery: IDelivery) => (
        <span
          className={classNames(
            delivery?.expired_children && delivery?.expired_children > 0
              ? 'text-red-two'
              : '',
          )}
        >
          {delivery.done_children}/
          {(delivery?.open_children ?? 0) + (delivery?.done_children ?? 0)}
        </span>
      ),
    },
    {
      name: 'responsible',
      size: '200',
      id: 'responsible',
      dataField: 'responsible',
      sortingField: 'responsible',
      filterType: filterType.DEFAULT,
      filterDataField: 'responsible.firstName||responsible.lastName',
      filterDataValue: 'responsible.id',
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? userComparator(a, b, direction) : 0) : 0,
      cell: (responsible: any) =>
        responsible ? (
          <UserIcon
            userId={responsible.id}
            firstName={responsible.firstName}
            lastName={responsible.lastName}
            image_url={responsible.image_url}
          />
        ) : (
          <span />
        ),
    },
    {
      name: 'discipline',
      size: '200',
      sortingField: 'discipline',
      id: 'disiplin',
      dataField: 'discipline',
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? displineComparator(a, b, direction) : 0) : 0,
      cell: (discipline: IDiscipline) => {
        return discipline ? (
          <span className={'flex flex-row'}>
            <span className={'mr-4'}>
              {setColorForIcon({
                color: discipline.color,
                shape: discipline.shape,
              })}
            </span>
            {concatinateValuesForTable(discipline.shortName, discipline.name)}
          </span>
        ) : (
          <span />
        )
      },
    },
    {
      name: 'created_at',
      size: '150',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '150',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const onRemoveDependencyDelivery = (delivery: IDelivery) => {
    setSelectedDependency(delivery.id)
    setSelectedDelivery(delivery)
    setShowDeleteModal(true)
  }

  const handlePreviewClick = (e: any, data: IDelivery) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenInspection(true)
    if (data) {
      setSelectedDelivery(data)
    }
  }

  return (
    <div className={'pl-4'}>
      <List
        actionButton={
          <Button
            type={Button.ButtonType.PRIMARY}
            size={Button.ButtonSize.SMALL}
            onClick={openModal}
          >
            {t('new_dependency')}
          </Button>
        }
        data={deliveries}
        columns={columns}
        tableName={showDependentOn ? 'DeliveryDependsOn' : 'DeliveryDependents'}
        itemsPerPage={0}
        onPreviewClick={handlePreviewClick}
        actionMenu={[
          {
            name: t('delete'),
            action: onRemoveDependencyDelivery,
          },
        ]}
        reload={reloadTable}
      />

      {showAddDependencyModal && (
        <Modal
          show={showAddDependencyModal}
          title={t('select_delivery_you_want_to_add')}
          closeModal={closeModal}
          size={'w-1/3'}
          maxWidth={600}
        >
          <div
            className={'text-left flex flex-col m-2 pr-4'}
            style={{ minHeight: '100px' }}
          >
            <MultiSelector
              items={projectDeliveries}
              label={t('delivery')}
              scroll={false}
              dataFields={['record_id', 'name']}
              selectedItems={selectedDeliveries}
              errorMessage={errorMessage}
              fontWeight={'bold'}
              onSelect={(ids: any[]) => setSelectedDeliveries(ids)}
              noBorder={true}
              loading={deliveryLoading}
              disableSelectAll={true}
            />
            <div className={'mt-2'}>
              <Button
                type={Button.ButtonType.PRIMARY}
                onClick={onSubmitDependency}
              >
                {t('save')}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {selectedDelivery && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={() => setShowDeleteModal(false)}
          onDelete={deleteDelivery}
          itemIdnType={`${t('dependency_to')} ${
            selectedDelivery.record_id
          } (${t('delivery')})`}
          itemName={`${t('dependency_to')} ${selectedDelivery.record_id} - ${
            selectedDelivery.name
          }`}
        />
      )}

      {openInspectorPanel && selectedDelivery && (
        <DeliveryInspectorPanel
          deliveryId={selectedDelivery?.id ?? 0}
          open={openInspectorPanel}
          onClose={onCloseInpectionPanel}
          projectId={projectId}
          onUpdate={loadDependencies}
        />
      )}
    </div>
  )
}

export default DependencyDelivery
