import moment from 'moment'

export const columns = [
  {
    name: 'name',
    size: '200',
    id: 'name',
    dataField: 'name',
  },
  {
    name: 'created_at',
    size: '130',
    id: 'createdAt',
    dataField: 'created_at',
    cell: (created: any) => (
      <span>{created ? moment(created).format('L') : ''}</span>
    ),
  },
  {
    name: 'updated_at',
    size: '130',
    id: 'updatedAt',
    dataField: 'updated_at',
    cell: (updated: any) => (
      <span>{updated ? moment(updated).format('L') : ''}</span>
    ),
  },
]
