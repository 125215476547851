import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ITestExecution } from 'src/service/OrgTypes'
import ListActionButton from 'src/ui-elements/button/ListActionButton'
import { capFirstLetter } from 'src/utility/utils'

interface ApproveTestExecutionProps {
  id: number
  isTestOwner: boolean
  step: string
  editExecution: (id: number, data: Partial<ITestExecution>) => void
  className?: string
}

const CloseTestExecution: FC<ApproveTestExecutionProps> = ({
  isTestOwner,
  step,
  editExecution,
  id,
  className = '',
}) => {
  const { t } = useTranslation()

  return (
    <ListActionButton
      className={className}
      onClick={() => {
        editExecution(id, { step: 'closed' })
      }}
      disable={!isTestOwner || step !== 'singing'}
      icon={'check_circle'}
    >
      {capFirstLetter(t('close'))}
    </ListActionButton>
  )
}

export default CloseTestExecution
