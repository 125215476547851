import * as React from 'react'
import { BASE_METADATA_TYPES } from '../../../types/FolderMetadataTypes'
import BoolIcon from '../icons/BoolIcon'
import ClockIcon from '../icons/ClockIcon'
import ListIcon from '../icons/ListIcon'
import MultiSelectIcon from '../icons/MultiSelectIcon'
import TextIcon from '../icons/TextIcon'

interface IFolderMetadataIconWrapper {
  icon?: JSX.Element
  type?: BASE_METADATA_TYPES
}

const FolderMetadataIconWrapper: React.FC<IFolderMetadataIconWrapper> = ({
  icon,
  type,
}): JSX.Element | null => {
  if (icon) return icon
  switch (type) {
    case BASE_METADATA_TYPES.time:
      return <ClockIcon />
    case BASE_METADATA_TYPES.string:
      return <TextIcon />
    case BASE_METADATA_TYPES.boolean:
      return <BoolIcon />
    case BASE_METADATA_TYPES.other:
      return <TextIcon />
    case BASE_METADATA_TYPES.enum:
      return <ListIcon />
    case BASE_METADATA_TYPES.multiEnum:
      return <MultiSelectIcon />
    default:
      console.error('Missing icon and/or type in FolderMetadataDisplayField')
      return null
  }
}

export default FolderMetadataIconWrapper
