import ContactSupport from '@icons/contact_support.svg'
import { Fragment, useContext, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import RadioItems from 'src/ui-elements/radio/RadioItems'
import Textarea from 'src/ui-elements/textarea/Textarea'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { IRole } from '../../service/OrgTypes'
import { createRole, editRole } from '../../service/RoleService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import { capFirstLetter, classNames } from '../../utility/utils'

interface IRoleFormProps extends WithTranslation {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  role: IRole
}

const RoleForm = (props: IRoleFormProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col', 'overflow-visible'),
    inputGroup: classNames('w-full', 'flex', 'row', 'my-1'),
  }

  const [loading, setLoading] = useState(false)
  const [roleName, setRoleName] = useState(props.role.roleName)
  const [description, setDescription] = useState(props.role.description)
  const [permissions, setPermissions] = useState(props.role.role_permissions)
  const [roleNameErrorMessage, setRoleNameErrorMessage] = useState('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')

  const id = props.role.id

  const onSubmit = (e: any) => {
    e.preventDefault()
    let error = false
    const { t } = props
    setRoleNameErrorMessage('')
    setDescription('')
    setLoading(true)

    if (!roleName) {
      setRoleNameErrorMessage(t('fill_in_the_name'))
      error = true
    }

    if (!description) {
      setDescription(t('fill_in_the_description'))
      error = true
    }

    if (!error) {
      if (props.editingMode) {
        editRole({
          id: id,
          roleName: roleName,
          description: description,
          role_permissions: permissions,
        }).then(() => {
          props.closeModal()
        })
      } else {
        createRole(
          {
            id: 0,
            roleName: roleName,
            description: description,
            role_permissions: permissions,
          },
          props.projectId,
        ).then(() => {
          setRoleName('')
          setDescription('')
          props.closeModal()
        })
      }
    }

    setLoading(false)
  }

  const onRoleNameChange = (e: any) => {
    const roleNameVal = e.target.value
    setRoleName(roleNameVal)
    setRoleNameErrorMessage('')
  }

  const onRoleDescriptionChange = (e: any) => {
    const val = e.target.value

    setDescription(val)
    setDescriptionErrorMessage('')
  }

  const { t } = props
  const projectContext = useContext(ProjectContext)
  const {
    document_control_module,
    systems_module,
    systematic_completion_module,
  } = projectContext.state.currentProject

  const MODULES = [
    {
      name: t('document'),
      value: 'document',
      tooltip: {
        admin: t('document_module_admin_permission'),
        contract: t('document_module_contract_permission'),
        discipline: t('document_module_discipline_permission'),
        standard: t('document_module_standard_permission'),
        none: t('document_module_no_permission'),
      },
      enabled: document_control_module,
    },
    {
      name: t('system'),
      value: 'object',
      tooltip: {
        admin: t('object_module_admin_permission'),
        contract: t('object_module_contract_permission'),
        discipline: t('object_module_discipline_permission'),
        standard: t('object_module_standard_permission'),
        none: t('object_module_no_permission'),
      },
      enabled: systems_module,
    },
    {
      name: t('systematic_completion'),
      value: 'systematic_completion',
      tooltip: {
        admin: t('systematic_completion_module_admin_permission'),
        contract: t('systematic_completion_module_contract_permission'),
        discipline: t('systematic_completion_module_discipline_permission'),
        standard: t('systematic_completion_module_standard_permission'),
        none: t('systematic_completion_module_no_permission'),
      },
      enabled: systematic_completion_module,
    },
  ]
  const anyEnabledModule = MODULES.some((module) => module.enabled)

  return (
    <form className={styleClass.root} onSubmit={onSubmit}>
      <div className={styleClass.inputGroup}>
        <Input
          label={t('role_name')}
          block={true}
          value={roleName}
          errorMessage={roleNameErrorMessage}
          onChange={onRoleNameChange}
          autoFocus={true}
          required={true}
        />
      </div>
      <div className={styleClass.inputGroup}>
        <Textarea
          isValid={false}
          label={t('description')}
          block={true}
          value={description}
          errorMessage={descriptionErrorMessage}
          required={true}
          onChange={onRoleDescriptionChange}
        />
      </div>
      {anyEnabledModule && (
        <div className="flex">
          <div className="flex flex-col px-2">
            <span className="first-capitalize mt-2 justify-start text-sm font-medium">
              {t('module')}
            </span>
            {MODULES.filter((module) => module.enabled).map((module) => (
              <label key={module.value} className="flex m-auto">
                <span className="first-capitalize">{module.name}</span>
              </label>
            ))}
          </div>

          <div className="flex flex-col px-2 items-start">
            <span className="flex items-center">
              <span className="ml-4 first-capitalize mt-2 text-sm font-medium">
                {t('permissions')}
              </span>
              <Tooltip message={t('hover_over_role_name')}>
                <ContactSupport className="fill-blue-500 w-5 h-5" />
              </Tooltip>
            </span>
            {MODULES.filter((module) => module.enabled).map((module) => (
              <Fragment key={module.value}>
                <RadioItems
                  className="m-auto mb-0"
                  value={
                    permissions.find(
                      (permission) => permission.module === module.value,
                    )?.permission ?? 'read'
                  }
                  onRadioClick={(e, id) => {
                    setPermissions([
                      ...permissions.filter(
                        (permission) => permission.module !== module.value,
                      ),
                      {
                        module: module.value,
                        permission: id?.toString() ?? 'read',
                      },
                    ])
                  }}
                  items={[
                    {
                      name: (
                        <Tooltip
                          classNames="m-auto"
                          message={module.tooltip.admin}
                          minWidth="128"
                        >
                          <p className="first-capitalize">{t('admin')}</p>
                        </Tooltip>
                      ),
                      id: 'admin',
                    },
                    {
                      name: (
                        <Tooltip
                          classNames="m-auto"
                          message={module.tooltip.contract}
                          minWidth="128"
                        >
                          <p className="first-capitalize">{t('contract')}</p>
                        </Tooltip>
                      ),
                      id: 'contract',
                    },
                    {
                      name: (
                        <Tooltip
                          classNames="m-auto"
                          message={module.tooltip.discipline}
                          minWidth="128"
                        >
                          <p className="first-capitalize">{t('discipline')}</p>
                        </Tooltip>
                      ),
                      id: 'discipline',
                    },
                    {
                      name: (
                        <Tooltip
                          classNames="m-auto"
                          message={module.tooltip.standard}
                          minWidth="128"
                        >
                          <p className="first-capitalize">{t('standard')}</p>
                        </Tooltip>
                      ),
                      id: 'read',
                    },
                    {
                      name: (
                        <Tooltip
                          classNames="m-auto"
                          message={module.tooltip.none}
                          minWidth="128"
                        >
                          <p className="first-capitalize">{t('none')}</p>
                        </Tooltip>
                      ),
                      id: 'none',
                    },
                  ]}
                />
              </Fragment>
            ))}
          </div>
        </div>
      )}

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={props.closeModal}>
          {capFirstLetter(t('cancel'))}
        </Button>
        {props.editingMode ? (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : capFirstLetter(t('update'))}
          </Button>
        ) : (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : capFirstLetter(t('add'))}
          </Button>
        )}
      </ModalFooter>
    </form>
  )
}

export default withTranslation()(RoleForm)
