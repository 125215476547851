import { ReactNode } from 'react'
import { classNames } from 'src/utility/utils'

const styleClass = {
  root: (isFullWidth: boolean, _noMargin: boolean) =>
    classNames(isFullWidth ? 'w-full' : '', 'bg-white', 'inline-block'),
}

interface IProps {
  children: ReactNode
  className?: string
  fullWidth?: boolean
  noMargin?: boolean
}

const InformationComponent = ({
  children,
  fullWidth = false,
  noMargin = false,
  className,
}: IProps) => {
  return (
    <div
      className={classNames(styleClass.root(fullWidth, noMargin), className)}
    >
      {children}
    </div>
  )
}

export default InformationComponent
