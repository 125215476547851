import { useState, PropsWithChildren } from 'react'
import DropdownMenu, {
  IDropDownItem,
} from 'src/document/components/DocumentButtonWithDropdown/DropdownMenu'
import IconButton, { IconButtonProps } from './IconButton'

interface IconButtonWithDropdownMenuProps
  extends Omit<IconButtonProps, 'onClick'> {
  items: IDropDownItem[]
  showOnLeft?: boolean
}

const IconButtonWithDropdownMenu = (
  props: PropsWithChildren<IconButtonWithDropdownMenuProps>,
) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <div>
      <IconButton onClick={() => setMenuIsOpen(true)} {...props} />
      <DropdownMenu
        isOpen={menuIsOpen}
        onClose={() => setMenuIsOpen(false)}
        items={props.items}
        position={props.showOnLeft ? 'left' : 'right'}
      />
    </div>
  )
}

export default IconButtonWithDropdownMenu
