import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import {
  editTest,
  getTest,
  getTestParticipants,
  getTestRelatedTestCases,
} from 'src/service/TestService'
import {
  getDisplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import { getErrorMessage, ValidationError } from 'src/service/ValidationErrors'
import { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { capFirstLetter, modalUrlBuilder } from 'src/utility/utils'
import TaskInspectorPanel from '../../../components/task/TaskInspectorPanel'

import {
  ITaskData,
  ITest,
  ITestCase,
  ITestParticipant,
} from '../../../service/OrgTypes'
import { testStatus } from '../../../service/SystemValues'
import { getFilterTasks } from '../../../service/TaskService'
import FixedPane from '../../../ui-elements/fixed-pane/FixedPane'

interface ITestInspectorPanel {
  testId: number
  open: boolean
  onClose: () => void
  onUpdate: () => void
  disabled?: boolean
  defaultIndex?: number
}

const TestInspectorPanel: React.FC<ITestInspectorPanel> = ({
  testId,
  open,
  onClose,
  onUpdate,
  disabled,
  defaultIndex = 0,
}) => {
  const { t } = useTranslation()
  const [test, setTest] = useState<ITest>()
  const [testCases, setTestCases] = useState<ITestCase[]>([])
  const [testParticipants, setTestParticipants] = useState<ITestParticipant[]>(
    [],
  )
  const [issues, setIssues] = useState<ITaskData[]>()
  const [selectedIssueId, setSelectedIssueId] = useState<number | undefined>(
    undefined,
  )
  const [showIssuePanel, setShowIssuePanel] = useState(false)
  const [dependentTests, setDependentTest] = useState<ITest[]>([])
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const history = useHistory()
  const modalContext = useContext(ModalContext)

  useEffect(() => {
    loadTest()
    loadTestParticipants()
    loadTestCases()
  }, [testId])

  const loadTest = () => {
    getTest(+testId).then((res) => {
      setTest(res)
      setDependentTest(res.dependent_on_tests)
    })
  }

  const loadTestParticipants = () => {
    getTestParticipants(testId).then((resTest) => {
      if (resTest) {
        setTestParticipants(resTest)
      }
    })
  }

  const loadTestCases = () => {
    getTestRelatedTestCases(testId, 1, {}, 1000).then((res) => {
      setTestCases(res.test_cases)
    })
  }

  const loadIssues = useCallback(() => {
    const activeFilters = {
      test: [testId],
    }

    getFilterTasks(projectId, activeFilters, 1, 1000).then((res) => {
      setIssues(res.tasks)
    })
  }, [testId, projectId])

  const onIssueClick = (id: number) => {
    setSelectedIssueId(id)
    setShowIssuePanel(true)
  }

  const onChangeInput = async (update: Partial<ITest>) => {
    if (test?.id) {
      editTest({
        ...update,
        id: test.id,
      }).then(() => {
        loadTest()
        onUpdate()
      })
    }
  }
  const disablePlannedDate = (): boolean => {
    return disabled || test?.status !== 'set_up'
  }

  const TestMainContent = (test: ITest): JSX.Element => {
    return (
      <InlineComponentsWrapper
        inputWidth="w-[480px]"
        padding="left"
        border={undefined}
      >
        <TextInlineInputCompontent
          label={t('name')}
          value={test?.name}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ name: newValue })
          }}
          validate={(value) => {
            if (value === undefined || value === '')
              return getErrorMessage(ValidationError.MISSING_TITLE, t)
            return
          }}
          disabled={disabled}
        />
        <SelectorInlineInputComponent
          items={testStatus(t)}
          label={t('status')}
          getItemLabel={(stat) => stat?.name}
          initialItem={{
            id: test?.status ?? '',
            name:
              testStatus(t).find((statData) => statData.id === test?.status)
                ?.name ?? '',
          }}
          selectedId={test?.status}
          onValueSubmitted={(stat) => {
            onChangeInput({ status: stat })
          }}
          disabled={true}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="planned_date"
          selectedTime={`${test?.execution_date}`}
          onValueSubmitted={(endTime) =>
            onChangeInput({ execution_date: moment(endTime) })
          }
          validate={(value) => {
            if (value === undefined)
              return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
            return
          }}
          disabled={disablePlannedDate()}
          inspectorPanel={true}
        />
        <TextInlineInputCompontent
          label={'description'}
          value={test?.description}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ description: newValue })
          }}
          textArea={true}
          disabled={disabled}
        />
        <SelectorInlineInputComponent
          label={'contract'}
          disabled={true}
          selectedId={test?.contract_id ?? ''}
          getItemLabel={(contract) =>
            `${contract?.contractNumber} - ${contract?.contractName}`
          }
          initialItem={test?.contract}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          getItems={() => getProjectDisciplines(projectId)}
          label="discipline"
          initialItem={test?.discipline}
          getItemLabel={(discipline) =>
            `${discipline?.shortName} - ${discipline?.name}`
          }
          validate={(value) => {
            if (value === undefined)
              return t('fill_out_w_param', {
                param: t('discipline'),
              })
            return
          }}
          selectedId={test?.discipline_id ?? 0}
          onValueSubmitted={(discipline_id) => {
            onChangeInput({ discipline_id, responsible_id: null })
          }}
          disabled={disabled}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          getItems={() =>
            test?.discipline_id
              ? getDisplineUsers(test?.discipline_id)
              : getProjectUsersWithDisciplines(projectId)
          }
          label="responsible"
          getItemLabel={(responsible) =>
            `${responsible?.firstName} ${responsible?.lastName}`
          }
          initialItem={test?.responsible}
          validate={(value) => {
            if (value === undefined)
              return t('fill_out_w_param', {
                param: t('responsible'),
              })
            return
          }}
          selectedId={test?.responsible_id ?? 0}
          onValueSubmitted={(responsible_id) => {
            onChangeInput({ responsible_id })
          }}
          dependencies={[test?.discipline_id]}
          disabled={disabled}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          items={test?.test_type ? [test?.test_type] : []}
          label={t('test_type')}
          initialItem={test?.test_type}
          getItemLabel={(test_type) => test_type?.name}
          selectedId={test?.test_type_id}
          disabled={true}
          inspectorPanel={true}
        />

        <TextInlineInputCompontent
          label={'location'}
          value={test?.location}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ location: newValue })
          }}
          validate={(value) => {
            if (value === undefined || value === '')
              return t('fill_in_meeting_place')
            return
          }}
          disabled={disabled}
        />

        <DateTimeInlineInputComponent
          label="created_at"
          selectedTime={test?.created_at}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="updated_at"
          selectedTime={test?.updated_at}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
      </InlineComponentsWrapper>
    )
  }

  const sections: IInspectorSection[] = [
    {
      name: capFirstLetter(t('test')),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: test ? TestMainContent(test) : <span />,
      onClick: loadTest,
    },
    {
      name: t('test_procedures'),
      icon: 'spellcheck',
      activeIcon: 'spellcheck',
      onClick: loadTestCases,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('title'),
            t('expected_result'),
            t('status'),
            t('done'),
          ]}
          rowsData={testCases?.map((testCase) => {
            return {
              cells: [
                testCase?.name ?? '',
                testCase?.result ?? '',
                `${t(
                  testCase?.last_test_case_execution?.status ?? 'not_checked',
                )}`,
                testCase?.last_test_case_execution?.executed_date
                  ? moment(
                      testCase?.last_test_case_execution?.executed_date,
                    ).format('L')
                  : '',
              ],
            }
          })}
        />
      ),
      isMaterialIcon: true,
    },
    {
      name: capFirstLetter(t('test_participant')),
      icon: 'group',
      activeIcon: 'group',
      isMaterialIcon: true,
      onClick: loadTestParticipants,
      content: (
        <InspectorSectionTable
          headerColumns={[t('name'), t('present')]}
          rowsData={testParticipants?.map((resp) => {
            return {
              cells: [
                `${resp?.firstName + ' ' + resp?.lastName}` ?? '',
                resp?.is_present ? t('yes') : t('no'),
              ],
              id: resp.user_id,
            }
          })}
          handleClick={(id) => {
            const modalURL = modalUrlBuilder(projectId, 'user', id)
            history.push(modalURL)
            modalContext.actions.checkURL()
          }}
        />
      ),
    },
    {
      name: capFirstLetter(t('depending_on_tests')),
      icon: Icons.DEPENDENCY_GRAY,
      activeIcon: Icons.DEPENDENCY,
      onClick: loadTest,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('name'),
            t('status'),
            t('deadline'),
            t('responsible'),
          ]}
          rowsData={dependentTests?.map((resp) => {
            return {
              cells: [
                resp?.record_id ?? '',
                resp?.name ?? '',
                t(resp?.status ?? ''),
                resp?.execution_date
                  ? moment(resp?.execution_date).format('L')
                  : '',
                resp?.responsible
                  ? `${resp?.responsible?.firstName} ${resp?.responsible?.lastName}`
                  : '',
              ],
              id: resp.id,
            }
          })}
          handleClick={(id) => {
            const modalURL = modalUrlBuilder(projectId, 'test', id)
            history.push(modalURL)
            modalContext.actions.checkURL()
          }}
        />
      ),
    },
    {
      name: capFirstLetter(t('cases')),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      onClick: loadIssues,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('title'),
            t('status'),
            t('deadline'),
            t('responsible'),
          ]}
          rowsData={
            issues
              ? issues.map((issue) => {
                  return {
                    cells: [
                      issue?.record_id,
                      issue?.title,
                      t(issue.status),
                      issue?.deadline ? moment(issue.deadline).format('L') : '',
                      issue?.responsible
                        ? `${issue?.responsible?.firstName} ${issue?.responsible?.lastName}`
                        : '',
                    ],
                    id: issue.id,
                  }
                })
              : []
          }
          handleClick={onIssueClick}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={testId} parentType="Test" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={testId} parentType="Test" />,
    },
  ]

  return (
    <>
      <FixedPane
        title={
          test?.name
            ? `${test?.record_id + ' - ' + test?.name}`
            : t('loading...')
        }
        show={open}
        onClose={() => onClose()}
        className={'w-[700px]'}
        disableOutsideClose
        detailPageData={{
          key: DetailPageKeys.TEST,
          ids: { testTypeId: test?.test_type_id ?? 0, testId: test?.id ?? 0 },
        }}
      >
        <div className={'-mt-2'}>
          <InspectorSections
            defaultIndex={defaultIndex ?? 0}
            sections={sections}
          />
        </div>
      </FixedPane>
      {selectedIssueId && showIssuePanel && (
        <TaskInspectorPanel
          taskId={selectedIssueId}
          open={showIssuePanel}
          onClose={() => {
            setSelectedIssueId(undefined)
            setShowIssuePanel(false)
          }}
          projectId={projectId}
          onUpdate={() => loadTest()}
        />
      )}
    </>
  )
}
export default TestInspectorPanel
