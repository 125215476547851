import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getImages } from 'src/service/ImageService'
import {
  getAllProjectTestTypes,
  getCandidateTestExecutionParticipants,
  getProjectTestDocumentTypes,
  getTestExecutionParticipants,
} from 'src/service/TestService'

export const useProjectTestTypes = (options?: { enabled: boolean }) => {
  const projectId = useProjectId()
  return useQuery({
    ...options,
    queryKey: ['testTypes', projectId],
    queryFn: () => getAllProjectTestTypes(projectId),
  })
}

export const useTestExecutionParticipants = (testExecutionId: number) =>
  useQuery({
    queryKey: ['testExecutionParticipants', testExecutionId],
    queryFn: () => getTestExecutionParticipants(testExecutionId),
  })

export const useCandidateTestExecutionParticipants = (
  testExecutionId: number,
) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [
      'candidateTestExecutionParticipants',
      projectId,
      testExecutionId,
    ],
    queryFn: () =>
      getCandidateTestExecutionParticipants(projectId, testExecutionId),
  })
}

export const useTestAttachments = (testId?: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['testAttachments', projectId, testId],
    queryFn: () => getImages('Test', testId ?? -1, projectId),
    enabled: !!testId,
  })
}

export const useTestDocumentTypes = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['testDocumentTypes', projectId],
    queryFn: () => getProjectTestDocumentTypes(projectId),
  })
}
