import { noop } from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import ConstructionModalToolbar from 'src/components/construction/toolbars/ConstructionModalToolbar'
import QrModal from 'src/components/qr/QrModal'
import TestListForControlArea from 'src/page/systematic-completion/tests/TestListForControlArea'
import { cleanStatusTypes, statusTypes } from 'src/service/SystemValues'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { ProjectContext } from '../../../context/ProjectContextProvider/ProjectContext'
import {
  editControlArea,
  getControlArea,
  getProjectControlAreaGroups,
} from '../../../service/ControlAreaService'
import {
  IConstructionCanvasControlArea,
  IControlArea,
} from '../../../service/OrgTypes'
import FixedHorizintalPane from '../../../ui-elements/fixed-pane/FixedHorizontalPane'
import DateTimeInlineInputComponent from '../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from '../../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from '../../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import { capFirstLetter, classNames } from '../../../utility/utils'
import ConstructionIssuesList from '../construction-issues/ConstructionIssuesList'
import TrainList from '../train/TrainList'
import ControlAreaRoomList from './ControlAreaRoomList'

interface IControlAreaModal {
  open: boolean
  onClose: () => void
  onUpdate: () => void
  controlAreaId: number
  notFound?: (id: number) => void
  defaultIndex?: number
  className?: string
}

const styleClass = {
  tab: (selected: boolean) =>
    classNames(
      selected ? 'bg-blue-root-tab-wash' : 'bg-white',
      'hover:bg-blue-root-tab-wash',
      selected ? 'text-blue-root' : 'text-gray-700 hover:text-blue-root',
      'inline-block',
      'relative',
      'text-sm',
      'font-normal',
      'px-3',
      'py-1',
      'list-none',
      'cursor-pointer',
      'rounded',
    ),
}

const ControlAreaModal = ({
  open,
  onClose,
  onUpdate,
  controlAreaId,
  notFound,
  defaultIndex = 0,
  className,
}: IControlAreaModal) => {
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id

  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState<number>(defaultIndex ?? 0)
  const [qrString, setQrString] = useState<string>('')
  const [showQrModal, setQrModal] = useState<boolean>(false)
  const [controlArea, setControlArea] =
    useState<IConstructionCanvasControlArea>()
  const { isConstructionManager } = projectContext.state

  useEffect(() => {
    loadControlArea()
  }, [controlAreaId])

  const loadControlArea = () => {
    getControlArea(controlAreaId).then(
      (res: IConstructionCanvasControlArea) => {
        setControlArea(res)
        if (notFound && !res.id) {
          notFound(controlAreaId)
          return
        }
      },
    )
  }

  const onTabChange = (tab: number) => {
    setTabIndex(tab)
  }

  const generateQrCode = () => {
    const url = window.location.href.split('?')[0]
    const copyURL =
      url + '?modal=controlarea&id=' + controlAreaId + '&project=' + projectId
    setQrString(copyURL)
  }

  const toggleQrModal = () => {
    generateQrCode()
    setQrModal((n) => !n)
  }

  const onChangeInput = async (update: Partial<IControlArea>) => {
    if (controlArea) {
      editControlArea({
        id: controlArea.id,
        ...update,
      }).then(() => {
        loadControlArea()
        onUpdate()
      })
    }
  }

  return (
    <FixedHorizintalPane
      title={
        controlArea
          ? t('control_area_title', {
              title: controlArea?.record_id + ' - ' + controlArea?.title,
            })
          : ''
      }
      closeClickOutside={false}
      show={open}
      onClose={onClose}
      minHeight={'min-h-[350px]'}
      className={className || 'h-[350px]'}
      detailPageData={{
        key: DetailPageKeys.CONTROL_AREA,
        ids: {
          cagId: controlArea?.control_area_group_id,
          caId: controlAreaId,
        },
      }}
    >
      <div className="controlareamodal">
        <Tabs
          defaultIndex={tabIndex}
          selectedIndex={tabIndex}
          onSelect={(index: number) => onTabChange(index)}
          className={'w-full react-tabs border-react-tabs modal-tabs'}
        >
          <div
            style={{
              position: 'sticky',
              top: 0,
              left: 0,
              zIndex: 50,
              paddingRight: 15,
              width: '100%',
            }}
          >
            <div className="w-full bg-white flex border-b border-gray-200 flex-row flex-wrap justify-between pb-2 px-2">
              <TabList className={'flex items-center flex-wrap gap-2'}>
                <Tab className={styleClass.tab(tabIndex === 0)}>
                  {capFirstLetter(t('control_area'))}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 1)}>
                  {capFirstLetter(t('train_list'))}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 2)}>
                  {capFirstLetter(t('room_list'))}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 3)}>
                  {capFirstLetter(t('tests'))}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 4)}>
                  {capFirstLetter(t('cases'))}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 5)}>
                  {capFirstLetter(t('comments'))}
                </Tab>
                <Tab className={styleClass.tab(tabIndex === 6)}>
                  {capFirstLetter(t('change_log'))}
                </Tab>
              </TabList>

              <ConstructionModalToolbar
                toggleQrModal={toggleQrModal}
                params={{
                  modal: 'controlarea',
                  id: controlAreaId,
                }}
              />
            </div>
          </div>
          <TabPanel className={'noTabPanelPadding'}>
            {controlArea && (
              <div className="flex">
                <InlineComponentsWrapper
                  padding="left"
                  border={'right'}
                  inputWidth={'w-64'}
                >
                  <TextInlineInputCompontent
                    label="title"
                    value={controlArea?.title}
                    validate={(newValue) => {
                      if (!newValue?.length) {
                        return t('fill_out_w_param', { param: t('title') })
                      }
                      return undefined
                    }}
                    onValueSubmitted={(newValue) => {
                      if (newValue) onChangeInput({ title: newValue })
                    }}
                    disabled={!isConstructionManager}
                  />
                  <TextInlineInputCompontent
                    label="area"
                    type={'number'}
                    value={`${controlArea?.total_area ?? 0}`}
                    validate={(newValue) => {
                      if (!newValue?.length) {
                        return t('fill_out_w_param', { param: t('area') })
                      }
                      return undefined
                    }}
                    onValueSubmitted={(newValue) => {
                      if (newValue) onChangeInput({ total_area: +newValue })
                    }}
                    disabled={!isConstructionManager}
                  />
                  <SelectorInlineInputComponent
                    getItems={() => getProjectControlAreaGroups(projectId)}
                    label={'control_area_group'}
                    selectedId={controlArea?.control_area_group_id}
                    getItemLabel={(controlAreaGr) =>
                      controlAreaGr
                        ? `${controlAreaGr?.record_id} - ${controlAreaGr?.title}`
                        : ''
                    }
                    initialItem={controlArea?.control_area_group}
                    onValueSubmitted={(newValue) => {
                      if (newValue)
                        onChangeInput({ control_area_group_id: +newValue })
                    }}
                    inspectorPanel={false}
                    disabled={!isConstructionManager}
                  />
                  <SelectorInlineInputComponent
                    items={statusTypes(t)}
                    label={t('status')}
                    getItemLabel={(stat) => stat?.name}
                    initialItem={{
                      id: controlArea?.status ?? '',
                      name:
                        statusTypes(t).find(
                          (statData) => statData.id === controlArea?.status,
                        )?.name ?? '',
                    }}
                    selectedId={controlArea?.status ?? undefined}
                    onValueSubmitted={(status) => {
                      onChangeInput({ status: status })
                    }}
                    inspectorPanel={false}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="planned_start"
                    selectedTime={controlArea?.planned_start ?? undefined}
                    validate={(newValue) => {
                      if (
                        (newValue &&
                          controlArea?.planned_end &&
                          moment(newValue).isAfter(controlArea.planned_end),
                        'day')
                      )
                        return t('can_not_be_after_w_date', {
                          date: moment(controlArea?.planned_end).format('L'),
                        })

                      return undefined
                    }}
                    onValueSubmitted={(planned_start) =>
                      onChangeInput({ planned_start: planned_start ?? null })
                    }
                    inspectorPanel={false}
                    id={'cam_planned_start'}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="planned_end"
                    selectedTime={controlArea?.planned_end ?? undefined}
                    validate={(newValue) => {
                      if (
                        (newValue &&
                          controlArea?.planned_start &&
                          moment(newValue).isBefore(controlArea.planned_start),
                        'day')
                      )
                        return t('can_not_be_before', {
                          date: moment(controlArea?.planned_start).format('L'),
                        })

                      return undefined
                    }}
                    onValueSubmitted={(planned_end) =>
                      onChangeInput({ planned_end: planned_end ?? null })
                    }
                    inspectorPanel={false}
                    id={'cam_planned_end'}
                    disabled={!isConstructionManager}
                  />

                  <TextInlineInputCompontent
                    label="total_working_hours"
                    type={'number'}
                    value={`${controlArea?.total_area ?? 0}`}
                    onValueSubmitted={(newValue) => {
                      if (newValue)
                        onChangeInput({ total_working_hours: +newValue })
                    }}
                    disabled={!isConstructionManager}
                  />
                  <TextInlineInputCompontent
                    label="progress"
                    type={'number'}
                    disabled={true}
                    value={`${controlArea?.progress ?? 0}`}
                    onValueSubmitted={noop}
                  />
                </InlineComponentsWrapper>
                <InlineComponentsWrapper
                  inputWidth={'w-64'}
                  padding="left"
                  border={'right'}
                >
                  <SelectorInlineInputComponent
                    items={cleanStatusTypes(t)}
                    label={t('rtb_status')}
                    getItemLabel={(stat) => stat?.name}
                    initialItem={{
                      id: controlArea?.rtb_status ?? '',
                      name:
                        cleanStatusTypes(t).find(
                          (statData) => statData.id === controlArea?.rtb_status,
                        )?.name ?? '',
                    }}
                    selectedId={controlArea?.rtb_status ?? undefined}
                    onValueSubmitted={(status) => {
                      onChangeInput({ rtb_status: status })
                    }}
                    inspectorPanel={false}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="planned_yellow_rtb"
                    selectedTime={controlArea?.planned_yellow_rtb ?? undefined}
                    validate={(newValue) => {
                      if (
                        newValue &&
                        controlArea?.rtb_status !== 'yellow_clean_zone'
                      )
                        return t('rtb_selected_is_not_correct')

                      return undefined
                    }}
                    onValueSubmitted={(planned_yellow_rtb) =>
                      onChangeInput({
                        planned_yellow_rtb: planned_yellow_rtb ?? null,
                      })
                    }
                    inspectorPanel={false}
                    id={'cam_planned_yellow_rtb'}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="confirmed_yellow_rtb"
                    validate={(newValue) => {
                      if (
                        newValue &&
                        controlArea?.rtb_status !== 'yellow_clean_zone'
                      )
                        return t('rtb_selected_is_not_correct')

                      return undefined
                    }}
                    selectedTime={
                      controlArea?.confirmed_yellow_rtb ?? undefined
                    }
                    onValueSubmitted={(confirmed_yellow_rtb) =>
                      onChangeInput({
                        confirmed_yellow_rtb: confirmed_yellow_rtb ?? null,
                      })
                    }
                    inspectorPanel={false}
                    id={'cam_confirmed_yellow_rtb'}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="planned_red_rtb"
                    validate={(newValue) => {
                      if (
                        newValue &&
                        controlArea?.rtb_status !== 'red_clean_zone'
                      )
                        return t('rtb_selected_is_not_correct')

                      return undefined
                    }}
                    selectedTime={controlArea?.planned_red_rtb ?? undefined}
                    onValueSubmitted={(planned_red_rtb) =>
                      onChangeInput({
                        planned_red_rtb: planned_red_rtb ?? null,
                      })
                    }
                    inspectorPanel={false}
                    id={'cam_planned_red_rtb'}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="confirmed_red_rtb"
                    validate={(newValue) => {
                      if (
                        newValue &&
                        controlArea?.rtb_status !== 'red_clean_zone'
                      )
                        return t('rtb_selected_is_not_correct')

                      return undefined
                    }}
                    selectedTime={controlArea?.confirmed_red_rtb ?? undefined}
                    onValueSubmitted={(confirmed_red_rtb) =>
                      onChangeInput({
                        confirmed_red_rtb: confirmed_red_rtb ?? null,
                      })
                    }
                    inspectorPanel={false}
                    id={'cam_confirmed_red_rtb'}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="planned_mc"
                    validate={(newValue) => {
                      if (
                        (newValue &&
                          controlArea?.planned_start &&
                          moment(newValue).isBefore(controlArea.planned_start),
                        'day')
                      )
                        return t('can_not_be_before', {
                          date: moment(controlArea?.planned_start).format('L'),
                        })

                      return undefined
                    }}
                    selectedTime={controlArea?.planned_mc ?? undefined}
                    onValueSubmitted={(planned_mc) =>
                      onChangeInput({ planned_mc: planned_mc ?? null })
                    }
                    inspectorPanel={false}
                    id={'cam_planned_mc'}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="confirmed_mc"
                    validate={(newValue) => {
                      if (
                        (newValue &&
                          controlArea?.planned_start &&
                          moment(newValue).isBefore(controlArea.planned_start),
                        'day')
                      )
                        return t('can_not_be_before', {
                          date: moment(controlArea?.planned_start).format('L'),
                        })

                      return undefined
                    }}
                    selectedTime={controlArea?.confirmed_mc ?? undefined}
                    onValueSubmitted={(confirmed_mc) =>
                      onChangeInput({ confirmed_mc: confirmed_mc ?? null })
                    }
                    inspectorPanel={false}
                    id={'cam_confirmed_mc'}
                    disabled={!isConstructionManager}
                  />
                  <DateTimeInlineInputComponent
                    label="planned_power_on"
                    validate={(newValue) => {
                      if (
                        (newValue &&
                          controlArea?.planned_start &&
                          moment(newValue).isBefore(controlArea.planned_start),
                        'day')
                      )
                        return t('can_not_be_before', {
                          date: moment(controlArea?.planned_start).format('L'),
                        })

                      return undefined
                    }}
                    selectedTime={controlArea?.planned_power_on ?? undefined}
                    onValueSubmitted={(planned_power_on) =>
                      onChangeInput({
                        planned_power_on: planned_power_on ?? null,
                      })
                    }
                    inspectorPanel={false}
                    id={'cam_planned_power_on'}
                    disabled={!isConstructionManager}
                  />
                </InlineComponentsWrapper>
                <InlineComponentsWrapper
                  inputWidth={'w-64'}
                  padding="left"
                  labelWidth={'w-40'}
                >
                  <DateTimeInlineInputComponent
                    label="confirmed_power_on"
                    validate={(newValue) => {
                      if (
                        (newValue &&
                          controlArea?.planned_start &&
                          moment(newValue).isBefore(controlArea?.planned_start),
                        'day')
                      )
                        return t('can_not_be_before', {
                          date: moment(controlArea?.planned_start).format('L'),
                        })

                      return undefined
                    }}
                    selectedTime={controlArea?.confirmed_power_on ?? undefined}
                    onValueSubmitted={(confirmed_power_on) =>
                      onChangeInput({
                        confirmed_power_on: confirmed_power_on ?? null,
                      })
                    }
                    inspectorPanel={false}
                    id={'cam_confirmed_power_on'}
                    disabled={!isConstructionManager}
                  />
                  <TextInlineInputCompontent
                    label="done_check_lists"
                    type={'number'}
                    disabled={true}
                    value={`${controlArea?.total_area ?? 0}`}
                    onValueSubmitted={noop}
                  />
                  <TextInlineInputCompontent
                    label="total_check_lists"
                    type={'number'}
                    disabled={true}
                    value={`${controlArea?.total_check_lists ?? 0}`}
                    onValueSubmitted={noop}
                  />
                  <TextInlineInputCompontent
                    label="open_issues"
                    type={'number'}
                    disabled={true}
                    value={`${controlArea?.open_issues ?? 0}`}
                    onValueSubmitted={noop}
                  />
                  <TextInlineInputCompontent
                    label="total_issues"
                    type={'number'}
                    disabled={true}
                    value={`${controlArea?.total_issues ?? 0}`}
                    onValueSubmitted={noop}
                  />
                  <DateTimeInlineInputComponent
                    label="created_at"
                    selectedTime={controlArea?.created_at}
                    onValueSubmitted={noop}
                    disabled={true}
                    inspectorPanel={false}
                  />
                  <DateTimeInlineInputComponent
                    label="updated_at"
                    selectedTime={controlArea?.updated_at}
                    onValueSubmitted={noop}
                    disabled={true}
                    inspectorPanel={false}
                  />
                </InlineComponentsWrapper>
              </div>
            )}
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div
              className={
                'pt-1 pl-5 pr-4 mr-4 overflow-x-auto overflow-y-hidden min-h-[340px]'
              }
            >
              <TrainList controlAreaId={controlAreaId} inModal={true} />
            </div>
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div
              className={
                'pt-1 pl-5 pr-4 mr-4 overflow-x-auto overflow-y-hidden min-h-[340px]'
              }
            >
              <ControlAreaRoomList controlAreaId={controlAreaId} />
            </div>
          </TabPanel>
          <TabPanel>
            <div
              className={
                'pt-1 pl-5 pr-4 mr-4 overflow-x-auto overflow-y-hidden min-h-[340px]'
              }
            >
              <TestListForControlArea controlAreaId={controlAreaId} />
            </div>
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div
              className={
                'pt-1 pl-5 pr-4 mr-4 overflow-x-auto overflow-y-hidden min-h-[340px]'
              }
            >
              {controlArea?.id && (
                <ConstructionIssuesList
                  parentId={controlArea?.id}
                  parentType={'ControlArea'}
                  tableName="controlAreaIssuesList"
                />
              )}
            </div>
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div className="px-4 w-1/3">
              <Comments parentType="ControlArea" parentId={controlAreaId} />
            </div>
          </TabPanel>
          <TabPanel className={'noTabPanelPadding'}>
            <div className="px-4 w-1/3">
              <ChangeLog parentType="ControlArea" parentId={controlAreaId} />
            </div>
          </TabPanel>
        </Tabs>

        {showQrModal && (
          <QrModal
            show={showQrModal}
            close={toggleQrModal}
            title={t('control_area_title', { title: controlArea?.title })}
            value={qrString}
          />
        )}
      </div>
    </FixedHorizintalPane>
  )
}

export default ControlAreaModal
