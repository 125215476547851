
import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const afConfig: IEnvironmentConfig = {
  host: "af.bygg.io",
  exportBaseUrl: "https://export-af.bygg.io/",
  frontendBaseUrl: "https://af.bygg.io/",
  organizationBaseUrl: "https://org-af.bygg.io/",
  proxyBaseUrl: "https://proxy-af.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-af.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-af.bygg.io/",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "https://kpi-af.bygg.io/"
}