import { useContext } from 'react'
import ResolutionHandler from '../../service/ResolutionHandler'
import { classNames } from '../../utility/utils'
import { Alert, IAlertProps } from './Alert'
import { AlertContext, IAlertContext } from './AlertContext'

export const AlertContainer = () => {
  const isMobile = ResolutionHandler.shouldShowMobileVersion()

  const styleClass = {
    root: classNames(
      'fixed inset-0 flex flex-col items-end px-4 px-6 pointer-events-none sm:p-6 h-screen overflow-auto',
      isMobile ? 'justify-end mb-12' : 'justify-start',
    ),
  }

  const alertContext: IAlertContext = useContext(AlertContext)

  const { alerts } = alertContext.state
  return (
    <div style={{ zIndex: 1000 }} className={styleClass.root}>
      {alerts?.map(({ alertId, ...rest }: IAlertProps, i: number) => (
        <Alert alertId={alertId} key={i} {...rest} />
      ))}
    </div>
  )
}
