import { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import MilestonePage from 'src/page/milestone/MilestonePage'
import { ProjectContext } from './ProjectContextProvider/ProjectContext'
import { UserContext } from './UserContextProvider/UserContext'

interface ISystemsContextHOC {
  routeProps?: RouteComponentProps
}

const MilestoneContextHOC = ({ routeProps }: ISystemsContextHOC) => {
  const projectContext = useContext(ProjectContext)
  const userContext = useContext(UserContext)

  return (
    projectContext.state.currentProject &&
    userContext.state.user && (
      <MilestonePage
        routeProps={routeProps}
        projectId={projectContext.state.currentProject.id}
      />
    )
  )
}

export default MilestoneContextHOC
