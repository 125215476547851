import { FC } from 'react'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { IUploadState } from '../types'
import { formatBytes } from '../util'

interface FileUploadProgressProps {
  fileInfo: IUploadState
  onCancel?: () => void
}

const FileUploadProgress: FC<FileUploadProgressProps> = ({
  onCancel,
  fileInfo,
}) => {
  const { fileName, uploadStatus } = fileInfo
  const { progress, loaded } = uploadStatus

  const uploaded = progress > 97

  const { t } = useMultipleKeysTranslation()

  return (
    <div key={fileName} className="flex px-2 w-full items-center justify-start">
      <MaterialIcon
        className="text-blue-root text-sm"
        icon="cloud_upload"
        outline={true}
      />
      <span className="mx-2 text-d-fontchip font-normal text-xs">
        {uploaded ? t('processing') : t('uploading')}
      </span>
      {!uploaded ? (
        <>
          <div
            style={{
              background: `linear-gradient(90deg, #269BF7 ${progress}%, #C4C4C4 ${progress}%)`,
            }}
            className="mx-2 bg-blue-root h-2 rounded-full grow"
          />
          <span className="mx-2 text-d-fontchip font-normal text-xs min-w-12">
            {formatBytes(loaded, 1)}
          </span>
        </>
      ) : (
        <div className="relative w-full sm:w-1/2 bg-gray-200 rounded mx-4">
          <Tooltip message={t('upload_processing')}>
            <div className="absolute top-0 h-2 rounded shim w-full bg-blue-root"></div>
          </Tooltip>
        </div>
      )}
      {onCancel && (
        <span
          onClick={onCancel}
          className="bg-d-lightblue rounded-sm h-4 w-4 flex justify-center items-center"
        >
          <MaterialIcon
            className="text-red-danger text-sm mx-auto my-auto"
            icon="close"
          />
        </span>
      )}
    </div>
  )
}

export default FileUploadProgress
