import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import { IUserData } from '../../service/OrgTypes'
import { getDisplineUsersData } from '../../service/UserService'
import List from '../../ui-elements/list/List'
import { IListColumns } from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import { classNames } from '../../utility/utils'
import AddUserForm from './AddUserForm'
import UserIcon from './UserIcon'

interface IDisciplineUsersListProps {
  disciplineId: number
  contractId?: number
  underContract?: boolean
  isExpandedElement?: boolean
  projectId?: number
  onOpenUserInpectorPanel?: (disciplineId: number, userId: number) => void
  OpenUserInpectorPanel?: boolean
}

const DisciplineUsersList = (props: IDisciplineUsersListProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  const [users, setUsers] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const { t } = useTranslation()

  const reload = () => {
    setLoading(true)
    getDisplineUsersData(props.disciplineId)
      .then((res: any) => {
        setUsers(Array.isArray(res) ? res : [])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    reload()
  }, [props.disciplineId])

  const goToUser = (row: IUserData) => {
    if (props.onOpenUserInpectorPanel) {
      props.onOpenUserInpectorPanel(props.disciplineId, row.id)
    }
  }

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const closeCreateModal = () => {
    setShowCreateModal(false)
  }

  const columns: IListColumns[] = [
    {
      name: 'name',
      size: '300',
      id: 'name',
      dataField: 'firstName||lastName||image_url',
      cell: (user: any) =>
        user ? (
          <UserIcon
            userId={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            image_url={user.image_url}
          />
        ) : (
          <span />
        ),
    },
    {
      name: 'role',
      size: '200',
      id: 'role',
      dataField: 'user_contract_mappers',
      cell: (access?: any[]) => (
        <span className="truncate">
          {access
            ? access
                .filter((ds: any) => ds.discipline_id === props.disciplineId)
                .map((ds: any) => ds.role.roleName)
                .join(', ')
                .slice(0, 50)
            : ''}
        </span>
      ),
    },
    {
      name: 'email',
      size: '300',
      id: 'email',
      dataField: 'email',
    },
    {
      name: 'telephoneNumber',
      size: '300',
      id: 'telephoneNumber',
      dataField: 'telephoneNumber',
    },
    {
      name: 'created_at',
      size: '100',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const underContractColumns: IListColumns[] = [
    {
      name: 'name',
      size: '300',
      id: 'name',
      dataField: 'firstName||lastName||image_url',
      cell: (user: any) =>
        user ? (
          <UserIcon
            firstName={user.firstName}
            lastName={user.lastName}
            image_url={user.image_url}
          />
        ) : (
          <span />
        ),
    },
    {
      name: 'role',
      size: '200',
      id: 'role',
      dataField: 'user_contract_mappers',
      cell: (access: any) => (
        <span>
          {access
            ? access
                .filter((ds: any) => ds.discipline_id === props.disciplineId)
                .filter((ds: any) => ds.role)
                .map((ds: any) => ds.role.roleName)
                .slice(0, 50)
            : ''}
        </span>
      ),
    },
    {
      name: 'email',
      size: '300',
      id: 'email',
      dataField: 'email',
    },
    {
      name: 'telephoneNumber',
      size: '300',
      id: 'telephoneNumber',
      dataField: 'telephoneNumber',
    },
    {
      name: 'created_at',
      size: '100',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '130',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  return (
    <div className={styleClass.root}>
      <List
        columns={props.underContract ? underContractColumns : columns}
        data={users}
        title={props.isExpandedElement ? t('users') : undefined}
        tableName={'disciplinUsersList'}
        onRowClick={goToUser}
        itemsPerPage={0}
        isExpandedElement={props.isExpandedElement}
        actionButton={
          <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
            {t('new_user')}
          </Button>
        }
        onPreviewClick={goToUser}
      />
      {props.projectId && (
        <Modal
          show={showCreateModal}
          closeModal={closeCreateModal}
          title={t('add_new_user')}
          size={'w-9/10'}
          maxWidth={800}
        >
          <div className={''}>
            <AddUserForm
              projectId={props.projectId}
              organizationId={0}
              organizationLevel={false}
              isEditMode={false}
              closeModal={closeCreateModal}
              contractAccess={{
                contract_id: props.contractId,
                discipline_id: Number(props.disciplineId),
              }}
            />
          </div>
        </Modal>
      )}

      {loading && !props.OpenUserInpectorPanel && !props.isExpandedElement ? (
        <Loader />
      ) : null}
    </div>
  )
}

export default DisciplineUsersList
