import { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import { classNames } from '../../utility/utils'

export interface ISignatureInputProps {
  width: number
  height: number
  data: (data: string) => void
  label: string
  required?: boolean
  errorMessage?: string
  inMobile?: boolean
}

const styleClass = {
  inputGroup: classNames('w-full', 'flex', 'flex-row', 'pr-4'),
  label: (mobile: boolean) =>
    classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      mobile ? 'text-gray-500 font-extralight' : 'text-gray-700',
      'my-2',
    ),
  errorMessage: classNames(
    'flex',
    'items-center',
    'text-red-600',
    'ml-2',
    'my-2',
    'text-sm',
    'font-normal',
  ),
}

const SignatureInput = ({
  width,
  height,
  data,
  label,
  errorMessage,
  required,
  inMobile = false,
}: ISignatureInputProps) => {
  const [trimmedDataURL, setTrimData] = useState<string>('')

  const sendData = () => {
    if (sigPad.current) {
      if (sigPad.current.isEmpty()) {
        data('')
      } else {
        data(sigPad.current.getTrimmedCanvas().toDataURL('image/png') as string)
      }
    } else {
      data('')
    }
  }

  const sigPad = useRef<any>()

  const clear = () => {
    if (sigPad.current) {
      sigPad.current.clear()
      data('')
    }
  }

  const trim = () => {
    if (sigPad.current) {
      setTrimData(
        sigPad.current.getTrimmedCanvas().toDataURL('image/png') as string,
      )
      data(trimmedDataURL)
    }
  }

  return (
    <div>
      <div>
        <div className={'pr-4 flex flex-row'}>
          {label && (
            <label className={styleClass.label(inMobile)}>
              {label}
              {required ? ' *' : ''}
            </label>
          )}
          {<p className={styleClass.errorMessage}>{errorMessage}</p>}
        </div>
      </div>
      <div className={styleClass.inputGroup}>
        <SignatureCanvas
          penColor="green"
          onEnd={sendData}
          canvasProps={{
            width,
            height: height - 100,
            className: `border border-gray-400 pr-4 ${
              inMobile ? 'border-dotted rounded-md' : ''
            }`,
          }}
          ref={(ref) => {
            sigPad.current = ref
          }}
        />
      </div>
      <div className={`flex justify-end ${inMobile ? '-mr-6' : '-mr-1'}`}>
        <Icon
          style={{ width: 20, height: 20 }}
          icon={Icon.IconType.CHECK}
          onClick={trim}
        />
        <Button size={ButtonSize.XSMALL} onClick={clear}>
          <Icon
            style={{ width: 20, height: 20 }}
            icon={Icon.IconType.CLOSE_GRAY}
          />
        </Button>
      </div>
    </div>
  )
}

export default SignatureInput
