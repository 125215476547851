interface ISpinnerMobile {
  isBlue?: boolean
  className?: string
}

const SpinnerMobile = ({
  isBlue = true,
  className = '',
}: ISpinnerMobile): JSX.Element => (
  <div className={`lds-default ${isBlue ? 'blue' : ''} ${className}`}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default SpinnerMobile
