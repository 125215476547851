export const userListExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/user_access_filter/pages/0/0',
    method: 'POST',
  },
  name: 'Users',
  displayName: 'Bruker',
  columns: [
    'name',
    'contract',
    'disiplin',
    'role',
    'projectEmail',
    'isProjectAdmin',
    'riskAdmin',
    'baselineAdmin',
    'meetingAdmin',
    'isQualityAdmin',
    'statusManager',
    'constructionManager',
    'createdAt',
    'updatedAt',
    'last_logged_in',
  ],
  rows: [
    'user.firstName||user.lastName',
    'user_contract_mappers->contract.contractName',
    'user_contract_mappers->discipline.name',
    'user_contract_mappers->role.roleName',
    'user.email',
    'isProjectAdmin',
    'isProjectRiskAdmin',
    'isBaselineAdmin',
    'meetingModuleAccess',
    'isQualityAdmin',
    'status_manager',
    'isConstructionManager',
    'created_at',
    'updated_at',
    'user.last_logged_in',
  ],
}
