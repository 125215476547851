import moment from 'moment'
import { createRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import tinycolor from 'tinycolor2'
import useWindowDimensions from 'src/ui-elements/page-display/UseWindowDimensions'
import {
  canvasRootStyles,
  canvasSubTitleStyles,
  canvasTitleStyles,
  getHoverItemPosition,
  IHoverPos,
} from 'src/utility/Utility'
import history from '../../history'
import { capFirstLetter, classNames } from '../../utility/utils'
import ContextMenu, { IContextMenuItem } from '../context-menu/ContextMenu'

interface IMilestoneCardTimelineItemProps {
  item: any
  getItemProps: any
  openMilestone: (data: any) => void
  itemContext: any
  timelineContext: any
  itemSelected: boolean
  copyItem: (item: any) => void
  updateItem: (item: any) => void
  deleteItem: (item: any) => void
  onCreateKeypoint: (item: any) => void
}

const styleClass = {
  root: classNames('truncate'),
  mainRoot: classNames(
    'border-solid',
    'text-left',
    'flex',
    'flex-col',
    'text-wrap',
    'text-xxs',
    'bg-gray-50',
    'hover:border-blue-100',
    'focus:border-blue-100',
    'break-words',
    'cursor-pointer',
    'relative',
    'text-gray-800',
    'border-b-4',
  ),
  statsColor: (status: string, expired: boolean, expiredChildren: boolean) =>
    twMerge(
      'justify-center',
      'items-center',
      'w-5',
      'h-5',
      'font-semibold',
      expired && status !== 'done' ? 'bg-red-four text-white' : 'bg-white',
      status === 'done' ? 'bg-green text-white' : '',
      expiredChildren ? 'bg-yellow-300' : '',
      'rounded-full',
      'shadow',
      'flex',
    ),
  title: classNames('text-sm', 'font-semibold', 'px-1', 'py-0.5', 'truncate'),
  subTitle: classNames('px-1', 'pt-0.5', 'truncate', 'text-xs'),
  floatLabel: classNames(
    'fixed',
    'bg-white',
    'text-sm',
    'shadow',
    'p-3',
    'flex',
    'z-20',
    'flex-col',
  ),
  floatLabelSmallText: classNames('text-xxs', 'py-1'),
  floatConditionText: (expired: boolean) =>
    classNames('text-xxs', 'mb-1', expired ? 'text-red-two' : ''),
}

const MilestoneCardTimelineItem = ({
  item,
  getItemProps,
  openMilestone,
  itemContext,
  itemSelected,
  timelineContext,
  copyItem,
  updateItem,
  deleteItem,
  onCreateKeypoint,
}: IMilestoneCardTimelineItemProps) => {
  const [showContext, setShowContext] = useState<boolean>(false)
  const [showLabel, setShowLabel] = useState<boolean>(false)
  const [floatLabel, setFloatLabel] = useState<IHoverPos>({
    left: 0,
    right: undefined,
    top: 0,
    bottom: undefined,
  })
  const [selectedColor, setSelectedColor] = useState<string>(
    itemSelected ? 'rgba(59,130,246,0.7' : 'transparent',
  )
  const timer = useRef({} as any)
  const { t } = useTranslation()
  const { width: vw, height: vh } = useWindowDimensions()
  const ref = useRef(createRef<HTMLDivElement>())

  const st = moment(timelineContext.visibleTimeStart)
  const et = moment(timelineContext.visibleTimeEnd)

  useEffect(() => {
    setSelectedColor(itemSelected ? 'rgba(59,130,246,0.7' : 'transparent')
  }, [itemSelected])

  const onMouseEnter = (e: any) => {
    const itemRect = ref?.current?.current?.getBoundingClientRect()
    if (itemRect) {
      const tmpFloatLabel = getHoverItemPosition(
        floatLabel,
        e,
        itemRect,
        vw,
        vh,
        itemContext,
      )
      timer.current = setTimeout(() => {
        setShowLabel(true)
        setFloatLabel(tmpFloatLabel)
      }, 750)
    }
  }

  const onMouseLeave = () => {
    clearTimeout(timer.current)
    setShowLabel(false)
  }

  const createKeypoint = () => {
    onCreateKeypoint(item)
  }

  const contextMenu: IContextMenuItem[] = [
    {
      title: t('create_new_param', { param: t('keypoint') }),
      active: true,
      onItemClick: () => createKeypoint(),
    },
    {
      title: t('copy'),
      active: true,
      onItemClick: () => copyItem(item),
    },
    {
      title: t('set_status_to_completed'),
      active: item.status !== 'done' && item.open_children === 0,
      onItemClick: () => updateItem({ id: item.id, status: 'done' }),
    },
    {
      title: t('view_or_plan_keypoints'),
      active: true,
      onItemClick: () => history.push(`/main-process/milestone/${item.id}`),
    },
    {
      title: t('delete'),
      active: true,
      onItemClick: () => deleteItem(item),
    },
  ]

  const showMenu = () => {
    clearTimeout(timer.current)
    const target = ref.current.current?.getBoundingClientRect()
    const tmpFloatLabel = { ...floatLabel }
    tmpFloatLabel.left = target?.x + itemContext.width
    tmpFloatLabel.top = target?.top
    setShowContext(true)
    setShowLabel(false)
    setFloatLabel(tmpFloatLabel)
  }

  const onClick = () => {
    openMilestone(item)
    setShowLabel(false)
    clearTimeout(timer.current)
  }

  return (
    <>
      <div
        className={'flex flex-col'}
        onMouseEnter={(e) => onMouseEnter(e)}
        onMouseLeave={onMouseLeave}
      >
        <div
          {...getItemProps({
            style: {
              backgroundColor: 'white',
              color: item.color,
              zIndex: 15,
              background: selectedColor,
              borderStyle: 'solid',
              minWidth: '20px',
              borderColor: selectedColor,
              borderWidth: 0,
              borderBottomWidth: 3,
            },
          })}
        >
          <div
            ref={ref.current}
            onDoubleClick={onClick}
            onContextMenu={showMenu}
            className={styleClass.mainRoot}
            style={canvasRootStyles(
              item.discipline?.color,
              itemContext.dimensions.height,
            )}
          >
            <div className={styleClass.root} id={`milestone-${item.id}`}>
              <div
                style={canvasTitleStyles(item.discipline?.color)}
                className={styleClass.title}
              >
                {item.name}
              </div>
              <p
                style={canvasSubTitleStyles(item.discipline?.color)}
                className={styleClass.subTitle}
              >
                {`${t('keypoint')}: ${item.done_children}/${
                  item.done_children + item.open_children
                }`}
              </p>
            </div>
            <div
              className={'flex flex-row items-start pt-px'}
              style={{
                backgroundColor: new tinycolor(item.discipline?.color)
                  .setAlpha(0.7)
                  .toString(),
              }}
            >
              <span
                className={`${
                  et.diff(st) < 30000000000 ? 'px-1 pb-px pt-1 w-5p' : ''
                }`}
              >
                <div
                  className={styleClass.statsColor(
                    item.status,
                    item.expired,
                    item.expired_children > 0,
                  )}
                />
              </span>
              <p className={'text-gray-800 font-semibold pl-3 pt-1 truncate'}>
                {item.record_id}
              </p>
            </div>
          </div>
        </div>
        {showLabel ? (
          <div
            className={styleClass.floatLabel}
            style={{
              left: floatLabel.left,
              right: floatLabel.right,
              top: floatLabel.top,
              bottom: floatLabel.bottom,
            }}
          >
            <div>
              <span className={'float-left'}>
                {item.record_id}: {item.name}
              </span>
            </div>
            <div className={styleClass.floatLabelSmallText}>
              {`${capFirstLetter(t('discipline'))}:`}{' '}
              {item.discipline ? item.discipline.name : ''}
            </div>
            <div className={styleClass.floatLabelSmallText}>
              {`${capFirstLetter(t('responsible'))}:`}{' '}
              {item.responsible
                ? item.responsible.firstName + ' ' + item.responsible.lastName
                : ''}
            </div>
            <div className={styleClass.floatLabelSmallText}>
              {`${capFirstLetter(t('deadline'))}:`}{' '}
              {item.deadline ? moment(item.deadline).format('L') : ''}
            </div>
          </div>
        ) : null}
      </div>
      <ContextMenu
        itemWidth={itemContext.width}
        show={showContext}
        pos={floatLabel}
        menus={contextMenu}
        closeMenu={() => setShowContext(false)}
      />
    </>
  )
}

export default MilestoneCardTimelineItem
