import moment from 'moment'
import * as React from 'react'

interface IGanttIconProps {
  date: moment.Moment
  item: any
}

const GantTimelineItemIcon: React.FunctionComponent<IGanttIconProps> = ({
  date: _date,
  item,
}: IGanttIconProps) => {
  const bgType =
    item.status === 'done'
      ? '-green'
      : moment(item.end_time).isBefore(moment(), 'day')
        ? ''
        : (item.expired_children && item.expired_children > 0) ||
            (item.behind_schedule_children && item.behind_schedule_children > 0)
          ? '-yellow'
          : '-black'

  switch (item.type) {
    case 'MainProcess':
      return (
        <div className={'pl-0.5 cursor-pointer'}>
          <div className={`triangle-down${bgType}`} />
        </div>
      )
    case 'MileStone':
      return (
        <div className={'pl-0.5 cursor-pointer'}>
          <div className={`triangle-down${bgType}`} />
        </div>
      )
    case 'KeyPoint':
      return (
        <div className={'pl-0.5 cursor-pointer'}>
          <div className={`diamond${bgType}`} />
        </div>
      )
    case 'Delivery':
      return (
        <div className={'pl-0.5 cursor-pointer'}>
          <div className={`circle${bgType}`} />
        </div>
      )
    case 'Task':
      return (
        <div className={'pl-0.5 cursor-pointer'}>
          <div className={`triangle-right${bgType}`} />
        </div>
      )
    default:
      return null
  }
}

export default GantTimelineItemIcon
