import { useContext } from 'react'
import { IAlert, IAlertType } from './Alert'
import { AlertContext } from './AlertContext'

const useAlert = (): {
  addAlert: (a: IAlert) => void
  showAlert: (
    type: IAlertType,
    title: string,
    description: string,
  ) => number | undefined
  showInfo: (title: string, description?: string) => number | undefined
  showError: (title: string, description?: string) => number | undefined
  showWarning: (title: string, description?: string) => number | undefined
  removeAlert: (id: number) => void
} => {
  const context = useContext(AlertContext)

  const showAlert = (type: IAlertType, title: string, description: string) => {
    return context.actions.addAlert({ type, title, description })
  }

  const showInfo = (title: string, description?: string) => {
    return context.actions.addAlert({ type: 'info', title, description })
  }

  const showError = (title: string, description?: string) => {
    return context.actions.addAlert({ type: 'error', title, description })
  }

  const showWarning = (title: string, description?: string) => {
    return context.actions.addAlert({ type: 'warning', title, description })
  }

  const removeAlert = (id: number) => {
    context.actions.removeAlert(id)
  }

  return {
    addAlert: context.actions.addAlert,
    showAlert,
    showInfo,
    showError,
    showWarning,
    removeAlert,
  }
}

export default useAlert
