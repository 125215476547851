import CalendarClock from '@material-symbols/svg-500/outlined/calendar_clock-fill.svg'
import Functions from '@material-symbols/svg-500/outlined/functions.svg'
import Person from '@material-symbols/svg-500/outlined/person-fill.svg'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { classNames } from 'src/utility/utils'
import { closedStatusTypes, statusTypes } from '../../service/SystemValues'
import Badge from '../../ui-elements/badge/Badge'
import { toFixed } from '../../utility/Utility'

interface ICustomCardProps {
  tasks: any[]
  id?: string
  showDetails?: boolean
}

const CustomCard = ({ tasks, id, showDetails = true }: ICustomCardProps) => {
  const { t } = useTranslation()
  const task = tasks.find((loc) => loc.id === Number(id))

  const statusBatch = (item: any) => {
    return (
      <Badge
        size={Badge.BadgeSize.SMALL}
        text={statusTypes(t)
          .filter((st: any) => st.id === item.status)
          .map((st: any) => st.name)
          .pop()}
        color={
          closedStatusTypes.some((x) => x === item.status)
            ? Badge.BadgeColor.GREEN
            : moment(item.deadline).isBefore(moment(), 'day')
              ? Badge.BadgeColor.RED
              : Badge.BadgeColor.BLUE
        }
      />
    )
  }

  return (
    <div className="pt-1">
      <div className="truncate text-sm font-bold">{task?.title}</div>
      <div
        className={classNames(
          'text-xs',
          !showDetails && 'border-t border1 mt-1 pt-1',
        )}
      >
        <div className="mr-1">{`${task?.discipline?.shortName ?? ''} - ${
          task?.discipline?.name ?? ''
        }`}</div>
      </div>
      {!showDetails && (
        <div className="text-xs flex border-1 pt-1 justify-between">
          <span className="flex flex-row items-center">
            <Person className="mr-1" style={{ width: 15, height: 15 }} />
            {toFixed(task?.number_of_workers)}
          </span>
          <span className="ml-2 flex flex-row items-center">
            <CalendarClock style={{ width: 13, height: 13 }} />
            <span className="ml-1">
              {toFixed(task?.planned_execution_hours)}
            </span>
          </span>
          <span className="ml-2 flex flex-row items-center">
            <Functions style={{ width: 15, height: 15 }} />
            <span className="ml-1">{toFixed(task?.total_work_hours)}</span>
          </span>
        </div>
      )}
      {task?.deadline && !showDetails && (
        <div className="text-xs flex border-t border-1 pt-1">
          <div className="mr-3">{moment(task?.deadline).format('L')}</div>
          {statusBatch(task)}
        </div>
      )}
    </div>
  )
}

export default CustomCard
