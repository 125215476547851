import { getFrontendBaseUrl } from 'src/utility/utils'

class VersionDetector {
  private latestVersion: string
  private newVersionCallback?: () => void
  private storageKey = 'appVersion'
  private interval: ReturnType<typeof setInterval>
  private delayCycle = 60000

  readonly setVersionCallback = (newVersionCallback: () => void) => {
    this.newVersionCallback = newVersionCallback
    this.initLatestVersion()
  }

  private initLatestVersion = async () => {
    const storedVersion = this.readVersionFromStorage()
    const latestVersion = await this.readVersionFromUrl()

    if (storedVersion && storedVersion !== 'undefined') {
      this.latestVersion = storedVersion
    }
    if (this.newVersionAvailable(latestVersion)) {
      try {
        await this.makeSureWeUseLatestVersion()
      } catch (e) {
        console.error(e)
      }
    }
    this.initReadFromApiCycle()
  }

  private makeSureWeUseLatestVersion = async () => {
    const version = await this.readVersionFromUrl()
    if (version) {
      this.storeVersion(version)
      this.refresh()
    }
  }

  private clearCache = () => {
    if ('caches' in window) {
      window.caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name)
        }
      })
    }
  }

  readonly refresh = () => {
    this.clearCache()
    window.location.reload()
  }

  private readVersionFromStorage = () => {
    const version = window.localStorage.getItem(this.storageKey)
    return version
  }

  private storeVersion = (newVersion: string) => {
    window.localStorage.setItem(this.storageKey, newVersion)
  }

  private newVersionAvailable = (newVersion?: string) => {
    if (!newVersion && this.latestVersion) return false
    return newVersion !== this.latestVersion
  }

  private readVersionFromUrl = async () => {
    try {
      const res = await fetch(`${getFrontendBaseUrl()}meta.json`, {
        cache: 'no-store',
      })
      const data: { version: string } = await res.json()
      return data.version
    } catch (e) {
      console.error(e)
      return
    }
  }

  private callCallbackFn = () => {
    if (!this.newVersionCallback) {
      console.error('[VersionDetector.ts]: No callback available')
      return
    }
    this.newVersionCallback()
  }

  private handleCycle = async () => {
    const externalVersion = await this.readVersionFromUrl()
    if (this.newVersionAvailable(externalVersion)) {
      this.callCallbackFn()
    }
  }

  private initReadFromApiCycle = () => {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.interval = setInterval(this.handleCycle, this.delayCycle)
  }
}

export default new VersionDetector()
