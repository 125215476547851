import React from 'react'
import { useTranslation } from 'react-i18next'
import TabView, { ITabs } from 'src/components/tab-view/TabView'
import UserKPIPage from 'src/page/user/UserKPIPage'

import ProjectUsersList from './ProjectUsersList'

interface IUserConfigPageProps {
  projectId: number
  projectAdmin: boolean
  reload: () => void
}

const UserConfigPage: React.FC<IUserConfigPageProps> = ({
  projectId,
  projectAdmin,
  reload,
}: IUserConfigPageProps) => {
  const { t } = useTranslation()

  const tabs: ITabs = {
    user_list: {
      title: t('user_list'),
      show: true,
      content: (
        <ProjectUsersList
          projectId={projectId}
          reload={reload}
          projectAdmin={projectAdmin}
        />
      ),
    },
    kpi: {
      title: t('kpi'),
      show: true,
      content: <UserKPIPage projectId={projectId} />,
    },
  }

  return <TabView level={2} tabs={tabs} />
}

export default UserConfigPage
