import { getCurrentProject } from 'src/utility/utils'
import { ISystemSyntax } from '../document/types/ISystemSyntax'
import { ISystemSetting, ISystemSyntaxHierarchy } from './OrgTypes'
import { request } from './service-utils'

export interface ISystemSyntaxObject {
  system_syntax: string
  system_syntax_categories: Partial<ISystemSyntax>[]
  name?: string
  has_no_syntax?: boolean
  is_active?: boolean
}

export const createSystemSyntax = async (
  system_syntax: ISystemSyntaxObject,
) => {
  const project_id = getCurrentProject()
  const res = await request(
    `projects/${project_id}/system_settings`,
    'POST',
    system_syntax,
  )
  return res.id
}

export const updateSystemSyntax = (
  system_syntax: Partial<ISystemSyntaxObject>,
  system_settings_id: number,
) => {
  return request(`system_settings/${system_settings_id}`, 'PUT', system_syntax)
}

export const updateSystemCategory = (
  systemSettingsId: number,
  systemCategoryId: number,
  body: Partial<ISystemSyntaxHierarchy>,
) => {
  return request(
    `system_settings/${systemSettingsId}/system_syntax_categoires/${systemCategoryId}`,
    'PUT',
    body,
  )
}

export const getSystemSyntaxTree = async (): Promise<
  {
    key: number
    label?: string
    icon: string
  }[]
> => {
  const project_id = getCurrentProject()
  const res = await request(
    `projects/${project_id}/system_settings/tree`,
    'GET',
  )
  return res
}

export const getSystemSyntaxes = async (): Promise<ISystemSetting[]> => {
  const project_id = getCurrentProject()
  return request(`projects/${project_id}/system_settings`, 'GET')
}

export const getSingleSystemSyntax = async (
  settingId: number,
): Promise<ISystemSetting> => {
  const res = await request(`system_settings/${settingId}`, 'GET')
  return res
}

export const deleteSingleSystemSyntax = async (settingId: number) => {
  const res = await request(`system_settings/${settingId}`, 'DELETE')
  return res
}

export const getSingleSystemSyntaxCategories = async (
  id: number,
): Promise<ISystemSyntaxHierarchy[]> => {
  const res = await request(`system_settings/${id}`, 'GET')
  return res.system_syntax_categories ?? []
}
