import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getDocumentsForType } from 'src/service/DocumentService'

export const useDocumentFortype = (
  parentType: string,
  parentId: number,
  options?: { enabled?: boolean },
) => {
  const projectId = useProjectId()
  return useQuery({
    ...options,
    queryKey: ['documents', projectId, parentType, parentId],
    queryFn: () => getDocumentsForType(projectId, parentType, parentId),
  })
}
