import { useEffect, MutableRefObject, useMemo, useState } from 'react'
import TreeMenu, { TreeNodeInArray } from 'react-simple-tree-menu'
import { IFileDate } from 'src/components/explorer/DropDownExplorer'
import TreeItemlist from 'src/ui-elements/downshift/TreeItemList'

interface IFileSelectorProps {
  data: IFileDate[] | TreeNodeInArray[]
  onItemClick: (item: any) => void
  containerRef: MutableRefObject<null | HTMLDivElement>
  initialActiveKey?: string
  initialOpenNodes?: string[]
  resetOpenNodesOnDataUpdate?: boolean
  activeKey?: string
  collapsed?: boolean
  onToggleNode?: (keys: string[]) => void
}

const FileSelector = ({
  data,
  onItemClick,
  containerRef,
  initialActiveKey,
  initialOpenNodes,
  resetOpenNodesOnDataUpdate = false,
  activeKey,
  collapsed = false,
  onToggleNode: onOpenNode,
}: IFileSelectorProps) => {
  const fileSelectorcontainerDiv = document.getElementById(
    'fileSelectorcontainer',
  )
  const open = initialActiveKey?.split('/')?.[0] ?? '1'
  const [openNodes, setOpenNodes] = useState(initialOpenNodes ?? [open])

  useEffect(() => {
    setOpenNodes(initialOpenNodes ?? [open])
  }, [resetOpenNodesOnDataUpdate])

  useEffect(() => {
    const newOpenNodes =
      initialOpenNodes?.filter((node) => !openNodes?.includes(node)) ?? []
    onOpenNode?.(newOpenNodes)
    setOpenNodes([...openNodes, ...newOpenNodes])
  }, [initialOpenNodes?.toString()])

  const toggleNode = (key: string): void => {
    const isOpen = openNodes.includes(key)
    if (isOpen) {
      setOpenNodes(openNodes.filter((node) => !node.startsWith(key)))
    } else {
      onOpenNode?.([key])
      setOpenNodes([...openNodes, key])
    }
  }

  const scrollPos = useMemo(() => {
    return fileSelectorcontainerDiv?.scrollTop
  }, [fileSelectorcontainerDiv?.scrollTop])

  useEffect(() => {
    const fileSelectorcontainerDiv = document.getElementById(
      'fileSelectorcontainer',
    )
    if (fileSelectorcontainerDiv !== null) {
      fileSelectorcontainerDiv.scrollTop = scrollPos ?? 0
    }
  }, [])

  return (
    <TreeMenu
      data={data}
      activeKey={activeKey}
      focusKey={activeKey}
      debounceTime={125}
      resetOpenNodesOnDataUpdate={false}
      openNodes={openNodes}
    >
      {({ items }) => (
        <TreeItemlist
          items={items}
          containerRef={containerRef}
          collapsed={collapsed}
          onItemClick={onItemClick}
          toggleNode={toggleNode}
        />
      )}
    </TreeMenu>
  )
}

export default FileSelector
