import { memo, useEffect, useState } from 'react'
import { withUserContext } from 'src/context/WithUserContext'
import {
  IWithProjectContext,
  withProjectContext,
} from 'src/context/withProjectContext'
import {
  IImportItemList,
  IImportWithValidation,
  IListFilterFromParent,
} from 'src/service/OrgTypes'
import {
  IListActionMenuItem,
  IListColumns,
  ISelectType,
  ISorting,
} from 'src/ui-elements/list/ListTypes'
import { IUserContext } from '../../context/UserContextProvider/UserContext'
import { cloneObj } from '../../utility/utils'
import ListBody from './ListBody'
import ListContextProvider, {
  IListFilter,
  ResourceType,
} from './ListContextProvider'

interface IListProps extends IWithProjectContext, IUserContext {
  columns: IListColumns[]
  data: any[]
  itemsPerPage: number
  onRowClick?: (row: any) => void
  actionButton?: JSX.Element
  inKeyPointDetail?: boolean
  actionMenu?: IListActionMenuItem[]
  parent_type?: string
  parentId?: number | number[]
  filterParent?: string
  title?: string | JSX.Element
  exportTemplate?: any
  exportQrCodes?: any
  tableName: string
  pagination?: boolean
  resourceType?: ResourceType
  getFilteredData?: (
    filters: IListFilter[],
    sorting: ISorting,
    page: number,
  ) => void
  totalPages?: number
  reload?: boolean
  centerHeaders?: boolean
  disableConfigStore?: boolean
  sortBackend?: boolean
  filterFromParent?: IListFilterFromParent[]
  isExpandedElement?: boolean
  expandedElement?: (data: any, id?: number, key?: number) => JSX.Element
  isRowDraggable?: boolean
  swapOrder?: (id: number, sequence: number) => void
  disableColumnSelector?: boolean
  columnChange?: (ids: string[]) => void
  importItem?: IImportItemList
  importItemWithValidation?: IImportWithValidation
  isRowSelectable?: boolean
  onSelectRow?: (data: number[], clickedRow?: ISelectType) => void
  selectedRows?: number[]
  bulkDelete?: boolean
  onBulkDelete?: () => void
  clickExpand?: boolean
  filterResetOption?: boolean
  onPreviewClick?: (e: any, data: any) => void
  onEditClick?: (e: any, data: any) => void
  onRowDisable?: (id: number, index: number, data?: any) => boolean
  onRowGrayOut?: (data?: any) => boolean
  expandedRows?: number[]
  highlightedRows?: number[]
}

const List = (props: IListProps) => {
  const [data, setData] = useState<any[]>([])
  const [filterReset, setFilterReset] = useState<boolean>(false)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  return (
    <ListContextProvider
      data={data}
      projectId={props.projectContext.state.currentProject.id}
      columns={props.columns.filter((it: IListColumns) => !it.disabled)}
      parent_type={props.parent_type ? props.parent_type : undefined}
      parentId={props.parentId ? props.parentId : undefined}
      filterParent={props.filterParent ? props.filterParent : ''}
      tableName={props.tableName}
      itemsPerPage={props.itemsPerPage}
      resourceType={props.resourceType}
      getFilteredData={props.getFilteredData}
      totalPages={props.totalPages}
      reload={props.reload}
      userId={props.state.user.id}
      disableConfigStore={props.disableConfigStore} // change this to true to disable persistent table config on all tables
      sortBackend={props.sortBackend}
      tableReset={filterReset}
      setResetToDone={() => setFilterReset(false)}
      columnChange={props.columnChange}
      expandedRows={props.expandedRows}
    >
      <ListBody
        onRowClick={props.onRowClick}
        actionButton={props.actionButton}
        actionMenu={props.actionMenu}
        title={props.title}
        exportTemplate={
          props.exportTemplate ? cloneObj(props.exportTemplate) : null
        }
        exportQrCodes={
          props.exportQrCodes ? cloneObj(props.exportQrCodes) : null
        }
        parentId={props.parentId}
        pagination={props.pagination}
        centerHeaders={props.centerHeaders}
        filterFromParent={props.filterFromParent}
        expandedElement={props.expandedElement}
        isExpandedElement={props.isExpandedElement}
        isRowDraggable={props.isRowDraggable}
        swapOrder={props.swapOrder}
        disableColumnSelector={props.disableColumnSelector}
        importItem={props.importItem}
        importItemWithValidation={props.importItemWithValidation}
        isRowSelectable={props.isRowSelectable}
        onSelectRow={props.onSelectRow}
        selectedRows={props.selectedRows}
        highlightedRows={props.highlightedRows}
        bulkDelete={props.bulkDelete}
        resetFilter={
          props.filterResetOption ? () => setFilterReset(true) : undefined
        }
        onBulkDelete={props.onBulkDelete}
        clickExpand={props.clickExpand}
        onEditClick={props.onEditClick}
        onPreviewClick={props.onPreviewClick}
        onRowDisable={props.onRowDisable}
        onRowGrayOut={props.onRowGrayOut}
      />
    </ListContextProvider>
  )
}

/**
 *
 * @deprecated use the new Table component
 */
export default memo(withUserContext(withProjectContext(List)))
