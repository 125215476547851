import * as React from 'react'
import { classNames } from '../../utility/utils'
import { contentStyle, contentStyleWithTabs } from '../MUtils'

interface IMContent {
  hasTabs?: boolean
  children: React.ReactElement | JSX.Element
  hide?: boolean
  className?: string
  setVh?: boolean
  disableScrolling?: boolean
}

const MContent = ({
  hasTabs = false,
  children,
  hide,
  className = '',
  setVh = false,
  disableScrolling = false,
}: IMContent) => {
  const styleClass = {
    root: (moreClasses: string) =>
      classNames(
        'flex',
        'flex-col',
        disableScrolling ? 'overflow-y-hidden' : 'overflow-y-auto',
        'overflow-x-hidden',
        'relative',
        moreClasses,
      ),
  }

  return !hide ? (
    <div
      style={
        setVh ? (!hasTabs ? contentStyle : contentStyleWithTabs) : undefined
      }
      className={styleClass.root(className)}
    >
      {children}
    </div>
  ) : null
}

export default MContent
