import * as React from 'react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import { styleClass } from 'src/ui-elements/Table/Columns'
import { getRandomId } from 'src/utility/getRandomId'
import DropdownArrowIcon from '../icons/DropdownArrowIcon'

interface IBooleanInput {
  value: boolean
  onChange: (newValue: boolean) => void
  width?: string
}

const _value = (v: boolean | string): boolean => {
  return typeof v === 'string' ? v === 'true' : v
}

const BooleanInput: React.FC<IBooleanInput> = ({
  value,
  onChange,
  width: _width,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [localData, setLocalData] = useState(_value(value))
  const [randomId] = useState(getRandomId())
  const { t } = useMultipleKeysTranslation()

  const onItemClicked = (item: boolean) => {
    setIsOpen(false)
    setLocalData(item)
    onChange(item)
  }

  return (
    <div className={'w-full h-full'}>
      <div
        className={twMerge(
          'flex justify-between items-center w-full h-full',
          styleClass.editableCell,
        )}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(true)
        }}
      >
        {`${localData ? t('yes') : t('no')}` || t('option')}
        <DropdownArrowIcon isOpen={isOpen} />
      </div>
      {isOpen ? (
        <CloseClickOutside onClose={() => setIsOpen(false)}>
          <div className="absolute bg-white shadow-md p-1 rounded-md z-50">
            {[
              { label: t('yes'), value: true },
              { label: t('no'), value: false },
            ].map((item) => (
              <div
                key={randomId + item.value}
                className="p-1 hover:bg-blue-50 rounded"
                onClick={(e) => {
                  e.stopPropagation()
                  onItemClicked(item.value)
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        </CloseClickOutside>
      ) : null}
    </div>
  )
}

export default BooleanInput
