import { TFunction } from 'i18next'
import { ActivityType, IActivityLog } from 'src/service/OrgTypes'
import { getModalUrl } from 'src/utility/modalUtils'
import { getModelName } from './utils'

export const getModalNameFromActivityLog = (activityLog: IActivityLog) => {
  const parentType = activityLog.parent_type.toLowerCase()

  if (activityLog.activity_type === ActivityType.SYSTEM_NOTIFICATION) {
    return 'notification'
  }
  if (parentType === 'revision') {
    return 'filecontainer'
  }
  return parentType
}

export const getIdFromActivityLog = (activityLog: IActivityLog) => {
  const parentType = activityLog.parent_type.toLowerCase()

  if (activityLog.activity_type === ActivityType.SYSTEM_NOTIFICATION) {
    return activityLog.id
  }
  if (parentType === 'revision') {
    return activityLog.parent.file_container_id
  }
  return activityLog.parent_id
}

export const getAdditionalParamsFromActivityLog = (
  activityLog: IActivityLog,
) => {
  const parentType = activityLog.parent_type.toLowerCase()

  if (
    activityLog.activity_type === ActivityType.COMMENT ||
    activityLog.activity_type === ActivityType.COMMENT_MENTION
  ) {
    return '&showLogsDefault=true'
  }
  if (parentType === 'risk') {
    return `&riskGroup=${activityLog.parent.risk_group_id}`
  }
  if (parentType === 'revision') {
    return `&showRevision=true`
  }
  return ''
}

export const getActivityLogModalUrl = (activityLog: IActivityLog) => {
  if (!activityLog.project_id) return ''
  const modal = getModalNameFromActivityLog(activityLog)
  const id = getIdFromActivityLog(activityLog)
  const additionalParams = getAdditionalParamsFromActivityLog(activityLog)

  return getModalUrl(activityLog.project_id, modal, id, additionalParams)
}

export const getItemNameFromActivityLog = (
  activityLog: IActivityLog,
  t: TFunction<'translation', undefined>,
) => {
  const parentTypeModelName = getModelName(
    activityLog.parent_type.toLowerCase(),
    t,
  )
  const recordId = activityLog?.parent?.record_id
  const parentName =
    activityLog?.parent?.name ||
    activityLog?.parent?.title ||
    activityLog.parent_title ||
    ''

  return `${parentTypeModelName} ${
    recordId ? `${recordId} - ` : ''
  }${parentName}`
}
