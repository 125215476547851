import 'moment/dist/locale/nb'
import '@taskctrl/react-calendar-timeline/lib/Timeline.css'
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from '@taskctrl/react-calendar-timeline'
import moment from 'moment-timezone'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import {
  CanvasType,
  ICanvasDuration,
  IMilestone,
  TimelineFilterTypes,
} from 'src/service/OrgTypes'
import {
  getProjectDisciplineMilestoneTree,
  getProjectMainProcessMilestoneTree,
} from 'src/service/ProjectService'
import Loader from 'src/ui-elements/loader/Loader'
import { classNames } from '../../utility/utils'
import CanvasFloatingFilter, {
  CanvasFilterType,
} from '../canvas-filter/CanvasFloatingFilter'
import HelperIcons from '../canvas-header/HelperIcons'
import TimeLineResetDate from '../canvas-header/TimelineResetDate'
import { setColorForIcon } from '../discipline/disciplineUtils'
import CustomMonthHeader from '../gantt/CustomMonthHeader'
import MilestoneInspectorPanel from '../milestones/MilestoneInspectorPanel'
import MilestoneTimelineItem from './MilestoneTimelineItem'

const styleClass = {
  rowName: classNames(
    'bg-cool-gray-200',
    'flex',
    'items-center',
    'pl-2',
    'pr-2',
    'justify-between',
    'text-sm',
    'text-gray-700',
    'border-grey-root-3',
    'border-b-015',
  ),
  dateItem: classNames(
    'sticky',
    'px-4',
    'flex',
    'flex-col',
    'items-center',
    'justify-center',
    'font-bold',
    'bg-white',
    'border-r-01',
    'border-l-01',
    'border-b-04',
    'border-grey-root-3',
    '',
  ),
  sideHeader: classNames(
    'absolute',
    'flex',
    'flex-row',
    'left-header-border',
    'justify-center',
    'items-center',
    'pl-8 ',
    'bg-green-wash',
  ),
  gridItem: classNames(
    'flex',
    'flex-1',
    'justify-center',
    'items-center',
    'min-w-19 ',
    'justify-between',
    'text-sm',
    'text-gray-700',
    'font-normal',
    'capitalize',
  ),
  item: classNames(
    'flex',
    'px-4',
    'overflow-visible',
    'whitespace-nowrap',
    'cursor-pointer',
    'relative',
  ),
  dateHeader: classNames(
    'text-sm',
    'capitalize',
    'text-gray-500',
    'whitespace-nowrap',
    'font-normal',
  ),
}

interface IMilestoneTimelineProps {
  canvasType: CanvasType
}

const MilestoneTimeline = ({ canvasType }: IMilestoneTimelineProps) => {
  moment.tz.setDefault('Europe/Oslo')
  const defaultTimeStart = moment().add(-2, 'weeks').toDate()
  const defaultTimeEnd = moment().add(4, 'weeks').toDate()
  const [items, setItems] = useState<any[]>([])
  const [groups, setGroups] = useState<any[]>([])
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [selectedMilestone, setSelectedMilestone] = useState<IMilestone>(
    {} as IMilestone,
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [canvasDuration, setCanvasDuration] = useState<ICanvasDuration>({
    canvasStartTime: defaultTimeStart,
    canvasEndTime: defaultTimeEnd,
  })
  const [showWeek, setShowWeek] = useState<boolean>(true)
  const [reloadTimeline, setReloadTimeline] = useState<boolean>(false)
  const [reloadData, setReloadData] = useState<boolean>(false)

  const { t } = useTranslation()

  const projectContext = useContext(ProjectContext)

  const getData = async (filters: any) => {
    const id = projectContext.state.currentProject.id
    const filterData = {
      discipline: filters[CanvasFilterType.DISCIPLINES],
      main_process: filters[CanvasFilterType.PROCESSES],
      team: filters[CanvasFilterType.THEMES],
      responsible: filters[CanvasFilterType.USERS],
      tag: filters[CanvasFilterType.TAGS],
      behind_schedule: filters[CanvasFilterType.BACK_PLAN],
      open: filters[CanvasFilterType.OPEN],
    }

    const milestoneData =
      canvasType === CanvasType.DISCIPLINE
        ? await getProjectDisciplineMilestoneTree(id, filterData)
        : await getProjectMainProcessMilestoneTree(id, filterData)

    setItems(milestoneData.items)
    setGroups(milestoneData.groups)
    setLoading(false)
  }

  const closeMilestoneModal = () => {
    setShowDetailsModal(false)
    setReloadData((n) => !n)
  }

  useDidMountEffect(() => {
    setLoading(true)
    setReloadData(!reloadData)
  }, [canvasType])

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps: _getResizeProps,
  }: any) => {
    return (
      <MilestoneTimelineItem
        getItemProps={getItemProps}
        item={item}
        openMilestone={openMilestone}
        timelineContext={timelineContext}
        alwaysShowLabel={true}
        itemContext={itemContext}
      />
    )
  }

  const groupRenderer = ({ group }: any) => {
    const { shape, color, title } = group
    return (
      <div
        style={{ height: 'inherit', fontSize: 14 }}
        className={styleClass.rowName}
      >
        {canvasType === CanvasType.DISCIPLINE && (
          <span className={'float-left mr-2'}>
            {setColorForIcon({
              shape,
              color,
            })}
          </span>
        )}
        <span
          className={'truncate'}
          style={{ color: '#6B7280', width: '250px' }}
        >
          {title}
        </span>
      </div>
    )
  }

  const openMilestone = (milestone: any, timelineContext: any) => {
    setSelectedMilestone(milestone)
    setShowDetailsModal(true)
    setCanvasDuration({
      canvasStartTime: new Date(timelineContext.visibleTimeStart),
      canvasEndTime: new Date(timelineContext.visibleTimeEnd),
    })
  }

  const leftMenuTitle = () => {
    if (canvasType === CanvasType.DISCIPLINE) {
      return t('discipline')
    }

    if (canvasType === CanvasType.MAIN_PROCESS) {
      return t('main_process')
    }

    return ''
  }

  const resetTimePosition = () => {
    setCanvasDuration({
      canvasStartTime: defaultTimeStart,
      canvasEndTime: defaultTimeEnd,
    })
    setReloadTimeline((n) => !n)
  }

  return (
    <>
      <div className="milestone">
        {loading ? (
          <Loader />
        ) : (
          <div key={reloadTimeline ? '1' : '2'}>
            <Timeline
              groups={groups}
              items={items}
              itemsSorted={true}
              itemTouchSendsClick={false}
              stackItems={true}
              sidebarWidth={250}
              itemHeightRatio={0.9}
              showCursorLine={true}
              lineHeight={33}
              groupRenderer={groupRenderer}
              canMove={false}
              canResize={false}
              minZoom={30 * 86400 * 1000} // A year
              maxZoom={3 * 365.24 * 86400 * 1000} // three Year
              defaultTimeStart={canvasDuration.canvasStartTime}
              defaultTimeEnd={canvasDuration.canvasEndTime}
              itemRenderer={itemRenderer}
            >
              <TodayMarker>
                {({ styles }: any) => {
                  const newStyles = {
                    ...styles,
                    backgroundColor: '#FD7171',
                    width: '6px',
                  }
                  return <div style={newStyles} />
                }}
              </TodayMarker>
              <TimelineHeaders
                className="sticky"
                style={{ display: 'flex', width: '100%', top: 0 }}
                classNames={styleClass.dateItem}
              >
                <SidebarHeader style={{ width: '250px' }}>
                  {({ getRootProps }: any) => {
                    return (
                      <div {...getRootProps()}>
                        <div
                          className={styleClass.sideHeader}
                          style={{
                            width: '250px',
                            maxHeight: '100%',
                            backgroundColor: '#F4F5F7',
                          }}
                        >
                          <div
                            style={{
                              height: '90px',
                              width: '250px',
                              color: '#6b7280',
                            }}
                            className={styleClass.gridItem}
                          >
                            {leftMenuTitle()}
                          </div>
                        </div>
                      </div>
                    )
                  }}
                </SidebarHeader>
                <DateHeader
                  className={styleClass.dateHeader}
                  unit="year"
                  labelFormat="YYYY"
                />
                <CustomMonthHeader setShowWeek={setShowWeek} />
                {showWeek && (
                  <DateHeader
                    className={styleClass.dateHeader}
                    unit="week"
                    labelFormat="w"
                  />
                )}
              </TimelineHeaders>
            </Timeline>
          </div>
        )}
      </div>
      <HelperIcons className="top-20 right-4">
        <>
          <CanvasFloatingFilter
            showProcessSelector={true}
            showThemes={true}
            showBackPlanFilter={true}
            showUser={true}
            showTag={true}
            showOpenFilter={true}
            filterName={TimelineFilterTypes.MILESTONE_TIMELINE}
            reload={reloadData}
            getData={getData}
          />
          <TimeLineResetDate onReset={resetTimePosition} />
        </>
      </HelperIcons>
      {showDetailsModal && selectedMilestone && selectedMilestone.id ? (
        <MilestoneInspectorPanel
          milestoneId={selectedMilestone.id}
          origin={{
            name: t('visual_overview_by_discipline'),
            url: '/milestone?tabIndex=2',
          }}
          open={showDetailsModal}
          onClose={closeMilestoneModal}
          onUpdate={() => setReloadData((n) => !n)}
        />
      ) : null}
    </>
  )
}

export default MilestoneTimeline
