import moment from 'moment'
import { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import UserIcon from 'src/components/user/UserIcon'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import {
  getConstructionWagon,
  getWagonCheckInOuts,
} from 'src/service/ConstructionWagonService'
import {
  ICheckInOut,
  IConstructionWagon,
  ITaskData,
} from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import useWindowDimensions from 'src/ui-elements/page-display/UseWindowDimensions'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { capFirstLetter, classNames } from '../../utility/utils'
import { contentStyle } from '../MUtils'
import MCard from '../components/MCard'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'

const MChecksDetail = () => {
  const styleClass = {
    root: classNames('bg-mobile', 'w-screen', 'h-screen'),
    container: classNames('p-4', 'overflow-y-auto'),
    row: classNames('flex', 'flex-nowrap', 'items-center'),
    label: classNames(
      'text-xs',
      'text-gray-600',
      'font-light',
      'p-2 pl-0',
      'min-w-24 ',
    ),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'my-2',
      'text-xs',
      'font-light',
    ),
  }

  const { t } = useTranslation()
  const history = useHistory()
  const { wid } = useParams<{ wid: string }>()
  const location = useLocation<{ isCheckIn: boolean }>()
  const isCheckIn = location.state.isCheckIn
  const userContext: IUserContext = useContext(UserContext)
  const projectContext: IProjectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const [loading, setLoading] = useState<boolean>(false)
  const [check, setCheck] = useState<ICheckInOut>({} as ICheckInOut)
  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )

  const checkInText = () => [
    t(
      'area_in_which_the_wagon_will_now_start_has_no_outstanding_work_to_prevent_the_work_to_be_carried_out_in_this_wagon',
    ),
    t(
      'area_has_no_material_or_equipment_left_in_the_area_that_hinders_the_work',
    ),
    t('area_has_been_cleared_and_vacuumed'),
    t('area_is_safe_to_work_in'),
    t(
      'all_damage_that_has_occurred_to_floors_walls_ceilings_and_any_installations_or_equipment_is_registered_before_start_up',
    ),
  ]

  const checkOutText = () => [
    t(
      'carriage_that_now_leaves_the_roof_area_has_no_outstanding_work_to_prevent_the_next_carriages',
    ),
    t(
      'carriage_that_now_leaves_the_area_has_no_material_or_equipment_left_in_the_area',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_cleared_and_vacuumed_the_area',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_checked_that_the_area_is_safe_for_others_to_move_in',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_registered_all_damage_that_has_occurred_to_the_floor_walls_roof_and_any_installations_or_equipment',
    ),
  ]

  const getLabel = (index: number): JSX.Element => {
    return (
      <div className={'text-xs font-light text-gray-700 py-1'}>
        {isCheckIn ? checkInText()[index] : checkOutText()[index]}
      </div>
    )
  }

  const onBackClick = () => {
    history.goBack()
  }

  useEffect(() => {
    if (wid) {
      setLoading(true)
      getConstructionWagon(+wid).then((res) => setWagon(res))
      getWagonCheckInOuts(+wid).then((res) => {
        const selectedCheck = res[isCheckIn ? 0 : 1]
        setCheck(selectedCheck)
        setLoading(false)
      })
    }
  }, [wid, projectId])

  const handleShowTasks = (type: number) => {
    let showTasks: ITaskData[] = []
    switch (type) {
      case 0:
        showTasks = check?.no_outstanding_work?.tasks
        break
      case 1:
        showTasks = check?.no_equipment_left?.tasks
        break
      case 2:
        showTasks = check?.cleared_and_vacuumed?.tasks
        break
      case 3:
        showTasks = check?.safe_to_work_in?.tasks
        break
      case 4:
        showTasks = check?.damage_is_registered?.tasks
        break
    }
    if (showTasks?.length > 0) {
      showTasks.map((item) => (item.category = 'CheckIn/Out'))
      history.push({
        pathname: `/mobile/${projectId}/issues/checkinout`,
        state: { tasks: showTasks },
      })
    }
  }

  const onEdit = () => {
    history.push({
      pathname: `/mobile/check-in-out/${wid}`,
      state: { isCheckIn, checkData: check },
    })
  }

  const getItem = (color: string) => (
    <div
      className={`rounded-full flex w-6 h-6 bg-${color} hover:border-white focus:border-white mr-2`}
    />
  )
  const getVal = (val: string, type: number) => (
    <div
      onClick={() => handleShowTasks(type)}
      className={`flex justify-center items-center rounded-full mr-1 w-6 h-6 bg-gray-mobile text-gray-400 border border-gray-300`}
    >
      {val}
    </div>
  )

  const { width } = useWindowDimensions()

  return (
    <MPage bgColor={'mobile'}>
      <>
        <MHeader
          title={capFirstLetter(
            t(isCheckIn ? 'check_in_detail' : 'check_out_detail'),
          )}
          showHomeBtn={true}
          showUser={false}
          onBackClick={onBackClick}
          user={userContext.state.user}
        />
        <div style={contentStyle} className={styleClass.container}>
          {loading ? (
            <div className={'w-full h-full grid place-items-center'}>
              <SpinnerMobile />
            </div>
          ) : (
            <>
              <MCard>
                <div className={'flex flex-col w-full'}>
                  <Button
                    onClick={onEdit}
                    width={(width - 75) / 2}
                    inMobile={true}
                    type={Button.ButtonType.SUCCESS}
                    size={ButtonSize.XSMALL}
                  >
                    <Icon
                      className={'w-4 h-4 mr-2 flex items-center'}
                      icon={Icons.EDIT_WHITE}
                    />
                    <p>{t('edit')}</p>
                  </Button>

                  <div className={`flex mb-3 w-full`}>
                    <div className={'mr-1 min-w-18 invisible flex-0'} />
                    <div
                      className={
                        'flex text-xs text-gray-500 justify-end flex-auto min-w-0 px-2'
                      }
                    >
                      {!isEmpty(check) && (
                        <p>
                          {moment(check?.check_in_date).format(
                            'ddd MMM DD YYYY',
                          )}
                        </p>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-start text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{getLabel(0)}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(check) && (
                        <>
                          {getItem(`m-${check?.no_outstanding_work?.color}`)}
                          {getVal(
                            `${check?.no_outstanding_work?.tasks.length}`,
                            0,
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-start text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{getLabel(1)}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(check) && (
                        <>
                          {getItem(`m-${check?.no_equipment_left?.color}`)}
                          {getVal(
                            `${check?.no_equipment_left?.tasks.length}`,
                            1,
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-start text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{getLabel(2)}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(check) && (
                        <>
                          {getItem(`m-${check?.cleared_and_vacuumed?.color}`)}
                          {getVal(
                            `${check?.cleared_and_vacuumed?.tasks.length}`,
                            2,
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-start text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{getLabel(3)}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(check) && (
                        <>
                          {getItem(`m-${check?.safe_to_work_in?.color}`)}
                          {getVal(`${check?.safe_to_work_in?.tasks.length}`, 3)}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-start text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{getLabel(4)}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(check) && (
                        <>
                          {getItem(`m-${check?.damage_is_registered?.color}`)}
                          {getVal(
                            `${check?.damage_is_registered?.tasks.length}`,
                            4,
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {!isCheckIn && (
                    <>
                      <div
                        className={'flex items-start text-xs justify-between'}
                      >
                        <div className={'font-light text-gray-700 py-1'}>
                          {t('actual_working_hours')}
                        </div>

                        <div className={'font-normal text-gray-800'}>
                          {wagon?.actual_work_hours ?? 0}
                        </div>
                      </div>

                      <div
                        className={'flex items-start text-xs justify-between'}
                      >
                        <div className={'font-light text-gray-700 py-1'}>
                          {t('actual_staffing')}
                        </div>

                        <div className={'font-normal text-gray-800'}>
                          {wagon?.actual_staffing ?? 0}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className={'my-4 flex flex-nowrap items-center'}>
                  <div className={'w-1/3 text-gray-700 text-xs'}>
                    {t('signed_by')}:
                  </div>
                  <div className={'w-2/3'}>
                    <UserIcon
                      userId={check?.check_in_by_id}
                      firstName={check?.check_in_by?.firstName}
                      lastName={check?.check_in_by?.lastName}
                      image_url={check?.check_in_by?.image_url}
                    />
                  </div>
                </div>
                <div className={'my-4 flex flex-nowrap items-center'}>
                  <div className={'w-1/3 text-gray-700 text-xs'}>
                    {t('signature')}:
                  </div>
                  <div className={'w-2/3'}>
                    <img
                      width="100%"
                      height={'auto'}
                      src={check?.signature}
                      alt={`${check?.check_in_by?.firstName} ${t('signature')}`}
                    />
                  </div>
                </div>
              </MCard>
            </>
          )}
        </div>
      </>
    </MPage>
  )
}

export default MChecksDetail
