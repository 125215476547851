import { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import { styleClass as columnStyleClass } from 'src/ui-elements/Table/Columns'
import { classNames } from 'src/utility/utils'
import { IStepData } from '../../types/IFlow'

interface IDocListStageProps {
  stage: IStepData
}

const styleClass = {
  count: (underReview: boolean) =>
    classNames(
      'rounded-full justify-center flex flex-shrink-0 w-5 aspect-square flex-col text-center font-bold text-xs text-white',
      underReview ? 'bg-d-grey-light' : 'bg-d-darkblue',
    ),
  text: (underReview: boolean) =>
    classNames(
      'ml-1 font-semibold text-xs capitalize',
      underReview ? 'text-d-grey-light' : 'text-d-darkblue',
    ),
}

const DocListStage: FC<IDocListStageProps> = ({
  stage,
}: IDocListStageProps) => {
  if (!stage) return null
  return (
    <div className={twMerge('flex w-full items-center', columnStyleClass.cell)}>
      <span className={styleClass.count(stage.review || false)}>
        {stage.sequence}
      </span>
      <span className={styleClass.text(stage.review || false)}>
        {stage.name}
      </span>
    </div>
  )
}

export default DocListStage
