import * as React from 'react'
import { IRisk } from 'src/service/OrgTypes'

export interface IRiskContextProps {
  projectId: number
  riskGroupId: number
  loadFromTable?: boolean
  children: React.ReactNode
}

export interface IRiskContext {
  actions: {
    reloadRiskData: () => void
    setRiskData: (risks: IRisk[]) => void
    setExpectedValues: (expectedValues: {
      totalExpectedValue: number
      totalExpectedValueWithProbability: number
    }) => void
  }
  state: IRiskContextState
}

export interface IRiskContextState {
  risks: IRisk[]
  projectId: number
  riskGroupId: number
  totalExpectedValue: number
  totalExpectedValueWithProbability: number
}

export type RiskContext = IRiskContext
export const RiskContext = React.createContext<RiskContext>({} as IRiskContext)
export const RiskContextConsumer = RiskContext.Consumer
