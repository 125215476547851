import { IActivityLog, IBroadcast } from './OrgTypes'
import { request } from './service-utils'

export const markLogRead = (activityLogId: number): Promise<any> => {
  return request(`activity_logs/${activityLogId}/mark_as_read`, 'PUT')
}

export const markLogUnread = (activityLogId: number): Promise<any> => {
  return request(`activity_logs/${activityLogId}/mark_as_unread`, 'PUT')
}

export const removeNotificationForUser = (
  activityLogId: number,
): Promise<any> => {
  return request(
    `/activity_logs/${activityLogId}/remove_notification_for_user`,
    'PUT',
  )
}

export const getUnreadLogs = (
  projectId: number,
  userId: number,
): Promise<IActivityLog[]> => {
  return request(
    `/projects/${projectId}/users/${userId}/unread_activity_logs`,
    'GET',
  )
}

export const markAllAsRead = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/activity_logs/mark_as_read/all`, 'PUT')
}

export const getNotification = (notificationId: number): Promise<any> => {
  return request(`/activity_logs/${notificationId}`, 'GET')
}

export const broadcast = (message: IBroadcast): Promise<any> => {
  return request(`activity_logs/broadcast`, 'POST', message)
}
