export enum ButtonType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BOUNDARY_WHITE = 'boundery_white',
  ICON = 'icon',
  INFO = 'info',
  DANGER = 'danger',
  SUCCESS = 'success',
  DARK = 'dark',
  TOP_MENU = 'topmenu',
  WARNING = 'WARNING',
  ROUNDED_SECONDARY = 'ROUNDED_SECONDARY',
  ROUNDED_DANGER = 'CIRCLULAR_DANGER',
  FADED = 'FADED',
}

export enum ButtonSize {
  XSMALL = 'xsmall',
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
  XLARGE = 'xlarge',
  NARROW = 'narrow',
}

export enum ButtonTextWeight {
  DEFAULT = 'default',
  THIN = 'thin',
}
